<template>
  <nav class="navbar navbar-default navbar-lg" role="navigation" id="header-content">
    <div class="navbar-header">
      <h2 class="navbar-title">{{ $t('sidebar.CIQ_settings') }}</h2>
    </div>
  </nav>
  <div class="wrapper with-navbar-fixed-top with-navbar-fixed-bottom">
    <div class="container-fluid">
      <div class="section noborder ptn" id="section--main">
        <div class="">
          <div class="row">
            <div class="col-sm-4 col-md-2">
              <div class="form-group viewonly">
                <label>
                  <span class="label-text">{{ $t('setting_ciq.country_code') }}</span>
                </label>
                <p class="viewonly-text input-sm input-sm">{{ settingCiq.countries?.code }}</p>
              </div>
            </div>
            <div class="col-sm-8 col-md-10">
              <div class="form-group viewonly">
                <label>
                  <span class="label-text">{{ $t('setting_ciq.country_name') }}</span>
                </label>
                <p class="viewonly-text input-sm input-sm">{{ labelLocale(settingCiq.countries) }}</p>
              </div>
            </div>
          </div>
          <div v-if="!checkEmpty(settingCiq)" class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label>
                  <span class="label-text">{{ $t('setting_ciq.requirements') }}</span>
                </label>
                <QuillEditor
                  contentType="html"
                  theme="snow"
                  v-model:content="settingCiq.requirements"
                  :toolbar="[
                    [
                      'bold',
                      'italic',
                      'underline',
                      'strike',
                      { align: ['', 'center', 'right', 'justify'] },
                      { size: ['small', false, 'large', 'huge'] },
                    ],
                    ['link'],
                  ]"
                />
                <p role="alert" class="error-feedback" v-text="errors.requirements"></p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label>
                  <span class="label-text">{{ $t('setting_ciq.document') }}</span>
                </label>
                <div class="mbm">
                  <ul class="uploaded--file-list" style="">
                    <li v-for="(item, index) in files" :key="index">
                      <a
                        :href="item.file_path"
                        target="_blank"
                        style="display: flex; align-items: center"
                        rel="noopener"
                      >
                        <i class="icon-abui-file"></i><span class="file--name">{{ item?.name || item.file.name }}</span>
                      </a>
                      <span class="file--date">{{ item?.date || item.created_at }}</span>
                      <button
                        type="button"
                        class="btn btn-flat-danger btn-sm mll"
                        style=""
                        @click="handleDeleteLocalCiqFile(item)"
                      >
                        <i class="icon-abui-cross"></i>
                      </button>
                    </li>
                  </ul>
                </div>
                <button type="button" class="btn btn-inverse btn-sm" @click="showModalUploadFile = true">
                  <i class="icon-abui-upload prx"></i>{{ $t('setting_ciq.upload') }}
                </button>
              </div>
            </div>
          </div>
          <div class="">
            <div class="last-update">
              {{ $t('setting_ciq.last_updated') }}：{{ settingCiq.updated_at }} {{ settingCiq.updated_by?.name }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="navbar navbar-default navbar-fixed-bottom navbar-footblock with-sidebar submit-new--wrap show--estimate"
      role="navigation"
      style="z-index: 990; padding: 12px"
    >
      <div class="row">
        <div class="col-sm-4 col-md-3 col-sm-offset-8 col-md-offset-9">
          <a href="#" class="btn btn-sm btn-block btn-primary open-alert" @click="updateSettingCiq">
            {{ $t('setting_ciq.keep') }}</a
          >
        </div>
      </div>
    </div>
  </div>
  <ModalUploadFile
    :dialogVisible="showModalUploadFile"
    @cancel="showModalUploadFile = false"
    @confirm="confirmUploadFile"
    :title="$t('setting_ciq.title_upload')"
  />
  <ModalConfirm
    :dialogVisible="showModalConfirm"
    @cancel="showModalConfirm = false"
    @yes="deleteCiqFile"
    :title="$t('Q-CM-104')"
  />
</template>

<script>
import ModalUploadFile from '@/components/common/ModalUploadFile';
import ModalConfirm from '@/components/common/ModalConfirm';
import moment from 'moment';

import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

export default {
  name: 'CIQ',
  components: { ModalUploadFile, ModalConfirm, QuillEditor },
  data() {
    return {
      showModalUploadFile: false,
      showModalConfirm: false,
      settingCiq: {},
      settingCiqOfficial: {},
      files: [],
      filesOfficial: [],
      errors: {
        name: '',
        code: '',
        parent_organization: '',
        burden_organization: '',
        requirements: '',
      },
      indexFile: '',
      ciqId: '',
      fileId: '',
      avoidRouteLeave: false,
    };
  },
  beforeRouteLeave(to, from, next) {
    if (
      (!this.compareObject(this.settingCiq, this.settingCiqOfficial) ||
        !this.compareArray(this.files, this.filesOfficial)) &&
      !this.avoidRouteLeave
    ) {
      const answer = window.confirm(this.$t('common.confirm_leave_page'));
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  computed: {
    settingCiqId() {
      return this.$route.params.id || '';
    },
  },
  created() {
    if (this.settingCiqId) {
      this.getDetailSettingCiq();
    }
  },
  methods: {
    updateSettingCiq() {
      if (!this.settingCiq.requirements.trim()) {
        this.errors.requirements = this.$t('E-CM-100', { item: this.$t('setting_ciq.requirements') });
        return;
      }

      this.startLoading();
      let formData = new FormData();
      for (let index = 0; index < this.files.length; index++) {
        if (this.checkEmpty(this.files[index].id)) {
          formData.append('files[]', this.files[index]);
        } else {
          formData.append('input_ids[]', this.files[index].input_id);
        }
      }
      formData.append('requirements', this.settingCiq.requirements);
      formData.append('country_id', this.settingCiq.countries.id);
      formData.append('updated_by', this.$store.state.auth?.user?.id);
      formData.append('updated_at', this.settingCiq.updated_at);

      this.$services.setting_CIQ.updateCiq(
        this.settingCiqId,
        formData,
        () => {
          // this.endLoading();
          this.notifySuccess(this.$t('I-CM-103'));
          this.avoidRouteLeave = true;
          this.$router.push(this.PAGES.SETTINGS_CIQ);
        },
        err => {
          this.endLoading();
          this.notifyError(this.$t(err.error), true);
        },
      );
    },
    confirmUploadFile(listFiles) {
      if (!this.checkEmpty(listFiles.length)) {
        listFiles.forEach(file => {
          let fileExits = this.files.find(item => item.name == file.name);
          if (this.checkEmpty(fileExits)) {
            file.created_at = moment(new Date()).format('YYYY/MM/DD HH:mm');
            this.files.push(file);
          } else {
            this.notifyError(this.$t('common.invalid_file'));
          }
        });
      } else {
        this.notifyError(this.$t('E-CM-101', { name: this.$t('common.file') }));
      }
      this.showModalUploadFile = false;
    },

    handleDeleteLocalCiqFile(item) {
      this.showModalConfirm = true;
      this.indexFile = item;
    },
    deleteCiqFile() {
      this.files = this.files.filter(file => file.name != this.indexFile.name);
      this.showModalConfirm = false;
    },
    async getDetailSettingCiq() {
      this.startLoading();
      await this.$services.setting_CIQ.detailCiq(
        this.settingCiqId,
        res => {
          this.endLoading();
          this.settingCiq = res;
          this.files = res.inputs.map(item => {
            item.file.input_id = item.id;
            return item.file;
          });
          this.settingCiqOfficial = this.cloneObject(res);
          this.filesOfficial = this.settingCiqOfficial.inputs.map(item => {
            item.file.input_id = item.id;
            return item.file;
          });
        },
        err => {
          // this.endLoading();
          this.notifyError(this.$t(err.error));
          this.$router.push(this.PAGES.SETTINGS_CIQ);
        },
      );
    },
  },
};
</script>
