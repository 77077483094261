<template>
  <nav class="navbar navbar-default navbar-lg" role="navigation" id="header-content">
    <div class="navbar-header">
      <h2 class="navbar-title">
        {{ $t('customer.company') }}
        <span v-show="tabIndex != 1">
          <span class="navbar-title-separator">-</span><span class="subinfo">{{ company.name }}</span>
        </span>
      </h2>
    </div>
    <div class="collapse navbar-collapse">
      <ul class="nav navbar-nav navbar-right">
        <li @click="changeSelect(1)" :class="tabIndex == 1 && 'active'">
          <a>{{ $t('customer.basic_information') }}</a>
        </li>
        <li @click="changeSelect(2)" :class="tabIndex == 2 && 'active'">
          <a><i class="icon-abui-addressbook prx" />{{ $t('customer.address_book') }}</a>
        </li>
        <li @click="changeSelect(3)" :class="tabIndex == 3 && 'active'">
          <a><i class="icon-abui-folder prx" />{{ $t('customer.file') }}</a>
        </li>
      </ul>
    </div>
  </nav>

  <div class="wrapper">
    <div class="container-fluid">
      <Contact v-show="tabIndex == 1" @onChangeData="onChangeData" />
      <People v-show="tabIndex == 2" @onChangeData="onChangeData" />
      <File v-show="tabIndex == 3" />
    </div>
  </div>
</template>
<script>
import Contact from '../../../components/customer-enterprise/Contact.vue';
import People from '../../../components/customer-enterprise/People.vue';
import File from '../../../components/customer-enterprise/File.vue';
export default {
  name: 'CustomerEnterprise',
  components: { Contact, People, File },
  data() {
    return {
      tabIndex: 1,
      company: {},
      isChangeData: false,
    };
  },
  created() {
    this.getCompanyInformation();
    if (this.$route.query.user_id && this.$route.query.from == 'requirement') {
      this.changeSelect(2);
    }
  },
  computed: {
    customerId() {
      return this.$route.params.id || '';
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.isChangeData) {
      const answer = window.confirm(this.$t('common.confirm_leave_page'));
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  methods: {
    onChangeData(val) {
      this.isChangeData = val;
    },
    changeSelect(index) {
      this.tabIndex = index;
    },
    async getCompanyInformation() {
      if (this.checkEmpty(this.customerId)) return;
      this.startLoading();
      await this.$services.customer_enterprise.getCompanyInformation(
        this.customerId,
        res => {
          this.endLoading();
          this.company = res;
        },
        err => {
          // this.endLoading();
          this.notifyError(this.$t(err.error));
          this.$router.push(this.PAGES.CUSTOMER_ENTERPRISE);
        },
      );
    },
  },
};
</script>
<style scoped>
.navbar-right li {
  cursor: pointer;
}
</style>
