<template>
  <el-dialog
      v-model="dialogVisibleStatus"
      :append-to-body="true"
      :show-close="false"
      @close="$emit('cancel')"
      width="642px"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-header--title">{{ title }}</div>
        </div>
        <div class="modal-body">
          <div class="uploaded" v-if="!checkEmpty(this.listOnlyFile)">
            <ul class="trip--info--file-list">
              <li class="uploaded-item" v-for="(item, index) in listOnlyFile" :key="index">
                <a :href="item.link" target="_blank" >
                  <i class="icon-abui-file"></i>
                  <span class="file--name">{{ item.name }}</span>
                </a>
<!--                <i-->
<!--                    style="padding-right: 14px; color: brown; font-weight: bold"-->
<!--                    class="icon-abui-cross pls cursor-pointer"-->
<!--                    @click="removeFile(index)"-->
<!--                ></i>-->
              </li>
            </ul>
          </div>
          <div class="row">
<!--            <div class="col-md-12" v-if="checkEmpty(this.listOnlyFile)">-->
<!--              <input-->
<!--                  type="file"-->
<!--                  :multiple="!isSingleUpload"-->
<!--                  name="file"-->
<!--                  style="display: none"-->
<!--                  @change="onFileChange"-->
<!--                  ref="inputFile"-->
<!--                  :accept="acceptedList"-->
<!--              />-->
<!--              <div-->
<!--                  class="dropzone cursor-pointer"-->
<!--                  @click="chooseFile()"-->
<!--                  :data-active="false"-->
<!--                  @dragenter.prevent="true"-->
<!--                  @dragover.prevent="true"-->
<!--                  @dragleave.prevent="false"-->
<!--                  @drop.prevent="onDrop"-->
<!--              >-->
<!--                <div class="dz-default dz-message">-->
<!--                  <span>-->
<!--                    <span class="icon icon-abui-file"></span>-->
<!--                    {{ $t('common.drop_file_here') }}-->
<!--                    <small>{{ $t('common.click_to_specify_the_location') }}</small>-->
<!--                  </span>-->
<!--                </div>-->
<!--              </div>-->
<!--              <p role="alert" class="error-feedback" v-text="errors.file"></p>-->
<!--            </div>-->
            <p role="alert" class="error-feedback" style="margin-left: 10px" v-text="errors.file"></p>
            <div class="col-md-12" v-if="isUploadWithNote">
              <label><span class="label-text">{{$t('trip_update.edit_text')}}</span></label>
              <textarea
                  class="form-control"
                  rows="10"
                  :placeholder="$t('trip.content_reservation')"
                  maxlength="10000"
                  style="resize: none;"
                  :disabled="true"
                  v-model="params.content_reservation"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer" >
          <div class="row">
            <div class="col-xs-6 col-sm-6 col-md-6">
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6">
<!--              <button class="btn btn-sm btn-primary btn-block" @click="submit()" v-if="!isOnlyView">-->
<!--                {{ $t('common.confirm') }}-->
<!--              </button>-->
              <button class="btn btn-sm btn-flat-default btn-block" @click="$emit('cancel')">
                {{ $t('common.cancel') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<style>
.dz-image .icon {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
<script>
import { ElDialog } from 'element-plus';
import { MAX_FILE_SIZE } from '@/constants/';
export default {
  components: {
    ElDialog,
  },
  name: 'ModalUploadFileEdit',
  emits: ['confirm', 'cancel', 'files-dropped'],
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    isSingleUpload: {
      type: Boolean,
      default: false,
    },
    isOnlyView: {
      type: Boolean,
      default: false,
    },
    isUploadWithNote: {
      type: Boolean,
      default: false,
    },
    acceptedFiles: {
      type: String,
      default: '',
    },
    acceptImageAndPDFOnly: {
      type: Boolean,
      default: false,
    },
    itemFileEdit: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dialogVisibleStatus: false,
      listFile: [],
      errors: {},
      params: {},
    };
  },
  watch: {
    itemFileEdit() {
      this.params = {
        input_id: this.itemFileEdit.input_id,
        content_reservation: this.itemFileEdit.content_reservation,
      }
      this.listFile = [];
      this.listFile.push(this.itemFileEdit);
    },
    dialogVisible() {
      this.dialogVisibleStatus = this.dialogVisible;
      if (this.dialogVisible) {
        // this.listFile = [];
      }
    },
    dialogVisibleStatus() {
      if (!this.dialogVisibleStatus) {
        // this.listFile = [];
        this.content_reservation = '';
        this.errors.file = '';
      }
    },
  },
  computed: {
    listOnlyFile() {
      return this.listFile.filter(function(item){
        return item.name !== undefined;
      });
    },
    acceptedList() {
      if (!this.checkEmpty(this.acceptedFiles)) {
        return this.acceptedFiles;
      }

      return 'image/jpg, image/jpeg, image/png, .pdf, .csv, .xlsx, .docx, .heif';
    },
  },
  methods: {
    submit() {
      if (this.checkEmpty(this.params.content_reservation) && this.checkEmpty(this.listOnlyFile)) {
        this.errors.file = this.$t('trip_update.validate_upload_trip_text');
        //Process this only when accept image only.
      } else if (this.acceptImageAndPDFOnly) {
        let flagValidateFormat = true;
        const acceptedFormatArr = this.acceptedList.split(', ');
        this.listFile.forEach(item => {
          if (!acceptedFormatArr.includes(item.type)) flagValidateFormat = false;
        });
        if (!flagValidateFormat) {
          this.errors.file = this.$t('common.invalid_image_or_pdf_format');
          return;
        } else {
          this.errors.file = '';
          this.$emit('confirm', this.params);
          this.listFile = [];
          this.content_reservation = '';
        }
      } else {
        this.errors.file = '';
        this.$emit('confirm', this.params);
        this.listFile = [];
        this.content_reservation = '';
      }
    },
    chooseFile() {
      this.$refs.inputFile.click();
    },
    removeFile(index) {
      this.listFile.splice(index, 1);
    },
    getSize(size) {
      return (size / (1024 * 1024)).toFixed(2);
    },
    async onFileChange(e) {
      this.handleUploadFile(e.target);
    },
    onDrop(e) {
      this.handleUploadFile(e.dataTransfer);
    },
    handleUploadFile(data) {
      const files = data.files;
      console.log(files)
      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        if (file?.type && !this.isTypeVideo(file.type)) {
          if (file.size <= MAX_FILE_SIZE) {
            if (this.isSingleUpload) {
              // this.listFile = [];
            }
            this.listFile.push(file);
          } else {
            this.notifyError(this.$t('E-CU-223'));
          }
        } else {
          this.notifyError(this.$t('E-CU-222'));
        }
      }
      this.$refs.inputFile.value = '';
    },
  },
};
</script>
