<template>
  <div class="wrapper with-navbar-fixed-top with-navbar-fixed-bottom">
    <div class="container-fluid">
      <div class="section noborder ptn">
        <div class="">
          <div class="row" v-if="!isDetail">
            <div class="col-sm-4 col-md-2">
              <div class="form-group required">
                <label>
                  <span class="label-text">{{ $t('user.employee_number') }}</span>
                </label>
                <input
                  :disabled="isDetail"
                  v-model="dataRequest.employee_number"
                  :class="!checkEmpty(errors.employee_number) && 'has-validation-error'"
                  maxlength="50"
                  type="text"
                  class="form-control input-sm"
                />
                <p role="alert" class="error-feedback" v-text="errors.employee_number"></p>
              </div>
            </div>
          </div>
          <div class="row" v-else>
            <div class="col-sm-4 col-md-2 col-lg-2">
              <div class="form-group viewonly">
                <label>
                  <span class="label-text">{{ $t('user.employee_number') }}</span>
                </label>
                <p class="viewonly-text input-sm input-sm" id="customer-id__view">{{ dataRequest.employee_number }}</p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <div class="form-group required show--edit show--new hide--view">
                <label>
                  <span class="label-text">{{ $t('user.name') }}</span>
                </label>
                <input
                  v-model="dataRequest.name"
                  type="text"
                  maxlength="100"
                  class="form-control input-sm"
                  :class="!checkEmpty(errors.name) && 'has-validation-error'"
                />
                <p role="alert" class="error-feedback" v-text="errors.name"></p>
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
              <div class="form-group required show--edit show--new hide--view">
                <label>
                  <span class="label-text">{{ $t('user.email') }}</span>
                </label>
                <vue3-tags-input
                  v-model="tag_mail"
                  :tags="tags"
                  :class="!checkEmpty(errors.email) && 'has-validation-error'"
                  @on-tags-changed="handleChangeTag"
                  @focusout="handleFocusOutTag(tag_mail)"
                  :validate="customValidate"
                />
                <p role="alert" class="error-feedback" v-text="errors.email"></p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group show--edit show--new hide--view hide--edit-personal">
                <label>
                  <span class="label-text">{{ $t('user.role_id') }}</span>
                </label>
                <div class="btn-group bootstrap-select select-block select-sm">
                  <el-select
                    v-model="dataRequest.role_id"
                    name="input"
                    popper-class="custom-dropdown-select"
                    class="select-block select-sm"
                  >
                    <el-option
                      v-for="item in listRole"
                      :key="item.id"
                      :label="labelLocale(item)"
                      :value="item.id"
                      :disabled="routeUserId == userId && !checkEmpty(userId)"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label>
                  <span class="label-text">{{ $t('user.deploy') }}</span>
                </label>
                <input
                  v-model="dataRequest.organization_name"
                  type="text"
                  maxlength="255"
                  class="form-control input-sm"
                />
                <p role="alert" class="error-feedback" v-text="errors.organization_name"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="navbar navbar-default navbar-fixed-bottom navbar-footblock with-sidebar submit-new--wrap show--estimate"
      role="navigation"
      style="z-index: 990; padding: 12px"
    >
      <div class="row">
        <div class="col-sm-3" v-if="isDetail">
          <button @click="showBoxConfirm = true" class="btn btn-sm btn-flat-default">
            <i class="icon-abui-trash prx"></i>{{ $t('user.delete') }}
          </button>
        </div>
        <div class="col-sm-3" v-else></div>
        <div class="col-sm-4 col-md-3 col-sm-offset-5 col-md-offset-6">
          <a href="#" class="btn btn-sm btn-block btn-primary" @click="submitData()">{{ $t('common.submit') }}</a>
        </div>
      </div>
    </div>
    <ModalConfirm
      :dialogVisible="showBoxConfirm"
      @cancel="showBoxConfirm = false"
      @yes="deleteUser"
      :title="titleBoxConfirm"
    />
  </div>
</template>
<script>
import ModalConfirm from '@/components/common/ModalConfirm';
import Vue3TagsInput from 'vue3-tags-input';

export default {
  name: 'User-Form',
  components: { ModalConfirm, Vue3TagsInput },
  emits: ['onChangeData'],
  props: {
    dataForm: {
      type: Object,
      default: () => {},
    },
    isDetail: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tag_mail: '',
      titleBoxConfirm: this.$t('Q-CM-104'),
      errors: {},
      showBoxConfirm: false,
      tags: [],
      dataRequest: {
        role_id: 6,
      },
      dataRequestOfficial: {
        role_id: 6,
      },
      listRole: [],
      isSubmit: false,
    };
  },
  watch: {
    tags: {
      handler(newVal) {
        if (!this.checkEmpty(newVal)) {
          this.dataRequest.emails = newVal;
          this.dataRequest.email = newVal[0];
        }
      },
      deep: true,
    },
    // tag_mail: function (val) {
    //   if (val || val == '') {
    //     this.errors.email = '';
    //   }
    // },
    dataForm: {
      handler: function (value) {
        const dataDetail = {
          id: value.id,
          employee_number: value.employee_number,
          name: value.name,
          email: value.email,
          emails: value.emails_string.split(',').filter(Boolean),
          role_id: value.authority.id,
          organization_name: value.organization_name,
        };
        (this.tags = value.emails_string.split(',').filter(Boolean)), (this.dataRequest = dataDetail);
        this.dataRequestOfficial = this.cloneObject(dataDetail);
      },
      deep: true,
    },
    dataRequest: {
      handler: function (newValue) {
        this.onChangeData();
        if (newValue.employee_number) {
          delete this.errors.employee_number;
        }
        if (newValue.name) {
          delete this.errors.name;
        }
        if (this.strLen(newValue.employee_number) > 50) {
          this.errors.employee_number = this.$t('E-CM-111', { length: 50 });
        } else {
          delete this.errors.employee_number;
        }
        if (this.strLen(newValue.name) > 100) {
          this.errors.name = this.$t('E-CM-111', { length: 100 });
        } else {
          delete this.errors.name;
        }
        if (this.strLen(newValue.email) > 100) {
          this.errors.email = this.$t('E-CM-111', { length: 100 });
        } else {
          delete this.errors.email;
        }
        if (this.strLen(newValue.organization_name) > 255) {
          this.errors.organization_name = this.$t('E-CM-111', { length: 255 });
        } else {
          delete this.errors.organization_name;
        }
      },
      deep: true,
    },
    isSubmit: {
      handler: function () {
        this.onChangeData();
      },
      deep: true,
    },
  },
  computed: {
    routeUserId() {
      return this.$route.params.id || '';
    },
  },
  created() {
    this.getListRole();
  },
  methods: {
    handleFocusOutTag(value) {
      if (this.checkEmpty(value)) {
        this.errors.email = '';
        return;
      }
      if (this.customValidate(value) && !this.tags.includes(value)) {
        this.errors.email = '';
        this.tags.push(value);
        this.tag_mail = '';
      } else {
        this.errors.email = this.$t('employee.invalid_email_or_existed');
      }
    },
    handleChangeTag(tags) {
      this.tags = tags;
      this.dataRequest.emails = this.tags;
      this.dataRequest.email = !this.checkEmpty(this.dataRequest.emails) ? this.dataRequest.emails[0] : '';
    },
    customValidate(value) {
      const regex = new RegExp(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );
      return regex.test(value);
    },
    onChangeData() {
      this.$emit('onChangeData', !this.compareObject(this.dataRequest, this.dataRequestOfficial) && !this.isSubmit);
    },
    submitData() {
      if (this.checkEmpty(this.dataRequest) || !this.checkEmpty(this.tag_mail)) return;
      let flgValidate = true;

      if (this.checkEmpty(this.dataRequest.employee_number)) {
        flgValidate = false;
        this.errors.employee_number = this.$t('E-CM-100', { item: this.$t('user.employee_number') });
      } else {
        this.errors.employee_number = '';
      }
      if (this.checkEmpty(this.dataRequest.name)) {
        flgValidate = false;
        this.errors.name = this.$t('E-CM-100', { item: this.$t('user.name') });
      } else {
        this.errors.name = '';
      }
      if (this.checkEmpty(this.dataRequest.email)) {
        flgValidate = false;
        this.errors.email = this.$t('E-CM-100', { item: this.$t('user.email') });
      } else {
        this.errors.email = '';
      }

      if (!flgValidate) return;

      if (this.isDetail) {
        this.updateData();
      } else {
        this.createData();
      }
    },
    createData() {
      this.isSubmit = true;
      this.startLoading();
      this.$services.user.createUser(
        this.dataRequest,
        () => {
          // this.endLoading();
          this.$router.push(this.PAGES.USER);
          this.notifySuccess(this.$t('I-CM-103'));
        },
        err => {
          if (!this.checkEmpty(err.error.employee_number) && err.error.employee_number[0] === 'E-CU-344') {
            this.errors.employee_number = this.$t('E-CU-344');
          }
          if (!this.checkEmpty(err.error.employee_number) && err.error.employee_number[0] === 'E-CM-107') {
            this.errors.employee_number = this.$t('E-CM-107', { item: this.$t('user.employee_number') });
          }
          if (!this.checkEmpty(err.error.email) && err.error.email[0] === 'E-CU-324') {
            this.errors.email = this.$t('E-CM-101', { name: this.$t('user.email') });
          }
          this.endLoading();

          this.notifyError(this.$t(err.error));
        },
      );
    },
    updateData() {
      this.isSubmit = true;
      this.dataRequest.updated_at = this.dataForm.updated_at;
      this.startLoading();
      this.$services.user.updateUser(
        this.dataRequest.id,
        this.dataRequest,
        () => {
          this.endLoading();
          this.$router.push(this.PAGES.USER);
          this.notifySuccess(this.$t('I-CM-103'));
        },
        err => {
          this.endLoading();
          this.notifyError(this.$t(err.error), true);
        },
      );
    },
    async getListRole() {
      this.startLoading();
      await this.$services.common.getListRole(
        res => {
          this.endLoading();
          this.listRole = res.list.filter(item => item.id > 5 && item.id != 8); // Only operator and admin role
        },
        err => {
          this.endLoading();
          this.notifyError(this.$t(err.error));
        },
      );
    },
    deleteUser() {
      this.isSubmit = true;
      this.startLoading();
      this.$services.user.deleteUser(
        this.dataRequest.id,
        () => {
          // this.endLoading();
          this.showBoxConfirm = false;
          this.$router.push(this.PAGES.USER);
          this.notifySuccess(this.$t('I-OP-053'));
        },
        err => {
          this.endLoading();
          this.showBoxConfirm = false;
          this.notifyError(this.$t(err.error));
        },
      );
    },
  },
};
</script>
<style lang="scss" scoped></style>
