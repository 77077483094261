<template>
  <nav class="navbar navbar-default navbar-lg" role="navigation" id="header-content">
    <div class="navbar-header">
      <h2 class="navbar-title">{{ $t('sidebar.procedure') }}</h2>
    </div>
    <div class="collapse navbar-collapse">
      <ul class="nav navbar-nav navbar-right">
        <li class="dropdown addNew hidden-sm">
          <a
              class="dropdown-toggle pls"
              @click="isShowDropdown = !isShowDropdown"
              v-click-outside="
              () => {
                isShowDropdown = false;
              }
            "
          >
            <b class="caret" />
          </a>
          <span class="dropdown-arrow" />
          <ul class="dropdown-menu" :class="isShowDropdown && 'showDropdown'" style="left: -320px">
            <li>
              <a @click="showModalUploadCSV = true">
                <span class="icon-abui-upload prx" />{{ $t('regulation.upload_csv_passport') }}
              </a>
            </li>
            <li>
              <a @click="downloadExampleFileCSV">
                <span class="icon-abui-download prx" />{{ $t('regulation.download_template_csv_passport') }}
              </a>
            </li>
            <li>
              <a href="https://www.iace.co.jp/travelmgr/pdf/import_sample/passport" target="_blank" >
                <span class="icon-abui-book prx" />{{ $t('common.manual_registration') }}
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </nav>

  <body>
    <div class="wrapper">
      <div class="container-fluid">
        <div class="section noborder">
          <div class="row">
            <div class="col-md-2 col-sm-3 scroll-margin-top" id="number_of_days_field">
              <div class="form-group">
                <label>
                  <span class="label-text">{{ $t('regulation.business_trip_day') }}</span>
                </label>
                <div class="input-group adjust-amount">
                  <input
                    type="text"
                    class="form-control input-sm text-right"
                    :class="!checkEmpty(errors.number_of_days) && 'has-validation-error'"
                    maxlength="3"
                    v-model="number_of_days"
                    @input="number_of_days = convertNumberFullToHalf(number_of_days)"
                  />
                  <span class="input-group-addon">{{ $t('regulation.until_the_day') }}</span>
                </div>
                <p class="error-feedback" style="width: 25vw" v-text="errors.number_of_days" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="form-inline" role="form" style="margin-bottom: 0">
                <label>
                  <span class="label-text">{{ $t('regulation.condition_not_required') }}</span>
                </label>
                <div class="form-group">
                  <label
                    class="checkbox checkbox-inline checkbox-type-div checkbox-expense"
                    :class="is_day_trip && 'checked'"
                  >
                    <span class="icons">
                      <span class="first-icon icon-abui-checkbox-unchecked" />
                      <span class="second-icon icon-abui-checkbox-checked" />
                    </span>
                    <input type="checkbox" v-model="is_day_trip" />
                    <span class="text_type">
                      {{ $t('regulation.day_trip') }}
                    </span>
                  </label>
                  <label
                    class="checkbox checkbox-inline checkbox-type-div checkbox-expense"
                    :class="is_distance && 'checked'"
                  >
                    <span class="icons">
                      <span class="first-icon icon-abui-checkbox-unchecked" />
                      <span class="second-icon icon-abui-checkbox-checked" />
                    </span>
                    <input type="checkbox" v-model="is_distance" />
                    <span class="text_type">{{ $t('regulation.the_distance_to_destination') }}</span>
                  </label>
                </div>
                <div class="form-group check-noapply-distance__tgt scroll-margin-top" id="distance_field">
                  <label class="sr-only">{{ $t('regulation.label_only') }}</label>
                  <div class="input-group adjust-amount">
                    <input
                      type="text"
                      class="form-control input-sm text-right"
                      :class="!checkEmpty(errors.distance) && 'has-validation-error'"
                      placeholder="0"
                      v-model="distance"
                      :disabled="!is_distance"
                      maxlength="10"
                      style="width: 6em"
                      @input="distance = convertNumberFullToHalf(distance)"
                      @change="convertDataCommon('distance')"
                      onkeydown="javascript: return event.keyCode !== 69;"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <span class="form-inline-text">{{ $t('regulation.with_km') }}</span>
                </div>
              </div>
              <div class="form-inline" role="form">
                <div class="form-group" style="visibility: hidden">
                  <label
                    class="checkbox checkbox-inline checkbox-type-div checkbox-expense"
                    :class="is_day_trip && 'checked'"
                  >
                    <span class="icons">
                      <span class="first-icon icon-abui-checkbox-unchecked" />
                      <span class="second-icon icon-abui-checkbox-checked" />
                    </span>
                    <input type="checkbox" v-model="is_day_trip" />
                    <span class="text_type">
                      {{ $t('regulation.day_trip') }}
                    </span>
                  </label>
                  <label
                    class="checkbox checkbox-inline checkbox-type-div checkbox-expense"
                    :class="is_distance && 'checked'"
                  >
                    <span class="icons">
                      <span class="first-icon icon-abui-checkbox-unchecked" />
                      <span class="second-icon icon-abui-checkbox-checked" />
                    </span>
                    <input type="checkbox" v-model="is_distance" />
                    <span class="text_type">
                      {{ $t('regulation.the_distance_to_destination') }}
                    </span>
                  </label>
                </div>
                <div class="form-group check-noapply-distance__tgt">
                  <p class="error-feedback" v-text="errors.distance" />
                </div>
              </div>
            </div>
          </div>

          <hr class="mtm" style="margin-bottom: 24px" />

          <div class="js-addcontrol4--wrap mbl">
            <div
              class="row addcontrol--item scroll-margin-top"
              v-for="(item, index) in passports"
              :key="index"
              :id="`passport_field_${index}`"
            >
              <div class="col-md-10 col-sm-9">
                <div class="form-group form-group-typeahead">
                  <label>
                    <span class="label-text">
                      {{ $t('regulation.passport_remaining_expiration_date') }}
                    </span>
                  </label>
                  <div class="btn-group bootstrap-select show-tick select-block select-sm dropup">
                    <MultiOptionSelectRegulation
                      :list-data="listCountry"
                      :display-by-locale="true"
                      :default-list="item.passports_countries"
                      @handle-selected-params="listenUpdatePassportsCountry"
                      :option-index="{ haveIndex: true, index }"
                      :placeholder="index ? $t('common.not_specified') :  $t('common.all_countries')"
                      :class="!checkEmpty(item?.errors?.passports_countries) && 'has-validation-error-el'"
                    />
                  </div>
                  <p class="error-feedback" v-text="item?.errors?.passports_countries" />
                </div>
              </div>
              <div class="col-md-2 col-sm-3">
                <div class="form-group">
                  <label><span class="label-text">&ensp;</span></label>
                  <div class="input-group adjust-amount">
                    <input
                      type="text"
                      class="form-control input-sm text-right"
                      :class="!checkEmpty(item?.errors?.remain_days) && 'has-validation-error'"
                      placeholder=""
                      maxlength="10"
                      v-model="item.remain_days"
                      @input="item.remain_days = convertNumberFullToHalf(item.remain_days)"
                      onkeydown="javascript: return event.keyCode !== 69;"
                      @change="convertDataCommon('passports.remain_days', true, index, true)"
                    />
                    <span class="input-group-addon">{{ $t('regulation.day') }}</span>
                  </div>
                  <p class="error-feedback" v-text="item?.errors?.remain_days" />
                </div>
              </div>
              <button
                  v-if="index > 0"
                class="btn btn-sm btn--ls btn-flat-danger js-addcontrol--del addcontrol--item-del"
                @click="deleteItem(1, index)"
              >
                <span class="icon-abui-cross" />
              </button>
            </div>
            <div class="row--addcontrol" v-if="!hideAddBtn">
              <button class="btn btn-sm btn-flat-primary js-addcontrol1" @click="addNewItem(1)">
                <span class="icon-abui-plus prx" />{{ $t('regulation.add_country') }}
              </button>
            </div>
          </div>
          <div class="mtl">
            <div class="row addcontrol--item scroll-margin-top" id="common_passport_day_field">
              <div class="col-md-10 col-sm-9">
                <div class="form-group">
                  <label
                      class="checkbox checkbox-inline checkbox-type-div checkbox-expense"
                      :class="is_check_remain_days && 'checked'"
                  >
                   <span class="icons">
                    <span class="first-icon icon-abui-checkbox-unchecked" :class="hideAddBtn && 'disabled-color-checkbox-background-color'"/>
                    <span class="second-icon icon-abui-checkbox-checked" :class="hideAddBtn && 'disabled-color-checkbox-background-color'"/>
                  </span>
                    <input type="checkbox" v-model="is_check_remain_days" :disabled="hideAddBtn"/>
                    <span class="text_type" :class="hideAddBtn && 'disabled-color-text'">
                    {{ $t('regulation.common_destination_other') }}
                    </span>
                  </label>
                </div>
              </div>
              <div class="col-md-2 col-sm-3">
                <div class="form-group">
                  <div class="input-group adjust-amount">
                    <input
                      type="text"
                      class="form-control input-sm text-right"
                      :class="!checkEmpty(errors?.common_passport_day_required) && 'has-validation-error'"
                      placeholder=""
                      v-model="common_passport_day_required"
                      maxlength="10"
                      :disabled="hideAddBtn"
                      @input="common_passport_day_required = convertNumberFullToHalfCustom(common_passport_day_required)"
                      onkeydown="javascript: return event.keyCode !== 69;"
                      @change="convertDataCommon('common_passport_day_required', false, -1, true)"
                    />
                    <span class="input-group-addon">{{ $t('regulation.day') }}</span>
                  </div>
                  <p class="error-feedback" v-text="errors?.common_passport_day_required" />
                </div>
              </div>
            </div>
          </div>

          <!-- <hr class="mtm mbl" /> -->

          <!-- <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label
                  class="checkbox checkbox-inline checkbox-type-div checkbox-expense"
                  :class="is_insurance_required && 'checked'"
                >
                  <span class="icons">
                    <span class="first-icon icon-abui-checkbox-unchecked" />
                    <span class="second-icon icon-abui-checkbox-checked" />
                  </span>
                  <input type="checkbox" v-model="is_insurance_required" />
                  <span class="text_type"> {{ $t('regulation.separate_overseas_travel_accident') }} </span>
                </label>
              </div>
            </div>
          </div> -->
        </div>

        <div class="section">
          <h4 class="section-title">{{ $t('regulation.flight') }}</h4>
          <div class="row">
            <div class="col-xs-9 col-md-10 col-all-closer col-all-closer--leftend">
              <div class="form-group viewonly">
                <label>
                  <span class="label-text">{{ $t('regulation.no_transit_sea') }}</span>
                </label>
                <p class="viewonly-text input-sm text-ellipsis input--fltr-airport-multi">
                  {{ getNameOfAirport(no_transit_airport_list) }}
                </p>
              </div>
            </div>
            <div class="col-xs-3 col-md-2 col-all-closer col-all-closer--rightend">
              <button class="btn btn-sm btn-inverse btn-block btn--ls" @click="showModalUpdateAirPort = true">
                {{ $t('regulation.reference') }}
              </button>
            </div>
          </div>
          <!-- <p class="error-feedback" v-text="errors?.no_transit_airport_list" /> -->
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group form-group-typeahead">
                <label>
                  <span class="label-text">{{ $t('regulation.prohibited_airlines') }}</span>
                </label>
                <div class="btn-group bootstrap-select show-tick select-block select-sm">
                  <MultiOptionSelectRegulation
                    :list-data="listAirline"
                    :display-by-locale="true"
                    :default-list="banned_airline_list"
                    @handle-selected-params="listenAirlineSelected"
                    :is-display-search="true"
                    :placeholder="$t('trip_item.right_menu.manual_input_model.unspecified')"
                  />
                  <!-- <p class="error-feedback" v-text="errors?.banned_airline_list" /> -->
                </div>
              </div>
            </div>
          </div>

          <hr class="mtm" style="margin-bottom: 24px" />

          <div class="js-addcontrol1--wrap mbl">
            <div
              class="row addcontrol--item scroll-margin-top"
              v-for="(item, index) in banned_flight_section_list"
              :key="index"
              :id="`banned_flight_section_list_field_${index}`"
            >
              <div class="col-sm-3 input-dep col-all-closer col-all-closer--leftend">
                <div class="form-group form-group-typeahead">
                  <label>
                    <span class="label-text">{{ $t('regulation.prohibited_flight_section') }}</span>
                  </label>
                  <div class="input-with-icon input-sm">
                    <!-- <el-select
                      v-model="item.departure_airport_id"
                      name="input"
                      popper-class="custom-dropdown-select"
                      class="select-block select-sm"
                      :placeholder="$t('regulation.city_or_airport')"
                      filterable
                    >
                      <el-option
                        v-for="ap in listAirport"
                        :key="ap.id"
                        :label="labelLocale(ap)"
                        :value="ap.id"
                        :disabled="ap.id == item.arrival_airport_id"
                      />
                    </el-select> -->
                    <el-autocomplete
                      v-model="item.departure_airport"
                      name="input"
                      :fetch-suggestions="querySearch"
                      popper-class="custom-dropdown-select"
                      class="select-block select-sm"
                      :placeholder="$t('regulation.city_or_airport')"
                      :class="!checkEmpty(item.errors?.departure_airport_id) && 'has-validation-error-el'"
                    />
                    <p class="error-feedback" v-text="item.errors?.departure_airport_id" />
                  </div>
                </div>
              </div>
              <div class="col-sm-3 col-all-closer col-all-closer--rightend">
                <div class="form-group form-group-typeahead">
                  <label><span class="label-text">&ensp;</span></label>
                  <div class="input-with-icon input-sm">
                    <!-- <el-select
                      v-model="item.arrival_airport_id"
                      name="input"
                      popper-class="custom-dropdown-select"
                      class="select-block select-sm"
                      :placeholder="$t('regulation.city_or_airport')"
                      filterable
                    >
                      <el-option
                        v-for="ap in listAirport"
                        :key="ap.id"
                        :label="labelLocale(ap)"
                        :value="ap.id"
                        :disabled="ap.id == item.departure_airport_id"
                      />
                    </el-select> -->
                    <el-autocomplete
                      v-model="item.arrival_airport"
                      name="input"
                      :fetch-suggestions="querySearch"
                      popper-class="custom-dropdown-select"
                      class="select-block select-sm"
                      :placeholder="$t('regulation.city_or_airport')"
                      :class="!checkEmpty(item.errors?.arrival_airport_id) && 'has-validation-error-el'"
                    />
                    <p class="error-feedback" v-text="item.errors?.arrival_airport_id" />
                  </div>
                </div>
              </div>
              <button
                class="btn btn-sm btn--ls btn-flat-danger js-addcontrol--del addcontrol--item-del"
                @click="deleteItem(2, index)"
              >
                <span class="icon-abui-cross" />
              </button>
            </div>
            <p v-if="checkEmpty(banned_flight_section_list)" class="no-data">
              {{ $t('common.no_data') }}
            </p>
            <div class="row--addcontrol">
              <button class="btn btn-sm btn-flat-primary js-addcontrol1" @click="addNewItem(2)">
                <span class="icon-abui-plus prx" />{{ $t('regulation.add_prohibited_flight_sections') }}
              </button>
            </div>
          </div>

          <hr class="mtn" style="margin-bottom: 24px" />

          <div class="js-addcontrol2--wrap mbl">
            <div
              class="row addcontrol--item scroll-margin-top"
              v-for="(item, index) in attention_flight_section_list"
              :key="index"
              :id="`attention_flight_section_list_field_${index}`"
            >
              <div class="col-sm-3 input-dep col-all-closer col-all-closer--leftend">
                <div class="form-group form-group-typeahead">
                  <label>
                    <span class="label-text">{{ $t('regulation.caution_flight_section') }}</span>
                  </label>
                  <div class="input-with-icon input-sm">
                    <!-- <el-select
                      v-model="item.departure_airport_id"
                      name="input"
                      popper-class="custom-dropdown-select"
                      class="select-block select-sm"
                      :placeholder="$t('regulation.city_or_airport')"
                      filterable
                    >
                      <el-option
                        v-for="ap in listAirport"
                        :key="ap.id"
                        :label="labelLocale(ap)"
                        :value="ap.id"
                        :disabled="ap.id == item.arrival_airport_id"
                      />
                    </el-select> -->
                    <el-autocomplete
                      v-model="item.departure_airport"
                      name="input"
                      :fetch-suggestions="querySearch"
                      popper-class="custom-dropdown-select"
                      class="select-block select-sm"
                      :placeholder="$t('regulation.city_or_airport')"
                      :class="!checkEmpty(item.errors?.departure_airport_id) && 'has-validation-error-el'"
                    />
                    <p class="error-feedback" v-text="item.errors?.departure_airport_id" />
                  </div>
                </div>
              </div>
              <div class="col-sm-3 col-all-closer col-all-closer--rightend">
                <div class="form-group form-group-typeahead">
                  <label><span class="label-text">&ensp;</span></label>
                  <div class="input-with-icon input-sm">
                    <!-- <el-select
                      v-model="item.arrival_airport_id"
                      name="input"
                      popper-class="custom-dropdown-select"
                      class="select-block select-sm"
                      :placeholder="$t('regulation.city_or_airport')"
                      filterable
                    >
                      <el-option
                        v-for="ap in listAirport"
                        :key="ap.id"
                        :label="labelLocale(ap)"
                        :value="ap.id"
                        :disabled="ap.id == item.departure_airport_id"
                      />
                    </el-select> -->
                    <el-autocomplete
                      v-model="item.arrival_airport"
                      name="input"
                      :fetch-suggestions="querySearch"
                      popper-class="custom-dropdown-select"
                      class="select-block select-sm"
                      :placeholder="$t('regulation.city_or_airport')"
                      :class="!checkEmpty(item.errors?.arrival_airport_id) && 'has-validation-error-el'"
                    />
                    <p class="error-feedback" v-text="item.errors?.arrival_airport_id" />
                  </div>
                </div>
              </div>
              <button
                class="btn btn-sm btn--ls btn-flat-danger js-addcontrol--del addcontrol--item-del"
                @click="deleteItem(3, index)"
              >
                <span class="icon-abui-cross" />
              </button>
            </div>
            <p v-if="checkEmpty(attention_flight_section_list)" class="no-data">
              {{ $t('common.no_data') }}
            </p>
            <div class="row--addcontrol">
              <button class="btn btn-sm btn-flat-primary js-addcontrol1" @click="addNewItem(3)">
                <span class="icon-abui-plus prx" />{{ $t('regulation.caution_flight_section_added') }}
              </button>
            </div>
          </div>

          <hr class="mtn" style="margin-bottom: 24px" />

          <div class="js-addcontrol3--wrap mbl">
            <div
              class="row addcontrol--item scroll-margin-top"
              v-for="(item, index) in transit_time_list"
              :key="index"
              :id="`transit_time_list_field_${index}`"
            >
              <div class="col-md-8 col-sm-6">
                <div class="form-group form-group-typeahead">
                  <label>
                    <span class="label-text">{{ $t('regulation.transit_time_at_each_airport') }}</span>
                  </label>
                  <div class="input-with-icon input-sm">
                    <!-- <el-select
                      v-model="item.airport_id"
                      name="input"
                      popper-class="custom-dropdown-select"
                      class="select-block select-sm"
                      :placeholder="$t('regulation.city_or_airport')"
                      filterable
                    >
                      <el-option
                        v-for="item in listAirport"
                        :key="item.id"
                        :label="labelLocale(item)"
                        :value="item.id"
                      />
                    </el-select> -->
                    <el-autocomplete
                      v-model="item.airport"
                      name="input"
                      :fetch-suggestions="querySearch"
                      popper-class="custom-dropdown-select"
                      class="select-block select-sm"
                      :placeholder="$t('regulation.city_or_airport')"
                      :class="!checkEmpty(item.errors?.airport_id) && 'has-validation-error-el'"
                    />
                    <p class="error-feedback" v-text="item.errors?.airport_id" />
                  </div>
                </div>
              </div>
              <div class="col-md-2 col-sm-3 col-all-closer col-all-closer--leftend">
                <div class="form-group">
                  <label><span class="label-text">&ensp;</span></label>
                  <div class="input-group adjust-amount">
                    <input
                      type="text"
                      class="form-control input-sm text-right"
                      :class="!checkEmpty(item.errors?.lower_limit_time) && 'has-validation-error'"
                      placeholder="1"
                      maxlength="10"
                      v-model="item.lower_limit_time"
                      @input="item.lower_limit_time = convertNumberFullToHalf(item.lower_limit_time)"
                      onkeydown="javascript: return event.keyCode !== 69;"
                      @change="convertDataCommon('transit_time_list.lower_limit_time', true, index, true)"
                    />
                    <span class="input-group-addon">{{ $t('regulation.from_time') }}</span>
                  </div>
                  <p class="error-feedback" v-text="item.errors?.lower_limit_time" />
                </div>
              </div>
              <!--              <div class="col-sm-2 col-all-closer col-all-closer&#45;&#45;rightend">-->
              <!--                <div class="form-group">-->
              <!--                  <label><span class="label-text">&ensp;</span></label>-->
              <!--                  <div class="input-group adjust-amount">-->
              <!--                    <input-->
              <!--                      type="text"-->
              <!--                      class="form-control input-sm text-right"-->
              <!--                      placeholder="1"-->
              <!--                      maxlength="10"-->
              <!--                      v-model="item.upper_limit_time"-->
              <!--                      @input="item.upper_limit_time = convertNumberFullToHalf(item.upper_limit_time)"-->
              <!--                      onkeydown="javascript: return event.keyCode !== 69;"-->
              <!--                    />-->
              <!--                    <span class="input-group-addon">{{ $t('regulation.util_time') }}</span>-->
              <!--                  </div>-->
              <!--                  <p class="error-feedback" v-text="item.errors?.upper_limit_time" />-->
              <!--                </div>-->
              <!--              </div>-->
              <div class="col-md-2 col-sm-3" style="display: contents; padding-right: 0">
                <button
                  class="btn btn-sm btn--ls btn-flat-danger js-addcontrol--del addcontrol--item-del"
                  @click="deleteItem(4, index)"
                >
                  <span class="icon-abui-cross" />
                </button>
              </div>
            </div>
            <p v-if="checkEmpty(transit_time_list)" class="no-data">
              {{ $t('common.no_data') }}
            </p>
            <div class="row--addcontrol">
              <button class="btn btn-sm btn-flat-primary js-addcontrol1" @click="addNewItem(4)">
                <span class="icon-abui-plus prx" />{{ $t('regulation.additional_transit_times_for_each_airport') }}
              </button>
            </div>
          </div>

          <hr class="mtn" style="margin-bottom: 24px" />

          <div class="js-addcontrol4--wrap mbl">
            <div
              class="row addcontrol--item scroll-margin-top"
              v-for="(item, index) in transit_caution_list"
              :key="index"
              :id="`transit_caution_field_${index}`"
            >
              <div class="col-md-3 col-sm-4">
                <div class="form-group form-group-typeahead">
                  <label>
                    <span class="label-text">
                      {{ $t('regulation.transit_caution_airlines_at_each_airport') }}
                    </span>
                  </label>
                  <div class="input-with-icon input-sm">
                    <!-- <el-select
                      v-model="item.airport_id"
                      name="input"
                      popper-class="custom-dropdown-select"
                      class="select-block select-sm"
                      :placeholder="$t('regulation.city_or_airport')"
                      filterable
                    >
                      <el-option
                        v-for="item in listAirport"
                        :key="item.id"
                        :label="labelLocale(item)"
                        :value="item.id"
                      />
                    </el-select> -->
                    <el-autocomplete
                      v-model="item.airport"
                      name="input"
                      :fetch-suggestions="querySearch"
                      :class="!checkEmpty(item.errors?.airport_id) && 'has-validation-error-el'"
                      popper-class="custom-dropdown-select"
                      class="select-block select-sm"
                      :placeholder="$t('regulation.city_or_airport')"
                    />
                    <p class="error-feedback" v-text="item.errors?.airport_id" />
                  </div>
                </div>
              </div>
              <div class="col-sm-8">
                <div class="form-inline" role="form">
                  <label><span class="label-text">&ensp;</span></label>
                  <div class="form-group form-group-typeahead" style="min-width: 33%; display: flex">
                    <div class="btn-group bootstrap-select show-tick select-block select-sm col-sm-4">
                      <MultiOptionSelectRegulation
                        :list-data="listAirline"
                        :display-by-locale="true"
                        :default-list="item.transit_caution_departure_airline"
                        :class="
                          !checkEmpty(item?.errors?.transit_caution_departure_airline) && 'has-validation-error-el'
                        "
                        @handle-selected-params="listenDepartureAirlineSelected"
                        :option-index="{ haveIndex: true, index }"
                      />
                      <p class="error-feedback" v-text="item?.errors?.transit_caution_departure_airline" />
                    </div>
                    <span class="form-inline-text" style="margin-top: 13px">{{ $t('regulation.from') }}</span>
                    <div class="btn-group bootstrap-select show-tick select-block select-sm col-sm-4">
                      <MultiOptionSelectRegulation
                        :list-data="listAirline"
                        :display-by-locale="true"
                        :class="!checkEmpty(item?.errors?.transit_caution_arrival_airline) && 'has-validation-error-el'"
                        :default-list="item.transit_caution_arrival_airline"
                        @handle-selected-params="listenArrivalAirlineSelected"
                        :option-index="{ haveIndex: true, index }"
                      />
                      <p class="error-feedback" v-text="item?.errors?.transit_caution_arrival_airline" />
                    </div>
                  </div>
                </div>
              </div>
              <button
                class="btn btn-sm btn--ls btn-flat-danger js-addcontrol--del addcontrol--item-del"
                @click="deleteItem(5, index)"
              >
                <span class="icon-abui-cross" />
              </button>
            </div>
            <p v-if="checkEmpty(transit_caution_list)" class="no-data">
              {{ $t('common.no_data') }}
            </p>
            <div class="row--addcontrol">
              <button class="btn btn-sm btn-flat-primary js-addcontrol1" @click="addNewItem(5)">
                <span class="icon-abui-plus prx" />{{ $t('regulation.added_transit_caution_airlines_by_airport') }}
              </button>
            </div>
          </div>

          <hr class="mtn" style="margin-bottom: 24px" />

          <div class="row scroll-margin-top" id="number_of_connections_field">
            <div class="col-sm-3 col-md-2">
              <div class="form-group">
                <label>
                  <span class="label-text">{{ $t('regulation.number_of_connections') }}</span>
                </label>
                <div class="input-group adjust-amount">
                  <input
                    type="text"
                    class="form-control input-sm text-right"
                    maxlength="10"
                    v-model="number_of_connection"
                    :class="!checkEmpty(errors?.number_of_connection) && 'has-validation-error'"
                    @input="number_of_connection = convertNumberFullToHalf(number_of_connection)"
                    @change="convertDataCommon('number_of_connection')"
                    onkeydown="javascript: return event.keyCode !== 69;"
                  />
                  <span class="input-group-addon">{{ $t('regulation.up_to_times') }}</span>
                </div>
                <p class="error-feedback" v-text="errors?.number_of_connection" />
              </div>
            </div>
          </div>
        </div>

        <div class="section">
          <h4 class="section-title">{{ $t('regulation.airline_ticket') }}</h4>
          <h6>{{ $t('regulation.seat_class') }}</h6>

          <div class="row addcontrol--item scroll-margin-top" id="domestic_flights_field">
            <div class="col-sm-4">
              <div class="form-group">
                {{ $t('regulation.domestic_flights') }}
              </div>
            </div>
            <div class="col-sm-2">
              <div v-if="domestic_flights" class="form-group text-right">
                <label
                  class="checkbox checkbox-inline checkbox-type-div checkbox-expense"
                  :class="domestic_flights?.is_depend_position && 'checked'"
                >
                  <span class="icons">
                    <span class="first-icon icon-abui-checkbox-unchecked" />
                    <span class="second-icon icon-abui-checkbox-checked" />
                  </span>
                  <input type="checkbox" v-model="domestic_flights.is_depend_position" />
                  <span class="text_type">
                    {{ $t('regulation.specified_by_job_title') }}
                  </span>
                </label>
              </div>
            </div>
            <div v-show="!domestic_flights?.is_depend_position" class="js-check_jobtitile__tgt--off">
              <div class="col-sm-3" v-if="domestic_flights">
                <div class="form-group">
                  <div v-if="domestic_flights" class="btn-group bootstrap-select show-tick select-block select-sm">
                    <MultiOptionSelectRegulation
                       :placeholder="$t('common.all_specified')"
                      :class="!checkEmpty(domestic_flights?.errors) && 'has-validation-error-el'"
                      :list-data="domesticFlightClass"
                      :display-by-locale="true"
                      :is-sort="false"
                      :default-list="domestic_flights.seat_class_types"
                      @handle-selected-params="listenDomesticFlightSelected"
                      :is-display-search="false"
                    />
                    <p class="error-feedback" v-text="domestic_flights?.errors" />
                  </div>
                </div>
              </div>
            </div>
            <div v-show="domestic_flights?.is_depend_position" class="js-check_jobtitile__tgt--on">
              <div class="col-sm-6" v-if="domestic_flights?.position_seat_class_types">
                <div
                  class="regulation-jobtitle scroll-margin-top"
                  v-for="(position, index) in domestic_flights.position_seat_class_types"
                  :key="index"
                  :id="`position_seat_class_types_field_${index}`"
                >
                  <div class="form-inline-text regulation-jobtitle--label">
                    {{ getPositionName(position.position_id) }}
                  </div>
                  <div class="row" style="width: 100%">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <div class="btn-group bootstrap-select show-tick select-block select-sm">
                          <MultiOptionSelectRegulation
                              :placeholder="$t('common.all_specified')"
                            :class="!checkEmpty(position?.errors) && 'has-validation-error-el'"
                            :list-data="domesticFlightClass"
                            :display-by-locale="true"
                            :default-list="position.seat_class_types"
                            @handle-selected-params="listenPositionDomesticFlightSelected"
                            :is-display-search="false"
                            :is-sort="false"
                            :option-index="{ haveIndex: true, index }"
                          />
                          <p class="error-feedback" v-text="position?.errors" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <p v-if="checkEmpty(domestic_flights.position_seat_class_types)" class="no-data">
                  {{ $t('common.no_data') }}
                </p>
              </div>
            </div>
          </div>
          <div class="row addcontrol--item scroll-margin-top" id="international_flights_field">
            <div class="col-sm-4">
              <div class="form-group">
                {{ $t('regulation.international_flights') }}
              </div>
            </div>
            <div class="col-sm-2">
              <div class="form-group text-right" v-if="international_flights">
                <label
                  class="checkbox checkbox-inline checkbox-type-div checkbox-expense"
                  :class="international_flights?.is_depend_position && 'checked'"
                >
                  <span class="icons">
                    <span class="first-icon icon-abui-checkbox-unchecked" />
                    <span class="second-icon icon-abui-checkbox-checked" />
                  </span>
                  <input type="checkbox" v-model="international_flights.is_depend_position" />
                  <span class="text_type">
                    {{ $t('regulation.specified_by_job_title') }}
                  </span>
                </label>
              </div>
            </div>
            <div v-show="!international_flights?.is_depend_position" class="js-check_jobtitile__tgt--off">
              <div class="col-sm-3" v-if="international_flights">
                <div class="form-group">
                  <div class="btn-group bootstrap-select show-tick select-block select-sm">
                    <MultiOptionSelectRegulation
                      :class="!checkEmpty(international_flights?.errors) && 'has-validation-error-el'"
                      :list-data="internationalFlightClass"
                      :placeholder="$t('common.all_specified')"
                      :display-by-locale="true"
                      :is-sort="false"
                      :default-list="international_flights.seat_class_types"
                      @handle-selected-params="listenInternationalFlightSelected"
                      :is-display-search="false"
                    />
                    <p class="error-feedback" v-text="international_flights?.errors" />
                  </div>
                </div>
              </div>
            </div>
            <div v-show="international_flights?.is_depend_position" class="js-check_jobtitile__tgt--on">
              <div class="col-sm-6" v-if="international_flights?.position_seat_class_types">
                <div
                  class="regulation-jobtitle scroll-margin-top"
                  v-for="(position, index) in international_flights.position_seat_class_types"
                  :key="index"
                  :id="`international_position_seat_class_types_field_${index}`"
                >
                  <div class="form-inline-text regulation-jobtitle--label">
                    {{ getPositionName(position.position_id) }}
                  </div>
                  <div class="row" style="width: 100%">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <div class="btn-group bootstrap-select show-tick select-block select-sm">
                          <MultiOptionSelectRegulation
                            :class="!checkEmpty(position?.errors) && 'has-validation-error-el'"
                            :list-data="internationalFlightClass"
                            :display-by-locale="true"
                            :is-sort="false"
                            :placeholder="$t('common.all_specified')"
                            :default-list="position.seat_class_types"
                            @handle-selected-params="listenPositionInternationalFlightSelected"
                            :is-display-search="false"
                            :option-index="{ haveIndex: true, index }"
                          />
                          <p class="error-feedback" v-text="position?.errors" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <p v-if="checkEmpty(international_flights.position_seat_class_types)" class="no-data">
                  {{ $t('common.no_data') }}
                </p>
              </div>
            </div>
          </div>

          <div class="row addcontrol--item scroll-margin-top" id="flight_times_field" :class="disabledFlightTime && 'flight-times-position'">
            <div class="col-sm-4">
              <div class="form-group">
                <div class="form-inline" role="form" style="margin-bottom: 0">
                  <div class="form-group" :class="disabledFlightTime && 'disabled-color-text'">
                      {{ $t('regulation.flight_time') }}
                  </div>
                  <div class="form-group check-noapply-distance__tgt">
                    <label class="sr-only">{{ $t('regulation.label_only') }}</label>
                    <div class="input-group adjust-amount">
                      <input
                          type="text"
                          class="form-control input-sm text-right"
                          :class="!checkEmpty(errors?.flight_time) && 'has-validation-error'"
                          v-model="flight_time"
                          maxlength="2"
                          style="width: 3em"
                          @input="flight_time = convertNumberFullToHalf(flight_time)"
                          onkeydown="javascript: return event.keyCode !== 69;"
                          @change="convertDataCommon('flight_time')"
                      />
                    </div>
                  </div>
                  <div class="form-group">
                    <span :class="disabledFlightTime && 'disabled-color-text'">{{ $t('regulation.upper_seat_class_available') }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-2">
              <div class="form-group text-right" v-if="flight_times">
                <label
                    class="checkbox checkbox-inline checkbox-type-div checkbox-expense"
                    :class="flight_times?.is_depend_position && 'checked'"
                >
                  <span class="icons">
                    <span class="first-icon icon-abui-checkbox-unchecked" :class="disabledFlightTime && 'disabled-color-checkbox-background-color'"/>
                    <span class="second-icon icon-abui-checkbox-checked" :class="disabledFlightTime && 'disabled-color-checkbox-background-color'"/>
                  </span>
                  <input type="checkbox" v-model="flight_times.is_depend_position" :disabled="disabledFlightTime"/>
                  <span class="text_type" :class="disabledFlightTime && 'disabled-color-text'">
                    {{ $t('regulation.specified_by_job_title') }}
                  </span>
                </label>
              </div>
            </div>
            <div v-show="!flight_times?.is_depend_position" class="js-check_jobtitile__tgt--off">
              <div class="col-sm-3" v-if="flight_times">
                <div class="form-group">
                  <div class="btn-group bootstrap-select show-tick select-block select-sm">
                    <MultiOptionSelectRegulation
                        :class="!checkEmpty(flight_times?.errors) && 'has-validation-error-el'"
                        :list-data="flightClass"
                        :is-disabled="disabledFlightTime"
                        :placeholder="$t('common.all_specified')"
                        :display-by-locale="true"
                        :is-sort="false"
                        :default-list="flight_times.seat_class_types"
                        @handle-selected-params="listenFlightTimesSelected"
                        :is-display-search="false"
                    />
                    <p class="error-feedback" v-text="flight_times?.errors" />
                  </div>
                </div>
              </div>
            </div>
            <div v-show="flight_times?.is_depend_position" class="js-check_jobtitile__tgt--on">
              <div class="col-sm-6" v-if="flight_times?.position_seat_class_types">
                <div
                    class="regulation-jobtitle scroll-margin-top"
                    v-for="(position, index) in flight_times.position_seat_class_types"
                    :key="index"
                    :id="`international_position_seat_class_types_field_${index}`"
                >
                  <div class="form-inline-text regulation-jobtitle--label">
                    {{ getPositionName(position.position_id) }}
                  </div>
                  <div class="row" style="width: 100%">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <div class="btn-group bootstrap-select show-tick select-block select-sm">
                          <MultiOptionSelectRegulation
                              :is-disabled="disabledFlightTime"
                              :placeholder="$t('common.all_specified')"
                              :class="!checkEmpty(position?.errors) && 'has-validation-error-el'"
                              :list-data="flightClass"
                              :display-by-locale="true"
                              :is-sort="false"
                              :default-list="position.seat_class_types"
                              @handle-selected-params="listenPositionFlightTimesSelected"
                              :is-display-search="false"
                              :option-index="{ haveIndex: true, index }"
                          />
                          <p class="error-feedback" v-text="position?.errors" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <p v-if="checkEmpty(flight_times.position_seat_class_types)" class="no-data">
                  {{ $t('common.no_data') }}
                </p>
              </div>
            </div>
          </div>

<!--          <div class="row scroll-margin-top" id="flight_time_field">-->
<!--            <div class="col-sm-12">-->
<!--              <div class="form-inline" role="form" style="margin-bottom: 0">-->
<!--                <div class="form-group">-->
<!--                  <label-->
<!--                    class="checkbox checkbox-inline checkbox-type-div checkbox-expense"-->
<!--                    :class="is_flight_time && 'checked'"-->
<!--                  >-->
<!--                    <span class="icons">-->
<!--                      <span class="first-icon icon-abui-checkbox-unchecked" />-->
<!--                      <span class="second-icon icon-abui-checkbox-checked" />-->
<!--                    </span>-->
<!--                    <input type="checkbox" v-model="is_flight_time" />-->
<!--                    <span class="text_type">-->
<!--                      {{ $t('regulation.flight_time') }}-->
<!--                    </span>-->
<!--                  </label>-->
<!--                </div>-->
<!--                <div class="form-group check-noapply-distance__tgt">-->
<!--                  <label class="sr-only">{{ $t('regulation.label_only') }}</label>-->
<!--                  <div class="input-group adjust-amount">-->
<!--                    <input-->
<!--                      type="text"-->
<!--                      class="form-control input-sm text-right"-->
<!--                      :class="!checkEmpty(errors?.flight_time) && 'has-validation-error'"-->
<!--                      v-model="flight_time"-->
<!--                      :disabled="!is_flight_time"-->
<!--                      maxlength="10"-->
<!--                      style="width: 6em"-->
<!--                      @input="flight_time = convertNumberFullToHalf(flight_time)"-->
<!--                      onkeydown="javascript: return event.keyCode !== 69;"-->
<!--                      @change="convertDataCommon('flight_time')"-->
<!--                    />-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="form-group">-->
<!--                  <span class="form-inline-text">{{ $t('regulation.upper_seat_class_available') }}</span>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="form-inline" role="form">-->
<!--                <div class="form-group" style="visibility: hidden">-->
<!--                  <label-->
<!--                    class="checkbox checkbox-inline checkbox-type-div checkbox-expense"-->
<!--                    :class="is_flight_time && 'checked'"-->
<!--                  >-->
<!--                    <span class="icons">-->
<!--                      <span class="first-icon icon-abui-checkbox-unchecked" />-->
<!--                      <span class="second-icon icon-abui-checkbox-checked" />-->
<!--                    </span>-->
<!--                    <input type="checkbox" v-model="is_flight_time" />-->
<!--                    <span class="text_type">-->
<!--                      {{ $t('regulation.flight_time') }}-->
<!--                    </span>-->
<!--                  </label>-->
<!--                </div>-->
<!--                <div class="form-group check-noapply-distance__tgt">-->
<!--                  <p class="error-feedback" v-text="errors?.flight_time" />-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->

          <hr class="mts mbl" />

          <h6>{{ $t('regulation.amount') }}</h6>
          <div class="js-addcontrol1--wrap">
            <div
              class="row addcontrol--item scroll-margin-top"
              v-for="(item, index) in ticket_costs"
              :key="index"
              :id="`ticket_field_${index}`"
            >
              <div class="col-sm-4">
                <div class="form-group">
                  <div class="btn-group bootstrap-select show-tick select-block select-sm">
                    <MultiOptionSelectRegulation
                      :class="!checkEmpty(item?.errors?.area_id) && 'has-validation-error-el'"
                      :list-data="listArea"
                      :default-list="item.area_id"
                      @handle-selected-params="listenUpdateTicketCostArea"
                      :is-display-search="false"
                      :option-index="{ haveIndex: true, index }"
                      :display-by-locale="true"
                    />
                    <p class="error-feedback" v-text="item?.errors?.area_id" />
                  </div>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group text-right">
                  <label
                    class="checkbox checkbox-inline checkbox-type-div checkbox-expense"
                    :class="item?.is_depend_position && 'checked'"
                  >
                    <span class="icons">
                      <span class="first-icon icon-abui-checkbox-unchecked" />
                      <span class="second-icon icon-abui-checkbox-checked" />
                    </span>
                    <input type="checkbox" v-model="item.is_depend_position" />
                    <span class="text_type">
                      {{ $t('regulation.specified_by_job_title') }}
                    </span>
                  </label>
                </div>
              </div>
              <div v-show="!item?.is_depend_position" class="js-check_jobtitile__tgt--off">
                <div class="col-sm-3 col-all-closer col-all-closer--leftend">
                  <div class="form-group">
                    <div class="input-group adjust-amount">
                      <input
                        type="text"
                        class="form-control input-sm text-right"
                        :class="!checkEmpty(item?.errors?.lower_limit_amount) && 'has-validation-error'"
                        maxlength="10"
                        v-model="item.lower_limit_amount"
                        @input="item.lower_limit_amount = convertNumberFullToHalf(item.lower_limit_amount)"
                        onkeydown="javascript: return event.keyCode !== 69;"
                        @change="convertDataCommon('ticket_costs.lower_limit_amount', true, index, true)"
                      />
                      <span class="input-group-addon">{{ $t('regulation.from_the_circle') }}</span>
                    </div>
                    <p class="error-feedback" v-text="item?.errors?.lower_limit_amount" />
                  </div>
                </div>
                <div class="col-sm-3 col-all-closer col-all-closer--rightend">
                  <div class="form-group">
                    <div class="input-group adjust-amount">
                      <input
                        type="text"
                        class="form-control input-sm text-right"
                        :class="!checkEmpty(item?.errors?.upper_limit_amount) && 'has-validation-error'"
                        maxlength="10"
                        v-model="item.upper_limit_amount"
                        @input="item.upper_limit_amount = convertNumberFullToHalf(item.upper_limit_amount)"
                        onkeydown="javascript: return event.keyCode !== 69;"
                        @change="convertDataCommon('ticket_costs.upper_limit_amount', true, index, true)"
                      />
                      <span class="input-group-addon">{{ $t('regulation.up_to_the_yen') }}</span>
                    </div>
                    <p class="error-feedback" v-text="item?.errors?.upper_limit_amount" />
                  </div>
                </div>
              </div>
              <div v-show="item?.is_depend_position" class="js-check_jobtitile__tgt--on">
                <div class="col-sm-6" v-if="item?.position_amounts">
                  <div
                    class="regulation-jobtitle"
                    v-for="(position, positionIndex) in item.position_amounts"
                    :key="positionIndex"
                  >
                    <div class="form-inline-text regulation-jobtitle--label">
                      {{ getPositionName(position.position_id) }}
                    </div>
                    <div class="row">
                      <div class="col-sm-6 col-all-closer col-all-closer--leftend">
                        <div class="form-group">
                          <div class="input-group adjust-amount">
                            <input
                              type="text"
                              class="form-control input-sm text-right"
                              :class="!checkEmpty(position?.errors?.lower_limit_amount) && 'has-validation-error'"
                              maxlength="10"
                              v-model="position.lower_limit_amount"
                              @input="
                                position.lower_limit_amount = convertNumberFullToHalf(position.lower_limit_amount)
                              "
                              onkeydown="javascript: return event.keyCode !== 69;"
                              @change="
                                convertNestedDataAmount(
                                  'ticket_costs.position_amounts.lower_limit_amount',
                                  index,
                                  positionIndex,
                                )
                              "
                            />
                            <span class="input-group-addon">{{ $t('regulation.from_the_circle') }}</span>
                          </div>
                          <p class="error-feedback" v-text="position?.errors?.lower_limit_amount" />
                        </div>
                      </div>
                      <div class="col-sm-6 col-all-closer col-all-closer--rightend">
                        <div class="form-group">
                          <div class="input-group adjust-amount">
                            <input
                              type="text"
                              class="form-control input-sm text-right"
                              :class="!checkEmpty(position?.errors?.upper_limit_amount) && 'has-validation-error'"
                              maxlength="10"
                              v-model="position.upper_limit_amount"
                              @input="
                                position.upper_limit_amount = convertNumberFullToHalf(position.upper_limit_amount)
                              "
                              onkeydown="javascript: return event.keyCode !== 69;"
                              @change="
                                convertNestedDataAmount(
                                  'ticket_costs.position_amounts.upper_limit_amount',
                                  index,
                                  positionIndex,
                                  false,
                                )
                              "
                            />
                            <span class="input-group-addon">{{ $t('regulation.up_to_the_yen') }}</span>
                          </div>
                          <p class="error-feedback" v-text="position?.errors?.upper_limit_amount" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <p v-if="checkEmpty(item.position_amounts)" class="no-data">
                    {{ $t('common.no_data') }}
                  </p>
                </div>
              </div>
              <button
                class="btn btn-sm btn-flat-danger js-addcontrol--del addcontrol--item-del"
                @click="deleteItem(6, index)"
              >
                <span class="icon-abui-cross" />
              </button>
            </div>
            <div class="row--addcontrol">
              <button class="btn btn-sm btn-flat-primary js-addcontrol1" @click="addNewItem(6)">
                <span class="icon-abui-plus prx" />{{ $t('regulation.add_target_area') }}
              </button>
            </div>
            <div class="mtl scroll-margin-top" id="ticket_cost_field">
              <div class="row addcontrol--item">
                <div class="col-sm-4">
                  <div class="form-group">
                    {{ $t('regulation.common_destination_other') }}
                  </div>
                </div>
                <div class="col-sm-2">
                  <div class="form-group text-right" v-if="ticket_cost_common">
                    <label
                      class="checkbox checkbox-inline checkbox-type-div checkbox-expense"
                      :class="ticket_cost_common?.is_depend_position && 'checked'"
                    >
                      <span class="icons">
                        <span class="first-icon icon-abui-checkbox-unchecked" />
                        <span class="second-icon icon-abui-checkbox-checked" />
                      </span>
                      <input type="checkbox" v-model="ticket_cost_common.is_depend_position" />
                      <span class="text_type">
                        {{ $t('regulation.specified_by_job_title') }}
                      </span>
                    </label>
                  </div>
                </div>
                <div v-show="!ticket_cost_common?.is_depend_position" class="js-check_jobtitile__tgt--off">
                  <div class="col-sm-3 col-all-closer col-all-closer--leftend">
                    <div class="form-group">
                      <div class="input-group adjust-amount" v-if="ticket_cost_common">
                        <input
                          type="text"
                          class="form-control input-sm text-right"
                          :class="!checkEmpty(ticket_cost_common?.errors?.lower_limit_amount) && 'has-validation-error'"
                          maxlength="10"
                          v-model="ticket_cost_common.lower_limit_amount"
                          @input="
                            ticket_cost_common.lower_limit_amount = convertNumberFullToHalf(
                              ticket_cost_common.lower_limit_amount,
                            )
                          "
                          onkeydown="javascript: return event.keyCode !== 69;"
                          @change="convertDataCommon('ticket_cost_common.lower_limit_amount', false, -1, true)"
                        />
                        <span class="input-group-addon">{{ $t('regulation.from_the_circle') }}</span>
                      </div>
                      <p class="error-feedback" v-text="ticket_cost_common?.errors?.lower_limit_amount" />
                    </div>
                  </div>
                  <div class="col-sm-3 col-all-closer col-all-closer--rightend">
                    <div class="form-group">
                      <div class="input-group adjust-amount" v-if="ticket_cost_common">
                        <input
                          type="text"
                          class="form-control input-sm text-right"
                          :class="!checkEmpty(ticket_cost_common?.errors?.upper_limit_amount) && 'has-validation-error'"
                          maxlength="10"
                          v-model="ticket_cost_common.upper_limit_amount"
                          @input="
                            ticket_cost_common.upper_limit_amount = convertNumberFullToHalf(
                              ticket_cost_common.upper_limit_amount,
                            )
                          "
                          onkeydown="javascript: return event.keyCode !== 69;"
                          @change="convertDataCommon('ticket_cost_common.upper_limit_amount', false, -1, true)"
                        />
                        <span class="input-group-addon">{{ $t('regulation.up_to_the_yen') }}</span>
                      </div>
                      <p class="error-feedback" v-text="ticket_cost_common?.errors?.upper_limit_amount" />
                    </div>
                  </div>
                </div>
                <div v-show="ticket_cost_common?.is_depend_position" class="js-check_jobtitile__tgt--on">
                  <div class="col-sm-6" v-if="ticket_cost_common">
                    <div
                      class="regulation-jobtitle"
                      v-for="(position, index) in ticket_cost_common.position_amounts"
                      :key="index"
                      :id="`ticket_position_amounts_${index}`"
                    >
                      <div class="form-inline-text regulation-jobtitle--label">
                        {{ getPositionName(position.position_id) }}
                      </div>
                      <div class="row">
                        <div class="col-sm-6 col-all-closer col-all-closer--leftend">
                          <div class="form-group">
                            <div class="input-group adjust-amount">
                              <input
                                type="text"
                                class="form-control input-sm text-right"
                                :class="!checkEmpty(position?.errors?.lower_limit_amount) && 'has-validation-error'"
                                maxlength="10"
                                v-model="position.lower_limit_amount"
                                @input="
                                  position.lower_limit_amount = convertNumberFullToHalf(position.lower_limit_amount)
                                "
                                onkeydown="javascript: return event.keyCode !== 69;"
                                @change="
                                  convertDataCommon(
                                    'ticket_cost_common.position_amounts.lower_limit_amount',
                                    true,
                                    index,
                                    true,
                                  )
                                "
                              />
                              <span class="input-group-addon">{{ $t('regulation.from_the_circle') }}</span>
                            </div>
                            <p class="error-feedback" v-text="position?.errors?.lower_limit_amount" />
                          </div>
                        </div>
                        <div class="col-sm-6 col-all-closer col-all-closer--rightend">
                          <div class="form-group">
                            <div class="input-group adjust-amount">
                              <input
                                type="text"
                                class="form-control input-sm text-right"
                                :class="!checkEmpty(position?.errors?.upper_limit_amount) && 'has-validation-error'"
                                maxlength="10"
                                v-model="position.upper_limit_amount"
                                @input="
                                  position.upper_limit_amount = convertNumberFullToHalf(position.upper_limit_amount)
                                "
                                onkeydown="javascript: return event.keyCode !== 69;"
                                @change="
                                  convertDataCommon(
                                    'ticket_cost_common.position_amounts.upper_limit_amount',
                                    true,
                                    index,
                                    true,
                                  )
                                "
                              />
                              <span class="input-group-addon">{{ $t('regulation.up_to_the_yen') }}</span>
                            </div>
                            <p class="error-feedback" v-text="position?.errors?.upper_limit_amount" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <p v-if="checkEmpty(ticket_cost_common.position_amounts)" class="no-data">
                      {{ $t('common.no_data') }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="section" id="hotel_directions_field">
          <h4 class="section-title">{{ $t('regulation.hotel') }}</h4>
          <h6>{{ $t('regulation.area') }}</h6>
          <div class="js-addcontrol1--wrap">
            <div
              class="row addcontrol--item scroll-margin-top"
              v-for="(item, index) in hotel_directions"
              :key="index"
              :id="`hotel_directions_field_${index}`"
            >
              <div class="col-sm-4">
                <div class="form-group">
                  <div class="btn-group bootstrap-select show-tick select-block select-sm">
                    <MultiOptionSelectRegulation
                      :class="!checkEmpty(item?.errors?.area_id) && 'has-validation-error-el'"
                      :list-data="listArea"
                      :default-list="item.area_id"
                      @handle-selected-params="listenUpdateHotelArea"
                      :is-display-search="false"
                      :option-index="{ haveIndex: true, index }"
                      :display-by-locale="true"
                    />
                    <p class="error-feedback" v-text="item?.errors?.area_id" />
                  </div>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group text-right">
                  <label
                    class="checkbox checkbox-inline checkbox-type-div checkbox-expense"
                    :class="item?.is_depend_position && 'checked'"
                  >
                    <span class="icons">
                      <span class="first-icon icon-abui-checkbox-unchecked" />
                      <span class="second-icon icon-abui-checkbox-checked" />
                    </span>
                    <input type="checkbox" v-model="item.is_depend_position" />
                    <span class="text_type">
                      {{ $t('regulation.specified_by_job_title') }}
                    </span>
                  </label>
                </div>
              </div>
              <div v-show="!item?.is_depend_position" class="js-check_jobtitile__tgt--off">
                <div class="col-sm-3 col-all-closer col-all-closer--leftend">
                  <div class="form-group">
                    <div class="input-group adjust-amount">
                      <input
                        type="text"
                        class="form-control input-sm text-right"
                        :class="!checkEmpty(item?.errors?.lower_limit_amount) && 'has-validation-error'"
                        maxlength="10"
                        v-model="item.lower_limit_amount"
                        @input="item.lower_limit_amount = convertNumberFullToHalf(item.lower_limit_amount)"
                        onkeydown="javascript: return event.keyCode !== 69;"
                        @change="convertDataCommon('hotel_directions.lower_limit_amount', true, index, true)"
                      />
                      <span class="input-group-addon">{{ $t('regulation.from_the_circle') }}</span>
                    </div>
                    <p class="error-feedback" v-text="item?.errors?.lower_limit_amount" />
                  </div>
                </div>
                <div class="col-sm-3 col-all-closer col-all-closer--rightend">
                  <div class="form-group">
                    <div class="input-group adjust-amount">
                      <input
                        type="text"
                        class="form-control input-sm text-right"
                        :class="!checkEmpty(item?.errors?.upper_limit_amount) && 'has-validation-error'"
                        maxlength="10"
                        v-model="item.upper_limit_amount"
                        @input="item.upper_limit_amount = convertNumberFullToHalf(item.upper_limit_amount)"
                        onkeydown="javascript: return event.keyCode !== 69;"
                        @change="convertDataCommon('hotel_directions.upper_limit_amount', true, index, true)"
                      />
                      <span class="input-group-addon">{{ $t('regulation.up_to_the_yen') }}</span>
                    </div>
                    <p class="error-feedback" v-text="item?.errors?.upper_limit_amount" />
                  </div>
                </div>
              </div>
              <div v-show="item?.is_depend_position" class="js-check_jobtitile__tgt--on">
                <div class="col-sm-6" v-if="item?.position_amounts">
                  <div
                    class="regulation-jobtitle scroll-margin-top"
                    v-for="(position, positionIndex) in item.position_amounts"
                    :key="positionIndex"
                    :id="`hotel_position_amounts_${index}`"
                  >
                    <div class="form-inline-text regulation-jobtitle--label">
                      {{ getPositionName(position.position_id) }}
                    </div>
                    <div class="row">
                      <div class="col-sm-6 col-all-closer col-all-closer--leftend">
                        <div class="form-group">
                          <div class="input-group adjust-amount">
                            <input
                              type="text"
                              class="form-control input-sm text-right"
                              :class="!checkEmpty(position?.errors?.lower_limit_amount) && 'has-validation-error'"
                              maxlength="10"
                              v-model="position.lower_limit_amount"
                              @input="
                                position.lower_limit_amount = convertNumberFullToHalf(position.lower_limit_amount)
                              "
                              onkeydown="javascript: return event.keyCode !== 69;"
                              @change="
                                convertNestedDataAmount(
                                  'hotel_directions.position_amounts.lower_limit_amount',
                                  index,
                                  positionIndex,
                                )
                              "
                            />
                            <span class="input-group-addon">{{ $t('regulation.from_the_circle') }}</span>
                          </div>
                          <p class="error-feedback" v-text="position?.errors?.lower_limit_amount" />
                        </div>
                      </div>
                      <div class="col-sm-6 col-all-closer col-all-closer--rightend">
                        <div class="form-group">
                          <div class="input-group adjust-amount">
                            <input
                              type="text"
                              class="form-control input-sm text-right"
                              :class="!checkEmpty(position?.errors?.upper_limit_amount) && 'has-validation-error'"
                              maxlength="10"
                              v-model="position.upper_limit_amount"
                              @input="
                                position.upper_limit_amount = convertNumberFullToHalf(position.upper_limit_amount)
                              "
                              onkeydown="javascript: return event.keyCode !== 69;"
                              @change="
                                convertNestedDataAmount(
                                  'hotel_directions.position_amounts.upper_limit_amount',
                                  index,
                                  positionIndex,
                                  false,
                                )
                              "
                            />
                            <span class="input-group-addon">{{ $t('regulation.up_to_the_yen') }}</span>
                          </div>
                          <p class="error-feedback" v-text="position?.errors?.upper_limit_amount" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <p v-if="checkEmpty(item.position_amounts)" class="no-data">
                    {{ $t('common.no_data') }}
                  </p>
                </div>
              </div>
              <button
                class="btn btn-sm btn-flat-danger js-addcontrol--del addcontrol--item-del"
                @click="deleteItem(7, index)"
              >
                <span class="icon-abui-cross" />
              </button>
            </div>
            <div class="row--addcontrol">
              <button class="btn btn-sm btn-flat-primary js-addcontrol1" @click="addNewItem(7)">
                <span class="icon-abui-plus prx" />{{ $t('regulation.add_target_area') }}
              </button>
            </div>
          </div>
          <hr class="mtl mbl" />

          <div>
            <div class="form-group">
              <label>
                <span class="label-text">{{ $t('regulation.remarks') }}</span>
              </label>
              <textarea
                class="form-control"
                maxlength="100"
                rows="2"
                :placeholder="$i18n.locale == 'en' ? 'Exceptions in cities' : '都市の例外など'"
                v-model="hotel_direction_note"
              />
            </div>
          </div>

          <div style="margin-top: 24px" class="scroll-margin-top" id="hotel_direction_common_field">
            <div class="row addcontrol--item">
              <div class="col-sm-4">
                <div class="form-group">
                  {{ $t('regulation.common_destination_other') }}
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group text-right" v-if="hotel_direction_common">
                  <label
                    class="checkbox checkbox-inline checkbox-type-div checkbox-expense"
                    :class="hotel_direction_common?.is_depend_position && 'checked'"
                  >
                    <span class="icons">
                      <span class="first-icon icon-abui-checkbox-unchecked" />
                      <span class="second-icon icon-abui-checkbox-checked" />
                    </span>
                    <input type="checkbox" v-model="hotel_direction_common.is_depend_position" />
                    <span class="text_type">
                      {{ $t('regulation.specified_by_job_title') }}
                    </span>
                  </label>
                </div>
              </div>
              <div v-show="!hotel_direction_common?.is_depend_position" class="js-check_jobtitile__tgt--off">
                <div class="col-sm-3 col-all-closer col-all-closer--leftend">
                  <div class="form-group">
                    <div class="input-group adjust-amount" v-if="hotel_direction_common">
                      <input
                        type="text"
                        class="form-control input-sm text-right"
                        :class="
                          !checkEmpty(hotel_direction_common?.errors?.lower_limit_amount) && 'has-validation-error'
                        "
                        maxlength="10"
                        v-model="hotel_direction_common.lower_limit_amount"
                        @input="
                          hotel_direction_common.lower_limit_amount = convertNumberFullToHalf(
                            hotel_direction_common.lower_limit_amount,
                          )
                        "
                        onkeydown="javascript: return event.keyCode !== 69;"
                        @change="convertDataCommon('hotel_direction_common.lower_limit_amount', false, -1, true)"
                      />
                      <span class="input-group-addon">{{ $t('regulation.from_the_circle') }}</span>
                    </div>
                    <p class="error-feedback" v-text="hotel_direction_common?.errors?.lower_limit_amount" />
                  </div>
                </div>
                <div class="col-sm-3 col-all-closer col-all-closer--rightend">
                  <div class="form-group">
                    <div class="input-group adjust-amount" v-if="hotel_direction_common">
                      <input
                        type="text"
                        class="form-control input-sm text-right"
                        :class="
                          !checkEmpty(hotel_direction_common?.errors?.upper_limit_amount) && 'has-validation-error'
                        "
                        maxlength="10"
                        v-model="hotel_direction_common.upper_limit_amount"
                        @input="
                          hotel_direction_common.upper_limit_amount = convertNumberFullToHalf(
                            hotel_direction_common.upper_limit_amount,
                          )
                        "
                        onkeydown="javascript: return event.keyCode !== 69;"
                        @change="convertDataCommon('hotel_direction_common.upper_limit_amount', false, -1, true)"
                      />
                      <span class="input-group-addon">{{ $t('regulation.up_to_the_yen') }}</span>
                    </div>
                    <p class="error-feedback" v-text="hotel_direction_common?.errors?.upper_limit_amount" />
                  </div>
                </div>
              </div>
              <div v-show="hotel_direction_common?.is_depend_position" class="js-check_jobtitile__tgt--on">
                <div class="col-sm-6" v-if="hotel_direction_common">
                  <div
                    class="regulation-jobtitle scroll-margin-top"
                    v-for="(position, index) in hotel_direction_common.position_amounts"
                    :key="index"
                    :id="`hotel_direction_common_position_amounts_${index}`"
                  >
                    <div class="form-inline-text regulation-jobtitle--label">
                      {{ getPositionName(position.position_id) }}
                    </div>
                    <div class="row">
                      <div class="col-sm-6 col-all-closer col-all-closer--leftend">
                        <div class="form-group">
                          <div class="input-group adjust-amount">
                            <input
                              type="text"
                              class="form-control input-sm text-right"
                              :class="!checkEmpty(position?.errors?.lower_limit_amount) && 'has-validation-error'"
                              maxlength="10"
                              v-model="position.lower_limit_amount"
                              @input="
                                position.lower_limit_amount = convertNumberFullToHalf(position.lower_limit_amount)
                              "
                              onkeydown="javascript: return event.keyCode !== 69;"
                              @change="
                                convertDataCommon(
                                  'hotel_direction_common.position_amounts.lower_limit_amount',
                                  true,
                                  index,
                                  true,
                                )
                              "
                            />
                            <span class="input-group-addon">{{ $t('regulation.from_the_circle') }}</span>
                          </div>
                          <p class="error-feedback" v-text="position?.errors?.lower_limit_amount" />
                        </div>
                      </div>
                      <div class="col-sm-6 col-all-closer col-all-closer--rightend">
                        <div class="form-group">
                          <div class="input-group adjust-amount">
                            <input
                              type="text"
                              class="form-control input-sm text-right"
                              :class="!checkEmpty(position?.errors?.upper_limit_amount) && 'has-validation-error'"
                              maxlength="10"
                              v-model="position.upper_limit_amount"
                              @input="
                                position.upper_limit_amount = convertNumberFullToHalf(position.upper_limit_amount)
                              "
                              onkeydown="javascript: return event.keyCode !== 69;"
                              @change="
                                convertDataCommon(
                                  'hotel_direction_common.position_amounts.upper_limit_amount',
                                  true,
                                  index,
                                  true,
                                )
                              "
                            />
                            <span class="input-group-addon">{{ $t('regulation.up_to_the_yen') }}</span>
                          </div>
                          <p class="error-feedback" v-text="position?.errors?.upper_limit_amount" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <p v-if="checkEmpty(hotel_direction_common.position_amounts)" class="no-data">
                    {{ $t('common.no_data') }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="section">
          <h4 class="section-title">
            {{ $t('regulation.total_trave_expenses') }}
          </h4>
          <h6>{{ $t('regulation.area') }}</h6>
          <div class="js-addcontrol1--wrap">
            <div
              class="row addcontrol--item scroll-margin-top"
              v-for="(item, index) in total_expense_directions"
              :key="index"
              :id="`total_expense_directions_field_${index}`"
            >
              <div class="col-sm-4">
                <div class="form-group">
                  <div class="btn-group bootstrap-select show-tick select-block select-sm">
                    <MultiOptionSelectRegulation
                      :class="!checkEmpty(item?.errors?.area_id) && 'has-validation-error-el'"
                      :list-data="listArea"
                      :default-list="item.area_id"
                      @handle-selected-params="listenUpdateTotalArea"
                      :is-display-search="false"
                      :option-index="{ haveIndex: true, index }"
                      :display-by-locale="true"
                    />
                    <p class="error-feedback" v-text="item?.errors?.area_id" />
                  </div>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group text-right">
                  <label
                    class="checkbox checkbox-inline checkbox-type-div checkbox-expense"
                    :class="item?.is_depend_position && 'checked'"
                  >
                    <span class="icons">
                      <span class="first-icon icon-abui-checkbox-unchecked" />
                      <span class="second-icon icon-abui-checkbox-checked" />
                    </span>
                    <input type="checkbox" v-model="item.is_depend_position" />
                    <span class="text_type">
                      {{ $t('regulation.specified_by_job_title') }}
                    </span>
                  </label>
                </div>
              </div>
              <div v-show="!item?.is_depend_position" class="js-check_jobtitile__tgt--off">
                <div class="col-sm-3 col-all-closer col-all-closer--leftend">
                  <div class="form-group">
                    <div class="input-group adjust-amount">
                      <input
                        type="text"
                        class="form-control input-sm text-right"
                        :class="!checkEmpty(item?.errors?.lower_limit_amount) && 'has-validation-error'"
                        maxlength="10"
                        v-model="item.lower_limit_amount"
                        @input="item.lower_limit_amount = convertNumberFullToHalf(item.lower_limit_amount)"
                        onkeydown="javascript: return event.keyCode !== 69;"
                        @change="convertDataCommon('total_expense_directions.lower_limit_amount', true, index, true)"
                      />
                      <span class="input-group-addon">{{ $t('regulation.from_the_circle') }}</span>
                    </div>
                    <p class="error-feedback" v-text="item?.errors?.lower_limit_amount" />
                  </div>
                </div>
                <div class="col-sm-3 col-all-closer col-all-closer--rightend">
                  <div class="form-group">
                    <div class="input-group adjust-amount">
                      <input
                        type="text"
                        class="form-control input-sm text-right"
                        :class="!checkEmpty(item?.errors?.upper_limit_amount) && 'has-validation-error'"
                        maxlength="10"
                        v-model="item.upper_limit_amount"
                        @input="item.upper_limit_amount = convertNumberFullToHalf(item.upper_limit_amount)"
                        onkeydown="javascript: return event.keyCode !== 69;"
                        @change="convertDataCommon('total_expense_directions.upper_limit_amount', true, index, true)"
                      />
                      <span class="input-group-addon">{{ $t('regulation.up_to_the_yen') }}</span>
                    </div>
                    <p class="error-feedback" v-text="item?.errors?.upper_limit_amount" />
                  </div>
                </div>
              </div>
              <div v-show="item?.is_depend_position" class="js-check_jobtitile__tgt--on">
                <div class="col-sm-6" v-if="item?.position_amounts">
                  <div
                    class="regulation-jobtitle"
                    v-for="(position, positionIndex) in item.position_amounts"
                    :key="positionIndex"
                  >
                    <div class="form-inline-text regulation-jobtitle--label">
                      {{ getPositionName(position.position_id) }}
                    </div>
                    <div class="row">
                      <div class="col-sm-6 col-all-closer col-all-closer--leftend">
                        <div class="form-group">
                          <div class="input-group adjust-amount">
                            <input
                              type="text"
                              class="form-control input-sm text-right"
                              :class="!checkEmpty(position?.errors?.lower_limit_amount) && 'has-validation-error'"
                              maxlength="10"
                              v-model="position.lower_limit_amount"
                              @input="
                                position.lower_limit_amount = convertNumberFullToHalf(position.lower_limit_amount)
                              "
                              onkeydown="javascript: return event.keyCode !== 69;"
                              @change="
                                convertNestedDataAmount(
                                  'total_expense_directions.position_amounts.lower_limit_amount',
                                  index,
                                  positionIndex,
                                  true,
                                )
                              "
                            />
                            <span class="input-group-addon">{{ $t('regulation.from_the_circle') }}</span>
                          </div>
                          <p class="error-feedback" v-text="position?.errors?.lower_limit_amount" />
                        </div>
                      </div>
                      <div class="col-sm-6 col-all-closer col-all-closer--rightend">
                        <div class="form-group">
                          <div class="input-group adjust-amount">
                            <input
                              type="text"
                              class="form-control input-sm text-right"
                              :class="!checkEmpty(position?.errors?.upper_limit_amount) && 'has-validation-error'"
                              maxlength="10"
                              v-model="position.upper_limit_amount"
                              @input="
                                position.upper_limit_amount = convertNumberFullToHalf(position.upper_limit_amount)
                              "
                              onkeydown="javascript: return event.keyCode !== 69;"
                              @change="
                                convertNestedDataAmount(
                                  'total_expense_directions.position_amounts.upper_limit_amount',
                                  index,
                                  positionIndex,
                                  false,
                                )
                              "
                            />
                            <span class="input-group-addon">{{ $t('regulation.up_to_the_yen') }}</span>
                          </div>
                          <p class="error-feedback" v-text="position?.errors?.upper_limit_amount" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <p v-if="checkEmpty(item.position_amounts)" class="no-data">
                    {{ $t('common.no_data') }}
                  </p>
                </div>
              </div>
              <button
                class="btn btn-sm btn-flat-danger js-addcontrol--del addcontrol--item-del"
                @click="deleteItem(8, index)"
              >
                <span class="icon-abui-cross" />
              </button>
            </div>
            <div class="row--addcontrol">
              <button class="btn btn-sm btn-flat-primary js-addcontrol1" @click="addNewItem(8)">
                <span class="icon-abui-plus prx" />{{ $t('regulation.add_target_area') }}
              </button>
            </div>
          </div>

          <hr class="mtl mbl" />

          <div>
            <div class="form-group">
              <label>
                <span class="label-text">{{ $t('regulation.remarks') }}</span>
              </label>
              <textarea
                class="form-control"
                maxlength="100"
                rows="2"
                :placeholder="$i18n.locale == 'en' ? 'Exceptions in cities' : '都市の例外など'"
                v-model="total_expense_direction_note"
              />
            </div>
          </div>

          <div style="margin-top: 24px" class="scroll-margin-top" id="total_expense_direction_common_field">
            <div class="row addcontrol--item">
              <div class="col-sm-4">
                <div class="form-group">
                  {{ $t('regulation.common_destination_other') }}
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group text-right" v-if="total_expense_direction_common">
                  <label
                    class="checkbox checkbox-inline checkbox-type-div checkbox-expense"
                    :class="total_expense_direction_common?.is_depend_position && 'checked'"
                  >
                    <span class="icons">
                      <span class="first-icon icon-abui-checkbox-unchecked" />
                      <span class="second-icon icon-abui-checkbox-checked" />
                    </span>
                    <input type="checkbox" v-model="total_expense_direction_common.is_depend_position" />
                    <span class="text_type">
                      {{ $t('regulation.specified_by_job_title') }}
                    </span>
                  </label>
                </div>
              </div>
              <div v-show="!total_expense_direction_common?.is_depend_position" class="js-check_jobtitile__tgt--off">
                <div class="col-sm-3 col-all-closer col-all-closer--leftend">
                  <div class="form-group">
                    <div class="input-group adjust-amount" v-if="total_expense_direction_common">
                      <input
                        type="text"
                        class="form-control input-sm text-right"
                        :class="
                          !checkEmpty(total_expense_direction_common?.errors?.lower_limit_amount) &&
                          'has-validation-error'
                        "
                        maxlength="10"
                        v-model="total_expense_direction_common.lower_limit_amount"
                        @input="
                          total_expense_direction_common.lower_limit_amount = convertNumberFullToHalf(
                            total_expense_direction_common.lower_limit_amount,
                          )
                        "
                        onkeydown="javascript: return event.keyCode !== 69;"
                        @change="
                          convertDataCommon('total_expense_direction_common.lower_limit_amount', false, -1, true)
                        "
                      />
                      <span class="input-group-addon">{{ $t('regulation.from_the_circle') }}</span>
                    </div>
                    <p class="error-feedback" v-text="total_expense_direction_common?.errors?.lower_limit_amount" />
                  </div>
                </div>
                <div class="col-sm-3 col-all-closer col-all-closer--rightend">
                  <div class="form-group">
                    <div class="input-group adjust-amount" v-if="total_expense_direction_common">
                      <input
                        type="text"
                        class="form-control input-sm text-right"
                        :class="
                          !checkEmpty(total_expense_direction_common?.errors?.upper_limit_amount) &&
                          'has-validation-error'
                        "
                        maxlength="10"
                        v-model="total_expense_direction_common.upper_limit_amount"
                        @input="
                          total_expense_direction_common.upper_limit_amount = convertNumberFullToHalf(
                            total_expense_direction_common.upper_limit_amount,
                          )
                        "
                        onkeydown="javascript: return event.keyCode !== 69;"
                        @change="
                          convertDataCommon('total_expense_direction_common.upper_limit_amount', false, -1, true)
                        "
                      />
                      <span class="input-group-addon">{{ $t('regulation.up_to_the_yen') }}</span>
                    </div>
                    <p class="error-feedback" v-text="total_expense_direction_common?.errors?.upper_limit_amount" />
                  </div>
                </div>
              </div>
              <div v-show="total_expense_direction_common?.is_depend_position" class="js-check_jobtitile__tgt--on">
                <div class="col-sm-6" v-if="total_expense_direction_common">
                  <div
                    class="regulation-jobtitle scroll-margin-top"
                    v-for="(position, index) in total_expense_direction_common.position_amounts"
                    :key="index"
                    :id="`total_expense_direction_common_position_amounts_field_${index}`"
                  >
                    <div class="form-inline-text regulation-jobtitle--label">
                      {{ getPositionName(position.position_id) }}
                    </div>
                    <div class="row">
                      <div class="col-sm-6 col-all-closer col-all-closer--leftend">
                        <div class="form-group">
                          <div class="input-group adjust-amount">
                            <input
                              type="text"
                              class="form-control input-sm text-right"
                              :class="!checkEmpty(position?.errors?.lower_limit_amount) && 'has-validation-error'"
                              maxlength="10"
                              v-model="position.lower_limit_amount"
                              @input="
                                position.lower_limit_amount = convertNumberFullToHalf(position.lower_limit_amount)
                              "
                              onkeydown="javascript: return event.keyCode !== 69;"
                              @change="
                                convertDataCommon(
                                  'total_expense_direction_common.position_amounts.lower_limit_amount',
                                  true,
                                  index,
                                  true,
                                )
                              "
                            />
                            <span class="input-group-addon">{{ $t('regulation.from_the_circle') }}</span>
                          </div>
                          <p class="error-feedback" v-text="position?.errors?.lower_limit_amount" />
                        </div>
                      </div>
                      <div class="col-sm-6 col-all-closer col-all-closer--rightend">
                        <div class="form-group">
                          <div class="input-group adjust-amount">
                            <input
                              type="text"
                              class="form-control input-sm text-right"
                              :class="!checkEmpty(position?.errors?.upper_limit_amount) && 'has-validation-error'"
                              maxlength="10"
                              v-model="position.upper_limit_amount"
                              @input="
                                position.upper_limit_amount = convertNumberFullToHalf(position.upper_limit_amount)
                              "
                              onkeydown="javascript: return event.keyCode !== 69;"
                              @change="
                                convertDataCommon(
                                  'total_expense_direction_common.position_amounts.upper_limit_amount',
                                  true,
                                  index,
                                  true,
                                )
                              "
                            />
                            <span class="input-group-addon">{{ $t('regulation.up_to_the_yen') }}</span>
                          </div>
                          <p class="error-feedback" v-text="position?.errors?.upper_limit_amount" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <p v-if="checkEmpty(total_expense_direction_common.position_amounts)" class="no-data">
                    {{ $t('common.no_data') }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="section section-last">
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label>
                  <span class="label-text">{{ $t('regulation.document') }}</span>
                </label>
                <button
                  v-if="files.length == 0"
                  type="button"
                  class="btn btn-inverse btn-sm"
                  @click="showModalUploadFile = true"
                  style="margin-bottom: 4px"
                >
                  <i class="icon-abui-upload prx" />{{ $t('regulation.upload') }}
                </button>
                <button
                  style="width: 100%; display: flex; justify-content: space-between; margin-bottom: 4px"
                  v-for="(item, index) in files"
                  :key="index"
                  type="button"
                  class="btn btn-inverse-hover-danger btn-sm js-btn--delete-voucher-general"
                >
                  <span class="thumbnail--voucher--filename">
                    {{ !checkEmpty(item.file_name) ? item.file_name : item.name }}
                  </span>
                  <i class="icon-abui-cross pls" @click="deleteFile(item)" />
                </button>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>
              <span class="label-text">{{ $t('regulation.remarks') }}</span>
            </label>
            <textarea maxlength="500" class="form-control" rows="6" placeholder="" v-model="note" />
            <!-- <p class="error-feedback" v-text="errors?.note" /> -->
          </div>
        </div>

        <div
          class="navbar navbar-default navbar-fixed-bottom navbar-footblock with-sidebar"
          role="navigation"
          style="z-index: 990; padding: 12px"
        >
          <div class="row">
            <div class="col-sm-4 col-md-3 col-sm-offset-8 col-md-offset-9">
              <a class="btn btn-sm btn-block btn-primary" @click="validate">{{ $t('regulation.keep') }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalUploadFile
      :dialogVisible="showModalUploadFile"
      :isSingleUpload="true"
      @cancel="showModalUploadFile = false"
      @confirm="confirmUploadFile"
      :title="$t('common.title_modal_upload_file_csv')"
    />
    <ModalUpdateAirPort
      :dialogVisible="showModalUpdateAirPort"
      :list-airport="listAirport"
      :default-list="defaultBannedAirlineList"
      @cancel="closeModalUpdateAirPort"
      @update="updateNoTransitAirportList"
    />
    <ModalUploadCSV
        :dialogVisible="showModalUploadCSV"
        @cancel="showModalUploadCSV = false"
        @confirm="confirmUploadFileCSVPassport"
        :errorValidate="errorValidate"
        :title="$t('common.title_modal_upload_file_csv')"
    />
    <ModalConfirm
      :dialogVisible="showBoxConfirm"
      @cancel="showBoxConfirm = false"
      @yes="confirmDelete"
      :title="$t('Q-CM-104')"
    />
  </body>
</template>
<script>
import ModalUploadFile from '@/components/common/ModalUploadFile';
import ModalUpdateAirPort from '@/components/common/ModalUpdateAirPort';
import MultiOptionSelectRegulation from '@/components/common/MultiOptionSelectRegulation';
import ModalConfirm from '@/components/common/ModalConfirm';
import ModalUploadCSV from '@/components/common/ModalUploadCSV';
import {
  DOMESTIC_FLIGHT_CLASS,
  INTERNATIONAL_FLIGHT_CLASS,
  TYPE_PASSPORT,
  TYPE_BANNED_FLIGHT,
  TYPE_ATTENTION_FLIGHT,
  TYPE_TRANSIT_TIME,
  TYPE_TRANSIT_CAUTION,
  TYPE_AMOUNT_MONEY,
  TYPE_HOTEL,
  TYPE_TOTAL_TRAVEL_EXPENSES,
} from '@/constants/';
import vClickOutside from "click-outside-vue3";

export default {
  name: 'Regulation',
  components: {
    ModalUploadFile,
    ModalUpdateAirPort,
    MultiOptionSelectRegulation,
    ModalConfirm,
    ModalUploadCSV,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  beforeRouteLeave(to, from, next) {
    if (this.isDataChanged) {
      const answer = window.confirm(this.$t('common.confirm_leave_page'));
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  data() {
    return {
      regulationDetail: {},
      showModalUploadFile: false,
      is_check_remain_days: false,
      showModalUpdateAirPort: false,
      isShowDropdown: false,
      showModalUploadCSV: false,
      showBoxConfirm: false,
      errorValidate: [],
      listCountry: [],
      listAirline: [],
      listPosition: [],
      listArea: [],
      listAirport: [],
      regulation: {
        id: null,
        number_of_days: '',
        is_day_trip: false,
        is_distance: false,
        is_check_remain_days: false,
        distance: 0,
        passports: [],
        common_passport_day_required: '',
        // is_insurance_required: false,
        no_transit_airport_list: [],
        banned_airline_list: [],
        banned_flight_section_list: [],
        attention_flight_section_list: [],
        transit_time_list: [],
        transit_caution_list: [],
        number_of_connection: 0,
        domestic_flights: [],
        international_flights: [],
        flight_times: [],
        is_flight_time: false,
        flight_time: 0,
        ticket_costs: [],
        ticket_cost_common: {},
        hotel_directions: [],
        hotel_direction_note: '',
        hotel_direction_common: {},
        total_expense_directions: [],
        total_expense_direction_note: '',
        total_expense_direction_common: {},
        note: '',
      },
      typeDelete: 0,
      indexDelete: {},
      flightClass: INTERNATIONAL_FLIGHT_CLASS,
      domesticFlightClass: DOMESTIC_FLIGHT_CLASS,
      internationalFlightClass: INTERNATIONAL_FLIGHT_CLASS,
      errors: {
        number_of_days: '',
        distance: '',
        common_passport_day_required: '',
        no_transit_airport_list: '',
        banned_airline_list: '',
        number_of_connection: '',
        flight_time: '',
        note: '',
      },
      number_of_days: null,
      number_of_days_official: null,
      is_day_trip: false,
      is_day_trip_official: false,
      is_distance: false,
      distance: 0,
      distance_official: 0,
      passports: [{
        passports_countries: [],
        remain_days: 0,
        errors: {
          passports_countries: '',
          remain_days: '',
        }
      }],
      passports_official: [],
      common_passport_day_required: '',
      common_passport_day_required_official: '',
      // is_insurance_required: false,
      no_transit_airport_list: [],
      no_transit_airport_list_official: [],
      banned_airline_list: [],
      banned_airline_list_official: [],
      banned_flight_section_list: [],
      banned_flight_section_list_official: [],
      attention_flight_section_list: [],
      attention_flight_section_list_official: [],
      transit_time_list: [],
      transit_time_list_official: [],
      transit_caution_list: [],
      transit_caution_list_official: [],
      number_of_connection: 0,
      number_of_connection_official: 0,
      domestic_flights: {
        is_depend_position: 0,
        seat_class_types: [],
        position_seat_class_types: [],
      },
      domestic_flights_official: {
        is_depend_position: 0,
        seat_class_types: [],
        position_seat_class_types: [],
      },
      international_flights: {
        is_depend_position: 0,
        seat_class_types: [],
        position_seat_class_types: [],
      },
      flight_times: {
        is_depend_position: 0,
        seat_class_types: [],
        position_seat_class_types: [],
      },
      international_flights_official: {
        is_depend_position: 0,
        seat_class_types: [],
        position_seat_class_types: [],
      },
      flight_times_official: {
        is_depend_position: 0,
        seat_class_types: [],
        position_seat_class_types: [],
      },
      is_flight_time: false,
      flight_time: 0,
      flight_time_official: 0,
      ticket_costs: [],
      ticket_costs_official: [],
      ticket_cost_common: {
        is_depend_position: 0,
        lower_limit_amount: '',
        upper_limit_amount: '',
        position_amounts: [],
        errors: {
          lower_limit_amount: '',
          upper_limit_amount: '',
        },
      },
      ticket_cost_common_official: {
        is_depend_position: 0,
        lower_limit_amount: '',
        upper_limit_amount: '',
        position_amounts: [],
        errors: {
          lower_limit_amount: '',
          upper_limit_amount: '',
        },
      },
      hotel_directions: [],
      hotel_directions_official: [],
      hotel_direction_note: '',
      hotel_direction_note_official: '',
      hotel_direction_common: {
        is_depend_position: 0,
        lower_limit_amount: 0,
        upper_limit_amount: 0,
        position_amounts: [],
        errors: {
          lower_limit_amount: '',
          upper_limit_amount: '',
        },
      },
      hotel_direction_common_official: {
        is_depend_position: 0,
        lower_limit_amount: 0,
        upper_limit_amount: 0,
        position_amounts: [],
        errors: {
          lower_limit_amount: '',
          upper_limit_amount: '',
        },
      },
      total_expense_directions: [],
      total_expense_directions_official: [],
      total_expense_direction_note: '',
      total_expense_direction_note_official: '',
      total_expense_direction_common: {
        is_depend_position: 0,
        lower_limit_amount: '',
        upper_limit_amount: '',
        position_amounts: [],
        errors: {
          lower_limit_amount: '',
          upper_limit_amount: '',
        },
      },
      total_expense_direction_common_official: {
        is_depend_position: 0,
        lower_limit_amount: '',
        upper_limit_amount: '',
        position_amounts: [],
        errors: {
          lower_limit_amount: '',
          upper_limit_amount: '',
        },
      },
      files: [],
      disabledFlightTime: false,
      files_official: [],
      note: '',
      note_official: '',
      defaultCommon: {
        is_depend_position: false,
        lower_limit_amount: '',
        upper_limit_amount: '',
        position_amounts: [],
        errors: {
          lower_limit_amount: '',
          upper_limit_amount: '',
        },
      },
      isSubmit: false,
    };
  },
  computed: {
    hideAddBtn() {
      return this.passports[0].passports_countries.length === 0;
    },
    defaultBannedAirlineList() {
      let rs = [];
      if (!this.showModalUpdateAirPort) {
        if (this.no_transit_airport_list) {
          this.no_transit_airport_list.forEach(id => {
            let air = this.no_transit_airport_list.find(ap => ap.id == id);
            rs.push(air);
          });
        }
      } else {
        if (this.no_transit_airport_list) {
          this.no_transit_airport_list.forEach(id => {
            let air = this.listAirport.find(ap => ap.id == id);
            rs.push(air);
          });
        }
      }

      return rs;
    },
    isDataChanged() {
      if (
        (!this.compareArray(this.attention_flight_section_list, this.attention_flight_section_list_official) ||
          !this.compareArray(this.banned_airline_list, this.banned_airline_list_official) ||
          !this.compareArray(this.banned_flight_section_list, this.banned_flight_section_list_official) ||
          !(
            this.convertToIntegerKite(this.common_passport_day_required) ==
            this.convertToIntegerKite(this.common_passport_day_required_official)
          ) ||
          !(this.convertToIntegerKite(this.distance) == this.convertToIntegerKite(this.distance_official)) ||
          !this.compareObject(this.domestic_flights, this.domestic_flights_official) ||
          !this.compareArray(this.files, this.files_official) ||
          !(this.convertToIntegerKite(this.flight_time) == this.convertToIntegerKite(this.flight_time_official)) ||
          !this.compareObject(this.hotel_direction_common, this.hotel_direction_common_official) ||
          !(this.hotel_direction_note == this.hotel_direction_note_official) ||
          !this.compareArray(this.hotel_directions, this.hotel_directions_official) ||
          !this.compareObject(this.international_flights, this.international_flights_official) ||
          !this.compareObject(this.flight_times, this.flight_times_official) ||
          !(this.is_day_trip == this.is_day_trip_official) ||
          !(this.is_distance == this.is_distance_official) ||
          !(this.is_check_remain_days == this.is_check_remain_days_official) ||
          !(this.is_flight_time == this.is_flight_time_official) ||
          // !this.is_insurance_required == this.is_insurance_required_official ||
          !this.compareArray(this.no_transit_airport_list, this.no_transit_airport_list_official) ||
          !(this.note == this.note_official) ||
          !(
            this.convertToIntegerKite(this.number_of_connection) ==
            this.convertToIntegerKite(this.number_of_connection_official)
          ) ||
          !(this.number_of_days == this.number_of_days_official) ||
          !(this.total_expense_direction_note == this.total_expense_direction_note_official) ||
          !this.compareArray(this.passports_official, this.passports) ||
          !this.compareObject(this.ticket_cost_common, this.ticket_cost_common_official) ||
          !this.compareArray(this.ticket_costs, this.ticket_costs_official) ||
          !this.compareObject(this.total_expense_direction_common, this.total_expense_direction_common_official) ||
          !this.compareArray(this.total_expense_directions, this.total_expense_directions_official) ||
          !this.compareArray(this.transit_caution_list, this.transit_caution_list_official) ||
          !this.compareArray(this.transit_time_list, this.transit_time_list_official)) &&
        !this.isSubmit
      )
        return true;
      return false;
    },
  },
  watch: {
    showModalUploadCSV() {
      this.errorValidate = [];
    },
    is_check_remain_days(val) {
      if (!val) {
        this.errors.common_passport_day_required = '';
      }
    },
    is_distance(val) {
      if (!val) {
        this.errors.distance = '';
        this.distance = 0;
      }
    },
    flight_time(val) {
      if (!val || val === "0") {
        this.disabledFlightTime = true;
      } else {
        this.disabledFlightTime = false;
      }
    },
    is_flight_time(val) {
      if (!val) {
        this.errors.flight_time = '';
      }
    },
    regulationDetail: {
      handler(newVal) {
        this.updateDataRegulation(newVal);
      },
      deep: true,
    },
    passports: {
      handler(newVal) {
        if (newVal.length === 0) {
          this.passports = [{
            passports_countries: [],
            remain_days: 0,
            errors: {
              passports_countries: '',
              remain_days: '',
            }
          }];
        }
        // let check = this.passports.filter(item => item.passports_countries?.length === 0).length;
        // if (check) {
        //   this.disabledFlightTime = true;
        // } else {
        //     if (!this.flight_time ||  this.flight_time === "0") {
        //       this.disabledFlightTime = true;
        //     } else {
        //       this.disabledFlightTime = false;
        //     }
        // }
      },
      deep: true,
    },
  },
  created() {
    this.startLoading();
    Promise.all([
      this.getListCountry(),
      this.getListAirLines(),
      this.getListArea(),
      this.getRegulation(),
      this.getListAirport(),
    ]).then(() => {
      this.initDataErrors();
      this.getListPosition();
      this.endLoading();
    });
    if (!this.flight_time || this.flight_time === "0") {
      this.disabledFlightTime = true;
    } else {
      this.disabledFlightTime = false;
    }
  },
  methods: {
    downloadExampleFileCSV() {
      this.startLoading();
      this.$services.regulation.downloadExampleFileCSVPassport(
          res => {
            this.endLoading();
            window.open(res.file_path, '_blank').focus();
          },
          err => {
            this.endLoading();
            this.notifyError(this.$t(err.error));
          },
      );
    },
    confirmUploadFileCSVPassport(listFiles) {
      let formData = new FormData();
      if (!this.checkEmpty(listFiles.length)) {
        formData.append('file', listFiles[0]);
        this.errorValidate = [];
        this.startLoading();
        this.$services.regulation.uploadFileCSVPassport(
            formData,
            (res) => {
              res.list.forEach(item => {
                this.passports.push({
                  passports_countries: item?.passports_countries || [],
                  remain_days: item?.remain_days || 0,
                  errors: {
                    passports_countries: '',
                    remain_days: '',
                  },
                });
              });
              this.endLoading();
              this.showModalUploadCSV = false;
              this.getListPosition();
            },
            err => {
              this.endLoading();
              if (err.code == 422) {
                this.errorValidate = err.error;
              } else {
                this.notifyError(this.$t(err.error));
              }
            },
        );
      } else {
        this.notifyError(this.$t('E-CM-101', { name: this.$t('common.file') }));
      }
    },
    updateDataRegulation(regulationDetail) {
      const res = this.cloneObject(regulationDetail);
      this.total_expense_directions = res.total_expense_directions;
      if (!this.checkEmpty(this.total_expense_directions)) {
        this.total_expense_directions.forEach(item => {
          item.is_depend_position = Boolean(item.is_depend_position);
          item.lower_limit_amount = this.splitThousandNumberKite(item.lower_limit_amount);
          item.upper_limit_amount = this.splitThousandNumberKite(item.upper_limit_amount);
          if (!this.checkEmpty(item.position_amounts)) {
            item.position_amounts.forEach(position => {
              position.lower_limit_amount = this.splitThousandNumberKite(position.lower_limit_amount);
              position.upper_limit_amount = this.splitThousandNumberKite(position.upper_limit_amount);
            });
          }
        });
      }

      let totalExpenseDirectionsOfficial = res.total_expense_directions;
      if (!this.checkEmpty(totalExpenseDirectionsOfficial)) {
        totalExpenseDirectionsOfficial.forEach(item => {
          item.lower_limit_amount = this.splitThousandNumberKite(item.lower_limit_amount);
          item.upper_limit_amount = this.splitThousandNumberKite(item.upper_limit_amount);
          if (!this.checkEmpty(item.position_amounts)) {
            item.position_amounts.forEach(position => {
              position.lower_limit_amount = this.splitThousandNumberKite(position.lower_limit_amount);
              position.upper_limit_amount = this.splitThousandNumberKite(position.upper_limit_amount);
            });
          }
        });
      }
      this.total_expense_directions_official = totalExpenseDirectionsOfficial;
      this.total_expense_direction_note = !this.checkEmpty(res.total_expense_direction_note)
        ? res.total_expense_direction_note
        : '';
      //
      this.regulation.id = res.id;
      this.number_of_days = res.number_of_days || 0;
      this.is_day_trip = Boolean(res.is_day_trip);
      this.is_distance = Boolean(res.is_distance);
      this.is_check_remain_days = Boolean(res.is_check_remain_days);
      this.distance = this.splitThousandNumberKite(res.distance);
      let passportList = res.passports;
      if (!this.checkEmpty(passportList)) {
        passportList.forEach(item => {
          item.remain_days = this.splitThousandNumberKiteCustom(item.remain_days);
        });
      }
      this.passports = passportList;
      this.common_passport_day_required = this.splitThousandNumberKiteCustom(res.common_passport_day_required);
      // this.is_insurance_required = res.is_insurance_required;
      this.no_transit_airport_list = res.no_transit_airport_list;
      this.banned_airline_list = res.banned_airline_list;
      this.banned_flight_section_list = res.banned_flight_section_list;
      this.attention_flight_section_list = res.attention_flight_section_list;
      let transitTimeList = res.transit_time_list;
      if (!this.checkEmpty(transitTimeList)) {
        transitTimeList.forEach(item => {
          item.lower_limit_time = this.splitThousandNumberKite(item.lower_limit_time);
          item.upper_limit_time = this.splitThousandNumberKite(item.upper_limit_time);
        });
      }
      this.transit_time_list = transitTimeList;
      this.transit_caution_list = res.transit_caution_list;
      this.number_of_connection = this.splitThousandNumberKite(res.number_of_connection);
      // this.domestic_flights = !this.checkEmpty(res.domestic_flights)
      //   ? res.domestic_flights
      //   : {
      //       is_depend_position: false,
      //       seat_class_types: [],
      //       position_seat_class_types: [],
      //     };
      if (!this.checkEmpty(res.domestic_flights)) {
        this.domestic_flights = res.domestic_flights;
        this.domestic_flights.is_depend_position = Boolean(res.domestic_flights.is_depend_position);
      } else {
        this.domestic_flights = {
          is_depend_position: false,
          seat_class_types: [],
          position_seat_class_types: [],
        };
      }
      // this.international_flights = !this.checkEmpty(res.international_flights)
      //   ? res.international_flights
      //   : {
      //       is_depend_position: false,
      //       seat_class_types: [],
      //       position_seat_class_types: [],
      //     };
      if (!this.checkEmpty(res.international_flights)) {
        this.international_flights = res.international_flights;
        this.international_flights.is_depend_position = Boolean(res.international_flights.is_depend_position);
      } else {
        this.international_flights = {
          is_depend_position: false,
          seat_class_types: [],
          position_seat_class_types: [],
        };
      }
      if (!this.checkEmpty(res.flight_times)) {
        this.flight_times = res.flight_times;
        this.flight_times.is_depend_position = Boolean(res.flight_times.is_depend_position);
      } else {
        this.flight_times = {
          is_depend_position: 0,
          seat_class_types: [],
          position_seat_class_types: [],
        };
      }
      this.is_flight_time = Boolean(res.is_flight_time == 1);
      this.flight_time = this.splitThousandNumberKite(res.flight_time);
      let costTickets = res.ticket_costs;
      if (!this.checkEmpty(costTickets)) {
        costTickets.forEach(item => {
          item.upper_limit_amount = this.splitThousandNumberKite(item.upper_limit_amount);
          item.lower_limit_amount = this.splitThousandNumberKite(item.lower_limit_amount);
          item.is_depend_position = Boolean(item.is_depend_position);
          if (!this.checkEmpty(item.position_amounts)) {
            item.position_amounts.forEach(position => {
              position.lower_limit_amount = this.splitThousandNumberKite(position.lower_limit_amount);
              position.upper_limit_amount = this.splitThousandNumberKite(position.upper_limit_amount);
            });
          }
        });
      }
      this.ticket_costs = costTickets;
      let costTicketsCommon = res.ticket_cost_common;
      if (!this.checkEmpty(costTicketsCommon)) {
        costTicketsCommon.upper_limit_amount = this.splitThousandNumberKite(costTicketsCommon.upper_limit_amount);
        costTicketsCommon.lower_limit_amount = this.splitThousandNumberKite(costTicketsCommon.lower_limit_amount);
        costTicketsCommon.is_depend_position = Boolean(costTicketsCommon.is_depend_position);
        if (!this.checkEmpty(costTicketsCommon.position_amounts)) {
          costTicketsCommon.position_amounts.forEach(item => {
            item.lower_limit_amount = this.splitThousandNumberKite(item.lower_limit_amount);
            item.upper_limit_amount = this.splitThousandNumberKite(item.upper_limit_amount);
          });
        }
      }
      this.ticket_cost_common = costTicketsCommon;
      let hotelDirection = res.hotel_directions;
      if (!this.checkEmpty(hotelDirection)) {
        hotelDirection.forEach(item => {
          item.lower_limit_amount = this.splitThousandNumberKite(item.lower_limit_amount);
          item.upper_limit_amount = this.splitThousandNumberKite(item.upper_limit_amount);
          item.is_depend_position = Boolean(item.is_depend_position);
          if (!this.checkEmpty(item.position_amounts)) {
            item.position_amounts.forEach(position => {
              position.lower_limit_amount = this.splitThousandNumberKite(position.lower_limit_amount);
              position.upper_limit_amount = this.splitThousandNumberKite(position.upper_limit_amount);
            });
          }
        });
      }
      this.hotel_directions = hotelDirection;
      this.hotel_direction_note = res.hotel_direction_note;
      let hotelDirectionCommon = res.hotel_direction_common;
      hotelDirectionCommon.lower_limit_amount = this.splitThousandNumberKite(hotelDirectionCommon.lower_limit_amount);
      hotelDirectionCommon.upper_limit_amount = this.splitThousandNumberKite(hotelDirectionCommon.upper_limit_amount);
      hotelDirectionCommon.is_depend_position = Boolean(hotelDirectionCommon.is_depend_position);
      if (!this.checkEmpty(hotelDirectionCommon.position_amounts)) {
        hotelDirectionCommon.position_amounts.forEach(position => {
          position.lower_limit_amount = this.splitThousandNumberKite(position.lower_limit_amount);
          position.upper_limit_amount = this.splitThousandNumberKite(position.upper_limit_amount);
        });
      }
      this.hotel_direction_common = hotelDirectionCommon;

      // this.total_expense_directions = res.total_expense_directions;
      let totalExpenseDirectionCommon = res.total_expense_direction_common;
      if (!this.checkEmpty(totalExpenseDirectionCommon)) {
        totalExpenseDirectionCommon.lower_limit_amount = this.splitThousandNumberKite(
          totalExpenseDirectionCommon.lower_limit_amount,
        );
        totalExpenseDirectionCommon.upper_limit_amount = this.splitThousandNumberKite(
          totalExpenseDirectionCommon.upper_limit_amount,
        );
        totalExpenseDirectionCommon.is_depend_position = Boolean(totalExpenseDirectionCommon.is_depend_position);
        if (!this.checkEmpty(totalExpenseDirectionCommon.position_amounts)) {
          totalExpenseDirectionCommon.position_amounts.forEach(position => {
            position.lower_limit_amount = this.splitThousandNumberKite(position.lower_limit_amount);
            position.upper_limit_amount = this.splitThousandNumberKite(position.upper_limit_amount);
          });
        }
      }
      this.total_expense_direction_common = totalExpenseDirectionCommon;

      this.files = !this.checkEmpty(res.files) ? [res.files[res.files.length - 1]] : [];
      this.note = res.note;

      let regulationData = this.cloneObject(res);
      this.attention_flight_section_list_official = regulationData.attention_flight_section_list;
      this.banned_airline_list_official = regulationData.banned_airline_list;
      this.banned_flight_section_list_official = regulationData.banned_flight_section_list;
      this.common_passport_day_required_official = this.splitThousandNumberKite(
        regulationData.common_passport_day_required,
      );
      this.distance_official = this.splitThousandNumberKite(regulationData.distance);
      // this.domestic_flights_official = !this.checkEmpty(regulationData.domestic_flights)
      //   ? regulationData.domestic_flights
      //   : {
      //       is_depend_position: false,
      //       seat_class_types: [],
      //       position_seat_class_types: [],
      //     };
      if (!this.checkEmpty(regulationData.domestic_flights)) {
        this.domestic_flights_official = regulationData.domestic_flights;
        this.domestic_flights_official.is_depend_position = Boolean(regulationData.domestic_flights.is_depend_position);
      } else {
        this.domestic_flights_official = {
          is_depend_position: false,
          seat_class_types: [],
          position_seat_class_types: [],
        };
      }
      this.files_official = !this.checkEmpty(regulationData.files)
        ? [regulationData.files[regulationData.files.length - 1]]
        : [];
      this.flight_time_official = this.splitThousandNumberKite(regulationData.flight_time);

      let hotelDirectionCommonOfficial = regulationData.hotel_direction_common;
      hotelDirectionCommonOfficial.lower_limit_amount = this.splitThousandNumberKite(
        hotelDirectionCommonOfficial.lower_limit_amount,
      );
      hotelDirectionCommonOfficial.upper_limit_amount = this.splitThousandNumberKite(
        hotelDirectionCommonOfficial.upper_limit_amount,
      );
      if (!this.checkEmpty(hotelDirectionCommonOfficial.position_amounts)) {
        hotelDirectionCommonOfficial.position_amounts.forEach(position => {
          position.lower_limit_amount = this.splitThousandNumberKite(position.lower_limit_amount);
          position.upper_limit_amount = this.splitThousandNumberKite(position.upper_limit_amount);
        });
      }
      this.hotel_direction_common_official = hotelDirectionCommonOfficial;

      this.hotel_direction_note_official = regulationData.hotel_direction_note_official;
      let hotelDirectionOfficial = regulationData.hotel_directions;
      if (!this.checkEmpty(hotelDirectionOfficial)) {
        hotelDirectionOfficial.forEach(item => {
          item.lower_limit_amount = this.splitThousandNumberKite(item.lower_limit_amount);
          item.upper_limit_amount = this.splitThousandNumberKite(item.upper_limit_amount);
          if (!this.checkEmpty(item.position_amounts)) {
            item.position_amounts.forEach(position => {
              position.lower_limit_amount = this.splitThousandNumberKite(position.lower_limit_amount);
              position.upper_limit_amount = this.splitThousandNumberKite(position.upper_limit_amount);
            });
          }
        });
      }
      this.hotel_directions_official = hotelDirectionOfficial;
      this.international_flights_official = !this.checkEmpty(regulationData.international_flights)
        ? regulationData.international_flights
        : {
            is_depend_position: false,
            seat_class_types: [],
            position_seat_class_types: [],
          };
      this.flight_times_official = !this.checkEmpty(regulationData.flight_times)
        ? regulationData.flight_times
        : {
            is_depend_position: 0,
            seat_class_types: [],
            position_seat_class_types: [],
          };
      this.is_day_trip_official = Boolean(regulationData.is_day_trip);
      this.is_distance_official = Boolean(regulationData.is_distance);
      this.is_check_remain_days_official = Boolean(regulationData.is_check_remain_days);
      this.is_flight_time_official = Boolean(regulationData.is_flight_time);
      // this.is_insurance_required_official = regulationData.is_insurance_required;
      this.no_transit_airport_list_official = regulationData.no_transit_airport_list;
      this.note_official = regulationData.note;
      this.number_of_connection_official = this.splitThousandNumberKite(regulationData.number_of_connection);
      this.number_of_days_official = regulationData.number_of_days;
      this.total_expense_direction_note_official = !this.checkEmpty(regulationData.total_expense_direction_note)
        ? regulationData.total_expense_direction_note
        : '';
      let passportListOfficial = regulationData.passports;
      if (!this.checkEmpty(passportListOfficial)) {
        passportListOfficial.forEach(item => {
          item.remain_days = this.splitThousandNumberKiteCustom(item.remain_days);
        });
      }
      this.passports_official = passportListOfficial;

      let costTicketsOfficial = regulationData.ticket_costs;
      if (!this.checkEmpty(costTicketsOfficial)) {
        costTicketsOfficial.forEach(item => {
          item.upper_limit_amount = this.splitThousandNumberKite(item.upper_limit_amount);
          item.lower_limit_amount = this.splitThousandNumberKite(item.lower_limit_amount);
          if (!this.checkEmpty(item.position_amounts)) {
            item.position_amounts.forEach(position => {
              position.lower_limit_amount = this.splitThousandNumberKite(position.lower_limit_amount);
              position.upper_limit_amount = this.splitThousandNumberKite(position.upper_limit_amount);
            });
          }
        });
      }
      this.ticket_costs_official = costTicketsOfficial;

      let costTicketsCommonOfficial = res.ticket_cost_common;
      if (!this.checkEmpty(costTicketsCommonOfficial)) {
        costTicketsCommonOfficial.upper_limit_amount = this.splitThousandNumberKite(
          costTicketsCommonOfficial.upper_limit_amount,
        );
        costTicketsCommonOfficial.lower_limit_amount = this.splitThousandNumberKite(
          costTicketsCommonOfficial.lower_limit_amount,
        );
        if (!this.checkEmpty(costTicketsCommonOfficial.position_amounts)) {
          costTicketsCommonOfficial.position_amounts.forEach(item => {
            item.lower_limit_amount = this.splitThousandNumberKite(item.lower_limit_amount);
            item.upper_limit_amount = this.splitThousandNumberKite(item.upper_limit_amount);
          });
        }
      }
      this.ticket_cost_common_official = costTicketsCommonOfficial;

      let totalExpenseDirectionCommonOfficial = regulationData.total_expense_direction_common;
      if (!this.checkEmpty(totalExpenseDirectionCommonOfficial)) {
        totalExpenseDirectionCommonOfficial.lower_limit_amount = this.splitThousandNumberKite(
          totalExpenseDirectionCommonOfficial.lower_limit_amount,
        );
        totalExpenseDirectionCommonOfficial.upper_limit_amount = this.splitThousandNumberKite(
          totalExpenseDirectionCommonOfficial.upper_limit_amount,
        );
        if (!this.checkEmpty(totalExpenseDirectionCommonOfficial.position_amounts)) {
          totalExpenseDirectionCommonOfficial.position_amounts.forEach(position => {
            position.lower_limit_amount = this.splitThousandNumberKite(position.lower_limit_amount);
            position.upper_limit_amount = this.splitThousandNumberKite(position.upper_limit_amount);
          });
        }
      }
      this.total_expense_direction_common_official = totalExpenseDirectionCommonOfficial;

      this.transit_caution_list_official = regulationData.transit_caution_list;
      let transitTimeListOfficial = res.transit_time_list;
      if (!this.checkEmpty(transitTimeListOfficial)) {
        transitTimeListOfficial.forEach(item => {
          item.lower_limit_time = this.splitThousandNumberKite(item.lower_limit_time);
          item.upper_limit_time = this.splitThousandNumberKite(item.upper_limit_time);
        });
      }
      this.transit_time_list_official = transitTimeListOfficial;
    },
    initDataErrors() {
      if (!this.checkEmpty(this.ticket_cost_common.position_amounts)) {
        this.ticket_cost_common.position_amounts.forEach(item => {
          item.errors = {};
        });
      }
      if (!this.checkEmpty(this.ticket_cost_common_official.position_amounts)) {
        this.ticket_cost_common_official.position_amounts.forEach(item => {
          item.errors = {};
        });
      }
      this.passports.forEach(passports => {
        passports.errors = {
          passports_countries: '',
          remain_days: '',
        };
      });

      this.passports_official.forEach(passports => {
        passports.errors = {
          passports_countries: '',
          remain_days: '',
        };
      });

      this.transit_caution_list.forEach(transit => {
        transit.errors = {
          airport_id: '',
          transit_caution_departure_airline: '',
          transit_caution_arrival_airline: '',
        };
      });

      this.transit_caution_list_official.forEach(transit => {
        transit.errors = {
          airport_id: '',
          transit_caution_departure_airline: '',
          transit_caution_arrival_airline: '',
        };
      });

      this.ticket_costs.forEach(ticket => {
        ticket.errors = {
          area_id: '',
          lower_limit_amount: '',
          upper_limit_amount: '',
        };

        ticket.position_amounts.forEach(item => {
          item.errors = {
            lower_limit_amount: '',
            upper_limit_amount: '',
          };
        });
      });

      this.ticket_costs_official.forEach(ticket => {
        ticket.errors = {
          area_id: '',
          lower_limit_amount: '',
          upper_limit_amount: '',
        };

        ticket.position_amounts.forEach(item => {
          item.errors = {
            lower_limit_amount: '',
            upper_limit_amount: '',
          };
        });
      });

      this.hotel_directions.forEach(hotel => {
        hotel.errors = {
          area_id: '',
          lower_limit_amount: '',
          upper_limit_amount: '',
        };

        hotel.position_amounts.forEach(item => {
          item.errors = {
            lower_limit_amount: '',
            upper_limit_amount: '',
          };
        });
      });

      this.hotel_directions_official.forEach(hotel => {
        hotel.errors = {
          area_id: '',
          lower_limit_amount: '',
          upper_limit_amount: '',
        };

        hotel.position_amounts.forEach(item => {
          item.errors = {
            lower_limit_amount: '',
            upper_limit_amount: '',
          };
        });
      });

      this.total_expense_directions.forEach(total => {
        total.errors = {
          area_id: '',
          lower_limit_amount: '',
          upper_limit_amount: '',
        };

        total.position_amounts.forEach(item => {
          item.errors = {
            lower_limit_amount: '',
            upper_limit_amount: '',
          };
        });
      });

      this.total_expense_directions_official.forEach(total => {
        total.errors = {
          area_id: '',
          lower_limit_amount: '',
          upper_limit_amount: '',
        };

        total.position_amounts.forEach(item => {
          item.errors = {
            lower_limit_amount: '',
            upper_limit_amount: '',
          };
        });
      });

      this.banned_flight_section_list.forEach(item => {
        item.errors = {
          departure_airport_id: '',
          arrival_airport_id: '',
        };
      });

      this.banned_flight_section_list_official.forEach(item => {
        item.errors = {
          departure_airport_id: '',
          arrival_airport_id: '',
        };
      });

      this.attention_flight_section_list.forEach(item => {
        item.errors = {
          departure_airport_id: '',
          arrival_airport_id: '',
        };
      });

      this.attention_flight_section_list_official.forEach(item => {
        item.errors = {
          departure_airport_id: '',
          arrival_airport_id: '',
        };
      });

      this.transit_time_list.forEach(item => {
        item.errors = {
          airport_id: '',
          lower_limit_time: '',
          upper_limit_time: '',
        };
      });

      this.transit_time_list_official.forEach(item => {
        item.errors = {
          airport_id: '',
          lower_limit_time: '',
          upper_limit_time: '',
        };
      });

      if (this.checkEmpty(this.ticket_cost_common)) {
        this.ticket_cost_common = this.cloneObject(this.defaultCommon);
      }

      this.ticket_cost_common.errors = {
        lower_limit_amount: '',
        upper_limit_amount: '',
      };

      if (this.checkEmpty(this.ticket_cost_common_official)) {
        this.ticket_cost_common_official = this.cloneObject(this.defaultCommon);
      }

      this.ticket_cost_common_official.errors = {
        lower_limit_amount: '',
        upper_limit_amount: '',
      };

      if (this.checkEmpty(this.hotel_direction_common)) {
        this.hotel_direction_common = this.cloneObject(this.defaultCommon);
      }

      this.hotel_direction_common.errors = {
        lower_limit_amount: '',
        upper_limit_amount: '',
      };

      if (this.checkEmpty(this.hotel_direction_common_official)) {
        this.hotel_direction_common_official = this.cloneObject(this.defaultCommon);
      }

      this.hotel_direction_common_official.errors = {
        lower_limit_amount: '',
        upper_limit_amount: '',
      };

      if (this.checkEmpty(this.total_expense_direction_common)) {
        this.total_expense_direction_common = this.cloneObject(this.defaultCommon);
      }

      this.total_expense_direction_common.errors = {
        lower_limit_amount: '',
        upper_limit_amount: '',
      };

      if (this.checkEmpty(this.total_expense_direction_common_official)) {
        this.total_expense_direction_common_official = this.cloneObject(this.defaultCommon);
      }

      this.total_expense_direction_common_official.errors = {
        lower_limit_amount: '',
        upper_limit_amount: '',
      };
    },

    //Convert Data Amount to String have ',' , use for data have 2 index inside
    convertNestedDataAmount(dataName, parentIndex, positionIndex, isUpdateLowerAmount = true) {
      let data = '';
      //Number of child level object we need to access
      const childName = dataName.split('.');
      data = this.cloneObject(
        this[`${childName[0]}`][parentIndex][`${childName[1]}`][positionIndex][`${childName.pop()}`],
      );

      if (this.checkEmpty(data)) return;
      if (String(data).includes('.') || String(data).includes('．')) {
        if (String(data).includes('．')) {
          data = data.replaceAll('．', '.');
        }
        //Return a string contain ',' split
        const returnStr =
          data == 0
            ? 0
            : String(parseFloat(data).toFixed(2))
                .replace(/^(?!00[^0])0/, '')
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        isUpdateLowerAmount
          ? (this[`${childName[0]}`][parentIndex].position_amounts[positionIndex].lower_limit_amount = returnStr)
          : (this[`${childName[0]}`][parentIndex].position_amounts[positionIndex].upper_limit_amount = returnStr);
      } else {
        data = data.replaceAll(',', '');
        //Return a string contain ',' split
        const returnStr =
          data == 0
            ? 0
            : data
                .replace(/(\..*?)\..*/g, '$1')
                .replace(/^(?!00[^0])0/, '')
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        isUpdateLowerAmount
          ? (this[`${childName[0]}`][parentIndex].position_amounts[positionIndex].lower_limit_amount = returnStr)
          : (this[`${childName[0]}`][parentIndex].position_amounts[positionIndex].upper_limit_amount = returnStr);
      }
    },
    convertNumberFullToHalf(number) {
      number = number.replace(/[^0-9０-９]/g, '');
      number = String(number).replace(/[\uFF10-\uFF19]/g, function (m) {
        return String.fromCharCode(m.charCodeAt(0) - 0xfee0);
      });

      return this.isNumeric(number) ? parseInt(number) : 0;
    },
    convertNumberFullToHalfCustom(number) {
      number = number.replace(/[^0-9０-９]/g, '');
      number = String(number).replace(/[\uFF10-\uFF19]/g, function (m) {
        return String.fromCharCode(m.charCodeAt(0) - 0xfee0);
      });

      return this.isNumeric(number) ? parseInt(number) : '';
    },
    querySearch(queryString, cb) {
      var links = this.listAirport;
      var results = queryString ? links.filter(this.createFilter(queryString)) : links;
      cb(results);
    },
    createFilter(queryString) {
      return link => {
        return (
          link.name.toLowerCase().includes(queryString.toLowerCase()) ||
          link.code.toLowerCase().includes(queryString.toLowerCase())
        );
      };
    },
    closeModalUpdateAirPort() {
      this.showModalUpdateAirPort = false;
    },
    async getListCountry() {
      await this.$services.security.getListCountry(
        res => {
          this.listCountry = this.sortCountryByName(res.list);
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    async getListAirLines() {
      await this.$services.security.getListAirLines(
        res => {
          this.listAirline = res.list;
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    async getListPosition(showEndLoading = false) {
      await this.$services.position.getListPosition(
        res => {
          this.listPosition = res.list;

          if (!this.checkEmpty(this.listPosition)) {
            this.listPosition.forEach(position => {
              this.ticket_costs.forEach(item => {
                if (this.checkEmpty(item.position_amounts.find(pa => pa.position_id == position.id))) {
                  let ps = {
                    position_id: position.id,
                    lower_limit_amount: '',
                    upper_limit_amount: '',
                    errors: {
                      lower_limit_amount: '',
                      upper_limit_amount: '',
                    },
                  };
                  item.position_amounts.push(ps);
                }
              });

              this.ticket_costs_official.forEach(item => {
                if (this.checkEmpty(item.position_amounts.find(pa => pa.position_id == position.id))) {
                  let ps = {
                    position_id: position.id,
                    lower_limit_amount: '',
                    upper_limit_amount: '',
                    errors: {
                      lower_limit_amount: '',
                      upper_limit_amount: '',
                    },
                  };
                  item.position_amounts.push(ps);
                }
              });

              this.hotel_directions.forEach(item => {
                if (this.checkEmpty(item.position_amounts.find(pa => pa.position_id == position.id))) {
                  let ps = {
                    position_id: position.id,
                    lower_limit_amount: '',
                    upper_limit_amount: '',
                    errors: {
                      lower_limit_amount: '',
                      upper_limit_amount: '',
                    },
                  };
                  item.position_amounts.push(ps);
                }
              });

              this.hotel_directions_official.forEach(item => {
                if (this.checkEmpty(item.position_amounts.find(pa => pa.position_id == position.id))) {
                  let ps = {
                    position_id: position.id,
                    lower_limit_amount: '',
                    upper_limit_amount: '',
                    errors: {
                      lower_limit_amount: '',
                      upper_limit_amount: '',
                    },
                  };
                  item.position_amounts.push(ps);
                }
              });

              this.total_expense_directions.forEach(item => {
                if (this.checkEmpty(item.position_amounts.find(pa => pa.position_id == position.id))) {
                  let ps = {
                    position_id: position.id,
                    lower_limit_amount: '',
                    upper_limit_amount: '',
                    errors: {
                      lower_limit_amount: '',
                      upper_limit_amount: '',
                    },
                  };
                  item.position_amounts.push(ps);
                }
              });

              this.total_expense_directions_official.forEach(item => {
                if (this.checkEmpty(item.position_amounts.find(pa => pa.position_id == position.id))) {
                  let ps = {
                    position_id: position.id,
                    lower_limit_amount: '',
                    upper_limit_amount: '',
                    errors: {
                      lower_limit_amount: '',
                      upper_limit_amount: '',
                    },
                  };
                  item.position_amounts.push(ps);
                }
              });
            });

            // if (this.checkEmpty(this.ticket_cost_common)) {
            //   this.ticket_cost_common = this.cloneObject(this.defaultCommon);
            // }

            // this.ticket_cost_common.errors = {
            //   lower_limit_amount: '',
            //   upper_limit_amount: '',
            // };

            // if (this.checkEmpty(this.ticket_cost_common_official)) {
            //   this.ticket_cost_common_official = this.cloneObject(this.defaultCommon);
            // }

            // this.ticket_cost_common_official.errors = {
            //   lower_limit_amount: '',
            //   upper_limit_amount: '',
            // };

            // if (this.checkEmpty(this.hotel_direction_common)) {
            //   this.hotel_direction_common = this.cloneObject(this.defaultCommon);
            // }

            // this.hotel_direction_common.errors = {
            //   lower_limit_amount: '',
            //   upper_limit_amount: '',
            // };

            // if (this.checkEmpty(this.hotel_direction_common_official)) {
            //   this.hotel_direction_common_official = this.cloneObject(this.defaultCommon);
            // }

            // this.hotel_direction_common_official.errors = {
            //   lower_limit_amount: '',
            //   upper_limit_amount: '',
            // };

            // if (this.checkEmpty(this.total_expense_direction_common)) {
            //   this.total_expense_direction_common = this.cloneObject(this.defaultCommon);
            // }

            // this.total_expense_direction_common.errors = {
            //   lower_limit_amount: '',
            //   upper_limit_amount: '',
            // };

            // if (this.checkEmpty(this.total_expense_direction_common_official)) {
            //   this.total_expense_direction_common_official = this.cloneObject(this.defaultCommon);
            // }

            // this.total_expense_direction_common_official.errors = {
            //   lower_limit_amount: '',
            //   upper_limit_amount: '',
            // };

            if (this.checkEmpty(this.domestic_flights.position_seat_class_types)) {
              if (!this.checkEmpty(this.listPosition)) {
                this.listPosition.forEach(position => {
                  let ps = {
                    position_id: position.id,
                    seat_class_types: [],
                  };
                  this.domestic_flights.position_seat_class_types.push(ps);
                });
              }
            } else {
              if (!this.checkEmpty(this.listPosition)) {
                this.listPosition.forEach(position => {
                  if (
                    this.checkEmpty(
                      this.domestic_flights.position_seat_class_types.find(pa => pa.position_id == position.id),
                    )
                  ) {
                    let ps = {
                      position_id: position.id,
                      seat_class_types: [],
                    };
                    this.domestic_flights.position_seat_class_types.push(ps);
                  }
                });
              }
            }

            if (this.checkEmpty(this.international_flights.position_seat_class_types)) {
              if (!this.checkEmpty(this.listPosition)) {
                this.listPosition.forEach(position => {
                  let ps = {
                    position_id: position.id,
                    seat_class_types: [],
                  };
                  this.international_flights.position_seat_class_types.push(ps);
                });
              }
            } else {
              if (!this.checkEmpty(this.listPosition)) {
                this.listPosition.forEach(position => {
                  if (
                    this.checkEmpty(
                      this.international_flights.position_seat_class_types.find(pa => pa.position_id == position.id),
                    )
                  ) {
                    let ps = {
                      position_id: position.id,
                      seat_class_types: [],
                    };
                    this.international_flights.position_seat_class_types.push(ps);
                  }
                });
              }
            }

            if (this.checkEmpty(this.flight_times.position_seat_class_types)) {
              if (!this.checkEmpty(this.listPosition)) {
                this.listPosition.forEach(position => {
                  let ps = {
                    position_id: position.id,
                    seat_class_types: [],
                  };
                  this.flight_times.position_seat_class_types.push(ps);
                });
              }
            } else {
              if (!this.checkEmpty(this.listPosition)) {
                this.listPosition.forEach(position => {
                  if (
                    this.checkEmpty(
                      this.flight_times.position_seat_class_types.find(pa => pa.position_id == position.id),
                    )
                  ) {
                    let ps = {
                      position_id: position.id,
                      seat_class_types: [],
                    };
                    this.flight_times.position_seat_class_types.push(ps);
                  }
                });
              }
            }

            if (this.checkEmpty(this.domestic_flights_official.position_seat_class_types)) {
              if (!this.checkEmpty(this.listPosition)) {
                this.listPosition.forEach(position => {
                  let ps = {
                    position_id: position.id,
                    seat_class_types: [],
                  };
                  this.domestic_flights_official.position_seat_class_types.push(ps);
                });
              }
            } else {
              if (!this.checkEmpty(this.listPosition)) {
                this.listPosition.forEach(position => {
                  if (
                    this.checkEmpty(
                      this.domestic_flights_official.position_seat_class_types.find(
                        pa => pa.position_id == position.id,
                      ),
                    )
                  ) {
                    let ps = {
                      position_id: position.id,
                      seat_class_types: [],
                    };
                    this.domestic_flights_official.position_seat_class_types.push(ps);
                  }
                });
              }
            }

            if (this.checkEmpty(this.international_flights_official.position_seat_class_types)) {
              if (!this.checkEmpty(this.listPosition)) {
                this.listPosition.forEach(position => {
                  let ps = {
                    position_id: position.id,
                    seat_class_types: [],
                  };
                  this.international_flights_official.position_seat_class_types.push(ps);
                });
              }
            } else {
              if (!this.checkEmpty(this.listPosition)) {
                this.listPosition.forEach(position => {
                  if (
                    this.checkEmpty(
                      this.international_flights_official.position_seat_class_types.find(
                        pa => pa.position_id == position.id,
                      ),
                    )
                  ) {
                    let ps = {
                      position_id: position.id,
                      seat_class_types: [],
                    };
                    this.international_flights_official.position_seat_class_types.push(ps);
                  }
                });
              }
            }

            if (this.checkEmpty(this.flight_times_official.position_seat_class_types)) {
              if (!this.checkEmpty(this.listPosition)) {
                this.listPosition.forEach(position => {
                  let ps = {
                    position_id: position.id,
                    seat_class_types: [],
                  };
                  this.flight_times_official.position_seat_class_types.push(ps);
                });
              }
            } else {
              if (!this.checkEmpty(this.listPosition)) {
                this.listPosition.forEach(position => {
                  if (
                    this.checkEmpty(
                      this.flight_times_official.position_seat_class_types.find(
                        pa => pa.position_id == position.id,
                      ),
                    )
                  ) {
                    let ps = {
                      position_id: position.id,
                      seat_class_types: [],
                    };
                    this.flight_times_official.position_seat_class_types.push(ps);
                  }
                });
              }
            }

            if (this.checkEmpty(this.ticket_cost_common.position_amounts)) {
              if (!this.checkEmpty(this.listPosition)) {
                this.listPosition.forEach(position => {
                  let ps = {
                    position_id: position.id,
                    lower_limit_amount: '',
                    upper_limit_amount: '',
                  };
                  this.ticket_cost_common.position_amounts.push(ps);
                });
              }
            } else {
              if (!this.checkEmpty(this.listPosition)) {
                this.listPosition.forEach(position => {
                  if (
                    this.checkEmpty(this.ticket_cost_common.position_amounts.find(pa => pa.position_id == position.id))
                  ) {
                    let ps = {
                      position_id: position.id,
                      lower_limit_amount: '',
                      upper_limit_amount: '',
                    };
                    this.ticket_cost_common.position_amounts.push(ps);
                  }
                });
              }
            }

            if (this.checkEmpty(this.ticket_cost_common_official.position_amounts)) {
              if (!this.checkEmpty(this.listPosition)) {
                this.listPosition.forEach(position => {
                  let ps = {
                    position_id: position.id,
                    lower_limit_amount: '',
                    upper_limit_amount: '',
                  };
                  this.ticket_cost_common_official.position_amounts.push(ps);
                });
              }
            } else {
              if (!this.checkEmpty(this.listPosition)) {
                this.listPosition.forEach(position => {
                  if (
                    this.checkEmpty(
                      this.ticket_cost_common_official.position_amounts.find(pa => pa.position_id == position.id),
                    )
                  ) {
                    let ps = {
                      position_id: position.id,
                      lower_limit_amount: '',
                      upper_limit_amount: '',
                    };
                    this.ticket_cost_common_official.position_amounts.push(ps);
                  }
                });
              }
            }

            if (this.checkEmpty(this.hotel_direction_common.position_amounts)) {
              if (!this.checkEmpty(this.listPosition)) {
                this.listPosition.forEach(position => {
                  let ps = {
                    position_id: position.id,
                    lower_limit_amount: '',
                    upper_limit_amount: '',
                  };
                  this.hotel_direction_common.position_amounts.push(ps);
                });
              }
            } else {
              if (!this.checkEmpty(this.listPosition)) {
                this.listPosition.forEach(position => {
                  if (
                    this.checkEmpty(
                      this.hotel_direction_common.position_amounts.find(pa => pa.position_id == position.id),
                    )
                  ) {
                    let ps = {
                      position_id: position.id,
                      lower_limit_amount: '',
                      upper_limit_amount: '',
                    };
                    this.hotel_direction_common.position_amounts.push(ps);
                  }
                });
              }
            }

            if (this.checkEmpty(this.hotel_direction_common_official.position_amounts)) {
              if (!this.checkEmpty(this.listPosition)) {
                this.listPosition.forEach(position => {
                  let ps = {
                    position_id: position.id,
                    lower_limit_amount: '',
                    upper_limit_amount: '',
                  };
                  this.hotel_direction_common_official.position_amounts.push(ps);
                });
              }
            } else {
              if (!this.checkEmpty(this.listPosition)) {
                this.listPosition.forEach(position => {
                  if (
                    this.checkEmpty(
                      this.hotel_direction_common_official.position_amounts.find(pa => pa.position_id == position.id),
                    )
                  ) {
                    let ps = {
                      position_id: position.id,
                      lower_limit_amount: '',
                      upper_limit_amount: '',
                    };
                    this.hotel_direction_common_official.position_amounts.push(ps);
                  }
                });
              }
            }

            if (this.checkEmpty(this.total_expense_direction_common.position_amounts)) {
              if (!this.checkEmpty(this.listPosition)) {
                this.listPosition.forEach(position => {
                  let ps = {
                    position_id: position.id,
                    lower_limit_amount: '',
                    upper_limit_amount: '',
                  };
                  this.total_expense_direction_common.position_amounts.push(ps);
                });
              }
            } else {
              if (!this.checkEmpty(this.listPosition)) {
                this.listPosition.forEach(position => {
                  if (
                    this.checkEmpty(
                      this.total_expense_direction_common.position_amounts.find(pa => pa.position_id == position.id),
                    )
                  ) {
                    let ps = {
                      position_id: position.id,
                      lower_limit_amount: '',
                      upper_limit_amount: '',
                    };
                    this.total_expense_direction_common.position_amounts.push(ps);
                  }
                });
              }
            }

            if (this.checkEmpty(this.total_expense_direction_common_official.position_amounts)) {
              if (!this.checkEmpty(this.listPosition)) {
                this.listPosition.forEach(position => {
                  let ps = {
                    position_id: position.id,
                    lower_limit_amount: '',
                    upper_limit_amount: '',
                  };
                  this.total_expense_direction_common_official.position_amounts.push(ps);
                });
              }
            } else {
              if (!this.checkEmpty(this.listPosition)) {
                this.listPosition.forEach(position => {
                  if (
                    this.checkEmpty(
                      this.total_expense_direction_common_official.position_amounts.find(
                        pa => pa.position_id == position.id,
                      ),
                    )
                  ) {
                    let ps = {
                      position_id: position.id,
                      lower_limit_amount: '',
                      upper_limit_amount: '',
                    };
                    this.total_expense_direction_common_official.position_amounts.push(ps);
                  }
                });
              }
            }

            this.ticket_cost_common.position_amounts.forEach(position => {
              position.errors = {
                lower_limit_amount: '',
                upper_limit_amount: '',
              };
            });

            this.ticket_cost_common_official.position_amounts.forEach(position => {
              position.errors = {
                lower_limit_amount: '',
                upper_limit_amount: '',
              };
            });

            this.hotel_direction_common.position_amounts.forEach(position => {
              position.errors = {
                lower_limit_amount: '',
                upper_limit_amount: '',
              };
            });

            this.hotel_direction_common_official.position_amounts.forEach(position => {
              position.errors = {
                lower_limit_amount: '',
                upper_limit_amount: '',
              };
            });

            this.total_expense_direction_common.position_amounts.forEach(position => {
              position.errors = {
                lower_limit_amount: '',
                upper_limit_amount: '',
              };
            });

            this.total_expense_direction_common_official.position_amounts.forEach(position => {
              position.errors = {
                lower_limit_amount: '',
                upper_limit_amount: '',
              };
            });
          }
          showEndLoading && this.endLoading();
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    async getListArea() {
      await this.$services.regulation.getListArea(
        res => {
          this.listArea = res.list;
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    async getListAirport() {
      await this.$services.regulation.getListAirport(
        res => {
          this.listAirport = res.list;
          let listDataAirport = this.cloneObject(res.list);
          this.listAirport.map(item => {
            item.name = this.labelLocale(item);
            item.value = this.labelLocale(item) + '（' + item.code + '）';
            return item;
          });

          this.banned_flight_section_list = this.banned_flight_section_list.map(item => {
            let departureAirport = this.listAirport.find(airport => airport.id == item.departure_airport_id);
            let arrivalAirport = this.listAirport.find(airport => airport.id == item.arrival_airport_id);
            item.departure_airport = this.labelLocale(departureAirport);
            item.arrival_airport = this.labelLocale(arrivalAirport);

            return item;
          });

          this.banned_flight_section_list_official = this.banned_flight_section_list_official.map(item => {
            let departureAirport = listDataAirport.find(airport => airport.id == item.departure_airport_id);
            let arrivalAirport = listDataAirport.find(airport => airport.id == item.arrival_airport_id);
            item.departure_airport = this.labelLocale(departureAirport);
            item.arrival_airport = this.labelLocale(arrivalAirport);

            return item;
          });

          this.attention_flight_section_list = this.attention_flight_section_list.map(item => {
            let departureAirport = this.listAirport.find(airport => airport.id == item.departure_airport_id);
            let arrivalAirport = this.listAirport.find(airport => airport.id == item.arrival_airport_id);
            item.departure_airport = this.labelLocale(departureAirport);
            item.arrival_airport = this.labelLocale(arrivalAirport);

            return item;
          });

          this.attention_flight_section_list_official = this.attention_flight_section_list_official.map(item => {
            let departureAirport = listDataAirport.find(airport => airport.id == item.departure_airport_id);
            let arrivalAirport = listDataAirport.find(airport => airport.id == item.arrival_airport_id);
            item.departure_airport = this.labelLocale(departureAirport);
            item.arrival_airport = this.labelLocale(arrivalAirport);

            return item;
          });

          this.transit_time_list = this.transit_time_list.map(item => {
            let airport = this.listAirport.find(airport => airport.id == item.airport_id);
            item.airport = this.labelLocale(airport);

            return item;
          });

          this.transit_time_list_official = this.transit_time_list_official.map(item => {
            let airport = listDataAirport.find(airport => airport.id == item.airport_id);
            item.airport = this.labelLocale(airport);

            return item;
          });

          this.transit_caution_list = this.transit_caution_list.map(item => {
            let airport = this.listAirport.find(airport => airport.id == item.airport_id);
            item.airport = this.labelLocale(airport);

            return item;
          });

          this.transit_caution_list_official = this.transit_caution_list_official.map(item => {
            let airport = listDataAirport.find(airport => airport.id == item.airport_id);
            item.airport = this.labelLocale(airport);

            return item;
          });
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    async getRegulation() {
      await this.$services.regulation.getRegulation(
        res => {
          if (!('list' in res)) {
            this.regulationDetail = res;
          }
        },
        err => {
          this.endLoading();
          this.notifyError(this.$t(err.error));
        },
      );
    },
    addNewItem(type) {
      switch (type) {
        case TYPE_PASSPORT:
          {
            let item = {
              passports_countries: [],
              remain_days: 0,
              errors: {
                passports_countries: '',
                remain_days: '',
              },
            };
            this.passports.push(item);
          }
          break;
        case TYPE_BANNED_FLIGHT:
          {
            let item = {
              departure_airport_id: '',
              arrival_airport_id: '',
              errors: {
                departure_airport_id: '',
                arrival_airport_id: '',
              },
              departure_airport: '',
              arrival_airport: '',
            };
            this.banned_flight_section_list.push(item);
          }
          break;
        case TYPE_ATTENTION_FLIGHT:
          {
            let item = {
              departure_airport_id: '',
              arrival_airport_id: '',
              errors: {
                departure_airport_id: '',
                arrival_airport_id: '',
              },
              departure_airport: '',
              arrival_airport: '',
            };
            this.attention_flight_section_list.push(item);
          }
          break;
        case TYPE_TRANSIT_TIME:
          {
            let item = {
              airport_id: '',
              lower_limit_time: '',
              upper_limit_time: '',
              errors: {
                airport_id: '',
                lower_limit_time: '',
                upper_limit_time: '',
              },
              airport: '',
            };
            this.transit_time_list.push(item);
          }
          break;
        case TYPE_TRANSIT_CAUTION:
          {
            let item = {
              transit_caution_departure_airline: [],
              transit_caution_arrival_airline: [],
              airport_id: '',
              errors: {
                airport_id: '',
                transit_caution_departure_airline: '',
                transit_caution_arrival_airline: '',
              },
              airport: '',
            };
            this.transit_caution_list.push(item);
          }
          break;
        case TYPE_AMOUNT_MONEY:
          {
            let ps = [];
            this.listPosition.forEach(position => {
              let item = {
                position_id: position.id,
                lower_limit_amount: '',
                upper_limit_amount: '',
                errors: {
                  lower_limit_amount: '',
                  upper_limit_amount: '',
                },
              };
              ps.push(item);
            });
            let item = {
              area_id: [],
              is_depend_position: false,
              lower_limit_amount: '',
              upper_limit_amount: '',
              errors: {
                area_id: '',
                lower_limit_amount: '',
                upper_limit_amount: '',
              },
              position_amounts: ps,
            };
            this.ticket_costs.push(item);
          }
          break;
        case TYPE_HOTEL:
          {
            let ps = [];
            this.listPosition.forEach(position => {
              let item = {
                position_id: position.id,
                lower_limit_amount: '',
                upper_limit_amount: '',
                errors: {
                  lower_limit_amount: '',
                  upper_limit_amount: '',
                },
              };
              ps.push(item);
            });
            let item = {
              area_id: [],
              is_depend_position: false,
              lower_limit_amount: '',
              upper_limit_amount: '',
              errors: {
                area_id: '',
                lower_limit_amount: '',
                upper_limit_amount: '',
              },
              position_amounts: ps,
            };
            this.hotel_directions.push(item);
          }
          break;
        case TYPE_TOTAL_TRAVEL_EXPENSES:
          {
            let ps = [];
            this.listPosition.forEach(position => {
              let item = {
                position_id: position.id,
                lower_limit_amount: '',
                upper_limit_amount: '',
                errors: {
                  lower_limit_amount: '',
                  upper_limit_amount: '',
                },
              };
              ps.push(item);
            });
            let item = {
              area_id: [],
              is_depend_position: false,
              lower_limit_amount: '',
              upper_limit_amount: '',
              errors: {
                area_id: '',
                lower_limit_amount: '',
                upper_limit_amount: '',
              },
              position_amounts: ps,
            };
            this.total_expense_directions.push(item);
          }
          break;
      }
    },
    confirmUploadFile(listFiles) {
      if (!this.checkEmpty(listFiles.length)) {
        listFiles.forEach(file => {
          let fileExits = this.files.find(item => item.file_name == file.name || item.name == file.name);
          if (this.checkEmpty(fileExits)) {
            this.files.push(file);
          } else {
            this.notifyError(this.$t('common.invalid_file'));
          }
        });
        this.showModalUploadFile = false;
      } else {
        this.notifyError(this.$t('E-CM-101', { item: this.$t('common.file') }));
      }
    },
    deleteFile(item) {
      if (!this.checkEmpty(item.name)) {
        this.files = this.files.filter(file => file.name != item.name);
      } else {
        this.files = this.files.filter(file => file.file_name != item.file_name);
      }
    },
    deleteItem(type, index) {
      this.typeDelete = type;
      this.indexDelete = index;
      this.showBoxConfirm = true;
    },
    confirmDelete() {
      switch (this.typeDelete) {
        case TYPE_PASSPORT:
          this.passports.splice(this.indexDelete, 1);
          this.showBoxConfirm = false;
          break;
        case TYPE_BANNED_FLIGHT:
          this.banned_flight_section_list.splice(this.indexDelete, 1);
          this.showBoxConfirm = false;
          break;
        case TYPE_ATTENTION_FLIGHT:
          this.attention_flight_section_list.splice(this.indexDelete, 1);
          this.showBoxConfirm = false;
          break;
        case TYPE_TRANSIT_TIME:
          this.transit_time_list.splice(this.indexDelete, 1);
          this.showBoxConfirm = false;
          break;
        case TYPE_TRANSIT_CAUTION:
          this.transit_caution_list.splice(this.indexDelete, 1);
          this.showBoxConfirm = false;
          break;
        case TYPE_AMOUNT_MONEY:
          this.ticket_costs.splice(this.indexDelete, 1);
          this.showBoxConfirm = false;
          break;
        case TYPE_HOTEL:
          this.hotel_directions.splice(this.indexDelete, 1);
          this.showBoxConfirm = false;
          break;
        case TYPE_TOTAL_TRAVEL_EXPENSES:
          this.total_expense_directions.splice(this.indexDelete, 1);
          this.showBoxConfirm = false;
          break;
      }
    },
    getNameOfAirport(listIdAirport) {
      let result = [];
      if (!this.checkEmpty(listIdAirport)) {
        this.listAirport.forEach(airport => {
          listIdAirport.forEach(item => {
            if (item == airport.id) {
              result.push(this.labelLocale(airport));
            }
          });
        });
      }
      return result.join(this.$t('common.comma'));
    },
    updateNoTransitAirportList(listAirportSelected) {
      this.no_transit_airport_list = [];
      listAirportSelected.forEach(airport => {
        this.no_transit_airport_list.push(airport.id);
      });
      this.showModalUpdateAirPort = false;
    },
    getPositionName(positionId) {
      if (!this.checkEmpty(positionId)) {
        let position = this.listPosition.find(ps => ps.id == positionId);
        return !this.checkEmpty(position) ? position.management_title : '';
      }
    },
    getAirportName(airportId) {
      if (!this.checkEmpty(airportId)) {
        let airport = this.listAirport.find(ap => ap.id == airportId);
        return this.labelLocale(airport);
      }
    },
    listenUpdatePassportsCountry(listCountrySelected, index) {
      if (!this.checkEmpty(this.passports[index])) {
        this.passports[index].passports_countries.splice(0);
      }
      listCountrySelected.forEach(id => {
        this.passports[index].passports_countries.push(id);
      });
    },
    listenAirlineSelected(listAirlineSelected) {
      if (!this.checkEmpty(this.banned_airline_list)) {
        this.banned_airline_list.splice(0);
      }
      listAirlineSelected.forEach(id => {
        this.banned_airline_list.push(id);
      });
    },
    listenDepartureAirlineSelected(listAirlineSelected, index) {
      if (!this.checkEmpty(this.transit_caution_list[index])) {
        this.transit_caution_list[index].transit_caution_departure_airline.splice(0);
      }
      listAirlineSelected.forEach(id => {
        this.transit_caution_list[index].transit_caution_departure_airline.push(id);
      });
    },
    listenArrivalAirlineSelected(listAirlineSelected, index) {
      if (!this.checkEmpty(this.transit_caution_list[index])) {
        this.transit_caution_list[index].transit_caution_arrival_airline.splice(0);
      }
      listAirlineSelected.forEach(id => {
        this.transit_caution_list[index].transit_caution_arrival_airline.push(id);
      });
    },
    listenDomesticFlightSelected(listFlightSelected) {
      if (!this.checkEmpty(this.domestic_flights.seat_class_types)) {
        this.domestic_flights.seat_class_types.splice(0);
      }
      listFlightSelected.forEach(item => {
        this.domestic_flights.seat_class_types.push(item);
      });
    },
    listenPositionDomesticFlightSelected(listFlightSelected, index) {
      if (!this.checkEmpty(this.domestic_flights.position_seat_class_types[index])) {
        this.domestic_flights.position_seat_class_types[index].seat_class_types.splice(0);
      }
      listFlightSelected.forEach(item => {
        this.domestic_flights.position_seat_class_types[index].seat_class_types.push(item);
      });
    },
    listenInternationalFlightSelected(listFlightSelected) {
      if (!this.checkEmpty(this.international_flights.seat_class_types)) {
        this.international_flights.seat_class_types.splice(0);
      }
      listFlightSelected.forEach(item => {
        this.international_flights.seat_class_types.push(item);
      });
    },
    listenPositionInternationalFlightSelected(listFlightSelected, index) {
      if (!this.checkEmpty(this.international_flights.position_seat_class_types[index])) {
        this.international_flights.position_seat_class_types[index].seat_class_types.splice(0);
      }
      listFlightSelected.forEach(item => {
        this.international_flights.position_seat_class_types[index].seat_class_types.push(item);
      });
    },
    listenFlightTimesSelected(listFlightSelected) {
      if (!this.checkEmpty(this.flight_times.seat_class_types)) {
        this.flight_times.seat_class_types.splice(0);
      }
      listFlightSelected.forEach(item => {
        this.flight_times.seat_class_types.push(item);
      });
    },
    listenPositionFlightTimesSelected(listFlightSelected, index) {
      if (!this.checkEmpty(this.flight_times.position_seat_class_types[index])) {
        this.flight_times.position_seat_class_types[index].seat_class_types.splice(0);
      }
      listFlightSelected.forEach(item => {
        this.flight_times.position_seat_class_types[index].seat_class_types.push(item);
      });
    },
    listenUpdateTicketCostArea(listAreaSelected, index) {
      if (!this.checkEmpty(this.ticket_costs[index])) {
        this.ticket_costs[index].area_id.splice(0);
      }
      listAreaSelected.forEach(item => {
        this.ticket_costs[index].area_id.push(item);
      });
    },
    listenUpdateHotelArea(listAreaSelected, index) {
      if (!this.checkEmpty(this.hotel_directions[index])) {
        this.hotel_directions[index].area_id.splice(0);
      }
      listAreaSelected.forEach(item => {
        this.hotel_directions[index].area_id.push(item);
      });
    },
    listenUpdateTotalArea(listAreaSelected, index) {
      if (!this.checkEmpty(this.total_expense_directions[index])) {
        this.total_expense_directions[index].area_id.splice(0);
      }
      listAreaSelected.forEach(item => {
        this.total_expense_directions[index].area_id.push(item);
      });
    },
    validate() {
      // this.logDataChanged();
      // this.startLoading();
      let flgValidate = true;
      let curElementIdErr = '';
      // validate number_of_days
      if (!this.checkEmpty(this.number_of_days)) {
      //   flgValidate = false;
      //   //Set auto scroll error element ID
      //   curElementIdErr = 'number_of_days_field';
      //   this.errors.number_of_days = this.$t('E-CM-100', {
      //     item: this.$t('regulation.business_trip_day'),
      //   });
      // } else {
        if (!this.isNumeric(this.number_of_days)) {
          flgValidate = false;
          this.errors.number_of_days = this.$t('E-CM-047', {
            item: this.$t('regulation.business_trip_day'),
          });
        } else if (this.strLen(this.number_of_days) > 10) {
          flgValidate = false;
          //Set auto scroll error element ID
          if (this.checkEmpty(curElementIdErr)) {
            curElementIdErr = 'number_of_days_field';
          }
          this.errors.number_of_days = this.$t('E-CM-111', { size: 10 });
        } else {
          this.errors.number_of_days = '';
        }
      }

      // validate distance
      if (this.is_distance) {
        const distanceValue = this.convertToIntegerKite(this.distance);
        if (this.checkEmpty(distanceValue)) {
          flgValidate = false;
          //Set auto scroll error element ID
          if (this.checkEmpty(curElementIdErr)) {
            curElementIdErr = 'distance_field';
          }
          this.errors.distance = this.$t('E-CM-004');
        } else {
          if (!this.isNumeric(distanceValue)) {
            flgValidate = false;
            //Set auto scroll error element ID
            if (this.checkEmpty(curElementIdErr)) {
              curElementIdErr = 'distance_field';
            }
            this.errors.distance = this.$t('E-CM-047', {
              item: this.$t('regulation.the_distance_to_destination'),
            });
          } else if (this.strLen(String(distanceValue)) > 10) {
            flgValidate = false;
            //Set auto scroll error element ID
            if (this.checkEmpty(curElementIdErr)) {
              curElementIdErr = 'distance_field';
            }
            this.errors.distance = this.$t('E-CM-111', { size: 10 });
          } else {
            this.errors.distance = '';
          }
        }
      } else {
        this.errors.distance = '';
      }

      //validate passports
      this.passports.forEach((passports, index) => {
        const remainDay = this.convertToIntegerKite(passports.remain_days);
        passports.errors.passports_countries = '';
        if (this.checkEmpty(passports.passports_countries) && index) {
          flgValidate = false;
          //Set auto scroll error element ID
          if (this.checkEmpty(curElementIdErr)) {
            curElementIdErr = `passport_field_${index}`;
          }
          passports.errors.passports_countries = this.$t('E-CM-004');
        } else {
          passports.errors.passports_countries = '';
        }

        let flgValidatePassport = false;
        this.passports.forEach((passportCheck, indexCheck) => {
          if (indexCheck != index) {
            // if (passports.passports_countries.length === 0) {
            //   flgValidatePassport = true;
            // } else if (passportCheck.passports_countries.length === 0) {
            //     flgValidatePassport = true;
            // } else {
              var commonElements = passports.passports_countries.filter(function(element) {
                return passportCheck.passports_countries.includes(element);
              });
              if (commonElements.length > 0) {
                flgValidatePassport = true;
              }
            // }
          }
          if (index && this.passports[0].passports_countries.length === 0) {
            this.passports[0].errors.passports_countries = this.$t('E-CU-009');
            flgValidatePassport = true;
          }
        });
        if (passports.errors.passports_countries == '') {
          if (flgValidatePassport) {
            flgValidate = false;
            passports.errors.passports_countries = this.$t('E-CU-009');
          } else {
            passports.errors.passports_countries = '';
          }
        }

        if (this.checkEmptyWithOutZero(remainDay)) {
          flgValidate = false;
          //Set auto scroll error element ID
          if (this.checkEmpty(curElementIdErr)) {
            curElementIdErr = `passport_field_${index}`;
          }
          passports.errors.remain_days = this.$t('E-CM-004');
        } else {
          if (!this.isNumeric(remainDay)) {
            flgValidate = false;
            //Set auto scroll error element ID
            if (this.checkEmpty(curElementIdErr)) {
              curElementIdErr = `passport_field_${index}`;
            }
            passports.errors.remain_days = this.$t('E-CM-047', {
              item: this.$t('regulation.day'),
            });
          } else if (this.strLen(String(remainDay)) > 10) {
            flgValidate = false;
            //Set auto scroll error element ID
            if (this.checkEmpty(curElementIdErr)) {
              curElementIdErr = `passport_field_${index}`;
            }
            passports.errors.remain_days = this.$t('E-CM-111', { size: 10 });
          } else {
            passports.errors.remain_days = '';
          }
        }
      });

      // validate common_passport_day_required
      if (!this.checkEmptyWithOutZero(this.common_passport_day_required)) {
      //   flgValidate = false;
      //   //Set auto scroll error element ID
      //   if (this.checkEmpty(curElementIdErr)) {
      //     curElementIdErr = `common_passport_day_field`;
      //   }
      //   this.errors.common_passport_day_required = this.$t('E-CM-004');
      // } else {
        let commonPassportDay = this.convertToIntegerKite(this.common_passport_day_required);
        if (!this.isNumeric(commonPassportDay)) {
          flgValidate = false;
          //Set auto scroll error element ID
          if (this.checkEmpty(curElementIdErr)) {
            curElementIdErr = `common_passport_day_field`;
          }
          this.errors.common_passport_day_required = this.$t('E-CM-047', {
            item: this.$t('regulation.day'),
          });
        } else if (this.strLen(String(commonPassportDay)) > 10) {
          flgValidate = false;
          //Set auto scroll error element ID
          if (this.checkEmpty(curElementIdErr)) {
            curElementIdErr = `common_passport_day_field`;
          }
          this.errors.common_passport_day_required = this.$t('E-CM-111', {
            size: 10,
          });
        } else {
          this.errors.common_passport_day_required = '';
        }
      } else if (this.is_check_remain_days){
        flgValidate = false;
        this.errors.common_passport_day_required = this.$t('E-CM-004');
      }

      // validate no_transit_airport_list
      // if (this.checkEmpty(this.no_transit_airport_list)) {
      //   flgValidate = false;
      //   this.errors.no_transit_airport_list = this.$t('E-CM-004');
      // } else {
      //   this.errors.no_transit_airport_list = '';
      // }

      // validate banned_airline_list
      // if (this.checkEmpty(this.banned_airline_list)) {
      //   flgValidate = false;
      //   this.errors.banned_airline_list = this.$t('E-CM-004');
      // } else {
      //   this.errors.banned_airline_list = '';
      // }

      // validate banned_flight_section
      this.banned_flight_section_list.forEach((item, index) => {
        // if (this.checkEmpty(item.departure_airport_id)) {
        //   flgValidate = false;
        //   item.errors.departure_airport_id = this.$t('E-CM-004');
        // } else {
        //   item.errors.departure_airport_id = '';
        // }

        // if (this.checkEmpty(item.arrival_airport_id)) {
        //   flgValidate = false;
        //   item.errors.arrival_airport_id = this.$t('E-CM-004');
        // } else {
        //   item.errors.arrival_airport_id = '';
        // }
        if (this.checkEmpty(item.departure_airport)) {
          flgValidate = false;
          item.errors.departure_airport_id = this.$t('E-CM-004');
          //Set auto scroll error element ID
          if (this.checkEmpty(curElementIdErr)) {
            curElementIdErr = `banned_flight_section_list_field_${index}`;
          }
        } else {
          item.errors.departure_airport_id = '';
        }

        if (this.checkEmpty(item.arrival_airport)) {
          flgValidate = false;
          item.errors.arrival_airport_id = this.$t('E-CM-004');
          //Set auto scroll error element ID
          if (this.checkEmpty(curElementIdErr)) {
            curElementIdErr = `banned_flight_section_list_field_${index}`;
          }
        } else {
          if (item.departure_airport == item.arrival_airport) {
            flgValidate = false;
            item.errors.arrival_airport_id = this.$t('E-CM-107', {
              item: item.arrival_airport,
            });
            //Set auto scroll error element ID
            if (this.checkEmpty(curElementIdErr)) {
              curElementIdErr = `banned_flight_section_list_field_${index}`;
            }
          } else {
            item.errors.arrival_airport_id = '';
          }
        }
      });

      // validate attention_flight_section_list
      this.attention_flight_section_list.forEach((item, index) => {
        // if (this.checkEmpty(item.departure_airport_id)) {
        //   flgValidate = false;
        //   item.errors.departure_airport_id = this.$t('E-CM-004');
        // } else {
        //   item.errors.departure_airport_id = '';
        // }

        // if (this.checkEmpty(item.arrival_airport_id)) {
        //   flgValidate = false;
        //   item.errors.arrival_airport_id = this.$t('E-CM-004');
        // } else {
        //   item.errors.arrival_airport_id = '';
        // }
        if (this.checkEmpty(item.departure_airport)) {
          flgValidate = false;
          item.errors.departure_airport_id = this.$t('E-CM-004');
          //Set auto scroll error element ID
          if (this.checkEmpty(curElementIdErr)) {
            curElementIdErr = `attention_flight_section_list_field_${index}`;
          }
        } else {
          item.errors.departure_airport_id = '';
        }

        if (this.checkEmpty(item.arrival_airport)) {
          flgValidate = false;
          item.errors.arrival_airport_id = this.$t('E-CM-004');
          //Set auto scroll error element ID
          if (this.checkEmpty(curElementIdErr)) {
            curElementIdErr = `attention_flight_section_list_field_${index}`;
          }
        } else {
          if (item.departure_airport == item.arrival_airport) {
            flgValidate = false;
            item.errors.arrival_airport_id = this.$t('E-CM-107', {
              item: item.arrival_airport,
            });
            //Set auto scroll error element ID
            if (this.checkEmpty(curElementIdErr)) {
              curElementIdErr = `attention_flight_section_list_field_${index}`;
            }
          } else {
            // item.errors.arrival_airport_id = '';
          }
        }
      });

      // validate transit_time_list
      this.transit_time_list.forEach((item, index) => {
        // if (this.checkEmpty(item.airport_id)) {
        //   flgValidate = false;
        //   item.errors.airport_id = this.$t('E-CM-004');
        // } else {
        //   item.errors.airport_id = '';
        // }
        if (this.checkEmpty(item.airport)) {
          flgValidate = false;
          item.errors.airport_id = this.$t('E-CM-004');
          //Set auto scroll error element ID
          if (this.checkEmpty(curElementIdErr)) {
            curElementIdErr = `transit_time_list_field_${index}`;
          }
        } else {
          item.errors.airport_id = '';
        }

        if (this.checkEmpty(item.lower_limit_time)) {
          flgValidate = false;
          item.errors.lower_limit_time = this.$t('E-CM-004');
          //Set auto scroll error element ID
          if (this.checkEmpty(curElementIdErr)) {
            curElementIdErr = `transit_time_list_field_${index}`;
          }
        } else {
          item.errors.lower_limit_time = '';
        }

        // if (this.checkEmpty(item.upper_limit_time)) {
        //   flgValidate = false;
        //   item.errors.upper_limit_time = this.$t('E-CM-004');
        // } else {
        //   item.errors.upper_limit_time = '';
        // }
      });

      // validate transit_caution_list
      this.transit_caution_list.forEach((transit, index) => {
        // if (this.checkEmpty(transit.airport_id)) {
        //   flgValidate = false;
        //   transit.errors.airport_id = this.$t('E-CM-004');
        // } else {
        //   transit.errors.airport_id = '';
        // }
        if (this.checkEmpty(transit.airport)) {
          flgValidate = false;
          transit.errors.airport_id = this.$t('E-CM-004');
          //Set auto scroll error element ID
          if (this.checkEmpty(curElementIdErr)) {
            curElementIdErr = `transit_caution_field_${index}`;
          }
        } else {
          transit.errors.airport_id = '';
        }

        if (this.checkEmpty(transit.transit_caution_departure_airline)) {
          flgValidate = false;
          transit.errors.transit_caution_departure_airline = this.$t('E-CM-004');
          if (this.checkEmpty(curElementIdErr)) {
            curElementIdErr = `transit_caution_field_${index}`;
          }
        } else {
          transit.errors.transit_caution_departure_airline = '';
        }

        if (this.checkEmpty(transit.transit_caution_arrival_airline)) {
          flgValidate = false;
          transit.errors.transit_caution_arrival_airline = this.$t('E-CM-004');
          //Set auto scroll error element ID
          if (this.checkEmpty(curElementIdErr)) {
            curElementIdErr = `transit_caution_field_${index}`;
          }
        } else {
          transit.errors.transit_caution_arrival_airline = '';
        }
      });

      // validate number_of_connection
      const numberOfConnections = this.convertToIntegerKite(this.number_of_connection);
      if (this.checkEmptyWithOutZero(numberOfConnections)) {
        // flgValidate = false;
        // this.errors.number_of_connection = this.$t('E-CM-100', {
        //   item: this.$t('regulation.number_of_connections'),
        // });
        // //Set auto scroll error element ID
        // if (this.checkEmpty(curElementIdErr)) {
        //   curElementIdErr = `number_of_connections_field`;
        // }
      } else {
        if (!this.isNumeric(numberOfConnections)) {
          flgValidate = false;
          this.errors.number_of_connection = this.$t('E-CM-047', {
            item: this.$t('regulation.number_of_connections'),
          });
          if (this.checkEmpty(curElementIdErr)) {
            curElementIdErr = `number_of_connections_field`;
          }
        } else if (this.strLen(String(numberOfConnections)) > 10) {
          flgValidate = false;
          this.errors.number_of_connection = this.$t('E-CM-111', { size: 10 });
          if (this.checkEmpty(curElementIdErr)) {
            curElementIdErr = `number_of_connections_field`;
          }
        } else {
          this.errors.number_of_connection = '';
        }
      }

      // validate domestic_flights
      if (!this.domestic_flights.is_depend_position) {
        this.domestic_flights.is_depend_position = false;
        // if (this.checkEmpty(this.domestic_flights.seat_class_types)) {
        //   flgValidate = false;
        //   this.domestic_flights.errors = this.$t('E-CM-004');
        //   if (this.checkEmpty(curElementIdErr)) {
        //     curElementIdErr = `domestic_flights_field`;
        //   }
        // } else {
          this.domestic_flights.errors = '';
        // }
      } else {
        this.domestic_flights.is_depend_position = true;
        // this.domestic_flights.position_seat_class_types.forEach((seat, index) => {
        //   if (this.checkEmpty(seat.seat_class_types)) {
        //     flgValidate = false;
        //     seat.errors = this.$t('E-CM-004');
        //     if (this.checkEmpty(curElementIdErr)) {
        //       curElementIdErr = `position_seat_class_types_field_${index}`;
        //     }
        //   } else {
        //     seat.errors = '';
        //   }
        // });
      }

      // validate international_flights
      if (!this.international_flights.is_depend_position) {
        this.international_flights.is_depend_position = false;
        if (this.checkEmpty(this.international_flights.seat_class_types)) {
        //   flgValidate = false;
        //   this.international_flights.errors = this.$t('E-CM-004');
        //   if (this.checkEmpty(curElementIdErr)) {
        //     curElementIdErr = `international_flights_field`;
        //   }
        // } else {
          this.international_flights.errors = '';
        }
      } else {
        // this.international_flights.is_depend_position = true;
        // this.international_flights.position_seat_class_types.forEach((seat, index) => {
        //   if (this.checkEmpty(seat.seat_class_types)) {
        //     flgValidate = false;
        //     seat.errors = this.$t('E-CM-004');
        //     if (this.checkEmpty(curElementIdErr)) {
        //       curElementIdErr = `international_position_seat_class_types_field_${index}`;
        //     }
        //   } else {
        //     seat.errors = '';
        //   }
        // });
      }


      // validate flight_times
      if (!this.flight_times.is_depend_position) {
        this.flight_times.is_depend_position = false;
        // if (this.checkEmpty(this.flight_times.seat_class_types)) {
        //   flgValidate = false;
        //   this.flight_times.errors = this.$t('E-CM-004');
        //   if (this.checkEmpty(curElementIdErr)) {
        //     curElementIdErr = `flight_times_field`;
        //   }
        // } else {
          this.flight_times.errors = '';
        // }
      } else {
        this.flight_times.is_depend_position = true;
        // this.flight_times.position_seat_class_types.forEach((seat, index) => {
        //   if (this.checkEmpty(seat.seat_class_types)) {
        //     flgValidate = false;
        //     seat.errors = this.$t('E-CM-004');
        //     if (this.checkEmpty(curElementIdErr)) {
        //       curElementIdErr = `international_position_seat_class_types_field_${index}`;
        //     }
        //   } else {
        //     seat.errors = '';
        //   }
        // });
      }

      // validate flight_time
      if (this.is_flight_time) {
        if (this.checkEmptyWithOutZero(this.flight_time)) {
          flgValidate = false;
          this.errors.flight_time = this.$t('E-CM-004');
          if (this.checkEmpty(curElementIdErr)) {
            curElementIdErr = `flight_time_field`;
          }
        } else {
          const flightTimeNum = this.convertToIntegerKite(this.flight_time);
          if (!this.isNumeric(flightTimeNum)) {
            flgValidate = false;
            this.errors.flight_time = this.$t('E-CM-047', {
              item: this.$t('regulation.flight_time'),
            });
            if (this.checkEmpty(curElementIdErr)) {
              curElementIdErr = `flight_time_field`;
            }
          } else if (this.strLen(String(flightTimeNum)) > 10) {
            flgValidate = false;
            this.errors.flight_time = this.$t('E-CM-111', { size: 10 });
            if (this.checkEmpty(curElementIdErr)) {
              curElementIdErr = `flight_time_field`;
            }
          } else {
            this.errors.flight_time = '';
          }
        }
      }

      // validate ticket_costs
      this.ticket_costs.forEach((ticket, index) => {
        const upperLimitAmount =
          ticket.upper_limit_amount == 0 ? ticket.upper_limit_amount : this.convertToIntegerKite(ticket.upper_limit_amount);
        const lowerLimitAmount =
          ticket.lower_limit_amount == 0 ? ticket.lower_limit_amount : this.convertToIntegerKite(ticket.lower_limit_amount);
        if (this.checkEmpty(ticket.area_id)) {
          flgValidate = false;
          ticket.errors.area_id = this.$t('E-CM-004');
          if (this.checkEmpty(curElementIdErr)) {
            curElementIdErr = `ticket_field_${index}`;
          }
        } else {
          ticket.errors.area_id = '';
        }
        if (!ticket.is_depend_position) {
          ticket.is_depend_position = false;
          if (!this.checkEmptyWithOutZero(lowerLimitAmount) && !this.isNumeric(lowerLimitAmount)) {
            flgValidate = false;
            ticket.errors.lower_limit_amount = this.$t('E-CM-047', {
              item: this.$t('regulation.from_the_circle'),
            });
            if (this.checkEmpty(curElementIdErr)) {
              curElementIdErr = `ticket_field_${index}`;
            }
          } else if (this.strLen(String(lowerLimitAmount)) > 10) {
            flgValidate = false;
            ticket.errors.lower_limit_amount = this.$t('E-CM-111', {
              size: 10,
            });
            if (this.checkEmpty(curElementIdErr)) {
              curElementIdErr = `ticket_field_${index}`;
            }
          } else {
            ticket.errors.lower_limit_amount = '';
          }

          if (!this.checkEmptyWithOutZero(upperLimitAmount) && !this.isNumeric(upperLimitAmount)) {
            flgValidate = false;
            ticket.errors.upper_limit_amount = this.$t('E-CM-047', {
              item: this.$t('regulation.up_to_the_yen'),
            });
            if (this.checkEmpty(curElementIdErr)) {
              curElementIdErr = `ticket_field_${index}`;
            }
          } else if (this.strLen(String(upperLimitAmount)) > 10) {
            flgValidate = false;
            ticket.errors.upper_limit_amount = this.$t('E-CM-111', {
              size: 10,
            });
            if (this.checkEmpty(curElementIdErr)) {
              curElementIdErr = `ticket_field_${index}`;
            }
          } else {
            ticket.errors.upper_limit_amount = '';
          }

          if (
            !this.checkEmptyWithOutZero(lowerLimitAmount) &&
            this.isNumeric(lowerLimitAmount) &&
            !this.checkEmptyWithOutZero(upperLimitAmount) &&
            this.isNumeric(upperLimitAmount) &&
            parseInt(lowerLimitAmount) == 0 &&
            parseInt(upperLimitAmount) == 0
          ) {
            ticket.errors.upper_limit_amount = '';
          } else if (
            (!this.checkEmptyWithOutZero(lowerLimitAmount) &&
              this.isNumeric(lowerLimitAmount) &&
              !this.checkEmptyWithOutZero(upperLimitAmount) &&
              this.isNumeric(upperLimitAmount) &&
              parseInt(lowerLimitAmount) > 0 &&
              parseInt(upperLimitAmount) > 0 &&
              parseInt(lowerLimitAmount) >= parseInt(upperLimitAmount)) ||
            (!this.checkEmptyWithOutZero(lowerLimitAmount) &&
              this.isNumeric(lowerLimitAmount) &&
              this.checkEmptyWithOutZero(upperLimitAmount))
          ) {
            flgValidate = false;
            ticket.errors.upper_limit_amount = this.$t('E-CM-115', {
              value: ticket.lower_limit_amount,
            });
            if (this.checkEmpty(curElementIdErr)) {
              curElementIdErr = `ticket_field_${index}`;
            }
          } else {
            ticket.errors.upper_limit_amount = '';
          }
        } else {
          ticket.is_depend_position = true;
          ticket.position_amounts.forEach(position => {
            const lowerLimit =
              position.lower_limit_amount == 0
                ? position.lower_limit_amount
                : this.convertToIntegerKite(position.lower_limit_amount);
            const upperLimit =
              position.upper_limit_amount == 0
                ? position.upper_limit_amount
                : this.convertToIntegerKite(position.upper_limit_amount);
            if (this.checkEmptyWithOutZero(lowerLimit) || !this.isNumeric(lowerLimit)) {
              flgValidate = false;
              position.errors.lower_limit_amount = this.$t('E-CM-047', {
                item: this.$t('regulation.from_the_circle'),
              });
              if (this.checkEmpty(curElementIdErr)) {
                curElementIdErr = `ticket_field_${index}`;
              }
            } else if (this.strLen(String(lowerLimit)) > 10) {
              flgValidate = false;
              position.errors.lower_limit_amount = this.$t('E-CM-111', {
                size: 10,
              });
              if (this.checkEmpty(curElementIdErr)) {
                curElementIdErr = `ticket_field_${index}`;
              }
            } else {
              position.errors.lower_limit_amount = '';
            }

            if (!this.checkEmptyWithOutZero(upperLimit) && !this.isNumeric(upperLimit)) {
              flgValidate = false;
              position.errors.upper_limit_amount = this.$t('E-CM-047', {
                item: this.$t('regulation.up_to_the_yen'),
              });
              if (this.checkEmpty(curElementIdErr)) {
                curElementIdErr = `ticket_field_${index}`;
              }
            } else if (this.strLen(String(upperLimit)) > 10) {
              flgValidate = false;
              position.errors.upper_limit_amount = this.$t('E-CM-111', {
                size: 10,
              });
              if (this.checkEmpty(curElementIdErr)) {
                curElementIdErr = `ticket_field_${index}`;
              }
            } else {
              position.errors.upper_limit_amount = '';
            }

            if (
              !this.checkEmptyWithOutZero(lowerLimit) &&
              this.isNumeric(lowerLimit) &&
              !this.checkEmptyWithOutZero(upperLimit) &&
              this.isNumeric(upperLimit) &&
              lowerLimit == 0 &&
              upperLimit == 0
            ) {
              position.errors.upper_limit_amount = '';
            } else if (
              (!this.checkEmptyWithOutZero(lowerLimit) &&
                this.isNumeric(lowerLimit) &&
                !this.checkEmptyWithOutZero(upperLimit) &&
                this.isNumeric(upperLimit) &&
                lowerLimit > 0 &&
                upperLimit > 0 &&
                lowerLimit >= upperLimit) ||
              (!this.checkEmptyWithOutZero(lowerLimit) &&
                this.isNumeric(lowerLimit) &&
                this.checkEmptyWithOutZero(upperLimit))
            ) {
              flgValidate = false;
              position.errors.upper_limit_amount = this.$t('E-CM-115', {
                value: position.lower_limit_amount,
              });
              if (this.checkEmpty(curElementIdErr)) {
                curElementIdErr = `ticket_field_${index}`;
              }
            } else {
              position.errors.upper_limit_amount = '';
            }
          });
        }
      });

      // validate ticket_cost_common
      if (!this.ticket_cost_common.is_depend_position) {
        this.ticket_cost_common.is_depend_position = false;
        const lowerLimitCommon =
          this.ticket_cost_common.lower_limit_amount == 0
            ? this.ticket_cost_common.lower_limit_amount
            : this.convertToIntegerKite(this.ticket_cost_common.lower_limit_amount);
        const upperLimitCommon =
          this.ticket_cost_common.upper_limit_amount == 0
            ? this.ticket_cost_common.upper_limit_amount
            : this.convertToIntegerKite(this.ticket_cost_common.upper_limit_amount);
        if (!this.checkEmptyWithOutZero(lowerLimitCommon) && !this.isNumeric(lowerLimitCommon)) {
          flgValidate = false;
          this.ticket_cost_common.errors.lower_limit_amount = this.$t('E-CM-047', {
            item: this.$t('regulation.from_the_circle'),
          });
          if (this.checkEmpty(curElementIdErr)) {
            curElementIdErr = `ticket_cost_field`;
          }
        } else {
          this.ticket_cost_common.errors.lower_limit_amount = '';
        }

        if (!this.checkEmptyWithOutZero(upperLimitCommon) && !this.isNumeric(upperLimitCommon)) {
          flgValidate = false;
          this.ticket_cost_common.errors.upper_limit_amount = this.$t('E-CM-047', {
            item: this.$t('regulation.up_to_the_yen'),
          });
          if (this.checkEmpty(curElementIdErr)) {
            curElementIdErr = `ticket_cost_field`;
          }
        } else {
          this.ticket_cost_common.errors.upper_limit_amount = '';
        }

        if (
          !this.checkEmptyWithOutZero(lowerLimitCommon) &&
          this.isNumeric(lowerLimitCommon) &&
          !this.checkEmptyWithOutZero(upperLimitCommon) &&
          this.isNumeric(upperLimitCommon) &&
          lowerLimitCommon == 0 &&
          upperLimitCommon == 0
        ) {
          this.ticket_cost_common.errors.upper_limit_amount = '';
        } else if (
          (!this.checkEmptyWithOutZero(lowerLimitCommon) &&
            this.isNumeric(lowerLimitCommon) &&
            !this.checkEmptyWithOutZero(upperLimitCommon) &&
            this.isNumeric(upperLimitCommon) &&
            lowerLimitCommon > 0 &&
            upperLimitCommon > 0 &&
            lowerLimitCommon >= upperLimitCommon) ||
          (!this.checkEmptyWithOutZero(lowerLimitCommon) &&
            this.isNumeric(lowerLimitCommon) &&
            this.checkEmptyWithOutZero(upperLimitCommon))
        ) {
          flgValidate = false;
          this.ticket_cost_common.errors.upper_limit_amount = this.$t('E-CM-115', {
            value: this.ticket_cost_common.lower_limit_amount,
          });
          if (this.checkEmpty(curElementIdErr)) {
            curElementIdErr = `ticket_cost_field`;
          }
        } else {
          this.ticket_cost_common.errors.upper_limit_amount = '';
        }
      } else {
        this.ticket_cost_common.is_depend_position = true;
        this.ticket_cost_common.position_amounts.forEach(position => {
          const lowerLimitCommon =
            position.lower_limit_amount == 0
              ? position.lower_limit_amount
              : this.convertToIntegerKite(position.lower_limit_amount);
          const upperLimitCommon =
            position.upper_limit_amount == 0
              ? position.upper_limit_amount
              : this.convertToIntegerKite(position.upper_limit_amount);
          if (this.checkEmptyWithOutZero(lowerLimitCommon) || !this.isNumeric(lowerLimitCommon)) {
            flgValidate = false;
            position.errors.lower_limit_amount = this.$t('E-CM-047', {
              item: this.$t('regulation.from_the_circle'),
            });
            if (this.checkEmpty(curElementIdErr)) {
              curElementIdErr = `ticket_cost_field`;
            }
          } else if (this.strLen(String(lowerLimitCommon)) > 10) {
            flgValidate = false;
            position.errors.lower_limit_amount = this.$t('E-CM-111', {
              size: 10,
            });
            if (this.checkEmpty(curElementIdErr)) {
              curElementIdErr = `ticket_cost_field`;
            }
          } else {
            position.errors.lower_limit_amount = '';
          }

          if (!this.checkEmptyWithOutZero(upperLimitCommon) && !this.isNumeric(upperLimitCommon)) {
            flgValidate = false;
            position.errors.upper_limit_amount = this.$t('E-CM-047', {
              item: this.$t('regulation.up_to_the_yen'),
            });
            if (this.checkEmpty(curElementIdErr)) {
              curElementIdErr = `ticket_cost_field`;
            }
          } else if (this.strLen(String(upperLimitCommon)) > 10) {
            flgValidate = false;
            position.errors.upper_limit_amount = this.$t('E-CM-111', {
              size: 10,
            });
            if (this.checkEmpty(curElementIdErr)) {
              curElementIdErr = `ticket_cost_field`;
            }
          } else {
            position.errors.upper_limit_amount = '';
          }

          if (
            !this.checkEmptyWithOutZero(lowerLimitCommon) &&
            this.isNumeric(lowerLimitCommon) &&
            !this.checkEmptyWithOutZero(upperLimitCommon) &&
            this.isNumeric(upperLimitCommon) &&
            lowerLimitCommon == 0 &&
            upperLimitCommon == 0
          ) {
            position.errors.upper_limit_amount = '';
          } else if (
            (!this.checkEmptyWithOutZero(lowerLimitCommon) &&
              this.isNumeric(lowerLimitCommon) &&
              !this.checkEmptyWithOutZero(upperLimitCommon) &&
              this.isNumeric(upperLimitCommon) &&
              lowerLimitCommon > 0 &&
              upperLimitCommon > 0 &&
              lowerLimitCommon >= upperLimitCommon) ||
            (!this.checkEmptyWithOutZero(lowerLimitCommon) &&
              this.isNumeric(lowerLimitCommon) &&
              this.checkEmptyWithOutZero(upperLimitCommon))
          ) {
            flgValidate = false;
            position.errors.upper_limit_amount = this.$t('E-CM-115', {
              value: position.lower_limit_amount,
            });
            if (this.checkEmpty(curElementIdErr)) {
              curElementIdErr = `ticket_cost_field`;
            }
          } else {
            position.errors.upper_limit_amount = '';
          }
        });
      }

      // validate hotel_directions
      this.hotel_directions.forEach((hotel, index) => {
        if (this.checkEmpty(hotel.area_id)) {
          flgValidate = false;
          hotel.errors.area_id = this.$t('E-CM-004');
          if (this.checkEmpty(curElementIdErr)) {
            curElementIdErr = `hotel_directions_field_${index}`;
          }
        } else {
          hotel.errors.area_id = '';
        }

        if (!hotel.is_depend_position) {
          hotel.is_depend_position = false;
          const lowerLimit =
            hotel.lower_limit_amount == 0 ? hotel.lower_limit_amount : this.convertToIntegerKite(hotel.lower_limit_amount);
          const upperLimit =
            hotel.upper_limit_amount == 0 ? hotel.upper_limit_amount : this.convertToIntegerKite(hotel.upper_limit_amount);
          if (!this.checkEmptyWithOutZero(lowerLimit) && !this.isNumeric(lowerLimit)) {
            flgValidate = false;
            hotel.errors.lower_limit_amount = this.$t('E-CM-047', {
              item: this.$t('regulation.from_the_circle'),
            });
            if (this.checkEmpty(curElementIdErr)) {
              curElementIdErr = `hotel_directions_field_${index}`;
            }
          } else {
            hotel.errors.lower_limit_amount = '';
          }
          if (!this.checkEmptyWithOutZero(upperLimit) && !this.isNumeric(upperLimit)) {
            flgValidate = false;
            hotel.errors.upper_limit_amount = this.$t('E-CM-047', {
              item: this.$t('regulation.up_to_the_yen'),
            });
            if (this.checkEmpty(curElementIdErr)) {
              curElementIdErr = `hotel_directions_field_${index}`;
            }
          } else {
            hotel.errors.upper_limit_amount = '';
          }

          if (
            !this.checkEmptyWithOutZero(lowerLimit) &&
            this.isNumeric(lowerLimit) &&
            !this.checkEmptyWithOutZero(upperLimit) &&
            this.isNumeric(upperLimit) &&
            lowerLimit == 0 &&
            upperLimit == 0
          ) {
            hotel.errors.upper_limit_amount = '';
          } else if (
            (!this.checkEmptyWithOutZero(lowerLimit) &&
              this.isNumeric(lowerLimit) &&
              !this.checkEmptyWithOutZero(upperLimit) &&
              this.isNumeric(upperLimit) &&
              lowerLimit > 0 &&
              upperLimit > 0 &&
              lowerLimit >= upperLimit) ||
            (!this.checkEmptyWithOutZero(lowerLimit) &&
              this.isNumeric(lowerLimit) &&
              this.checkEmptyWithOutZero(upperLimit))
          ) {
            flgValidate = false;
            hotel.errors.upper_limit_amount = this.$t('E-CM-115', {
              value: hotel.lower_limit_amount,
            });
            if (this.checkEmpty(curElementIdErr)) {
              curElementIdErr = `hotel_directions_field_${index}`;
            }
          } else {
            hotel.errors.upper_limit_amount = '';
          }
        } else {
          hotel.is_depend_position = true;
          hotel.position_amounts.forEach(position => {
            const lowerLimit =
              position.lower_limit_amount == 0
                ? position.lower_limit_amount
                : this.convertToIntegerKite(position.lower_limit_amount);
            const upperLimit =
              position.upper_limit_amount == 0
                ? position.upper_limit_amount
                : this.convertToIntegerKite(position.upper_limit_amount);
            if (this.checkEmptyWithOutZero(lowerLimit) || !this.isNumeric(lowerLimit)) {
              flgValidate = false;
              position.errors.lower_limit_amount = this.$t('E-CM-047', {
                item: this.$t('regulation.from_the_circle'),
              });
              if (this.checkEmpty(curElementIdErr)) {
                curElementIdErr = `hotel_directions_field_${index}`;
              }
            } else if (this.strLen(String(lowerLimit)) > 10) {
              flgValidate = false;
              position.errors.lower_limit_amount = this.$t('E-CM-111', {
                size: 10,
              });
              if (this.checkEmpty(curElementIdErr)) {
                curElementIdErr = `hotel_directions_field_${index}`;
              }
            } else {
              position.errors.lower_limit_amount = '';
            }

            if (!this.checkEmptyWithOutZero(upperLimit) && !this.isNumeric(upperLimit)) {
              flgValidate = false;
              position.errors.upper_limit_amount = this.$t('E-CM-047', {
                item: this.$t('regulation.up_to_the_yen'),
              });
              if (this.checkEmpty(curElementIdErr)) {
                curElementIdErr = `hotel_directions_field_${index}`;
              }
            } else if (this.strLen(String(upperLimit)) > 10) {
              flgValidate = false;
              position.errors.upper_limit_amount = this.$t('E-CM-111', {
                size: 10,
              });
              if (this.checkEmpty(curElementIdErr)) {
                curElementIdErr = `hotel_directions_field_${index}`;
              }
            } else {
              position.errors.upper_limit_amount = '';
            }

            if (
              !this.checkEmptyWithOutZero(lowerLimit) &&
              this.isNumeric(lowerLimit) &&
              !this.checkEmptyWithOutZero(upperLimit) &&
              this.isNumeric(upperLimit) &&
              lowerLimit == 0 &&
              upperLimit == 0
            ) {
              position.errors.upper_limit_amount = '';
            } else if (
              (!this.checkEmptyWithOutZero(lowerLimit) &&
                this.isNumeric(lowerLimit) &&
                !this.checkEmptyWithOutZero(upperLimit) &&
                this.isNumeric(upperLimit) &&
                lowerLimit > 0 &&
                upperLimit > 0 &&
                lowerLimit >= upperLimit) ||
              (!this.checkEmptyWithOutZero(lowerLimit) &&
                this.isNumeric(lowerLimit) &&
                this.checkEmptyWithOutZero(upperLimit))
            ) {
              flgValidate = false;
              position.errors.upper_limit_amount = this.$t('E-CM-115', {
                value: position.lower_limit_amount,
              });
              if (this.checkEmpty(curElementIdErr)) {
                curElementIdErr = `hotel_directions_field_${index}`;
              }
            } else {
              position.errors.upper_limit_amount = '';
            }
          });
        }
      });

      // validate hotel_direction_common
      if (!this.hotel_direction_common.is_depend_position) {
        this.hotel_direction_common.is_depend_position = false;
        const lowerLimitAmount =
          this.hotel_direction_common.lower_limit_amount == 0
            ? this.hotel_direction_common.lower_limit_amount
            : this.convertToIntegerKite(this.hotel_direction_common.lower_limit_amount);
        const upperLimitAmount =
          this.hotel_direction_common.upper_limit_amount == 0
            ? this.hotel_direction_common.upper_limit_amount
            : this.convertToIntegerKite(this.hotel_direction_common.upper_limit_amount);
        if (!this.checkEmptyWithOutZero(lowerLimitAmount) && !this.isNumeric(lowerLimitAmount)) {
          flgValidate = false;
          this.hotel_direction_common.errors.lower_limit_amount = this.$t('E-CM-047', {
            item: this.$t('regulation.from_the_circle'),
          });
          if (this.checkEmpty(curElementIdErr)) {
            curElementIdErr = `hotel_direction_common_field`;
          }
        } else {
          this.hotel_direction_common.errors.lower_limit_amount = '';
        }

        if (!this.checkEmptyWithOutZero(upperLimitAmount) && !this.isNumeric(upperLimitAmount)) {
          flgValidate = false;
          this.hotel_direction_common.errors.upper_limit_amount = this.$t('E-CM-047', {
            item: this.$t('regulation.up_to_the_yen'),
          });
          if (this.checkEmpty(curElementIdErr)) {
            curElementIdErr = `hotel_direction_common_field`;
          }
        } else {
          this.hotel_direction_common.errors.upper_limit_amount = '';
        }

        if (
          !this.checkEmptyWithOutZero(lowerLimitAmount) &&
          this.isNumeric(lowerLimitAmount) &&
          !this.checkEmptyWithOutZero(upperLimitAmount) &&
          this.isNumeric(upperLimitAmount) &&
          lowerLimitAmount == 0 &&
          upperLimitAmount == 0
        ) {
          this.hotel_direction_common.errors.upper_limit_amount = '';
        } else if (
          (!this.checkEmptyWithOutZero(lowerLimitAmount) &&
            this.isNumeric(lowerLimitAmount) &&
            !this.checkEmptyWithOutZero(upperLimitAmount) &&
            this.isNumeric(upperLimitAmount) &&
            lowerLimitAmount > 0 &&
            upperLimitAmount > 0 &&
            lowerLimitAmount >= upperLimitAmount) ||
          (!this.checkEmptyWithOutZero(lowerLimitAmount) &&
            this.isNumeric(lowerLimitAmount) &&
            this.checkEmptyWithOutZero(upperLimitAmount))
        ) {
          flgValidate = false;
          this.hotel_direction_common.errors.upper_limit_amount = this.$t('E-CM-115', {
            value: this.hotel_direction_common.lower_limit_amount,
          });
          if (this.checkEmpty(curElementIdErr)) {
            curElementIdErr = `hotel_direction_common_field`;
          }
        } else {
          this.hotel_direction_common.errors.upper_limit_amount = '';
        }
      } else {
        this.hotel_direction_common.is_depend_position = true;
        this.hotel_direction_common.position_amounts.forEach((position, index) => {
          const lowerLimitAmount =
            position.lower_limit_amount == 0
              ? position.lower_limit_amount
              : this.convertToIntegerKite(position.lower_limit_amount);
          const upperLimitAmount =
            position.upper_limit_amount == 0
              ? position.upper_limit_amount
              : this.convertToIntegerKite(position.upper_limit_amount);
          if (this.checkEmptyWithOutZero(lowerLimitAmount) || !this.isNumeric(lowerLimitAmount)) {
            flgValidate = false;
            position.errors.lower_limit_amount = this.$t('E-CM-047', {
              item: this.$t('regulation.from_the_circle'),
            });
            if (this.checkEmpty(curElementIdErr)) {
              curElementIdErr = `hotel_direction_common_field`;
            }
          } else if (this.strLen(String(lowerLimitAmount)) > 10) {
            flgValidate = false;
            position.errors.lower_limit_amount = this.$t('E-CM-111', {
              size: 10,
            });
            if (this.checkEmpty(curElementIdErr)) {
              curElementIdErr = `hotel_direction_common_position_amounts_${index}`;
            }
          } else {
            position.errors.lower_limit_amount = '';
          }

          if (!this.checkEmptyWithOutZero(upperLimitAmount) && !this.isNumeric(upperLimitAmount)) {
            flgValidate = false;
            position.errors.upper_limit_amount = this.$t('E-CM-047', {
              item: this.$t('regulation.up_to_the_yen'),
            });
            if (this.checkEmpty(curElementIdErr)) {
              curElementIdErr = `hotel_direction_common_position_amounts_${index}`;
            }
          } else if (this.strLen(String(upperLimitAmount)) > 10) {
            flgValidate = false;
            position.errors.upper_limit_amount = this.$t('E-CM-111', {
              size: 10,
            });
            if (this.checkEmpty(curElementIdErr)) {
              curElementIdErr = `hotel_direction_common_position_amounts_${index}`;
            }
          } else {
            position.errors.upper_limit_amount = '';
          }

          if (
            !this.checkEmptyWithOutZero(lowerLimitAmount) &&
            this.isNumeric(lowerLimitAmount) &&
            !this.checkEmptyWithOutZero(upperLimitAmount) &&
            this.isNumeric(upperLimitAmount) &&
            lowerLimitAmount == 0 &&
            upperLimitAmount == 0
          ) {
            position.errors.upper_limit_amount = '';
          } else if (
            (!this.checkEmptyWithOutZero(lowerLimitAmount) &&
              this.isNumeric(lowerLimitAmount) &&
              !this.checkEmptyWithOutZero(upperLimitAmount) &&
              this.isNumeric(upperLimitAmount) &&
              lowerLimitAmount > 0 &&
              upperLimitAmount > 0 &&
              lowerLimitAmount >= upperLimitAmount) ||
            (!this.checkEmptyWithOutZero(lowerLimitAmount) &&
              this.isNumeric(lowerLimitAmount) &&
              this.checkEmptyWithOutZero(upperLimitAmount))
          ) {
            flgValidate = false;
            position.errors.upper_limit_amount = this.$t('E-CM-115', {
              value: position.lower_limit_amount,
            });
            if (this.checkEmpty(curElementIdErr)) {
              curElementIdErr = `hotel_direction_common_position_amounts_${index}`;
            }
          } else {
            position.errors.upper_limit_amount = '';
          }
        });
      }

      // validate total_expense_directions
      this.total_expense_directions.forEach((total, index) => {
        if (this.checkEmpty(total.area_id)) {
          flgValidate = false;
          total.errors.area_id = this.$t('E-CM-004');
          if (this.checkEmpty(curElementIdErr)) {
            curElementIdErr = `total_expense_directions_field_${index}`;
          }
        } else {
          total.errors.area_id = '';
        }

        if (!total.is_depend_position) {
          total.is_depend_position = false;
          const lowerLimitAmount =
            total.lower_limit_amount == 0 ? total.lower_limit_amount : this.convertToIntegerKite(total.lower_limit_amount);
          const upperLimitAmount =
            total.upper_limit_amount == 0 ? total.upper_limit_amount : this.convertToIntegerKite(total.upper_limit_amount);
          if (!this.checkEmptyWithOutZero(lowerLimitAmount) && !this.isNumeric(lowerLimitAmount)) {
            flgValidate = false;
            total.errors.lower_limit_amount = this.$t('E-CM-047', {
              item: this.$t('regulation.from_the_circle'),
            });
            if (this.checkEmpty(curElementIdErr)) {
              curElementIdErr = `total_expense_directions_field_${index}`;
            }
          } else {
            total.errors.lower_limit_amount = '';
          }

          if (!this.checkEmptyWithOutZero(upperLimitAmount) && !this.isNumeric(upperLimitAmount)) {
            flgValidate = false;
            total.errors.upper_limit_amount = this.$t('E-CM-047', {
              item: this.$t('regulation.up_to_the_yen'),
            });
            if (this.checkEmpty(curElementIdErr)) {
              curElementIdErr = `total_expense_directions_field_${index}`;
            }
          } else {
            total.errors.upper_limit_amount = '';
          }

          if (
            !this.checkEmptyWithOutZero(lowerLimitAmount) &&
            this.isNumeric(lowerLimitAmount) &&
            !this.checkEmptyWithOutZero(upperLimitAmount) &&
            this.isNumeric(upperLimitAmount) &&
            lowerLimitAmount == 0 &&
            upperLimitAmount == 0
          ) {
            total.errors.upper_limit_amount = '';
          } else if (
            (!this.checkEmptyWithOutZero(lowerLimitAmount) &&
              this.isNumeric(lowerLimitAmount) &&
              !this.checkEmptyWithOutZero(upperLimitAmount) &&
              this.isNumeric(upperLimitAmount) &&
              lowerLimitAmount > 0 &&
              upperLimitAmount > 0 &&
              lowerLimitAmount >= upperLimitAmount) ||
            (!this.checkEmptyWithOutZero(lowerLimitAmount) &&
              this.isNumeric(lowerLimitAmount) &&
              this.checkEmptyWithOutZero(upperLimitAmount))
          ) {
            flgValidate = false;
            total.errors.upper_limit_amount = this.$t('E-CM-115', {
              value: total.lower_limit_amount,
            });
            if (this.checkEmpty(curElementIdErr)) {
              curElementIdErr = `total_expense_directions_field_${index}`;
            }
          } else {
            total.errors.upper_limit_amount = '';
          }
        } else {
          total.is_depend_position = true;
          total.position_amounts.forEach(position => {
            const lowerLimit =
              position.lower_limit_amount == 0
                ? position.lower_limit_amount
                : this.convertToIntegerKite(position.lower_limit_amount);
            const upperLimit =
              position.upper_limit_amount == 0
                ? position.upper_limit_amount
                : this.convertToIntegerKite(position.upper_limit_amount);
            if (this.checkEmptyWithOutZero(lowerLimit) || !this.isNumeric(lowerLimit)) {
              flgValidate = false;
              position.errors.lower_limit_amount = this.$t('E-CM-047', {
                item: this.$t('regulation.from_the_circle'),
              });
              if (this.checkEmpty(curElementIdErr)) {
                curElementIdErr = `total_expense_directions_field_${index}`;
              }
            } else if (this.strLen(String(lowerLimit)) > 10) {
              flgValidate = false;
              position.errors.lower_limit_amount = this.$t('E-CM-111', {
                size: 10,
              });
              if (this.checkEmpty(curElementIdErr)) {
                curElementIdErr = `total_expense_directions_field_${index}`;
              }
            } else {
              position.errors.lower_limit_amount = '';
            }

            if (!this.checkEmptyWithOutZero(upperLimit) && !this.isNumeric(upperLimit)) {
              flgValidate = false;
              position.errors.upper_limit_amount = this.$t('E-CM-047', {
                item: this.$t('regulation.up_to_the_yen'),
              });
              if (this.checkEmpty(curElementIdErr)) {
                curElementIdErr = `total_expense_directions_field_${index}`;
              }
            } else if (this.strLen(String(upperLimit)) > 10) {
              flgValidate = false;
              position.errors.upper_limit_amount = this.$t('E-CM-111', {
                size: 10,
              });
              if (this.checkEmpty(curElementIdErr)) {
                curElementIdErr = `total_expense_directions_field_${index}`;
              }
            } else {
              position.errors.upper_limit_amount = '';
            }

            if (
              !this.checkEmptyWithOutZero(lowerLimit) &&
              this.isNumeric(lowerLimit) &&
              !this.checkEmptyWithOutZero(upperLimit) &&
              this.isNumeric(upperLimit) &&
              lowerLimit == 0 &&
              upperLimit == 0
            ) {
              position.errors.upper_limit_amount = '';
            } else if (
              (!this.checkEmptyWithOutZero(lowerLimit) &&
                this.isNumeric(lowerLimit) &&
                !this.checkEmptyWithOutZero(upperLimit) &&
                this.isNumeric(upperLimit) &&
                lowerLimit > 0 &&
                upperLimit > 0 &&
                lowerLimit >= upperLimit) ||
              (!this.checkEmptyWithOutZero(lowerLimit) &&
                this.isNumeric(lowerLimit) &&
                this.checkEmptyWithOutZero(upperLimit))
            ) {
              flgValidate = false;
              position.errors.upper_limit_amount = this.$t('E-CM-115', {
                value: position.lower_limit_amount,
              });
              if (this.checkEmpty(curElementIdErr)) {
                curElementIdErr = `total_expense_directions_field_${index}`;
              }
            } else {
              position.errors.upper_limit_amount = '';
            }
          });
        }
      });

      // validate total_expense_direction_common
      if (!this.total_expense_direction_common.is_depend_position) {
        this.total_expense_direction_common.is_depend_position = false;
        const lowerLimit =
          this.total_expense_direction_common.lower_limit_amount == 0
            ? this.total_expense_direction_common.lower_limit_amount
            : this.convertToIntegerKite(this.total_expense_direction_common.lower_limit_amount);
        const upperLimit =
          this.total_expense_direction_common.upper_limit_amount == 0
            ? this.total_expense_direction_common.upper_limit_amount
            : this.convertToIntegerKite(this.total_expense_direction_common.upper_limit_amount);
        if (!this.checkEmptyWithOutZero(lowerLimit) && !this.isNumeric(lowerLimit)) {
          flgValidate = false;
          this.total_expense_direction_common.errors.lower_limit_amount = this.$t('E-CM-047', {
            item: this.$t('regulation.from_the_circle'),
          });
          if (this.checkEmpty(curElementIdErr)) {
            curElementIdErr = `total_expense_direction_common_field`;
          }
        } else {
          this.total_expense_direction_common.errors.lower_limit_amount = '';
        }

        if (!this.checkEmptyWithOutZero(upperLimit) && !this.isNumeric(upperLimit)) {
          flgValidate = false;
          this.total_expense_direction_common.errors.upper_limit_amount = this.$t('E-CM-047', {
            item: this.$t('regulation.up_to_the_yen'),
          });
          if (this.checkEmpty(curElementIdErr)) {
            curElementIdErr = `total_expense_direction_common_field`;
          }
        } else {
          this.total_expense_direction_common.errors.upper_limit_amount = '';
        }

        if (
          !this.checkEmptyWithOutZero(lowerLimit) &&
          this.isNumeric(lowerLimit) &&
          !this.checkEmptyWithOutZero(upperLimit) &&
          this.isNumeric(upperLimit) &&
          lowerLimit == 0 &&
          upperLimit == 0
        ) {
          this.total_expense_direction_common.errors.upper_limit_amount = '';
        } else if (
          (!this.checkEmptyWithOutZero(lowerLimit) &&
            this.isNumeric(lowerLimit) &&
            !this.checkEmptyWithOutZero(upperLimit) &&
            this.isNumeric(upperLimit) &&
            lowerLimit > 0 &&
            upperLimit > 0 &&
            lowerLimit >= upperLimit) ||
          (!this.checkEmptyWithOutZero(lowerLimit) &&
            this.isNumeric(lowerLimit) &&
            this.checkEmptyWithOutZero(upperLimit))
        ) {
          flgValidate = false;
          this.total_expense_direction_common.errors.upper_limit_amount = this.$t('E-CM-115', {
            value: this.total_expense_direction_common.lower_limit_amount,
          });
          if (this.checkEmpty(curElementIdErr)) {
            curElementIdErr = `total_expense_direction_common_field`;
          }
        } else {
          this.total_expense_direction_common.errors.upper_limit_amount = '';
        }
      } else {
        this.total_expense_direction_common.is_depend_position = true;
        this.total_expense_direction_common.position_amounts.forEach((position, index) => {
          const lowerLimit =
            position.lower_limit_amount == 0
              ? position.lower_limit_amount
              : this.convertToIntegerKite(position.lower_limit_amount);
          const upperLimit =
            position.upper_limit_amount == 0
              ? position.upper_limit_amount
              : this.convertToIntegerKite(position.upper_limit_amount);
          if (this.checkEmptyWithOutZero(lowerLimit) || !this.isNumeric(lowerLimit)) {
            flgValidate = false;
            position.errors.lower_limit_amount = this.$t('E-CM-047', {
              item: this.$t('regulation.from_the_circle'),
            });
            if (this.checkEmpty(curElementIdErr)) {
              curElementIdErr = `total_expense_direction_common_position_amounts_field_${index}`;
            }
          } else if (this.strLen(String(lowerLimit)) > 10) {
            flgValidate = false;
            position.errors.lower_limit_amount = this.$t('E-CM-111', {
              size: 10,
            });
            if (this.checkEmpty(curElementIdErr)) {
              curElementIdErr = `total_expense_direction_common_position_amounts_field_${index}`;
            }
          } else {
            position.errors.lower_limit_amount = '';
          }

          if (!this.checkEmptyWithOutZero(upperLimit) && !this.isNumeric(upperLimit)) {
            flgValidate = false;
            position.errors.upper_limit_amount = this.$t('E-CM-047', {
              item: this.$t('regulation.up_to_the_yen'),
            });
            if (this.checkEmpty(curElementIdErr)) {
              curElementIdErr = `total_expense_direction_common_position_amounts_field_${index}`;
            }
          } else if (this.strLen(String(upperLimit)) > 10) {
            flgValidate = false;
            position.errors.upper_limit_amount = this.$t('E-CM-111', {
              size: 10,
            });
            if (this.checkEmpty(curElementIdErr)) {
              curElementIdErr = `total_expense_direction_common_position_amounts_field_${index}`;
            }
          } else {
            position.errors.upper_limit_amount = '';
          }

          if (
            !this.checkEmptyWithOutZero(lowerLimit) &&
            this.isNumeric(lowerLimit) &&
            !this.checkEmptyWithOutZero(upperLimit) &&
            this.isNumeric(upperLimit) &&
            lowerLimit == 0 &&
            upperLimit == 0
          ) {
            position.errors.upper_limit_amount = '';
          } else if (
            (!this.checkEmptyWithOutZero(lowerLimit) &&
              this.isNumeric(lowerLimit) &&
              !this.checkEmptyWithOutZero(upperLimit) &&
              this.isNumeric(upperLimit) &&
              lowerLimit > 0 &&
              upperLimit > 0 &&
              lowerLimit >= upperLimit) ||
            (!this.checkEmptyWithOutZero(lowerLimit) &&
              this.isNumeric(lowerLimit) &&
              this.checkEmptyWithOutZero(upperLimit))
          ) {
            flgValidate = false;
            position.errors.upper_limit_amount = this.$t('E-CM-115', {
              value: position.lower_limit_amount,
            });
            if (this.checkEmpty(curElementIdErr)) {
              curElementIdErr = `total_expense_direction_common_position_amounts_field_${index}`;
            }
          } else {
            position.errors.upper_limit_amount = '';
          }
        });
      }

      //validate note
      // if (this.checkEmpty(this.note)) {
      //   flgValidate = false;
      //   this.errors.note = this.$t('E-CM-100', { item: this.$t('regulation.remarks') });
      // } else {
      //   this.errors.note = '';
      // }

      if (!flgValidate) {
        this.scrollIntoDivCustom(curElementIdErr);
        return;
      }

      // delete errors
      let listPassports = this.cloneObject(this.passports);
      listPassports.forEach(passport => {
        passport.remain_days = parseInt(String(passport.remain_days).replaceAll(',', ''));
        delete passport.errors;
      });

      let listTransitCaution = this.cloneObject(this.transit_caution_list);
      listTransitCaution.forEach(transit => {
        let airport = this.listAirport.find(
          ap => this.labelLocale(ap) == transit.airport || ap.value == transit.airport || ap.name == transit.airport,
        );
        transit.airport_id = airport.id;
        delete transit.errors;
        delete transit.airport;
      });

      let listTicketCosts = this.cloneObject(this.ticket_costs);
      listTicketCosts.forEach(ticket => {
        ticket.upper_limit_amount = parseInt(String(ticket.upper_limit_amount).replaceAll(',', ''));
        ticket.lower_limit_amount = parseInt(String(ticket.lower_limit_amount).replaceAll(',', ''));
        delete ticket.errors;
        if (!this.checkEmpty(ticket.position_amounts)) {
          ticket.position_amounts.forEach(position => {
            delete position.errors;
            position.lower_limit_amount = parseInt(String(position.lower_limit_amount).replaceAll(',', ''));
            position.upper_limit_amount = parseInt(String(position.upper_limit_amount).replaceAll(',', ''));
          });
        }
      });

      let listHotelDirections = this.cloneObject(this.hotel_directions);
      listHotelDirections.forEach(hotel => {
        hotel.lower_limit_amount = parseInt(String(hotel.lower_limit_amount).replaceAll(',', ''));
        hotel.upper_limit_amount = parseInt(String(hotel.upper_limit_amount).replaceAll(',', ''));
        delete hotel.errors;
        if (!this.checkEmpty(hotel.position_amounts)) {
          hotel.position_amounts.forEach(position => {
            position.lower_limit_amount = parseInt(String(position.lower_limit_amount).replaceAll(',', ''));
            position.upper_limit_amount = parseInt(String(position.upper_limit_amount).replaceAll(',', ''));
            delete position.errors;
          });
        }
      });

      let listTotalExpenseDirections = this.cloneObject(this.total_expense_directions);
      listTotalExpenseDirections.forEach(total => {
        total.lower_limit_amount =
          total.lower_limit_amount == 0 ? total.lower_limit_amount : this.convertToIntegerKite(total.lower_limit_amount);
        total.upper_limit_amount =
          total.upper_limit_amount == 0 ? total.upper_limit_amount : this.convertToIntegerKite(total.upper_limit_amount);
        delete total.errors;
        if (!this.checkEmpty(total.position_amounts)) {
          total.position_amounts.forEach(position => {
            position.lower_limit_amount =
              position.lower_limit_amount == 0
                ? position.lower_limit_amount
                : this.convertToIntegerKite(position.lower_limit_amount);
            position.upper_limit_amount =
              position.upper_limit_amount == 0
                ? position.upper_limit_amount
                : this.convertToIntegerKite(position.upper_limit_amount);
            delete position.errors;
          });
        }
      });

      let listTicketCostCommon = this.cloneObject(this.ticket_cost_common);
      delete listTicketCostCommon.errors;
      listTicketCostCommon.lower_limit_amount = parseInt(
        String(listTicketCostCommon.lower_limit_amount).replaceAll(',', ''),
      );
      listTicketCostCommon.upper_limit_amount = parseInt(
        String(listTicketCostCommon.upper_limit_amount).replaceAll(',', ''),
      );
      listTicketCostCommon.position_amounts.forEach(position => {
        position.lower_limit_amount = parseInt(String(position.lower_limit_amount).replaceAll(',', ''));
        position.upper_limit_amount = parseInt(String(position.upper_limit_amount).replaceAll(',', ''));
        delete position.errors;
      });

      let listHotelDirectionCommon = this.cloneObject(this.hotel_direction_common);
      delete listHotelDirectionCommon.errors;
      listHotelDirectionCommon.lower_limit_amount = parseInt(
        String(listHotelDirectionCommon.lower_limit_amount).replaceAll(',', ''),
      );
      listHotelDirectionCommon.upper_limit_amount = parseInt(
        String(listHotelDirectionCommon.upper_limit_amount).replaceAll(',', ''),
      );
      listHotelDirectionCommon.position_amounts.forEach(position => {
        position.lower_limit_amount =
          position.lower_limit_amount == 0
            ? position.lower_limit_amount
            : this.convertToIntegerKite(position.lower_limit_amount);
        position.upper_limit_amount =
          position.upper_limit_amount == 0
            ? position.upper_limit_amount
            : this.convertToIntegerKite(position.upper_limit_amount);
        delete position.errors;
      });

      let listTotalExpenseDirectionCommon = this.cloneObject(this.total_expense_direction_common);
      delete listTotalExpenseDirectionCommon.errors;
      listTotalExpenseDirectionCommon.lower_limit_amount =
        listTotalExpenseDirectionCommon.lower_limit_amount == 0
          ? listTotalExpenseDirectionCommon.lower_limit_amount
          : this.convertToIntegerKite(listTotalExpenseDirectionCommon.lower_limit_amount);
      listTotalExpenseDirectionCommon.upper_limit_amount =
        listTotalExpenseDirectionCommon.upper_limit_amount == 0
          ? listTotalExpenseDirectionCommon.upper_limit_amount
          : this.convertToIntegerKite(listTotalExpenseDirectionCommon.upper_limit_amount);
      if (!this.checkEmpty(listTotalExpenseDirectionCommon.position_amounts)) {
        listTotalExpenseDirectionCommon.position_amounts.forEach(position => {
          position.lower_limit_amount =
            position.lower_limit_amount == 0
              ? position.lower_limit_amount
              : this.convertToIntegerKite(position.lower_limit_amount);
          position.upper_limit_amount =
            position.upper_limit_amount == 0
              ? position.upper_limit_amount
              : this.convertToIntegerKite(position.upper_limit_amount);
          delete position.errors;
        });
      }

      let listDomesticFlights = this.cloneObject(this.domestic_flights);
      delete listDomesticFlights.errors;
      listDomesticFlights.position_seat_class_types.forEach(seat => {
        delete seat.errors;
      });

      let listFlightTimes = this.cloneObject(this.flight_times);
      let listInternationalFlights = this.cloneObject(this.international_flights);
      delete listInternationalFlights.errors;
      listInternationalFlights.position_seat_class_types.forEach(seat => {
        delete seat.errors;
      });
      delete listFlightTimes.errors;
      listFlightTimes.position_seat_class_types.forEach(seat => {
        delete seat.errors;
      });

      let listBannedFlightSectionList = this.cloneObject(this.banned_flight_section_list);
      listBannedFlightSectionList.forEach(item => {
        let departureAirport = this.listAirport.find(
          ap =>
            this.labelLocale(ap) == item.departure_airport ||
            ap.value == item.departure_airport ||
            ap.name == item.departure_airport,
        );
        let arrivalAirport = this.listAirport.find(
          ap =>
            this.labelLocale(ap) == item.arrival_airport ||
            ap.value == item.arrival_airport ||
            ap.name == item.arrival_airport,
        );
        item.departure_airport_id = departureAirport.id;
        item.arrival_airport_id = arrivalAirport.id;
        delete item.errors;
        delete item.departure_airport;
        delete item.arrival_airport;
      });

      let listAttentionFlightSectionList = this.cloneObject(this.attention_flight_section_list);
      listAttentionFlightSectionList.forEach(item => {
        let departureAirport = this.listAirport.find(
          ap =>
            this.labelLocale(ap) == item.departure_airport ||
            ap.value == item.departure_airport ||
            ap.name == item.departure_airport,
        );
        let arrivalAirport = this.listAirport.find(
          ap =>
            this.labelLocale(ap) == item.arrival_airport ||
            ap.value == item.arrival_airport ||
            ap.name == item.arrival_airport,
        );
        item.departure_airport_id = departureAirport.id;
        item.arrival_airport_id = arrivalAirport.id;
        delete item.errors;
        delete item.departure_airport;
        delete item.arrival_airport;
      });

      let listTransitTimeList = this.cloneObject(this.transit_time_list);
      listTransitTimeList.forEach(item => {
        item.lower_limit_time = parseInt(String(item.lower_limit_time).replaceAll(',', ''));
        item.upper_limit_time = parseInt(String(item.upper_limit_time).replaceAll(',', ''));
        let airport = this.listAirport.find(
          ap => this.labelLocale(ap) == item.airport || ap.value == item.airport || ap.name == item.airport,
        );
        item.airport_id = airport.id;
        delete item.errors;
        delete item.airport;
      });

      this.regulation = {
        ...this.regulation,
        number_of_days: parseInt(String(this.number_of_days).replaceAll(',', '')),
        is_day_trip: this.is_day_trip,
        is_distance: this.is_distance,
        is_check_remain_days: this.is_check_remain_days,
        distance: parseInt(String(this.distance).replaceAll(',', '')),
        passports: listPassports,
        common_passport_day_required:  parseInt(String(this.common_passport_day_required).replaceAll(',', '')),
        // is_insurance_required: this.is_insurance_required,
        no_transit_airport_list: this.no_transit_airport_list,
        banned_airline_list: this.banned_airline_list,
        banned_flight_section_list: listBannedFlightSectionList,
        attention_flight_section_list: listAttentionFlightSectionList,
        transit_time_list: listTransitTimeList,
        transit_caution_list: listTransitCaution,
        number_of_connection: numberOfConnections,
        domestic_flights: listDomesticFlights,
        international_flights: listInternationalFlights,
        flight_times: listFlightTimes,
        is_flight_time: this.is_flight_time,
        flight_time: parseInt(String(this.flight_time).replaceAll(',', '')),
        ticket_costs: listTicketCosts,
        ticket_cost_common: listTicketCostCommon,
        hotel_directions: listHotelDirections,
        hotel_direction_note: this.hotel_direction_note,
        hotel_direction_common: listHotelDirectionCommon,
        total_expense_directions: listTotalExpenseDirections,
        total_expense_direction_note: this.total_expense_direction_note,
        total_expense_direction_common: listTotalExpenseDirectionCommon,
        note: this.note,
      };

      this.createRegulation();
    },
    createRegulation() {
      this.startLoading();
      this.$services.regulation.createRegulation(
        this.regulation,
        res => {
          if (this.checkEmpty(this.regulation.id)) {
            this.regulation.id = res.id;
          }

          this.saveRegulationFiles();
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    saveRegulationFiles() {
      let formData = new FormData();
      formData.append('regulation_id', this.regulation.id);
      if (this.checkEmpty(this.files)) {
        formData.append('input_ids[]', 0);
      } else {
        for (let index = 0; index < this.files.length; index++) {
          if (this.checkEmpty(this.files[index].id)) {
            formData.append('files[]', this.files[index]);
          } else {
            formData.append('input_ids[]', this.files[index].id);
          }
        }
      }

      this.startLoading();
      this.$services.regulation.saveRegulationFiles(
        formData,
        () => {
          this.isSubmit = true;

          this.notifySuccess(this.$t('I-CM-103'));
          Promise.all([this.getRegulation(), this.getListAirport()]).then(() => {
            this.initDataErrors();
            this.getListPosition(true);
          });
        },
        err => {
          this.endLoading();
          if (typeof err.error === 'object' && !Array.isArray(err.error) && err.error !== null) {
            this.notifyError(this.$t(String(Object.values(err.error)[0][0])));
            return;
          }
          this.notifyError(this.$t(err.error));
        },
      );
    },
  },
};
</script>
<style scoped>
.navbar-right a {
  cursor: pointer;
}
.section-last {
  padding-bottom: 64px;
}
.text_type {
  padding-left: 3rem !important;
}
.check-pass {
  color: #657a84;
}
.checkbox {
  margin-right: 22px !important;
}
</style>
<style>
.el-autocomplete {
  width: 100%;
}

.disabled-color-text {
  color: #a8abb2 !important;
}

.disabled-color-checkbox-background-color {
  background-color: #ffffff !important;
  color: #a5a9ad !important;
}

.flight-times-position .el-input.is-disabled .el-input__wrapper{
    background-color: #e5e8eb !important;
}
</style>
