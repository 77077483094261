<template>
  <div class="info-list-wrap overflow-auto" style="height: calc(100vh - 330px)">
    <div class="detail--wrap clip--detail" v-show="showBoxCreate">
      <button class="btn btn-sm btn-flat-default btn-detail-close js-btn--btn--close-detail" @click="closeBoxCreate()">
        <i class="icon-abui-cross" />
      </button>
      <div class="detail--title">
        <input
          v-model="dataRequest.title"
          maxlength="100"
          type="text"
          class="form-control input-sm"
          :placeholder="$t('trip.clip.pl_title')"
          :disabled="isOperatorOrAdmin"
        />
        <p v-if="dataRequest.has_address" class="clip-not-address">
          {{ $t('trip.clip.clip_not_address') }}
        </p>
      </div>
      <p role="alert" class="error-feedback" v-text="errors.title"></p>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label>
              <span class="label-text">{{ $t('trip.clip.url') }}</span>
            </label>
            <div class="input-with-icon">
              <input
                v-model="dataRequest.url"
                type="text"
                class="form-control input-sm"
                :placeholder="$t('trip.clip.pl_url')"
                :disabled="isOperatorOrAdmin"
              />
            </div>
            <p role="alert" class="error-feedback" v-text="errors.url"></p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label>
              <span class="label-text">{{ $t('trip.clip.memo') }}</span>
            </label>
            <textarea
              v-model="dataRequest.note"
              maxlength="500"
              :disabled="isOperatorOrAdmin"
              class="form-control"
              rows="4"
            />
          </div>
          <p role="alert" class="error-feedback" v-text="errors.note"></p>
        </div>
      </div>
      <div v-if="!disableEdit" style="position: relative">
        <button
          class="btn btn-sm btn-flat-default"
          v-if="!flgCreate"
          :disabled="isOperatorOrAdmin"
          @click="showModalDelete(dataRequest.id)"
        >
          <i class="icon-abui-trash prx"></i>{{ $t('trip.clip.delete_clip') }}
        </button>
        <button
          class="btn btn-sm btn-primary"
          style="min-width: 100px; position: absolute; right: 0; top: 0"
          @click="submitData()"
          :disabled="isOperatorOrAdmin"
        >
          {{ $t('common.submit') }}
        </button>
      </div>
    </div>
    <draggable
      tag="ul"
      class="trip--info--clip-list ui-sortable"
      :list="listClips"
      @change="updateOrder"
      handle=".ui-sortable-handle"
      :disabled="disableEdit"
    >
      <li v-for="(item, index) in listClips" :key="index" @click="detailClip(item)">
        <a href="#" id="dummy--clip1">
          <span v-if="item.is_relationship_schedule === 1" class="info-item-linked" />
          <span v-else class="info-item-no-linked" />
          <span class="clip--thumb"><img :src="item?.image" alt="image" /></span>
          <span class="clip--name clip-url">
            <a v-on:click.stop :href="!checkEmpty(item.url) ? item.url : '#'" target="_blank">
              {{ item.title }}
            </a>
            <p v-if="checkEmpty(item.lat) || checkEmpty(item.lng)" class="clip-not-address">
              {{ $t('trip.clip.clip_not_address') }}
            </p>
          </span>
        </a>
        <button
          v-if="!disableEdit && tripData.user_id == userId"
          class="btn btn-sm btn-flat-danger del-item"
          :disabled="isOperatorOrAdmin"
          @click.stop="showModalDelete(item.id)"
        >
          <i class="icon-abui-cross" />
        </button>
        <span v-show="!showBoxCreate" class="sortable-handle-transparent ui-sortable-handle" />
      </li>
    </draggable>
    <div v-show="!showBoxCreate && btnShowAdd && !disableEdit && tripData?.user_id == userId" class="detail-foot">
      <button
        class="btn btn-flat-primary btn-sm js-btn-addnew-info-item"
        :disabled="isOperatorOrAdmin"
        @click="newClip()"
      >
        <i class="icon-abui-plus prx" />{{ $t('trip.clip.add_clip') }}
      </button>
    </div>
    <ModalConfirm
      :dialogVisible="showBoxConfirm"
      @cancel="showBoxConfirm = false"
      @yes="deleteClip()"
      :title="$t('Q-CM-104')"
    />
  </div>
</template>
<script>
import ModalConfirm from '@/components/common/ModalConfirm';
import { VueDraggableNext } from 'vue-draggable-next';

export default {
  name: 'Trip-Clip',
  emits: ['onChangeData'],
  components: { ModalConfirm, draggable: VueDraggableNext },
  props: {
    resetClip: {
      type: [Number, String],
      default: 0,
    },
    showButtonCreate: {
      type: Boolean,
      default: true,
    },
    checkDisableEdit: {
      type: Boolean,
      default: false,
    },
    tripData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      btnShowAdd: true,
      disableEdit: false,
      showBoxCreate: false,
      flgCreate: false,
      enabled: true,
      showBoxConfirm: false,
      clip_id: '',
      listClips: [],
      dragging: false,
      dataRequest: {
        id: '',
        trip_id: this.$route.params.id,
        title: '',
        url: '',
        note: '',
      },
      dataRequestOfficial: {
        id: '',
        trip_id: this.$route.params.id,
        title: '',
        url: '',
        note: '',
      },
      isSubmit: false,
      errors: {
        title: '',
        url: '',
        note: '',
      },
    };
  },
  watch: {
    showButtonCreate(val) {
      this.btnShowAdd = val;
    },
    checkDisableEdit(val) {
      this.disableEdit = val;
    },
    resetClip() {
      this.showBoxCreate = false;
      this.$emitter.emit('activeScheduleOff', true);
    },
    dataRequest: {
      handler: function (newValue) {
        this.onCheckDataChange();
        if (newValue) {
          delete this.errors.title;
          delete this.errors.url;
          delete this.errors.note;
        }
        if (this.strLen(newValue.title) > 100) {
          this.errors.title = this.$t('E-CM-111', { length: 100 });
        } else {
          delete this.errors.title;
        }
        if (this.strLen(newValue.note) > 500) {
          this.errors.note = this.$t('E-CM-111', { length: 500 });
        } else {
          delete this.errors.note;
        }
      },
      deep: true,
    },
    isSubmit: {
      handler: function () {
        this.onCheckDataChange();
      },
      deep: true,
    },
  },
  created() {
    this.disableEdit = this.checkDisableEdit;
    this.getListClips();
  },
  mounted() {
    this.$emitter.on('updateClipIcon', isIcon => {
      if (isIcon == 1) {
        this.getListClips();
      }
    });
  },
  beforeUnmount() {
    this.$emitter.off('updateClipIcon');
  },
  computed: {
    tripId() {
      return this.$route.params.id;
    },
    markers() {
      return this.$store.state.map.markers;
    },
  },
  methods: {
    resetClipDetail() {
      this.dataRequest = {
        id: '',
        trip_id: this.$route.params.id,
        title: '',
        url: '',
        note: '',
      };
      this.dataRequestOfficial = {
        id: '',
        trip_id: this.$route.params.id,
        title: '',
        url: '',
        note: '',
      };
      this.isSubmit = false;
      this.errors = {
        title: '',
        url: '',
        note: '',
      };
    },
    onCheckDataChange() {
      let result = !this.compareObject(this.dataRequest, this.dataRequestOfficial) && !this.isSubmit;
      this.$emit('onChangeData', result);
    },
    submitData() {
      if (!this.dataRequest) return;
      let flgValidate = true;

      if (this.checkEmpty(this.dataRequest.title)) {
        flgValidate = false;
        this.errors.title = this.$t('E-CM-006');
      } else {
        delete this.errors.title;
      }

      if (this.checkEmpty(this.dataRequest.url)) {
        flgValidate = false;
        this.errors.url = this.$t('E-CM-004');
      } else {
        this.errors.url = '';
      }

      if (!this.isValidHttpUrl(this.dataRequest.url)) {
        flgValidate = false;
        this.errors.url = this.$t('common.valid_url');
      } else {
        delete this.errors.url;
      }

      if (!flgValidate) return;

      if (!this.flgCreate) {
        this.updateData();
      } else {
        this.createData();
      }
    },
    createData() {
      this.isSubmit = true;
      this.startLoading();
      this.$services.common.createClip(
        this.dataRequest,
        () => {
          this.endLoading();
          this.showBoxCreate = false;
          this.flgCreate = false;
          this.resetData();
          this.$emitter.emit('activeTodo', 0);
          this.getListClips();
        },
        err => {
          if (!this.checkEmpty(err.error.url)) {
            this.errors.url = this.$t('common.valid_url');
          } else {
            this.errors.url = '';
          }
          this.endLoading();
          this.notifyError(this.$t(err.error));
        },
      );
    },
    updateData() {
      this.isSubmit = true;
      this.startLoading();
      this.$services.common.updateClip(
        this.clip_id,
        this.dataRequest,
        () => {
          this.endLoading();
          this.showBoxCreate = false;
          this.flgCreate = false;
          this.resetData();
          this.$emitter.emit('activeTodo', 0);
          this.getListClips();
        },
        err => {
          if (!this.checkEmpty(err.error.url)) {
            this.errors.url = this.$t('common.valid_url');
          } else {
            this.errors.url = '';
          }
          this.endLoading();
          this.notifyError(this.$t(err.error), true);
        },
      );
    },
    newClip() {
      this.showBoxCreate = true;
      this.flgCreate = true;
    },
    detailClip(item) {
      if (item.is_relationship_schedule === 1) {
        this.$emitter.emit('activeSchedule', item.schedules);
      } else {
        this.$emitter.emit('activeScheduleOff', true);
      }
      this.clip_id = item.id;
      this.showBoxCreate = true;
      this.flgCreate = false;
      this.dataRequest = {
        id: item.id,
        trip_id: this.$route.params.id,
        title: item.title,
        url: item.url,
        note: item.note,
        updated_at: item.updated_at,
        has_address: this.checkEmpty(item.lat) || this.checkEmpty(item.lng),
      };
      this.dataRequestOfficial = {
        id: item.id,
        trip_id: this.$route.params.id,
        title: item.title,
        url: item.url,
        note: item.note,
        updated_at: item.updated_at,
        has_address: this.checkEmpty(item.lat) || this.checkEmpty(item.lng),
      };

      const marker = this.markers.find(item => item.id == this.clip_id);
      if (marker) this.$emit('setCenterMap', { lat: marker.position.lat, lng: marker.position.lng });
    },
    closeBoxCreate() {
      this.$emitter.emit('activeScheduleOff', true);
      this.showBoxCreate = false;
      this.flgCreate = false;
      this.resetData();
    },
    resetData() {
      this.dataRequest = {
        id: '',
        trip_id: this.$route.params.id,
        title: '',
        url: '',
        note: '',
        updated_at: '',
      };
    },
    isValidHttpUrl(string) {
      let res = string.match(
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g,
      );
      if (res == null) {
        return false;
      } else {
        return true;
      }
    },
    showModalDelete(id) {
      this.clip_id = id;
      this.showBoxCreate = false;
      this.flgCreate = false;
      this.showBoxConfirm = true;
    },
    updateOrder() {
      if (this.disableEdit) return;

      let data = [];
      let order = 0;
      for (let i = 0; i < this.listClips.length; i++) {
        this.listClips[i].order = order;
        data.push({
          order: this.listClips[i].order,
          id: this.listClips[i].id,
        });
        order++;
      }

      this.$services.common.updateClipOrder(
        { data: data },
        () => {
          this.getListClips();
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    async getLatLng(id, address) {
      if (!address) return;

      await this.$services.common.searchTimeZones(
        { address },
        res => {
          if (res?.lat && res?.lng) {
            this.$store.dispatch('map/updateMarkers', [
              ...this.$store.state.map.markers,
              {
                id,
                position: { lat: res.lat, lng: res.lng },
                name: address,
              },
            ]);
          }
        },
        () => {
          return {};
        },
      );
    },
    getListClips() {
      this.$services.common.getListClipsByTrip(
        this.tripId,
        res => {
          this.listClips = res.list;
          const listMarkers = [];

          res.list.map(async item => {
            if (!this.checkEmpty(item.schedules)) {
              await this.getLatLng(item.id, item.schedules[0].location_string);
            } else if (item.lat && item.lng) {
              listMarkers.push({
                id: item.id,
                position: { lat: +item.lat, lng: +item.lng },
                name: item.title,
              });
            }
          });

          this.$store.dispatch('map/updateMarkers', listMarkers);
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    deleteClip() {
      this.isSubmit = true;
      this.startLoading();
      this.$services.common.deleteClip(
        this.clip_id,
        () => {
          this.endLoading();
          this.showBoxConfirm = false;
          this.notifySuccess(this.$t('I-OP-053'));
          this.$emitter.emit('activeTodo', 0);
          this.getListClips();
          this.resetClipDetail();
        },
        err => {
          this.endLoading();
          this.showBoxConfirm = false;
          this.notifyError(this.$t(err.error));
        },
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.display-button {
  float: right;
}
.detail--wrap.clip--detail {
  overflow: scroll;
  height: calc(100vh - 145px);
}
.info-item-no-linked {
  margin-right: 25px;
}
.clip-url a {
  width: fit-content;
  color: #3650a5 !important;
}
.clip-not-address {
  font-size: 11px;
  padding-left: 8px;
  color: #657a84;
}
.detail--title {
  display: block;
  .clip-not-address {
    padding-left: 0px !important;
  }
}
</style>
