<template>
  <Form class="expense--item">
    <div class="row">
      <div class="col-sm-4">
        <div class="form-group">
          <label>
            <span class="label-text">{{ $t('input.input_type') }}</span>
          </label>
          <div class="btn-group bootstrap-select select-block select-sm">
            <el-select
              :disabled="isDisabled"
              v-model="dataRequest.type"
              popper-class="custom-dropdown-select"
              class="select-block select-sm"
            >
              <el-option v-for="item in listTicketType" :key="item.id" :label="labelLocale(item)" :value="item.id">
              </el-option>
            </el-select>
          </div>
        </div>
      </div>

      <template v-if="[TYPE.INTERNATIONAL_TICKET, TYPE.DOMESTIC_TICKET, TYPE.STATION].includes(dataRequest.type)">
        <div class="col-sm-2" v-if="[TYPE.INTERNATIONAL_TICKET, TYPE.DOMESTIC_TICKET].includes(dataRequest.type)">
          <div class="form-group">
            <label>
              <span class="label-text">{{ $t('input.airport_type') }}</span>
            </label>
            <div class="btn-group bootstrap-select select-block select-sm">
              <el-select
                :disabled="isDisabled"
                v-model="dataRequest.airplane_type"
                popper-class="custom-dropdown-select"
                class="select-block select-sm"
              >
                <el-option :label="$t('schedule.direct')" :value="1" />
                <el-option :label="$t('schedule.connecting')" :value="2" />
              </el-select>
            </div>
          </div>
        </div>
        <div
          :class="
            [TYPE.INTERNATIONAL_TICKET, TYPE.DOMESTIC_TICKET].includes(dataRequest.type) ? 'col-sm-2' : 'col-sm-4'
          "
        >
          <div class="form-group">
            <label>
              <span class="label-text">{{ $t('input.flight_number') }}</span>
            </label>
            <div
              v-if="[TYPE.INTERNATIONAL_TICKET, TYPE.DOMESTIC_TICKET].includes(dataRequest.type)"
              class="input-with-icon input-sm"
            >
              <el-input maxlength="10" :disabled="isDisabled" v-model="dataRequest.flight_number" type="text" />
              <p v-if="errors.flight_number" class="error-feedback" v-text="errors.flight_number"></p>
              <p v-if="errors.valid_flight_number" class="error-feedback" v-text="errors.valid_flight_number"></p>
            </div>
            <div v-else class="input-with-icon input-sm">
              <el-input :disabled="isDisabled" v-model="dataRequest.train_number" type="text" />
              <p class="error-feedback" v-text="errors.train_number"></p>
            </div>
          </div>
        </div>
        <div class="col-sm-2" v-if="[1, 2].includes(dataRequest.type)">
          <div class="form-group">
            <!-- 予約クラス -->
            <label>
              <span class="label-text">{{ $t('input.booking_class') }}</span>
            </label>
            <div class="btn-group bootstrap-select select-block select-sm">
              <el-select
                :disabled="isDisabled"
                v-model="dataRequest.seat_class_type"
                popper-class="custom-dropdown-select"
                class="select-block select-sm"
              >
                <el-option v-for="item in flightClass" :key="item.id" :label="labelLocale(item)" :value="item.id">
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="col-sm-2">
          <div class="form-group">
            <label>
              <span class="label-text">{{ $t('input.seat_number') }}</span>
            </label>
            <div class="input-with-icon input-sm">
              <el-input maxlength="10" :disabled="isDisabled" v-model="dataRequest.seat_number" type="text" />
              <p class="error-feedback" v-text="errors.seat_number"></p>
            </div>
          </div>
        </div>
      </template>
      <template v-if="[TYPE.HOTEL].includes(dataRequest.type)">
        <div class="col-sm-4">
          <div class="form-group">
            <label>
              <span class="label-text">{{ $t('input.hotel_name') }}</span>
            </label>
            <div class="input-with-icon input-sm">
              <el-input maxlength="30" :disabled="isDisabled" v-model="dataRequest.hotel_name" type="text" />
              <p class="error-feedback" v-text="errors.hotel_name"></p>
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group">
            <label>
              <span class="label-text">{{ $t('input.room') }}</span>
            </label>
            <div class="input-with-icon input-sm">
              <el-input maxlength="200" :disabled="isDisabled" v-model="dataRequest.hotel_room" type="text" />
              <p class="error-feedback" v-text="errors.room"></p>
            </div>
          </div>
        </div>
      </template>
      <template v-if="[TYPE.EXPENSES].includes(dataRequest.type)">
        <div class="col-sm-8">
          <div class="form-group">
            <label>
              <span class="label-text">{{ $t('input.title') }}</span>
            </label>
            <div class="input-with-icon input-sm">
              <el-input maxlength="30" :disabled="isDisabled" v-model="dataRequest.title" type="text" />
              <p class="error-feedback" v-text="errors.title"></p>
            </div>
          </div>
        </div>
      </template>
    </div>

    <div v-if="[TYPE.INTERNATIONAL_TICKET, TYPE.DOMESTIC_TICKET, TYPE.STATION].includes(dataRequest.type)">
      <div class="row">
        <div class="col-sm-4">
          <div class="form-group form-group-typeahead">
            <label v-if="[TYPE.INTERNATIONAL_TICKET, TYPE.DOMESTIC_TICKET].includes(dataRequest.type)">
              <span class="label-text">{{ $t('schedule.departure_location') }}</span>
            </label>
            <label v-if="[TYPE.STATION].includes(dataRequest.type)">
              <span class="label-text">{{ $t('input.boarding_station') }}</span>
            </label>
            <div class="input-with-icon input-sm no-prefix">
              <el-autocomplete
                style="width: 100%"
                v-if="[TYPE.INTERNATIONAL_TICKET, TYPE.DOMESTIC_TICKET].includes(dataRequest.type)"
                class="select-block select-sm select-input"
                v-model="dataRequest.departure_location_id_name"
                :fetch-suggestions="querySearch"
                :placeholder="$t('common.city_or_airport')"
                :trigger-on-focus="false"
                :disabled="isDisabled"
                @select="handleSelectDeparture"
              >
              </el-autocomplete>
              <div v-else class="input-sm">
                <el-input
                  maxlength="10"
                  v-model="dataRequest.departure_station"
                  :disabled="isDisabled"
                  type="text"
                  :placeholder="$t('input.station_name')"
                />
                <p role="alert" class="error-feedback" v-text="errors.departure_station"></p>
              </div>
              <span
                v-if="[TYPE.INTERNATIONAL_TICKET, TYPE.DOMESTIC_TICKET].includes(dataRequest.type)"
                class="input-icon-typeahead icon-abui-pencil"
                style="display: flex; align-items: center"
              >
              </span>
            </div>
            <p role="alert" class="error-feedback" v-text="errors.departure_location"></p>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group">
            <label>
              <span class="label-text">{{ $t('schedule.start_date') }}</span>
            </label>
            <div class="input-with-icon">
              <el-date-picker
                :disabled="isDisabled"
                class="date-input"
                v-model="dataRequest.start_date"
                :clearable="false"
                type="date"
                format="YYYY/MM/DD"
                value-format="YYYY/MM/DD"
                :placeholder="$t('common.enter_or_select')"
                :disabled-date="pickerFromOptions"
              />
            </div>
            <p role="alert" class="error-feedback" v-text="errors.start_date"></p>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group">
            <label>
              <span class="label-text">{{ $t('schedule.start_time') }}</span>
            </label>
            <div class="input-with-icon">
              <el-input
                :disabled="isDisabled"
                v-model="dataRequest.start_time"
                placeholder="00:00"
                @blur="checkStartTime('start_time')"
              >
                <template #suffix>
                  <el-icon class="el-input__icon"><clock /></el-icon>
                </template>
              </el-input>
            </div>
            <p role="alert" class="error-feedback" v-text="errors.start_time"></p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4">
          <div class="form-group form-group-typeahead">
            <label v-if="[TYPE.INTERNATIONAL_TICKET, TYPE.DOMESTIC_TICKET].includes(dataRequest.type)">
              <span class="label-text">{{ $t('schedule.arrival_location') }}</span>
            </label>
            <label v-if="[TYPE.STATION].includes(dataRequest.type)">
              <span class="label-text">{{ $t('input.get_up_station') }}</span>
            </label>
            <div class="input-with-icon input-sm no-prefix">
              <el-autocomplete
                v-if="[TYPE.INTERNATIONAL_TICKET, TYPE.DOMESTIC_TICKET].includes(dataRequest.type)"
                style="width: 100%"
                class="select-block select-sm select-input"
                v-model="dataRequest.arrival_location_id_name"
                :fetch-suggestions="querySearch"
                :placeholder="$t('common.city_or_airport')"
                :trigger-on-focus="false"
                :disabled="isDisabled"
                @select="handleSelectArrival"
              >
              </el-autocomplete>
              <div v-else class="input-sm">
                <el-input
                  maxlength="10"
                  v-model="dataRequest.arrival_station"
                  :disabled="isDisabled"
                  type="text"
                  :placeholder="$t('input.station_name')"
                  @blur="updateTimeZone(dataRequest.arrival_station)"
                />
                <p role="alert" class="error-feedback" v-text="errors.arrival_station"></p>
              </div>
              <span
                v-if="[TYPE.INTERNATIONAL_TICKET, TYPE.DOMESTIC_TICKET].includes(dataRequest.type)"
                class="input-icon-typeahead icon-abui-pencil"
                style="display: flex; align-items: center"
              >
              </span>
            </div>
            <p role="alert" class="error-feedback" v-text="errors.arrival_location"></p>
          </div>
        </div>
        <div v-if="[TYPE.INTERNATIONAL_TICKET, TYPE.DOMESTIC_TICKET].includes(dataRequest.type)" class="col-sm-4">
          <div class="form-group">
            <label>
              <span class="label-text">{{ $t('schedule.end_date') }}</span>
            </label>
            <div class="input-with-icon">
              <el-date-picker
                :disabled="isDisabled"
                class="date-input"
                v-model="dataRequest.end_date"
                :clearable="false"
                type="date"
                format="YYYY/MM/DD"
                value-format="YYYY/MM/DD"
                :placeholder="$t('common.enter_or_select')"
                :disabled-date="pickerToOptionsTypePlane"
              />
            </div>
            <p role="alert" class="error-feedback" v-text="errors.end_date"></p>
          </div>
        </div>
        <div v-else class="col-sm-4">
          <div class="form-group">
            <label>
              <span class="label-text">{{ $t('schedule.end_date') }}</span>
            </label>
            <div class="input-with-icon">
              <el-date-picker
                :disabled="isDisabled"
                class="date-input"
                v-model="dataRequest.end_date"
                :clearable="false"
                type="date"
                format="YYYY/MM/DD"
                value-format="YYYY/MM/DD"
                :placeholder="$t('common.enter_or_select')"
                :disabled-date="pickerToOptions"
              />
            </div>
            <p role="alert" class="error-feedback" v-text="errors.end_date"></p>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group">
            <label>
              <span class="label-text">{{ $t('schedule.end_time') }}</span>
            </label>
            <div class="input-with-icon">
              <el-input
                :disabled="isDisabled"
                v-model="dataRequest.end_time"
                placeholder="00:00"
                @blur="checkStartTime('end_time')"
              >
                <template #suffix>
                  <el-icon class="el-input__icon"><clock /></el-icon>
                </template>
              </el-input>
            </div>
            <p v-if="errors.end_time" role="alert" class="error-feedback" v-text="errors.end_time"></p>
            <p v-if="errors.time_end_error" role="alert" class="error-feedback" v-text="errors.time_end_error"></p>
          </div>
        </div>
      </div>
      <div class="row" v-if="[TYPE.STATION].includes(dataRequest.type)">
        <div class="col-sm-4">
          <div class="form-group">
            <label>
              <span class="label-text">{{ $t('schedule.time_zone') }}</span>
            </label>
            <el-select
              v-model="dataRequest.time_zone_id"
              :disabled="isDisabled"
              name="input"
              popper-class="custom-dropdown-select"
              class="select-block select-sm"
              :placeholder="$t('common.not_specified')"
            >
              <el-option v-for="item in optionsUTC" :key="item.id" :label="this.labelLocale(item)" :value="item.id">
              </el-option>
            </el-select>
            <p role="alert" class="error-feedback" v-text="errors.time_zone"></p>
          </div>
        </div>
      </div>
    </div>

    <div v-if="dataRequest.type == TYPE.HOTEL">
      <div class="row">
        <div class="col-sm-4">
          <div class="form-group">
            <label>
              <span class="label-text">{{ $t('schedule.checkin_time') }}</span>
            </label>
            <div class="input-with-icon">
              <el-date-picker
                :disabled="isDisabled"
                class="date-input"
                v-model="dataRequest.checkin_time"
                :clearable="false"
                type="date"
                format="YYYY/MM/DD"
                value-format="YYYY/MM/DD"
                :placeholder="$t('common.enter_or_select')"
                :disabled-date="pickerFromOptionsCheckInTime"
              />
            </div>
            <p role="alert" class="error-feedback" v-text="errors.checkin_time"></p>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group">
            <label>
              <span class="label-text">{{ $t('schedule.checkout_time') }}</span>
            </label>
            <div class="input-with-icon">
              <el-date-picker
                :disabled="isDisabled"
                class="date-input"
                v-model="dataRequest.checkout_time"
                :clearable="false"
                type="date"
                format="YYYY/MM/DD"
                value-format="YYYY/MM/DD"
                :placeholder="$t('common.enter_or_select')"
                :disabled-date="pickerToOptionsCheckOutTime"
              />
            </div>
            <p role="alert" class="error-feedback" v-text="errors.checkout_time"></p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4">
          <div class="form-group">
            <label>
              <span class="label-text">{{ $t('schedule.time_zone') }}</span>
            </label>
            <el-select
              v-model="dataRequest.time_zone_id"
              :disabled="isDisabled"
              name="input"
              popper-class="custom-dropdown-select"
              class="select-block select-sm"
              :placeholder="$t('common.not_specified')"
            >
              <el-option v-for="item in optionsUTC" :key="item.id" :label="this.labelLocale(item)" :value="item.id">
              </el-option>
            </el-select>
            <p role="alert" class="error-feedback" v-text="errors.time_zone"></p>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group">
            <label>
              <span class="label-text">{{ $t('schedule.location_string') }}</span>
            </label>
            <el-input
              maxlength="100"
              v-model="dataRequest.location_string"
              :disabled="isDisabled"
              type="text"
              @blur="updateTimeZone(dataRequest.location_string)"
              :placeholder="$t('common.address_etc')"
            />
          </div>
          <p role="alert" class="error-feedback" v-text="errors.location_string"></p>
        </div>
      </div>
    </div>
    <div v-if="dataRequest.type == TYPE.EXPENSES">
      <div class="row">
        <div class="col-sm-4">
          <div class="form-group">
            <label>
              <span class="label-text">{{ $t('schedule.time_zone') }}</span>
            </label>
            <el-select
              v-model="dataRequest.time_zone_id"
              name="input"
              :disabled="isDisabled"
              popper-class="custom-dropdown-select"
              class="select-block select-sm"
              :placeholder="$t('common.not_specified')"
            >
              <el-option v-for="item in optionsUTC" :key="item.id" :label="this.labelLocale(item)" :value="item.id">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group">
            <label>
              <span class="label-text">{{ $t('schedule.location_string') }}</span>
            </label>
            <el-input
              maxlength="100"
              v-model="dataRequest.location_string"
              :disabled="isDisabled"
              type="text"
              @blur="updateTimeZone(dataRequest.location_string)"
              :placeholder="$t('common.address_etc')"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4">
          <div class="form-group">
            <!-- 日付 -->
            <label>
              <span class="label-text">{{ $t('schedule.date') }}</span>
            </label>
            <div class="input-with-icon">
              <el-date-picker
                :disabled="isDisabled"
                class="date-input"
                v-model="dataRequest.start_date_type_5"
                :clearable="false"
                type="date"
                format="YYYY/MM/DD"
                value-format="YYYY/MM/DD"
                :placeholder="$t('common.enter_or_select')"
              />
            </div>
            <p role="alert" class="error-feedback" v-text="errors.start_date_type_5"></p>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-group">
            <label><span class="label-text">&ensp;</span></label>
            <label class="checkbox checkbox-sm checkbox-inline js-check_item" id="other-schedule-item--multiple-date">
              <el-checkbox
                class="checkbox-display"
                v-model="checkedMultipleDays"
                size="large"
                :disabled="(checkCustomDisable && 'disabled') || isDisabled"
              >
                {{ $t('schedule.multiple_days') }}
              </el-checkbox>
            </label>
          </div>
        </div>
      </div>
      <div class="row" v-if="checkedMultipleDays && ![1, 2, 3, 4].includes(dataRequest.type_id)">
        <div class="col-sm-4">
          <div class="form-group">
            <label>
              <span class="label-text">{{ $t('schedule.date_end') }}</span>
            </label>
            <div class="input-with-icon">
              <el-date-picker
                class="date-input"
                v-model="dataRequest.end_date_type_5"
                :clearable="false"
                type="date"
                format="YYYY/MM/DD"
                value-format="YYYY/MM/DD"
                :disabled="isDisabled"
                :placeholder="$t('common.enter_or_select_required')"
                :disabled-date="pickerToOptions"
              />
            </div>
            <p role="alert" class="error-feedback" v-text="errors.end_date_type_5"></p>
          </div>
        </div>
      </div>
    </div>
    <!-- <div>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label>
              <span class="label-text">{{ $t('input.note') }}</span>
            </label>
            <textarea
              :disabled="isDisabled"
              v-model="dataRequest.note"
              class="form-control"
              rows="2"
              :placeholder="$t('input.pl_note')"
            >
            </textarea>
          </div>
        </div>
      </div>
    </div> -->
  </Form>
</template>
<script>
import moment from 'moment';
import { Form } from 'vee-validate';
import { Clock } from '@element-plus/icons-vue';
import { FLIGHT_CLASS } from '@/constants/';

export default {
  components: {
    Form,
    Clock,
  },
  props: {
    submitFromParent: {
      type: Boolean,
      default: false,
    },
    listTicketType: {
      type: Array,
      default: () => [],
      required: true,
    },
    listAirport: {
      type: Array,
      default: () => [],
      required: true,
    },
    listStation: {
      type: Array,
      default: () => [],
      required: true,
    },
    optionsUTC: {
      type: Array,
      default: () => [],
      required: true,
    },
    currencySelect: {
      type: Object,
      default: () => {},
      required: true,
    },
    listTaxClassification: {
      type: Array,
      default: () => [],
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: 0,
    },
    dataForm: {
      type: Object,
      default: () => {},
    },
    nameAirportDeparture: {
      type: String,
      default: '',
    },
    nameAirportArrival: {
      type: String,
      default: '',
    },
  },
  computed: {},
  watch: {
    // dataRequest: {
    //   handler: function (newValue) {
    //     if (newValue) {
    //       this.$emit('listDataInput', this.dataRequest, this.index);
    //     }
    //   },
    //   deep: true,
    // },
    dataForm: {
      handler: function (newValue) {
        this.dataRequest = {
          id: newValue.id ? newValue.id : '',
          type: newValue.type,
          airplane_type: newValue.airplane_type ? newValue.airplane_type : 1,
          flight_number: newValue.flight_number,
          train_number: newValue.train_number,
          seat_class_type: newValue.seat_class_type ? newValue.seat_class_type : 1,
          seat_number: newValue.seat_number,
          start_date: newValue.departure_date ? newValue.departure_date : '',
          start_date_type_5: newValue.start_date ? newValue.start_date : '',
          end_date_type_5: newValue.end_date ? newValue.end_date : '',
          end_date: newValue.arrival_date ? newValue.arrival_date : '',
          start_time: newValue.departure_time,
          end_time: newValue.arrival_time,
          departure_location_id: newValue.departure_location_id,
          departure_location_id_name: this.getNameAirport(newValue.departure_location_id),
          arrival_location_id: newValue.arrival_location_id,
          arrival_location_id_name: this.getNameAirport(newValue.arrival_location_id),
          amount: newValue.amount,
          hotel_name: newValue.hotel_name,
          checkin_time: newValue.checkin_time ? newValue.checkin_time : '',
          checkout_time: newValue.checkout_time ? newValue.checkout_time : '',
          hotel_room: newValue.room,
          tax_classification: newValue.tax_classification,
          tax_amount: newValue.tax_amount,
          title: newValue.title,
          departure_station: newValue.departure_station,
          arrival_station: newValue.arrival_station,
          time_zone_id: newValue.time_zone_id,
          location_string: newValue.location_string,
        };
      },
      deep: true,
    },
    nameAirportDeparture: {
      handler: function (val) {
        this.dataRequest.departure_location_id_name = val;
      },
    },
    nameAirportArrival: {
      handler: function (val) {
        this.dataRequest.arrival_location_id_name = val;
      },
    },
    'dataRequest.type'(val) {
      if (val) {
        this.errors = {};
      }
      if (val === 5) {
        if (this.dataRequest.end_date) {
          this.checkedMultipleDays = true;
        }
      }
    },
    'dataRequest.start_date'(val) {
      if (val && !this.dataRequest.end_date) {
        this.dataRequest.end_date = val;
      }
    },
    'dataRequest.checkin_time'(val) {
      if (val) {
        this.errors = {};
      }
      if (val && !this.dataRequest.checkout_time) {
        this.dataRequest.checkout_time = val;
      }
    },
    'dataRequest.checkout_time'(val) {
      if (val) {
        this.errors = {};
      }
    },
    submitFromParent() {
      if (this.dataRequest.type === 5) {
        this.dataRequest.start_date = this.dataRequest.start_date_type_5;
        this.dataRequest.end_date = this.dataRequest.end_date_type_5;
      }
      if (this.dataRequest.type === 4 && this.checkEmpty(this.dataRequest.checkin_time)) {
        this.errors.checkin_time = this.$t('E-CM-100', {
          item: this.$t('schedule.checkin_time'),
        });
      } else {
        delete this.errors.checkin_time;
      }
      if (this.dataRequest.type === 4 && this.checkEmpty(this.dataRequest.checkout_time)) {
        this.errors.checkout_time = this.$t('E-CM-100', {
          item: this.$t('schedule.checkout_time'),
        });
      } else {
        delete this.errors.checkout_time;
      }

      if (
        this.dataRequest.type === 4 &&
        !this.checkEmpty(this.dataRequest.checkin_time) &&
        !this.checkEmpty(this.dataRequest.checkout_time)
      ) {
        if (this.dataRequest.checkin_time >= this.dataRequest.checkout_time) {
          this.errors.checkin_time = this.$t('schedule.checkin_time_more_than_checkout');
        } else {
          delete this.errors.checkin_time;
        }
      }

      if ([1, 2, 3].includes(this.dataRequest.type) && this.checkEmpty(this.dataRequest.start_date)) {
        this.errors.start_date = this.$t('E-CM-100', {
          item: this.$t('schedule.start_date'),
        });
      } else {
        delete this.errors.start_date;
      }

      if ([1, 2, 3].includes(this.dataRequest.type) && this.checkEmpty(this.dataRequest.end_date)) {
        this.errors.end_date = this.$t('E-CM-100', {
          item: this.$t('schedule.end_date'),
        });
      } else {
        delete this.errors.end_date;
      }

      if ([5].includes(this.dataRequest.type) && this.checkEmpty(this.dataRequest.start_date_type_5)) {
        this.errors.start_date_type_5 = this.$t('E-CM-100', {
          item: this.$t('schedule.date'),
        });
      } else {
        delete this.errors.start_date_type_5;
      }

      if (
        [5].includes(this.dataRequest.type) &&
        this.checkedMultipleDays &&
        this.checkEmpty(this.dataRequest.end_date_type_5)
      ) {
        this.errors.end_date_type_5 = this.$t('E-CM-100', {
          item: this.$t('schedule.date_end'),
        });
      } else {
        delete this.errors.end_date_type_5;
      }

      this.$emit('cost-emit', this.dataRequest, this.errors, this.index);
    },
  },
  data() {
    return {
      checkedMultipleDays: false,
      customPrefix: {},
      dataRequest: {},
      errors: {},
      TYPE: {
        INTERNATIONAL_TICKET: 1,
        DOMESTIC_TICKET: 2,
        STATION: 3,
        HOTEL: 4,
        EXPENSES: 5,
      },
      flightClass: FLIGHT_CLASS,
    };
  },
  created() {
    this.syncDataRequest();

    // this.dataRequest = {
    //   id: this.dataForm.id ? this.dataForm.id : '',
    //   type: this.dataForm.type,
    //   airplane_type: this.dataForm.airplane_type ? this.dataForm.airplane_type : 1,
    //   flight_number: this.dataForm.flight_number,
    //   train_number: this.dataForm.train_number,
    //   seat_class_type: this.dataForm.seat_class_type ? this.dataForm.seat_class_type : 1,
    //   seat_number: this.dataForm.seat_number,
    //   start_date: this.dataForm.departure_date,
    //   start_date_type_5: this.dataForm.start_date,
    //   end_date_type_5: this.dataForm.end_date,
    //   end_date: this.dataForm.arrival_date,
    //   start_time: this.dataForm.departure_time,
    //   end_time: this.dataForm.arrival_time,
    //   departure_location_id: this.dataForm.departure_location_id,
    //   departure_location_id_name: this.getNameAirport(this.dataForm.departure_location_id),
    //   arrival_location_id: this.dataForm.arrival_location_id,
    //   arrival_location_id_name: this.getNameAirport(this.dataForm.arrival_location_id),
    //   amount: this.dataForm.amount,
    //   hotel_name: this.dataForm.hotel_name,
    //   checkin_time: this.dataForm.checkin_time ? this.dataForm.checkin_time : '',
    //   checkout_time: this.dataForm.checkout_time ? this.dataForm.checkout_time : '',
    //   hotel_room: this.dataForm.room,
    //   tax_classification: this.dataForm.tax_classification,
    //   tax_amount: this.dataForm.tax_amount,
    //   title: this.dataForm.title,
    //   departure_station: this.dataForm.departure_station,
    //   arrival_station: this.dataForm.arrival_station,
    //   time_zone_id: this.dataForm.time_zone_id,
    //   location_string: this.dataForm.location_string,
    // };
  },
  methods: {
    syncDataRequest() {
      this.dataRequest = {
        id: this.dataForm.id ? this.dataForm.id : '',
        type: this.dataForm.type,
        airplane_type: this.dataForm.airplane_type ? this.dataForm.airplane_type : 1,
        flight_number: this.dataForm.flight_number,
        train_number: this.dataForm.train_number,
        seat_class_type: this.dataForm.seat_class_type ? this.dataForm.seat_class_type : 1,
        seat_number: this.dataForm.seat_number,
        start_date: this.dataForm.departure_date ? this.dataForm.departure_date : '',
        start_date_type_5: this.dataForm.start_date ? this.dataForm.start_date : '',
        end_date_type_5: this.dataForm.end_date ? this.dataForm.end_date : '',
        end_date: this.dataForm.arrival_date ? this.dataForm.arrival_date : '',
        start_time: this.dataForm.departure_time,
        end_time: this.dataForm.arrival_time,
        departure_location_id: this.dataForm.departure_location_id,
        departure_location_id_name: this.getNameAirport(this.dataForm.departure_location_id),
        arrival_location_id: this.dataForm.arrival_location_id,
        arrival_location_id_name: this.getNameAirport(this.dataForm.arrival_location_id),
        amount: this.dataForm.amount,
        hotel_name: this.dataForm.hotel_name,
        checkin_time: this.dataForm.checkin_time ? this.dataForm.checkin_time : '',
        checkout_time: this.dataForm.checkout_time ? this.dataForm.checkout_time : '',
        hotel_room: this.dataForm.room,
        tax_classification: this.dataForm.tax_classification,
        tax_amount: this.dataForm.tax_amount,
        title: this.dataForm.title,
        departure_station: this.dataForm.departure_station,
        arrival_station: this.dataForm.arrival_station,
        time_zone_id: this.dataForm.time_zone_id,
        location_string: this.dataForm.location_string,
      };
    },
    querySearch(queryString, cb) {
      var links = this.listAirport;
      var results = queryString ? links.filter(this.createFilter(queryString)) : links;
      // call callback function to return suggestions
      cb(results);
    },
    createFilter(queryString) {
      return link => {
        return link.value.toLowerCase().includes(queryString.toLowerCase());
      };
    },
    handleSelectDeparture(item) {
      this.dataRequest.departure_location_id = item.id;
    },
    handleSelectArrival(item) {
      this.dataRequest.arrival_location_id = item.id;
    },
    getNameAirport(id) {
      var nameAirport = '';
      this.listAirport.filter(item => {
        if (item.id === id) {
          return (nameAirport = item.name);
        }
      });
      return nameAirport;
    },
    isValidFlightNumber(string) {
      let res = string.match(/^[A-Z|a-z]{1}[A-Z|a-z|0-9]{1}[0-9]{1,5}$/g);
      if (res == null) {
        return false;
      } else {
        return true;
      }
    },
    pickerFromOptions(time) {
      if (!this.dataRequest.start_date)
        return moment(new Date(time)).format('YYYY-MM-DD') < moment(new Date()).format('YYYY-MM-DD');

      return moment(new Date(time)).format('YYYY-MM-DD') > moment(this.dataRequest?.end_date).format('YYYY-MM-DD');
    },
    pickerToOptions(time) {
      if (!this.dataRequest.start_date)
        return moment(new Date(time)).format('YYYY-MM-DD') < moment(new Date()).format('YYYY-MM-DD');

      return moment(new Date(time)).format('YYYY-MM-DD') < moment(this.dataRequest?.start_date).format('YYYY-MM-DD');
    },

    pickerFromOptionsCheckInTime(time) {
      if (!this.dataRequest.checkin_time)
        return moment(new Date(time)).format('YYYY-MM-DD') < moment(new Date()).format('YYYY-MM-DD');

      return moment(new Date(time)).format('YYYY-MM-DD') > moment(this.dataRequest?.checkout_time).format('YYYY-MM-DD');
    },
    pickerToOptionsCheckOutTime(time) {
      if (!this.dataRequest.checkin_time)
        return moment(new Date(time)).format('YYYY-MM-DD') < moment(new Date()).format('YYYY-MM-DD');

      return moment(new Date(time)).format('YYYY-MM-DD') < moment(this.dataRequest?.checkin_time).format('YYYY-MM-DD');
    },
    pickerToOptionsTypePlane(time) {
      if (!this.checkEmpty(this.dataRequest.start_date))
        return (
          moment(new Date(time)).format('YYYY-MM-DD') <
            moment(new Date(this.dataRequest.start_date)).add(-2, 'days').format('YYYY-MM-DD') ||
          moment(new Date(time)).format('YYYY-MM-DD') >
            moment(new Date(this.dataRequest.start_date)).add(2, 'days').format('YYYY-MM-DD')
        );

      return moment(new Date(time)).format('YYYY-MM-DD') < moment(this.dataRequest?.start_date).format('YYYY-MM-DD');
    },
    checkStartTime(key) {
      let val = this.dataRequest[key];
      if (val) {
        val = this.autoFormatTime(val);
        if (this.isValidTime(val)) {
          delete this.errors[key];
        } else {
          this.errors[key] = this.$t('input.time_error');
        }

        this.dataRequest[key] = val;

        return;
      }
      delete this.errors[key];
    },
    updateTimeZone(address) {
      // if (![1, 2].includes(this.dataRequest.type_id) && !this.dataRequest.location_string) return;

      this.$services.common.searchTimeZones(
        { address },
        res => {
          if (!this.checkEmpty(res.lat)) {
            this.dataRequest.time_zone_id = res.id;
          } else {
            this.dataRequest.time_zone_id = '';
          }
        },
        () => {
          this.dataRequest.time_zone_id = '';
        },
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.no-prefix .el-input__suffix {
  display: none;
}
.select-icon .el-input__wrapper {
  padding-right: 30px !important;
}
.select-input .el-input .el-input__wrapper .el-input__inner {
  padding-right: 25px !important;
}
.checkbox-display {
  font-weight: normal;
  font-size: 13px;
  line-height: 0.9999999975;
  color: #657a84;
  max-width: 100%;
  margin-bottom: 8px;
  display: inline-flex !important;
}
</style>
