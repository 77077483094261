import BaseService from './BaseService';
import API_CODE from '../utils/api_code.js';

export default class DailyAllowanceAPI extends BaseService {
  async getDailyAllowanceSchedule(success, error) {
    await this.get(API_CODE.CU_030, success, error);
  }
  async getDetailDailyAllowance(params, success, error) {
    await this.get(API_CODE.CU_034 + this.urlParse(params), success, error);
  }
  async updateDailyAllowance(params, success, error) {
    await this.post(API_CODE.CU_031, params, success, error);
  }
  async getDailyAllowanceHotel(success, error) {
    await this.get(API_CODE.CU_221, success, error);
  }
}
