<template>
  <el-select
    v-model="selectedList"
    :popper-append-to-body="false"
    popper-class="custom-dropdown-select"
    @visible-change="resetDataSelect"
    multiple
    clearable
    :title="displayByLocale ? $t('common.all') : $t('common.not_specified')"
    :placeholder="displayByLocale ? $t('common.all') : $t('common.not_specified')"
  >
    <div v-if="isDisplaySearch" class="bs-searchbox" onclick="event.stopPropagation()">
      <input
        type="text"
        class="form-control"
        autocomplete="off"
        v-model="searchName"
        role="tetxbox"
        aria-label="Search"
      />
    </div>
    <div v-if="!checkEmpty(filteredSearchData)">
      <el-option-group
        v-for="(dataItem, dataIndex) in filteredSearchData"
        :key="dataItem.name"
        :label="$t(dataItem.name)"
        @onclick="event.preventDefault()"
      >
        <div v-if="!checkEmpty(dataItem.select_all_text)" onclick="event.stopPropagation()" class="border">
          <li
            class="el-select-dropdown__item"
            :class="isCheckAllList[dataIndex] && 'selected'"
            @click="handleSelectAll(dataIndex, !isCheckAllList[dataIndex])"
          >
            <div class="el-custom-select-dropdown">
              <label class="checkbox no-label no-hover" :class="isCheckAllList[dataIndex] && 'checked'">
                <div>
                  <span class="icons dropdown-option-icon">
                    <span class="first-icon icon-abui-checkbox-unchecked"></span>
                    <span class="second-icon icon-abui-checkbox-checked"></span>
                  </span>
                </div>
              </label>
              <span class="dropdown-option-name select-all-option">{{ $t(`${dataItem.select_all_text}`) }}</span>
            </div>
          </li>
          <li class="divider" />
        </div>
        <el-option
          v-for="item in dataItem.data"
          :key="item.id"
          :label="displayByLocale ? this.labelLocale(item) : item.name"
          :value="getListName(dataItem.name) + '#' + item.id"
        >
          <div class="el-custom-select-dropdown">
            <label
              :class="
                selectedList.includes(getListName(dataItem.name) + '#' + item.id)
                  ? 'checkbox no-label no-hover checked'
                  : 'checkbox no-label no-hover'
              "
            >
              <div>
                <span class="icons dropdown-option-icon">
                  <span class="first-icon icon-abui-checkbox-unchecked"></span>
                  <span class="second-icon icon-abui-checkbox-checked"></span>
                </span>
              </div>
            </label>
            <span class="dropdown-option-name"> {{ displayByLocale ? this.labelLocale(item) : item.name }}</span>
          </div>
        </el-option>
      </el-option-group>
    </div>
    <div v-else-if="checkEmpty(filteredSearchData) && !checkEmpty(listData)">
      <el-option value="" disabled>{{ " '" + searchName + "'" + $t('common.no_results_matched') }}</el-option>
    </div>
    <div v-else>
      <p class="no-data">{{ $t('common.no_data') }}</p>
    </div>
  </el-select>
</template>
<script>
export default {
  name: 'MultiSelectExpanded',
  emits: ['handleSelectedParams'],
  props: {
    listData: {
      type: Array,
      default: () => [],
    },
    displayByLocale: {
      type: Boolean,
      default: false,
    },
    isDisplaySearch: {
      type: Boolean,
      default: false,
    },
    defaultList: {
      type: Array,
      default: () => [],
    },
    parentSelected: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      searchName: '',
      selectedList: [],
    };
  },
  created() {
    this.asyncSelectedList();
  },
  computed: {
    filteredSearchData() {
      return this.listData;
    },
    searchActivated() {
      return this.$route.query.is_search_activated || 0;
    },
    isCheckAllList() {
      let flagList = [];
      if (!this.checkEmpty(this.filteredSearchData)) {
        this.filteredSearchData.forEach(item => {
          const result = item.data.every(val => {
            return this.selectedList.includes(this.getListName(item.name) + '#' + val.id);
          });
          flagList.push(result);
        });
      }
      return flagList;
    },
  },
  watch: {
    selectedList: {
      handler(newVal) {
        this.$emit('handleSelectedParams', newVal);
      },
      deep: true,
    },
  },
  methods: {
    handleSelectAll(index, newVal) {
      if (newVal) {
        this.filteredSearchData[index].data.forEach(val => {
          if (!this.selectedList.includes(this.getListName(this.filteredSearchData[index].name) + '#' + val.id)) {
            this.selectedList.push(this.getListName(this.filteredSearchData[index].name) + '#' + val.id);
          }
        });
      } else {
        if (!this.checkEmpty(this.selectedList)) {
          const cloneArr = this.selectedList.filter(item => {
            return !item.includes(this.getListName(this.filteredSearchData[index].name));
          });
          this.selectedList = cloneArr;
        }
      }
    },
    asyncSelectedList() {
      this.selectedList = [];
      if (this.searchActivated || this.searchActivated == '1') {
        if (this.parentSelected.length > 0) {
          this.parentSelected.forEach(ele => ele.data.forEach(item => this.selectedList.push(ele.name + '#' + item)));
          return;
        } else {
          this.selectedList = [];
          return;
        }
      } else if ((!this.searchActivated || this.searchActivated != '1') && this.defaultList.length > 0) {
        this.defaultList.forEach(ele => ele.data.forEach(item => this.selectedList.push(ele.name + '#' + item)));
        return;
      } else {
        this.selectedList = [];
      }
    },
    resetDataSelect() {
      this.searchName = '';
    },
    getListName(localeStr) {
      return localeStr.split('.').pop();
    },
    clearFilter() {
      if (this.defaultList.length > 0) {
        this.defaultList.forEach(ele => ele.data.forEach(item => this.selectedList.push(ele.name + '#' + item)));
        return;
      }
      this.selectedList = [];
    },
  },
};
</script>
<style scoped>
.custom-dropdown-select li.el-select-dropdown__item.selected {
  background: #3650a5 !important;
  color: #fff !important;
}
.custom-dropdown-select li.el-select-dropdown__item.selected:hover {
  background: #5e73b7 !important;
  color: #fff !important;
}
.dropdown-option-icon {
  margin-top: 50%;
}
.dropdown-option-icon span.first-icon.icon-abui-checkbox-unchecked {
  font-size: 15px;
  color: rgba(152, 169, 176, 0.5);
}
.dropdown-option-icon span.second-icon.icon-abui-checkbox-checked {
  font-size: 15px;
  color: rgba(255, 255, 255, 0.8) !important;
}
.dropdown-option-name {
  padding-left: 2.7rem;
}
.bs-searchbox {
  margin-bottom: 5px;
  padding-top: 0;
}
.no-hover {
  pointer-events: none !important;
}
.dropdown-option-name {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.select-all-option {
  font-weight: bold;
}
</style>
