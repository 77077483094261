import BaseService from './BaseService';
import API_CODE from '../utils/api_code.js';

export default class ReportAPI extends BaseService {
  async getReportFile(params, success, error) {
    await this.get(API_CODE.CU_016 + this.urlParse(params), success, error);
  }
  async createReport(params, success, error) {
    await this.post(API_CODE.CU_063, params, success, error);
  }
  async deleteReport(id, success, error) {
    await this.delete(API_CODE.CU_323 + id, {}, success, error);
  }
}
