<template>
  <div class="viewer-item">
    <div class="wrapper with-navbar-fixed-bottom" style="padding-bottom: 100px">
      <div v-if="inputDetail.status == 1" :class="inputDetail.status == 1 && 'overlay'">
        <button class="btn btn-hg btn-primary js-btn--incharge open-alert hide--inprogress" @click="editingInput">
          <i class="icon-abui-play prx"></i>
          {{ $t('input.while_typing') }}
        </button>
        <div class="overlay--msg show--inprogress" style="display: none">{{ $t('input.another_person') }}</div>
      </div>
      <div
        v-if="inputDetail.status == 2 && inputDetail.user_action_id !== userLoginId && !isAnother"
        class="alert alert-center alert-default al--inprogress alert-dismissible show-alert"
        role="alert"
        data-status="1"
      >
        <span class="alert-message">
          {{ $t('input.another_person') }}
          <div class="mtm">
            <a href="#" @click="goToNextInput" class="alert-link" data-hide="alert">
              {{ $t('input.next_input') }}
              <i class="icon-abui-arrow-right plx"> </i>
            </a>
          </div>
        </span>
      </div>

      <div v-if="inputDetail.status == 2 && isAnother && showButtonAnother" class="overlay">
        <button class="btn btn-hg btn-primary js-btn--incharge open-alert hide--inprogress" @click="goToNextInput">
          <i class="icon-abui-play prx"></i>
          {{ $t('input.while_typing') }}
        </button>
      </div>

      <div class="with-sidebar">
        <div class="trip-info-col bg-gray">
          <div style="overflow: hidden; height: 889px; cursor: move" @mousedown="mousedown">
            <div class="btn-group trip--receipt--control">
              <button type="button" class="btn btn-sm btn-inverse hide--voucher" @click="rotate = rotate - 90">
                <i class="icon-abui-rotate"></i>
              </button>
              <button type="button" class="btn btn-sm btn-inverse" @click="showTabPreview()">
                <i class="icon-abui-expand"></i>
              </button>
              <button type="button" class="btn btn-sm btn-inverse" @click="zoomOut()">
                <i class="icon-abui-zoomout"></i>
              </button>
              <button type="button" class="btn btn-sm btn-inverse" @click="zoom = zoom + 0.25">
                <i class="icon-abui-zoomin"></i>
              </button>
            </div>
            <div
              class="trip--receipt hide--new"
              style="height: 665px; top: 16px; overflow: unset"
              :style="styleRotate"
              id="componentMove"
            >
              <div
                v-if="inputDetail?.status !== '4'"
                class="ocr--receipt"
                :class="isTypePDF && 'pdf'"
                :style="styleImage"
                @mousedown="mousedown"
              >
                <a
                  v-for="(data, index) in dataContent"
                  :key="index"
                  class="ocr--receipt--item"
                  :style="processStyle(data)"
                  @click="copyValue(data.text)"
                />
              </div>
              <VuePdfEmbed
                id="elMove"
                style="
                  cursor: move;
                  position: absolute;
                  width: auto;
                  height: auto;
                  left: 50%;
                  transform: translateX(-50%);
                  top: 30px;
                "
                ref="pdfRef"
                v-if="isTypePDF"
                :width="totalWidth"
                :height="totalHeight"
                :source="{
                  cMapUrl: 'https://cdn.jsdelivr.net/npm/pdfjs-dist@2.16.105/cmaps/',
                  cMapPacked: true,
                  url: inputDetail.file_path,
                }"
                @rendered="listLoadedDataPDF"
                @mousedown="mousedown"
              />
              <img id="elMove" v-else :src="inputDetail.file_path" :style="styleImage" />
            </div>
          </div>

          <div
            :class="inputDetail.file_id ? 'text-entry-box' : 'text-entry-box only-text-entry'"
            v-if="inputDetail.content_reservation && checkEmpty(inputDetail.file_id)"
            style="top: 47%; height: 285px !important; overflow: unset !important"
          >
            <div class="title-text-entry">{{ $t('input.text_entry') }}</div>
            <div class="content-text-entry"></div>
          </div>
          <div
            :class="inputDetail.file_id ? 'text-entry-box' : 'text-entry-box only-text-entry'"
            v-if="inputDetail.content_reservation"
          >
            <div class="title-text-entry" v-if="inputDetail.content_reservation && inputDetail.file_id">
              {{ $t('input.text_entry') }}
            </div>
            <div
              class=""
              :class="
                inputDetail.content_reservation && inputDetail.file_id
                  ? 'content-text-entry pd-top-52p'
                  : 'content-text-entry'
              "
            >
              {{ inputDetail.content_reservation }}
            </div>
          </div>
        </div>

        <div class="plan-stage">
          <div
            :style="stylePlan(inputDetail.status)"
            :class="inputDetail.status === '4' ? 'plan-status status-done' : 'plan-status'"
          >
            <span class="plan--staff">{{ inputDetail.user_action_name }}</span>
            <span class="status-text">
              {{ getInputStatusLabel(inputDetail.status) }}
              <i class="icon-abui-check-inverted plx"></i>
            </span>
          </div>

          <div
            class="section noborder"
            id="section--main"
            :validation-schema="validate"
            ref="form"
            style="margin-bottom: 50px"
          >
            <Field name="payment_method" type="hidden" v-model="inputDetail.payment_method" />
            <div class="expense--uploaded">
              <ul class="uploaded--file-list">
                <li>
                  <i class="icon-abui-file" v-if="inputDetail.file_name"></i>
                  <i class="icon-abui-pencil" v-else></i>
                  <span class="file--name"
                    >{{ inputDetail.file_name ? inputDetail.file_name : inputDetail.content_reservation }}
                  </span>
                  <span class="file--date">{{ inputDetail.created_at }} </span>
                </li>
              </ul>
            </div>

            <div class="expense--detail">
              <div class="mbl">
                <router-link
                  v-if="!checkEmpty(inputDetail.trip_id)"
                  :to="{
                    name: this.PAGES.TRIP_UPDATE,
                    params: {
                      id: inputDetail.trip_id,
                    },
                  }"
                  target="_blank"
                  rel="noopener"
                >
                  <a> <i class="icon-abui-globe prx"></i>{{ inputDetail.trip_code }} ：{{ inputDetail.trip_name }} </a>
                </router-link>
                <div v-if="!checkEmpty(inputDetail.company_id)" style="font-size: 15px">
                  <router-link
                    :to="{
                      name: this.PAGES.CUSTOMER_ENTERPRISE_DETAIL,
                      params: {
                        id: inputDetail.company_id || 0,
                      },
                    }"
                    target="_blank"
                    rel="noopener"
                  >
                    <a class="cursor-pointer"> <i class="icon-abui-company prx"></i>{{ inputDetail.company_name }} </a>
                  </router-link>
                </div>

                <div v-if="!checkEmpty(inputDetail.user_id)" style="font-size: 15px">
                  <span> <i class="prx icon-abui-user"></i>{{ inputDetail?.user_name }} </span>
                </div>
              </div>

              <div v-if="inputDetail.note" style="margin-bottom: 15px">
                <label>
                  <span class="label-text">{{ $t('input.note_input') }}</span>
                </label>
                <div style="white-space: pre-wrap">
                  <div class="regulation--item--applied input-note">
                    <span class="label-text">{{ inputDetail.note }}</span>
                  </div>
                  <div class="regulation--item--reffered"></div>
                </div>
              </div>

              <div class="row" v-if="inputDetail.type === '1'">
                <div class="col-sm-4">
                  <div class="form-group">
                    <label>
                      <span class="label-text">{{ $t('input.payment_method') }}</span>
                    </label>
                    <div class="btn-group bootstrap-select select-block select-sm">
                      <el-select
                        :disabled="isDisabled"
                        popper-class="custom-dropdown-select"
                        v-model="inputDetail.payment_method"
                        :clearable="false"
                      >
                        <el-option
                          v-for="item in listPaymentMethod"
                          :key="item.id"
                          :label="labelLocale(item)"
                          :value="item.id"
                        />
                      </el-select>
                    </div>
                    <ErrorMessage name="payment_method" class="error-feedback" as="p" />
                  </div>
                </div>

                <div class="col-sm-8">
                  <div class="form-group">
                    <label>
                      <span class="label-text">{{ $t('input.payable_to') }}</span>
                    </label>
                    <input
                      name="payable_to"
                      type="text"
                      style="background: white"
                      class="form-control input-sm"
                      v-model="inputDetail.payable_to"
                      :disabled="isDisabled"
                      maxlength="30"
                    />
                    <ErrorMessage name="payable_to" class="error-feedback" as="p" />
                  </div>
                </div>
              </div>

              <div class="row" v-if="inputDetail.type === '1'">
                <div class="col-sm-4">
                  <div class="form-group">
                    <label>
                      <span class="label-text">{{ $t('input.currency') }}</span>
                    </label>
                    <div class="btn-group bootstrap-select select-block select-sm js-select_item">
                      <el-select
                        :disabled="isDisabled"
                        popper-class="custom-dropdown-select"
                        v-model="inputDetail.currency_id"
                        :clearable="false"
                      >
                        <el-option
                          v-for="item in currencyList"
                          :key="item.id"
                          :label="labelLocale(item) + '(' + item.code + ')'"
                          :value="item.id"
                        />
                      </el-select>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label>
                      <span class="label-text">{{ $t('input.total_amount') }}</span>
                    </label>
                    <div class="input-group">
                      <span class="input-group-addon">
                        <span class="js-select_item-currency__info-col__tgt">
                          {{ signalCurrency }}
                        </span>
                      </span>
                      <input
                        :disabled="true"
                        type="text"
                        style="background: white; text-align: right"
                        class="form-control input-sm"
                        :value="totalInputAmount"
                        maxlength="14"
                        onkeydown="javascript: return event.keyCode !== 69;"
                        @change="convertData(inputDetail.currency_amount)"
                      />
                    </div>
                    <p
                      class="help-block js-select_item-currency__info-col__tgt amount-in-jpy--wrap text-right"
                      v-show="inputDetail.currency_id && inputDetail.currency_id != 1"
                    >
                      <span>
                        <span>{{ $t('trip.yen') }}</span>
                        <span class="plx">{{ calculateTotalAmount() }}</span>
                        <span class=""> {{ ` (${currentRate})` }}</span>
                      </span>
                    </p>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label>
                      <span class="label-text">{{ $t('expense_cost.payment_date') }}</span>
                    </label>
                    <div class="input-with-icon">
                      <el-date-picker
                        class="date-input"
                        :disabled="isDisabled"
                        v-model="inputDetail.payment_date"
                        :clearable="false"
                        type="date"
                        format="YYYY/MM/DD"
                        value-format="YYYY/MM/DD"
                        :placeholder="$t('common.enter_or_select')"
                      />
                    </div>
                    <Field name="payment_date" type="hidden" v-model="inputDetail.payment_date" />
                    <ErrorMessage name="payment_date" class="error-feedback" as="p" />
                  </div>
                </div>
              </div>

              <div class="show--voucher hide--expense" style="display: grid">
                <hr class="mts mbl" v-if="inputDetail.type === '1'" />

                <!-- COST -->
                <div>
                  <template v-if="inputDetail.type === '1'">
                    <template v-for="(item, index) in inputDetail.items" :key="index">
                      <div class="col-sm-10 col-md-11">
                        <InputCostForm
                          :index="index"
                          :currencyRateSelected="currentRate"
                          :dataForm="item"
                          :listCostItem="listCostItem"
                          :listTaxClassification="listTaxClassification"
                          :currencySelect="currencySelect"
                          :isDisabled="isDisabled"
                          :submitFromParent="submitFromParent"
                          @cost-emit="handleEmitChild"
                          @amount-emit="handleAmount"
                          @listDataInput="handleListDataInput"
                        />
                        <hr class="mts mbl" />
                      </div>
                      <div v-if="!isDisabled" class="col-sm-2 col-md-1" style="padding-top: 80px">
                        <a @click="deleteItem(item, index)" class="btn btn-sm btn-flat-danger btn-block">
                          <span class="icon-abui-cross"></span>
                        </a>
                      </div>
                    </template>
                  </template>

                  <!-- RESERVATION_SLIP -->
                  <template v-if="inputDetail.type === '2'">
                    <template v-for="(item, index) in inputDetail.items" :key="index">
                      <div class="col-sm-10 col-md-11">
                        <ReservationSlipForm
                          :index="index"
                          :dataForm="item"
                          :nameAirportDeparture="nameAirportDeparture(item)"
                          :nameAirportArrival="nameAirportArrival(item)"
                          :listTicketType="listTicketType"
                          :listAirport="listAirport"
                          :listStation="listStation"
                          :optionsUTC="optionsUTC"
                          :listTaxClassification="listTaxClassification"
                          :currencySelect="currencySelect"
                          :isDisabled="isDisabled"
                          :submitFromParent="submitFromParent"
                          @cost-emit="handleEmitChild"
                          @listDataInput="handleListDataInput"
                        />
                        <hr class="mts mbl" />
                      </div>
                      <div v-if="!isDisabled" class="col-sm-2 col-md-1" style="padding-top: 80px">
                        <a @click="deleteItem2(item, index)" class="btn btn-sm btn-flat-danger btn-block">
                          <span class="icon-abui-cross"></span>
                        </a>
                      </div>
                    </template>
                  </template>
                </div>

                <div class="row--addcontrol" v-if="!isDisabled">
                  <a
                    v-if="inputDetail.type === '1'"
                    class="btn btn-sm btn-flat-primary cursor-pointer"
                    @click="addItemType1"
                  >
                    <span class="icon-abui-plus prx"></span>{{ $t('input.add_item') }}
                  </a>
                  <a v-else class="btn btn-sm btn-flat-primary cursor-pointer" @click="addItemType2">
                    <span class="icon-abui-plus prx"></span>{{ $t('input.add_item') }}
                  </a>
                </div>
              </div>

              <div class="row" style="margin-top: 15px">
                <div class="col-sm-12">
                  <div class="form-group">
                    <label>
                      <span class="label-text">{{ $t('input.note') }}</span>
                    </label>
                    <textarea
                      :disabled="isDisabled"
                      v-model="inputDetail.comment"
                      class="form-control"
                      rows="2"
                      :placeholder="$t('input.pl_note')"
                    >
                    </textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="navbar navbar-default navbar-fixed-bottom navbar-footblock with-sidebar with-chat submit-new--wrap show--estimate hide--inprogress"
          style="z-index: 990; padding: 12px"
        >
          <div class="row">
            <div class="col-sm-4">
              <button
                href="#"
                :disabled="isStatusPublished"
                class="btn btn-sm btn-block btn-inverse-primary"
                @click="submitFormStopInput"
              >
                {{ $t('input.stop_input') }}
              </button>
            </div>
            <div class="col-sm-4" v-if="['1', '2', '4'].includes(inputDetail.status)">
              <button
                :disabled="isDisabled"
                href="#"
                class="btn btn-sm btn-block btn-inverse-primary"
                @click="submitForm"
              >
                {{ $t('input.save') }}
              </button>
            </div>
            <div class="col-sm-4" v-else>
              <button href="#" class="btn btn-sm btn-block btn-inverse-primary" @click="submitForm">
                {{ $t('input.save') }}
              </button>
            </div>
            <div
              class="col-sm-4 show--done show--published"
              v-if="inputDetail.status == 3"
              @click="showBoxPublic = true"
            >
              <button :disabled="!flgPublic" class="btn btn-sm btn-block btn-primary js-btn--publish">
                {{ $t('input.release') }}
              </button>
            </div>
            <div class="col-sm-4 show--done show--published" v-else>
              <button :disabled="true" class="btn btn-sm btn-block btn-primary js-btn--publish">
                {{ $t('input.release') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <el-dialog v-model="outerVisible" :show-close="true" :close-on-press-escape="false" :close-on-click-modal="false">
    <div class="">
      <button type="button" class="close" @click="outerVisible = false">
        <span class="icon-abui-cross" aria-hidden="true"></span>
      </button>
      <div class="viewer-item">
        <div class="trip--receipt hide--new" style="max-width: 100%; height: 90vh">
          <iframe
            v-if="isTypePDF"
            :src="inputDetail.file_path"
            style="width: 100%; height: 100%; border: none; margin-top: 35px"
          />
          <img v-else :src="inputDetail.file_path" width="100%" />
        </div>
      </div>
    </div>
  </el-dialog>

  <ModalConfirm
    :dialogVisible="showBoxPublic"
    @cancel="showBoxPublic = false"
    @yes="publicCost"
    classCustom="btn-primary"
    :labelButtonConfirm="$t('common.execution')"
    :title="$t('input.text_confirm_public')"
  />
</template>
<script>
import ModalConfirm from '@/components/common/ModalConfirm';
import ReservationSlipForm from '@/components/input/ReservationSlipForm';
import InputCostForm from '@/components/input/InputCostForm';
import { PAYMENT_DATE_TYPE } from '@/constants/';
import { Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import VuePdfEmbed from 'vue-pdf-embed';
import moment from 'moment';

export default {
  name: 'Input',
  components: {
    Field,
    ErrorMessage,
    ReservationSlipForm,
    InputCostForm,
    ModalConfirm,
    VuePdfEmbed,
  },
  beforeRouteLeave(to, from, next) {
    if (
      (!this.compareObject(this.inputDetail, this.inputDetailOfficial) ||
        !this.compareArray(this.inputDetailItems, this.inputDetailOfficial.items)) &&
      !this.isCommit
    ) {
      const answer = window.confirm(this.$t('common.confirm_leave_page'));
      if (answer) {
        this.updateStatus();
        next();
      } else {
        next(false);
      }
    } else {
      this.updateStatus();
      next();
    }
  },
  data() {
    return {
      rotate: 0,
      currentRate: 1,
      companySettingDetail: {},
      zoom: 1,
      firstLoadingPDF: true,
      styleRotate: `transform: rotate(0deg) scale(1);`,
      isAnother: false,
      isDisabled: false,
      outerVisible: false,
      showButtonAnother: true,
      listPaymentMethod: [],
      listAmount: [],
      currencyList: [],
      allCurrencyList: [],
      listTicketType: [],
      listAirport: [],
      listStation: [],
      optionsUTC: [],
      listTaxClassification: [],
      listCostItem: [],
      listItemSubmit: [],
      submitFromParent: false,
      submitStopInput: false,
      showBoxPublic: false,
      inputDetail: {},
      inputDetailOfficial: {
        // currency_id: 1, // JPY
        // tax_type: '', // Default 8%
        // payment_method: 1,
        // payment_date: '',
        // cost_item: '',
        // trip_id: '',
        // company_id: '',
        // items: [],
      },
      inValid: true,
      dataEmit: {},
      dataAmount: [],
      isCommit: false,
      flgPublic: false,
      flgSubmit: false,
      totalHeight: 0,
      totalWidth: 0,
      canvasHeight: 0,
      canvasWidth: 0,
      dataContent: [],
    };
  },
  computed: {
    totalInputAmount() {
      if (this.checkEmpty(this.inputDetail.items)) return 0;
      let totalAmount = 0;
      this.inputDetail.items.forEach(item => {
        if (!this.checkEmpty(item.currency_amount)) {
          let amountNum = parseInt(String(item.currency_amount).replaceAll(',', ''));
          totalAmount += amountNum;
        }
      });
      return this.splitThousandNumber(totalAmount);
    },
    applicableDate() {
      return this.companySettingDetail.setting?.applicable_date || '';
    },
    isStatusPublished() {
      return this.inputDetail.status === '4';
    },
    isStatusEntering() {
      return this.inputDetail.status === '2';
    },
    styleImage() {
      let maxWidth = 400;
      if (this.isTypePDF) {
        maxWidth = 900;
      }

      let resizeRatio = 1;

      if (this.totalWidth > this.totalHeight) {
        if (this.totalWidth > maxWidth) {
          resizeRatio = this.totalWidth / maxWidth;
        }
      }

      let widthImg = this.totalWidth / resizeRatio;
      let heightImg = this.totalHeight / resizeRatio;

      return `width: ${widthImg}px; height: ${heightImg}px`;
    },
    pageCount() {
      return !this.checkEmpty(this.inputDetail.content) ? this.inputDetail.content.length : 1;
    },
    isTypePDF() {
      if (this.checkEmpty(this.inputDetail?.file_name)) return false;
      return this.inputDetail.file_name.split('.').pop() == 'pdf';
    },
    countWidth() {
      if (this.isTypePDF) {
        return 300;
      }

      return 0;
    },
    countHeight() {
      if (this.isTypePDF) {
        return this.pageCount * 424;
      }

      return 0;
    },
    userLoginId() {
      return this.$store.state.auth?.user?.id || '';
    },
    inputDetailItems() {
      return this.inputDetail.items || [];
    },
    currencySelect() {
      const currency = this.currencyList.find(item => item.id == this.inputDetail.currency_id);
      return currency || {};
    },
    signalCurrency() {
      const currency = this.currencyList.find(item => item.id == this.inputDetail.currency_id);
      return currency?.signal || '¥';
    },
    rateCurrency() {
      const currency = this.currencyList.find(item => item.id == this.inputDetail.currency_id);
      return currency?.rate || '';
    },
    inputId() {
      return this.$route.params.id;
    },
    validate() {
      return yup.object().shape({
        payable_to: yup
          .string()
          .trim()
          // .required(this.$t('E-CM-100', { item: this.$t('expense_cost.payment_to') }))
          .max(100, this.$t('E-CM-111', { size: 100 }))
          .nullable(),
        currency_id: yup.number(),
        payment_method: yup
          .string()
          .trim()
          // .required(this.$t('E-CM-100', { item: this.$t('expense_cost.payment_method') }))
          .nullable(),
        payment_date: yup
          .string()
          .trim()
          // .required(this.$t('E-CM-100', { item: this.$t('expense_cost.payment_date') }))
          .nullable(),
      });
    },
  },
  watch: {
    'inputDetail.payment_date': {
      handler(newVal) {
        if (!this.checkEmpty(newVal)) this.checkCurrencyRate();
      },
      deep: true,
    },
    'inputDetail.applied_rate'(newVal) {
      if (!this.checkEmpty(newVal)) {
        this.currentRate = newVal;
        return;
      }
      this.currentRate = 1;
    },
    'inputDetail.currency_id'(newVal) {
      if (!this.checkEmpty(newVal)) this.checkCurrencyRate();
    },
    rotate(val) {
      this.styleRotate = `transform: rotate(${val}deg) scale(${this.zoom});`;
    },
    zoom(val) {
      this.styleRotate = `transform: rotate(${this.rotate}deg) scale(${val});`;
    },
    'inputDetail.company_id'(val) {
      if (this.checkEmpty(val)) return;
      this.getRateApplicableDateByCompany();
      this.getCurrencyList();
      this.getCostItemList(val);
    },
    inputDetail: function (val) {
      if (val.type === '2' && val.items.length === 0) {
        this.inputDetail.items.push({
          airplane_type: 1,
          arrival_date: null,
          arrival_location_id: null,
          arrival_time: null,
          seat_class_type: 1,
          departure_date: null,
          start_time: '00:00',
          end_time: '00:00',
          departure_location_id: null,
          departure_time: null,
          time_zone_id: null,
          location_string: null,
          flight_number: null,
          id: null,
          seat_number: null,
          title: null,
          type: 1,
        });
        this.inputDetailOfficial.items.push({
          airplane_type: 1,
          arrival_date: null,
          arrival_location_id: null,
          arrival_time: null,
          seat_class_type: 1,
          departure_date: null,
          start_time: '00:00',
          end_time: '00:00',
          departure_location_id: null,
          departure_time: null,
          time_zone_id: null,
          location_string: null,
          flight_number: null,
          id: null,
          seat_number: null,
          title: null,
          type: 1,
        });
      }
      if (val.type === '1' && val.items.length === 0) {
        this.inputDetail.items.push({
          amount: null,
          cost_id: null,
          id: null,
          tax_amount: null,
          tax_classification: 1,
          title: null,
        });
        this.inputDetailOfficial.items.push({
          amount: null,
          cost_id: null,
          id: null,
          tax_amount: null,
          tax_classification: 1,
          title: null,
        });
      }
    },
  },
  created() {
    this.startLoading();
    Promise.all([
      this.getInputDetail(),
      this.getListTicketType(),
      this.getListAirport(),
      this.getListStation(),
      this.getCurrencyList(),
      this.getAllCurrencyList(),
      this.getPaymentMethodList(),
      this.getTaxClassificationList(),
      this.getTimeZone(),
    ]).then(() => {
      this.endLoading();
    });
  },
  methods: {
    getRateApplicableDateByCompany() {
      if (this.checkEmpty(this.inputDetail.company_id)) return;
      this.$services.input.getRateApplicableDateByCompany(
        this.inputDetail.company_id,
        res => {
          this.companySettingDetail = res;
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    calculateTotalAmount() {
      if (this.checkEmpty(this.inputDetail.items)) return 0;
      let totalAmount = 0;
      this.inputDetail.items.forEach(inputCost => {
        let costAmount = parseInt(String(inputCost.amount).replaceAll(',', ''));
        totalAmount += parseInt(costAmount.toFixed(0));
      });

      return this.splitThousandNumber(totalAmount.toFixed(0));
    },
    calculateCostAmount(totalAmount) {
      if (this.checkEmpty(totalAmount) || this.checkEmpty(this.currentRate)) return 0;
      let amount = parseInt(String(totalAmount).replaceAll(',', '')) * this.currentRate;
      if (!this.isInt(amount)) amount = parseFloat(amount).toFixed(0);
      return amount;
    },
    sortExchangeRate(listExchangeRate) {
      listExchangeRate.sort((a, b) => {
        if (
          moment(new Date(a.change_reservation_date)).format('YYYY-MM-DD') >
          moment(new Date(b.change_reservation_date)).format('YYYY-MM-DD')
        )
          return -1;
        if (
          moment(new Date(a.change_reservation_date)).format('YYYY-MM-DD') <
          moment(new Date(b.change_reservation_date)).format('YYYY-MM-DD')
        )
          return 1;
        return 0;
      });

      return listExchangeRate;
    },
    checkCurrencyRate() {
      if (this.checkEmpty(this.inputDetail.currency_id) || this.checkEmpty(this.currencyList)) return;
      let crs = this.currencyList.find(currency => currency.id == this.inputDetail.currency_id);
      // this.errors = {
      //   files: '',
      //   amount: '',
      //   tax_amount: '',
      // };
      let date = '';

      if (!this.checkEmpty(this.inputDetail.payment_date) && String(this.inputDetail.payment_date).includes('/')) {
        date = this.inputDetail.payment_date;
      } else if (
        !this.checkEmpty(this.inputDetail.payment_date) &&
        !String(this.inputDetail.payment_date).includes('/')
      ) {
        date = `${this.inputDetail.payment_date.substr(0, 4)}-${this.inputDetail.payment_date.substring(
          4,
          6,
        )}-${this.inputDetail.payment_date.substring(6, 8)}`;
      } else {
        date = new Date();
      }

      let listExchangeRate = this.sortExchangeRate(this.cloneObject(crs.exchange_rates));
      let latestDate = listExchangeRate.find(
        item =>
          moment(new Date(item.change_reservation_date)).format('YYYY-MM-DD') <=
          moment(new Date(date)).format('YYYY-MM-DD'),
      );
      if (this.applicableDate == PAYMENT_DATE_TYPE) {
        switch (listExchangeRate.length) {
          case 0:
            this.currentRate = crs.rate;
            break;
          case 1:
            if (
              moment(new Date(listExchangeRate[0].change_reservation_date)).format('YYYY-MM-DD') <=
              moment(new Date(date)).format('YYYY-MM-DD')
            ) {
              this.currentRate = listExchangeRate[0].change_rate;
            } else {
              this.currentRate = listExchangeRate[0].applying_rate;
            }
            break;
          default:
            if (!this.checkEmpty(latestDate)) {
              this.currentRate = latestDate.change_rate;
            } else {
              this.currentRate = listExchangeRate[listExchangeRate.length - 1].applying_rate;
            }
            break;
        }
      } else if (!this.checkEmpty(crs.exchange_rates)) {
        this.currentRate = crs.exchange_rates[0].applying_rate;
      } else {
        this.currentRate = 1;
      }
    },
    updateStatus() {
      if (this.isStatusEntering) {
        let formData = {
          status: 1,
        };
        this.$services.input.updateStatus(
          this.inputId,
          formData,
          () => {},
          err => {
            this.notifyError(this.$t(err.error));
          },
        );
      }
    },
    goToUserDetail(id) {
      let routeData = this.$router.resolve({
        name: this.PAGES.USER_DETAIL,
        params: {
          id: id,
        },
      });
      window.open(routeData.href, '_blank');
    },
    showTabPreview() {
      let routeData = this.$router.resolve({
        name: this.PAGES.INPUT_PREVIEW,
      });
      window.open(
        routeData.href,
        'Preview',
        'height=800,width=960,toolbar=no,menubar=no,scrollbars=no,location=no,status=no',
      );
    },
    handleListDataInput(data, index) {
      this.inputDetail.items[index] = data;
    },
    getTimeZone() {
      this.$services.common.getListTimeZones(
        res => {
          this.optionsUTC = res.list;
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    listLoadedDataPDF() {
      if (this.pageCount >= 1 && this.firstLoadingPDF) {
        this.totalWidth = 0;
        this.totalHeight = 0;
        this.canvasWidth = 0;
        this.canvasHeight = 0;
        for (let index = 1; index <= this.pageCount; index++) {
          let el = document.getElementById('elMove-' + index);
          this.totalWidth = el.offsetWidth;
          this.totalHeight += el.offsetHeight;
          this.canvasWidth = el.offsetWidth;
          this.canvasHeight = el.offsetHeight;
        }
        this.firstLoadingPDF = false;
      }
    },
    convertData(data) {
      if (String(data).includes('.')) {
        this.inputDetail.currency_amount = String(parseFloat(data).toFixed(2))
          .replace(/^(?!00[^0])0/, '')
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      } else {
        data = data.replaceAll(',', '');
        this.inputDetail.currency_amount = data
          .replace(/(\..*?)\..*/g, '$1')
          .replace(/^(?!00[^0])0/, '')
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    },
    zoomOut() {
      if (this.zoom <= 0.25) return;
      this.zoom = this.zoom - 0.25;
    },
    goToNextInput() {
      this.isCommit = true;
      this.startLoading();
      let formData = new FormData();
      formData.append('query', this.$route.query);

      this.$services.input.nextDataInput(
        this.inputId,
        formData,
        res => {
          this.endLoading();
          this.showButtonAnother = !this.showButtonAnother;
          this.$router.push({
            name: this.PAGES.INPUT_DETAIL,
            params: {
              id: res.id,
            },
            query: {
              page: this.$route.query.page,
              per_page: this.$route.query.per_page,
              trip_name: this.$route.query.trip_name,
              company_keyword: this.$route.query.company_keyword,
              employee_keyword: this.$route.query.employee_keyword,
              is_reservation_slip: this.$route.query.is_reservation_slip,
              is_cost: this.$route.query.is_cost,
              reimbursement: this.$route.query.reimbursement,
              corporate_card: this.$route.query.corporate_card,
              request: this.$route.query.request,
              with_deleted: this.$route.query.with_deleted,
              country_ids: this.$route.query.country_ids,
              status: this.$route.query.status,
              is_search_activated: this.$route.query.is_search_activated,
              start_date_from: this.$route.query.start_date_from,
              start_date_to: this.$route.query.start_date_to,
              return_date_from: this.$route.query.return_date_from,
              return_date_to: this.$route.query.return_date_to,
              upload_date_from: this.$route.query.upload_date_from,
              upload_date_to: this.$route.query.upload_date_to,
              completion_date_from: this.$route.query.completion_date_from,
              completion_date_to: this.$route.query.completion_date_to,
            },
          });
          setTimeout(() => {
            window.location.reload();
          }, 100);
        },
        err => {
          this.endLoading();
          this.$router.push(this.PAGES.INPUT);
          this.notifyError(this.$t(err.error));
        },
      );
    },
    publicCost() {
      this.startLoading();
      this.isCommit = true;
      this.$services.input.publishInput(
        this.inputId,
        {
          updated_at: this.inputDetail.updated_at,
        },
        () => {
          this.showBoxPublic = false;
          this.flgPublic = !this.flgPublic;
          this.goToNextInput();
        },
        err => {
          this.endLoading();
          this.showBoxPublic = false;
          this.notifyError(this.$t(err.error), true);
        },
      );
    },
    addItemType1() {
      let item = {
        amount: null,
        cost_id: null,
        id: null,
        tax_amount: null,
        tax_classification: 1,
        title: null,
      };
      this.inputDetail.items.push(item);
    },
    addItemType2() {
      let item = {
        airplane_type: 1,
        arrival_date: null,
        arrival_location_id: null,
        arrival_time: null,
        seat_class_type: 1,
        departure_date: null,
        start_time: '00:00',
        end_time: '00:00',
        departure_location_id: null,
        departure_time: null,
        time_zone_id: null,
        location_string: null,
        flight_number: null,
        id: null,
        seat_number: null,
        title: null,
        type: 1,
      };
      this.inputDetail.items.push(item);
    },
    submitFormStopInput() {
      this.submitStopInput = true;
      if (this.submitStopInput) {
        this.submitForm();
      }
    },
    submitForm() {
      if (this.flgSubmit) return;
      this.startLoading();
      this.flgSubmit = true;
      this.listItemSubmit = [];
      this.dataEmit = {};
      this.submitFromParent = !this.submitFromParent;

      setTimeout(() => {
        let noErr = true;
        for (let key in this.dataEmit) {
          if (this.dataEmit[key].errors && !this.checkEmpty(this.dataEmit[key].errors)) {
            noErr = false;
          }
        }

        if (noErr) {
          this.updateData();
        } else {
          this.flgSubmit = false;
          this.endLoading();
        }
      }, 100);
    },
    editingInput() {
      this.startLoading();
      this.$services.input.editingInput(
        this.inputDetail.id,
        () => {
          this.endLoading();
          this.getInputDetail();
        },
        err => {
          this.notifyError(this.$t(err.error));
          this.endLoading();
        },
      );
    },
    async updateData() {
      this.startLoading();
      var formData = {};
      if (this.inputDetail.type === '1') {
        formData = {
          payable_to: this.inputDetail.payable_to,
          payment_method: this.inputDetail.payment_method,
          currency_amount:
            this.inputDetail.currency_amount?.length > 3
              ? this.inputDetail.currency_amount.replaceAll(',', '')
              : this.inputDetail.currency_amount,
          currency_id: this.inputDetail.currency_id,
          company_id: this.inputDetail.company_id,
          applied_rate: this.currentRate,
          items: this.listItemSubmit,
          payment_date: this.inputDetail.payment_date,
          cost_id: this.inputDetail.cost_id ? this.inputDetail.cost_id : '',
        };
      } else {
        formData = {
          items: this.listItemSubmit,
        };
      }
      formData.comment = this.inputDetail.comment;
      formData.updated_at = this.inputDetail.updated_at;
      if (this.submitStopInput) {
        formData.stop_input = true;
      }

      await this.$services.input.saveInput(
        this.inputDetail.id,
        formData,
        () => {
          setTimeout(() => {
            this.getInputDetail();
            this.notifySuccess(this.$t('I-CM-103'));
          }, 100);
          this.flgSubmit = false;
          this.submitStopInput = false;
          this.endLoading();
        },
        err => {
          this.endLoading();
          this.flgSubmit = false;
          this.notifyError(this.$t(err.error), true);
        },
      );
    },
    handleEmitChild(data, errors, index) {
      this.listItemSubmit = [];
      data.currency_amount = parseInt(String(data.currency_amount).replaceAll(',', ''));
      // data.amount = this.calculateCostAmount(data.currency_amount);
      // this.inputDetail.items[index].amount = this.calculateCostAmount(data.currency_amount);
      this.dataEmit[index] = {
        data,
        errors,
      };

      for (let key in this.dataEmit) {
        if (this.dataEmit[key].errors && this.checkEmpty(this.dataEmit[key].errors)) {
          this.listItemSubmit.push(this.dataEmit[key].data);
        }
      }
    },
    deleteItem(item, index) {
      const dataClone = this.cloneObject(this.inputDetail.items);
      dataClone.splice(index, 1);

      this.inputDetail.items = [...dataClone];

      if (this.inputDetailItems.length === 0) {
        this.dataAmount = [];
        this.inputDetail.currency_amount = 0;
      } else {
        this.dataAmount.splice(index, 1);
        let amount = this.sumArray(this.dataAmount);
        this.inputDetail.currency_amount = !isNaN(amount) ? this.splitThousandNumber(amount) : 0;
      }
    },
    deleteItem2(item, index) {
      this.inputDetail.items.splice(index, 1);
    },
    handleAmount(data, index) {
      this.dataAmount[index] = {
        data,
      };

      let amount = this.sumArray(this.dataAmount);
      this.inputDetail.currency_amount = !isNaN(amount) ? this.splitThousandNumber(amount) : 0;
    },
    sumArray(array) {
      let sum = 0;
      array.map(function (value) {
        sum += parseInt(value.data);
      });

      return sum;
    },
    async getTaxClassificationList() {
      await this.$services.common.getTaxClassificationList(
        res => {
          this.listTaxClassification = res.list;
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    async getListAirport() {
      await this.$services.schedule.getListAirport(
        res => {
          this.listAirport = res.list;
          this.listAirport.map(item => {
            item.value = item.name;
            return item;
          });
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    nameAirportDeparture(data) {
      var nameAirport = '';
      this.listAirport.filter(item => {
        if (data.departure_location_id && item.id === data.departure_location_id) {
          return (nameAirport = item.name);
        }
      });
      return nameAirport;
    },
    nameAirportArrival(data) {
      var nameAirport = '';
      this.listAirport.filter(item => {
        if (data.arrival_location_id && item.id === data.arrival_location_id) {
          return (nameAirport = item.name);
        }
      });
      return nameAirport;
    },
    async getListStation() {
      await this.$services.schedule.getListStation(
        res => {
          this.listStation = res.list;
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    async getListTicketType() {
      await this.$services.common.getListTicketType(
        res => {
          this.listTicketType = res.list;
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    //Get currency exchange rates by company id
    async getCurrencyList() {
      if (this.checkEmpty(this.inputDetail.company_id)) return;
      await this.$services.common.getCurrencyList(
        { company_id: this.inputDetail.company_id },
        res => {
          this.currencyList = res.list;
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    //CU-050
    async getAllCurrencyList() {
      await this.$services.common.getCurrencyList(
        {},
        res => {
          this.allCurrencyList = res.list;
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    async getPaymentMethodList() {
      await this.$services.common.getPaymentMethodList(
        res => {
          this.listPaymentMethod = res.list;
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    async getInputDetail() {
      await this.$services.input.getInputDetail(
        this.inputId,
        res => {
          this.listItemSubmit = [];
          this.firstLoadingPDF = true;
          this.inputDetail = res;
          if (this.isTypePDF) {
            this.totalWidth = this.countWidth;
            this.totalHeight = this.countHeight;
          } else {
            this.totalWidth = !this.checkEmpty(res.width) ? res.width : 0;
            this.totalHeight = !this.checkEmpty(res.height) ? res.height : 0;
          }

          this.flgPublic = !this.checkEmpty(res.items);

          if (this.inputDetail.status === '4') {
            this.isDisabled = true;
          }
          if (this.inputDetail.currency_id == null) {
            this.inputDetail.currency_id = 1;
          }
          if (this.inputDetail.payment_method == null) {
            this.inputDetail.payment_method = 1;
          }
          this.inputDetailOfficial = this.cloneObject(res);
          if (this.inputDetailOfficial.currency_id == null) {
            this.inputDetailOfficial.currency_id = 1;
          }
          if (this.inputDetailOfficial.payment_method == null) {
            this.inputDetailOfficial.payment_method = 1;
          }
          this.dataContent = this.handleData(res);
          this.getCostItemList(res.company_id);
        },
        err => {
          this.isCommit = true;
          this.notifyError(this.$t(err.error));
          this.$router.push(this.PAGES.INPUT);
        },
      );
    },
    getInputNext(id) {
      this.startLoading();
      this.$services.input.getInputDetail(
        id,
        res => {
          this.endLoading();
          this.listItemSubmit = [];
          this.inputDetail = res;
          if (this.inputDetail.currency_id == null) {
            this.inputDetail.currency_id = 1;
          }
          if (this.inputDetail.payment_method == null) {
            this.inputDetail.payment_method = 1;
          }
          this.inputDetailOfficial = this.cloneObject(res);
          if (this.inputDetailOfficial.currency_id == null) {
            this.inputDetailOfficial.currency_id = 1;
          }
          if (this.inputDetailOfficial.payment_method == null) {
            this.inputDetailOfficial.payment_method = 1;
          }
        },
        err => {
          this.endLoading();
          this.notifyError(this.$t(err.error));
        },
      );
    },
    getCostItemList(id) {
      if (this.checkEmpty(id)) return;
      this.$services.trip.getCostItemListInput(
        { company_id: id },
        res => {
          this.listCostItem = res.list;
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },

    mousedown(e) {
      let el = document.getElementById('componentMove');
      window.addEventListener('mousemove', mousemove);
      window.addEventListener('mouseup', mouseup);

      let coordinatesX = el.style.left.slice(0, -2);
      let coordinatesY = el.style.top.slice(0, -2);

      let prevX = e.clientX;
      let prevY = e.clientY;

      function mousemove(e) {
        const newX = prevX - e.clientX;
        const newY = prevY - e.clientY;

        el.style.left = coordinatesX - newX + 'px';
        el.style.top = coordinatesY - newY + 'px';
      }

      function mouseup() {
        window.removeEventListener('mousemove', mousemove);
        window.removeEventListener('mouseup', mouseup);
      }
    },
    stylePlan(status) {
      let backgroundColor = '';
      let color = '';

      if ([1, 3].includes(status)) {
        backgroundColor = '#e6eaeb';
        color = '#657a84';
      }
      if (status === '4') {
        backgroundColor = '#e6f5e5';
        color = '#65c15e';
      }
      if (status === '2') {
        backgroundColor = '#d8f1f7';
        color = '#0aaace';
      }

      return `background-color: ${backgroundColor}; color: ${color}`;
    },
    handleData(response) {
      let data = [];
      response.content.forEach((content, index) => {
        content.forEach(para => {
          let item = {};
          if (!this.checkEmpty(para.paragraphs)) {
            item = {
              text: para.paragraphs[0].text,
              verticies: para.paragraphs[0].verticies,
            };
          } else {
            item = {
              page: index + 1,
              text: para.text,
              verticies: para.verticies,
            };
          }
          data.push(item);
        });
      });
      return data;
    },
    processStyle(data) {
      const { verticies } = data;
      let width = 0,
        height = 0,
        top = 0,
        left = 0;

      const imgWidth = this.totalWidth;
      const imgHeight = this.totalHeight;

      const [vertices_1, vertices_2, vertices_3, vertices_4] = verticies;

      let scaleWidth = 1;
      let scaleHeight = 1;
      if (this.isTypePDF) {
        scaleWidth = this.canvasWidth;
        scaleHeight = this.canvasHeight;
      }
      left = (((vertices_1.x * scaleWidth) / imgWidth) * 100).toFixed(6);
      top = (
        ((this.canvasHeight * data.page - this.canvasHeight + vertices_1.y * scaleHeight) / imgHeight) *
        100
      ).toFixed(6);
      width = (((vertices_3.x * scaleWidth - vertices_1.x * scaleWidth) / imgWidth) * 100).toFixed(6);
      height = (((vertices_4.y * scaleHeight - vertices_2.y * scaleHeight) / imgHeight) * 100).toFixed(6);

      return `width: ${+width + 1}%; height: ${+height + 0.2}%; top: ${top}%; left: ${left}%;`;
    },
    copyValue(text) {
      this.copyTextToClipboard(text);
      this.notifySuccess(text);
    },
  },
};
</script>
