<template>
  <button
    class="btn btn-sm btn-inverse btn-block btn-download-search-result"
    :disabled="isDisabled"
    @click="$emit('download')"
  >
    <i class="icon-abui-download prx"></i>
    {{ $t('application.download_search_result') }}
  </button>
</template>
<script>
export default {
  name: 'DownloadApplicationCSV',
  emits: ['download'],
  props: {
    isDownloadDisabled: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    isDownloadDisabled() {
      this.isDisabled = this.isDownloadDisabled;
    },
  },
  data() {
    return {
      isDisabled: true,
    };
  },
};
</script>
<style scoped>
.btn-download-search-result[disabled] {
  border: 1px solid #657a84 !important;
}
</style>
