<template>
  <div class="filter-result js-filter-result--minimization">
    <div class="section noborder">
      <Pagination
        v-if="listSafetyInfo.length"
        :paginate="paginate"
        :current-number="+paginate.currentPage"
        @change-page="changePageData"
        :hasOptionPaginate="false"
      />

      <div class="hidden-xs">
        <div class="table-responsive">
          <table class="table table-hover table-hover--pointer table--org-list" id="">
            <colgroup v-if="isOperatorOrAdmin" span="1" style="min-width: 200px"></colgroup>
            <colgroup span="1" style="width: 22.27%"></colgroup>
            <colgroup span="1" style="width: 22.27%"></colgroup>
            <colgroup span="1" style="width: 22.27%"></colgroup>
            <colgroup span="1" style="width: 16.66%"></colgroup>
            <colgroup span="1" style="width: 8.33%"></colgroup>
            <colgroup span="1" style="width: 8.33%"></colgroup>
            <thead>
              <tr>
                <th v-if="isOperatorOrAdmin">{{ $t('common.company') }}</th>
                <th>{{ $t('common.target_country') }}</th>
                <th>{{ $t('common.target_city') }}</th>
                <th>{{ $t('common.security_title') }}</th>
                <th>{{ $t('common.target_period') }}</th>
                <th>{{ $t('common.target_number_of_people') }}</th>
                <th>{{ $t('common.status') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(safetyInfo, index) in listSafetyInfo"
                :key="index"
                :class="safetyInfo.answered_member === safetyInfo.total_member ? 'muted' : ''"
                @click="onRedirectSecurityItemPage(safetyInfo)"
              >
                <td v-if="isOperatorOrAdmin">{{ safetyInfo?.company?.name || '-' }}</td>
                <td>
                  {{ getListCountryTarget(safetyInfo) }}
                </td>
                <td>
                  {{ getListCityTarget(safetyInfo) }}
                </td>
                <td>
                  {{ !checkEmpty(safetyInfo.title) ? safetyInfo.title : '-' }}
                </td>
                <td>
                  {{ getTargetPeriod(safetyInfo) }}
                </td>
                <td v-html="getTargetNumberOfPeople(safetyInfo)"></td>
                <td v-html="getStatus(safetyInfo)"></td>
              </tr>
            </tbody>
          </table>
          <p v-if="!listSafetyInfo.length" class="no-data">{{ $t('security.no_data') }}</p>
        </div>
      </div>

      <Pagination
        v-if="listSafetyInfo.length"
        :paginate="paginate"
        :current-number="+paginate.currentPage"
        @change-page="changePageData"
        :hasOptionPaginate="false"
      />
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/common/Pagination';
import { CHECKING_EN, CHECKING_JA, CONFIRMED_COMPLETED_EN, CONFIRMED_COMPLETED_JA } from '@/constants/';

export default {
  name: 'DataTableResult',
  components: {
    Pagination,
  },
  props: {
    listSafetyInfo: {
      type: Array,
      default: () => [],
    },
    paginate: {
      type: Object,
      default: () => {
        return {
          page: 1,
          perPage: 1,
          from: 1,
          to: 1,
          lastPage: 1,
          total: 1,
        };
      },
    },
  },
  methods: {
    changePageData(page) {
      this.$emit('changePageData', page);
    },

    getListCountryTarget(safetyInfo) {
      if (safetyInfo.total_country == safetyInfo.countries_safety.length) {
        return this.$t('common.all');
      } else if (safetyInfo.countries_safety.length >= 2) {
        return this.$t('security.2_or_more_countries');
      } else {
        let countryTarget = '';
        safetyInfo.countries_safety.map(country => {
          countryTarget += this.labelLocale(country) + this.$t('common.comma');
        });

        return countryTarget.length ? countryTarget.substr(0, countryTarget.length - 1) : countryTarget;
      }
    },

    getListCityTarget(safetyInfo) {
      let cityTarget = '';
      let result = [];
      if (safetyInfo.total_country == safetyInfo.countries_safety.length || safetyInfo.countries_safety.length >= 2) {
        return 'ー';
      } else if (safetyInfo.is_other_country) {
        result = [...new Set(safetyInfo.countries.map(item => String(this.labelLocale(item)).toLowerCase()))];
      } else {
        result = [...new Set(safetyInfo.countries.map(item => String(this.labelLocale(item.cities)).toLowerCase()))];
      }
      result.map(city => {
        if (!this.checkEmpty(city)) {
          cityTarget += city + ', ';
        }
      });

      return cityTarget.length ? cityTarget.substr(0, cityTarget.length - 2) : cityTarget;
    },

    getTargetPeriod(data) {
      const { start_date, end_date } = data;
      return this.showDateTime(start_date, 'YYYY/MM/DD') + ' - ' + this.showDateTime(end_date, 'YYYY/MM/DD');
    },

    getTargetNumberOfPeople(data) {
      const { answered_member, total_member } = data;

      if (answered_member !== total_member) {
        return `<span class="text-success">${answered_member}</span> / ${total_member}`;
      }

      return `<span>${answered_member} / ${total_member}</span>`;
    },

    getStatus(data) {
      const { answered_member, total_member } = data;

      const {
        $i18n: { locale },
      } = this;

      if (answered_member === total_member) {
        return `<span class="text-muted">${locale === 'ja' ? CONFIRMED_COMPLETED_JA : CONFIRMED_COMPLETED_EN}</span>`;
      }

      return `<span class="text-warning">${locale === 'ja' ? CHECKING_JA : CHECKING_EN}</span>`;
    },

    onRedirectSecurityItemPage(safetyInfo) {
      this.$router.push({
        name: this.PAGES.CRISIS_MANAGEMENT_DETAIL,
        params: {
          id: safetyInfo.id,
        },
      });
    },
  },
};
</script>
