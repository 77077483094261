<template>
  <el-dialog
    v-model="showModalListAuthor"
    :show-close="true"
    width="700px"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <button @click="showModalListAuthor = false" type="button" class="close">
          <span class="icon-abui-cross" aria-hidden="true"></span>
        </button>
        <div class="modal-header">
          <div class="modal-header--title">{{ $t('approval_flow.author_user') }}</div>
        </div>
        <div class="modal-body">
          <div class="section noborder">
            <div class="add-selected">
              <div class="row">
                <div class="col-sm-12 col-md-12">
                  <div>
                    <table class="table table-striped table-word-seach">
                      <thead>
                        <tr>
                          <th>
                            <div class="input-group input-group-sm input-group-rounded" :class="isActive && 'focus'">
                              <span class="input-group-btn">
                                <button type="submit" class="btn"><span class="icon-abui-search"></span></button>
                              </span>
                              <input
                                @focus="isActive = true"
                                @blur="isActive = false"
                                type="text"
                                v-model="searchKeyword"
                                class="form-control"
                                :placeholder="$t('approval_flow.search')"
                              />
                            </div>
                          </th>
                        </tr>
                      </thead>
                    </table>
                    <div class="innerScrollFade">
                      <div style="overflow: auto; max-height: calc(100vh - 300px)">
                        <table class="table table-striped table-hover table-word-seach__body table-hover--pointer">
                          <colgroup span="1"></colgroup>
                          <colgroup span="1" style="width: 96px"></colgroup>
                          <colgroup span="1"></colgroup>
                          <colgroup span="1" style="width: 88px"></colgroup>
                          <tbody>
                            <tr v-for="(item, index) in listFilter" :key="index" @click="getUser(item)">
                              <td>{{ item.employee_number }}</td>
                              <td style="min-width: 150px">{{ item.name }}</td>
                              <td>{{ item.organization }}</td>
                              <td>{{ item.position || '' }}</td>
                            </tr>
                          </tbody>
                        </table>
                        <p v-if="listFilter.length == 0" class="no-data">{{ $t('common.no_data') }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
  <div class="wrapper with-navbar-fixed-top with-navbar-fixed-bottom">
    <div class="container-fluid">
      <div class="section noborder ptn">
        <div class="expense--detail">
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label>
                  <span class="label-text">{{ $t('approval_flow.approval_flow_name') }}</span>
                </label>
                <input
                  v-model="dataRequest.name"
                  maxlength="30"
                  type="text"
                  class="form-control input-sm"
                  :class="!checkEmpty(errors.name) && 'has-validation-error'"
                />
                <p class="error-feedback" v-text="errors.name"></p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-3">
              <div class="form-group">
                <label>
                  <span class="label-text">{{ $t('approval_flow.request_type') }}</span>
                </label>
                <label
                    v-if="isShowSettingApproval"
                  class="radio radio-inline radio-sm radio-m-d"
                  :class="dataRequest.request_type == 1 && 'checked'"
                  @click="(dataRequest.request_type = 1), (dataRequest.trip_type = 1)"
                >
                  <span class="icons">
                    <span class="first-icon icon-abui-radio-unchecked" />
                    <span class="second-icon icon-abui-radio-checked" />
                  </span>
                  <input type="radio" name="03-03" value="1" checked="" />
                  <span class="text_type-special">{{ $t('approval_flow.business') }}</span>
                </label>
                <label
                    v-if="showApprovalExpense && !isShowSettingApproval"
                    class="radio radio-inline radio-sm radio-m-d"
                    :class="'checked'"
                    :disabled="showApprovalExpense"
                    @click="dataRequest.request_type = 2"
                >
                  <span class="icons">
                    <span class="first-icon icon-abui-radio-unchecked" />
                    <span class="second-icon icon-abui-radio-checked" />
                  </span>
                  <input type="radio" name="03-03" value="2" />
                  <span class="text_type-special">{{ $t('approval_flow.calculate') }}</span>
                </label>
                <label
                  v-else-if="showApprovalExpense"
                  class="radio radio-inline radio-sm radio-m-d"
                  :class="dataRequest.request_type == 2 && 'checked'"
                  @click="dataRequest.request_type = 2"
                >
                  <span class="icons">
                    <span class="first-icon icon-abui-radio-unchecked" />
                    <span class="second-icon icon-abui-radio-checked" />
                  </span>
                  <input type="radio" name="03-03" value="2" />
                  <span class="text_type-special">{{ $t('approval_flow.calculate') }}</span>
                </label>
              </div>
            </div>
            <div class="col-sm-12" v-if="dataRequest.request_type === 1 && isShowSettingApproval">
              <div class="form-group">
                <label>
                  <span class="label-text">{{ $t('approval_flow.trip_type') }}</span>
                </label>
                <label
                  class="radio radio-inline radio-sm radio-m-d"
                  :class="dataRequest.trip_type == 1 && 'checked'"
                  @click="dataRequest.trip_type = 1"
                >
                  <span class="icons">
                    <span class="first-icon icon-abui-radio-unchecked" />
                    <span class="second-icon icon-abui-radio-checked" />
                  </span>
                  <input type="radio" name="03-03" value="1" checked="" />
                  <span class="text_type-special">{{ $t('approval_flow.domestic') }}</span>
                </label>
                <label
                  class="radio radio-inline radio-sm radio-m-d"
                  :class="dataRequest.trip_type == 2 && 'checked'"
                  @click="dataRequest.trip_type = 2"
                >
                  <span class="icons">
                    <span class="first-icon icon-abui-radio-unchecked" />
                    <span class="second-icon icon-abui-radio-checked" />
                  </span>
                  <input type="radio" name="03-03" value="2" />
                  <span class="text_type-special">{{ $t('approval_flow.overseas') }}</span>
                </label>
              </div>
            </div>
          </div>
          <hr />
          <p class="note">{{ $t('approval_flow.multiple') }}／{{ $t('approval_flow.specify') }}</p>
          <draggable
            tag="ul"
            class="approval-flow--wrap ui-sortable"
            :list="steps"
            @change="updateOrder"
            handle=".ui-sortable-handle"
          >
            <li class="approval-flow--item--wrap approval-flow-step" v-for="(step, index) in steps" :key="index">
              <div class="approval-flow--item dropshadow--lv1">
                <div class="approval-flow--item--num" v-if="steps.length == index + 1" style="font-size: 16px">
                  {{ $t('approval_flow.finally') }}
                </div>
                <div class="approval-flow--item--num" v-else>{{ index + 1 }}</div>
                <div class="approval-flow--item--role">
                  <div class="btn-group bootstrap-select select-block select-sm">
                    <el-select
                      v-model="steps[index].approve_type"
                      popper-class="custom-dropdown-select"
                      class="select-block select-sm"
                    >
                      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                      </el-option>
                    </el-select>
                  </div>
                </div>
                <div class="approval-flow--item--list">
                  <table class="table">
                    <tbody>
                      <tr v-if="steps[index].user_select_option === 1">
                        <td style="width: 150px">{{ $t('approval_flow.optional') }}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td class="cell-control">
                          <a class="btn btn-flat-danger btn-sm delrow" @click="deleteUserOption(index)">
                            <span class="icon-abui-cross"></span>
                          </a>
                        </td>
                      </tr>
                      <tr v-for="(user, index2) in steps[index].user_appro" :key="index2">
                        <td style="min-width: 150px">{{ user.employee_code }}</td>
                        <td style="min-width: 180px">{{ user.name }}</td>
                        <td>{{ user.organization }}</td>
                        <td style="min-width: 80px">{{ user.position || '' }}</td>
                        <td class="cell-control">
                          <a class="btn btn-flat-danger btn-sm delrow" @click="deleteUser(index, user)">
                            <span class="icon-abui-cross"></span>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr class="addrow">
                        <td colspan="5">
                          <a class="btn btn-flat-primary btn-sm cursor-pointer" @click="addUser(index)">
                            <span class="icon-abui-plus"></span>
                          </a>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                  <p class="error-feedback" v-text="errors.steps[index]"></p>
                </div>
                <button :disabled="steps.length == 1" @click="deleteStep(step)" class="btn btn-sm btn-flat-danger mll">
                  <i class="icon-abui-cross"></i>
                </button>
                <span class="sortable-handle-transparent ui-sortable-handle" style="margin-left: 15px"></span>
              </div>
            </li>
          </draggable>
          <button class="btn btn-sm btn-flat-primary" style="margin-top: 24px" @click="addStep">
            <span class="icon-abui-plus prx"></span>{{ $t('approval_flow.add_step') }}
          </button>
        </div>
      </div>
    </div>

    <div
      class="navbar navbar-default navbar-fixed-bottom navbar-footblock with-sidebar submit-new--wrap show--estimate"
      role="navigation"
      style="z-index: 990; padding: 12px"
    >
      <div class="row">
        <div class="col-sm-3" v-if="isDetail">
          <button @click="showModalDelete()" class="btn btn-sm btn-flat-default">
            <i class="icon-abui-trash prx"></i>{{ $t('approval_flow.delete') }}
          </button>
        </div>
        <div class="col-sm-3" v-else></div>
        <div class="col-sm-4 col-md-3 col-sm-offset-5 col-md-offset-6">
          <a href="#" class="btn btn-sm btn-block btn-primary hide--estimate" @click="submitData">
            {{ $t('common.submit') }}
          </a>
        </div>
      </div>
    </div>
    <ModalConfirm
      :dialogVisible="showBoxConfirm"
      @cancel="showBoxConfirm = false"
      @yes="deleteApprovalFlow()"
      :title="$t('Q-CM-104')"
    />
  </div>
</template>
<script>
import ModalConfirm from '@/components/common/ModalConfirm';
import { APPROVAL, PASS_ROUND_PERUSAL } from '@/constants/';
import { VueDraggableNext } from 'vue-draggable-next';

export default {
  name: 'ApprovalFlowForm',
  components: { ModalConfirm, draggable: VueDraggableNext },
  emits: ['onChangData'],
  props: {
    dataForm: {
      type: Object,
      default: () => {},
    },
    isDetail: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    dataRequest: {
      handler: function (newValue) {
        this.onCheckChangData();
        if (newValue) {
          this.errors = {
            steps: [],
          };
        }
        if (this.strLen(newValue.name) > 30) {
          this.errors.name = this.$t('E-CM-111', { size: 30 });
        } else {
          delete this.errors.name;
        }
      },
      deep: true,
    },
    steps: {
      handler: function () {
        this.onCheckChangData();
      },
      deep: true,
    },
    isSubmit: {
      handler: function () {
        this.onCheckChangData();
      },
      deep: true,
    },
    dataForm: {
      handler: function (value) {
        this.dataRequest = {
          ...this.dataRequest,
          ...value,
        };
        if (value.request_type === 1) {
          this.dataRequest.trip_type = value.trip_type;
        }
        if (!this.checkEmpty(value.steps)) {
          this.steps = value.steps;
        }
        this.dataRequestOfficial = {
          ...this.dataRequestOfficial,
          ...value,
        };
        if (value.request_type === 1) {
          this.dataRequestOfficial.trip_type = value.trip_type;
        }
        if (!this.checkEmpty(value.steps)) {
          this.stepsOfficial = value.steps;
        }
      },
    },
  },
  data() {
    return {
      dataRequest: {
        trip_type: this.isShowSettingApproval ? 1 : 2,
        request_type: 1,
      },
      steps: [
        {
          order: '1',
          approve_type: 1,
          user_appro: [],
          user_select_option: 0,
        },
      ],
      dataRequestOfficial: {
        trip_type: 1,
        request_type: 1,
      },
      stepsOfficial: [
        {
          order: '1',
          approve_type: 1,
          user_appro: [],
          user_select_option: 0,
        },
      ],
      options: [
        {
          value: PASS_ROUND_PERUSAL,
          label: this.$t('approval_flow.perusal'),
        },
        {
          value: APPROVAL,
          label: this.$t('approval_flow.admit'),
        },
      ],
      showModalListAuthor: false,
      showBoxConfirm: false,
      searchKeyword: '',
      listAuthorsToEdit: [],
      indexUser: '',
      errors: {
        steps: [],
      },
      isActive: false,
      isSubmit: false,
    };
  },
  computed: {
    listFilter() {
      return this.listAuthorsToEdit.filter(item => {
        if (this.searchKeyword === '') return item;

        const keyword = this.searchKeyword.toLowerCase();
        const nameMatches = String(item.name).toLowerCase().indexOf(keyword) > -1;
        const employeeNumberMatches = String(item.employee_number).toLowerCase().indexOf(keyword) > -1;

        return nameMatches || employeeNumberMatches;
      });
    },
    showApprovalExpense() {
      return this.$store.state.auth?.user?.setting?.is_feature_payment || false;
    },
    isShowSettingApproval() {
      return this.$store.state.auth?.user?.setting.is_show_approval || false;
    },
  },
  methods: {
    updateOrder() {
      let data = [];
      let order = 0;
      for (let i = 0; i < this.steps.length; i++) {
        this.steps[i].order = order;
        data.push({
          order: this.steps[i].order,
        });
        order++;
      }
    },
    onCheckChangData() {
      let result =
        (!this.compareObject(this.dataRequest, this.dataRequestOfficial) ||
          !this.compareArray(this.steps, this.stepsOfficial)) &&
        !this.isSubmit;

      this.$emit('onChangData', result);
    },
    submitData() {
      if (!this.dataRequest) return;
      let flgValidate = true;

      if (this.checkEmpty(this.dataRequest.name)) {
        flgValidate = false;
        this.errors.name = this.$t('E-CM-100', { item: this.$t('approval_flow.approval_flow_name') });
      } else {
        this.errors.name = '';
      }
      this.steps.forEach((step, index) => {
        this.errors.steps[index] = {};
        if (this.steps[index].user_select_option === 0 && this.checkEmpty(this.steps[index].user_appro)) {
          flgValidate = false;
          this.errors.steps[index] = this.$t('E-CM-004');
        } else {
          this.errors.steps[index] = '';
        }
      });

      if (!flgValidate) return;

      if (this.isDetail) {
        this.updateData();
      } else {
        this.createData();
      }
    },
    createData() {
      this.isSubmit = true;
      let steps = this.cloneObject(this.steps);
      steps.forEach(step => {
        let listIdUser = [];
        step.user_appro.forEach(user => {
          listIdUser.push(user.id);
        });
        step.user_appro = listIdUser;
      });
      if (this.dataRequest.request_type === 2) {
        this.dataRequest.trip_type = '';
      }
      let formData = {
        name: this.dataRequest.name,
        trip_type: this.dataRequest.trip_type,
        request_type: this.dataRequest.request_type,
        updated_at: this.dataRequest.updated_at,
        steps: steps,
      };
      this.startLoading();
      this.$services.approval_flow.createApprovalFlow(
        formData,
        () => {
          // this.endLoading();
          this.$router.push(this.PAGES.APPROVAL_FLOW);
          this.notifySuccess(this.$t('I-CM-103'));
        },
        err => {
          if (err?.error?.name) {
            this.errors.name = this.$t(err.error.name[0], { item: this.$t('approval_flow.approval_flow_name') });
          }
          this.endLoading();
          this.notifyError(this.$t(err.error));
        },
      );
    },
    updateData() {
      this.isSubmit = true;
      let steps = this.cloneObject(this.steps);
      steps.forEach(step => {
        let listIdUser = [];
        step.user_appro.forEach(user => {
          listIdUser.push(user.id);
        });
        step.user_appro = listIdUser;
      });
      if (this.dataRequest.request_type === 2) {
        this.dataRequest.trip_type = '';
      }
      let formData = {
        name: this.dataRequest.name,
        trip_type: this.dataRequest.trip_type,
        request_type: this.dataRequest.request_type,
        updated_at: this.dataRequest.updated_at,
        steps: steps,
      };
      this.startLoading();
      this.$services.approval_flow.updateApprovalFlow(
        this.dataRequest.id,
        formData,
        () => {
          // this.endLoading();
          this.$router.push(this.PAGES.APPROVAL_FLOW);
          this.notifySuccess(this.$t('I-CM-103'));
        },
        err => {
          if (err?.error?.name) {
            this.errors.name = this.$t(err.error.name[0], { item: this.$t('approval_flow.approval_flow_name') });
          }
          this.endLoading();
          this.notifyError(this.$t(err.error), true);
        },
      );
    },
    showModalDelete() {
      this.showBoxConfirm = true;
    },
    deleteApprovalFlow() {
      this.isSubmit = true;
      this.startLoading();
      this.$services.approval_flow.deleteApprovalFlow(
        this.dataRequest.id,
        () => {
          // this.endLoading();
          this.showBoxConfirm = false;
          this.$router.push(this.PAGES.APPROVAL_FLOW);
          this.notifySuccess(this.$t('I-OP-053'));
        },
        err => {
          this.endLoading();
          this.showBoxConfirm = false;
          this.notifyError(this.$t(err.error));
        },
      );
    },
    addUser(index) {
      this.indexUser = index;
      this.showModalListAuthor = true;
      this.getApproval(index);
    },
    getApproval(index) {
      this.$services.approval_flow.getApproval(
        { request_type: this.dataRequest.request_type },
        res => {
          const list = res.list;

          this.listAuthorsToEdit = list;

          if (this.steps[index].user_select_option === 0) {
            let userOption = {
              employee_number: this.$t('approval_flow.optional'),
              name: '',
              organization: '',
              position: null,
            };
            this.listAuthorsToEdit.unshift(userOption);
          }
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    sortAuthorByName(listAuthor) {
      listAuthor.sort((a, b) => {
        if (a['name'] < b['name']) return -1;
        if (a['name'] > b['name']) return 1;
        return 0;
      });

      return listAuthor;
    },
    addStep() {
      const newItem = {
        order: this.steps.length + 1,
        approve_type: 1,
        user_appro: [],
        user_select_option: 0,
      };
      this.steps.push(newItem);
    },
    deleteStep(item) {
      this.steps.splice(this.steps.indexOf(item), 1);
    },
    deleteUser(index, user) {
      this.steps[index].user_appro.splice(this.steps[index].user_appro.indexOf(user), 1);
    },
    deleteUserOption(index) {
      this.steps[index].user_select_option = 0;
    },
    getUser(item) {
      let index = this.indexUser;
      let addItem = {
        id: item.id,
        employee_code: item.employee_number,
        name: item.name,
        organization: item.organization,
        position: item.position,
      };
      let check = true;
      this.steps[index].user_appro.forEach(element => {
        if (element.employee_code === item.employee_number) {
          check = false;
        }
      });
      if (!check) {
        this.notifyError(this.$t('approval_flow.duplicated'));
        this.showModalListAuthor = true;
      } else {
        if (item.employee_number === this.$t('approval_flow.optional')) {
          this.steps[index].user_select_option = 1;
        } else {
          this.steps[index].user_appro.push(addItem);
        }
        this.showModalListAuthor = false;
      }
    },
    getUserOptional() {
      let index = this.indexUser;
      this.steps[index].user_select_option = 1;
      this.showModalListAuthor = false;
    },
  },
};
</script>
<style scoped>
.tr-text {
  color: #606266;
  height: 35px;
}
.text-other {
  font-size: 14px;
  line-height: 1.4;
  vertical-align: middle;
  border-top: 1px solid #d0d6da;
  padding: 8px;
}

.approval-flow-step::after {
  top: 50% !important;
}

.approval-flow-step:first-child::before {
  content: '';
  height: 24px;
  width: 32px;
  border-right: 4px solid #e4e9ea;
  display: block;
  visibility: hidden;
}
</style>
