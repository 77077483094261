<template>
  <nav class="navbar navbar-default navbar-lg" role="navigation" id="header-content">
    <div class="navbar-header">
      <h2 class="navbar-title">{{ $t('sidebar.regulation_area') }}</h2>
    </div>
    <div class="collapse navbar-collapse">
      <ul class="nav navbar-nav navbar-right">
        <li>
          <a href="#" @click="downloadFileCSV">
            <span class="icon-abui-download prx"></span>{{ $t('zone.download_in_csv') }}
          </a>
        </li>
        <li>
          <a href="#" @click="goToScreenCreateNew">
            <span class="icon-abui-plus prx"></span>{{ $t('zone.add_new') }}
          </a>
        </li>
        <li class="dropdown addNew hidden-sm">
          <a
            href="#"
            class="dropdown-toggle pls"
            @click="isShowDropdown = !isShowDropdown"
            v-click-outside="
              () => {
                isShowDropdown = false;
              }
            "
          >
            <b class="caret" />
          </a>
          <span class="dropdown-arrow" />
          <ul class="dropdown-menu" :class="isShowDropdown && 'showDropdown'" style="left: -200px">
            <li>
              <a href="#" @click="showModalUploadCSV = true">
                <span class="icon-abui-upload prx" />{{ $t('zone.batch_registration_from_csv') }}
              </a>
            </li>
            <li class="divider" />
            <li>
              <a href="#" @click="downloadExampleFileCSV">
                <span class="icon-abui-download prx" />{{ $t('zone.download_csv_formate') }}
              </a>
            </li>
            <li>
              <a href="https://www.iace.co.jp/travelmgr/pdf/import_sample/passport" target="_blank" >
                <span class="icon-abui-book prx" />{{ $t('common.manual_registration') }}
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </nav>

  <body class="">
    <div class="wrapper">
      <div class="container-fluid">
        <div class="section noborder">
          <div>
            <div class="hidden-xs">
              <div class="table-responsive">
                <table class="table table-hover table-hover--pointer" id="">
                  <colgroup span="1" style="width: 16.66%" />
                  <colgroup span="1" style="" />
                  <thead>
                    <tr>
                      <th>{{ $t('regulation.regulation_area_name') }}</th>
                      <th>{{ $t('zone.country') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="info-item"
                      v-for="(item, index) in listArea"
                      :key="index"
                      @click="goToScreenDetail(item.id)"
                    >
                      <td>{{ labelLocale(item) }}</td>
                      <td>
                        <span v-for="(country, index) in item.countries" :key="index">
                          {{ labelLocale(country) }}
                          <span v-if="index < item.countries.length - 1">{{ $t('common.comma') }}</span>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p v-if="listArea.length == 0" class="no-data">{{ $t('common.no_data') }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalUploadCSV
      :dialogVisible="showModalUploadCSV"
      @cancel="showModalUploadCSV = false"
      @confirm="confirmUploadFileCSV"
      :errorValidate="errorValidate"
      :title="$t('common.title_modal_upload_file_csv')"
    />
  </body>
</template>
<script>
import ModalUploadCSV from '@/components/common/ModalUploadCSV';
import vClickOutside from 'click-outside-vue3';

export default {
  name: 'RegulationArea',
  components: { ModalUploadCSV },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      listArea: [],
      showModalUploadCSV: false,
      isShowDropdown: false,
      errorValidate: [],
    };
  },
  watch: {
    showModalUploadCSV() {
      this.errorValidate = [];
    },
  },
  created() {
    this.getListArea();
  },
  methods: {
    async getListArea() {
      this.startLoading();
      await this.$services.regulation.getListArea(
        res => {
          this.endLoading();
          this.listArea = res.list;
        },
        err => {
          this.endLoading();
          this.notifyError(this.$t(err.error));
        },
      );
    },
    downloadFileCSV() {
      this.startLoading();
      this.$services.regulation.downloadFileCSV(
        res => {
          this.endLoading();
          this.handleDownloadCSV(res.file_path);
        },
        err => {
          this.endLoading();
          this.notifyError(this.$t(err.error));
        },
      );
    },
    goToScreenCreateNew() {
      this.$router.push(this.PAGES.REGULATION_AREA_NEW);
    },
    goToScreenDetail(id) {
      this.$router.push({
        name: this.PAGES.REGULATION_AREA_DETAIL,
        params: {
          id: id,
        },
      });
    },
    confirmUploadFileCSV(listFiles) {
      let formData = new FormData();
      formData.append('file', listFiles[0]);
      this.errorValidate = [];
      this.startLoading();
      this.$services.regulation.uploadFileCSV(
        formData,
        () => {
          this.endLoading();
          this.showModalUploadCSV = false;
          this.getListArea();
        },
        err => {
          this.endLoading();
          if (err.code == 422) {
            this.errorValidate = err.error;
          } else {
            this.notifyError(this.$t(err.error));
          }
        },
      );
    },
    downloadExampleFileCSV() {
      this.startLoading();
      this.$services.regulation.downloadExampleFileCSV(
        res => {
          this.endLoading();
          this.handleDownloadCSV(res.file_path);
        },
        err => {
          this.endLoading();
          this.notifyError(this.$t(err.error));
        },
      );
    },
  },
};
</script>
