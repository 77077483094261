<template>
  <nav class="navbar navbar-default navbar-lg" role="navigation" id="header-content">
    <div class="navbar-header">
      <h2 class="navbar-title">{{ $t('sidebar.expense_item') }}</h2>
    </div>
    <div class="collapse navbar-collapse">
      <ul class="nav navbar-nav navbar-right">
        <li>
          <a href="#" @click="downloadFileCSV">
            <span class="icon-abui-download prx"></span>{{ $t('zone.download_in_csv') }}
          </a>
        </li>
        <li>
          <a href="#" @click="$router.push(PAGES.EXPENSE_ITEM_CREATE)">
            <span class="icon-abui-plus prx"></span>{{ $t('zone.add_new') }}
          </a>
        </li>
        <li class="dropdown addNew hidden-sm">
          <a
            href="#"
            class="dropdown-toggle pls"
            @click="isShowDropdown = !isShowDropdown"
            v-click-outside="
              () => {
                isShowDropdown = false;
              }
            "
          >
            <b class="caret"></b>
          </a>
          <span class="dropdown-arrow"></span>
          <ul class="dropdown-menu" :class="isShowDropdown && 'showDropdown'" style="left: -200px">
            <li>
              <a href="#" @click="showModalUploadCSV = true">
                <span class="icon-abui-upload prx" />{{ $t('zone.batch_registration_from_csv') }}
              </a>
            </li>
            <li class="divider"></li>
            <li>
              <a href="#" @click="downloadFileCsvExample">
                <span class="icon-abui-download prx"></span>{{ $t('zone.download_csv_formate') }}
              </a>
            </li>
            <li>
              <a href="https://www.iace.co.jp/travelmgr/pdf/import_sample/hiyou" target="_blank" >
                <span class="icon-abui-book prx" />{{ $t('common.manual_registration') }}
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </nav>

  <div class="filter-wrap">
    <div class="filter js-filter--minimization" v-show="showBoxFilter">
      <div class="section section-filter section-filter-special noborder js-section-filter">
        <div class="row">
          <div class="col-xs-12 col-sm-6 col-md-6">
            <div class="form-group">
              <label>{{ $t('expense.item.keyword') }}</label>
              <input
                type="text"
                v-model="searchParams.keyword"
                class="form-control input-sm"
                :placeholder="$t('placeholder.cost_item')"
              />
            </div>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-6">
            <div class="form-group">
              <label>&nbsp;</label>
              <label
                class="checkbox checkbox-sm checkbox-inline"
                :class="searchParams.include_invalid && 'checked'"
                @click="searchParams.include_invalid = !searchParams.include_invalid"
              >
                <span class="icons">
                  <span class="first-icon icon-abui-checkbox-unchecked"></span>
                  <span class="second-icon icon-abui-checkbox-checked"></span>
                </span>
                {{ $t('expense.item.including_invalidity') }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /.filter -->

    <div class="section-submit js-section-submit" v-show="showBoxFilter">
      <span class="submit-arrow"></span>
      <div class="row">
        <div class="col-sm-4 col-md-5 hidden-xs">
          <button class="btn btn-sm btn-flat-default btn-clear" @click="clearFilter()">{{ $t('common.clear') }}</button>
        </div>
        <div class="col-sm-4 col-md-2">
          <button class="btn btn-sm btn-primary btn-block js-btn-filter--minimization" @click="handleSearch()">
            {{ $t('common.search') }}
          </button>
        </div>
        <div class="col-sm-4 col-md-5 hidden-xs">
          <button class="btn btn-sm btn-flat-default btn-close" @click="showBoxFilter = false">
            {{ $t('common.close') }}
          </button>
        </div>
      </div>
    </div>
    <!-- /.section-submit -->

    <div
      class="btn-filter-again with-condition"
      v-show="!showBoxFilter"
      @click="showBoxFilter = true"
      style="display: block"
    >
      <div class="filter--condition">
        <span>{{ $t('common.all') }}</span>
        <span style="color: #3650a5">{{ $t('common.reset_conditions') }}</span>
      </div>
    </div>

    <div class="filter-result js-filter-result--minimization">
      <div class="section noborder">
        <Pagination
          v-if="listData?.length > 0"
          :paginate="paginate"
          :hasOptionPaginate="false"
          @change-page="changePageData"
          @change-number="changeNumber"
        />

        <div>
          <div class="table-responsive">
            <table class="table table-hover table-hover--pointer table--property-list">
              <colgroup span="1" style="width: 12.5%"></colgroup>
              <colgroup span="1" style="width: 12.5%"></colgroup>
              <colgroup span="1" style="width: 12.5%"></colgroup>
              <colgroup span="1" style="width: 12.5%"></colgroup>
              <thead>
                <tr>
                  <th>{{ $t('expense.item.code') }}</th>
                  <th>{{ $t('expense.item.name') }}</th>
                  <th>{{ $t('expense.item.item_expense_internal') }}</th>
                  <th>{{ $t('expense.item.valid_status') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in listData"
                  :key="index"
                  class="expense--route--dummy--tgt"
                  @click="goToEditCostItem(item)"
                >
                  <td>{{ item.code }}</td>
                  <td>{{ item.parent_name || item.name || '' }}</td>
                  <td>
                    <i class="org-divider icon-abui-arrow-right" v-if="item.name && item.parent_name"></i>
                    {{ item.parent_name ? item.name : '' }}
                  </td>
                  <td>
                    <span class="text-success" v-if="item.valid_status == 1">
                      {{ $t('expense.item.valid_status') }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <p v-if="listData?.length == 0" class="no-data">{{ $t('common.no_data') }}</p>
          </div>
        </div>

        <Pagination
          v-if="listData?.length > 0"
          :paginate="paginate"
          :hasOptionPaginate="false"
          @change-page="changePageData"
          @change-number="changeNumber"
        />
      </div>
    </div>
  </div>

  <ModalUploadCSV
    :dialogVisible="showModalUploadCSV"
    @cancel="showModalUploadCSV = false"
    @confirm="confirmUploadFileCSV"
    :errorValidate="errorValidate"
    :title="$t('common.title_modal_upload_file_csv')"
  />
</template>
<script>
import ModalUploadCSV from '@/components/common/ModalUploadCSV';
import Pagination from '@/components/common/Pagination';
import mixins from '@/helpers/mixins';
import vClickOutside from 'click-outside-vue3';

export default {
  name: 'Expense-List',
  mixins: [mixins],
  components: {
    Pagination,
    ModalUploadCSV,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      errorValidate: [],
      isShowDropdown: false,
      showBoxFilter: false,
      showModalUploadCSV: false,
      listData: [],
      paginate: {
        page: +this.$route.query.page || 1,
        per_page: +this.$route.query.per_page || 10,
      },
      searchParams: {
        keyword: this.$route.query.keyword || '',
        include_invalid: this.$route.query.include_invalid ? this.$route.query.include_invalid == 1 : true,
      },
    };
  },
  watch: {
    showModalUploadCSV() {
      this.errorValidate = [];
    },
  },
  created() {
    this.getListExpenseItem();
  },
  methods: {
    goToEditCostItem(item) {
      this.$router.push({
        name: this.PAGES.EXPENSE_ITEM_EDIT,
        params: {
          id: item.id,
        },
      });
    },
    confirmUploadFileCSV(listFiles) {
      if (listFiles?.length > 0) {
        let formData = new FormData();
        formData.append('file', listFiles[0]);

        this.startLoading();
        this.errorValidate = [];
        this.$services.cost.uploadCsv(
          formData,
          () => {
            this.endLoading();
            this.notifySuccess(this.$t('I-CM-103'));
            this.showModalUploadCSV = false;
            this.paginate.page = 1;
            this.getListExpenseItem();
          },
          err => {
            this.endLoading();
            if (err.code == 422) {
              this.errorValidate = err.error;
            } else {
              this.notifyError(this.$t(err.error));
            }
          },
        );
      }
    },
    changePageData(page) {
      this.paginate.page = +page;
      this.getListExpenseItem();
    },
    handleSearch() {
      this.paginate.page = 1;
      this.getListExpenseItem();
    },
    async getListExpenseItem() {
      const dataSearch = {
        ...this.searchParams,
        include_invalid: !this.searchParams.include_invalid,
        page: this.paginate.page,
        per_page: this.paginate.per_page,
      };

      this.startLoading();
      await this.$services.cost.getListExpenseItem(
        dataSearch,
        res => {
          this.endLoading();
          this.listData = res.list;
          this.paginate = { ...this.paginate, ...res.paginate };
          this.showBoxFilter = false;

          this.$router.push({
            path: this.$route.path,
            query: {
              page: res.paginate.current_page,
              per_page: res.paginate.per_page,
              keyword: dataSearch.keyword,
              include_invalid: this.searchParams.include_invalid ? 1 : 0,
            },
          });
        },
        err => {
          this.endLoading();
          this.notifyError(this.$t(err.error));
        },
      );
    },
    downloadFileCSV() {
      this.startLoading();
      this.$services.cost.downloadCSVExpenseItem(
        {
          ...this.searchParams,
          include_invalid: !this.searchParams.include_invalid,
        },
        res => {
          this.endLoading();
          res?.file_path && this.handleDownloadCSV(res.file_path);
        },
        err => {
          this.endLoading();
          this.notifyError(this.$t(err.error));
        },
      );
    },
    downloadFileCsvExample() {
      this.$services.cost.downloadCsvExample(
        res => {
          res?.file_path && this.handleDownloadCSV(res.file_path);
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    changeNumber(number) {
      this.paginate = {
        ...this.paginate,
        page: 1,
        per_page: number,
      };
      this.getListExpenseItem();
    },
    clearFilter() {
      this.searchParams = {
        keyword: '',
        include_invalid: false,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.section-filter-special {
  .checkbox-inline,
  .radio {
    margin-right: 22px !important;
    margin-top: 8px !important;
    margin-bottom: 6px !important;
    padding-left: 32px !important;
  }
}
</style>
