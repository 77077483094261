<template>
  <div class="filter-wrap">
    <div class="section noborder">
      <div class="hidden-xs">
        <h6>{{ $t('customer.file_type_regulation') }}</h6>
        <div v-for="(item, index) in listFileRegulation" :key="index">
          <div class="mbm">
            <ul class="uploaded--file-list" style="">
              <li>
                <a :href="item.link" target="_blank" style="display: flex; align-items: center" rel="noopener">
                  <i class="icon-abui-file"></i><span class="file--name">{{ item.name }}</span>
                </a>
                <span class="file--date">{{ item.created_at }}</span>
              </li>
            </ul>
          </div>
        </div>
        <p v-if="checkEmpty(listFileRegulation)" class="no-data">{{ $t('common.no_data') }}</p>
      </div>
      <div class="hidden-xs">
        <h6>{{ $t('customer.file_type_daily_allowance') }}</h6>
        <div v-for="(item, index) in listFileDailyAllowance" :key="index">
          <div class="mbm">
            <ul class="uploaded--file-list" style="">
              <li>
                <a :href="item.link" target="_blank" style="display: flex; align-items: center" rel="noopener">
                  <i class="icon-abui-file"></i><span class="file--name">{{ item.name }}</span>
                </a>
                <span class="file--date">{{ item.created_at }}</span>
              </li>
            </ul>
          </div>
        </div>
        <p v-if="checkEmpty(listFileDailyAllowance)" class="no-data">{{ $t('common.no_data') }}</p>
      </div>
      <div class="hidden-xs">
        <h6>{{ $t('customer.file_type_cost') }}</h6>
        <div v-for="(item, index) in listFileCost" :key="index">
          <div class="mbm">
            <ul class="uploaded--file-list" style="">
              <li>
                <a :href="item.link" target="_blank" style="display: flex; align-items: center" rel="noopener">
                  <i class="icon-abui-file"></i><span class="file--name">{{ item.name }}</span>
                </a>
                <span class="file--date">{{ item.created_at }}</span>
              </li>
            </ul>
          </div>
        </div>
        <p v-if="checkEmpty(listFileCost)" class="no-data">{{ $t('common.no_data') }}</p>
      </div>
      <div class="hidden-xs">
        <h6>{{ $t('customer.file_type_report') }}</h6>
        <div v-for="(item, index) in listFileReport" :key="index">
          <div class="mbm">
            <ul class="uploaded--file-list" style="">
              <li>
                <a :href="item.link" target="_blank" style="display: flex; align-items: center" rel="noopener">
                  <i class="icon-abui-file"></i><span class="file--name">{{ item.name }}</span>
                </a>
                <span class="file--date">{{ item.created_at }}</span>
              </li>
            </ul>
          </div>
        </div>
        <p v-if="checkEmpty(listFileReport)" class="no-data">{{ $t('common.no_data') }}</p>
      </div>
      <div class="hidden-xs">
        <h6>{{ $t('customer.file_type_insurance') }}</h6>
        <div v-for="(item, index) in listFileInsurance" :key="index">
          <div class="mbm">
            <ul class="uploaded--file-list" style="">
              <li>
                <a :href="item.link" target="_blank" style="display: flex; align-items: center" rel="noopener">
                  <i class="icon-abui-file"></i><span class="file--name">{{ item.name }}</span>
                </a>
                <span class="file--date">{{ item.created_at }}</span>
              </li>
            </ul>
          </div>
        </div>
        <p v-if="checkEmpty(listFileInsurance)" class="no-data">{{ $t('common.no_data') }}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'File',
  components: {},
  data() {
    return {
      listFileRegulation: [],
      listFileDailyAllowance: [],
      listFileCost: [],
      listFileReport: [],
      listFileInsurance: [],
    };
  },
  created() {
    this.getListFile();
  },
  computed: {
    customerId() {
      return this.$route.params.id;
    },
  },
  methods: {
    async getListFile() {
      this.startLoading();
      await this.$services.customer_enterprise.getListFile(
        { company_id: this.customerId },
        res => {
          this.endLoading();
          this.listFileRegulation = res.list.filter(item => !this.checkEmpty(item.regulation_id));
          this.listFileDailyAllowance = res.list.filter(item => !this.checkEmpty(item.daily_allowance_id));
          this.listFileCost = res.list.filter(item => !this.checkEmpty(item.cost_id));
          this.listFileReport = res.list.filter(item => !this.checkEmpty(item.report_id));
          this.listFileInsurance = res.list.filter(
            item =>
              !this.checkEmpty(item.insurance_id) || (!this.checkEmpty(item.is_insurance) && item.is_insurance == 1),
          );
        },
        err => {
          this.endLoading();
          this.notifyError(this.$t(err.error));
        },
      );
    },
  },
};
</script>
