<template>
  <div class="wrapper">
    <div v-if="isConnectSb" class="header-info">
      <i class="info-item-linked" style="display: inline-block"></i>{{ $t('employee.smartBTM') }}
    </div>
    <div class="section noborder">
      <div class="row">
        <div class="col-sm-4 col-md-2 col-lg-2">
          <div class="form-group viewonly">
            <label>
              <span class="label-text">{{ $t('employee.employee_number') }}</span>
            </label>
            <p class="viewonly-text input-sm input-sm" id="customer-id__view">{{ dataRequest.employee_number }}</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 col-md-4">
          <div class="form-group viewonly">
            <label>
              <span class="label-text">{{ $t('employee.name') }}</span>
            </label>
            <p class="viewonly-text input-sm input-sm" id="customer-id__view">{{ dataRequest.name }}</p>
          </div>
        </div>
        <div class="col-sm-6 col-md-4">
          <div class="form-group viewonly">
            <label>
              <span class="label-text">{{ $t('employee.department') }}</span>
            </label>
            <p class="viewonly-text input-sm input-sm" id="customer-id__view">
              {{ positionName }}
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-4">
          <div class="form-group viewonly">
            <label>
              <span class="label-text">{{ $t('employee.email') }}</span>
            </label>
            <p class="viewonly-text input-sm input-sm" id="customer-id__view">{{ dataRequest.emails_string }}</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 col-md-4">
          <div class="form-group viewonly">
            <label>
              <span class="label-text">{{ $t('employee.authority') }}</span>
            </label>
            <p class="viewonly-text input-sm input-sm" id="customer-id__view">{{ roleLabel }}</p>
          </div>
        </div>
      </div>
      <div class="row" v-if="dataRequest.organization_id">
        <div class="col-sm-6">
          <div class="form-group viewonly">
            <label>
              <span class="label-text">{{ $t('employee.organization') }}</span>
            </label>
            <p class="viewonly-text input-sm input-sm" id="customer-id__view">{{ organizationLabel }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- COMMUTER -->
    <div class="section">
      <h4 class="section-title">{{ $t('employee.commuter') }}</h4>
      <div style="display: flex; align-items: center" v-for="(railRoad, index) in listRailroadCompany" :key="index">
        <div class="row" style="width: 100%">
          <div class="col-sm-4">
            <div class="form-group viewonly">
              <label>
                <span class="label-text">{{ $t('employee.railway_company_name') }}</span>
              </label>
              <p class="viewonly-text input-sm">{{ railRoad.railway_company_name }}</p>
            </div>
          </div>
          <div class="col-sm-8">
            <div class="form-group viewonly">
              <label>
                <span class="label-text">{{ $t('employee.departure_station') }}</span>
              </label>
              <p class="viewonly-text input-sm">
                <span>{{ railRoad.departure_station }}</span>
                <small class="member--transport--divider">
                  <i class="icon-abui-arrow-alt-left" style="margin-left: 10px"></i>
                  <i class="icon-abui-arrow-alt-right" style="margin-right: 10px"></i>
                </small>
                <span>{{ railRoad.arrival_station }}</span>
              </p>
            </div>
          </div>
        </div>
        <button
          :disabled="listRailroadCompany.length == 1"
          @click="confirmCommuter(railRoad)"
          class="btn btn-sm btn-flat-danger"
        >
          <i class="icon-abui-cross mll"></i>
        </button>
      </div>
    </div>
    <!-- PASSPORT -->
    <div class="section">
      <h4 class="section-title">{{ $t('employee.passport') }}</h4>
      <div class="row mbs">
        <div class="col-sm-6 col-md-3 show--edit show--new hide--view">
          <div class="form-group">
            <a @click="showModalUploadFile = true" class="btn btn-sm btn-inverse-primary">
              <i class="icon-abui-upload prx"></i>{{ $t('employee.btn_passport') }}
            </a>
            <a
              href="#"
              class="btn btn-sm btn-flat-danger"
              style="display: none"
              data-toggle="modal"
              data-target=".modal--del"
            >
              <i class="icon-abui-trash"></i>
            </a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="info-list-file">
          <ul class="trip--info--file-list">
            <li v-for="item in listFileUpdate" :key="item.id">
              <a :href="item.file_path" target="_blank">
                <i class="icon-abui-file icon-file-new" /><span class="file-name-new">{{ item.name }}</span>
                <span class="file--date"> </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="show--edit hide--view show--new">
          <div class="col-sm-6 col-md-4">
            <div class="form-group show--edit hide--view show--new">
              <label>
                <span class="label-text">{{ $t('employee.passport_number') }}</span>
              </label>
              <input maxlength="10" type="text" class="form-control input-sm" v-model="passport.passport_number" />
            </div>
          </div>
          <div class="col-sm-6 col-md-4" style="display: flex; justify-content: left">
            <div class="form-group picker-custom" style="max-width: 25%; margin-right: 20px">
              <label for="select--year-issue">
                <span class="label-text">{{ $t('employee.passport_date_of_issue') }}</span>
              </label>
              <div class="btn-group bootstrap-select select-block select-sm select--year">
                <el-select
                  popper-class="custom-dropdown-select"
                  v-model="passport.year_of_issue"
                  :clearable="false"
                  placeholder="-"
                >
                  <el-option v-for="item in listYearStartPassport" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
              </div>
            </div>
            <div class="form-group picker-custom" style="max-width: 20%; margin-right: 20px">
              <label class="invisible" for="select--month-issue"><span class="label-text">月</span></label>
              <el-select
                popper-class="custom-dropdown-select"
                v-model="passport.month_of_issue"
                :clearable="false"
                placeholder="-"
              >
                <el-option v-for="item in listMonth" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </div>
            <div class="form-group picker-custom" style="max-width: 20%">
              <label class="invisible" for="select--day-issue"><span class="label-text">日</span></label>
              <el-select
                popper-class="custom-dropdown-select"
                v-model="passport.date_of_issue"
                :clearable="false"
                placeholder="-"
              >
                <el-option v-for="item in listDay" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </div>
          </div>
          <div class="col-sm-6 col-md-4" style="display: flex; justify-content: left">
            <div class="form-group picker-custom" style="max-width: 25%; margin-right: 20px">
              <label for="select--year-expiry">
                <span class="label-text">{{ $t('employee.passport_date_of_expiry') }}</span>
              </label>
              <el-select
                popper-class="custom-dropdown-select"
                v-model="passport.year_of_validity"
                :clearable="false"
                placeholder="-"
              >
                <el-option v-for="item in listYear" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </div>
            <div class="form-group picker-custom" style="max-width: 20%; margin-right: 20px">
              <label class="invisible" for="select--month-expiry"><span class="label-text">月</span></label>
              <el-select
                popper-class="custom-dropdown-select"
                v-model="passport.month_of_validity"
                :clearable="false"
                placeholder="-"
              >
                <el-option v-for="item in listMonth" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </div>
            <div class="form-group picker-custom" style="max-width: 20%">
              <label class="invisible" for="select--day-expiry"><span class="label-text">日</span></label>
              <el-select
                popper-class="custom-dropdown-select"
                v-model="passport.date_of_validity"
                :clearable="false"
                placeholder="-"
              >
                <el-option v-for="item in listDay" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="show--edit hide--view show--new">
          <div class="col-sm-6 col-md-4 col-all-closer col-all-closer--leftend">
            <div class="form-group">
              <label class="inline-guide">
                <span class="label-text">{{ $t('employee.middle_name') }}</span>
                <el-tooltip
                  class="label-icon-guide"
                  :content="$t('employee.name_title')"
                  raw-content
                  popper-class="tool-tip-guide"
                  placement="top"
                >
                  <span class="label-icon-guide" />
                </el-tooltip>
              </label>
              <input
                maxlength="100"
                type="text"
                class="form-control input-sm"
                placeholder="Family Name"
                v-model="passport.last_name"
              />
            </div>
          </div>
          <div class="col-sm-6 col-md-4" style="margin-top: 13px">
            <div class="form-group">
              <label><span class="label-text"></span></label>
              <input
                maxlength="100"
                type="text"
                class="form-control input-sm"
                placeholder="First Name"
                v-model="passport.first_name"
              />
            </div>
          </div>
          <div class="col-sm-6 col-md-4 col-all-closer--rightend" style="margin-top: 13px">
            <div class="form-group">
              <label><span class="label-text"></span></label>
              <input
                maxlength="100"
                type="text"
                class="form-control input-sm"
                placeholder="Middle Name"
                v-model="passport.middle_name"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="show--edit hide--view show--new">
          <div class="col-sm-6 col-md-4">
            <div class="form-group">
              <label>
                <span class="label-text">{{ $t('employee.sex') }}</span>
              </label>
              <div>
                <el-radio v-model="passport.sex" label="1">{{ $t('employee.male') }}</el-radio>
                <el-radio v-model="passport.sex" label="2">{{ $t('employee.female') }}</el-radio>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 col-md-4">
          <div class="form-group show--edit hide--view show--new">
            <label>
              <span class="label-text">{{ $t('employee.country') }}</span>
            </label>
            <SingleOptionSelect
              v-model="passport.nationality"
              :list-data="listCountry"
              :displayByLocale="true"
              :placeholder="$t('common.please_select')"
              @handle-selected-params="listenCountry"
            />
          </div>
        </div>
        <div class="col-md-8">
          <div class="form-inline">
            <div class="form-group picker-custom" style="margin-right: -40px">
              <label for="select--year-birth">
                <span class="label-text">{{ $t('employee.birth_date') }}</span>
              </label>
              <div class="btn-group bootstrap-select select-block select-sm select--year">
                <el-select
                  style="width: 75%"
                  popper-class="custom-dropdown-select"
                  v-model="dataBirth.year"
                  :clearable="false"
                  placeholder="-"
                >
                  <el-option v-for="item in listYearBirth" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
              </div>
            </div>
            <div class="form-group picker-custom" style="margin-right: -107px">
              <label class="invisible" for="select--month-birth"><span class="label-text">月</span></label>
              <div class="btn-group bootstrap-select select-block select-sm select--month">
                <el-select
                  style="width: 40%"
                  popper-class="custom-dropdown-select"
                  v-model="dataBirth.month"
                  :clearable="false"
                  placeholder="-"
                >
                  <el-option v-for="item in listMonth" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
              </div>
            </div>
            <div class="form-group picker-custom" style="margin-right: -100px">
              <label class="invisible" for="select--day-birth"><span class="label-text">日</span></label>
              <div class="btn-group bootstrap-select select-block select-sm select--day">
                <el-select
                  style="width: 40%"
                  popper-class="custom-dropdown-select"
                  v-model="dataBirth.date"
                  :clearable="false"
                  placeholder="-"
                >
                  <el-option v-for="item in listDay" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
              </div>
            </div>
            <div class="form-group viewonly mls hidden-xs">
              <label>
                <span class="label-text">{{ $t('employee.age') }}</span>
              </label>
              <div class="input-group">
                <p class="viewonly-text input-sm text-right" style="width: 4em">{{ age_now }}</p>
                <span class="input-group-addon">{{ $t('employee.age_now') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-10 col-md-12" style="display: flex; justify-content: center">
        <p class="error-feedback" v-text="errors.passport"></p>
      </div>
    </div>
    <!-- VISA -->
    <div class="section">
      <h4 class="section-title">{{ $t('employee.visa') }}</h4>
      <div>
        <div
          v-for="(visa, index) in listVisas"
          :key="index"
          class="row"
          style="margin-bottom: 25px; border-bottom: 1px solid #d0d6da"
        >
          <div class="show--edit hide--view show--new">
            <div class="col-sm-10 col-md-11">
              <div class="row">
                <div class="col-sm-3 col-md-2">
                  <div class="form-group">
                    <label>
                      <span class="label-text">{{ $t('employee.visa_type') }}</span>
                    </label>
                    <el-select
                      v-model="listVisas[index].type_id"
                      name="input"
                      popper-class="custom-dropdown-select"
                      class="select-block select-sm"
                    >
                      <el-option
                        v-for="item in listVisa"
                        :key="item.id"
                        :label="this.labelLocale(item)"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </div>
                <div v-if="showVisaDetail(index)">
                  <div class="col-sm-3 col-md-4">
                    <div class="form-group show--edit hide--view show--new">
                      <label>
                        <span class="label-text">{{ $t('employee.visa_country') }}</span>
                      </label>
                      <div class="btn-group bootstrap-select select-block select-sm">
                        <SingleOptionSelect
                          v-model="listVisas[index].country_id"
                          :list-data="listCountry"
                          :display-by-locale="true"
                          :placeholder="$t('common.please_select')"
                          :option-index="{ haveIndex: true, index }"
                          @handle-selected-params="listenCountryVisa"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6 col-md-4">
                    <div class="form-group">
                      <label>
                        <span class="label-text">{{ $t('employee.visa_number') }}</span>
                      </label>
                      <input
                        maxlength="10"
                        v-model="listVisas[index].visa_number"
                        type="text"
                        class="form-control input-sm"
                      />
                    </div>
                  </div>
                  <div class="col-sm-3 col-md-6"></div>
                  <div class="col-sm-3 col-md-6">
                    <div class="form-group show--edit hide--view show--new">
                      <label>
                        <span class="label-text">{{ $t('employee.place_of_use') }}</span>
                      </label>
                      <input v-model="listVisas[index].place_of_use" type="text" class="form-control input-sm" />
                    </div>
                  </div>
                  <div class="col-sm-3 col-md-6">
                    <div class="">
                      <label>
                        <span class="label-text">{{ $t('employee.purpose') }}</span>
                      </label>
                      <input v-model="listVisas[index].purpose" type="text" class="form-control input-sm" />
                    </div>
                  </div>
                  <div class="col-sm-3 col-md-6" style="display: flex; justify-content: left; margin-top: 8px">
                    <div class="form-group picker-custom" style="max-width: 20%; margin-right: 20px">
                      <label for="select--year-issue">
                        <span class="label-text">{{ $t('employee.visa_date_issue') }}</span>
                      </label>
                      <div class="btn-group bootstrap-select select-block select-sm select--year">
                        <el-select
                          popper-class="custom-dropdown-select"
                          v-model="listVisas[index].year_issue"
                          :clearable="false"
                          placeholder="-"
                        >
                          <el-option
                            v-for="item in listYear"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          />
                        </el-select>
                      </div>
                    </div>
                    <div class="form-group picker-custom" style="max-width: 15%; margin-right: 20px">
                      <label class="invisible" for="select--month-expiry"><span class="label-text">月</span></label>
                      <div class="btn-group bootstrap-select select-block select-sm select--month">
                        <el-select
                          popper-class="custom-dropdown-select"
                          v-model="listVisas[index].month_issue"
                          :clearable="false"
                          placeholder="-"
                        >
                          <el-option
                            v-for="item in listMonth"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          />
                        </el-select>
                      </div>
                    </div>
                    <div class="form-group picker-custom" style="max-width: 15%">
                      <label class="invisible" for="select--day-expiry"><span class="label-text">日</span></label>
                      <div class="btn-group bootstrap-select select-block select-sm select--day">
                        <el-select
                          popper-class="custom-dropdown-select"
                          v-model="listVisas[index].date_issue"
                          :clearable="false"
                          placeholder="-"
                        >
                          <el-option
                            v-for="item in listDay"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          />
                        </el-select>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-3 col-md-6" style="display: flex; justify-content: left">
                    <div class="form-group picker-custom" style="max-width: 20%; margin-right: 20px">
                      <label for="select--year-expiry">
                        <span class="label-text">{{ $t('employee.visa_expiry') }}</span>
                      </label>
                      <div class="btn-group bootstrap-select select-block select-sm select--year">
                        <el-select
                          popper-class="custom-dropdown-select"
                          v-model="listVisas[index].year_expiry"
                          :clearable="false"
                          placeholder="-"
                        >
                          <el-option
                            v-for="item in listYear"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          />
                        </el-select>
                      </div>
                    </div>
                    <div class="form-group picker-custom" style="max-width: 15%; margin-right: 20px">
                      <label class="invisible" for="select--month-expiry"><span class="label-text">月</span></label>
                      <div class="btn-group bootstrap-select select-block select-sm select--month">
                        <el-select
                          popper-class="custom-dropdown-select"
                          v-model="listVisas[index].month_expiry"
                          :clearable="false"
                          placeholder="-"
                        >
                          <el-option
                            v-for="item in listMonth"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          />
                        </el-select>
                      </div>
                    </div>
                    <div class="form-group picker-custom" style="max-width: 15%">
                      <label class="invisible" for="select--day-expiry"><span class="label-text">日</span></label>
                      <div class="btn-group bootstrap-select select-block select-sm select--day">
                        <el-select
                          popper-class="custom-dropdown-select"
                          v-model="listVisas[index].date_expiry"
                          :clearable="false"
                          placeholder="-"
                        >
                          <el-option
                            v-for="item in listDay"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          />
                        </el-select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-2 col-md-1">
              <div class="form-group pull-right">
                <label><span class="label-text"></span></label>
                <button
                  :disabled="listVisas.length == 1"
                  @click="confirmVisa(visa)"
                  class="btn btn-sm btn-flat-danger btn-block"
                >
                  <span class="icon-abui-cross"></span>
                </button>
              </div>
            </div>
            <div class="col-sm-10 col-md-12" style="display: flex; justify-content: center">
              <p class="error-feedback" v-text="errors.listVisas[index]"></p>
            </div>
          </div>
        </div>

        <div class="row row--addcontrol">
          <div class="col-md-12">
            <div class="form-group">
              <button @click="addNewVisa" class="btn btn-sm btn-flat-default">
                <span class="icon-abui-plus"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- CONTACT -->
    <div class="section">
      <h4 class="section-title">{{ $t('employee.contact') }}</h4>

      <div class="row">
        <div class="show--edit show--new hide--view">
          <div class="col-xs-6 col-sm-2 col-md-2 col-all-closer col-all-closer--leftend">
            <div class="form-group">
              <label>
                <span class="label-text">{{ $t('employee.zip_code') }}</span>
              </label>
              <input
                v-model="dataContact.zip_code"
                id="zip-code__start"
                class="form-control input-sm"
                type="text"
                maxlength="8"
                @input="dataContact.zip_code = convertNumberFullToHalf(dataContact.zip_code)"
                placeholder="〒"
              />
            </div>
          </div>
          <div class="col-xs-6 col-sm-2 col-md-2 col-all-closer col-all-closer--leftend">
            <div class="form-group">
              <button
                id="zip-btn__start"
                class="btn btn-sm btn-inverse-primary btn--ls"
                @click="getAddressFromZipCode(this.dataContact.zip_code)"
                :disabled="dataContact.country_type == 2"
              >
                <span class="icon-abui-write prx"></span>{{ $t('employee.btn_zip_code') }}
              </button>
            </div>
          </div>

          <div class="col-xs-6 col-sm-8 col-md-8 col-all-closer col-all-closer--rightend">
            <div class="form-group btn--ls">
              <label
                class="radio radio-inline radio-sm radio-m-d"
                :class="dataContact.country_type == 1 && 'checked'"
                @click="dataContact.country_type = 1"
              >
                <span class="icons">
                  <span class="first-icon icon-abui-radio-unchecked" />
                  <span class="second-icon icon-abui-radio-checked" />
                </span>
                <input type="radio" name="03-03" value="1" checked="" />
                <span class="text_type-special">{{ $t('approval_flow.domestic') }}</span>
              </label>
              <label
                class="radio radio-inline radio-sm radio-m-d"
                :class="dataContact.country_type == 2 && 'checked'"
                @click="dataContact.country_type = 2"
              >
                <span class="icons">
                  <span class="first-icon icon-abui-radio-unchecked" />
                  <span class="second-icon icon-abui-radio-checked" />
                </span>
                <input type="radio" name="03-03" value="2" />
                <span class="text_type-special">{{ $t('approval_flow.overseas') }}</span>
              </label>
            </div>
          </div>
          <div v-if="errors.zip_code" class="col-xs-12 col-sm-12 col-md-12" style="margin-top: -12px">
            <p role="alert" class="error-feedback" v-text="errors.zip_code"></p>
          </div>
          <div v-else-if="errors.err_zip_code" class="col-xs-12 col-sm-12 col-md-12" style="margin-top: -12px">
            <p role="alert" class="error-feedback" v-text="errors.err_zip_code"></p>
          </div>
          <div class="col-xs-6 col-sm-12 col-md-12 col-all-closer col-all-closer--rightend"></div>
          <div class="col-sm-2 col-md-2 col-sm-closer col-all-closer--leftend">
            <div class="form-group required">
              <label class="sr-only">{{ $t('employee.city') }}</label>
              <el-select
                v-show="dataContact.country_type == 1"
                v-model="dataContact.city_id"
                name="input"
                popper-class="custom-dropdown-select"
                class="select-block select-sm"
              >
                <el-option v-for="item in listCity" :key="item.id" :label="labelLocale(item)" :value="item.id">
                </el-option>
              </el-select>
              <input
                v-show="dataContact.country_type == 2"
                maxlength="100"
                type="text"
                v-model="dataContact.country_name"
                class="form-control input-sm"
                :placeholder="$t('employee.country_name')"
              />
            </div>
          </div>
          <div class="col-sm-4 col-md-24 col-sm-closer">
            <div class="form-group required">
              <label class="sr-only">住所1</label>
              <input
                maxlength="100"
                type="text"
                v-model="dataContact.street"
                id="municipality__start"
                class="form-control input-sm"
                :placeholder="$t('employee.pl_street')"
              />
            </div>
          </div>
          <div class="col-sm-6 col-md-6 col-sm-closer col-sm-closer--rightend col-md-closer--rightend">
            <div class="form-group required">
              <label class="sr-only">住所2</label>
              <input
                maxlength="100"
                v-model="dataContact.district"
                type="text"
                class="form-control input-sm"
                :placeholder="$t('employee.pl_district')"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 col-md-4">
          <div class="form-group">
            <label>
              <span class="label-text">{{ $t('employee.phone_emergency') }}</span>
            </label>
            <input
              maxlength="20"
              v-model="dataContact.phone"
              type="text"
              @input="dataContact.phone = convertNumberFullToHalf(dataContact.phone)"
              class="form-control input-sm"
            />
          </div>
        </div>
      </div>

      <div class="col-sm-10 col-md-12" style="display: flex; justify-content: center">
        <p class="error-feedback" v-text="errors.dataContact"></p>
      </div>
      <hr />
      <p>{{ $t('employee.contact_emergency') }}</p>
      <div v-for="(contact, index) in listContact" :key="index" class="member-contact--item--wrap">
        <div class="member-contact--item" style="border-top-width: 0">
          <div class="member-contact--item--num">{{ index + 1 }}</div>
          <div style="width: 100%">
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <div class="form-group">
                  <label>
                    <span class="label-text">{{ $t('employee.full_name') }}</span>
                  </label>
                  <input
                    v-model="listContact[index].full_name"
                    type="text"
                    class="form-control input-sm"
                    :placeholder="$t('employee.pl_full_name')"
                  />
                </div>
              </div>
              <div class="col-sm-6 col-md-3">
                <div class="form-group">
                  <label>
                    <span class="label-text">{{ $t('employee.relationship') }}</span>
                  </label>
                  <el-select
                    v-model="listContact[index].relationship_id"
                    name="input"
                    popper-class="custom-dropdown-select"
                    class="select-block select-sm"
                  >
                    <el-option v-for="item in listRelationship" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-6 col-sm-2 col-md-2 col-all-closer col-all-closer--leftend">
                <div class="form-group">
                  <label>
                    <span class="label-text">{{ $t('employee.address') }}</span>
                  </label>
                  <input
                    v-model="listContact[index].zip_code"
                    class="form-control input-sm"
                    type="text"
                    placeholder="〒"
                    maxlength="8"
                    @input="listContact[index].zip_code = convertNumberFullToHalf(listContact[index].zip_code)"
                  />
                </div>
              </div>
              <div
                class="col-xs-6 col-sm-2 col-md-2 col-all-closer col-all-closer--leftend"
                style="padding-top: 22px"
              >
                <div class="form-group">
                  <button @click="getAddressFromZipCodeEmergency(index)" class="btn btn-sm btn-inverse-primary" :disabled="listContact[index].country_type == 2">
                    <span class="icon-abui-write prx"></span>{{ $t('employee.btn_zip_code') }}
                  </button>
                </div>
              </div>

              <div class="col-xs-6 col-sm-8 col-md-8 col-all-closer col-all-closer--rightend">
                <div class="form-group btn--ls">
                  <label
                    class="radio radio-inline radio-sm radio-m-d"
                    :class="listContact[index].country_type == 1 && 'checked'"
                    @click="listContact[index].country_type = 1"
                  >
                    <span class="icons">
                      <span class="first-icon icon-abui-radio-unchecked" />
                      <span class="second-icon icon-abui-radio-checked" />
                    </span>
                    <input type="radio" name="03-03" value="1" checked="" />
                    <span class="text_type-special">{{ $t('approval_flow.domestic') }}</span>
                  </label>
                  <label
                    class="radio radio-inline radio-sm radio-m-d"
                    :class="listContact[index].country_type == 2 && 'checked'"
                    @click="listContact[index].country_type = 2"
                  >
                    <span class="icons">
                      <span class="first-icon icon-abui-radio-unchecked" />
                      <span class="second-icon icon-abui-radio-checked" />
                    </span>
                    <input type="radio" name="03-03" value="2" />
                    <span class="text_type-special">{{ $t('approval_flow.overseas') }}</span>
                  </label>
                </div>
              </div>

              <div class="col-xs-12 col-sm-12 col-md-12" style="margin-top: -12px">
                <p role="alert" class="error-feedback" v-text="errors.zip_code2[index]"></p>
              </div>
              <div class="col-sm-2 col-md-2 col-sm-closer col-all-closer--leftend">
                <div class="form-group required">
                  <label class="sr-only">{{ $t('employee.city') }}</label>
                  <div class="btn-group bootstrap-select select-block select-sm">
                    <el-select
                      v-show="listContact[index].country_type == 1"
                      v-model="listContact[index].city_id"
                      name="input"
                      popper-class="custom-dropdown-select"
                      class="select-block select-sm"
                    >
                      <el-option v-for="item in listCity" :key="item.id" :label="labelLocale(item)" :value="item.id">
                      </el-option>
                    </el-select>
                    <input
                      v-show="listContact[index].country_type == 2"
                      maxlength="100"
                      type="text"
                      v-model="listContact[index].country_name"
                      class="form-control input-sm"
                      :placeholder="$t('employee.country_name')"
                    />
                  </div>
                </div>
              </div>
              <div class="col-sm-4 col-md-24 col-sm-closer">
                <div class="form-group required">
                  <label class="sr-only">住所1</label>
                  <input
                    type="text"
                    id="municipality__start"
                    class="form-control input-sm"
                    :placeholder="$t('employee.pl_street')"
                    v-model="listContact[index].street"
                  />
                </div>
              </div>
              <div class="col-sm-6 col-md-6 col-sm-closer col-sm-closer--rightend col-md-closer--rightend">
                <div class="form-group required">
                  <label class="sr-only">住所2</label>
                  <input
                    type="text"
                    class="form-control input-sm"
                    :placeholder="$t('employee.pl_district')"
                    v-model="listContact[index].district"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6 col-md-4">
                <div class="form-group">
                  <label>
                    <span class="label-text">{{ $t('employee.phone') }}</span>
                  </label>
                  <input
                    type="text"
                    maxlength="20"
                    class="form-control input-sm"
                    v-model="listContact[index].phone"
                    @input="listContact[index].phone = convertNumberFullToHalf(listContact[index].phone)"
                  />
                </div>
              </div>
            </div>
          </div>
          <div style="margin-left: 24px">
            <button
              @click="confirmContact(listContact[index])"
              class="btn btn-sm btn-flat-danger"
            >
              <span class="icon-abui-cross"></span>
            </button>
          </div>
        </div>
        <div style="display: flex; justify-content: center; margin-top: 20px">
          <p class="error-feedback" v-text="errors.listContact[index]"></p>
        </div>
      </div>

      <div class="row--addcontrol--wrap">
        <hr class="mvl" />
        <div class="row row--addcontrol">
          <div class="col-md-12">
            <div class="form-group">
              <button v-show="listContact.length < 3" @click="addNewContact" class="btn btn-sm btn-flat-default">
                <span class="icon-abui-plus"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- BANK -->
    <div class="section">
      <h4 class="section-title">{{ $t('employee.bank') }}</h4>
      <div class="row">
        <div class="col-sm-6 col-md-4">
          <div class="form-group viewonly">
            <label>
              <span class="label-text">{{ $t('employee.delivery_place') }}</span>
            </label>
            <p class="viewonly-text input-sm input-sm" id="customer-id__view">{{ bank.delivery_place }}</p>
          </div>
        </div>
        <div v-show="showBranch" class="col-sm-6 col-md-4">
          <div class="form-group viewonly">
            <label>
              <span class="label-text">{{ $t('employee.branch') }}</span>
            </label>
            <p class="viewonly-text input-sm input-sm" id="customer-id__view">{{ bank.branch }}</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 col-md-4">
          <div class="form-group">
            <label>
              <span class="label-text">{{ $t('employee.bank_type') }}</span>
            </label>
            <div>
              <el-radio v-model="bank.type" disabled label="1">{{ $t('employee.usually') }}</el-radio>
              <el-radio v-model="bank.type" disabled label="2">{{ $t('employee.current') }}</el-radio>
              <el-radio v-model="bank.type" disabled label="3">{{ $t('employee.savings') }}</el-radio>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-4">
          <div class="form-group">
            <label>
              <span class="label-text">{{ $t('employee.account_number') }}</span>
            </label>
            <input readonly maxlength="15" v-model="bank.account_number" type="text" class="form-control input-sm" />
          </div>
        </div>
      </div>
      <div class="col-sm-10 col-md-12" style="display: flex; justify-content: center">
        <p class="error-feedback" v-text="errors.bank"></p>
      </div>
    </div>
    <!-- FOOTER -->
    <div class="section" v-if="!isDeleted">
      <div class="row">
        <div class="col-sm-3 col-md-2 hidden-xs"></div>
        <div
          :class="!isDetail && 'col-md-offset-8'"
          class="col-sm-3 col-md-2 col-sm-offset-6 show--edit show--new hide--view"
        >
          <a class="btn btn-sm btn-primary btn-block open-alert js-btn--save alertAfterAnother mbl" @click="submitData">
            {{ $t('common.submit') }}
          </a>
        </div>
      </div>
    </div>
    <ModalConfirm
      :dialogVisible="showBoxConfirm"
      @cancel="cancelConfirm"
      @yes="confirmSuccess"
      :title="titleBoxConfirm"
    />
    <ModalTransportationCommuter
      :dialogVisible="showTransportation"
      @cancel="showTransportation = false"
      @confirm="confirmUploadTransportation"
    />
    <ModalUploadImage
      :dialogVisible="showModalUploadFile"
      :isPassport="isPassport"
      @cancel="showModalUploadFile = false"
      @confirm="confirmUploadFile"
      :title="$t('employee.title_modal_passport')"
    />
  </div>
</template>
<script>
import ModalConfirm from '@/components/common/ModalConfirm';
import ModalTransportationCommuter from '@/components/common/ModalTransportationCommuter';
import SingleOptionSelect from '@/components/common/SingleOptionSelect.vue';
import moment from 'moment';
import ModalUploadImage from '@/components/common/ModalUploadImage';
export default {
  name: 'ProfileForm',
  components: { ModalConfirm, ModalTransportationCommuter, SingleOptionSelect, ModalUploadImage },
  emits: ['create-success', 'onChangeData'],
  props: {
    dataForm: {
      type: Object,
      default: () => {},
    },
    isDetail: {
      type: Boolean,
      default: false,
    },
    isDeleted: {
      type: Boolean,
      default: false,
    },
    isOperator: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isPassport: true,
      uploadPassportSuccess: false,
      showModalUploadFile: false,
      titleBoxConfirm: '',
      showTransportation: false,
      showBoxConfirm: false,
      isConnectSb: false,
      customPrefix: {},
      age_now: '',
      dataRequest: {
        authority: 1,
        name: '',
        department: null,
        email: '',
        emails_string: '',
        organization_id: null,
        employee_number: '',
      },
      dataRequestOrigin: {
        authority: 1,
        name: '',
        department: null,
        email: '',
        emails_string: '',
        organization_id: null,
        employee_number: '',
      },
      dataContact: {
        zip_code: '',
        city_id: '',
        street: '',
        district: '',
        phone: '',
        country_type: 1,
        country_name: '',
      },
      dataContactOrigin: {
        zip_code: '',
        city_id: '',
        street: '',
        district: '',
        phone: '',
        country_type: 1,
        country_name: '',
      },
      dataVisa: {},
      listContact: [],
      listContactOrigin: [],
      listVisas: [],
      listVisasOrigin: [],
      bank: {
        delivery_place: '',
        branch: '',
        type: '',
        account_number: '',
      },
      bankOrigin: {
        delivery_place: '',
        branch: '',
        type: '',
        account_number: '',
      },
      passport: {
        passport_number: '',
        year_of_issue: '',
        month_of_issue: '',
        date_of_issue: '',
        year_of_validity: '',
        month_of_validity: '',
        date_of_validity: '',
        last_name: '',
        first_name: '',
        middle_name: '',
        sex: '',
        nationality: '',
      },
      passportOrigin: {
        passport_number: '',
        year_of_issue: '',
        month_of_issue: '',
        date_of_issue: '',
        year_of_validity: '',
        month_of_validity: '',
        date_of_validity: '',
        last_name: '',
        first_name: '',
        middle_name: '',
        sex: '',
        nationality: '',
      },
      dataBirth: {
        year: '',
        month: '',
        date: '',
      },
      dataBirthOrigin: {
        year: '',
        month: '',
        date: '',
      },
      listPosition: [],
      listRole: [],
      listOrganizations: [],
      listBank: [],
      listBranch: [],
      listCountry: [],
      listVisa: [],
      listRelationship: [],
      listRailroadCompany: [],
      listCity: [],
      showBranch: false,
      employeeId: '',
      itemVisaDelete: '',
      itemCommuterDelete: '',
      isDeleteVisa: false,
      isDeleteCommuter: false,
      isDeleteContact: false,
      isBack: false,
      isBackUpdate: false,
      itemContactDelete: '',
      errors: {
        listVisas: [],
        listContact: [],
        zip_code2: [],
        zip_code: '',
      },
      listFileUpdate: [],
      listYearBirth: [
        {
          value: '1957',
          label: '1957（昭和32）年',
        },
        {
          value: '1958',
          label: '1958（昭和33）年',
        },
        {
          value: '1959',
          label: '1959（昭和34）年',
        },
        {
          value: '1960',
          label: '1960（昭和35）年',
        },
        {
          value: '1961',
          label: '1961（昭和36）年',
        },
        {
          value: '1962',
          label: '1962（昭和37）年',
        },
        {
          value: '1963',
          label: '1963（昭和38）年',
        },
        {
          value: '1964',
          label: '1964（昭和39）年',
        },
        {
          value: '1965',
          label: '1965（昭和40）年',
        },
        {
          value: '1966',
          label: '1966（昭和41）年',
        },
        {
          value: '1967',
          label: '1967（昭和42）年',
        },
        {
          value: '1968',
          label: '1968（昭和43）年',
        },
        {
          value: '1969',
          label: '1969（昭和44）年',
        },
        {
          value: '1970',
          label: '1970（昭和45）年',
        },
        {
          value: '1971',
          label: '1971（昭和46）年',
        },
        {
          value: '1972',
          label: '1972（昭和47）年',
        },
        {
          value: '1973',
          label: '1963（昭和48）年',
        },
        {
          value: '1974',
          label: '1974（昭和49）年',
        },
        {
          value: '1975',
          label: '1975（昭和50）年',
        },
        {
          value: '1976',
          label: '1976（昭和51）年',
        },
        {
          value: '1977',
          label: '1977（昭和52）年',
        },
        {
          value: '1978',
          label: '1978（昭和53）年',
        },
        {
          value: '1979',
          label: '1979（昭和54）年',
        },
        {
          value: '1980',
          label: '1980（昭和55）年',
        },
        {
          value: '1981',
          label: '1981（昭和56）年',
        },
        {
          value: '1982',
          label: '1982（昭和57）年',
        },
        {
          value: '1983',
          label: '1983（昭和58）年',
        },
        {
          value: '1984',
          label: '1984（昭和59）年',
        },
        {
          value: '1985',
          label: '1985（昭和60）年',
        },
        {
          value: '1986',
          label: '1986（昭和61）年',
        },
        {
          value: '1987',
          label: '1987（昭和62）年',
        },
        {
          value: '1988',
          label: '1988（昭和63）年',
        },
        {
          value: '1989',
          label: '1989（平成1）年',
        },
        {
          value: '1990',
          label: '1990（平成2）年',
        },
        {
          value: '1991',
          label: '1991（平成3）年',
        },
        {
          value: '1992',
          label: '1992（平成4）年',
        },
        {
          value: '1993',
          label: '1993（平成5）年',
        },
        {
          value: '1994',
          label: '1994（平成6）年',
        },
        {
          value: '1995',
          label: '1995（平成7）年',
        },
        {
          value: '1996',
          label: '1996（平成8）年',
        },
        {
          value: '1997',
          label: '1997（平成9）年',
        },
        {
          value: '1998',
          label: '1998（平成10）年',
        },
        {
          value: '1999',
          label: '1999（平成11）年',
        },
        {
          value: '2000',
          label: '2000（平成12）年',
        },
        {
          value: '2001',
          label: '2001（平成13）年',
        },
        {
          value: '2002',
          label: '2002（平成14）年',
        },
        {
          value: '2003',
          label: '2003（平成15）年',
        },
        {
          value: '2004',
          label: '2004（平成16）年',
        },
        {
          value: '2005',
          label: '2005（平成17）年',
        },
        {
          value: '2006',
          label: '2006（平成18）年',
        },
        {
          value: '2007',
          label: '2007（平成19）年',
        },
        {
          value: '2008',
          label: '2008（平成20）年',
        },
        {
          value: '2009',
          label: '2009（平成21）年',
        },
        {
          value: '2010',
          label: '2010（平成22）年',
        },
        {
          value: '2011',
          label: '2011（平成23）年',
        },
        {
          value: '2012',
          label: '2012（平成24）年',
        },
        {
          value: '2013',
          label: '2013（平成25）年',
        },
        {
          value: '2014',
          label: '2014（平成26）年',
        },
        {
          value: '2015',
          label: '2015（平成27）年',
        },
        {
          value: '2016',
          label: '2016（平成28）年',
        },
        {
          value: '2017',
          label: '2017（平成29）年',
        },
      ],
      province: '',
      provinceEmergency: '',
    };
  },
  watch: {
    dataForm: {
      handler: function (value) {
        this.isConnectSb = value.user.is_sb;
        this.employeeId = value.user.id;
        this.dataRequest.employee_number = value.user.employee_number;
        this.dataRequest.name = value.user.name;
        if (!this.checkEmpty(value.user.position_id)) {
          this.dataRequest.department = value.user.position_id;
        }
        this.dataRequest.email = value.user.email;
        this.dataRequest.emails_string = value.user.emails_string.replaceAll(',', '、  ');
        if (!this.checkEmpty(value.user.organization_id)) {
          this.dataRequest.organization_id = value.user.organization_id;
        }
        this.dataRequest.authority = value.user.role_id;
        if (!this.checkEmpty(value.season_ticket)) {
          this.listRailroadCompany = value.season_ticket;
        }
        if (!this.checkEmpty(value.files)) {
          this.listFileUpdate = value.files;
        }
        if (!this.checkEmpty(value.passport)) {
          this.passport.id = value.passport[0].id;
          this.passport.passport_number = value.passport[0].passport_number;
          this.passport.first_name = value.passport[0].first_name;
          this.passport.middle_name = value.passport[0].middle_name;
          this.passport.last_name = value.passport[0].last_name;
          this.passport.sex = value.passport[0].sex;
          this.passport.nationality = value.passport[0].country_id;
          this.passport.year_of_issue = moment(value.passport[0].issue_date, 'YYYY-MM-DD').format('YYYY');
          this.passport.month_of_issue = moment(value.passport[0].issue_date, 'YYYY-MM-DD').format('MM');
          this.passport.date_of_issue = moment(value.passport[0].issue_date, 'YYYY-MM-DD').format('DD');
          this.passport.year_of_validity = moment(value.passport[0].expired_date, 'YYYY-MM-DD').format('YYYY');
          this.passport.month_of_validity = moment(value.passport[0].expired_date, 'YYYY-MM-DD').format('MM');
          this.passport.date_of_validity = moment(value.passport[0].expired_date, 'YYYY-MM-DD').format('DD');
          this.dataBirth.year = moment(value.passport[0].bird_date, 'YYYY-MM-DD').format('YYYY');
          this.dataBirth.month = moment(value.passport[0].bird_date, 'YYYY-MM-DD').format('MM');
          this.dataBirth.date = moment(value.passport[0].bird_date, 'YYYY-MM-DD').format('DD');
        }
        if (!this.checkEmpty(value.contact_addresses)) {
          value.contact_addresses.forEach(item => {
            if (item.is_home === 1) {
              this.dataContact.id = item.id;
              this.dataContact.zip_code = item.zip_code;
              this.dataContact.city_id = item.city_id;
              this.dataContact.street = item.street;
              this.dataContact.district = item.district;
              this.dataContact.phone = item.phone;
              this.dataContact.country_type = item.country_type;
              this.dataContact.country_name = item.country_name;
            }
            if (item.is_home === 0) {
              let data = {
                id: item.id,
                zip_code: item.zip_code,
                full_name: item.full_name,
                city_id: item.city_id,
                street: item.street,
                district: item.district,
                phone: item.phone,
                relationship_id: item.relationship_id,
                country_type: item.country_type,
                country_name: item.country_name,
              };
              this.listContact.push(data);
            }
          });
        }
        if (!this.checkEmpty(value.visas)) {
          value.visas.forEach(item => {
            let dataVisa = {
              id: item.id,
              type_id: item.type_id,
              country_id: item.country_id,
              visa_number: item.visa_number,
              purpose: item.purpose,
              place_of_use: item.place_of_use,
              year_issue: moment(item.issue_date, 'YYYY-MM-DD').format('YYYY'),
              month_issue: moment(item.issue_date, 'YYYY-MM-DD').format('MM'),
              date_issue: moment(item.issue_date, 'YYYY-MM-DD').format('DD'),
              year_expiry: moment(item.expired_date, 'YYYY-MM-DD').format('YYYY'),
              month_expiry: moment(item.expired_date, 'YYYY-MM-DD').format('MM'),
              date_expiry: moment(item.expired_date, 'YYYY-MM-DD').format('DD'),
            };
            this.listVisas.push(dataVisa);
          });
        }
        if (!this.checkEmpty(value.bank_account)) {
          this.bank.id = value.bank_account ? value.bank_account[0].id : '';
          this.bank.delivery_place = value.bank_account ? value.bank_account[0].bank_id : '';
          this.bank.branch = value.bank_account ? value.bank_account[0].branch_id : '';
          this.bank.type = value.bank_account ? value.bank_account[0].type : '';
          this.bank.account_number = value.bank_account ? value.bank_account[0].account_number : '';
        }
        let valueOrigin = this.cloneObject(value);
        this.dataRequestOrigin.employee_number = valueOrigin.user.employee_number;
        this.dataRequestOrigin.name = valueOrigin.user.name;
        if (!this.checkEmpty(valueOrigin.user.position_id)) {
          this.dataRequestOrigin.department = valueOrigin.user.position_id;
        }
        this.dataRequestOrigin.email = valueOrigin.user.email;
        if (!this.checkEmpty(valueOrigin.user.organization_id)) {
          this.dataRequestOrigin.organization_id = valueOrigin.user.organization_id;
        }
        this.dataRequestOrigin.authority = valueOrigin.user.role_id;
        if (!this.checkEmpty(valueOrigin.passport)) {
          this.passportOrigin.passport_number = valueOrigin.passport[0].passport_number;
          this.passportOrigin.first_name = valueOrigin.passport[0].first_name;
          this.passportOrigin.middle_name = valueOrigin.passport[0].middle_name;
          this.passportOrigin.last_name = valueOrigin.passport[0].last_name;
          this.passportOrigin.sex = valueOrigin.passport[0].sex;
          this.passportOrigin.nationality = valueOrigin.passport[0].country_id;
          this.passportOrigin.year_of_issue = moment(valueOrigin.passport[0].issue_date, 'YYYY-MM-DD').format('YYYY');
          this.passportOrigin.month_of_issue = moment(valueOrigin.passport[0].issue_date, 'YYYY-MM-DD').format('MM');
          this.passportOrigin.date_of_issue = moment(valueOrigin.passport[0].issue_date, 'YYYY-MM-DD').format('DD');
          this.passportOrigin.year_of_validity = moment(valueOrigin.passport[0].expired_date, 'YYYY-MM-DD').format(
            'YYYY',
          );
          this.passportOrigin.month_of_validity = moment(valueOrigin.passport[0].expired_date, 'YYYY-MM-DD').format(
            'MM',
          );
          this.passportOrigin.date_of_validity = moment(valueOrigin.passport[0].expired_date, 'YYYY-MM-DD').format(
            'DD',
          );
          this.dataBirthOrigin.year = moment(valueOrigin.passport[0].bird_date, 'YYYY-MM-DD').format('YYYY');
          this.dataBirthOrigin.month = moment(valueOrigin.passport[0].bird_date, 'YYYY-MM-DD').format('MM');
          this.dataBirthOrigin.date = moment(valueOrigin.passport[0].bird_date, 'YYYY-MM-DD').format('DD');
        }
        if (!this.checkEmpty(valueOrigin.contact_addresses)) {
          valueOrigin.contact_addresses.forEach(item => {
            if (item.is_home === 1) {
              this.dataContactOrigin.id = item.id;
              this.dataContactOrigin.zip_code = item.zip_code;
              this.dataContactOrigin.city_id = item.city_id;
              this.dataContactOrigin.street = item.street;
              this.dataContactOrigin.district = item.district;
              this.dataContactOrigin.phone = item.phone;
              this.dataContactOrigin.country_type = item.country_type;
              this.dataContactOrigin.country_name = item.country_name;
            }
            if (item.is_home === 0) {
              let data = {
                id: item.id,
                zip_code: item.zip_code,
                full_name: item.full_name,
                city_id: item.city_id,
                street: item.street,
                district: item.district,
                phone: item.phone,
                relationship_id: item.relationship_id,
                country_type: item.country_type,
                country_name: item.country_name,
              };
              this.listContactOrigin.push(data);
            }
          });
        }
        if (!this.checkEmpty(valueOrigin.visas)) {
          valueOrigin.visas.forEach(item => {
            let dataVisa = {
              id: item.id,
              type_id: item.type_id,
              country_id: item.country_id,
              visa_number: item.visa_number,
              purpose: item.purpose,
              place_of_use: item.place_of_use,
              year_issue: moment(item.issue_date, 'YYYY-MM-DD').format('YYYY'),
              month_issue: moment(item.issue_date, 'YYYY-MM-DD').format('MM'),
              date_issue: moment(item.issue_date, 'YYYY-MM-DD').format('DD'),
              year_expiry: moment(item.expired_date, 'YYYY-MM-DD').format('YYYY'),
              month_expiry: moment(item.expired_date, 'YYYY-MM-DD').format('MM'),
              date_expiry: moment(item.expired_date, 'YYYY-MM-DD').format('DD'),
            };
            this.listVisasOrigin.push(dataVisa);
          });
        }
        if (!this.checkEmpty(valueOrigin.bank_account)) {
          this.bankOrigin.delivery_place = value.bank_account ? value.bank_account[0].bank_id : '';
          this.bankOrigin.branch = value.bank_account ? value.bank_account[0].branch_id : '';
          this.bankOrigin.type = value.bank_account ? value.bank_account[0].type : '';
          this.bankOrigin.account_number = value.bank_account ? value.bank_account[0].account_number : '';
        }
      },
      deep: true,
    },
    'bank.delivery_place': function (val) {
      if (val) {
        this.getListBranch(val);
      }
    },
    // 'dataContact.zip_code': function (val) {
    //   if (val && !this.isValidZipCode(val)) {
    //     this.errors.zip_code = this.$t('common.valid_zip_code');
    //   } else {
    //     delete this.errors.zip_code;
    //   }
    // },
    dataBirth: {
      handler: function (newValue) {
        if (newValue) {
          let currentDate = moment(new Date()).format('YYYY-MM-DD');
          let newDate = moment();
          newDate.set('year', newValue.year);
          if (!newValue.month) {
            newDate.set('month', 1);
          } else {
            newDate.set('month', newValue.month - 1);
          }
          newDate.set('date', newValue.date);

          let birth = newDate.format('YYYY-MM-DD');
          this.age_now = this.diffDate(currentDate, birth, 'years');
        }
      },
      deep: true,
    },
    dataRequest: {
      handler: function (newValue) {
        if (newValue) {
          delete this.errors.employee_number;
          delete this.errors.name;
          delete this.errors.email;
          delete this.errors.dataContact;
          delete this.errors.bank;
        }
        if (this.strLen(newValue.name) > 100) {
          this.errors.name = this.$t('E-CM-111', { length: 100 });
        } else {
          delete this.errors.name;
        }
        if (this.strLen(newValue.email) > 100) {
          this.errors.email = this.$t('E-CM-111', { length: 100 });
        } else {
          delete this.errors.email;
        }
      },
      deep: true,
    },
    bank: {
      handler: function (newValue) {
        if (newValue) {
          delete this.errors.bank;
        }
      },
      deep: true,
    },
    dataContact: {
      handler: function (newValue) {
        if (newValue) {
          delete this.errors.dataContact;
          delete this.errors.zip_code;
          delete this.errors.err_zip_code;
        }
      },
      deep: true,
    },
    passport: {
      handler: function (newValue) {
        if (newValue) {
          delete this.errors.passport;
        }
      },
      deep: true,
    },
  },
  computed: {
    positionName() {
      const position = this.listPosition.find(role => role.id === this.dataRequest.department);

      return !this.checkEmpty(position) ? position.management_title : '';
    },
    roleLabel() {
      const role = this.listRole.find(role => role.id === this.dataRequest.authority);

      return role ? this.labelLocale(role) : '';
    },
    organizationLabel() {
      const organization = this.listOrganizations.find(
        organization => organization.id === this.dataRequest.organization_id,
      );

      return organization?.name || '';
    },
    listYear() {
      let listYear = [];
      for (let i = moment().year(); i <= 2099; i++) {
        listYear.push({
          value: '' + i,
          label: i + '年',
        });
      }
      return listYear;
    },
    listYearStartPassport() {
      let listYear = [];
      for (let i = moment().year() - 10; i <= 2099; i++) {
        listYear.push({
          value: '' + i,
          label: i + '年',
        });
      }
      return listYear;
    },
    listMonth() {
      let listMonth = [];
      for (let i = 1; i <= 9; i++) {
        listMonth.push({
          value: '0' + i,
          label: i + '月',
        });
      }
      for (let i = 10; i <= 12; i++) {
        listMonth.push({
          value: '' + i,
          label: i + '月',
        });
      }
      return listMonth;
    },
    listDay() {
      let listDay = [];
      for (let i = 1; i <= 9; i++) {
        listDay.push({
          value: '0' + i,
          label: i + '日',
        });
      }
      for (let i = 10; i <= 31; i++) {
        listDay.push({
          value: '' + i,
          label: i + '日',
        });
      }
      return listDay;
    },
  },
  created() {
    this.startLoading();
    Promise.all([
      this.getListPosition(),
      this.getListRole(),
      this.getListOrganizations(),
      this.getListBank(),
      this.getListCity(),
      this.getListCountry(),
      this.getListVisa(),
      this.getListRelationship(),
    ]).then(() => {
      this.endLoading();
    });
  },
  methods: {
    isValidZipCode(string) {
      let res = string.match(/^[0-9]{3}-?[0-9]{4}$/);
      if (res == null) {
        return false;
      } else {
        return true;
      }
    },
    submitData() {
      if (!this.dataRequest) return;
      let flgValidate = true;

      if (this.checkEmpty(this.dataRequest.employee_number)) {
        flgValidate = false;
        this.errors.employee_number = this.$t('E-CM-100', { item: this.$t('employee.employee_number') });
      } else {
        this.errors.employee_number = '';
      }
      if (this.checkEmpty(this.dataRequest.name)) {
        flgValidate = false;
        this.errors.name = this.$t('E-CM-100', { item: this.$t('employee.name') });
      } else {
        this.errors.name = '';
      }
      if (this.checkEmpty(this.dataRequest.email)) {
        flgValidate = false;
        this.errors.email = this.$t('E-CM-100', { item: this.$t('employee.email') });
      } else {
        this.errors.email = '';
      }

      this.listVisas.forEach((visa, index) => {
        this.errors.listVisas[index] = '';
        if (this.listVisas[index].type_id) {
          if (
            this.checkEmpty(this.listVisas[index].country_id) ||
            this.checkEmpty(this.listVisas[index].year_issue) ||
            this.checkEmpty(this.listVisas[index].month_issue) ||
            this.checkEmpty(this.listVisas[index].date_issue) ||
            this.checkEmpty(this.listVisas[index].year_expiry) ||
            this.checkEmpty(this.listVisas[index].month_expiry) ||
            this.checkEmpty(this.listVisas[index].date_expiry) ||
            this.checkEmpty(this.listVisas[index].visa_number) ||
            this.checkEmpty(this.listVisas[index].place_of_use) ||
            this.checkEmpty(this.listVisas[index].purpose)
          ) {
            flgValidate = false;

            this.errors.listVisas[index] = this.$t('employee.err_visa');
          } else {
            this.errors.listVisas[index] = '';
          }
        }
      });

      this.listContact.forEach((contact, index) => {
        this.errors.listContact[index] = '';
        if (
          this.checkEmpty(contact.zip_code) ||
          this.checkEmpty(contact.full_name) ||
          (this.checkEmpty(contact.country_name) && contact.country_type == 2) ||
          this.checkEmpty(contact.relationship_id) ||
          this.checkEmpty(contact.district) ||
          this.checkEmpty(contact.phone)
        ) {
          flgValidate = false;

          this.errors.listContact[index] = this.$t('employee.err_contact_emergency');
        } else {
          this.errors.listContact[index] = '';
        }
        if (!this.checkEmpty(contact.zip_code) && !this.isValidZipCode(contact.zip_code) && contact.country_type === 1) {
          flgValidate = false;
          this.errors.zip_code2[index] = this.$t('common.valid_zip_code');
        } else {
          this.errors.zip_code2[index] = '';
        }
      });
      if (!this.checkEmpty(this.dataContact.zip_code) && !this.isValidZipCode(this.dataContact.zip_code) && this.dataContact.country_type === 1) {
        flgValidate = false;
        this.errors.zip_code = this.$t('common.valid_zip_code');
      } else {
        this.errors.zip_code = '';
      }
      if (!this.checkEmpty(this.dataContact.zip_code) || !this.checkEmpty(this.dataContact.district) || !this.checkEmpty(this.dataContact.phone)
        || (!this.checkEmpty(this.dataContact.country_name) && this.dataContact.country_type == 2)) {
          if (this.checkEmpty(this.dataContact.zip_code) || this.checkEmpty(this.dataContact.district) || this.checkEmpty(this.dataContact.phone)
          || (this.checkEmpty(this.dataContact.country_name) && this.dataContact.country_type == 2)) {
          flgValidate = false;
          this.errors.dataContact = this.$t('employee.err_contact');
        } else {
          this.errors.dataContact = '';
        }
      }
      if (this.bank.delivery_place) {
        if (this.checkEmpty(this.bank.type) || this.checkEmpty(this.bank.account_number)) {
          flgValidate = false;
          this.errors.bank = this.$t('employee.err_bank');
        } else {
          this.errors.bank = '';
        }
      }
      if (this.passport.passport_number) {
        if (
          this.checkEmpty(this.passport.sex) ||
          this.checkEmpty(this.passport.year_of_issue) ||
          this.checkEmpty(this.passport.month_of_issue) ||
          this.checkEmpty(this.passport.date_of_issue) ||
          this.checkEmpty(this.passport.year_of_validity) ||
          this.checkEmpty(this.passport.month_of_validity) ||
          this.checkEmpty(this.passport.date_of_validity) ||
          this.checkEmpty(this.dataBirth.year) ||
          this.checkEmpty(this.dataBirth.month) ||
          this.checkEmpty(this.dataBirth.date) ||
          this.checkEmpty(this.passport.first_name) ||
          this.checkEmpty(this.passport.last_name) ||
          this.checkEmpty(this.passport.nationality)
        ) {
          flgValidate = false;
          this.errors.passport = this.$t('employee.err_passport');
        } else {
          this.errors.passport = '';
        }
      }

      if (!flgValidate) return;

      this.updateData();
    },
    uploadPassport(id) {
      let formData = new FormData();
      formData.append('passport_id', id);
      if (this.listFileUpdate.length > 0) {
        for (let index = 0; index < this.listFileUpdate.length; index++) {
          formData.append('files[]', this.listFileUpdate[index]);
        }
      }

      this.$services.employee.uploadPassport(
        formData,
        () => {
          this.endLoading();
          this.notifySuccess(this.$t('I-CM-103'));
        },
        err => {
          this.endLoading();
          this.notifyError(this.$t(err.error));
        },
      );
    },
    updateData() {
      let birdDate = '';
      let passportIssueDate = '';
      let passportExpiredDate = '';
      if (this.passport.year_of_issue && this.passport.month_of_issue && this.passport.date_of_issue) {
        passportIssueDate = moment(
          this.passport.year_of_issue + this.passport.month_of_issue + this.passport.date_of_issue,
        ).format('YYYY-MM-DD');
      }
      if (this.passport.year_of_validity && this.passport.month_of_validity && this.passport.date_of_validity) {
        passportExpiredDate = moment(
          this.passport.year_of_validity + this.passport.month_of_validity + this.passport.date_of_validity,
        ).format('YYYY-MM-DD');
      }
      if (this.dataBirth.year && this.dataBirth.month && this.dataBirth.date) {
        birdDate = moment(this.dataBirth.year + this.dataBirth.month + this.dataBirth.date).format('YYYY-MM-DD');
      }
      let emails = [];
      emails = this.dataForm.user.emails_string.split(',');
      let formDataUpdate = {
        id: this.employeeId,
        employee_number: this.dataRequest.employee_number,
        name: this.dataRequest.name,
        email: this.dataRequest.email,
        emails: emails,
        position_id: this.dataRequest.department,
        role_id: this.dataRequest.authority,
        organization_id: this.dataRequest.organization_id,
        passport: {
          id: this.passport.id ? this.passport.id : '',
          passport_number: this.passport.passport_number,
          first_name: this.passport.first_name,
          last_name: this.passport.last_name,
          middle_name: this.passport.middle_name,
          country_id: this.passport.nationality,
          sex: this.passport.sex,
          bird_date: birdDate,
          issue_date: passportIssueDate,
          expired_date: passportExpiredDate,
        },
      };

      if (this.checkEmpty(this.dataRequest.department)) {
        delete formDataUpdate.position_id;
      }

      if (this.checkEmpty(this.dataRequest.organization_id)) {
        delete formDataUpdate.organization_id;
      }

      let bankData = {
        id: this.bank.id ? this.bank.id : '',
        bank_id: this.bank.delivery_place,
        branch_id: this.bank.branch,
        bank_type: this.bank.type,
        account_number: this.bank.account_number,
      };
      formDataUpdate.bank_account = bankData;

      let visaData = [];
      visaData = this.listVisas.map(item => {
        return {
          id: item.id ? item.id : '',
          type_id: item.type_id,
          country_id: item.country_id,
          visa_number: item.visa_number,
          purpose: item.purpose,
          place_of_use: item.place_of_use,
          visa_issue_date: moment(item.year_issue + item.month_issue + item.date_issue).format('YYYY-MM-DD'),
          visa_expired_date: moment(item.year_expiry + item.month_expiry + item.date_expiry).format('YYYY-MM-DD'),
        };
      });
      formDataUpdate.visa = visaData;

      let ticketData = [];
      ticketData = this.listRailroadCompany.map(item => {
        return {
          id: item.id ? item.id : '',
          railway_company_code: item.railway_company_name,
          railway_company_name: item.railway_company_name,
          departure_station: item.departure_station,
          arrival_station: item.arrival_station,
        };
      });
      formDataUpdate.season_tickets = ticketData;

      let contactData = [];
      contactData = this.listContact.map(item => {
        return {
          id: item.id ? item.id : '',
          full_name: item.full_name,
          relationship_id: item.relationship_id,
          city_id: item.city_id,
          zip_code: item.zip_code,
          district: item.district,
          street: item.street,
          phone: item.phone,
          is_emergency: 1,
          is_home: 0,
          country_type: item.country_type,
          country_name: item.country_name,
        };
      });
      formDataUpdate.contact = contactData;
      if (!this.checkEmpty(this.dataContact.zip_code)) {
        contactData.push({
          id: this.dataContact.id ? this.dataContact.id : '',
          full_name: this.dataRequest.name,
          zip_code: this.dataContact.zip_code,
          city_id: this.dataContact.city_id,
          street: this.dataContact.street,
          district: this.dataContact.district,
          phone: this.dataContact.phone,
          country_type: this.dataContact.country_type,
          country_name: this.dataContact.country_name,
          is_emergency: 0,
          is_home: 1,
          province: this.province,
        });
        formDataUpdate.contact = contactData;
      }
      if (this.checkEmpty(this.passport.passport_number)) {
        formDataUpdate.passport = {};
      }
      if (this.checkEmpty(this.bank.delivery_place)) {
        formDataUpdate.bank_account = [];
      }
      this.startLoading();

      this.$services.employee.updateEmployee(
        formDataUpdate,
        () => {
          if (!this.uploadPassportSuccess) {
            this.notifySuccess(this.$t('I-CM-103'));
            this.endLoading();
          } else {
            this.uploadPassport(this.passport.id);
          }
        },
        err => {
          this.endLoading();
          // if (!this.checkEmpty(err.error) && err.error === 'E-CU-345') {
          //   this.errors.email = this.$t('E-CU-345');
          // }
          if (!this.checkEmpty(err.error.email) && err.error.email[0] === 'E-CU-324') {
            this.errors.email = this.$t('E-CM-101', { name: this.$t('employee.email') });
          }
          // if (!this.checkEmpty(err.error.email) && err.error.email[0] === 'E-CU-345') {
          //   this.errors.email = this.$t('E-CU-345');
          // }
          if (!this.checkEmpty(err.error.employee_number) && err.error.employee_number[0] == 'E-CU-344') {
            this.errors.employee_number = this.$t('E-CU-344');
          }
        },
      );
    },
    showVisaDetail(index) {
      return this.listVisas[index].type_id !== 0;
    },
    addNewContact() {
      const newItem = {
        country_type: 1,
      };
      this.listContact.push(newItem);
    },
    addNewVisa() {
      const newItem = {
        type_id: 1,
      };
      this.listVisas.push(newItem);
    },
    cancelConfirm() {
      this.showBoxConfirm = false;
      this.isDeleteVisa = false;
      this.isDeleteContact = false;
      this.isDeleteCommuter = false;
      this.isBack = false;
    },
    confirmSuccess() {
      this.showBoxConfirm = false;
      if (this.isDeleteVisa) {
        this.deleteVisa(this.itemVisaDelete);
      }
      if (this.isDeleteContact) {
        this.deleteContact(this.itemContactDelete);
      }
      if (this.isDeleteCommuter) {
        this.deleteCommuter(this.itemCommuterDelete);
      }
    },
    confirmContact(item) {
      this.titleBoxConfirm = this.$t('Q-CM-104');
      this.showBoxConfirm = true;
      this.itemContactDelete = item;
      this.isDeleteContact = true;
    },
    confirmVisa(item) {
      this.titleBoxConfirm = this.$t('Q-CM-104');
      this.showBoxConfirm = true;
      this.itemVisaDelete = item;
      this.isDeleteVisa = true;
    },
    confirmCommuter(item) {
      this.titleBoxConfirm = this.$t('Q-CM-104');
      this.showBoxConfirm = true;
      this.itemCommuterDelete = item;
      this.isDeleteCommuter = true;
    },
    deleteContact(item) {
      this.startLoading();
      if (item.id) {
        this.$services.employee.deleteContact(
          item.id,
          () => {
            this.endLoading();
            this.listContact.splice(this.listContact.indexOf(item), 1);
            this.isDeleteContact = false;
            this.notifySuccess(this.$t('I-OP-053'));
          },
          err => {
            this.notifyError(this.$t(err.error));
            this.endLoading();
          },
        );
      } else {
        this.endLoading();
        this.listContact.splice(this.listContact.indexOf(item), 1);
        this.isDeleteContact = false;
        this.notifySuccess(this.$t('I-OP-053'));
      }
    },
    deleteCommuter(item) {
      this.startLoading();
      if (item.id) {
        this.$services.employee.deleteSeasonTicket(
          item.id,
          () => {
            this.endLoading();
            this.listRailroadCompany.splice(this.listRailroadCompany.indexOf(item), 1);
            this.isDeleteCommuter = false;
            this.notifySuccess(this.$t('I-OP-053'));
          },
          err => {
            this.notifyError(this.$t(err.error));
            this.endLoading();
          },
        );
      } else {
        this.endLoading();
        this.listRailroadCompany.splice(this.listRailroadCompany.indexOf(item), 1);
        this.isDeleteCommuter = false;
        this.notifySuccess(this.$t('I-OP-053'));
      }
    },
    deleteVisa(item) {
      this.startLoading();
      if (item.id) {
        this.$services.employee.deleteVisa(
          item.id,
          () => {
            this.endLoading();
            this.listVisas.splice(this.listVisas.indexOf(item), 1);
            this.isDeleteVisa = false;
            this.notifySuccess(this.$t('I-OP-053'));
          },
          err => {
            this.notifyError(this.$t(err.error));
            this.endLoading();
          },
        );
      } else {
        this.endLoading();
        this.listVisas.splice(this.listVisas.indexOf(item), 1);
        this.isDeleteVisa = false;
        this.notifySuccess(this.$t('I-OP-053'));
      }
    },
    async getListOrganizations() {
      const param = {};
      if (this.isOperatorOrAdmin) {
        param.company_id = this.$route.params.id;
      }
      await this.$services.application.getListParentOrganizations(
        param,
        res => {
          this.listOrganizations = res.list;
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    async getListPosition() {
      const param = {};
      if (this.isOperatorOrAdmin) {
        param.company_id = this.$route.params.id;
      }
      await this.$services.common.getListPosition(
        param,
        res => {
          this.listPosition = res.list;
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    async getListRelationship() {
      await this.$services.common.getListRelationship(
        res => {
          this.listRelationship = res.list;
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    async getListRole() {
      await this.$services.common.getListRole(
        res => {
          let listRoleRes = res.list.filter(item => item.id != 2 && item.id != 6 && item.id != 7); // Only company role and web role
          this.listRole = listRoleRes.sort(function(a, b) {
            return a.sort_order - b.sort_order;
          });
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    async getListCity() {
      await this.$services.common.getListProvinces(
        res => {
          this.listCity = res.list;
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    getAddressFromZipCode(zip_code) {
      if (zip_code && !this.isValidZipCode(zip_code)) {
        this.errors.zip_code = this.$t('common.valid_zip_code');
        return;
      } else {
        this.errors.zip_code = '';
      }
      this.$services.common.getAddressByZipCode(
        zip_code,
        res => {
          if (
            this.checkEmpty(res.city_id) &&
            this.checkEmpty(res.city_name) &&
            this.checkEmpty(res.district) &&
            this.checkEmpty(res.province) &&
            this.checkEmpty(res.street)
          ) {
            this.errors.err_zip_code = this.$t('employee.err_zip_code');
            return;
          } else {
            this.errors.err_zip_code = '';
          }
          this.listCity.every(city => {
            if (!this.checkEmpty(res.province) && this.labelLocale(city) == res.province) {
              this.dataContact.city_id = city.id;
              this.province = res.province != '日本' ? res.province : res.city_name;
              this.dataContact.street = res.city_name + res.district;
              this.dataContact.district = res.street;
              return false;
            } else if (!this.checkEmpty(res.city_name) && this.labelLocale(city) == res.city_name) {
              this.dataContact.city_id = city.id;
              this.province = res.province != '日本' ? res.province : res.city_name;
              this.dataContact.street = res.district;
              this.dataContact.district = res.street;
              return false;
            } else {
              this.dataContact.city_id = '';
              this.dataContact.street = res.city_name;
              this.dataContact.district = '';
              return true;
            }
          });
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    getAddressFromZipCodeEmergency(index) {
      if (this.listContact[index].zip_code && !this.isValidZipCode(this.listContact[index].zip_code)) {
        this.errors.zip_code2[index] = this.$t('common.valid_zip_code');
        return;
      } else {
        this.errors.zip_code2[index] = '';
      }
      this.$services.common.getAddressByZipCode(
        this.listContact[index].zip_code,
        res => {
          if (
            this.checkEmpty(res.city_id) &&
            this.checkEmpty(res.city_name) &&
            this.checkEmpty(res.district) &&
            this.checkEmpty(res.province) &&
            this.checkEmpty(res.street)
          ) {
            this.errors.zip_code2[index] = this.$t('employee.err_zip_code');
            return;
          } else {
            this.errors.zip_code2[index] = '';
          }
          this.listCity.every(city => {
            if (!this.checkEmpty(res.province) && this.labelLocale(city) == res.province) {
              this.listContact[index].city_id = city.id;
              this.provinceEmergency = res.province != '日本' ? res.province : res.city_name;
              this.listContact[index].street = res.city_name + res.district;
              this.listContact[index].district = res.street;
              return false;
            } else if (!this.checkEmpty(res.city_name) && this.labelLocale(city) == res.city_name) {
              this.listContact[index].city_id = city.id;
              this.provinceEmergency = res.province != '日本' ? res.province : res.city_name;
              this.listContact[index].street = res.district;
              this.listContact[index].district = res.street;
              return false;
            } else {
              this.listContact[index].street = res.city_name;
              this.listContact[index].district = '';
              this.listContact[index].city_id = '';
              return true;
            }
          });
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    async getListCountry() {
      await this.$services.security.getListCountry(
        res => {
          this.listCountry = this.sortCountryByName(res.list);
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    async getListBank() {
      await this.$services.common.getListBank(
        res => {
          this.listBank = res.list;
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    async getListVisa() {
      await this.$services.common.getListVisa(
        res => {
          this.listVisa = res.list;
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    getListBranch(id) {
      this.$services.common.getListBranch(
        { bank_id: id },
        res => {
          if (this.checkEmpty(res.list)) {
            this.showBranch = false;
          } else {
            this.showBranch = true;
            this.listBranch = res.list;
          }
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    confirmUploadTransportation(item) {
      this.showTransportation = false;
      this.listRailroadCompany.push({
        railway_company_name: item.corporation,
        departure_station: item.route[0].point_name,
        arrival_station: item.route[item.route.length - 1].point_name,
      });
    },
    listenOrganization(item) {
      this.dataRequest.organization_id = item;
    },
    listenCountry(item) {
      this.passport.nationality = item;
    },
    listenCountryVisa(item, index) {
      this.listVisas[index].country_id = item;
    },
    listenBank(item) {
      this.bank.delivery_place = item;
    },
    listenBranch(item) {
      this.bank.branch = item;
    },
    pickerToOptions(time) {
      return moment(new Date()).format('YYYY') < moment(time).format('YYYY');
    },
    confirmUploadFile(files) {
      this.showModalUploadFile = false;
      this.listFileUpdate = files;

      this.scanPassport();
    },
    scanPassport() {
      let formData = new FormData();
      if (this.listFileUpdate.length > 0) {
        for (let index = 0; index < this.listFileUpdate.length; index++) {
          formData.append('image', this.listFileUpdate[index]);
        }
      }

      this.startLoading();
      this.$services.employee.scanPassport(
        formData,
        res => {
          this.endLoading();
          if (res.list && this.checkEmpty(res.list)) {
            this.notifyError(this.$t('employee.upload_img_err'));
          } else {
            this.passport.passport_number = res.fields.document_number;
            this.passport.sex = res.fields.sex;
            this.passport.year_of_validity = moment(res.fields.expiry_date, 'YYYY-MM-DD').format('YYYY');
            this.passport.month_of_validity = moment(res.fields.expiry_date, 'YYYY-MM-DD').format('MM');
            this.passport.date_of_validity = moment(res.fields.expiry_date, 'YYYY-MM-DD').format('DD');
            this.passport.year_of_issue = moment(res.fields.issue_date, 'YYYY-MM-DD').format('YYYY');
            this.passport.month_of_issue = moment(res.fields.issue_date, 'YYYY-MM-DD').format('MM');
            this.passport.date_of_issue = moment(res.fields.issue_date, 'YYYY-MM-DD').format('DD');
            this.dataBirth.year = moment(res.fields.birth_date, 'YYYY-MM-DD').format('YYYY');
            this.dataBirth.month = moment(res.fields.birth_date, 'YYYY-MM-DD').format('MM');
            this.dataBirth.date = moment(res.fields.birth_date, 'YYYY-MM-DD').format('DD');
            this.passport.nationality = res.fields.country_id;
            this.passport.first_name = res.fields.surname;
            this.passport.last_name = res.fields.name;
            this.uploadPassportSuccess = true;
            this.notifySuccess(this.$t('employee.upload_img_success'));
          }
        },
        err => {
          this.endLoading();
          this.notifyError(this.$t(err.error));
        },
      );
    },
    convertNumberFullToHalf(number) {
      number = number.replace(/[^0-9０-９-－]/g, '');
      number = String(number).replace(/[\uFF10-\uFF19]/g, function (m) {
        return String.fromCharCode(m.charCodeAt(0) - 0xfee0);
      });
      if (String(number).includes('－')) {
        number = number.replaceAll('－', '-');
      }

      return number;
    },
  },
};
</script>
<style lang="scss" scoped>
.header-info {
  padding: 12px 16px;
  border-top: 1px solid white;
  background-color: rgba(242, 243, 245, 0.75);
  color: #98a9b0;
  font-size: 13px;
}
</style>
