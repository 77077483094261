<template>
  <nav class="navbar navbar-default navbar-lg">
    <div class="navbar-header">
      <h2 class="navbar-title">{{ $t('user.user') }}</h2>
    </div>
  </nav>
  <UserForm :dataForm="dataUser" :isDetail="isDetail" @onChangeData="onChangeData" />
</template>
<script>
import UserForm from '@/components/user/UserForm';

export default {
  name: 'UserDetail',
  components: { UserForm },
  data() {
    return {
      dataUser: {},
      isDetail: false,
      isChangeData: false,
    };
  },
  beforeRouteLeave(to, from, next) {
    if (this.isChangeData) {
      const answer = window.confirm(this.$t('common.confirm_leave_page'));
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  created() {
    this.getDetail();
  },
  methods: {
    onChangeData(val) {
      this.isChangeData = val;
    },
    async getDetail() {
      const id = this.$route.params.id;
      this.startLoading();
      await this.$services.user.getUserDetail(
        id,
        res => {
          this.dataUser = res;
          this.isDetail = true;
          this.endLoading();
        },
        err => {
          // this.endLoading();
          this.notifyError(this.$t(err.message_code));
          this.$router.push(this.PAGES.USER);
        },
      );
    },
  },
};
</script>
<style lang="scss" scoped></style>
