import BaseService from './BaseService';
import API_CODE from '../utils/api_code.js';

export default class BudgetAPI extends BaseService {
  async getListBudgets(params, success, error) {
    await this.get(API_CODE.CU_213 + params, success, error);
  }
  async saveBudget(params, success, error) {
    await this.post(API_CODE.CU_214, params, success, error);
  }
  async downloadFileCSV(params, success, error) {
    await this.get(API_CODE.CU_226 + params, success, error);
  }
  async downloadExampleFileCSV(success, error) {
    await this.get(API_CODE.CU_227, success, error);
  }
  async uploadFileCSV(params, success, error) {
    await this.post(API_CODE.CU_228, params, success, error);
  }
}
