<template>
  <div class="viewer-item">
    <div class="wrapper with-navbar-fixed-bottom" style="padding-bottom: 100px">
      <div v-if="inputDetail.status == 1" :class="inputDetail.status == 1 && 'overlay'">
        <button class="btn btn-hg btn-primary js-btn--incharge open-alert hide--inprogress" @click="editingInput">
          <i class="icon-abui-play prx"></i>
          {{ $t('input.while_typing') }}
        </button>
        <div class="overlay--msg show--inprogress" style="display: none">{{ $t('input.another_person') }}</div>
      </div>
      <div
        v-if="inputDetail.status == 2 && inputDetail.user_action_id !== userLoginId && !isAnother"
        class="alert alert-center alert-default al--inprogress alert-dismissible show-alert"
        role="alert"
        data-status="1"
      >
        <span class="alert-message">
          {{ $t('input.another_person') }}
          <div class="mtm">
            <a href="#" @click="goToNextInput" class="alert-link" data-hide="alert">
              {{ $t('input.next_input') }}
              <i class="icon-abui-arrow-right plx"> </i>
            </a>
          </div>
        </span>
      </div>

      <div v-if="inputDetail.status == 2 && isAnother && showButtonAnother" class="overlay">
        <button class="btn btn-hg btn-primary js-btn--incharge open-alert hide--inprogress" @click="goToNextInput">
          <i class="icon-abui-play prx"></i>
          {{ $t('input.while_typing') }}
        </button>
      </div>

      <div class="with-sidebar">
        <div
          class="trip-info-col bg-gray preview"
          style="overflow: hidden; height: 889px; cursor: move"
          @mousedown="mousedown"
        >
          <div class="btn-group trip--receipt--control">
            <button type="button" class="btn btn-sm btn-inverse hide--voucher" @click="rotate = rotate - 90">
              <i class="icon-abui-rotate"></i>
            </button>
            <button type="button" class="btn btn-sm btn-inverse" @click="zoomOut()">
              <i class="icon-abui-zoomout"></i>
            </button>
            <button type="button" class="btn btn-sm btn-inverse" @click="zoom = zoom + 0.25">
              <i class="icon-abui-zoomin"></i>
            </button>
          </div>
          <div
            class="trip--receipt hide--new preview"
            style="height: 857px; top: 16px; overflow: unset"
            :style="styleRotate"
            id="componentMove"
          >
            <div
              v-if="inputDetail?.status !== '4'"
              class="ocr--receipt"
              :class="isTypePDF && 'pdf'"
              :style="styleImage()"
              @mousedown="mousedown"
            >
              <a
                v-for="(data, index) in dataContent"
                :key="index"
                class="ocr--receipt--item"
                :style="processStyle(data)"
                @click="copyValue(data.text)"
              />
            </div>
            <VuePdfEmbed
              id="elMove"
              style="
                cursor: move;
                position: absolute;
                width: auto;
                height: auto;
                left: 50%;
                transform: translateX(-50%);
                top: 270px;
              "
              ref="pdfRef"
              v-if="isTypePDF"
              :width="totalWidth"
              :height="totalHeight"
              :source="{
                cMapUrl: 'https://cdn.jsdelivr.net/npm/pdfjs-dist@2.16.105/cmaps/',
                cMapPacked: true,
                url: inputDetail.file_path,
              }"
              @rendered="listLoadedDataPDF"
              @mousedown="mousedown"
            />
            <img id="elMove" v-else :src="inputDetail.file_path" :style="styleImage()" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <el-dialog v-model="outerVisible" :show-close="true" :close-on-press-escape="false" :close-on-click-modal="false">
    <div class="">
      <button type="button" class="close" @click="outerVisible = false">
        <span class="icon-abui-cross" aria-hidden="true"></span>
      </button>
      <div class="viewer-item">
        <div class="trip--receipt hide--new" style="max-width: 100%; height: 90vh">
          <iframe
            v-if="isTypePDF"
            :src="inputDetail.file_path"
            style="width: 100%; height: 100%; border: none; margin-top: 35px"
          />
          <img v-else :src="inputDetail.file_path" width="100%" />
        </div>
      </div>
    </div>
  </el-dialog>

  <ModalConfirm
    :dialogVisible="showBoxPublic"
    @cancel="showBoxPublic = false"
    @yes="publicCost"
    classCustom="btn-primary"
    :labelButtonConfirm="$t('common.execution')"
    :title="$t('input.text_confirm_public')"
  />
</template>
<script>
import ModalConfirm from '@/components/common/ModalConfirm';
import * as yup from 'yup';
import VuePdfEmbed from 'vue-pdf-embed';

export default {
  name: 'InputPreview',
  components: {
    ModalConfirm,
    VuePdfEmbed,
  },
  beforeRouteLeave(to, from, next) {
    if (
      (!this.compareObject(this.inputDetail, this.inputDetailOfficial) ||
        !this.compareArray(this.inputDetailItems, this.inputDetailOfficial.items)) &&
      !this.isCommit
    ) {
      const answer = window.confirm(this.$t('common.confirm_leave_page'));
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  data() {
    return {
      rotate: 0,
      zoom: 1,
      firstLoadingPDF: true,
      styleRotate: `transform: rotate(0deg) scale(1);`,
      isAnother: false,
      isDisabled: false,
      outerVisible: false,
      showButtonAnother: true,
      listPaymentMethod: [],
      listAmount: [],
      currencyList: [],
      listTicketType: [],
      listAirport: [],
      listStation: [],
      optionsUTC: [],
      listTaxClassification: [],
      listCostItem: [],
      listItemSubmit: [],
      submitFromParent: false,
      showBoxPublic: false,
      inputDetail: {},
      inputDetailOfficial: {
        currency_id: 1, // JPY
        tax_type: '', // Default 8%
        payment_method: 1,
        payment_date: '',
        cost_item: '',
        trip_id: '',
        company_id: '',
        items: [],
      },
      inValid: true,
      dataEmit: {},
      dataAmount: [],
      isCommit: false,
      flgPublic: false,
      flgSubmit: false,
      totalHeight: 0,
      totalWidth: 0,
      canvasHeight: 0,
      canvasWidth: 0,
      dataContent: [],
      countStyleImage: 0,
    };
  },
  computed: {
    countWidth() {
      if (this.isTypePDF) {
        return 300;
      }

      return 0;
    },
    countHeight() {
      if (this.isTypePDF) {
        return this.pageCount * 424;
      }

      return 0;
    },
    pageCount() {
      return !this.checkEmpty(this.inputDetail.content) ? this.inputDetail.content.length : 1;
    },
    isTypePDF() {
      if (this.checkEmpty(this.inputDetail?.file_name)) return false;
      return this.inputDetail.file_name.split('.').pop() == 'pdf';
    },
    userLoginId() {
      return this.$store.state.auth?.user?.id || '';
    },
    inputDetailItems() {
      return this.inputDetail.items || [];
    },
    currencySelect() {
      const currency = this.currencyList.find(item => item.id == this.inputDetail.currency_id);
      return currency || {};
    },
    signalCurrency() {
      const currency = this.currencyList.find(item => item.id == this.inputDetail.currency_id);
      return currency?.signal || '¥';
    },
    rateCurrency() {
      const currency = this.currencyList.find(item => item.id == this.inputDetail.currency_id);
      return currency?.rate || '';
    },
    totalAmount() {
      if (!this.inputDetail?.currency_amount) return 0;
      return this.splitThousandNumber(
        (this.rateCurrency * String(this.inputDetail.currency_amount).replace(/[^0-9]/g, '')).toFixed(0),
      );
    },
    inputId() {
      return this.$route.params.id;
    },
    validate() {
      return yup.object().shape({
        payable_to: yup
          .string()
          .trim()
          // .required(this.$t('E-CM-100', { item: this.$t('expense_cost.payment_to') }))
          .max(100, this.$t('E-CM-111', { size: 100 }))
          .nullable(),
        currency_id: yup.number(),
        payment_method: yup
          .string()
          .trim()
          // .required(this.$t('E-CM-100', { item: this.$t('expense_cost.payment_method') }))
          .nullable(),
        payment_date: yup
          .string()
          .trim()
          // .required(this.$t('E-CM-100', { item: this.$t('expense_cost.payment_date') }))
          .nullable(),
      });
    },
  },
  watch: {
    rotate(val) {
      this.styleRotate = `transform: rotate(${val}deg) scale(${this.zoom});`;
    },
    zoom(val) {
      this.styleRotate = `transform: rotate(${this.rotate}deg) scale(${val});`;
    },
    'inputDetail.company_id'(val) {
      this.getCostItemList(val);
    },
    inputDetail: function (val) {
      if (val.type === '2' && val.items.length === 0) {
        this.inputDetail.items.push({
          airplane_type: 1,
          arrival_date: null,
          arrival_location_id: null,
          arrival_time: null,
          seat_class_type: 1,
          departure_date: null,
          start_time: '00:00',
          end_time: '00:00',
          departure_location_id: null,
          departure_time: null,
          time_zone_id: null,
          location_string: null,
          flight_number: null,
          id: null,
          seat_number: null,
          title: null,
          type: 1,
        });
      }
      if (val.type === '1' && val.items.length === 0) {
        this.inputDetail.items.push({
          amount: null,
          cost_id: null,
          id: null,
          tax_amount: null,
          tax_classification: 1,
          title: null,
        });
      }
    },
  },
  created() {
    this.startLoading();
    Promise.all([
      this.getInputDetail(),
      this.getListTicketType(),
      this.getListAirport(),
      this.getListStation(),
      this.getCurrencyList(),
      this.getPaymentMethodList(),
      this.getTaxClassificationList(),
      this.getTimeZone(),
    ]).then(() => {
      this.endLoading();
    });
  },
  methods: {
    goToUserDetail(id) {
      let routeData = this.$router.resolve({
        name: this.PAGES.USER_DETAIL,
        params: {
          id: id,
        },
      });
      window.open(routeData.href, '_blank');
    },
    handleListDataInput(data, index) {
      this.inputDetail.items[index] = data;
    },
    getTimeZone() {
      this.$services.common.getListTimeZones(
        res => {
          this.optionsUTC = res.list;
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    listLoadedDataPDF() {
      if (this.pageCount >= 1 && this.firstLoadingPDF) {
        this.totalWidth = 0;
        this.totalHeight = 0;
        this.canvasWidth = 0;
        this.canvasHeight = 0;
        for (let index = 1; index <= this.pageCount; index++) {
          let el = document.getElementById('elMove-' + index);
          this.totalWidth = el.offsetWidth;
          this.totalHeight += el.offsetHeight;
          this.canvasWidth = el.offsetWidth;
          this.canvasHeight = el.offsetHeight;
        }
        this.firstLoadingPDF = false;
      }
    },
    convertData(data) {
      if (String(data).includes('.')) {
        this.inputDetail.currency_amount = String(parseFloat(data).toFixed(2))
          .replace(/^(?!00[^0])0/, '')
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      } else {
        data = data.replaceAll(',', '');
        this.inputDetail.currency_amount = data
          .replace(/(\..*?)\..*/g, '$1')
          .replace(/^(?!00[^0])0/, '')
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    },
    zoomOut() {
      if (this.zoom <= 0.25) return;
      this.zoom = this.zoom - 0.25;
    },
    goToNextInput() {
      this.isCommit = true;
      this.startLoading();
      let formData = new FormData();
      formData.append('query', this.$route.query);

      this.$services.input.nextDataInput(
        this.inputId,
        formData,
        res => {
          this.endLoading();
          this.showButtonAnother = !this.showButtonAnother;
          this.$router.push({
            name: this.PAGES.INPUT_DETAIL,
            params: {
              id: res.id,
            },
            query: {
              page: this.$route.query.page,
              per_page: this.$route.query.per_page,
              trip_name: this.$route.query.trip_name,
              company_keyword: this.$route.query.company_keyword,
              employee_keyword: this.$route.query.employee_keyword,
              is_reservation_slip: this.$route.query.is_reservation_slip,
              is_cost: this.$route.query.is_cost,
              reimbursement: this.$route.query.reimbursement,
              corporate_card: this.$route.query.corporate_card,
              request: this.$route.query.request,
              with_deleted: this.$route.query.with_deleted,
              country_ids: this.$route.query.country_ids,
              status: this.$route.query.status,
              is_search_activated: this.$route.query.is_search_activated,
              start_date_from: this.$route.query.start_date_from,
              start_date_to: this.$route.query.start_date_to,
              return_date_from: this.$route.query.return_date_from,
              return_date_to: this.$route.query.return_date_to,
              upload_date_from: this.$route.query.upload_date_from,
              upload_date_to: this.$route.query.upload_date_to,
              completion_date_from: this.$route.query.completion_date_from,
              completion_date_to: this.$route.query.completion_date_to,
            },
          });
          setTimeout(() => {
            window.location.reload();
          }, 100);
        },
        err => {
          this.endLoading();
          this.$router.push(this.PAGES.INPUT);
          this.notifyError(this.$t(err.error));
        },
      );
    },
    publicCost() {
      this.isCommit = true;
      this.$services.input.publishInput(
        this.inputId,
        {
          updated_at: this.inputDetail.updated_at,
        },
        () => {
          this.showBoxPublic = false;
          this.flgPublic = !this.flgPublic;
          this.goToNextInput();
        },
        err => {
          this.showBoxPublic = false;
          this.notifyError(this.$t(err.error), true);
        },
      );
    },
    addItemType1() {
      let item = {
        amount: null,
        cost_id: null,
        id: null,
        tax_amount: null,
        tax_classification: 1,
        title: null,
      };
      this.inputDetail.items.push(item);
    },
    addItemType2() {
      let item = {
        airplane_type: 1,
        arrival_date: null,
        arrival_location_id: null,
        arrival_time: null,
        seat_class_type: 1,
        departure_date: null,
        start_time: '00:00',
        end_time: '00:00',
        departure_location_id: null,
        departure_time: null,
        time_zone_id: null,
        location_string: null,
        flight_number: null,
        id: null,
        seat_number: null,
        title: null,
        type: 1,
      };
      this.inputDetail.items.push(item);
    },
    submitForm() {
      if (this.flgSubmit) return;

      this.flgSubmit = true;
      this.listItemSubmit = [];
      this.dataEmit = {};
      this.submitFromParent = !this.submitFromParent;

      setTimeout(() => {
        let noErr = true;
        for (let key in this.dataEmit) {
          if (this.dataEmit[key].errors && !this.checkEmpty(this.dataEmit[key].errors)) {
            noErr = false;
          }
        }

        if (noErr) {
          this.updateData();
        } else {
          this.flgSubmit = false;
        }
      }, 100);
    },
    editingInput() {
      this.startLoading();
      this.$services.input.editingInput(
        this.inputDetail.id,
        () => {
          this.endLoading();
          this.getInputDetail();
        },
        err => {
          this.notifyError(this.$t(err.error));
          this.endLoading();
        },
      );
    },
    async updateData() {
      var formData = {};
      if (this.inputDetail.type === '1') {
        formData = {
          payable_to: this.inputDetail.payable_to,
          payment_method: this.inputDetail.payment_method,
          currency_amount:
            this.inputDetail.currency_amount?.length > 3
              ? this.inputDetail.currency_amount.replaceAll(',', '')
              : this.inputDetail.currency_amount,
          currency_id: this.inputDetail.currency_id,
          company_id: this.inputDetail.company_id,
          items: this.inputDetail.items,
          payment_date: this.inputDetail.payment_date,
          cost_id: this.inputDetail.cost_id ? this.inputDetail.cost_id : '',
        };
      } else {
        formData = {
          items: this.listItemSubmit,
        };
      }
      formData.comment = this.inputDetail.comment;
      formData.updated_at = this.inputDetail.updated_at;

      await this.$services.input.saveInput(
        this.inputDetail.id,
        formData,
        () => {
          setTimeout(() => {
            this.getInputDetail();
            this.notifySuccess(this.$t('I-CM-103'));
          }, 100);
          this.flgSubmit = false;
        },
        err => {
          this.flgSubmit = false;
          this.notifyError(this.$t(err.error), true);
        },
      );
    },
    handleEmitChild(data, errors, index) {
      this.listItemSubmit = [];
      this.dataEmit[index] = {
        data,
        errors,
      };

      for (let key in this.dataEmit) {
        if (this.dataEmit[key].errors && this.checkEmpty(this.dataEmit[key].errors)) {
          this.listItemSubmit.push(this.dataEmit[key].data);
        }
      }
    },
    deleteItem(item, index) {
      const dataClone = this.cloneObject(this.inputDetail.items);
      dataClone.splice(index, 1);

      this.inputDetail.items = [...dataClone];

      if (this.inputDetailItems.length === 0) {
        this.dataAmount = [];
        this.inputDetail.currency_amount = 0;
      } else {
        this.dataAmount.splice(index, 1);
        let amount = this.sumArray(this.dataAmount);
        this.inputDetail.currency_amount = !isNaN(amount) ? amount : 0;
      }
    },
    deleteItem2(item, index) {
      this.inputDetail.items.splice(index, 1);
    },
    handleAmount(data, index) {
      this.dataAmount[index] = {
        data,
      };

      let amount = this.sumArray(this.dataAmount);
      this.inputDetail.currency_amount = !isNaN(amount) ? amount : 0;
    },
    sumArray(array) {
      let sum = 0;
      array.map(function (value) {
        sum += parseInt(value.data);
      });

      return sum;
    },
    async getTaxClassificationList() {
      await this.$services.common.getTaxClassificationList(
        res => {
          this.listTaxClassification = res.list;
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    async getListAirport() {
      await this.$services.schedule.getListAirport(
        res => {
          this.listAirport = res.list;
          this.listAirport.map(item => {
            item.value = item.name;
            return item;
          });
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    nameAirportDeparture(data) {
      var nameAirport = '';
      this.listAirport.filter(item => {
        if (data.departure_location_id && item.id === data.departure_location_id) {
          return (nameAirport = item.name);
        }
      });
      return nameAirport;
    },
    nameAirportArrival(data) {
      var nameAirport = '';
      this.listAirport.filter(item => {
        if (data.arrival_location_id && item.id === data.arrival_location_id) {
          return (nameAirport = item.name);
        }
      });
      return nameAirport;
    },
    async getListStation() {
      await this.$services.schedule.getListStation(
        res => {
          this.listStation = res.list;
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    async getListTicketType() {
      await this.$services.common.getListTicketType(
        res => {
          this.listTicketType = res.list;
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    async getCurrencyList() {
      await this.$services.common.getCurrencyList(
        {},
        res => {
          this.currencyList = res.list;
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    async getPaymentMethodList() {
      await this.$services.common.getPaymentMethodList(
        res => {
          this.listPaymentMethod = res.list;
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    async getInputDetail() {
      await this.$services.input.getInputDetail(
        this.inputId,
        res => {
          this.listItemSubmit = [];
          this.firstLoadingPDF = true;
          this.inputDetail = res;
          if (this.isTypePDF) {
            this.totalWidth = this.countWidth;
            this.totalHeight = this.countHeight;
          } else {
            this.totalWidth = !this.checkEmpty(res.width) ? res.width : 0;
            this.totalHeight = !this.checkEmpty(res.height) ? res.height : 0;
          }

          this.flgPublic = !this.checkEmpty(res.items);

          if (this.inputDetail.status === '4') {
            this.isDisabled = true;
          }
          if (this.inputDetail.currency_id == null) {
            this.inputDetail.currency_id = 1;
          }
          if (this.inputDetail.payment_method == null) {
            this.inputDetail.payment_method = 1;
          }
          this.inputDetailOfficial = this.cloneObject(res);
          if (this.inputDetailOfficial.currency_id == null) {
            this.inputDetailOfficial.currency_id = 1;
          }
          if (this.inputDetailOfficial.payment_method == null) {
            this.inputDetailOfficial.payment_method = 1;
          }
          this.dataContent = this.handleData(res);
          this.getCostItemList(res.company_id);
        },
        err => {
          this.isCommit = true;
          this.notifyError(this.$t(err.error));
          this.$router.push(this.PAGES.INPUT);
        },
      );
    },
    getInputNext(id) {
      this.startLoading();
      this.$services.input.getInputDetail(
        id,
        res => {
          this.endLoading();
          this.listItemSubmit = [];
          this.inputDetail = res;
          if (this.inputDetail.currency_id == null) {
            this.inputDetail.currency_id = 1;
          }
          if (this.inputDetail.payment_method == null) {
            this.inputDetail.payment_method = 1;
          }
          this.inputDetailOfficial = this.cloneObject(res);
          if (this.inputDetailOfficial.currency_id == null) {
            this.inputDetailOfficial.currency_id = 1;
          }
          if (this.inputDetailOfficial.payment_method == null) {
            this.inputDetailOfficial.payment_method = 1;
          }
        },
        err => {
          this.endLoading();
          this.notifyError(this.$t(err.error));
        },
      );
    },
    getCostItemList(id) {
      if (this.checkEmpty(id)) return;
      this.$services.trip.getCostItemListInput(
        { company_id: id },
        res => {
          this.listCostItem = res.list;
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    styleImage() {
      let maxWidth = 900;
      let resizeRatio = 1;

      if (this.isTypePDF && this.countStyleImage == 0) {
        this.zoom = 2.5;
        this.countStyleImage++;
      }

      if (this.totalWidth > this.totalHeight) {
        if (this.totalWidth > maxWidth) {
          resizeRatio = this.totalWidth / maxWidth;
        }
      }

      let widthImg = this.totalWidth / resizeRatio;
      let heightImg = this.totalHeight / resizeRatio;

      return `width: ${widthImg}px; height: ${heightImg}px`;
    },
    mousedown(e) {
      let el = document.getElementById('componentMove');
      window.addEventListener('mousemove', mousemove);
      window.addEventListener('mouseup', mouseup);

      let coordinatesX = el.style.left.slice(0, -2);
      let coordinatesY = el.style.top.slice(0, -2);

      let prevX = e.clientX;
      let prevY = e.clientY;

      function mousemove(e) {
        const newX = prevX - e.clientX;
        const newY = prevY - e.clientY;

        el.style.left = coordinatesX - newX + 'px';
        el.style.top = coordinatesY - newY + 'px';
      }
      function mouseup() {
        window.removeEventListener('mousemove', mousemove);
        window.removeEventListener('mouseup', mouseup);
      }
    },
    stylePlan(status) {
      let backgroundColor = '';
      let color = '';

      if ([1, 3].includes(status)) {
        backgroundColor = '#e6eaeb';
        color = '#657a84';
      }
      if (status === '4') {
        backgroundColor = '#e6f5e5';
        color = '#65c15e';
      }
      if (status === '2') {
        backgroundColor = '#d8f1f7';
        color = '#0aaace';
      }

      return `background-color: ${backgroundColor}; color: ${color}`;
    },
    handleData(response) {
      let data = [];
      response.content.forEach((content, index) => {
        content.forEach(para => {
          let item = {};
          if (!this.checkEmpty(para.paragraphs)) {
            item = {
              text: para.paragraphs[0].text,
              verticies: para.paragraphs[0].verticies,
            };
          } else {
            item = {
              page: index + 1,
              text: para.text,
              verticies: para.verticies,
            };
          }
          data.push(item);
        });
      });
      return data;
    },
    processStyle(data) {
      const { verticies } = data;
      let width = 0,
        height = 0,
        top = 0,
        left = 0;

      const imgWidth = this.totalWidth;
      const imgHeight = this.totalHeight;

      const [vertices_1, vertices_2, vertices_3, vertices_4] = verticies;

      let scaleWidth = 1;
      let scaleHeight = 1;
      if (this.isTypePDF) {
        scaleWidth = this.canvasWidth;
        scaleHeight = this.canvasHeight;
      }
      left = (((vertices_1.x * scaleWidth) / imgWidth) * 100).toFixed(6);
      top = (
        ((this.canvasHeight * data.page - this.canvasHeight + vertices_1.y * scaleHeight) / imgHeight) *
        100
      ).toFixed(6);
      width = (((vertices_3.x * scaleWidth - vertices_1.x * scaleWidth) / imgWidth) * 100).toFixed(6);
      height = (((vertices_4.y * scaleHeight - vertices_2.y * scaleHeight) / imgHeight) * 100).toFixed(6);

      return `width: ${+width + 1}%; height: ${+height + 0.2}%; top: ${top}%; left: ${left}%;`;
    },
    copyValue(text) {
      this.copyTextToClipboard(text);
    },
  },
};
</script>
