export const FLIGHT_CLASS = [
  {
    id: 3,
    en_name: 'Economy',
    ja_name: '(国際線)エコノミー',
  },
  {
    id: 4,
    en_name: 'Premium Economy',
    ja_name: '(国際線)プレミアムエコノミー',
  },
  {
    id: 2,
    en_name: 'Business',
    ja_name: '(国際線)ビジネス',
  },
  {
    id: 1,
    en_name: 'First',
    ja_name: '(国際線)ファーストクラス',
  },
  {
    id: 5,
    en_name: 'Ordinary seat',
    ja_name: '(国内線)普通席',
  },
  {
    id: 7,
    en_name: 'Class J',
    ja_name: '(国内線)クラスJ',
  },
  {
    id: 6,
    en_name: 'Premium Class',
    ja_name: '(国内線)プレミアムクラス',
  },
  {
    id: 8,
    en_name: 'First Class',
    ja_name: '(国内線)ファーストクラス',
  },
];
export const DOMESTIC_FLIGHT_CLASS = [
  {
    id: 5,
    en_name: 'Ordinary seat',
    ja_name: '(国内線)普通席',
  },
  {
    id: 7,
    en_name: 'Class J',
    ja_name: '(国内線)クラスJ',
  },
  {
    id: 8,
    en_name: 'First Class',
    ja_name: '(国内線)ファーストクラス',
  },
  {
    id: 6,
    en_name: 'Premium Class',
    ja_name: '(国内線)プレミアムクラス',
  },
];
export const INTERNATIONAL_FLIGHT_CLASS = [
  {
    id: 3,
    en_name: 'Economy',
    ja_name: '(国際線)エコノミー',
  },
  {
    id: 4,
    en_name: 'Premium Economy',
    ja_name: '(国際線)プレミアムエコノミー',
  },
  {
    id: 2,
    en_name: 'Business',
    ja_name: '(国際線)ビジネス',
  },
  {
    id: 1,
    en_name: 'First',
    ja_name: '(国際線)ファーストクラス',
  },
];

export const TYPE_PASSPORT = 1;
export const TYPE_BANNED_FLIGHT = 2;
export const TYPE_ATTENTION_FLIGHT = 3;
export const TYPE_TRANSIT_TIME = 4;
export const TYPE_TRANSIT_CAUTION = 5;
export const TYPE_AMOUNT_MONEY = 6;
export const TYPE_HOTEL = 7;
export const TYPE_TOTAL_TRAVEL_EXPENSES = 8;
