<template>
  <nav class="navbar navbar-default navbar-lg" role="navigation" id="header-content">
    <div class="navbar-header">
      <h2 class="navbar-title">{{ $t('budget.title_top') }}</h2>
    </div>
    <div class="collapse navbar-collapse">
      <ul class="nav navbar-nav navbar-right">
        <li>
          <a href="#" @click="downloadFileCSV">
            <span class="icon-abui-download prx"></span>{{ $t('zone.download_in_csv') }}
          </a>
        </li>
        <li>
          <a href="#" @click="showModalUploadCSV = true">
            <span class="icon-abui-upload prx" />{{ $t('zone.batch_registration_from_csv') }}
          </a>
        </li>
        <li class="dropdown addNew hidden-sm">
          <a
            href="#"
            class="dropdown-toggle pls"
            @click="isShowDropdown = !isShowDropdown"
            v-click-outside="
              () => {
                isShowDropdown = false;
              }
            "
          >
            <b class="caret"></b>
          </a>
          <span class="dropdown-arrow"></span>
          <ul class="dropdown-menu" :class="isShowDropdown && 'showDropdown'" style="left: -200px">
            <li>
              <a href="#" @click="downloadExampleFileCSV">
                <span class="icon-abui-download prx"></span>{{ $t('zone.download_csv_formate') }}
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </nav>
  <!-- /.navbar -->

  <div class="wrapper with-navbar-fixed-bottom">
    <div class="container-fluid">
      <div style="height: 60px"></div>
      <div class="section noborder pbn">
        <ul id="myTab" class="nav nav-tabs nav-justified" role="tablist">
          <li
            v-for="(detail, stepIndex) in dataRequest"
            :key="stepIndex"
            :class="tabActive == stepIndex + 1 && 'active'"
            @click="tabActive = stepIndex + 1"
          >
            <a href="#" role="tab"
              >{{ $t('budget.stage_' + Number(stepIndex + 1)) }}（{{ this.getFirstDayOfMonth(detail.start_month) }} -
              {{ this.getLastDayOfMonth(detail.end_month) }}）</a
            >
          </li>
        </ul>
      </div>
      <div style="height: 60px"></div>
      <div class="section noborder pts">
        <div id="myTabContent" class="tab-content">
          <div
            class="tab-pane fade"
            :class="tabActive == budget.period_type && 'active in'"
            id="1"
            v-show="tabActive == budget.period_type"
            v-for="(budget, budgetIndex) in dataRequest"
            :key="budgetIndex"
          >
            <div class="switcher--budget" v-if="isShowChart">
              <div class="btn-group btn-switcher js-switcher">
                <button
                  type="button"
                  class="btn btn-inverse"
                  :class="tabTypeActive == 1 && 'active'"
                  @click="tabTypeActive = 1"
                >
                  {{ $t('budget.type_1') }}
                </button>
                <button
                  type="button"
                  class="btn btn-inverse"
                  :class="tabTypeActive == 2 && 'active'"
                  @click="tabTypeActive = 2"
                >
                  {{ $t('budget.type_2') }}
                </button>
              </div>
            </div>
            <template v-if="tabTypeActive == 1">
              <div class="js-switcher__tgt switcher-a">
                <div class="row" style="margin-bottom: 54px; margin-top: 50px">
                  <div class="col-sm-12">
                    <template v-if="!isShowChart">
                      {{ $t('budget.stage_' + tabActive) }}
                    </template>
                    <template v-else>
                      <div class="chart">
                        <apexchart
                          type="line"
                          :height="height"
                          :width="width"
                          :options="chartOptions"
                          ref="myChart"
                          :key="keyRefresh"
                          :series="series"
                        ></apexchart>
                      </div>
                    </template>
                  </div>
                </div>
                <template v-if="isShowChart">
                  <h6 class="mbn">{{ $t('budget.title_domestic') }}</h6>

                  <div class="table-responsive">
                    <span class="note-shoulder">{{ $t('budget.unit') }}</span>
                    <table class="table table-bordered table-budget">
                      <thead>
                        <tr>
                          <th style="width: 12.5%">{{ $t('budget.organize') }}</th>
                          <th>{{ $t('budget.yearly') }}</th>
                          <th style="width: 44px"></th>
                          <th v-for="(data, index) in companyWide" :key="index">{{ data.month }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="sum">
                          <td>{{ $t('budget.company_wide') }}</td>
                          <td class="cell-control">
                            <input
                              type="text"
                              readonly
                              class="form-control input-sm text-right"
                              :value="
                                isIntegerCustom(totalAllOrganization)
                                  ? splitThousandNumber(totalAllOrganization)
                                  : splitThousandNumber(totalAllOrganization.toFixed(2))
                              "
                            />
                          </td>
                          <td class="cell-control">
                            <button class="btn btn-sm btn-flat-default" :title="$t('budget.title_btn')">
                              <i class="icon-abui-arrow-right"></i>
                            </button>
                          </td>
                          <td class="cell-control" v-for="(data, index) in companyWide" :key="index">
                            <input type="text" readonly class="form-control input-sm text-right" :value="data.amount" />
                          </td>
                        </tr>
                        <tr v-for="(organization, index) in listOrganizations" :key="index">
                          <td>
                            <span class="hasTooltip">
                              {{ organization.name }}
                              <span>{{ organization.name }}</span>
                            </span>
                          </td>
                          <td class="cell-control">
                            <input
                              type="text"
                              class="form-control input-sm text-right"
                              @keypress.capture="keyUpEvent($event)"
                              @input="
                                organization.total_budget_domestics = convertNumberFullToHalf(
                                  organization.total_budget_domestics,
                                )
                              "
                              v-model="organization.total_budget_domestics"
                              @change="convertDataCommon('listOrganizations.total_budget_domestics', true, index, true)"
                            />
                          </td>
                          <td class="cell-control">
                            <button
                              @click="setDataFromTotal(organization.id)"
                              class="btn btn-sm btn-flat-default"
                              :title="$t('budget.title_btn')"
                            >
                              <i class="icon-abui-arrow-right"></i>
                            </button>
                          </td>
                          <template
                            v-for="(budget, domesticBudgetIndex) in budget.organization_budget_domestics"
                            :key="domesticBudgetIndex"
                          >
                            <td class="cell-control" v-if="budget.organization_id == organization.id">
                              <input
                                type="text"
                                class="form-control input-sm text-right"
                                @keypress.capture="keyUpEvent($event)"
                                @focus="
                                  budget.amount = isIntegerCustom(convertToFloatNumber(budget.amount))
                                    ? parseInt(convertToFloatNumber(budget.amount))
                                    : convertToFloatNumber(budget.amount)
                                "
                                :value="budget.amount"
                                @focusout="
                                  convertNestedDataAmount(
                                    'dataRequest.organization_budget_domestics.amount',
                                    budgetIndex,
                                    domesticBudgetIndex,
                                    true,
                                    $event,
                                  )
                                "
                              />
                            </td>
                          </template>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </template>
              </div>
            </template>
            <template v-else>
              <div class="js-switcher__tgt switcher-a">
                <div class="row" style="margin-bottom: 54px; margin-top: 50px">
                  <div class="col-sm-12">
                    <template v-if="!isShowChart">
                      {{ $t('budget.stage_' + tabActive) }}
                    </template>
                    <template v-else>
                      <div class="chart">
                        <apexchart
                          type="line"
                          :height="height"
                          :width="width"
                          :options="chartOptions"
                          ref="myChart"
                          :key="keyRefresh"
                          :series="series"
                        ></apexchart>
                      </div>
                    </template>
                  </div>
                </div>
                <template v-if="isShowChart">
                  <h6 class="mbn">{{ $t('budget.title_overseas') }}</h6>

                  <div class="table-responsive">
                    <span class="note-shoulder">{{ $t('budget.unit') }}</span>
                    <table class="table table-bordered table-budget">
                      <thead>
                        <tr>
                          <th style="width: 12.5%">{{ $t('budget.organize') }}</th>
                          <th>{{ $t('budget.yearly') }}</th>
                          <th style="width: 44px"></th>
                          <th v-for="(data, index) in companyWide" :key="index">{{ data.month }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="sum">
                          <td>{{ $t('budget.company_wide') }}</td>
                          <td class="cell-control">
                            <input
                              type="text"
                              readonly
                              class="form-control input-sm text-right"
                              :value="
                                isIntegerCustom(totalAllOrganization)
                                  ? splitThousandNumber(totalAllOrganization)
                                  : splitThousandNumber(totalAllOrganization.toFixed(2))
                              "
                            />
                          </td>
                          <td class="cell-control">
                            <button class="btn btn-sm btn-flat-default" title="年間の予算額を各月に均等に配分します">
                              <i class="icon-abui-arrow-right"></i>
                            </button>
                          </td>
                          <td class="cell-control" v-for="(data, index) in companyWide" :key="index">
                            <input type="text" readonly class="form-control input-sm text-right" :value="data.amount" />
                          </td>
                        </tr>
                        <tr v-for="(organization, index) in listOrganizations" :key="index">
                          <td>
                            <span class="hasTooltip"
                              >{{ organization.name }}
                              <span>{{ organization.name }}</span>
                            </span>
                          </td>
                          <td class="cell-control">
                            <input
                              type="text"
                              class="form-control input-sm text-right"
                              @keypress.capture="keyUpEvent($event)"
                              @input="
                                organization.total_budget_overseas = convertNumberFullToHalf(
                                  organization.total_budget_overseas,
                                )
                              "
                              v-model="organization.total_budget_overseas"
                              @change="convertDataCommon('listOrganizations.total_budget_overseas', true, index, true)"
                            />
                          </td>
                          <td class="cell-control">
                            <button
                              @click="setDataFromTotal(organization.id)"
                              class="btn btn-sm btn-flat-default"
                              title="年間の予算額を各月に均等に配分します"
                            >
                              <i class="icon-abui-arrow-right"></i>
                            </button>
                          </td>
                          <template
                            v-for="(budget, overseaBudgetIndex) in budget.organization_budget_overseas"
                            :key="overseaBudgetIndex"
                          >
                            <td class="cell-control" v-if="budget.organization_id == organization.id">
                              <input
                                type="text"
                                class="form-control input-sm text-right"
                                @input="budget.amount = convertNumberFullToHalf(budget.amount)"
                                @change="changeDataInput($event, budget)"
                                @keypress.capture="keyUpEvent($event)"
                                @focus="
                                  budget.amount = isIntegerCustom(convertToFloatNumber(budget.amount))
                                    ? parseInt(convertToFloatNumber(budget.amount))
                                    : convertToFloatNumber(budget.amount)
                                "
                                :value="budget.amount"
                                @focusout="
                                  convertNestedDataAmount(
                                    'dataRequest.organization_budget_overseas.amount',
                                    budgetIndex,
                                    overseaBudgetIndex,
                                    false,
                                    $event,
                                  )
                                "
                              />
                            </td>
                          </template>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </template>
              </div>
            </template>
          </div>
        </div>
      </div>

      <div v-for="(detail, stepIndex) in dataRequest" :key="stepIndex">
        <div class="section" v-show="tabActive == stepIndex + 1">
          <div class="row">
            <div class="col-sm-3 col-md-2 col-all-closer col-all-closer--leftend col-xs-closer--rightrnd">
              <div class="form-group">
                <label
                  ><span class="label-text">{{ $t('budget.start_month') }}</span></label
                >
                <div class="btn-group bootstrap-select select-block select-sm">
                  <div class="form-group">
                    <div class="input-with-icon">
                      <el-date-picker
                        :disabled="stepIndex != 0"
                        @change="changeDate"
                        type="month"
                        class="date-input"
                        v-model="detail.start_month"
                        :clearable="false"
                        format="YYYY/MM"
                        value-format="YYYY/MM"
                        :placeholder="$t('common.enter_or_select_required')"
                      />
                    </div>
                    <p role="alert" class="error-feedback" v-text="errors[stepIndex]?.start_month"></p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-sm-3 col-md-2 col-all-closer col-all-closer--rightend col-xs-closer--leftrnd"
              :class="$i18n.locale == 'en' && 'fix-width'"
            >
              <div class="form-group">
                <label
                  ><span class="label-text">{{ $t('budget.end_month') }}</span></label
                >
                <div class="btn-group bootstrap-select select-block select-sm">
                  <div class="form-group">
                    <div class="input-with-icon">
                      <el-date-picker
                        @change="changeDate"
                        type="month"
                        class="date-input"
                        v-model="detail.end_month"
                        :clearable="false"
                        format="YYYY/MM"
                        value-format="YYYY/MM"
                        :placeholder="$t('common.enter_or_select_required')"
                      />
                    </div>
                    <p role="alert" class="error-feedback" v-text="errors[stepIndex]?.end_month"></p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-3 col-md-2">
              <div class="form-group">
                <label
                  ><span class="label-text">{{ $t('budget.next_month') }}</span></label
                >
                <div class="btn-group bootstrap-select select-block select-sm">
                  <div class="form-group">
                    <div class="input-with-icon">
                      <el-date-picker
                        disabled
                        type="month"
                        class="date-input"
                        v-model="detail.next_month"
                        :clearable="false"
                        format="YYYY/MM"
                        value-format="YYYY/MM"
                        :placeholder="$t('common.enter_or_select_required')"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="navbar navbar-default navbar-fixed-bottom navbar-footblock with-sidebar"
        role="navigation"
        style="z-index: 990; padding: 12px"
      >
        <div class="row">
          <div class="col-sm-4 col-md-3 col-sm-offset-8 col-md-offset-9">
            <a href="#" class="btn btn-sm btn-block btn-primary" @click="saveBudget()">{{ $t('budget.save') }}</a>
          </div>
        </div>
      </div>
    </div>
    <!-- /.container -->
    <ModalUploadCSV
      :dialogVisible="showModalUploadCSV"
      @cancel="showModalUploadCSV = false"
      @confirm="confirmUploadFileCSV"
      :errorValidate="errorValidate"
      :title="$t('common.title_modal_upload_file_csv')"
    />
  </div>
  <!-- /.wrapper -->
</template>

<script>
import vClickOutside from 'click-outside-vue3';
import ModalUploadCSV from '@/components/common/ModalUploadCSV';
import moment from 'moment';
import VueApexCharts from 'vue3-apexcharts';
import _ from 'lodash';

export default {
  name: 'Budget',
  emits: [],
  components: {
    apexchart: VueApexCharts,
    ModalUploadCSV,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  async created() {
    await this.getCurrentUser()
    if (!this.isShowSettingBudget || !this.showApprovalExpense) {
      this.$router.push(this.PAGES.UNAUTHORIZED);
      return;
    }
    this.startLoading();
    Promise.all([this.getListOrganizations(), this.getListBudgets(true)]).then(() => {
      this.endLoading();
    });
  },
  computed: {
    isShowSettingBudget() {
      return this.$store.state.auth?.user?.setting.is_show_setting_budget == 1 || false;
    },
    showApprovalExpense() {
      return this.$store.state.auth?.user?.setting?.is_feature_payment || false;
    },
    maxValue() {
      return 50000;
    },
    isShowChart() {
      if (this.listOrganizations.length === 0) {
        return false;
      }
      let checkNullData = true;
      this.dataRequest.forEach(item => {
        if (item.start_month || item.end_month) {
          checkNullData = false;
        }
      });
      return !checkNullData;
    },
  },
  data() {
    return {
      isSubmit: false,
      dataBudget: [],
      budgetData: {},
      height: 400,
      keyRefresh: 0,
      width: 650,
      setTotalStatus: {
        status: false,
        organization_id: null,
      },
      maxAmount: 10,
      maxPercent: 100,
      error: [],
      companyWide: [],
      tabActive: 2,
      listMonthYChart: [],
      listMonth: [],
      listMonthChart: [],
      listMonthYear: [],
      tabTypeActive: 1,
      showModalUploadCSV: false,
      isShowDropdown: false,
      errors: [],
      errorValidate: [],
      totalOverseas: [],
      totalDomestics: [],
      totalOrganization: [],
      totalAllOrganization: 0,
      actualCosts: [],
      dataRequestInit: [],
      // companyWideDomesticAmount: [],
      // companyWideOverseasAmount: [],
      arrEachCompanyDomesticTotal: [],
      arrEachCompanyOverseasTotal: [],
      dataRequest: [
        {
          start_month: null,
          end_month: null,
          next_month: null,
          period_type: 1,
          organization_budget_overseas: [
            {
              organization_id: null,
              amount: null,
              month: null,
              year: null,
            },
          ],
          organization_budget_domestics: [
            {
              organization_id: null,
              amount: null,
              month: null,
              year: null,
            },
          ],
        },
        {
          start_month: null,
          end_month: null,
          next_month: null,
          period_type: 2,
          organization_budget_overseas: [
            {
              organization_id: null,
              amount: null,
              month: null,
              year: null,
            },
          ],
          organization_budget_domestics: [
            {
              organization_id: null,
              amount: null,
              month: null,
              year: null,
            },
          ],
        },
        {
          start_month: null,
          end_month: null,
          next_month: null,
          period_type: 3,
          organization_budget_overseas: [
            {
              organization_id: null,
              amount: null,
              month: null,
              year: null,
            },
          ],
          organization_budget_domestics: [
            {
              organization_id: null,
              amount: null,
              month: null,
              year: null,
            },
          ],
        },
      ],
      listOrganizations: [],
      series: [
        {
          name: this.$t('budget.chart_value_1'),
          type: 'line',
          colors: '#ADBAC0',
          data: [],
        },
        {
          name: this.$t('budget.chart_value_2'),
          type: 'column',
          colors: '#ADBAC0',
          data: [],
        },
        {
          name: this.$t('budget.chart_value_3'),
          type: 'column',
          colors: '#ADBAC0',
          data: [],
        },
      ],
      colors: ['#999999'],
      chartOptions: {
        colors: ['#FFA866', '#ADBAC0', '#5E73B7'],
        chart: {
          height: 400,
          width: 650,
          type: 'line',
          align: 'center',
          stacked: false,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        markers: {
          size: 2.5,
          colors: ['#FFA866'],
          strokeColor: '#FFA866',
          strokeWidth: 2.5,
          offsetX: -1,
          offsetY: 0.5,
          hover: {
            size: undefined,
            sizeOffset: 1.5,
          },
        },
        stroke: {
          colors: ['#FFA866', 'transparent', 'transparent'],
          width: [2, 6, 6],
        },
        title: {
          text: this.$t('budget.title_chart_domestic'),
          align: 'center',
          offsetX: 0,
          style: {
            fontSize: '14px',
            fontWeight: 'bold',
            fontFamily: undefined,
            color: '#263238',
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 0,
            columnWidth: '60%',
            barHeight: '100%',
            distributed: false,
            rangeBarOverlap: true,
            rangeBarGroupRows: false,
          },
        },
        fill: {
          opacity: 1,
          type: 'solid',
          pattern: {
            style: 'verticalLines',
            width: 6,
            height: 6,
            strokeWidth: 2,
          },
        },
        xaxis: {
          categories: [],
        },
        yaxis: [
          {
            seriesName: this.$t('budget.chart_value_1'),
            min: 0,
            max: 100,
            tickAmount: 5,
            decimalsInFloat: Number,
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: false,
              color: '#E0E4E7',
            },
            labels: {
              style: {
                colors: '#ADBAC0',
              },
            },
          },
          {
            seriesName: this.$t('budget.chart_value_2'),
            min: 0,
            max: 10,
            tickAmount: 5,
            decimalsInFloat: Number,
            opposite: true,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#E0E4E7',
            },
            labels: {
              style: {
                colors: '#ADBAC0',
              },
            },
          },
          {
            seriesName: this.$t('budget.chart_value_3'),
            min: 0,
            max: 10,
            tickAmount: 5,
            decimalsInFloat: Number,
            opposite: true,
            show: false,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#E0E4E7',
            },
            labels: {
              style: {
                colors: '#ADBAC0',
              },
            },
          },
        ],
        tooltip: {
          enabled: true,
          theme: 'dark',
          offsetX: 1000,
          marker: {
            show: true,
          },
        },
        legend: {
          horizontalAlign: 'center',
          offsetX: 40,
          position: 'bottom',
          offsetY: 0,
          markers: {
            width: 50,
            height: 15,
            strokeWidth: [1, 0, 0],
            strokeColor: ['#E4A574', undefined, undefined],
            fillColors: ['#ADBAC0', '#ADBAC0', '#5E73B7'],
            radius: 0,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0,
            onDblClick: undefined,
            showNullDataPoints: true,
            hover: {
              size: undefined,
              sizeOffset: 3,
            },
          },
        },
      },
    };
  },
  watch: {
    tabActive() {
      this.tabTypeActive = 1;
      this.setMonthYearData();
      this.resetTotal(this.dataRequest);
      this.setDataChangeTab();
      this.setBudgets(this.budgetData, false);
      // window.setInterval(async () => {
      //   window.dispatchEvent(new Event('resize'));
      // }, 3000);
      this.keyRefresh++;
    },
    showModalUploadCSV(data) {
      if (data === false) {
        this.errorValidate = [];
      }
    },
    tabTypeActive(data) {
      this.chartOptions = {
        ...this.chartOptions,
        ...{
          title: {
            text: Number(data) === 1 ? this.$t('budget.title_chart_domestic') : this.$t('budget.title_chart_overseas'),
            align: 'center',
            offsetX: 0,
            style: {
              fontSize: '14px',
              fontWeight: 'bold',
              fontFamily: undefined,
              color: '#263238',
            },
          },
        },
      };
      this.keyRefresh++;
      this.resetTotal(this.dataRequest);
    },
    maxAmount(data) {
      let option = this.chartOptions.yaxis;
      option[0]['max'] = this.maxPercent;
      option[1]['max'] = data;
      option[2]['max'] = data;
      this.chartOptions = {
        ...this.chartOptions,
        ...{
          yaxis: option,
        },
      };
      this.keyRefresh++;
    },
    maxPercent(data) {
      let option = this.chartOptions.yaxis;
      option[0]['max'] = data;
      option[1]['max'] = this.maxAmount;
      option[2]['max'] = this.maxAmount;
      this.chartOptions = {
        ...this.chartOptions,
        ...{
          yaxis: option,
        },
      };
      this.keyRefresh++;
    },
    dataRequest: {
      handler: function (data) {
        this.setMonthYearData();
        this.resetTotal(data);
        let chartYaxis = this.chartOptions.yaxis;
        chartYaxis[0]['max'] = this.maxPercent;
        chartYaxis[1]['max'] = this.maxAmount;
        chartYaxis[2]['max'] = this.maxAmount;
        this.chartOptions = {
          ...this.chartOptions,
          ...{
            yaxis: chartYaxis,
          },
        };
        this.keyRefresh++;
      },
      deep: true,
    },
    listOrganizations: {
      handler: function () {
        this.setTotalAllAmount();
      },
      deep: true,
    },

    companyWide: {
      handler: function (data) {
        const listAmount = [];
        const listPercentAmount = [];
        let maxAmount = 0;
        let maxPercent = 100;
        let totalActualAmount = 0;
        let totalExpectationAmount = this.totalAllOrganization;
        if (data !== undefined && data.length > 0 && this.listOrganizations.length > 0) {
          // data.forEach((item, index) => {
          //   const actualCost =
          //       this.tabTypeActive == 1
          //           ? this.actualCosts[this.tabActive - 1]['budget_domestics']
          //           : this.actualCosts[this.tabActive - 1]['budget_overseas'];
          //   if (actualCost[index].amount) {
          //     totalActualAmount += actualCost[index].amount;
          //   }
          // });
          data.forEach((item, index) => {
            if (item.month) {
              const actualCost =
                this.tabTypeActive == 1
                  ? this.actualCosts[this.tabActive - 1]['budget_domestics']
                  : this.actualCosts[this.tabActive - 1]['budget_overseas'];
              if (actualCost[index].amount) {
                totalActualAmount += actualCost[index].amount;
              }
              maxAmount =
                maxAmount < this.convertToFloatNumber(item.amount) ? this.convertToFloatNumber(item.amount) : maxAmount;
              listAmount.push(parseFloat(this.convertToFloatNumber(item.amount)));
              const percentAmount =
                totalExpectationAmount && totalExpectationAmount > 0.4
                  ? (totalActualAmount * 100) / totalExpectationAmount
                  : 0;
              maxPercent = percentAmount < maxPercent ? maxPercent : percentAmount;
              listPercentAmount.push(percentAmount);
            }
          });
          maxAmount = (parseInt(maxAmount / 5) + 1) * 5;
          maxPercent = (parseInt(maxPercent / 5) + 1) * 5;
          // const oldMaxAmount = maxAmount;
          // maxAmount = (parseInt(maxAmount / 500)) * 500;
          // maxAmount = Math.round(oldMaxAmount) > Math.round(maxAmount) ?
          //     (maxAmount + 500) : Math.round(maxAmount);
          // const oldMaxPercent = maxPercent;
          // maxPercent = (parseInt(maxPercent / 5)) * 5;
          // maxPercent = Math.round(oldMaxPercent) > Math.round(maxPercent) ?
          //     (maxPercent + 5) : Math.round(maxPercent);
          this.maxAmount = maxAmount + Math.round(0.05 * maxAmount);
          this.maxPercent = maxPercent < 106 ? 100 : maxPercent;
          this.series[1]['data'] = listAmount;
          this.series[0]['data'] = listPercentAmount;
          // window.dispatchEvent(new Event('resize'));

          this.keyRefresh++;
        } else if (this.listOrganizations.length !== 0) {
          this.companyWide = [];
        }
        this.setDataChangeTab();
      },
      deep: true,
    },
    actualCosts: {
      handler: function () {
        this.setDataChangeTab();
      },
      deep: true,
    },
    arrEachCompanyDomesticTotal: {
      handler(newVal) {
        if (!this.checkEmpty(newVal)) this.setDataTotalDomestic(newVal);
      },
      deep: true,
    },
    arrEachCompanyOverseasTotal: {
      handler(newVal) {
        if (!this.checkEmpty(newVal)) this.setDataTotalOverseas(newVal);
      },
      deep: true,
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!this.compareData(this.dataRequest, this.dataRequestInit) && !this.isSubmit) {
      const answer = window.confirm(this.$t('common.confirm_leave_page'));
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  methods: {
    async getCurrentUser() {
      await this.$services.account.current(
          res => {
            this.$store.dispatch('auth/initCurrentData', res);
          },
          () => {
            this.logout();
          },
      );
    },
    //Convert Data Amount to String have ',' , use for data have 2 index inside
    convertNestedDataAmount(dataName, parentIndex, positionIndex, isUpdateDomesticAmount = true, event) {
      let data = '';
      //Number of child level object we need to access
      const childName = dataName.split('.');
      data = event.target.value;

      if (this.checkEmpty(data)) return;
      if (String(data).includes('.') || String(data).includes('．')) {
        if (String(data).includes('．')) {
          data = data.replaceAll('．', '.');
        }
        //Return a string contain ',' split
        const returnStr =
          data == 0
            ? 0
            : String(parseFloat(data).toFixed(2))
                .replace(/^(?!00[^0])0/, '')
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        isUpdateDomesticAmount
          ? (this[`${childName[0]}`][parentIndex].organization_budget_domestics[positionIndex].amount = returnStr)
          : (this[`${childName[0]}`][parentIndex].organization_budget_overseas[positionIndex].amount = returnStr);
      } else {
        data = String(data).replaceAll(',', '');
        //Return a string contain ',' split
        const returnStr =
          data == 0
            ? 0
            : data
                .replace(/(\..*?)\..*/g, '$1')
                .replace(/^(?!00[^0])0/, '')
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        isUpdateDomesticAmount
          ? (this[`${childName[0]}`][parentIndex].organization_budget_domestics[positionIndex].amount = returnStr)
          : (this[`${childName[0]}`][parentIndex].organization_budget_overseas[positionIndex].amount = returnStr);
      }
    },
    // splitData(data) {
    //   if (String(data).includes('.') || String(data).includes('．')) {
    //     if (String(data).includes('．')) {
    //       data = data.replaceAll('．', '.');
    //     }
    //     return String(parseFloat(data).toFixed(2))
    //       .replace(/^(?!00[^0])0/, '')
    //       .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    //   } else {
    //     data = String(data).replaceAll(',', '');
    //     return data
    //       .replace(/(\..*?)\..*/g, '$1')
    //       .replace(/^(?!00[^0])0/, '')
    //       .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    //   }
    // },
    convertNumberFullToHalf(number) {
      if (number) {
        number = String(number);
        if (number.charAt(0) == 0 && number.length > 1) {
          number = number.substring(1);
        }
        number = number.replace(/[^0-9０-９]/g, '');
        number = String(number).replace(/[\uFF10-\uFF19]/g, function (m) {
          return String.fromCharCode(m.charCodeAt(0) - 0xfee0);
        });
      }

      return number;
    },
    changeDate() {
      this.errors = [];
      let flagValidate = false;
      this.dataRequest.forEach((item, index) => {
        this.errors[index] = {
          start_month: null,
          end_month: null,
        };
        if (item.start_month == null && index == 0) {
          flagValidate = true;
          this.errors[index].start_month = this.$t('E-CM-100', { item: this.$t('budget.start_month') });
        }
        if (item.end_month == null) {
          flagValidate = true;
          this.errors[index].end_month = this.$t('E-CM-100', { item: this.$t('budget.end_month') });
        } else if (item.start_month && item.start_month >= item.end_month) {
          flagValidate = true;
          this.errors[index].end_month = this.$t('E-CU-008', {
            to: this.$t('budget.end_month'),
            from: this.$t('budget.start_month'),
          });
        }
      });

      if (flagValidate) return;

      this.getListBudgets();
    },
    keyUpEvent(event) {
      if (
        (event.keyCode === 46 && event.target.value.includes('.')) ||
        ((event.keyCode < 48 || event.keyCode > 57) && event.keyCode !== 46 && event.keyCode !== 45)
      ) {
        event.preventDefault();
        return;
      }
    },
    changeDataInput(event, data) {
      const itemCost = {
        type: this.tabTypeActive, //budget_domestics: 1, budget_overseas: 2
        organization_id: data.organization_id,
        amount: data.amount,
        year: data.year,
        month: data.month,
      };

      if (this.dataBudget.length === 0) {
        this.dataBudget.push(itemCost);
      } else {
        this.dataBudget.forEach((item, index) => {
          if (
            item.type == this.tabTypeActive &&
            item.organization_id == data.organization_id &&
            item.year == data.year &&
            item.month == data.month
          ) {
            this.dataBudget[index] = itemCost;
          } else {
            this.dataBudget.push(itemCost);
          }
        });
      }
      this.dataBudget = this.dataBudget.filter((v, p) => this.dataBudget.indexOf(v) == p);
    },
    setDataChangeTab() {
      const listAmount = [];
      const listMonthChart = [];
      const listMonth = [];
      let actualCosts =
        this.tabTypeActive == 1
          ? this.actualCosts[this.tabActive - 1]['budget_domestics']
          : this.actualCosts[this.tabActive - 1]['budget_overseas'];
      const listMonthYear =
        this.tabTypeActive == 1
          ? this.listMonthYChart[this.tabTypeActive - 1]
          : this.listMonthYChart[this.tabTypeActive - 1];
      let maxAmount = 0;
      actualCosts = this.listOrganizations.length > 0 ? actualCosts : listMonthYear;
      actualCosts.forEach(item => {
        maxAmount = maxAmount < item.amount ? item.amount : maxAmount;
        listAmount.push(item.amount);
        let month = Number(item.month);
        const monthName = this.$t('month.' + month);
        listMonthChart.push(monthName);
        listMonth.push(month);
      });
      maxAmount = (parseInt(maxAmount / 5) + 1) * 5;
      this.maxAmount = maxAmount < this.maxAmount ? this.maxAmount : maxAmount;
      this.listMonth = listMonth;
      this.listMonthChart = listMonthChart;
      // let chartXaxis = this.chartOptions.xaxis;
      // chartXaxis['categories'] = [];
      this.chartOptions = {
        ...this.chartOptions,
        ...{
          xaxis: {
            categories: listMonthChart,
          },
        },
      };
      this.series[2]['data'] = listAmount;

      this.keyRefresh++;
      // window.dispatchEvent(new Event('resize'));
    },
    changeTab(stepIndex) {
      this.tabActive = stepIndex + 1;
    },
    setMonthYearData() {
      this.dataRequest.forEach((item, index) => {
        const nextMonth = this.dataRequest[index]['next_month'];
        const endMonth = item.end_month ? moment(item.end_month, 'YYYY/MM').add(1, 'M').format('YYYY/MM') : null;
        this.dataRequest[index]['next_month'] = endMonth;
        if (this.dataRequest[index + 1] !== undefined) {
          this.dataRequest[index + 1]['start_month'] = endMonth;
        }
        if (nextMonth === null) {
          this.dataRequestInit = this.cloneObject(this.dataRequest);
        }
      });
    },
    setDataFromTotal(organization_id) {
      this.setTotalStatus = {
        status: true,
        organization_id: organization_id,
      };
      let totalAmount = 0;
      this.listOrganizations.forEach(organization => {
        if (organization.id === organization_id) {
          totalAmount =
            this.tabTypeActive === 1
              ? this.convertToFloatNumber(organization.total_budget_domestics)
              : this.convertToFloatNumber(organization.total_budget_overseas);
        }
      });

      let amount = Math.ceil((totalAmount / this.listMonth.length) * 100) / 100;
      if (this.tabTypeActive === 1) {
        this.dataRequest[this.tabActive - 1].organization_budget_domestics.forEach((item, index) => {
          if (item.organization_id === organization_id) {
            this.dataRequest[this.tabActive - 1].organization_budget_domestics[index]['amount'] =
              this.splitThousandNumber(amount);
          }
        });
      } else {
        this.dataRequest[this.tabActive - 1].organization_budget_overseas.forEach((item, index) => {
          if (item.organization_id === organization_id) {
            this.dataRequest[this.tabActive - 1].organization_budget_overseas[index]['amount'] =
              this.splitThousandNumber(amount);
          }
        });
      }
    },
    async resetTotal(data) {
      this.arrEachCompanyDomesticTotal = [];
      this.arrEachCompanyOverseasTotal = [];
      this.listOrganizations.forEach((organization, index) => {
        this.arrEachCompanyDomesticTotal.push(0);
        this.arrEachCompanyOverseasTotal.push(0);
        if (this.setTotalStatus.status === false && this.setTotalStatus.organization_id !== organization.id) {
          this.listOrganizations[index]['total_budget_domestics'] = 0;
          this.listOrganizations[index]['total_budget_overseas'] = 0;
        }
      });
      if (this.listMonthYear[Number(this.tabActive - 1)] != undefined && this.listOrganizations.length !== 0) {
        this.companyWide = _.cloneDeep(this.listMonthYear[Number(this.tabActive - 1)]);
      }
      // Init money amount of each company months
      let companyWideOverseasAmount = [];
      if (!this.checkEmpty(this.companyWide)) {
        for (let i = 0; i < this.companyWide.length; i++) {
          companyWideOverseasAmount.push(0);
        }
      }
      data[this.tabActive - 1].organization_budget_overseas.forEach(expectedCost => {
        if (this.tabTypeActive === 2) {
          const month = expectedCost.month;
          const checkListCompanyWide = this.companyWide.filter(
            item =>
              item.month !== undefined &&
              Number(item.year) == Number(expectedCost.year) &&
              Number(item.month) == Number(month),
          );
          const amount = !isNaN(expectedCost.amount) ? this.convertToFloatNumber(expectedCost.amount) : 0;
          if (this.companyWide.length === 0 || checkListCompanyWide.length == 0) {
            this.companyWide.push({
              month: month,
              year: Number(expectedCost.year),
              amount: parseFloat(amount),
            });
          } else {
            this.companyWide.forEach((item, index) => {
              if (Number(item.month) == Number(month) && Number(item.year) == Number(expectedCost.year)) {
                companyWideOverseasAmount[index] = parseFloat(
                  parseFloat(this.convertToFloatNumber(expectedCost.amount)) +
                    parseFloat(companyWideOverseasAmount[index]),
                ).toFixed(2);
                this.companyWide[index]['month'] = Number(expectedCost.month);
                this.companyWide[index]['year'] = Number(expectedCost.year);
              }
            });
          }
        }

        this.listOrganizations.forEach((organization, index) => {
          if (organization.id === expectedCost.organization_id) {
            this.arrEachCompanyOverseasTotal[index] = parseFloat(
              parseFloat(this.convertToFloatNumber(this.arrEachCompanyOverseasTotal[index])) +
                parseFloat(this.convertToFloatNumber(expectedCost.amount)),
            ).toFixed(2);
          }
        });
      });

      // Init money amount of each company months
      let companyWideDomesticAmount = [];
      if (!this.checkEmpty(this.companyWide)) {
        for (let i = 0; i < this.companyWide.length; i++) {
          companyWideDomesticAmount.push(0);
        }
      }

      data[this.tabActive - 1].organization_budget_domestics.forEach(expectedCost => {
        if (this.tabTypeActive === 1) {
          const month = Number(expectedCost.month);
          const checkListCompanyWide = this.companyWide.filter(
            item =>
              item.month !== undefined &&
              Number(item.year) == Number(expectedCost.year) &&
              Number(item.month) == Number(month),
          );
          const amount = !isNaN(expectedCost.amount) ? this.convertToFloatNumber(expectedCost.amount) : 0;
          if (this.companyWide.length === 0 || checkListCompanyWide.length == 0) {
            this.companyWide.push({
              month: month,
              year: Number(expectedCost.year),
              amount: parseFloat(amount),
            });
          } else {
            this.companyWide.forEach((item, index) => {
              if (
                parseInt(item.month) == parseInt(expectedCost.month) &&
                parseInt(item.year) == parseInt(expectedCost.year)
              ) {
                companyWideDomesticAmount[index] = parseFloat(
                  parseFloat(this.convertToFloatNumber(expectedCost.amount)) +
                    parseFloat(companyWideDomesticAmount[index]),
                ).toFixed(2);
                this.companyWide[index]['month'] = Number(expectedCost.month);
                this.companyWide[index]['year'] = Number(expectedCost.year);
              }
            });
          }
        }

        this.listOrganizations.forEach((organization, index) => {
          // item.total_budget_domestics = this.splitThousandNumber(item.total_budget_domestics);
          if (organization.id === expectedCost.organization_id) {
            this.arrEachCompanyDomesticTotal[index] = parseFloat(
              parseFloat(this.convertToFloatNumber(this.arrEachCompanyDomesticTotal[index])) +
                parseFloat(this.convertToFloatNumber(expectedCost.amount)),
            ).toFixed(2);
          }
        });
      });

      if (this.tabTypeActive == 1) {
        this.setCompanyWideAmount(companyWideDomesticAmount);
      } else {
        this.setCompanyWideAmount(companyWideOverseasAmount);
      }
      this.setTotalStatus = {
        status: false,
        organization_id: null,
      };
      this.setTotalAllAmount();
    },
    setCompanyWideAmount(companyWideDomesticAmount) {
      companyWideDomesticAmount.forEach((item, index) => {
        this.companyWide[index].amount = this.splitThousandNumber(parseFloat(item));
      });
    },
    setTotalAllAmount() {
      this.totalAllOrganization = 0;
      this.listOrganizations.forEach(item => {
        const totalDomestic = this.convertToFloatNumber(item.total_budget_domestics);
        const totalOverseas = this.convertToFloatNumber(item.total_budget_overseas);
        let totalAmount = this.tabTypeActive === 1 ? totalDomestic : totalOverseas;
        if (totalAmount) {
          this.totalAllOrganization += parseFloat(totalAmount);
        }
      });
      window.dispatchEvent(new Event('resize'));
    },
    async getListBudgets(isFirstLoad = false) {
      let queryBuild = '';
      this.dataRequest.forEach((item, index) => {
        let startMonth = item.start_month ? moment(item.start_month, 'YYYY/MM').format('YYYY-M') : null;
        let endMonth = item.end_month ? moment(item.end_month, 'YYYY/MM').format('YYYY-M') : null;
        queryBuild += 'period_times[' + index + '][start_month]=' + startMonth + '&';
        queryBuild += 'period_times[' + index + '][end_month]=' + endMonth + '&';
        queryBuild += 'period_times[' + index + '][period_type]=' + item.period_type + '&';
      });
      queryBuild = '?' + queryBuild.substring(0, queryBuild.length - 1);
      await this.$services.budget.getListBudgets(
        queryBuild,
        res => {
          let checkNullData = true;
          this.budgetData = res.budget;
          let budgetResponse = res.budget;
          this.dataRequest.forEach(item => {
            if (item.start_month || item.end_month) {
              checkNullData = false;
            }
          });
          this.dataBudget;
          budgetResponse.forEach((item, index) => {
            if (checkNullData) {
              this.dataRequest[index]['start_month'] = item.start_month;
              this.dataRequest[index]['end_month'] = item.end_month;
            }
            if (this.checkEmpty(this.dataBudget)) {
              this.dataBudget.forEach(valBudget => {
                if (valBudget.type == 1) {
                  item.budget_domestics.forEach((budgetDomestics, indexBudgetDomestics) => {
                    if (budgetDomestics.organization_id == valBudget.organization_id) {
                      budgetDomestics.expected_costs.forEach((expectedCosts, indexExpectedCosts) => {
                        if (expectedCosts.month == valBudget.month && expectedCosts.year == valBudget.year) {
                          expectedCosts['amount'] = valBudget.amount;
                          budgetResponse[index]['budget_domestics'][indexBudgetDomestics]['expected_costs'][
                            indexExpectedCosts
                          ] = expectedCosts;
                        }
                      });
                    }
                  });
                } else if (valBudget.type == 2) {
                  item.budget_overseas.forEach((budgetOverseas, indexBudgetOverseas) => {
                    if (budgetOverseas.organization_id == valBudget.organization_id) {
                      budgetOverseas.expected_costs.forEach((expectedCosts, indexExpectedCosts) => {
                        if (expectedCosts.month == valBudget.month && expectedCosts.year == valBudget.year) {
                          expectedCosts['amount'] = valBudget.amount;
                          budgetResponse[index]['budget_overseas'][indexBudgetOverseas]['expected_costs'][
                            indexExpectedCosts
                          ] = expectedCosts;
                        }
                      });
                    }
                  });
                }
              });
            }
          });
          this.setBudgets(budgetResponse, isFirstLoad);
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    async setBudgets(budgets, isFirstLoad = false) {
      let arrDomesticTotal = [];
      let arrOverseasTotal = [];
      for (let i = 0; i < this.listOrganizations.length; i++) {
        arrDomesticTotal.push(0);
        arrOverseasTotal.push(0);
      }
      await budgets.forEach((item, index) => {
        this.totalOverseas[index] = item.total_overseas;
        this.totalDomestics[index] = item.total_domestics;
        this.actualCosts[index] = item.actual_costs;

        let organization_budget_overseas = [];
        this.listMonthYChart[index] = _.cloneDeep(item.list_month_year);
        this.listMonthYear[index] = _.cloneDeep(item.list_month_year);
        if (this.tabActive == Number(index + 1) && this.listOrganizations.length !== 0) {
          this.companyWide = item.list_month_year;
        }
        item.budget_overseas.forEach(budgetOversea => {
          budgetOversea.expected_costs.forEach(expectedCost => {
            let month = Number(expectedCost.month);
            const monthName = this.$t('month.' + month);
            // if (!this.listMonth.includes(month)) {
            this.listMonth.push(month);
            // }
            // if (!this.listMonthChart.includes(monthName)) {
            this.listMonthChart.push(monthName);
            // }

            if (this.tabTypeActive === 2 && this.tabActive == Number(index + 1)) {
              const checkListCompanyWide = this.companyWide.filter(
                item =>
                  item.month !== undefined &&
                  Number(item.year) == Number(expectedCost.year) &&
                  Number(item.month) == Number(month),
              );
              if (this.companyWide.length === 0 || checkListCompanyWide.length == 0) {
                this.companyWide.push({
                  month: month,
                  year: Number(expectedCost.year),
                  amount: parseFloat(expectedCost.amount),
                });
              } else {
                this.companyWide.forEach((item, index) => {
                  if (Number(item.month) == Number(month) && Number(item.year) == Number(expectedCost.year)) {
                    this.companyWide[index]['amount'] =
                      this.companyWide[index]['amount'] !== undefined
                        ? this.companyWide[index]['amount'] + parseFloat(expectedCost.amount)
                        : parseFloat(expectedCost.amount);
                    this.companyWide[index]['month'] = Number(expectedCost.month);
                    this.companyWide[index]['year'] = Number(expectedCost.year);
                  }
                });
              }
            }
            const data = {
              month: expectedCost.month,
              amount: this.splitThousandNumber(expectedCost.amount),
              year: expectedCost.year,
              organization_id: budgetOversea.organization_id,
            };
            organization_budget_overseas.push(data);
            this.listOrganizations.forEach((organization, index) => {
              if (organization.id === budgetOversea.organization_id) {
                arrOverseasTotal[index] = parseFloat(
                  parseFloat(arrOverseasTotal[index]) + parseFloat(expectedCost.amount),
                ).toFixed(2);
              }
            });
          });
        });
        this.dataRequest[index].organization_budget_overseas = organization_budget_overseas;

        let organization_budget_domestics = [];

        item.budget_domestics.forEach(budgetDomestic => {
          budgetDomestic.expected_costs.forEach(expectedCost => {
            if (this.tabTypeActive === 1 && this.tabActive == Number(index + 1)) {
              const month = Number(expectedCost.month);
              const checkListCompanyWide = this.companyWide.filter(
                item =>
                  item.month !== undefined &&
                  Number(item.year) == Number(expectedCost.year) &&
                  Number(item.month) == Number(month),
              );
              if (this.companyWide.length === 0 || checkListCompanyWide.length == 0) {
                this.companyWide.push({
                  month: month,
                  year: Number(expectedCost.year),
                  amount: parseFloat(expectedCost.amount),
                });
              } else {
                this.companyWide.forEach((item, index) => {
                  if (Number(item.month) == Number(month) && Number(item.year) == Number(expectedCost.year)) {
                    this.companyWide[index]['amount'] =
                      this.companyWide[index]['amount'] !== undefined
                        ? this.companyWide[index]['amount'] + parseFloat(expectedCost.amount)
                        : parseFloat(expectedCost.amount);
                    this.companyWide[index]['month'] = Number(expectedCost.month);
                    this.companyWide[index]['year'] = Number(expectedCost.year);
                  }
                });
              }
            }

            const data = {
              month: expectedCost.month,
              amount: this.splitThousandNumber(expectedCost.amount),
              year: expectedCost.year,
              organization_id: budgetDomestic.organization_id,
            };

            organization_budget_domestics.push(data);
            this.listOrganizations.forEach((organization, index) => {
              if (organization.id === budgetDomestic.organization_id) {
                arrDomesticTotal[index] = parseFloat(
                  parseFloat(arrDomesticTotal[index]) + parseFloat(expectedCost.amount),
                ).toFixed(2);
              }
            });
          });
        });
        this.dataRequest[index].organization_budget_domestics = organization_budget_domestics;
        if (isFirstLoad) {
          this.dataRequestInit = this.cloneObject(this.dataRequest);
        }

        this.setTotalAllAmount();
      });
    },

    //Set total domestic data amount for each organization
    setDataTotalDomestic(arrDomesticTotal) {
      arrDomesticTotal.forEach((item, index) => {
        this.listOrganizations[index].total_budget_domestics = Number.isInteger(parseFloat(item))
          ? this.splitThousandNumber(parseInt(item))
          : this.splitThousandNumber(item);
      });
    },

    //Set total overseas data amount for each organization
    setDataTotalOverseas(arrOverseasTotal) {
      arrOverseasTotal.forEach((item, index) => {
        this.listOrganizations[index].total_budget_overseas = Number.isInteger(parseFloat(item))
          ? this.splitThousandNumber(parseInt(item))
          : this.splitThousandNumber(item);
      });
    },
    async saveBudget() {
      await this.getCurrentUser()
      if (!this.isShowSettingBudget || !this.showApprovalExpense) {
        this.$router.push(this.PAGES.UNAUTHORIZED);
        return;
      }
      this.errors = [];
      let flagValidate = false;
      this.dataRequest.forEach((item, index) => {
        this.errors[index] = {
          start_month: null,
          end_month: null,
        };
        if (item.start_month == null && index == 0) {
          flagValidate = true;
          this.errors[index].start_month = this.$t('E-CM-100', { item: this.$t('budget.start_month') });
        }
        if (item.end_month == null) {
          flagValidate = true;
          this.errors[index].end_month = this.$t('E-CM-100', { item: this.$t('budget.end_month') });
        } else if (item.start_month && item.start_month >= item.end_month) {
          flagValidate = true;
          this.errors[index].end_month = this.$t('E-CU-008', {
            to: this.$t('budget.end_month'),
            from: this.$t('budget.start_month'),
          });
        }
      });

      if (flagValidate) return;
      this.isSubmit = true;
      this.startLoading();
      this.dataRequest.forEach(data => {
        data.organization_budget_domestics.forEach(budgetData => {
          budgetData.amount = this.convertToFloatNumber(budgetData.amount);
        });
        data.organization_budget_overseas.forEach(budgetData => {
          budgetData.amount = this.convertToFloatNumber(budgetData.amount);
        });
      });
      this.$services.budget.saveBudget(
        {
          budgets: this.dataRequest,
        },
        () => {
          this.dataBudget = [];
          this.getListBudgets();
          this.endLoading();
          this.notifySuccess(this.$t('I-CM-103'));
        },
        err => {
          this.endLoading();
          this.notifyError(this.$t(err.error));
        },
      );
    },
    getFirstDayOfMonth(time) {
      return time ? moment(time, 'YYYY/MM').startOf('month').format('YYYY/M/D') : '';
    },
    getLastDayOfMonth(time) {
      return time ? moment(time, 'YYYY/MM').endOf('month').format('YYYY/M/D') : '';
    },
    pickerFromOptions(time) {
      return (
        moment(new Date(time)).format('YYYY-MM-DD') > moment(this.dataRequest?.end_date).format('YYYY-MM-DD') ||
        (this.mode == 'edit' &&
          moment(new Date(time)).format('YYYY-MM-DD') < moment(this.dataRequest?.created_at).format('YYYY-MM-DD')) ||
        (this.mode == 'create' && moment(new Date(time)).format('YYYY-MM-DD') < moment(new Date()).format('YYYY-MM-DD'))
      );
    },
    pickerToOptions(time) {
      if (!this.dataRequest.from_date)
        return moment(new Date(time)).format('YYYY-MM-DD') < moment(new Date()).format('YYYY-MM-DD');

      return (
        moment(new Date(time)).format('YYYY-MM-DD') < moment(this.dataRequest?.from_date).format('YYYY-MM-DD') ||
        (this.mode == 'edit' &&
          moment(new Date(time)).format('YYYY-MM-DD') < moment(this.dataRequest?.created_at).format('YYYY-MM-DD'))
      );
    },
    confirmUploadFileCSV(listFiles) {
      let formData = new FormData();
      if (listFiles.length > 0) {
        formData.append('file', listFiles[0]);
        this.errorValidate = [];
        this.startLoading();
        this.$services.budget.uploadFileCSV(
          formData,
          () => {
            this.getListBudgets();
            this.endLoading();
            this.showModalUploadCSV = false;
          },
          err => {
            this.endLoading();
            if (err.code == 422) {
              this.errorValidate = err.error;
            } else {
              this.notifyError(this.$t(err.error));
            }
          },
        );
      } else {
        this.notifyError(this.$t('E-CM-101', { item: this.$t('common.file') }));
      }
    },
    downloadExampleFileCSV() {
      this.startLoading();
      this.$services.budget.downloadExampleFileCSV(
        res => {
          this.endLoading();
          this.handleDownloadCSV(res);
        },
        err => {
          this.endLoading();
          this.notifyError(this.$t(err.error));
        },
      );
    },
    handleDownloadCSV(res) {
      window.open(res.file_path, '_blank').focus();
    },
    downloadFileCSV() {
      this.startLoading();
      let queryBuild = this.tabTypeActive == 2 ? 'is_oversea=1&' : 'is_oversea=0&';
      this.dataRequest.forEach((item, index) => {
        let startMonth = item.start_month ? moment(item.start_month, 'YYYY/MM').format('YYYY-M') : null;
        let endMonth = item.end_month ? moment(item.end_month, 'YYYY/MM').format('YYYY-M') : null;
        queryBuild += 'period_times[' + index + '][start_month]=' + startMonth + '&';
        queryBuild += 'period_times[' + index + '][end_month]=' + endMonth + '&';
        queryBuild += 'period_times[' + index + '][period_type]=' + item.period_type + '&';
      });
      queryBuild = '?' + queryBuild.substring(0, queryBuild.length - 1);
      this.$services.budget.downloadFileCSV(
        queryBuild,
        res => {
          this.endLoading();
          this.handleDownloadCSV(res);
        },
        err => {
          this.endLoading();
          this.notifyError(this.$t(err.error));
        },
      );
    },
    async getListOrganizations() {
      const params = {
        all_data: true,
      };
      await this.$services.common.getListOrganizations(
        params,
        res => {
          this.listOrganizations = res.list;
          this.listOrganizations.forEach((organization, index) => {
            this.listOrganizations[index]['total_budget_domestics'] = 0;
            this.listOrganizations[index]['total_budget_overseas'] = 0;
          });
          this.arrEachCompanyDomesticTotal = [];
          this.arrEachCompanyOverseasTotal = [];
          if (!this.checkEmpty(res.list)) {
            for (let i = 0; i < res.list.length; i++) {
              this.arrEachCompanyDomesticTotal.push(0);
              this.arrEachCompanyOverseasTotal.push(0);
            }
          }
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
  },
};
</script>
<style scoped>
.chart {
  width: 650px;
  margin: auto;
}

tr td:first-child {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.apexcharts-tooltip {
  background: #f3f3f3;
  color: orange;
}

input:read-only {
  background-color: #fff !important;
}

.hasTooltip span {
  display: none;
  color: #000;
  padding: 3px;
}

.hasTooltip:hover span {
  display: block;
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  margin: 2px 10px;
}

.fix-width {
  width: 325px !important;
}
</style>
