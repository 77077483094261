<template>
  <el-dialog
    v-model="isVisible"
    width="640px"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    :show-close="true"
    @close="closeModal()"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-header--title">{{ $t('trip_item.main_content.comment_reject') }}</div>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <textarea
                  v-model="cancelMsg"
                  class="form-control fixed-width-textarea"
                  rows="6"
                  :placeholder="$t('trip_item.modal_reject.msg_placeholder')"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="row">
            <div class="col-xs-6 col-sm-6 col-md-6">
              <button @click="closeModal()" class="btn btn-sm btn-flat-default btn-block">
                {{ $t('common.cancel') }}
              </button>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6">
              <button
                @click="submitModal()"
                class="btn btn-sm btn-default btn-block open-alert"
                id="btn-do-reject"
              >
                {{ nameButton }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>

export default {
  name: 'ModalCancel',
  emits: ['submitCancelModal', 'closeModal'],
  props: {
    nameBtn: {
      type: String,
      default: '稟議取下',
    },
    isModalVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isVisible: false,
      nameButton: '稟議取下',
      cancelMsg: ''
    };
  },
  watch: {
    isModalVisible(newVal) {
      this.isVisible = newVal;
      this.nameButton = this.nameBtn;
      if (newVal) {
        this.defaultStepBack = '';
        this.stepBack = -1;
      }
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
    submitModal() {
      this.$emit('submitCancelModal', this.cancelMsg);
      this.cancelMsg = '';
    },
  },
};
</script>
<style scoped></style>
