<template>
  <el-dialog
    v-model="dialogVisibleStatus"
    :append-to-body="true"
    :show-close="false"
    @close="$emit('cancel')"
    width="640px"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <div class="modal--2fa">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <div class="modal-header--title"></div>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <p class="modal--diaglog--text text-center" v-html="title" />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="row">
              <div class="col-xs-6 col-sm-6 col-md-6">
                <button class="btn btn-sm btn-flat-default btn-block" @click="$emit('cancel')">
                  {{ $t('common.cancel') }}
                </button>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-6">
                <button class="btn btn-sm btn-block open-alert" :class="classCustom" @click="$emit('yes')">
                  {{ labelButtonConfirm || $t('common.yes') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { ElDialog } from 'element-plus';
export default {
  components: {
    ElDialog,
  },
  name: 'ModalConfirm',
  emits: ['yes', 'cancel'],
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    classCustom: {
      type: String,
      default: 'btn-flat-danger',
    },
    labelButtonConfirm: {
      type: String,
      default: '',
    },
  },
  watch: {
    dialogVisible() {
      this.dialogVisibleStatus = this.dialogVisible;
    },
  },
  data() {
    return {
      dialogVisibleStatus: false,
    };
  },
};
</script>
