<template>
  <nav class="navbar navbar-default navbar-lg" role="navigation" id="header-content">
    <div class="navbar-header">
      <h2 class="navbar-title">{{ $t('sidebar.expense_item') }}</h2>
    </div>
  </nav>
  <div class="wrapper with-navbar-fixed-top with-navbar-fixed-bottom">
    <div class="container-fluid">
      <div class="section noborder ptn" id="section--main">
        <div class="mbl">
          <p>{{ $t('expense.item.text_note') }}</p>
        </div>

        <ExpenseForm
          :dataForm="dataRequest"
          :actionSubmitForm="actionSubmitForm"
          :errors="errors"
          @submit="submitForm"
          @onChangeData="checkOnChangeData"
        />
      </div>
    </div>
    <div
      class="navbar navbar-default navbar-fixed-bottom navbar-footblock with-sidebar submit-new--wrap show--estimate"
      style="z-index: 990; padding: 12px"
    >
      <div class="row">
        <div class="col-sm-3"></div>
        <div class="col-sm-4 col-md-3 col-sm-offset-5 col-md-offset-6">
          <a
            href="#"
            class="btn btn-sm btn-block btn-primary open-alert"
            @click="this.actionSubmitForm = !this.actionSubmitForm"
          >
            {{ $t('common.submit') }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ExpenseForm from '@/components/expense/ExpenseForm';
export default {
  components: {
    ExpenseForm,
  },
  data() {
    return {
      flgCreate: false,
      actionSubmitForm: false,
      errors: {},
      dataRequest: {
        type: 2,
        cost_code: '',
        name: '',
        display_order: '',
        note: '',
        parent_cost_items: '',
        items_code: '',
        sub_subject_code: '',
        tax_type: 3,
        is_valid: '',
      },
      isChangeData: false,
    };
  },
  beforeRouteLeave(to, from, next) {
    if (this.isChangeData) {
      const answer = window.confirm(this.$t('common.confirm_leave_page'));
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  methods: {
    checkOnChangeData(val) {
      this.isChangeData = val;
    },
    submitForm(val) {
      this.isChangeData = false;
      const data = { ...val, cost_items_type: val.type };
      if (val.type == 1) delete data.parent_cost_item_id;

      this.startLoading();
      this.$services.cost.createCostItem(
        data,
        () => {
          // this.endLoading();
          this.notifySuccess(this.$t('I-CM-103'));
          this.$router.push(this.PAGES.EXPENSE_ITEM);
        },
        err => {
          this.endLoading();
          if (err?.error) {
            this.errors = {
              code: err.error?.code ? err.error?.code[0] : '',
              name: err.error?.name ? err.error?.name[0] : '',
              subject_code: err.error?.subject_code ? err.error?.subject_code[0] : '',
              import_code: err.error?.import_code ? err.error?.import_code[0] : '',
              time: new Date().getTime(),
            };
          } else {
            this.errors = {};
            this.notifyError(this.$t(err.error));
          }
        },
      );
    },
  },
};
</script>
