export const SEX_TYPE = [
  {
    type: 1,
    en_name: 'Male',
    ja_name: '男性',
  },
  {
    type: 2,
    en_name: 'Female',
    ja_name: '女性',
  },
  {
    type: 3,
    en_name: 'Other',
    ja_name: 'その他',
  },
];
