<template>
  <div v-show="isShow" class="section-submit js-section-submit">
    <span class="submit-arrow"></span>
    <div class="row">
      <div class="col-sm-4 col-md-5 hidden-xs">
        <button class="btn btn-sm btn-flat-default btn-clear" @click.prevent="onHandleClearCondition">
          {{ $t('common.clear') }}
        </button>
      </div>
      <div class="col-sm-4 col-md-2">
        <button
          class="btn btn-sm btn-primary btn-block js-btn-filter--minimization"
          @click.prevent="onHandleSubmitFilter"
        >
          {{ $t('common.search') }}
        </button>
      </div>
      <div class="col-sm-4 col-md-5 hidden-xs">
        <button class="btn btn-sm btn-flat-default btn-close" @click="emitCloseSearchBox()">
          {{ $t('common.close') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ButtonSubmit',
  emits: ['listenCloseSearchBox'],
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onHandleClearCondition() {
      this.$emit('onHandleClearCondition');
    },
    onHandleSubmitFilter() {
      this.$emit('onHandleSubmitFilter');
    },
    emitCloseSearchBox() {
      this.$emit('listenCloseSearchBox');
    },
  },
};
</script>
