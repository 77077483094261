<template>
  <el-dialog
    v-model="dialogVisibleStatus"
    :show-close="false"
    @close="onCloseModal"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    width="640px"
  >
    <div class="modal-dialog">
      <div class="modal-content" @click="onClickOutSide">
        <div class="modal-header">
          <div class="modal-header--title">{{ $t('sidebar.daily_allowance') }}</div>
          <div style="margin-left: auto">
            <div class="reference">
              <a href="#" rel="noopener" @click="openFileOnNewTab">
                {{ $t('trip.daily_allowance_regulation') }}
                <i class="icon-abui-outside plx" />
              </a>
            </div>
          </div>
        </div>
        <div class="modal-body">
          <p>{{ $t('trip.note_update_daily_allowance_regulation') }}</p>
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <div
                  class="btn-group bootstrap-select select-block select-sm"
                  id="dropdown--allowance-stay"
                  style="z-index: 999"
                >
                  <button
                    type="button"
                    class="btn dropdown-toggle btn-input"
                    data-toggle="dropdown"
                    role="button"
                    title="項目1"
                    aria-expanded="false"
                    @click="onChangeAmount"
                    v-on:click.stop
                    :disabled="isViewOnly || isRequestPage"
                  >
                    <span class="filter-option pull-left">
                      <span class="text">{{ currentDailyAllowance?.daily_allowance_type }}</span>
                      <span style="margin-left: auto; position: absolute; right: 24px">
                        {{ this.splitThousandYen(currentDailyAllowance?.amount) }}
                      </span>
                    </span>
                    &nbsp;
                    <span class="bs-caret">
                      <span class="caret" :class="isSelectAmount ? 'active' : ''"> </span>
                    </span>
                  </button>
                  <div v-if="isSelectAmount" class="dropdown-menu menu-parent" role="combobox">
                    <ul class="dropdown-menu inner" role="listbox" aria-expanded="false">
                      <li
                        v-for="(item, index) in listDailyAllowance"
                        :key="index"
                        :data-original-index="item.id"
                        @click="checkEmpty(item.type) ? onSelectAmount(item) : () => {}"
                        v-on:click.stop
                        :class="
                          item.amount == currentDailyAllowance.amount &&
                          item.id == currentDailyAllowance.id &&
                          item.type_daily == currentDailyAllowance.type_daily &&
                          item.daily_allowance_type == currentDailyAllowance.daily_allowance_type
                            ? checkEmpty(item.type)
                              ? 'selected'
                              : 'selected parent'
                            : checkEmpty(item.type)
                            ? ''
                            : 'parent'
                        "
                      >
                        <a :tabindex="item.id" class="">
                          <span class="text">{{ item.daily_allowance_type }}</span>
                          <span
                            v-if="checkEmpty(item.type)"
                            span
                            style="margin-left: auto; position: absolute; right: 12px"
                          >
                            {{ this.splitThousandYen(item.amount) }}
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div v-else></div>
                </div>
                <p
                  role="alert"
                  style="font-size: 11px !important"
                  class="error-feedback"
                  v-text="errors.daily_allowance"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label>
                  <span class="label-text">{{ $t('trip.accommodation_fee') }}</span>
                </label>
                <div
                  class="btn-group bootstrap-select select-block select-sm"
                  id="dropdown--allowance-stay"
                  style="z-index: 100"
                >
                  <button
                    type="button"
                    class="btn dropdown-toggle btn-input"
                    data-toggle="dropdown"
                    role="button"
                    title="項目1"
                    aria-expanded="false"
                    @click="onChangeFee"
                    v-on:click.stop
                    :disabled="isViewOnly || isRequestPage"
                  >
                    <span class="filter-option pull-left">
                      <span class="text">
                        {{ labelHotel }}
                      </span>
                      <span style="margin-left: auto; position: absolute; right: 24px">
                        {{
                          !checkEmpty(currentDailyAllowanceHotel)
                            ? this.splitThousandYen(currentDailyAllowanceHotel.amount)
                            : ''
                        }}
                      </span>
                    </span>
                    &nbsp;
                    <span class="bs-caret">
                      <span class="caret" :class="isSelectFee ? 'active' : ''"> </span>
                    </span>
                  </button>
                  <div v-if="isSelectFee" class="dropdown-menu menu-parent" role="combobox">
                    <ul class="dropdown-menu inner" role="listbox" aria-expanded="false">
                      <li
                        v-for="(item, index) in listDailyAllowanceHotel"
                        :key="index"
                        :data-original-index="item.id"
                        @click="onSelectFee(item)"
                        v-on:click.stop
                        :class="
                          !checkEmpty(currentDailyAllowanceHotel) &&
                          item.amount == currentDailyAllowanceHotel.amount &&
                          item.id == currentDailyAllowanceHotel.id
                            ? 'selected'
                            : ''
                        "
                      >
                        <a :tabindex="item.id" class="">
                          <span class="text">{{ labelLocale(item) }}</span>
                          <span style="margin-left: auto; position: absolute; right: 12px">
                            {{ this.splitThousandYen(item.amount) }}
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div v-else></div>
                </div>
                <!-- <p role="alert" class="error-feedback" v-text="errors.daily_allowance_hotel" /> -->
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-7 col-all-closer col-all-closer--leftend">
              <div class="form-group">
                <div class="input-group">
                  <span class="input-group-addon" style="padding-right: 24px">
                    {{ $t('trip.addition_subtraction_adjustment_amount') }}
                  </span>
                  <el-input
                    :disabled="isViewOnly || isRequestPage"
                    type="text"
                    maxlength="50"
                    :placeholder="$t('trip.description')"
                    v-model="currentNote"
                  />
                </div>
                <p role="alert" style="padding-left: 114px; font-size: 11px !important" v-text="errors.note" />
              </div>
            </div>
            <div class="col-sm-5 col-all-closer col-all-closer--rightend">
              <div class="form-group">
                <div class="input-group adjust-amount">
                  <span class="input-group-addon pls">{{ $t('common.money') }}</span>
                  <el-input
                    name="display_order"
                    type="text"
                    style=""
                    maxlength="14"
                    v-model="currentAdjustAmount"
                    onkeydown="javascript: return event.keyCode !== 69;"
                    @input="currentAdjustAmount = convertNumberFullToHalf(currentAdjustAmount)"
                    :disabled="isViewOnly || isRequestPage"
                    @change="checkValidData"
                  />
                  <p
                    role="alert"
                    style="font-size: 11px !important"
                    class="error-feedback"
                    v-text="errors.extra_amount"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="text-right prm" style="display: flex; justify-content: between">
            <button
              class="btn btn-sm btn-flat-default btn-block mbl"
              style="margin-right: auto; width: 80px"
              @click="clearDailyAllowances()"
            >
              {{ $t('daily_allowance.clear') }}
            </button>
            <span style="margin-left: auto; font-weight: 700">{{ this.splitThousandYen(total) }}</span>
          </div>
        </div>
        <div class="modal-footer">
          <div class="row">
            <div class="col-xs-6 col-sm-6 col-md-6">
              <button class="btn btn-sm btn-flat-default btn-block" @click="onCloseModal" v-on:click.stop>
                {{ $t('common.close') }}
              </button>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6">
              <button
                :disabled="isViewOnly || trip?.user_id != userId || isRequestPage"
                class="btn btn-sm btn-primary btn-block open-alert"
                @click="onSubmit"
                v-on:click.stop
              >
                {{ $t('common.submit') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { ElDialog } from 'element-plus';
import moment from 'moment';

export default {
  components: {
    ElDialog,
  },
  name: 'ModalUpdateDailyAllowance',
  emits: ['yes', 'cancel'],
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    date: {
      type: String,
      default: '',
    },
    isCreate: {
      type: Boolean,
      default: false,
    },
    files: {
      type: Array,
      default: () => [],
    },
    trip: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    dialogVisible(newVal) {
      this.resetForm();
      if (!newVal) {
        this.dialogVisibleStatus = newVal;
      } else {
        this.startLoading();
        Promise.all([this.initDataDailyAllowance()]).then(() => {
          this.endLoading();
          this.dialogVisibleStatus = true;
        });
      }

      this.errors.daily_allowance = '';
      this.errors.daily_allowance_hotel = '';
      this.errors.note = '';
      this.errors.extra_amount = '';
    },
    date() {},
  },
  computed: {
    isShowSettingApproval() {
      return this.$store.state.auth?.user?.setting.is_show_approval || false;
    },
    isShowSettingReport() {
      return this.$store.state.auth?.user?.setting.is_show_report || false;
    },
    showApprovalExpense() {
      return this.$store.state.auth?.user?.setting?.is_feature_payment || false;
    },
    total() {
      let newVal = this.currentAdjustAmount.replaceAll(',', '');
      const val = !isNaN(parseInt(newVal)) ? parseInt(newVal) : 0;
      const dailyAmount =
        !this.checkEmpty(this.currentDailyAllowance) && !isNaN(parseInt(this.currentDailyAllowance.amount))
          ? parseInt(this.currentDailyAllowance.amount)
          : 0;
      const dailyHotelAmount =
        !this.checkEmpty(this.currentDailyAllowanceHotel) && !isNaN(parseInt(this.currentDailyAllowanceHotel.amount))
          ? parseInt(this.currentDailyAllowanceHotel.amount)
          : 0;

      return dailyAmount + dailyHotelAmount + val;
    },
    tripId() {
      return parseInt(this.$route.params.id);
    },
    isRequestPage() {
      return window.location.pathname.includes("/request/");
    },
    tripCode() {
      if (!this.checkEmpty(this.tripData?.expense_settlement?.code)) {
        return this.tripData?.expense_settlement?.code;
      }

      if (!this.checkEmpty(this.tripData?.report_status?.code)) {
        return this.tripData?.report_status?.code;
      }

      return this.tripData?.approval_status?.code || false;
    },
    isViewOnly() {
      return [
        'report_approved',
        'report_cancel',
        'expense_waiting',
        'expense_pending',
        'expense_rejected',
        'expense_approved',
      ].includes(this.tripCode) || (this.tripData?.trip_status === 3 && (this.isShowSettingApproval ||
          ['expense_waiting', 'expense_pending', 'expense_approved'].includes(this.tripData?.expense_settlement?.code)))
      ||
      (this.isTripStartTraveling() && this.isShowSettingApproval && !this.isShowSettingReport  && !this.showApprovalExpense);
    },
    labelHotel() {
      if (
        this.checkEmpty(this.listDailyAllowance) ||
        this.checkEmpty(this.currentDailyAllowanceHotel?.id) ||
        !this.currentDailyAllowanceHotel.id
      )
        return '';
      const hotel = this.listDailyAllowanceHotel.find(item => item.id === this.currentDailyAllowanceHotel?.id);
      return hotel ? this.labelLocale(hotel) : '';
    },
  },
  data() {
    return {
      dialogVisibleStatus: false,
      dailyAllowance: {},
      dailyAllowanceHotel: {},
      listDailyAllowance: [],
      listDailyAllowanceHotel: [],
      currentDailyAllowance: {},
      currentDailyAllowanceHotel: {},
      currentAdjustAmount: '',
      isSelectAmount: false,
      isSelectFee: false,
      currentNote: '',
      currentTypeDaily: null,
      errors: {
        daily_allowance: '',
        daily_allowance_hotel: '',
        note: '',
        extra_amount: '',
      },
      tripData: {},
    };
  },
  mounted() {
    this.$emitter.on('checkShowButtonSettlementOfExpenses', data => {
      this.tripData = data;
    });
  },
  methods: {
    async initDataDailyAllowance() {
      await this.getDailyAllowanceSchedule();
      if (!this.isCreate) {
        await this.getDetailDailyAllowance();
      }
      await this.getDailyAllowanceHotel();
    },
    openFileOnNewTab() {
      // for (let i = 0; i < this.files.length; i++) {
      if (this.files.length > 0 && this.files[this.files.length - 1]?.link)
        window.open(this.files[this.files.length - 1].link, '_blank');
      // }
    },
    clearDailyAllowances() {
      this.currentDailyAllowance = {};
      this.currentDailyAllowanceHotel = {};
      this.currentNote = '';
      this.currentAdjustAmount = '';
      this.currentTypeDaily = null;
    },
    convertNumberFullToHalf(number) {
      number = number.replace(/[^0-9０-９-－]/g, '');
      number = String(number).replace(/[\uFF10-\uFF19]/g, function (m) {
        return String.fromCharCode(m.charCodeAt(0) - 0xfee0);
      });

      return number;
    },
    checkValidData() {
      if (String(this.currentAdjustAmount).includes('－')) {
        this.currentAdjustAmount = this.currentAdjustAmount.replaceAll('－', '-');
      }
      if (
        !this.checkEmpty(this.currentAdjustAmount) &&
        !this.isNumeric(this.currentAdjustAmount.substring(0).replaceAll(',', ''))
      ) {
        this.errors.extra_amount = this.$t('E-CM-047', {
          item: this.$t('trip.addition_subtraction_adjustment_amount'),
        });
      } else {
        this.errors.extra_amount = '';
        this.currentAdjustAmount = this.currentAdjustAmount.replaceAll(',', '');
        this.currentAdjustAmount = this.currentAdjustAmount
          .replace(/(\..*?)\..*/g, '$1')
          .replace(/^(?!00[^0])0/, '')
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    },
    async getDailyAllowanceSchedule() {
      await this.$services.allowance.getDailyAllowanceSchedule(
        res => {
          if (res.all_day.length > 0) {
            let parent = {
              type: 'all',
              daily_allowance_type: this.$i18n.locale == 'en' ? 'All day' : '全日',
              amount: 0,
            };
            this.listDailyAllowance.push(parent);
            res.all_day.forEach(item => {
              if (!this.checkEmpty(item.amount)) {
                this.listDailyAllowance.push(item);
              }
            });
          }
          if (res.half_day.length > 0) {
            let parent = {
              type: 'half',
              daily_allowance_type: this.$i18n.locale == 'en' ? 'Half day' : '半日',
              amount: 0,
            };
            this.listDailyAllowance.push(parent);
            res.half_day.forEach(item => {
              if (!this.checkEmpty(item.amount)) {
                this.listDailyAllowance.push(item);
              }
            });
          }
        },
        err => {
          this.endLoading();
          this.notifyError(this.$t(err.error));
        },
      );
    },
    async getDetailDailyAllowance() {
      await this.$services.allowance.getDetailDailyAllowance(
        {
          trip_id: this.isRequestPage ?  this.trip?.id : this.tripId,
          date: this.date,
        },
        res => {
          if (res.daily_allowance.all_day != null) {
            this.currentDailyAllowance = res.daily_allowance.all_day;
            this.currentTypeDaily = res.daily_allowance.all_day.type_daily;
          } else {
            this.currentDailyAllowance = res.daily_allowance.half_day;
            this.currentTypeDaily = res.daily_allowance.half_day.type_daily;
          }
          this.currentDailyAllowanceHotel = res.daily_allowance_hotel;
          this.currentAdjustAmount = String(res.amount).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
          this.currentNote = res.note;
        },
        err => {
          this.endLoading();
          this.notifyError(this.$t(err.error));
        },
      );
    },
    async getDailyAllowanceHotel() {
      await this.$services.allowance.getDailyAllowanceHotel(
        res => {
          this.listDailyAllowanceHotel = res.list;
        },
        err => {
          this.endLoading();
          this.notifyError(this.$t(err.error));
        },
      );
    },
    onChangeAmount() {
      this.isSelectFee = false;
      this.isSelectAmount = !this.isSelectAmount;
    },
    onChangeFee() {
      this.isSelectAmount = false;
      this.isSelectFee = !this.isSelectFee;
    },
    onCloseModal() {
      this.isSelectAmount = false;
      this.isSelectFee = false;
      this.$emit('cancel');
    },
    onClickOutSide() {
      this.isSelectAmount = false;
      this.isSelectFee = false;
    },
    onSelectFee(item) {
      this.isSelectFee = false;
      this.currentDailyAllowanceHotel = {
        ...this.currentDailyAllowanceHotel,
        id: item.id,
        en_name: item.en_name,
        ja_name: item.ja_name,
        amount: item.amount,
      };
    },
    onSelectAmount(item) {
      this.isSelectAmount = false;
      this.currentDailyAllowance.daily_allowance_type = item.daily_allowance_type;
      this.currentDailyAllowance.amount = item.amount;
      this.currentDailyAllowance.id = item.id;
      this.currentTypeDaily = item.type_daily;
    },
    onSubmit() {
      let flgValidate = true;

      if (this.strLen(this.currentNote) > 50) {
        flgValidate = false;
        this.errors.note = this.$t('E-CM-111', { size: 50 });
      } else {
        this.errors.note = '';
      }

      if (
        (!this.isNumeric(parseInt(this.currentAdjustAmount)) ||
          !this.isNumeric(this.currentAdjustAmount.substring(0).replaceAll(',', ''))) &&
        !this.checkEmpty(this.currentAdjustAmount)
      ) {
        flgValidate = false;
        this.errors.extra_amount = this.$t('E-CM-047', {
          item: this.$t('trip.addition_subtraction_adjustment_amount'),
        });
      } else {
        this.errors.extra_amount = '';
      }

      if (!flgValidate || this.isRequestPage) return;

      const data = {
        trip_id: this.tripId,
        date: this.date,
        extra_note: this.currentNote,
        extra_amount: !this.checkEmpty(this.currentAdjustAmount)
          ? parseInt(this.currentAdjustAmount.replaceAll(',', ''))
          : 0,

        allowance_hotel_id: this.currentDailyAllowanceHotel?.id || '',
        type_daily: this.currentTypeDaily,
        daily_allowance_id: this.currentDailyAllowance?.id || null,
      };

      if (!data.allowance_hotel_id) {
        delete data.allowance_hotel_id;
      }
      this.$emit('yes', data);
    },
    resetForm() {
      this.currentDailyAllowance = {};
      this.currentDailyAllowanceHotel = {};
      this.listDailyAllowance = [];
      this.listDailyAllowanceHotel = [];
      this.dailyAllowance = {};
      this.dailyAllowanceHotel = {};
      this.currentAdjustAmount = '';
      this.isSelectAmount = false;
      this.isSelectFee = false;
      this.currentNote = '';
      this.currentTypeDaily = null;
    },
    isTripStartTraveling() {
      if (!this.checkEmpty(this.tripData.start_time) && this.tripData?.approval_status_id === 6) {
        if (
            moment(new Date()).format('YYYY-MM-DD') >= moment(this.tripData.start_time).format('YYYY-MM-DD')
        ) {
          return true;
        }
      }
      return false;
    }
  },
};
</script>
<style scoped>
.caret.active {
  border-top-color: #3650a5;
  border-bottom-color: #3650a5;
}
.modal-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dropdown-menu.menu-parent {
  max-height: 533.812px;
  overflow: hidden;
  min-height: 0px;
  visibility: visible;
  opacity: 1;
  margin: 4px 0px !important;
}
.dropdown-menu.inner {
  max-height: 533.812px;
  overflow-y: auto;
  min-height: 0px;
  visibility: visible;
  opacity: 1;
}
.input-group .pls {
  padding-bottom: 24px !important;
}
.parent a {
  font-weight: 700;
  color: #3650a5 !important;
}
</style>
<style>
.el-select-dropdown__item .item-val {
  position: absolute;
  right: 12px;
}
</style>
