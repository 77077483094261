<template>
  <el-dialog
    v-model="dialogVisibleStatus"
    @close="closeModal()"
    width="736px"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <button type="button" class="close" @click="closeModal()">
          <span class="icon-abui-cross" aria-hidden="true"></span>
        </button>
        <div class="modal-header">
          <div class="modal-header--title">{{ $t('trip.sb_list_title') }}</div>
        </div>
        <div class="modal-body">
          <div class="section">
            <div class="add-selected">
              <div class="row">
                <div class="col-sm-12 col-md-12">
                  <div class="js-refine-search">
                    <table class="table table-striped table-word-seach">
                      <thead>
                        <tr>
                          <th>
                            <div class="input-group input-group-sm input-group-rounded" :class="{ focus: hasFocus }">
                              <span class="input-group-btn">
                                <button type="submit" class="btn btn-sm"><span class="icon-abui-search"></span></button>
                              </span>
                              <input
                                type="text"
                                v-model="searchKeyword"
                                class="form-control js-refine-search__input input-sm"
                                :placeholder="$t('trip.sb_search_place')"
                                @focus="onFocus"
                                @blur="onBlur"
                              />
                            </div>
                          </th>
                        </tr>
                      </thead>
                    </table>
                    <div style="overflow: auto">
                      <table class="table table-striped table-hover table-word-seach__body table-hover--pointer">
                        <tbody v-if="!checkEmpty(listFilter)">
                          <tr
                            v-for="item in listFilter"
                            :key="item.id"
                            class="js-refine-search__item"
                            @click="chooseItem(item)"
                          >
                            <td class="js-refine-search__text" style="min-width: 65px">{{ item.itineraryId }}</td>
                            <td class="js-refine-search__text">{{ item.travelName }}</td>
                            <td>
                              {{ showDateTime(item?.startDate, 'YYYY/MM/DD（ddd）') }} -
                              {{ showDateTime(item?.endDate, 'YYYY/MM/DD（ddd）') }}
                            </td>
                            <td class="js-refine-search__text">{{ item.destination }}</td>
                          </tr>
                        </tbody>
                        <tbody v-else>
                          <tr>
                            <p class="no-data">{{ $t('common.no_data') }}</p>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<style></style>
<script>
import { ElDialog } from 'element-plus';

export default {
  components: {
    ElDialog,
  },
  name: 'ModalListTripSB',
  emits: ['success', 'cancel'],
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    listTripSB: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    dialogVisible() {
      this.dialogVisibleStatus = this.dialogVisible;
    },
  },
  data() {
    return {
      dialogVisibleStatus: false,
      searchKeyword: '',
      hasFocus: false,
    };
  },
  computed: {
    listFilter() {
      return this.listTripSB.filter(item => {
        if (this.searchKeyword.trim() == '') return item;
        const keySearch = String(this.searchKeyword);

        return (
          String(item.itineraryId).toLowerCase().indexOf(keySearch.toLowerCase()) > -1 ||
          (item?.travelName && item?.travelName.toLowerCase().indexOf(keySearch.toLowerCase()) > -1) ||
          (item?.destination && item?.destination.toLowerCase().indexOf(keySearch.toLowerCase()) > -1)
        );
      });
    },
  },
  methods: {
    onFocus() {
      this.hasFocus = true;
    },
    onBlur() {
      this.hasFocus = false;
    },
    closeModal() {
      this.$emit('cancel');
    },
    chooseItem(item) {
      this.$emit('success', item);
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-show-again-search {
  height: 80px;
  margin-bottom: 16px;
  background: #e7ebec;
  margin-right: -32px;
  margin-left: -32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    border-color: #dfe3f1;
    background-color: #dfe3f1;
  }

  span {
    color: #3650a5;
  }
}
</style>
