<template>
  <nav class="navbar navbar-default navbar-lg" role="navigation" id="header-content">
    <div class="navbar-header">
      <h2 class="navbar-title">{{ $t('organization.organization') }}</h2>
    </div>
  </nav>

  <div class="wrapper with-navbar-fixed-top with-navbar-fixed-bottom">
    <div class="container-fluid">
      <div class="section noborder ptn" id="section--main">
        <div class="mbl">
          <p>
            {{ $t('organization.organization_desc') }}
          </p>
        </div>

        <div class="">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group" style="margin-top: 6px; margin-bottom: 23px">
                <label
                  @click="changeType(1)"
                  class="radio radio-inline js-radio_item"
                  style="margin-right: 21px !important"
                  :class="checkClass(1)"
                >
                  <span class="icons">
                    <span class="first-icon icon-abui-radio-unchecked"></span>
                    <span class="second-icon icon-abui-radio-checked"></span>
                  </span>
                  <span class="text_type-special">{{ $t('organization.parent_organization') }}</span>
                </label>
                <label @click="changeType(2)" class="radio radio-inline js-radio_item" :class="checkClass(2)">
                  <span class="icons">
                    <span class="first-icon icon-abui-radio-unchecked"></span>
                    <span class="second-icon icon-abui-radio-checked"></span>
                  </span>
                  <span class="text_type-special">{{ $t('organization.c-burden_organization') }}</span>
                </label>
              </div>
            </div>
          </div>
          <div v-if="organizations.type == 1" class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label>
                  <span class="label-text">{{ $t('organization.c-parent-organization') }}</span>
                </label>
                <SingleOptionSelect
                  ref="TripOrganizationSelect"
                  :list-data="listParentOrganizations"
                  @handle-selected-params="listenParentOrganizations"
                  :defaultList="organizations.parent_organization_id"
                  :class="!checkEmpty(errors.parent_organization) && 'has-validation-error-el'"
                />
                <p role="alert" class="error-feedback" v-text="errors.parent_organization" />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-3">
              <div class="form-group">
                <label>
                  <span class="label-text">{{ $t('organization.c-code') }}</span>
                </label>
                <input
                  maxlength="30"
                  :placeholder="$t('common.required')"
                  type="text"
                  class="form-control input-sm"
                  v-model="organizations.code"
                  :class="!checkEmpty(errors.code) && 'has-validation-error'"
                  id=""
                />
                <p role="alert" class="error-feedback" v-text="errors.code" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label>
                  <span class="label-text">{{ $t('organization.organization_name') }}</span>
                </label>
                <input
                  maxlength="50"
                  :placeholder="$t('common.required')"
                  type="text"
                  class="form-control input-sm"
                  v-model="organizations.name"
                  :class="!checkEmpty(errors.name) && 'has-validation-error'"
                  id=""
                />
                <p role="alert" class="error-feedback" v-text="errors.name" />
              </div>
            </div>
          </div>

          <div class="row js-check_item01-01__tgt" v-if="organizations.type == 1">
            <div class="col-sm-6">
              <div class="form-group">
                <label>
                  <span class="label-text">{{ $t('organization.c-burden_organization') }}</span>
                </label>
                <SingleOptionSelect
                  ref="TripOrganizationSelect"
                  :list-data="listBurdenOrganizations"
                  @handle-selected-params="listenBurdenOrganizations"
                  :defaultList="organizations.burden_organization_id"
                  :class="!checkEmpty(errors.burden_organization) && 'has-validation-error-el'"
                />
                <p role="alert" class="error-feedback" v-text="errors.burden_organization" />
              </div>
            </div>
          </div>

          <hr />

          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label>
                  <span class="label-text">{{ $t('organization.note_1') }}</span>
                </label>
                <textarea v-model="organizations.note1" class="form-control" rows="2"></textarea>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label>
                  <span class="label-text">{{ $t('organization.note_2') }}</span>
                </label>
                <textarea v-model="organizations.note2" class="form-control" rows="2"></textarea>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label>
                  <span class="label-text">{{ $t('organization.note_3') }}</span>
                </label>
                <textarea v-model="organizations.note3" class="form-control" rows="2"></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="wrapper with-navbar-fixed-top with-navbar-fixed-bottom" v-if="!isDeleted">
      <div
        class="navbar navbar-default navbar-fixed-bottom navbar-footblock with-sidebar submit-new--wrap show--estimate"
        id=""
        role="navigation"
        style="z-index: 990; padding: 12px"
      >
        <div class="row">
          <div class="col-sm-3">
            <button @click="showModalConfirm = true" v-if="organizationId" class="btn btn-sm btn-flat-default">
              <i class="icon-abui-trash prx"></i>{{ $t('organization.delete_organization') }}
            </button>
          </div>
          <div class="col-sm-4 col-md-3 col-sm-offset-5 col-md-offset-6">
            <a href="#" @click="submitData()" class="btn btn-sm btn-block btn-primary open-alert" data-tgt="al1" id="">
              {{ $t('organization.keep') }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ModalConfirm
    :dialogVisible="showModalConfirm"
    @cancel="showModalConfirm = false"
    @yes="deleteOrganization"
    :title="$t('Q-CM-104')"
  />
</template>
<script>
import SingleOptionSelect from '@/components/common/SingleOptionSelect.vue';
import ModalConfirm from '@/components/common/ModalConfirm';
export default {
  name: 'Organization',
  components: { SingleOptionSelect, ModalConfirm },
  data() {
    return {
      isDeleted: false,
      showModalConfirm: false,
      listParentOrganizations: [],
      listBurdenOrganizations: [],
      organizations: {
        code: '',
        name: '',
        note1: '',
        note2: '',
        note3: '',
        type: 2,
        parent_organization_id: '',
        burden_organization_id: '',
        updated_at: '',
      },
      organizationsOfficial: {
        code: '',
        name: '',
        note1: '',
        note2: '',
        note3: '',
        type: 2,
        parent_organization_id: '',
        burden_organization_id: '',
        updated_at: '',
      },
      errors: {
        name: '',
        code: '',
        parent_organization: '',
        burden_organization: '',
      },
      isSubmit: false,
      isChangeData: false,
    };
  },
  beforeRouteLeave(to, from, next) {
    if (this.isChangeData) {
      const answer = window.confirm(this.$t('common.confirm_leave_page'));
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  watch: {
    organizations: {
      handler: function () {
        this.onCheckChangData();
      },
      deep: true,
    },
    isSubmit: {
      handler: function () {
        this.onCheckChangData();
      },
      deep: true,
    },
  },
  computed: {
    organizationId() {
      return this.$route.params.id;
    },
  },
  created() {
    this.startLoading();
    Promise.all([this.getListParentOrganizations(), this.getBurdenOrganizations()]).then(() => {
      this.getDetailOrganization();
      this.endLoading();
    });
  },
  methods: {
    checkClass(type) {
      let result = '';
      if (this.organizationId) result = 'disabled ';

      if (this.organizations.type == type) result += ' checked';

      return result;
    },
    changeType(type) {
      if (!this.organizations?.id && type) this.organizations.type = type;
      this.onCheckChangData();
    },
    onCheckChangData() {
      this.isChangeData = !this.compareObject(this.organizations, this.organizationsOfficial) && !this.isSubmit;
    },
    deleteOrganization() {
      this.isSubmit = true;
      this.startLoading();
      this.$services.organization.deleteOrganization(
        this.organizationId,
        () => {
          // this.endLoading();
          this.showModalConfirm = false;
          this.notifySuccess(this.$t('I-CM-102'));
          this.$router.push(this.PAGES.ORGANIZATION);
        },
        err => {
          this.endLoading();
          this.showModalConfirm = false;
          this.notifyError(this.$t(err.error));
        },
      );
    },
    async getDetailOrganization() {
      if (!this.organizationId) return;
      await this.$services.organization.detailOrganization(
        this.organizationId,
        res => {
          this.organizations = res;
          if (!this.checkEmpty(res.deleted_at)) {
            this.isDeleted = true;
          }
          this.organizationsOfficial = this.cloneObject(res);

          this.listParentOrganizations = this.listParentOrganizations.filter(
            x => !this.organizations.children_ids.includes(x.id),
          );
          const curOrganizationIndex = this.listParentOrganizations.findIndex(e => e.id == this.organizationId);
          if (curOrganizationIndex != -1) {
            this.listParentOrganizations.splice(curOrganizationIndex, 1);
          }

          if (this.organizations.burden_organization_id) {
            this.organizations.is_burden = false;
          } else {
            this.organizations.is_burden = true;
          }

          if (!this.organizations.parent_organization_id) {
            this.organizations.parent_organization_id = '';
          }
          if (this.organizationsOfficial.burden_organization_id) {
            this.organizationsOfficial.is_burden = false;
          } else {
            this.organizationsOfficial.is_burden = true;
          }

          if (!this.organizationsOfficial.parent_organization_id) {
            this.organizationsOfficial.parent_organization_id = '';
          }
        },
        err => {
          this.notifyError(this.$t(err.error));
          this.$router.push(this.PAGES.ORGANIZATION);
        },
      );
    },
    async getListParentOrganizations() {
      await this.$services.organization.getListParentOrganizations(
        { no_code: true, only_parent: true },
        res => {
          const data = res.list.filter(x => {
            if (x.name && x.name.split('>').length <= 2) return x;
          });
          this.listParentOrganizations = data;
          this.listParentOrganizations.unshift({
            id: '',
            name: this.$t('organization.parent_organization_default'),
          });
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    async getBurdenOrganizations() {
      await this.$services.organization.getBurdenOrganizations(
        { no_code: true },
        res => {
          this.listBurdenOrganizations = res.list;
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    listenParentOrganizations(selectedList) {
      this.organizations.parent_organization_id = selectedList;
    },
    listenBurdenOrganizations(selectedList) {
      this.organizations.burden_organization_id = selectedList;
    },
    updateOrganization() {
      this.isSubmit = true;
      this.startLoading();
      this.$services.organization.updateOrganization(
        { id: this.organizationId, organizations: this.organizations },
        () => {
          // this.endLoading();
          this.notifySuccess(this.$t('I-CM-103'));
          this.$router.push(this.PAGES.ORGANIZATION);
        },
        () => {
          this.endLoading();
          this.notifyError(this.$t('E-CU-068'));
        },
      );
    },
    createOrganization() {
      this.isSubmit = true;
      this.startLoading();
      this.$services.organization.createOrganization(
        this.organizations,
        () => {
          // this.endLoading();
          this.notifySuccess(this.$t('I-CM-103'));
          this.$router.push(this.PAGES.ORGANIZATION);
        },
        () => {
          this.endLoading();
          this.notifyError(this.$t('E-CU-068'));
        },
      );
    },
    submitData() {
      let flgValidate = true;
      if (this.organizations.name == '') {
        flgValidate = false;
        this.errors.name = this.$t('E-CM-100', {
          item: this.$t('organization.organization_name'),
        });
      } else {
        this.errors.name = '';
      }

      if (this.organizations.code == '') {
        flgValidate = false;
        this.errors.code = this.$t('E-CM-100', {
          item: this.$t('organization.c-code'),
        });
      } else {
        this.errors.code = '';
      }
      if (!this.checkEmpty(this.organizations.code) && this.organizations.code.length > 30) {
        flgValidate = false;
        this.errors.code = this.$t('E-CM-111', { size: 30 });
      } else {
        this.errors.code = '';
      }

      if (!this.checkEmpty(this.organizations.name) && this.organizations.name.length > 50) {
        flgValidate = false;
        this.errors.name = this.$t('E-CM-111', { size: 50 });
      } else {
        this.errors.name = '';
      }

      if (this.organizations.type == 1) {
        if (!this.organizations.burden_organization_id) {
          flgValidate = false;
          this.errors.burden_organization = this.$t('E-CM-100', {
            item: this.$t('organization.c-burden_organization'),
          });
        } else {
          this.errors.burden_organization = '';
        }
      }
      if (!flgValidate) return;

      if (this.organizationId) {
        this.updateOrganization();
      } else {
        this.createOrganization();
      }
    },
  },
};
</script>
