<template>
  <div v-show="isShow" class="filter js-filter--minimization" style="height: auto; overflow: visible">
    <div class="section section-filter noborder js-section-filter" style="visibility: visible">
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <div class="form-group">
            <label>
              <span class="label-text"> {{ $t('notification.title') }}</span>
            </label>
            <input
              v-model="title"
              type="text"
              :placeholder="$t('notification.partial_match')"
              class="form-control input-sm"
              maxlength="100"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div
          class="col-sm-6 col-md-3 col-all-closer col-all-closer--leftend col-sm-closer--rightend col-xs-closer--rightend"
        >
          <div class="form-group">
            <label>
              <span class="label-text"> {{ $t('notification.delivery_date') }}</span>
            </label>
            <div class="input-group">
              <div class="input-with-icon">
                <el-date-picker
                  type="date"
                  class="date-input"
                  format="YYYY/MM/DD"
                  value-format="YYYY-MM-DD"
                  v-model="delivery_date_from"
                  :clearable="false"
                  :placeholder="$t('common.enter_or_select')"
                  :disabled-date="pickerStartOptions"
                />
              </div>
              <span class="input-group-addon prn"> {{ $t('notification.from') }}</span>
            </div>
          </div>
        </div>
        <div
          class="col-sm-6 col-md-3 col-all-closer col-all-closer--rightend col-sm-closer--leftend col-xs-closer--leftend"
        >
          <div class="form-group">
            <label class="hidden-xs"><span class="label-text">&nbsp;</span></label>
            <div class="input-group">
              <div class="input-with-icon">
                <el-date-picker
                  type="date"
                  class="date-input"
                  format="YYYY/MM/DD"
                  value-format="YYYY-MM-DD"
                  v-model="delivery_date_to"
                  :clearable="false"
                  :placeholder="$t('common.enter_or_select')"
                  :disabled-date="pickerEndOptions"
                />
              </div>
              <span class="input-group-addon prn"> {{ $t('notification.to') }}</span>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="form-group">
            <label>
              <span class="label-text">{{ $t('notification.delivery_destination') }}</span>
            </label>
            <MultiOptionSelect
              ref="CompanyNotificationSelect"
              :listData="listCompanies"
              :displayByLocale="false"
              :default-list="listCompany"
              @handle-selected-params="listenCompanies"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 col-sm-6 col-md-6">
          <div class="form-group">
            <label>{{ $t('notification.status') }}</label>
            <div style="display: flex" class="d-flex">
              <el-checkbox
                v-model="is_waiting_for_delivery"
                class="checkbox-display"
                style="display: flex"
                size="large"
              >
                {{ $t('notification.waiting_for_delivery') }}
              </el-checkbox>
              <el-checkbox v-model="is_delivered" class="checkbox-display" style="display: flex" size="large">
                {{ $t('notification.delivered') }}
              </el-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import MultiOptionSelect from '@/components/common/MultiOptionSelect';
import mixins from '@/helpers/mixins';

export default {
  name: 'Filter',
  mixins: [mixins],
  components: { MultiOptionSelect },
  emits: ['emitSelectedCompanies'],
  props: {
    isShow: {
      type: Boolean,
      default: true,
    },
    clearFilter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      listCompanies: [],
      company_ids: this.$route.query?.company_ids ? JSON.parse(this.$route.query.company_ids) : [],
      title: this.$route.query?.title || '',
      delivery_date_from: this.$route.query?.delivery_date_from || '',
      delivery_date_to: this.$route.query?.delivery_date_to || '',
      is_waiting_for_delivery: this.$route.query?.is_waiting_for_delivery
        ? this.$route.query?.is_waiting_for_delivery == '1'
        : true,
      is_delivered: this.$route.query?.is_delivered ? this.$route.query?.is_delivered == '1' : true,
    };
  },
  created() {
    this.getListCompany();
  },
  computed: {
    listCompany() {
      let rs = [];
      if (!this.checkEmpty(this.$route.query.company_ids)) {
        let listCom = JSON.parse(this.$route.query.company_ids);
        listCom.forEach(val => {
          this.listCompanies.find(item => {
            if (item.id == val) {
              rs.push(item.id);
            }
          });
        });
      }
      return rs;
    },
  },
  watch: {
    title: function (newVal) {
      this.onHandleChangeCondition('title', newVal);
    },
    delivery_date_from: function (newVal) {
      if (this.checkEmpty(this.delivery_date_to)) this.delivery_date_to = newVal;
      this.onHandleChangeCondition('delivery_date_from', newVal);
    },
    delivery_date_to: function (newVal) {
      this.onHandleChangeCondition('delivery_date_to', newVal);
    },
    is_waiting_for_delivery: function (newVal) {
      this.onHandleChangeCondition('is_waiting_for_delivery', newVal);
    },
    is_delivered: function (newVal) {
      this.onHandleChangeCondition('is_delivered', newVal);
    },
    clearFilter: function (newVal) {
      if (newVal) {
        this.title = '';
        this.delivery_date_from = '';
        this.delivery_date_to = '';
        this.is_waiting_for_delivery = true;
        this.is_delivered = true;
        this.$emit('setClearFilter', false);
      }
    },
  },
  methods: {
    async getListCompany() {
      await this.$services.notification.getCompanies(
        { all_data: 1 },
        res => {
          this.listCompanies = res.list;
          this.listCompanies = this.listCompanies.map(item => {
            item.name = item.code + ':' + item.name;
            return item;
          });
        },
        err => {
          this.notifyError(this.$t(err.err));
        },
      );
    },
    listenCompanies(listCompanies) {
      this.$emit('emitSelectedCompanies', listCompanies);
    },
    onHandleChangeCondition(condition, value) {
      this.$emit('onHandleChangeCondition', condition, value);
    },
    onHandleClickFilter() {
      this.$emit('onHandleClickFilter');
    },
    pickerStartOptions(time) {
      if (this.checkEmpty(this.delivery_date_to)) return false;

      return moment(new Date(time)).format('YYYY-MM-DD') > moment(this.delivery_date_to).format('YYYY-MM-DD');
    },
    pickerEndOptions(time) {
      if (this.checkEmpty(this.delivery_date_from)) return false;

      return moment(new Date(time)).format('YYYY-MM-DD') < moment(this.delivery_date_from).format('YYYY-MM-DD');
    },
  },
};
</script>
