import BaseService from './BaseService';
import API_CODE from '../utils/api_code.js';

export default class SettingAPI extends BaseService {
  async getAllSettings(success, error) {
    await this.get(API_CODE.CU_192, success, error);
  }
  async updateSetting(params, success, error) {
    await this.post(API_CODE.CU_193, params, success, error);
  }
  async getExchangeRateCsvFile(params, success, error) {
    await this.get(API_CODE.CU_218 + this.urlParse(params), success, error);
  }
  async getSettingsFromCsv(params, success, error) {
    await this.post(API_CODE.CU_256, params, success, error);
  }
  async getTwoStepVerification(success, error) {
    await this.get(API_CODE.CU_306, success, error);
  }
  async toggleTwoStepVerification(params, success, error) {
    await this.post(API_CODE.CU_305, params, success, error);
  }
}
