<template>
  <el-dialog
    v-model="isVisible"
    width="640px"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    :show-close="true"
    @close="closeModal()"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-header--title">{{ $t('trip_item.modal_reject.title') }}</div>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <p class="modal--diaglog--text">{{ $t('trip_item.modal_reject.text') }}</p>
              <div>
                <label>
                  <span class="label-text">{{ $t('trip_item.modal_reject.reject_step') }}</span>
                </label>
                <SingleOptionSelect
                  ref="RejectStep"
                  :showClearable="false"
                  :list-data="stepList"
                  :default-list="stepBack"
                  @handle-selected-params="listenRejectBackStep"
                />
                <p role="alert" class="error-feedback" v-text="errors.step_back" />
              </div>
              <hr />
              <div class="form-group">
                <textarea
                  v-model="rejectMsg"
                  class="form-control fixed-width-textarea"
                  rows="6"
                  :placeholder="$t('trip_item.modal_reject.msg_placeholder')"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="row">
            <div class="col-xs-6 col-sm-6 col-md-6">
              <button @click="closeModal()" class="btn btn-sm btn-flat-default btn-block">
                {{ $t('common.cancel') }}
              </button>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6">
              <button
                @click="submitRejectModal()"
                class="btn btn-sm btn-default btn-block open-alert"
                id="btn-do-reject"
              >
                {{ $t('trip_item.modal_reject.execute_reject') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import SingleOptionSelect from '@/components/common/SingleOptionSelect';

export default {
  name: 'ModalReject',
  emits: ['submitRejectModal', 'closeModal'],
  components: { SingleOptionSelect },
  props: {
    isModalVisible: {
      type: Boolean,
      default: false,
    },
    requestDetail: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isVisible: false,
      rejectMsg: '',
      stepBack: -1,
      errors: {
        step_back: '',
      },
      defaultStepBack: '',
    };
  },
  computed: {
    stepList() {
      if (this.checkEmpty(this.requestDetail.approval_flow_detail)) return [];
      let stepListArr = [];
      this.requestDetail.approval_flow_detail.forEach((item, index) => {
        if (index + 1 < this.requestDetail.current_step && item.approval_flow_type != 2) {
          let approverListString = '';
          let approverList = [];
          if (!this.checkEmpty(item.step_approver)) {
            item.step_approver.forEach(approver => {
              approverList.push(approver.old_approver_name);
            });
          }
          if (!this.checkEmpty(approverList)) approverListString = ` （ ${approverList.join('、 ')} ）`;
          if (approverListString) {
            stepListArr.push({
              id: index + 1,
              name: String(index + 1) + this.$t('trip_item.modal_reject.text_step') + String(approverListString),
            });
          }
        }
      });

      stepListArr.push({
        id: -1,
        name:
          this.$t('trip_item.modal_reject.reject_entire_request') +
          String(` （ ${this.requestDetail.applicant_name} ）`),
      });

      return stepListArr;
    },
  },
  watch: {
    isModalVisible(newVal) {
      this.isVisible = newVal;
      if (newVal) {
        this.defaultStepBack = '';
        this.stepBack = -1;
      }
    },
    stepBack(newVal) {
      if (newVal !== '' && newVal !== undefined) this.errors.step_back = '';
    },
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
    listenRejectBackStep(value) {
      this.stepBack = value;
    },
    submitRejectModal() {
      if (this.stepBack === '' || this.stepBack === undefined) {
        this.errors.step_back = this.$t('E-CM-100', { item: this.$t('trip_item.modal_reject.reject_step') });
        return;
      } else {
        this.errors.step_back = '';
      }
      this.$emit('submitRejectModal', this.rejectMsg, this.stepBack);
      this.rejectMsg = '';
    },
  },
};
</script>
<style scoped></style>
