<template>
  <el-select
    v-model="selectedList"
    multiple
    clearable
    :placeholder="$t('trip_item.right_menu.manual_input_model.unspecified')"
    popper-class="custom-dropdown-select"
    :popper-append-to-body="false"
  >
    <el-option-group v-for="(schedule, index) in listData" :key="index" :label="schedule.date">
      <el-option
        v-for="item in schedule.schedules"
        :key="item.id"
        :label="!checkEmpty(item.title) ? item.title : scheduleInfo(schedule, item)"
        :value="item.id"
        :disabled="disableSelect"
      >
        <div class="el-custom-select-dropdown">
          <label class="checkbox no-label no-hover" :class="selectedList.includes(item.id) && ' checked selected'">
            <div>
              <span class="icons dropdown-option-icon">
                <span class="first-icon icon-abui-checkbox-unchecked" />
                <span class="second-icon icon-abui-checkbox-checked" />
              </span>
            </div>
          </label>
          <span class="dropdown-option-name">
            {{ !checkEmpty(item.title) ? item.title : scheduleInfo(schedule, item) }}
          </span>
        </div>
        <li class="divider" />
        <hr />
      </el-option>
    </el-option-group>
  </el-select>
</template>
<script>
import mixins from '@/helpers/mixins';

export default {
  name: 'MultiGroupOptionSelect',
  emits: ['handleSelectedParams'],
  mixins: [mixins],
  props: {
    listData: {
      type: Array,
      default: () => [],
    },
    disableSelect: {
      type: Boolean,
      default: false,
    },
    defaultList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      searchName: '',
      selectedList: [],
    };
  },
  created() {
    if (!this.checkEmpty(this.defaultList)) {
      this.selectedList = this.defaultList;
    }
  },
  watch: {
    selectedList() {
      this.$emit('handleSelectedParams', this.selectedList);
    },
    defaultList(value) {
      this.selectedList = value;
    },
  },
  methods: {
    scheduleInfo(schedule, item) {
      return schedule.date + ' ' + this.labelLocale(item.schedule_type);
    },
  },
};
</script>
<style scoped>
.dropdown-option-icon {
  margin-top: 50%;
}
.dropdown-option-icon span.first-icon.icon-abui-checkbox-unchecked {
  font-size: 15px;
  color: rgba(152, 169, 176, 0.5);
}
.dropdown-option-icon span.second-icon.icon-abui-checkbox-checked {
  font-size: 15px;
  color: rgba(255, 255, 255, 0.8) !important;
}
.dropdown-option-name {
  padding-left: 2.7rem;
}
.bs-searchbox {
  margin-bottom: 5px;
}
.no-hover {
  pointer-events: none !important;
}
.border {
  border-bottom: 1px solid #d0d6da;
}
</style>
