<template>
  <div>
    <div class="section noborder">
      <div class="row">
        <div class="col-sm-4 col-md-2 col-lg-2">
          <div class="form-group viewonly">
            <label>
              <span class="label-text">{{ $t('customer.company_id') }}</span>
            </label>
            <p class="viewonly-text input-sm input-sm" id="customer-id__view">{{ company.code }}</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <div class="form-group required show--edit show--new hide--view">
            <label>
              <span class="label-text">{{ $t('customer.company_name') }}</span>
            </label>
            <input
                type="text"
                class="form-control input-sm"
                :class="!checkEmpty(errors.name) && 'has-validation-error'"
                placeholder=""
                maxlength="100"
                id="customer-name"
                v-model="company.name"
            />
            <p v-text="errors.name" class="error-feedback" as="p"/>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-6 col-sm-3 col-md-2 col-all-closer col-all-closer--leftend">
          <div class="form-group required">
            <label>
              <span class="label-text">{{ $t('customer.location') }}</span>
            </label>
            <input
                id="zip-code"
                class="form-control input-sm js-zip-code"
                :class="!checkEmpty(errors.zip_code) && 'has-validation-error'"
                type="text"
                :placeholder="$t('customer.zip_code_placeholder')"
                maxlength="10"
                @input="company.zip_code = convertNumberFullToHalf(company.zip_code)"
                v-model="company.zip_code"
            />
            <p v-text="errors.zip_code" class="error-feedback" as="p"/>
          </div>
        </div>
        <div class="col-xs-6 col-sm-4 col-md-3 col-all-closer col-all-closer--rightend">
          <div class="form-group">
            <button
                id="zip-btn"
                class="btn btn-sm btn-inverse-primary js-btn--feedback-changed js-zip-code-btn btn--ls"
                @click="getAddressFromZipCode"
            >
              <span class="icon-abui-write prx"/>{{ $t('customer.from_zip_code') }}
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 col-md-2 col-sm-closer col-all-closer--leftend">
          <div class="form-group js-btn--feedback-changed__tgt-01">
            <label class="sr-only required">{{ $t('customer.prefecture') }}</label>
            <div class="btn-group bootstrap-select select-block select-sm js-select-item01 js-prefecture">
              <SingleGroupOptionSelectCity
                  :init-item="company.prefecture_id"
                  :is-display-search="false"
                  :list-data="listCity"
                  @handle-selected-params="onChangeCity"
              />
            </div>
          </div>
        </div>
        <div class="col-sm-4 col-md-5 col-sm-closer">
          <div class="form-group js-btn--feedback-changed__tgt-02">
            <label class="sr-only">{{ $t('customer.address_1') }}</label>
            <input
                type="text"
                id="municipality"
                class="form-control input-sm js-municipality"
                :class="!checkEmpty(errors.district) && 'has-validation-error'"
                v-model="company.district"
                maxlength="100"
                :placeholder="$t('customer.municipalities')"
            />
            <p v-text="errors.district" class="error-feedback" as="p"/>
          </div>
        </div>
        <div class="col-sm-4 col-md-5 col-sm-closer col-all-closer--rightend">
          <div class="form-group js-btn--feedback-changed__tgt-03">
            <label class="sr-only">{{ $t('customer.address_2') }}</label>
            <input
                type="text"
                class="form-control input-sm js-address--01"
                :class="!checkEmpty(errors.address) && 'has-validation-error'"
                v-model="company.address"
                maxlength="100"
                :placeholder="$t('customer.address')"
            />
            <p v-text="errors.address" class="error-feedback" as="p"/>
          </div>
        </div>
        <div class="col-sm-12 col-md-12">
          <div class="form-group">
            <label class="sr-only">{{ $t('customer.address_3') }}</label>
            <input
                type="text"
                class="form-control input-sm js-address--02"
                v-model="company.building"
                maxlength="100"
                :placeholder="$t('customer.building')"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6 col-md-4 col-lg-4">
          <div class="form-group required">
            <label>
              <span class="label-text">{{ $t('customer.telephone') }}</span>
            </label>
            <input
              type="text"
              class="form-control input-sm"
              v-model="company.phone_number"
              :class="!checkEmpty(errors.phone_number) && 'has-validation-error'"
              :placeholder="$t('customer.phone_placeholder')"
              id="company-phone"
              maxlength="13"
            />
            <p v-text="errors.phone_number" class="error-feedback" as="p" />
          </div>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-4">
          <div class="form-group required">
            <label>
              <span class="label-text">{{ $t('customer.email') }}</span>
            </label>
            <input
                type="text"
                class="form-control input-sm"
                :class="!checkEmpty(errors.email) && 'has-validation-error'"
                v-model="company.email"
                placeholder="info@sample.com"
                id="company-mail"
                maxlength="100"
            />
            <p v-text="errors.email" class="error-feedback" as="p"/>
          </div>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-4">
          <div class="form-group required">
            <label>
              <span class="label-text">{{ $t('customer.first_password') }}</span>
            </label>
            <input
                type="text"
                class="form-control input-sm"
                v-model="company.first_password"
                :class="!checkEmpty(errors.first_password) && 'has-validation-error'"
                :placeholder="$t('customer.first_password')"
                maxlength="50"
            />
            <p v-text="errors.first_password" class="error-feedback" as="p"/>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group show--edit show--new hide--view">
            <label>
              <span class="label-text">{{ $t('customer.remark') }}</span>
            </label>
            <textarea class="form-control" rows="6" placeholder="" v-model="company.note"/>
          </div>
        </div>
      </div>
    </div>
    <div data-v-369b90fa="">
      <div class="section"><h4 class="section--title"> {{ $t('trip.trip') }}</h4>
        <div class="js-switch_item02-01__tgt">
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group" v-if="company.setting">
                <label
                    class="checkbox checkbox-sm checkbox-inline"
                    :class="company.setting.is_upload_itinerary && 'checked'"
                >
                <span class="icons">
                  <span class="first-icon icon-abui-checkbox-unchecked"/>
                  <span class="second-icon icon-abui-checkbox-checked"/>
                </span>
                  <input type="checkbox" v-model="company.setting.is_upload_itinerary" id=""/>
                  <span style="margin-left: 32px">
              {{ $t('customer.is_upload_itinerary') }}
                </span>
                </label>
                <p></p>
              </div>
            </div>
          </div>
        </div>
        <div class="js-switch_item02-01__tgt">
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group" v-if="company.setting">
                <label
                    class="checkbox checkbox-sm checkbox-inline"
                    :class="company.setting.is_show_change_status_request && 'checked'"
                >
                <span class="icons">
                  <span class="first-icon icon-abui-checkbox-unchecked"/>
                  <span class="second-icon icon-abui-checkbox-checked"/>
                </span>
                  <input type="checkbox" v-model="company.setting.is_show_change_status_request" id=""/>
                  <span style="margin-left: 32px">
                  {{ $t('customer.is_show_change_status_request') }}
                </span>
                </label>
                <p></p>
              </div>
            </div>
          </div>
        </div>
        <div class="js-switch_item02-01__tgt">
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group" v-if="company.setting">
                <label
                    class="checkbox checkbox-sm checkbox-inline"
                    :class="company.setting.is_send_notification_ciq && 'checked'"
                >
                <span class="icons">
                  <span class="first-icon icon-abui-checkbox-unchecked"/>
                  <span class="second-icon icon-abui-checkbox-checked"/>
                </span>
                  <input type="checkbox" v-model="company.setting.is_send_notification_ciq" id=""/>
                  <span style="margin-left: 32px">
                           {{ $t('customer.is_send_notification_ciq') }}
                </span>
                </label>
                <p></p>
              </div>
            </div>
          </div>
        </div>
        <div class="js-switch_item02-01__tgt">
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group" v-if="company.setting">
                <label
                    class="checkbox checkbox-sm checkbox-inline"
                    :class="company.setting.is_need_schedule_hotel && 'checked'"
                >
                <span class="icons">
                  <span class="first-icon icon-abui-checkbox-unchecked"/>
                  <span class="second-icon icon-abui-checkbox-checked"/>
                </span>
                  <input type="checkbox" v-model="company.setting.is_need_schedule_hotel" id=""/>
                  <span style="margin-left: 32px">
                           {{ $t('customer.is_need_schedule_hotel') }}
                </span>
                </label>
                <p></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section">
        <h4 class="section--title"> {{ $t('customer.is_show_approval') }}</h4>
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group js-switch_item" v-if="company.setting" id="02-01">
              <span class="label-additional js-label-tgl-swt"/>
              <div
                  class="js-tgl-swt js-tgl-swt--rnd has-switch"
                  @click="company.setting.is_show_approval = !company.setting.is_show_approval"
              >
                <div class="switch-animate"
                     :class="company.setting.is_show_approval ? 'switch-on' : 'switch-off'">
                  <span class="switch-left">{{ $t('customer.on') }}</span>
                  <label>&nbsp;</label>
                  <span class="switch-right">{{ $t('customer.off') }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section" v-show="company.setting.is_show_approval">
        <h4 class="section--title"> {{ $t('customer.is_show_report') }}</h4>
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group js-switch_item" v-if="company.setting" id="02-02">
              <span class="label-additional js-label-tgl-swt"/>
              <div
                  class="js-tgl-swt js-tgl-swt--rnd has-switch"
                  @click="company.setting.is_show_report = !company.setting.is_show_report"
              >
                <div class="switch-animate"
                     :class="company.setting.is_show_report ? 'switch-on' : 'switch-off'">
                  <span class="switch-left">{{ $t('customer.on') }}</span>
                  <label>&nbsp;</label>
                  <span class="switch-right">{{ $t('customer.off') }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section">
        <h4 class="section--title">{{ $t('customer.cost_reimbursement') }}</h4>
        <p>
          {{ $t('customer.note_on_off') }}
        </p>
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group js-switch_item" v-if="company.setting" id="02-01">
              <span class="label-additional js-label-tgl-swt"/>
              <div
                  class="js-tgl-swt js-tgl-swt--rnd has-switch"
                  @click="company.setting.is_feature_payment = !company.setting.is_feature_payment"
              >
                <div class="switch-animate" :class="company.setting.is_feature_payment ? 'switch-on' : 'switch-off'">
                  <span class="switch-left">{{ $t('customer.on') }}</span>
                  <label>&nbsp;</label>
                  <span class="switch-right">{{ $t('customer.off') }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="js-switch_item02-01__tgt">
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label
                    class="checkbox checkbox-sm checkbox-inline"
                    :class="company.setting.is_upload_invoice && 'checked'"
                >
                <span class="icons">
                  <span class="first-icon icon-abui-checkbox-unchecked"/>
                  <span class="second-icon icon-abui-checkbox-checked"/>
                </span>
                  <input type="checkbox" v-model="company.setting.is_upload_invoice" id=""/>
                  <span style="margin-left: 32px">
                  {{ $t('customer.upload_voucher') }}
                </span>
                </label>
                <p></p></div>
            </div>
          </div>
        </div>
        <div class="js-switch_item02-01__tgt">
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group" v-if="company.setting">
                <label
                    class="checkbox checkbox-sm checkbox-inline"
                    :class="company.setting.is_upload_receipt_cost  && 'checked'"
                >
                <span class="icons">
                  <span class="first-icon icon-abui-checkbox-unchecked"/>
                  <span class="second-icon icon-abui-checkbox-checked"/>
                </span>
                  <input type="checkbox" v-model="company.setting.is_upload_receipt_cost " id=""/>
                  <span style="margin-left: 32px">
                       {{ $t('customer.is_upload_receipt_cost') }}
             </span>
                </label>
                <p></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section">
        <h4 class="section--title"> {{ $t('budget.title_top') }}</h4>
        <p>
          {{ $t('customer.is_show_setting_budget') }}
        </p>
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group js-switch_item" v-if="company.setting" id="02-01">
              <span class="label-additional js-label-tgl-swt"/>
              <div
                  class="js-tgl-swt js-tgl-swt--rnd has-switch"
                  @click="company.setting.is_show_setting_budget = !company.setting.is_show_setting_budget"
              >
                <div class="switch-animate"
                     :class="company.setting.is_show_setting_budget ? 'switch-on' : 'switch-off'">
                  <span class="switch-left">{{ $t('customer.on') }}</span>
                  <label>&nbsp;</label>
                  <span class="switch-right">{{ $t('customer.off') }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section">
        <h4 class="section--title"> {{ $t('customer.is_show_kikikanri') }}</h4>
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group js-switch_item" v-if="company.setting" id="02-01">
              <span class="label-additional js-label-tgl-swt"/>
              <div
                  class="js-tgl-swt js-tgl-swt--rnd has-switch"
                  @click="company.setting.is_show_kikikanri = !company.setting.is_show_kikikanri"
              >
                <div class="switch-animate"
                     :class="company.setting.is_show_kikikanri ? 'switch-on' : 'switch-off'">
                  <span class="switch-left">{{ $t('customer.on') }}</span>
                  <label>&nbsp;</label>
                  <span class="switch-right">{{ $t('customer.off') }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="row">
        <div class="col-sm-3 col-md-2 hidden-xs">
          <a class="btn btn-sm btn-flat-default js-btn--back" @click="dialogVisibleStatus = true">
            <span class="icon-abui-arrow-left prx"/>{{ $t('customer.back_to_list') }}
          </a>
        </div>
        <div class="col-sm-3 col-md-2 col-sm-offset-6 col-md-offset-8 show--edit show--new hide--view">
          <a class="btn btn-sm btn-primary btn-block open-alert js-btn--save alertAfterAnother mbl" @click="validate">
            {{ $t('customer.save_change') }}
          </a>
        </div>
      </div>
    </div>

    <el-dialog
        v-model="dialogVisibleStatus"
        :show-close="false"
        @close="dialogVisibleStatus = false"
        width="640px"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
    >
      <div class="modal--2fa">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <div class="modal-header--title"></div>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-md-12">
                  <p class="modal--diaglog--text text-center">
                    {{ $t('customer.save_and_move_confirm') }}
                  </p>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <div class="row">
                <div class="col-xs-6 col-sm-6 col-md-6">
                  <a
                      class="btn btn-sm btn-flat-default btn-block js--btn-do-move open-alert alertAfterAnother"
                      @click="dialogVisibleStatus = false"
                  >
                    {{ $t('common.cancel') }}
                  </a>
                </div>
                <div class="col-xs-6 col-sm-6 col-md-6">
                  <a
                      class="btn btn-sm btn-flat-default btn-block js--btn-do-move open-alert alertAfterAnother"
                      @click="backToList"
                  >
                    {{ $t('common.yes') }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import SingleGroupOptionSelectCity from './SingleGroupOptionSelectCity.vue';
import {ElDialog} from 'element-plus';

export default {
  name: 'Contact',
  components: {SingleGroupOptionSelectCity, ElDialog},
  emits: ['onChangeData'],
  data() {
    return {
      listCity: [],
      company: {
        id: 0,
        code: '',
        name: '',
        email: '',
        setting_id: 0,
        zip_code: '',
        building: null,
        prefecture_id: null,
        phone_number: null,
        district: null,
        address: '',
        note: null,
        setting: {
          is_feature_payment: 0,
          is_upload_invoice: 0,
          is_upload_receipt_cost: 0,
          is_upload_itinerary: 0,
          is_show_change_status_request: 0,
          is_show_approval: 0,
          is_show_report: 0,
          is_show_kikikanri: 0,
          is_show_setting_budget: 0,
          is_send_notification_ciq: 0,
          is_need_schedule_hotel: 1
        },
        city: null,
      },
      companyOfficial: {
        id: 0,
        code: '',
        name: '',
        email: '',
        setting_id: 0,
        zip_code: '',
        building: null,
        prefecture_id: null,
        phone_number: null,
        district: null,
        address: '',
        note: null,
        setting: {
          is_feature_payment: 0,
          is_upload_invoice: 0,
          is_upload_receipt_cost: 0,
          is_show_setting_budget: 0,
          is_upload_itinerary: 0,
          is_show_change_status_request: 0,
          is_show_approval: 0,
          is_show_report: 0,
          is_show_kikikanri: 0,
          is__setting_budget: 0,
          is_send_notification_ciq: 0,
          is_need_schedule_hotel: 1
        },
        city: null,
      },
      dialogVisibleStatus: false,
      errors: {
        name: '',
        zip_code: '',
        phone_number: '',
        email: '',
        first_password: '',
        district: '',
        address: '',
      },
    };
  },
  created() {
    this.startLoading();
    Promise.all([this.getListCity(), this.getCompanyInformation()]).then(() => {
      this.endLoading();
    });
  },
  computed: {
    customerId() {
      return this.$route.params.id;
    },
  },
  watch: {
    'company.name'() {
      this.$emit('onChangeData', !this.compareObject(this.company, this.companyOfficial));
    },
    'company.zip_code'() {
      this.$emit('onChangeData', !this.compareObject(this.company, this.companyOfficial));
    },
    'company.district'() {
      this.$emit('onChangeData', !this.compareObject(this.company, this.companyOfficial));
    },
    'company.address'() {
      this.$emit('onChangeData', !this.compareObject(this.company, this.companyOfficial));
    },
    'company.building'() {
      this.$emit('onChangeData', !this.compareObject(this.company, this.companyOfficial));
    },
    'company.phone_number'() {
      this.$emit('onChangeData', !this.compareObject(this.company, this.companyOfficial));
    },
    'company.email'() {
      this.$emit('onChangeData', !this.compareObject(this.company, this.companyOfficial));
    },
    'company.first_password'() {
      this.$emit('onChangeData', !this.compareObject(this.company, this.companyOfficial));
    },
    'company.note'() {
      this.$emit('onChangeData', !this.compareObject(this.company, this.companyOfficial));
    },
    'company.setting.is_upload_invoice'() {
      this.$emit('onChangeData', !this.compareObject(this.company, this.companyOfficial));
    },
    'company.setting.is_feature_payment'() {
      this.$emit('onChangeData', !this.compareObject(this.company, this.companyOfficial));
    },
  },
  methods: {
    convertNumberFullToHalf(number) {
      number = number.replace(/[^0-9０-９-－]/g, '');
      number = String(number).replace(/[\uFF10-\uFF19]/g, function (m) {
        return String.fromCharCode(m.charCodeAt(0) - 0xfee0);
      });

      return number;
    },
    async getListCity() {
      await this.$services.common.getListProvinces(
          res => {
            this.listCity = res.list;
          },
          err => {
            this.notifyError(this.$t(err.error));
          },
      );
    },
    async getCompanyInformation() {
      await this.$services.customer_enterprise.getCompanyInformation(
          this.customerId,
          res => {
            this.company = res;
            this.companyOfficial = this.cloneObject(res);
          },
          err => {
            this.notifyError(this.$t(err.error));
          },
      );
    },
    getAddressFromZipCode() {
      let flagValidate = true;
      if (String(this.company.zip_code).includes('－')) {
        this.company.zip_code = this.company.zip_code.replaceAll('－', '-');
      }
      if (this.checkEmpty(this.company.zip_code)) {
        flagValidate = false;
        this.errors.zip_code = this.$t('E-CM-100', {item: this.$t('customer.location')});
      } else if (!this.isZipCode(this.company.zip_code)) {
        flagValidate = false;
        this.errors.zip_code = this.$t('E-CM-101', {name: this.$t('customer.location')});
      } else if (this.strLen(this.company.zip_code) > 10) {
        flagValidate = false;
        this.errors.zip_code = this.$t('E-CM-111', {size: 10});
      } else {
        this.errors.zip_code = '';
      }

      if (!flagValidate) return;

      this.startLoading();
      this.$services.common.getAddressByZipCode(
          this.company.zip_code,
          res => {
            this.listCity.forEach(city => {
              if (!this.checkEmpty(res.province) && this.labelLocale(city) == res.province) {
                this.company.prefecture_id = city.id;
                this.company.address = res.city_name + res.street;
                this.company.district = res.district;
              } else if (!this.checkEmpty(res.city_name) && this.labelLocale(city) == res.city_name) {
                this.company.prefecture_id = city.id;
                this.company.address = res.street;
                this.company.district = res.district;
              }
            });
            this.endLoading();
          },
          err => {
            this.endLoading();
            this.notifyError(this.$t(err.error));
          },
      );
    },
    onChangeCity(id) {
      if (!this.checkEmpty(id)) {
        this.listCity.forEach(city => {
          if (city.id == id) {
            this.company.city = city;
            this.company.prefecture_id = city.id;
          }
        });
      }
    },
    validate() {
      let flagValidate = true;

      if (this.checkEmpty(this.company.name)) {
        flagValidate = false;
        this.errors.name = this.$t('E-CM-100', {item: this.$t('customer.company_name')});
      } else if (this.strLen(this.company.name) > 100) {
        flagValidate = false;
        this.errors.name = this.$t('E-CM-111', {size: 100});
      } else {
        this.errors.name = '';
      }

      if (String(this.company.zip_code).includes('－')) {
        this.company.zip_code = this.company.zip_code.replaceAll('－', '-');
      }
      if (this.checkEmpty(this.company.zip_code)) {
        flagValidate = false;
        this.errors.zip_code = this.$t('E-CM-100', {item: this.$t('customer.location')});
      } else if (!this.isZipCode(this.company.zip_code)) {
        flagValidate = false;
        this.errors.zip_code = this.$t('E-CM-101', {name: this.$t('customer.location')});
      } else if (this.strLen(this.company.zip_code) > 10) {
        flagValidate = false;
        this.errors.zip_code = this.$t('E-CM-111', {size: 10});
      } else {
        this.errors.zip_code = '';
      }

      if (this.checkEmpty(this.company.phone_number)) {
        flagValidate = false;
        this.errors.phone_number = this.$t('E-CM-100', { item: this.$t('customer.telephone') });
      } else if (!this.isJapanesePhoneNumber(this.company.phone_number)) {
        flagValidate = false;
        this.errors.phone_number = this.$t('E-CM-101', { name: this.$t('customer.telephone') });
      } else if (this.strLen(this.company.phone_number) > 20) {
        flagValidate = false;
        this.errors.phone_number = this.$t('E-CM-111', {size: 20});
      } else {
        this.errors.phone_number = '';
      }

      if (this.checkEmpty(this.company.email)) {
        flagValidate = false;
        this.errors.email = this.$t('E-CM-100', {item: this.$t('customer.email')});
      } else if (!this.isEmail(this.company.email)) {
        flagValidate = false;
        this.errors.email = this.$t('E-CM-101', {name: this.$t('customer.email')});
      } else if (this.strLen(this.company.email) > 100) {
        flagValidate = false;
        this.errors.email = this.$t('E-CM-111', {size: 100});
      } else {
        this.errors.email = '';
      }

      if (this.checkEmpty(this.company.first_password)) {
        flagValidate = false;
        this.errors.first_password = this.$t('E-CM-100', {item: this.$t('customer.first_password')});
      } else {
        this.errors.first_password = '';
      }

      if (this.checkEmpty(this.company.district)) {
        flagValidate = false;
        this.errors.district = this.$t('E-CM-100', {item: this.$t('customer.municipalities')});
      } else {
        this.errors.district = '';
      }

      if (this.checkEmpty(this.company.address)) {
        flagValidate = false;
        this.errors.address = this.$t('E-CM-100', {item: this.$t('customer.address')});
      } else {
        this.errors.address = '';
      }

      if (!flagValidate) return;

      this.updateCompanyInformation();
    },
    updateCompanyInformation() {
      this.$emit('onChangeData', false);
      this.$services.customer_enterprise.updateCompanyInformation(
          this.company,
          () => {
            this.notifySuccess(this.$t('I-CM-103'));
            this.$router.push(this.PAGES.CUSTOMER_ENTERPRISE);
          },
          err => {
            this.notifyError(this.$t(err.error), true);
          },
      );
    },
    backToList() {
      this.$emit('onChangeData', false);
      this.$router.push(this.PAGES.CUSTOMER_ENTERPRISE);
    },
  },
};
</script>
