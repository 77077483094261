<template>
  <div class="section noborder">
    <div class="row" v-if="!this.isOperatorOrAdmin">
      <div class="col-md-8">
        <ListApproveStatus />

        <ListTripCondition />
      </div>

      <div class="col-md-4">
        <AbsentList />
        <div class="sheet mhn mtn">
          <Notification />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie';

import ListApproveStatus from '@/components/dashboard/ListApproveStatus';
import ListTripCondition from '@/components/dashboard/ListCondition';
import Notification from '@/components/dashboard/Notification';
import AbsentList from '@/components/absent/List';
export default {
  name: 'Dashboard',
  components: {
    ListApproveStatus,
    ListTripCondition,
    Notification,
    AbsentList,
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  watch: {
    loggedIn(val) {
      if (val && ['admin', 'operator'].includes(Cookies.get('role'))) {
        this.$router.push(this.PAGES.INPUT);
      }
    },
  },
  created() {
    if (this.isOperatorOrAdmin) this.$router.push(this.PAGES.INPUT);
  },
};
</script>
