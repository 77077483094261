<template>
  <div v-show="!isExpandInvoice" class="container-fluid main-content-trip-item">
    <!-- Left content -->
    <div class="plan-stage">
      <div class="plan-status" :style="'background-color:' + requestDetail.status_data?.background_color">
        <span class="plan--code" :style="'color:' + requestDetail.status_data?.color">
          {{ requestDetail?.trip_code }}
        </span>
        <span class="status-text" :style="'color:' + requestDetail.status_data?.color">
          {{ labelLocale(requestDetail?.status_data) }}
          <i class="icon-abui-check-inverted plx"></i>
        </span>
      </div>
      <div>
        <!-- Approver comments -->
        <div
          v-if="!checkEmpty(approverComments) && showApproveComment"
          class="panel--wrap show--ringi custom-show-ringi"
        >
          <div class="panel panel-info mbn mts custom-show-ringi">
            <div class="panel-body break-word-custom">
              <ul class="regulation-check--feedback">
                <span v-for="(item, index) in approverComments" :key="index">
                  <li v-if="!checkEmpty(item.comment) && item.comment !== null" class="regulation-check--feedback-item">
                    {{ !checkEmpty(item.authorizer) ? item.authorizer?.name : item.approver_name }}
                    {{ item.authorizer_id ? this.$t('trip_item.trip_process.is_authorizer') : '' }}
                    {{ `: ${item.comment !== null ? item.comment : ''}` }}
                  </li>
                </span>
              </ul>
            </div>
          </div>
        </div>

        <!-- Regular check -->
        <div
          v-if="!checkEmpty(requestDetail?.regular_check) && $i18n.locale != 'en'"
          class="panel--wrap show--status20"
          style=""
          id="msg--regulation-check"
        >
          <div class="panel panel-warning mbn">
            <div class="panel-heading" style="border: none">
              <p class="panel-title"><i class="icon-abui-alert prx"></i>{{ $t('trip_item.main_content.rule_text') }}</p>
            </div>
            <div class="panel-body break-word-custom">
              <ul class="regulation-check--feedback">
                <li
                  v-for="(item, index) in requestDetail.regular_check"
                  :key="index"
                  class="regulation-check--feedback-item"
                >
                  <b>{{ titleType(item.title_type) }}</b> : {{ item.comment }}
                  <br />
                </li>
              </ul>
            </div>
          </div>
        </div>

        <!-- Hold or remand comments -->
        <div v-if="!checkEmpty(holdOrRejectCommentsOrCancel) && showHoldOrRejectCommentOrCancel" class="panel--wrap show--status20">
          <div class="panel panel-warning mbn">
            <div class="panel-body break-word-custom">
              <ul class="regulation-check--feedback">
                <span v-for="(item, index) in holdOrRejectCommentsOrCancel" :key="index">
                  <li v-if="item.comment !== null && !checkEmpty(item.comment)" class="regulation-check--feedback-item">
                    {{ !checkEmpty(item.authorizer) ? item.authorizer?.name : item.approver_name }}
                    {{ item.authorizer_id ? this.$t('trip_item.trip_process.is_authorizer') : '' }}
                    {{ `: ${item.comment !== null ? item.comment : ''}` }}
                  </li>
                </span>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div v-if="requestType == EXPENSE_SETTLEMENT">
        <div class="plan-head hide--estimate">
          <div class="ringi--apply-user show--ringi">
            [{{$t('trip_item.main_content.applier')}}]
                    <span class="ringi--apply-username" v-html="requestDetail.applicant_name"></span> <br>
            [{{$t('trip_item.main_content.apply_day')}}]
                    <span class="ringi--apply-datetime margin-left" v-html="showDateTime(requestDetail.created_at, 'YYYY/MM/DD HH:mm')"></span> <br>
            [{{$t('trip_item.main_content.accept_flow')}}]
                    <span class="ringi--apply-username" v-html="flowName"></span> 
          </div>
          <div class="apply-expense--head">
            <div class="apply-expense--head--left" style="width: 100%">
              <div class="apply-expense--trip-title" v-if="!checkEmpty(requestDetail.trip_id)">
                <a class="cursor-pointer" style="text-decoration: none" @click="goToTripDetail(requestDetail.trip_id)">
                  {{ requestDetail?.trip_name }}
                </a>
              </div>
              <div
                v-if="checkEmpty(requestDetail.trip_id) && requestDetail?.cost?.type_cost == 1"
                class="apply-expense--trip-title"
              >
                <a style="text-decoration: none">
                  {{ $t('expense.dropdown.option2') }}
                </a>
              </div>
              <div
                v-if="checkEmpty(requestDetail.trip_id) && requestDetail?.cost?.type_cost == 3"
                class="apply-expense--trip-title"
              >
                <a style="text-decoration: none">
                  {{ $t('expense.dropdown.option1') }}
                </a>
              </div>
              <div class="apply-expense--trip-period" v-if="requestDetail.trip_id != null">
                <i class="icon-abui-calendar prx"></i>
                <span>
                  {{ showDateTime(requestDetail.trip_start_time, 'YYYY/MM/DD（ddd）') }}
                  -
                  {{ showDateTime(requestDetail.trip_end_time, 'YYYY/MM/DD（ddd）') }}
                </span>
              </div>
              <div class="apply-expense--trip-destination" v-if="requestDetail.trip_id != null">
                <i class="icon-abui-location prx"></i>
                {{ labelLocale(requestDetail.location_string) }}
              </div>
            </div>
            <div class="apply-expense--head--right">
              <div class="apply-expense--total-sum">
                <span v-if="requestDetail.trip_id == null">
                  {{ splitThousandYen(requestDetail.cost?.amount) }}
                </span>
                <span v-else>
                  {{ splitThousandYen(calTotalCompanyExpenses) }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-else class="plan-head hide--estimate">
        <div class="ringi--apply-user show--ringi">
          [{{$t('trip_item.main_content.applier')}}]
                    <span class="ringi--apply-username" v-html="requestDetail.applicant_name"></span> <br>
          [{{$t('trip_item.main_content.apply_day')}}]
                    <span class="ringi--apply-datetime margin-left" v-html="showDateTime(requestDetail.created_at, 'YYYY/MM/DD HH:mm') "></span> <br>
          [{{$t('trip_item.main_content.accept_flow')}}]
                    <span class="ringi--apply-username" v-html="flowName"></span>
        </div>

        <div class="plan-head-block">
          <div class="plan-head--left">
            <div class="plan-title">
              <div class="plan-head--left">
                <div class="form-group">
                  <div class="input-with-icon hide--ringi">
                    <p class="plan-title--view show--ringi" v-if="!checkEmpty(requestDetail.trip_id)">
                      <a
                        class="cursor-pointer"
                        style="text-decoration: none"
                        @click="goToTripDetail(requestDetail.trip_id)"
                      >
                        {{ requestDetail.trip_name }}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="plan-date-dest hide--new hide--estimate">
              <span class="plan-dest"
                ><i class="icon-abui-location prx"></i> {{ labelLocale(requestDetail.location_string) }}</span
              >
            </div>
          </div>
        </div>
      </div>

      <div v-if="requestType == EXPENSE_SETTLEMENT" class="section noborder ptn" id="section--main">
        <ul id="myTab--stage" class="nav nav-tabs hide--status00 hide--status10 hide--new" role="tablist" style="">
          <li class="has-pointer" :class="tabActive == 1 && 'active'" @click="tabActive = 1">
            <a role="tab">{{ $t('trip_item.main_content.expense_history') }}</a>
          </li>
        </ul>

        <!-- Schedule tab -->
        <div v-show="tabActive == 1 && requestDetail.trip_id != null" class="ringi-approve-wrap">
          <CostTab
              :trip-id-params="requestDetail.trip_id"
              :request-histories="trip?.request_histories_with_trashed"
              :date-value="requestDate"
              :isEmbedFromRequest="true"
          />
        </div>
        <!-- Schedule tab -->
        <div v-show="tabActive == 1 && requestDetail.cost_id != null" class="ringi-approve-wrap">
          <CostTab
              :trip-id-params="requestDetail.trip_id"
              :request-histories="requestDetail?.request_histories_with_trashed"
              :date-value="requestDate"
              :isEmbedFromRequest="true"
          />
        </div>
        <div class="section noborder ptn" id="section--main" v-if="!requestDetail.trip_id">
          <div v-if="!checkEmpty(requestDetail.trip_id) && !checkEmpty(costExpenses)">
            <div v-for="(item, index) in costExpenses" :key="index" class="mbl expence--approve--wrap">
              <div class="apply-expense--section--wrap">
                <div class="apply-expense--section-head">
                  <h6>{{ item?.title }}</h6>
                  <div class="apply-expense--section-sum">{{ splitThousandYen(item?.amount) }}</div>
                </div>
                <div v-if="!checkEmpty(item.cost_items)">
                  <div v-for="(costDetail, detailId) in item.cost_items" :key="detailId" class="apply-expense--wrap">
                    <div
                      class="apply-expense--item"
                      @click="chooseCostRow(costDetail)"
                      :class="selectedCost.name === `${costDetail.title}-${costDetail.cost_id}` && 'active'"
                    >
                      <span class="apply-expense--title">{{ costDetail.title }}</span>
                      <span class="apply-expense--date">{{ showDateTime(costDetail.payment_date, 'YYYY/MM/DD') }}</span>
                      <span class="apply-expense--payto">{{ costDetail.payable_to }}</span>
                      <span class="apply-expense--paymenttype">
                        {{ textToPaymentMethod(costDetail.payment_method) }}
                      </span>
                      <span class="apply-expense--price">{{ splitThousandYen(costDetail.amount) }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="checkEmpty(requestDetail.trip_id) && !checkEmpty(requestDetail?.cost)">
            <div class="mbl expence--approve--wrap">
              <div class="apply-expense--section--wrap">
                <div class="apply-expense--wrap">
                  <div
                    class="apply-expense--item"
                    @click="chooseCostRow(requestDetail?.cost, 2)"
                    :class="selectedCost.name === `${requestDetail.cost.title}-${requestDetail.cost.id}` && 'active'"
                  >
                    <span class="apply-expense--title">{{ requestDetail?.cost.title }}</span>
                    <span class="apply-expense--date">{{
                      showDateTime(requestDetail?.cost.payment_date, 'YYYY/MM/DD')
                    }}</span>
                    <span class="apply-expense--payto">{{ requestDetail?.cost.payable_to }}</span>
                    <span class="apply-expense--paymenttype">
                      {{ textToPaymentMethod(requestDetail?.cost.payment_method) }}
                    </span>
                    <span class="apply-expense--price">
                      {{ splitThousandYen(requestDetail?.cost.amount) }}
                      <p class="box-eki" v-if="requestDetail?.cost?.flg_change_eki == 1"><span class="change-eki-cost">{{ $t('expense.with_modification') }}</span></p>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Cost bearing organizations -->
          <div v-if="!checkEmpty(requestDetail.cost_bearing_organizations)">
            <div class="apply-expense--section-head">
              <h6>{{ $t('business_report.cost_bearing') }}</h6>
              <!-- <div class="apply-expense--section-sum">{{ splitThousandYen(costItem.amount) }}</div> -->
            </div>
            <div class="apply-expense--wrap">
              <div
                v-for="(item, index) in requestDetail?.cost_bearing_organizations"
                :key="index"
                class="apply-expense--item fixed"
              >
                <span class="apply-expense--icon"><i class="icon-abui-check"></i></span>
                <span class="apply-expense--title" v-html="item.organization_name"></span>

                <span class="apply-expense--paymenttype">{{ item.price_percentage }}%</span>
                <span class="apply-expense--price" v-html="splitThousandYen(item.amount)"></span>
              </div>
            </div>
            <hr />
          </div>
        </div>
      </div>
      <div v-else class="section noborder ptn" id="section--main">
        <ul id="myTab--stage" class="nav nav-tabs hide--status00 hide--status10 hide--new" role="tablist" style="">
          <li class="has-pointer" :class="tabActive == 1 && 'active'" @click="tabActive = 1">
            <a role="tab">
              {{ this.isShowSettingReport ? $t('trip_item.main_content.approval_or_report_history') : $t('trip_item.main_content.approval_history') }}
            </a>
          </li>
          <li class="has-pointer" :class="tabActive == 2 && 'active'" @click="tabActive = 2">
            <a role="tab">{{ $t('trip_item.main_content.schedule') }}</a>
          </li>
          <li
            v-if="requestType != 1 && !checkEmpty(requestType)"
            :class="tabActive == 3 && 'active'"
            @click="tabActive = 3"
          >
            <a href="#">{{ $t('trip_item.main_content.report') }}</a>
          </li>
        </ul>

        <!-- Schedule tab -->
        <div v-show="tabActive == 1 && requestDetail.trip_id != null" class="ringi-approve-wrap">
          <CostTab
            :trip-id-params="requestDetail.trip_id"
            :request-histories="trip?.request_histories_with_trashed"
            :date-value="requestDate"
            :isEmbedFromRequest="true"
          />
        </div>
        <!-- Approval application/ report history -->
        <div v-show="tabActive == 2 && requestDetail?.trip_id" class="ringi-approve-wrap">
          <div class="schedule--wrap hide--new" id="schedule-tip-item">
            <Detail :is-action-ringi="true" :requestPage="true" :trip-id-from-application="requestDetail.trip_id" />
          </div>
        </div>
        <!-- Report history -->
        <div v-show="tabActive == 3 && requestDetail?.trip_id && requestType != 1 && !checkEmpty(requestType)">
          <ReportList :tripId="requestDetail.trip_id" :requestDetail="requestDetail" />
        </div>
      </div>
    </div>

    <!-- Right content -->
    <div v-if="requestType == EXPENSE_SETTLEMENT" class="trip-info-col bg-gray pal" style="overflow: scroll">
      <div class="trip--receipt hide--new">
        <div v-if="selectedUrlIsImage" class="scrollable-img">
          <img
            :src="selectedCost.file_url"
            style="width: 100%"
            :style="isZoomIn && 'width: 125%'"
            class="dropshadow--lv3"
          />
        </div>
        <div v-else style="word-break: break-word">
          <span
            v-if="!checkEmpty(selectedCost.file_url) && selectedCost.file_url.split('?')[0].split('.').pop() == 'pdf'"
            class="cursor-pointer"
            style="font-weight: bold"
          >
            <div class="scrollable-img">
              <iframe
                :src="selectedCost.file_url"
                style="width: 100%; height: 80vh; border: none; margin-top: 35px"
              ></iframe>
            </div>
          </span>
          <span v-else>
            {{ $t('trip_item.no_invoice') }}
          </span>
        </div>
        <div
          v-if="!checkEmpty(selectedCost.file_url)"
          class="more-buttons"
          :style="!selectedUrlIsImage && 'left: 50px !important'"
        >
          <button
            class="btn btn-sm btn-flat-default btn-col-custom"
            @click="(isRotateInvoice = true), (isExpandInvoice = true)"
          >
            <i class="icon-abui-rotate" />
          </button>
          <button class="btn btn-sm btn-flat-default btn-col-custom" @click="isExpandInvoice = true">
            <i class="icon-abui-expand" />
          </button>
          <button
            v-show="!isZoomIn && selectedUrlIsImage"
            class="btn btn-sm btn-flat-default btn-col-custom"
            @click="isZoomIn = true"
          >
            <i class="icon-abui-zoomin" />
          </button>
          <button
            v-show="isZoomIn && selectedUrlIsImage"
            class="btn btn-sm btn-flat-default btn-col-custom"
            @click="isZoomIn = false"
          >
            <i class="icon-abui-zoomout" />
          </button>
        </div>
      </div>
    </div>
    <div v-else class="trip-info-col">
      <div class="trip--map">
        <GMapMap :center="centerPosition" :options="options" :zoom="5" map-type-id="terrain" style="height: 200px">
          <GMapMarker :key="m.id" v-for="m in markers" :position="m.position"> </GMapMarker>
        </GMapMap>
      </div>
      <ul id="Tab--trip--info" class="nav nav-tabs nav-justified" role="tablist">
        <li class="active">
          <a href="#trip--info--1" role="tab"
            ><i class="icon-abui-file"></i>
            <span>{{ $t('trip_item.main_content.receipt_card_and_bill_list') }}</span>
          </a>
        </li>
      </ul>
      <div class="tab-pane fade active in" id="trip--info--2" style="overflow: auto; max-height: calc(100vh - 400px)">
        <div class="info-list-wrap">
          <ul class="trip--info--file-list">
            <li v-for="item in listFileByTrip" :key="item.id">
              <a :href="item.link" target="_blank" v-if="item.name && item.content_reservation === ''">
                <i class="icon-abui-file" v-if="item.name" />
                <span class="file--name">{{ item.name }}</span>
                <span class="file--date">{{ showDateTime(item.created_at, 'YYYY/MM/DD') }}</span>
              </a>
              <a @click="openModalEdit(item)" v-else>
                <i class="icon-abui-file" v-if="item.name" />
                <span style="width: 33px" v-if="item.content_reservation && item.name == undefined"> </span>
                <span class="file--name" v-if="item.name && item.content_reservation"
                  >{{ trimNameFile(item) }} | {{ item.content_reservation }}</span
                >
                <span class="file--name" v-else>{{ item.content_reservation }}</span>
                <span class="file--date">{{ showDateTime(item.created_at, 'YYYY/MM/DD') }}</span>
              </a>
              <!--              <a :href="item.link" target="_blank">-->
              <!--                <i class="icon-abui-file" /><span class="file&#45;&#45;name">{{ item.name }}</span>-->
              <!--                <span class="file&#45;&#45;date">{{ showDateTime(item.created_at, 'YYYY/MM/DD') }}</span>-->
              <!--              </a>-->
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div v-show="isExpandInvoice" class="container-fluid main-content-trip-item">
    <div class="trip-info-col bg-gray col-expandable" style="width: calc(100% - 55px); z-index: 1">
      <button
        class="btn btn-sm btn-flat-default btn-col-expand expanded"
        @click="(isExpandInvoice = false), (isRotateInvoice = false)"
      >
        <i class="icon-abui-contract" />
      </button>
      <div
        class="security--map hide--new expand-invoice"
        style="background-position: 1200px -40px; background-size: 1923px; height: 100%"
        :style="isRotateInvoice && 'padding-left:15%'"
      >
        <img
          v-if="selectedUrlIsImage"
          style="margin-top: 12px; margin-bottom: 12px"
          :src="selectedCost.file_url"
          alt=""
          class="dropshadow--lv3 img-expand"
          :class="customClassImagePreview()"
        />
        <iframe
          v-else-if="
            !checkEmpty(selectedCost.file_url) && selectedCost.file_url.split('?')[0].split('.').pop() == 'pdf'
          "
          :src="selectedCost.file_url"
          :class="isRotateInvoice && 'rotate90-pdf'"
          :style="
            isRotateInvoice
              ? 'width: 70%; height: 150vh; border: none; margin-left: 20vh'
              : 'width: 100%; height: 1200px; border: none; margin-left: 40px'
          "
        ></iframe>
      </div>
    </div>
  </div>
  <ModalExpenseCost
    ref="ModalExpenseCost"
    :view-only="true"
    :showMask="false"
    :dialogVisible="showExpenseCost"
    :expenseCostDetail="expenseCostDetail"
    :listFile="listFile"
    :list-schedule="listTripSchedule"
    @cancel="reloadData"
  />
  <ModalUploadFileEdit
    :dialogVisible="showModalUploadFileEdit"
    :itemFileEdit="itemFileEdit"
    :isOnlyView="true"
    @cancel="showModalUploadFileEdit = false"
    :isUploadWithNote="true"
    :title="$t('trip_update.file_upload_edit')"
  />
</template>
<script>
import Detail from '@/components/trip/trip-item/Detail';
import CostTab from '@/components/trip/trip-item/CostTab';
import ModalExpenseCost from '@/components/common/ModalExpenseCost';
import ReportList from '@/components/trip/trip-item/ReportList';
import ModalUploadFileEdit from '@/components/common/ModalUploadFileEdit';
import {
  EXPENSE_SETTLEMENT,
  payment_reimbursement,
  PAYMENT_CORPORATE_CARD,
  PAYMENT_INVOICE,
  HOLD,
  REMAND,
  CANCEL,
  APPROVAL,
  COMMON_IMAGE_EXTENSIONS,
} from '@/constants';
import mixins from '@/helpers/mixins';

export default {
  name: 'MainTripItemContent',
  components: { Detail, CostTab, ReportList, ModalExpenseCost, ModalUploadFileEdit },
  mixins: [mixins],
  props: {
    requestDetail: {
      type: Object,
      default: () => {
        return {
          daily_allowance: {
            amount: '',
            data: [],
            note: '',
          },
          fees: {
            title: '',
            amount: '',
            data: [],
          },
        };
      },
    },
    allCosts: {
      type: Object,
      default: () => {},
    },
    requestDate: {
      type: String,
      default: '',
    },
  },
  beforeCreate() {
    this.$store.dispatch('map/updateMarkers', []);
  },
  data() {
    return {
      urlLoad: window.location.pathname,
      showModalUploadFileEdit: false,
      itemFileEdit: {},
      trip: {},
      imageDimension: {},
      showExpenseCost: false,
      expenseCostDetail: {},
      listFile: [],
      options: {
        disableDefaultUI: true,
        minZoom: 4,
      },
      listFileByTrip: [],
      isExpandInvoice: false,
      isRotateInvoice: false,
      isZoomIn: false,
      tabActive: 1,
      EXPENSE_SETTLEMENT,
      costExpenses: {},
      selectedCost: {
        name: '',
        file_url: '',
        cost_title: '',
      },
      listTripSchedule: [],
      centerPosition: {
        lat: 35.672855,
        lng: 139.817413,
      },
      isFirstLoad: true,
    };
  },
  created() {
    Promise.all([
      this.getTripDetail(),
      this.getListCostsByDate(),
      this.getFilesByTrip(),
      this.getDefaultCenterMap(),
    ]).then(() => {});
  },
  computed: {
    isShowSettingReport() {
      return this.$store.state.auth?.user?.setting.is_show_report || false;
    },
    selectedUrlIsImage() {
      if (this.checkEmpty(this.selectedCost.file_url)) return false;
      const fileType = this.selectedCost.file_url.split('?')[0].split('.').pop();
      return COMMON_IMAGE_EXTENSIONS.includes(String(fileType).toLowerCase());
    },
    showApproveComment() {
      let flgShow = false;
      if (this.checkEmpty(this.approverComments)) return false;
      this.approverComments.forEach(item => {
        if (item.comment != null && !this.checkEmpty(item.comment)) flgShow = true;
      });
      return flgShow;
    },
    flowName() {
      let flowName = '';
      if (this.requestDetail.approval_flow_id) return this.requestDetail.approval_flow_data.name;
      else if (this.requestDetail.expense_flow_id) return this.requestDetail.expense_flow_data.name;
      else if (this.requestDetail.report_flow_id) return this.requestDetail.report_flow_data.name;
      return flowName;
    },
    showHoldOrRejectCommentOrCancel() {
      let flgShow = false;
      if (this.checkEmpty(this.holdOrRejectCommentsOrCancel)) return false;
      this.holdOrRejectCommentsOrCancel.forEach(item => {
        if (item.comment != null && !this.checkEmpty(item.comment)) flgShow = true;
      });
      return flgShow;
    },
    markers() {
      return this.$store.state.map.markers;
    },
    companyAddress() {
      return this.$store.state.auth?.user?.company?.address || '';
    },
    requestType() {
      return this.$route.query.type || '';
    },
    tripId() {
      return this.requestDetail.trip_id || '';
    },
    calTotalCompanyExpenses() {
      let totalCost = 0;
      if (!this.checkEmpty(this.costExpenses)) {
        this.costExpenses.forEach(item => {
          if (!this.checkEmpty(item.cost_items)) {
            item.cost_items.forEach(cost => {
              totalCost += cost.amount;
            });
          }
        });
      }

      return totalCost;
    },
    approverComments() {
      let approverComments = [];
      if (!this.checkEmpty(this.requestDetail.comment_histories)) {
        this.requestDetail.comment_histories.forEach(item => {
          if (item.request_status === APPROVAL) approverComments.push(item);
        });
      }

      return approverComments;
    },
    holdOrRejectCommentsOrCancel() {
      let holdOrRejectCommentsOrCancel = [];
      if (!this.checkEmpty(this.requestDetail.comment_histories)) {
        this.requestDetail.comment_histories.forEach(item => {
          if (item.request_status === HOLD || item.request_status === REMAND || item.request_status === CANCEL) holdOrRejectCommentsOrCancel.push(item);
        });
      }

      return holdOrRejectCommentsOrCancel;
    },
    listDestination() {
      let arrLocation = [];
      const locationString = this.labelLocale(this.requestDetail.location_string) || '';
      if (this.checkEmpty(locationString)) return [];
      arrLocation = locationString.split('-');

      return arrLocation;
    },
    imageIsVertical() {
      if (this.checkEmpty(this.imageDimension)) return true;
      return this.imageDimension.width < this.imageDimension.height;
    },
  },
  watch: {
    'selectedCost.file_url'(newVal) {
      let img = new Image();
      img.src = newVal;
      if (!this.checkEmpty(newVal) && this.selectedUrlIsImage) {
        img.onload = () => {
          if (!this.checkEmpty(img.width) && !this.checkEmpty(img.height))
            this.imageDimension = {
              width: img.width,
              height: img.height,
            };
        };
      }
    },
    costExpenses: {
      handler(newVal) {
        this.isFirstLoad = true;
        if (!this.checkEmpty(newVal) && this.requestType == EXPENSE_SETTLEMENT && this.isFirstLoad)
          this.chooseFirstCost(1);
      },
      deep: true,
    },
    requestDetail: {
      handler() {
        if (this.urlLoad == window.location.pathname) {
          this.getTripDetail();
        }
      },
      deep: true,
    },
    'requestDetail.cost': {
      handler(newVal) {
        this.isFirstLoad = true;
        if (!this.checkEmpty(newVal) && this.requestType == EXPENSE_SETTLEMENT && this.isFirstLoad)
          this.chooseFirstCost(2);
      },
      deep: true,
    },

    tripId() {
      this.getListCostsByDate();
      this.getFilesByTrip();
      this.getTripDetail();
    },
    listDestination(newVal) {
      if (this.checkEmpty(newVal)) return;
      newVal.forEach((item, index) => {
        this.getLatLng(index + 1, item);
      });
    },
    companyAddress() {
      this.getDefaultCenterMap();
    },
    markers(newVal) {
      if (this.checkEmpty(newVal)) this.getDefaultCenterMap();
      this.centerPosition = {
        lat: +newVal[0].position.lat,
        lng: +newVal[0].position.lng,
      };
    },
  },
  methods: {
    trimNameFile(val) {
      let textName = val.name;
      if (val.name && val.content_reservation) {
        if (val.name.length > 18 && val.content_reservation.length > 16) {
          textName = textName.substring(0, 18) + '...';
        }
      }
      return textName;
    },
    openModalEdit(item) {
      this.showModalUploadFileEdit = true;
      this.itemFileEdit = item;
    },
    customClassImagePreview() {
      if (this.isRotateInvoice && this.imageIsVertical) {
        return 'rotate90-image';
      } else if (this.isRotateInvoice && !this.imageIsVertical) {
        return 'rotate90-image image-margin-top-15rem';
      } else {
        return '';
      }
    },
    chooseFirstCost(type = 1) {
      if (
        !this.checkEmpty(this.costExpenses) &&
        !this.checkEmpty(this.requestDetail.trip_id) &&
        !this.checkEmpty(this.costExpenses[0].cost_items)
      ) {
        this.chooseCostRow(this.costExpenses[0].cost_items[0], type, false);
        this.isFirstLoad = false;
      } else if (this.checkEmpty(this.requestDetail.trip_id) && !this.checkEmpty(this.requestDetail?.cost)) {
        this.chooseCostRow(this.requestDetail?.cost, type, false);
        this.isFirstLoad = false;
      }
    },
    async getTripDetail() {
      if (this.checkEmpty(this.tripId)) return;
      await this.$services.trip.tripDetail(
        this.tripId,
        res => {
          this.trip = res;
        },
          () => {
          // this.notifyError(this.$t(err.error));
        },
      );
    },
    async getScheduleByTripList() {
      if (this.checkEmpty(this.tripId)) return;
      await this.$services.trip.getScheduleByTripList(
        { trip_id: this.tripId },
        res => {
          this.listTripSchedule = res.list;
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    async getCostDetail(id, isDeleted = false, showDialogAfter = true) {
      this.getScheduleByTripList();
      await this.$services.trip.getCostDetail(
        id,
        res => {
          this.expenseCostDetail = {
            ...res,
            currency_rate: res.currency_id == 1 ? 0 : res.currency_rate,
            amount: this.splitThousandNumber(res.amount || 0),
            currency_amount: this.splitThousandNumber(res.currency_amount || 0),
            firstLoading: res.currency_id != 1 ? 1 : 0,
            is_deleted: isDeleted,
          };
          this.listFile = res.files;
          if (showDialogAfter) this.showExpenseCost = true;
        },
        () => {
        },
      );
    },
    reloadData() {
      this.showExpenseCost = false;
      this.listFile = [];
      // this.getListExpense(false);
    },
    goToTripDetail(tripId) {
      let routeData = this.$router.resolve({
        name: this.PAGES.TRIP_UPDATE,
        params: {
          id: tripId,
        },
      });
      window.open(routeData.href, '_blank');
    },
    //costType = 1: cost with trip, costType = 2: cost without trip
    chooseCostRow(costDetail, type = 1, showDialogCostDetail = true) {
      this.selectedCost.cost_title = costDetail.title;
      if (type == 2) {
        this.selectedCost.name = costDetail?.title + '-' + String(costDetail?.id);
        this.getCostDetail(costDetail.id, false, showDialogCostDetail);
        if (!this.checkEmpty(costDetail.inputs)) {
          this.selectedCost.file_url = costDetail?.inputs[0]?.file?.file_path;
        } else {
          this.selectedCost.file_url = '';
        }
      } else {
        this.selectedCost.name = costDetail?.title + '-' + String(costDetail?.cost_id);
        this.getCostDetail(costDetail.cost_id, false, showDialogCostDetail);
        this.selectedCost.file_url = costDetail?.file_url;
      }
    },
    async getLatLng(id, address) {
      if (!address) return;

      await this.$services.common.searchTimeZones(
        { address },
        res => {
          if (res?.lat && res?.lng) {
            const arrHavePositionYet = this.markers.filter(item => item.name == address);
            if (this.checkEmpty(arrHavePositionYet)) {
              this.$store.dispatch('map/updateMarkers', [
                ...this.$store.state.map.markers,
                {
                  id,
                  position: { lat: +res.lat, lng: +res.lng },
                  name: address,
                },
              ]);
            }
          }
        },
        () => {
          return {};
        },
      );
    },
    async getDefaultCenterMap() {
      if (this.companyAddress) {
        await this.$services.common.searchTimeZones(
          { address: this.companyAddress },
          res => {
            if (res) this.setCenterMap(res);
          },
          () => {},
        );
      }
    },
    setCenterMap(data) {
      this.centerPosition = {
        lat: +data.lat,
        lng: +data.lng,
      };
    },
    async getFilesByTrip() {
      if (this.checkEmpty(this.tripId) || !this.tripId) return;
      await this.$services.trip.getFilesByTrip(
        { trip_id: this.tripId },
        res => {
          this.listFileByTrip = res.list;
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    async getListCostsByDate() {
      let params = {
        trip_id: this.tripId,
        request_id: this.requestDetail.id,
      };
      if (this.tripId && this.requestDetail.id) {
        await this.$services.trip_item.getListCostsByDate(
          params,
          res => {
            this.costExpenses = res.list.company_cost_items;
          },
          err => {
            this.notifyError(this.$t(err.error));
          },
        );
      }
    },
    textToPaymentMethod(status) {
      if (status === payment_reimbursement) return this.$t('trip_item.main_content.payment_reimbursement');
      if (status === PAYMENT_CORPORATE_CARD) return this.$t('trip_item.main_content.payment_corporate_card');
      if (status === PAYMENT_INVOICE) return this.$t('trip_item.main_content.payment_invoice');
      return '';
    },
    titleType(type) {
      switch (type) {
        case 1:
          return '出張日数';
        case 2:
          return 'パスポート残存有効期限必要日数';
        case 3:
          return '別途海外旅行傷害保険の手続き必須';
        case 4:
          return '乗り継ぎ禁止地（空港）';
        case 5:
          return '利用禁止航空会社';
        case 6:
          return '禁止フライト区間';
        case 7:
          return '注意フライト区間';
        case 8:
          return '空港ごとの乗り継ぎ時間(下限より短い)';
        case 9:
          return '空港ごとの乗り継ぎ時間(上限より長い)';
        case 10:
          return '空港ごとの乗り継ぎ注意航空会社';
        case 11:
          return '乗り継ぎ回数';
        case 12:
          return '座席クラス(国内線)';
        case 13:
          return '座席クラス(国際線)';
        case 14:
          return '金額(航空券、下限より安い)';
        case 15:
          return '金額(航空券、上限より高い)';
        case 16:
          return '金額(ホテル、下限より安い)';
        case 17:
          return '金額(ホテル、上限より高い)';
        case 18:
          return '出張費用総額(下限より安い)';
        case 19:
          return '出張費用総額(上限より高い)';
        default:
          return '-';
      }
    },
  },
};
</script>
<style scoped>
.file--name {
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 313px;
  white-space: nowrap;
  padding-right: 10px;
}
.main-content-trip-item {
  display: flex;
  justify-content: flex-start;
  margin-top: -48px;
}
.plan-stage {
  width: calc(100% - 480px);
}
.custom-show-ringi {
  margin-top: 20px;
}
#schedule-tip-item {
  margin: 0 -32px !important;
}
.trip-info-col {
  padding-bottom: 185px !important;
}
.trip--receipt .more-buttons {
  position: absolute;
  top: 50px;
  left: 90px;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #ffff;
  color: #0000;
  font-size: 16px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}
.btn-col-custom {
  border: 1px solid #afafaf;
}
.img-expand {
  width: 30%;
  overflow: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.expand-invoice {
  overflow: auto;
}
.rotate90-image {
  -webkit-transform: rotate(-90deg);
  width: 40%;
  margin-top: -10rem;
}
.image-margin-top-15rem {
  margin-top: 15rem !important;
}
.rotate90-pdf {
  -webkit-transform: rotate(-90deg);
  width: 40%;
  margin-top: -10rem;
}
.scrollable-img {
  width: auto;
  overflow-x: auto;
  overflow-y: auto;
  white-space: nowrap;
}
.cursor-pointer:hover {
  text-decoration: underline;
}
.margin-left {
    margin-left: 0;
}
.change-eki-cost {
  background-color: #8B0000;
  font-size: 10px;
  color: #fff;
  border-radius: 12px;
  padding: 2px 5px;
  margin-left: 5px;
}
.box-eki {
  margin: 0px !important;
  padding: 0px !important;
  line-height: 10px;
}
</style>
