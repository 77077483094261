const initialState = {
  markers: [],
  center: {
    lat: 35.6762,
    lng: 139.6503,
  },
};

export const map = {
  namespaced: true,
  state: initialState,
  actions: {
    updateMarkers({ commit }, markers) {
      commit('updateMarkers', markers);
    },
    updateCenter({ commit }, center) {
      commit('updateCenter', center);
    },
  },
  mutations: {
    updateMarkers(state, markers) {
      state.markers = markers;
    },
    updateCenter(state, center) {
      state.center = center;
    },
  },
};
