<template>
  <div class="table-responsive">
    <table class="table table-hover table-hover--pointer table--property-list" id="table--plan">
      <thead>
      <draggable v-model="headers" tag="tr" :options="draggableOptions" @end="saveColumns" :class="{'grabbing': isDragging}"
                 @start="onStart" :force-fallback="true">
        <th class="table-sort"
            v-for="header in headers"
            :key="header"
            :class="[(sortActive.sort_column == header && header != 'checkbox') && 'table-sort-active', classHeaders[header]]"
            @click="sortAction(header)">
              <span :class="sortActive.sort_column == header ? sortActive.sort_by : 'asc'"
                    v-if="!['status', 'checkbox'].includes(header)"
              >
                {{ $t(nameHeaders[header]) }}
              </span>

          <label :class="allSelected === true ? 'checkbox no-label checked' : 'checkbox no-label'"
                 v-if="header == 'checkbox'">
                  <span class="icons">
                    <span class="first-icon icon-abui-checkbox-unchecked"></span>
                    <span class="second-icon icon-abui-checkbox-checked"></span>
                  </span>
            <input type="checkbox" @click="selectAll" v-model="allSelected"/>
          </label>
        </th>
      </draggable>
      </thead>
      <tbody>
      <tr v-if="checkEmpty(sortData)" class="no-data">
        <td colspan="8">{{ $t('common.no_data') }}</td>
      </tr>
      <tr
          v-for="(item, index) in sortData"
          :key="index"
          @click="pushToTripItem(item)"
          :class="!canSelectForApproving(index) && 'done muted'"
      >
        <td v-for="header in headers" :key="header">
          <span v-if="header == 'checkbox'" class="cell-control width-33p" @click.stop>
            <label
                class="checkbox no-label"
                style="margin-left: 0px !important;"
                :class="submitApplications.includes(item.id) && canSelectForApproving(index) && 'checked'"
            >
              <div>
                  <span class="icons">
                    <span class="first-icon icon-abui-checkbox-unchecked"></span>
                    <span class="second-icon icon-abui-checkbox-checked"></span>
                  </span>
                <input
                    type="checkbox"
                    :disabled="!canSelectForApproving(index) && 'disabled'"
                    @click="selectApplication(item)"
                />
              </div>
            </label>
          </span>
          <div v-if="header == 'approval_or_report'"
               :style="{color: styleToApprovalOrReport(item)}"
          >
            {{ textToApprovalOrReport(item) }}
          </div>
          <div v-if="header == 'expense_settlement_status'"
               v-html="checkEmpty(item.expense_settlement) ? '-' : labelLocale(item.expense_settlement)"
               :style="{
              color: checkEmpty(item.expense_settlement) ? '' : item.expense_settlement.color,
            }"
          >
          </div>
          <div v-if="header == 'date'"
          >
            {{ showDateTime(item.date, 'YYYY/MM/DD（ddd）') }}
          </div>
          <div v-if="header == 'application_type'"
          >
            {{ this.convertApplicationType(item.type) }}
          </div>
          <div v-if="header == 'applicant_name'"
               class="td-100p"
          >
            <el-tooltip
                v-if="!checkEmpty(item.old_applicant_name) && item.applicant.id != userId && calTextWidth(item.old_applicant_name) > 90"
                popper-class="tool-tip-guide custom-tooltip-title"
                class="label-icon-guide"
                effect="dark"
                :content="item.old_applicant_name"
                :visible-arrow="false"
                placement="top-start"
            >
              <span class="td-100p">{{ item.old_applicant_name }}</span>
            </el-tooltip>
            <span v-else>
             {{ item.applicant.id == userId ? $t('application.myself') : item.old_applicant_name }}
            </span>
          </div>
          <div v-if="header == 'code'"
               class="td-200p"
          >
            <el-tooltip
                v-if="!checkEmpty(item.code) && calTextWidth(item.code) > 180"
                popper-class="tool-tip-guide custom-tooltip-title"
                class="label-icon-guide"
                effect="dark"
                :content="item.code"
                :visible-arrow="false"
                placement="top-start"
            >
              <span class="td-200p">{{ item.code }}</span>
            </el-tooltip>
            <span v-else>
             {{ item.code }}
            </span>
          </div>
          <div v-if="header == 'trip_name'"
               class="td-250p"
          >
            <el-tooltip
                v-if="!checkEmpty(showRequestTitle(item)) && calTextWidth(showRequestTitle(item)) > 230"
                popper-class="tool-tip-guide custom-tooltip-title"
                class="label-icon-guide"
                effect="dark"
                :content="showRequestTitle(item)"
                :visible-arrow="false"
                placement="top-start"
            >
              <span :ref="`title-expense-${item.id}`" class="td-250p">{{ showRequestTitle(item) }}</span>
            </el-tooltip>
            <span v-else :ref="`title-expense-${item.id}`">
              {{ showRequestTitle(item) }}
            </span>
          </div>
          <div v-if="header == 'approver_name'"
               class="td-200p"
          >
            <el-tooltip
                v-if="!checkEmpty(item.approver_name) && calTextWidth(item.approver_name) > 180"
                popper-class="tool-tip-guide custom-tooltip-title"
                class="label-icon-guide"
                effect="dark"
                :content="item.approver_name"
                :visible-arrow="false"
                placement="top-start"
            >
              <span class="td-200p">{{ checkEmpty(item.approver) ? '-' : item.approver_name }}</span>
            </el-tooltip>
            <span v-else>
             {{ checkEmpty(item.approver) ? '-' : item.approver_name }}
            </span>
          </div>
          <div v-if="header == 'status'">
            <a
                v-if="!checkEmpty(item.trip_id)"
                class="btn btn-sm btn-flat-default"
                v-on:click.stop
                target="_blank"
                @click="goToTripDetail(item.trip_id)"
            >
              <i class="icon-abui-globe"/>
            </a>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import mixins from '@/helpers/mixins';
import {HOLD} from '@/constants/';
import {ORDER_TYPE_REQUEST_LIST} from '@/constants';
import {VueDraggableNext} from 'vue-draggable-next';

export default {
  name: 'DataTable',
  emits: ['listenDisableDownload', 'listenDisableApprove', 'sortDataTable'],
  mixins: [mixins],
  components: {
    draggable: VueDraggableNext
  },
  async created() {
    await this.$services.account.current(
        res => {
          this.$store.dispatch('auth/initCurrentData', res);
        },
        () => {
          this.logout();
        },
    );
    this.filterHeaderData();
    this.setApprovalOrReport();
  },
  data() {
    return {
      isDragging: false, // Initialize the isDragging state
      sortActive: {
        sort_column: '',
        sort_by: '',
        locale: this.$i18n.locale,
      },
      allSelected: false,
      submitApplications: [],
      nameHeaders: {
        checkbox: "",
        approval_or_report: "application.table.approval_or_report",
        expense_settlement_status: "application.table.expense_column",
        date: "application.table.application_date",
        application_type: "application.table.application_type",
        applicant_name: "application.table.applicant",
        code: "application.table.code",
        trip_name: "trip.trip",
        approver_name: "application.table.authorizer",
        status: "abc",
      },
      classHeaders: {
        checkbox: "cell-control fixed-colum width-33p",
        approval_or_report: "width-833-per",
        expense_settlement_status: "width-833-per",
        date: "width-833-per",
        application_type: "",
        applicant_name: "mw-100p",
        code: "mw-200p",
        trip_name: "mw-250p",
        approver_name: "mw-200p",
        status: "",
      },
      headers: this.requestOrderData || [
        "checkbox",
        "approval_or_report",
        "expense_settlement_status",
        "date",
        "application_type",
        "applicant_name",
        "code",
        "trip_name",
        "approver_name",
        "status",
      ],
      draggableOptions: {
        draggable: '.draggable-column',
        handle: '.drag-handle',
        filter: '.fixed-column'
      }
    };
  },
  props: {
    applicationData: {
      type: Array,
      default: () => [],
    },
    listApplicationSelected: {
      type: Array,
      default: () => [],
    },
    resetCheckAll: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    requestOrderData() {
      return this.$store.state.auth?.user?.order_setting?.request || [
        "checkbox",
        "approval_or_report",
        "expense_settlement_status",
        "date",
        "application_type",
        "applicant_name",
        "code",
        "trip_name",
        "approver_name",
        "status",
      ];
    },
    showApprovalExpense() {
      return this.$store.state.auth?.user?.setting?.is_feature_payment || false;
    },
    isShowSettingApproval() {
      return this.$store.state.auth?.user?.setting?.is_show_approval || false;
    },
    isShowSettingReport() {
      return this.$store.state.auth?.user?.setting.is_show_report || false;
    },
    sortData() {
      return [...this.applicationData];
    },
    userId() {
      return this.$store.state.auth?.user?.id || '';
    },
  },
  watch: {
    requestOrderData() {
      if (this.requestOrderData) {
        this.headers = this.requestOrderData;
        this.filterHeaderData();
      }
    },
    sortData(newVal) {
      if (!this.checkEmpty(newVal)) {
        newVal.every((element, index) => {
          if (
              this.checkEmpty(this.submitApplications) ||
              (this.canSelectForApproving(index) && !this.submitApplications.includes(element.id))
          ) {
            this.allSelected = false;
            return false;
          } else {
            this.allSelected = true;
            return true;
          }
        });
        return;
      }
      this.allSelected = false;
    },
    submitApplications: {
      handler(newVal) {
        if (this.checkEmpty(newVal)) {
          this.allSelected = false;
          // this.$emit('listenDisableDownload', true);
          this.$emit('listenDisableApprove', true);
        } else {
          if (!this.checkEmpty(this.sortData)) {
            this.sortData.every((element, index) => {
              if (
                  this.checkEmpty(newVal) ||
                  (this.canSelectForApproving(index) && !this.submitApplications.includes(element.id))
              ) {
                this.allSelected = false;
                return false;
              } else {
                this.allSelected = true;
                return true;
              }
            });
          } else {
            this.allSelected = false;
          }

          // this.$emit('listenDisableDownload', false, this.submitApplications);
          this.$emit('listenDisableApprove', false, this.submitApplications);
        }
      },
      deep: true,
    },
    resetCheckAll(newVal) {
      if (newVal) {
        this.allSelected = false;
        this.submitApplications = [];
      }
    },
    listApplicationSelected(newVal) {
      if (newVal.length == 0) {
        this.allSelected = false;
        this.submitApplications = [];
      }
    },
  },
  methods: {
    setApprovalOrReport() {
      if (this.isShowSettingApproval && !this.isShowSettingReport) {
        this.nameHeaders.approval_or_report = "application.table.approval";
      } else if (!this.isShowSettingApproval && this.isShowSettingReport) {
        this.nameHeaders.approval_or_report = "application.table.report";
      }
    },
    onColumnChange() {
      console.log('Column order changed');
      console.log(this.headers);
    },
    async filterHeaderData() {
      if (!this.showApprovalExpense) {
        this.headers = this.headers.filter(item => {
          return item != 'expense_settlement_status'
        });
      }
      if (!this.isShowSettingApproval) {
        this.headers = this.headers.filter(item => {
          return item != 'approval_or_report'
        });
      }
    },
    onStart() {
      this.isDragging = true
    },
    async saveColumns() {
      this.isDragging = false
      let dataHeader = [...this.headers];
      if (!this.headers.includes('expense_settlement_status')) {
        var index = this.requestOrderData.indexOf('expense_settlement_status');
        dataHeader.splice(index, 0, 'expense_settlement_status');
      }
      if (!this.headers.includes('approval_or_report')) {
        var index2 = this.requestOrderData.indexOf('approval_or_report');
        dataHeader.splice(index2, 0, 'approval_or_report');
      }
      this.startLoading();
      await this.$services.account.saveOrderColumn(
          {
            content: dataHeader,
            type: ORDER_TYPE_REQUEST_LIST
          },
          () => {
            this.filterHeaderData();
            this.endLoading();
          },
          () => {
            this.endLoading();
          },
      );
    },
    calTextWidth(inputText = '') {
      let font = '14px SpicaNeueP-Regular';
      let canvas = document.createElement('canvas');
      let context = canvas.getContext('2d');
      context.font = font;
      return context.measureText(inputText).width;
    },
    showRequestTitle(item) {
      if (!this.checkEmpty(item.trip_id)) return `${item.trip_code}: ${item?.trip_name}`;
      if (this.checkEmpty(item.trip_id) && item.cost.type_cost == 1) return this.$t('expense.dropdown.option2');
      if (this.checkEmpty(item.trip_id) && item.cost.type_cost == 3) return this.$t('expense.dropdown.option1');
      return '-';
    },
    goToTripDetail(tripId) {
      let routeData = this.$router.resolve({
        name: this.PAGES.TRIP_UPDATE,
        params: {
          id: tripId,
        },
      });
      window.open(routeData.href, '_blank');
    },
    sortAction(name) {
      if (!['status', 'checkbox'].includes(name)) {
        this.sortActive.sort_column = name;
        if (this.sortActive.sort_by == 'dsc') this.sortActive.sort_by = 'asc';
        else this.sortActive.sort_by = 'dsc';
        // //Emit sort action
        const sortParams = {
          sort_by: this.sortActive.sort_by == 'dsc' ? 'desc' : 'asc',
          sort_column: this.sortActive.sort_column,
        };

        this.$emit('sortDataTable', sortParams);
      }
    },
    selectAll() {
      this.allSelected = !this.allSelected;
      if (this.allSelected) {
        if (!this.checkEmpty(this.sortData)) {
          this.sortData.forEach((item, index) => {
            if (this.submitApplications.indexOf(item.id) === -1 && this.canSelectForApproving(index))
              this.submitApplications.push(item.id);
          });
        }
        return;
      }

      if (!this.checkEmpty(this.sortData)) {
        this.sortData.forEach(item => {
          const requestIndex = this.submitApplications.indexOf(item.id);
          if (requestIndex !== -1) {
            this.submitApplications.splice(requestIndex, 1);
          }
        });
      }
    },
    convertApplicationType(type) {
      switch (type) {
        case 1:
          return this.$t('application.type.approval');
        case 2:
          return this.$t('application.type.report');
        case 3:
          return this.$t('application.type.expense_reimbursement');
        default:
          return '-';
      }
    },
    canSelectForApproving(requestIndex) {
      if (
          this.sortData[requestIndex]?.flag_withdrawal === 1
      ) {
        return false;
      }

      if (
          this.checkEmpty(this.sortData) ||
          (!this.checkEmpty(this.sortData[requestIndex]) && this.checkRequestRejected(this.sortData[requestIndex]))
      )
        return false;
      const curStep = this.sortData[requestIndex]?.current_step || '';
      let canSubmit = false;
      let hasHoldOn = false;
      this.sortData[requestIndex]?.approval_flow_detail[curStep - 1]?.step_approver.forEach(element => {
        if (element.request_status === HOLD) {
          hasHoldOn = true;
          return;
        }
        hasHoldOn = false;
      });

      //1.Can submit when in current step don't have HoldOn status and requestStatus of current user is null.
      //2.Can submit when current user held on current step.
      this.sortData[requestIndex]?.approval_flow_detail[curStep - 1]?.step_approver.forEach(element => {
        if (element.approver_id === this.userId && element.request_status === HOLD) {
          canSubmit = true;
          return;
        }
        if (element.approver_id === this.userId && element.request_status === null && !hasHoldOn) {
          canSubmit = true;
          return;
        }
      });

      if (this.sortData[requestIndex]?.approval_status && canSubmit) {
        return this.sortData[requestIndex]?.approval_status?.id != 6; //APPROVAL_APPROVED_STATUS_ID = 6;
      }

      if (this.sortData[requestIndex]?.report_status && canSubmit) {
        return this.sortData[requestIndex]?.report_status?.id != 6; //REPORT_APPROVED_STATUS_ID = 6;
      }

      if (this.sortData[requestIndex]?.expense_settlement && canSubmit) {
        return this.sortData[requestIndex]?.expense_settlement?.id != 5; //SETTLEMENT_APPROVED_STATUS_ID = 5;
      }

      return canSubmit;
    },
    checkRequestRejected(request) {
      let flag = false;
      if (!this.checkEmpty(request.expense_settlement) && request.expense_settlement.id == 4) {
        flag = true;
      } else if (!this.checkEmpty(request.report_status) && request.report_status.id == 5) {
        flag = true;
      } else if (!this.checkEmpty(request.approval_status) && request.approval_status.id == 5) {
        flag = true;
      }

      return flag;
    },
    sortColumnByLocale(column1) {
      return column1 + '-' + this.$i18n.locale;
    },
    textToApprovalOrReport(item) {
      if (!this.checkEmpty(item.report_status)) return this.labelLocale(item.report_status);
      if (!this.checkEmpty(item.approval_status)) return this.labelLocale(item.approval_status);
      return '-';
    },
    styleToApprovalOrReport(item) {
      if (item?.flag_withdrawal === 1) {
        return '';
      }
      if (!this.checkEmpty(item.report_status)) return item.report_status.color;
      if (!this.checkEmpty(item.report_status) && !this.checkEmpty(item.approval_status))
        return item.approval_status.color;
      return '';
    },
    textToExpenseColumn(item) {
      if (!this.checkEmpty(item.expense_status)) return this.labelLocale(item.expense_status);
      return '-';
    },
    selectApplication(item) {
      const requestIndex = this.submitApplications.indexOf(item.id);
      if (requestIndex !== -1) {
        this.submitApplications.splice(requestIndex, 1);
      } else {
        this.submitApplications.push(item.id);
      }
    },
    pushToTripItem(item) {
      this.$router.push({
        name: this.PAGES.TRIP_ITEM,
        params: {
          id: item.id,
        },
        query: {
          date: this.showDateTime(item.date, 'YYYY/MM/DD'),
          // Approval - report or expense request
          type: item.type,
        },
      });
    },
  },
};
</script>
<style scoped>
.width-33p {
  width: 33px !important;
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: #fff;
}

.fixed-column {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: #fff;
}

.ml-12p {
  margin-left: 12px !important;
}

.el-tooltip__trigger {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
