<template>
  <div class="dashboard-head-title-flex">
    <h6 class="mbn">
      <i class="icon-abui-bell prx"></i>
      {{ $t('dashboard.notifications.title') }}
    </h6>
    <a
      href="#"
      class="plm"
      style="display: inline-block; font-size: 13px;"
      @click="this.$router.push(this.PAGES.INFORMATION)"
    >
      <i class="icon-abui-arrow-right prx"></i>
      {{ $t('common.all') }}
    </a>
  </div>

  <ul class="list--dashboard-news">
    <li
      v-for="(item, index) in listNotifications"
      :key="index"
      @click="
        $router.push({
          name: PAGES.INFORMATION_DETAIL,
          params: {
            id: item.id,
          },
        })
      "
      class="list--dashboard-news--item dummy-infomation--rejected"
    >
      <div class="news--date">
        {{ showDateTime(item?.send_time, 'YYYY-MM-DD HH:mm') }}
      </div>
      <div class="news--text">
        <span
          v-if="item.notification_type != 2 && !checkEmpty(item.trip_id)"
          @click.stop
        >
          <span @click.stop>
            {{ handleTitleInformation(item)[0] }}
            {{ item.trip_code }}
          </span>
          {{ handleTitleInformation(item)[1] }}
        </span>
        <span v-else-if="!checkEmpty(item.is_view_passport)">
          {{ $t('information.my_passport_is_about_to_expire') }}
        </span>
        <span
          v-else-if="
            checkEmpty(item.trip_id) &&
            checkEmpty(item.is_view_passport) &&
            (
              item?.notification_type == 4 ||
              item?.notification_type == 6
            )
          "
          @click.stop
        >
          {{
            $t('information.check_claim_for_non_travel_related_expense') +
            item?.title
          }}
        </span>
        <span v-else @click.stop>
          {{ item?.title }}
        </span>
      </div>
      <span class="news--type">
        {{ getNotificationTypeLabel(item.notification_type) }}
      </span>
    </li>
  </ul>
  <p v-if="listNotifications.length == 0" class="no-data">
    {{ $t('common.no_data_notification') }}
  </p>
</template>

<script>
export default {
  name: 'Notification',
  data() {
    return {
      listNotifications: [],
    }
  },
  computed: {
    userId() {
      return this.$store.state.auth?.user?.id || ''
    },
  },
  created() {
    this.getListNotifications()
  },
  methods: {
    async getListNotifications() {
      this.startLoading()
      await this.$services.notification.getListNotifications(
        { un_read: 1 },
        (res) => {
          this.endLoading()
          this.listNotifications = res.list
        },
        (err) => {
          this.endLoading()
          this.notifyError(this.$t(err.error))
        },
      )
    },
    checkCondition(item) {
      if (
        !this.checkEmpty(item?.trip_id) ||
        !this.checkEmpty(item?.request_id) ||
        !this.checkEmpty(item?.is_view_passport)
      ) {
        return true
      }

      return false
    },
    redirectNotification(item) {
      let routeData
      if (!this.checkEmpty(item.trip_id)) {
        routeData = this.$router.resolve({
          name: this.PAGES.TRIP_UPDATE,
          params: {
            id: item.trip_id,
          },
        })
      } else if (!this.checkEmpty(item.request_id)) {
        routeData = this.$router.resolve({
          name: this.PAGES.TRIP_ITEM,
          params: {
            id: item.request_id,
          },
        })
      } else {
        routeData = this.$router.resolve({
          name: this.PAGES.EMPLOYEE_DETAIL,
          params: {
            id: this.userId,
          },
        })
      }
      window.open(routeData.href, '_blank')
    },
    handleTitleInformation(item) {
      if (this.checkEmpty(item.trip_code)) return item.title
      let newTitle = item.title.split(item.trip_code)

      return newTitle
    },
  },
}
</script>
