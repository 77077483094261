export const AIRPLANE = 1;
export const TRAIN = 2;
export const PICK_DROP = 3;
export const TAXI = 4;
export const PUBLIC_TRANSPORT = 5;
export const HOTEL = 6;
export const MEETING = 7;
export const MEETING2 = 8;
export const OPPORTUNITY = 9;
export const SALE = 10;
export const EXHIBITION = 11;
export const EVENT = 12;
export const DINER = 13;
export const MEAL = 14;
export const HOLIDAY = 15;
export const SIGHTSEEING = 16;
export const OTHER = 17;
export const CAR_RENTAL = 18;
