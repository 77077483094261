<template>
  <div class="modal--password-reset" id="modal--password-reset">
    <div class="modal-dialog">
      <div class="modal-content">
        <button type="button" class="close" @click="$emit('close-popup')">
          <span class="icon-abui-cross" aria-hidden="true"></span>
        </button>
        <div class="modal-header">
          <div class="modal-header--title text-center">{{ $t('forgot_password.forget_your_password') }}</div>
        </div>
        <div class="modal-body">
          <Form>
            <p class="modal--diaglog--text mbl">
              {{ $t('forgot_password.forget_your_password_note') }}
            </p>
            <div class="row">
              <div class="col-xs-12 col-sm-6 col-md-6 col-sm-offset-3 col-md-offset-3">
                <div class="">
                  <div class="form-group">
                    <label>
                      <span class="label-text">{{ $t('forgot_password.company_code') }}</span>
                    </label>
                    <Field
                      name="pw_company_code"
                      type="text"
                      class="form-control"
                      maxlength="50"
                      v-model="passwordForget.company_code"
                      :rules="validateCompanyCode"
                      :placeholder="$t('common.please_input')"
                    />
                    <ErrorMessage name="pw_company_code" class="error-feedback" as="p" />
                    <p
                      class="error-feedback"
                      v-text="
                        errors_message.company_code &&
                        $t(errors_message.company_code[0], { item: $t('forgot_password.company_code') })
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12 col-sm-6 col-md-6 col-sm-offset-3 col-md-offset-3">
                <div class="">
                  <div class="form-group">
                    <label>
                      <span class="label-text">{{ $t('forgot_password.user_id') }}</span>
                    </label>
                    <Field
                      name="pw_user_id"
                      type="text"
                      class="form-control"
                      maxlength="50"
                      v-model="passwordForget.user_id"
                      :rules="validateUserId"
                      :placeholder="$t('common.please_input')"
                    />
                    <ErrorMessage name="pw_user_id" class="error-feedback" as="p" />
                    <p
                      class="error-feedback"
                      v-text="
                        errors_message.employee_number &&
                        $t(errors_message.employee_number[0], { item: $t('forgot_password.user_id') })
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
        <div class="modal-footer pts">
          <div class="row">
            <div class="col-xs-12 col-sm-6 col-md-6 col-sm-offset-3 col-md-offset-3">
              <p class="error-feedback" v-text="errors_message.forgotPassword && $t(errors_message.forgotPassword)" />
              <button
                class="btn btn-primary btn-block js-send-reset-password open-alert"
                :disabled="!passwordForget.company_code.trim() || !passwordForget.user_id.trim()"
                @click="sendPasswordReset"
              >
                <i class="icon-abui-send prx"></i>{{ $t('forgot_password.send') }}
              </button>
            </div>
          </div>
          <p class="note text-center ptl mtl mbn">
            {{ $t('forgot_password.forget_your_password_note2') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';

export default {
  name: 'ForgotPassword',
  emits: ['close-popup'],
  components: { Form, Field, ErrorMessage },
  data() {
    return {
      errors_message: {
        forgotPassword: '',
      },
      passwordForget: {
        company_code: '',
        user_id: '',
      },
    };
  },
  computed: {
    validateUserId() {
      return yup
        .string()
        .trim()
        .required(this.$t('E-CM-100', { item: this.$t('login.user_id') }));
    },
    validateCompanyCode() {
      return yup
        .string()
        .trim()
        .required(this.$t('E-CM-100', { item: this.$t('login.company_code') }));
    },
  },
  methods: {
    sendPasswordReset() {
      this.startLoading();
      this.errors_message = {
        company_code: '',
        employee_number: '',
        forgotPassword: '',
      };

      this.$services.account.forgotPassword(
        {
          employee_number: this.passwordForget.user_id,
          company_code: this.passwordForget.company_code,
        },
        () => {
          this.endLoading();
          this.$emit('close-popup');
          this.notifySuccess(this.$t('E-CU-065'));
        },
        () => {
          this.endLoading();
          this.errors_message = {
            forgotPassword: this.$t('E-CU-030'),
          };
        },
      );
    },
  },
};
</script>
