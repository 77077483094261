import BaseService from './BaseService';
import API_CODE from '../utils/api_code.js';

export default class RequirementAPI extends BaseService {
  async getListInput(params, query, success, error) {
    await this.get(API_CODE.OP_101 + this.urlParse(params, query), success, error);
  }
  async getInputDetail(id, success, error) {
    await this.get(API_CODE.OP_111 + id, success, error);
  }
  async getListInputStatus(success, error) {
    await this.get(API_CODE.CU_099, success, error);
  }
  async getListCountry(success, error) {
    await this.get(API_CODE.CU_069, success, error);
  }
  async saveInput(id, params, success, error) {
    await this.post(API_CODE.OP_109 + id, params, success, error);
  }
  async updateInput(id, params, success, error) {
    await this.post(API_CODE.CU_313 + id, params, success, error);
  }
  async editingInput(id, success, error) {
    await this.post(API_CODE.OP_131 + id, {}, success, error);
  }
  async publishInput(id, params, success, error) {
    await this.post(API_CODE.OP_112 + id, params, success, error);
  }
  async nextDataInput(id, params, success, error) {
    await this.post(API_CODE.OP_113 + id, params, success, error);
  }
  async updateStatus(id, params, success, error) {
    await this.post(API_CODE.OP_309 + id, params, success, error);
  }
  async getRateApplicableDateByCompany(id, success, error) {
    await this.get(API_CODE.OP_200 + id, success, error);
  }
  async deleteInput(id, success, error) {
    await this.delete(API_CODE.CU_320 + id, {}, success, error);
  }
}
