<template>
  <div class="wrapper-page">
    <nav class="navbar navbar-default navbar-lg" role="navigation" id="header-content">
      <div class="navbar-header">
        <h2 class="navbar-title">{{ $t('approval_flow.approval_flow') }}</h2>
      </div>
    </nav>
    <ApprovalFlowForm @onChangData="onChangData" :dataForm="dataApprovalFlow" :isDetail="isDetail" />
  </div>
</template>
<script>
import ApprovalFlowForm from '@/components/approval-flow/ApprovalFlowForm';
export default {
  name: 'UpdateApprovalFlow',
  components: { ApprovalFlowForm },
  data() {
    return {
      dataApprovalFlow: {},
      isDetail: false,
      isChangeData: false,
    };
  },
  beforeRouteLeave(to, from, next) {
    if (this.isChangeData) {
      const answer = window.confirm(this.$t('common.confirm_leave_page'));
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  created() {
    this.getDetail();
  },
  methods: {
    onChangData(val) {
      this.isChangeData = val;
    },
    async getDetail() {
      const id = this.$route.params.id;
      this.startLoading();
      await this.$services.approval_flow.approvalFlowDetail(
        id,
        res => {
          this.dataApprovalFlow = res;
          this.isDetail = true;
          this.endLoading();
        },
        err => {
          // this.endLoading();
          this.notifyError(this.$t(err.error));
          this.$router.push(this.PAGES.APPROVAL_FLOW);
        },
      );
    },
  },
};
</script>
<style></style>
