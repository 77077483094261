<template>
  <div class="filter-result js-filter-result--minimization">
    <div class="section noborder">
      <Pagination
        v-if="listOrganizations.length"
        :paginate="paginate"
        :current-number="+paginate.currentPage"
        @change-page="changePageData"
        :hasOptionPaginate="false"
      />

      <div class="hidden-xs">
        <div class="table-responsive">
          <table class="table table-hover table-hover--pointer table--org-list" id="">
            <colgroup span="1" style=""></colgroup>
            <colgroup span="1" style=""></colgroup>
            <colgroup span="1" style=""></colgroup>
            <colgroup span="1" style="width: 300px"></colgroup>
            <thead>
              <tr>
                <th>{{ $t('organization.code') }}</th>
                <th>{{ $t('organization.burden_organization') }}</th>
                <th>{{ $t('organization.parent_organization') }}</th>
                <th>{{ $t('organization.child_organization') }}</th>
                <th>{{ $t('employee.is_deleted') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(organization, index) in listOrganizations"
                :class="organization.deleted && 'text-muted'"
                :key="index"
                @click="onRedirectOrganizationItemPage(organization)"
              >
                <td>
                  {{ organization.code }}
                </td>
                <td>
                  {{ organization?.burden_organization || organization.name }}
                </td>
                <td>
                  <template v-if="organization.type == 1">
                    <i
                      v-if="organization?.parent_organization || organization.name"
                      class="org-divider icon-abui-arrow-right"
                    >
                    </i>
                    {{ organization?.parent_organization || organization.name }}
                  </template>
                </td>
                <td>
                  <i v-if="organization.parent_organization" class="org-divider icon-abui-arrow-right"> </i>
                  {{ organization?.parent_organization && organization.name }}
                </td>
                <td>
                  {{ organization.deleted ? $t('employee.deleted') : '' }}
                </td>
              </tr>
            </tbody>
          </table>
          <p v-if="!listOrganizations.length" class="no-data">{{ $t('security.no_data') }}</p>
        </div>
      </div>

      <Pagination
        v-if="listOrganizations.length"
        :paginate="paginate"
        :current-number="+paginate.currentPage"
        @change-page="changePageData"
        :hasOptionPaginate="false"
      />
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/common/Pagination';

export default {
  name: 'DataTableResult',
  components: {
    Pagination,
  },
  props: {
    listOrganizations: {
      type: Array,
      default: () => [],
    },
    paginate: {
      type: Object,
      default: () => {
        return {
          page: 1,
          perPage: 1,
          from: 1,
          to: 1,
          lastPage: 1,
          total: 1,
        };
      },
    },
  },
  methods: {
    changePageData(page) {
      this.$emit('changePageData', page);
    },

    onRedirectOrganizationItemPage(organization) {
      this.$router.push({
        name: this.PAGES.ORGANIZATION_DETAIL,
        params: {
          id: organization.id,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
td.text-over {
  width: 191px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  p {
    margin-bottom: 0px;
    font-size: 14px;
    display: inline-block;
    width: 191px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
