<template>
  <div class="sheet mhn mtn">
    <div class="sheet-header">
      <h5 class="sheet-title">
        <span>
          {{ $t('dashboard.approval_pending.title') }}
          <a
            class="cursor-pointer plm"
            @click="
              $router.push({
                path: PAGES.APPLICATION,
                query: {
                  page: 1,
                  per_page: 10,
                  sort_by: 'asc',
                  sort_column: 'date',
                  approval_status: '[3,4]',
                  reporting_status: '[3,4]',
                  expense_status: '[2,3]',
                  des_abroad: 0,
                  des_domestic: 0,
                  is_approval_or_report: 0,
                  is_expense_reimbursement: 0,
                  is_applicant_or_approver: 2,
                  isRequestInMyTurn: 1,
                  is_search_activated: 1,
                  search_from_dashboard: 1,
                },
              })
            "
            style="font-size: 13px"
          >
            <i class="icon-abui-arrow-right prx"></i>{{ $t('common.show_all_request') }}
          </a>
        </span>
      </h5>
    </div>
    <div class="table-responsive">
      <table class="table table-hover table-hover--pointer" id="table--plans">

        <colgroup span="1" :style="$i18n.locale == 'en' ? 'width: 10em' : 'width: 7em'"></colgroup>
        <colgroup span="1" style="width: 12em"></colgroup>
        <colgroup span="1" style=""></colgroup>
        <colgroup span="1" :style="$i18n.locale == 'en' ? 'width: 14em' : 'width: 7em'"></colgroup>
        <thead>
          <tr>
            <th class="cell-date">{{ $t('dashboard.approval_pending.created_at') }}</th>
            <th>{{ $t('dashboard.approval_pending.created_by') }}</th>
            <th>{{ $t('trip.trip') }}</th>
            <th>{{ $t('dashboard.approval_pending.type') }}</th>
          </tr>
        </thead>
        <tbody v-if="listTrips">
          <tr
            v-for="(item, index) in listTrips"
            :key="index"
            @click="goRequestPage(item)"
          >
            <td>{{ showDateTime(item.date, 'YYYY/MM/DD（ddd）') }}</td>
            <td>{{ item.old_applicant_name }}</td>
            <td>{{ showRequestTitle(item) }}</td>
            <td>{{ getStatusLabel(item) }}</td>
          </tr>
        </tbody>
      </table>
      <span v-if="showMoreRequest" style="width: 100%; display: flex; justify-content: end">
        <button
          class="cursor-pointer plm btn btn-flat-default"
          @click="
            $router.push({
              path: PAGES.APPLICATION,
              query: {
                page: 1,
                per_page: 10,
                sort_by: 'asc',
                sort_column: 'date',
                approval_status: '[3,4]',
                reporting_status: '[3,4]',
                expense_status: '[2,3]',
                des_abroad: 0,
                des_domestic: 0,
                is_approval_or_report: 0,
                is_expense_reimbursement: 0,
                is_applicant_or_approver: 2,
                isRequestInMyTurn: 1,
                is_search_activated: 1,
                search_from_dashboard: 1,
              },
            })
          "
          style="font-size: 13px"
        >
          {{ $t('dashboard.show_request_list') }}
        </button>
      </span>
      <p v-if="listTrips.length == 0" class="no-data">{{ $t('common.no_data_request') }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RegulationCheck',
  data() {
    return {
      listTrips: [],
      showMoreRequest: false,
    };
  },
  created() {
    this.getListTrips();
  },
  methods: {
    getStatusLabel(item) {
      let result = '';

      if (item.expense_settlement_status && item.expense_settlement_status.length > 2) {
        return this.$i18n.locale == 'en' ? item.expense_settlement_status : item.expense_settlement_status.substring(0, 2);
      }

      if (item.approval_or_report && item.approval_or_report.length > 2) {
        return this.$i18n.locale == 'en' ? item.approval_or_report : item.approval_or_report.substring(0, 2);
      }

      return result;
    },
    goRequestPage(item) {
      localStorage.setItem('hoild_ids', JSON.stringify([]));
      this.$router.push({
        name: this.PAGES.TRIP_ITEM,
        params: {
          id: item.id,
        },
        query: {
          //Request date
          date: this.showDateTime(item.date, 'YYYY/MM/DD'),
          //Request type(approval, report or expense)
          type: item.type,
        },
      });
      return;
    },
    showRequestTitle(item) {
      if (!this.checkEmpty(item.trip_id)) return `${item.trip_code}: ${item?.trip_name}`;
      if (this.checkEmpty(item.trip_id) && item.cost.type_cost == 1) return this.$t('expense.dropdown.option2');
      if (this.checkEmpty(item.trip_id) && item.cost.type_cost == 3) return this.$t('expense.dropdown.option1');
      return '-';
    },
    async getListTrips() {
      this.startLoading();
      let queryBuilder =
        'approval_status[]=3&approval_status[]=4&reporting_status[]=3&reporting_status[]=4&expense_status[]=2&expense_status[]=3';
      await this.$services.application.getListApplications(
        {
          'expected_approval[]': 1,
          is_applicant_or_approver: 2,
          sort_by: 'asc',
          sort_column: 'date',
          page: 1,
          per_page: 6,
        },
        queryBuilder,
        res => {
          this.endLoading();
          if (res.list.length < 6) {
            this.listTrips = res.list;
          } else {
            this.showMoreRequest = true;
            res.list.forEach((element, index) => {
              if (index < 5) this.listTrips.push(element);
            });
          }
        },
        err => {
          this.endLoading();
          this.notifyError(this.$t(err.error));
        },
      );
    },
  },
};
</script>
