<template>
  <el-select
    v-model="selectedItem"
    :placeholder="$t('trip_item.right_menu.manual_input_model.unspecified')"
    popper-class="custom-dropdown-select"
    :popper-append-to-body="false"
  >
    <div v-if="!checkEmpty(listData)">
      <el-option v-for="item in listData" :key="item.id" :label="labelLocale(item)" :value="item.id">
        <div class="el-custom-select-dropdown">
          <span class="dropdown-option-name">
            {{ labelLocale(item) }}
          </span>
        </div>
        <li class="divider" />
        <hr />
      </el-option>
    </div>
    <div v-else>
      <p class="no-data">{{ $t('common.no_data') }}</p>
    </div>
  </el-select>
</template>
<script>
import mixins from '@/helpers/mixins';

export default {
  name: 'SingleGroupOptionSelectCity',
  emits: ['handleSelectedParams'],
  mixins: [mixins],
  props: {
    listData: {
      type: Array,
      default: () => [],
    },
    labelShow: {
      type: String,
      default: () => 'name',
    },
    initItem: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      selectedItem: '',
    };
  },
  created() {},
  watch: {
    selectedItem() {
      this.$emit('handleSelectedParams', this.selectedItem);
    },
    initItem(val) {
      this.selectedItem = val;
    },
  },
  methods: {},
};
</script>
<style scoped>
.dropdown-option-icon {
  margin-top: 50%;
}
.dropdown-option-icon span.first-icon.icon-abui-checkbox-unchecked {
  font-size: 15px;
  color: rgba(152, 169, 176, 0.5);
}
.dropdown-option-icon span.second-icon.icon-abui-checkbox-checked {
  font-size: 15px;
  color: rgba(255, 255, 255, 0.8) !important;
}
.bs-searchbox {
  margin-bottom: 5px;
}
.no-hover {
  pointer-events: none !important;
}
.border {
  border-bottom: 1px solid #d0d6da;
}
</style>
