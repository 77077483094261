<template>
  <nav class="navbar navbar-default navbar-lg" role="navigation">
    <div class="navbar-header">
      <h2 class="navbar-title">{{ $t('approval_flow.approval_flow') }}</h2>
    </div>
    <div class="collapse navbar-collapse">
      <ul class="nav navbar-nav navbar-right">
        <li>
          <a href="#" @click="downloadFileCSV">
            <span class="icon-abui-download prx"></span>{{ $t('employee.download_in_csv') }}
          </a>
        </li>
        <li>
          <a href="#" @click="$router.push(PAGES.APPROVAL_NEW)">
            <span class="icon-abui-plus prx"></span>
            {{ $t('employee.add_new') }}
          </a>
        </li>
        <li class="dropdown addNew hidden-sm">
          <a
            href="#"
            class="dropdown-toggle pls"
            @click="isShowDropdown = !isShowDropdown"
            v-click-outside="
              () => {
                isShowDropdown = false;
              }
            "
          >
            <b class="caret"></b>
          </a>
          <span class="dropdown-arrow"></span>
          <ul class="dropdown-menu" :class="isShowDropdown && 'showDropdown'" style="left: -200px">
            <li>
              <a href="#" @click="showModalUploadCSV = true">
                <span class="icon-abui-upload prx" />{{ $t('employee.batch_registration_from_csv') }}
              </a>
            </li>
            <li class="divider"></li>
            <li>
              <a href="#" @click="downloadExampleFileCSV">
                <span class="icon-abui-download prx"></span>{{ $t('employee.download_csv_formate') }}
              </a>
            </li>
            <li>
              <a href="https://www.iace.co.jp/travelmgr/pdf/import_sample/shounin" target="_blank" >
                <span class="icon-abui-book prx" />{{ $t('common.manual_registration') }}
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </nav>
  <el-dialog
    v-model="showModalListAuthor"
    :show-close="false"
    width="700px"
    :before-close="closeModalUpdateAuthor"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-header--title">{{ $t('approval_flow.author_user') }}</div>
        </div>
        <div class="modal-body">
          <div class="section noborder">
            <div class="add-selected">
              <div class="row">
                <div class="col-sm-12 col-md-12">
                  <div class="add-selected__wrap">
                    <div class="add-selected__wrap__list">
                      <ul class="add-selected-list">
                        <li v-for="(item, index) in listAuthorsRender" :key="index">
                          {{ item.employee_number }}： {{ item.name }}
                          <span
                            v-if="listAuthorsRender.length > 0"
                            class="icon icon-abui-cross"
                            @click="removeAuthor(item, index)"
                          >
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div class="add-selected__wrap__btn" @click="deleteAllAuthor">
                      <button class="btn btn-sm">
                        <span class="icon-abui-trash"></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-12">
                  <div>
                    <table class="table table-striped table-word-seach">
                      <thead>
                        <tr>
                          <th>
                            <div class="input-group input-group-sm input-group-rounded" :class="isActive && 'focus'">
                              <span class="input-group-btn">
                                <button type="submit" class="btn"><span class="icon-abui-search"></span></button>
                              </span>
                              <input
                                @focus="isActive = true"
                                @blur="isActive = false"
                                type="text"
                                v-model="searchKeyword"
                                class="form-control"
                                :placeholder="$t('approval_flow.search')"
                              />
                            </div>
                          </th>
                        </tr>
                      </thead>
                    </table>
                    <div class="innerScrollFade">
                      <div style="overflow: auto; max-height: calc(100vh - 300px)">
                        <table class="table table-striped table-hover table-word-seach__body table-hover--pointer">
                          <colgroup span="1"></colgroup>
                          <colgroup span="1" style="width: 96px"></colgroup>
                          <colgroup span="1"></colgroup>
                          <colgroup span="1" style="width: 88px"></colgroup>
                          <tbody>
                            <tr v-for="(item, index) in listFilter" :key="index">
                              <td>{{ item.employee_number }}</td>
                              <td style="min-width: 150px">{{ item.name }}</td>
                              <td>{{ item.organization }}</td>
                              <td>{{ item.position ? item.position : '' }}</td>
                              <td class="cell-control" @click="addAuthor(item)">
                                <a class="btn btn-flat-default btn-sm" href="#fakelink">
                                  <span class="icon-abui-plus"></span>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <p v-if="listFilter.length == 0" class="no-data">{{ $t('common.no_data') }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="row">
            <div class="col-xs-6 col-sm-6 col-md-6">
              <button class="btn btn-flat-default btn-block" @click="closeModalUpdateAuthor">
                {{ $t('common.cancel') }}
              </button>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6">
              <button class="btn btn-flat-primary btn-block btn--feedback" @click="updateListAuthor">
                {{ $t('common.confirm') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
  <div class="filter-wrap">
    <div class="filter" v-show="showBoxFilter">
      <div class="section section-filter noborder">
        <div class="row">
          <div class="col-xs-12 col-sm-6 col-md-6">
            <div class="form-group">
              <label>{{ $t('approval_flow.approval_flow_name') }}</label>
              <input
                v-model="searchParams.name"
                maxlength="30"
                type="text"
                class="form-control input-sm"
                :placeholder="$t('approval_flow.pl_approval_flow_name')"
              />
              <p class="error-feedback" v-text="errors.name"></p>
            </div>
          </div>
          <div class="col-xs-9 col-md-4 col-all-closer col-all-closer--leftend">
            <div class="form-group viewonly">
              <label>{{ $t('approval_flow.list_name') }}</label>
              <p class="viewonly-text input-sm text-ellipsis input--drldn-input2-multi">
                {{ listNameAuthor }}
              </p>
            </div>
          </div>
          <div class="col-xs-3 col-md-2 col-all-closer col-all-closer--rightend">
            <div class="form-group">
              <label></label>
              <button class="btn btn-sm btn-inverse btn-block" style="margin-top: 12px" @click="openModalAuthor()">
                {{ $t('approval_flow.reference') }}
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 col-md-6" v-if="isShowSettingApproval && showApprovalExpense">
            <div class="form-group">
              <label>
                <span class="label-text">{{ $t('approval_flow.request_type') }}</span>
              </label>
              <label
                  v-if="isShowSettingApproval"
                class="checkbox checkbox-inline checkbox-type-div"
                :class="searchParams.is_business_trip && ' checked'"
              >
                <span class="icons">
                  <span class="first-icon icon-abui-checkbox-unchecked"></span>
                  <span class="second-icon icon-abui-checkbox-checked"></span>
                </span>
                <input type="checkbox" v-model="searchParams.is_business_trip" />
                <span class="text_type" style="margin-left: 30px; margin-right: 30px">
                  {{ $t('approval_flow.business') }}
                </span>
              </label>
              <label
                v-if="showApprovalExpense"
                class="checkbox checkbox-inline checkbox-type-div"
                :class="searchParams.is_calculate && ' checked'"
              >
                <span class="icons">
                  <span class="first-icon icon-abui-checkbox-unchecked"></span>
                  <span class="second-icon icon-abui-checkbox-checked"></span>
                </span>
                <input type="checkbox" v-model="searchParams.is_calculate" />
                <span class="text_type" style="margin-left: 30px">{{ $t('approval_flow.calculate') }}</span>
              </label>
            </div>
          </div>
          <div class="col-sm-6 col-md-6"  v-if="isShowSettingApproval">
            <div class="form-group">
              <label>
                <span class="label-text">
                  {{ $t('approval_flow.trip_type') }}
                </span>
              </label>
              <label class="checkbox checkbox-inline checkbox-type-div" :class="searchParams.is_overseas && ' checked'">
                <span class="icons">
                  <span class="first-icon icon-abui-checkbox-unchecked"></span>
                  <span class="second-icon icon-abui-checkbox-checked"></span>
                </span>
                <input type="checkbox" v-model="searchParams.is_overseas" />
                <span class="text_type" style="margin-left: 30px; margin-right: 30px">
                  {{ $t('approval_flow.overseas') }}
                </span>
              </label>
              <label class="checkbox checkbox-inline checkbox-type-div" :class="searchParams.is_domestic && ' checked'">
                <span class="icons">
                  <span class="first-icon icon-abui-checkbox-unchecked"></span>
                  <span class="second-icon icon-abui-checkbox-checked"></span>
                </span>
                <input type="checkbox" v-model="searchParams.is_domestic" />
                <span class="text_type" style="margin-left: 30px; margin-right: 30px">
                  {{ $t('approval_flow.domestic') }}
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /.filter -->

    <div class="section-submit" v-show="showBoxFilter">
      <span class="submit-arrow"></span>
      <div class="row">
        <div class="col-sm-4 col-md-5 hidden-xs">
          <button class="btn btn-sm btn-flat-default btn-clear" @click="clearFilter()">{{ $t('common.clear') }}</button>
        </div>
        <div class="col-sm-4 col-md-2">
          <button class="btn btn-sm btn-primary btn-block" @click="handleSearch()">{{ $t('common.search') }}</button>
        </div>
        <div class="col-sm-4 col-md-5 hidden-xs">
          <button class="btn btn-sm btn-flat-default btn-close" @click="showBoxFilter = false">
            {{ $t('common.close') }}
          </button>
        </div>
      </div>
    </div>
    <!-- /.section-submit -->

    <div
      class="btn-filter-again with-condition"
      v-show="!showBoxFilter"
      @click="showBoxFilter = true"
      style="display: block"
    >
      <div class="filter--condition">
        <span>{{ $t('common.all') }}</span>
        <span style="color: #3650a5">{{ $t('common.reset_conditions') }}</span>
      </div>
    </div>
    <!-- /.btn-filter-again -->

    <div class="filter-result">
      <div class="section noborder">
        <Pagination
          v-if="listApprovalFlows.length > 0"
          @change-page="changePageData"
          :paginate="paginate"
          @change-number="changeNumber"
        />

        <div class="hidden-xs">
          <div class="table-responsive">
            <table class="table table-hover table-hover--pointer table--property-list">
              <thead>
                <tr>
                  <th>{{ $t('approval_flow.approval_flow_name') }}</th>
                  <th>{{ $t('approval_flow.list_name') }}</th>
                  <th v-if="isShowSettingApproval">{{ $t('approval_flow.trip_type') }}</th>
                  <th>{{ $t('approval_flow.request_type') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in listApprovalFlows" :key="index" @click="goToApprovalFlowDetail(item.id)">
                  <td>{{ item.name }}</td>
                  <td>{{ item.authors_name ? item.authors_name : '' }}</td>
                  <td v-if="isShowSettingApproval">{{ getDestinationTypeLabel(item.trip_type) }}</td>
                  <td>{{ getRequestTypeLabel(item.request_type) }}</td>
                </tr>
              </tbody>
            </table>
            <p v-if="listApprovalFlows.length == 0" class="no-data">{{ $t('common.no_data') }}</p>
          </div>
        </div>

        <Pagination
          v-if="listApprovalFlows.length > 0"
          @change-page="changePageData"
          :paginate="paginate"
          @change-number="changeNumber"
        />
      </div>
    </div>
    <ModalUploadCSV
      :dialogVisible="showModalUploadCSV"
      @cancel="showModalUploadCSV = false"
      @confirm="confirmUploadFileCSV"
      :errorValidate="errorValidate"
      :title="$t('common.title_modal_upload_file_csv')"
    />
  </div>
</template>
<script>
import Pagination from '@/components/common/Pagination';
import ModalUploadCSV from '@/components/common/ModalUploadCSV';
import vClickOutside from 'click-outside-vue3';

export default {
  name: 'ApprovalFlow',
  components: { ModalUploadCSV, Pagination },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      isActive: false,
      isShowDropdown: false,
      listNameAuthor: '',
      showModalListAuthor: false,
      listAuthors: [],
      dataRenderId: [],
      listAuthorsToEdit: [],
      listAuthorsRender: [],
      searchKeyword: '',
      searchParams: {
        name: this.$route.query?.name || '',
        is_overseas: this.$route.query?.is_overseas ? this.$route.query.is_overseas == '1' : true,
        is_domestic: this.$route.query?.is_domestic ? this.$route.query.is_domestic == '1' : true,
        is_business_trip: this.$route.query?.is_business_trip ? this.$route.query.is_business_trip == '1' : true,
        is_calculate: this.$route.query?.is_calculate ? this.$route.query.is_calculate == '1' : true,
      },
      showModalUploadCSV: false,
      listApprovalFlows: [],
      showBoxFilter: false,
      paginate: {
        page: +this.$route.query.page || 1,
        per_page: +this.$route.query.per_page || 10,
      },
      errors: {},
      isSearchActivated: this.$route.query.is_search_activated || 0,
      errorValidate: [],
    };
  },
  watch: {
    searchParams: {
      handler: function (newValue) {
        if (this.strLen(newValue.name) > 30) {
          this.errors.name = this.$t('E-CM-111', { size: 30 });
        } else {
          delete this.errors.name;
        }
      },
      deep: true,
    },
    showModalUploadCSV() {
      this.errorValidate = [];
    },
  },
  computed: {
    listFilter() {
      return this.listAuthorsToEdit.filter(item => {
        if (this.searchKeyword === '') return item;

        const keyword = this.searchKeyword.toLowerCase();
        const nameMatches = String(item.name).toLowerCase().indexOf(keyword) > -1;
        const employeeNumberMatches = String(item.employee_number).toLowerCase().indexOf(keyword) > -1;

        return nameMatches || employeeNumberMatches;
      });
    },
    showApprovalExpense() {
      return this.$store.state.auth?.user?.setting?.is_feature_payment || false;
    },
    isShowSettingApproval() {
      return this.$store.state.auth?.user?.setting.is_show_approval || false;
    },
  },
  async created() {
    await this.getCurrentUser()
    if (!this.isShowSettingApproval && !this.showApprovalExpense) {
      this.$router.push(this.PAGES.UNAUTHORIZED);
      return;
    }
    this.getListApprovalFlow();
  },
  methods: {
    async getCurrentUser() {
      await this.$services.account.current(
          res => {
            this.$store.dispatch('auth/initCurrentData', res);
          },
          () => {
            this.logout();
          },
      );
    },
    sortAuthorByName(listAuthor) {
      listAuthor.sort((a, b) => {
        if (a['name'] < b['name']) return -1;
        if (a['name'] > b['name']) return 1;
        return 0;
      });

      return listAuthor;
    },
    closeModalUpdateAuthor() {
      if (this.listAuthors.length > 0) {
        this.showModalListAuthor = false;
      } else {
        this.notifyError(this.$t('E-CU-057'));
      }
    },
    goToApprovalFlowDetail(id) {
      this.$router.push({
        name: this.PAGES.APPROVAL_DETAIL,
        params: {
          id: id,
        },
      });
    },
    removeAuthor(item, index) {
      if (this.listAuthorsRender[index] === item) {
        this.listAuthorsRender.splice(index, 1);
      } else {
        let found = this.listAuthors.indexOf(item);
        this.listAuthorsRender.splice(found, 1);
      }
    },
    addAuthor(item) {
      let check = 0;
      this.listAuthorsRender.forEach(author => {
        if (author.id == item.id) {
          check++;
        }
      });
      if (check > 0) {
        this.notifyError(this.$t('E-CU-058'));
      } else {
        this.listAuthorsRender.push(item);
      }
    },
    deleteAllAuthor() {
      this.listAuthorsRender.splice(0);
    },
    openModalAuthor() {
      (this.showModalListAuthor = true), this.getApproval();
    },
    updateListAuthor() {
      this.showModalListAuthor = false;
      let dataRender = [];
      this.listAuthorsRender.filter(item => {
        dataRender.push(item.name);
        this.dataRenderId.push(item.id);
      });
      this.listNameAuthor = dataRender.join('、');
    },
    getApproval() {
      this.$services.dashboard.getApproval(
        {},
        res => {
          const list = res.list.filter(item => [3, 4, 5, 8].includes(item.role_id));

          this.listAuthors = list;
          this.listAuthorsToEdit = list;
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    changePageData(page) {
      this.paginate.page = +page;
      this.getListApprovalFlow();
    },
    async getListApprovalFlow() {
      const { dataFilter, queryBuild } = this.buildParamsSearch();

      this.startLoading();

      await this.$services.approval_flow.getListApprovalFlow(
        dataFilter,
        queryBuild,
        res => {
          this.endLoading();
          this.listApprovalFlows = res.list;
          this.paginate = { ...this.paginate, ...res.paginate };
          this.showBoxFilter = false;

          //Keep data search
          this.updateRouter(res);
        },
        err => {
          this.endLoading();
          this.notifyError(this.$t(err.error));
        },
      );
    },
    updateRouter(res) {
      let query = {
        page: res.paginate.current_page,
        per_page: res.paginate.per_page,
        name: this.searchParams.name,
        is_overseas: this.searchParams.is_overseas ? '1' : '0',
        is_domestic: this.searchParams.is_domestic ? '1' : '0',
        is_business_trip: this.searchParams.is_business_trip ? '1' : '0',
        is_calculate: this.searchParams.is_calculate ? '1' : '0',

        is_search_activated: this.isSearchActivated ? 1 : 0,
      };
      for (let key in query) {
        if (this.checkEmpty(query[key])) delete query[key];
      }
      this.$router.push({
        path: this.$route.path,
        query: this.isSearchActivated
          ? query
          : {
              page: res.paginate.current_page,
              per_page: res.paginate.per_page,
            },
      });
    },
    changeNumber(number) {
      this.paginate = {
        ...this.paginate,
        page: 1,
        per_page: number,
      };
      this.getListApprovalFlow();
    },
    clearFilter() {
      this.searchParams = {
        name: '',
        is_overseas: true,
        is_domestic: true,
        is_business_trip: true,
        is_calculate: true,
      };
      this.listNameAuthor = '';
    },
    handleSearch() {
      if (!this.checkEmpty(this.errors)) return;

      this.paginate.page = 1;
      this.isSearchActivated = 1;
      this.getListApprovalFlow();
    },
    confirmUploadFileCSV(listFiles) {
      let formData = new FormData();
      if (listFiles.length > 0) {
        formData.append('file', listFiles[0]);
        this.errorValidate = [];
        this.startLoading();
        this.$services.approval_flow.uploadFileCSV(
          formData,
          () => {
            this.endLoading();
            this.showModalUploadCSV = false;
            this.getListApprovalFlow();
          },
          err => {
            this.endLoading();
            if (err.error === 'E-CM-500') {
              this.notifyError(this.$t('E-CM-500'));
            }
            if (err.error?.steps_approve_type_3 && err.error?.steps_approve_type_3[0] === 'E-CM-100') {
              this.notifyError(
                this.$t('E-CM-100', {
                  item: this.$t('approval_flow.step'),
                }),
              );
            }
            if (err.code == 422) {
              this.errorValidate = err.error;
            } else {
              this.notifyError(this.$t(err.error));
            }
          },
        );
      } else {
        this.notifyError(this.$t('E-CM-101', { name: this.$t('common.file') }));
      }
    },
    buildParamsSearch() {
      const dataFilter = {
        name: this.searchParams.name,
        page: this.paginate.page,
        per_page: this.paginate.per_page,
      };

      if (this.$i18n.locale != 'ja') {
        dataFilter.locale = this.$i18n.locale;
      }
      let queryBuild = '';
      if (this.searchParams.is_domestic) queryBuild += 'destination_type[]=1&';
      if (this.searchParams.is_overseas) queryBuild += 'destination_type[]=2&';

      if (this.searchParams.is_business_trip) queryBuild += 'request_type[]=1&';
      if (this.searchParams.is_calculate) queryBuild += 'request_type[]=2&';

      this.dataRenderId.forEach(id => {
        queryBuild += `authors[]=${id}&`;
      });

      if (queryBuild) queryBuild = queryBuild.slice(0, -1);

      return { dataFilter, queryBuild };
    },
    downloadFileCSV() {
      const { dataFilter, queryBuild } = this.buildParamsSearch();

      this.startLoading();

      this.$services.approval_flow.downloadFileCSV(
        dataFilter,
        queryBuild,
        res => {
          this.endLoading();
          if (res.file_path) this.handleDownloadCSV(res.file_path);
        },
        err => {
          this.endLoading();
          this.notifyError(this.$t(err.error));
        },
      );
    },
    downloadExampleFileCSV() {
      this.startLoading();
      this.$services.approval_flow.downloadExampleFileCSV(
        res => {
          this.endLoading();
          if (res.file_path) this.handleDownloadCSV(res.file_path);
        },
        err => {
          this.endLoading();
          this.notifyError(this.$t(err.error));
        },
      );
    },
  },
};
</script>
