<template>
  <div
      v-if="!checkEmpty(trip)"
      class="plan-stage"
      style="padding-bottom: 40px"
      :style="!isActionRingi && 'padding-right: 480px;'"
  >
    <div v-if="!isActionRingi && (isShowSettingApproval || showApprovalExpense)">
      <div v-if="trip?.approval_status?.id == 1 && trip?.approve_message && isShowSettingApproval" class="plan-status status10">
        <span class="plan--code">{{ trip?.code }}</span>
        <span class="status-text">
          {{ labelLocale(trip?.approval_status) }}
          <i class="icon-abui-check-inverted plx"/>
        </span>
        <a
            href="#"
            class="btn-xs btn-flat-default btn-noapply show--status10"
            @click="showBoxConfirm('need-approve')"
            style="display: inline; margin-left: 0"
            v-if="isShowChangeStatusRequest"
        >
          <span class="icon-abui-arrow-right" style="padding-right: 2px"></span>
          {{ $t('trip_update.no_need_for_approval') }}
        </a>
      </div>
      <div v-else-if="trip?.approval_status?.id == 2 && isShowSettingApproval" class="plan-status status10"
           :style="'background-color:' + getBackgroundColor(trip)">
        <span class="plan--code" :style="'color:' + getColorId(trip)">{{ trip?.code }}</span>
        <span class="status-text">
          {{ getStatusLabel(trip) }}
        </span>
        <a
            href="#"
            class="btn-xs btn-flat-default btn-noapply show--status10"
            @click="showBoxConfirm('need-for-approve')"
            style="display: inline; margin-left: 0"
            v-if="isShowChangeStatusRequest"
        >
          <span class="icon-abui-arrow-right" style="padding-right: 2px"></span>
          {{ $t('trip_update.need_for_approval') }}
        </a>
      </div>
      <div v-else-if="trip?.approval_status?.id == 5 && isShowSettingApproval" class="plan-status"
           :style="'background-color:' + getBackgroundColor(trip)">
        <span class="plan--code" :style="'color:' + getColorId(trip)">{{ trip?.code }}</span>
        <span class="status-text" :style="'color:' + getColorId(trip)">
          {{ getStatusLabel(trip) }}
        </span>
        <a
            href="#"
            class="btn-xs btn-flat-danger btn-noapply show--status10"
            @click="showBoxConfirm('need-approve')"
            style="display: inline; margin-left: 0; font-size: 13px;"
            v-if="isShowChangeStatusRequest"
        >
          <span class="icon-abui-arrow-right" style="padding-right: 2px"></span>
          {{ $t('trip_update.no_need_for_approval') }}
        </a>
      </div>
      <div v-else class="plan-status" :style="'background-color:' + getBackgroundColor(trip)">
        <span class="plan--code" :style="'color:' + getColorId(trip)">{{ trip?.code }}</span>
        <span class="status-text" :style="'color:' + getColorId(trip)" v-if="(showApprovalExpense && trip?.trip_status == 3) || isShowSettingApproval">
          {{ getStatusLabel(trip) }}
          <i class="icon-abui-check-inverted plx"></i>
        </span>
      </div>
    </div>

    <!-- <div v-show="showNoteInsurance">
      <div v-show="!checkEmpty(trip.insurance_note)" class="panel--wrap show--status00">
        <div class="panel panel-withclose panel-warning mbn mts">
          <button type="button" class="close" @click="showNoteInsurance = false">
            <span class="icon-abui-cross" aria-hidden="true"></span>
          </button>
          <div class="panel-heading" style="border: none">
            <p class="panel-title">{{ $t('trip_update.insurance') }}</p>
          </div>
          <div class="panel-body break-word-custom">
            <ul class="regulation-check--feedback">
              <li v-for="(item, index) in trip.insurance_note" :key="index" class="regulation-check--feedback-item">
                {{ `${item.user_name} : ${item.note !== null ? item.note : ''}` }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div> -->

    <div v-if="checkInputProcess && !isActionRingi">
      <div class="panel--wrap">
        <div class="panel panel-default mbn mts">
          <div class="panel-body">
            {{ $t('trip.input_entering') }}
            <template v-if="!trip.name">
              <br/>
              {{ $t('trip.please_enter_trip_title') }}
            </template>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="panel--wrap" style="margin-top: 10px">
      <div v-if="!checkInsuranceInputProcess && !isActionRingi" class="panel panel-default mbn mts">
        <div class="panel-body">{{ $t('trip.input_insurance_note') }}</div>
      </div>
    </div> -->

    <div
        v-if="requestData.length > 0 && showCommentRequest && !this.isOperatorOrAdmin"
        class="panel--wrap show--ringi custom-show-ringi"
    >
      <div class="panel panel-info mbn mts custom-show-ringi">
        <div class="panel-heading comment-box">
          <p class="panel-title"><i class="icon-abui-time prx"></i>{{ $t('common.comment') }}</p>
        </div>
        <div class="panel-body break-word-custom">
          <ul class="regulation-check--feedback">
            <span v-for="item in requestData" :key="item.id">
            <li v-if="item.comment" class="regulation-check--feedback-item">
              <a class="cursor-pointer" @click="pushToTripItem(item.approval)">
                {{
                  `${textToRequestType(item.request_type)}(${showDateTime(item.approve_date, 'YYYY/MM/DD HH:mm')}): `
                }}
              </a>
              <span class="comment-text">
              {{ item.comment }}
              </span>
            </li>
            </span>
          </ul>
        </div>
      </div>
    </div>
    <div
        v-if="
        this.isOperatorOrAdmin &&
        !checkEmpty(lastRequestComments) &&
        !isActionRingi &&
        (showApprovalCommentList || showReportCommentList || showExpenseCommentList)
      "
        class="panel--wrap show--ringi custom-show-ringi"
    >
      <div class="panel panel-info mbn mts custom-show-ringi">
        <div class="panel-heading comment-box">
          <p class="panel-title"><i class="icon-abui-time prx"></i>{{ $t('common.comment') }}</p>
        </div>
        <div class="panel-body break-word-custom">
          <ul class="regulation-check--feedback">
            <li v-if="showApprovalCommentList" class="regulation-check--feedback-item">
              <a>
                {{
                  `${textToRequestType(lastRequestComments.approval.request_type)} ${lastRequestComments.approval.approve_date}:`
                }}
              </a>
              <span class="comment-text">
              {{ lastRequestComments.approval.comment }}
              </span>
            </li>
            <li v-if="showReportCommentList" class="regulation-check--feedback-item">
              <a>
                {{
                  `${textToRequestType(lastRequestComments.report.request_type)} ${lastRequestComments.report.approve_date}:`
                }}
              </a>
              <span class="comment-text">
              {{ lastRequestComments.report.comment }}
              </span>
            </li>
            <li v-if="showExpenseCommentList" class="regulation-check--feedback-item">
              <a>
                {{
                  `${textToRequestType(lastRequestComments.expense.request_type)}${lastRequestComments.expense.approve_date}: `
                }}
              </a>
              <span class="comment-text">
              {{ lastRequestComments.expense.comment }}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- Hold or remand comments -->
    <!-- <div
      v-if="!checkEmpty(holdOrRejectCommentsOrCancel) && !isActionRingi && showHoldOrRejectCommentOrCancel"
      class="panel--wrap show--status20"
    >
      <div class="panel panel-warning mbn">
        <div class="panel-body break-word-custom">
          <ul class="regulation-check--feedback">
            <span v-for="(item, index) in holdOrRejectCommentsOrCancel" :key="index">
              <li v-if="item.comment !== null && !checkEmpty(item.comment)" class="regulation-check--feedback-item">
                {{ !checkEmpty(item.authorizer) ? item.authorizer?.name : item.approver_name }}
                {{ item.authorizer_id ? this.$t('trip_item.trip_process.is_authorizer') : '' }}
                {{ `: ${item.comment !== null ? item.comment : ''}` }}
              </li>
            </span>
          </ul>
        </div>
      </div>
    </div> -->

    <div
        v-if="trip?.regular_check?.length > 0 && $i18n.locale != 'en'"
        class="panel--wrap show--status20"
        style=""
        id="msg--regulation-check"
    >
      <div class="panel panel-warning mbn">
        <div class="panel-heading" style="border: none">
          <p class="panel-title"><i class="icon-abui-alert prx"></i>{{ $t('trip_item.main_content.rule_text') }}</p>
        </div>
        <div class="panel-body break-word-custom">
          <ul class="regulation-check--feedback">
            <li
                v-for="(item, index) in trip.regular_check"
                :key="index"
                class="regulation-check--feedback-item"
            >
              <b>{{ titleType(item.title_type) }}</b> : {{ item.comment }}
              <br />
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- Notification list from requirement -->
    <div v-if="requirementNotifications.length > 0 && userId == tripUserId" class="panel--wrap show--status20">
      <div class="panel panel-warning mbn">
        <div class="panel-body">
          <ul class="regulation-check--feedback">
            <li v-for="(item, index) in requirementNotifications" :key="index" class="regulation-check--feedback-item">
              <span class="regulation-check--feedback-item--coment">
                <a class="cursor-pointer" @click="goToNotificationDetail(item.id)">
                  {{ item.send_time + ' ' + this.$t('trip.requirement_necessary') }}
                </a>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div v-if="!isActionRingi" class="plan-head hide--estimate">
      <div class="plan-head-block">
        <div class="plan-head--left">
          <div class="plan-title">
            <div class="form-group">
              <p class="plan-title--view show--ringi" style="display: none">{{ trip.name }}</p>
              <div class="input-with-icon hide--ringi">
                <input
                    type="text"
                    style="padding-right: 40px; font-size: 18px !important;"
                    class="form-control trip-name"
                    id="plan-title--input"
                    :placeholder="$t('trip_item.main_content.name_this_trip')"
                    v-model="trip.name"
                    maxlength="50"
                    :disabled="checkDisableEdit || trip?.user_id != userId"
                    ref="TripName"
                    @blur="updateTrip('name')"
                />
                <span class="input-icon icon-abui-pencil"></span>
              </div>
              <p class="error-feedback" v-text="errors_message.trip_name"/>
            </div>
          </div>
          <div class="plan-date-dest hide--new hide--estimate">
            <span class="plan-date text-primary">
              <div>
                <i class="icon-abui-calendar prx"></i>
                {{ trip.start_time && showDateTime(trip.start_time, 'YYYY/MM/DD（ddd）') }} -
                {{ trip.end_time && showDateTime(trip.end_time, 'YYYY/MM/DD（ddd）') }}
                <i
                    v-if="!showEditDate && !checkDisableEdit"
                    class="icon-abui-pencil plx cursor-pointer"
                    @click="showEditDate = true"
                />
              </div>
            </span>
            <div class="plan-dest">
              <span> <i class="icon-abui-company prx"></i>{{ trip?.company_name }} </span>
            </div>
            <div class="plan-dest">
              <span> <i class="prx icon-abui-user"></i>{{ trip?.user_name }} </span>
            </div>

            <div v-if="showEditDate" class="js-btn--changedate__tgt pts">
              <div class="row">
                <div class="col-sm-5 col-all-closer col-all-closer--leftend">
                  <div class="form-group">
                    <label>
                      <span class="label-text">{{ $t('common.departure_date') }}</span>
                    </label>
                    <div class="input-with-icon">
                      <el-date-picker
                          class="date-input input-sm datepicker--2month"
                          v-model="start_time_temp"
                          :clearable="false"
                          :disabled="checkDisableEdit || trip?.user_id != userId"
                          type="date"
                          format="YYYY/MM/DD"
                          value-format="YYYY/MM/DD"
                          :placeholder="$t('common.enter_or_select_required')"
                          :disabled-date="pickerFromOptions"
                      />
                    </div>
                    <p
                        role="alert"
                        class="error-feedback"
                        v-text="errors_message.start_time_temp"
                        v-if="!checkDisableEdit && trip?.user_id == userId"
                    ></p>
                  </div>
                </div>
                <div class="col-sm-5 col-all-closer">
                  <div class="form-group">
                    <label>
                      <span class="label-text">{{ $t('common.return_date') }}</span>
                    </label>
                    <div class="input-with-icon">
                      <el-date-picker
                          class="date-input input-sm datepicker--2month"
                          v-model="end_time_temp"
                          :clearable="false"
                          :disabled="checkDisableEdit || trip?.user_id != userId"
                          type="date"
                          format="YYYY/MM/DD"
                          value-format="YYYY/MM/DD"
                          :placeholder="$t('common.enter_or_select_required')"
                          :disabled-date="pickerToOptions"
                      />
                    </div>
                    <p
                        role="alert"
                        class="error-feedback"
                        v-text="errors_message.end_time_temp"
                        v-if="!checkDisableEdit && trip?.user_id == userId"
                    ></p>
                  </div>
                </div>
                <div class="col-sm-2 col-all-closer col-all-closer--rightend">
                  <div class="form-group">
                    <button
                        class="btn btn-sm btn--ls btn-inverse-primary"
                        @click="handleChangeDate()"
                        :disabled="checkDisableEdit || trip?.user_id != userId"
                    >
                      {{ $t('trip_update.create') }}
                    </button>
                  </div>
                </div>
              </div>
              <p role="alert" class="error-feedback" v-text="errors_message.dateMinMax"></p>
            </div>

            <span class="plan-dest">
              <i class="icon-abui-location prx"></i>
              {{ labelLocale(trip.schedule_location) }}
            </span>
          </div>
        </div>

        <div class="plan-head--right">
          <div v-if="showUploadFile" class="btn-group hide--newfirstonly">
            <div class="btn-group show--status10" :class="sbOpen && 'open'">
              <button
                  v-if="userIsSB"
                  type="button"
                  class="btn btn btn-sm btn-inverse dropdown-toggle"
                  @click="handleShowUploadSB()"
              >
                <img src="@/assets/images/logo--mark--smartbtm1.svg" style="width: 14px; height: 14px" alt=""/>
              </button>
              <button
                  v-else-if="isShowUploadTrip"
                  type="button"
                  class="btn btn btn-sm btn-inverse dropdown-toggle"
                  @click="sbOpen = !sbOpen"
                  v-click-outside="
                  () => {
                    sbOpen = false;
                  }
                "
              >
                {{ $t('trip_update.capture') }}
              </button>
              <ul class="dropdown-menu">
                <li class="mln">
                  <a href="#" @click="showModalUploadCost = true"> {{ $t('new_trip.reservation_slip_upload') }}.. </a>
                </li>
                <li class="mln" v-if="userIsSB">
                  <a href="#" @click="showUploadTripSB = true">
                    {{ $t('trip_update.smart_btm_and_add') }}
                  </a>
                </li>
              </ul>
            </div>
            <button type="button" class="btn btn-sm btn-inverse" @click="showUploadInsurance = true">
              <i
                  class="prx hide--ringi"
                  :class="!checkEmpty(trip.insurances) ? 'icon-abui-check' : 'icon-abui-upload'"
              ></i>
              {{ $t('trip_update.insurance') }}
            </button>
          </div>
          <div class="plan-head--trip-type js--change--trip-type hide--new">
            <span
                role="alert"
                class="error-feedback"
                style="margin-left: -45px"
                v-text="errors_message.meet_insurance_requirement"
            ></span>
          </div>
          <!-- <div v-if="showEditTripType" class="js--change--trip-type__tgt text-right">
            <div class="btn-group btn-group-sm btn-switcher js-switcher switcher--trip-type mtm">
              <button
                type="button"
                class="btn btn-inverse-primary"
                :class="trip.trip_type == TRIP_TYPE_DOMESTIC && 'active'"
                @click="changeTripType(TRIP_TYPE_DOMESTIC)"
                v-click-outside="
                  () => {
                    showEditTripType = false;
                  }
                "
              >
                <i class="icon-abui-check prx"></i>{{ $t('trip.type.1') }}
              </button>
              <button
                type="button"
                class="btn btn-inverse-primary"
                :class="trip.trip_type == TRIP_TYPE_ABROAD && 'active'"
                @click="changeTripType(TRIP_TYPE_ABROAD)"
                v-click-outside="
                  () => {
                    showEditTripType = false;
                  }
                "
              >
                <i class="icon-abui-check prx"></i>{{ $t('trip.type.2') }}
              </button>
            </div>
          </div>
          <div v-else class="plan-head--trip-type js--change--trip-type hide--new">
            <a v-if="checkDisableEdit" href="#">
              {{ getTripTypeLabel(trip.trip_type) }}
            </a>
            <a v-else href="#" @click="showEditTripType = true">
              {{ getTripTypeLabel(trip.trip_type) }}
              <i class="icon-abui-pencil plx"></i>
            </a>
          </div> -->
        </div>
      </div>
    </div>

    <div class="section noborder ptn">
      <div v-if="isActionRingi">
        <div class="schedule--wrap hide--new">
          <DayDetail
              v-for="(item, index) in trip.activities"
              :is-action-ringi="isActionRingi"
              :key="index"
              :activity="item"
              :number="index + 1"
              :numberOfActivities="trip.activities.length"
              :newStartDateTrip="start_time_temp"
              :newEndDateTrip="end_time_temp"
              :todoIdEditing="todoIdEditing"
              :errorNoScheduleCreated="errors_message.no_schedule_created"
              :disableEditTrip="checkDisableEdit || (item.date < trip.start_time || item.date > trip.start_time)"
              @open="openModalUpdateAllowance"
              @listenShowModalSchedule="listenShowModalSchedule"
              @date="listenDate"
              @editTodo="editTodo"
              @oldTimeZone="listenOldTimeZone"
              @listenShowModalUpdateSchedule="listenShowModalUpdateSchedule"
          />
        </div>
      </div>
      <div v-else>
        <span role="alert" class="error-feedback" v-text="errors_message.hotel_schedule_required"> </span>
        <ul
            v-if="trip.approval_status?.id >= 3 || !checkEmpty(trip?.request_histories_with_trashed)"
            id="myTab--stage"
            class="nav nav-tabs"
        >
          <li :class="tabActive == 1 && 'active'" @click="showCostTab()">
            <a href="#">
              {{ this.isShowSettingReport ? $t('trip_item.main_content.approval_or_report_history') : $t('trip_item.main_content.approval_history') }}
            </a>
          </li>
          <li :class="tabActive == 2 && 'active'" @click="tabActive = 2">
            <a href="#">{{ $t('trip_item.main_content.schedule') }}</a>
          </li>
          <li v-if="trip.report_status" :class="tabActive == 3 && 'active'" @click="tabActive = 3">
            <a href="#">{{ $t('trip_item.main_content.report') }}</a>
          </li>
        </ul>

        <div v-show="tabActive == 1" class="tab-pane fade active in">
          <CostTab :trip-id-params="trip.id" :request-histories="trip.request_histories_with_trashed" ref="CostTab"
                   @passRequestData="getRequestData"/>
        </div>

        <div v-show="tabActive == 2" class="schedule--wrap hide--new">
          <div
              v-for="(item, index) in trip.activities"
              :key="index"
              class="scroll-margin-top"
              :id="`day_detail_${item.date.split('/')[1]}_${item.date.split('/')[2]}`"
          >
            <DayDetail
                :is-action-ringi="isActionRingi"
                :activity="item"
                :number="index + 1"
                :numberOfActivities="trip.activities.length"
                :newStartDateTrip="start_time_temp"
                :newEndDateTrip="end_time_temp"
                :todoIdEditing="todoIdEditing"
                :scheduleClip="scheduleClip"
                :scheduleOff="scheduleOff"
                :errorNoScheduleCreated="errors_message.no_schedule_created"
                :listScheduleError="listScheduleError"
                :disableEditTrip="checkDisableEdit || (item.date < trip.start_time || item.date > trip.end_time)"
                @open="openModalUpdateAllowance"
                @listenShowModalSchedule="listenShowModalSchedule"
                @date="listenDate"
                @editTodo="editTodo"
                @oldTimeZone="listenOldTimeZone"
                @listenShowModalUpdateSchedule="listenShowModalUpdateSchedule"
            />
          </div>
        </div>

        <div v-show="tabActive == 3 && trip?.id">
          <ReportList :tripId="trip.id" ref="report_list_field"/>
        </div>
      </div>
    </div>

    <div
        class="navbar navbar-default navbar-fixed-bottom navbar-footblock with-sidebar with-chat submit-new--wrap"
        style="z-index: 990; padding: 12px"
    >
      <div class="row">
        <div class="col-sm-2 col-md-2" v-if="showDeleteTrip">
          <a href="#" class="btn btn-sm btn-flat-danger" @click="showBoxConfirm('delete-trip')">
            <i class="icon-abui-trash prx"></i>
            {{ $t('trip_update.delete') }}
          </a>
        </div>
        <div class="col-sm-2 col-md-2" v-if="showCancelTrip">
          <a href="#" class="btn btn-sm btn-flat-danger" @click="showBoxConfirm('cancel-trip')">
            {{ $t('trip_update.btn_cancel_trip') }}
          </a>
        </div>
        <div class="col-sm-2 col-md-2" v-if="showRestoreTrip">
          <a href="#" class="btn btn-sm btn-flat-danger" @click="showBoxConfirm('restore-trip')">
            {{ $t('btn_restore_trip') }}
          </a>
        </div>
        <span v-if="(trip?.trip_status !== 3 && isShowSettingApproval) || trip?.trip_status === 3">
        <div
            class="col-sm-2 col-md-2"
            v-if="showCancelRingi && !checkEmpty(trip?.requests) && !(trip?.trip_status === 2 && trip?.report_status_id === 6)
          && !(trip?.trip_status === 1 && trip?.approval_status_id === 6) && (showApprovalExpense || trip?.trip_status !== 3)"
            :class="styleCancelRingi()"
        >
          <a href="#" class="btn btn-sm btn-flat-default pull-right" @click="showBoxConfirm('cancel-ringi')">
            <span v-if="trip?.trip_status === 1">{{ $t('trip_update.btn_cancel_ringi') }}</span>
            <span v-else-if="trip?.trip_status === 2">{{ $t('trip_update.btn_cancel_ringi_report') }}</span>
            <span v-else-if="trip?.trip_status === 3">{{ $t('trip_update.btn_cancel_ringi_expense') }}</span>
          </a>
        </div>
        <div v-if="showSubmitFinal" class="col-sm-3 col-md-3 col-submit-trip">
          <button
              class="btn btn-sm btn-block btn-primary js-btn--submit-final show--status31 show--status41"
              :disabled="isDisableRequestReport"
              @click="createReport()"
          >
            {{ $t('trip_update.btn_submit_business_report') }}
          </button>
        </div>
        <div v-if="showSubmitRingi" class="col-sm-3 col-md-3 col-submit-trip">
          <button
              class="btn btn-sm btn-block btn-primary js-btn--submit-ringi"
              :disabled="isDisableRequest"
              @click="createRequest()"
          >
            <span v-if="trip?.trip_status === 1">{{ $t('trip_update.btn_submit_business_approval') }}</span>
            <span v-else>{{ $t('trip_update.btn_submit_business_approval_2') }}</span>
          </button>
        </div>
        </span>
      </div>
    </div>
  </div>

  <ModalConfirm
      :dialogVisible="showBoxNeedApprove"
      @cancel="showBoxNeedApprove = false"
      @yes="submitAction"
      :classCustom="actionBoxConfirm == 'need-approve' ? 'btn-flat-default' : 'btn-flat-danger'"
      :title="titleBoxConfirm"
  />

  <ModalCancel
      :is-modal-visible="showBoxCancel"
      :name-btn="$i18n.locale == 'en' ? 'Withdraw Execution' : '取下実行'"
      @submit-cancel-modal="cancelRingi"
      @close-modal="showBoxCancel = false"
  />

  <DialogSchedule
      :visible="visible"
      :trip-id-from-parent="parseInt(tripId)"
      :trip-status="trip.trip_status"
      :scheduleId="scheduleId"
      :isActionCreate="isActionCreate"
      :scheduleData="scheduleData"
      :tripDate="tripDate"
      :dateDefault="dateDefault"
      :timeZoneBefore="timeZoneBefore"
      :isTripApproval="isTripApproval"
      :isReportEdit="canAddMoreReport"
      :disableHotelOption="tripDate.start_time === tripDate.end_time || dateDefault === tripDate.end_time"
      :disableOtherPlaneOption="dateDefault < tripDate.start_time || dateDefault > tripDate.end_time"
      :isTripApprovalDisable="isTripApprovalDisable"
      :is-action-ringi="checkDisableEdit"
      :reload-data="reloadData"
      @yes="handlingData"
      @cancel="handlingClose"
  />

  <ReservationUpload
      :title="$t('new_trip.reservation_slip')"
      :subTitle="$t('new_trip.upload_note')"
      :acceptImageAndPDFOnly="true"
      :isSingleUpload="true"
      accepted-files="image/jpg, image/jpeg, image/png, .heif, application/pdf"
      :dialogVisible="showModalUploadCost"
      @cancel="showModalUploadCost = false"
      @yes="confirmUploadCost"
  />

  <ReservationUpload
      :title="$t('trip_update.reservation_slip')"
      :subTitle="$t('trip_update.upload_note')"
      :acceptImageAndPDFOnly="true"
      :dialogVisible="showUploadInsurance"
      :sbUpload="true"
      :insurance="trip.insurances"
      :isModalInsurance="true"
      @cancel="showUploadInsurance = false"
      @yes="handleFile"
  />

  <ModalUpdateDailyAllowance
      :dialogVisible="showUpdateAllowance"
      @cancel="showUpdateAllowance = false"
      @yes="updateAllowance"
      :date="selectedDate"
      :isCreate="isCreateDailyAllowance"
      :files="dailyAllowanceOfCompany"
      :trip="trip"
  />

  <ModalListTripSB
      :dialogVisible="showUploadTripSB"
      :listTripSB="listTripSB"
      @cancel="showUploadTripSB = false"
      @success="syncTrip"
  />
</template>

<script>
import {
  TRIP_TYPE_DOMESTIC,
  TRIP_TYPE_ABROAD,
  ACTIVITY_ACTION,
  ACTIVITY_TODO,
  REPORT_STATUS,
  APPROVAL_STATUS,
  HOLD,
  REMAND,
  CANCEL,
  APPROVAL,
} from '@/constants/';

import ReservationUpload from '@/components/trip/trip-item/ReservationUpload';
import ReportList from '@/components/trip/trip-item/ReportList';
import DayDetail from '@/components/trip/trip-item/DayDetail';
import ModalConfirm from '@/components/common/ModalConfirm';
import ModalListTripSB from '@/components/common/ModalListTripSB';
import ModalUpdateDailyAllowance from '@/components/trip/ModalUpdateDailyAllowance';
import CostTab from '@/components/trip/trip-item/CostTab';
import DialogSchedule from '@/components/schedule/DialogSchedule';
import vClickOutside from 'click-outside-vue3';
import mixins from '@/helpers/mixins';
import moment from 'moment';
import ModalCancel from '@/components/trip/trip-item/modal/ModalCancel';

export default {
  name: 'Trip-Detail',
  emits: [
    'showBusinessApproval',
    'showBusinessReport',
    'syncDataToBusinessReport',
    'onChangeTimeTrip',
    'onChangeData',
    'syncTripDetail',
    'reloadManualInput',
  ],
  mixins: [mixins],
  components: {
    ReservationUpload,
    ModalCancel,
    DayDetail,
    ModalConfirm,
    ModalUpdateDailyAllowance,
    ModalListTripSB,
    CostTab,
    ReportList,
    DialogSchedule,
  },
  props: {
    isActionRingi: {
      type: Boolean,
      default: false,
    },
    requestPage: {
      type: Boolean,
      default: false,
    },
    tripIdFromApplication: {
      type: Number,
      default: 0,
    },
    isButtonCreateRequestVisible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isActionCreate: false,
      dailyAllowanceOfCompany: {},
      requestData: [],
      titleBoxConfirm: '',
      actionBoxConfirm: '',
      todoIdEditing: 0,
      tabActive: 2,
      firstLoading: true,
      reloadData: false,
      fagDisabled: false,
      visible: false,
      errors_message: {
        no_schedule_created: '',
        dateMinMax: '',
        hotel_schedule_required: '',
      },
      trip: {},
      sbOpen: false,
      showUploadTripSB: false,
      showUploadInsurance: false,
      showBoxNeedApprove: false,
      showBoxCancel: false,
      showNoteInsurance: false,
      showEditDate: false,
      showEditTripType: false,
      start_time_temp: '',
      end_time_temp: '',
      tripDate: {
        start_time: '',
        end_time: '',
      },
      showUpdateAllowance: false,
      selectedDate: '',
      scheduleData: {
        report_files: [],
      },
      scheduleId: 0,
      dateDefault: '',
      isTripApproval: false,
      isTripApprovalDisable: false,
      showModalUploadCost: false,
      timeZoneBefore: '',
      isCreateDailyAllowance: false,
      scheduleClip: [],
      listTripSB: [],
      scheduleOff: false,
      tripApproval: APPROVAL_STATUS,
      tripReport: REPORT_STATUS,
      haveScheduleYet: false,
      tripNameOfficial: '',
      tripStartTimeOfficial: '',
      tripEndTimeOfficial: '',
      isSubmit: false,
      dateMinMax: {
        min: '',
        max: '',
      },
      isDisableRequest: false,
      isDisableRequestReport: false,
      listScheduleError: [],
      isUpdateScheduleSuccess: false,
      listActivities: [],
    };
  },
  created() {
    if (!this.requestPage) {
      this.startLoading();
    }
    Promise.all([this.getTripDetail(), this.getListTripSB(), this.getDailyAllowance()]).then(() => {
        if (!this.requestPage) { this.endLoading(); }
    });
  },
  mounted() {
    this.$emitter.on('activeTodo', todoId => {
      this.todoIdEditing = todoId;
      if (todoId == 0) {
        this.getTripDetail();
        this.reloadData = !this.reloadData;
      }
    });
    this.$emitter.on('activeSchedule', value => {
      this.scheduleOff = false;
      if (this.checkEmpty(value)) {
        this.scheduleClip = [];
      } else {
        this.scheduleClip = value.map(item => item?.id);
      }
    });
    this.$emitter.on('activeScheduleOff', value => {
      this.scheduleOff = value;
    });
    this.$emitter.on('updateScheduleSuccess', value => {
      this.isUpdateScheduleSuccess = value;
    });
    this.$emitter.on('checkCanSendRequest', value => {
      if (value) {
        if (!this.haveScheduleYet) {
          this.errors_message.no_schedule_created = this.$t('E-CM-004');
        }
        if (this.checkEmpty(this.trip.name)) {
          this.errors_message.trip_name = this.$t('E-CM-100', {item: this.$t('trip_update.trip_name')});
        }
        if (this.checkEmpty(this.trip.start_time)) {
          this.errors_message.start_time_temp = this.$t('E-CM-100', {
            item: this.$t('common.departure_date'),
          });
        }
        if (this.checkEmpty(this.trip.end_time)) {
          this.errors_message.end_time_temp = this.$t('E-CM-100', {
            item: this.$t('common.return_date'),
          });
        }
        if (this.checkEmpty(this.trip.start_time) || this.checkEmpty(this.trip.end_time)) {
          this.showEditDate = true;
        }
        if (
            !this.haveScheduleYet ||
            this.checkEmpty(this.trip.name) ||
            this.checkEmpty(this.trip.start_time) ||
            !this.isButtonCreateRequestVisible ||
            this.checkEmpty(this.trip.end_time) ||
            !this.checkDataSchedule()
        ) {
          this.isDisableRequest = true;
          this.notifyError(this.$t('trip.the_application_not_be_sent'));
          return;
        }

        if (
            !this.checkEmpty(this.trip.start_time) &&
            !this.checkEmpty(this.trip.end_time) &&
            this.trip.start_time !== this.trip.end_time &&
            !this.haveHotelYet && this.isNeedScheduleHotel
        ) {
          this.isDisableRequest = true;
          this.errors_message.hotel_schedule_required = this.$t('trip.please_input_hotel_schedule');
          this.notifyError(this.$t('trip.please_input_hotel_schedule'));
          return;
        } else {
          this.errors_message.hotel_schedule_required = '';
        }

        this.isDisableRequest = false;
        this.errors_message.no_schedule_created = '';
      }
      this.$emitter.emit('getStatusSendRequest', 1);
      this.$emit('onShowModalExpense');

    });
  },
  beforeUnmount() {
    this.$emitter.off('activeTodo');
    this.$emitter.off('activeSchedule');
    this.$emitter.off('activeScheduleOff');
    this.$emitter.off('updateScheduleSuccess');
    this.$emitter.off('checkCanSendRequest');
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  watch: {
    tabActive(newVal) {
      if (newVal != 1) {
        this.$refs.CostTab.changeToNewDate();
      }
    },
    sbOpen() {
      Promise.all([this.getCurrentUserCheck()]).then(() => {
        if (!this.isShowUploadTrip) {
          this.$router.push(this.PAGES.UNAUTHORIZED);
        }
      });
    },
    tripIdFromApplication(value) {
      if (value) {
        this.getTripDetail();
      }
    },
    'trip.request_histories_with_trashed'(newVal) {
      if (this.checkEmpty(newVal)) this.tabActive = 2;
    },
    'trip.name'(val) {
      this.onCheckDataChange();
      if (this.firstLoading) return;

      if (val) {
        this.errors_message.trip_name = '';

        if (this.strLen(val) > 50) {
          this.errors_message.trip_name = this.$t('E-CM-111', {size: 50});
        }
      } else {
        this.errors_message.trip_name = this.$t('E-CM-100', {item: this.$t('trip_update.trip_name')});
      }

      if (
          !this.checkEmpty(val) &&
          !this.checkEmpty(this.trip.start_time) &&
          !this.checkEmpty(this.trip.end_time) &&
          this.isButtonCreateRequestVisible &&
          this.haveScheduleYet
      ) {
        this.isDisableRequest = false;
      }
    },
    'trip.start_time'(val) {
      if (this.checkEmpty(val) || this.checkEmpty(this.trip.end_time)) this.showEditDate = true;
      !this.firstLoading && this.updateActivities();
      if (
          !this.checkEmpty(val) &&
          !this.checkEmpty(this.trip.name) &&
          !this.checkEmpty(this.trip.end_time) &&
          this.isButtonCreateRequestVisible &&
          this.haveScheduleYet
      ) {
        this.isDisableRequest = false;
      }
    },
    'trip.end_time'(val) {
      if (this.checkEmpty(val) || this.checkEmpty(this.trip.start_time)) this.showEditDate = true;
      !this.firstLoading && this.updateActivities();
      if (
          !this.checkEmpty(val) &&
          !this.checkEmpty(this.trip.start_time) &&
          !this.checkEmpty(this.trip.name) &&
          this.isButtonCreateRequestVisible &&
          this.haveScheduleYet
      ) {
        this.isDisableRequest = false;
      }
    },
    isSubmit: {
      handler: function () {
        this.onCheckDataChange();
      },
      deep: true,
    },
    start_time_temp(val) {
      this.onCheckDataChange();
      this.errors_message.start_time_temp = '';
      this.errors_message.dateMinMax = '';

      if (!this.end_time_temp && val) {
        this.end_time_temp = val;
      }

      if (!val) {
        this.errors_message.start_time_temp = this.$t('E-CM-100', {
          item: this.$t('common.departure_date'),
        });
      }
    },
    end_time_temp(val) {
      this.onCheckDataChange();
      this.errors_message.end_time_temp = '';
      this.errors_message.dateMinMax = '';

      if (!val) {
        this.errors_message.end_time_temp = this.$t('E-CM-100', {
          item: this.$t('common.return_date'),
        });
      }
    },
    dataToBusinessReport(val) {
      this.$emit('syncDataToBusinessReport', val);
    },
    'trip.destination_type'() {
      this.meetInsuranceUploadRequirement();
    },
    'trip.insurances'() {
      this.meetInsuranceUploadRequirement();
    },
    'trip.activities'(newVal) {
      this.haveScheduleYet = false;
      if (!this.checkEmpty(newVal)) {
        for (let i = 0; i < newVal.length; i++) {
          if (newVal[i].schedules && newVal[i].schedules.length > 0) {
            this.haveScheduleYet = true;
            newVal[i].schedules.forEach(item => {
              if (item.schedule_type_id == 6) {
                this.errors_message.hotel_schedule_required = '';
                this.isDisableRequest = false;
                this.isDisableRequestReport = false;
              }
            });
            if (this.haveScheduleYet && !this.isDisableRequestReport && !this.isDisableRequest) break;
          }
        }
      }
    },
    haveScheduleYet(newVal) {
      if (newVal) this.errors_message.no_schedule_created = '';
      if (
          newVal &&
          !this.checkEmpty(this.trip.start_time) &&
          !this.checkEmpty(this.trip.end_time) &&
          this.isButtonCreateRequestVisible &&
          !this.checkEmpty(this.trip.name)
      ) {
        this.isDisableRequest = false;
      }
    },
    trip(newVal) {
      this.$emit('syncTripDetail', newVal);
    },
    isButtonCreateRequestVisible(val) {
      if (
          val &&
          !this.checkEmpty(this.trip.start_time) &&
          !this.checkEmpty(this.trip.end_time) &&
          this.haveScheduleYet &&
          !this.checkEmpty(this.trip.name)
      ) {
        this.isDisableRequest = false;
        this.isDisableRequestReport = false;
      } else {
        this.isDisableRequest = true;
        this.isDisableRequestReport = true;
        this.notifyError(this.$t('trip.the_application_not_be_sent'));
      }
    },
    isUpdateScheduleSuccess(val) {
      if (
          val &&
          !this.checkEmpty(this.trip.start_time) &&
          !this.checkEmpty(this.trip.end_time) &&
          this.haveScheduleYet &&
          this.isButtonCreateRequestVisible &&
          !this.checkEmpty(this.trip.name)
      ) {
        this.isDisableRequest = false;
        this.listScheduleError = [];
      }
    },
  },
  computed: {
    isShowSettingApproval() {
      return this.$store.state.auth?.user?.setting.is_show_approval || false;
    },
    isShowSettingReport() {
      return this.$store.state.auth?.user?.setting.is_show_report || false;
    },
    showApprovalExpense() {
      return this.$store.state.auth?.user?.setting?.is_feature_payment || false;
    },
    isShowUploadTrip() {
      return this.$store.state.auth?.user?.setting.is_upload_itinerary == 1 || false;
    },
    isShowChangeStatusRequest() {
      return this.$store.state.auth?.user?.setting.is_show_change_status_request == 1 || false;
    },
    isNeedScheduleHotel() {
      return this.$store.state.auth?.user?.setting.is_need_schedule_hotel == 1 || false;
    },
    canAddMoreReport() {
      let flagCanAddMoreReport = false;
      if (this.checkEmpty(this.trip.trip_status) || this.checkEmpty(this.trip.report_status?.id)) return false;
      if (this.trip.trip_status === 2 && [1, 2, 5].includes(this.trip.report_status?.id)) {
        flagCanAddMoreReport = true;
      }
      return flagCanAddMoreReport;
    },
    firstDateActivities() {
      let activity = this.listActivities.find(activity => !this.checkEmpty(activity.schedules));

      return !this.checkEmpty(activity) ? activity.date : '';
    },
    showCommentRequest() {
      let comment = this.requestData.find(item => !this.checkEmpty(item.comment));

      return !this.checkEmpty(comment);
    },
    haveHotelYet() {
      let flag = false;
      if (!this.checkEmpty(this.trip.activities)) {
        this.trip.activities.forEach(activity => {
          if (!this.checkEmpty(activity.schedules)) {
            activity.schedules.every(schedule => {
              if (schedule.schedule_type_id === 6) {
                flag = true;
                return false;
              } else {
                return true;
              }
            });
          }
        });
      }
      return flag;
    },
    lastDateActivities() {
      let date = '';
      this.listActivities.forEach(activity => {
        if (!this.checkEmpty(activity.schedules)) {
          date = activity.date;
        }
      });

      return date;
    },
    showApprovalCommentList() {
      if (
          (this.tripCode == 'approval_no_applied' && !this.checkEmpty(this.lastRequestComments.request_status)) ||
          this.checkEmpty(this.lastRequestComments.approval) ||
          this.checkEmpty(this.lastRequestComments.approval.comment)
      )
        return false;
      return true;
    },
    showReportCommentList() {
      if (
          (this.tripCode == 'report_no_report' && !this.checkEmpty(this.lastRequestComments.request_status)) ||
          this.checkEmpty(this.lastRequestComments.report) ||
          this.checkEmpty(this.lastRequestComments.report.comment)
      )
        return false;
      return true;
    },
    showExpenseCommentList() {
      if (
          (this.tripCode == 'expense_no_applied' && !this.checkEmpty(this.lastRequestComments.request_status)) ||
          this.checkEmpty(this.lastRequestComments.expense) ||
          this.checkEmpty(this.lastRequestComments.expense.comment)
      )
        return false;
      return true;
    },
    checkInputProcess() {
      let flg = false;

      if (this.checkEmpty(this.trip?.input_entering)) {
        return false;
      }

      this.trip?.input_entering.forEach(item => {
        if ([1, 2, 3].includes(+item.status) && item.type == 2) flg = true;
      });

      return flg;
    },
    checkInsuranceInputProcess() {
      let flgValidate = true;
      if (this.checkEmpty(this.trip.insurances)) return true;
      const insuranceList = this.trip.insurances || [];
      if (!this.checkEmpty(insuranceList)) {
        insuranceList.forEach(item => {
          if (!item.is_published) flgValidate = false;
        });
      }
      return flgValidate;
    },
    checkDisableEdit() {
      return this.isActionRingi || (!this.showUploadFile && this.isShowSettingApproval) || this.isOperatorOrAdmin || this.tripUserId != this.userId ||
          (this.isTripStartTraveling && this.isShowSettingApproval && !this.isShowSettingReport  && !this.showApprovalExpense) ||
          (this.trip?.trip_status === 3 &&
              (this.isShowSettingApproval || ['expense_waiting', 'expense_pending', 'expense_approved'].includes(this.trip?.expense_settlement?.code)));
    },
    customOffset() {
      if (this.showDeleteTrip || this.showCancelTrip) return 'col-sm-offset-7 col-md-offset-8';
      else return 'col-sm-offset-9 col-md-offset-10';
    },
    TRIP_TYPE_DOMESTIC() {
      return TRIP_TYPE_DOMESTIC;
    },
    TRIP_TYPE_ABROAD() {
      return TRIP_TYPE_ABROAD;
    },
    tripId() {
      if (this.requestPage) return this.tripIdFromApplication;

      return this.$route.params?.id;
    },
    tripUserId() {
      return this.trip?.user_id || '';
    },
    totalDays() {
      if (this.trip.start_time && this.trip.end_time) {
        return moment.duration(moment(this.trip.end_time).diff(moment(this.trip.start_time))).asDays() + 1;
      }

      return 0;
    },
    isTripTraveling() {
      if (!this.checkEmpty(this.trip.start_time) || !this.checkEmpty(this.trip.end_time)) {
        if (
            moment(new Date()).format('YYYY-MM-DD') >= moment(this.trip.start_time).format('YYYY-MM-DD') &&
            moment(new Date()).format('YYYY-MM-DD') <= moment(this.trip.end_time).format('YYYY-MM-DD')
        ) {
          return true;
        }
      }
      return false;
    },
    isTripStartTraveling() {
      if (!this.checkEmpty(this.trip.start_time) && this.trip?.approval_status_id === 6) {
        if (
            moment(new Date()).format('YYYY-MM-DD') >= moment(this.trip.start_time).format('YYYY-MM-DD')
        ) {
          return true;
        }
      }
      return false;
    },
    showUploadFile() {
      if (this.isTripStartTraveling && this.isShowSettingApproval && !this.isShowSettingReport  && !this.showApprovalExpense) {
        return false;
      }
      return (
          !this.isOperatorOrAdmin &&
          ![
            'approval_waiting',
            'approval_pending',
            'report_waiting',
            'report_pending',
            'report_approved',
            'report_cancel',
            'expense_waiting',
            'expense_pending',
            'expense_approved',
          ].includes(this.tripCode) && this.trip?.trip_status !== 3 &&
          this.tripUserId == this.userId
      ) ||
          (!this.isOperatorOrAdmin && this.tripUserId == this.userId &&
              this.trip?.trip_status === 3 && this.tripUserId == this.userId && !this.isShowSettingApproval && this.showApprovalExpense &&
              ['expense_no_applied', 'expense_rejected', 'expense_canceled'].includes(this.trip?.expense_settlement?.code))
    },
    showComment() {
      return ['approval_pending', 'approval_rejected', 'approval_approval'].includes(this.tripCode);
    },
    showDeleteTrip() {
      return (
          (!this.isOperatorOrAdmin &&
              [
                'approval_no_applied',
                'approval_waiting',
                'approval_pending',
                'approval_rejected',
                'approval_approval',
                'approval_changed',
              ].includes(this.tripCode) &&
              this.tripUserId == this.userId) ||
          (!this.isOperatorOrAdmin &&
              ['approval_no_need'].includes(this.tripCode) &&
              !this.isTripTraveling &&
              this.tripUserId == this.userId)
      ) || (this.trip?.trip_status === 3 && !this.isShowSettingApproval &&
          !['expense_waiting', 'expense_pending', 'expense_approved'].includes(this.trip?.expense_settlement?.code));
    },
    showCancelTrip() {
      return (
          (!this.isOperatorOrAdmin &&
              ['report_no_report', 'report_no_report_change'].includes(this.tripCode) &&
              this.tripUserId == this.userId) ||
          (!this.isOperatorOrAdmin &&
              ['approval_no_need'].includes(this.tripCode) &&
              this.isTripTraveling &&
              this.tripUserId == this.userId)
      );
    },
    showRestoreTrip() {
      return (
          (!this.isOperatorOrAdmin &&
              ['report_cancel'].includes(this.tripCode) &&
              this.tripUserId == this.userId) ||
          (!this.isOperatorOrAdmin &&
              ['report_cancel'].includes(this.tripCode) &&
              this.isTripTraveling &&
              this.tripUserId == this.userId)
      );
    },
    showSubmitRingi() {
      return (
          !this.isOperatorOrAdmin &&
          (['approval_no_applied', 'approval_rejected', 'approval_changed'].includes(this.tripCode) ||
              (['approval_approval', 'approval_changed'].includes(this.approvalCode) &&
                  ['report_no_report_change', 'report_no_report', 'report_rejected'].includes(this.tripCode))) &&
          this.tripUserId == this.userId
      );
    },
    showSubmitFinal() {
      return (
          !this.isOperatorOrAdmin &&
          ['report_no_report', 'report_no_report_change', 'report_rejected'].includes(this.tripCode) &&
          moment(new Date()).format('YYYY-MM-DD') >= moment(this.trip?.end_time).format('YYYY-MM-DD') &&
          this.tripUserId == this.userId
      );
    },
    showCancelRingi() {
      return (
          !this.isOperatorOrAdmin &&
          [
            'approval_approval',
            'approval_pending',
            'approval_waiting',
            'report_waiting',
            'report_pending',
            'report_approved',
            'expense_waiting',
            'expense_pending',
          ].includes(this.tripCode) &&
          this.tripUserId == this.userId
      );
    },
    tripCode() {
      if (!this.checkEmpty(this.trip?.expense_settlement?.code)) {
        return this.trip?.expense_settlement?.code;
      }

      if (!this.checkEmpty(this.trip?.report_status?.code)) {
        return this.trip?.report_status?.code;
      }

      return this.trip?.approval_status?.code || false;
    },
    approvalCode() {
      return this.trip?.approval_status?.code || false;
    },
    dataToBusinessReport() {
      if (!this.checkEmpty(this.trip)) {
        return {
          tripId: this.tripId,
          name: this.trip.name,
          trip_type: this.trip.trip_type,
          start_time: this.trip.start_time,
          end_time: this.trip.end_time,
          schedule_location: this.trip.schedule_location,
          trip_code: this.tripCode,
          requests: this.trip.requests,
          destination_type: this.trip.destination_type,
        };
      }

      return {};
    },
    requestListByType() {
      let requestListByType = {
        approval: [],
        report: [],
        expense: [],
      };
      if (this.checkEmpty(this.trip.request_histories_with_trashed) || !this.trip?.request_histories_with_trashed)
        return requestListByType;
      this.trip.request_histories_with_trashed
          .slice()
          .reverse()
          .forEach(request => {
            if (request.type == 1) requestListByType.approval.push(request);
            if (request.type == 2) requestListByType.report.push(request);
            if (request.type == 3) requestListByType.expense.push(request);
          });
      return requestListByType;
    },
    lastRequestComments() {
      let requestListByType = {
        approval: [],
        report: [],
        expense: [],
      };
      let lastRequestComment = {
        approval: {},
        report: {},
        expense: {},
      };
      if (this.checkEmpty(this.trip.request_histories_with_trashed) || !this.trip?.request_histories_with_trashed)
        return lastRequestComment;
      this.trip.request_histories_with_trashed
          .slice()
          .reverse()
          .forEach(request => {
            if (request.type == 1) requestListByType.approval.push(request);
            if (request.type == 2) requestListByType.report.push(request);
            if (request.type == 3) requestListByType.expense.push(request);
          });
      for (let key in requestListByType) {
        if (!this.checkEmpty(requestListByType[key])) {
          const lastR = requestListByType[key][0];
          lastRequestComment[key].request_id = lastR.id;
          lastRequestComment[key].created_at = lastR.created_at;
          lastRequestComment[key].updated_at = lastR.updated_at;
          if (key == 'approval') {
            lastRequestComment[key].status = lastR.approval_status_id;
          } else if (key == 'report') {
            lastRequestComment[key].status = lastR.report_status_id;
          } else {
            lastRequestComment[key].status = lastR.expense_status_id;
          }
          lastRequestComment[key].request_type = lastR.type;
          let lastComment = '';
          let lastCommentDate = '';
          let lastRequestStatus = null;
          if (!this.checkEmpty(lastR.request_approval_histories)) {
            lastR.request_approval_histories
                .slice()
                .reverse()
                .every(item => {
                  if (!this.checkEmpty(item.comment)) {
                    lastComment = item.comment;
                    lastRequestStatus = item.request_status;
                    lastCommentDate = '(' + String(this.showDateTime(item.approve_date, 'YYYY/MM/DD HH:mm')) + ')';
                    return false;
                  } else {
                    return true;
                  }
                });
          }
          lastRequestComment[key].comment = lastComment;
          lastRequestComment[key].request_status = lastRequestStatus;
          lastRequestComment[key].approve_date = lastCommentDate;
        }
      }

      return lastRequestComment;
    },
    approverComments() {
      let approverComments = [];
      if (!this.checkEmpty(this.trip.comment_histories)) {
        this.trip.comment_histories.forEach(item => {
          if (item.request_status === APPROVAL) approverComments.push(item);
        });
      }

      return approverComments;
    },
    holdOrRejectCommentsOrCancel() {
      let holdOrRejectCommentsOrCancel = [];
      if (!this.checkEmpty(this.trip.comment_histories)) {
        this.trip.comment_histories.forEach(item => {
          if (item.request_status === HOLD || item.request_status === REMAND || item.request_status === CANCEL) holdOrRejectCommentsOrCancel.push(item);
        });
      }

      return holdOrRejectCommentsOrCancel;
    },
    showApproveComment() {
      let flgShow = false;
      if (this.checkEmpty(this.approverComments)) return false;
      this.approverComments.forEach(item => {
        if (item.comment != null && !this.checkEmpty(item.comment)) flgShow = true;
      });
      return flgShow;
    },
    showHoldOrRejectCommentOrCancel() {
      let flgShow = false;
      if (this.checkEmpty(this.holdOrRejectCommentsOrCancel)) return false;
      this.holdOrRejectCommentsOrCancel.forEach(item => {
        if (item.comment != null && !this.checkEmpty(item.comment)) flgShow = true;
      });
      return flgShow;
    },
    disableCreateRequest() {
      return moment(this.trip.start_time).format('YYYY/MM/DD') <= moment(new Date()).format('YYYY/MM/DD');
    },
    requirementNotifications() {
      if (this.checkEmpty(this.trip?.notifications)) return [];
      return this.trip.notifications.filter(item => item.notification_type == 1 && item.type_from_operator == 1);
    },
    lastRequestId() {
      if (this.checkEmpty(this.trip?.requests)) return 0;
      return this.trip.requests[this.trip.requests.length - 1].id;
    },
  },
  methods: {
    titleType(type) {
      switch (type) {
        case 1:
          return '出張日数';
        case 2:
          return 'パスポート残存有効期限必要日数';
        case 3:
          return '別途海外旅行傷害保険の手続き必須';
        case 4:
          return '乗り継ぎ禁止地（空港）';
        case 5:
          return '利用禁止航空会社';
        case 6:
          return '禁止フライト区間';
        case 7:
          return '注意フライト区間';
        case 8:
          return '空港ごとの乗り継ぎ時間(下限より短い)';
        case 9:
          return '空港ごとの乗り継ぎ時間(上限より長い)';
        case 10:
          return '空港ごとの乗り継ぎ注意航空会社';
        case 11:
          return '乗り継ぎ回数';
        case 12:
          return '座席クラス(国内線)';
        case 13:
          return '座席クラス(国際線)';
        case 14:
          return '金額(航空券、下限より安い)';
        case 15:
          return '金額(航空券、上限より高い)';
        case 16:
          return '金額(ホテル、下限より安い)';
        case 17:
          return '金額(ホテル、上限より高い)';
        case 18:
          return '出張費用総額(下限より安い)';
        case 19:
          return '出張費用総額(上限より高い)';
        default:
          return '-';
      }
    },
    async getRequestData(data) {
      this.requestData = data;
    },
    async covertDateTime(data) {
      return moment(new Date(data)).format('YYYY-MM-DD')
    },
    async getCurrentUserCheck() {
      await this.$services.account.current(
          res => {
            this.$store.dispatch('auth/initCurrentData', res);
          },
          () => {
            this.logout();
          },
      );
    },
    async handleShowUploadSB() {
      await this.getListTripSB(true);
      this.showUploadTripSB = true;
    },
    async createReport() {
      if (
          !this.checkEmpty(this.trip.start_time) &&
          !this.checkEmpty(this.trip.end_time) &&
          this.trip.start_time !== this.trip.end_time &&
          !this.haveHotelYet && this.isNeedScheduleHotel
      ) {
        this.isDisableRequestReport = true;
        this.notifyError(this.$t('trip.please_input_hotel_schedule'));
        this.errors_message.hotel_schedule_required = this.$t('trip.please_input_hotel_schedule');
        return;
      } else {
        this.errors_message.hotel_schedule_required = '';
      }
      if (this.meetInsuranceUploadRequirement()) {
        await this.getCurrentUser(2);
      }
    },
    styleCancelRingi() {
      if (!this.showSubmitRingi && !this.showSubmitFinal) return this.customOffset;
      if (this.showSubmitRingi && this.showSubmitFinal) return 'custom-cancel-request-1';
      return '';
    },

    showCostTab() {
      this.tabActive = 1;
    },
    goToNotificationDetail(notificationId) {
      let routeData = this.$router.resolve({
        name: this.PAGES.INFORMATION_DETAIL,
        params: {
          id: notificationId,
        },
      });
      window.open(routeData.href, '_blank');
    },
    pushToTripItem(item) {
      let routeData = this.$router.resolve({
        name: this.PAGES.TRIP_ITEM,
        params: {
          id: item.request_id,
        },
        query: {
          date: this.showDateTime(item.approve_date, 'YYYY/MM/DD'),
          // Approval - report or expense request
          type: item.request_type,
        },
      });
      window.open(routeData.href, '_blank');
    },
    onCheckDataChange() {
      let result =
          !this.isSubmit &&
          (this.trip.name != this.tripNameOfficial ||
              this.start_time_temp != this.tripStartTimeOfficial ||
              this.end_time_temp != this.tripEndTimeOfficial);
      this.$emit('onChangeData', result);
    },
    syncTrip(item) {
      if (item?.itineraryId) {
        if (!this.requestPage) {
          this.startLoading();
        }
        this.$services.trip.overrideTripFromSB(
            {
              trip_id: item?.itineraryId,
              id: this.tripId,
            },
            () => {
              this.showUploadTripSB = false;
              this.getTripDetail();
              this.endLoading();
            },
            () => {
              this.endLoading();
            },
        );
      }
    },
    async getListTripSB(showLoading = false) {
      if (showLoading) this.startLoading();
      await this.$services.trip.getListTripSB(
          res => {
            if (showLoading) this.endLoading();
            this.listTripSB = res.list;
          },
          () => {
            if (showLoading) this.endLoading();
          },
      );
    },
    async getDailyAllowance() {
      await this.$services.zone.getDailyAllowance(
          res => {
            this.dailyAllowanceOfCompany = res.files;
            this.$emitter.emit('dailyAllowanceOfCompanyFiles', res.files);
          },
          err => {
            console.log(err);
          },
      );
    },
    async confirmUploadCost(files, note, content_reservation) {
      await this.getCurrentUserCheck();
      if (!this.isShowUploadTrip) {
        this.$router.push(this.PAGES.UNAUTHORIZED);
        return;
      }
      this.showModalUploadCost = false;
      let formData = new FormData();
      // if (files.length > 0) {
      if (note) formData.append('note', note);
      if (files.length > 0) {
        for (let index = 0; index < files.length; index++) {
          formData.append('files[]', files[index]);
        }
      }
      formData.append('trip_id', this.tripId);
      formData.append('type', 'OCR');
      formData.append('content_reservation', content_reservation);
      this.startLoading();
      this.$services.trip.uploadFile(
          formData,
          () => {
            this.endLoading();
            this.getTripDetail();
            this.$emit('reloadTripDetail');
            this.notifySuccess(this.$t('trip_item.main_content.message_upload_file_success'));
          },
          () => {
            this.endLoading();
          },
      );
      // }
      // else {
      //   this.notifyError(this.$t('E-CM-101', { name: this.$t('common.file') }));
      // }
    },
    async createRequest() {
      if (!this.haveScheduleYet) {
        this.errors_message.no_schedule_created = this.$t('E-CM-004');
      }
      if (this.checkEmpty(this.trip.name)) {
        this.errors_message.trip_name = this.$t('E-CM-100', {item: this.$t('trip_update.trip_name')});
      }
      if (this.checkEmpty(this.trip.start_time)) {
        this.errors_message.start_time_temp = this.$t('E-CM-100', {
          item: this.$t('common.departure_date'),
        });
      }
      if (this.checkEmpty(this.trip.end_time)) {
        this.errors_message.end_time_temp = this.$t('E-CM-100', {
          item: this.$t('common.return_date'),
        });
      }
      if (this.checkEmpty(this.trip.start_time) || this.checkEmpty(this.trip.end_time)) {
        this.showEditDate = true;
      }
      if (
          !this.haveScheduleYet ||
          this.checkEmpty(this.trip.name) ||
          this.checkEmpty(this.trip.start_time) ||
          !this.isButtonCreateRequestVisible ||
          this.checkEmpty(this.trip.end_time) ||
          !this.checkDataSchedule()
      ) {
        this.isDisableRequest = true;
        this.notifyError(this.$t('trip.the_application_not_be_sent'));
        return;
      }

      if (
          !this.checkEmpty(this.trip.start_time) &&
          !this.checkEmpty(this.trip.end_time) &&
          this.trip.start_time !== this.trip.end_time &&
          !this.haveHotelYet && this.isNeedScheduleHotel
      ) {
        this.isDisableRequest = true;
        this.errors_message.hotel_schedule_required = this.$t('trip.please_input_hotel_schedule');
        this.notifyError(this.$t('trip.please_input_hotel_schedule'));
        return;
      } else {
        this.errors_message.hotel_schedule_required = '';
      }

      this.isDisableRequest = false;
      this.errors_message.no_schedule_created = '';
      if (this.meetInsuranceUploadRequirement()) {
        await this.getCurrentUser();
      }
    },
    // ReportType (1 - Approval, 2 - Report)
    async getCurrentUser(reportType = 1) {
      if (reportType == 1) {
        await this.$services.trip.checkRegulationTrip(this.tripId);
      }
      await this.$services.account.current(
          async res => {
            await this.$store.dispatch('auth/initCurrentData', res);
            if (reportType == 1) {
              this.$emit('showBusinessApproval');
              return;
            }
            this.$emit('showBusinessReport');
          },
          () => {
            this.logout();
          },
      );
    },
    meetInsuranceUploadRequirement() {
      if (
          this.trip.destination_type === this.TRIP_TYPE_ABROAD &&
          this.trip.is_oversea_insurance_required &&
          this.checkEmpty(this.trip.insurances)
      ) {
        this.errors_message.meet_insurance_requirement = this.$t('trip.upload_insurance_note');
        return false;
      }

      if (!this.firstLoading && this.checkEmpty(this.trip.name)) {
        this.errors_message.trip_name = this.$t('E-CM-100', {item: this.$t('trip_update.trip_name')});
        this.$refs['TripName'].focus();
        return false;
      }

      this.errors_message.meet_insurance_requirement = '';
      return true;
    },
    async showBoxConfirm(action = '') {
      if (this.fagDisabled) return;
      if (action == 'need-approve') {
        await this.getCurrentUserCheck();
        if (!this.isShowChangeStatusRequest) {
          this.$router.push(this.PAGES.UNAUTHORIZED);
          return;
        }
      }
      if (this.isOperatorOrAdmin || !action) return;

      switch (action) {
        case 'need-approve':
          this.titleBoxConfirm = this.trip.approve_message;
          this.actionBoxConfirm = 'need-approve';
          break;

        case 'need-for-approve':
          this.titleBoxConfirm = '稟議未申請に戻します。';
          this.actionBoxConfirm = 'need-approve';
          break;

        case 'cancel-trip':
          this.titleBoxConfirm = this.$t('Q-CU-003');
          this.actionBoxConfirm = 'cancel-trip';
          break;

        case 'restore-trip':
          this.titleBoxConfirm = this.$t('Q-CU-005');
          this.actionBoxConfirm = 'restore-trip';
          break;

        case 'delete-trip':
          this.titleBoxConfirm = this.$t('trip.mes_confirm_delete');
          this.actionBoxConfirm = 'delete-trip';
          break;

        case 'cancel-ringi':
          this.showBoxCancel = true;
          break;
      }
      if (action != 'cancel-ringi') {
        this.showBoxNeedApprove = true;
      }
    },
    async submitAction() {
      if (this.actionBoxConfirm == 'need-approve') {
        await this.getCurrentUserCheck();
        if (!this.isShowChangeStatusRequest) {
          this.$router.push(this.PAGES.UNAUTHORIZED);
          return;
        }
      }
      switch (this.actionBoxConfirm) {
        case 'need-approve':
          this.needApproveStatus();
          break;

        case 'cancel-trip':
          this.cancelTrip();
          break;

        case 'restore-trip':
          this.restoreTrip();
          break;

        case 'delete-trip':
          this.deleteTrip();
          break;

        case 'cancel-ringi':
          this.cancelRingi();
          break;
      }

      this.showBoxNeedApprove = false;
    },
    cancelRingi(comment) {
      if (this.trip?.requests && this.lastRequestId != 0) {
        this.startLoading();
        this.$services.trip.cancelRingi(
            this.lastRequestId + '?comment=' + comment,
            () => {
              this.showBoxCancel = false;
              this.endLoading();
              this.getTripDetail();
            },
            err => {
              this.endLoading();
              this.notifyError(this.$t(err.error));
            },
        );
      }
    },
    deleteTrip() {
      this.startLoading();
      this.$services.trip.deleteTrip(
          this.tripId,
          () => {
            // this.endLoading();
            this.$router.push(this.PAGES.TRIP);
          },
          err => {
            this.endLoading();
            this.notifyError(this.$t(err.error));
          },
      );
    },
    cancelTrip() {
      this.startLoading();
      this.$services.trip.cancelTrip(
          this.tripId,
          () => {
            this.endLoading();
            this.getTripDetail();
          },
          err => {
            this.endLoading();
            this.notifyError(this.$t(err.error));
          },
      );
    },
    restoreTrip() {
      this.startLoading();
      this.$services.trip.restoreTrip(
          this.tripId,
          () => {
            this.endLoading();
            this.getTripDetail();
          },
          err => {
            this.endLoading();
            this.notifyError(this.$t(err.error));
          },
      );
    },
    editTodo(todoId) {
      this.todoIdEditing = todoId;
    },
    openModalUpdateAllowance(params) {
      this.showUpdateAllowance = true;
      this.selectedDate = params.date;
      this.isCreateDailyAllowance = params.isCreate;
    },
    updateAllowance(data) {
      this.startLoading();
      this.$services.allowance.updateDailyAllowance(
          data,
          () => {
            this.endLoading();
            this.showUpdateAllowance = false;
            this.getTripDetail();
          },
          err => {
            this.endLoading();
            this.notifyError(this.$t(err.error));
          },
      );
    },
    listenShowModalSchedule() {
      this.scheduleId = 0;
      this.isActionCreate = true;
      setTimeout(() => {
        this.visible = true;
      }, 100);
    },
    listenDate(date) {
      this.dateDefault = date;
    },
    listenOldTimeZone(value) {
      this.timeZoneBefore = value;
    },
    listenShowModalUpdateSchedule(schedule) {
      let approvalId = true;
      if (schedule.is_approval === 0) {
        approvalId = false;
      }
      this.scheduleId = schedule?.id;
      this.scheduleData.trip_id = this.$route.params?.id;
      this.scheduleData.id = schedule?.id;
      this.scheduleData.approval_status_id = approvalId;
      this.scheduleData.title = schedule.title;
      this.scheduleData.type_id = schedule.schedule_type_id;
      this.scheduleData.location_string = schedule.location_string;
      this.scheduleData.time_zone_id = schedule.time_zone_id;
      this.scheduleData.start_date = schedule.start_date;
      this.scheduleData.start_time = schedule.start_time;
      this.scheduleData.end_date = schedule.end_date;
      this.scheduleData.end_time = schedule.end_time;
      this.scheduleData.hotel_name = schedule.hotel_name;
      this.scheduleData.hotel_room = schedule.hotel_room;
      this.scheduleData.checkin_time = schedule.hotel_checkin_time;
      this.scheduleData.checkout_time = schedule.hotel_checkout_time;
      this.scheduleData.note = schedule.note;
      this.scheduleData.web_page = schedule.web_page;
      this.scheduleData.flight_number = schedule.flight_number;
      this.scheduleData.train_number = schedule.train_number;
      this.scheduleData.cost_amount = schedule.cost_amount;
      this.scheduleData.input_file = schedule.input_file;
      this.scheduleData.updated_at = schedule.updated_at;
      if (!this.checkEmpty(schedule.input_id)) {
        this.scheduleData.input_id = schedule.input_id;
      } else {
        this.scheduleData.input_id = '';
      }
      if (schedule.input_file) {
        this.scheduleData.input_file = schedule.input_file;
      } else {
        this.scheduleData.input_file = '';
      }
      if (schedule.schedule_type_id === 1) {
        this.scheduleData.departure_location_id = schedule.departure_location_id;
        this.scheduleData.arrival_location_id = schedule.arrival_location_id;
        this.scheduleData.airplane_type = schedule.airplane_type;
        this.scheduleData.transit_airplane_type = schedule.transit_airplane_type;
        this.scheduleData.seat_class_type = schedule.seat_class_type;
        this.scheduleData.seat_number = schedule.seat_number;
      }
      if (schedule.schedule_type_id === 2) {
        this.scheduleData.departure_station = schedule.departure_station;
        this.scheduleData.arrival_station = schedule.arrival_station;
        this.scheduleData.seat_number = schedule.seat_number;
      }
      this.scheduleData.clip_ids = schedule.clips.map(item => item?.id);
      this.scheduleData.cost_ids = schedule.costs.map(item => item?.id);
      if (schedule.report) {
        this.scheduleData.report_description = schedule.report.description;
        this.scheduleData.report_id = schedule.report?.id;
        this.scheduleData.report_files = schedule.report.files;
      } else {
        this.scheduleData.report_description = '';
        this.scheduleData.report_id = '';
        this.scheduleData.report_files = '';
      }

      this.visible = true;
    },
    handlingData() {
      this.visible = false;
      this.getTripDetail();
      this.$emit('reloadManualInput');
      this.$emitter.emit('reload_report_list');
    },
    handlingClose(id) {
      if (this.scheduleId !== id) {
        this.scheduleData = {
          report_files: [],
        };
      }
      this.visible = false;
      this.isActionCreate = false;
    },
    updateActivities() {
      let tempActivities = [...this.trip.activities];

      if (tempActivities.length == 0) return;

      const firstDate = tempActivities[0].date;
      const lastDate = tempActivities[tempActivities.length - 1].date;

      let totalDiffDayStart = moment.duration(moment(firstDate).diff(moment(this.trip.start_time))).asDays();
      let totalDiffDayEnd = moment.duration(moment(this.trip.end_time).diff(moment(lastDate))).asDays();

      if (totalDiffDayStart > 0) {
        for (let index = 0; index < totalDiffDayStart; index++) {
          tempActivities.push({
            date: moment(this.trip.start_time).add(index, 'days').format('YYYY/MM/DD'),
          });
        }
      } else {
        totalDiffDayStart = Math.abs(totalDiffDayStart);
        for (let index = 0; index < totalDiffDayStart; index++) {
          tempActivities.splice(0, 1);
        }
      }

      if (totalDiffDayEnd > 0) {
        for (let index = 1; index <= totalDiffDayEnd; index++) {
          tempActivities.push({
            date: moment(lastDate).add(index, 'days').format('YYYY/MM/DD'),
          });
        }
      } else {
        totalDiffDayEnd = Math.abs(totalDiffDayEnd);
        for (let index = 0; index < totalDiffDayEnd; index++) {
          tempActivities.pop();
        }
      }

      tempActivities.sort((a, b) => {
        return moment(a.date).valueOf() - moment(b.date).valueOf();
      });

      this.trip.activities = tempActivities;
    },
    handleFile(files, listInsurances, note) {
      let formData = new FormData();
      formData.append('trip_id', this.tripId);
      formData.append('note', note);
      if (!this.checkEmpty(listInsurances)) {
        listInsurances.forEach(item => {
          formData.append('input_ids[]', item.id);
        });
      } else {
        formData.append('input_ids[]', null);
      }
      // if (this.trip?.insurance?.id) {
      //   formData.append('insurance_id', this.trip.insurance?.id);
      // }
      if (files.length > 0) {
        for (let index = 0; index < files.length; index++) {
          formData.append('files[]', files[index]);
        }
      }

      this.startLoading();
      this.$services.trip.updateInsurance(
          formData,
          () => {
            this.endLoading();
            this.showUploadInsurance = false;
            this.showNoteInsurance = true;
            this.$emitter.emit('reloadInsuranceList');
            if (!this.checkEmpty(files)) this.notifySuccess(this.$t('trip_update.upload_file_done'));
            this.getTripDetail();
          },
          err => {
            this.endLoading();
            this.showUploadInsurance = false;
            this.notifyError(this.$t(err.error));
          },
      );
    },
    async getTripDetail() {
      if (this.checkEmpty(this.tripId)) return;

      if (this.isOperatorOrAdmin) {
        await this.$services.trip.tripDetailOP(
            this.tripId,
            res => {
              this.processTrip(res);
            },
            err => {
              if (!this.requestPage) {
                this.notifyError(this.$t(err.error));
                this.$router.push(this.PAGES.TRIP);
              } else {
                this.$router.push(this.PAGES.APPLICATION);
              }
            },
        );
      } else {
        if (this.requestPage) {
          await this.$services.trip.tripDetailRequest(
              this.tripId,
              res => {
                this.processTrip(res);
              },
              err => {
                if (!this.requestPage) {
                  this.notifyError(this.$t(err.error));
                  this.$router.push(this.PAGES.TRIP);
                } else {
                  this.notifyError(this.$t(err.error));
                  this.$router.push(this.PAGES.APPLICATION);
                }
              },
          );
        } else {
          await this.$services.trip.tripDetail(
              this.tripId,
              res => {
                this.processTrip(res);
              },
              err => {
                if (!this.requestPage) {
                  this.notifyError(this.$t(err.error));
                  this.$router.push(this.PAGES.TRIP);
                } else {
                  this.$router.push(this.PAGES.APPLICATION);
                }
              },
          );
        }
      }
      this.fagDisabled = false;
    },
    processTrip(res) {
      this.$emitter.emit('checkShowButtonSettlementOfExpenses', res);
      let tempActivities = res.activities;
      this.listActivities = this.cloneObject(res.activities);

      let startDate = res?.start_time || '';
      let endDate = res?.end_time || '';

      if (this.checkEmpty(res?.start_time) && !this.checkEmpty(res.activities)) {
        let firstActivityIndex = res.activities.findIndex(item => item.schedules.length > 0);
        startDate = res.activities[firstActivityIndex].date;
      }
      if (this.checkEmpty(res?.end_time) && !this.checkEmpty(res.activities)) {
        let lastActivityIndex = res.activities.reverse().findIndex(item => item.schedules.length > 0);
        endDate = res.activities[lastActivityIndex].date;
      }
      if (res?.activities.length > 0 && res?.start_time) {
        let firstActivityIndex = res.activities.findIndex(item => item.schedules.length > 0);
        if (
            firstActivityIndex != -1 &&
            moment(res.activities[firstActivityIndex].date).isBefore(moment(res.start_time))
        ) {
          startDate = res.activities[firstActivityIndex].date;
        }
      }
      if (res?.activities.length > 0 && res?.end_time) {
        let lastActivityIndex = res.activities.reverse().findIndex(item => item.schedules.length > 0);
        if (lastActivityIndex != -1 && moment(res.activities[lastActivityIndex].date).isAfter(moment(res.end_time))) {
          endDate = res.activities[lastActivityIndex].date;
        }
      }
      let totalDays = moment.duration(moment(endDate).diff(moment(startDate))).asDays() + 1;

      for (let index = 0; index < totalDays; index++) {
        const st = moment(startDate).add(index, 'days').format('YYYY/MM/DD');
        const inx = tempActivities.findIndex(item => item.date === st);

        if (inx === -1) {
          tempActivities.push({
            date: st,
          });
        }
      }

      tempActivities.sort((a, b) => {
        return moment(a.date).valueOf() - moment(b.date).valueOf();
      });

      let cloneActivities = this.cloneObject(tempActivities);
      let firstActivityIndex = cloneActivities.findIndex(item => item?.schedules && item.schedules.length > 0);
      if (firstActivityIndex != -1) {
        this.dateMinMax.min = cloneActivities[firstActivityIndex].date;
      }

      cloneActivities = this.cloneObject(tempActivities).reverse();
      let lastActivityIndex = cloneActivities.findIndex(item => item?.schedules && item.schedules.length > 0);
      if (lastActivityIndex != -1) {
        this.dateMinMax.max = cloneActivities[lastActivityIndex].date;
      }

      let activities = tempActivities.filter(item => {
        if (item.date && startDate && endDate && startDate <= item.date && endDate >= item.date) {
          if (item.schedules) {
            let schedules = item.schedules.filter(i => {
              i.date_time = i.start_date + ' ' + i.start_time || '23:59:59';
              i.schedule_type = ACTIVITY_ACTION;
              if (this.checkEmpty(i?.departure_station) && !this.checkEmpty(i?.departure_station_relation)) {
                i.departure_station = this.labelLocale(i?.departure_station_relation);
              }
              if (this.checkEmpty(i?.arrival_station) && !this.checkEmpty(i?.arrival_station_relation)) {
                i.arrival_station = this.labelLocale(i?.arrival_station_relation);
              }

              if (i?.schedule_type_id == 6) {
                if (i?.hotel_checkout_time != item.date) return i;
                else return false;
              } else return i;
            });

            let todos = item.todos.map(i => {
              i.schedule_type = ACTIVITY_TODO;
              return i;
            });

            item.schedules = [...schedules, ...todos];
            //
            // item.schedules.sort((a, b) => {
            //   return moment(a.date_time).valueOf() - moment(b.date_time).valueOf();
            // });
          }

          return item;
        }
      });

      activities.sort((a, b) => {
        return moment(a.date).valueOf() - moment(b.date).valueOf();
      });
      activities.forEach((item, idx) => {
        if (!this.checkEmpty(item.schedules) && item.schedules[item.schedules.length - 1].time_zone_id) {
          let timeZoneNow = item.schedules[item.schedules.length - 1].time_zone_id;
          item.timeZoneNow = timeZoneNow;
        } else {
          if (idx > 0) {
            item.timeZoneNow = activities[idx - 1].timeZoneNow;
          } else {
            item.timeZoneNow = '';
          }
        }
      });

      this.start_time_temp = res.start_time;
      this.end_time_temp = res.end_time;
      this.tripStartTimeOfficial = res.start_time;
      this.tripEndTimeOfficial = res.end_time;
      this.tripNameOfficial = res.name;
      this.tripDate = {
        start_time: res.start_time,
        end_time: res.end_time,
      };
      this.trip = {...res, activities};

      if (
          (this.trip.trip_status === 2 && [1, 2, 3, 4, 5, 6, 7].includes(this.trip.report_status?.id)) ||
          [1, 2, 3, 4, 5].includes(this.trip.expense_settlement?.id)
      ) {
        this.isTripApproval = true;
      }
      if (this.trip.trip_type === 1 && [2].includes(this.trip.approval_status?.id)) {
        this.isTripApprovalDisable = true;
      }
      this.showNoteInsurance = true;
      this.$refs.report_list_field.getReportList();
      setTimeout(() => {
        this.firstLoading = false;
      }, 100);
    },
    needApproveStatus() {
      this.startLoading();
      this.fagDisabled = true;
      this.$services.trip.needApproveTrip(
          this.tripId,
          () => {
            this.showBoxNeedApprove = false;
            this.getTripDetail();
            this.endLoading();
          },
          err => {
            this.fagDisabled = false;
            this.endLoading();
            this.notifyError(this.$t(err.error));
          },
      );
    },
    scrollToSchedule(id) {
      this.$nextTick(function () {
        let elementPosition = document.getElementsByClassName('schedule' + id);

        if (elementPosition?.length > 0) {
          setTimeout(() => {
            window.scrollTo({
              top: elementPosition[0].offsetTop - 50,
              behavior: 'smooth',
            });
          }, 100);
        }
      });
    },
    textToRequestType(typeId) {
      switch (typeId) {
        case 1:
          return this.$t('application.type.approval_request');
        case 2:
          return this.$t('application.type.report_request');
        case 3:
          return this.$t('application.type.expense_reimbursement');
        default:
          return '-';
      }
    },
    updateTrip(action) {
      let dataUpdate = {};

      if (action == 'name' && this.trip?.name?.trim()) {
        dataUpdate.name = this.trip.name.trim();
      }

      if (action == 'type') {
        dataUpdate.trip_type = this.trip.trip_type;
      }

      if (action == 'date') {
        dataUpdate = {
          start_time: this.trip.start_time,
          end_time: this.trip.end_time,
        };
      }

      if (this.checkEmpty(dataUpdate)) return;
      dataUpdate.updated_at = this.trip.updated_at;
      this.isSubmit = true;

      this.startLoading();
      this.$services.trip.updateTrip(
          this.tripId,
          dataUpdate,
          () => {
            this.endLoading();
            this.getTripDetail();
          },
          err => {
            this.endLoading();
            this.notifyError(this.$t(err.error), true);
          },
      );
    },
    handleChangeDate() {
      if (this.start_time_temp && this.end_time_temp) {
        const oldStartTime = this.trip.start_time;
        const oldEndTime = this.trip.end_time;

        if (
            (this.firstDateActivities && this.firstDateActivities < this.start_time_temp) ||
            (this.lastDateActivities && this.lastDateActivities > this.end_time_temp)
        ) {
          this.errors_message.dateMinMax = this.$t('E-CM-123');
          return;
        }

        this.trip = {
          ...this.trip,
          start_time: this.start_time_temp,
          end_time: this.end_time_temp,
        };

        if (this.start_time_temp != oldStartTime || this.end_time_temp != oldEndTime) {
          this.isSubmit = true;
          this.updateTrip('date');
          this.$emitter.emit('reloadTripTime', {
            startTime: this.start_time_temp,
            endTime: this.end_time_temp,
          });
          this.$emit('onChangeTimeTrip', {
            startTime: this.start_time_temp,
            endTime: this.end_time_temp,
          });
        }

        this.showEditDate = false;
      }
    },
    changeTripType(type) {
      if (this.trip.trip_type != type) {
        this.trip.trip_type = type;
        this.updateTrip('type');
      }

      this.showEditTripType = false;
    },
    pickerFromOptions(time) {
      return (
          (this.end_time_temp &&
              moment(new Date(time)).format('YYYY-MM-DD') > moment(this.end_time_temp).format('YYYY-MM-DD')) ||
          moment(new Date(time)).format('YYYY-MM-DD') < moment(this.trip.created_at).format('YYYY-MM-DD')
      );
    },
    pickerToOptions(time) {
      if (this.start_time_temp)
        return moment(new Date(time)).format('YYYY-MM-DD') < moment(this.start_time_temp).format('YYYY-MM-DD');
      return false;
    },
    getColorId(item) {
      if (this.showApprovalExpense) {
        if (!this.checkEmpty(item.expense_settlement)) {
          return item.expense_settlement?.color;
        }
      }
      if (this.showApprovalExpense) {
        if (!this.checkEmpty(item.report_status)) {
          return item.report_status?.color;
        }
        if (!this.checkEmpty(item.approval_status)) {
          return item.approval_status?.color;
        }
      }

      return;
    },
    getBackgroundColor(item) {
      if (this.showApprovalExpense) {
        if (!this.checkEmpty(item.expense_settlement)) {
          return item.expense_settlement?.background_color;
        }
      }
      if (this.isShowSettingApproval) {
        if (!this.checkEmpty(item.report_status)) {
          return item.report_status?.background_color;
        }
        if (!this.checkEmpty(item.approval_status)) {
          return item.approval_status?.background_color;
        }
      }

      return;
    },
    getStatusLabel(item) {
      if (!this.checkEmpty(item.expense_settlement)) {
        return this.labelLocale(item.expense_settlement);
      }
      if (!this.checkEmpty(item.report_status)) {
        return this.labelLocale(item.report_status);
      }
      if (!this.checkEmpty(item.approval_status)) {
        return this.labelLocale(item.approval_status);
      }
      return;
    },
    checkDataSchedule() {
      this.listScheduleError = [];
      if (this.userIsSB) {
        if (!this.checkEmpty(this.trip.activities)) {
          this.trip.activities.forEach(activity => {
            if (!this.checkEmpty(activity.schedules)) {
              activity.schedules.forEach(schedule => {
                if (this.checkScheduleHaveErr(schedule)) this.listScheduleError.push(schedule.id);
              });
            }
          });
        }
        return this.checkEmpty(this.listScheduleError) ? true : false;
      }

      if (!this.checkEmpty(this.trip.activities)) {
        this.trip.activities.forEach(activity => {
          if (!this.checkEmpty(activity.schedules)) {
            activity.schedules.forEach(schedule => {
              if (
                  (schedule.schedule_type_id === 1 &&
                      // !this.checkEmpty(schedule.input_id) &&
                      (this.checkEmpty(schedule.flight_number) ||
                          this.checkEmpty(schedule.seat_class_type) ||
                          this.checkEmpty(schedule.departure_location_id) ||
                          this.checkEmpty(schedule.arrival_location_id) ||
                          this.checkEmpty(schedule.start_time) ||
                          this.checkEmpty(schedule.end_time))) ||
                  (schedule.schedule_type_id === 2 &&
                      // !this.checkEmpty(schedule.input_id) &&
                      (this.checkEmpty(schedule.train_number) ||
                          this.checkEmpty(schedule.departure_station) ||
                          this.checkEmpty(schedule.arrival_station) ||
                          this.checkEmpty(schedule.start_time) ||
                          this.checkEmpty(schedule.end_time) ||
                          this.checkEmpty(schedule.time_zone_id))) ||
                  (schedule.schedule_type_id === 6 &&
                      !this.checkEmpty(schedule.input_id) &&
                      (this.checkEmpty(schedule.hotel_name) ||
                          this.checkEmpty(schedule.hotel_room) ||
                          this.checkEmpty(schedule.time_zone_id) ||
                          this.checkEmpty(schedule.location_string))) ||
                  (schedule.schedule_type_id === 17 &&
                      !this.checkEmpty(schedule.input_id) &&
                      (this.checkEmpty(schedule.title) ||
                          this.checkEmpty(schedule.time_zone_id) ||
                          this.checkEmpty(schedule.location_string)))
              ) {
                this.listScheduleError.push(schedule.id);
              }
            });
          }
        });
      }
      if (!this.checkEmpty(this.listScheduleError) && !this.checkEmpty(this.trip.activities)) {
        let firstScheduleErr = '';
        this.trip.activities.find(item => {
          if (!this.checkEmpty(item.schedules))
            item.schedules.find(schedule => {
              if (schedule.id == this.listScheduleError[0]) firstScheduleErr = item;
            });
        });
        if (!this.checkEmpty(firstScheduleErr))
          this.scrollIntoDivCustom(
              `day_detail_${firstScheduleErr.date.split('/')[1]}_${firstScheduleErr.date.split('/')[2]}`,
          );
      }

      return this.checkEmpty(this.listScheduleError) ? true : false;
    },
    checkScheduleHaveErr(schedule) {
      if (this.checkEmpty(schedule.schedule_type_id)) return false;
      let flgValidate = false;
      switch (schedule.schedule_type_id) {
        case 1: {
          if (
              this.checkEmpty(schedule.flight_number) ||
              this.checkEmpty(schedule.seat_class_type) ||
              this.checkEmpty(schedule.departure_location_id) ||
              this.checkEmpty(schedule.arrival_location_id) ||
              this.checkEmpty(schedule.start_time) ||
              this.checkEmpty(schedule.end_time)
          )
            flgValidate = true;
          break;
        }
        case 2: {
          if (
              this.checkEmpty(schedule.train_number) ||
              this.checkEmpty(schedule.departure_station) ||
              this.checkEmpty(schedule.arrival_station) ||
              this.checkEmpty(schedule.start_time) ||
              this.checkEmpty(schedule.end_time) ||
              this.checkEmpty(schedule.time_zone_id)
          )
            flgValidate = true;
          break;
        }
        case 6: {
          if (
              this.checkEmpty(schedule.hotel_name) ||
              this.checkEmpty(schedule.hotel_room) ||
              this.checkEmpty(schedule.time_zone_id) ||
              this.checkEmpty(schedule.location_string)
          )
            flgValidate = true;
          break;
        }
        case 17: {
          if (
              this.checkEmpty(schedule.title) ||
              this.checkEmpty(schedule.time_zone_id) ||
              this.checkEmpty(schedule.location_string)
          )
            flgValidate = true;
          break;
        }
      }
      return flgValidate;
    },
  },
  isValidHttpUrl(string) {
    let res = string.match(
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g,
    );
    if (res == null) {
      return false;
    } else {
      return true;
    }
  },
  isValidFlightNumber(string) {
    let res = string.match(/^[A-Z|a-z]{1}[A-Z|a-z|0-9]{1}[0-9]{1,5}$/g);
    if (res == null) {
      return false;
    } else {
      return true;
    }
  },
};
</script>
<style scoped>
#plan-title--input::placeholder {
  font-size: 18px;
}
.col-submit-trip {
  display: flex;
  justify-content: flex-end;
  float: right;
}

.custom-cancel-request-1 {
  margin-left: 9.5vw;
}

.comment-box {
  border: none;
  margin-bottom: -10px;
  color: #67c23a !important;
}

.comment-text {
  color: #000000;
  font-weight: bold;
}
</style>
