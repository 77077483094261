<template>
  <nav class="navbar navbar-default navbar-lg">
    <div class="navbar-header">
      <h2 class="navbar-title">{{ $t('requirement.requirement') }}</h2>
    </div>
  </nav>
  <div class="wrapper">
    <div class="container-fluid">
      <div class="filter-wrap">
        <div class="filter" v-show="showBoxFilter">
          <div class="section section-filter noborder">
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <div class="form-group">
                  <label>
                    <span class="label-text">{{ $t('requirement.journey') }}</span>
                  </label>
                  <input
                    v-model="searchParams.trip_name"
                    maxlength="50"
                    type="text"
                    :placeholder="$t('requirement.pl_journey')"
                    class="form-control input-sm"
                  />
                  <p class="error-feedback" v-text="errors.trip_name"></p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <div class="form-group">
                  <label>
                    <span class="label-text">{{ $t('requirement.company_name') }}</span>
                  </label>
                  <input
                    v-model="searchParams.company_name"
                    maxlength="50"
                    type="text"
                    :placeholder="$t('requirement.pl_company_name')"
                    class="form-control input-sm"
                  />
                  <p class="error-feedback" v-text="errors.company_name"></p>
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="form-group">
                  <label>
                    <span class="label-text">{{ $t('requirement.employee_number') }}</span>
                  </label>
                  <input
                    v-model="searchParams.employee_number"
                    maxlength="50"
                    type="text"
                    :placeholder="$t('requirement.pl_employee_number')"
                    class="form-control input-sm"
                  />
                  <p class="error-feedback" v-text="errors.employee_number"></p>
                </div>
              </div>
            </div>
            <div class="row">
              <DatePickerWithRange
                :parent-start-date="searchParams.departure_date_from"
                :parent-end-date="searchParams.departure_date_to"
                :date-picker-title="$t('requirement.departure_date')"
                @listenStartDateChange="listenDepartureDateFrom"
                @listenEndDateChange="listenDepartureDateTo"
              />
              <DatePickerWithRange
                :parent-start-date="searchParams.arrival_date_from"
                :parent-end-date="searchParams.arrival_date_to"
                :date-picker-title="$t('requirement.arrival_date')"
                @listenStartDateChange="listenArrivalDateFrom"
                @listenEndDateChange="listenArrivalDateTo"
              />
            </div>

            <div class="row">
              <div class="col-sm-12 col-md-6">
                <div class="form-group">
                  <label>
                    <span class="label-text">{{ $t('requirement.country') }}</span>
                  </label>
                  <div class="btn-group bootstrap-select show-tick select-block select-sm js-select_item">
                    <MultiOptionSelect
                      ref="ListCountrySelect"
                      :list-data="listCountries"
                      :default-list="searchParams.countries"
                      :display-by-locale="true"
                      :is-display-search="true"
                      :isListCountry="true"
                      @handle-selected-params="listenCountry"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="row" style="display: -webkit-box">
              <div class="col-sm-12 col-md-6">
                <div class="form-group">
                  <label>
                    <span class="label-text">{{ $t('requirement.status_requirement') }}</span>
                  </label>
                  <div class="btn-group bootstrap-select show-tick select-block select-sm">
                    <MultiOptionSelect
                      ref="ListStatusSelect"
                      :list-data="listStatus"
                      :default-list="searchParams.status_list"
                      :displayByLocale="true"
                      :is-display-search="false"
                      @handle-selected-params="listenStatus"
                    />
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-12" style="margin-left: -12px">
                <DatePickerWithRange
                  :parent-start-date="searchParams.information_date_from"
                  :parent-end-date="searchParams.information_date_to"
                  :date-picker-title="$t('requirement.information_date')"
                  @listenStartDateChange="listenInformationDateFrom"
                  @listenEndDateChange="listenInformationDateTo"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- /.filter -->
        <div class="section-submit" v-show="showBoxFilter">
          <span class="submit-arrow"></span>
          <div class="row">
            <div class="col-sm-4 col-md-5 hidden-xs">
              <button class="btn btn-sm btn-flat-default btn-clear" @click="clearFilter()">
                {{ $t('common.clear') }}
              </button>
            </div>
            <div class="col-sm-4 col-md-2">
              <button class="btn btn-sm btn-primary btn-block" @click="handleSearch()">
                {{ $t('common.search') }}
              </button>
            </div>
            <div class="col-sm-4 col-md-5 hidden-xs">
              <button class="btn btn-sm btn-flat-default btn-close" @click="showBoxFilter = false">
                {{ $t('common.close') }}
              </button>
            </div>
          </div>
        </div>
        <div
          class="btn-filter-again with-condition"
          v-show="!showBoxFilter"
          @click="showBoxFilter = true"
          style="display: block"
        >
          <div class="filter--condition">
            <span>{{ $t('common.all') }}</span>
            <span style="color: #3650a5">{{ $t('common.reset_conditions') }}</span>
          </div>
        </div>
        <div class="filter-result">
          <div class="section noborder">
            <Pagination
              v-if="listRequirements.length > 0"
              @change-page="changePageData"
              :paginate="paginate"
              @change-number="changeNumber"
            />
            <div class="hidden-xs">
              <div class="table-responsive">
                <table class="table table-hover table-hover--pointer table--property-list">
                  <thead>
                    <tr>
                      <th style="width: 12.5%">{{ $t('requirement.destination_country') }}</th>
                      <th
                        class="table-sort"
                        @click="sortAction('start_time')"
                        :class="sortActive.column == 'start_time' && 'table-sort-active'"
                      >
                        <span :class="sortActive.column == 'start_time' ? sortActive.by : 'asc'">
                          {{ $t('requirement.period') }}
                        </span>
                      </th>
                      <th>{{ $t('requirement.journey') }}</th>
                      <th>{{ $t('requirement.status_requirement') }}</th>
                      <th>{{ $t('requirement.information_date_time') }}</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in listRequirements"
                      :key="index"
                      :class="item.status === 3 && 'done muted'"
                    >
                      <td @click="onRedirectRequirementItemPage(item)">
                        {{ item.country }}
                      </td>
                      <td @click="onRedirectRequirementItemPage(item)">
                        {{ showDateTime(item.trip_start_time, 'YYYY/MM/DD（ddd）') }} -
                        {{ showDateTime(item.trip_end_time, 'YYYY/MM/DD（ddd）') }}
                      </td>

                      <td class="toTrip" @click="onRedirectRequirementItemPage(item)">
                        {{ item.trip_code }}：{{ item.trip_name }}
                      </td>
                      <td v-if="item.status === 4" @click="onRedirectRequirementItemPage(item)">
                        {{ getRequirementStatusLabel(item.status) }}
                        <span class="text-info">{{ $t('requirement.CIQ_update') }}</span>
                      </td>
                      <td v-else @click="onRedirectRequirementItemPage(item)">
                        {{ getRequirementStatusLabel(item.status) }}
                      </td>
                      <td @click="onRedirectRequirementItemPage(item)">
                        {{ showDateTime(item.updated_at, 'YYYY/MM/DD HH:mm') }}
                      </td>
                      <td @click="onRedirectRequirementItemPage(item)">{{ item?.creator?.name }}</td>
                      <td class="toTrip">
                        <!-- <a @click="goToTripItem(item.trip_id)"> {{ item.trip_code }}：{{ item.trip_name }} </a> -->
                        <a
                          class="btn btn-sm btn-flat-default"
                          v-on:click.stop
                          target="_blank"
                          @click="goToTripItem(item.trip_id)"
                        >
                          <i class="icon-abui-globe" />
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p v-if="listRequirements.length == 0" class="no-data">{{ $t('common.no_data') }}</p>
              </div>
            </div>
            <Pagination
              v-if="listRequirements.length > 0"
              @change-page="changePageData"
              :paginate="paginate"
              @change-number="changeNumber"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- /.container -->
  </div>
</template>
<script>
import Pagination from '@/components/common/Pagination';
import DatePickerWithRange from '@/components/application/DatePickerWithRange';
import MultiOptionSelect from '@/components/common/MultiOptionSelect';

export default {
  name: 'Requirement',
  components: { Pagination, MultiOptionSelect, DatePickerWithRange },
  data() {
    return {
      sortActive: {
        column: 'id',
        by: 'asc',
      },
      listRequirements: [],
      listStatus: [],
      listCountries: [],
      searchParams: {
        trip_name: this.$route.query?.trip_name || '',
        company_name: this.$route.query?.company_name || '',
        employee_number: this.$route.query?.employee_number || '',
        departure_date_from: this.$route.query?.departure_date_from || '',
        departure_date_to: this.$route.query?.departure_date_to || '',
        arrival_date_from: this.$route.query?.arrival_date_from || '',
        arrival_date_to: this.$route.query?.arrival_date_to || '',
        countries: this.$route.query.countries ? JSON.parse(this.$route.query.countries) : [],
        status_list: this.$route.query.status_list ? JSON.parse(this.$route.query.status_list) : [],
        information_date_from: this.$route.query?.information_date_from || '',
        information_date_to: this.$route.query?.information_date_to || '',
      },
      showBoxFilter: false,
      paginate: {
        page: +this.$route.query.page || 1,
        per_page: +this.$route.query.per_page || 10,
      },
      isSearchActivated: this.$route.query.is_search_activated || 0,
      errors: {},
    };
  },
  watch: {
    searchParams: {
      handler: function (newValue) {
        if (this.strLen(newValue.trip_name) > 50) {
          this.errors.trip_name = this.$t('E-CM-111', { size: 50 });
        } else {
          delete this.errors.trip_name;
        }
        if (this.strLen(newValue.company_name) > 50) {
          this.errors.company_name = this.$t('E-CM-111', { size: 50 });
        } else {
          delete this.errors.company_name;
        }
        if (this.strLen(newValue.employee_number) > 50) {
          this.errors.employee_number = this.$t('E-CM-111', { size: 50 });
        } else {
          delete this.errors.employee_number;
        }
      },
      deep: true,
    },
  },
  computed: {},
  created() {
    this.startLoading();
    Promise.all([this.getListRequirement(), this.getListRequirementStatus(), this.getListCountry()]).then(() => {
      this.endLoading();
    });
  },
  methods: {
    goToTripItem(id) {
      let routeData = this.$router.resolve({
        name: this.PAGES.TRIP_UPDATE,
        params: {
          id: id,
        },
      });
      window.open(routeData.href, '_blank');
    },
    getListCountry() {
      this.$services.requirement.getListCountry(
        res => {
          this.listCountries = this.sortCountryByName(res.list);
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    getListRequirementStatus() {
      this.$services.requirement.getListRequirementStatus(
        res => {
          this.listStatus = res.list;
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    sortAction(name) {
      this.sortActive.column = name;
      if (this.sortActive.by == 'dsc') this.sortActive.by = 'asc';
      else this.sortActive.by = 'dsc';

      this.getListRequirement();
    },
    listenStatus(selectedList) {
      this.searchParams.status_list = selectedList;
    },
    listenCountry(selectedList) {
      this.searchParams.countries = selectedList;
    },
    changePageData(page) {
      this.paginate.page = +page;
      this.getListRequirement();
    },
    getListRequirement() {
      const { dataFilter, queryBuild } = this.buildParamsSearch();

      this.startLoading();
      this.$services.requirement.getListRequirement(
        dataFilter,
        queryBuild,
        res => {
          this.endLoading();
          this.listRequirements = res.list;
          this.listRequirements.map(require => {
            if (require.country && typeof require.country === 'string') {
              return require.country;
            }
            if (require.country && typeof require.country === 'object') {
              return (require.country = this.labelLocale(require.country));
            }
          });
          this.paginate = { ...this.paginate, ...res.paginate };
          this.showBoxFilter = false;
          //Keep data search
          this.updateRouter(res);
        },
        err => {
          this.endLoading();
          this.notifyError(this.$t(err.error));
        },
      );
    },
    updateRouter(res) {
      let query = {
        page: res.paginate.current_page,
        per_page: res.paginate.per_page,
        trip_name: this.searchParams.trip_name,
        company_name: this.searchParams.company_name,
        employee_number: this.searchParams.employee_number,
        departure_date_from: this.searchParams.departure_date_from,
        departure_date_to: this.searchParams.departure_date_to,
        arrival_date_from: this.searchParams.arrival_date_from,
        arrival_date_to: this.searchParams.arrival_date_to,
        information_date_from: this.searchParams.information_date_from,
        information_date_to: this.searchParams.information_date_to,
        countries: !this.checkEmpty(this.searchParams.countries) ? JSON.stringify(this.searchParams.countries) : '',
        status_list: !this.checkEmpty(this.searchParams.status_list)
          ? JSON.stringify(this.searchParams.status_list)
          : '',
        is_search_activated: this.isSearchActivated ? 1 : 0,
      };
      for (let key in query) {
        if (this.checkEmpty(query[key])) delete query[key];
      }
      this.$router.push({
        path: this.$route.path,
        query: this.isSearchActivated
          ? query
          : {
              page: res.paginate.current_page,
              per_page: res.paginate.per_page,
            },
      });
    },
    changeNumber(number) {
      this.paginate = {
        ...this.paginate,
        page: 1,
        per_page: number,
      };
      this.getListRequirement();
    },
    clearFilter() {
      this.searchParams = {
        trip_name: '',
        company_name: '',
        employee_number: '',
        departure_date_from: '',
        departure_date_to: '',
        arrival_date_from: '',
        arrival_date_to: '',
        countries: [],
        status_list: [],
        information_date_from: '',
        information_date_to: '',
      };
      this.$refs.ListCountrySelect.clearFilter();
      this.$refs.ListStatusSelect.clearFilter();
    },
    handleSearch() {
      if (!this.checkEmpty(this.errors)) return;

      this.paginate.page = 1;
      this.isSearchActivated = 1;
      this.getListRequirement();
    },

    listenDepartureDateTo(date) {
      this.searchParams.departure_date_to = date;
    },
    listenDepartureDateFrom(date) {
      this.searchParams.departure_date_from = date;
    },
    listenArrivalDateFrom(date) {
      this.searchParams.arrival_date_from = date;
    },
    listenArrivalDateTo(date) {
      this.searchParams.arrival_date_to = date;
    },
    listenInformationDateFrom(date) {
      this.searchParams.information_date_from = date;
    },
    listenInformationDateTo(date) {
      this.searchParams.information_date_to = date;
    },

    buildParamsSearch() {
      const dataFilter = {
        trip_name: this.searchParams.trip_name,
        company_name: this.searchParams.company_name,
        employee_number: this.searchParams.employee_number,
        departure_date_from: this.searchParams.departure_date_from,
        departure_date_to: this.searchParams.departure_date_to,
        arrival_date_from: this.searchParams.arrival_date_from,
        arrival_date_to: this.searchParams.arrival_date_to,
        information_date_from: this.searchParams.information_date_from,
        information_date_to: this.searchParams.information_date_to,
        page: this.paginate.page,
        per_page: this.paginate.per_page,
        sort_column: this.sortActive.column,
        sort_by: this.sortActive.by == 'dsc' ? 'desc' : 'asc',
      };

      if (this.$i18n.locale != 'ja') {
        dataFilter.locale = this.$i18n.locale;
      }
      let queryBuild = '';
      if (!this.checkEmpty(this.searchParams.countries)) {
        this.searchParams.countries.forEach(item => {
          queryBuild += 'countries[]=' + item + '&';
        });
      }
      if (!this.checkEmpty(this.searchParams.status_list)) {
        this.searchParams.status_list.forEach(item => {
          queryBuild += 'status_list[]=' + item + '&';
        });
      }

      if (queryBuild) queryBuild = queryBuild.slice(0, -1);

      return { dataFilter, queryBuild };
    },
    onRedirectRequirementItemPage(item) {
      this.$router.push({
        name: this.PAGES.REQUIREMENT_DETAIL,
        params: {
          id: item.id,
        },
      });
    },
  },
};
</script>
