<template>
  <nav class="navbar navbar-default navbar-lg" role="navigation" id="header-content">
    <div class="navbar-header">
      <h2 class="navbar-title">{{ $t('user.user') }}</h2>
    </div>
    <div class="collapse navbar-collapse">
      <ul class="nav navbar-nav navbar-right">
        <li>
          <a href="#" @click="$router.push(PAGES.USER_NEW)">
            <span class="icon-abui-plus prx"></span>{{ $t('user.add_new') }}
          </a>
        </li>
      </ul>
    </div>
  </nav>
  <div class="wrapper">
    <div class="container-fluid">
      <div class="section" style="border-bottom: 1px solid #d0d6da">
        <div class="">
          <h4 class="section--title" style="padding-top: 0">{{ $t('sidebar.settings') }}</h4>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label
                  class="checkbox checkbox-inline checkbox-type-div checkbox-expense"
                  :class="isTwoAuthenticated && 'checked'"
                >
                  <span class="icons">
                    <span class="first-icon icon-abui-checkbox-unchecked"></span>
                    <span class="second-icon icon-abui-checkbox-checked"></span>
                  </span>
                  <input type="checkbox" v-model="isTwoAuthenticated" />
                  <span class="text_type" style="padding-left: 3rem !important">
                    {{ $t('settings.two_step_verification_note') }}
                  </span>
                </label>
              </div>
            </div>
            <div class="col-sm-4 col-md-2 col-sm-offset-5 col-md-offset-4">
              <button type="submit" class="btn btn-sm btn-block btn-primary open-alert" @click="saveSetting()">
                {{ $t('common.submit') }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="">
        <div class="">
          <div class="section noborder">
            <Pagination
              v-if="listUsers.length > 0"
              :hasOptionPaginate="false"
              @change-page="changePageData"
              :paginate="paginate"
              @change-number="changeNumber"
            />
            <div class="hidden-xs">
              <div class="table-responsive">
                <table class="table table-hover table--user-list table-hover--pointer">
                  <colgroup span="1" style="width: 12.5%"></colgroup>
                  <colgroup span="1" style="width: 12.5%"></colgroup>
                  <colgroup span="1"></colgroup>
                  <colgroup span="1" style="width: 12.5%"></colgroup>
                  <thead>
                    <tr>
                      <th>{{ $t('user.employee_number') }}</th>
                      <th>{{ $t('user.name') }}</th>
                      <th style="width: 30%">{{ $t('user.deploy') }}</th>
                      <th>{{ $t('user.role_id') }}</th>
                      <th>{{ $t('user.register_date') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in listUsers" :key="index" @click="goToUserDetail(item.id)">
                      <td>{{ item.employee_number }}</td>
                      <td style="width: 20%">{{ item.name }}</td>
                      <td style="width: 20%; word-break: break-word">{{ item.organization_name }}</td>
                      <td style="width: 30%">{{ item.roles ? labelLocale(item.roles) : '' }}</td>
                      <td>{{ showDateTime(item.created_at, 'YYYY/MM/DD') }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <Pagination
              v-if="listUsers.length > 0"
              :hasOptionPaginate="false"
              @change-page="changePageData"
              :paginate="paginate"
              @change-number="changeNumber"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Pagination from '@/components/common/Pagination';
export default {
  name: 'User',
  components: { Pagination },
  data() {
    return {
      listUsers: [],
      isTwoAuthenticated: false,
      paginate: {
        page: +this.$route.query.page || 1,
        per_page: +this.$route.query.per_page || 10,
      },
    };
  },
  created() {
    this.getSetting();
    this.getListUsers();
  },
  methods: {
    getSetting() {
      this.$services.settings.getTwoStepVerification(
        res => {
          this.isTwoAuthenticated = res.is_two_authenticate == 1;
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    saveSetting() {
      this.startLoading();
      this.$services.settings.toggleTwoStepVerification(
        {
          is_two_step_verification: this.isTwoAuthenticated ? 1 : 0,
        },
        () => {
          this.endLoading();
          this.notifySuccess(this.$t('I-CM-103'));
        },
        err => {
          this.endLoading();
          this.notifyError(this.$t(err.error));
        },
      );
    },
    goToUserDetail(id) {
      this.$router.push({
        name: this.PAGES.USER_DETAIL,
        params: {
          id: id,
        },
      });
    },
    changePageData(page) {
      this.paginate.page = +page;
      this.getListUsers();
    },
    async getListUsers() {
      this.startLoading();
      const formData = {
        page: this.paginate.page,
        per_page: this.paginate.per_page,
      };

      await this.$services.user.getListUsers(
        formData,
        res => {
          this.listUsers = res.list;
          this.paginate = { ...this.paginate, ...res.paginate };
          this.endLoading();

          this.$router.push({
            path: this.$route.path,
            query: {
              page: res.paginate.current_page,
              per_page: res.paginate.per_page,
            },
          });
        },
        err => {
          this.endLoading();
          this.notifyError(this.$t(err.error));
        },
      );
    },
    changeNumber(number) {
      this.paginate = {
        ...this.paginate,
        page: 1,
        per_page: number,
      };
      this.getListUsers();
    },
  },
};
</script>
