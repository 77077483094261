import { createApp } from 'vue';

import Cookies from 'js-cookie';

import App from './App.vue';
import './app.css';

import router from './router';
import store from './store';

import ElementPlus from '@/plugins/element-plus';
import services from '@/plugins/services';

import mixins from '@/helpers/mixins';

import PAGES from '@/utils/pages.js';
import VueApexCharts from "vue3-apexcharts";
import { createI18n } from 'vue-i18n';
import en from './locales/en.json';
import ja from './locales/ja.json';
const i18n = createI18n({
  locale: Cookies.get('CurrentLanguage') || 'ja',
  fallbackLocale: 'ja',
  messages: { en, ja },
});

import elen from 'element-plus/es/locale/lang/en';
import elja from 'element-plus/es/locale/lang/ja';
import VueGoogleMaps from '@fawmi/vue-google-maps';

import mitt from 'mitt';
const emitter = mitt();

const app = createApp(App);
app.use(VueApexCharts);
app.config.globalProperties.$services = services;
app.config.globalProperties.PAGES = PAGES;
app.config.globalProperties.$emitter = emitter;

app.mixin(mixins);
app
  .use(router)
  .use(store)
  .use(ElementPlus, {
    locale: (Cookies.get('CurrentLanguage') || 'ja') == 'ja' ? elja : elen,
  })
  .use(i18n)
  .use(VueGoogleMaps, {
    load: {
      key: process.env.VUE_APP_GOOGLE_MAP_API_KEY,
      libraries: 'places',
    },
  })
  .mount('#app');
