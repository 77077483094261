<template>
  <Form @submit.prevent="submitForm" :validation-schema="validate" ref="form">
    <div class="row">
      <div class="col-md-12">
        <Field name="type" type="hidden" class="form-control input-sm" v-model="dataRequest.type" />
        <div class="form-group" style="margin-top: 6px; margin-bottom: 23px">
          <label
            @click="changeType(CONSTANT.BUSINESS_COST_ITEM)"
            class="radio radio-inline js-radio_item"
            style="margin-right: 21px !important"
            :class="checkClass(CONSTANT.BUSINESS_COST_ITEM)"
          >
            <span class="icons">
              <span class="first-icon icon-abui-radio-unchecked"></span>
              <span class="second-icon icon-abui-radio-checked"></span>
            </span>
            <span class="text_type-special">{{ $t('expense.item.type.business_cost_item') }}</span>
          </label>
          <label
            @click="changeType(CONSTANT.INTERNAL_ACCOUNTING_EXPENSE_ITEM)"
            class="radio radio-inline js-radio_item"
            :class="checkClass(CONSTANT.INTERNAL_ACCOUNTING_EXPENSE_ITEM)"
          >
            <span class="icons">
              <span class="first-icon icon-abui-radio-unchecked"></span>
              <span class="second-icon icon-abui-radio-checked"></span>
            </span>
            <span class="text_type-special">{{ $t('expense.item.type.internal_accounting_expense_item') }}</span>
          </label>
        </div>
      </div>
    </div>
    <div
      class="row js-radio_item-expenseType__tgt expenseType_2"
    >
      <div class="col-sm-3">
        <div class="form-group">
          <label>
            <span class="label-text">{{ $t('expense.item.form.import_code') }}</span>
          </label>
          <Field
            name="import_code"
            type="text"
            class="form-control input-sm"
            v-model="dataRequest.import_code"
            maxlength="20"
          />
          <ErrorMessage name="import_code" class="error-feedback" as="p" />

          <p class="error-feedback" v-text="errors_message.import_code && $t(errors_message.import_code, { item: $t('expense.item.form.import_code') })" />
        </div>
      </div>
    </div>
    <div
      v-if="dataRequest.type == CONSTANT.INTERNAL_ACCOUNTING_EXPENSE_ITEM"
      class="row js-radio_item-expenseType__tgt expenseType_2"
    >
      <div class="col-sm-6">
        <div class="form-group">
          <label>
            <span class="label-text">{{ $t('expense.item.form.pro_expense_item') }}</span>
          </label>

          <SingleOptionSelect
            :displayByLocale="false"
            ref="TripPaymentMethodSelect"
            :listData="parentList"
            :defaultList="dataRequest.parent_id"
            @handle-selected-params="listenParentList"
          />

          <p class="error-feedback" v-text="errors_message.parent_id && $t(errors_message.parent_id)" />
        </div>
      </div>
    </div>
    <div class="row" v-if="dataRequest.type == CONSTANT.INTERNAL_ACCOUNTING_EXPENSE_ITEM">
      <div class="col-sm-3">
        <div class="form-group">
          <label>
            <span class="label-text">{{ $t('expense.item.code') }}</span>
          </label>
          <Field
            :disabled="dataRequest.type == CONSTANT.INTERNAL_ACCOUNTING_EXPENSE_ITEM"
            name="code"
            type="text"
            class="form-control input-sm"
            v-model="dataRequest.code"
            maxlength="20"
          />
          <ErrorMessage name="code" class="error-feedback" as="p" />

          <p class="error-feedback" v-text="errors_message.code && $t(errors_message.code)" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <label>
            <span class="label-text">
              <span class="label-text">
                {{ this.dataRequest.type == 1 ? $t('expense.item.form.category_name') : $t('expense.item.form.name') }}
              </span>
            </span>
          </label>
          <Field name="name" type="text" class="form-control input-sm" v-model="dataRequest.name" maxlength="50" />
          <ErrorMessage name="name" class="error-feedback" as="p" />

          <p class="error-feedback" v-if="this.dataRequest.type == 1" v-text="errors_message.name && $t(errors_message.name, { item: $t('expense.item.form.category_name') })" />
          <p class="error-feedback" v-else v-text="errors_message.name && $t(errors_message.name, { item: $t('expense.item.form.name') })" />
        </div>
      </div>
    </div>
    <div class="row" v-if="dataRequest.type == CONSTANT.BUSINESS_COST_ITEM">
      <div class="col-sm-3">
        <div class="form-group">
          <label>
            <span class="label-text">{{ $t('expense.item.code') }}</span>
          </label>
          <Field
            :disabled="dataRequest.type == CONSTANT.INTERNAL_ACCOUNTING_EXPENSE_ITEM"
            name="code"
            type="text"
            class="form-control input-sm"
            v-model="dataRequest.code"
            maxlength="20"
          />
          <ErrorMessage name="code" class="error-feedback" as="p" />

          <p class="error-feedback" v-text="errors_message.code && $t(errors_message.code)" />
        </div>
      </div>
    </div>
    <div
      v-if="dataRequest.type == CONSTANT.INTERNAL_ACCOUNTING_EXPENSE_ITEM"
      class="row js-radio_item-expenseType__tgt expenseType_2"
    >
      <div class="col-sm-3">
        <div class="form-group">
          <label>
            <span class="label-text">{{ $t('expense.item.form.subject_code') }}</span>
          </label>
          <Field
            name="subject_code"
            type="text"
            class="form-control input-sm"
            v-model="dataRequest.subject_code"
            maxlength="50"
          />
          <ErrorMessage name="subject_code" class="error-feedback" as="p" />

          <p class="error-feedback" v-text="errors_message.subject_code && $t(errors_message.subject_code)" />
        </div>
      </div>
    </div>
    <div
      v-if="dataRequest.type == CONSTANT.INTERNAL_ACCOUNTING_EXPENSE_ITEM"
      class="row js-radio_item-expenseType__tgt expenseType_2"
    >
      <div class="col-sm-3">
        <div class="form-group">
          <label>
            <span class="label-text">{{ $t('expense.item.form.sub_subject_code') }}</span>
          </label>
          <Field
            name="sub_subject_code"
            type="text"
            class="form-control input-sm"
            v-model="dataRequest.sub_subject_code"
            maxlength="50"
          />
          <ErrorMessage name="sub_subject_code" class="error-feedback" as="p" />
        </div>
      </div>
    </div>
    <div
      v-if="dataRequest.type == CONSTANT.INTERNAL_ACCOUNTING_EXPENSE_ITEM"
      class="row js-radio_item-expenseType__tgt expenseType_2"
    >
      <div class="col-sm-3">
        <div class="form-group">
          <label>
            <span class="label-text">{{ $t('expense_cost.tax_distinction') }}</span>
          </label>
          <div class="btn-group bootstrap-select select-block select-sm">
            <el-select popper-class="custom-dropdown-select" v-model="dataRequest.tax_type" :clearable="false">
              <el-option
                v-for="item in listTaxClassification"
                :key="item.id"
                :label="labelLocale(item)"
                :value="item.id"
              />
            </el-select>
          </div>
        </div>
      </div>
    </div>
    <div class="row js-radio_item-expenseType__tgt expenseType_2">
      <div class="col-sm-6">
        <div class="form-group">
          <span class="label-additional js-label-tgl-swt">
            <span class="label-text">{{ $t('expense.item.form.valid') }}</span>
          </span>
          <div class="js-tgl-swt js-tgl-swt--rnd has-switch">
            <div
              class="switch-animate"
              :class="dataRequest.is_valid ? 'switch-on' : 'switch-off'"
              @click="changeValid"
            >
              <input type="checkbox" checked="" components="switch" />
              <span class="switch-left"> ON </span>
              <label>&nbsp;</label><span class="switch-right">OFF</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-2">
        <div class="form-group">
          <label>
            <span class="label-text">{{ $t('expense.item.form.express') }}</span>
          </label>
          <Field
            name="display_order"
            type="text"
            placeholder="1"
            style="width: 115px"
            class="form-control input-sm"
            v-model="dataRequest.display_order"
            oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^(?!00[^0])0/, '');"
          />
          <ErrorMessage name="display_order" class="error-feedback" as="p" />
        </div>
      </div>
    </div>
    <hr />

    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label>
            <span class="label-text">{{ $t('expense.item.form.remark') }}</span>
          </label>
          <el-input type="textarea" :rows="2" :autosize="{ minRows: 2, maxRows: 4 }" v-model="dataRequest.note" />
        </div>
      </div>
    </div>
  </Form>
</template>
<script>
import SingleOptionSelect from '@/components/common/SingleOptionSelect.vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import { BUSINESS_COST_ITEM, INTERNAL_ACCOUNTING_EXPENSE_ITEM } from '@/constants/';

export default {
  name: 'ExpenseForm',
  components: { Form, Field, ErrorMessage, SingleOptionSelect },
  emits: ['submit', 'onChangeData'],
  props: {
    dataForm: {
      type: Object,
      default: () => {},
    },
    errors: {
      type: Object,
      default: () => {},
    },
    actionSubmitForm: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      CONSTANT: {
        BUSINESS_COST_ITEM,
        INTERNAL_ACCOUNTING_EXPENSE_ITEM,
      },
      parentList: [],
      showChangeValid: true,
      listTaxClassification: [],
      dataRequest: {
        type: 2,
        tax_type: 3,
        parent_cost_item_id: 0,
      },
      dataRequestOfficial: {
        type: 2,
        tax_type: 3,
        parent_cost_item_id: 0,
      },
      errors_message: {},
    };
  },
  computed: {
    validate() {
      return yup.object().shape({
        type: yup.number(),
        code: yup
          .string()
          .trim()
          .required(this.$t('E-CM-100', { item: this.$t('expense.item.code') }))
          .max(20, this.$t('E-CM-111', { size: 20 })),
        name: yup
          .string()
          .trim()
          .required(this.$t('E-CM-100', { item: this.$t('expense.item.form.name') }))
          .max(50, this.$t('E-CM-111', { size: 50 })),
        subject_code: yup
          .string()
          .trim()
          .when('type', {
            is: 2,
            then: yup.string().nullable(),
          })
          .max(50, this.$t('E-CM-111', { size: 50 })),
        sub_subject_code: yup
          .string()
          .trim()
          .max(50, this.$t('E-CM-111', { size: 50 }))
          .nullable(),
        display_order: yup
          .string()
          .trim()
          .required(this.$t('E-CM-100', { item: this.$t('expense.item.form.express') })),
        import_code: yup
          .string()
          .trim()
          .required(this.$t('E-CM-100', { item: this.$t('expense.item.form.import_code') }))
          .max(20, this.$t('E-CM-111', { size: 20 })),
      });
    },
  },
  created() {
    this.startLoading();
    Promise.all([this.getCostParentList(), this.getTaxClassificationList()]).then(() => {
      this.endLoading();
    });
  },
  watch: {
    dataForm: {
      handler: function (newValue) {
        this.dataRequest = {
          ...newValue,
          display_order: newValue?.display_order == 0 ? '' : newValue?.display_order,
        };
        let data = this.cloneObject(newValue);
        this.dataRequestOfficial = {
          ...data,
          display_order: data?.display_order == 0 ? '' : data?.display_order,
        };
      },
      deep: true,
    },
    errors: {
      handler: function (newValue) {
        this.errors_message = {
          ...this.errors_message,
          code: newValue.code,
          name: newValue.name,
          subject_code: newValue.subject_code,
          import_code: newValue.import_code,
        };
      },
      deep: true,
    },
    'dataRequest.parent_cost_item_id'(val) {
      this.checkOnChangeData();
      if (this.dataRequest.type == 2 && val) {
        this.errors_message.parent_id = '';
      }
    },
    'dataRequest.type'() {
      this.checkOnChangeData();
    },
    'dataRequest.code'() {
      this.checkOnChangeData();
    },
    'dataRequest.name'() {
      this.checkOnChangeData();
    },
    'dataRequest.subject_code'() {
      this.checkOnChangeData();
    },
    'dataRequest.sub_subject_code'() {
      this.checkOnChangeData();
    },
    'dataRequest.tax_type'() {
      this.checkOnChangeData();
    },
    'dataRequest.display_order'(val) {
      if (val) this.dataRequest.display_order = this.convertNumberFullToHalf(val);
      this.checkOnChangeData();
    },
    'dataRequest.note'() {
      this.checkOnChangeData();
    },
    'dataRequest.is_valid'() {
      this.checkOnChangeData();
    },
    'dataRequest.parent_is_valid'(val) {
      if (!val) {
        this.showChangeValid = false;
      }
    },
    'dataRequest.import_code'() {
      this.checkOnChangeData();
    },
    actionSubmitForm() {
      this.errors_message.code = '';
      this.errors_message.name = '';
      this.errors_message.subject_code = '';
      this.errors_message.import_code = '';
      this.$emit('onChangeData', false);
      this.$refs.form.validate().then(res => {
        let dataForm = this.dataRequest;

        if (this.dataRequest.type == 2) {
          if (this.dataRequest.parent_cost_item_id) {
            this.errors_message.parent_id = '';
          } else {
            this.errors_message.parent_id = this.$t('E-CM-100', {
              item: this.$t('expense.item.form.pro_expense_item'),
            });
          }
        } else {
          delete dataForm.subject_code;
          delete dataForm.sub_subject_code;
          delete this.dataRequestOfficial.subject_code;
          delete this.dataRequestOfficial.sub_subject_code;
          this.errors_message.parent_id = '';
        }

        if (res.valid && this.errors_message.parent_id == '') {
          this.$emit('submit', dataForm);
        }
      });
    },
  },
  methods: {
    convertNumberFullToHalf(number) {
      if (!number) return '';

      number = String(number).replace(/[^0-9０-９]/g, '');
      number = String(number).replace(/[\uFF10-\uFF19]/g, function (m) {
        return String.fromCharCode(m.charCodeAt(0) - 0xfee0);
      });

      return Number(number);
    },
    changeValid() {
      if (this.showChangeValid) {
        this.dataRequest.is_valid = !this.dataRequest.is_valid;
      } else {
        this.dataRequest.is_valid = false;
      }
    },
    checkOnChangeData() {
      let result = !this.compareObject(this.dataRequest, this.dataRequestOfficial);
      if (result) {
        this.$emit('onChangeData', true);
      } else {
        this.$emit('onChangeData', false);
      }
    },
    async getTaxClassificationList() {
      await this.$services.common.getTaxClassificationList(
        res => {
          this.listTaxClassification = res.list;
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    changeType(type) {
      if (!this.dataRequest?.id && type) this.dataRequest.type = type;
      this.checkOnChangeData();
    },
    checkClass(type) {
      let result = '';
      if (this.dataRequest.id) result = 'disabled ';

      if (this.dataRequest.type == type) result += ' checked';

      return result;
    },
    submitForm() {},
    listenParentList(selectedList) {
      this.checkOnChangeData();
      this.dataRequest.parent_cost_item_id = selectedList;
      this.dataRequestOfficial.parent_cost_item_id = selectedList;

      const parentData = this.parentList.find(item => item.id == selectedList);
      if (parentData) {
        this.dataRequest.code = parentData.code;
        if (!parentData.is_valid) {
          this.showChangeValid = false;
          this.dataRequest.is_valid = false;
        } else {
          this.showChangeValid = true;
        }
      }
    },
    async getCostParentList() {
      await this.$services.cost.getCostParentList(
        res => {
          this.parentList = res.list;
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
  },
};
</script>
