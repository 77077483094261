const PAGES = {
  HOME: '/',
  DASHBOARD: '/dashboard',
  LOGIN: '/login',
  UNAUTHORIZED: '/401_Unauthorized',
  TRIP: '/trip',
  APPLICATION: '/request',
  TRIP_ITEM: '/request/:id/detail',
  TRIP_NEW: '/trip/new',
  TRIP_UPDATE: '/trip/:id/update',
  REGULATION_CHECK: '/regulation-check',
  REGULATION_CHECK_DETAIL: '/regulation-check/:id/detail',
  UPDATE_PASSWORD: '/change-password',
  INFORMATION: '/information',
  EXPENSE: '/expense/',
  EXPENSE_ITEM: '/expense-item',
  EXPENSE_ITEM_EDIT: '/expense-item/:id/edit',
  EXPENSE_ITEM_CREATE: '/expense-item/new',
  INFORMATION_DETAIL: '/information/:id/detail',
  ZONE: '/zone',
  ZONE_NEW: '/zone/new',
  ZONE_DETAIL: '/zone/:id/detail',
  CRISIS_MANAGEMENT: '/crisis-management',
  CRISIS_MANAGEMENT_DETAIL: '/crisis-management/:id/detail',
  CRISIS_MANAGEMENT_CREATE: '/crisis-management/create',
  ORGANIZATION: '/organization',
  ORGANIZATION_CREATE: '/organization/create',
  ORGANIZATION_DETAIL: '/organization/:id/detail',
  INPUT: '/input',
  INFO: '/info',
  EMPLOYEE: '/employee',
  POSITION: '/position',
  DAILY_ALLOWANCE: '/daily-allowance',
  SETTINGS: '/settings',
  REGULATION: '/regulation',
  EMPLOYEE_NEW: '/employee/new',
  EMPLOYEE_DETAIL: '/employee/:id/detail',
  PROFILE: '/profile',
  BUDGET: '/budget',
  SETTINGS_CIQ: '/setting-CIQ',
  SETTINGS_CIQ_DETAIL: '/setting-CIQ/:id/detail',
  APPROVAL_FLOW: '/approval-flow',
  INPUT_DETAIL: '/input/:id/detail',
  INPUT_PREVIEW: '/prevew/:id/detail',
  CUSTOMER_ENTERPRISE: '/company',
  CUSTOMER_ENTERPRISE_DETAIL: '/company/:id/detail',
  CUSTOMER_ENTERPRISE_NEW: '/company/create',
  NOTIFICATION: '/notification',
  NOTIFICATION_CREATE: '/notification/create',
  NOTIFICATION_DETAIL: '/notification/:id/detail',
  APPROVAL_NEW: '/approval-flow/new',
  APPROVAL_DETAIL: '/approval-flow/:id/detail',
  USER: '/user',
  USER_NEW: '/user/new',
  USER_DETAIL: '/user/:id/detail',
  REGULATION_AREA: '/regulation-area',
  REGULATION_AREA_NEW: '/regulation-area/new',
  REGULATION_AREA_DETAIL: '/regulation-area/:id/detail',
  REQUIREMENT: '/requirement',
  REQUIREMENT_DETAIL: '/requirement/:id/detail',
  SSO_AUTH: '/login/sso/authed',
};

export default PAGES;
