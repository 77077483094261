export const REQUEST_BUSINESS_TRIP_TYPE = 1;
export const REQUEST_CALCULATE_TYPE = 2;
export const CANCEL = 0;
export const APPROVAL = 1;
export const PASS_ROUND_PERUSAL = 2;
export const REMAND = 3;
export const HOLD = 4;
export const REMOVE_HOLD = 5;
export const payment_reimbursement = 1;
export const PAYMENT_CORPORATE_CARD = 2;
export const PAYMENT_INVOICE = 3;

export const EXPENSE_SETTLEMENT = 3;
export const REPORT_STATUS = 2;
export const APPROVAL_STATUS = 1;

export const ACTIVITY_ACTION = 1;
export const ACTIVITY_TODO = 2;
export const COMMON_IMAGE_EXTENSIONS = [
  'apng',
  'avif',
  'gif',
  'jpg',
  'jpeg',
  'jfif',
  'pjpeg',
  'pjp',
  'png',
  'svg',
  'webp',
  'heic',
  'heif',
];
