<template>
  <nav class="navbar navbar-default navbar-lg" role="navigation" id="header-content">
    <div class="navbar-header">
      <h2 class="navbar-title">{{ $t('sidebar.daily_allowance') }}</h2>
    </div>
  </nav>

  <body class="">
    <div class="wrapper">
      <div class="container-fluid">
        <div class="section">
          <h5>{{ $t('daily_allowance.all_day') }}</h5>
          <div class="js-addcontrol1--wrap">
            <div
              v-for="(item, index) in all_days"
              :key="index"
              class="row addcontrol--item scroll-margin-top"
              :id="`all_days_element_${index}`"
            >
              <div class="col-sm-3">
                <div class="form-group">
                  <input
                    type="text"
                    class="adjust-amount form-control input-sm"
                    :class="!checkEmpty(item?.error_message?.daily_allowance_type) && 'has-validation-error'"
                    :placeholder="$t('daily_allowance.daily_allowance_type_name')"
                    v-model="item.daily_allowance_type"
                  />
                  <p class="error-feedback" v-text="item?.error_message?.daily_allowance_type" />
                </div>
              </div>
              <div class="col-sm-5">
                <div class="form-group">
                  <SingleOptionSelect
                    :placeholder="$t('common.please_select')"
                    :class="!checkEmpty(item?.error_message?.zone_id) && 'has-validation-error-el'"
                    :list-data="listZone"
                    @handle-selected-params="selectZoneAllDay"
                    :is-display-search="false"
                    :display-by-locale="true"
                    :option-index="{ haveIndex: true, index }"
                    :default-list="item.zone_id"
                  />
                  <p class="error-feedback" v-text="item?.error_message?.zone_id" />
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group text-right">
                  <label
                    class="checkbox checkbox-inline checkbox-type-div checkbox-expense"
                    :class="item?.is_depend_position && 'checked'"
                  >
                    <span class="icons">
                      <span class="first-icon icon-abui-checkbox-unchecked" />
                      <span class="second-icon icon-abui-checkbox-checked" />
                    </span>
                    <input type="checkbox" v-model="item.is_depend_position" />
                    <span class="text_type" style="margin-left: 32px">
                      {{ $t('daily_allowance.specified_by_job_title') }}
                    </span>
                  </label>
                </div>
              </div>
              <div v-show="!item.is_depend_position" class="js-check_jobtitile__tgt--off scroll-margin-top">
                <div class="col-sm-2">
                  <div class="form-group">
                    <div class="input-group adjust-amount">
                      <input
                        type="text"
                        v-model="item.amount"
                        :class="!checkEmpty(item?.error_message?.amount) && 'has-validation-error'"
                        autocomplete="off"
                        class="adjust-amount form-control input-sm text-right"
                        onkeydown="javascript: return event.keyCode !== 69;"
                        @input="item.amount = convertNumberFullToHalf(item.amount)"
                        maxlength="11"
                        @change="convertDataCommon('all_days.amount', true, index, true)"
                      />
                      <span class="input-group-addon">{{ $t('daily_allowance.circle') }}</span>
                    </div>
                    <p class="error-feedback" v-text="item?.error_message?.amount" />
                  </div>
                </div>
              </div>
              <div v-show="item.is_depend_position" class="js-check_jobtitile__tgt--on">
                <div class="col-sm-2">
                  <div
                    v-for="(position, positionIndex) in item.position_amounts"
                    :key="positionIndex"
                    class="regulation-jobtitle scroll-margin-top"
                    style="display: block !important"
                    :id="`all_days_position_element_${positionIndex}`"
                  >
                    <div style="display: flex; align-items: center">
                      <div class="form-inline-text regulation-jobtitle--label">
                        {{ getPositionName(position.position_id) }}
                      </div>
                      <div class="row">
                        <div class="col-sm-12">
                          <div class="form-group">
                            <div class="input-group adjust-amount">
                              <input
                                type="text"
                                v-model="position.amount"
                                :class="!checkEmpty(position?.error_message) && 'has-validation-error'"
                                autocomplete="off"
                                class="adjust-amount form-control input-sm text-right"
                                onkeydown="javascript: return event.keyCode !== 69;"
                                @input="position.amount = convertNumberFullToHalf(position.amount)"
                                maxlength="11"
                                @change="
                                  convertNestedDataAmount('all_days.position_amounts.amount', index, positionIndex)
                                "
                              />
                              <span class="input-group-addon">{{ $t('daily_allowance.circle') }}</span>
                            </div>
                            <p class="error-feedback" v-text="position?.error_message" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button
                @click="deleteItem(2, item)"
                class="btn btn-sm btn-flat-danger js-addcontrol--del addcontrol--item-del"
              >
                <span class="icon-abui-cross" />
              </button>
            </div>
            <div class="row--addcontrol">
              <button class="btn btn-sm btn-flat-primary js-btn-addcontrol-allday" @click="addNewItem(2)">
                <span class="icon-abui-plus prx" />{{ $t('daily_allowance.add_daily_allowance_type') }}
              </button>
            </div>
          </div>
          <hr class="mtl mbl" />
          <div class="" style="margin-top: 24px">
            <div class="row addcontrol--item scroll-margin-top" id="all_day_common_element">
              <div class="col-sm-8">
                <div class="form-group">{{ $t('daily_allowance.common_destination_other_than_above') }}</div>
              </div>
              <div class="col-sm-2">
                <div class="form-group text-right">
                  <label
                    class="checkbox checkbox-inline checkbox-type-div checkbox-expense"
                    :class="all_day_common?.is_depend_position && 'checked'"
                  >
                    <span class="icons">
                      <span class="first-icon icon-abui-checkbox-unchecked" />
                      <span class="second-icon icon-abui-checkbox-checked" />
                    </span>
                    <input type="checkbox" v-model="all_day_common.is_depend_position" />
                    <span class="text_type" style="margin-left: 32px">
                      {{ $t('daily_allowance.specified_by_job_title') }}
                    </span>
                  </label>
                </div>
              </div>
              <div v-show="!all_day_common.is_depend_position" class="js-check_jobtitile__tgt--off scroll-margin-top">
                <div class="col-sm-2">
                  <div class="form-group">
                    <div class="input-group adjust-amount">
                      <input
                        type="text"
                        v-model="all_day_common.amount"
                        :class="!checkEmpty(all_day_common?.error_message) && 'has-validation-error'"
                        autocomplete="off"
                        class="adjust-amount form-control input-sm text-right"
                        onkeydown="javascript: return event.keyCode !== 69;"
                        @input="all_day_common.amount = convertNumberFullToHalf(all_day_common.amount)"
                        maxlength="11"
                        @change="convertDataCommon('all_day_common.amount', false, -1, true)"
                      />
                      <span class="input-group-addon">{{ $t('daily_allowance.circle') }}</span>
                    </div>
                    <p class="error-feedback" v-text="all_day_common?.error_message" />
                  </div>
                </div>
              </div>
              <div v-show="all_day_common.is_depend_position" class="js-check_jobtitile__tgt--on">
                <div class="col-sm-2">
                  <div
                    v-for="(position, positionIndex) in all_day_common.position_amounts"
                    :key="positionIndex"
                    class="regulation-jobtitle scroll-margin-top"
                    :id="`all_day_common_position_${positionIndex}`"
                  >
                    <div class="form-inline-text regulation-jobtitle--label">
                      {{ getPositionName(position.position_id) }}
                    </div>
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="form-group">
                          <div class="input-group adjust-amount">
                            <input
                              type="text"
                              v-model="position.amount"
                              :class="!checkEmpty(position?.error_message) && 'has-validation-error'"
                              autocomplete="off"
                              class="adjust-amount form-control input-sm text-right"
                              onkeydown="javascript: return event.keyCode !== 69;"
                              @input="position.amount = convertNumberFullToHalf(position.amount)"
                              maxlength="11"
                              @change="convertDataCommon('all_day_common.position_amounts.amount', true, index, true)"
                            />
                            <span class="input-group-addon">{{ $t('daily_allowance.circle') }}</span>
                          </div>
                          <p class="error-feedback" v-text="position?.error_message" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="section">
          <h5>{{ $t('daily_allowance.half_day') }}</h5>
          <div class="js-addcontrol1--wrap">
            <div
              v-for="(item, index) in half_days"
              :key="index"
              class="row addcontrol--item scroll-margin-top"
              :id="`half_days_element_${index}`"
            >
              <div class="col-sm-3">
                <div class="form-group">
                  <input
                    type="text"
                    class="adjust-amount form-control input-sm"
                    :class="!checkEmpty(item?.error_message?.daily_allowance_type) && 'has-validation-error'"
                    :placeholder="$t('daily_allowance.daily_allowance_type_name')"
                    v-model="item.daily_allowance_type"
                  />
                  <p class="error-feedback" v-text="item?.error_message?.daily_allowance_type" />
                </div>
              </div>
              <div class="col-sm-5">
                <div class="form-group">
                  <SingleOptionSelect
                    :placeholder="$t('common.please_select')"
                    :list-data="listZone"
                    :class="!checkEmpty(item?.error_message?.zone_id) && 'has-validation-error-el'"
                    @handle-selected-params="selectZoneHalfDay"
                    :is-display-search="false"
                    :display-by-locale="true"
                    :option-index="{ haveIndex: true, index }"
                    :default-list="item.zone_id"
                  />
                  <p class="error-feedback" v-text="item?.error_message?.zone_id" />
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group text-right">
                  <label
                    class="checkbox checkbox-inline checkbox-type-div checkbox-expense"
                    :class="item?.is_depend_position && 'checked'"
                  >
                    <span class="icons">
                      <span class="first-icon icon-abui-checkbox-unchecked" />
                      <span class="second-icon icon-abui-checkbox-checked" />
                    </span>
                    <input type="checkbox" v-model="item.is_depend_position" />
                    <span class="text_type" style="margin-left: 32px">
                      {{ $t('daily_allowance.specified_by_job_title') }}
                    </span>
                  </label>
                </div>
              </div>
              <div v-show="!item.is_depend_position" class="js-check_jobtitile__tgt--off scroll-margin-top">
                <div class="col-sm-2">
                  <div class="form-group">
                    <div class="input-group adjust-amount">
                      <input
                        type="text"
                        v-model="item.amount"
                        autocomplete="off"
                        class="adjust-amount form-control input-sm text-right"
                        :class="!checkEmpty(item?.error_message?.amount) && 'has-validation-error'"
                        onkeydown="javascript: return event.keyCode !== 69;"
                        @input="item.amount = convertNumberFullToHalf(item.amount)"
                        maxlength="11"
                        @change="convertDataCommon('half_days.amount', true, index, true)"
                      />
                      <span class="input-group-addon">{{ $t('daily_allowance.circle') }}</span>
                    </div>
                    <p class="error-feedback" v-text="item?.error_message?.amount" />
                  </div>
                </div>
              </div>
              <div v-show="item.is_depend_position" class="js-check_jobtitile__tgt--on">
                <div class="col-sm-2">
                  <div
                    v-for="(position, positionIndex) in item.position_amounts"
                    :key="positionIndex"
                    class="regulation-jobtitle scroll-margin-top"
                    style="display: block !important"
                    :id="`half_days_position_${positionIndex}`"
                  >
                    <div style="display: flex; align-items: center">
                      <div class="form-inline-text regulation-jobtitle--label">
                        {{ getPositionName(position.position_id) }}
                      </div>
                      <div class="row">
                        <div class="col-sm-12">
                          <div class="form-group">
                            <div class="input-group adjust-amount">
                              <input
                                type="text"
                                v-model="position.amount"
                                autocomplete="off"
                                class="adjust-amount form-control input-sm text-right"
                                :class="!checkEmpty(position?.error_message) && 'has-validation-error'"
                                onkeydown="javascript: return event.keyCode !== 69;"
                                maxlength="11"
                                @input="position.amount = convertNumberFullToHalf(position.amount)"
                                @change="
                                  convertNestedDataAmount('half_days.position_amounts.amount', index, positionIndex)
                                "
                              />
                              <span class="input-group-addon">{{ $t('daily_allowance.circle') }}</span>
                            </div>
                            <p class="error-feedback" v-text="position?.error_message" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button
                @click="deleteItem(1, item)"
                class="btn btn-sm btn-flat-danger js-addcontrol--del addcontrol--item-del"
              >
                <span class="icon-abui-cross" />
              </button>
            </div>
            <div class="row--addcontrol">
              <button class="btn btn-sm btn-flat-primary js-btn-addcontrol-halfday" @click="addNewItem(1)">
                <span class="icon-abui-plus prx" />{{ $t('daily_allowance.add_daily_allowance_type') }}
              </button>
            </div>
          </div>
          <hr class="mtl mbl" />
          <div class="" style="margin-top: 24px">
            <div class="row addcontrol--item scroll-margin-top" id="half_day_common_element">
              <div class="col-sm-8">
                <div class="form-group">{{ $t('daily_allowance.common_destination_other_than_above') }}</div>
              </div>
              <div class="col-sm-2">
                <div class="form-group text-right">
                  <label
                    class="checkbox checkbox-inline checkbox-type-div checkbox-expense"
                    :class="half_day_common?.is_depend_position && 'checked'"
                  >
                    <span class="icons">
                      <span class="first-icon icon-abui-checkbox-unchecked" />
                      <span class="second-icon icon-abui-checkbox-checked" />
                    </span>
                    <input type="checkbox" v-model="half_day_common.is_depend_position" />
                    <span class="text_type" style="margin-left: 32px">
                      {{ $t('daily_allowance.specified_by_job_title') }}
                    </span>
                  </label>
                </div>
              </div>
              <div v-show="!half_day_common.is_depend_position" class="js-check_jobtitile__tgt--off scroll-margin-top">
                <div class="col-sm-2">
                  <div class="form-group">
                    <div class="input-group adjust-amount">
                      <input
                        type="text"
                        v-model="half_day_common.amount"
                        autocomplete="off"
                        class="adjust-amount form-control input-sm text-right"
                        :class="!checkEmpty(half_day_common?.error_message) && 'has-validation-error'"
                        onkeydown="javascript: return event.keyCode !== 69;"
                        @input="half_day_common.amount = convertNumberFullToHalf(half_day_common.amount)"
                        maxlength="11"
                        @change="convertDataCommon('half_day_common.amount', false, -1, true)"
                      />
                      <span class="input-group-addon">{{ $t('daily_allowance.circle') }}</span>
                    </div>
                    <p class="error-feedback" v-text="half_day_common?.error_message" />
                  </div>
                </div>
              </div>
              <div v-show="half_day_common.is_depend_position" class="js-check_jobtitile__tgt--on">
                <div class="col-sm-2">
                  <div
                    v-for="(position, positionIndex) in half_day_common.position_amounts"
                    :key="positionIndex"
                    class="regulation-jobtitle scroll-margin-top"
                    :id="`half_days_common_position_${positionIndex}`"
                  >
                    <div class="form-inline-text regulation-jobtitle--label">
                      {{ getPositionName(position.position_id) }}
                    </div>
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="form-group">
                          <div class="input-group adjust-amount">
                            <input
                              type="text"
                              v-model="position.amount"
                              autocomplete="off"
                              class="adjust-amount form-control input-sm text-right"
                              :class="!checkEmpty(position?.error_message) && 'has-validation-error'"
                              onkeydown="javascript: return event.keyCode !== 69;"
                              @input="position.amount = convertNumberFullToHalf(position.amount)"
                              maxlength="11"
                              @change="convertDataCommon('half_day_common.position_amounts.amount', true, index, true)"
                            />
                            <span class="input-group-addon">{{ $t('daily_allowance.circle') }}</span>
                          </div>
                          <p class="error-feedback" v-text="position?.error_message" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="section">
          <h5>{{ $t('daily_allowance.accommodation_fee') }}</h5>
          <div class="js-addcontrol1--wrap">
            <div
              v-for="(item, index) in accommodation_fees"
              :key="index"
              class="row addcontrol--item scroll-margin-top"
              :id="`accommodation_fees_element_${index}`"
            >
              <div class="col-sm-4">
                <div class="form-group">
                  <SingleOptionSelect
                    :placeholder="$t('common.please_select')"
                    :list-data="listZone"
                    :class="!checkEmpty(item?.error_message?.zone_id) && 'has-validation-error-el'"
                    @handle-selected-params="selectZoneAccommodationFee"
                    :is-display-search="false"
                    :display-by-locale="true"
                    :option-index="{ haveIndex: true, index }"
                    :default-list="item.zone_id"
                  />
                  <p class="error-feedback" v-text="item?.error_message?.zone_id" />
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <input
                    type="text"
                    class="adjust-amount form-control input-sm"
                    :class="!checkEmpty(item?.error_message?.name_cities) && 'has-validation-error'"
                    :placeholder="$t('common.city')"
                    v-model="item.name_cities"
                  />
                  <p class="error-feedback" v-text="item?.error_message?.name_cities" />
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group text-right">
                  <label
                    class="checkbox checkbox-inline checkbox-type-div checkbox-expense"
                    :class="item?.is_depend_position && 'checked'"
                  >
                    <span class="icons">
                      <span class="first-icon icon-abui-checkbox-unchecked" />
                      <span class="second-icon icon-abui-checkbox-checked" />
                    </span>
                    <input type="checkbox" v-model="item.is_depend_position" />
                    <span class="text_type" style="margin-left: 32px">
                      {{ $t('daily_allowance.specified_by_job_title') }}
                    </span>
                  </label>
                </div>
              </div>
              <div v-show="!item.is_depend_position" class="js-check_jobtitile__tgt--off scroll-margin-top">
                <div class="col-sm-2">
                  <div class="form-group">
                    <div class="input-group adjust-amount">
                      <input
                        type="text"
                        v-model="item.amount"
                        autocomplete="off"
                        class="adjust-amount form-control input-sm text-right"
                        :class="!checkEmpty(item?.error_message?.amount) && 'has-validation-error'"
                        onkeydown="javascript: return event.keyCode !== 69;"
                        @input="item.amount = convertNumberFullToHalf(item.amount)"
                        @change="convertDataCommon('accommodation_fees.amount', true, index, true)"
                        maxlength="11"
                      />
                      <span class="input-group-addon">{{ $t('daily_allowance.circle') }}</span>
                    </div>
                    <p class="error-feedback" v-text="item?.error_message?.amount" />
                  </div>
                </div>
              </div>
              <div v-show="item.is_depend_position" class="js-check_jobtitile__tgt--on">
                <div class="col-sm-2">
                  <div
                    v-for="(position, positionIndex) in item.position_amounts"
                    :key="positionIndex"
                    class="regulation-jobtitle scroll-margin-top"
                    style="display: block !important"
                    :id="`accommodation_fees_position_${positionIndex}`"
                  >
                    <div style="display: flex; align-items: center">
                      <div class="form-inline-text regulation-jobtitle--label">
                        {{ getPositionName(position.position_id) }}
                      </div>
                      <div class="row">
                        <div class="col-sm-12">
                          <div class="form-group">
                            <div class="input-group adjust-amount">
                              <input
                                type="text"
                                v-model="position.amount"
                                autocomplete="off"
                                class="adjust-amount form-control input-sm text-right"
                                :class="!checkEmpty(position?.error_message) && 'has-validation-error'"
                                onkeydown="javascript: return event.keyCode !== 69;"
                                @input="position.amount = convertNumberFullToHalf(position.amount)"
                                maxlength="11"
                                @change="
                                  convertNestedDataAmount(
                                    'accommodation_fees.position_amounts.amount',
                                    index,
                                    positionIndex,
                                  )
                                "
                              />
                              <span class="input-group-addon">{{ $t('daily_allowance.circle') }}</span>
                            </div>
                          </div>
                          <p class="error-feedback" v-text="position?.error_message" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button
                @click="deleteItem(3, item)"
                class="btn btn-sm btn-flat-danger js-addcontrol--del addcontrol--item-del"
              >
                <span class="icon-abui-cross" />
              </button>
            </div>
            <div class="row--addcontrol">
              <button class="btn btn-sm btn-flat-primary js-btn-addcontrol-overnight" @click="addNewItem(3)">
                <span class="icon-abui-plus prx" />{{ $t('daily_allowance.add_city') }}
              </button>
            </div>
          </div>
          <hr class="mtl mbl" />
          <div class="" style="margin-top: 24px">
            <div class="row addcontrol--item" id="accommodation_fees_common_element">
              <div class="col-sm-8">
                <div class="form-group">{{ $t('daily_allowance.common_destination_other_than_above') }}</div>
              </div>
              <div class="col-sm-2">
                <div class="form-group text-right">
                  <label
                    class="checkbox checkbox-inline checkbox-type-div checkbox-expense"
                    :class="accommodation_fees_common?.is_depend_position && 'checked'"
                  >
                    <span class="icons">
                      <span class="first-icon icon-abui-checkbox-unchecked" />
                      <span class="second-icon icon-abui-checkbox-checked" />
                    </span>
                    <input type="checkbox" v-model="accommodation_fees_common.is_depend_position" />
                    <span class="text_type" style="margin-left: 32px">
                      {{ $t('daily_allowance.specified_by_job_title') }}
                    </span>
                  </label>
                </div>
              </div>
              <div
                v-show="!accommodation_fees_common.is_depend_position"
                class="js-check_jobtitile__tgt--off scroll-margin-top"
              >
                <div class="col-sm-2">
                  <div class="form-group">
                    <div class="input-group adjust-amount">
                      <input
                        type="text"
                        v-model="accommodation_fees_common.amount"
                        autocomplete="off"
                        class="adjust-amount form-control input-sm text-right"
                        :class="!checkEmpty(accommodation_fees_common?.error_message) && 'has-validation-error'"
                        onkeydown="javascript: return event.keyCode !== 69;"
                        @input="
                          accommodation_fees_common.amount = convertNumberFullToHalf(accommodation_fees_common.amount)
                        "
                        maxlength="11"
                        @change="convertDataCommon('accommodation_fees_common.amount', false, -1, true)"
                      />
                      <span class="input-group-addon">{{ $t('daily_allowance.circle') }}</span>
                    </div>
                    <p class="error-feedback" v-text="accommodation_fees_common?.error_message" />
                  </div>
                </div>
              </div>
              <div v-show="accommodation_fees_common.is_depend_position" class="js-check_jobtitile__tgt--on">
                <div class="col-sm-2">
                  <div
                    v-for="(position, positionIndex) in accommodation_fees_common.position_amounts"
                    :key="positionIndex"
                    class="regulation-jobtitle scroll-margin-top"
                    :id="`accommodation_fees_common_position_${positionIndex}`"
                  >
                    <div class="form-inline-text regulation-jobtitle--label">
                      {{ getPositionName(position.position_id) }}
                    </div>
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="form-group">
                          <div class="input-group adjust-amount">
                            <input
                              type="text"
                              v-model="position.amount"
                              autocomplete="off"
                              class="adjust-amount form-control input-sm text-right"
                              :class="!checkEmpty(position?.error_message) && 'has-validation-error'"
                              onkeydown="javascript: return event.keyCode !== 69;"
                              @input="position.amount = convertNumberFullToHalf(position.amount)"
                              maxlength="11"
                              @change="
                                convertDataCommon(
                                  'accommodation_fees_common.position_amounts.amount',
                                  true,
                                  index,
                                  true,
                                )
                              "
                            />
                            <span class="input-group-addon">{{ $t('daily_allowance.circle') }}</span>
                          </div>
                          <p class="error-feedback" v-text="position?.error_message" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="section">
          <div class="row" id="">
            <div class="col-sm-12">
              <div class="form-group">
                <label>
                  <span class="label-text">{{ $t('daily_allowance.document') }}</span>
                </label>
                <button
                  v-if="files.length == 0"
                  @click="showModalUploadFile = true"
                  type="button"
                  class="btn btn-inverse btn-sm"
                  style="margin-bottom: 4px"
                >
                  <i class="icon-abui-upload prx" />{{ $t('daily_allowance.upload') }}
                </button>
                <button
                  style="width: 100%; display: flex; justify-content: space-between; margin-bottom: 4px"
                  v-for="(item, index) in files"
                  :key="index"
                  type="button"
                  class="btn btn-inverse-hover-danger btn-sm js-btn--delete-voucher-general"
                >
                  <span class="thumbnail--voucher--filename">{{ item.name }}</span>
                  <i class="icon-abui-cross pls" @click="deleteFile(item)" />
                </button>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>
              <span class="label-text">{{ $t('daily_allowance.remarks') }}</span>
            </label>
            <textarea class="form-control" rows="6" placeholder="" v-model="note" />
          </div>
        </div>

        <div
          class="navbar navbar-default navbar-fixed-bottom navbar-footblock with-sidebar"
          role="navigation"
          style="z-index: 990; padding: 12px"
        >
          <div class="row">
            <div class="col-sm-4 col-md-3 col-sm-offset-8 col-md-offset-9">
              <a class="btn btn-sm btn-block btn-primary" @click="validate">{{ $t('daily_allowance.save') }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ModalConfirm
      :dialogVisible="showModalConfirm"
      @cancel="showModalConfirm = false"
      @yes="confirmDelete"
      :title="$t('Q-CM-104')"
    />

    <ModalUploadFile
      :dialogVisible="showModalUploadFile"
      :isSingleUpload="true"
      @cancel="showModalUploadFile = false"
      @confirm="confirmUploadFile"
      :title="$t('common.document_upload')"
    />
  </body>
</template>
<script>
import ModalConfirm from '@/components/common/ModalConfirm';
import ModalUploadFile from '@/components/common/ModalUploadFile';
import SingleOptionSelect from '@/components/common/SingleOptionSelect.vue';
import { DAILY_ALLOWANCE_TYPE, HALF_DAYS, ALL_DAYS, ACCOMMODATION_FEES } from '@/constants/';
import vClickOutside from 'click-outside-vue3';

export default {
  name: 'DailyAllowance',
  components: { ModalConfirm, ModalUploadFile, SingleOptionSelect },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      dailyAllowanceDetail: {},
      showModalConfirm: false,
      showModalUploadFile: false,
      note: '',
      half_days: [],
      all_days: [],
      accommodation_fees: [],
      half_day_common: {
        daily_allowance_type: null,
        is_depend_position: false,
        amount: null,
        position_amounts: [],
      },
      all_day_common: {
        daily_allowance_type: null,
        is_depend_position: false,
        amount: null,
        position_amounts: [],
      },
      accommodation_fees_common: {
        is_depend_position: false,
        amount: null,
        position_amounts: [],
      },
      files: [],
      note_official: '',
      half_days_official: [],
      all_days_official: [],
      accommodation_fees_official: [],
      half_day_common_official: {
        daily_allowance_type: null,
        is_depend_position: false,
        amount: null,
        position_amounts: [],
      },
      all_day_common_official: {
        daily_allowance_type: null,
        is_depend_position: false,
        amount: null,
        position_amounts: [],
      },
      accommodation_fees_common_official: {
        is_depend_position: false,
        amount: null,
        position_amounts: [],
      },
      files_official: [],
      listZone: [],
      listPosition: [],
      typeDelete: 0,
      indexDelete: {},
    };
  },
  beforeRouteLeave(to, from, next) {
    if (this.isDataChanged) {
      const answer = window.confirm(this.$t('common.confirm_leave_page'));
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  created() {
    this.startLoading();
    Promise.all([this.getDailyAllowance(), this.getListZone()]).then(() => {
      this.getListPosition();
      this.endLoading();
    });
  },
  watch: {
    dailyAllowanceDetail: {
      handler(newVal) {
        this.initData(newVal);
        this.setupData();
      },
      deep: true,
    },
  },
  computed: {
    isDataChanged() {
      return (
        !(this.note === this.note_official) ||
        !this.compareObject(this.half_day_common, this.half_day_common_official) ||
        !this.compareObject(this.all_day_common, this.all_day_common_official) ||
        !this.compareObject(this.accommodation_fees_common, this.accommodation_fees_common_official) ||
        !this.compareArray(this.half_days, this.half_days_official) ||
        !this.compareArray(this.all_days, this.all_days_official) ||
        !this.compareArray(this.accommodation_fees, this.accommodation_fees_official) ||
        !this.compareArray(this.files, this.files_official)
      );
    },
  },
  methods: {
    convertNumberFullToHalf(number) {
      number = number.replace(/[^0-9０-９]/g, '');
      number = String(number).replace(/[\uFF10-\uFF19]/g, function (m) {
        return String.fromCharCode(m.charCodeAt(0) - 0xfee0);
      });

      return number;
    },
    setupData() {
      this.half_days.forEach(item => {
        item.error_message = {
          daily_allowance_type: '',
          zone_id: '',
          amount: '',
        };
      });

      this.half_days_official.forEach(item => {
        item.error_message = {
          daily_allowance_type: '',
          zone_id: '',
          amount: '',
        };
      });

      this.all_days.forEach(item => {
        item.error_message = {
          daily_allowance_type: '',
          zone_id: '',
          amount: '',
        };
      });

      this.all_days_official.forEach(item => {
        item.error_message = {
          daily_allowance_type: '',
          zone_id: '',
          amount: '',
        };
      });

      this.accommodation_fees.forEach(item => {
        item.error_message = {
          daily_allowance_type: '',
          zone_id: '',
          amount: '',
        };
      });

      this.accommodation_fees_official.forEach(item => {
        item.error_message = {
          daily_allowance_type: '',
          zone_id: '',
          amount: '',
        };
      });
    },
    getListPosition() {
      this.$services.position.getListPosition(
        res => {
          this.listPosition = res.list;

          if (!this.checkEmpty(this.listPosition)) {
            this.listPosition.forEach(position => {
              this.half_days.forEach(item => {
                if (this.checkEmpty(item.position_amounts.find(pa => pa.position_id == position.id))) {
                  let ps = {
                    position_id: position.id,
                    title: position.management_title,
                    amount: '',
                  };
                  item.position_amounts.push(ps);
                }
              });

              this.half_days_official.forEach(item => {
                if (this.checkEmpty(item.position_amounts.find(pa => pa.position_id == position.id))) {
                  let ps = {
                    position_id: position.id,
                    title: position.management_title,
                    amount: '',
                  };
                  item.position_amounts.push(ps);
                }
              });

              this.all_days.forEach(item => {
                if (this.checkEmpty(item.position_amounts.find(pa => pa.position_id == position.id))) {
                  let ps = {
                    position_id: position.id,
                    title: position.management_title,
                    amount: '',
                  };
                  item.position_amounts.push(ps);
                }
              });

              this.all_days_official.forEach(item => {
                if (this.checkEmpty(item.position_amounts.find(pa => pa.position_id == position.id))) {
                  let ps = {
                    position_id: position.id,
                    title: position.management_title,
                    amount: '',
                  };
                  item.position_amounts.push(ps);
                }
              });

              this.accommodation_fees.forEach(item => {
                if (this.checkEmpty(item.position_amounts.find(pa => pa.position_id == position.id))) {
                  let ps = {
                    position_id: position.id,
                    title: position.management_title,
                    amount: '',
                  };
                  item.position_amounts.push(ps);
                }
              });

              this.accommodation_fees_official.forEach(item => {
                if (this.checkEmpty(item.position_amounts.find(pa => pa.position_id == position.id))) {
                  let ps = {
                    position_id: position.id,
                    title: position.management_title,
                    amount: '',
                  };
                  item.position_amounts.push(ps);
                }
              });

              if (this.checkEmpty(this.half_day_common.position_amounts.find(pa => pa.position_id == position.id))) {
                let ps = {
                  position_id: position.id,
                  title: position.management_title,
                  amount: '',
                };
                this.half_day_common.position_amounts.push(ps);
              }

              if (
                this.checkEmpty(
                  this.half_day_common_official.position_amounts.find(pa => pa.position_id == position.id),
                )
              ) {
                let ps = {
                  position_id: position.id,
                  title: position.management_title,
                  amount: '',
                };
                this.half_day_common_official.position_amounts.push(ps);
              }

              if (this.checkEmpty(this.all_day_common.position_amounts.find(pa => pa.position_id == position.id))) {
                let ps = {
                  position_id: position.id,
                  title: position.management_title,
                  amount: '',
                };
                this.all_day_common.position_amounts.push(ps);
              }

              if (
                this.checkEmpty(this.all_day_common_official.position_amounts.find(pa => pa.position_id == position.id))
              ) {
                let ps = {
                  position_id: position.id,
                  title: position.management_title,
                  amount: '',
                };
                this.all_day_common_official.position_amounts.push(ps);
              }

              if (
                this.checkEmpty(
                  this.accommodation_fees_common.position_amounts.find(pa => pa.position_id == position.id),
                )
              ) {
                let ps = {
                  position_id: position.id,
                  title: position.management_title,
                  amount: '',
                };
                this.accommodation_fees_common.position_amounts.push(ps);
              }

              if (
                this.checkEmpty(
                  this.accommodation_fees_common_official.position_amounts.find(pa => pa.position_id == position.id),
                )
              ) {
                let ps = {
                  position_id: position.id,
                  title: position.management_title,
                  amount: '',
                };
                this.accommodation_fees_common_official.position_amounts.push(ps);
              }
            });
          }
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    async getDailyAllowance() {
      await this.$services.zone.getDailyAllowance(
        res => {
          this.dailyAllowanceDetail = res;
        },
        err => {
          if (err.code != 400) {
            this.notifyError(this.$t(err.error));
          }
        },
      );
    },
    //Convert Data Amount to String have ',' , use for data have 2 index inside
    convertNestedDataAmount(dataName, parentIndex, positionIndex) {
      let data = '';
      //Number of child level object we need to access
      const childName = dataName.split('.');
      data = this.cloneObject(
        this[`${childName[0]}`][parentIndex][`${childName[1]}`][positionIndex][`${childName.pop()}`],
      );

      if (this.checkEmpty(data)) return;
      if (String(data).includes('.') || String(data).includes('．')) {
        if (String(data).includes('．')) {
          data = data.replaceAll('．', '.');
        }
        //Return a string contain ',' split
        const returnStr =
          data == 0
            ? 0
            : String(parseFloat(data).toFixed(2))
                .replace(/^(?!00[^0])0/, '')
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        this[`${childName[0]}`][parentIndex].position_amounts[positionIndex].amount = returnStr;
      } else {
        data = data.replaceAll(',', '');
        //Return a string contain ',' split
        const returnStr =
          data == 0
            ? 0
            : data
                .replace(/(\..*?)\..*/g, '$1')
                .replace(/^(?!00[^0])0/, '')
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        this[`${childName[0]}`][parentIndex].position_amounts[positionIndex].amount = returnStr;
      }
    },
    initData(data) {
      const res = this.cloneObject(data);
      this.note = res.note;
      //Half day daily allowances
      let halfDayData = res.half_days;
      if (!this.checkEmpty(halfDayData)) {
        halfDayData.forEach(item => {
          item.amount = this.splitThousandNumber(item.amount);
          if (!this.checkEmpty(item.position_amounts)) {
            item.position_amounts.forEach(position => (position.amount = this.splitThousandNumber(position.amount)));
          }
        });
      }
      this.half_days = halfDayData;
      //All day daily allowances
      let allDayData = res.all_days;
      if (!this.checkEmpty(allDayData)) {
        allDayData.forEach(item => {
          item.amount = this.splitThousandNumber(item.amount);
          if (!this.checkEmpty(item.position_amounts)) {
            item.position_amounts.forEach(position => (position.amount = this.splitThousandNumber(position.amount)));
          }
        });
      }
      this.all_days = allDayData;

      //Accommodation daily allowances
      let accommodationFees = res.accommodation_fees;
      if (!this.checkEmpty(accommodationFees)) {
        accommodationFees.forEach(item => {
          item.amount = this.splitThousandNumber(item.amount);
          if (!this.checkEmpty(item.position_amounts)) {
            item.position_amounts.forEach(position => (position.amount = this.splitThousandNumber(position.amount)));
          }
        });
      }
      this.accommodation_fees = accommodationFees;

      //Half day common
      let halfDayCommon = res.half_day_common;
      if (!this.checkEmpty(halfDayCommon)) {
        halfDayCommon.amount = this.splitThousandNumber(halfDayCommon.amount);
        if (!this.checkEmpty(halfDayCommon.position_amounts)) {
          halfDayCommon.position_amounts.forEach(
            position => (position.amount = this.splitThousandNumber(position.amount)),
          );
        }
      }
      this.half_day_common = halfDayCommon;
      //Daily all day common allowances
      let allDayDataCommon = res.all_day_common;
      if (!this.checkEmpty(allDayDataCommon)) {
        allDayDataCommon.amount = this.splitThousandNumber(allDayDataCommon.amount);
        if (!this.checkEmpty(allDayDataCommon.position_amounts)) {
          allDayDataCommon.position_amounts.forEach(
            position => (position.amount = this.splitThousandNumber(position.amount)),
          );
        }
      }
      this.all_day_common = allDayDataCommon;

      //Accommodation fees common
      let accommodationFeesCommon = res.accommodation_fees_common;
      if (!this.checkEmpty(accommodationFeesCommon)) {
        accommodationFeesCommon.amount = this.splitThousandNumber(accommodationFeesCommon.amount);
        if (!this.checkEmpty(accommodationFeesCommon.position_amounts)) {
          accommodationFeesCommon.position_amounts.forEach(
            position => (position.amount = this.splitThousandNumber(position.amount)),
          );
        }
      }
      this.accommodation_fees_common = res.accommodation_fees_common;

      //List files
      this.files = !this.checkEmpty(res.files) ? [res.files[res.files.length - 1]] : [];

      //Clone data for back browser
      let dataClone = this.cloneObject(res);
      this.note_official = dataClone.note;
      //Official half day daily allowances
      let halfDayDataOfficial = dataClone.half_days;
      if (!this.checkEmpty(halfDayDataOfficial)) {
        halfDayDataOfficial.forEach(item => {
          item.amount = this.splitThousandNumber(item.amount);
          if (!this.checkEmpty(item.position_amounts)) {
            item.position_amounts.forEach(position => (position.amount = this.splitThousandNumber(position.amount)));
          }
        });
      }
      this.half_days_official = halfDayDataOfficial;

      let allDayDataOfficial = dataClone.all_days;
      if (!this.checkEmpty(allDayDataOfficial)) {
        allDayDataOfficial.forEach(item => {
          item.amount = this.splitThousandNumber(item.amount);
          if (!this.checkEmpty(item.position_amounts)) {
            item.position_amounts.forEach(position => (position.amount = this.splitThousandNumber(position.amount)));
          }
        });
      }
      this.all_days_official = allDayDataOfficial;

      //Accommodation daily allowances
      let accommodationFeesOfficial = dataClone.accommodation_fees;
      if (!this.checkEmpty(accommodationFeesOfficial)) {
        accommodationFeesOfficial.forEach(item => {
          item.amount = this.splitThousandNumber(item.amount);
          if (!this.checkEmpty(item.position_amounts)) {
            item.position_amounts.forEach(position => (position.amount = this.splitThousandNumber(position.amount)));
          }
        });
      }
      this.accommodation_fees_official = accommodationFeesOfficial;

      //Official half day common
      let halfDayCommonOfficial = dataClone.half_day_common;
      if (!this.checkEmpty(halfDayCommonOfficial)) {
        halfDayCommonOfficial.amount = this.splitThousandNumber(halfDayCommonOfficial.amount);
        if (!this.checkEmpty(halfDayCommonOfficial.position_amounts)) {
          halfDayCommonOfficial.position_amounts.forEach(
            position => (position.amount = this.splitThousandNumber(position.amount)),
          );
        }
      }
      this.half_day_common_official = halfDayCommonOfficial;

      //Initial all day common daily allowances
      let allDayDataCommonOfficial = dataClone.all_day_common;
      if (!this.checkEmpty(allDayDataCommonOfficial)) {
        allDayDataCommonOfficial.amount = this.splitThousandNumber(allDayDataCommonOfficial.amount);
        if (!this.checkEmpty(allDayDataCommonOfficial.position_amounts)) {
          allDayDataCommonOfficial.position_amounts.forEach(
            position => (position.amount = this.splitThousandNumber(position.amount)),
          );
        }
      }
      this.all_day_common_official = allDayDataCommonOfficial;

      //Accommodation fees common (initial)
      let accommodationFeesCommonOfficial = dataClone.accommodation_fees_common;
      if (!this.checkEmpty(accommodationFeesCommonOfficial)) {
        accommodationFeesCommonOfficial.amount = this.splitThousandNumber(accommodationFeesCommonOfficial.amount);
        if (!this.checkEmpty(accommodationFeesCommonOfficial.position_amounts)) {
          accommodationFeesCommonOfficial.position_amounts.forEach(
            position => (position.amount = this.splitThousandNumber(position.amount)),
          );
        }
      }
      this.accommodation_fees_common_official = accommodationFeesCommonOfficial;
      this.files_official = !this.checkEmpty(dataClone.files) ? [dataClone.files[dataClone.files.length - 1]] : [];
    },
    async getListZone() {
      await this.$services.position.getListZone(
        res => {
          this.listZone = res.list;
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    validate() {
      let flgValidate = true;
      let errorElementId = '';

      // validate all_days
      this.all_days.forEach((all_day, index) => {
        // check daily_allowance_type
        if (this.checkEmpty(all_day.daily_allowance_type)) {
          flgValidate = false;
          all_day.error_message.daily_allowance_type = this.$t('E-CM-004');
          //Auto scroll ID
          if (this.checkEmpty(errorElementId)) errorElementId = `all_days_element_${index}`;
        } else if (this.strLen(all_day.daily_allowance_type) > 50) {
          flgValidate = false;
          all_day.error_message.daily_allowance_type = this.$t('E-CM-111', { size: 50 });
          //Auto scroll ID
          if (this.checkEmpty(errorElementId)) errorElementId = `all_days_element_${index}`;
        } else {
          all_day.error_message.daily_allowance_type = '';
        }

        // check zone_id
        if (this.checkEmpty(all_day.zone_id)) {
          flgValidate = false;
          all_day.error_message.zone_id = this.$t('E-CM-004');
          //Auto scroll ID
          if (this.checkEmpty(errorElementId)) errorElementId = `all_days_element_${index}`;
        } else {
          all_day.error_message.zone_id = '';
        }

        if (!all_day.is_depend_position) {
          // check amount
          if (this.checkEmptyWithOutZero(all_day.amount)) {
            flgValidate = false;
            all_day.error_message.amount = this.$t('E-CM-004');
            //Auto scroll ID
            if (this.checkEmpty(errorElementId)) errorElementId = `all_days_element_${index}`;
          } else {
            const allDayAmount = this.convertToInteger(all_day.amount);
            if (!this.isNumeric(allDayAmount)) {
              flgValidate = false;
              all_day.error_message.amount = this.$t('daily_allowance.please_input_number');
              //Auto scroll ID
              if (this.checkEmpty(errorElementId)) errorElementId = `all_days_element_${index}`;
            } else if (this.strLen(String(allDayAmount)) > 11) {
              flgValidate = false;
              all_day.error_message.amount = this.$t('E-CM-111', { size: 11 });
              //Auto scroll ID
              if (this.checkEmpty(errorElementId)) errorElementId = `all_days_element_${index}`;
            } else {
              all_day.error_message.amount = '';
            }
          }
        } else {
          // check position_amounts
          all_day.position_amounts.forEach((position, positionIndex) => {
            const positionAmount = this.convertToInteger(position.amount);
            if (this.checkEmptyWithOutZero(positionAmount)) {
              flgValidate = false;
              position.error_message = this.$t('E-CM-004');
              //Auto scroll ID
              if (this.checkEmpty(errorElementId)) errorElementId = `all_days_position_element_${positionIndex}`;
            } else {
              if (!this.isNumeric(positionAmount)) {
                flgValidate = false;
                position.error_message = this.$t('daily_allowance.please_input_number');
                //Auto scroll ID
                if (this.checkEmpty(errorElementId)) errorElementId = `all_days_position_element_${positionIndex}`;
              } else if (this.strLen(String(positionAmount)) > 11) {
                flgValidate = false;
                position.error_message = this.$t('E-CM-111', { size: 11 });
                //Auto scroll ID
                if (this.checkEmpty(errorElementId)) errorElementId = `all_days_position_element_${positionIndex}`;
              } else {
                position.error_message = '';
              }
            }
          });
        }
      });

      if (!this.all_day_common.is_depend_position) {
        // check amount
        const allDayCommonAmount = this.convertToInteger(this.all_day_common.amount);
        if (this.checkEmptyWithOutZero(allDayCommonAmount)) {
          flgValidate = false;
          this.all_day_common.error_message = this.$t('E-CM-004');
        } else {
          if (!this.isNumeric(allDayCommonAmount)) {
            flgValidate = false;
            this.all_day_common.error_message = this.$t('daily_allowance.please_input_number');
            //Auto scroll ID
            if (this.checkEmpty(errorElementId)) errorElementId = `all_day_common_element`;
          } else if (this.strLen(String(allDayCommonAmount)) > 11) {
            flgValidate = false;
            this.all_day_common.error_message = this.$t('E-CM-111', { size: 11 });
            //Auto scroll ID
            if (this.checkEmpty(errorElementId)) errorElementId = `all_day_common_element`;
          } else {
            this.all_day_common.error_message = '';
          }
        }
      } else {
        // check position_amounts
        this.all_day_common.position_amounts.forEach((position, positionIndex) => {
          const positionAmount = this.convertToInteger(position.amount);
          if (this.checkEmptyWithOutZero(positionAmount)) {
            flgValidate = false;
            position.error_message = this.$t('E-CM-004');
            //Auto scroll ID
            if (this.checkEmpty(errorElementId)) errorElementId = `all_day_common_position_element_${positionIndex}`;
          } else {
            if (!this.isNumeric(positionAmount)) {
              flgValidate = false;
              position.error_message = this.$t('daily_allowance.please_input_number');
              //Auto scroll ID
              if (this.checkEmpty(errorElementId)) errorElementId = `all_day_common_position_element_${positionIndex}`;
            } else if (this.strLen(String(positionAmount)) > 11) {
              flgValidate = false;
              position.error_message = this.$t('E-CM-111', { size: 11 });
              //Auto scroll ID
              if (this.checkEmpty(errorElementId)) errorElementId = `all_day_common_position_element_${positionIndex}`;
            } else {
              position.error_message = '';
            }
          }
        });
      }

      // validate half_days
      this.half_days.forEach((half_day, index) => {
        // check daily_allowance_type
        if (this.checkEmpty(half_day.daily_allowance_type)) {
          flgValidate = false;
          half_day.error_message.daily_allowance_type = this.$t('E-CM-004');
          //Auto scroll ID
          if (this.checkEmpty(errorElementId)) errorElementId = `half_days_element_${index}`;
        } else if (this.strLen(half_day.daily_allowance_type) > 50) {
          flgValidate = false;
          half_day.error_message.daily_allowance_type = this.$t('E-CM-111', { size: 50 });
          //Auto scroll ID
          if (this.checkEmpty(errorElementId)) errorElementId = `half_days_element_${index}`;
        } else {
          half_day.error_message.daily_allowance_type = '';
        }

        // check zone_id
        if (this.checkEmpty(half_day.zone_id)) {
          flgValidate = false;
          //Auto scroll ID
          if (this.checkEmpty(errorElementId)) errorElementId = `half_days_element_${index}`;
          half_day.error_message.zone_id = this.$t('E-CM-004');
        } else {
          half_day.error_message.zone_id = '';
        }

        if (!half_day.is_depend_position) {
          // check amount
          const halfDayAmount = this.convertToInteger(half_day.amount);
          if (this.checkEmptyWithOutZero(halfDayAmount)) {
            flgValidate = false;
            half_day.error_message.amount = this.$t('E-CM-004');
            //Auto scroll ID
            if (this.checkEmpty(errorElementId)) errorElementId = `half_days_element_${index}`;
          } else {
            if (!this.isNumeric(halfDayAmount)) {
              flgValidate = false;
              half_day.error_message.amount = this.$t('daily_allowance.please_input_number');
              //Auto scroll ID
              if (this.checkEmpty(errorElementId)) errorElementId = `half_days_element_${index}`;
            } else if (this.strLen(String(halfDayAmount)) > 11) {
              flgValidate = false;
              half_day.error_message.amount = this.$t('E-CM-111', { size: 11 });
              //Auto scroll ID
              if (this.checkEmpty(errorElementId)) errorElementId = `half_days_element_${index}`;
            } else {
              half_day.error_message.amount = '';
            }
          }
        } else {
          // check position_amounts
          half_day.position_amounts.forEach((position, index) => {
            // check amount
            const positionAmount = this.convertToInteger(position.amount);
            if (this.checkEmptyWithOutZero(positionAmount)) {
              flgValidate = false;
              position.error_message = this.$t('E-CM-004');
              //Auto scroll ID
              if (this.checkEmpty(errorElementId)) errorElementId = `half_days_position_${index}`;
            } else {
              if (!this.isNumeric(positionAmount)) {
                flgValidate = false;
                position.error_message = this.$t('daily_allowance.please_input_number');
                //Auto scroll ID
                if (this.checkEmpty(errorElementId)) errorElementId = `half_days_position_${index}`;
              } else if (this.strLen(String(positionAmount)) > 11) {
                flgValidate = false;
                position.error_message = this.$t('E-CM-111', { size: 11 });
                //Auto scroll ID
                if (this.checkEmpty(errorElementId)) errorElementId = `half_days_position_${index}`;
              } else {
                position.error_message = '';
              }
            }
          });
        }
      });

      if (!this.half_day_common.is_depend_position) {
        // check amount
        const halfDayCommonAmount = this.convertToInteger(this.half_day_common.amount);
        if (this.checkEmptyWithOutZero(halfDayCommonAmount)) {
          flgValidate = false;
          this.half_day_common.error_message = this.$t('E-CM-004');
          //Auto scroll ID
          if (this.checkEmpty(errorElementId)) errorElementId = `half_days_common_element`;
        } else {
          if (!this.isNumeric(halfDayCommonAmount)) {
            flgValidate = false;
            this.half_day_common.error_message = this.$t('daily_allowance.please_input_number');
            //Auto scroll ID
            if (this.checkEmpty(errorElementId)) errorElementId = `half_days_common_element`;
          } else if (this.strLen(String(halfDayCommonAmount)) > 11) {
            flgValidate = false;
            this.half_day_common.error_message = this.$t('E-CM-111', { size: 11 });
            //Auto scroll ID
            if (this.checkEmpty(errorElementId)) errorElementId = `half_days_common_element`;
          } else {
            this.half_day_common.error_message = '';
          }
        }
      } else {
        // check position_amounts
        this.half_day_common.position_amounts.forEach((position, index) => {
          const positionAmount = this.convertToInteger(position.amount);
          if (this.checkEmptyWithOutZero(positionAmount)) {
            flgValidate = false;
            position.error_message = this.$t('E-CM-004');
            //Auto scroll ID
            if (this.checkEmpty(errorElementId)) errorElementId = `half_days_common_position_${index}`;
          } else {
            if (!this.isNumeric(positionAmount)) {
              flgValidate = false;
              position.error_message = this.$t('daily_allowance.please_input_number');
              //Auto scroll ID
              if (this.checkEmpty(errorElementId)) errorElementId = `half_days_common_position_${index}`;
            } else if (this.strLen(String(positionAmount)) > 11) {
              flgValidate = false;
              position.error_message = this.$t('E-CM-111', { size: 11 });
              //Auto scroll ID
              if (this.checkEmpty(errorElementId)) errorElementId = `half_days_common_position_${index}`;
            } else {
              position.error_message = '';
            }
          }
        });
      }

      // validate accommodation_fees
      this.accommodation_fees.forEach((acc_fee, index) => {
        // check name_cities
        if (this.checkEmpty(acc_fee.name_cities)) {
          flgValidate = false;
          acc_fee.error_message.name_cities = this.$t('E-CM-004');
          //Auto scroll ID
          if (this.checkEmpty(errorElementId)) errorElementId = `accommodation_fees_element_${index}`;
        } else if (this.strLen(acc_fee.name_cities) > 50) {
          flgValidate = false;
          acc_fee.error_message.name_cities = this.$t('E-CM-111', { size: 50 });
          //Auto scroll ID
          if (this.checkEmpty(errorElementId)) errorElementId = `accommodation_fees_element_${index}`;
        } else {
          acc_fee.error_message.name_cities = '';
        }

        // check zone_id
        if (this.checkEmpty(acc_fee.zone_id)) {
          flgValidate = false;
          acc_fee.error_message.zone_id = this.$t('E-CM-004');
          //Auto scroll ID
          if (this.checkEmpty(errorElementId)) errorElementId = `accommodation_fees_element_${index}`;
        } else {
          acc_fee.error_message.zone_id = '';
        }

        if (!acc_fee.is_depend_position) {
          // check amount
          const accommodationFeeAmount = this.convertToInteger(acc_fee.amount);
          if (this.checkEmptyWithOutZero(accommodationFeeAmount)) {
            flgValidate = false;
            acc_fee.error_message.amount = this.$t('E-CM-004');
            //Auto scroll ID
            if (this.checkEmpty(errorElementId)) errorElementId = `accommodation_fees_element_${index}`;
          } else {
            if (!this.isNumeric(accommodationFeeAmount)) {
              flgValidate = false;
              acc_fee.error_message.amount = this.$t('daily_allowance.please_input_number');
              //Auto scroll ID
              if (this.checkEmpty(errorElementId)) errorElementId = `accommodation_fees_element_${index}`;
            } else if (this.strLen(String(accommodationFeeAmount)) > 11) {
              flgValidate = false;
              acc_fee.error_message.amount = this.$t('E-CM-111', { size: 11 });
              //Auto scroll ID
              if (this.checkEmpty(errorElementId)) errorElementId = `accommodation_fees_element_${index}`;
            } else {
              acc_fee.error_message.amount = '';
            }
          }
        } else {
          // check position_amounts
          acc_fee.position_amounts.forEach((position, positionIndex) => {
            const positionAmount = this.convertToInteger(position.amount);
            if (this.checkEmptyWithOutZero(positionAmount)) {
              flgValidate = false;
              position.error_message = this.$t('E-CM-004');
              //Auto scroll ID
              if (this.checkEmpty(errorElementId)) errorElementId = `accommodation_fees_position_${positionIndex}`;
            } else {
              if (!this.isNumeric(positionAmount)) {
                flgValidate = false;
                position.error_message = this.$t('daily_allowance.please_input_number');
                //Auto scroll ID
                if (this.checkEmpty(errorElementId)) errorElementId = `accommodation_fees_position_${positionIndex}`;
              } else if (this.strLen(String(positionAmount)) > 11) {
                flgValidate = false;
                position.error_message = this.$t('E-CM-111', { size: 11 });
                //Auto scroll ID
                if (this.checkEmpty(errorElementId)) errorElementId = `accommodation_fees_position_${positionIndex}`;
              } else {
                position.error_message = '';
              }
            }
          });
        }
      });

      if (!this.accommodation_fees_common.is_depend_position) {
        // check amount
        const acc_fees_amount = this.convertToInteger(this.accommodation_fees_common.amount);
        if (this.checkEmptyWithOutZero(acc_fees_amount)) {
          flgValidate = false;
          this.accommodation_fees_common.error_message = this.$t('E-CM-004');
          //Auto scroll ID
          if (this.checkEmpty(errorElementId)) errorElementId = `accommodation_fees_common_element`;
        } else {
          if (!this.isNumeric(acc_fees_amount)) {
            flgValidate = false;
            this.accommodation_fees_common.error_message = this.$t('daily_allowance.please_input_number');
            //Auto scroll ID
            if (this.checkEmpty(errorElementId)) errorElementId = `accommodation_fees_common_element`;
          } else if (this.strLen(String(acc_fees_amount)) > 11) {
            flgValidate = false;
            this.accommodation_fees_common.error_message = this.$t('E-CM-111', { size: 11 });
            //Auto scroll ID
            if (this.checkEmpty(errorElementId)) errorElementId = `accommodation_fees_common_element`;
          } else {
            this.accommodation_fees_common.error_message = '';
          }
        }
      } else {
        // check position_amounts
        this.accommodation_fees_common.position_amounts.forEach((position, positionIndex) => {
          if (this.checkEmptyWithOutZero(position.amount)) {
            flgValidate = false;
            position.error_message = this.$t('E-CM-004');
            //Auto scroll ID
            if (this.checkEmpty(errorElementId)) errorElementId = `accommodation_fees_common_position_${positionIndex}`;
          } else {
            const positionAmount = this.convertToInteger(position.amount);
            if (!this.isNumeric(positionAmount)) {
              flgValidate = false;
              position.error_message = this.$t('daily_allowance.please_input_number');
              //Auto scroll ID
              if (this.checkEmpty(errorElementId))
                errorElementId = `accommodation_fees_common_position_${positionIndex}`;
            } else if (this.strLen(String(positionAmount)) > 11) {
              flgValidate = false;
              position.error_message = this.$t('E-CM-111', { size: 11 });
              //Auto scroll ID
              if (this.checkEmpty(errorElementId))
                errorElementId = `accommodation_fees_common_position_${positionIndex}`;
            } else {
              position.error_message = '';
            }
          }
        });
      }

      if (!flgValidate) {
        this.scrollIntoDivCustom(errorElementId);
        return;
      }

      this.accommodation_fees.forEach(data => {
        data.amount = this.convertToInteger(data.amount);
        delete data.error_message;
        data.position_amounts.forEach(position => {
          position.amount = this.convertToInteger(position.amount);
          delete position.error_message;
          delete position.management_title;
        });
      });

      this.all_days.forEach(data => {
        delete data.error_message;
        data.amount = this.convertToInteger(data.amount);
        data.position_amounts.forEach(position => {
          position.amount = this.convertToInteger(position.amount);
          delete position.error_message;
          delete position.management_title;
        });
      });

      this.half_days.forEach(data => {
        delete data.error_message;
        data.amount = this.convertToInteger(data.amount);
        data.position_amounts.forEach(position => {
          position.amount = this.convertToInteger(position.amount);
          delete position.error_message;
          delete position.management_title;
        });
      });

      delete this.half_day_common.error_message;
      this.half_day_common.amount = this.convertToInteger(this.half_day_common.amount);
      this.half_day_common.position_amounts.forEach(position => {
        position.amount = this.convertToInteger(position.amount);
        delete position.error_message;
        delete position.management_title;
      });

      delete this.all_day_common.error_message;
      this.all_day_common.amount = this.convertToInteger(this.all_day_common.amount);
      this.all_day_common.position_amounts.forEach(position => {
        position.amount = this.convertToInteger(position.amount);
        delete position.error_message;
        delete position.management_title;
      });

      delete this.accommodation_fees_common.error_message;
      this.accommodation_fees_common.amount = this.convertToInteger(this.accommodation_fees_common.amount);
      this.accommodation_fees_common.position_amounts.forEach(position => {
        position.amount = this.convertToInteger(position.amount);
        delete position.error_message;
        delete position.management_title;
      });

      const dailyAllowanceValues = {
        id: this.dailyAllowanceDetail.id,
        note: this.note,
        half_days: this.half_days,
        all_days: this.all_days,
        accommodation_fees: this.accommodation_fees,
        half_day_common: this.half_day_common,
        all_day_common: this.all_day_common,
        accommodation_fees_common: this.accommodation_fees_common,
      };

      this.saveDailyAllowance(dailyAllowanceValues);
    },
    saveDailyAllowance(dailyAllowanceValues = []) {
      if (this.checkEmpty(dailyAllowanceValues)) return;
      this.startLoading();
      this.$services.zone.saveDailyAllowance(
        dailyAllowanceValues,
        res => {
          this.getDailyAllowance();
          if (this.checkEmpty(this.dailyAllowanceDetail.id)) {
            this.dailyAllowanceDetail.id = res.id;
          }
          this.saveDailyAllowanceFiles();
        },
        err => {
          this.notifyError(this.$t(err.error));
          this.endLoading();
          this.setupData();
        },
      );
    },
    saveDailyAllowanceFiles() {
      let formData = new FormData();
      formData.append('daily_allowance_id', this.dailyAllowanceDetail.id);

      if (this.checkEmpty(this.files)) {
        formData.append('input_ids[]', 0);
      } else {
        for (let index = 0; index < this.files.length; index++) {
          if (this.checkEmpty(this.files[index].id)) {
            formData.append('files[]', this.files[index]);
          } else {
            formData.append('input_ids[]', this.files[index].id);
          }
        }
      }

      this.$services.zone.saveDailyAllowanceFiles(
        formData,
        () => {
          this.endLoading();
          this.notifySuccess(this.$t('I-CM-103'));
          this.getDailyAllowance();
        },
        err => {
          this.notifyError(this.$t(err.error));
          this.endLoading();
          this.getDailyAllowance();
        },
      );
    },
    confirmUploadFile(listFiles) {
      if (!this.checkEmpty(listFiles.length)) {
        listFiles.forEach(file => {
          let fileExits = this.files.find(item => item.name == file.name);
          if (this.checkEmpty(fileExits)) {
            this.files.push(file);
          } else {
            this.notifyError(this.$t('common.invalid_file'));
          }
        });
      }
      this.showModalUploadFile = false;
    },
    deleteFile(item) {
      if (!this.checkEmpty(item.name)) {
        this.files = this.files.filter(file => file.name != item.name);
      } else {
        this.files = this.files.filter(file => file.file_name != item.file_name);
      }
    },
    addNewItem(type) {
      let item = {
        daily_allowance_type: '',
        is_depend_position: false,
        zone_id: null,
        amount: null,
        position_amounts: [],
        error_message: {
          daily_allowance_type: '',
          zone_id: '',
          amount: null,
        },
      };
      this.listPosition.forEach(position => {
        let ps = {
          position_id: position.id,
          title: position.management_title,
          amount: null,
        };
        item.position_amounts.push(ps);
      });
      switch (type) {
        case HALF_DAYS:
          this.half_days.push(item);
          break;
        case ALL_DAYS:
          this.all_days.push(item);
          break;
        case ACCOMMODATION_FEES:
          this.accommodation_fees.push(item);
          break;
      }
    },
    deleteItem(type, item) {
      this.typeDelete = type;
      this.indexDelete = item;
      this.showModalConfirm = true;
    },
    confirmDelete() {
      switch (this.typeDelete) {
        case HALF_DAYS:
          this.half_days.splice(this.half_days.indexOf(this.indexDelete), 1);
          this.showModalConfirm = false;
          break;
        case ALL_DAYS:
          this.all_days.splice(this.all_days.indexOf(this.indexDelete), 1);
          this.showModalConfirm = false;
          break;
        case ACCOMMODATION_FEES:
          this.accommodation_fees.splice(this.accommodation_fees.indexOf(this.indexDelete), 1);
          this.showModalConfirm = false;
          break;
      }
    },
    getNameDailyAllowance(item) {
      let daily = DAILY_ALLOWANCE_TYPE.find(da => item.type_id == da.id);
      return this.labelLocale(daily);
    },
    selectZoneHalfDay(zoneId, index) {
      this.half_days[index].zone_id = zoneId;
    },
    selectZoneAllDay(zoneId, index) {
      this.all_days[index].zone_id = zoneId;
    },
    selectZoneAccommodationFee(zoneId, index) {
      this.accommodation_fees[index].zone_id = zoneId;
    },
    getPositionName(positionId) {
      if (!this.checkEmpty(positionId)) {
        let position = this.listPosition.find(ps => ps.id == positionId);
        return !this.checkEmpty(position) ? position.management_title : '';
      }
    },
  },
};
</script>
<style scoped>
.border-error {
  border: 1px solid red;
}
.container-fluid {
  margin-bottom: 62px !important;
}
.custom-dropdown-select {
  max-width: 414.75px;
}
</style>
<style>
.text-right input {
  text-align: right !important;
}
.regulation-jobtitle--label {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-right: 0px !important;
}
</style>
