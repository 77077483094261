<template>
  <nav class="navbar navbar-default navbar-lg" role="navigation" id="header-content">
    <div class="navbar-header">
      <h2 class="navbar-title">{{ $t('sidebar.expense') }}</h2>
    </div>
    <div class="collapse navbar-collapse">
      <ul class="nav navbar-nav navbar-right">
        <li v-if="!isOperatorOrAdmin">
          <a href="#" @click="downloadFileCSV">
            <span class="icon-abui-download prx"></span>{{ $t('expense.download_in_csv') }}
          </a>
        </li>
        <li v-if="!isOperatorOrAdmin" class="dropdown hidden-sm">
          <a
              href="#"
              class="dropdown-toggle"
              @click="isShowDropdown = !isShowDropdown"
              v-click-outside="
              () => {
                isShowDropdown = false;
              }
            "
          >
            <span class="icon-abui-plus"></span>
            {{ $t('expense.add_new') }}
            <b class="caret"></b>
          </a>
          <span class="dropdown-arrow"></span>
          <ul class="dropdown-menu" :class="isShowDropdown && 'showDropdown'" style="left: unset; right: 0">
            <li class="dropdown-header">
              <span class="text">{{ $t('expense.dropdown.note') }}</span>
            </li>
            <li v-if="!isOperatorOrAdmin">
              <a href="#" @click="showTransportation = true">
                {{ $t('expense.dropdown.option1') }}
              </a>
            </li>
            <li v-if="!isOperatorOrAdmin">
              <a href="#" @click="showCreateOtherCost()">
                {{ $t('expense.dropdown.option2') }}
              </a>
            </li>
            <li class="divider"></li>
            <li>
              <a href="#" @click="$router.push(PAGES.TRIP)"> {{ $t('expense.dropdown.option3') }}</a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </nav>

  <div class="filter-wrap">
    <div class="filter js-filter--minimization" v-show="showBoxFilter">
      <div class="section section-filter noborder js-section-filter">
        <div class="row">
          <div
              class="col-sm-6 col-md-3 col-all-closer col-all-closer--leftend col-sm-closer--rightend col-xs-closer--rightend"
          >
            <div class="form-group">
              <label>
                <span class="label-text">{{ $t('expense.departure_date') }}</span>
              </label>
              <div class="input-group">
                <div class="input-with-icon">
                  <el-date-picker
                      class="date-input"
                      v-model="searchParams.date_from"
                      type="date"
                      format="YYYY/MM/DD"
                      value-format="YYYY/MM/DD"
                      :placeholder="$t('common.enter_or_select')"
                      :clearable="false"
                      :disabled-date="pickerStartOptions"
                  />
                </div>
                <span class="input-group-addon prn">{{ $t('common.from') }}</span>
              </div>
            </div>
          </div>
          <div
              class="col-sm-6 col-md-3 col-all-closer col-all-closer--rightend col-sm-closer--leftend col-xs-closer--leftend"
          >
            <div class="form-group">
              <label class="hidden-xs"><span class="label-text">&nbsp;</span></label>
              <div class="input-group">
                <div class="input-with-icon">
                  <el-date-picker
                      class="date-input"
                      v-model="searchParams.date_to"
                      type="date"
                      format="YYYY/MM/DD"
                      value-format="YYYY/MM/DD"
                      :placeholder="$t('common.enter_or_select')"
                      :clearable="false"
                      :disabled-date="pickerEndOptions"
                  />
                </div>
                <span class="input-group-addon prn">{{ $t('common.to') }}</span>
              </div>
            </div>
          </div>
          <div
              class="col-sm-6 col-md-3 col-all-closer col-all-closer--leftend col-sm-closer--rightend col-xs-closer--rightend"
          >
            <div class="form-group">
              <label>
                <span class="label-text">{{ $t('expense.amount') }}</span>
              </label>
              <div class="input-group">
                <span class="input-group-addon">{{ $t('common.money') }}</span>
                <el-input
                    v-model="searchParams.amount_from"
                    type="text"
                    class="adjust-amount"
                    :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                    :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                />
                <span class="input-group-addon prn">{{ $t('common.from') }}</span>
              </div>
            </div>
          </div>
          <div
              class="col-sm-6 col-md-3 col-all-closer col-all-closer--rightend col-sm-closer--leftend col-xs-closer--leftend"
          >
            <div class="form-group">
              <label class="hidden-xs"><span class="label-text">&nbsp;</span></label>
              <div class="input-group">
                <span class="input-group-addon">{{ $t('common.money') }}</span>
                <el-input
                    v-model="searchParams.amount_to"
                    type="text"
                    class="adjust-amount"
                    :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                    :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                />
                <span class="input-group-addon prn">{{ $t('common.to') }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div
              class="col-sm-6 col-md-3 col-all-closer col-all-closer--leftend col-sm-closer--rightend col-xs-closer--rightend"
          >
            <div class="form-group">
              <label>
                <span class="label-text">{{ $t('application.table.application_date') }}</span>
              </label>
              <div class="input-group">
                <div class="input-with-icon">
                  <el-date-picker
                      class="date-input"
                      v-model="searchParams.create_date_from"
                      type="date"
                      format="YYYY/MM/DD"
                      value-format="YYYY/MM/DD"
                      :placeholder="$t('common.enter_or_select')"
                      :clearable="false"
                      :disabled-date="pickerStartCreateDateOptions"
                  />
                </div>
                <span class="input-group-addon prn">{{ $t('common.from') }}</span>
              </div>
            </div>
          </div>
          <div
              class="col-sm-6 col-md-3 col-all-closer col-all-closer--rightend col-sm-closer--leftend col-xs-closer--leftend"
          >
            <div class="form-group">
              <label class="hidden-xs"><span class="label-text">&nbsp;</span></label>
              <div class="input-group">
                <div class="input-with-icon">
                  <el-date-picker
                      class="date-input"
                      v-model="searchParams.create_date_to"
                      type="date"
                      format="YYYY/MM/DD"
                      value-format="YYYY/MM/DD"
                      :placeholder="$t('common.enter_or_select')"
                      :clearable="false"
                      :disabled-date="pickerEndCreateDateOptions"
                  />
                </div>
                <span class="input-group-addon prn">{{ $t('common.to') }}</span>
              </div>
            </div>
          </div>
          <div
              class="col-sm-6 col-md-3 col-all-closer col-all-closer--leftend col-sm-closer--rightend col-xs-closer--rightend"
          >
            <div class="form-group">
              <label>
                <span class="label-text">{{ $t('application.approve_date') }}</span>
              </label>
              <div class="input-group">
                <div class="input-with-icon">
                  <el-date-picker
                      class="date-input"
                      v-model="searchParams.approve_date_from"
                      type="date"
                      format="YYYY/MM/DD"
                      value-format="YYYY/MM/DD"
                      :placeholder="$t('common.enter_or_select')"
                      :clearable="false"
                      :disabled-date="pickerStartApproveDateOptions"
                  />
                </div>
                <span class="input-group-addon prn">{{ $t('common.from') }}</span>
              </div>
            </div>
          </div>
          <div
              class="col-sm-6 col-md-3 col-all-closer col-all-closer--rightend col-sm-closer--leftend col-xs-closer--leftend"
          >
            <div class="form-group">
              <label class="hidden-xs"><span class="label-text">&nbsp;</span></label>
              <div class="input-group">
                <div class="input-with-icon">
                  <el-date-picker
                      class="date-input"
                      v-model="searchParams.approve_date_to"
                      type="date"
                      format="YYYY/MM/DD"
                      value-format="YYYY/MM/DD"
                      :placeholder="$t('common.enter_or_select')"
                      :clearable="false"
                      :disabled-date="pickerEndApproveDateOptions"
                  />
                </div>
                <span class="input-group-addon prn">{{ $t('common.to') }}</span>
              </div>
            </div>
          </div>
        </div>
        <!--        <div class="row">-->
        <!--          <div class="col-xs-12 col-sm-6 col-md-6">-->
        <!--            <div class="form-group">-->
        <!--              <label>{{ $t('expense.expense_code') + '・' + $t('expense_cost.title') }}</label>-->
        <!--              <input-->
        <!--                type="text"-->
        <!--                v-model="searchParams.expense_code"-->
        <!--                maxlength="15"-->
        <!--                class="form-control input-sm"-->
        <!--                :placeholder="$t('expense.match')"-->
        <!--              />-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="row">
          <div class="col-xs-12 col-sm-6 col-md-6">
            <div class="form-group">
              <label>{{ $t('expense.tour_name') }}</label>
              <input
                  type="text"
                  v-model="searchParams.name"
                  maxlength="200"
                  class="form-control input-sm"
                  :placeholder="$t('placeholder.kana_kanji')"
              />
            </div>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-6">
            <div class="form-group">
              <label>{{ $t('expense.title_cost') }}</label>
              <input
                  type="text"
                  maxlength="200"
                  v-model="searchParams.title"
                  class="form-control input-sm"
                  :placeholder="$t('placeholder.kana_kanji')"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-sm-6 col-md-6">
            <div class="form-group">
              <label>{{ $t('expense.name') }}</label>
              <MultiOptionSelect
                  :list-data="listCostItem"
                  :defaultList="searchParams.cost_item"
                  @handle-selected-params="listenSearchParamsCostItem"
              />
            </div>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-6">
            <div class="form-group">
              <label>{{ $t('expense.expense_code') }}</label>
              <MultiOptionSelect
                  :list-data="listCostCode"
                  :defaultList="searchParams.expense_code"
                  @handle-selected-params="listenSearchParamsCostCodeItem"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-sm-6 col-md-6">
            <div class="form-group">
              <label>{{ $t('expense.pay_to') }}</label>
              <input
                  type="text"
                  maxlength="30"
                  v-model="searchParams.payment_destination"
                  class="form-control input-sm"
                  :placeholder="$t('placeholder.kana_kanji')"
              />
            </div>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-6">
            <div class="form-group">
              <label>
                <span class="label-text">{{ $t('expense.pay_method') }}</span>
              </label>
              <MultiOptionSelect
                  :isDisplaySearch="false"
                  :isSort="false"
                  :displayByLocale="true"
                  ref="TripPaymentMethodSelect"
                  :listData="listPaymentMethod"
                  :defaultList="searchParams.payment_method"
                  :placeholder="$t('common.not_specified')"
                  @handle-selected-params="listenSearchParamsPaymentMethod"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group">
              <label>
                <span class="label-text">{{ $t('expense.status') }}</span>
              </label>
              <MultiOptionSelect
                  popper-class="custom-dropdown-select"
                  :isDisplaySearch="false"
                  :isSort="true"
                  :showSelectAll="true"
                  :displayByLocale="true"
                  ref="ExpenseStatus"
                  :listData="listExpenseStatus"
                  :defaultList="searchParams.status"
                  :placeholder="$t('common.all')"
                  @handle-selected-params="listenSearchParamsExpenseStatus"
              />
            </div>
          </div>
          <div class="col-sm-6" v-if="isOperatorOrAdmin">
            <div class="form-group">
              <label>
                <span class="label-text">{{ $t('common.company') }}</span>
              </label>
              <MultiOptionSelect
                  ref="CompanySelect"
                  :list-data="listCompanies"
                  :defaultList="searchParams.company"
                  @handle-selected-params="listenSearchParamCompany"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12 col-sm-6 col-md-6">
            <div class="form-group">
              <label>{{ $t('trip.employee') }}</label>
              <input
                  :disabled="isDisableSearchByUser"
                  type="text"
                  maxlength="30"
                  v-model="searchParams.employee"
                  class="form-control input-sm"
                  :placeholder="$t('placeholder.kana_kanji')"
              />
              <p class="error-feedback" v-text="errors.employee"></p>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-6">
            <div class="form-group">
              <label>
                <span class="label-text">{{ $t('trip.organization') }}</span>
              </label>
              <MultiOptionSelect
                  ref="TripOrganizationSelect"
                  :disabled="isDisableSearchByUser"
                  :list-data="listOrganizations"
                  :defaultList="searchParams.organization_ids"
                  @handle-selected-params="listenSearchParams"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-2">
            <div class="form-group">
              <label class="checkbox checkbox-sm checkbox-inline">
                <el-checkbox
                    v-model="searchParams.with_deleted"
                    class="pl-0"
                    :label="$t('expense.show_deleted')"
                    size="large"
                />
              </label>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-group">
              <label class="checkbox checkbox-sm checkbox-inline">
                <el-checkbox
                    v-model="searchParams.search_duplicated_cost"
                    class="pl-0"
                    :label="$t('expense.search_duplicated_cost')"
                    size="large"
                />
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /.filter -->

    <div class="section-submit js-section-submit" v-show="showBoxFilter">
      <span class="submit-arrow"></span>
      <div class="row">
        <div class="col-sm-4 col-md-5 hidden-xs">
          <button class="btn btn-sm btn-flat-default btn-clear" @click="clearFilter()">{{ $t('common.clear') }}</button>
        </div>
        <div class="col-sm-4 col-md-2">
          <button class="btn btn-sm btn-primary btn-block js-btn-filter--minimization" @click="handleSearch()">
            {{ $t('common.search') }}
          </button>
        </div>
        <div class="col-sm-4 col-md-5 hidden-xs">
          <button class="btn btn-sm btn-flat-default btn-close" @click="showBoxFilter = false">
            {{ $t('common.close') }}
          </button>
        </div>
      </div>
    </div>
    <!-- /.section-submit -->

    <div
        class="btn-filter-again with-condition"
        v-show="!showBoxFilter"
        @click="showBoxFilter = true"
        style="display: block"
    >
      <div class="filter--condition">
        <span>{{ $t('common.all') }}</span>
        <span style="color: #3650a5">{{ $t('common.reset_conditions') }}</span>
      </div>
    </div>

    <div class="filter-result js-filter-result--minimization">
      <div class="section noborder">
        <Pagination
            v-if="listExpense.length > 0"
            :paginate="paginate"
            @change-page="changePageData"
            @change-number="changeNumber"
        />

        <div>
          <div class="table-responsive">
            <table class="table table-hover table-hover--pointer table--property-list" id="table--plan">
              <thead>
              <draggable v-model="headers" tag="tr" @end="saveColumns" :class="{'grabbing': isDragging}"
                         @start="onStart" :force-fallback="true">
                <th class="table-sort"
                    v-for="header in headers"
                    :key="header"
                    :class="[sortActive.column == header && 'table-sort-active', classHeaders[header]]"
                    @click="sortAction(header)">
                    <span :class="sortActive.column == header ? sortActive.by : 'asc'"
                    >
                      {{ $t(nameHeaders[header]) }}
                    </span>
                </th>
              </draggable>
              </thead>
              <tbody>
              <tr
                  v-for="(item, index) in listExpense"
                  :key="index"
                  class="expense--route--dummy--tgt"
                  :class="(item.deleted || item.is_canceled) && 'text-muted'"
                  @click="showModalEdit(item)"
              >
                <td v-for="header in headers" :key="header">
                  <div v-if="header == 'status_sort'">
                    <span v-if="isOperatorOrAdmin || item.status || item.trip_id || userId != item.user_id"
                          :class="bindCostStatus(item)"
                    >
                     {{ getLabelStatus(item) }}
                    </span>
                  <span v-else-if="item.is_reject && userId == item.user_id" class="cell-control">
                      <button
                          :disabled="item.deleted || item.disable_btn"
                          href="#"
                          class="btn btn-sm btn-primary btn-reject-custom"
                          @click.stop="showModalSettlementOfExpense(item)"
                      >
                    <span class="label-cost-reject">{{ getLabelStatus(item) }}</span>
                  </button>
                  </span>
                  <span v-else class="cell-control">
                      <button
                          :disabled="item.deleted || item.disable_btn"
                          href="#"
                          class="btn btn-sm btn-primary"
                          @click.stop="showModalSettlementOfExpense(item)"
                      >
                    <span>{{ getLabelStatus(item) }}</span>
                  </button>
                  </span>
                  </div>
                  <div v-if="header == 'date_sort'"

                  >
                    {{ item.date && showDateTime(item.date, 'YYYY/MM/DD（ddd）') }}
                  </div>
                  <div v-if="header == 'application_date'"

                  >
                    {{ item.application_date && showDateTime(item.application_date, 'YYYY/MM/DD（ddd）') }}
                  </div>
                  <div v-if="header == 'approve_date'"

                  >
                    {{ item.approve_date && showDateTime(item.approve_date, 'YYYY/MM/DD（ddd）') }}
                  </div>
                  <div v-if="header == 'user_name_sort'"
                        class="td-100p"
                  >
                     <el-tooltip
                         v-if="!checkEmpty(item?.user?.name) && calTextWidth(item?.user?.name) > 90"
                         popper-class="tool-tip-guide custom-tooltip-title"
                         class="label-icon-guide"
                         effect="dark"
                         :content="item?.user?.name"
                         :visible-arrow="false"
                         placement="top-start"
                     >
                    <span class="td-100p"> {{ item.user_id == userId ? $t('application.myself') : item?.user?.name }}</span>
                  </el-tooltip>
                  <span v-else>
                  {{ item.user_id == userId ? $t('application.myself') : item?.user?.name }}
                  </span>
                  </div>
                  <div v-if="header == 'company_name_sort'"
                       class="td-200p"
                  >
                    <el-tooltip
                        v-if="!checkEmpty(item?.company?.name) && calTextWidth(item?.company?.name) > 180"
                        popper-class="tool-tip-guide custom-tooltip-title"
                        class="label-icon-guide"
                        effect="dark"
                        :content="item?.company?.name"
                        :visible-arrow="false"
                        placement="top-start"
                    >
                    <span class="td-200p"> {{ item?.company?.name || '-' }}</span>
                  </el-tooltip>
                  <span v-else>
                  {{ item?.company?.name || '-' }}
                  </span>
                  </div>
                  <div v-if="header == 'trip_name_sort'"
                        class="td-250p"
                  >
                     <el-tooltip
                         v-if="!checkEmpty(getTypeCost(item)) && calTextWidth(getTypeCost(item)) > 230"
                         popper-class="tool-tip-guide custom-tooltip-title"
                         class="label-icon-guide"
                         effect="dark"
                         :content="getTypeCost(item)"
                         :visible-arrow="false"
                         placement="top-start"
                     >
                    <span class="td-250p">{{ getTypeCost(item) }}</span>
                  </el-tooltip>
                  <span v-else>
                  {{ getTypeCost(item) }}
                  </span>
                  </div>
                  <div v-if="header == 'amount'"

                  >
                    {{ splitThousandYen(item.amount) }}
                  </div>
                  <div v-if="header == 'company_cost_item_name'"

                  >
                     <div class="route-search">
                    <div>{{ item.company_cost_item_name || '-' }}</div>
                    <div v-if="item.type_cost == 3 && item.flg_change_eki != 1" class="route-search-icon icon-list" :class="$i18n.locale == 'en' && 'w-38p'">
                      <span>{{ $t('expense.route') }}</span>
                      <span style="margin-top: -2px">{{ $t('expense.search') }}</span>
                    </div>
                    <div v-if="item.type_cost == 3 && item.flg_change_eki == 1" class="route-search-icon change-eki" :class="$i18n.locale == 'en' && 'box-edited'">
                      <span>{{ $t('expense.with_modification_top') }}</span>
                      <span style="margin-top: -2px" v-if="$i18n.locale != 'en'">{{ $t('expense.with_modification_bottom') }}</span>
                    </div>
                    <div v-if="item.is_one_way !== null && item.is_one_way == 0 && $i18n.locale == 'ja'" class="route-search-icon icon-list">

                      <span style="position: relative; top: 25%">{{ $t('transportation.round_ticket') }}</span>
                    </div>
                    <div v-if="item.is_one_way !== null && item.is_one_way == 0 && $i18n.locale == 'en'" class="route-search-icon icon-list" :class="$i18n.locale == 'en' && 'w-38p'">
                      <span>Round</span>
                      <span style="margin-top: -2px">Trip</span>
                    </div>
                  </div>
                  </div>
                  <div v-if="header == 'expense_code'"
                        class="td-200p"
                  >
                     <el-tooltip
                         v-if="!checkEmpty(item.expense_code) && calTextWidth(item.expense_code) > 180"
                         popper-class="tool-tip-guide custom-tooltip-title"
                         class="label-icon-guide"
                         effect="dark"
                         :content="item.expense_code"
                         :visible-arrow="false"
                         placement="top-start"
                     >
                    <span class="td-200p"> {{ !checkEmpty(item.expense_code) ? item.expense_code : '-' }}</span>
                  </el-tooltip>
                  <span v-else>
                   {{ !checkEmpty(item.expense_code) ? item.expense_code : '-' }}
                  </span>
                  </div>
                  <div v-if="header == 'title'"
                        class="td-250p"
                  >
                     <el-tooltip
                         v-if="!checkEmpty(item.title) && calTextWidth(item.title) > 230"
                         popper-class="tool-tip-guide custom-tooltip-title"
                         class="label-icon-guide"
                         effect="dark"
                         :content="item.title"
                         :visible-arrow="false"
                         placement="top-start"
                     >
                    <span :ref="`title-expense-${item.id}`" class="td-250p">{{ item.title }}</span>
                  </el-tooltip>
                  <span v-else :ref="`title-expense-${item.id}`">
                      {{ !checkEmpty(item.title) ? item.title : '-' }}
                    </span>
                  </div>
                  <div v-if="header == 'payment_destination'"
                        class="td-250p"
                  >
                     <el-tooltip
                         v-if="!checkEmpty(item.payment_destination) && calTextWidth(item.payment_destination) > 230"
                         popper-class="tool-tip-guide custom-tooltip-title"
                         class="label-icon-guide"
                         effect="dark"
                         :content="item.payment_destination"
                         :visible-arrow="false"
                         placement="top-start"
                     >
                    <span class="td-250p"> {{ item.payment_destination || '-' }}</span>
                  </el-tooltip>
                  <span v-else>
                      {{ item.payment_destination || '-' }}
                    </span>
                  </div>
                  <div v-if="header == 'pay_method_sort'"

                  >
                    {{ getLabelPaymentMethod(item.payment_method) }}
                  </div>
                  <div v-if="header == 'receipt_sort'"
                        class="cell-control receipt-column" style="padding: 3px"
                  >
                      <template v-if="item?.receipt?.length > 0">
                    <a
                        @click.stop
                        class="btn btn-sm btn-flat-primary"
                        :href="item.receipt[0]?.file_path"
                        target="_blank"
                        rel="noopener"
                    >
                      <i class="icon-abui-receipt"></i>
                    </a>
                    <a
                        @click.stop="viewFileInfo(item)"
                        href="#"
                        class="btn btn-sm btn-flat-primary"
                        style="margin-right: 10px"
                    >
                      <i class="icon-abui-info" style="color: #98a9b0"></i>
                    </a>
                  </template>

                  <a
                      @click.stop
                      v-else-if="checkIconFile(item.file_input)"
                      class="btn btn-sm btn-flat-primary"
                      :href="item.file_input"
                      target="_blank"
                      rel="noopener"
                  >
                    <i class="icon-abui-receipt"></i>
                  </a>
                  </div>
                  <div v-if="header == 'situation'"
                       class="text-warning"
                  >
                    <span v-if="$i18n.locale == 'en'">
                    {{item.flag_temporary_cost === 1 ? 'Temporary expenses' : ''}}
                    </span>
                    <span v-else>
                    {{item.flag_temporary_cost === 1 ? '仮費用' : ''}}
                    </span>
                  </div>
                  <div v-if="header == 'is_deleted_sort'"
                        class="text-muted"
                  >
                    {{ getLabelDeleted(item) }}
                  </div>
                  <div v-if="header == 'date_create'">{{
                      item.date_create && showDateTime(item.date_create, 'YYYY/MM/DD HH:mm:ss')
                    }}
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
            <p v-if="listExpense.length == 0" class="no-data">{{ $t('common.no_data') }}</p>
          </div>
        </div>

        <Pagination
            v-if="listExpense.length > 0"
            @change-page="changePageData"
            :paginate="paginate"
            @change-number="changeNumber"
        />
      </div>
    </div>
  </div>

  <ModalTransportation
      :dialogVisible="showTransportation"
      @cancel="showTransportation = false"
      @success="successCreate"
  />

  <ModalUploadFile
      :dialogVisible="showModalUploadFile"
      accepted-files="image/jpg, image/jpeg, image/png, .heif, application/pdf"
      :acceptImageAndPDFOnly="true"
      :isSingleUpload="true"
      :title="$t('trip_item.main_content.file')"
      @cancel="showModalUploadFile = false"
      @confirm="confirmUploadFile"
  />

  <ModalExpenseCost
      ref="ModalExpenseCost"
      :dialogVisible="showExpenseCost"
      :expenseCostDetail="expenseCostDetail"
      :listFile="listFile"
      @cancel="reloadData"
      @success="fetchData"
      @showUploadFile="showUploadFile"
      @removeFile="removeFile"
  />

  <ModalSettlementExpenseWithoutTrip
      :dialogVisible="showExpenseWithoutTrip"
      :last-expense-flow-id="lastExpenseFlowId"
      :expenseDetail="expenseDetail"
      @cancel="showExpenseWithoutTrip = false"
      @success="successCreateWithoutTrip"
  />

  <ModalListFileInfo
      :dialogVisible="isShowPopupFileInfo"
      :listFile="listFilePreview"
      @close="isShowPopupFileInfo = false"
  />
</template>
<script>
import Pagination from '@/components/common/Pagination';
import ModalTransportation from '@/components/common/ModalTransportation';
import ModalExpenseCost from '@/components/common/ModalExpenseCost';
import ModalSettlementExpenseWithoutTrip from '@/components/common/ModalSettlementExpenseWithoutTrip';
import ModalUploadFile from '@/components/common/ModalUploadFile';
import MultiOptionSelect from '@/components/common/MultiOptionSelect.vue';
import ModalListFileInfo from '@/components/common/ModalListFileInfo';
import moment from 'moment';
import mixins from '@/helpers/mixins';
import vClickOutside from 'click-outside-vue3';
import {VueDraggableNext} from 'vue-draggable-next';
import {ORDER_TYPE_COST_LIST} from '@/constants';

export default {
  name: 'Expense-List',
  mixins: [mixins],
  components: {
    Pagination,
    ModalTransportation,
    ModalExpenseCost,
    ModalUploadFile,
    ModalSettlementExpenseWithoutTrip,
    MultiOptionSelect,
    ModalListFileInfo,
    draggable: VueDraggableNext
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      isDragging: false, // Initialize the isDragging state
      nameHeaders: {
        status_sort: "expense.status",
        date_sort: "expense.departure_date",
        application_date: "application.table.application_date",
        approve_date: "application.approve_date",
        user_name_sort: "expense.user_name",
        company_name_sort: "common.company",
        trip_name_sort: "trip.trip",
        amount: "expense.amount",
        company_cost_item_name: "expense.name",
        expense_code: "expense.expense_code",
        title: "expense.title",
        payment_destination: "expense.pay",
        pay_method_sort: "expense.pay_method",
        receipt_sort: "expense.receipt",
        situation: "expense.situation",
        is_deleted_sort: "expense.is_deleted",
        date_create: "expense.timestamp",
      },
      classHeaders: {
        status_sort: "",
        date_sort: "",
        application_date: "",
        approve_date: "",
        user_name_sort: "mw-100p",
        company_name_sort: "mw-200p",
        trip_name_sort: "mw-250p",
        amount: "",
        company_cost_item_name: "",
        expense_code: "mw-200p",
        title: "mw-250p",
        payment_destination: "mw-250p",
        pay_method_sort: "",
        receipt_sort: "",
        situation: "",
        is_deleted_sort: "",
        date_create: "",
      },
      headers: this.costOrderData || [
        "status_sort",
        "date_sort",
        "application_date",
        "approve_date",
        "user_name_sort",
        "company_name_sort",
        "trip_name_sort",
        "amount",
        "company_cost_item_name",
        "expense_code",
        "title",
        "payment_destination",
        "pay_method_sort",
        "receipt_sort",
        "situation",
        "is_deleted_sort",
        "date_create",
      ],
      sortActive: {
        column: this.$route.query.sort_column || 'id',
        by: this.$route.query.sort_by || 'dsc',
      },
      errors: {
        employee: '',
      },
      listOrganizations: [],
      expenseDetail: {},
      isShowDropdown: false,
      isShowPopupFileInfo: false,
      listFilePreview: [],
      showBoxFilter: false,
      showTransportation: false,
      showExpenseWithoutTrip: false,
      showExpenseCost: false,
      showModalUploadFile: false,
      expenseCostDetail: {},
      lastExpenseFlowId: '',
      listExpense: [],
      listFile: [],
      listCostItem: [],
      listCostCode: [],
      listCompanies: [],
      paginate: {
        page: +this.$route.query.page || 1,
        per_page: +this.$route.query.per_page || 10,
      },
      listExpenseStatus: [],
      listPaymentMethod: [],
      searchParams: {
        cost_item: '',
        date_from: this.$route.query.date_from || '',
        date_to: this.$route.query.date_to || '',
        create_date_from: this.$route.query.create_date_from || '',
        create_date_to: this.$route.query.create_date_to || '',
        approve_date_from: this.$route.query.approve_date_from || '',
        approve_date_to: this.$route.query.approve_date_to || '',
        amount_from: this.$route.query.amount_from || '',
        amount_to: this.$route.query.amount_to || '',
        name: this.$route.query.name || '',
        title: this.$route.query.title || '',
        payment_destination: this.$route.query.payment_destination || '',
        organization_ids: this.$route.query.organization_ids ? JSON.parse(this.$route.query.organization_ids) : [],
        payment_method: !this.checkEmpty(this.$route.query.payment_method)
            ? JSON.parse(this.$route.query.payment_method)
            : [],
        employee: this.$route.query.employee || '',
        with_deleted: this.$route.query?.with_deleted ? this.$route.query.with_deleted == 1 : false,
        search_duplicated_cost: this.$route.query?.search_duplicated_cost
            ? this.$route.query.search_duplicated_cost == 1
            : false,
        status: !this.checkEmpty(this.$route.query.status) ? JSON.parse(this.$route.query.status) : [],
        company: !this.checkEmpty(this.$route.query.company) ? JSON.parse(this.$route.query.company) : [],
        expense_code: '',
      },
    };
  },
  watch: {
    costOrderData() {
      if (this.costOrderData) {
        this.headers = this.costOrderData;
        this.filterHeaderData();
      }
    },
    isOperatorOrAdmin(val) {
      if (val) this.getListCompanies();
    },
    showBoxFilter(val) {
      if (val) this.checkRole();
    },
    'searchParams'(val) {
      if (val) this.checkRole();
    },
    'searchParams.date_from'(newVal) {
      if (this.checkEmpty(this.searchParams.date_to)) this.searchParams.date_to = newVal;
    },
    'searchParams.create_date_from'(newVal) {
      if (this.checkEmpty(this.searchParams.date_to)) this.searchParams.create_date_to = newVal;
    },
    'searchParams.approve_date_from'(newVal) {
      if (this.checkEmpty(this.searchParams.date_to)) this.searchParams.approve_date_to = newVal;
    },
    'searchParams.employee'(newVal) {
      if (this.strLen(newVal.employee) > 30 && !this.checkEmpty(newVal.employee)) {
        this.errors.employee = this.$t('E-CM-111', {size: 30});
      } else {
        delete this.errors.employee;
      }
    },
    userRole() {
      this.initData();
    },
  },
  created() {
    this.startLoading();
    this.checkRole();
    this.filterHeaderData();
    if (this.isOperatorOrAdmin) this.getListCompanies();
    if (this.checkEmpty(this.searchParams.employee) && !this.isOperatorOrAdmin)
      this.searchParams.employee = this.userName || '';
    if (!this.checkEmpty(this.userOrganizationId) && this.checkEmpty(this.searchParams.organization_ids)) {
      this.searchParams.organization_ids = [];
      this.searchParams.organization_ids.push(this.userOrganizationId);
    }

    Promise.all([this.getListCodeExpense(), this.getListStatusExpense(), this.getCostItemList(), this.getPaymentMethodList(), this.getListOrganizations()]).then(() => {
      this.getListExpense(), this.endLoading();
    });
  },
  computed: {
    costOrderData() {
      return this.$store.state.auth?.user?.order_setting?.cost || [
        "status_sort",
        "date_sort",
        "application_date",
        "approve_date",
        "user_name_sort",
        "company_name_sort",
        "trip_name_sort",
        "amount",
        "company_cost_item_name",
        "expense_code",
        "title",
        "payment_destination",
        "pay_method_sort",
        "receipt_sort",
        "is_deleted_sort",
        "date_create",
      ];
    },
    showApprovalExpense() {
      return this.$store.state.auth?.user?.setting?.is_feature_payment || this.isOperatorOrAdmin;
    },
    isUploadInvoice() {
      return this.$store.state.auth?.user?.setting.is_upload_invoice == 1 || false;
    },
    userId() {
      return this.$store.state.auth?.user?.id || '';
    },
    userName() {
      return this.$store.state.auth?.user?.name || '';
    },
    userRole() {
      return this.$store.state.auth?.user?.role?.id || '';
    },
    userOrganizationId() {
      return this.$store.state.auth?.user?.parent_organization_id || '';
    },
    isDisableSearchByUser() {
      if (!this.checkEmpty(this.userRole) && this.userRole != 1) return false;
      return true;
    },
  },
  methods: {
    filterHeaderData() {
      if (!this.isOperatorOrAdmin) {
        this.headers = this.headers.filter(item => {
          return item != 'company_name_sort'
        });
      }
      if (this.headers.length > 1 && !this.headers.includes('situation')) {
        this.headers.splice(this.headers.length - 1, 0, 'situation');
      }
    },
    onStart() {
      this.isDragging = true
    },
    async saveColumns() {
      this.isDragging = false
      let dataHeader = [...this.headers];
      this.startLoading();
      await this.$services.account.saveOrderColumn(
          {
            content: dataHeader,
            type: ORDER_TYPE_COST_LIST
          },
          () => {
            this.filterHeaderData();
            this.endLoading();
          },
          () => {
            this.endLoading();
          },
      );
    },
    async checkRole() {
      await this.$services.account.current(
          res => {
            this.$store.dispatch('auth/initCurrentData', res);
          },
          () => {
            this.logout();
          },
      );
      if (!this.showApprovalExpense) {
        this.$router.push(this.PAGES.UNAUTHORIZED);
        return;
      }
    },
    bindCostStatus(item) {
      if (item.is_reject && !this.checkEmpty(item.trip_id) && this.userId == item.user_id) {
        return 'text-danger';
      } else if (
          !item.is_approved &&
          !item.status &&
          !this.checkEmpty(item.trip_id) &&
          !item.is_reject &&
          !item.is_hold_on &&
          this.userId == item.user_id
      ) {
        return 'text-info';
      } else {
        return 'text-muted';
      }
    },
    initData() {
      if (this.checkEmpty(this.searchParams.employee) && !this.isOperatorOrAdmin) {
        this.searchParams.employee = this.userName;
      }
      if (!this.checkEmpty(this.userOrganizationId) && this.checkEmpty(this.searchParams.organization_ids)) {
        this.searchParams.organization_ids = [];
        this.searchParams.organization_ids.push(this.userOrganizationId);
      }
    },
    showCreateOtherCost() {
      this.listFile = [];
      this.expenseCostDetail = {};
      this.showExpenseCost = true;
    },
    calTextWidth(inputText) {
      let font = '14px SpicaNeueP-Regular';
      let canvas = document.createElement('canvas');
      let context = canvas.getContext('2d');
      context.font = font;
      return context.measureText(inputText).width;
    },
    viewFileInfo(item) {
      if (!item.receipt) return;

      this.isShowPopupFileInfo = true;

      const files = this.cloneObject(item.receipt);
      let listFilePreview = [];
      files.forEach(file => {
        if (file.attributes) {
          file.attributes = JSON.parse(file.attributes);

          if (file.type != 'pdf') {
            listFilePreview.push(file);
          } else {
            const total_page = file.attributes.total_page;
            for (let index = 1; index <= total_page; index++) {
              const pageData = file.attributes[`page${index}`];

              file.attributes = {
                ...file.attributes,
                width: pageData.width,
                height: pageData.height,
              };

              listFilePreview.push(file);
            }
          }
        } else {
          listFilePreview.push(file);
        }
      });

      this.listFilePreview = listFilePreview;
    },
    async getListOrganizations() {
      await this.$services.application.getListParentOrganizations(
          {},
          res => {
            this.listOrganizations = res.list;
          },
          err => {
            this.notifyError(this.$t(err.error));
          },
      );
    },
    getTypeCost(item) {
      var result = '';
      if (!this.checkEmpty(item.trip_name)) {
        result = item.trip_name;
      }
      if (this.checkEmpty(item.trip_name) && !this.checkEmpty(item.trip_id)) {
        result = this.$t('expense.text_no_trip_name');
      }
      if (item.type_cost === 3) {
        result = this.$t('expense.cost_transit');
      }
      if (item.type_cost === 1 && this.checkEmpty(item.trip_id)) {
        result = this.$t('expense.cost_other');
      }
      return result;
    },
    listenSearchParams(selectedList) {
      this.searchParams.organization_ids = selectedList;
    },
    listenSearchParamsCostItem(selectedList) {
      this.searchParams.cost_item = selectedList;
    },
    listenSearchParamsCostCodeItem(selectedList) {
      this.searchParams.expense_code = selectedList;
    },
    listenSearchParamsPaymentMethod(selectedList) {
      this.searchParams.payment_method = selectedList;
    },
    listenSearchParamsExpenseStatus(selectedList) {
      this.searchParams.status = selectedList;
    },
    listenSearchParamCompany(selectedList) {
      this.searchParams.company = selectedList;
    },
    getListCompanies() {
      const params = {
        all_data: true,
      };
      this.$services.common.getListCompanies(
          params,
          res => {
            this.listCompanies = res.list;
          },
          err => {
            this.notifyError(this.$t(err.error));
          },
      );
    },
    successCreate() {
      this.paginate = {
        ...this.paginate,
        page: 1,
      };
      this.showTransportation = false;

      this.getListExpense(false);
    },
    successCreateWithoutTrip() {
      this.paginate = {
        ...this.paginate,
        page: 1,
      };

      this.showExpenseWithoutTrip = false;
      this.getListExpense(false);
    },
    showUploadFile() {
      this.showModalUploadFile = true;
    },
    removeFile(index) {
      let cloneArr = this.listFile;
      cloneArr.splice(index, 1);

      this.listFile = cloneArr;
    },
    confirmUploadFile(files) {
      if (!this.checkEmpty(files)) {
        let fileExits = this.listFile.find(item => item.name == files[0].name);
        if (this.checkEmpty(fileExits)) {
          this.listFile = files;
        }
      }

      this.showModalUploadFile = false;
    },
    reloadData() {
      this.showExpenseCost = false;
      this.listFile = [];
      this.getListExpense(false);
    },
    fetchData() {
      this.paginate = {
        ...this.paginate,
        page: 1,
      };
      this.showExpenseCost = false;
      this.listFile = [];
      this.expenseCostDetail = {};
      this.getListExpense(false);
    },
    pickerStartOptions(time) {
      if (!this.searchParams.date_to) return false;

      return moment(new Date(time)).format('YYYY-MM-DD') > moment(this.searchParams?.date_to).format('YYYY-MM-DD');
    },
    pickerEndOptions(time) {
      if (!this.searchParams.date_from) return false;

      return moment(new Date(time)).format('YYYY-MM-DD') < moment(this.searchParams?.date_from).format('YYYY-MM-DD');
    },
    pickerStartCreateDateOptions(time) {
      if (!this.searchParams.create_date_to) return false;

      return moment(new Date(time)).format('YYYY-MM-DD') > moment(this.searchParams?.create_date_to).format('YYYY-MM-DD');
    },
    pickerEndCreateDateOptions(time) {
      if (!this.searchParams.create_date_from) return false;

      return moment(new Date(time)).format('YYYY-MM-DD') < moment(this.searchParams?.create_date_from).format('YYYY-MM-DD');
    },
    pickerStartApproveDateOptions(time) {
      if (!this.searchParams.approve_date_to) return false;

      return moment(new Date(time)).format('YYYY-MM-DD') > moment(this.searchParams?.approve_date_to).format('YYYY-MM-DD');
    },
    pickerEndApproveDateOptions(time) {
      if (!this.searchParams.approve_date_from) return false;

      return moment(new Date(time)).format('YYYY-MM-DD') < moment(this.searchParams?.approve_date_from).format('YYYY-MM-DD');
    },
    changePageData(page) {
      this.paginate.page = +page;
      this.getListExpense();
    },
    sortAction(name) {
      if (!['location', 'status_deleted'].includes(name)) {
        this.sortActive.column = name;
        if (this.sortActive.by == 'dsc') this.sortActive.by = 'asc';
        else this.sortActive.by = 'dsc';

        this.getListExpense();
      }
    },
    handleSearch() {
      this.checkRole();
      if (!this.checkEmpty(this.errors.employee)) return;

      this.paginate.page = 1;
      this.getListExpense();
    },
    async getCostItemList() {
      await this.$services.trip.getCostItemListInput(
          {with_parent_name: 1},
          res => {
            this.listCostItem = res.list;
          },
          err => {
            this.notifyError(this.$t(err.error));
          },
      );
    },
    async getListCodeExpense() {
      await this.$services.cost.getListCodeExpense(
          {
            company: !this.checkEmpty(this.$route.query.company) ? JSON.parse(this.$route.query.company) : [],
          },
          '',
          res => {
            this.listCostCode = res.list;
          },
          err => {
            this.notifyError(this.$t(err.error));
          },
      );
    },
    async getListExpense(hasLoading = true) {
      const dataSearch = {
        ...this.searchParams,
        page: this.paginate.page,
        per_page: this.paginate.per_page,
        sort_column: this.sortActive.column,
        sort_by: this.sortActive.by == 'dsc' ? 'desc' : 'asc',
      };

      dataSearch.amount_from = String(dataSearch.amount_from).replace(/[^0-9.]/g, '');
      dataSearch.amount_to = String(dataSearch.amount_to).replace(/[^0-9.]/g, '');

      let queryBuild = '';

      this.searchParams.payment_method.forEach(item => {
        queryBuild += 'payment_method[]=' + item + '&';
      });

      if (!this.checkEmpty(this.searchParams.status)) {
        this.searchParams.status.forEach(item => {
          queryBuild += 'status[]=' + item + '&';
        });
      }

      if (this.searchParams?.organization_ids?.length > 0) {
        this.searchParams.organization_ids.forEach(item => {
          queryBuild += 'organization_ids[]=' + item + '&';
        });
      }

      delete dataSearch.payment_method;
      delete dataSearch.organization_ids;
      delete dataSearch.status;

      if (hasLoading) this.startLoading();
      if (this.isOperatorOrAdmin) {
        if (this.searchParams?.company?.length > 0) {
          this.searchParams.company.forEach(item => {
            queryBuild += 'company[]=' + item + '&';
          });
        }

        delete dataSearch.company;

        await this.$services.cost.getListExpenseOP(
            dataSearch,
            queryBuild,
            res => {
              if (hasLoading) this.endLoading();
              this.listExpense = res.list;
              this.paginate = {...this.paginate, ...res.paginate};
              this.showBoxFilter = false;

              this.updateRouter(res, true);
            },
            err => {
              if (hasLoading) this.endLoading();
              this.notifyError(this.$t(err.error));
            },
        );
      } else {
        await this.$services.cost.getListExpense(
            dataSearch,
            queryBuild,
            res => {
              if (hasLoading) this.endLoading();
              this.listExpense = res.list;
              this.paginate = {...this.paginate, ...res.paginate};
              this.showBoxFilter = false;

              this.updateRouter(res);
            },
            err => {
              if (hasLoading) this.endLoading();
              this.notifyError(this.$t(err.error));
            },
        );
      }
    },
    updateRouter(res, isOperatorOrAdmin = false) {
      let query = {
        page: res.paginate.current_page,
        per_page: res.paginate.per_page,
        date_from: this.searchParams.date_from,
        expense_code: this.searchParams.expense_code,
        date_to: this.searchParams.date_to,
        amount_from: this.searchParams.amount_from,
        create_date_from: this.searchParams.create_date_from,
        create_date_to: this.searchParams.create_date_to,
        approve_date_from: this.searchParams.approve_date_from,
        approve_date_to: this.searchParams.approve_date_to,
        amount_to: this.searchParams.amount_to,
        name: this.searchParams.name,
        title: this.searchParams.title,
        payment_destination: this.searchParams.payment_destination,
        with_deleted: this.searchParams?.with_deleted ? 1 : 0,
        search_duplicated_cost: this.searchParams?.search_duplicated_cost ? 1 : 0,
        organization_ids: !this.checkEmpty(this.searchParams.organization_ids)
            ? JSON.stringify(this.searchParams.organization_ids)
            : '',
        payment_method: !this.checkEmpty(this.searchParams.payment_method)
            ? JSON.stringify(this.searchParams.payment_method)
            : '',
        status: !this.checkEmpty(this.searchParams.status) ? JSON.stringify(this.searchParams.status) : '',
        employee: this.searchParams.employee,
        sort_column: this.sortActive.column,
        sort_by: this.sortActive.by,
      };

      if (isOperatorOrAdmin) query.company = JSON.stringify(this.searchParams.company);
      query = this.removeEmptyObject(query);

      this.$router.push({
        path: this.$route.path,
        query,
      });
    },
    showModalEdit(item) {
      this.checkRole();
      if (item.trip_id && !item.deleted) {
        this.$router.push({
          name: this.PAGES.TRIP_UPDATE,
          params: {
            id: item.trip_id,
          },
        });
      } else {
        this.getCostDetail(item.id, item.deleted);
      }
    },
    async getCostDetail(id, isDeleted = false, showModalCostDetailAfter = true) {
      this.startLoading();
      await this.$services.trip.getCostDetail(
          id,
          res => {
            this.endLoading();
            this.expenseCostDetail = {
              ...res,
              currency_rate: res.currency_id == 1 ? 0 : res.currency_rate,
              amount: this.splitThousandNumber(res.amount || 0),
              currency_amount: this.splitThousandNumber(res.currency_amount || 0),
              firstLoading: res.currency_id != 1 ? 1 : 0,
              is_deleted: isDeleted,
            };
            this.lastExpenseFlowId = res.last_expense_flow_id;
            this.listFile = res.files;
            this.showExpenseCost = showModalCostDetailAfter;
          },
          err => {
            this.endLoading();
            this.notifyError(this.$t(err.error));
          },
      );
    },
    async showModalSettlementOfExpense(item) {
      this.checkRole();
      this.expenseDetail = item;
      let flgValidate = true;
      if (
          item.is_reject ||
          this.checkEmpty(this.expenseDetail.title) ||
          this.checkEmpty(this.expenseDetail.payment_destination) ||
          (this.expenseDetail.amount == 0 && item.type_cost != 3) ||
          (this.checkEmpty(this.expenseDetail.amount) && item.type_cost != 3) ||
          (this.expenseDetail.currency_id == 1 && this.checkEmpty(this.expenseDetail.amount) && item.type_cost != 3) ||
          (this.expenseDetail.currency_id == 1 &&
              (this.expenseDetail.tax_type == 1 || this.expenseDetail.tax_type == 2) &&
              this.checkEmpty(this.expenseDetail.tax_amount) &&
              item.type_cost != 3) ||
          (this.expenseDetail.currency_id != 1 && this.checkEmpty(this.expenseDetail.change_rate)) ||
          (this.expenseDetail.currency_id != 1 && this.checkEmpty(this.expenseDetail.currency_amount)) ||
          this.checkEmpty(this.expenseDetail.date) ||
          this.checkEmpty(this.expenseDetail.payment_method) ||
          this.checkEmpty(this.expenseDetail.company_cost_item_id) ||
          (this.expenseDetail.company_cost_is_valid === 0 && !this.checkEmpty(this.expenseDetail.company_cost_item_id)) ||
          (this.expenseDetail.type_cost == 1 && this.checkEmpty(this.expenseDetail.receipt) && this.isUploadInvoice) ||
          (this.expenseDetail.currency_id != 1 && this.checkEmpty(this.expenseDetail.currency_amount))
      ) {
        flgValidate = false;
      }

      if (flgValidate) {
        await this.getCostDetail(item.id, false, false);
        this.showExpenseWithoutTrip = true;
        return;
      }
      this.getCostDetail(item.id, false, true);
    },

    async getListStatusExpense() {
      await this.$services.common.getListStatusExpense(
          {with_other: 1},
          res => {
            this.listExpenseStatus = [];
            if (!this.checkEmpty(res.list)) {
              res.list.map(item => {
                item.jp_name = item.jp_name.substring(2);
                this.listExpenseStatus.push(item);
              });
            }
          },
          err => {
            this.notifyError(this.$t(err.error));
          },
      );
    },
    async getPaymentMethodList() {
      await this.$services.common.getPaymentMethodList(
          res => {
            this.listPaymentMethod = res.list;
          },
          err => {
            this.notifyError(this.$t(err.error));
          },
      );
    },
    downloadFileCSV() {
      this.startLoading();
      let paramsCSV = this.cloneObject(this.searchParams);
      delete paramsCSV.organization_ids;
      delete paramsCSV.status;
      delete paramsCSV.payment_method;
      let queryBuilder = '';
      if (!this.checkEmpty(this.searchParams.organization_ids)) {
        this.searchParams.organization_ids.forEach(item => {
          queryBuilder += 'organization_ids[]=' + item + '&';
        });
      }

      if (!this.checkEmpty(this.searchParams.status)) {
        this.searchParams.status.forEach(item => {
          queryBuilder += 'status[]=' + item + '&';
        });
      }

      if (!this.checkEmpty(this.searchParams.payment_method)) {
        this.searchParams.payment_method.forEach(item => {
          queryBuilder += 'payment_method[]=' + item + '&';
        });
      }
      this.$services.cost.expensesDownloadCSV(
          paramsCSV,
          queryBuilder,
          res => {
            this.endLoading();
            if (res.file_path) this.handleDownloadCSV(res.file_path);
          },
          err => {
            this.endLoading();
            this.notifyError(this.$t(err.error));
          },
      );
    },
    changeNumber(number) {
      this.paginate = {
        ...this.paginate,
        page: 1,
        per_page: number,
      };
      this.getListExpense();
    },
    clearFilter() {
      this.checkRole();
      this.searchParams = {
        date_from: '',
        date_to: '',
        amount_from: '',
        amount_to: '',
        create_date_from: '',
        create_date_to: '',
        approve_date_from: '',
        approve_date_to: '',
        name: '',
        title: '',
        payment_destination: '',
        payment_method: [],
        employee: '',
        organization_ids: [],
        status: [],
        with_deleted: false,
        search_duplicated_cost: false,
        expense_code: '',
      };
      this.$refs.TripPaymentMethodSelect.clearFilter();
      if (this.isOperatorOrAdmin) this.$refs.CompanySelect.clearFilter();
      this.initData();
    },
    getLabelPaymentMethod(paymentMethodId) {
      const paymentMethod = this.listPaymentMethod.find(item => item.id == paymentMethodId);
      if (paymentMethod) return this.labelLocale(paymentMethod);

      return '-';
    },
    getLabelStatus(item) {
      if (!item.is_approved && !item.status && !item.trip_id && !item.is_reject && this.userId == item.user_id) {
        return this.$t('expense.label_status.actuarial_application');
      } else if (item.is_approved) {
        return this.$t('expense.label_status.approval');
      } else if (item.is_reject) {
        return this.$t('expense.label_status.rejected');
      } else if (item.is_hold_on) {
        return this.$t('expense.label_status.is_hold_on');
      } else if (item.status) {
        return this.$t('expense.label_status.applied');
      } else {
        return this.$t('expense.label_status.not_applied');
      }
    },
    getLabelDeleted(item) {
      if (item.deleted) return this.$t('expense.label_deleted');
      if (item.is_canceled) return this.$t('expense.label_status.canceled');
      return '';
    },
    checkIconFile(item) {
      return !this.checkEmpty(item);
    },
  },
};
</script>
<style scoped>
.route-search {
  display: flex;
  align-items: center;
}

.route-search-icon {
  font-size: 10px;
  transform: scale(0.8);
  transform-origin: 0, 0;
  color: #657a84;
  background: #e4e9ea;
  border-radius: 2px;
  width: 32px;
  height: 32px;
  padding: 4px;
  margin-left: 2px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.btn-reject-custom {
  background: #f55361;
}

.btn-reject-custom:hover,
.btn-reject-custom:active,
.btn-reject-custom:focus {
  background: #ff6a77 !important;
}

.label-cost-reject {
  color: #fff;
}

.icon-list {
  background-color: #657a84 !important;
  color: #fff !important;
}

.change-eki {
  background-color: #8B0000 !important;
  color: #fff !important;
}

.w-38p {
  width: 38px;
}

.el-tooltip__trigger {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.box-edited {
  align-items: center;
  justify-content: center;
  padding: 0px !important;
  flex-direction: unset !important;
  width: 38px;
  height: 34px;
}
</style>
