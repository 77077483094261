<template>
  <div class="wrapper-page">
    <nav class="navbar navbar-default navbar-lg" role="navigation" id="header-content">
      <div class="navbar-header">
        <h2 class="navbar-title">{{ $t('information.notice') }}</h2>
      </div>
    </nav>

    <div class="wrapper with-navbar-fixed-top">
      <div class="filter-wrap">
        <div class="section noborder ptn" id="section--main">
          <div class="">
            <h4 v-if="information.notification_type != 2" class="information--title">
              <span>
                <div v-if="information.trip != null || information.trip_id != null">
                  <span @click.stop>
                    <a @click="redirectNotificationTrip(information)" class="cursor-pointer">
                      {{ handleTitleInformationTrip(information).name_and_code }}
                      {{ information.trip_code }}
                    </a>
                  </span>
                  {{ handleTitleInformationTrip(information).auto_gen_string }}
                </div>
                <div
                  v-else-if="
                    information?.notification_type == 4 &&
                    !checkEmpty(information?.request_id)
                  "
                >
                 <a @click="redirectNotification(information)" class="cursor-pointer" v-if="checkEmpty(information?.trip_id)">
                    {{$t('application.cost_no_trip') +  handleTitleInformationCost(information).name_and_code }}
                 </a>
                 <a @click="redirectNotification(information)" class="cursor-pointer" v-else>
                    {{handleTitleInformationCost(information).name_and_code }}
                 </a>
                  {{ handleTitleInformationCost(information).auto_gen_string }}
                </div>
                <div v-else>
                  <span v-if="checkEmpty(information?.trip_id)">
                     {{$t('application.cost_no_trip') + information.title }}
                  </span>
                  <span v-else>
                     {{ information.title }}
                  </span>
                </div>
              </span>
            </h4>
            <h4 v-else class="information--title">
              {{ information?.title }}
              <a v-if="information.trip !== null" class="cursor-pointer" @click="redirectNotification(information)">
                ({{ information?.trip?.code }})
              </a>
            </h4>
            <p class="information--datetime">
              {{ showDateTime(information?.send_time, 'YYYY-MM-DD HH:mm') }}
            </p>
            <p class="information--type">
              <span class="news--type">
                {{ getNotificationTypeLabel(information.notification_type) }}
              </span>
            </p>
            <template v-if="!checkEmpty(information?.procedure_detail)">
              <div v-for="(item, index) in information.procedure_detail" :key="index" class="form-group">
                <p>{{ labelLocale(item.detail.country) }}</p>
                <div v-if="!checkEmpty(item.detail.detail_information)" class="quill-content-custom">
                  <QuillEditor
                    :content="item.detail.detail_information.split(',').reverse()[0]"
                    :readOnly="true"
                    contentType="html"
                    theme="bubble"
                    toolbar="none"
                  />
                </div>
                <div class="mt-2" style="margin-top: 10px" v-if="!checkEmpty(item.detail.country.ciq[0].inputs)">
                  <ul class="uploaded--file-list" style="">
                    <li style="" v-for="(input, index) in item.detail.country.ciq[0].inputs" :key="index">
                      <a
                        :href="input?.file?.file_path"
                        target="_blank"
                        style="display: flex; align-items: center"
                        rel="noopener"
                      >
                        <i class="icon-abui-file" />
                        <span class="file--name">{{ input?.file?.name }}</span>
                      </a>
                      <span class="file--date text-left">
                        {{ input?.file?.created_at }}
                      </span>
                    </li>
                  </ul>
                </div>
                <hr />
              </div>
            </template>
            <template v-else-if="!checkEmpty(information?.text_detail)">
              <div class="form-group group-text-detail d-flex">
                <span
                  v-if="
                    checkEmpty(information?.trip_id) &&
                    checkEmpty(information?.is_view_passport) &&
                    (information?.notification_type == 4 || information?.notification_type == 6)
                  "
                >
                  {{ $t('application.cost_no_trip') + information?.text_detail }}
                </span>
                <span v-else>{{ information?.text_detail }}</span>
                <!-- <div class="text-detail-info quill-content-custom">
                  <QuillEditor
                    :content="information?.text_detail.split(',').reverse()[0]"
                    :readOnly="true"
                    contentType="html"
                    theme="bubble"
                    toolbar="none"
                  />
                </div> -->
                <br />
                <span v-if="!checkEmpty(information?.request_id)" style="margin-right: 32px">
                  <a class="cursor-pointer link-to-request" @click="redirectToRequest(information?.request)">
                    {{ $t('application.confirm_request') }}
                  </a>
                </span>
                <span v-if="!checkEmpty(information?.is_view_passport)" style="margin-right: 32px">
                  <a class="cursor-pointer link-to-request" @click="redirectToUser()">
                    {{ $t('application.update_employee_inf') }}
                  </a>
                </span>
                <span v-if="!checkEmpty(information?.cost_id) && information?.request?.applicant_id == userId" style="margin-right: 32px">
                  <a class="cursor-pointer link-to-request" @click="reclaimCost()">
                    {{ $t('application.reclaim_cost') }}
                  </a>
                </span>
              </div>
            </template>
          </div>
        </div>
        <div class="section">
          <div class="row">
            <div class="col-sm-3 col-md-2">
              <a class="btn btn-sm btn-flat-default" href="#" @click="$router.push(this.PAGES.INFORMATION)">
                <span class="icon-abui-arrow-left prx"></span>
                {{ $t('information.back') }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ModalConfirm
      :dialogVisible="showPopup"
      @cancel="showPopup = false"
      :title="$t('application.request_deleted')"
  />
</template>

<script>
import moment from 'moment';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import ModalConfirm from '@/components/common/ModalMessage';

export default {
  name: 'InformationDetail',
  components: { QuillEditor, ModalConfirm },
  data() {
    return {
      showPopup: false,
      information: {
        title: '',
        created_at: '',
        notification_type: '',
        text_detail: '',
        company_id: '',
        send_time: '',
        status: '',
        employees: [],
      },
      listEmployee: [],
    };
  },
  computed: {
    userNumber() {
      return this.$store.state.auth?.user?.employee_number;
    },
    userId() {
      return this.$store.state.auth?.user?.id || '';
    },
    roleId() {
      return this.$store.state.auth?.user?.role?.id || '';
    },
  },
  created() {
    this.getInformationDetail();
  },
  watch: {},
  methods: {
    handleTitleInformation(item) {
      if (this.checkEmpty(item.trip)) return item.title;
      let newTitle = item.title.split(item.trip.code);

      return {
        name_and_code: newTitle[0],
        auto_gen_string: newTitle[1],
      };
    },
    handleTitleInformationTrip(item) {
      if (this.checkEmpty(item.trip_id)) return item.title;
      let newTitle = item.title.split(item.trip_code);
      return {
        name_and_code: newTitle[0],
        auto_gen_string: newTitle[1],
      };
    },
    handleTitleInformationCost(item) {
      let newTitle = item.title.split('の申請');

      return {
        name_and_code: newTitle[0],
        auto_gen_string: 'の申請' + newTitle[1],
      };
    },
    redirectNotificationTrip(item) {
      let routeData;
      if (this.checkEmpty(item.trip?.id)) {
        this.showPopup = true;
        return;
      }
      if (this.checkEmpty(item.trip_id) && !this.checkEmpty(item.request_id)) {
        routeData = this.$router.resolve({
          name: this.PAGES.TRIP_ITEM,
          params: {
            id: item.request_id,
          },
          query: {
            type: item.request?.type,
          },
        });
      } else if (!this.checkEmpty(item.trip_id)) {
        routeData = this.$router.resolve({
          name: this.PAGES.TRIP_UPDATE,
          params: {
            id: item.trip_id,
          },
        });
      } else if (!this.checkEmpty(item.request_id)) {
        routeData = this.$router.resolve({
          name: this.PAGES.TRIP_ITEM,
          params: {
            id: item.request_id,
          },
        });
      } else {
        routeData = this.$router.resolve({
          name: this.PAGES.EMPLOYEE_DETAIL,
          params: {
            id: this.userId,
          },
        });
      }
      window.open(routeData.href, '_blank');
    },
    redirectNotification(item) {
      if (this.checkEmpty(item.request?.id)) {
        this.showPopup = true;
        return;
      }
      let routeData;
      if (this.checkEmpty(item.trip_id) && !this.checkEmpty(item.request_id)) {
        routeData = this.$router.resolve({
          name: this.PAGES.TRIP_ITEM,
          params: {
            id: item.request_id,
          },
          query: {
            type: item.request?.type,
          },
        });
      } else if (!this.checkEmpty(item.trip_id)) {
        routeData = this.$router.resolve({
          name: this.PAGES.TRIP_UPDATE,
          params: {
            id: item.trip_id,
          },
        });
      } else if (!this.checkEmpty(item.request_id)) {
        routeData = this.$router.resolve({
          name: this.PAGES.TRIP_ITEM,
          params: {
            id: item.request_id,
          },
        });
      } else {
        routeData = this.$router.resolve({
          name: this.PAGES.EMPLOYEE_DETAIL,
          params: {
            id: this.userId,
          },
        });
      }
      window.open(routeData.href, '_blank');
    },
    redirectToRequest(request) {
      if (this.checkEmpty(request?.id)) {
        this.showPopup = true;
        return;
      }

      const routeTo = this.$router.resolve({
        name: this.PAGES.TRIP_ITEM,
        params: {
          id: request.id,
        },
      });

      window.open(
        routeTo.href + '?type=' + request.type + '&date=' + moment(request.date).format('YYYY/MM/DD'),
        '_blank',
      );
    },
    redirectToUser() {
      let routeTo = '';
      if (this.roleId !== 4) {
        routeTo = this.$router.resolve({
          name: this.PAGES.PROFILE,
        });
      } else {
        routeTo = this.$router.resolve({
          name: this.PAGES.EMPLOYEE_DETAIL,
          params: {
            id: this.userId,
          },
        });
      }

      window.open(routeTo.href, '_blank');
    },
    reclaimCost() {
      let routeTo = this.$router.resolve({
        path: this.PAGES.EXPENSE,
      });

      window.open(routeTo.href, '_blank');
    },
    async getInformationDetail() {
      const id = this.$route.params.id;
      this.startLoading();
      await this.$services.notification.getInformationDetail(
        id,
        res => {
          this.endLoading();
          this.information = res;
          if (res.notification_type == 3) {
            this.information.send_time = moment(res.send_time).subtract(10, 'minutes').format('YYYY/MM/DD HH:mm:ss');
          }
          this.listEmployee = res.employees;
          this.listEmployee.forEach(emp => {
            if (emp.is_read == 0 && emp.employee_number == this.userNumber) {
              this.$services.notification.updateIsReadNotification(
                id,
                () => {
                  this.checkNotificationUnread();
                },
                err => {
                  this.notifyError(this.$t(err.message_code));
                },
              );
            }
          });
        },
        err => {
          // this.endLoading();
          this.notifyError(this.$t(err.error));
          this.$router.push(this.PAGES.INFORMATION);
        },
      );
    },
    checkNotificationUnread() {
      this.$services.notification.countNotificationUnread(
        res => {
          this.$store.dispatch('notification/totalNotificationUnread', res.count);
        },
        () => {},
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.text-detail-info {
  min-height: 28px;
}
.quill-content-custom {
  border: none;
  padding-left: 0;
}
</style>
