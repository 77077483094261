import BaseService from './BaseService';
import API_CODE from '../utils/api_code.js';

export default class ApplicationAPI extends BaseService {
  async getListApplications(params, query, success, error) {
    await this.get(API_CODE.CU_001 + this.urlParse(params, query), success, error);
  }
  async getBurdenOrganizations(success, error) {
    await this.get(API_CODE.CU_170, success, error);
  }
  async getListParentOrganizations(params, success, error) {
    await this.get(API_CODE.CU_169 + this.urlParse(params), success, error);
  }
  async approveApplications(params, success, error) {
    await this.post(API_CODE.CU_002, params, success, error);
  }
  async downloadRequestsCSV(params, query, success, error) {
    await this.get(API_CODE.CU_292 + this.urlParse(params, query), success, error);
  }
}
