<template>
  <div
      class="wrapper with-navbar-fixed-top with-navbar-fixed-bottom"
      :style="showTripProcessDetail ? { 'padding-top': paddingProcessHeight } : { 'padding-top': '140px' }"
  >
    <TripProcess
        :show-detail="showTripProcessDetail"
        :request-detail="requestDetail"
        @can-approver-submit="listenApproverSubmit"
        @show-history="showHistory"
        @handle-show-detail="listenShowDetail"
    />
    <MainTripItemContent :request-detail="requestDetail" :request-date="requestDate" :all-costs="allCosts" />
    <FooterButtons
        v-if="showApprovalExpense || requestDetail.type !== 3"
        :request-detail="requestDetail"
        :can-submit="canApproverSubmit"
        :is-current-user-holding="isHoldingRequest"
        :is-current-step-user="isCurrentStepUser"
        :is-show-btn-cancel-request="isShowBtnCancelRequest"
        :isStepView="isRoundViewOnly"
        @show-approve-modal="showApproveModal"
        @show-cancel-modal="showCancelModal"
        @show-suspend-ringi="showSuspendRingi"
        @show-reject-modal="showRejectModal"
        @submit-remove-hold="listenRemoveHoldAction"
    />

    <!-- List Modal -->
    <ModalRingiHistory
        :request-histories="requestHistories"
        :is-modal-visible="isHistoryModalVisible"
        @handleCloseModal="closeHistoryModal"
    />
    <ModalApprove
        :is-modal-visible="isApproveModalVisible"
        :next-approver-inf="nextStepApprovers"
        :isStepView="isRoundViewOnly"
        @submit-approve-modal="submitApproveModal"
        @close-modal="closeApproveModal"
    />
    <ModalSuspendRingi
        :is-modal-visible="isSuspendRingi"
        @submit-suspend-ringi-modal="submitSuspendRingiModal"
        @close-modal="closeSuspendRingiModal"
    />
    <ModalReject
        :is-modal-visible="isRejectModalVisible"
        :request-detail="requestDetail"
        @submit-reject-modal="submitRejectModal"
        @close-modal="closeRejectModal"
    />
  </div>
  <ModalConfirm
      :dialogVisible="showBoxNeedApprove"
      @cancel="showBoxNeedApprove = false"
      @yes="submitCancelRequest"
      :classCustom="actionBoxConfirm == 'need-approve' ? 'btn-flat-default' : 'btn-flat-danger'"
      :title="titleBoxConfirm"
  />

  <ModalCancel
      :is-modal-visible="showBoxCancel"
      :name-btn="$i18n.locale == 'en' ? 'Withdraw Execution' : '取下実行'"
      @submit-cancel-modal="submitCancelRequest"
      @close-modal="showBoxCancel = false"
  />
</template>
<script>

import ModalConfirm from '@/components/common/ModalConfirm';
import mixins from '@/helpers/mixins';
import TripProcess from '@/components/trip/trip-item/TripProcess';
import MainTripItemContent from '@/components/trip/trip-item/MainTripItemContent';
import FooterButtons from '@/components/trip/trip-item/FooterButtons';
//List modal components
import ModalApprove from '@/components/trip/trip-item/modal/ModalApprove';
import ModalRingiHistory from '@/components/trip/trip-item/modal/ModalRingiHistory';
import ModalReject from '@/components/trip/trip-item/modal/ModalReject';
import ModalSuspendRingi from '@/components/trip/trip-item/modal/ModalSuspendRingi';
import ModalCancel from '@/components/trip/trip-item/modal/ModalCancel';
//Constants
import { HOLD, REMAND, APPROVAL, PASS_ROUND_PERUSAL, REMOVE_HOLD } from '@/constants/';

export default {
  name: 'TripItem',
  emits: ['handleCloseModal'],
  mixins: [mixins],
  components: {
    TripProcess,
    ModalCancel,
    ModalConfirm,
    MainTripItemContent,
    FooterButtons,
    //List modal
    ModalApprove,
    ModalRingiHistory,
    ModalReject,
    ModalSuspendRingi,
  },
  data() {
    return {
      requestDetail: {},
      requestHistories: {},
      showTripProcessDetail: false,
      //Modal visible status
      isHistoryModalVisible: false,
      showBoxCancel: false,
      isApproveModalVisible: false,
      isRejectModalVisible: false,
      showBoxNeedApprove: false,
      //Hold modal visible
      isSuspendRingi: false,
      allCosts: [],
      canApproverSubmit: false,
      nextStepApprovers: [],
      paddingProcessHeight: '229px',
    };
  },
  created() {
    // this.startLoading();
    Promise.all([this.getRequestDetail(), this.getRequestHistories()]).then(() => {
      // if (this.requestType == 3) this.endLoading();
    });
  },
  computed: {
    showApprovalExpense() {
      return this.$store.state.auth?.user?.setting?.is_feature_payment || false;
    },
    requestId() {
      return parseInt(this.$route.params.id);
    },
    requestType() {
      return parseInt(this.$route.query.type);
    },
    requestDate() {
      return this.$route.query.date ? this.$route.query.date : '';
    },
    isRoundViewOnly() {
      if (this.checkEmpty(this.requestDetail)) return false;
      return (
          this.requestDetail.approval_flow_detail[this.requestDetail.current_step - 1].approval_flow_type ==
          PASS_ROUND_PERUSAL
      );
    },
    userId() {
      return this.$store.state.auth?.user?.id || '';
    },
    isHoldingRequest() {
      const curStep = this.requestDetail.current_step || 0;
      if (curStep) {
        const curApproverFlow = this.requestDetail.approval_flow_detail[curStep - 1].step_approver || [];
        if (!this.checkEmpty(curApproverFlow)) {
          for (let i = 0; i < curApproverFlow.length; i++) {
            if (curApproverFlow[i].approver_id == this.userId && curApproverFlow[i].request_status == HOLD) return true;
          }
        }
      }
      return false;
    },
    isCurrentStepUser() {
      const curStep = this.requestDetail.current_step || 0;
      if (curStep) {
        const curApproverFlow = this.requestDetail.approval_flow_detail[curStep - 1].step_approver || [];
        if (!this.checkEmpty(curApproverFlow)) {
          for (let i = 0; i < curApproverFlow.length; i++) {
            if (curApproverFlow[i].approver_id == this.userId) return true;
          }
        }
      }
      return false;
    },
    isShowBtnCancelRequest() {
      return this.$store.state.auth?.user?.id === this.requestDetail?.applicant_id
          && this.requestDetail?.expense_status_id
          && this.requestDetail?.expense_status_id != 4
          && this.requestDetail?.expense_status_id != 5
          && this.requestDetail?.deleted_at === null;
    },
  },
  watch: {
    requestId(newVal) {
      if (!this.checkEmpty(newVal) && !isNaN(newVal)) this.reloadRequestDetail();
    },
    requestDetail(newVal) {
      const nextStep = this.requestDetail.current_step + 1;
      const countAllStep = this.requestDetail.approval_flow_detail.length;
      if (countAllStep < nextStep) {
        this.nextStepApprovers = [];
        return;
      }
      const nextStepApprover = this.requestDetail.approval_flow_detail[this.requestDetail.current_step]?.step_approver;
      if (!this.checkEmpty(nextStepApprover)) {
        this.nextStepApprovers = [];
        nextStepApprover.forEach(item => {
          if (item.authorizer == null) this.nextStepApprovers.push(item);
        });
      }
      if (!this.checkEmpty(newVal.deleted_at)) {
        this.canApproverSubmit = false;
      }
      this.calHeightApprover();
    },
    // canApproverSubmit(newVal) {
    //   if (newVal) this.submitIfIsRoundViewOnly();
    // },
    'requestDetail.applicant_check'(newVal) {
      if (this.checkEmpty(newVal)) {
        this.canApproverSubmit = false;
      }
    },
    // 'requestDetail.current_step'() {
    //   this.submitIfIsRoundViewOnly();
    // },
  },
  methods: {
    reloadRequestDetail() {
      this.startLoading();
      Promise.all([this.getRequestDetail(), this.getRequestHistories()]).then(() => {
        this.endLoading();
      });
    },
    calHeightApprover() {
      let maxLengthApprover = 0;
      this.requestDetail?.approval_flow_detail.forEach(item => {
        if (item.step_approver?.length > maxLengthApprover) maxLengthApprover = item.step_approver?.length;
      });
      if (maxLengthApprover > 3) {
        this.paddingProcessHeight = String(208 + (maxLengthApprover - 3) * 18) + 'px';
        return;
      }
      this.paddingProcessHeight = '230px';
    },
    closeHistoryModal() {
      this.isHistoryModalVisible = false;
    },
    closeApproveModal() {
      this.isApproveModalVisible = false;
    },
    listenApproverSubmit(value) {
      if (!this.checkEmpty(this.requestDetail.deleted_at)) {
        this.canApproverSubmit = false;
      } else {
        this.canApproverSubmit = value;
      }
    },
    listenRemoveHoldAction() {
      this.removeHoldStatus();
    },
    // submitIfIsRoundViewOnly() {
    //   if (this.canApproverSubmit && this.isRoundViewOnly) this.submitApproveModal();
    // },
    submitApproveModal(approveMsg = '', isNextApprover) {
      this.startLoading();
      const executeParams = {
        request_id: this.requestId,
        status_type: this.requestType,
        approval_status: this.isRoundViewOnly ? PASS_ROUND_PERUSAL : APPROVAL,
        comment: approveMsg,
        current_step: this.requestDetail.current_step,
        updated_at: this.requestDetail.updated_at,
      };
      this.$services.trip_item.executeRequest(
          executeParams,
          () => {
            if (!isNextApprover) {
              this.getListApplications();
            } else {
              this.getRequestDetail();
              this.getRequestHistories();
              this.notifySuccess(this.$t('trip_item.msg_approve_success'));
            }
            this.endLoading();
          },
          err => {
            this.endLoading();
            this.notifyError(this.$t(err.error), true);
          },
      );
      this.isApproveModalVisible = false;
    },

    async getListApplications() {
      let queryBuilder =
          'approval_status[]=3&approval_status[]=4&reporting_status[]=3&reporting_status[]=4&expense_status[]=2&expense_status[]=3';
      await this.$services.application.getListApplications(
          {
            'expected_approval[]': 1,
            is_applicant_or_approver: 2,
            sort_by: 'asc',
            sort_column: 'date',
            page: 1,
            per_page: 10,
          },
          queryBuilder,
          res => {
            let holdIds = JSON.parse(localStorage.getItem('hoild_ids')) || [];
            let list = res.list.filter(item => !holdIds.includes(item.id));
            if (list.length > 0) {
              this.getRequestDetailOther(list[0].id, list[0].type);
              this.$router.push({
                name: this.PAGES.TRIP_ITEM,
                params: {
                  id: list[0].id,
                  trip_id: list[0].trip_id,
                },
                query: {
                  date: this.showDateTime(list[0].date, 'YYYY/MM/DD'),
                  type: list[0].type,
                },
              });
            } else {
              this.$router.push(this.PAGES.DASHBOARD);
            }
          },
          err => {
            this.notifyError(this.$t(err.error));
          },
      );
    },

    //Handle event Reject Modal
    closeRejectModal() {
      this.isRejectModalVisible = false;
    },
    submitRejectModal(rejectMsg = '', stepBack = '') {
      this.startLoading();
      const executeParams = {
        request_id: this.requestId,
        status_type: this.requestType,
        approval_status: REMAND,
        step_back: stepBack == -1 ? 0 : stepBack,
        comment: rejectMsg,
        current_step: this.requestDetail.current_step,
        trip_id: this.requestDetail?.trip_id,
        trip_name: this.requestDetail?.trip_name,
        updated_at: this.requestDetail?.updated_at,
      };
      this.$services.trip_item.executeRequest(
          executeParams,
          () => {
            this.getListApplications();
          },
          err => {
            this.endLoading();
            this.notifyError(this.$t(err.error), true);
          },
      );
      this.isRejectModalVisible = false;
    },

    //Handle event Suspend Ringi Modal
    closeSuspendRingiModal() {
      this.isSuspendRingi = false;
    },
    submitSuspendRingiModal(suspendMsg = '') {
      this.startLoading();
      const executeParams = {
        request_id: this.requestId,
        status_type: this.requestType,
        approval_status: HOLD,
        comment: suspendMsg,
        current_step: this.requestDetail.current_step,
        updated_at: this.requestDetail.updated_at,
      };
      this.$services.trip_item.executeRequest(
          executeParams,
          () => {
            let holdIds = JSON.parse(localStorage.getItem('hoild_ids')) || [];
            holdIds.push(this.requestId)
            localStorage.setItem('hoild_ids', JSON.stringify(holdIds));
            this.getListApplications();
          },
          err => {
            this.endLoading();
            this.notifyError(this.$t(err.error), true);
          },
      );
      this.isSuspendRingi = false;
    },
    //Remove hold request status
    removeHoldStatus(removeSuspendMsg = '') {
      this.startLoading();
      const executeParams = {
        request_id: this.requestId,
        status_type: this.requestType,
        approval_status: REMOVE_HOLD,
        comment: removeSuspendMsg,
        current_step: this.requestDetail.current_step,
        updated_at: this.requestDetail.updated_at,
      };
      this.$services.trip_item.executeRequest(
          executeParams,
          () => {
            this.endLoading();
            this.getRequestDetail();
            this.getRequestHistories();
            this.notifySuccess(this.$t('trip_item.msg_remove_hold_success'));
          },
          err => {
            this.endLoading();
            this.notifyError(this.$t(err.error), true);
          },
      );
      this.isSuspendRingi = false;
    },

    listenShowDetail(isShow) {
      this.showTripProcessDetail = isShow;
    },

    //Handle show action from child button components
    async showHistory() {
      this.startLoading()
      await this.getRequestHistories();
      this.endLoading();
      this.isHistoryModalVisible = true;
    },
    showApproveModal() {
      this.isApproveModalVisible = true;
    },
    showCancelModal() {
      this.showBoxCancel = true;
    },
    submitCancelRequest(comment) {
      if (this.requestId) {
        this.startLoading();
        this.$services.trip.cancelRingi(
            this.requestId + '?comment=' + comment,
            () => {
              this.endLoading();
              this.showBoxCancel = false;
              this.$router.push(this.PAGES.APPLICATION);
            },
            err => {
              this.endLoading();
              this.notifyError(this.$t(err.error));
            },
        );
      }
    },
    showSuspendRingi() {
      this.isSuspendRingi = true;
    },
    showRejectModal() {
      this.isRejectModalVisible = true;
    },
    //CU-094
    async getRequestDetail() {
      const params = {
        id: this.requestId,
        type: this.requestType,
      };
      await this.$services.trip_item.getRequestDetail(
          params,
          res => {
            this.canApproverSubmit = false;
            this.requestDetail = res;
          },
          err => {
            this.notifyError(this.$t(err.error));
            this.$router.push(this.PAGES.APPLICATION);
          },
      );
    },
    async getRequestDetailOther(requestIdOther, requestTypeOther) {
      const params = {
        id: requestIdOther,
        type: requestTypeOther,
      };
      await this.$services.trip_item.getRequestDetail(
          params,
          res => {
            this.canApproverSubmit = false;
            this.requestDetail = res;
          },
          err => {
            this.notifyError(this.$t(err.error));
            this.$router.push(this.PAGES.APPLICATION);
          },
      );
    },
    //CU-084
    async getRequestHistories() {
      const requestId = this.requestId;
      await this.$services.trip_item.getRequestHistories(
          requestId,
          res => {
            this.requestHistories = res.list;
          },
          err => {
            this.notifyError(this.$t(err.error));
          },
      );
    },
  },
};
</script>
<style scoped></style>
