<template>
  <div class="">
    <el-dialog
        title="Tips"
        v-model="dialogVisibleStatus"
        @close="onCloseModal"
        width="736px"
        :show-close="true"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <button type="button" class="close" @click="onCloseModal()">
            <span class="icon-abui-cross" aria-hidden="true"></span>
          </button>
          <div class="modal-header" style="display: flex; justify-content: space-between">
            <div class="modal-header--title" style="width: 100%">{{ $t('schedule.schedule') }}</div>
            <button
                v-if="!flagCreate && !isRequestPage && !checkCustomDisable"
                class="btn btn-sm btn-flat-default"
                @click="confirmDelete()"
                :disabled="checkCustomDisable"
            >
              <i class="icon-abui-trash prx"></i>{{ $t('schedule.delete') }}
            </button>
          </div>
          <div class="modal-body">
            <ul
                v-show="(isTripApproval && !isActionRingi) || tripStatus == EXPENSE_SETTLEMENT"
                class="nav nav-tabs"
                role="tablist"
            >
              <li :class="[tab && 'active']">
                <a @click="chooseTab1()" class="cursor-pointer" role="tab">
                  {{ $t('schedule.detailed_information') }}
                </a>
              </li>
              <li
                  v-if="(tripStatus == REPORT_STATUS || tripStatus == EXPENSE_SETTLEMENT) && !flagCreate"
                  :class="[!tab && 'active']"
              >
                <a @click="chooseTab2()" class="cursor-pointer" role="tab">{{ $t('schedule.report') }}</a>
              </li>
            </ul>
            <div class="tab-content">
              <div class="tab-pane fade" :class="[tab && 'active in']">
                <div class="row">
                  <div class="col-sm-12">
                    <div>
                      <el-checkbox
                          class="checkbox-display"
                          :disabled="(isTripApprovalDisable || checkCustomDisable) && 'disabled'"
                          v-model="dataRequest.approval_status_id"
                          size="large"
                      >
                        {{ $t('schedule.approval') }}
                      </el-checkbox>
                    </div>
                  </div>
                </div>
                <div class="row" v-if="![1, 2, 6].includes(dataRequest.type_id)">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label>
                        <span class="label-text">{{ $t('schedule.title') }}</span>
                      </label>
                      <input
                          maxlength="50"
                          v-model="dataRequest.title"
                          type="text"
                          class="form-control input-sm"
                          id="modal--other-schedule-item--title"
                          :disabled="checkCustomDisable"
                      />
                    </div>
                    <p role="alert" class="error-feedback" v-text="errors.title"></p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6 col-md-4">
                    <div class="form-group">
                      <label>
                        <span class="label-text">{{ $t('schedule.type') }}</span>
                      </label>
                      <el-select
                          name="input"
                          class="select-block select-sm js-select_item"
                          popper-class="custom-dropdown-select"
                          v-model="dataRequest.type_id"
                          :disabled="checkCustomDisable || disableOtherPlaneOption"
                      >
                        <el-option-group v-for="group in listType" :key="group.label" :label="group.label">
                          <el-option
                              v-for="item in group.options"
                              @click="selectChangeType = true"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                              :disabled="checkCustomDisable || (disableHotelOption && item.value === 6)"
                          >
                          </el-option>
                        </el-option-group>
                      </el-select>
                    </div>
                  </div>
                </div>

                <div
                    v-if="dataRequest.type_id === 1"
                    class="js-select_item-modal--schedule-item--type__tgt modal--schedule-item--type_1"
                >
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label>
                          <span class="label-text">{{ $t('schedule.flight_number') }}</span>
                        </label>
                        <div class="input-with-icon input-sm">
                          <input
                              maxlength="50"
                              v-model="dataRequest.flight_number"
                              type="text"
                              class="form-control input-sm"
                              :disabled="checkCustomDisable"
                              :onchange="convertFlightNumberFullToHalf(dataRequest.flight_number)"
                          />
                        </div>
                        <p role="alert" class="error-feedback" v-text="errors.flight_number"></p>
                      </div>
                    </div>
                    <div class="col-sm-8">
                      <div class="form-group">
                        <label>
                          <span class="label-text">{{ $t('schedule.direct_transit') }}</span>
                        </label>
                        <label
                            class="radio radio-sm radio-inline"
                            :class="dataRequest.airplane_type == 1 && 'checked'"
                            @click="changeAirplaneType(1)"
                            style="margin-right: 20px !important"
                        >
                          <span class="icons">
                            <span class="first-icon icon-abui-radio-unchecked" />
                            <span class="second-icon icon-abui-radio-checked" />
                          </span>
                          <input type="radio" name="03-03" value="1" checked="" :disabled="checkCustomDisable" />
                          <span class="text_type-special">{{ $t('schedule.direct') }}</span>
                        </label>
                        <label
                            class="radio radio-sm radio-inline"
                            :class="dataRequest.airplane_type == 2 && 'checked'"
                            @click="changeAirplaneType(2)"
                        >
                          <span class="icons">
                            <span class="first-icon icon-abui-radio-unchecked" />
                            <span class="second-icon icon-abui-radio-checked" />
                          </span>
                          <input type="radio" name="03-03" value="2" checked="" :disabled="checkCustomDisable" />
                          <span class="text_type-special">{{ $t('schedule.connecting') }}</span>
                        </label>
                        <span v-if="this.dataRequest.airplane_type == 2">
                          <span style="vertical-align: middle">&nbsp;&#40;&nbsp;</span>
                          <label
                              class="radio radio-sm radio-inline"
                              :class="dataRequest.transit_airplane_type == 1 && 'checked'"
                              @click="changeTransitAirplaneType(1)"
                          >
                            <span class="icons">
                              <span class="first-icon icon-abui-radio-unchecked" />
                              <span class="second-icon icon-abui-radio-checked" />
                            </span>
                            <input type="radio" name="03-03" value="1" checked="" :disabled="checkCustomDisable" />
                            <span class="text_type-special">{{ $t('schedule.departure_transit') }}</span>
                          </label>
                          &nbsp;
                          <label
                              class="radio radio-sm radio-inline"
                              :class="dataRequest.transit_airplane_type == 2 && 'checked'"
                              @click="changeTransitAirplaneType(2)"
                          >
                            <span class="icons">
                              <span class="first-icon icon-abui-radio-unchecked" />
                              <span class="second-icon icon-abui-radio-checked" />
                            </span>
                            <input type="radio" name="03-03" value="2" checked="" :disabled="checkCustomDisable" />
                            <span class="text_type-special">{{ $t('schedule.arrival_transit') }}</span>
                          </label>
                          <span style="vertical-align: middle">&nbsp;&#41;&nbsp;</span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="form-group">
                        <!-- 予約クラス -->
                        <label>
                          <span class="label-text">{{ $t('input.booking_class') }}</span>
                        </label>
                        <div class="btn-group bootstrap-select select-block select-sm">
                          <el-select
                              :disabled="isDisabled || checkCustomDisable"
                              v-model="dataRequest.seat_class_type"
                              popper-class="custom-dropdown-select"
                              class="select-block select-sm"
                          >
                            <el-option
                                v-for="item in flightClass"
                                :key="item.id"
                                :label="labelLocale(item)"
                                :value="item.id"
                            >
                            </el-option>
                          </el-select>
                        </div>
                        <p role="alert" class="error-feedback" v-text="errors.seat_class_type"></p>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label>
                          <span class="label-text">{{ $t('input.seat_number') }}</span>
                        </label>
                        <div class="input-with-icon input-sm">
                          <input
                              maxlength="10"
                              :disabled="isDisabled || checkCustomDisable"
                              :value="dataRequest.seat_number"
                              @input="dataRequest.seat_number = $event.target.value.toUpperCase()"
                              type="text"
                              class="form-control input-sm"
                          />
                        </div>
                        <p role="alert" class="error-feedback" v-text="errors.seat_number"></p>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="form-group form-group-typeahead">
                        <label>
                          <span class="label-text">{{ $t('schedule.departure_location') }}</span>
                        </label>
                        <div class="input-with-icon input-sm select-input">
                          <el-autocomplete
                              style="width: 100%"
                              class="select-block select-sm"
                              v-model="dataRequest.departure_location_id_name"
                              :fetch-suggestions="querySearch"
                              :placeholder="$t('common.city_or_airport_required')"
                              :trigger-on-focus="false"
                              :disabled="checkCustomDisable"
                              @select="handleSelectDeparture"
                          >
                          </el-autocomplete>
                          <span class="input-icon-typeahead icon-abui-pencil"></span>
                        </div>
                        <p role="alert" class="error-feedback" v-text="errors.departure_location"></p>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label>
                          <span class="label-text">{{ $t('schedule.start_date') }}</span>
                        </label>
                        <div class="input-with-icon">
                          <el-date-picker
                              class="date-input"
                              v-model="dataRequest.start_date"
                              :clearable="false"
                              type="date"
                              format="YYYY/MM/DD"
                              value-format="YYYY/MM/DD"
                              :placeholder="$t('common.enter_or_select_required')"
                              :disabled-date="pickerFromOptionsTypePlane"
                              :disabled="checkCustomDisable"
                          />
                        </div>
                        <p role="alert" class="error-feedback" v-text="errors.start_date"></p>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label>
                          <span class="label-text">{{ $t('schedule.start_time') }}</span>
                        </label>
                        <div class="input-with-icon">
                          <el-input
                              :disabled="checkCustomDisable"
                              v-model="dataRequest.start_time"
                              placeholder="00:00"
                              @blur="checkStartTime('start_time')"
                          >
                            <template #suffix>
                              <el-icon class="el-input__icon"><clock /></el-icon>
                            </template>
                          </el-input>
                        </div>
                        <p role="alert" class="error-feedback" v-text="errors.start_time"></p>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="form-group form-group-typeahead">
                        <label>
                          <span class="label-text">{{ $t('schedule.arrival_location') }}</span>
                        </label>
                        <div class="input-with-icon input-sm select-input">
                          <el-autocomplete
                              style="width: 100%"
                              class="select-block select-sm"
                              v-model="dataRequest.arrival_location_id_name"
                              :fetch-suggestions="querySearch"
                              :placeholder="$t('common.city_or_airport_required')"
                              :trigger-on-focus="false"
                              :disabled="checkCustomDisable"
                              @select="handleSelectArrival"
                          >
                          </el-autocomplete>
                          <span class="input-icon-typeahead icon-abui-pencil"></span>
                        </div>
                        <p role="alert" class="error-feedback" v-text="errors.arrival_location"></p>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label>
                          <span class="label-text">{{ $t('schedule.end_date') }}</span>
                        </label>
                        <div class="input-with-icon">
                          <el-date-picker
                              class="date-input"
                              v-model="dataRequest.end_date"
                              :clearable="false"
                              type="date"
                              format="YYYY/MM/DD"
                              value-format="YYYY/MM/DD"
                              :placeholder="$t('common.enter_or_select_required')"
                              :disabled-date="pickerToOptionsTypePlane"
                              :disabled="checkCustomDisable"
                          />
                        </div>
                        <p role="alert" class="error-feedback" v-text="errors.end_date"></p>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label>
                          <span class="label-text">{{ $t('schedule.end_time') }}</span>
                        </label>
                        <div class="input-with-icon">
                          <el-input
                              :disabled="checkCustomDisable"
                              v-model="dataRequest.end_time"
                              placeholder="00:00"
                              @blur="checkStartTime('end_time')"
                          >
                            <template #suffix>
                              <el-icon class="el-input__icon"><clock /></el-icon>
                            </template>
                          </el-input>
                        </div>
                        <p role="alert" class="error-feedback" v-text="errors.end_time"></p>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                    class="js-select_item-modal--schedule-item--type__tgt modal--schedule-item--type_2"
                    v-if="dataRequest.type_id === 2"
                >
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label>
                          <span class="label-text">{{ $t('schedule.train_number') }}</span>
                        </label>
                        <div class="input-with-icon input-sm">
                          <input
                              maxlength="50"
                              v-model="dataRequest.train_number"
                              type="text"
                              class="form-control input-sm"
                              :disabled="checkCustomDisable"
                          />
                        </div>
                        <p role="alert" class="error-feedback" v-text="errors.train_number"></p>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label>
                          <span class="label-text">{{ $t('input.seat_number') }}</span>
                        </label>
                        <div class="input-with-icon input-sm">
                          <input
                              maxlength="10"
                              v-model="dataRequest.seat_number"
                              type="text"
                              class="form-control input-sm"
                              :disabled="checkCustomDisable"
                          />
                        </div>
                        <p role="alert" class="error-feedback" v-text="errors.seat_number"></p>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="form-group form-group-typeahead">
                        <label>
                          <span class="label-text">{{ $t('schedule.departure_station') }}</span>
                        </label>
                        <div class="input-sm">
                          <input
                              maxlength="100"
                              v-model="dataRequest.departure_station"
                              :disabled="checkCustomDisable"
                              type="text"
                              class="form-control input-sm"
                              :placeholder="$t('common.city_or_train_station_required')"
                          />
                          <p role="alert" class="error-feedback" v-text="errors.departure_station"></p>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label>
                          <span class="label-text">{{ $t('schedule.start_date') }}</span>
                        </label>
                        <div class="input-with-icon">
                          <el-date-picker
                              v-model="dataRequest.start_date"
                              type="text"
                              class="date-input"
                              :placeholder="$t('common.enter_or_select_required')"
                              :clearable="false"
                              value-format="YYYY/MM/DD"
                              format="YYYY/MM/DD"
                              :disabled-date="pickerFromOptions"
                              :disabled="checkCustomDisable"
                          />
                        </div>
                        <p role="alert" class="error-feedback" v-text="errors.start_date"></p>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label>
                          <span class="label-text">{{ $t('schedule.start_time') }}</span>
                        </label>
                        <div class="input-with-icon">
                          <el-input
                              :disabled="checkCustomDisable"
                              v-model="dataRequest.start_time"
                              placeholder="00:00"
                              @blur="checkStartTime('start_time')"
                          >
                            <template #suffix>
                              <el-icon class="el-input__icon"><clock /></el-icon>
                            </template>
                          </el-input>
                        </div>
                        <p role="alert" class="error-feedback" v-text="errors.start_time"></p>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="form-group form-group-typeahead">
                        <label>
                          <span class="label-text">{{ $t('schedule.arrival_station') }}</span>
                        </label>
                        <div class="input-sm">
                          <input
                              maxlength="100"
                              v-model="dataRequest.arrival_station"
                              @blur="updateTimeZoneTrain(dataRequest.arrival_station)"
                              :disabled="checkCustomDisable"
                              type="text"
                              class="form-control input-sm"
                              :placeholder="$t('common.city_or_train_station_required')"
                          />
                          <p role="alert" class="error-feedback" v-text="errors.arrival_station"></p>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label>
                          <span class="label-text">{{ $t('schedule.end_date') }}</span>
                        </label>
                        <div class="input-with-icon">
                          <el-date-picker
                              v-model="dataRequest.end_date"
                              type="date"
                              class="date-input"
                              :placeholder="$t('common.enter_or_select_required')"
                              value-format="YYYY/MM/DD"
                              :clearable="false"
                              format="YYYY/MM/DD"
                              :disabled-date="pickerToOptions"
                              :disabled="checkCustomDisable"
                          />
                        </div>
                        <p role="alert" class="error-feedback" v-text="errors.end_date"></p>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label>
                          <span class="label-text">{{ $t('schedule.end_time') }}</span>
                        </label>
                        <div class="input-with-icon">
                          <el-input
                              :disabled="checkCustomDisable"
                              v-model="dataRequest.end_time"
                              placeholder="00:00"
                              @blur="checkStartTime('end_time')"
                          >
                            <template #suffix>
                              <el-icon class="el-input__icon"><clock /></el-icon>
                            </template>
                          </el-input>
                        </div>
                        <p role="alert" class="error-feedback" v-text="errors.end_time"></p>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                    class="js-select_item-modal--schedule-item--type__tgt modal--schedule-item--type_6"
                    v-if="dataRequest.type_id === 6"
                >
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="form-group">
                        <label>
                          <span class="label-text">{{ $t('schedule.hotel_name') }}</span>
                        </label>
                        <div class="input-with-icon input-sm">
                          <input
                              maxlength="300"
                              v-model="dataRequest.hotel_name"
                              type="text"
                              class="form-control input-sm"
                              :disabled="checkCustomDisable"
                          />
                        </div>
                        <p role="alert" class="error-feedback" v-text="errors.hotel_name"></p>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="form-group">
                        <label>
                          <span class="label-text">{{ $t('input.room') }}</span>
                        </label>
                        <div class="input-with-icon input-sm">
                          <input
                              maxlength="100"
                              v-model="dataRequest.hotel_room"
                              type="text"
                              class="form-control input-sm"
                              :disabled="checkCustomDisable"
                          />
                        </div>
                        <p role="alert" class="error-feedback" v-text="errors.hotel_room"></p>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label>
                          <span class="label-text">{{ $t('schedule.checkin_time') }}</span>
                        </label>
                        <div class="input-with-icon">
                          <el-date-picker
                              class="date-input"
                              v-model="dataRequest.checkin_time"
                              :clearable="false"
                              type="date"
                              format="YYYY/MM/DD"
                              value-format="YYYY/MM/DD"
                              :placeholder="$t('common.enter_or_select_required')"
                              :disabled-date="pickerFromOptionsCheckInTime"
                              :disabled="checkCustomDisable"
                          />
                        </div>
                        <p role="alert" class="error-feedback" v-text="errors.checkin_time"></p>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label>
                          <span class="label-text">{{ $t('schedule.checkout_time') }}</span>
                        </label>
                        <div class="input-with-icon">
                          <el-date-picker
                              class="date-input"
                              v-model="dataRequest.checkout_time"
                              :clearable="false"
                              type="date"
                              format="YYYY/MM/DD"
                              value-format="YYYY/MM/DD"
                              :placeholder="$t('common.enter_or_select_required')"
                              :disabled-date="pickerToOptionsCheckOutTime"
                              :disabled="checkCustomDisable"
                          />
                        </div>
                        <p role="alert" class="error-feedback" v-text="errors.checkout_time"></p>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                    v-if="dataRequest.type_id !== 1"
                    class="js-select_item-modal--schedule-item--type__tgt modal--schedule-item--type_2 modal--schedule-item--type_3 modal--schedule-item--type_4 modal--schedule-item--type_6 modal--schedule-item--type_5 modal--schedule-item--type_7 modal--schedule-item--type_8 modal--schedule-item--type_9 modal--schedule-item--type_10 modal--schedule-item--type_11 modal--schedule-item--type_12 modal--schedule-item--type_13 modal--schedule-item--type_14 modal--schedule-item--type_15 modal--schedule-item--type_16 modal--schedule-item--type_17"
                >
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="form-group">
                        <label>
                          <span class="label-text">{{ $t('schedule.time_zone') }}</span>
                        </label>
                        <el-select
                            v-model="dataRequest.time_zone_id"
                            name="input"
                            popper-class="custom-dropdown-select"
                            class="select-block select-sm"
                            :disabled="checkCustomDisable"
                            :placeholder="$t('common.not_specified')"
                        >
                          <el-option
                              v-for="item in optionsUTC"
                              :key="item.id"
                              :label="this.labelLocale(item)"
                              :value="item.id"
                              :disabled="checkCustomDisable"
                          >
                          </el-option>
                        </el-select>
                        <p role="alert" class="error-feedback" v-text="errors.time_zone"></p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                    v-if="![1, 2].includes(dataRequest.type_id)"
                    class="js-select_item-modal--schedule-item--type__tgt modal--schedule-item--type_3 modal--schedule-item--type_4 modal--schedule-item--type_6 modal--schedule-item--type_5 modal--schedule-item--type_7 modal--schedule-item--type_8 modal--schedule-item--type_9 modal--schedule-item--type_10 modal--schedule-item--type_11 modal--schedule-item--type_12 modal--schedule-item--type_13 modal--schedule-item--type_14 modal--schedule-item--type_15 modal--schedule-item--type_16 modal--schedule-item--type_17"
                >
                  <div class="row">
                    <div class="col-sm-12">
                      <label>
                        <span class="label-text">{{ $t('schedule.location_string') }}</span>
                      </label>
                      <div>
                        <input
                            maxlength="100"
                            v-model="dataRequest.location_string"
                            type="text"
                            @blur="updateTimeZone(dataRequest.location_string)"
                            class="form-control input-sm"
                            :placeholder="$t('common.address_etc')"
                            :disabled="checkCustomDisable"
                        />
                      </div>
                      <p role="alert" class="error-feedback" v-text="errors.location_string"></p>
                    </div>
                  </div>
                  <div v-if="dataRequest.type_id !== 6" class="row">
                    <div class="col-sm-6 col-md-4">
                      <div class="form-group">
                        <label>
                          <span class="label-text">{{ $t('schedule.date') }}</span>
                        </label>
                        <div class="input-with-icon">
                          <el-date-picker
                              class="date-input"
                              v-model="dataRequest.start_date"
                              :clearable="false"
                              type="date"
                              format="YYYY/MM/DD"
                              value-format="YYYY/MM/DD"
                              :placeholder="$t('common.enter_or_select_required')"
                              :disabled-date="pickerFromOptions"
                              :disabled="checkCustomDisable"
                          />
                        </div>
                        <p role="alert" class="error-feedback" v-text="errors.date"></p>
                      </div>
                    </div>
                    <div
                        v-if="!checkedMultipleDays"
                        class="col-sm-3 col-md-2 js-check_item_other-schedule-item--multiple-date__tgt__hide"
                    >
                      <div class="form-group">
                        <label>
                          <span class="label-text">{{ $t('schedule.time') }}</span>
                        </label>
                        <el-input
                            :disabled="checkCustomDisable"
                            v-model="dataRequest.start_time"
                            placeholder="00:00"
                            @blur="checkStartTime('start_time')"
                        >
                          <template #suffix>
                            <el-icon class="el-input__icon"><clock /></el-icon>
                          </template>
                        </el-input>
                      </div>
                      <p role="alert" class="error-feedback" v-text="errors.time"></p>
                    </div>

                    <div class="col-sm-3">
                      <div class="form-group">
                        <label><span class="label-text">&ensp;</span></label>
                        <label
                            class="checkbox checkbox-sm checkbox-inline js-check_item"
                            id="other-schedule-item--multiple-date"
                        >
                          <el-checkbox
                              class="checkbox-display"
                              v-model="checkedMultipleDays"
                              size="large"
                              :disabled="checkCustomDisable && 'disabled'"
                          >
                            {{ $t('schedule.multiple_days') }}
                          </el-checkbox>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div
                      v-if="checkedMultipleDays && dataRequest.type_id !== 6"
                      class="row js-check_item_other-schedule-item--multiple-date__tgt"
                  >
                    <div class="col-sm-6 col-md-4">
                      <div class="form-group">
                        <label>
                          <span class="label-text">{{ $t('schedule.date_end') }}</span>
                        </label>
                        <div class="input-with-icon">
                          <el-date-picker
                              class="date-input"
                              v-model="dataRequest.end_date"
                              :clearable="false"
                              type="date"
                              format="YYYY/MM/DD"
                              value-format="YYYY/MM/DD"
                              :placeholder="$t('common.enter_or_select_required')"
                              :disabled-date="pickerToOptions"
                              :disabled="checkCustomDisable"
                          />
                        </div>
                        <p role="alert" class="error-feedback" v-text="errors.date_end"></p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row" style="margin-bottom: 12px">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label>
                        <span class="label-text">{{ $t('schedule.web_page') }}</span>
                      </label>
                      <input
                          v-if="isEditingWebpage || checkEmpty(dataRequest.web_page)"
                          ref="webpage_compo"
                          maxlength="100"
                          v-model="dataRequest.web_page"
                          type="text"
                          class="form-control input-sm webpage_compo"
                          :placeholder="$t('common.url')"
                          :disabled="checkCustomDisable"
                          @focusout="handleFocusoutUrl()"
                          @focus="handleFocusUrl()"
                      />
                      <span v-else>
                        <a :href="dataRequest.web_page" target="_blank">
                          {{ dataRequest.web_page }}
                        </a>
                      </span>
                      <span
                          class="input-icon icon-abui-pencil cursor-pointer"
                          @click="handleShowWebpage()"
                          style="float: right; padding-top: 7px"
                      ></span>
                    </div>
                    <p role="alert" class="error-feedback" v-text="errors.web_page"></p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label>
                        <span class="label-text">{{ $t('schedule.note') }}</span>
                      </label>
                      <textarea
                          :disabled="checkCustomDisable"
                          maxlength="500"
                          v-model="dataRequest.note"
                          class="form-control max-textarea"
                          rows="3"
                      >
                      </textarea>
                    </div>
                    <p role="alert" class="error-feedback" v-text="errors.note"></p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6 col-all-closer col-all-closer--leftend">
                    <div class="form-group mbs" style="margin-bottom: 20px !important">
                      <label>
                        <span class="label-text">{{ $t('schedule.cost') }}</span>
                      </label>
                      <label
                          class="radio radio-sm radio-inline"
                          :class="radioData == '1' && 'checked'"
                          @click="changeRadioData('1')"
                          style="margin-right: 20px !important"
                      >
                        <span class="icons">
                          <span class="first-icon icon-abui-radio-unchecked" />
                          <span class="second-icon icon-abui-radio-checked" />
                        </span>
                        <input type="radio" name="03-03" value="1" checked="" :disabled="checkCustomDisable" />
                        <span class="text_type-special">{{ $t('schedule.enter_amount') }}</span>
                      </label>
                      <label
                          class="radio radio-sm radio-inline"
                          :class="radioData == '2' && 'checked'"
                          @click="changeRadioData('2')"
                      >
                        <span class="icons">
                          <span class="first-icon icon-abui-radio-unchecked" />
                          <span class="second-icon icon-abui-radio-checked" />
                        </span>
                        <input type="radio" name="03-03" value="2" checked="" :disabled="checkCustomDisable" />
                        <span class="text_type-special">{{ $t('schedule.link_cost') }}</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row mbl">
                  <div class="col-sm-12">
                    <div
                        v-if="radioData == '1'"
                        class="form-group js-radio_item-radio-input-expense__tgt radio-input-expense_1"
                    >
                      <div class="input-group">
                        <span class="input-group-addon">{{ $t('common.money') }}</span>
                        <el-input
                            :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                            :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                            onkeydown="javascript: return event.keyCode !== 69;"
                            maxlength="14"
                            v-model="dataRequest.cost_amount"
                            @input="dataRequest.cost_amount = convertNumberFullToHalf(dataRequest.cost_amount)"
                            @change="convertData(dataRequest.cost_amount)"
                            :disabled="checkCustomDisable"
                        />
                      </div>
                    </div>
                    <div v-else class="form-group js-radio_item-radio-input-expense__tgt radio-input-expense_2">
                      <MultiOptionSelect
                          ref="CostsSelect"
                          :listData="optionCost"
                          :default-list="defaultCostsData"
                          :displayByLocale="false"
                          labelShow="title"
                          :is-disabled="checkCustomDisable"
                          @handle-selected-params="listenCosts"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label>
                        <span class="label-text">{{ $t('schedule.clips') }}</span>
                      </label>
                      <MultiOptionSelect
                          ref="ClipsSelect"
                          :listData="optionClip"
                          :default-list="defaultClipsData"
                          :displayByLocale="false"
                          labelShow="title"
                          :is-disabled="checkCustomDisable"
                          @handle-selected-params="listenClips"
                      />
                    </div>
                  </div>
                </div>

                <div class="mbm mtl" v-if="dataRequest.input_file">
                  <ul class="uploaded--file-list" style="">
                    <li style="">
                      <a
                          :href="dataRequest.input_file?.file_path"
                          target="_blank"
                          style="display: flex; align-items: center"
                          rel="noopener"
                          :class="checkCustomDisable && 'disabled'"
                      >
                        <i class="icon-abui-file"></i>
                        <span class="file--name">{{ dataRequest.input_file.file_name }}</span>
                      </a>
                      <span class="file--date text-left"></span>
                    </li>
                  </ul>
                </div>

                <div style="padding: 16px 0 32px">
                  <div class="row">
                    <div class="col-xs-6 col-sm-6 col-md-6 col-xs-offset-6 col-sm-offset-6 col-md-offset-6">
                      <button
                          v-show="!isActionRingi"
                          class="btn btn-sm btn-inverse-primary btn-block js-btn--close-modal-schedule-item"
                          id="js-btn--save-other-schedule-item"
                          @click="submitData()"
                          :disabled="checkCustomDisable"
                      >
                        {{ $t('schedule.submit') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" :class="[!tab && 'active in']">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <textarea
                          :disabled="checkCustomDisable || !isReportEdit"
                          class="form-control max-textarea"
                          rows="8"
                          maxlength="10000"
                          v-model="dataReport.remarks"
                      />
                    </div>
                    <p role="alert" class="error-feedback" v-text="errors.remarks"></p>
                  </div>
                </div>
                <div class="row">
                  <div style="padding: 0 12px">
                    <div class="info-list-file">
                      <ul class="trip--info--file-list">
                        <li v-for="item in listFile" :key="item.id">
                          <a :href="item.file_path" target="_blank">
                            <i class="icon-abui-file" /><span class="file-name">{{ item.name }}</span>
                            <span class="file--date"> </span>
                          </a>
                          <i
                              style="padding-right: 14px; color: brown; font-weight: bold"
                              class="icon-abui-cross pls cursor-pointer"
                              @click="handleRemoveReportFile(item.id)"
                          ></i>
                        </li>
                        <li v-for="(item, index) in listFileUpdate" :key="index">
                          <a :href="item.link" target="_blank">
                            <i class="icon-abui-file icon-file-new" /><span class="file-name-new">{{ item.name }}</span>
                            <span class="file--date"> </span>
                          </a>
                          <i
                              style="padding-right: 14px; color: brown; font-weight: bold"
                              class="icon-abui-cross pls cursor-pointer"
                              @click="handleRemoveReportFile(item.name, 2)"
                          ></i>
                        </li>
                      </ul>
                    </div>
                    <div class="form-group">
                      <label>
                        <span class="label-text">{{ $t('schedule.attachment') }}</span>
                      </label>
                      <button
                          type="button"
                          class="btn btn-inverse btn-sm"
                          :disabled="checkCustomDisable || !isReportEdit"
                          @click="showModalUploadFile = true"
                      >
                        <i class="icon-abui-upload prx"></i>{{ $t('schedule.upload') }}
                      </button>
                    </div>
                  </div>
                </div>
                <div style="padding: 16px 0 32px">
                  <div class="row" v-if="dataReport.id">
                    <div class="col-xs-6 col-sm-6 col-md-6 ">
                      <button
                          class="btn btn-sm btn-flat-danger btn-block js-btn--close-modal-schedule-item btn-update-report"
                          id="js-btn--save-other-schedule-item"
                          :disabled="checkCustomDisable || !isReportEdit"
                          @click="confirmDeleteReport()"
                      >
                        {{ $t('schedule.delete_report') }}
                      </button>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-6">
                      <button
                          class="btn btn-sm btn-inverse-primary btn-block js-btn--close-modal-schedule-item btn-update-report"
                          id="js-btn--save-other-schedule-item"
                          :disabled="checkCustomDisable || !isReportEdit"
                          @click="saveReport()"
                      >
                        {{ $t('schedule.save_report') }}
                      </button>
                    </div>
                  </div>
                  <div class="row" v-else>
                    <div class="col-xs-6 col-sm-6 col-md-6 col-xs-offset-6 col-sm-offset-6 col-md-offset-6">
                      <button
                          class="btn btn-sm btn-inverse-primary btn-block js-btn--close-modal-schedule-item btn-update-report"
                          id="js-btn--save-other-schedule-item"
                          :disabled="checkCustomDisable || !isReportEdit"
                          @click="saveReport()"
                      >
                        {{ $t('schedule.save_report') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <ModalConfirm
        :dialogVisible="showBoxConfirm"
        @cancel="showBoxConfirm = false"
        @yes="deleteSchedule()"
        :title="$t('Q-CM-104')"
    />
    <ModalConfirm
        :dialogVisible="showBoxConfirmReport"
        @cancel="showBoxConfirmReport = false"
        @yes="deleteReport()"
        :title="$t('Q-CM-104')"
    />
    <ModalUploadFile
        :dialogVisible="showModalUploadFile"
        @cancel="showModalUploadFile = false"
        @confirm="confirmUploadFile"
        :title="$t('common.title_modal_upload_file_csv')"
    />
  </div>
</template>
<script>
import moment from 'moment';
import { ElDialog } from 'element-plus';
import ModalConfirm from '@/components/common/ModalConfirm';
import ModalUploadFile from '@/components/common/ModalUploadFile';
import MultiOptionSelect from '@/components/common/MultiOptionSelect';
import { REPORT_STATUS, EXPENSE_SETTLEMENT } from '@/constants/';
import mixins from '@/helpers/mixins';
import { FLIGHT_CLASS } from '@/constants/';

export default {
  components: {
    ElDialog,
    ModalConfirm,
    MultiOptionSelect,
    ModalUploadFile,
  },
  name: 'DialogSchedule',
  mixins: [mixins],
  emits: ['yes', 'cancel'],
  props: {
    isActionRingi: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    reloadData: {
      type: Boolean,
      default: false,
    },
    isTripApproval: {
      type: Boolean,
      default: false,
    },
    isReportEdit: {
      type: Boolean,
      default: false,
    },
    disableHotelOption: {
      type: Boolean,
      default: false,
    },
    disableOtherPlaneOption: {
      type: Boolean,
      default: false,
    },
    isTripApprovalDisable: {
      type: Boolean,
      default: false,
    },
    scheduleId: {
      type: Number,
      default: 0,
    },
    timeZoneBefore: {
      type: [Number, String],
      default: '',
    },
    tripDate: {
      type: Object,
      default: () => {},
    },
    dateDefault: {
      type: String,
      default: '',
    },
    scheduleData: {
      type: Object,
      default: () => {},
    },
    tripStatus: {
      type: Number,
      default: 0,
    },
    tripIdFromParent: {
      type: Number,
      default: 0,
    },
    isActionCreate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectChangeType: false,
      customPrefix: {},
      isEditingWebpage: false,
      listFile: [],
      listFileUpdate: [],
      showModalUploadFile: false,
      showBoxConfirm: false,
      showBoxConfirmReport: false,
      tab: true,
      optionsUTC: [],
      listType: [],
      deletedFiles: [],
      scheduleTypeList: [],
      dataReport: {
        id: '',
        remarks: '',
      },
      dataRequest: {
        id: '',
        input_id: '',
        trip_id: this.$route.params.id,
        title: '',
        approval_status_id: true,
        type_id: 1,
        time_zone_id: '',
        flight_number: '',
        seat_class_type: 3,
        seat_number: '',
        train_number: '',
        airplane_type: 1,
        transit_airplane_type: 1,
        location_string: '',
        start_date: this.dateDefault,
        end_date: '',
        departure_location_id: '',
        arrival_location_id: '',
        departure_station: '',
        arrival_station: '',
        start_time: '12:00',
        end_time: '12:00',
        web_page: '',
        note: '',
        hotel_name: '',
        hotel_room: '',
        checkin_time: '',
        checkout_time: '',
        cost_amount: '',
        input_file: '',
        cost_ids: [],
        clip_ids: [],
        updated_at: '',
      },
      errors: {
        title: '',
        flight_number: '',
        train_number: '',
        seat_number: '',
        seat_class_type: '',
        departure_location: '',
        arrival_location: '',
        departure_station: '',
        arrival_station: '',
        location_string: '',
        web_page: '',
        start_date: '',
        end_date: '',
        start_time: '',
        end_time: '',
        time: '',
        date: '',
        date_end: '',
        note: '',
        checkin_time: '',
        checkout_time: '',
        hotel_name: '',
        hotel_room: '',
        remarks: '',
        api_res: '',
        time_zone: '',
      },
      checkedMultipleDays: false,
      modalEdit: false,
      innerValue: false,
      radioData: '1',
      linkCosts: ['99'],
      linkClips: [],
      optionClip: [],
      optionCost: [],
      links: [],
      listAirport: [],
      listLabel: this.$i18n.locale == 'ja' ? ['移動', '宿泊', '仕事', 'その他'] : ['Transportation', 'Accommodation', 'Work', 'Other'],
      flightClass: FLIGHT_CLASS,
      dialogVisibleStatus: false,
      flagCreate: true,
      REPORT_STATUS,
      EXPENSE_SETTLEMENT,
    };
  },
  computed: {
    isRequestPage() {
      return window.location.pathname.includes("/request/");
    },
    defaultCostsData() {
      return this.dataRequest.cost_ids;
    },
    defaultClipsData() {
      return this.dataRequest.clip_ids;
    },
    checkCustomDisable() {
      //Disable input if embedded from request component
      return this.isActionRingi;
    },
    tripId() {
      if (!this.checkEmpty(this.tripIdFromParent)) {
        return this.tripIdFromParent;
      } else if (!this.checkEmpty(this.$route.params.trip_id)) {
        return this.$route.params.trip_id;
      } else {
        return this.$route.params?.id;
      }
    },
    estimateCostTitle() {
      if (this.dataRequest.type_id === 6) {
        return `${
            this.dataRequest.checkin_time != this.dataRequest.checkout_time
                ? this.dataRequest.checkin_time + '-' + this.dataRequest.checkout_time
                : this.dataRequest.checkin_time
        } ${this.setDefaultTitle(this.dataRequest.type_id)}`;
      }
      if (this.checkEmpty(this.dataRequest.title))
        return `${
            this.dataRequest.end_date != this.dataRequest.start_date
                ? this.dataRequest.start_date + '-' + this.dataRequest.end_date
                : this.dataRequest.start_date
        } ${this.setDefaultTitle(this.dataRequest.type_id)}`;
      return this.dataRequest.title;
    },
  },
  watch: {
    'scheduleData.report_description'(newVal) {
      this.dataReport.remarks = newVal || '';
    },
    isActionCreate(newVal) {
      if (newVal) this.flagCreate = true;
    },
    tripId() {
      this.getClips();
      this.getCosts();
    },
    visible(newVal) {
      if (newVal) {
        this.listFile = this.cloneObject(this.scheduleData.report_files);
        this.listFileUpdate = [];
        this.selectChangeType = false;
        if (!this.flagCreate) {
          this.initScheduleData();
        } else {
          this.listFile = [];
          this.listFileUpdate = [];
        }
      }
      // if (!this.flagCreate) {
      //   this.validateSeatNumber();
      // } else {
      //   this.errors.seat_number = '';
      // }

      this.dialogVisibleStatus = newVal;
    },
    reloadData() {
      this.getClips();
      this.getCosts();
    },
    dateDefault(date) {
      this.dataRequest.start_date = this.scheduleData.start_date || date;
      this.dataRequest.end_date = this.scheduleData.end_date || date;
    },
    timeZoneBefore(value) {
      this.dataRequest.time_zone_id = value;
    },
    isTripApprovalDisable(value) {
      if (value) {
        this.dataRequest.approval_status_id = false;
      }
    },
    scheduleId(value) {
      // if (value == 0) {
      //   this.resetData(1);
      //   return;
      // }
      if (value) {
        this.initScheduleData();
      } else {
        this.resetData(1);
        this.listFile = [];
        this.listFileUpdate = [];
        this.checkedMultipleDays = false;
        this.flagCreate = true;
        // this.radioData = '1';
        this.tab = true;
      }
    },

    dataReport: {
      handler: function (newValue) {
        if (newValue) {
          delete this.errors.remarks;
        }
        if (this.strLen(newValue.remarks) > 10000) {
          this.errors.remarks = this.$t('E-CM-111', { size: 10000 });
        } else {
          delete this.errors.remarks;
        }
      },
      deep: true,
    },

    dataRequest: {
      handler: function (newValue) {
        if (newValue) {
          delete this.errors.start_date;
          delete this.errors.end_date;
          delete this.errors.flight_number;
          delete this.errors.train_number;
          delete this.errors.departure_location;
          delete this.errors.arrival_location;
          delete this.errors.checkin_time;
          delete this.errors.checkout_time;
          delete this.errors.date;
          delete this.errors.date_end;
          delete this.errors.hotel_name;
          delete this.errors.hotel_room;
          delete this.errors.departure_station;
          delete this.errors.arrival_station;
          delete this.errors.time_zone;
        }
        if (this.strLen(newValue.title) > 50) {
          this.errors.title = this.$t('E-CM-111', { size: 50 });
        } else {
          delete this.errors.title;
        }
        if (this.strLen(newValue.flight_number) > 50) {
          this.errors.flight_number = this.$t('E-CM-111', { size: 50 });
        } else {
          delete this.errors.flight_number;
        }
        if (this.strLen(newValue.departure_station) > 100) {
          this.errors.departure_station = this.$t('E-CM-111', { size: 100 });
        } else {
          delete this.errors.departure_station;
        }
        if (this.strLen(newValue.arrival_station) > 100) {
          this.errors.arrival_station = this.$t('E-CM-111', { size: 100 });
        } else {
          delete this.errors.arrival_station;
        }
        if (this.strLen(newValue.train_number) > 50) {
          this.errors.train_number = this.$t('E-CM-111', { size: 50 });
        } else {
          delete this.errors.train_number;
        }
        if (newValue.location_string && this.strLen(newValue.location_string) > 100) {
          this.errors.location_string = this.$t('E-CM-111', { size: 100 });
        } else {
          delete this.errors.location_string;
        }
        if (this.strLen(newValue.web_page) > 100) {
          this.errors.web_page = this.$t('E-CM-111', { size: 100 });
        } else {
          delete this.errors.web_page;
        }
        if (this.strLen(newValue.note) > 500) {
          this.errors.note = this.$t('E-CM-111', { size: 500 });
        } else {
          delete this.errors.note;
        }
        if (this.strLen(newValue.hotel_name) > 300) {
          this.errors.hotel_name = this.$t('E-CM-111', { size: 300 });
        } else {
          delete this.errors.hotel_name;
        }
        if (newValue.start_date) {
          delete this.errors.start_date;
        }
        if (newValue.type_id === 1 && !this.checkEmpty(newValue.input_id)) {
          if (this.checkEmpty(newValue.flight_number)) {
            this.errors.flight_number = this.$t('schedule.input_error');
          } else {
            delete this.errors.flight_number;
          }
          if (this.checkEmpty(newValue.seat_number)) {
            this.errors.seat_number = this.$t('schedule.input_error');
          } else {
            this.errors.seat_number = '';
          }
          if (this.checkEmpty(newValue.seat_class_type)) {
            this.errors.seat_class_type = this.$t('schedule.input_error');
          } else {
            this.errors.seat_class_type = '';
          }
          if (this.checkEmpty(newValue.departure_location_id_name)) {
            this.errors.departure_location = this.$t('schedule.input_error');
          } else {
            delete this.errors.departure_location;
          }
          if (this.checkEmpty(newValue.arrival_location_id_name)) {
            this.errors.arrival_location = this.$t('schedule.input_error');
          } else {
            delete this.errors.arrival_location;
          }
          if (this.checkEmpty(newValue.start_time)) {
            this.errors.start_time = this.$t('schedule.input_error');
          } else {
            delete this.errors.start_time;
          }
          if (this.checkEmpty(newValue.end_time)) {
            this.errors.end_time = this.$t('schedule.input_error');
          } else {
            delete this.errors.end_time;
          }
        }
        if (newValue.type_id === 2 && !this.checkEmpty(newValue.input_id)) {
          if (this.checkEmpty(newValue.seat_number)) {
            this.errors.seat_number = this.$t('schedule.input_error');
          } else {
            delete this.errors.seat_number;
          }
          if (this.checkEmpty(newValue.train_number)) {
            this.errors.train_number = this.$t('schedule.input_error');
          } else {
            delete this.errors.train_number;
          }
          if (this.checkEmpty(newValue.departure_station)) {
            this.errors.departure_station = this.$t('schedule.input_error');
          } else {
            delete this.errors.departure_station;
          }
          if (this.checkEmpty(newValue.arrival_station)) {
            this.errors.arrival_station = this.$t('schedule.input_error');
          } else {
            delete this.errors.arrival_station;
          }
          if (this.checkEmpty(newValue.start_time)) {
            this.errors.start_time = this.$t('schedule.input_error');
          }
          if (this.checkEmpty(newValue.end_time)) {
            this.errors.end_time = this.$t('schedule.input_error');
          }
          if (this.checkEmpty(newValue.time_zone_id)) {
            this.errors.time_zone = this.$t('schedule.input_error');
          } else {
            delete this.errors.time_zone;
          }
        }
        if (newValue.type_id === 6 && !this.checkEmpty(newValue.input_id)) {
          if (this.checkEmpty(newValue.hotel_name)) {
            this.errors.hotel_name = this.$t('schedule.input_error');
          } else {
            delete this.errors.hotel_name;
          }
          if (this.checkEmpty(newValue.hotel_room)) {
            this.errors.hotel_room = this.$t('schedule.input_error');
          } else {
            delete this.errors.hotel_room;
          }
          if (this.checkEmpty(newValue.time_zone_id)) {
            this.errors.time_zone = this.$t('schedule.input_error');
          } else {
            delete this.errors.time_zone;
          }
          if (this.checkEmpty(newValue.location_string)) {
            this.errors.location_string = this.$t('schedule.input_error');
          } else {
            delete this.errors.location_string;
          }
        }
        if (newValue.type_id === 17 && !this.checkEmpty(newValue.input_id)) {
          if (this.checkEmpty(newValue.title)) {
            this.errors.title = this.$t('schedule.input_error');
          } else {
            delete this.errors.title;
          }
          if (this.checkEmpty(newValue.time_zone_id)) {
            this.errors.time_zone = this.$t('schedule.input_error');
          } else {
            delete this.errors.time_zone;
          }
          if (this.checkEmpty(newValue.location_string)) {
            this.errors.location_string = this.$t('schedule.input_error');
          } else {
            delete this.errors.location_string;
          }
        }
      },
      deep: true,
    },
    'dataRequest.type_id': function (val) {
      if (val) {
        this.resetDataAfter(val);
      }
    },
    'scheduleData.type_id': function (val) {
      if (val) {
        this.resetDataAfter(val);
      }
    },
  },
  created() {
    Promise.all([this.getTypeSchedule(), this.getTimeZone(), this.getClips(), this.getCosts()]).then(() => {});
  },
  mounted() {
    this.getListAirport();
  },
  methods: {
    setDefaultTitle(typeId) {
      const scheduleType = this.scheduleTypeList.filter(item => item.id === typeId);
      if (scheduleType.length) {
        return this.labelLocale(scheduleType[0]);
      }
      return '';
    },
    convertData(data) {
      if (String(data).includes('.') || String(data).includes('．')) {
        if (String(data).includes('．')) {
          data = data.replaceAll('．', '.');
        }
        this.dataRequest.cost_amount = String(parseFloat(data).toFixed(2))
            .replace(/^(?!00[^0])0/, '')
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      } else {
        data = data.replaceAll(',', '');
        this.dataRequest.cost_amount = data
            .replace(/(\..*?)\..*/g, '$1')
            .replace(/^(?!00[^0])0/, '')
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    },
    initScheduleData() {
      if (this.checkEmpty(this.scheduleData)) return;
      this.tab = true;
      this.flagCreate = false;
      if (this.scheduleData.end_date) {
        this.checkedMultipleDays = true;
      }
      if (this.scheduleData.cost_amount && this.checkEmpty(this.scheduleData.cost_ids)) {
        this.radioData = '1';
      }
      if (this.checkEmpty(this.scheduleData.cost_amount) && this.checkEmpty(this.scheduleData.cost_ids)) {
        this.radioData = '1';
      }
      if (this.checkEmpty(this.scheduleData.cost_amount) && !this.checkEmpty(this.scheduleData.cost_ids)) {
        this.radioData = '2';
      }
      if (!this.checkEmpty(this.scheduleData.cost_amount) && !this.checkEmpty(this.scheduleData.cost_ids)) {
        this.radioData = '2';
      }
      if (this.isTripApprovalDisable) {
        this.dataRequest.approval_status_id = false;
      }
      this.dataRequest.id = this.scheduleData.id;
      this.dataRequest.trip_id = this.scheduleData.trip_id;
      this.dataRequest.input_id = this.scheduleData.input_id;
      this.dataRequest.approval_status_id = this.scheduleData.approval_status_id;
      this.dataRequest.title = this.scheduleData.title;
      this.dataRequest.type_id = this.scheduleData.type_id;
      this.dataRequest.start_date = this.scheduleData.start_date;
      this.dataRequest.end_date = this.scheduleData.end_date;
      this.dataRequest.flight_number = this.scheduleData.flight_number;
      this.dataRequest.train_number = this.scheduleData.train_number;
      this.dataRequest.seat_class_type = this.scheduleData.seat_class_type;
      this.dataRequest.seat_number = this.scheduleData.seat_number;
      this.dataRequest.airplane_type = this.scheduleData.airplane_type;
      this.dataRequest.transit_airplane_type = this.scheduleData.transit_airplane_type;
      this.dataRequest.location_string = this.scheduleData.location_string;
      this.dataRequest.time_zone_id = this.scheduleData.time_zone_id;
      this.dataRequest.start_time = this.scheduleData.start_time;
      this.dataRequest.end_time = this.scheduleData.end_time;
      this.dataRequest.hotel_name = this.scheduleData.hotel_name;
      this.dataRequest.hotel_room = this.scheduleData.hotel_room;
      this.dataRequest.checkin_time = this.scheduleData.checkin_time;
      this.dataRequest.checkout_time = this.scheduleData.checkout_time;
      this.dataRequest.note = this.scheduleData.note;
      this.dataRequest.web_page = this.scheduleData.web_page;
      this.dataRequest.cost_amount = this.splitThousandNumber(this.scheduleData.cost_amount);
      this.dataRequest.cost_ids = this.scheduleData.cost_ids;
      this.dataRequest.clip_ids = this.scheduleData.clip_ids;
      this.dataRequest.departure_location_id = this.scheduleData.departure_location_id;
      this.dataRequest.departure_location_id_name = this.getNameAirport(this.scheduleData.departure_location_id);
      this.dataRequest.arrival_location_id = this.scheduleData.arrival_location_id;
      this.dataRequest.arrival_location_id_name = this.getNameAirport(this.scheduleData.arrival_location_id);
      this.dataRequest.departure_station = this.scheduleData.departure_station;
      this.dataRequest.arrival_station = this.scheduleData.arrival_station;
      this.dataRequest.input_file = this.scheduleData.input_file;
      this.dataRequest.updated_at = this.scheduleData.updated_at;
      this.dataReport.remarks = this.scheduleData.report_description || '';
      this.dataReport.id = this.scheduleData.report_id;
      this.listFile = this.cloneObject(this.scheduleData.report_files);
      this.listFileUpdate = [];
    },
    //Type 1: uploaded files, Type 2: New Uploaded files(not saved)
    handleRemoveReportFile(fileId, type = 1) {
      if (this.checkCustomDisable || !this.isReportEdit) return;
      if (type == 1) {
        const targetFileIndex = this.listFile.findIndex(item => item.id == fileId);
        if (targetFileIndex != -1) {
          this.listFile.splice(targetFileIndex, 1);
          this.deletedFiles.push(fileId);
        }
        return;
      }
      const targetFileIndex = this.listFileUpdate.findIndex(item => item.name == fileId);
      if (targetFileIndex != -1) this.listFileUpdate.splice(targetFileIndex, 1);
    },
    handleShowWebpage() {
      if (this.isActionRingi) return;
      this.isEditingWebpage = true;
      this.$nextTick(() => {
        this.$refs.webpage_compo.focus();
      });
    },
    handleFocusoutUrl() {
      this.isEditingWebpage = false;
    },
    handleFocusUrl() {
      this.isEditingWebpage = true;
    },
    checkStartTime(key) {
      let val = this.dataRequest[key];
      if (val) {
        val = this.autoFormatTime(val);
        if (this.isValidTime(val)) {
          this.errors[key] = '';
        } else {
          this.errors[key] = this.$t('input.time_error');
        }

        this.dataRequest[key] = val;
      }
    },
    changeAirplaneType(type = '') {
      if (this.checkCustomDisable) return;
      this.dataRequest.airplane_type = type;
      if (type == 2 && !this.checkEmpty(this.scheduleData.transit_airplane_type)) {
        this.dataRequest.transit_airplane_type = this.scheduleData.transit_airplane_type;
      } else {
        //Default: Departure transit
        this.dataRequest.transit_airplane_type = 1;
      }
    },
    changeTransitAirplaneType(type = '') {
      if (!this.checkCustomDisable) this.dataRequest.transit_airplane_type = type;
    },
    changeRadioData(type = '') {
      if (!this.checkCustomDisable) this.radioData = type;
    },
    querySearch(queryString, cb) {
      var links = this.listAirport;
      var results = queryString ? links.filter(this.createFilter(queryString)) : links;
      // call callback function to return suggestions
      cb(results);
    },
    createFilter(queryString) {
      return link => {
        return link.value.toLowerCase().includes(queryString.toLowerCase());
      };
    },
    handleSelectDeparture(item) {
      this.dataRequest.departure_location_id = item.id;
    },
    handleSelectArrival(item) {
      this.dataRequest.arrival_location_id = item.id;
      var airport_id = item.id;
      this.$services.common.searchByAirport(
          { airport_id },
          res => {
            if (res) {
              this.dataRequest.time_zone_id = res.id;
            }
          },
          () => {
            this.dataRequest.time_zone_id = this.timeZoneBefore;
          },
      );
    },
    getNameAirport(id) {
      var nameAirport = '';
      this.listAirport.filter(item => {
        if (item.id === id) {
          return (nameAirport = item.name);
        }
      });
      return nameAirport;
    },
    chooseTab1() {
      this.tab = true;
    },
    chooseTab2() {
      this.tab = false;
    },
    getListAirport() {
      this.$services.schedule.getListAirport(
          res => {
            this.listAirport = res.list;
            this.listAirport.map(item => {
              item.value = item.name;
              return item;
            });
          },
          err => {
            this.notifyError(this.$t(err.error));
          },
      );
    },
    async getClips() {
      if (
          this.isActionRingi &&
          (this.tripIdFromParent == 0 || this.checkEmpty(this.tripIdFromParent) || isNaN(this.tripIdFromParent))
      )
        return;
      await this.$services.common.getListClipsByTrip(
          this.tripId,
          res => {
            this.optionClip = res.list;
          },
          err => {
            this.notifyError(this.$t(err.error));
          },
      );
    },
    async getCosts() {
      if (
          this.isActionRingi &&
          (this.tripIdFromParent == 0 || this.checkEmpty(this.tripIdFromParent) || isNaN(this.tripIdFromParent))
      )
        return;
      await this.$services.common.getListCostsByTrip(
          this.tripId,
          res => {
            let optionData = res.list.filter(item => {
              if (item.title !== null && !this.checkEmpty(item.is_insurance_cost) && !item.is_insurance_cost) {
                if (item.amount !== null) {
                  item.title = `${item.title} ： ¥${item.amount}`;
                }
                return item;
              }
            });
            this.optionCost = optionData;
          },
          () => {
            // this.notifyError(this.$t(err.error));
          },
      );
    },
    async getTimeZone() {
      await this.$services.common.getListTimeZones(
          res => {
            this.optionsUTC = res.list;
          },
          err => {
            this.notifyError(this.$t(err.error));
          },
      );
    },
    async getTypeSchedule() {
      await this.$services.schedule.getListScheduleType(
          res => {
            let listData = [];

            this.listLabel.forEach(element => {
              listData.push({
                label: element,
                options: [],
              });
            });

            res.list.forEach(item => {
              listData[+item.group_type].options.push({
                value: item.id,
                label: this.labelLocale(item),
              });
            });
            this.scheduleTypeList = res.list;
            this.listType = listData;
          },
          err => {
            this.notifyError(this.$t(err.error));
          },
      );
    },
    validateSeatNumber() {
      if ([1, 2].includes(this.dataRequest.type_id) && this.checkEmpty(this.dataRequest.seat_number)) {
        this.errors.seat_number = this.$t('E-CM-100', {
          item: this.$t('input.seat_number'),
        });
      } else {
        this.errors.seat_number = '';
      }
    },
    submitData() {
      if (!this.dataRequest) return;
      let flgValidate = true;

      if ([1].includes(this.dataRequest.type_id) && this.checkEmpty(this.dataRequest.seat_class_type)) {
        flgValidate = false;
        this.errors.seat_class_type = this.$t('E-CM-100', {
          item: this.$t('input.booking_class'),
        });
      } else {
        this.errors.seat_class_type = '';
      }
      // if ([1, 2].includes(this.dataRequest.type_id) && this.checkEmpty(this.dataRequest.seat_number)) {
      //   flgValidate = false;
      //   this.errors.seat_number = this.$t('E-CM-100', {
      //     item: this.$t('input.seat_number'),
      //   });
      // } else {
      //   this.errors.seat_number = '';
      // }

      if (![1, 2, 6].includes(this.dataRequest.type_id) && this.checkEmpty(this.dataRequest.title)) {
        flgValidate = false;
        this.errors.title = this.$t('E-CM-100', {
          item: this.$t('schedule.title'),
        });
      } else {
        this.errors.title = '';
      }

      if (![1, 2].includes(this.dataRequest.type_id) && this.checkEmpty(this.dataRequest.location_string)) {
        flgValidate = false;
        this.errors.location_string = this.$t('E-CM-100', {
          item: this.$t('schedule.location_string'),
        });
      } else {
        this.errors.location_string = '';
      }
      if (![1].includes(this.dataRequest.type_id) && this.checkEmpty(this.dataRequest.time_zone_id)) {
        flgValidate = false;
        this.errors.time_zone = this.$t('E-CM-100', {
          item: this.$t('schedule.time_zone'),
        });
      } else {
        this.errors.time_zone = '';
      }
      if ([1].includes(this.dataRequest.type_id) && this.checkEmpty(this.dataRequest.flight_number)) {
        flgValidate = false;
        this.errors.flight_number = this.$t('E-CM-100', {
          item: this.$t('schedule.flight_number'),
        });
      } else if (this.dataRequest.type_id === 1 && !this.isValidFlightNumber(this.dataRequest.flight_number)) {
        flgValidate = false;
        this.errors.flight_number = this.$t('common.valid_flight_number');
      } else {
        this.errors.flight_number = '';
      }
      if ([2].includes(this.dataRequest.type_id) && this.checkEmpty(this.dataRequest.train_number)) {
        flgValidate = false;
        this.errors.train_number = this.$t('E-CM-100', {
          item: this.$t('schedule.train_number'),
        });
      } else {
        this.errors.train_number = '';
      }

      if ([6].includes(this.dataRequest.type_id) && this.checkEmpty(this.dataRequest.hotel_name)) {
        flgValidate = false;
        this.errors.hotel_name = this.$t('E-CM-100', {
          item: this.$t('schedule.hotel_name'),
        });
      } else {
        this.errors.hotel_name = '';
      }

      if ([6].includes(this.dataRequest.type_id) && this.checkEmpty(this.dataRequest.hotel_room)) {
        flgValidate = false;
        this.errors.hotel_room = this.$t('E-CM-100', {
          item: this.$t('input.room'),
        });
      } else {
        this.errors.hotel_room = '';
      }

      if ([1].includes(this.dataRequest.type_id) && this.checkEmpty(this.dataRequest.departure_location_id)) {
        flgValidate = false;
        this.errors.departure_location = this.$t('E-CM-100', {
          item: this.$t('schedule.departure_location'),
        });
      } else {
        this.errors.departure_location = '';
      }

      if ([1].includes(this.dataRequest.type_id) && this.checkEmpty(this.dataRequest.arrival_location_id)) {
        flgValidate = false;
        this.errors.arrival_location = this.$t('E-CM-100', {
          item: this.$t('schedule.arrival_location'),
        });
      } else {
        this.errors.arrival_location = '';
      }

      if (
          [1].includes(this.dataRequest.type_id) &&
          !this.checkEmpty(this.dataRequest.departure_location_id) &&
          !this.checkEmpty(this.dataRequest.arrival_location_id)
      ) {
        if (this.dataRequest.departure_location_id === this.dataRequest.arrival_location_id) {
          flgValidate = false;
          this.errors.departure_location = this.$t('schedule.departure_err');
          this.errors.arrival_location = this.$t('schedule.arrival_err');
        } else {
          this.errors.departure_location = '';
          this.errors.arrival_location = '';
        }
      }

      if ([2].includes(this.dataRequest.type_id) && this.checkEmpty(this.dataRequest.departure_station)) {
        flgValidate = false;

        this.errors.departure_station = this.$t('E-CM-100', {
          item: this.$t('schedule.departure_station'),
        });
      } else {
        this.errors.departure_station = '';
      }
      if ([2].includes(this.dataRequest.type_id) && this.checkEmpty(this.dataRequest.arrival_station)) {
        flgValidate = false;

        this.errors.arrival_station = this.$t('E-CM-100', {
          item: this.$t('schedule.arrival_station'),
        });
      } else {
        this.errors.arrival_station = '';
      }

      if ([1, 2].includes(this.dataRequest.type_id) && this.checkEmpty(this.dataRequest.start_time)) {
        flgValidate = false;
        this.errors.start_time = this.$t('E-CM-100', {
          item: this.$t('schedule.start_time'),
        });
      } else {
        this.errors.start_time = '';
      }

      if ([1, 2].includes(this.dataRequest.type_id) && this.checkEmpty(this.dataRequest.end_time)) {
        flgValidate = false;
        this.errors.end_time = this.$t('E-CM-100', {
          item: this.$t('schedule.end_time'),
        });
      } else {
        this.errors.end_time = '';
      }

      if ([1, 2].includes(this.dataRequest.type_id)) {
        if (this.isValidTime(this.dataRequest.start_time)) {
          this.errors.start_time = '';
        } else {
          this.errors.start_time = this.$t('input.time_error');
          flgValidate = false;
        }
        if (this.isValidTime(this.dataRequest.end_time)) {
          this.errors.end_time = '';
        } else {
          this.errors.end_time = this.$t('input.time_error');
          flgValidate = false;
        }
      }

      if (![1, 2, 6].includes(this.dataRequest.type_id) && this.checkEmpty(this.dataRequest.start_date)) {
        flgValidate = false;
        this.errors.date = this.$t('E-CM-100', {
          item: this.$t('schedule.date'),
        });
      } else {
        this.errors.date = '';
      }

      if (
          ![1, 2, 6].includes(this.dataRequest.type_id) &&
          !this.checkedMultipleDays &&
          this.checkEmpty(this.dataRequest.start_time)
      ) {
        flgValidate = false;
        this.errors.time = this.$t('E-CM-100', {
          item: this.$t('schedule.time'),
        });
      } else {
        this.errors.time = '';
      }

      if (![1, 2, 6].includes(this.dataRequest.type_id) && !this.checkedMultipleDays) {
        if (this.isValidTime(this.dataRequest.start_time)) {
          this.errors.time = '';
        } else {
          this.errors.time = this.$t('input.time_error');
          flgValidate = false;
        }
      }

      if ([1, 2].includes(this.dataRequest.type_id) && this.checkEmpty(this.dataRequest.start_date)) {
        flgValidate = false;
        this.errors.start_date = this.$t('E-CM-100', {
          item: this.$t('schedule.start_date'),
        });
      } else {
        this.errors.start_date = '';
      }

      if ([1, 2].includes(this.dataRequest.type_id) && this.checkEmpty(this.dataRequest.end_date)) {
        flgValidate = false;
        this.errors.end_date = this.$t('E-CM-100', {
          item: this.$t('schedule.end_date'),
        });
      } else {
        this.errors.end_date = '';
      }

      if (this.dataRequest.type_id === 6 && this.checkEmpty(this.dataRequest.checkin_time)) {
        flgValidate = false;
        this.errors.checkin_time = this.$t('E-CM-100', {
          item: this.$t('schedule.checkin_time'),
        });
      } else {
        this.errors.checkin_time = '';
      }

      if (this.dataRequest.type_id === 6 && this.checkEmpty(this.dataRequest.checkout_time)) {
        flgValidate = false;
        this.errors.checkout_time = this.$t('E-CM-100', {
          item: this.$t('schedule.checkout_time'),
        });
      } else {
        this.errors.checkout_time = '';
      }

      if (
          this.dataRequest.type_id === 6 &&
          !this.checkEmpty(this.dataRequest.checkin_time) &&
          !this.checkEmpty(this.dataRequest.checkout_time)
      ) {
        if (this.dataRequest.checkin_time >= this.dataRequest.checkout_time) {
          flgValidate = false;
          this.errors.checkin_time = this.$t('schedule.checkin_time_more_than_checkout');
        }
      }

      if (this.checkedMultipleDays && this.checkEmpty(this.dataRequest.end_date)) {
        flgValidate = false;
        this.errors.date_end = this.$t('E-CM-100', {
          item: this.$t('schedule.date_end'),
        });
      } else {
        this.errors.date_end = '';
      }

      if (this.dataRequest.web_page) {
        if (!this.isValidHttpUrl(this.dataRequest.web_page)) {
          flgValidate = false;
          this.errors.web_page = this.$t('common.valid_url');
        } else {
          delete this.errors.web_page;
        }
      }
      // if (this.dataRequest.type_id === 1 && !this.isValidFlightNumber(this.dataRequest.flight_number)) {
      //   flgValidate = false;
      //   this.errors.flight_number = this.$t('common.valid_flight_number');
      // } else {
      //   delete this.errors.flight_number;
      // }
      if (!flgValidate) return;

      if (!this.flagCreate) {
        this.updateData();
      } else {
        this.createData();
      }
    },
    createData() {
      let approvalId = 1;
      if (!this.dataRequest.approval_status_id) {
        approvalId = 0;
      }
      if (this.dataRequest.type_id === 6) {
        this.dataRequest.start_date = '';
        this.dataRequest.end_date = '';
        this.dataRequest.start_time = '12:00';
        this.dataRequest.end_time = '12:00';
      }
      if (![1, 2].includes(this.dataRequest.type_id)) {
        this.dataRequest.end_time = '';
        if (this.checkedMultipleDays) {
          this.dataRequest.start_time = '';
        }
      }
      let costTitle = '';
      if (this.dataRequest.type_id === 6) {
        costTitle = `${
            this.dataRequest.checkin_time != this.dataRequest.checkout_time
                ? this.dataRequest.checkin_time + '-' + this.dataRequest.checkout_time
                : this.dataRequest.checkin_time
        } ${this.setDefaultTitle(this.dataRequest.type_id)}`;
      } else if (this.dataRequest.type_id === 1 || this.dataRequest.type_id === 2) {
        costTitle = `${
            this.dataRequest.end_date != this.dataRequest.start_date
                ? this.dataRequest.start_date + '-' + this.dataRequest.end_date
                : this.dataRequest.start_date
        } ${this.setDefaultTitle(this.dataRequest.type_id)}`;
      } else {
        costTitle = this.dataRequest.title;
      }

      let formData = {
        type_id: this.dataRequest.type_id,
        trip_id: this.tripId,
        title: costTitle,
        title_amount: costTitle,
        approval_status_id: approvalId,
        time_zone_id: this.dataRequest.time_zone_id,
        location_string: this.dataRequest.location_string,
        start_date: this.dataRequest.start_date,
        end_date: this.dataRequest.end_date,
        start_time: this.dataRequest.start_time,
        end_time: this.dataRequest.end_time,
        departure_location_id: this.dataRequest.departure_location_id,
        arrival_location_id: this.dataRequest.arrival_location_id,
        web_page: this.dataRequest.web_page,
        note: this.dataRequest.note,
        hotel_name: this.dataRequest.hotel_name,
        hotel_room: this.dataRequest.hotel_room,
        checkin_time: this.dataRequest.checkin_time,
        checkout_time: this.dataRequest.checkout_time,
        clip_ids: this.dataRequest.clip_ids,
        cost_ids: this.dataRequest.cost_ids,
        cost_amount: this.dataRequest.cost_amount.replaceAll(',', ''),
      };
      if (this.dataRequest.type_id === 1) {
        formData.flight_number = this.dataRequest.flight_number;
        formData.airplane_type = this.dataRequest.airplane_type;
        if (this.dataRequest.airplane_type == 2)
          formData.transit_airplane_type = this.dataRequest.transit_airplane_type;
        formData.seat_class_type = this.dataRequest.seat_class_type;
        formData.seat_number = this.dataRequest.seat_number;
      }
      if (this.dataRequest.type_id === 2) {
        formData.train_number = this.dataRequest.train_number;
        formData.departure_station = this.dataRequest.departure_station;
        formData.arrival_station = this.dataRequest.arrival_station;
        formData.seat_number = this.dataRequest.seat_number;
      }

      this.startLoading();
      this.$services.schedule.createSchedule(
          formData,
          () => {
            this.checkedMultipleDays = false;
            this.endLoading();
            this.$emit('yes', true);
            this.$emitter.emit('updateClipIcon', 1);
            this.$emitter.emit('updateCostAfterCreatingSchedule');
            this.resetData(1);
          },
          err => {
            this.notifyError(this.$t(err.error));
            this.endLoading();
          },
      );
    },

    updateData() {
      let approvalId = 1;
      let startTime = '';
      let endTime = '';
      let checkinTime = '';
      let checkoutTime = '';
      if (this.dataRequest.type_id === 6 && this.dataRequest.checkin_time) {
        checkinTime = moment(this.dataRequest.checkin_time, 'YYYY-MM-DD HH:mm:ss').format('YYYY/MM/DD');
      }
      if (this.dataRequest.type_id === 6 && this.dataRequest.checkout_time) {
        checkoutTime = moment(this.dataRequest.checkout_time, 'YYYY-MM-DD HH:mm:ss').format('YYYY/MM/DD');
      }
      if (this.dataRequest.type_id === 6) {
        this.dataRequest.start_date = '';
        this.dataRequest.end_date = '';
        this.dataRequest.start_time = '12:00';
        this.dataRequest.end_time = '12:00';
      }
      if (!this.dataRequest.approval_status_id) {
        approvalId = 0;
      }
      if (![1, 2].includes(this.dataRequest.type_id)) {
        startTime = '';
        if (this.checkedMultipleDays) {
          endTime = '';
        } else {
          this.dataRequest.end_date = '';
        }
      }
      if (this.dataRequest.start_time) {
        startTime = moment(this.dataRequest.start_time, 'HH:mm:ss').format('HH:mm');
      }
      if (this.dataRequest.end_time) {
        endTime = moment(this.dataRequest.end_time, 'HH:mm:ss').format('HH:mm');
      }

      if (this.dataRequest.cost_amount && this.checkEmpty(this.dataRequest.cost_ids)) {
        this.radioData = '1';
      }
      if (this.checkEmpty(this.dataRequest.cost_amount) && this.checkEmpty(this.dataRequest.cost_ids)) {
        this.radioData = '1';
      }
      if (this.checkEmpty(this.dataRequest.cost_amount) && !this.checkEmpty(this.dataRequest.cost_ids)) {
        this.radioData = '2';
      }
      if (!this.checkEmpty(this.dataRequest.cost_amount) && !this.checkEmpty(this.dataRequest.cost_ids)) {
        this.radioData = '2';
      }

      let costTitle = '';
      if (this.dataRequest.type_id === 6) {
        costTitle = `${
            this.dataRequest.checkin_time != this.dataRequest.checkout_time
                ? this.dataRequest.checkin_time + '-' + this.dataRequest.checkout_time
                : this.dataRequest.checkin_time
        } ${this.setDefaultTitle(this.dataRequest.type_id)}`;
      } else if (this.dataRequest.type_id === 1 || this.dataRequest.type_id === 2) {
        costTitle = `${
            this.dataRequest.end_date != this.dataRequest.start_date
                ? this.dataRequest.start_date + '-' + this.dataRequest.end_date
                : this.dataRequest.start_date
        } ${this.setDefaultTitle(this.dataRequest.type_id)}`;
      } else {
        costTitle = this.dataRequest.title;
      }
      let formData = {
        type_id: this.dataRequest.type_id,
        trip_id: this.tripId,
        title: costTitle,
        title_amount: costTitle,
        approval_status_id: approvalId,
        time_zone_id: this.dataRequest.time_zone_id,
        flight_number: this.dataRequest.flight_number,
        location_string: this.dataRequest.location_string,
        start_date: this.dataRequest.start_date,
        end_date: this.dataRequest.end_date,
        start_time: startTime,
        end_time: endTime,
        departure_location_id: this.dataRequest.departure_location_id,
        arrival_location_id: this.dataRequest.arrival_location_id,

        web_page: this.dataRequest.web_page,
        note: this.dataRequest.note,
        hotel_name: this.dataRequest.hotel_name,
        hotel_room: this.dataRequest.hotel_room,
        checkin_time: checkinTime,
        checkout_time: checkoutTime,
        clip_ids: this.dataRequest.clip_ids,
        cost_ids: this.dataRequest.cost_ids,
        cost_amount: this.dataRequest.cost_amount.replaceAll(',', ''),
        updated_at: this.scheduleData.updated_at,
      };
      if (this.dataRequest.type_id === 1) {
        formData.airplane_type = this.dataRequest.airplane_type;
        if (this.dataRequest.airplane_type == 2)
          formData.transit_airplane_type = this.dataRequest.transit_airplane_type;
        formData.seat_class_type = this.dataRequest.seat_class_type;
        formData.seat_number = this.dataRequest.seat_number;
      }
      if (this.dataRequest.type_id === 2) {
        formData.train_number = this.dataRequest.train_number;
        formData.departure_station = this.dataRequest.departure_station;
        formData.arrival_station = this.dataRequest.arrival_station;
        formData.seat_number = this.dataRequest.seat_number;
      }
      this.startLoading();

      this.$services.schedule.updateSchedule(
          this.dataRequest.id,
          formData,
          () => {
            this.checkedMultipleDays = false;
            this.endLoading();
            this.$emit('yes', true);
            this.$emitter.emit('updateClipIcon', 1);
            this.$emitter.emit('updateCostAfterCreatingSchedule');
            this.$emitter.emit('updateScheduleSuccess', true);
          },
          err => {
            this.notifyError(this.$t(err.error), true);
            this.endLoading();
          },
      );
    },

    pickerFromOptions(time) {
      return (
          moment(new Date(time)).format('YYYY-MM-DD') > moment(this.dataRequest?.end_date).format('YYYY-MM-DD') ||
          moment(new Date(time)).format('YYYY-MM-DD') < moment(new Date(this.tripDate.start_time)).format('YYYY-MM-DD') ||
          moment(new Date(time)).format('YYYY-MM-DD') > moment(new Date(this.tripDate.end_time)).format('YYYY-MM-DD')
      );
    },

    pickerToOptions(time) {
      if (!this.dataRequest.start_date)
        return (
            moment(new Date(time)).format('YYYY-MM-DD') <
            moment(new Date(this.dataRequest.start_date)).format('YYYY-MM-DD')
        );

      return (
          moment(new Date(time)).format('YYYY-MM-DD') > moment(new Date(this.tripDate.end_time)).format('YYYY-MM-DD') ||
          moment(new Date(time)).format('YYYY-MM-DD') < moment(this.dataRequest?.start_date).format('YYYY-MM-DD')
      );
    },
    pickerFromOptionsTypePlane(time) {
      return (
          moment(new Date(time)).format('YYYY-MM-DD') < moment(new Date(this.tripDate.start_time)).add(-2, 'days').format('YYYY-MM-DD') ||
          moment(new Date(time)).format('YYYY-MM-DD') > moment(new Date(this.tripDate.end_time)).add(2, 'days').format('YYYY-MM-DD')
      );
    },
    pickerToOptionsTypePlane(time) {
      if (this.dataRequest.start_date)
        return (
            moment(new Date(time)).format('YYYY-MM-DD') <
            moment(new Date(this.tripDate.start_time)).add(-2, 'days').format('YYYY-MM-DD') ||
            moment(new Date(time)).format('YYYY-MM-DD') >
            moment(new Date(this.tripDate.end_time)).add(2, 'days').format('YYYY-MM-DD')
        );

      return (
          moment(new Date(time)).format('YYYY-MM-DD') > moment(new Date(this.tripDate.end_time)).format('YYYY-MM-DD') ||
          moment(new Date(time)).format('YYYY-MM-DD') < moment(this.dataRequest?.start_date).format('YYYY-MM-DD')
      );
    },

    pickerFromOptionsCheckInTime(time) {
      return (
          moment(new Date(time)).format('YYYY-MM-DD') > moment(this.dataRequest?.checkout_time).format('YYYY-MM-DD') ||
          moment(new Date(time)).format('YYYY-MM-DD') < moment(new Date(this.tripDate.start_time)).format('YYYY-MM-DD') ||
          moment(new Date(time)).format('YYYY-MM-DD') > moment(new Date(this.tripDate.end_time)).format('YYYY-MM-DD')
      );
    },
    pickerToOptionsCheckOutTime(time) {
      if (!this.dataRequest.checkin_time)
        return (
            moment(new Date(time)).format('YYYY-MM-DD') <
            moment(new Date(this.dataRequest.checkin_time)).format('YYYY-MM-DD')
        );

      return (
          moment(new Date(time)).format('YYYY-MM-DD') > moment(new Date(this.tripDate.end_time)).format('YYYY-MM-DD') ||
          moment(new Date(time)).format('YYYY-MM-DD') < moment(this.dataRequest?.checkin_time).format('YYYY-MM-DD')
      );
    },
    isValidHttpUrl(string) {
      let res = string.match(
          /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g,
      );
      if (res == null) {
        return false;
      } else {
        return true;
      }
    },
    isValidFlightNumber(string) {
      // let res = string.match(/^[A-Z|a-z]{1}[A-Z|a-z|0-9]{1}[0-9]{1,5}$/g);
      if (string.length > 6) {
        return false;
      } else {
        return true;
      }
    },
    onCloseModal() {
      this.checkedMultipleDays = false;
      let currentScheduleId = this.dataRequest.id;
      if (!this.scheduleId) {
        this.resetData(1);
      }
      this.$emit('cancel', currentScheduleId);
    },
    confirmDelete() {
      this.dialogVisibleStatus = false;
      this.showBoxConfirm = true;
    },
    confirmDeleteReport() {
      this.dialogVisibleStatus = false;
      this.showBoxConfirmReport = true;
    },
    deleteSchedule() {
      this.startLoading();
      this.$services.schedule.deleteSchedule(
          this.dataRequest.id,
          () => {
            this.endLoading();
            this.resetData(1);
            this.showBoxConfirm = false;
            this.$emit('yes');
          },
          err => {
            this.endLoading();
            this.showBoxConfirm = false;
            this.notifyError(this.$t(err.error));
          },
      );
    },
    listenCosts(searchList) {
      this.dataRequest.cost_ids = searchList;
    },
    listenClips(searchList) {
      this.dataRequest.clip_ids = searchList;
    },
    resetData(val) {
      this.checkedMultipleDays = false;
      // this.radioData = '1';
      this.dataRequest.type_id = val;
      this.dataRequest.approval_status_id = true;
      this.dataRequest.title = '';
      this.dataRequest.flight_number = '';
      this.dataRequest.train_number = '';
      this.dataRequest.airplane_type = 1;
      this.dataRequest.transit_airplane_type = 1;
      this.dataRequest.location_string = '';
      this.dataRequest.time_zone_id = this.timeZoneBefore;
      this.dataRequest.start_date = this.dateDefault;
      this.dataRequest.end_date = this.dateDefault;
      this.dataRequest.departure_location_id = '';
      this.dataRequest.departure_location_id_name = '';
      this.dataRequest.arrival_location_id = '';
      this.dataRequest.arrival_location_id_name = '';
      this.dataRequest.start_time = '12:00';
      this.dataRequest.end_time = '12:00';
      if (![1, 2].includes(val)) {
        this.dataRequest.start_time = '12:00';
        this.dataRequest.end_time = '12:00';
      }
      if ([1].includes(val)) {
        this.dataRequest.seat_class_type = 3;
        this.dataRequest.seat_number = '';
      }
      if ([2].includes(val)) {
        this.dataRequest.departure_station = '';
        this.dataRequest.arrival_station = '';
        this.dataRequest.seat_number = '';
      }
      this.dataRequest.web_page = '';
      this.dataRequest.note = '';
      this.dataRequest.input_file = '';
      this.dataRequest.hotel_name = '';
      this.dataRequest.hotel_room = '';
      if ([6].includes(val)) {
        this.dataRequest.checkin_time = this.dateDefault;
        this.dataRequest.checkout_time = moment(new Date(this.dateDefault)).add(+1, 'days').format('YYYY/MM/DD');
      }
      this.dataRequest.cost_amount = '';
      this.dataRequest.cost_ids = [];
      this.dataRequest.clip_ids = [];
      this.dataReport.id = '';
      this.dataReport.remarks = '';
    },
    resetDataAfter(val) {
      if (!this.selectChangeType) return;
      this.checkedMultipleDays = false;
      // this.radioData = '1';
      this.dataRequest.type_id = val;
      this.dataRequest.approval_status_id = true;
      this.dataRequest.flight_number = '';
      this.dataRequest.train_number = '';
      this.dataRequest.airplane_type = 1;
      this.dataRequest.location_string = '';
      this.dataRequest.time_zone_id = this.timeZoneBefore;
      this.dataRequest.start_date = this.dateDefault;
      this.dataRequest.end_date = ![1, 2, 6].includes(this.dataRequest.type_id) ? '' : this.dateDefault;
      this.dataRequest.departure_location_id = '';
      this.dataRequest.departure_location_id_name = '';
      this.dataRequest.arrival_location_id = '';
      this.dataRequest.arrival_location_id_name = '';
      this.dataRequest.start_time = '12:00';
      this.dataRequest.end_time = '12:00';
      if (![1, 2].includes(val)) {
        this.dataRequest.start_time = '12:00';
        this.dataRequest.end_time = '12:00';
      }
      if ([1].includes(val)) {
        this.dataRequest.seat_class_type = 3;
        this.dataRequest.seat_number = '';
      }
      if ([2].includes(val)) {
        this.dataRequest.departure_station = '';
        this.dataRequest.arrival_station = '';
        this.dataRequest.seat_number = '';
      }
      this.dataRequest.web_page = '';
      this.dataRequest.note = '';
      this.dataRequest.hotel_name = '';
      this.dataRequest.hotel_room = '';
      this.dataRequest.input_file = '';
      if ([6].includes(val)) {
        this.dataRequest.checkin_time = this.dateDefault;
        this.dataRequest.checkout_time = moment(new Date(this.dateDefault)).add(+1, 'days').format('YYYY/MM/DD');
      }
      this.dataRequest.cost_amount = '';
      this.dataRequest.cost_ids = [];
      this.dataRequest.clip_ids = [];
      this.dataReport.id = '';
      this.dataReport.remarks = '';
    },
    confirmUploadFile(files) {
      this.showModalUploadFile = false;
      this.listFileUpdate = files;
      if (this.listFileUpdate.length > 0) {
        this.errors.remarks = '';
      }
    },
    updateTimeZone(address) {
      if (![1].includes(this.dataRequest.type_id) && !this.dataRequest.location_string) return;

      this.$services.common.searchTimeZones(
          { address },
          res => {
            if (!this.checkEmpty(res.lat)) {
              this.dataRequest.time_zone_id = res.id;
            } else {
              this.dataRequest.time_zone_id = this.timeZoneBefore;
              // this.dataRequest.time_zone_id = '';
            }
          },
          () => {
            this.dataRequest.time_zone_id = this.timeZoneBefore;
          },
      );
    },
    updateTimeZoneTrain(address) {
      if (![1].includes(this.dataRequest.type_id) && !this.dataRequest.arrival_station) return;

      this.$services.common.searchTimeZones(
          { address },
          res => {
            if (!this.checkEmpty(res.lat)) {
              this.dataRequest.time_zone_id = res.id;
            } else {
              this.dataRequest.time_zone_id = this.timeZoneBefore;
              // this.dataRequest.time_zone_id = '';
            }
          },
          () => {
            this.dataRequest.time_zone_id = this.timeZoneBefore;
          },
      );
    },
    convertNumberFullToHalf(number) {
      number = number.replace(/[^0-9０-９]/g, '');
      number = String(number).replace(/[\uFF10-\uFF19]/g, function (m) {
        return String.fromCharCode(m.charCodeAt(0) - 0xfee0);
      });

      return number;
    },
    convertFlightNumberFullToHalf(e) {
      if (!e) return '';
      e = String(e).replace(/[！-～]/g, halfwidthChar => String.fromCharCode(halfwidthChar.charCodeAt(0) - 0xfee0));
      this.dataRequest.flight_number = e;
    },
    deleteReport() {
      this.startLoading();
      this.$services.report.deleteReport(
          this.dataReport.id,
          () => {
            this.endLoading();
            this.showModalUploadFile = false;
            this.showBoxConfirmReport = false;
            this.tab = true;
            this.$emit('yes', true);
          },
          err => {
            this.showBoxConfirmReport = false;
            this.endLoading();
            this.notifyError(this.$t(err.error));
          },
      );
    },
    saveReport() {
      let flgValidate = true;

      if (this.checkEmpty(this.dataReport.remarks) && this.listFileUpdate.length == 0 &&  this.listFile.length == 0) {
        flgValidate = false;
        this.errors.remarks = this.$t('REPORT-ERROR-100', {
          item: this.$t('schedule.remarks'),
        });
      } else {
        this.errors.remarks = '';
      }

      if (!flgValidate) return;
      if (this.strLen(this.dataReport.remarks) > 10000) {
        flgValidate = false;
        this.errors.remarks = this.$t('E-CM-111', { size: 10000 });
      } else {
        this.errors.remarks = '';
      }
      let formData = new FormData();
      if (this.listFileUpdate.length > 0) {
        for (let index = 0; index < this.listFileUpdate.length; index++) {
          formData.append('files[]', this.listFileUpdate[index]);
        }
      }
      if (!this.checkEmpty(this.deletedFiles)) {
        this.deletedFiles.forEach(item => formData.append('deleted_files[]', item));
      }
      formData.append('schedule_id', this.dataRequest.id);
      formData.append('date', this.dateDefault);
      formData.append('description', this.dataReport.remarks);
      if (this.dataReport.id) {
        formData.append('report_id', this.dataReport.id);
      }
      this.startLoading();
      this.$services.report.createReport(
          formData,
          () => {
            this.endLoading();
            this.showModalUploadFile = false;
            this.tab = true;
            this.$emit('yes', true);
          },
          err => {
            this.notifyError(err.error, true);
            this.endLoading();
          },
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.el-overlay-dialog {
  position: unset !important;
  top: 0 !important;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
}
.el-input__wrapper i.svg {
  display: none;
}
.max-textarea {
  max-width: 100%;
  min-width: 100%;
}
.file-name {
  display: block;
  width: 60%;
}
.file-name-new {
  display: block;
  width: 60%;
  color: #3650a5;
}
.icon-file-new {
  color: #3650a5;
}
.file-list {
  margin-bottom: inherit !important;
}
.checkbox-display {
  font-weight: normal;
  font-size: 13px;
  line-height: 0.9999999975;
  color: #657a84;
  max-width: 100%;
  margin-bottom: 8px;
  display: inline-flex !important;
}
</style>
<style lang="scss">
.select-input .el-input .el-input__wrapper .el-input__inner {
  padding-right: 25px !important;
}
.btn-update-report[disabled] {
  border: 1px solid #657a84 !important;
}
</style>
