
<template>
  <div>
    <button
    class="btn btn-sm btn-inverse-primary btn-block btn-approve-application"
    @click="handleDialogApproveApplication"
    :disabled="isDisabled"
  >
    {{ $t("application.approve_selected_items") }}
  </button>
  <ModalConfirm
    :dialogVisible="isShow"
    @cancel="isShow = false"
    @yes="emitApproveApplications()"
    :title="$t('Q-CU-001')"
  />
  </div>
</template>
<script>
import ModalConfirm from '@/components/common/ModalConfirm'
export default {
  name: "ApproveApplicationButton",
  emits: ['listenApproveApplications'],
  components: { ModalConfirm },
  data() {
    return {
      isShow: false,
      isDisabled: true,
    }
  },
  props: {
    isApproveDisabled: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    isApproveDisabled() {
      this.isDisabled = this.isApproveDisabled
    },
  },
  methods: {
    handleDialogApproveApplication() {
      this.isShow = true
    },
    emitApproveApplications() {
      this.$emit("listenApproveApplications")
      this.isShow = false
    },
  },
};
</script>
<style scoped>
.btn-approve-application {
  color: #3650a5;
  background-color: transparent;
  border-color: #3650a5;
}
.btn-approve-application:hover {
  color: #5e73b7;
  border: 1px solid #c7cee6;
  background-color: #dfe3f1;
}
.btn-approve-application[disabled] {
  border: 1px solid #3650a5 !important;
}
</style>
