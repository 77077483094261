<template>
  <nav class="navbar navbar-default navbar-lg" role="navigation" id="header-content">
    <div class="navbar-header">
      <h2 class="navbar-title">{{ $t('sidebar.position') }}</h2>
    </div>
    <div class="collapse navbar-collapse">
      <ul class="nav navbar-nav navbar-right">
        <li>
          <a @click="downloadFileCSV"> <span class="icon-abui-download prx" />{{ $t('zone.download_in_csv') }} </a>
        </li>
        <li class="dropdown addNew hidden-sm">
          <a
            class="dropdown-toggle pls"
            @click="isShowDropdown = !isShowDropdown"
            v-click-outside="
              () => {
                isShowDropdown = false;
              }
            "
          >
            <b class="caret" />
          </a>
          <span class="dropdown-arrow" />
          <ul class="dropdown-menu" :class="isShowDropdown && 'showDropdown'" style="left: -200px">
            <li>
              <a @click="showModalUploadCSV = true">
                <span class="icon-abui-upload prx" />{{ $t('zone.batch_registration_from_csv') }}
              </a>
            </li>
            <li>
              <a @click="downloadExampleFileCSV">
                <span class="icon-abui-download prx" />{{ $t('zone.download_csv_formate') }}
              </a>
            </li>
            <li>
              <a href="https://www.iace.co.jp/travelmgr/pdf/import_sample/yakushoku" target="_blank" >
                <span class="icon-abui-book prx" />{{ $t('common.manual_registration') }}
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </nav>

  <body class="">
    <div class="wrapper">
      <div class="container-fluid">
        <div class="section noborder">
          <div>
            <table class="table table-bordered js-addcontrol-item-1--table">
              <thead>
                <tr>
                  <th style="width: 1em"></th>
                  <th>{{ $t('position.job_title_to_display') }}</th>
                  <th>{{ $t('position.management_title') }}</th>
                  <th style="width: 44px"></th>
                </tr>
              </thead>
              <tbody id="js-addcontrol-item-1--tbody" ref="position-list">
                <tr
                  v-for="(position, index) in listPosition"
                  :key="index"
                  class="js-addcontrol-item-1--row1 scroll-margin-top"
                  :id="`position_${index}`"
                >
                  <td class="js-addcontrol-item-1--row--num">{{ index + 1 }}</td>
                  <td class="cell-control">
                    <input
                      type="text"
                      class="form-control input-sm input-content"
                      :class="position.isErrorTitle && 'has-error'"
                      v-model.trim="position.title"
                    />
                  </td>
                  <td class="cell-control">
                    <input
                      type="text"
                      class="form-control input-sm input-content"
                      :class="position.isErrorManagementTitle && 'has-error'"
                      v-model.trim="position.management_title"
                    />
                  </td>
                  <td class="cell-control">
                    <a class="btn btn-flat-danger btn-sm delrow" @click="deletePosition(position)">
                      <span class="icon-abui-cross" />
                    </a>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr class="addrow js-addcontrol-item" id="js-addcontrol-item-1">
                  <td colspan="4">
                    <a @click="addNewPosition" class="btn"><span class="icon-abui-plus" /></a>
                  </td>
                </tr>
              </tfoot>
            </table>
            <p v-if="checkEmpty(listPosition)" class="no-data">{{ $t('common.no_data') }}</p>
          </div>
        </div>
        <div
          class="navbar navbar-default navbar-fixed-bottom navbar-footblock with-sidebar"
          role="navigation"
          style="z-index: 990; padding: 12px"
        >
          <div class="row">
            <div class="col-sm-4 col-md-3 col-sm-offset-8 col-md-offset-9">
              <a @click="validate" class="btn btn-sm btn-block btn-primary open-alert">
                {{ $t('common.btn_create') }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ModalUploadCSV
      :dialogVisible="showModalUploadCSV"
      @cancel="showModalUploadCSV = false"
      @confirm="confirmUploadFileCSV"
      :errorValidate="errorValidate"
      :title="$t('common.title_modal_upload_file_csv')"
    />

    <ModalConfirm
      :dialogVisible="showModalConfirm"
      @cancel="showModalConfirm = false"
      @yes="confirmDelete"
      :title="$t('Q-CM-104')"
    />
  </body>
</template>
<script>
import ModalUploadCSV from '@/components/common/ModalUploadCSV';
import vClickOutside from 'click-outside-vue3';
import ModalConfirm from '@/components/common/ModalConfirm';

export default {
  name: 'Position',
  components: { ModalUploadCSV, ModalConfirm },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      isShowDropdown: false,
      showModalUploadCSV: false,
      showModalConfirm: false,
      listPosition: [],
      listPositionOfficial: [],
      itemSelected: {},
      errorValidate: [],
    };
  },
  watch: {
    showModalUploadCSV() {
      this.errorValidate = [];
    },
  },
  created() {
    this.getListPosition();
  },
  beforeRouteLeave(to, from, next) {
    if (!this.compareObject(this.listPosition, this.listPositionOfficial)) {
      const answer = window.confirm(this.$t('common.confirm_leave_page'));
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  methods: {
    async getListPosition() {
      this.startLoading();
      await this.$services.position.getListPosition(
        res => {
          this.endLoading();
          this.listPosition = res.list;
          this.listPositionOfficial = this.cloneObject(res.list);
        },
        err => {
          this.endLoading();
          this.notifyError(this.$t(err.error));
        },
      );
    },
    deletePosition(item) {
      this.itemSelected = item;
      if (item.can_delete == false) {
        this.notifyError(this.$t('position.item_is_in_used'));
        return;
      }
      this.showModalConfirm = true;
    },
    confirmDelete() {
      this.listPosition.splice(this.listPosition.indexOf(this.itemSelected), 1);
      this.showModalConfirm = false;
    },
    validate() {
      let flgValidate = true;
      let scrollPositionIndex = -1;
      let listManagementTitle = this.listPosition.map(item => {
        return item.management_title;
      });

      if (!this.checkEmpty(this.listPosition)) {
        this.listPosition.forEach((position, index) => {
          if (this.checkEmpty(position.title)) {
            flgValidate = false;
            position.isErrorTitle = true;
            if (scrollPositionIndex == -1) scrollPositionIndex = index;
          } else {
            position.isErrorTitle = false;
          }

          if (this.checkEmpty(position.management_title)) {
            flgValidate = false;
            if (scrollPositionIndex == -1) scrollPositionIndex = index;
            position.isErrorManagementTitle = true;
          } else {
            position.isErrorManagementTitle = false;
          }
        });

        this.listPosition.forEach((position, index) => {
          if (
            (listManagementTitle.indexOf(position.management_title) !== -1 &&
              listManagementTitle.indexOf(position.management_title) != index) ||
            this.checkEmpty(position.management_title)
          ) {
            flgValidate = false;
            position.isErrorManagementTitle = true;
          } else {
            position.isErrorManagementTitle = false;
          }
        });
      }
      if (!flgValidate) {
        this.scrollIntoDivCustom(`position_${scrollPositionIndex}`);
        return;
      }

      this.listPosition.forEach(position => {
        delete position.isErrorTitle;
        delete position.isErrorManagementTitle;
      });

      this.updateListPosition();
    },
    updateListPosition() {
      this.$services.position.updateListPosition(
        { data: this.listPosition },
        () => {
          this.notifySuccess(this.$t('I-CM-103'));
          this.getListPosition();
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    addNewPosition() {
      const newItem = {};
      this.listPosition.push(newItem);
    },
    downloadFileCSV() {
      this.startLoading();
      this.$services.position.downloadFileCSV(
        res => {
          this.endLoading();
          this.handleDownloadCSV(res);
        },
        err => {
          this.endLoading();
          this.notifyError(this.$t(err.error));
        },
      );
    },
    confirmUploadFileCSV(listFiles) {
      let formData = new FormData();
      if (!this.checkEmpty(listFiles.length)) {
        formData.append('file', listFiles[0]);
        this.errorValidate = [];
        this.startLoading();
        this.$services.position.uploadFileCSV(
          formData,
          () => {
            this.endLoading();
            this.showModalUploadCSV = false;
            this.getListPosition();
          },
          err => {
            this.endLoading();
            if (err.code == 422) {
              this.errorValidate = err.error;
            } else {
              this.notifyError(this.$t(err.error));
            }
          },
        );
      } else {
        this.notifyError(this.$t('E-CM-101', { name: this.$t('common.file') }));
      }
    },
    downloadExampleFileCSV() {
      this.startLoading();
      this.$services.position.downloadExampleFileCSV(
        res => {
          this.endLoading();
          this.handleDownloadCSV(res);
        },
        err => {
          this.endLoading();
          this.notifyError(this.$t(err.error));
        },
      );
    },
    handleDownloadCSV(res) {
      window.open(res.file_path, '_blank').focus();
    },
  },
};
</script>
<style scoped>
.border-error {
  border: 1px solid red;
}
.noborder {
  margin-bottom: 62px !important;
}
.input-content {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.has-error {
  border: 1px solid red;
}
</style>
