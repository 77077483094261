<template>
  <div class="expense--item">
    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <label>
            <span class="label-text">{{ $t('input.title') }}</span>
          </label>
          <input
            :disabled="isDisabled"
            maxlength="30"
            v-model="dataRequest.title"
            type="text"
            class="form-control input-sm"
          />
          <p class="error-feedback" v-text="errors.title"></p>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group">
          <!-- 費用項目 -->
          <label>
            <span class="label-text">{{ $t('input.cost_id') }}</span>
          </label>
          <div class="btn-group bootstrap-select select-block select-sm">
            <SingleGroupOptionSelect
              :disabled="isDisabled"
              v-model="dataRequest.cost_id"
              :list-data="listCostItem"
              @handle-selected-params="onChangeCostType"
              :initItem="dataRequest.cost_id"
            />
            <p class="error-feedback" v-text="errors.cost_id"></p>
          </div>
        </div>
      </div>
    </div>

    <div class="">
      <div class="col-sm-4">
        <div class="form-group">
          <!-- 金額 -->
          <label>
            <span class="label-text-1">{{ $t('input.amount_of_money') }}</span>
          </label>
          <div class="input-group">
            <span class="input-group-addon">{{ currencySelect.signal }}</span>
            <input
              :disabled="isDisabled"
              type="text"
              v-model="dataRequest.currency_amount"
              onkeydown="javascript: return event.keyCode !== 69;"
              autocomplete="off"
              class="form-control input-sm text-right"
              maxlength="14"
              @input="dataRequest.currency_amount = convertNumberFullToHalfCommon(dataRequest.currency_amount)"
              @change="convertData(dataRequest.currency_amount)"
            />
          </div>
          <p v-if="currencySelect.id && currencySelect.id != 1" class="help-block amount-in-jpy--wrap text-right">
            <span>
              <span>{{ $t('trip.yen') }}</span>
              <span class="plx">{{ calculateTotalAmount(dataRequest.currency_amount) }}</span>
              <span> {{ ` (${currencyRateSelected})` }} </span>
            </span>
          </p>
        </div>
      </div>
      <template v-if="currencySelect.id == 1">
        <div class="col-sm-4">
          <div class="form-group">
            <label>
              <span class="label-text">{{ $t('input.tax_amount') }}</span>
            </label>
            <div class="btn-group bootstrap-select select-block select-sm">
              <el-select
                :disabled="isDisabled || existCost"
                popper-class="custom-dropdown-select"
                v-model="dataRequest.tax_classification"
                :clearable="false"
              >
                <el-option
                  v-for="item in listTaxClassification"
                  :key="item.id"
                  :label="labelLocale(item)"
                  :value="item.id"
                />
              </el-select>
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group">
            <!-- 税額 -->
            <label>
              <span class="label-text">{{ $t('input.amount') }}</span>
            </label>
            <div class="input-group">
              <span class="input-group-addon">¥</span>
              <input
                type="text"
                :disabled="isDisabled"
                v-model="dataRequest.tax_amount"
                autocomplete="off"
                class="form-control input-sm text-right"
                onkeydown="javascript: return event.keyCode !== 69;"
                maxlength="14"
                @input="dataRequest.tax_amount = convertNumberFullToHalfCommon(dataRequest.tax_amount)"
                @change="convertDataTaxAmount(dataRequest.tax_amount)"
              />
            </div>
            <!-- <p v-if="currencySelect.id && currencySelect.id != 1" class="help-block amount-in-jpy--wrap text-right">
              <span>
                {{ currencySelect.signal }}<span class="plx">{{ totalAmount }}</span>
                <span> ({{ currencySelect.rate }}) </span>
              </span>
            </p> -->
          </div>
        </div>
      </template>
    </div>

    <div class="row">
      <!-- <div class="col-sm-12">
        <div class="form-group">
          <label>
            <span class="label-text">{{ $t('input.note') }}</span>
          </label>
          <textarea
            :disabled="isDisabled"
            v-model="dataRequest.note"
            class="form-control"
            rows="2"
            :placeholder="$t('input.pl_note')"
          >
          </textarea>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import SingleGroupOptionSelect from '@/components/common/SingleGroupOptionSelect.vue';

export default {
  components: {
    SingleGroupOptionSelect,
  },
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
    listCostItem: {
      type: Array,
      default: () => [],
      required: true,
    },
    currencyRateSelected: {
      type: Number,
      default: 1,
    },
    currencySelect: {
      type: Object,
      default: () => {},
      required: true,
    },
    dataForm: {
      type: Object,
      default: () => {},
    },
    listTaxClassification: {
      type: Array,
      default: () => [],
      required: true,
    },
    submitFromParent: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    totalAmount() {
      if (!this.dataRequest?.amount) return 0;
      return this.splitThousandNumber(
        (this.currencySelect.rate * String(this.dataRequest.amount).replace(/[^0-9]/g, '')).toFixed(0),
      );
    },
  },

  watch: {
    currencyRateSelected() {
      this.convertData(this.dataRequest.currency_amount);
    },
    dataForm: {
      handler: function (newValue) {
        if (!this.checkEmpty(newValue)) {
          this.dataRequest = {
            title: newValue.title,
            amount: this.splitThousandNumber(newValue.amount),
            cost_id: newValue.cost_id,
            tax_classification: newValue.tax_classification,
            tax_amount: newValue.tax_amount,
            id: newValue.id ? newValue.id : '',
            currency_amount: this.splitThousandNumber(newValue.currency_amount),
          };

          setTimeout(() => {
            this.isFirstLoadingInput = false;
          }, 120);
          return;
        }
        this.isFirstLoadingInput = true;
      },
      deep: true,
    },
    submitFromParent() {
      if (!this.checkEmpty(this.dataRequest.amount)) {
        this.dataRequest.amount =
          this.dataRequest.amount.length > 3 ? this.dataRequest.amount.replaceAll(',', '') : this.dataRequest.amount;
      }
      this.$emit('cost-emit', this.dataRequest, this.errors, this.index);
    },
    'dataRequest.tax_classification'() {
      this.updateTaxAmount();
    },
    // 'dataRequest.amount'(amount) {
    //   // this.countAmount();
    //   this.updateTaxAmount();
    //   this.$emit('listDataInput', { ...this.dataRequest, amount }, this.index);
    // },
  },
  data() {
    return {
      isFirstLoadingInput: true,
      dataRequest: {
        tax_classification: 1,
      },
      errors: {},
      TYPE: {
        INTERNATIONAL_TICKET: 1,
        DOMESTIC_TICKET: 2,
        STATION: 3,
        HOTEL: 4,
        EXPENSES: 5,
      },
      existCost: false,
    };
  },
  created() {
    this.dataRequest = this.dataForm;
  },
  methods: {
    calculateTotalAmount(totalAmount) {
      if (this.checkEmpty(totalAmount) || this.checkEmpty(this.currencyRateSelected)) return 0;
      let amount = parseInt(String(totalAmount).replaceAll(',', '')) * this.currencyRateSelected;
      if (!this.isInt(amount)) amount = parseFloat(amount).toFixed(0);
      return this.splitThousandNumber(amount);
    },
    convertData(data) {
      if (String(data).includes('.') || String(data).includes('．')) {
        if (String(data).includes('．')) {
          data = data.replaceAll('．', '.');
        }
        this.dataRequest.currency_amount = String(parseFloat(data).toFixed(2))
          .replace(/^(?!00[^0])0/, '')
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      } else {
        data = data.replaceAll(',', '');
        this.dataRequest.currency_amount = data
          .replace(/(\..*?)\..*/g, '$1')
          .replace(/^(?!00[^0])0/, '')
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
      this.dataRequest.amount = this.calculateTotalAmount(data);
      this.updateTaxAmount();
      this.$emit('listDataInput', { ...this.dataRequest }, this.index);
    },
    convertDataTaxAmount(data) {
      if (String(data).includes('.')) {
        this.dataRequest.tax_amount = String(parseFloat(data).toFixed(2))
          .replace(/^(?!00[^0])0/, '')
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      } else {
        data = data.replaceAll(',', '');
        this.dataRequest.tax_amount = data
          .replace(/(\..*?)\..*/g, '$1')
          .replace(/^(?!00[^0])0/, '')
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    },
    onChangeCostType(id) {
      this.dataRequest.cost_id = id;
      this.listCostItem.forEach(parent => {
        parent.data.forEach(item => {
          if (item.id === id) {
            this.dataRequest.tax_classification = item.tax_type;
          }
        });
      });
    },
    countAmount() {
      let amount = !this.checkEmpty(this.dataRequest?.amount) ? this.convertToFloatNumber(this.dataRequest?.amount) : 0;
      let sum = parseInt(amount);
      this.$emit('amount-emit', sum, this.index);
    },
    countTaxAmount() {
      let taxAmount = !this.checkEmpty(this.dataRequest?.amount)
        ? this.convertToFloatNumber(this.dataRequest?.amount)
        : 0;
      let sum = parseInt(taxAmount) + this.convertToFloatNumber(this.dataRequest.amount);
      this.$emit('amount-emit', sum, this.index);
    },
    updateTaxAmount() {
      if (this.isFirstLoadingInput) {
        this.dataRequest.tax_amount = this.splitThousandNumber(this.dataRequest.tax_amount);
        return;
      }
      const taxValue = this.listTaxClassification.find(item => item.id == this.dataRequest.tax_classification);
      if (taxValue) {
        let amount = !this.checkEmpty(this.dataRequest?.amount)
          ? this.convertToFloatNumber(this.dataRequest?.amount)
          : 0;
        let percentNow = 1 + taxValue.percent;
        let result = amount / percentNow;
        let taxAmount = Math.round(amount - result);
        this.dataRequest.tax_amount = String(taxAmount).length > 3 ? this.splitThousandNumber(taxAmount) : taxAmount;
      }
    },
    pickerFromOptions(time) {
      if (!this.dataRequest.start_date)
        return moment(new Date(time)).format('YYYY-MM-DD') < moment(new Date()).format('YYYY-MM-DD');

      return moment(new Date(time)).format('YYYY-MM-DD') > moment(this.dataRequest?.end_date).format('YYYY-MM-DD');
    },
    pickerToOptions(time) {
      if (!this.dataRequest.start_date)
        return moment(new Date(time)).format('YYYY-MM-DD') < moment(new Date()).format('YYYY-MM-DD');

      return moment(new Date(time)).format('YYYY-MM-DD') < moment(this.dataRequest?.start_date).format('YYYY-MM-DD');
    },

    pickerFromOptionsCheckInTime(time) {
      return moment(new Date(time)).format('YYYY-MM-DD') > moment(this.dataRequest?.checkout_time).format('YYYY-MM-DD');
    },
    pickerToOptionsCheckOutTime(time) {
      if (!this.dataRequest.checkin_time)
        return (
          moment(new Date(time)).format('YYYY-MM-DD') <
          moment(new Date(this.dataRequest.checkin_time)).format('YYYY-MM-DD')
        );

      return moment(new Date(time)).format('YYYY-MM-DD') < moment(this.dataRequest?.checkin_time).format('YYYY-MM-DD');
    },
  },
};
</script>
<style scoped>
.label-text-1 {
  margin-left: -10px;
}
</style>
