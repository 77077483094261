export const APPROVAL_OR_REPORT = 1;
export const EXPENSE_REIMBURSEMENT = 2;
export const IS_APPLICANT_ONLY = 1;
export const IS_APPROVER_ONLY = 2;
export const IS_APPROVER_AND_APPLICANT = 3;
export const DES_TYPE_DOMESTIC = 1;
export const DES_TYPE_ABROAD = 2;
export const DES_TYPE_OTHER = 3;
export const TRIP_TYPE_DOMESTIC = 1;
export const TRIP_TYPE_ABROAD = 2;
export const DEFAULT_APPROVAL = [1, 2, 3, 4, 5, 7];
export const DEFAULT_REPORTING = [1, 2, 3, 4, 5];
export const DEFAULT_EXPENSE = [1, 2, 3, 4];
