<template>
  <nav class="navbar navbar-default navbar-lg" role="navigation" id="header-content">
    <div class="navbar-header">
      <h2 class="navbar-title">{{ $t('sidebar.CIQ_settings') }}</h2>
    </div>
  </nav>

  <div class="wrapper wrapper with-navbar-fixed-bottom">
    <div class="container-fluid">
      <div class="">
        <div class="">
          <div class="section noborder">
            <div class="table-responsive">
              <table class="table table-hover table--ciq-list table-hover--pointer">
                <colgroup span="1" style="width: 8.33%"></colgroup>
                <colgroup span="1" style=""></colgroup>
                <thead>
                  <tr>
                    <th class="table-sort" @click="sortAction('country_code')">
                      <span :class="sortActive.column == 'country_code' ? sortActive.by : 'asc'">
                        {{ $t('setting_ciq.country_code') }}
                      </span>
                    </th>
                    <th class="table-sort table-sort-active" @click="sortAction('country_name')">
                      <span :class="sortActive.column == 'country_name' ? sortActive.by : 'asc'">
                        {{ $t('setting_ciq.country_name') }}
                      </span>
                    </th>
                    <th class="text-right">{{ $t('setting_ciq.last_updated') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(ciq, index) in listCiq" :key="index" @click="onRedirectCiqItemPage(ciq.id)">
                    <td class="">{{ ciq.countries?.code }}</td>
                    <td class="">{{ labelLocale(ciq.countries) }}</td>
                    <td class="last-update">{{ formatCreateAt(ciq.updated_at) }} {{ ciq?.updated_by?.name || '' }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
export default {
  name: 'setting-CIQ',
  components: {},
  data() {
    return {
      sortActive: {
        column: 'country_name',
        by: 'asc',
      },
      listCiq: [],
    };
  },
  computed: {},
  created() {
    this.getListCiq();
  },
  methods: {
    formatCreateAt(value) {
      return moment(value).format('YYYY/MM/DD');
    },
    sortAction(name) {
      this.sortActive.column = name;
      if (this.sortActive.by == 'dsc') this.sortActive.by = 'asc';
      else this.sortActive.by = 'dsc';

      this.getListCiq();
    },
    async getListCiq() {
      const dataSearch = {
        sort_column: this.sortActive.column,
        sort_by: this.sortActive.by == 'dsc' ? 'desc' : 'asc',
      };
      this.startLoading();
      await this.$services.setting_CIQ.getListCiq(
        dataSearch,
        response => {
          this.endLoading();
          this.listCiq = response.list;
        },
        err => {
          this.endLoading();
          this.notifyError(this.$t(err.error));
        },
      );
    },
    onRedirectCiqItemPage(id) {
      this.$router.push({
        name: this.PAGES.SETTINGS_CIQ_DETAIL,
        params: {
          id: id,
        },
      });
    },
  },
};
</script>
