<template>
  <el-dialog
    v-model="dialogVisibleStatus"
    :show-close="false"
    @close="$emit('cancel')"
    width="642px"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-header--title">{{ title }}</div>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <input
                type="file"
                :multiple="multiple ? 'multiple' : false"
                name="file"
                style="display: none"
                @change="onFileChange"
                ref="inputFile"
              />
              <div
                class="dropzone cursor-pointer"
                @click="chooseFile()"
                :data-active="false"
                @dragenter.prevent="true"
                @dragover.prevent="true"
                @dragleave.prevent="false"
                @drop.prevent="onDrop"
              >
                <div v-if="listFile.length == 0" class="dz-default dz-message">
                  <span>
                    <span class="icon icon-abui-file"></span>
                    {{ $t('common.drop_file_here') }}
                    <small>{{ $t('common.click_to_specify_the_location') }}</small>
                  </span>
                </div>
                <div v-else>
                  <div v-for="(item, index) in listFile" :key="index" class="dz-preview dz-complete dz-image-preview">
                    <div class="dz-image" v-on:click.stop>
                      <span class="icon icon-abui-file"></span>
                    </div>
                    <div class="dz-details" v-on:click.stop>
                      <div class="dz-size">
                        <span data-dz-size="">
                          <strong>{{ getSize(item.size) }}</strong> MB
                        </span>
                      </div>
                      <div class="dz-filename">
                        <span data-dz-name="">{{ item.name }}</span>
                      </div>
                    </div>
                    <div class="dz-success-mark" v-on:click.stop>
                      <img src="@/assets/images/dz-success-mark.svg" alt="" />
                    </div>
                    <a class="dz-remove" @click="removeFile(index)" v-on:click.stop></a>
                  </div>
                </div>
              </div>
              <p role="alert" class="error-feedback" v-text="errors.file"></p>
              <div class="error-validate">
                <template v-for="(err, lineNumber) in incrementKeyErrorValidate" :key="lineNumber">
                  <template v-for="(errRow, nameColumn) in err" :key="nameColumn">
                    <template v-if="nameColumn == '承認ユーザ番号' && errRow[0] !== 'E-CM-100'">
                      <div v-for="(errRowName, indexErrorName) in errRow" :key="indexErrorName">
                        <p
                          role="alert"
                          class="error-feedback"
                          v-for="(errDetail, indexDetail) in errRowName"
                          :key="indexDetail"
                        >
                          {{
                            ($t('line') === 'Line' ? $t('line') + lineNumber : lineNumber + $t('line')) +
                            nameColumn + indexErrorName +
                            ((errDetail.split(' ').length > 1) ? $t(errDetail.split(' ')[0], { size: errDetail.split(' ')[1] }) : $t(errDetail))
                          }}
                        </p>
                      </div>
                    </template>
                    <div v-else
                      role="alert"
                      class="error-feedback"
                      v-for="(errDetail, indexDetail) in errRow"
                      :key="indexDetail"
                    >
                      <p v-if="errDetail === 'E-CM-118' || errDetail === 'E-CM-124'"
                        class="error-feedback"
                      >
                        {{ $t(errDetail, { 'approval_flow_name': lineNumber, 'step': nameColumn }) }}
                      </p>
                      <p v-else
                        class="error-feedback"
                      >
                        {{
                          ($t('line') === 'Line' ? $t('line') + lineNumber : lineNumber + $t('line')) +
                          nameColumn +
                          ((errDetail.split(' ').length > 1) ? $t(errDetail.split(' ')[0], { size: errDetail.split(' ')[1] }) : $t(errDetail))
                        }}
                      </p>
                    </div>
                  </template>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="row">
            <div class="col-xs-6 col-sm-6 col-md-6">
              <button class="btn btn-sm btn-flat-default btn-block" data-dismiss="modal" @click="$emit('cancel')">
                {{ $t('common.cancel') }}
              </button>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6">
              <button class="btn btn-sm btn-primary btn-block" id="btn-do-upload" @click="submit()">
                {{ $t('common.confirm') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<style>
.dz-image .icon {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
<script>
import { ElDialog } from 'element-plus';
import { MAX_FILE_SIZE } from '@/constants/';
export default {
  components: {
    ElDialog,
  },
  name: 'ModalUploadCSV',
  emits: ['confirm', 'cancel', 'files-dropped'],
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    errorValidate: {
      type: [Array, Object],
      default: () => [] || {},
    },
  },
  watch: {
    dialogVisible() {
      this.dialogVisibleStatus = this.dialogVisible;
      if (this.dialogVisible) {
        this.listFile = [];
      }
    },
  },
  data() {
    return {
      dialogVisibleStatus: false,
      listFile: [],
      errors: {},
    };
  },
  computed: {
    incrementKeyErrorValidate() {
      const currentKey = Object.keys(this.errorValidate)[0];
      const newKey = parseInt(currentKey) + 1;
      
      return {
        [newKey]: { ...this.errorValidate[currentKey] }
      };
    }
  },
  methods: {
    chooseFile() {
      this.$refs.inputFile.click();
    },
    removeFile(index) {
      this.listFile.splice(index, 1);
    },
    getSize(size) {
      return (size / (1024 * 1024)).toFixed(2);
    },
    async onFileChange(e) {
      this.handleUploadFile(e.target);
    },
    onDrop(e) {
      this.handleUploadFile(e.dataTransfer);
    },
    submit() {
      if (this.checkEmpty(this.listFile)) {
        this.errors.file = this.$t('E-CM-100', { item: this.$t('common.file') });
      } else {
        this.errors.file = '';
        this.$emit('confirm', this.listFile);
      }
    },
    handleUploadFile(data) {
      const files = data.files;
      this.errors.file = '';

      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        if (file?.type && this.isTypeCSV(file.type)) {
          if (file.size <= MAX_FILE_SIZE) {
            if (!this.multiple) this.listFile = [];
            this.listFile.push(file);
          } else {
            this.notifyError(this.$t('E-CU-223'));
          }
        } else {
          this.notifyError(this.$t('E-CU-222'));
        }
      }
      this.$refs.inputFile.value = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.error-validate {
  max-height: 250px;
  overflow-y: auto;
  p {
    margin-bottom: 0px;
  }
}
</style>
