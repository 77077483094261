<template>
  <div class="custom-nav-footer" role="navigation">
    <div class="row" style="display: flex; justify-content: end">
      <div v-if="!isStepView" class="col-sm-3 col-md-2 col-sm-offset-3 col-md-offset-5">
        <button
            v-if="isShowBtnCancelRequest"
            @click="showCancelRequest"
            class="btn btn-sm btn-block btn-inverse js-btn--suspend-ringi js-btn--group"
            id="js-btn--suspend"
        >
          {{ $t('trip_update.btn_cancel_ringi_expense') }}
        </button>
      </div>
      <div v-if="!isStepView" class="col-sm-3 col-md-2">
        <button
            v-if="(requestDetail?.approval_status_id === 4 ||
          requestDetail?.report_status_id === 4 ||
          requestDetail?.expense_status_id === 3)"
            @click="$emit('submitRemoveHold')"
            class="btn btn-sm btn-block btn-inverse js-btn--suspend-ringi js-btn--group"
            id="js-btn--suspend"
            :disabled="!isCurrentStepUser"
        >
          {{ $t('trip_item.footer_buttons.btn_remove_suspend') }}
        </button>
        <button
            v-else
            @click="showSuspendRingi"
            class="btn btn-sm btn-block btn-inverse js-btn--suspend-ringi js-btn--group"
            id="js-btn--suspend"
            :disabled="!canSubmit"
        >
          {{ $t('trip_item.footer_buttons.btn_suspend') }}
        </button>
      </div>
      <div v-if="!isStepView" class="col-sm-3 col-md-2">
        <button
            @click="showRejectModal"
            class="btn btn-sm btn-block btn-inverse js-btn--group"
            id="js-btn--reject"
            :disabled="!canSubmit"
        >
          {{ $t('trip_item.footer_buttons.btn_remand') }}
        </button>
      </div>
      <div class="col-sm-3 col-md-3">
        <button
            @click="showApproveModal"
            class="btn btn-sm btn-block btn-primary js-btn--group"
            id="js-btn--approve"
            :disabled="!canSubmit"
        >
          {{
            isStepView
                ? $t('trip_item.footer_buttons.btn_approve_view_step')
                : $t('trip_item.footer_buttons.btn_approve')
          }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'FooterButtons',
  emits: ['showSuspendRingi', 'showApproveModal', 'showRejectModal', 'submitRemoveHold'],
  props: {
    canSubmit: {
      type: Boolean,
      default: false,
    },
    isCurrentUserHolding: {
      type: Boolean,
      default: false,
    },
    isCurrentStepUser: {
      type: Boolean,
      default: false,
    },
    isShowBtnCancelRequest: {
      type: Boolean,
      default: false,
    },
    isStepView: {
      type: Boolean,
      default: false,
    },
    requestDetail: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  computed: {
    userRole() {
      return this.$store.state.auth?.user?.role || '';
    },
  },
  methods: {
    showCancelRequest() {
      this.$emit('showCancelModal');
    },
    showApproveModal() {
      this.$emit('showApproveModal');
    },
    showSuspendRingi() {
      this.$emit('showSuspendRingi');
    },
    showRejectModal() {
      this.$emit('showRejectModal');
    },
  },
};
</script>
<style scoped>
.custom-nav-footer {
  z-index: 1060;
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 12px 12px !important;
  opacity: 1;
  background-color: white;
  border-top: 1px solid #d0d6da !important;
  margin-bottom: 0;
  border-width: 0;
  border-radius: 0;
  min-height: 48px;
  border: none;
  font-size: 14px;
}
.js-btn--group[disabled] {
  border: 1px solid #657a84;
  border-radius: 2px;
}
</style>
