<template>
  <ul class="nav mtm">
    <li v-for="(route, index) in listRouterActive" :key="index">
      <p v-if="route.dividerTop" class="divider"></p>

      <template v-if="route.children && route.children.length > 0">
        <el-tooltip
          popper-class="tool-tip-guide"
          class="label-icon-guide"
          effect="dark"
          :content="route.name"
          placement="right"
        >
          <span
            class="expander expander-trigger expander-children"
            :class="menuOpen == index && 'open'"
            @click="menuOpen = menuOpen == index ? '' : index"
          >
            <span class="caret"></span>
          </span>

          <span class="expander-trigger cursor-pointer" @click="menuOpen = menuOpen == index ? '' : index">
            <i class="prx" :class="route.icon"></i>
            <span class="sidebar-text" :class="classActive(route)">{{ route.name }}</span>
          </span>
        </el-tooltip>
        <!-- <span
          v-else
          class="expander expander-trigger expander-children"
          :class="menuOpen == index && 'open'"
          @click="menuOpen = menuOpen == index ? '' : index"
        >
          <span class="caret"></span>
        </span> -->

        <span class="expander-trigger cursor-pointer" @click="menuOpen = menuOpen == index ? '' : index">
          <i class="prx" :class="route.icon"></i>
          <span class="sidebar-text" :class="classActive(route)">{{ route.name }}</span>
        </span>
        <ul v-show="menuOpen == index" class="expander-target">
          <li
            v-for="(child, i) in route.children"
            class="js-sidenav-tooltip"
            :key="i"
            @click="$router.push(child.path)"
            :class="{'active': currentPath == child.path, 'en-sidebar': $i18n.locale == 'en'}"
          >
            <el-tooltip
              popper-class="tool-tip-guide"
              class="label-icon-guide"
              effect="dark"
              :content="child.name"
              placement="right"
            >
              <!-- <i class="sidebar-text" :class="classActive(child)">{{ child.name }}</i> -->
              <a href="#" :class="classActive(child)" class="sidebar-text sidebar-child">{{ child.name }}</a>
            </el-tooltip>
            <!-- <div v-else>
              <i class="sidebar-text" :class="classActive(child)">{{ child.name }}</i>
              <a href="#" :class="classActive(child)" class="sidebar-text sidebar-child">{{ child.name }}</a>
            </div> -->
          </li>
        </ul>
      </template>
      <div v-else>
        <el-tooltip
          popper-class="tool-tip-guide"
          class="label-icon-guide"
          effect="dark"
          :content="route.name"
          placement="right"
        >
          <a
            href="#"
            @click="
              () => {
                $router.push(route.path);
                this.menuOpen = '';
              }
            "
            :class="classActive(route)"
            class="js-sidenav-tooltip"
          >
            <i class="prx" :class="route.icon" />
            <span class="sidebar-text" :class="classActive(route)">{{ route.name }}</span>
          </a>
        </el-tooltip>
        <!-- <a
          v-else
          href="#"
          @click="
            () => {
              $router.push(route.path);
              this.menuOpen = '';
            }
          "
          :class="classActive(route)"
          class="js-sidenav-tooltip"
        >
          <i class="prx" :class="route.icon" />
          <span class="sidebar-text" :class="classActive(route)">{{ route.name }}</span>
        </a> -->
      </div>
      <p v-if="route.divider" class="divider"></p>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'Sidebar',
  data() {
    return {
      menuOpen: '',
      listRouter: [
        {
          name: this.$t('sidebar.dashboard'),
          path: this.PAGES.DASHBOARD,
          icon: 'icon-abui-dashboard',
          role_access: [
            'employee_using_app',
            'employee_no_authority',
            'employee_approval',
            'employee_manager',
            'employee_approval_management',
            'employee_manager_reference',
          ],
        },
        {
          name: this.$t('sidebar.trip'),
          path: this.PAGES.TRIP,
          icon: 'icon-abui-globe',
          role_access: [
            'employee_no_authority',
            'employee_approval',
            'employee_manager',
            'employee_approval_management',
            'employee_manager_reference',
          ],
        },
        {
          name: this.$t('sidebar.application'),
          path: this.PAGES.APPLICATION,
          icon: 'icon-abui-file-check',
          role_access: [
            'employee_no_authority',
            'employee_approval',
            'employee_manager',
            'employee_approval_management',
            'employee_manager_reference',
          ],
        },
        {
          name: this.$t('sidebar.expense'),
          path: this.PAGES.EXPENSE,
          icon: 'icon-abui-jpy',
          role_access: [
            'employee_no_authority',
            'employee_approval',
            'employee_manager',
            'employee_approval_management',
            'employee_manager_reference',
          ],
        },
        {
          name: this.$t('sidebar.crisis_management'),
          path: this.PAGES.CRISIS_MANAGEMENT,
          icon: 'icon-abui-shield',
          role_access: [
            'employee_manager',
            ],
          divider: true,
        },
        {
          name: this.$t('sidebar.crisis_management'),
          path: this.PAGES.CRISIS_MANAGEMENT,
          icon: 'icon-abui-shield',
          role_access: [
            'employee_manager_reference',
            ],
        },
        {
          dividerTop: this.$store.state.auth?.user?.setting.is_show_kikikanri ? false : true,
          name: this.$t('sidebar.employee'),
          path: this.PAGES.EMPLOYEE,
          icon: 'icon-abui-user',
          role_access: ['employee_manager'],
        },
        {
          dividerTop: true,
          name: this.$t('sidebar.employee'),
          path: this.PAGES.PROFILE,
          icon: 'icon-abui-user',
          role_access: [
            'employee_using_app',
            'employee_no_authority',
            'employee_approval',
            'employee_approval_management',
            'employee_manager_reference',
          ],
        },
        {
          name: this.$t('sidebar.organization'),
          path: this.PAGES.ORGANIZATION,
          icon: 'icon-abui-organization prx',
          role_access: ['employee_manager'],
        },
        {
          name: this.$t('sidebar.position'),
          path: this.PAGES.POSITION,
          icon: 'icon-abui-award prx',
          role_access: ['employee_manager'],
        },
        {
          name: this.$t('sidebar.procedure'),
          icon: 'icon-abui-file-line prx',
          role_access: ['employee_manager'],
          children: [
            {
              name: this.$t('sidebar.procedure'),
              icon: 'icon-abui-file-line prx',
              path: this.PAGES.REGULATION,
              role_access: ['employee_manager'],
            },
            {
              name: this.$t('sidebar.regulation_area'),
              icon: 'icon-abui-file-line prx',
              path: this.PAGES.REGULATION_AREA,
              role_access: ['employee_manager'],
            },
          ],
        },
        {
          name: this.$t('sidebar.daily_allowance'),
          icon: 'icon-abui-file-jpy',
          role_access: ['employee_manager', 'employee_approval_management'],
          children: [
            {
              name: this.$t('sidebar.daily_allowance'),
              icon: 'icon-abui-file-jpy',
              path: this.PAGES.DAILY_ALLOWANCE,
              role_access: ['employee_manager', 'employee_approval_management'],
            },
            {
              name: this.$t('sidebar.zone'),
              icon: 'icon-abui-file-jpy',
              path: this.PAGES.ZONE,
              role_access: ['employee_manager', 'employee_approval_management'],
            },
          ],
        },
        {
          name: this.$t('sidebar.budget'),
          path: this.PAGES.BUDGET,
          icon: 'icon-abui-calculator prx',
          role_access: ['employee_manager', 'employee_approval_management'],
        },
        {
          name: this.$t('sidebar.expense_item'),
          path: this.PAGES.EXPENSE_ITEM,
          icon: 'icon-abui-jpy',
          role_access: ['employee_manager', 'employee_approval_management'],
        },
        {
          name: this.$t('sidebar.approval_flow'),
          path: this.PAGES.APPROVAL_FLOW,
          icon: 'icon-abui-flow',
          role_access: ['employee_manager'],
        },
        {
          dividerTop: true,
          name: this.$t('sidebar.settings'),
          path: this.PAGES.SETTINGS,
          icon: 'icon-abui-gear',
          role_access: ['employee_manager', 'employee_approval_management'],
          divider: true,
        },
      ],
      listRouterManager: [
        {
          name: this.$t('sidebar.input'),
          path: this.PAGES.INPUT,
          icon: 'icon-abui-write prx',
          role_access: ['admin', 'operator'],
        },
        {
          name: this.$t('sidebar.regulation_check'),
          path: this.PAGES.REGULATION_CHECK,
          icon: 'icon-abui-file-line prx',
          role_access: ['admin', 'operator'],
        },
        {
          name: this.$t('sidebar.requirement'),
          path: this.PAGES.REQUIREMENT,
          icon: 'icon-abui-member prx',
          role_access: ['admin', 'operator'],
        },
        {
          name: this.$t('sidebar.notification'),
          path: this.PAGES.NOTIFICATION,
          icon: 'icon-abui-bell prx',
          role_access: ['admin', 'operator'],
          divider: true,
        },
        {
          name: this.$t('sidebar.trip'),
          path: this.PAGES.TRIP,
          icon: 'icon-abui-globe',
          role_access: ['admin', 'operator'],
        },
        {
          name: this.$t('sidebar.expense'),
          path: this.PAGES.EXPENSE,
          icon: 'icon-abui-jpy',
          role_access: ['admin', 'operator'],
        },
        {
          name: this.$t('sidebar.crisis_management'),
          path: this.PAGES.CRISIS_MANAGEMENT,
          icon: 'icon-abui-shield',
          role_access: ['admin', 'operator'],
          divider: true,
        },
        {
          name: this.$t('sidebar.customer_enterprise'),
          path: this.PAGES.CUSTOMER_ENTERPRISE,
          icon: 'icon-abui-company prx',
          role_access: ['admin', 'operator'],
        },
        {
          name: this.$t('sidebar.CIQ_settings'),
          path: this.PAGES.SETTINGS_CIQ,
          icon: 'icon-abui-globe-asia prx',
          role_access: ['admin', 'operator'],
        },
        {
          name: this.$t('sidebar.user'),
          path: this.PAGES.USER,
          icon: 'icon-abui-user',
          role_access: ['admin'],
        },
      ],
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
      // isResponsive: false,
    };
  },
  // mounted() {
  //   window.addEventListener('resize', this.getDimensions);
  // },
  // unmounted() {
  //   window.removeEventListener('resize', this.getDimensions);
  // },
  computed: {
    currentPath() {
      return this.$route.path;
    },
    listRouterActive() {
      let listRouter = ['admin', 'operator'].includes(this.userRole) ? this.listRouterManager : this.listRouter;
      this.listRouter.forEach((item, index) => {
        if (item.path == '/employee') {
          this.listRouter[index]['dividerTop'] = this.isShowSettingKikikanri ? 0 : 1;
        }
      });
      listRouter = listRouter.filter(route => {
        if (((!this.isShowSettingBudget && route.path != '/budget') || this.isShowSettingBudget))  return route;
      })

      listRouter = listRouter.filter(route => {
        if (((!this.isShowSettingKikikanri && route.path != '/crisis-management') || this.isShowSettingKikikanri))  return route;
      })

      listRouter = listRouter.filter(route => {
        if (((this.isShowSettingApproval || this.showApprovalExpense) && route.path == '/request') || route.path != '/request')  return route;
      })


      listRouter = listRouter.filter(route => {
        if (((this.isShowSettingApproval || this.showApprovalExpense) && route.path == '/approval-flow') || route.path != '/approval-flow')  return route;
      })

      return listRouter.filter(route => {
        if (
            (!this.showApprovalExpense && route.path != '/expense/' && route.path != '/budget') ||
            this.showApprovalExpense ||
            ['admin', 'operator'].includes(this.userRole)
        )
        {
          if (!route?.role_access) return route;
          if (route.role_access && route.role_access.includes(this.userRole)) return route;
        }
      });
    },
    isShowSettingBudget() {
      return this.$store.state.auth?.user?.setting.is_show_setting_budget || false;
    },
    isShowSettingKikikanri() {
      return this.$store.state.auth?.user?.setting.is_show_kikikanri || false;
    },
    isShowSettingApproval() {
      return this.$store.state.auth?.user?.setting.is_show_approval || false;
    },
    showApprovalExpense() {
      return this.$store.state.auth?.user?.setting?.is_feature_payment || false;
    },
  },
  methods: {
    // getDimensions() {
    //   this.width = document.documentElement.clientWidth;
    //   this.height = document.documentElement.clientHeight;
    //   this.isResponsive = this.width < 1366;
    // },
    classActive(route) {
      // Dashboard path is '/'
      if (route.path == '/' && this.currentPath == route.path) return 'active';

      if (route.path != '/' && this.currentPath.indexOf(route.path) !== -1) return 'active';

      return '';
    },
  },
};
</script>
