import BaseService from './BaseService';
import API_CODE from '../utils/api_code.js';

export default class RequirementAPI extends BaseService {
  async getListRequirement(params, query, success, error) {
    await this.get(API_CODE.OP_165 + this.urlParse(params, query), success, error);
  }
  async getListRequirementStatus(success, error) {
    await this.get(API_CODE.OP_164, success, error);
  }
  async getListCountry(success, error) {
    await this.get(API_CODE.CU_069, success, error);
  }
  async getRequirementDetail(id, success, error) {
    await this.get(API_CODE.OP_195 + id, success, error);
  }
  async createRequirement(params, success, error) {
    await this.post(API_CODE.OP_196, params, success, error);
  }
  async updateRequirement(params, success, error) {
    await this.post(API_CODE.OP_197 + params.id, params, success, error);
  }
  async deleteRequirement(id, success, error) {
    await this.delete(API_CODE.OP_198 + id, {}, success, error);
  }
  async sendNotifyRequirement(params, success, error) {
    await this.post(API_CODE.OP_199, params, success, error);
  }
}
