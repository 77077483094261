<template>
  <nav class="navbar navbar-default navbar-lg" role="navigation" id="header-content">
    <div class="navbar-header">
      <h2 class="navbar-title">{{ $t('security.security') }}</h2>
    </div>
    <div class="collapse navbar-collapse" style="padding-right: 0px; padding-left: 0px">
      <ul v-if="!isOperatorOrAdmin" class="nav navbar-nav navbar-right">
        <li>
          <a href="#" @click="downloadFileCSV">
            <span class="icon-abui-download prx" />{{ $t('common.download_in_csv') }}
          </a>
        </li>
      </ul>
    </div>
  </nav>

  <body class="body-content">
    <el-dialog
      v-model="isShowModalEmergencyContact"
      :show-close="false"
      width="640px"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <div class="modal-header--title">{{ $t('security.emergency_contact_information') }}</div>
          </div>
          <div class="modal-body">
            <div class="js-addcontrol1--wrap mbl" v-if="selectedEmergencyContacts?.length > 0">
              <div class="row js-addcontrolemergency--1">
                <div class="col-sm-8">
                  <div class="form-group viewonly">
                    <label class="label--name">
                      <span class="label-text">{{ $t('security.name') }}</span>
                    </label>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group viewonly">
                    <label class="label--price">
                      <span class="label-text">{{ $t('security.telephone_number') }}</span>
                    </label>
                  </div>
                </div>
              </div>
              <div
                v-for="(emergency, index) in selectedEmergencyContacts"
                :key="index"
                class="row js-addcontrolemergency--1"
              >
                <div class="col-sm-8">
                  <div class="form-group viewonly">
                    <p class="viewonly-text input-sm">{{ emergency.name }}</p>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group viewonly">
                    <p class="viewonly-text input-sm">{{ emergency.phone }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <p class="no-data">{{ $t('common.no_data') }}</p>
            </div>
          </div>
          <div class="modal-footer">
            <div class="row">
              <div class="col-sm-12">
                <button class="btn btn-sm btn-flat-default btn-block" @click="isShowModalEmergencyContact = false">
                  {{ $t('common.close') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>

    <el-dialog
      v-model="isShowModalReplyOutside"
      :show-close="false"
      @close="onCancelModalConfirm"
      width="640px"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <div class="modal-header--title"></div>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <textarea
                    class="form-control"
                    rows="4"
                    :placeholder="$t('common.comment')"
                    v-model.trim="answerMessage"
                    maxlength="200"
                  />
                  <p role="alert" class="error-feedback" v-text="errors.answerMessage"></p>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="row">
              <div class="col-xs-6 col-sm-6 col-md-6">
                <button class="btn btn-sm btn-flat-default btn-block" @click="onCancelModalConfirm">
                  {{ $t('common.cancel') }}
                </button>
              </div>
              <div class="col-xs-3 col-sm-3 col-md-3">
                <button
                  class="btn btn-sm btn-danger btn-block js--btn-do-reply-outside open-alert"
                  @click="makeConfirmed(3)"
                >
                  {{ $t('regulation_check.have_problem') }}
                </button>
              </div>
              <div class="col-xs-3 col-sm-3 col-md-3">
                <button
                  class="btn btn-sm btn-success btn-block js--btn-do-reply-outside open-alert"
                  @click="makeConfirmed(2)"
                >
                  {{ $t('regulation_check.no_problem') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>

    <div class="wrapper-page">
      <div class="wrapper">
        <div class="container-fluid">
          <div v-show="!isExpandMap">
            <div class="trip-info-col bg-gray col-expandable" style="z-index: 1">
              <button class="btn btn-sm btn-flat-default btn-col-expand" @click="isExpandMap = true">
                <i class="icon-abui-expand" />
              </button>
              <div class="security--map hide--new">
                <Map
                  :selectedMember="selectedMember"
                  :listMembers="listMembers"
                  :countrySafety="safetyInfo.countrySafety"
                  :isDisplayMap="isDisplayMap"
                  @showEmergencyContact="showEmergencyContact"
                />
              </div>
            </div>

            <div class="plan-stage">
              <div class="section noborder" id="">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="plan-title">
                      <div class="form-group">
                        <div class="input-with-icon">
                          <input
                            type="text"
                            class="form-control"
                            id="plan-title--input"
                            :placeholder="$t('security.give_safety_confirmation_list_name')"
                            v-model.trim="safetyInfo.title"
                            maxlength="100"
                            :disabled="isOperatorOrAdmin"
                            @change="updateSafetyInfo"
                            style="width: calc(100% - 24px)"
                          />
                          <span class="input-icon icon-abui-pencil" />
                        </div>
                        <p role="alert" class="error-feedback" v-text="errors.title"></p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row hide--edit show--view">
                  <div class="col-sm-12">
                    <div class="form-group viewonly">
                      <label>
                        <span class="label-text">{{ $t('common.target_country') }}</span>
                      </label>
                      <p class="viewonly-text input-sm">{{ targetCountry }}</p>
                    </div>
                  </div>
                </div>
                <div class="row hide--edit show--view">
                  <div class="col-sm-12">
                    <div class="form-group viewonly">
                      <label>
                        <span class="label-text">{{ $t('security.target_period') }}</span>
                      </label>
                      <p class="viewonly-text input-sm">{{ safetyInfo.target_period }}</p>
                    </div>
                  </div>
                </div>
                <hr style="margin-bottom: 8px" />
                <div class="result hide--new">
                  <div style="display: flex; align-items: center; justify-content: space-between">
                    <h5 class="">{{ $t('security.target_person') }}</h5>
                    <div class="count-safty hide--new">
                      <span class="count-safty--label">{{ $t('security.safety_confirmed') }}</span>
                      <span class="count-safty--value text-info">{{ countSafetyMember }}</span>
                      <span class="count-safty--value text-danger">{{ countNotSafetyMember }}</span>
                      <span class="count-safty--divider" />
                      <span class="count-safty--value">{{ countTotalMember }}</span>
                    </div>
                  </div>
                  <div>
                    <div class="table-responsive" style="padding-bottom: 100px">
                      <table class="table table-hover table-hover--pointer" id="list-security">
                        <thead>
                          <tr>
                            <th>{{ $t('security.family_name') }}</th>
                            <th>
                              <span class="inline-guide" id="inline-guide--2">
                                <span class="label-text">{{ $t('security.place') }}</span>
                                <el-tooltip
                                  class="label-icon-guide"
                                  :content="$t('security.tooltip_content')"
                                  raw-content
                                  popper-class="tool-tip-guide"
                                  placement="top"
                                >
                                  <span class="label-icon-guide" />
                                </el-tooltip>
                              </span>
                            </th>
                            <th>{{ $t('security.plan_stay_period') }}</th>
                            <th>{{ $t('security.answer_day') }}</th>
                            <th>{{ $t('security.answer') }}</th>
                            <th>{{ $t('security.journey') }}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(item, index) in safetyInfo.members" :key="index" @click="selectMember(item)">
                            <td>
                              {{
                                item.employee_number +
                                ' ' +
                                (item.member_name ? item.member_name : item.full_name ? item.full_name : '')
                              }}
                            </td>
                            <td>
                              <span v-html="getListPlaceInformation(item)" />
                            </td>
                            <td>
                              <span v-html="getLengthOfStayInformation(item)" />
                            </td>
                            <td>
                              {{ getAnswerDay(item) }}
                            </td>
                            <td v-if="isOperatorOrAdmin">
                              <i
                                v-if="item.answer_type == 2 || item.answer_type == 3"
                                class="icon-abui-chat-dots"
                                :class="item.answer_type == 2 ? 'ok' : 'ng'"
                              />
                              <span v-else-if="item.answer_type == 6" class="text-muted">
                                {{ $t('create_security.unsent') }}
                              </span>
                              <div v-else class="btn-group" v-on:click.stop>
                                <button
                                  :disabled="isOperatorOrAdmin"
                                  type="button"
                                  class="btn btn-sm btn-flat-default dropdown-toggle"
                                >
                                  <span v-if="item.answer_type == 1">{{ $t('security.unanswered') }}</span>
                                  <span v-else-if="item.answer_type == 4">
                                    {{ $t('security.no_confirmation_required') }}
                                  </span>
                                  <i v-else class="icon-abui-chat-dots prx" />
                                  <span
                                    class="caret"
                                    :class="item.id == selectedMember.id && item.isOpenMenu ? 'active' : ''"
                                  />
                                </button>
                              </div>
                            </td>
                            <td v-else class="answer" :class="responseType(item.answer_type)">
                              <button
                                v-if="(item.answer_type == 2 || item.answer_type == 3) && item.is_update_by_app == 1"
                                type="button"
                                class="btn btn-sm btn-flat-default dropdown-toggle"
                                @click="showMessage(item)"
                              >
                                <i class="icon-abui-chat-dots" :class="item.answer_type == 2 ? 'ok' : 'ng'" />
                              </button>
                              <span v-else-if="item.answer_type == 6" class="text-muted">
                                {{ $t('create_security.unsent') }}
                              </span>
                              <div v-else class="btn-group" v-on:click.stop>
                                <button
                                  type="button"
                                  class="btn btn-sm btn-flat-default dropdown-toggle"
                                  @click.stop="openDropdown(item)"
                                  v-click-outside="closeAllMenuItem"
                                >
                                  <span v-if="item.answer_type == 1">{{ $t('security.unanswered') }}</span>
                                  <span v-else-if="item.answer_type == 4">
                                    {{ $t('security.no_confirmation_required') }}
                                  </span>
                                  <i
                                    v-else-if="item.answer_type == 2 || item.answer_type == 3"
                                    class="icon-abui-chat-dots"
                                    :class="item.answer_type == 2 ? 'ok' : 'ng'"
                                  />
                                  <i v-else class="icon-abui-chat-dots prx" />
                                  <span
                                    class="caret"
                                    :class="item.id == selectedMember.id && item.isOpenMenu ? 'active' : ''"
                                  />
                                </button>
                                <ul
                                  v-if="item.id == selectedMember.id && item.isOpenMenu"
                                  class="dropdown-menu"
                                  role="menu"
                                >
                                  <li v-show="item.answer_type != 4" @click="updateAnswerStatus(item, false)">
                                    <a class="js-btn-reply-exclude open-alert">
                                      {{ $t('security.make_confirm_is_unnecessary') }}
                                    </a>
                                  </li>
                                  <li @click="isShowModalReplyOutside = true">
                                    <a class="js-btn-reply-outside">
                                      {{ $t('security.confirmed_outside_the_app') }}
                                    </a>
                                  </li>
                                  <li
                                    v-show="item.answer_type == 2 || item.answer_type == 3 || item.answer_type == 4"
                                    @click="updateToUnAnswer(item)"
                                  >
                                    <a class="js-btn-reply-outside">
                                      {{ $t('security.unanswered') }}
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </td>
                            <td class="cell-control">
                              <a
                                class="btn btn-sm btn-flat-default"
                                target="_blank"
                                @click="navigateToTripDetail(item)"
                              >
                                <i class="icon-abui-globe" />
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <p v-if="safetyInfo.members?.length == 0" class="no-data">{{ $t('common.no_data') }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-show="isExpandMap">
            <div class="trip-info-col bg-gray col-expandable" style="width: calc(100% - 55px); z-index: 1">
              <button class="btn btn-sm btn-flat-default btn-col-expand expanded" @click="isExpandMap = false">
                <i class="icon-abui-contract" />
              </button>
              <div
                class="security--map hide--new"
                style="
                  background-image: url(/assets/images/dummy/dummny-map--secutiry2.png);
                  background-position: 1200px -40px;
                  background-size: 1923px;
                  height: 100%;
                "
              >
                <Map
                  :selectedMember="selectedMember"
                  :listMembers="listMembers"
                  :countrySafety="safetyInfo.countrySafety"
                  :isDisplayMap="isDisplayMap"
                  @showEmergencyContact="showEmergencyContact"
                />
              </div>
            </div>
          </div>
          <div
            v-if="!isOperatorOrAdmin"
            class="navbar navbar-default navbar-fixed-bottom navbar-footblock with-sidebar submit-new--wrap show--estimate hide--new"
            role="navigation"
            style="z-index: 1; padding: 12px; opacity: 1"
          >
            <div class="row">
              <div class="col-sm-3">
                <button class="btn btn-sm btn-flat-default js-btn--del-item" @click="showBoxConfirm = true">
                  <i class="icon-abui-trash prx" />{{ $t('security.delete_safety_confirmation') }}
                </button>
              </div>
              <div class="col-sm-4 col-md-3 col-sm-offset-5 col-md-offset-6">
                <button
                  :disabled="!isHasMemberUnAnswer"
                  class="btn btn-sm btn-block btn-primary js-btn--submit open-alert"
                  @click="sendBroadcastToAllUser"
                >
                  {{
                    safetyInfo.have_sent_before == 1
                      ? $t('security.resend_safety_confirmation_to_responder_all_at_once')
                      : $t('security.broadcast_safety_confirmation')
                  }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </body>
  <ModalConfirm
    :dialogVisible="showBoxConfirm"
    @cancel="showBoxConfirm = false"
    @yes="deleteSafetyInfo"
    :title="$t('Q-CM-104')"
  />
  <el-dialog v-model="showModalMessage" :append-to-body="true" width="640px" :close-on-press-escape="false">
    <div class="modal--2fa">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <div class="modal-header--title"></div>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <pre
                  type="text"
                  class="form-control input-sm max-textarea"
                  style="height: auto !important"
                  v-html="selectedMember.answer_message"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-12">
                <button class="btn btn-sm btn-flat-default btn-block" @click="showModalMessage = false">
                  {{ $t('common.close') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import Map from '@/components/map/Map';
import ModalConfirm from '@/components/common/ModalConfirm';
import vClickOutside from 'click-outside-vue3';
import moment from 'moment';
import {
  SAFE_INFO_ANSWER_SAFE,
  SAFE_INFO_ANSWER_NO_SAFE,
  SAFE_INFO_CONFIRM_UNNECESSARY,
  SAFE_INFO_UNANSWERED,
  SAFE_INFO_UNSEND_TYPE,
} from '@/constants/';
export default {
  name: 'Zone',
  components: { Map, ModalConfirm },
  data() {
    return {
      showBoxConfirm: false,
      isExpandMap: false,
      isShowModalReplyOutside: false,
      isShowModalEmergencyContact: false,
      selectedItem: {},
      selectedEmergencyContacts: [],
      selectedMember: {},
      listMembers: [],
      countTotalMember: 0,
      countNotSafetyMember: 0,
      countSafetyMember: 0,
      safetyInfo: {},
      safetyInfoOfficial: {},
      answerMessage: '',
      errors: {
        title: '',
        answerMessage: '',
      },
      targetCountry: '',
      centerMap: {
        lat: 35.672855,
        lng: 139.817413,
      },
      options: {
        disableDefaultUI: true,
      },
      startDate: '',
      endDate: '',
      showModalMessage: false,
    };
  },
  beforeRouteLeave(to, from, next) {
    if (this.safetyInfo.title !== this.safetyInfoOfficial.title) {
      const answer = window.confirm(this.$t('common.confirm_leave_page'));
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  computed: {
    safetyInfoId() {
      return this.$route.params.id;
    },
    isHasMemberUnAnswer() {
      return !this.checkEmpty(this.safetyInfo.members)
        ? this.safetyInfo?.members.find(
            member => member.answer_type == SAFE_INFO_UNANSWERED || member.answer_type == SAFE_INFO_UNSEND_TYPE,
          )
        : false;
    },
    isDisplayMap() {
      return this.diffDate(this.startDate, this.endDate) == 0;
    },
  },
  created() {
    this.getSafetyInfoDetail();
  },
  methods: {
    showEmergencyContact(item) {
      this.selectedEmergencyContacts = item.emergency_contacts ? item.emergency_contacts : [];
      this.isShowModalEmergencyContact = true;
    },
    async getSafetyInfoDetail() {
      this.startLoading();
      await this.$services.security.getSafetyInfoDetail(
        this.safetyInfoId,
        res => {
          this.endLoading();
          this.safetyInfo = res;
          this.safetyInfoOfficial = this.cloneObject(res);
          let arr = this.safetyInfo.target_period?.split(' - ');
          if (!this.checkEmpty(arr)) {
            this.startDate = arr[0];
            this.endDate = arr[1];
          }
          this.listMembers = res.members;
          if (!this.checkEmpty(res.countrySafety)) {
            if (res.countrySafety.length == res.total_country) {
              this.targetCountry = this.$t('common.all');
            } else {
              let result = [];
              res.countrySafety.forEach(country => {
                result.push(this.labelLocale(country));
              });
              this.targetCountry = result.join(this.$t('common.comma'));
            }
          }
          this.countSafetyMember = 0;
          this.countNotSafetyMember = 0;
          this.countTotalMember = 0;
          this.listMembers.forEach(member => {
            if (member.answer_type == SAFE_INFO_ANSWER_SAFE) {
              this.countSafetyMember += 1;
            }
            if (member.answer_type == SAFE_INFO_ANSWER_NO_SAFE) {
              this.countNotSafetyMember += 1;
            }
            if (member.answer_type != SAFE_INFO_CONFIRM_UNNECESSARY) {
              this.countTotalMember += 1;
            }
          });
          this.closeAllMenuItem();
        },
        err => {
          // this.endLoading();
          this.notifyError(this.$t(err.error));
          this.$router.push(this.PAGES.CRISIS_MANAGEMENT);
        },
      );
    },
    deleteSafetyInfo() {
      this.startLoading();
      this.$services.security.deleteSafetyInfo(
        this.safetyInfoId,
        () => {
          // this.endLoading();
          this.showBoxConfirm = false;
          this.notifySuccess(this.$t('I-CM-102'));
          this.$router.push(this.PAGES.CRISIS_MANAGEMENT);
        },
        err => {
          this.endLoading();
          this.showBoxConfirm = false;
          this.notifyError(this.$t(err.error));
        },
      );
    },
    updateSafetyInfo() {
      let flagValidate = true;
      if (this.safetyInfo.title == '') {
        flagValidate = false;
        this.errors.title = this.$t('E-CM-006');
      } else {
        if (this.strLen(this.safetyInfo.title) > 100) {
          flagValidate = false;
          this.errors.title = this.$t('E-CU-013');
        } else {
          this.errors.title = '';
        }
      }

      if (!flagValidate) return;

      let params = { id: this.safetyInfoId, title: this.safetyInfo.title };
      this.$services.security.updateSafetyInfo(
        params,
        () => {
          this.getSafetyInfoDetail();
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    sendBroadcastToAllUser() {
      this.$services.security.sendBroadcastToAllUser(
        { safety_info_id: this.safetyInfoId },
        () => {
          this.notifySuccess(this.$t('E-CU-020'));
          this.getSafetyInfoDetail();
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    downloadFileCSV() {
      this.startLoading();
      this.$services.security.downloadFileCSV(
        this.safetyInfoId,
        res => {
          this.endLoading();
          this.handleDownloadCSV(res);
        },
        err => {
          this.endLoading();
          this.notifyError(this.$t(err.error));
        },
      );
    },
    handleDownloadCSV(res) {
      window.open(res.file_path, '_blank').focus();
    },
    responseType(type) {
      switch (type) {
        case SAFE_INFO_ANSWER_SAFE:
          return 'answer--ok';
        case SAFE_INFO_ANSWER_NO_SAFE:
          return 'answer--ng';
        default:
          return 'cell-control';
      }
    },
    updateAnswerStatus(item, isHaveMessage, type) {
      let params = {
        id: item.id,
        request: {},
      };
      if (isHaveMessage) {
        params.request = {
          answer_type: type,
          answer_message: this.answerMessage,
        };
        this.selectedMember.answer_type = type;
      } else {
        params.request = {
          answer_type: SAFE_INFO_CONFIRM_UNNECESSARY,
        };
        this.selectedMember.answer_type = SAFE_INFO_CONFIRM_UNNECESSARY;
      }
      this.$services.security.updateAnswerStatus(
        params,
        () => {
          this.closeAllMenuItem();
          this.isShowModalReplyOutside = false;
          if (type) {
            this.notifySuccess(this.$t('E-CU-022'));
          } else {
            this.notifySuccess(this.$t('E-CU-021'));
          }
          this.answerMessage = '';
          this.getSafetyInfoDetail();
        },
        err => {
          this.closeAllMenuItem();
          this.isShowModalReplyOutside = false;
          this.notifyError(this.$t(err.error));
          this.answerMessage = '';
        },
      );
    },
    updateToUnAnswer(item) {
      let params = {
        id: item.id,
        request: {
          answer_type: SAFE_INFO_UNANSWERED,
        },
      };
      this.selectedMember.answer_type = SAFE_INFO_UNANSWERED;
      this.$services.security.updateAnswerStatus(
        params,
        () => {
          this.closeAllMenuItem();
          this.isShowModalReplyOutside = false;
          this.notifySuccess(this.$t('E-CU-023'));
          this.answerMessage = '';
          this.getSafetyInfoDetail();
        },
        err => {
          this.closeAllMenuItem();
          this.isShowModalReplyOutside = false;
          this.notifyError(this.$t(err.error));
          this.answerMessage = '';
        },
      );
    },
    makeConfirmed(type) {
      let flagValidate = true;
      if (type == 2) {
        if (this.strLen(this.answerMessage) > 200) {
          flagValidate = false;
          this.errors.answerMessage = this.$t('E-CU-013');
        } else {
          this.errors.answerMessage = '';
        }
      } else {
        if (this.answerMessage == '') {
          flagValidate = false;
          this.errors.answerMessage = this.$t('E-CM-004');
        } else {
          if (this.strLen(this.answerMessage) > 200) {
            flagValidate = false;
            this.errors.answerMessage = this.$t('E-CU-013');
          } else {
            this.errors.answerMessage = '';
          }
        }
      }

      if (!flagValidate) return;
      this.updateAnswerStatus(this.selectedMember, true, type);
    },
    selectMember(item) {
      this.selectedMember = item;
      this.selectedEmergencyContacts = item.emergency_contacts;
    },
    showMessage(item) {
      this.selectedMember = item;
      this.showModalMessage = true;
    },
    openDropdown(item) {
      this.selectedMember = item;
      if (
        this.checkEmpty(item.gps_confirm_safety_info) &&
        (item.answer_type == 2 || item.answer_type == 3) &&
        !this.isDisplayMap &&
        item.is_update_by_app
      ) {
        this.showModalMessage = true;
      } else {
        this.safetyInfo.members.forEach(member => {
          if (item.id == member.id) {
            member.isOpenMenu = !member.isOpenMenu;
          } else {
            member.isOpenMenu = false;
          }
        });
      }
    },
    onCancelModalConfirm() {
      this.isShowModalReplyOutside = false;
      this.closeAllMenuItem();
    },
    closeAllMenuItem() {
      this.safetyInfo.members.forEach(member => {
        member.isOpenMenu = false;
      });
    },
    navigateToTripDetail(item) {
      let routeData = this.$router.resolve({
        name: this.PAGES.TRIP_UPDATE,
        params: {
          id: item.trip_id,
        },
      });
      window.open(routeData.href, '_blank');
    },
    getListPlaceInformation(item) {
      let location = '';
      if (!this.checkEmpty(item.gps_show_data.country)) {
        location += item.gps_show_data.country;
        if (!this.checkEmpty(item.gps_show_data.city)) {
          location +=
            '<i class="icon-abui-arrow-right phx" />' + item.gps_show_data.city + '<i class="icon-abui-gps plx" />';
        }
        location +=
          '&nbsp;&nbsp;&nbsp;&nbsp;取得時間：' +
          moment(item.gps_show_data.start_date).format('YYYY/MM/DD HH:mm') +
          '<br/>';
      }

      if (!this.checkEmpty(item.schedule_data)) {
        item.schedule_data.forEach(schedule => {
          if (!this.checkEmpty(schedule.departure_country)) {
            location += schedule.departure_country;
            if (!this.checkEmpty(schedule.departure_city)) {
              location += '<i class="icon-abui-arrow-right phx" />' + schedule.departure_city;
            }
          }
          if (!this.checkEmpty(schedule.arrival_country)) {
            if (!this.checkEmpty(schedule.departure_country) && !this.checkEmpty(schedule.departure_city)) {
              location += ' → ';
            }
            location += schedule.arrival_country;
            if (!this.checkEmpty(schedule.arrival_city)) {
              location += '<i class="icon-abui-arrow-right phx" />' + schedule.arrival_city;
            }
            location += '<br/>';
          } else {
            location += '<br/>';
          }
        });
      }

      return location;
    },
    getLengthOfStayInformation(item) {
      let lengthOfStay = '';
      if (!this.checkEmpty(item.gps_show_data)) {
        lengthOfStay +=
          moment(item.gps_show_data.start_date).format('YYYY/MM/DD') +
          ' - ' +
          moment(item.gps_show_data.end_date).format('YYYY/MM/DD') +
          '<br/>';
      }

      if (!this.checkEmpty(item.schedule_data)) {
        item.schedule_data.forEach(schedule => {
          lengthOfStay += schedule.start_date + ' - ' + schedule.end_date + '<br/>';
        });
      }

      return lengthOfStay;
    },
    getAnswerDay(item) {
      if (!this.checkEmpty(item.gps_confirm_safety_info)) {
        return item.gps_confirm_safety_info.start_date;
      }
      if (item.answer_type == 2 || item.answer_type == 3) {
        return item.answer_at;
      }

      return ' - ';
    },
  },
};
</script>
<style scoped lang="scss">
.navbar {
  position: fixed;
  width: calc(100% - 55px);
  z-index: 100;
}
.body-content {
  margin-top: 64px;
}
.plan-stage {
  width: calc(100% - 480px);
  margin-bottom: 62px;
}
.dropdown-menu.menu-parent {
  max-height: 533.812px;
  min-height: 0px;
  visibility: visible;
  opacity: 1;
  margin: 4px 0px !important;
}
.dropdown-menu {
  margin: 4px 0px !important;
  opacity: 1;
  visibility: visible;
}
.caret.active {
  border-top-color: #3650a5;
  border-bottom-color: #3650a5;
}
.caret {
  margin-left: 2px;
}
.btn-success {
  background-color: #65c15e !important;
}
.icon-abui-chat-dots {
  &.ng {
    color: #f55361;
  }
  &.ok {
    color: #65c15e;
  }
}
.dropdown-menu {
  a:hover {
    color: #45545b !important;
  }
}
</style>
