<template>
  <el-dialog
    v-model="dialogVisibleStatus"
    :append-to-body="true"
    :show-close="false"
    @close="$emit('cancel')"
    width="642px"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-header--title">{{ title }}</div>
        </div>
        <div class="modal-body">
          <p class="modal--diaglog--text" v-if="isUploadWithNote">{{ $t('trip_update.file_upload_note') }}</p>
          <div class="row">
            <div class="col-md-12">
              <input
                type="file"
                :multiple="!isSingleUpload"
                name="file"
                style="display: none"
                @change="onFileChange"
                ref="inputFile"
                :accept="acceptedList"
              />
              <div
                class="dropzone cursor-pointer"
                @click="chooseFile()"
                :data-active="false"
                @dragenter.prevent="true"
                @dragover.prevent="true"
                @dragleave.prevent="false"
                @drop.prevent="onDrop"
              >
                <div v-if="listFile.length == 0" class="dz-default dz-message">
                  <span>
                    <span class="icon icon-abui-file"></span>
                    {{ $t('common.drop_file_here') }}
                    <small>{{ $t('common.click_to_specify_the_location') }}</small>
                  </span>
                </div>
                <div v-else>
                  <div v-for="(item, index) in listFile" :key="index" class="dz-preview dz-complete dz-image-preview">
                    <div class="dz-image" v-on:click.stop>
                      <span class="icon icon-abui-file"></span>
                    </div>
                    <div class="dz-details" v-on:click.stop>
                      <div class="dz-size">
                        <span>
                          <strong>{{ getSize(item.size) }}</strong> MB
                        </span>
                      </div>
                      <div class="dz-filename">
                        <span>{{ item.name }}</span>
                      </div>
                    </div>
                    <div class="dz-success-mark" v-on:click.stop>
                      <img src="@/assets/images/dz-success-mark.svg" alt="" />
                    </div>
                    <a class="dz-remove" @click="removeFile(index)" v-on:click.stop></a>
                  </div>
                </div>
              </div>
              <p role="alert" class="error-feedback" v-text="errors.file"></p>
            </div>
            <div class="col-md-12" v-if="isUploadWithNote">
              <label><span class="label-text">{{$t('trip.enter_content_with_text')}}</span></label>
              <textarea
                  class="form-control"
                  rows="10"
                  :placeholder="$t('trip.content_reservation')"
                  maxlength="10000"
                  style="resize: none;"
                  v-model="content_reservation"
              />
              <p role="alert" class="error-feedback" v-text="errors.content_reservation"></p>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="row">
            <div class="col-xs-6 col-sm-6 col-md-6">
              <button class="btn btn-sm btn-flat-default btn-block" @click="$emit('cancel')">
                {{ $t('common.cancel') }}
              </button>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6">
              <button class="btn btn-sm btn-primary btn-block" @click="submit()">
                {{ $t('common.confirm') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<style>
.dz-image .icon {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
<script>
import { ElDialog } from 'element-plus';
import { MAX_FILE_SIZE } from '@/constants/';
export default {
  components: {
    ElDialog,
  },
  name: 'ModalUploadFile',
  emits: ['confirm', 'cancel', 'files-dropped'],
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    checkShowModal: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    isSingleUpload: {
      type: Boolean,
      default: false,
    },
    isUploadWithNote: {
      type: Boolean,
      default: false,
    },
    acceptedFiles: {
      type: String,
      default: '*',
    },
    acceptImageAndPDFOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogVisibleStatus: false,
      listFile: [],
      content_reservation: '',
      errors: {},
    };
  },
  watch: {
    dialogVisible() {
      this.dialogVisibleStatus = this.dialogVisible;
      if (this.dialogVisible) {
        this.listFile = [];
      }
    },
  },
  computed: {
    isShowUploadReceiptCost() {
      return this.$store.state.auth?.user?.setting.is_upload_receipt_cost == 1 || false;
    },
    acceptedList() {
      if (!this.checkEmpty(this.acceptedFiles)) {
        return this.acceptedFiles;
      }

      return 'image/jpg, image/jpeg, image/png, .pdf, .csv, .xlsx, .docx, .heif';
    },
  },
  methods: {
    async getCurrentUserCheck() {
      await this.$services.account.current(
          res => {
            this.$store.dispatch('auth/initCurrentData', res);
          },
          () => {
            this.logout();
          },
      );
    },
    async submit() {
      if (this.checkShowModal) {
        await this.getCurrentUserCheck();
        if (!this.isShowUploadReceiptCost) {
          this.$router.push(this.PAGES.UNAUTHORIZED);
          return;
        }
      }
      if (this.isUploadWithNote && this.content_reservation.length > 10000) {
        this.errors.content_reservation = this.$t('E-CM-111', { size: 10000 });
        return;
      }
      if (this.checkEmpty(this.listFile)) {
        if (this.isUploadWithNote && this.checkEmpty(this.content_reservation)) {
          this.errors.file = this.$t('trip_update.validate_upload_trip');
        } else if (!this.isUploadWithNote){
          this.errors.file = this.$t('E-CM-100', {item: this.$t('common.file')});
        } else {
          this.errors.file = '';
          this.$emit('confirm', this.listFile, this.content_reservation);
          this.content_reservation = '';
        }
        //Process this only when accept image only.
      } else if (this.acceptImageAndPDFOnly) {
        let flagValidateFormat = true;
        const acceptedFormatArr = this.acceptedList.split(', ');
        this.listFile.forEach(item => {
          if (!acceptedFormatArr.includes(item.type)) flagValidateFormat = false;
        });
        if (!flagValidateFormat) {
          this.errors.file = this.$t('common.invalid_image_or_pdf_format');
          return;
        } else {
          this.errors.file = '';
          this.$emit('confirm', this.listFile, this.content_reservation);
          this.content_reservation = '';
        }
      } else {
        this.errors.file = '';
        this.$emit('confirm', this.listFile, this.content_reservation);
        this.content_reservation = '';
      }
    },
    chooseFile() {
      this.$refs.inputFile.click();
    },
    removeFile(index) {
      this.listFile.splice(index, 1);
    },
    getSize(size) {
      return (size / (1024 * 1024)).toFixed(2);
    },
    async onFileChange(e) {
      this.handleUploadFile(e.target);
    },
    onDrop(e) {
      this.handleUploadFile(e.dataTransfer);
    },
    handleUploadFile(data) {
      const files = data.files;
      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        if (file?.type) {
          if (file.size <= MAX_FILE_SIZE) {
            if (this.isSingleUpload) {
              this.listFile = [];
            }
            this.listFile.push(file);
          } else {
            this.notifyError(this.$t('E-CU-223'));
          }
        } else {
          this.notifyError(this.$t('E-CU-222'));
        }
      }
      this.$refs.inputFile.value = '';
    },
  },
};
</script>
