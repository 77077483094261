<template>
  <div class="wrapper-page">
    <nav class="navbar navbar-default navbar-lg" role="navigation" id="header-content">
      <div class="navbar-header">
        <h2 class="navbar-title">
          {{ $t('employee.employee') }}<span class="navbar-title-separator">｜</span>
          <span>{{ $t('employee.edit') }}</span>
        </h2>
      </div>
    </nav>
    <ProfileForm :dataForm="dataEmployee" />
  </div>
</template>
<script>
import ProfileForm from '@/components/employee/ProfileForm';
export default {
  name: 'Profile',
  components: { ProfileForm },
  data() {
    return {
      dataEmployee: {},
    };
  },
  created() {
    this.getDetail();
  },
  methods: {
    async getDetail() {
      this.startLoading();
      await this.$services.employee.getMyProfile(
        res => {
          this.endLoading();
          this.dataEmployee = res;
        },
        err => {
          this.endLoading();
          this.notifyError(this.$t(err.error));
          this.$router.push(this.PAGES.HOME);
        },
      );
    },
  },
};
</script>
