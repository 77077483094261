import BaseService from './BaseService';
import API_CODE from '../utils/api_code.js';

export default class DashBoardAPI extends BaseService {
  async getListTripsWaiting(params, success, error) {
    await this.get(API_CODE.CU_066 + this.urlParse(params), success, error);
  }
  async getListNotification(success, error) {
    await this.get(API_CODE.CU_065, success, error);
  }
  async getListTripsCondition(success, error) {
    await this.get(API_CODE.CU_068, success, error);
  }
  async getListAbsent(success, error) {
    await this.get(API_CODE.CU_067, success, error);
  }
  async detailAbsent(id, success, error) {
    await this.get(API_CODE.CU_076 + id, success, error);
  }
  async createAbsent(params, success, error) {
    await this.post(API_CODE.CU_073, params, success, error);
  }
  async updateAbsent(params, success, error) {
    await this.post(API_CODE.CU_074 + params.id, params, success, error);
  }
  async deleteAbsent(id, success, error) {
    await this.delete(API_CODE.CU_075 + id, {}, success, error);
  }
  async getApproval(params, success, error) {
    await this.get(API_CODE.CU_015 + this.urlParse(params), success, error);
  }
}
