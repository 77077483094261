import BaseService from './BaseService';
import API_CODE from '../utils/api_code.js';

export default class AuthAPI extends BaseService {
  async current(success, error) {
    await this.get(API_CODE.CU_215, success, error);
  }
  async saveOrderColumn(params, success, error) {
    await this.post(API_CODE.CU_321, params, success, error);
  }
  async login(params, success, error) {
    await this.post(API_CODE.CU_071, params, success, error);
  }
  async verify(params, success, error) {
    await this.post(API_CODE.CU_072, params, success, error);
  }
  async forgotPassword(params, success, error) {
    await this.post(API_CODE.CU_005, params, success, error);
  }
  async logout(params, success, error) {
    await this.post(API_CODE.CU_177, params, success, error);
  }
  async updatePassword(params, success, error) {
    await this.post(API_CODE.CU_216, params, success, error);
  }
}
