import BaseService from './BaseService';
import API_CODE from '../utils/api_code.js';

export default class SecurityServiceAPI extends BaseService {
  async getListCountry(success, error) {
    await this.get(API_CODE.CU_069, success, error);
  }

  async getListSafetyInfo(params, query, success, error) {
    await this.get(API_CODE.CU_004 + this.urlParse(params, query), success, error);
  }

  async getListSafetyInfoOP(params, query, success, error) {
    await this.get(API_CODE.CU_266 + this.urlParse(params, query), success, error);
  }

  async getListAirLines(success, error) {
    await this.get(API_CODE.CU_070, success, error);
  }

  async getListTargetPerson(params, query, success, error) {
    await this.get(API_CODE.CU_064 + this.urlParse(params, query), success, error);
  }

  async createSecurity(params, success, error) {
    await this.post(API_CODE.CU_077, params, success, error);
  }

  async updateInfoListSafety(params, success, error) {
    const { id } = params;
    await this.post(API_CODE.CU_083 + id, params, success, error);
  }

  async getSafetyInfoDetail(id, success, error) {
    await this.get(API_CODE.CU_078 + id, success, error);
  }

  async deleteSafetyInfo(id, success, error) {
    await this.delete(API_CODE.CU_079 + id, {}, success, error);
  }

  async sendBroadcastToAllUser(params, success, error) {
    await this.post(API_CODE.CU_080, params, success, error);
  }

  async sendBroadcastToSingleUser(id, success, error) {
    await this.post(API_CODE.CU_081 + id, {}, success, error);
  }

  async updateAnswerStatus(params, success, error) {
    await this.post(API_CODE.CU_082 + params.id, params.request, success, error);
  }

  async updateSafetyInfo(params, success, error) {
    await this.post(API_CODE.CU_223 + params.id, params, success, error);
  }

  async downloadFileCSV(id, success, error) {
    await this.get(API_CODE.CU_224 + id, success, error);
  }
}
