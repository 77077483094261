<template>
  <nav class="navbar navbar-default navbar-lg" role="navigation" id="header-content">
    <div class="navbar-header">
      <h2 class="navbar-title">{{ $t('notification.notification') }}</h2>
    </div>
    <div class="collapse navbar-collapse">
      <ul class="nav navbar-nav navbar-right">
        <li>
          <a class="cursor-pointer" @click="this.$router.push(this.PAGES.NOTIFICATION_CREATE)">
            <span class="icon-abui-plus prx"></span>{{ $t('notification.new_notification') }}
          </a>
        </li>
      </ul>
    </div>
  </nav>
  <div class="filter-wrap">
    <Filter
      :is-show="isShowFilter"
      :clear-filter="clearFilter"
      @onHandleChangeCondition="onHandleChangeCondition"
      @emitSelectedCompanies="handleSelectedCompanies"
      @setClearFilter="setClearFilter"
    />
    <ButtonSubmit
      :is-show="isShowFilter"
      @onHandleClearCondition="onHandleClearCondition"
      @onHandleSubmitFilter="onHandleSubmitFilter"
      @listenCloseSearchBox="listenCloseSearchBox"
    />
    <ButtonFilter :is-show="!isShowFilter" @onHandleClickFilter="onHandleClickFilter" />
    <DataTableResult :listNotifications="listNotification" :paginate="paginate" @changePageData="changePageData" />
  </div>
</template>
<script>
import Filter from '@/components/notification/Filter';
import ButtonSubmit from '@/components/security/ButtonSubmit';
import DataTableResult from '@/components/notification/DataTableResult';
import ButtonFilter from '@/components/security/ButtonFilter';
import vClickOutside from 'click-outside-vue3';
import mixins from '@/helpers/mixins';

export default {
  name: 'Notification',
  mixins: [mixins],
  components: {
    Filter,
    ButtonSubmit,
    ButtonFilter,
    DataTableResult,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      listNotification: [],
      isShowFilter: false,
      clearFilter: false,
      isFilter: false,
      conditions: {
        company_ids: this.$route.query?.company_ids ? JSON.parse(this.$route.query.company_ids) : [],
        title: this.$route.query?.title || '',
        delivery_date_from: this.$route.query?.delivery_date_from || '',
        delivery_date_to: this.$route.query?.delivery_date_to || '',
        is_waiting_for_delivery: this.$route.query?.is_waiting_for_delivery
          ? this.$route.query?.is_waiting_for_delivery == '1'
          : true,
        is_delivered: this.$route.query?.is_delivered ? this.$route.query?.is_delivered == '1' : true,
      },
      paginate: {
        page: 1,
        per_page: 10,
        from: 1,
        to: 1,
        lastPage: 1,
        total: 1,
      },
    };
  },
  created() {
    this.startLoading();
    Promise.all([this.getListNotifications()]).then(() => {
      this.endLoading();
    });
  },
  methods: {
    handleSelectedCompanies(selectedCompanies) {
      this.conditions.company_ids = selectedCompanies;
    },
    onHandleClearCondition() {
      this.clearFilter = true;
    },

    changePageData(page) {
      this.paginate.page = page;
      this.getListNotifications();
    },
    onHandleChangeCondition(condition, value) {
      this.conditions[condition] = value;
    },
    async getListNotifications() {
      const { title, delivery_date_from, delivery_date_to, is_waiting_for_delivery, is_delivered, company_ids } =
        this.conditions;

      const params = {
        page: this.paginate.page,
      };

      let queryBuild = '';
      if (is_waiting_for_delivery) queryBuild += 'status[]=1&';
      if (is_delivered) queryBuild += 'status[]=2&';
      if (!this.checkEmpty(company_ids)) company_ids.forEach(item => (queryBuild += `company_ids[]=${item}&`));
      params['title'] = title;
      params['delivery_date_from'] = delivery_date_from;
      params['delivery_date_to'] = delivery_date_to;
      await this.$services.notification.getListNotificationsOp(
        params,
        queryBuild,
        response => {
          const { list, paginate } = response;
          this.listNotification = list;
          this.paginate = paginate;
          this.$router.push({
            path: this.$route.path,
            query: {
              page: response.paginate.current_page,
              per_page: response.paginate.per_page,
              title: title,
              delivery_date_from: delivery_date_from,
              delivery_date_to: delivery_date_to,
              is_waiting_for_delivery: is_waiting_for_delivery ? '1' : '0',
              is_delivered: is_delivered ? '1' : '0',
              company_ids: JSON.stringify(company_ids),
            },
          });
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    setClearFilter(value) {
      this.clearFilter = value;
      this.isFilter = false;
    },
    onHandleClickFilter() {
      this.isShowFilter = true;
    },
    onHandleSubmitFilter() {
      this.isFilter = true;
      this.getListNotifications();
    },
    listenCloseSearchBox() {
      this.isShowFilter = false;
    },
  },
};
</script>
