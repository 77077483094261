<template>
  <!-- Error Page -->
  <div class="error">
    <div class="container-floud">
      <div class="col-xs-12 ground-color text-center">
        <div class="container-error-404">
          <div class="msg">Oh!<span class="triangle"></span></div>
        </div>
        <h2 class="h1">Sorry! Page not found</h2>
        <a class="home-link" href="#" style="color: #8d8d8d" @click="$router.push('/')"> Hitch a ride back home. </a>
      </div>
    </div>
  </div>
  <!-- Error Page -->
</template>
<style lang="scss" scoped>
.error h2   /*Contrall*/ {
  font-size: 32px;
}
.error .msg /*Contrall*/ {
  width: 80px;
  line-height: 80px;
  font-size: 32px;
  display: block;
  margin: auto;
}
.error span.triangle    /*Contrall*/ {
  top: 70%;
  right: 0%;
  border-left: 20px solid #535353;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
}

.error .container-error-404 {
  margin-top: 10%;
  position: relative;
  padding-top: 40px;
}
.error h2 {
  color: #a2a2a2;
  font-weight: bold;
  padding-bottom: 20px;
}
.error .msg {
  position: relative;
  z-index: 9999;
  display: block;
  background: #535353;
  color: #a2a2a2;
  border-radius: 50%;
  font-style: italic;
}
.error .triangle {
  position: absolute;
  z-index: 999;
  transform: rotate(45deg);
  content: '';
  width: 0;
  height: 0;
}
</style>
