export const DAY_TRIPS = [
  {
    id: null,
    type_id: 1,
    is_depend_position: false,
    amount: '',
    position_amounts: [],
  },
  {
    id: null,
    type_id: 2,
    is_depend_position: false,
    amount: '',
    position_amounts: [],
  },
  {
    id: null,
    type_id: 3,
    is_depend_position: false,
    amount: '',
    position_amounts: [],
  },
  {
    id: null,
    type_id: 4,
    is_depend_position: false,
    amount: '',
    position_amounts: [],
  },
];

export const DAILY_ALLOWANCE_TYPE = [
  {
    id: 1,
    en_name: 'Domestic (business trip)',
    ja_name: '国内（出張）',
  },
  {
    id: 2,
    en_name: 'Domestic (training) ',
    ja_name: '国内（研修）',
  },
  {
    id: 3,
    en_name: 'Overseas (business trip)',
    ja_name: '海外（出張）',
  },
  {
    id: 4,
    en_name: 'Overseas (training) ',
    ja_name: '海外（研修）',
  },
];

export const HALF_DAYS = 1;
export const ALL_DAYS = 2;
export const ACCOMMODATION_FEES = 3;
