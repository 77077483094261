<template>
  <div class="wrapper-page">
    <nav class="navbar navbar-default navbar-lg" role="navigation" id="header-content">
      <div class="navbar-header">
        <h2 class="navbar-title">
          {{ $t('employee.employee') }}<span class="navbar-title-separator">｜</span>
          <span>{{ $t('employee.edit') }}</span>
        </h2>
      </div>
    </nav>
    <EmployeeForm :dataForm="dataEmployee" :isDetail="isDetail" :isDeleted="isDeleted" @onChangeData="onChangeData" @getDetail="getDetail"/>
  </div>
</template>
<script>
import EmployeeForm from '@/components/employee/EmployeeForm';
export default {
  name: 'Employee-detail',
  components: { EmployeeForm },
  data() {
    return {
      dataEmployee: {},
      isDetail: false,
      isDeleted: false,
      isChangeData: false,
    };
  },
  watch: {},
  computed: {},
  created() {
    this.getDetail();
  },
  beforeRouteLeave(to, from, next) {
    if (this.isChangeData) {
      const answer = window.confirm(this.$t('common.confirm_leave_page'));
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  methods: {
    onChangeData(val) {
      this.isChangeData = val;
    },
    async getDetail() {
      const id = this.$route.params.id;
      this.startLoading();
      await this.$services.employee.getEmployeeDetail(
        id,
        res => {
          this.dataEmployee = res;
          this.isDetail = true;
          if (!this.checkEmpty(res.user.deleted_at)) {
            this.isDeleted = true;
          }
          this.endLoading();
        },
        err => {
          this.notifyError(this.$t(err.error));
          this.$router.push(this.PAGES.EMPLOYEE);
        },
      );
    },
  },
};
</script>
<style lang="scss" scoped></style>
