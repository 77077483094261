import axios from 'axios';
import Cookies from 'js-cookie';
import moment from 'moment';

const headers = {
  'x-locale': Cookies.get('CurrentLanguage') || 'ja',
};

const token = Cookies.get('access_token');
if (token != undefined && token.length != 0) {
  headers.Authorization = 'Bearer ' + token;
}

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 60000,
  headers,
});

let isRefreshing = false;
let failedQueue = [];
const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

const getAccessToken = () => {
  return Cookies.get('access_token');
};

axiosInstance.interceptors.response.use(
  response => response.data,
  async error => {
    const originalRequest = error.config;
    if (error.response.status == 401) {
      if (getAccessToken() && !originalRequest._retry) {
        if (isRefreshing) {
          return new Promise((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          })
            .then(async token => {
              originalRequest.headers.Authorization = `Bearer ${token}`;
              return axios(originalRequest);
            })
            .catch(err => {
              Promise.reject(err);
            });
        }
        originalRequest._retry = true;
        isRefreshing = true;
        return new Promise((resolve, reject) => {
          axios
            .post(
              process.env.VUE_APP_API_URL + 'user/refresh-token',
              {},
              {
                headers: { Authorization: 'Bearer ' + getAccessToken() },
              },
            )
            .then(data => {
              let item = data.data;
              // eslint-disable-next-line no-prototype-builtins
              if (item.hasOwnProperty('data')) {
                item = item.data;
              }
              Cookies.set('access_token', item.token, {
                expires: 90,
              });
              Cookies.set('user_id', item.user_id, {
                expires: 90,
              });
              error.config.headers.Authorization = `Bearer ${item.token}`;
              if (item.expires_at !== undefined && item.expires_at) {
                const expiredDateTime = moment().add(item.expires_at, 'seconds').format('YYYY/MM/DD-HH:mm:ss');
                Cookies.set('expired_date_time', expiredDateTime, {
                  expires: 90,
                });
              }
              processQueue(null, getAccessToken());
              resolve(axios(originalRequest));
            })
            .catch(err => {
              processQueue(err, null);
              reject(err);
            })
            .finally(() => {
              isRefreshing = false;
            });
        });
      }
    }
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.request.use(request => {
  if (Cookies.get('access_token') !== undefined) {

    //get last time access from cookie
    let lastTimeAccess = Cookies.get('last_time_access') || moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
    let now = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
    let duration = moment.duration(moment(now).diff(moment(lastTimeAccess)));
    let diffSecond = duration.asSeconds();
    const tokenExpiredSecond = process.env?.VUE_APP_TOKEN_EXPIRED || 60*60;

    //check if last time access less than TOKEN_EXPIRED
    if (diffSecond > tokenExpiredSecond) {
      // romve token and load to page login
      Cookies.remove('access_token');
      setTimeout(() => {
        location.reload();
      }, 150);
    } else {
      request.headers.Authorization = `Bearer ${Cookies.get('access_token')}`;
    }
  }
  Cookies.set('last_time_access', moment(new Date()).format('YYYY-MM-DD HH:mm:ss'), {
    expires: 90,
  });
  Cookies.set('VUE_APP_TOKEN_EXPIRED', process.env?.VUE_APP_TOKEN_EXPIRED || 60*60, {
    expires: 90,
  });
  return request;
});

export default axiosInstance;
