import BaseService from './BaseService';
import API_CODE from '../utils/api_code.js';

export default class OrganizationAPI extends BaseService {
  async getListCiq(params, success, error) {
    await this.get(API_CODE.OP_166 + this.urlParse(params), success, error);
  }
  async detailCiq(id, success, error) {
    await this.get(API_CODE.OP_178 + id, success, error);
  }
  async deleteCiqFile(params, success, error) {
    await this.delete(API_CODE.OP_180 + this.urlParse(params), params, success, error);
  }
  async uploadFileCiq(id, params, success, error) {
    await this.post(API_CODE.OP_184 + id, params, success, error);
  }
  async updateCiq(id, params, success, error) {
    await this.post(API_CODE.OP_189 + id, params, success, error);
  }
}
