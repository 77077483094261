import axiosInstance from '@/plugins/axios';
import CommonAPI from '@/services/CommonAPI';
import AccountAPI from '@/services/AccountAPI';
import DashBoardAPI from '@/services/DashBoardAPI';
import TripAPI from '@/services/TripAPI';
import NotificationAPI from '@/services/NotificationAPI';
import SecurityServiceAPI from '../services/SecurityServiceAPI';
import ZoneAPI from '@/services/ZoneAPI';
import ApplicationAPI from '@/services/ApplicationAPI';
import TripItemAPI from '@/services/TripItemAPI';
import DailyAllowanceAPI from '@/services/DailyAllowanceAPI';
import ScheduleAPI from '@/services/ScheduleAPI';
import ReportAPI from '@/services/ReportAPI';
import EmployeeAPI from '@/services/EmployeeAPI';
import CostAPI from '@/services/CostAPI';
import TripBusinessReportAPI from '@/services/TripBusinessReportAPI';
import PositionAPI from '@/services/PositionAPI';
import OrganizationAPI from '@/services/OrganizationAPI';
import SettingCIQ from '@/services/SettingCIQAPI';
import SettingAPI from '@/services/SettingAPI';
import BudgetAPI from '@/services/BudgetAPI';
import RegulationAPI from '@/services/RegulationAPI';
import ApprovalFlowAPI from '@/services/ApprovalFlowAPI';
import CustomerEnterpriseAPI from '@/services/CustomerEnterpriseAPI';
import UserAPI from '@/services/UserAPI';
import RequirementAPI from '@/services/RequirementAPI';
import InputAPI from '@/services/InputAPI';
import RegulationCheckAPI from '@/services/RegulationCheckAPI';

const services = {
  common: new CommonAPI(axiosInstance, '/'),
  dashboard: new DashBoardAPI(axiosInstance, '/'),
  account: new AccountAPI(axiosInstance, '/'),
  trip: new TripAPI(axiosInstance, '/'),
  notification: new NotificationAPI(axiosInstance, '/'),
  security: new SecurityServiceAPI(axiosInstance, '/'),
  zone: new ZoneAPI(axiosInstance, '/'),
  application: new ApplicationAPI(axiosInstance, '/'),
  trip_item: new TripItemAPI(axiosInstance, '/'),
  allowance: new DailyAllowanceAPI(axiosInstance, '/'),
  schedule: new ScheduleAPI(axiosInstance, '/'),
  report: new ReportAPI(axiosInstance, '/'),
  cost: new CostAPI(axiosInstance, '/'),
  trip_business_report: new TripBusinessReportAPI(axiosInstance, '/'),
  employee: new EmployeeAPI(axiosInstance, '/'),
  position: new PositionAPI(axiosInstance, '/'),
  regulation: new RegulationAPI(axiosInstance, '/'),
  organization: new OrganizationAPI(axiosInstance, '/'),
  setting_CIQ: new SettingCIQ(axiosInstance, '/'),
  settings: new SettingAPI(axiosInstance, '/'),
  budget: new BudgetAPI(axiosInstance, '/'),
  approval_flow: new ApprovalFlowAPI(axiosInstance, '/'),
  customer_enterprise: new CustomerEnterpriseAPI(axiosInstance, '/'),
  user: new UserAPI(axiosInstance, '/'),
  requirement: new RequirementAPI(axiosInstance, '/'),
  input: new InputAPI(axiosInstance, '/'),
  regulation_check: new RegulationCheckAPI(axiosInstance, '/'),
};

export default services;
