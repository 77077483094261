<template>
  <div class="modal--2fa">
    <div class="modal-dialog">
      <div class="modal-content">
        <button type="button" class="close" @click="$emit('cancelChoose')">
          <span class="icon-abui-cross" aria-hidden="true"></span>
        </button>
        <div class="modal-header">
          <div class="modal-header--title">{{ $t('absent.authorized_user.title') }}</div>
        </div>
        <div class="modal-body" style="padding-bottom: 24px">
          <div class="section">
            <div class="add-selected">
              <div class="row">
                <div class="col-sm-12 col-md-12">
                  <div class="js-refine-search">
                    <table class="table table-striped table-word-seach">
                      <thead>
                        <tr>
                          <th>
                            <div class="input-group input-group-sm input-group-rounded" :class="{ focus: hasFocus }">
                              <span class="input-group-btn">
                                <button type="submit" class="btn"><span class="icon-abui-search"></span></button>
                              </span>
                              <input
                                type="text"
                                v-model="searchKeyword"
                                class="form-control js-refine-search__input"
                                :placeholder="$t('absent.authorized_user.search')"
                                @focus="onFocus"
                                @blur="onBlur"
                              />
                            </div>
                          </th>
                        </tr>
                      </thead>
                    </table>
                    <div id="table--user--wrap" style="overflow: auto">
                      <table
                        class="table table-striped table-hover table-word-seach__body table-hover--pointer"
                        style="cursor: not-allowed"
                      >
                        <colgroup v-if="isShowUserId || isEmployeeManagerOperatorOrAdmin" span="1"></colgroup>
                        <colgroup span="1" style="width: 120px"></colgroup>
                        <colgroup span="1"></colgroup>
                        <colgroup span="1" style="width: 60px"></colgroup>
                        <colgroup span="1" style="width: 88px"></colgroup>
                        <tbody id="refine-tgtlist--modal">
                          <tr
                            v-for="(item, index) in listFilter"
                            :key="index"
                            class="js-refine-search__item"
                            :class="absentUserList.includes(item.id) && 'disable-select-custom'"
                            @click="chooseUser(item)"
                          >
                            <td v-if="isShowUserId || isEmployeeManagerOperatorOrAdmin" class="js-refine-search__text">
                              {{ item.employee_number }}
                            </td>
                            <td class="js-refine-search__text">{{ item.name }}</td>
                            <td class="js-refine-search__text">{{ item.organization }}</td>
                            <td>{{ absentUserList.includes(item.id) ? $t('dashboard.absent.is_absent') : '' }}</td>
                            <td class="js-refine-search__text">{{ item.position }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AuthorizedUser',
  emits: ['chooseUser', 'cancelChoose'],
  props: {
    absentUserList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      listApproval: [],
      searchKeyword: '',
      hasFocus: false,
    };
  },
  created() {
    this.startLoading();
    Promise.all([this.getApproval()]).then(() => {
      this.endLoading();
    });
  },
  computed: {
    listFilter() {
      return this.listApproval.filter(item => {
        if (this.searchKeyword == '') return item;

        return (
          String(item.id).toLowerCase().indexOf(this.searchKeyword.toLowerCase()) > -1 ||
          item.name.toLowerCase().indexOf(this.searchKeyword.toLowerCase()) > -1
        );
      });
    },
  },
  methods: {
    onFocus() {
      this.hasFocus = true;
    },
    onBlur() {
      this.hasFocus = false;
    },
    chooseUser(item) {
      this.$emit('chooseUser', item);
    },
    async getApproval() {
      await this.$services.dashboard.getApproval(
        { current_company: 1, request_type: 2 },
        res => {
          if (this.userRole == 'employee_manager') {
            this.listApproval = res.list;
          } else {
            this.listApproval = res.list.filter(item => item.id != this.userId);
          }
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
  },
};
</script>
<style scoped>
.disable-select-custom {
  pointer-events: none;
  -webkit-text-fill-color: var(--el-disabled-text-color);
}
</style>
