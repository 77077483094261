<template>
  <div class="schedule--day--wrap">
    <div class="schedule--day">
      <div class="schedule--day--innner">
        <span class="schedule--daynum">{{ number }}</span>
      </div>
    </div>
    <div class="schedule--date--wrap">
      <span class="schedule--date">
        {{ showDateTime(activity.date, 'YYYY/MM/DD（ddd）') }}
      </span>

      <div class="schedule--allowance auto-detected">
        <a
          href="javascript:void(0)"
          @click="$emit('open', { date: activity.date, isCreate: showAllowance(activity) == '' })"
        >
          <span v-if="showAllowance(activity)">{{ showAllowance(activity) }} </span>
          <span v-else class="text-day">{{$t('trip.daily_allowance_entry')}}：</span>
          <i v-if="!isActionRingi && !isOperatorOrAdmin" class="icon-abui-pencil plx"></i>
        </a>
      </div>
    </div>
  </div>
  <div class="schedule--items--wrap">
    <div v-if="!checkEmpty(listSchedulesOther)">
      <div v-for="(item, i) in listSchedulesOther" :key="i">
        <!-- Display all schedules in Trip component -->
        <!-- Don't show if embed from request page when click button 'Include in request for approval' in schedule dialog -->
        <div v-show="!isActionRingi || (item.is_approval && isActionRingi)">
          <div
            v-if="item.schedule_type == 1"
            class="schedule--item"
            :class="classScheduleCustom(item, item.schedule_type_id, 'item--')"
            @click="showModalUpdateSchedule(item)"
          >
            <el-tooltip
              popper-class="tool-tip-guide"
              v-if="item.is_approval"
              :content="$t('trip.is_approval')"
              placement="top"
            >
              <span v-if="item.is_edited === 1" class="changed--mark">＊</span>
            </el-tooltip>
            <el-tooltip
              popper-class="tool-tip-guide"
              v-if="item.regular_comment"
              :content="item.regular_comment"
              placement="top"
            >
              <span class="regulation-check--mark">
                <i class="icon-abui-alert"></i>
              </span>
            </el-tooltip>
            <div class="schedule--item--icons">
              <span v-if="!checkEmpty(item.note)" class="with-report"></span>
              <span v-if="item.is_file_exist" class="with-file"></span>
              <span v-if="item.is_cost_exist" class="with-expense"></span>
              <span v-if="item.is_clips_exist" class="with-clip"></span>
            </div>
            <div v-if="[1].includes(item.schedule_type_id)" class="schedule--item--inner padding-bottom-24">
              <span class="schedule--time">
                {{ item.start_time ? moment(item.start_time, 'HH:mm:ss').format('HH : mm') : '--:--' }}
              </span>
              <span class="schedule-icon" :class="scheduleIcon(item.schedule_type_id)"></span>
              <img
                v-if="item.airline_code"
                :src="require('@/assets/images/airline/' + item.airline_code + '.gif')"
                alt="logo"
                style="width: 50px; position: relative; height: auto; margin-top: 2px"
              />
              <div class="schedule--transport-code-wrap air" style="margin-right: 70px">
                <span v-if="item.flight_number" class="schedule--transport-code" :class="!item?.url_logo && 'pl-0px'">
                  {{ item.flight_number }}
                </span>
                <span v-if="item.booking_class" class="booking-class">{{ item.booking_class }}</span>
              </div>
              <span class="bottom-left-custom">
                <span v-if="!checkEmpty(item.seat_number)" style="margin-right: 15px">
                  {{ $t('schedule.seat_number') + ' : ' + String(item.seat_number) }}
                </span>
                <span v-if="!checkEmpty(item.seat_class_type)" style="margin-right: 15px">
                  {{ $t('schedule.boarding_class') + ' : ' + labelSeatClass(item.seat_class_type) }}
                </span>
                <span v-if="item.airplane_type == 2 && !checkEmpty(item.transit_airplane_type)">
                  {{ $t('schedule.transit') + ' : ' }}
                  {{
                    item.transit_airplane_type == 1 ? $t('schedule.departure_transit') : $t('schedule.arrival_transit')
                  }}
                </span>
              </span>
              <!-- <span class="schedule--seat" style="display: grid; justify-items: center">
                <span class="top-custom">{{ labelSeatClass(item.seat_class_type) }}</span>
                <br />
                <span class="hide--estimate">{{ item.seat_number }}</span>
              </span> -->
              <span class="schedule--info" :class="scheduleType(item.schedule_type_id)">
                <div class="schedule--transport-item">
                  <span class="transport--airport">{{ labelLocale(item.departure_airport) }}</span>
                  <span class="transport--city">{{ labelLocale(item.departure_city) }}</span>
                  <span class="transport--datetime">
                    {{ showDateTime(item.start_date + ' ' + item.start_time, 'MM/DD（ddd） HH:mm') }}
                  </span>
                </div>
                <div class="schedule--transport-arrow">
                  <!-- <span v-if="item.airplane_type == 1">{{ $t('schedule.direct') }}</span>
                  <span v-if="item.airplane_type == 2">{{ $t('schedule.connecting') }}</span> -->
                </div>
                <div class="schedule--transport-item">
                  <span class="transport--airport">{{ labelLocale(item.arrival_airport) }}</span>
                  <span class="transport--city">{{ labelLocale(item.arrival_city) }}</span>
                  <span class="transport--datetime">
                    {{ showDateTime(item.end_date + ' ' + item.end_time, 'MM/DD（ddd） HH:mm') }}
                  </span>
                </div>
              </span>
            </div>
            <div v-else class="schedule--item--inner">
              <span class="schedule--time">
                {{ item.start_time ? moment(item.start_time, 'HH:mm:ss').format('HH : mm') : '--:--' }}
              </span>
              <span class="schedule-icon" :class="scheduleIcon(item.schedule_type_id)">
                <img v-if="[16].includes(item.schedule_type_id)" src="@/assets/images/icon-baloon.svg" alt="icon" />
                <img v-if="[15].includes(item.schedule_type_id)" src="@/assets/images/icon-deckchair.svg" alt="icon" />
                <img v-if="[3, 5].includes(item.schedule_type_id)" src="@/assets/images/icon-bus.svg" alt="icon" />
                <img v-if="[8].includes(item.schedule_type_id)" src="@/assets/images/icon-chat-dots.svg" alt="icon" />
              </span>
              <div
                class="schedule--transport-code-wrap air"
                v-if="[2].includes(item.schedule_type_id)"
                style="min-width: 150px"
              >
                <span v-if="item.train_number" class="schedule--transport-code">
                  {{ item.train_number }}
                </span>
              </div>
              <span class="schedule--seat" v-if="[2].includes(item.schedule_type_id)">
                <span class="hide--estimate">{{ item.seat_number }}</span>
              </span>
              <span class="schedule--info" :class="[2].includes(item.schedule_type_id) && 'flight'">
                <template v-if="[2].includes(item.schedule_type_id)">
                  <div class="schedule--transport-item">
                    <span class="transport--airport">{{ item.departure_station }}</span>
                    <span class="transport--datetime">
                      {{ showDateTime(item.start_date + ' ' + item.start_time, 'MM/DD（ddd） HH:mm') }}
                    </span>
                  </div>
                  <div class="schedule--transport-arrow"></div>
                  <div class="schedule--transport-item">
                    <span class="transport--airport">{{ item.arrival_station }}</span>
                    <span class="transport--datetime">
                      {{ showDateTime(item.end_date + ' ' + item.end_time, 'MM/DD（ddd） HH:mm') }}
                    </span>
                  </div>
                </template>
                <template v-else>
                  <span v-if="item.schedule_type_id == 6" class="schedule--title">{{ item.hotel_name }}</span>
                  <span v-else class="schedule--title">{{ item.title }}</span>
                  <span v-if="item.schedule_type_id == 6" class="schedule--description">{{ item.hotel_room }}</span>
                  <span v-if="item.schedule_type_id == 6" class="schedule--description">{{ item.arrival_city?.long_name || '' }}</span>
                </template>
              </span>
            </div>
          </div>
          <div class="schedule--todo" v-if="item.schedule_type == 2">
            <div
              class="schedule--todo--item"
              :class="todoIdEditing === item.id && 'item--pointed'"
              @click="editTodo(item)"
            >
              <div class="schedule--todo--title">{{ item.title }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!checkEmpty(listSchedulesHotel)">
      <div v-if="number == numberOfActivities && !checkDateInRange()"></div>
      <div v-else-if="number == numberOfActivities && checkDateInRange()">
        <div v-for="(item, i) in listSchedulesHotel" :key="i">
          <div v-show="!isActionRingi || (item.is_approval && isActionRingi)">
            <div
              v-if="item.schedule_type == 1"
              class="schedule--item"
              :class="classScheduleCustom(item, item.schedule_type_id, 'item--')"
              @click="showModalUpdateSchedule(item)"
            >
              <el-tooltip
                popper-class="tool-tip-guide"
                v-if="item.is_approval"
                :content="$t('trip.is_approval')"
                placement="top"
              >
                <span v-if="item.is_edited === 1" class="changed--mark">＊</span>
              </el-tooltip>
              <el-tooltip
                popper-class="tool-tip-guide"
                v-if="item.regular_comment"
                :content="item.regular_comment"
                placement="top"
              >
                <span class="regulation-check--mark">
                  <i class="icon-abui-alert"></i>
                </span>
              </el-tooltip>
              <div class="schedule--item--icons">
                <span v-if="!checkEmpty(item.note)" class="with-report"></span>
                <span v-if="item.is_file_exist" class="with-file"></span>
                <span v-if="item.is_cost_exist" class="with-expense"></span>
                <span v-if="item.is_clips_exist" class="with-clip"></span>
              </div>
              <div v-if="[1].includes(item.schedule_type_id)" class="schedule--item--inner">
                <span class="schedule--time">
                  {{ '--:--' }}
                </span>
                <span class="schedule-icon" :class="scheduleIcon(item.schedule_type_id)"></span>
                <div class="schedule--transport-code-wrap air">
                  <img
                    v-if="item.url_logo"
                    :src="item.url_logo"
                    alt="logo"
                    style="width: 25px; width: 25px; position: absolute"
                  />
                  <span v-if="item.flight_number" class="schedule--transport-code" :class="!item?.url_logo && 'pl-0px'">
                    {{ item.flight_number }}
                  </span>
                  <span v-if="item.booking_class" class="booking-class">{{ item.booking_class }}</span>
                </div>
                <span class="schedule--seat">
                  <span class="hide--estimate">{{ item.seat_number }}</span>
                </span>
                <span class="schedule--info" :class="scheduleType(item.schedule_type_id)">
                  <div class="schedule--transport-item">
                    <span class="transport--airport">{{ labelLocale(item.departure_airport) }}</span>
                    <span class="transport--city">{{ labelLocale(item.departure_city) }}</span>
                    <span class="transport--datetime">
                      {{ showDateTime(item.start_date + ' ' + item.start_time, 'MM/DD（ddd） HH:mm') }}
                    </span>
                  </div>
                  <div class="schedule--transport-arrow">
                    <!-- <span v-if="item.airplane_type == 1">{{ $t('schedule.direct') }}</span>
                    <span v-if="item.airplane_type == 2">{{ $t('schedule.connecting') }}</span> -->
                  </div>
                  <div class="schedule--transport-item">
                    <span class="transport--airport">{{ labelLocale(item.arrival_airport) }}</span>
                    <span class="transport--city">{{ labelLocale(item.arrival_city) }}</span>
                    <span class="transport--datetime">
                      {{ showDateTime(item.end_date + ' ' + item.end_time, 'MM/DD（ddd） HH:mm') }}
                    </span>
                  </div>
                </span>
              </div>
              <div v-else class="schedule--item--inner">
                <span class="schedule--time">
                  {{ '--:--' }}
                </span>
                <span class="schedule-icon" :class="scheduleIcon(item.schedule_type_id)">
                  <img v-if="[16].includes(item.schedule_type_id)" src="@/assets/images/icon-baloon.svg" alt="icon" />
                  <img
                    v-if="[15].includes(item.schedule_type_id)"
                    src="@/assets/images/icon-deckchair.svg"
                    alt="icon"
                  />
                  <img v-if="[3, 5].includes(item.schedule_type_id)" src="@/assets/images/icon-bus.svg" alt="icon" />
                  <img v-if="[8].includes(item.schedule_type_id)" src="@/assets/images/icon-chat-dots.svg" alt="icon" />
                </span>
                <div class="schedule--transport-code-wrap air" v-if="[2].includes(item.schedule_type_id)">
                  <span v-if="item.train_number" class="schedule--transport-code">{{ item.train_number }}</span>
                </div>
                <span class="schedule--seat" v-if="[2].includes(item.schedule_type_id)">
                  <span class="hide--estimate">{{ item.seat_number }}</span>
                </span>
                <span class="schedule--info" :class="[2].includes(item.schedule_type_id) && 'flight'">
                  <template v-if="[2].includes(item.schedule_type_id)">
                    <div class="schedule--transport-item">
                      <span class="transport--airport">{{ item.departure_station }}</span>
                      <span class="transport--datetime">
                        {{ showDateTime(item.start_date + ' ' + item.start_time, 'MM/DD（ddd） HH:mm') }}
                      </span>
                    </div>
                    <div class="schedule--transport-arrow"></div>
                    <div class="schedule--transport-item">
                      <span class="transport--airport">{{ item.arrival_station }}</span>
                      <span class="transport--datetime">
                        {{ showDateTime(item.end_date + ' ' + item.end_time, 'MM/DD（ddd） HH:mm') }}
                      </span>
                    </div>
                  </template>
                  <div v-else :class="item.schedule_type_id == 6 && 'd-block'">
                    <span v-if="item.schedule_type_id == 6" class="schedule--title">{{ item.hotel_name }}</span>
                    <span v-else class="schedule--title">{{ item.title }}</span>
                    <span v-if="item.schedule_type_id == 6" class="schedule--description">{{ item.hotel_room }}</span>
                    <span v-if="item.schedule_type_id == 6" class="schedule--description">{{ item.arrival_city?.long_name || '' }}</span>
                  </div>
                </span>
              </div>
            </div>
            <div class="schedule--todo" v-if="item.schedule_type == 2">
              <div
                class="schedule--todo--item"
                :class="todoIdEditing === item.id && 'item--pointed'"
                @click="editTodo(item)"
              >
                <div class="schedule--todo--title">{{ item.title }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div v-for="(item, i) in listSchedulesHotel" :key="i">
          <div v-show="!isActionRingi || (item.is_approval && isActionRingi)">
            <div
              v-if="item.schedule_type == 1"
              class="schedule--item"
              :class="classScheduleCustom(item, item.schedule_type_id, 'item--')"
              @click="showModalUpdateSchedule(item)"
            >
              <el-tooltip
                popper-class="tool-tip-guide"
                v-if="item.is_approval"
                :content="$t('trip.is_approval')"
                placement="top"
              >
                <span v-if="item.is_edited === 1" class="changed--mark">＊</span>
              </el-tooltip>
              <el-tooltip
                popper-class="tool-tip-guide"
                v-if="item.regular_comment"
                :content="item.regular_comment"
                placement="top"
              >
                <span class="regulation-check--mark">
                  <i class="icon-abui-alert"></i>
                </span>
              </el-tooltip>
              <div class="schedule--item--icons">
                <span v-if="!checkEmpty(item.note)" class="with-report"></span>
                <span v-if="item.is_file_exist" class="with-file"></span>
                <span v-if="item.is_cost_exist" class="with-expense"></span>
                <span v-if="item.is_clips_exist" class="with-clip"></span>
              </div>
              <div v-if="[1].includes(item.schedule_type_id)" class="schedule--item--inner">
                <span class="schedule--time">
                  {{ '--:--' }}
                </span>
                <span class="schedule-icon" :class="scheduleIcon(item.schedule_type_id)"></span>
                <div class="schedule--transport-code-wrap air">
                  <img
                    v-if="item.url_logo"
                    :src="item.url_logo"
                    alt="logo"
                    style="width: 25px; width: 25px; position: absolute"
                  />
                  <span v-if="item.flight_number" class="schedule--transport-code" :class="!item?.url_logo && 'pl-0px'">
                    {{ item.flight_number }}
                  </span>
                  <span v-if="item.booking_class" class="booking-class">{{ item.booking_class }}</span>
                </div>
                <span class="schedule--seat">
                  <span class="hide--estimate">{{ item.seat_number }}</span>
                </span>
                <span class="schedule--info" :class="scheduleType(item.schedule_type_id)">
                  <div class="schedule--transport-item">
                    <span class="transport--airport">{{ labelLocale(item.departure_airport) }}</span>
                    <span class="transport--city">{{ labelLocale(item.departure_city) }}</span>
                    <span class="transport--datetime">
                      {{ showDateTime(item.start_date + ' ' + item.start_time, 'MM/DD（ddd） HH:mm') }}
                    </span>
                  </div>
                  <div class="schedule--transport-arrow">
                    <!-- <span v-if="item.airplane_type == 1">{{ $t('schedule.direct') }}</span>
                    <span v-if="item.airplane_type == 2">{{ $t('schedule.connecting') }}</span> -->
                  </div>
                  <div class="schedule--transport-item">
                    <span class="transport--airport">{{ labelLocale(item.arrival_airport) }}</span>
                    <span class="transport--city">{{ labelLocale(item.arrival_city) }}</span>
                    <span class="transport--datetime">
                      {{ showDateTime(item.end_date + ' ' + item.end_time, 'MM/DD（ddd） HH:mm') }}
                    </span>
                  </div>
                </span>
              </div>
              <div v-else class="schedule--item--inner">
                <span class="schedule--time">
                  <!-- {{ '--:--' }} -->
                </span>
                <span class="schedule-icon" :class="scheduleIcon(item.schedule_type_id)">
                  <img v-if="[16].includes(item.schedule_type_id)" src="@/assets/images/icon-baloon.svg" alt="icon" />
                  <img
                    v-if="[15].includes(item.schedule_type_id)"
                    src="@/assets/images/icon-deckchair.svg"
                    alt="icon"
                  />
                  <img v-if="[3, 5].includes(item.schedule_type_id)" src="@/assets/images/icon-bus.svg" alt="icon" />
                  <img v-if="[8].includes(item.schedule_type_id)" src="@/assets/images/icon-chat-dots.svg" alt="icon" />
                </span>
                <div class="schedule--transport-code-wrap air" v-if="[2].includes(item.schedule_type_id)">
                  <span v-if="item.train_number" class="schedule--transport-code">{{ item.train_number }}</span>
                </div>
                <span class="schedule--seat" v-if="[2].includes(item.schedule_type_id)">
                  <span class="hide--estimate">{{ item.seat_number }}</span>
                </span>
                <span class="schedule--info" :class="[2].includes(item.schedule_type_id) && 'flight'">
                  <template v-if="[2].includes(item.schedule_type_id)">
                    <div class="schedule--transport-item">
                      <span class="transport--airport">{{ item.departure_station }}</span>
                      <span class="transport--datetime">
                        {{ showDateTime(item.start_date + ' ' + item.start_time, 'MM/DD（ddd） HH:mm') }}
                      </span>
                    </div>
                    <div class="schedule--transport-arrow"></div>
                    <div class="schedule--transport-item">
                      <span class="transport--airport">{{ item.arrival_station }}</span>
                      <span class="transport--datetime">
                        {{ showDateTime(item.end_date + ' ' + item.end_time, 'MM/DD（ddd） HH:mm') }}
                      </span>
                    </div>
                  </template>
                  <div v-else :class="item.schedule_type_id == 6 && 'd-block'">
                    <span v-if="item.schedule_type_id == 6" class="schedule--title">{{ item.hotel_name }}</span>
                    <span v-else class="schedule--title">{{ item.title }}</span>
                    <span v-if="item.schedule_type_id == 6" class="schedule--description">{{ item.hotel_room }}</span>
                    <span v-if="item.schedule_type_id == 6" class="schedule--description">{{ item.arrival_city?.long_name || '' }}</span>
                  </div>
                </span>
              </div>
            </div>
            <div class="schedule--todo" v-if="item.schedule_type == 2">
              <div
                class="schedule--todo--item"
                :class="todoIdEditing === item.id && 'item--pointed'"
                @click="editTodo(item)"
              >
                <div class="schedule--todo--title">{{ item.title }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!isActionRingi && !isOperatorOrAdmin && !disableEditTrip" class="schedule--add-item">
      <button type="button" class="btn btn-sm btn-flat-primary" @click="showModalSchedule()">
        <i class="icon-abui-plus prx"></i>{{ $t('trip_update.add_an_appointment') }}
      </button>
      <p class="error-feedback" v-text="errorNoScheduleCreated" />
    </div>
  </div>
</template>

<script>
import { FLIGHT_CLASS, AIRPLANE, TRAIN, TAXI, HOTEL, MEETING, EXHIBITION, EVENT, OTHER, CAR_RENTAL } from '@/constants/';
import moment from 'moment';
import mixins from '@/helpers/mixins';

export default {
  name: 'Day-Detail',
  emits: ['open', 'editTodo', 'oldTimeZone', 'date', 'listenShowModalSchedule', 'listenShowModalUpdateSchedule'],
  mixins: [mixins],
  components: {},
  props: {
    number: {
      type: Number,
      default: 0,
    },
    todoIdEditing: {
      type: Number,
      default: 0,
    },
    disableEditTrip: {
      type: Boolean,
      default: false,
    },
    scheduleClip: {
      type: Array,
      default: () => [],
    },
    activity: {
      type: Object,
      default: () => {},
    },
    tripDate: {
      type: Object,
      default: () => {},
    },
    isActionRingi: {
      type: Boolean,
      default: false,
    },
    scheduleOff: {
      type: Boolean,
      default: false,
    },
    errorNoScheduleCreated: {
      type: String,
      default: '',
    },
    numberOfActivities: {
      type: Number,
      default: 0,
    },
    newStartDateTrip: {
      type: Date,
      default: null,
    },
    newEndDateTrip: {
      type: Date,
      default: null,
    },
    listScheduleError: {
      type: [Array, Object],
      default: () => [] || {},
    },
  },
  data() {
    return {
      moment: moment,
      showUpdateAllowance: false,
      activeScheduleClick: false,
      scheduleClipActive: [],
      currentDate: moment(new Date()).format('YYYY-MM-DD'),
      flightClass: FLIGHT_CLASS,
    };
  },
  watch: {
    todoIdEditing(val) {
      val && this.scrollFirstItemPointed();
    },
    scheduleOff(value) {
      this.activeScheduleClick = value;
    },
    scheduleClip(value) {
      if (!this.checkEmpty(value)) {
        this.scheduleClipActive = value;
        this.scrollFirstItemPointed();
      }
    },
  },
  computed: {
    listSchedulesOther() {
      let listData = [];
      if (!this.checkEmpty(this.activity?.schedules)) {
        this.activity.schedules.forEach(schedule => {
          if (schedule?.schedule_type_id != 6) {
            listData.push(schedule);
          }
        });
      }
      return listData;
    },
    listSchedulesHotel() {
      let listData = [];
      if (!this.checkEmpty(this.activity?.schedules)) {
        this.activity.schedules.forEach(schedule => {
          if (schedule?.schedule_type_id == 6) {
            listData.push(schedule);
          }
        });
      }
      return listData;
    },
  },
  methods: {
    labelSeatClass(typeId) {
      const typeDetail = this.flightClass.find(item => item.id == typeId);
      if (this.checkEmpty(typeDetail)) return '-';
      return this.labelLocale(typeDetail);
    },
    checkDateInRange() {
      return (
        moment(this.activity.date).isBefore(moment(this.newStartDateTrip)) ||
        moment(this.activity.date).isAfter(moment(this.newEndDateTrip))
      );
    },
    disabledButton() {
      var isDisable = false;
      if (moment(this.activity.date).format('YYYY-MM-DD') < this.currentDate) {
        isDisable = true;
      }
      return isDisable;
    },
    scrollFirstItemPointed() {
      let elementPosition = document.getElementsByClassName('item--pointed');
      if (elementPosition?.length > 0) {
        setTimeout(() => {
          window.scrollTo({
            top: elementPosition[0].offsetTop - 200,
            behavior: 'smooth',
          });
        }, 100);
      }
    },
    editTodo(item) {
      this.$emit('editTodo', item.id);
      this.$emitter.emit('edit-todo', item);
    },
    scheduleIcon(type) {
      let className = '';
      switch (+type) {
        case AIRPLANE:
          className = 'flight';
          break;
        case TRAIN:
          className = 'icon-abui-train';
          break;
        case TAXI:
        case CAR_RENTAL:
          className = 'car';
          break;
        case HOTEL:
          className = 'hotel';
          break;
        case MEETING:
          className = 'icon-abui-blackboard';
          break;
        case EVENT:
        case 13:
        case 14:
          className = 'icon-abui-dine';
          break;
        case EXHIBITION:
        case OTHER:
        case 9:
        case 10:
          className = 'other';
          break;
      }
      return className;
    },
    classScheduleCustom(item, type, text) {
      let className = `schedule${item.id}`;
      if (this.scheduleClipActive.includes(item.id)) {
        className += ' item--pointed';
      }
      if (this.activeScheduleClick) {
        className += '';
      }

      if (this.listScheduleError.includes(item.id)) {
        className += ' error';
      }

      switch (+type) {
        case AIRPLANE:
          className += ' ' + text + 'flight';
          break;
        case TRAIN:
          className += ' train';
          break;
        case TAXI:
          className += ' ' + text + 'car';
          break;
        case HOTEL:
          className += ' ' + text + 'hotel';
          break;
        case MEETING:
          className += ' ' + text + 'meeting';
          break;
        case EVENT:
          className += ' ' + text + 'dine';
          break;
        case EXHIBITION:
        case OTHER:
          className += ' ' + text + 'other';
          break;
      }

      return className;
    },
    activityError(item) {
      if (this.checkDateInRange() || this.listScheduleError.includes(item.id)) {
        return 'error';
      }

      return '';
    },
    activeSchedule(id) {
      let className = `schedule${id}`;
      if (this.scheduleClipActive.includes(id)) {
        className += ' item--pointed';
      }
      if (this.activeScheduleClick) {
        className += '';
      }
      return className;
    },
    scheduleType(type, text = '') {
      let className = '';
      switch (+type) {
        case AIRPLANE:
          className = 'flight';
          break;
        case TRAIN:
          className = 'train';
          break;
        case TAXI:
          className = 'car';
          break;
        case HOTEL:
          className = 'hotel';
          break;
        case MEETING:
          className = 'meeting';
          break;
        case EVENT:
          className = 'dine';
          break;
        case EXHIBITION:
        case OTHER:
          className = 'other';
          break;
      }
      return text + className;
    },
    showAllowance(activity) {
      let label = this.$t('trip_update.daily_allowance') + '：';
      let elm = {};

      if (activity?.daily_allowance_all_day) {
        elm = activity?.daily_allowance_all_day;
      } else if (activity?.daily_allowance_half_day) {
        elm = activity?.daily_allowance_half_day;
      } else if (activity?.daily_allowance_day_trip) {
        elm = activity?.daily_allowance_day_trip;
      }
      if (!this.checkEmpty(elm)) {
        let total = '';
        if (!this.checkEmpty(activity.daily_allowance_hotel)) {
          total =
            ' + ' +
            this.$t('trip_update.accommodation_fee') +
            ' ¥' +
            String(+elm.amount + +activity?.daily_allowance_hotel?.amount + +activity?.extra_amount).replace(
              /(\d)(?=(\d{3})+(?!\d))/g,
              '$1,',
            );
        } else {
          total = ' ¥' + String(+elm.amount + +activity?.extra_amount).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        }

        if (elm?.daily_allowance_type) label += elm.daily_allowance_type;
        return label + total;
      } else {
        return '';
      }
    },
    showModalSchedule() {
      this.$emit('listenShowModalSchedule');
      this.$emit('date', this.activity.date);
      this.$emit('oldTimeZone', this.activity.timeZoneNow);
    },
    showModalUpdateSchedule(schedule) {
      this.$emit('listenShowModalUpdateSchedule', schedule);
      this.$emit('date', this.activity.date);
    },
  },
};
</script>
<style scoped>
.schedule--item.error {
  border: 1px solid red;
}
.schedule--transport-item {
  word-break: break-word;
}
.bottom-left-custom {
  position: absolute;
  bottom: 0;
  left: 5px;
  font-size: 11px;
}
.top-custom {
  color: #2d383c;
}
.min-width-120 {
  min-width: 120px !important;
}
.padding-bottom-24 {
  padding-bottom: 24px !important;
}
.text-day {
  color: #3650a5;
  font-weight: bold;
}
</style>
