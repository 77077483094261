<template>
  <div>
    <div class="mbl" style="display: flex; align-items: center">
      <h4 class="ptn mbn">{{ $t('trip.trip') }}</h4>
      <a href="#" @click="$router.push(PAGES.TRIP)" class="plm" style="display: block; font-size: 13px"
        ><i class="icon-abui-arrow-right prx"></i>{{ $t('common.read_all_trip') }}</a
      >
      <div class="text-right" style="margin-left: auto">
        <a href="#" @click="$router.push({ path: PAGES.TRIP_NEW })" class="btn btn-sm btn-flat-primary hide--sbtm">
          <i class="icon-abui-plus prx"></i>{{ $t('dashboard.trip.add_new') }}
        </a>
      </div>
    </div>

    <div v-if="listTrips">
      <div
        v-for="item in listTrips"
        :key="item.trip_id"
        class="sheet dashboard--plan-item"
        @click="
          $router.push({
            name: PAGES.TRIP_UPDATE,
            params: {
              id: item.trip_id,
            },
          })
        "
      >
        <div class="plan-item--status plan-status" :style="'background-color:' + getBackgroundColor(item)">
          <span :style="'color:' + getColorId(item)">{{ item.code }}</span>
          <span class="item--status-text">
            <span
              class="text-danger"
              v-if="!checkEmpty(item.report_status)"
              :style="'color:' + item?.report_status?.color"
              v-html="labelLocale(item.report_status)"
            ></span>
            <span
              class="text-danger"
              v-else
              :style="'color:' + item?.approval_status?.color"
              v-html="labelLocale(item.approval_status)"
            ></span>
            <span class="phx" v-if="showApprovalExpense && !checkEmpty(item.expense_settlement) && (!checkEmpty(item.report_status) || !checkEmpty(item.approval_status))">/</span>
            <span
              class="text-danger"
              v-if="!checkEmpty(item.expense_settlement) && showApprovalExpense"
              :style="'color:' + item?.expense_settlement?.color"
              v-html="labelLocale(item.expense_settlement)"
            ></span>
          </span>
        </div>
        <div class="plan-item--head">
          <div class="plan-item--head-block">
            <div class="plan-item--title">{{ item.name }}</div>
          </div>
        </div>
        <div class="plan-item--date">
          <i class="icon-abui-calendar prx"></i>
          {{ showDateTime(item.start_time, 'YYYY/MM/DD（ddd）') }} -
          {{ showDateTime(item.end_time, 'YYYY/MM/DD（ddd）') }}
          &nbsp;
          {{
            diffDateTrip(item.end_time, item.start_time) != 0
              ? diffDateTrip(item.end_time, item.start_time) + ' ' + $t('dashboard.trip.days')
              : $t('trip.inputting')
          }}
        </div>
        <div class="plan-item--dest">
          <i class="icon-abui-location prx"></i>
          {{ item.destination }}
          <span class="label label-default">{{ getTripTypeLabel(item.trip_type) }}</span>
        </div>
        <div class="plan-item--update">
          {{ showDateTime(item.updated_at, 'YYYY/MM/DD HH:mm') }} {{ $t('common.label_update') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TripConditions',
  data() {
    return {
      listTrips: [],
    };
  },
  created() {
    this.getListTrips();
  },
  computed: {
    showApprovalExpense() {
      return this.$store.state.auth?.user?.setting?.is_feature_payment || false;
    }
  },
  methods: {
    async getListTrips() {
      this.startLoading();
      await this.$services.dashboard.getListTripsCondition(
        res => {
          this.endLoading();
          this.listTrips = res.list;
        },
        err => {
          this.endLoading();
          this.notifyError(this.$t(err.error));
        },
      );
    },
    getColorId(item) {
      if (!this.checkEmpty(item?.report_status?.color) && item?.report_status?.color != '#') {
        return item?.report_status?.color || '';
      }

      if (!this.checkEmpty(item?.expense_settlement?.color) && item?.expense_settlement?.color != '#') {
        return item?.expense_settlement?.color || '';
      }

      return item?.approval_status?.color || '';
    },
    getBackgroundColor(item) {
      if (!this.checkEmpty(item?.report_status?.background_color) && item?.report_status?.background_color != '#') {
        return item?.report_status?.background_color || '';
      }
      if (
        !this.checkEmpty(
          item?.expense_settlement?.background_color && item?.expense_settlement?.background_color != '#',
        )
      ) {
        return item?.expense_settlement?.background_color || '';
      }

      return item?.approval_status?.background_color || '';
    },
  },
};
</script>
