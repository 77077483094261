import BaseService from './BaseService';
import API_CODE from '../utils/api_code.js';

export default class ZoneAPI extends BaseService {
  async getListZone(success, error) {
    await this.get(API_CODE.CU_185, success, error);
  }
  async downloadFileCSV(success, error) {
    await this.get(API_CODE.CU_186, success, error);
  }
  async downloadExampleFileCSV(success, error) {
    await this.get(API_CODE.CU_187, success, error);
  }
  async uploadFileCSV(params, success, error) {
    await this.post(API_CODE.CU_188, params, success, error);
  }
  async getZoneDetail(id, success, error) {
    await this.get(API_CODE.CU_201 + id, success, error);
  }
  async updateZone(params, success, error) {
    await this.post(API_CODE.CU_202 + params.id, params.zone, success, error);
  }
  async createZone(params, success, error) {
    await this.post(API_CODE.CU_203, params, success, error);
  }
  async deleteZone(id, success, error) {
    await this.delete(API_CODE.CU_204 + id, {}, success, error);
  }
  async getDailyAllowance(success, error) {
    await this.get(API_CODE.CU_141, success, error);
  }
  async saveDailyAllowance(params, success, error) {
    await this.post(API_CODE.CU_142, params, success, error);
  }
  async saveDailyAllowanceFiles(params, success, error) {
    await this.post(API_CODE.CU_281, params, success, error);
  }
}
