<template>
  <div class="todo-wrap info-list-wrap" id="list-todo-wrap">
    <div v-loading="loading" v-show="showBoxCreate" class="detail--wrap todo--detail">
      <Form @submit.prevent="submitTodo" :validation-schema="validate" ref="form">
        <a class="btn btn-sm btn-flat-default btn-detail-close js-btn--btn--close-detail" @click="cancelTodo()">
          <i class="icon-abui-cross"></i>
        </a>
        <div class="detail--title">
          <label
            v-if="!flgCreate && tripData.user_id == userId"
            class="checkbox"
            :class="todo.is_completed && 'checked'"
          >
            <span class="icons">
              <span class="first-icon icon-abui-checkbox-unchecked" />
              <span class="second-icon icon-abui-checkbox-checked" />
            </span>
            <input
              v-model="todo.is_completed"
              :disabled="(isOperatorOrAdmin || tripData.user_id != userId) && 'disabled'"
              type="checkbox"
            />
          </label>
          <Field
            name="title"
            type="text"
            class="form-control input-sm"
            :placeholder="$t('trip.todo.pl_title')"
            id="login-company"
            maxlength="300"
            v-model="todo.title"
            :disabled="isOperatorOrAdmin || tripData.user_id != userId"
          />
          <ErrorMessage style="position: absolute; top: 50px" name="title" class="error-feedback" as="p" />
        </div>
        <div class="row">
          <div class="col-sm-6 col-all-closer col-all-closer--leftend">
            <div class="form-group">
              <label>
                <span class="label-text">{{ $t('trip.todo.date_time') }}</span>
              </label>
              <div class="input-with-icon">
                <el-date-picker
                  class="date-input"
                  v-model="todo.date"
                  :clearable="false"
                  type="date"
                  format="YYYY/MM/DD"
                  value-format="YYYY/MM/DD"
                  :placeholder="$t('common.enter_or_select')"
                  :disabled-date="pickerOptions"
                  :disabled="isOperatorOrAdmin || tripData.user_id != userId"
                />
              </div>
              <p role="alert" class="error-feedback" v-text="errors_message.date"></p>

              <p class="help-block">{{ $t('trip.todo.date_time_note') }}</p>
            </div>
          </div>
          <div class="col-sm-6 col-all-closer col-all-closer--rightend">
            <div class="form-group">
              <label><span class="label-text">&ensp;</span></label>
              <div class="input-with-icon">
                <el-input
                  :disabled="isOperatorOrAdmin || tripData.user_id != userId"
                  v-model="todo.time"
                  placeholder="00:00"
                  @blur="checkStartTime('time')"
                >
                  <template #suffix>
                    <el-icon class="el-input__icon"><clock /></el-icon>
                  </template>
                </el-input>
              </div>
              <p role="alert" class="error-feedback" v-text="errors_message.time" ></p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <label>
                <span class="label-text">{{ $t('trip.todo.location_string') }}</span>
              </label>
              <Field
                name="location_string"
                type="text"
                class="form-control input-sm"
                :placeholder="$t('trip.todo.pl_location_string_required')"
                maxlength="300"
                v-model="todo.location_string"
                :disabled="isOperatorOrAdmin || tripData.user_id != userId"
              />
              <ErrorMessage name="location_string" class="error-feedback" as="p" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <label>
                <span class="label-text">{{ $t('trip.todo.note') }}</span>
              </label>
              <el-input
                :rows="4"
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 4 }"
                maxlength="300"
                v-model="todo.note"
                :disabled="isOperatorOrAdmin || tripData.user_id != userId"
              />
              <ErrorMessage name="note" class="error-feedback" as="p" />
            </div>
          </div>
        </div>
      </Form>
      <div v-if="!disableEdit" style="position: relative">
        <button
          class="btn btn-sm btn-flat-default"
          v-if="!flgCreate"
          :disabled="isOperatorOrAdmin || tripData.user_id != userId"
          @click="showBoxDelete = true"
        >
          <i class="icon-abui-trash prx"></i>{{ $t('trip.todo.delete') }}
        </button>
        <button
          class="btn btn-sm btn-primary"
          style="min-width: 100px; position: absolute; right: 0"
          @click="submitTodo()"
          :disabled="isOperatorOrAdmin || isSubmitCreate || tripData.user_id != userId"
        >
          {{ $t('common.submit') }}
        </button>
      </div>
    </div>

    <div v-show="!showBoxCreate" class="list-todo">
      <div class="todo--progress">
        <div v-for="(item, index) in listTodosProcess" :key="index" class="todo-item" @click="editTodo(item)">
          <label
            v-if="!disableEdit && tripData.user_id == userId"
            class="checkbox"
            @click.stop="completeTodo(item, true)"
          >
            <span class="icons">
              <span class="first-icon icon-abui-checkbox-unchecked" />
              <span class="second-icon icon-abui-checkbox-checked" />
            </span>
            <input type="checkbox" :disabled="(isOperatorOrAdmin || tripData.user_id != userId) && 'disabled'" />
          </label>
          <div class="todo-item-body">
            <div class="todo-item-title one-line">{{ item.title }}</div>
            <div class="todo-item-duedate-location">
              <span class="todo-duedate">{{ showDateTime(item.date_time, 'YYYY/MM/DD') }}</span>
              <span class="todo-location one-line">{{ item.location_string }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="js-accordion">
        <a
          v-if="listTodosComplete.length > 0"
          href="#"
          class="btn btn-sm btn-flat-default js-accordion-btn"
          @click="showComplete = !showComplete"
        >
          <template v-if="!showComplete">
            <span class="icon icon-abui-arrow-right prx" />
            {{ $t('trip.todo.view_completed') }}
          </template>
          <template v-else>
            <span class="icon prx icon-abui-arrow-down" />
            {{ $t('trip.todo.hide_completed') }}
          </template>
        </a>
        <div class="js-toggle-content" v-show="showComplete">
          <div class="form-group todo--done">
            <div v-for="(item, index) in listTodosComplete" :key="index" class="todo-item" @click="editTodo(item)">
              <label
                v-if="!disableEdit && tripData.user_id == userId"
                class="checkbox checked"
                @click.stop="completeTodo(item, false)"
              >
                <span class="icons">
                  <span class="first-icon icon-abui-checkbox-unchecked" />
                  <span class="second-icon icon-abui-checkbox-checked" />
                </span>

                <span class="icons">
                  <span class="first-icon icon-abui-checkbox-unchecked" />
                  <span class="second-icon icon-abui-checkbox-checked" />
                </span>

                <input type="checkbox" :disabled="(isOperatorOrAdmin || tripData.user_id != userId) && 'disabled'" />
              </label>
              <div class="todo-item-body">
                <div class="todo-item-title one-line">{{ item.title }}</div>
                <div class="todo-item-duedate-location">
                  <span class="todo-duedate"> {{ showDateTime(item.date_time, 'YYYY/MM/DD') }}</span>
                  <span class="todo-location one-line"> {{ item.location_string }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showButtonCreate && !disableEdit" class="detail-foot">
      <button
        class="btn btn-flat-primary btn-sm js-btn-addnew-info-item"
        :disabled="isOperatorOrAdmin || tripData.user_id != userId"
        @click="newTodo()"
      >
        <i class="icon-abui-plus prx"></i>{{ $t('trip.todo.add_todo') }}
      </button>
    </div>
  </div>

  <ModalConfirm
    :dialogVisible="showBoxDelete"
    @cancel="showBoxDelete = false"
    @yes="deleteTodo()"
    :title="$t('Q-CM-104')"
  />
</template>
<script>
import ModalConfirm from '@/components/common/ModalConfirm';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import moment from 'moment';

export default {
  name: 'Trip-Todo',
  emits: ['setCenterMap', 'activeTabTodo', 'onChangeData'],
  components: { Form, Field, ErrorMessage, ModalConfirm },
  props: {
    resetTodo: {
      type: [Number, String],
      default: 0,
    },
    tripData: {
      type: Object,
      default: () => {},
    },
    showButtonCreate: {
      type: Boolean,
      default: true,
    },
    checkDisableEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      btnShowAdd: true,
      disableEdit: false,
      listTodosProcess: [],
      listTodosComplete: [],
      loading: false,
      showComplete: false,
      showBoxCreate: false,
      showBoxDelete: false,
      flgCreate: false,
      todo: {
        is_completed: false,
        title: '',
        date: '',
        time: '',
        location_string: '',
        note: '',
        updated_at: '',
      },
      todoOfficial: {
        is_completed: false,
        title: '',
        date: '',
        time: '',
        location_string: '',
        note: '',
        updated_at: '',
      },
      isSubmit: false,
      isSubmitCreate: false,
      errors_message: {},
    };
  },
  watch: {
    showButtonCreate(val) {
      this.btnShowAdd = val;
    },
    checkDisableEdit(val) {
      this.disableEdit = val;
    },
    resetTodo() {
      this.showBoxCreate = false;
      this.$emitter.emit('activeTodo', 0);
    },
    'todo.date'(val) {
      if (val) {
        this.errors_message.date = '';
      } else {
        this.errors_message.date = this.$t('E-CM-100', { item: this.$t('trip.todo.date') });
      }
    },
    'todo.time'(val) {
      if (!val) {
        this.errors_message.time = this.$t('E-CM-100', { item: this.$t('trip.todo.time') });
      }
    },
    todo: {
      handler: function () {
        this.onCheckDataChange();
      },
      deep: true,
    },
    isSubmit: {
      handler: function () {
        this.onCheckDataChange();
      },
      deep: true,
    },
  },
  created() {
    this.getListTodos();
    this.disableEdit = this.checkDisableEdit;
  },
  computed: {
    markers() {
      return this.$store.state.map.markers;
    },
    tripId() {
      return this.$route.params.id;
    },
    validate() {
      return yup.object().shape({
        title: yup
          .string()
          .trim()
          .required(this.$t('E-CM-006'))
          .max(300, this.$t('E-CM-111', { size: 300 })),
        date: yup.string().required(this.$t('E-CM-100', { item: this.$t('trip.todo.date') })),
        time: yup.string().required(this.$t('E-CM-100', { item: this.$t('trip.todo.time') })),
        location_string: yup
          .string()
          .trim()
          .required(this.$t('E-CM-100', { item: this.$t('trip.todo.location_string') }))
          .max(300, this.$t('E-CM-111', { size: 300 })),
        note: yup
          .string()
          .trim()
          .max(300, this.$t('E-CM-111', { size: 300 })),
      });
    },
  },
  mounted() {
    this.$emitter.on('edit-todo', async item => {
      if (item) {
        await this.getTodoDetail(item.id);

        this.flgCreate = false;
        this.showBoxCreate = true;
      }
    });
  },
  beforeUnmount() {
    this.$emitter.off('edit-todo');
  },
  methods: {
    checkStartTime(key) {
      let val = this.todo[key];
      if (val) {
        val = this.autoFormatTime(val);
        if (this.isValidTime(val)) {
          this.errors_message[key] = '';
        } else {
          this.errors_message[key] = this.$t('input.time_error');
        }

        this.todo[key] = val;
      }
    },
    onCheckDataChange() {
      let result = !this.compareObject(this.todo, this.todoOfficial) && !this.isSubmit;
      this.$emit('onChangeData', result);
    },
    async getLatLng(id, address) {
      if (!address) return;

      await this.$services.common.searchTimeZones(
        { address },
        res => {
          if (res?.lat && res?.lng) {
            this.$store.dispatch('map/updateMarkers', [
              ...this.$store.state.map.markers,
              {
                id,
                position: { lat: +res.lat, lng: +res.lng },
                name: address,
              },
            ]);
          }
        },
        () => {
          return {};
        },
      );
    },
    pickerOptions(time) {
      if (this.checkEmpty(this.tripData)) return false;

      return (
        moment(new Date(time)).format('YYYY-MM-DD') < moment(this.tripData.start_time).format('YYYY-MM-DD') ||
        moment(new Date(time)).format('YYYY-MM-DD') > moment(this.tripData.end_time).format('YYYY-MM-DD')
      );
    },
    getTodoDetail(todoId) {
      if (!todoId) return;
      this.$emit('activeTabTodo');

      this.loading = true;
      this.$services.trip.todoDetail(
        todoId,
        res => {
          this.loading = false;
          this.configTodo(res);
          const marker = this.markers.find(item => item.id == todoId);
          if (marker) this.$emit('setCenterMap', { lat: marker.position.lat, lng: marker.position.lng });
        },
        err => {
          this.loading = false;
          this.notifyError(this.$t(err.error));
        },
      );
    },
    configTodo(item) {
      const { date_time } = item;
      let [date, time] = date_time.split(' ');
      this.todo = {
        id: Number(item.id),
        title: item.title,
        is_completed: item.is_completed == 1,
        date: moment(date).format('YYYY/MM/DD'),
        time: time.substring(0, 5),
        location_string: item.location_string || '',
        note: item.note || '',
        updated_at: item.updated_at,
      };
      this.todoOfficial = {
        id: Number(item.id),
        title: item.title,
        is_completed: item.is_completed == 1,
        date: moment(date).format('YYYY/MM/DD'),
        time: time.substring(0, 5),
        location_string: item.location_string || '',
        note: item.note || '',
        updated_at: item.updated_at,
      };
    },
    deleteTodo() {
      this.isSubmit = true;
      if (!this.todo.id) return;

      this.$services.trip.deleteTodo(
        this.todo.id,
        () => {
          this.showBoxDelete = false;
          this.showBoxCreate = false;
          this.notifySuccess(this.$t('I-CM-102'));
          this.$emitter.emit('activeTodo', 0);
          this.getListTodos();
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    cancelTodo() {
      this.todo = {
        is_completed: false,
        date: '',
        time: '',
        note: '',
      };
      this.showBoxCreate = false;
      this.$emitter.emit('activeTodo', 0);
    },
    newTodo() {
      this.$emitter.emit('activeTodo', 0);
      this.$refs.form.setValues({
        title: '',
        date: '',
        time: '',
        is_completed: false,
        location_string: '',
        note: '',
      });

      this.todo = {
        is_completed: false,
        date: '',
        time: '',
        note: '',
      };

      setTimeout(() => {
        this.$refs.form.setErrors({
          title: '',
          date: '',
          time: '',
        });

        this.errors_message = {
          ...this.errors_message,
          date: '',
          time: '',
        };

        this.showBoxCreate = true;
        this.flgCreate = true;
      }, 100);
    },
    async editTodo(item) {
      await this.getTodoDetail(item.id);

      this.showBoxCreate = true;
      this.flgCreate = false;

      this.$emitter.emit('activeTodo', item.id);
    },
    submitTodo() {
      this.$refs.form.setFieldValue('date', this.todo.date || '');
      this.$refs.form.setFieldValue('time', this.todo.time || '');
      this.$refs.form.setFieldValue('note', this.todo.note || '');

      if (this.errors_message.time) return;

      this.$refs.form.validate().then(res => {
        this.isSubmit = true;
        if (res.valid) {
          this.isSubmitCreate = true;
          if (this.flgCreate) {
            this.$services.trip.createTodo(
              { ...this.todo, trip_id: this.tripId },
              () => {
                this.showBoxCreate = false;
                this.getListTodos();
                this.setCenter(this.todo.location_string);
                this.isSubmitCreate = false;
                this.$emitter.emit('activeTodo', 0);
              },
              err => {
                this.isSubmitCreate = false;
                this.showBoxCreate = false;
                this.notifyError(this.$t(err.error));
              },
            );
          } else {
            this.$services.trip.updateTodo(
              this.todo.id,
              this.todo,
              () => {
                this.showBoxCreate = false;
                this.isSubmitCreate = false;
                this.getListTodos();
                this.setCenter(this.todo.location_string);
                this.$emitter.emit('activeTodo', 0);
              },
              err => {
                this.isSubmitCreate = false;
                this.showBoxCreate = false;
                this.notifyError(this.$t(err.error), true);
              },
            );
          }
        } else {
          this.errors_message = {
            ...res.errors,
          };
        }
      });
    },
    setCenter(address) {
      this.$services.common.searchTimeZones(
        { address },
        res => {
          if (res?.lat && res?.lng) {
            this.$emit('setCenterMap', { lat: res?.lat, lng: res?.lng });
          }
        },
        () => {},
      );
    },
    completeTodo(item, is_completed) {
      if (this.isOperatorOrAdmin || this.tripData.user_id != this.userId) return;

      const { date_time } = item;
      let [date, time] = date_time.split(' ');

      this.$services.trip.updateTodo(
        item.id,
        {
          ...item,
          date: moment(date).format('YYYY/MM/DD'),
          time: time.substring(0, 5),
          is_completed,
        },
        () => {
          this.$emitter.emit('activeTodo', 0);
          this.getListTodos();
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    getListTodos() {
      this.loading = true;
      this.$services.trip.getListTodos(
        { trip_id: this.tripId },
        res => {
          this.loading = false;
          this.$store.dispatch('map/updateMarkers', []);
          let listTodosProcess = [];
          let listTodosComplete = [];

          res.list.forEach(todo => {
            if (todo.is_completed) listTodosComplete.push(todo);
            else listTodosProcess.push(todo);
          });

          this.listTodosProcess = listTodosProcess;
          this.listTodosComplete = listTodosComplete;

          res.list.map(async item => {
            await this.getLatLng(item.id, item.location_string);
          });
        },
        err => {
          this.loading = false;
          this.notifyError(this.$t(err.error));
        },
      );
    },
  },
};
</script>
