<template>
  <nav class="navbar navbar-default navbar-lg" role="navigation" id="header-content">
    <div class="navbar-header">
      <h2 class="navbar-title">{{ $t('requirement.requirement') }}</h2>
    </div>
  </nav>
  <div class="wrapper with-navbar-fixed-bottom">
    <div class="container-fluid">
      <div class="plan-status status-done" style="margin-bottom: 40px; background-color: #f7f8f9; color: #657a84">
        <span style="color: #657a84" class="">
          {{ requirement.user?.name }}
        </span>
        <span>
          <span v-if="requirement.required_procedure_status == 1" class="plan--staff">
            {{ $t('requirement.status.1') }}
          </span>
          <span v-if="requirement.required_procedure_status == 2" class="plan--staff">
            {{ $t('requirement.status.2') }}
          </span>
          <span v-if="requirement.required_procedure_status == 3" class="plan--staff">
            {{ $t('requirement.status.3') }}
          </span>
          <span v-if="requirement.required_procedure_status == 4" class="plan--staff">
            {{ $t('requirement.status.4') }}
          </span>
          <span
            v-if="[3, 4].includes(requirement.required_procedure_status)"
            class="status-text"
            style="color: #657a84"
          >
            <i class="icon-abui-check-inverted plx"
          /></span>
        </span>
      </div>

      <div class="section noborder ptn" id="section--main">
        <div class="">
          <div class="row">
            <div class="col-sm-12">
              <div class="row">
                <div class="col-sm-12">
                  <div class="">
                    <a @click="redirectTrip()" style="cursor: pointer" rel="noopener">
                      <i class="icon-abui-globe prx" />{{ requirement.trip?.code }}：{{ requirement.trip?.name }}
                    </a>
                  </div>
                </div>
              </div>
              <p>
                {{ showDateTime(requirement.trip?.start_time, 'YYYY/MM/DD（ddd）') }} -
                {{ showDateTime(requirement.trip?.end_time, 'YYYY/MM/DD（ddd）') }}
              </p>
              <p>
                <a @click="redirectCustomer()" style="cursor: pointer" rel="noopener">
                  {{ requirement?.trip_creator?.name }}
                </a>
              </p>
              <p class="reauirement--passport">
                {{ !checkEmpty(fullName) ? fullName : '' }}
                {{ !checkEmpty(countryName) ? countryName : '' }}
                {{ !checkEmpty(old) ? old : '' }}
                {{ !checkEmpty(old) ? $t('requirement.age') : '' }}
                <label v-show="!checkEmpty(expiredDate)">{{ $t('requirement.passport_validity_period') }}：</label>
                <span v-show="!checkEmpty(expiredDate)" class="expire ok" :style="expired <= 0 ? 'color:red' : ''">
                  {{ expiredDate }}
                  {{ `(${expired > 0 ? `${expired + $t('requirement.day')}` : $t('requirement.passport_expired')})` }}
                  <i v-if="expired > 0" class="icon-abui-check-inverted plx" />
                </span>
              </p>

              <hr />

              <div
                v-for="(item, index) in requirement.guids"
                :key="index"
                class="requirement-item dropshadow--lv1 checked dummy--has-update__tgt"
                :class="requirement.required_procedure_status == 4 && index == 0 && 'has-update'"
                :style="item.is_invited ? '' : 'opacity: 0.7'"
              >
                <div style="display: flex; align-items: flex-start; justify-content: space-between">
                  <div class="form-group">
                    <div style="display: flex" class="d-flex">
                      <el-checkbox
                        @change="changeStatus(item)"
                        v-model="item.is_invited"
                        class="checkbox-display"
                        style="display: flex"
                        :true-label="1"
                        :false-label="0"
                        size="large"
                        :disabled="requirement.required_procedure_status == 3"
                      >
                        <label>{{ $t('requirement.invite') }}</label>
                      </el-checkbox>
                    </div>
                  </div>
                  <button @click="handleDeleteRequirement(item.id)" class="btn btn-sm btn-flat-danger"
                          v-if="requirement.required_procedure_status != 3">
                    <i class="icon-abui-cross" />
                  </button>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <div class="form-group viewonly">
                      <label>
                        <span class="label-text">{{ $t('requirement.destination_country_1') }}</span>
                      </label>
                      <p class="viewonly-text input-sm input-sm" id="">{{ labelLocale(item.country) }}</p>
                    </div>
                  </div>
                  <div class="col-sm-8">
                    <div class="form-group viewonly">
                      <label>
                        <span class="label-text">{{ $t('requirement.departure_and_arrival') }}</span>
                      </label>
                      <input
                        type="text"
                        v-model="item.departure"
                        @change="checkOnChangeData"
                        @blur="handleFocusOut(item, index)"
                        :tabindex="index"
                        class="form-control input-sm"
                        :placeholder="$t('requirement.placeholder_detail_information')"
                        :disabled="requirement.required_procedure_status == 3"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="mbm">
                      <ul class="uploaded--file-list" style="">
                        <li style="" v-for="(input, index) in item.attachments[0].inputs" :key="index">
                          <a
                            :href="input?.file?.file_path"
                            target="_blank"
                            style="display: flex; align-items: center"
                            rel="noopener"
                          >
                            <i class="icon-abui-file" />
                            <span class="file--name">{{ input?.file?.name }}</span>
                          </a>
                          <span class="file--date text-left">{{ input?.file?.created_at }}</span>
                        </li>
                      </ul>
                    </div>

                    <div class="form-group viewonly">
                      <div
                        :class="
                          requirement.required_procedure_status == 4 && item.attachments[0].updated_by
                            ? 'show--has-update'
                            : ''
                        "
                      >
                        <label>
                          <span class="label-text">{{ $t('requirement.requirements') }}</span>
                        </label>
                        <div
                          v-if="requirement.required_procedure_status == 3"
                          class="text-disable-with-style"
                          style="min-height: 400px; overflow-x: auto;"
                          :style="
                            requirement.required_procedure_status == 4 &&
                            item.attachments[0].updated_by &&
                            item.is_ciq_update == 1
                              ? 'border-color: #65c15e; background-color: rgba(101, 193, 94, 0.1)'
                              : ''
                          "
                        >
                          <div
                            v-for="(text, index) in splitEndLine(listDetail[index])"
                            :key="`idx${index}`"
                            v-html="text"
                          />
                        </div>
                        <QuillEditor
                          v-else
                          @blur="handleFocusOut(item, index)"
                          @focus="handleFocusEditor(index)"
                          contentType="html"
                          theme="snow"
                          v-model:content="listDetail[index]"
                          :content="listDetail[index]"
                          :style="
                            requirement.required_procedure_status == 4 && 'background-color: rgba(101,193,94,.1);'
                          "
                          :toolbar="[
                            [
                              'bold',
                              'italic',
                              'underline',
                              'strike',
                              { align: ['', 'center', 'right', 'justify'] },
                              { size: ['small', false, 'large', 'huge'] },
                            ],
                            ['link'],
                          ]"
                        />
                        <div v-show="false">
                          <QuillEditor
                            @blur="handleFocusOut(item, index)"
                            @focus="handleFocusEditor(index)"
                            contentType="html"
                            theme="snow"
                            v-model:content="listDetail[index]"
                            :content="listDetail[index]"
                            :style="
                              requirement.required_procedure_status == 4 && 'background-color: rgba(101,193,94,.1);'
                            "
                            :toolbar="[
                              [
                                'bold',
                                'italic',
                                'underline',
                                'strike',
                                { align: ['', 'center', 'right', 'justify'] },
                                { size: ['small', false, 'large', 'huge'] },
                              ],
                              ['link'],
                            ]"
                          />
                        </div>
                      </div>
                      <div
                        v-if="
                          item.detail_information &&
                          requirement.required_procedure_status == 4 &&
                          item.attachments[0].updated_by > 0
                        "
                      >
                        <label>
                          <span class="label-text">{{ $t('requirement.guided_content') }}</span>
                        </label>
                        <div
                          v-for="(detailInfo, index) in item.detail_information.split(',')"
                          :key="index"
                          class="text-disable-with-style"
                          style="min-height: 400px; overflow-x: auto;"
                          v-html="item.detail_information.split(',').reverse()[index]"
                        />
                      </div>
                      <p></p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row--addcontrol" v-if="![4, 3].includes(this.requirement.required_procedure_status)">
                <div class="btn-group js-btn--addcountry">
                  <button
                    @click="btnAddCountry"
                    type="button"
                    class="btn btn-sm btn-flat-primary dropdown-toggle"
                    style="margin-bottom: 10px"
                  >
                    <i class="icon-abui-plus prx" />{{ $t('requirement.add_country') }}
                    <span class="caret" />
                  </button>
                  <SingleOptionSelect
                    v-if="isAddRequirement"
                    ref="CountryRequirementSelect"
                    :list-data="validCountryList"
                    :display-by-locale="true"
                    :isListCountry="true"
                    :isFocus="isFocus"
                    :showClearable="false"
                    @handle-selected-params="listenSelectCountry"
                    @defaultSelectFocus="defaultSelectFocus"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="requirement.required_procedure_status != 3 && guidActive"
      class="navbar navbar-default navbar-fixed-bottom navbar-footblock with-sidebar submit-new--wrap show--estimate"
      role="navigation"
      style="z-index: 990; padding: 12px"
    >
      <div class="row">
        <div class="col-sm-4 col-md-3 col-sm-offset-8 col-md-offset-9">
          <a @click="handleSendNotifyAction()" href="#" class="btn btn-sm btn-block btn-primary">
            {{ $t('requirement.guide_to_customers') }}
          </a>
        </div>
      </div>
    </div>
  </div>
  <ModalConfirm
    :dialogVisible="showModalConfirm"
    @cancel="showModalConfirm = false"
    @yes="deleteRequirement"
    :title="$t('Q-CM-104')"
  />
  <ModalConfirmSendNotify
    :dialogVisible="showModalConfirmSend"
    @cancel="showModalConfirmSend = false"
    @yes="sendNotifyRequirement"
    :title="$t('requirement.confirm_send_notify')"
  />
</template>
<script>
import moment from 'moment';
import SingleOptionSelect from '@/components/common/SingleOptionSelect.vue';
import ModalConfirm from '@/components/common/ModalConfirm';
import ModalConfirmSendNotify from '@/components/common/ModalConfirmSendNotify';
import mixins from '@/helpers/mixins';

import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

export default {
  name: 'detail',
  mixins: [mixins],
  components: { SingleOptionSelect, ModalConfirm, ModalConfirmSendNotify, QuillEditor },
  props: {},
  data() {
    return {
      showEdit: false,
      input: '',
      listCountryNew: [],
      guid_id: '',
      country: '',
      isAddRequirement: false,
      showModalConfirm: false,
      showModalConfirmSend: false,
      listCountries: [],
      requirement: {},
      requirementOfficial: {},
      errors: {
        delivery_date: '',
        title: '',
        text_detail: '',
        company_id: '',
      },
      isFocus: false,
      listDetail: [],
      listDetailOfficial: [],
      isSubmit: false,
      listCountryInDetail: [],
      currentRequirementIndex: -1,
    };
  },
  beforeRouteLeave(to, from, next) {
    if (
      (!this.compareArray(this.requirement.guids, this.requirementOfficial.guids) ||
        !this.compareArray(this.listDetail, this.listDetailOfficial)) &&
      !this.isSubmit
    ) {
      const answer = window.confirm(this.$t('common.confirm_leave_page'));
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  watch: {
    country: function (newVal) {
      if (newVal) {
        this.isAddRequirement = false;
      }
    },
  },
  computed: {
    validCountryList() {
      let listData = [];
      if (this.checkEmpty(this.listCountries)) return listData;
      this.listCountries.forEach(country => {
        if (!this.listCountryInDetail.includes(country.id)) listData.push(country);
      });
      return listData;
    },
    guidActive() {
      return !this.checkEmpty(this.requirement?.guids?.find(item => item.is_invited == 1));
    },
    requirementId() {
      return this.$route.params.id || 0;
    },
    old() {
      let bird_date = !this.checkEmpty(this.requirement?.trip_creator?.passports[0])
        ? this.requirement?.trip_creator.passports[0].bird_date
        : Date.now();
      let currentDate = moment(new Date()).format('YYYY-MM-DD');

      return this.diffDate(currentDate, bird_date, 'years');
    },
    expired() {
      function datediff(first, second) {
        return Math.round((second - first) / (1000 * 60 * 60 * 24));
      }
      let now = Date.now();
      let expired = !this.checkEmpty(this.requirement?.trip_creator?.passports[0])
        ? new Date(this.requirement?.trip_creator.passports[0].expired_date)
        : Date.now();

      return datediff(now, expired);
    },
    fullName() {
      let first_name = !this.requirement?.trip_creator?.passports[0]?.first_name
        ? ''
        : this.requirement.trip_creator.passports[0].first_name;
      let middle_name = !this.requirement?.trip_creator?.passports[0]?.middle_name
        ? ''
        : this.requirement.trip_creator.passports[0].middle_name;
      let last_name = !this.requirement?.trip_creator?.passports[0]?.last_name
        ? ''
        : this.requirement.trip_creator.passports[0].last_name;

      return first_name + ' ' + middle_name + ' ' + last_name;
    },
    countryName() {
      let country = !this.checkEmpty(this.requirement?.trip_creator?.passports[0])
        ? this.requirement?.trip_creator?.passports[0].country
        : '';
      return !this.checkEmpty(country) ? '(' + this.labelLocale(country) + ')' : '';
    },
    expiredDate() {
      let expired_date = !this.checkEmpty(this.requirement?.trip_creator?.passports[0])
        ? this.requirement.trip_creator.passports[0].expired_date
        : '';
      return !this.checkEmpty(expired_date) ? moment(expired_date).format('YYYY-MM-DD') : '';
    },
  },
  created() {
    this.startLoading();
    Promise.all([this.getListCountry(), this.getRequirementDetail()]).then(() => {
      this.endLoading();
    });
  },
  methods: {
    defaultSelectFocus() {
      this.isFocus = false;
    },
    handleFocusEditor(index) {
      this.currentRequirementIndex = index;
    },
    handleEditDetailInformation() {
      this.showEdit = !this.showEdit;
    },
    changeStatus(item) {
      this.startLoading();
      this.$services.requirement.updateRequirement(
        {
          id: item.id,
          is_invited: item.is_invited,
          detail_information: item.detail_information,
          ciq_id: this.requirement.ciq_id,
          updated_at: item.updated_at,
        },
        () => {
          this.getRequirementDetail();
        },
        err => {
          this.endLoading();
          this.notifyError(this.$t(err.error), true);
        },
      );
    },
    colorToHex(color) {
      var hexadecimal = parseInt(color).toString(16);
      return hexadecimal.length == 1 ? '0' + hexadecimal : hexadecimal;
    },
    async handleFocusOut(item, index, isShowConfirmNotifyThen = false) {
      if (
          this.listDetail[index] === this.listDetailOfficial[index] &&
          this.requirement.guids[index].departure === this.requirementOfficial.guids[index].departure
      ) {
        this.currentRequirementIndex = -1;
        return;
      }
      var rgbColor = this.listDetail[index].match(/rgb\((\d+), (\d+), (\d+)\)/g);
      if (rgbColor) {
        rgbColor.forEach(el => {
          var matchColors = /rgb\((\d+), (\d+), (\d+)\)/;
          var match = matchColors.exec(el);
          if (match !== null) {
            this.listDetail[index] = this.listDetail[index].replace(
              el,
              '#' + this.colorToHex(match[1]) + this.colorToHex(match[2]) + this.colorToHex(match[3]),
            );
          }
        });
      }
      this.showEdit = !this.showEdit;
      await this.$services.requirement.updateRequirement(
        {
          id_req: this.requirementId,
          id: item.id,
          is_invited: item.is_invited,
          detail_information: this.listDetail[index],
          departure: item.departure,
          ciq_id: this.requirement.ciq_id,
          updated_at: item.updated_at,
        },
        () => {
          this.getRequirementDetail(true, isShowConfirmNotifyThen);
        },
        err => {
          this.notifyError(this.$t(err.error), true);
        },
      );
    },
    async handleSendNotifyAction() {
      if (this.currentRequirementIndex != -1) {
        this.handleFocusOut(this.requirement.guids[this.currentRequirementIndex], this.currentRequirementIndex, true);
        return;
      }
      this.showModalConfirmSend = true;
    },
    handleDeleteRequirement(id) {
      this.guid_id = id;
      this.showModalConfirm = true;
    },
    redirectTrip() {
      let routeData = this.$router.resolve({
        name: this.PAGES.TRIP_UPDATE,
        params: {
          id: this.requirement.trip.id,
        },
      });
      window.open(routeData.href, '_blank');
    },
    redirectCustomer() {
      let routeData = this.$router.resolve({
        name: this.PAGES.CUSTOMER_ENTERPRISE_DETAIL,
        params: {
          id: this.requirement.trip_creator.company.id,
        },
        query: {
          user_id: this.requirement.trip_creator.id,
          from: 'requirement',
        },
      });
      window.open(routeData.href, '_blank');
    },
    btnAddCountry() {
      this.isAddRequirement = !this.isAddRequirement;
      this.isFocus = true;
    },
    getRequirementDetail(showLoading = true, isShowConfirmNotifyThen = false) {
      if (!this.requirementId || this.requirementId == 0) return;
      this.listDetail = [];
      if (showLoading) this.startLoading();
      this.$services.requirement.getRequirementDetail(
        this.requirementId,
        res => {
          if (showLoading) this.endLoading();
          this.listDetail = [];
          this.listDetailOfficial = [];
          this.requirement = res;
          this.listCountryInDetail = [];
          this.requirement.guids.forEach(item => {
            this.listCountryInDetail.push(item.country_id);
            if (item.is_ciq_update == 0) {
              this.listDetail.push(item.detail_information.split(',').reverse()[0]);
            } else {
              this.listDetail.push(item.attachments[0].requirements);
            }
          });
          const result = this.cloneObject(res);
          this.requirementOfficial = result;
          this.requirementOfficial.guids.forEach(item => {
            if (item.is_ciq_update == 0) {
              this.listDetailOfficial.push(item.detail_information.split(',').reverse()[0]);
            } else {
              this.listDetailOfficial.push(item.attachments[0].requirements);
            }
          });
          if (isShowConfirmNotifyThen) this.showModalConfirmSend = true;
        },
        err => {
          // this.endLoading();
          this.notifyError(this.$t(err.error));
          this.$router.push(this.PAGES.REQUIREMENT);
        },
      );
    },
    getListCountry() {
      this.$services.requirement.getListCountry(
        res => {
          this.listCountries = this.sortCountryByName(res.list);
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    deleteRequirement() {
      this.startLoading();
      this.$services.requirement.deleteRequirement(
        this.guid_id,
        () => {
          this.isSubmit = true;
          this.endLoading();
          this.showModalConfirm = false;
          this.notifySuccess(this.$t('I-CM-102'));
        },
        err => {
          this.endLoading();
          this.showModalConfirm = false;
          this.notifyError(this.$t(err.error));
        },
      );
      this.getRequirementDetail();
    },
    sendNotifyRequirement() {
      this.isSubmit = true;
      let guids = [];
      this.requirement.guids.forEach(element => {
        if (element.is_invited == 1) {
          guids.push(element.id);
        }
      });
      this.startLoading();
      this.$services.requirement.sendNotifyRequirement(
        {
          trip_id: this.requirement.trip_id,
          customer_id: this.requirement?.trip_creator?.id,
          guids,
          required_procedure_id: this.$route.params.id,
        },
        () => {
          this.endLoading();
          this.showModalConfirmSend = false;
          this.notifySuccess(this.$t('requirement.send_notify_success'));
          this.getRequirementDetail();
        },
        err => {
          this.endLoading();
          this.notifyError(this.$t(err.error));
        },
      );
    },
    listenSelectCountry(select) {
      this.country = select;
      if (select) {
        this.isSubmit = true;
        this.$services.requirement.createRequirement(
          { country_id: select, required_procedure_id: this.requirementId },
          res => {
            let { id } = res;
            this.listCountryNew.push(id);
            this.getRequirementDetail();
            this.notifySuccess(this.$t('I-CM-103'));
          },
          err => {
            this.endLoading();
            this.notifyError(this.$t(err.error));
          },
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.status-text {
  color: #65c15e;
}
</style>
