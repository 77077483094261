<template>
  <nav class="navbar navbar-default navbar-lg" role="navigation">
    <div class="navbar-header">
      <h2 class="navbar-title">{{ $t('input.input') }}</h2>
    </div>
  </nav>
  <div class="wrapper">
    <div class="container-fluid">
      <div class="filter-wrap">
        <div class="filter" v-show="showBoxFilter">
          <div class="section section-filter noborder">
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <div class="form-group">
                  <label>
                    <span class="label-text">{{ $t('input.journey') }}</span>
                  </label>
                  <input
                    v-model="searchParams.trip_name"
                    maxlength="50"
                    type="text"
                    :placeholder="$t('input.pl_journey')"
                    class="form-control input-sm"
                  />
                  <p class="error-feedback" v-text="errors.trip_name"></p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <div class="form-group">
                  <label>
                    <span class="label-text">{{ $t('input.company_name') }}</span>
                  </label>
                  <input
                    v-model="searchParams.company_keyword"
                    maxlength="50"
                    type="text"
                    :placeholder="$t('input.pl_company_name')"
                    class="form-control input-sm"
                  />
                  <p class="error-feedback" v-text="errors.company_keyword"></p>
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="form-group">
                  <label>
                    <span class="label-text">{{ $t('input.employee_number') }}</span>
                  </label>
                  <input
                    v-model="searchParams.employee_keyword"
                    maxlength="50"
                    type="text"
                    :placeholder="$t('input.pl_employee_number')"
                    class="form-control input-sm"
                  />
                  <p class="error-feedback" v-text="errors.employee_keyword"></p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <div class="form-group">
                  <label>
                    <span class="label-text">{{ $t('input.country') }}</span>
                  </label>
                  <div class="btn-group bootstrap-select show-tick select-block select-sm">
                    <MultiOptionSelect
                      ref="ListCountrySelect"
                      :list-data="listCountries"
                      :display-by-locale="true"
                      :is-display-search="true"
                      :isListCountry="true"
                      :default-list="searchParams.country_ids"
                      @handle-selected-params="listenCountry"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <DatePickerWithRange
                :parent-start-date="searchParams.start_date_from"
                :parent-end-date="searchParams.start_date_to"
                :date-picker-title="$t('input.departure_date')"
                @listenStartDateChange="listenStartDateFrom"
                @listenEndDateChange="listenStartDateTo"
              />
              <DatePickerWithRange
                :parent-start-date="searchParams.return_date_from"
                :parent-end-date="searchParams.return_date_to"
                :date-picker-title="$t('input.return_date')"
                @listenStartDateChange="listenReturnDateFrom"
                @listenEndDateChange="listenReturnDateTo"
              />
            </div>

            <div class="row">
              <DatePickerWithRange
                :parent-start-date="searchParams.upload_date_from"
                :parent-end-date="searchParams.upload_date_to"
                :date-picker-title="$t('input.upload_date')"
                @listenStartDateChange="listenUploadDateFrom"
                @listenEndDateChange="listenUploadDateTo"
              />
              <DatePickerWithRange
                :parent-start-date="searchParams.completion_date_from"
                :parent-end-date="searchParams.completion_date_to"
                :date-picker-title="$t('input.completion_date')"
                @listenStartDateChange="listenCompletionDateFrom"
                @listenEndDateChange="listenCompletionDateTo"
              />
            </div>
            <div class="row">
              <div class="col-xs-12 col-sm-6 col-md-6">
                <div class="form-group">
                  <label>{{ $t('input.kinds') }}</label>
                  <label class="checkbox checkbox-inline checkbox-type-div" :class="searchParams.is_cost && ' checked'">
                    <span class="icons">
                      <span class="first-icon icon-abui-checkbox-unchecked"></span>
                      <span class="second-icon icon-abui-checkbox-checked"></span>
                    </span>
                    <input type="checkbox" v-model="searchParams.is_cost" />
                    <span class="text_type" style="margin-left: 30px; margin-right: 30px">
                      {{ $t('input.reservation_slip') }}
                    </span>
                  </label>
                  <label
                      class="checkbox checkbox-inline checkbox-type-div"
                      :class="searchParams.is_reservation_slip && ' checked'"
                  >
                    <span class="icons">
                      <span class="first-icon icon-abui-checkbox-unchecked"></span>
                      <span class="second-icon icon-abui-checkbox-checked"></span>
                    </span>
                    <input type="checkbox" v-model="searchParams.is_reservation_slip" />
                    <span class="text_type" style="margin-left: 30px; margin-right: 30px">
                      {{ $t('input.cost') }}
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <div class="form-group">
                  <label>
                    <span class="label-text">{{ $t('input.status_label') }}</span>
                  </label>
                  <div class="btn-group bootstrap-select show-tick select-block select-sm">
                    <MultiOptionSelectStatus
                      ref="ListStatusSelect"
                      :list-data="listStatus"
                      :displayByLocale="true"
                      :is-display-search="false"
                      :isSort="false"
                      :parent-selected="statusSearched"
                      :default-list="defaultStatus"
                      @handle-selected-params="listenStatus"
                    />
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-sm-6">
                <div class="form-group">
                  <label>{{ $t('input.payment_method') }}</label>
                  <label
                    class="checkbox checkbox-inline checkbox-type-div"
                    :class="searchParams.reimbursement && ' checked'"
                  >
                    <span class="icons">
                      <span class="first-icon icon-abui-checkbox-unchecked"></span>
                      <span class="second-icon icon-abui-checkbox-checked"></span>
                    </span>
                    <input type="checkbox" v-model="searchParams.reimbursement" />
                    <span class="text_type" style="margin-left: 30px; margin-right: 30px">
                      {{ $t('input.reimbursement') }}
                    </span>
                  </label>
                  <label
                    class="checkbox checkbox-inline checkbox-type-div"
                    :class="searchParams.corporate_card && ' checked'"
                  >
                    <span class="icons">
                      <span class="first-icon icon-abui-checkbox-unchecked"></span>
                      <span class="second-icon icon-abui-checkbox-checked"></span>
                    </span>
                    <input type="checkbox" v-model="searchParams.corporate_card" />
                    <span class="text_type" style="margin-left: 30px; margin-right: 30px">
                      {{ $t('input.corporate_card') }}
                    </span>
                  </label>
                  <label class="checkbox checkbox-inline checkbox-type-div" :class="searchParams.request && ' checked'">
                    <span class="icons">
                      <span class="first-icon icon-abui-checkbox-unchecked"></span>
                      <span class="second-icon icon-abui-checkbox-checked"></span>
                    </span>
                    <input type="checkbox" v-model="searchParams.request" />
                    <span class="text_type" style="margin-left: 30px; margin-right: 30px">
                      {{ $t('input.request') }}
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6 col-md-4">
                <div class="form-group">
                  <label
                    class="checkbox checkbox-inline checkbox-type-div"
                    :class="searchParams.with_deleted && ' checked'"
                  >
                    <span class="icons">
                      <span class="first-icon icon-abui-checkbox-unchecked"></span>
                      <span class="second-icon icon-abui-checkbox-checked"></span>
                    </span>
                    <input type="checkbox" v-model="searchParams.with_deleted" />
                    <span class="text_type" style="margin-left: 30px; margin-right: 30px">
                      {{ $t('input.with_deleted') }}
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /.filter -->
        <div class="section-submit" v-show="showBoxFilter">
          <span class="submit-arrow"></span>
          <div class="row">
            <div class="col-sm-4 col-md-5 hidden-xs">
              <button class="btn btn-sm btn-flat-default btn-clear" @click="clearFilter()">
                {{ $t('common.clear') }}
              </button>
            </div>
            <div class="col-sm-4 col-md-2">
              <button class="btn btn-sm btn-primary btn-block" @click="handleSearch()">
                {{ $t('common.search') }}
              </button>
            </div>
            <div class="col-sm-4 col-md-5 hidden-xs">
              <button class="btn btn-sm btn-flat-default btn-close" @click="showBoxFilter = false">
                {{ $t('common.close') }}
              </button>
            </div>
          </div>
        </div>
        <!-- /.section-submit -->
        <div
          class="btn-filter-again with-condition"
          v-show="!showBoxFilter"
          @click="showBoxFilter = true"
          style="display: block"
        >
          <div class="filter--condition">
            <span>{{ $t('common.all') }}</span>
            <span style="color: #3650a5">{{ $t('common.reset_conditions') }}</span>
          </div>
        </div>

        <div class="filter-result">
          <div class="section noborder">
            <Pagination
              v-if="listInputs.length > 0"
              @change-page="changePageData"
              :paginate="paginate"
              @change-number="changeNumber"
            />

            <div class="hidden-xs">
              <div class="table-responsive">
                <table class="table table-hover table-hover--pointer table--org-list" id="table--plan">
                  <colgroup span="1" style="width: 12.5%"></colgroup>
                  <colgroup span="1" style=""></colgroup>
                  <thead>
                    <tr>
                      <th>{{ $t('input.updated_date') }}</th>
                      <th></th>
                      <th>{{ $t('input.kinds') }}</th>
                      <th>{{ $t('input.company_name') }}</th>
                      <th>{{ $t('input.employee_number') }}</th>
                      <th>{{ $t('input.journey') }}</th>
                      <th>{{ $t('input.status_label') }}</th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in listInputs"
                      :key="index"
                      :class="classCustomStatus(item.status)"
                      @click="goToDetail(item)"
                    >
                      <td style="min-width: 8vw">{{ showDateTime(item.created_at, 'YYYY/MM/DD HH:mm') }}</td>
                      <td>{{ item.name }}</td>
                      <td style="min-width: 5vw">{{ getInputTypeLabel(item.type) }}</td>
                      <td>{{ item.company_name }}</td>
                      <td style="min-width: 4vw">{{ item.employee_number }}</td>
                      <td style="min-width: 10vw" class="totrip">{{ item.trip_code }}：{{ item.trip_name }}</td>
                      <td style="min-width: 7vw">
                        <span v-if="item.status === '1'" class="text-warning">
                          {{ getInputStatusLabel(item.status) }}
                        </span>
                        <span v-if="item.status === '2'" class="text-primary">
                          {{ getInputStatusLabel(item.status) }}
                        </span>
                        <span v-if="item.status === '3'">
                          {{ getInputStatusLabel(item.status) }}
                        </span>
                        <span v-if="item.status === '4'">
                          {{ getInputStatusLabel(item.status) }}
                        </span>
                      </td>
                      <td>{{ item.user_action_name }}</td>
                      <td style="min-width: 8vw">{{ showDateTime(item.updated_at, 'YYYY/MM/DD HH:mm') }}</td>
                      <td class="totrip">
                        <a
                          class="btn btn-sm btn-flat-default"
                          v-on:click.stop
                          target="_blank"
                          @click="goToTripItem(item.trip_id)"
                        >
                          <i class="icon-abui-globe" />
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p v-if="listInputs.length == 0" class="no-data">{{ $t('common.no_data') }}</p>
              </div>
            </div>
            <Pagination
              v-if="listInputs.length > 0"
              @change-page="changePageData"
              :paginate="paginate"
              @change-number="changeNumber"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { DEFAULT_STATUS } from '@/constants/';
import Pagination from '@/components/common/Pagination';
import DatePickerWithRange from '@/components/application/DatePickerWithRange';
import MultiOptionSelect from '@/components/common/MultiOptionSelect';
import MultiOptionSelectStatus from '@/components/input/MultiOptionSelectStatus';

export default {
  name: 'Input',
  components: { Pagination, MultiOptionSelect, DatePickerWithRange, MultiOptionSelectStatus },
  data() {
    return {
      listInputs: [],
      listStatus: [],
      listCountries: [],
      searchParams: {
        trip_name: this.$route.query?.trip_name || '',
        company_keyword: this.$route.query?.company_keyword || '',
        employee_keyword: this.$route.query?.employee_keyword || '',
        country_ids: this.$route.query.country_ids ? JSON.parse(this.$route.query.country_ids) : [],
        status: this.$route.query.status ? JSON.parse(this.$route.query.status) : DEFAULT_STATUS,
        start_date_from: this.$route.query?.start_date_from || '',
        start_date_to: this.$route.query?.start_date_to || '',
        return_date_from: this.$route.query?.return_date_from || '',
        return_date_to: this.$route.query?.return_date_to || '',
        upload_date_from: this.$route.query?.upload_date_from || '',
        upload_date_to: this.$route.query?.upload_date_to || '',
        completion_date_from: this.$route.query?.completion_date_from || '',
        completion_date_to: this.$route.query?.completion_date_to || '',
        is_reservation_slip: this.$route.query?.is_reservation_slip
          ? this.$route.query.is_reservation_slip == '1'
          : true,
        is_cost: this.$route.query?.is_cost ? this.$route.query.is_cost == '1' : true,
        reimbursement: this.$route.query?.reimbursement ? this.$route.query.reimbursement == '1' : true,
        corporate_card: this.$route.query?.corporate_card ? this.$route.query.corporate_card == '1' : true,
        request: this.$route.query?.request ? this.$route.query.request == '1' : true,
        with_deleted: this.$route.query?.with_deleted ? this.$route.query.with_deleted == '1' : true,
      },
      isSearchActivated: this.$route.query.is_search_activated || 0,
      showBoxFilter: false,
      paginate: {
        page: +this.$route.query.page || 1,
        per_page: +this.$route.query.per_page || 10,
      },
      errors: {
        trip_name: '',
        company_keyword: '',
        employee_keyword: '',
      },
    };
  },
  watch: {
    searchParams: {
      handler: function (newValue) {
        if (this.strLen(newValue.trip_name) > 50) {
          this.errors.trip_name = this.$t('E-CM-111', { size: 50 });
        } else {
          delete this.errors.trip_name;
        }
        if (this.strLen(newValue.company_keyword) > 50) {
          this.errors.company_keyword = this.$t('E-CM-111', { size: 50 });
        } else {
          delete this.errors.company_keyword;
        }
        if (this.strLen(newValue.employee_keyword) > 50) {
          this.errors.employee_keyword = this.$t('E-CM-111', { size: 50 });
        } else {
          delete this.errors.employee_keyword;
        }
      },
      deep: true,
    },
  },
  computed: {
    statusSearched() {
      let list = [];
      if (this.$route.query.status) {
        const statusSearchList = JSON.parse(this.$route.query.status);
        if (statusSearchList.length > 0) statusSearchList.forEach(item => list.push(item));
      }
      return list;
    },
    defaultStatus() {
      return DEFAULT_STATUS;
    },
  },
  created() {
    this.startLoading();
    Promise.all([this.getListInput(), this.getListInputStatus(), this.getListCountry()]).then(() => {
      this.endLoading();
    });
  },
  methods: {
    goToTripItem(id) {
      let routeData = this.$router.resolve({
        name: this.PAGES.TRIP_UPDATE,
        params: {
          id: id,
        },
      });
      window.open(routeData.href, '_blank');
    },
    goToDetail(item) {
      if (!this.checkEmpty(item.deleted_at)) {
        this.$notify({
          title: '警告',
          message: 'このレコードは削除されました。',
          type: 'warning',
        });
        return;
      }

      this.$router.push({
        name: this.PAGES.INPUT_DETAIL,
        params: {
          id: item.id,
        },
        query: {
          page: this.paginate.current_page,
          per_page: this.paginate.per_page,
          trip_name: this.searchParams.trip_name,
          company_keyword: this.searchParams.company_keyword,
          employee_keyword: this.searchParams.employee_keyword,
          is_reservation_slip: this.searchParams.is_reservation_slip ? '1' : '0',
          is_cost: this.searchParams.is_cost ? '1' : '0',
          reimbursement: this.searchParams.reimbursement ? '1' : '0',
          corporate_card: this.searchParams.corporate_card ? '1' : '0',
          request: this.searchParams.request ? '1' : '0',
          with_deleted: this.searchParams.with_deleted ? '1' : '0',
          country_ids: !this.checkEmpty(this.searchParams.country_ids)
            ? JSON.stringify(this.searchParams.country_ids)
            : [],
          status: !this.checkEmpty(this.searchParams.status) ? JSON.stringify(this.searchParams.status) : [],
          is_search_activated: this.isSearchActivated ? 1 : 0,
          start_date_from: this.searchParams.start_date_from,
          start_date_to: this.searchParams.start_date_to,
          return_date_from: this.searchParams.return_date_from,
          return_date_to: this.searchParams.return_date_to,
          upload_date_from: this.searchParams.upload_date_from,
          upload_date_to: this.searchParams.upload_date_to,
          completion_date_from: this.searchParams.completion_date_from,
          completion_date_to: this.searchParams.completion_date_to,
        },
      });
    },
    async getListCountry() {
      await this.$services.input.getListCountry(
        res => {
          this.listCountries = this.sortCountryByName(res.list);
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    async getListInputStatus() {
      await this.$services.input.getListInputStatus(
        res => {
          this.listStatus = res.list;
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    changePageData(page) {
      this.paginate.page = +page;
      this.getListInput();
    },
    async getListInput() {
      const { dataFilter, queryBuild } = this.buildParamsSearch();

      await this.$services.input.getListInput(
        dataFilter,
        queryBuild,
        res => {
          this.listInputs = res.list;
          this.paginate = { ...this.paginate, ...res.paginate };
          this.showBoxFilter = false;
          //Keep data search
          this.updateRouter(res);
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    updateRouter(res) {
      let query = {
        page: res.paginate.current_page,
        per_page: res.paginate.per_page,
        trip_name: this.searchParams.trip_name,
        company_keyword: this.searchParams.company_keyword,
        employee_keyword: this.searchParams.employee_keyword,
        is_reservation_slip: this.searchParams.is_reservation_slip ? '1' : '0',
        is_cost: this.searchParams.is_cost ? '1' : '0',
        reimbursement: this.searchParams.reimbursement ? '1' : '0',
        corporate_card: this.searchParams.corporate_card ? '1' : '0',
        request: this.searchParams.request ? '1' : '0',
        with_deleted: this.searchParams.with_deleted ? '1' : '0',
        country_ids: !this.checkEmpty(this.searchParams.country_ids)
          ? JSON.stringify(this.searchParams.country_ids)
          : [],
        status: !this.checkEmpty(this.searchParams.status) ? JSON.stringify(this.searchParams.status) : [],
        is_search_activated: this.isSearchActivated ? 1 : 0,
        start_date_from: this.searchParams.start_date_from,
        start_date_to: this.searchParams.start_date_to,
        return_date_from: this.searchParams.return_date_from,
        return_date_to: this.searchParams.return_date_to,
        upload_date_from: this.searchParams.upload_date_from,
        upload_date_to: this.searchParams.upload_date_to,
        completion_date_from: this.searchParams.completion_date_from,
        completion_date_to: this.searchParams.completion_date_to,
      };
      for (let key in query) {
        if (this.checkEmpty(query[key])) delete query[key];
      }
      this.$router.push({
        path: this.$route.path,
        query: this.isSearchActivated
          ? query
          : {
              page: res.paginate.current_page,
              per_page: res.paginate.per_page,
            },
      });
    },
    changeNumber(number) {
      this.paginate = {
        ...this.paginate,
        page: 1,
        per_page: number,
      };
      this.getListInput();
    },
    clearFilter() {
      this.searchParams = {
        trip_name: '',
        company_keyword: '',
        employee_keyword: '',
        country_ids: [],
        status: DEFAULT_STATUS,
        start_date_from: '',
        start_date_to: '',
        return_date_from: '',
        return_date_to: '',
        upload_date_from: '',
        upload_date_to: '',
        completion_date_from: '',
        completion_date_to: '',
        is_reservation_slip: true,
        is_cost: true,
        reimbursement: true,
        corporate_card: true,
        request: true,
        with_deleted: true,
      };
      this.$refs.ListCountrySelect.clearFilter();
      this.$refs.ListStatusSelect.clearFilter();
    },
    handleSearch() {
      if (!this.checkEmpty(this.errors)) return;

      this.paginate.page = 1;
      this.isSearchActivated = 1;
      this.getListInput();
    },
    listenCountry(selectedList) {
      this.searchParams.country_ids = selectedList;
    },
    listenStatus(selectedList) {
      this.searchParams.status = selectedList;
    },
    listenStartDateFrom(date) {
      this.searchParams.start_date_from = date;
    },
    listenStartDateTo(date) {
      this.searchParams.start_date_to = date;
    },
    listenReturnDateFrom(date) {
      this.searchParams.return_date_from = date;
    },
    listenReturnDateTo(date) {
      this.searchParams.return_date_to = date;
    },
    listenUploadDateFrom(date) {
      this.searchParams.upload_date_from = date;
    },
    listenUploadDateTo(date) {
      this.searchParams.upload_date_to = date;
    },
    listenCompletionDateFrom(date) {
      this.searchParams.completion_date_from = date;
    },
    listenCompletionDateTo(date) {
      this.searchParams.completion_date_to = date;
    },
    classCustomStatus(status) {
      let classStt = '';
      if (status === '3') {
        classStt = 'voucher inprogress';
      }
      if (status === '4') {
        classStt = 'done muted';
      }

      return classStt;
    },
    buildParamsSearch() {
      const dataFilter = {
        trip_name: this.searchParams.trip_name,
        company_keyword: this.searchParams.company_keyword,
        employee_keyword: this.searchParams.employee_keyword,
        start_date_from: this.searchParams.start_date_from,
        start_date_to: this.searchParams.start_date_to,
        return_date_from: this.searchParams.return_date_from,
        return_date_to: this.searchParams.return_date_to,
        upload_date_from: this.searchParams.upload_date_from,
        upload_date_to: this.searchParams.upload_date_to,
        completion_date_from: this.searchParams.completion_date_from,
        completion_date_to: this.searchParams.completion_date_to,
        with_deleted: this.searchParams.with_deleted,
        page: this.paginate.page,
        per_page: this.paginate.per_page,
      };

      if (this.$i18n.locale != 'ja') {
        dataFilter.locale = this.$i18n.locale;
      }
      let queryBuild = '';
      if (!this.checkEmpty(this.searchParams.country_ids)) {
        this.searchParams.country_ids.forEach(item => {
          queryBuild += 'country_ids[]=' + item + '&';
        });
      }
      if (!this.checkEmpty(this.searchParams.status)) {
        this.searchParams.status.forEach(item => {
          queryBuild += 'status[]=' + item + '&';
        });
      }

      if (this.searchParams.is_reservation_slip) queryBuild += 'type[]=1&';
      if (this.searchParams.is_cost) queryBuild += 'type[]=2&';

      if (this.searchParams.reimbursement) queryBuild += 'payment_method[]=1&';
      if (this.searchParams.corporate_card) queryBuild += 'payment_method[]=2&';
      if (this.searchParams.request) queryBuild += 'payment_method[]=3&';

      if (queryBuild) queryBuild = queryBuild.slice(0, -1);

      return { dataFilter, queryBuild };
    },
  },
};
</script>
