import BaseService from './BaseService';
import API_CODE from '../utils/api_code.js';

export default class OrganizationAPI extends BaseService {
  async getListOrganization(params, success, error) {
    await this.get(API_CODE.CU_157 + this.urlParse(params), success, error);
  }
  async downloadOrganizationCSV(params, success, error) {
    await this.get(API_CODE.CU_158 + this.urlParse(params), success, error);
  }
  async downloadOrganizationExampleCSV(success, error) {
    await this.get(API_CODE.CU_159, success, error);
  }
  async uploadFileCSV(params, success, error) {
    await this.post(API_CODE.CU_160, params, success, error);
  }
  async createOrganization(params, success, error) {
    await this.post(API_CODE.CU_171, params, success, error);
  }
  async detailOrganization(id, success, error) {
    await this.get(API_CODE.CU_173 + id, success, error);
  }
  async updateOrganization(params, success, error) {
    await this.post(API_CODE.CU_172 + params.id, params.organizations, success, error);
  }
  async deleteOrganization(id, success, error) {
    await this.delete(API_CODE.CU_174 + id, {}, success, error);
  }
  async getListParentOrganizations(params, success, error) {
    await this.get(API_CODE.CU_169 + this.urlParse(params), success, error);
  }
  async getBurdenOrganizations(params, success, error) {
    await this.get(API_CODE.CU_170 + this.urlParse(params), success, error);
  }
}
