<template>
  <el-dialog
      v-model="dialogVisibleStatus"
      :show-close="false"
      @close="$emit('cancel')"
      width="704px"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <button type="button" class="close" @click="$emit('cancel')">
          <span class="icon-abui-cross" aria-hidden="true" />
        </button>
        <div class="modal-header" style="display: flex; justify-content: flex-start">
          <div class="modal-header--title" style="width: 100%">
            {{ $t('trip_item.right_menu.settlement_of_expenses') }}
          </div>
        </div>
        <div class="modal-body">
          <p class="modal-dialog-text" style="margin-bottom: 24px">
            {{ $t('trip_item.right_menu.modal_settlement_expense.note') }}
          </p>
          <hr style="margin-bottom: 24px" />
          <div class="apply-expense--head">
            <div class="apply-expense--head--left" style="width: 75%">
              <div class="apply-expense--trip-title">
                {{ costInformation.title }}
              </div>
              <div class="apply-expense--trip-period">
                <i class="icon-abui-calendar prx" />
                {{ showDateTime(costInformation.start_date, 'YYYY/MM/DD（ddd）') }} -
                {{ showDateTime(costInformation.end_date, 'YYYY/MM/DD（ddd）') }}
              </div>
              <div class="apply-expense--trip-destination">
                <i class="icon-abui-location prx" />{{ costInformation.location_string }}
              </div>
            </div>
            <div class="apply-expense--head--right">
              <div class="apply-expense--total-sum">
                {{ splitThousandYen(totalAmount) }}
              </div>
            </div>
          </div>

          <div class="apply-approval-flow">
            <div class="form-group">
              <label>
                <span class="label-text">
                  {{ $t('trip_item.right_menu.modal_settlement_expense.approval_flow') }}
                </span>
              </label>
              <div class="btn-group bootstrap-select select-block select-sm js-select_item">
                <SingleOptionSelect
                    ref="RequestOrganizationSelect"
                    :default-list="default_approval_flow_id ? default_approval_flow_id : listApprovalFlowType[0]?.id"
                    :showClearable="false"
                    :list-data="listApprovalFlowType"
                    @handle-selected-params="listenApprovalFlows"
                />
                <p role="alert" class="error-feedback" style="margin-top: 4px" v-text="errors.approval_flow_id" />
              </div>
            </div>
            <div class="js-select_item-select-approval-flow__tgt select-approval-flow_1">
              <p class="note mbs" style="margin-bottom: 20px !important">
                {{ $t('business_report.approval_flow_note') }}
              </p>
              <div v-if="!checkEmpty(approvalFlowDetail)" class="step-list step-list--ringi-apply">
                <div
                    v-for="(detail, stepIndex) in approvalFlowDetail"
                    :key="stepIndex"
                    class="step-item"
                    :class="appendToStepClass(stepIndex, detail.user_select_option)"
                >
                  <i class="step-mark"></i>
                  <span>{{ displayStepTitle(stepIndex) }}</span>
                  <span class="ringi-step--type">{{ textToStepType(detail.step_type) }}</span>
                  <ul v-if="!checkEmpty(detail.step_detail)" class="list--approver">
                    <li v-for="(stepDetail, id) in detail.step_detail" :key="id">{{ stepDetail.user_name }}</li>
                  </ul>
                  <div v-if="detail.user_select_option == 1" class="custom-select-approver">
                    <div class="row">
                      <div class="col-sm-8">
                        <div class="form-group viewonly text-left">
                          <p class="viewonly-text input-sm input-approver break-down-span">
                            {{ inputApprover[stepIndex]?.display_text }}
                          </p>
                          <p
                              role="alert"
                              class="error-feedback"
                              style="margin-top: 4px"
                              v-text="detail.error_message"
                          />
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <a
                            @click="openAddApprover(stepIndex, detail.step_id)"
                            class="btn btn-sm btn-block btn-inverse-primary"
                        >
                          {{ $t('business_report.reference') }}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr class="mbl" />

          <div ref="bearing_organizations">
            <label>
              <span class="label-text">{{ $t('business_report.cost_bearing') }}</span>
            </label>
            <div
                v-for="(bearingDetail, index) in approvedBearing"
                :key="index"
                class="apply-expense--dept--item custom-apply-expense"
            >
              <div style="width: 50%" class="prs organizarion_select">
                <div class="form-group">
                  <SingleOptionSelect
                      :list-data="listOrganizations"
                      :default-list="bearingDetail.organization_id"
                      :option-index="{ haveIndex: true, index }"
                      :is-display-search="false"
                      :using-list-items="listOrganizationUsing"
                      @handle-selected-params="handleBearingOrganization"
                  />
                </div>
                <div>
                  <p role="alert" class="error-feedback" v-text="errors.selected_bearing.organization[index]" />
                </div>
              </div>
              <div style="width: 25%" class="phs">
                <div class="form-group">
                  <div class="input-group percentage">
                    <input
                        v-model="approvedBearing[index].price_percentage"
                        @input="
                        approvedBearing[index].price_percentage = convertNumberFullToHalf(
                          approvedBearing[index].price_percentage,
                        )
                      "
                        type="text"
                        maxlength="5"
                        @keypress="isNumber($event)"
                        class="form-control input-sm"
                        :ref="'price_percentage'"
                    />
                    <span class="input-group-addon">%</span>
                  </div>
                </div>
                <div>
                  <p role="alert" class="error-feedback" v-text="errors.selected_bearing.price_percentage[index]" />
                </div>
              </div>
              <div class="phs" style="margin-left: auto">
                <div class="form-group">
                  <div class="apply-expense--dept">
                    {{ splitThousandYen(approvedBearing[index].amount) }}
                  </div>
                </div>
              </div>
              <div style="width: 38px" class="mls">
                <div class="form-group">
                  <button @click="removeCostBearing(bearingDetail)" class="btn btn-sm btn-flat-danger">
                    <i class="icon-abui-cross"></i>
                  </button>
                </div>
              </div>
            </div>
            <div>
              <span role="alert" class="error-feedback" v-text="errors.total_price_percentage" />
            </div>
            <div>
              <span role="alert" class="error-feedback" v-text="errors.have_duplicated_fields" />
            </div>
            <button
                @click="addCostBearing()"
                class="btn btn-flat-primary btn-sm js-add-expense-dept"
                :class="!displayAddCostBearing && 'disabled'"
            >
              <i class="icon-abui-plus prx"></i>
              {{ $t('business_report.add_organization') }}
            </button>
          </div>

          <hr class="mbl" />

          <div class="mbl">
            <div v-if="costInformation?.costs?.length > 0">
              <div class="apply-expense--section--wrap" v-for="(cost, i) in costInformation.costs" :key="i">
                <div class="apply-expense--section-head">
                  <h6>{{ cost.title }}</h6>
                  <div class="apply-expense--section-sum">
                    {{ splitThousandYen(cost.amount) }}
                  </div>
                </div>

                <div class="apply-expense--wrap">
                  <div v-for="(item, j) in cost.cost_items" :key="j">
                    <div
                        class="apply-expense--item"
                        :class="
                        checkEmpty(item.image_url) && isUploadInvoice && item.type_cost != 3
                          ? 'no-evidence'
                          : !costItemSelected.includes(item.id) && 'exclude'
                      "
                        @click="onChangeSelectItem(item, i)"
                    >
                      <span class="apply-expense--icon"><i class="icon-abui-check" /></span>
                      <span class="apply-expense--title">{{ item.title }}</span>
                      <span class="apply-expense--date">{{ item.payment_date }}</span>
                      <span class="apply-expense--payto">{{ item.payment_destination }}</span>
                      <span class="apply-expense--paymenttype">{{ textToPaymentMethod(item.payment_method) }}</span>
                      <span class="apply-expense--price">
                        {{ splitThousandYen(item.amount) }}
                      </span>
                      <div
                          v-if="checkEmpty(item.image_url) && isUploadInvoice && item.type_cost != 3"
                          class="no-evidence--msg"
                      >
                        {{ $t('trip_item.right_menu.modal_settlement_expense.error_no_has_file') }}
                      </div>
                    </div>
                  </div>
                  <p v-if="checkEmpty(cost.cost_items)" class="no-data">{{ $t('common.no_data') }}</p>
                </div>
              </div>
            </div>
            <p v-else class="no-data">
              {{ $t('common.no_data') }}
            </p>
            <hr />
          </div>

          <!--          <div class="mbl">-->
          <!--            <div v-if="costInformation?.wifi_router_rental?.length > 0">-->
          <!--              <div class="apply-expense&#45;&#45;section&#45;&#45;wrap">-->
          <!--                <div class="apply-expense&#45;&#45;section-head">-->
          <!--                  <h6>{{ $t('settings.temporary_expenses') }}</h6>-->
          <!--                  <div class="apply-expense&#45;&#45;section-sum">-->
          <!--                    {{ splitThousandYen(totalWifiRouterRental) }}-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--                <div class="apply-expense&#45;&#45;wrap">-->
          <!--                  <div v-for="(item, j) in costInformation.wifi_router_rental" :key="j">-->
          <!--                    <div-->
          <!--                      class="apply-expense&#45;&#45;item"-->
          <!--                      @click="selectWifiRouterRental(item)"-->
          <!--                      :class="!isInSelectedWifiRouterRental(item) && 'exclude'"-->
          <!--                    >-->
          <!--                      <span class="apply-expense&#45;&#45;icon"><i class="icon-abui-check" /></span>-->
          <!--                      <span class="btn btn-used-in-request" style="background-color: #dcb823; color: #fff">-->
          <!--                           {{ $t('settings.temporary_expenses_label')}}-->
          <!--                        </span>-->
          <!--                      <span class="apply-expense&#45;&#45;title">{{ item.title }}</span>-->
          <!--                      <span class="apply-expense&#45;&#45;date">{{ item.payment_date}}</span>-->
          <!--                      <span class="apply-expense&#45;&#45;payto">{{ item.payment_destination }}</span>-->
          <!--                      <span class="apply-expense&#45;&#45;paymenttype">{{ textToPaymentMethod(item.payment_method) }}</span>-->
          <!--                      <span class="apply-expense&#45;&#45;price">-->
          <!--                        {{ splitThousandYen(item.amount) }}-->
          <!--                      </span>-->
          <!--                    </div>-->
          <!--                  </div>-->
          <!--                  <p v-if="checkEmpty(costInformation.wifi_router_rental.length)" class="no-data">{{ $t('common.no_data') }}</p>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--            <p v-else class="no-data">-->
          <!--              {{ $t('common.no_data') }}-->
          <!--            </p>-->
          <!--            <hr />-->
          <!--          </div>-->
        </div>

        <div class="modal-footer">
          <div class="row">
            <div class="col-xs-6 col-sm-6 col-md-6">
              <button class="btn btn-sm btn-flat-default btn-block" @click="$emit('cancel')">
                {{ $t('common.cancel') }}
              </button>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6">
              <button class="btn btn-sm btn-primary btn-block open-alert" @click="sendSettlement">
                {{ $t('trip_item.right_menu.modal_settlement_expense.send_settlement_application') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
  <AddApproverModal
      :visible="addApproverVisible"
      :current-approver-list="currentApproverStepList"
      request-type="3"
      @close-modal="addApproverVisible = false"
      @emit-add-approver="handleAddApprover"
  />
</template>
<style></style>
<script>
import { ElDialog } from 'element-plus';
import { REQUEST_CALCULATE_TYPE } from '@/constants/';
import SingleOptionSelect from '@/components/common/SingleOptionSelect';
import AddApproverModal from '@/components/trip/AddApproverModal';
import {
  APPROVAL,
  PASS_ROUND_PERUSAL,
  payment_reimbursement,
  PAYMENT_CORPORATE_CARD,
  PAYMENT_INVOICE,
} from '@/constants/';

export default {
  components: {
    ElDialog,
    SingleOptionSelect,
    AddApproverModal,
  },
  name: 'ModalSettlementExpense',
  emits: ['cancel', 'success'],
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    destinationType: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    tripId() {
      return this.$route.params.id;
    },
    isUploadInvoice() {
      return this.$store.state.auth?.user?.setting.is_upload_invoice == 1 || false;
    },
    totalWifiRouterRental() {
      let totalCost = 0;
      this.selectedWifiRouterRental?.forEach(element => {
        totalCost += element.amount;
      });
      let otherInsuranceEstNum = 0;
      if (!this.checkEmpty(this.otherInsuranceFeeEst)) {
        otherInsuranceEstNum = parseInt(this.otherInsuranceFeeEst?.replaceAll(',', '')) || 0;
      }
      return totalCost + otherInsuranceEstNum;
    },
    totalAmount() {
      let total = 0;
      if (!this.checkEmpty(this.costInformation.costs)) {
        this.costInformation.costs.forEach(cost => (total += cost.amount));
      }
      // if (!this.checkEmpty(this.costInformation.wifi_router_rental)) {
      //   this.selectedWifiRouterRental.forEach(cost => (total += cost.amount));
      // }
      return total;
    },
    totalCostBearingDecimal() {
      let total = 0;
      if (this.approvedBearing.length < 2) return 0;
      this.approvedBearing.forEach(item => {
        const price = item.price_percentage * this.totalAmount * 0.01;
        total += price - Math.floor(price);
      });
      return total;
    },
    currentApproverStepList() {
      return this.approvalFlowDetail[this.curInputApprover]?.step_detail || [];
    },
    listOrganizationUsing() {
      let listIdUsing = this.approvedBearing.map(item => item.organization_id) || [];

      return listIdUsing;
    },
  },
  watch: {
    'costInformation.wifi_router_rental'(newVal) {
      if (!this.checkEmpty(newVal)) {
        this.selectedWifiRouterRental = this.cloneObject(newVal);
      }
    },
    dialogVisible() {
      if (this.dialogVisible) {
        this.getCostByTrip();
        this.getApprovalFlowByType();
      } else {
        this.costItemSelected = [];
      }
      this.dialogVisibleStatus = this.dialogVisible;
      if (this.dialogVisible) {
        this.getApprovalFlowsByNearest({request_type: 3});
      }
    },
    destinationType() {
      this.getApprovalFlowByType();
    },
    totalAmount() {
      this.calCostBearing(this.approvedBearing);
    },
    approvedBearing: {
      handler(newVal) {
        this.calCostBearing(newVal);
      },

      deep: true,
    },
    'costInformation.request_cost_bearing_organizations'(newVal) {
      this.approvedBearing = [];
      if (!this.checkEmpty(newVal)) {
        newVal.forEach(item => {
          this.approvedBearing.push({
            price_percentage: item.price_percentage,
            organization_id: item.burden_organization_id,
            amount: 0,
          });
        });
      }
      this.calCostBearing(this.approvedBearing);
    },
  },
  created() {
    this.startLoading();
    Promise.all([this.getListOrganizations()]).then(() => {
      this.endLoading();
    });
  },
  data() {
    return {
      default_approval_flow_id: null,
      dialogVisibleStatus: false,
      listApprovalFlowType: [],
      costInformation: {},
      approvalSelected: {},
      selectedWifiRouterRental: [],
      errors: {
        approval_flow_id: '',
        total_price_percentage: '',
        have_duplicated_fields: '',
        selected_bearing: {
          organization: [],
          price_percentage: [],
        },
      },
      displayAddCostBearing: true,
      flagHaveDuplicated: false,
      approvalFlowDetail: [],
      addApproverVisible: false,
      inputApprover: [],
      curInputApprover: 0,
      curApprovalFLowId: 0,
      costItemSelected: [],
      listOrganizations: [],
      approvedBearing: [
        {
          organization_id: '',
          price_percentage: 0,
        },
      ],
    };
  },
  methods: {
    selectWifiRouterRental(costItem) {
      if (this.isInSelectedWifiRouterRental(costItem)) {
        this.selectedWifiRouterRental = this.selectedWifiRouterRental.filter(item => item.id != costItem.id);
        return;
      }
      this.selectedWifiRouterRental.push(costItem);
    },
    isInSelectedWifiRouterRental(item) {
      const costIndex = this.selectedWifiRouterRental.findIndex(ele => ele.id === item.id);

      return costIndex !== -1;
    },
    //CU-314
    async getApprovalFlowsByNearest(params) {
      await this.$services.trip.getApprovalFlowNearest(
          params,
          res => {
            this.default_approval_flow_id = res?.approval_flow_id || null;
          },
          err => {
            this.notifyError(this.$t(err.error));
          },
      );
    },
    isNumber(event = window.event) {
      let charCode = event.which ? event.which : event.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        event.preventDefault();
      } else {
        return true;
      }
    },
    handleBearingOrganization(organizationId, optionIndex) {
      this.approvedBearing[optionIndex].organization_id = organizationId;
    },
    convertNumberFullToHalf(number) {
      number = number.replace(/[^0-9０-９.．]/g, '');
      number = String(number).replace(/[\uFF10-\uFF19]/g, function (m) {
        return String.fromCharCode(m.charCodeAt(0) - 0xfee0);
      });

      return number.replaceAll('．', '.');
    },
    textToStepType(stepType) {
      if (stepType === APPROVAL) return this.$t('trip_item.trip_process.approval');
      if (stepType === PASS_ROUND_PERUSAL) return this.$t('trip_item.trip_process.pass_perusal');
      return '-';
    },
    displayStepTitle(stepIndex) {
      stepIndex++;
      const lastStep = this.approvalFlowDetail.length;
      if (stepIndex == lastStep) {
        return this.$t('trip_item.trip_process.finally');
      }
      return stepIndex;
    },
    appendToStepClass(stepIndex, hasForm) {
      if (hasForm === 1) {
        if (stepIndex == 0) return 'first-step has-form';
        if (stepIndex != 0 && this.approvalFlowDetail[stepIndex - 1]?.user_select_option == 1)
          return 'has-previous-form';
        return 'has-form';
      }
      if (stepIndex == 0) return 'first-step';
      return '';
    },
    openAddApprover(inputApproverId, stepId) {
      this.inputApprover[inputApproverId] = {};
      this.curInputApprover = inputApproverId;
      this.inputApprover[inputApproverId].step_id = stepId;
      this.addApproverVisible = true;
    },
    handleAddApprover(params) {
      this.inputApprover[this.curInputApprover].display_text = params.employee_number + ' ' + params.name;
      this.inputApprover[this.curInputApprover].approve_id = params.id;
      (this.addApproverVisible = false), (this.dialogVisibleStatus = true);
    },
    listenApprovalFlows(approvalFlowId) {
      if (!this.checkEmpty(approvalFlowId)) {
        this.curApprovalFLowId = approvalFlowId;
        if (!this.checkEmpty(approvalFlowId)) this.getApprovalFlowStep(approvalFlowId);
      }
    },
    //CU-003
    async getListOrganizations() {
      const params = {
        all_data: true,
      };
      await this.$services.common.getListOrganizations(
          params,
          res => {
            this.listOrganizations = res.list;
          },
          err => {
            this.notifyError(this.$t(err.error));
          },
      );
    },
    removeCostBearing(bearingDetail) {
      if (this.approvedBearing.includes(bearingDetail)) {
        this.approvedBearing.splice(this.approvedBearing.indexOf(bearingDetail), 1);
      }
    },
    addCostBearing() {
      let currentPercentage = 0;
      this.approvedBearing.forEach(item => {
        if (!this.checkEmpty(item.price_percentage)) {
          currentPercentage += parseFloat(item.price_percentage);
        } else {
          currentPercentage += 0;
        }
      });
      if (currentPercentage != 100)
        this.approvedBearing.push({ price_percentage: +(Math.round(100 - currentPercentage + 'e+3') + 'e-3') });
    },
    calCostBearing(newVal) {
      let totalPercentage = 0;
      if (!this.checkEmpty(newVal)) {
        newVal.forEach((item, index) => {
          if (this.isNumeric(parseFloat(item.price_percentage))) totalPercentage += parseFloat(item.price_percentage);
          const costPercentage = this.isNumeric(parseFloat(item.price_percentage))
              ? parseFloat(item.price_percentage)
              : 0;
          const price = costPercentage * this.totalAmount * 0.01;
          const roundNum = Math.floor(price);
          this.approvedBearing[index].amount =
              index != 0 ? roundNum : +(Math.floor(price + this.totalCostBearingDecimal + 'e+0') + 'e-0');
        });
      }
      if (totalPercentage >= 100) {
        this.displayAddCostBearing = false;
        return;
      }
      this.displayAddCostBearing = true;
    },
    getApprovalFlowStep(approvalFlowId) {
      const params = {
        approval_flow_id: approvalFlowId,
      };
      this.$services.trip_business_report.getApprovalFlowStep(
          params,
          res => {
            this.approvalFlowDetail = res.list;
            this.inputApprover = [];
          },
          err => {
            this.notifyError(this.$t(err.error));
          },
      );
    },
    getCostByTrip() {
      this.$services.trip.getCostByTrip(
          { trip_id: this.tripId },
          res => {
            this.costInformation = res;
            this.costInformation.costs.forEach(cost => {
              cost.amount = 0;
              cost.cost_items.forEach(item => {
                if (!this.isUploadInvoice || !this.checkEmpty(item.image_url) || item.type_cost == 3) {
                  cost.amount += item.amount;
                  this.costItemSelected.push(item.id);
                }
              });
            });
          },
          err => {
            this.notifyError(this.$t(err.error));
          },
      );
    },
    getApprovalFlowByType() {
      let params = {
        request_type: REQUEST_CALCULATE_TYPE,
      };
      //Don't send param trip destination if request_type is Calculate
      // if (this.destinationType !== 0) params.trip_type = this.destinationType;
      this.$services.trip.getApprovalFlowByType(
          params,
          res => {
            this.listApprovalFlowType = res.list;
          },
          err => {
            this.notifyError(this.$t(err.error));
          },
      );
    },
    onChangeSelectItem(item, i) {
      if (this.costItemSelected.includes(item.id)) {
        this.costItemSelected.splice(this.costItemSelected.indexOf(item.id), 1);
      } else {
        this.costItemSelected.push(item.id);
      }
      let amount = 0;
      this.costInformation.costs[i].cost_items.forEach(cost => {
        if (this.costItemSelected.includes(cost.id)) {
          amount += cost.amount;
        }
      });
      this.costInformation.costs[i].amount = amount;
    },
    textToPaymentMethod(status) {
      if (status === payment_reimbursement) return this.$t('trip_item.main_content.payment_reimbursement');
      if (status === PAYMENT_CORPORATE_CARD) return this.$t('trip_item.main_content.payment_corporate_card');
      if (status === PAYMENT_INVOICE) return this.$t('trip_item.main_content.payment_invoice');
      return '';
    },
    checkDuplicatedField(value, name) {
      let countItem = 0;
      this.approvedBearing.forEach(item => {
        if (item[`${name}`] === value) {
          countItem++;
        }
      });
      if (countItem > 1) {
        this.flagHaveDuplicated = true;
        return true;
      } else {
        this.flagHaveDuplicated = false;
        this.errors.have_duplicated_fields = '';
        return false;
      }
    },
    sendSettlement() {
      let flgValidate = true;
      if (this.checkEmpty(this.curApprovalFLowId)) {
        flgValidate = false;
        this.errors.approval_flow_id = this.$t('common.please_select');
      } else {
        this.errors.approval_flow_id = '';
      }

      this.approvalFlowDetail.forEach(approval => {
        // if (
        //   approval.user_select_option == 1 &&
        //   this.checkEmpty(approval.step_detail) &&
        //   this.checkEmpty(this.inputApprover[index]?.approve_id)
        // ) {
        //   flgValidate = false;
        //   approval.error_message = this.$t('E-CM-004');
        // } else {
        delete approval.error_message;
        // }
      });

      //validate cost bearing organization
      let totalPricePercentage = 0;
      this.flagHaveDuplicated = false;

      this.approvedBearing.forEach((item, index) => {
        totalPricePercentage += parseFloat(item.price_percentage);
        if (this.checkEmpty(item.organization_id)) {
          flgValidate = false;
          this.errors.selected_bearing.organization[index] = this.$t('E-CM-004');
          this.$refs.bearing_organizations.scrollIntoView({ behavior: 'smooth' });
        }
        if (!this.checkEmpty(item.organization_id)) {
          if (!this.flagHaveDuplicated && this.checkDuplicatedField(item.organization_id, 'organization_id')) {
            this.errors.have_duplicated_fields = this.$t('E-CU-068');
            flgValidate = false;
            this.$refs.bearing_organizations.scrollIntoView({ behavior: 'smooth' });
          }
          this.errors.selected_bearing.organization[index] = '';
        }
        if (this.checkEmpty(item.price_percentage) && item.price_percentage !== 0) {
          flgValidate = false;
          this.errors.selected_bearing.price_percentage[index] = this.$t('E-CM-004');
          this.$refs.price_percentage[index].focus();
        } else if (!this.isNumeric(item.price_percentage)) {
          flgValidate = false;
          this.errors.selected_bearing.price_percentage[index] = this.$t(
              'E-CM-047',
              this.$t('business_report.cost_percentage'),
          );
        } else {
          this.errors.selected_bearing.price_percentage[index] = '';
        }
      });

      if (totalPricePercentage != 100) {
        flgValidate = false;
        this.errors.total_price_percentage = this.$t('business_report.total_cost_bearing_err');
      } else {
        this.errors.total_price_percentage = '';
      }

      if (!flgValidate) return;

      let inputApproverList = [];
      if (!this.checkEmpty(this.inputApprover)) {
        this.inputApprover.forEach(item => {
          if (!this.checkEmpty(item) && !this.checkEmpty(item.approve_id)) {
            inputApproverList.push(item);
          }
        });
      }

      this.approvedBearing.forEach((item, index) => {
        this.approvedBearing[index].price_percentage = parseFloat(item.price_percentage);
      });

      let companyCostItems = [];
      this.costInformation.costs.forEach(cost => {
        let listCostItem = [];
        if (!this.checkEmpty(cost.cost_items)) {
          cost.cost_items.forEach(cost => {
            if (this.costItemSelected.includes(cost.id)) {
              cost.checked = true;
              listCostItem.push(cost);
            }
          });
        }
        companyCostItems.push({
          id: cost.id,
          title: cost.title,
          cost_items: listCostItem,
        });
      });

      let params = {
        trip_id: parseInt(this.tripId),
        approval_flow_id: this.curApprovalFLowId ? parseInt(this.curApprovalFLowId) : null,
        costs: !this.checkEmpty(this.costItemSelected) ? this.costItemSelected : null,
        approval_flow_steps: inputApproverList,
        company_cost_items: companyCostItems,
        cost_bearing_organizations: this.approvedBearing,
      };

      this.startLoading();
      this.$services.trip.sendSettlement(
          params,
          () => {
            this.endLoading();
            this.notifySuccess(this.$t('trip_item.right_menu.modal_settlement_expense.have_sent_application'));
            this.$emit('success');
          },
          err => {
            this.endLoading();
            this.notifyError(this.$t(err.error));
          },
      );
    },
  },
};
</script>
<style scoped>
.list-tab {
  cursor: pointer;
}
.select-approval-flow_1 {
  margin-top: 12px;
}
.step-list.step-list--ringi-apply {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
  margin-top: 12px;
  margin-bottom: 24px;
}
.step-list.step-list--ringi-apply .step-item {
  justify-content: flex-start;
  width: 100%;
  height: 24px;
  margin-bottom: 16px;
}
.step-list.step-list--ringi-apply .step-item.has-form {
  height: 36px;
}
.step-list.step-list--ringi-apply .step-item.has-form + .step-item::before {
  position: absolute;
  top: -26px;
  left: 7px;
  width: 2px;
  height: 30px;
}
.step-item.first-step::before {
  display: none;
}
.step-item.has-form::before {
  height: 30px !important;
}
.step-list.step-list--ringi-apply .step-mark + span {
  width: 40px;
  min-width: 40px;
  text-align: left;
}
.step-list.step-list--ringi-apply .list--approver li {
  display: inline-block;
}
.step-list.step-list--ringi-apply .list--approver {
  margin-bottom: 0;
  padding: 0;
}
.step-list.step-list--ringi-apply .list--approver li + li::before {
  display: inline-block;
  margin-right: 4px;
  content: '、';
}
.step-list.step-list--ringi-apply .step-item::before {
  position: absolute;
  top: -20px;
  left: 7px;
  width: 2px;
  height: 24px;
}
.step-list.step-list--ringi-apply .step-mark {
  min-width: 16px;
  margin-right: 12px;
}
.step-list.step-list--ringi-apply .step-item::after {
  display: none;
}
.step-list.step-list--ringi-apply .step-item span {
  display: inline-block;
  position: unset;
  top: unset;
  width: auto;
}
.step-list.step-list--ringi-apply .ringi-step--type {
  width: 48px;
  min-width: 48px;
  margin-right: 12px;
}
.step-item {
  display: flex;
  position: relative;
  align-items: center;
  color: #98a9b0;
  font-size: 12px;
  text-align: center;
}
.step-item::before {
  display: block;
  content: '';
  background-color: #d0d6da;
  content: '';
}
.ringi-step--type {
  top: unset !important;
  bottom: -24px;
  color: #45545b;
  font-size: 11px;
}
.js-btn--toggle-allowance {
  cursor: pointer;
}
.custom-select-approver {
  width: 55%;
  display: revert;
  justify-content: flex-end;
  margin-left: auto;
}
.padding-10 {
  padding-top: 10px;
}
.adjust-amount input {
  text-align: right;
}
.est-cost-check {
  padding-left: 28px;
  padding-top: 7px;
}
.custom-apply-expense {
  align-items: flex-start !important;
}
.apply-expense--dept {
  padding-top: 5px;
}
.btn-used-in-request {
  padding: 3px 7px;
  font-size: 11px;
  border-radius: 10px;
  margin-right: 5px;
}
</style>
