import BaseService from './BaseService';
import API_CODE from '../utils/api_code.js';

export default class PositionAPI extends BaseService {
  async getListPosition(success, error) {
    await this.get(API_CODE.CU_127, success, error);
  }
  async updateListPosition(params, success, error) {
    await this.post(API_CODE.CU_151, params, success, error);
  }
  async downloadFileCSV(success, error) {
    await this.get(API_CODE.CU_152, success, error);
  }
  async downloadExampleFileCSV(success, error) {
    await this.get(API_CODE.CU_153, success, error);
  }
  async uploadFileCSV(params, success, error) {
    await this.post(API_CODE.CU_154, params, success, error);
  }
  async getListZone(success, error) {
    await this.get(API_CODE.CU_140, success, error);
  }
  async getListPositionUnique(params, success, error) {
    await this.get(API_CODE.CU_127 + this.urlParse(params), success, error);
  }
}
