<template>
  <div class="modal--password-reset" id="modal--password-reset">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-header--title text-center">{{ $t('customer.login_with_sso') }}</div>
        </div>
        <div class="modal-body">
            <div class="row">
              <div class="col-xs-12 col-sm-6 col-md-6 col-sm-offset-3 col-md-offset-3">
              <p class="error-feedback" v-text="messageValidate"></p>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-6 col-sm-offset-3 col-md-offset-3">
                <div class="">
                  <div class="form-group">
                    <label>
                      <span class="label-text">{{ $t('forgot_password.company_code') }}</span>
                    </label>
                    <input
                      name="company_code"
                      type="text"
                      @keyup.enter="loginWithSso"
                      class="form-control"
                      maxlength="50"
                      v-model="company_code"
                      :placeholder="$t('common.please_input_company_code')"
                    />
                  </div>
                </div>
              </div>
            </div>
        </div>
        <div class="modal-footer pts">
          <div class="row">
            <div class="col-xs-12 col-sm-6 col-md-6 col-sm-offset-3 col-md-offset-3">
              <p class="error-feedback" v-text="errors_message.forgotPassword && $t(errors_message.forgotPassword)" />
              <button
                class="btn btn-primary btn-block"
                :disabled="!company_code.trim()"
                @click="loginWithSso"
              >
                {{ $t('login.btn_login') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SsoCompany',
  emits: ['close-popup'],
  data() {
    return {
      company_code: '',
      errors_message: {
        forgotPassword: '',
      }
    };
  },
  props: {
    messageValidate: {
      type: String,
      default: '',
    }
  },
  methods: {
    loginWithSso() {
      if (this.company_code) {
        this.startLoading();
        let loginSsoUrl = process.env.VUE_APP_API_URL.replace("api/v1/", "sso/login?company_code=" + this.company_code);
        window.location = loginSsoUrl;
      }
    }
  },
};
</script>