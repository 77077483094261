<template>
  <div class="wrapper-page">
    <nav class="navbar navbar-default navbar-lg" role="navigation" id="header-content">
      <div class="navbar-header">
        <h2 class="navbar-title">{{ $t('sidebar.settings') }}</h2>
      </div>
    </nav>
    <div>
      <div class="container-fluid">
        <div v-show="showExpenseSettlement" class="section noborder ptn">
          <h5>{{ $t('settings.current_contract_detail') }}</h5>
          <div>
            <span>• {{ $t('settings.business_trip_management_function') }}</span>
            <span v-if="allSettings?.is_feature_payment"><br />• {{ $t('settings.expense_reimbursement_function') }}</span>
          </div>
        </div>
        <div class="section" v-if="userRole == 'employee_manager'">
          <div class="">
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label
                    class="checkbox checkbox-inline checkbox-type-div checkbox-expense"
                    :class="isShowUserId && 'checked'"
                  >
                    <span class="icons">
                      <span class="first-icon icon-abui-checkbox-unchecked"></span>
                      <span class="second-icon icon-abui-checkbox-checked"></span>
                    </span>
                    <input type="checkbox" v-model="isShowUserId" />
                    <span class="text_type">
                      {{ $t('settings.show_user_id') }}
                    </span>
                  </label>
                  <p class="help-block">{{ $t('settings.show_user_id_note') }}</p>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label
                    class="checkbox checkbox-inline checkbox-type-div checkbox-expense"
                    :class="isOverseaInsuranceRequired && 'checked'"
                  >
                    <span class="icons">
                      <span class="first-icon icon-abui-checkbox-unchecked"></span>
                      <span class="second-icon icon-abui-checkbox-checked"></span>
                    </span>
                    <input type="checkbox" v-model="isOverseaInsuranceRequired" />
                    <span class="text_type">
                      {{ $t('settings.arrange_insurance_note') }}
                    </span>
                  </label>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label
                    class="checkbox checkbox-inline checkbox-type-div checkbox-expense"
                    :class="isTwoAuthenticated && 'checked'"
                  >
                    <span class="icons">
                      <span class="first-icon icon-abui-checkbox-unchecked"></span>
                      <span class="second-icon icon-abui-checkbox-checked"></span>
                    </span>
                    <input type="checkbox" v-model="isTwoAuthenticated" />
                    <span class="text_type">
                      {{ $t('settings.two_step_verification_note') }}
                    </span>
                  </label>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label
                    class="checkbox checkbox-inline checkbox-type-div checkbox-expense"
                    :class="isSendMultiEmail && 'checked'"
                  >
                    <span class="icons">
                      <span class="first-icon icon-abui-checkbox-unchecked"></span>
                      <span class="second-icon icon-abui-checkbox-checked"></span>
                    </span>
                    <input type="checkbox" v-model="isSendMultiEmail" />
                    <span class="text_type">
                      {{ $t('settings.notify_add_email_address') }}
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="section table-exchange" id="">
          <div style="display: flex; align-items: center; justify-content: space-between">
            <h4 class="section--title">{{ $t('settings.exchange_rate') }}</h4>
            <div class="btn-group">
              <button @click="getExchangeRateCsvFile()" class="btn btn-sm btn-inverse">
                <i class="icon-abui-download prx"></i>
                {{ $t('settings.download_csv') }}
              </button>
              <button @click="showUploadFile = true" class="btn btn-sm btn-inverse">
                <i class="icon-abui-upload prx"></i>
                {{ $t('settings.upload') }}
              </button>
              <button @click="getExchangeRateCsvExampleFile()" class="btn btn-sm btn-inverse">
                <i class="icon-abui-download prx"></i>
                {{ $t('settings.download_csv_example') }}
              </button>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-4">
              <div class="form-group">
                <div class="form-group">
                  <label>
                    <span class="label-text">{{ $t('settings.applicable_date') }}</span>
                  </label>
                  <label class="checkbox checkbox-inline" :class="applicableDate === DEPARTURE_DATE_TYPE && 'checked'">
                    <span class="icons">
                      <span class="first-icon icon-abui-radio-unchecked"></span>
                      <span class="second-icon icon-abui-radio-checked"></span>
                    </span>
                    <input type="radio" :value="DEPARTURE_DATE_TYPE" v-model="applicableDate" />
                    <span class="text_type_radio">
                      {{ $t('settings.departure_date') }}
                    </span>
                  </label>

                  <label
                    class="checkbox checkbox-inline checkbox-radio-div"
                    :class="applicableDate === RETURN_DATE_TYPE && 'checked'"
                  >
                    <span class="icons">
                      <span class="first-icon icon-abui-radio-unchecked"></span>
                      <span class="second-icon icon-abui-radio-checked"></span>
                    </span>
                    <input type="radio" :value="RETURN_DATE_TYPE" v-model="applicableDate" />
                    <span class="text_type_radio">
                      {{ $t('settings.return_date') }}
                    </span>
                  </label>

                  <label
                    class="checkbox checkbox-inline checkbox-radio-div type-approver"
                    :class="applicableDate === PAYMENT_DATE_TYPE && 'checked'"
                  >
                    <span class="icons">
                      <span class="first-icon icon-abui-radio-unchecked"></span>
                      <span class="second-icon icon-abui-radio-checked"></span>
                    </span>
                    <input type="radio" :value="PAYMENT_DATE_TYPE" v-model="applicableDate" />
                    <span class="text_type_radio">
                      {{ $t('settings.payment_date') }}
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label>
                  <span class="label-text">{{ $t('settings.override') }}</span>
                </label>
                <label
                  class="checkbox checkbox-inline checkbox-type-div checkbox-expense"
                  :class="isExchangeRateOverwrite && 'checked'"
                >
                  <span class="icons">
                    <span class="first-icon icon-abui-checkbox-unchecked"></span>
                    <span class="second-icon icon-abui-checkbox-checked"></span>
                  </span>
                  <input type="checkbox" v-model="isExchangeRateOverwrite" />
                  <span class="text_type">
                    {{ $t('settings.can_be_overridden_note') }}
                  </span>
                </label>
              </div>
            </div>
          </div>
          <div class="filter-wrap mt-3" v-show="showBoxFilter">
            <div class="filter js-filter--minimization">
              <div class="section section-filter noborder js-section-filter">
                <div class="row">
                  <div
                    class="col-sm-6 col-md-4 col-all-closer col-all-closer--leftend col-sm-closer--rightend col-xs-closer--rightend"
                  >
                    <div class="form-group">
                      <label>
                        <span class="label-text">{{ $t('settings.currency') }}</span>
                      </label>
                      <input
                        type="text"
                        v-model="searchParams.key_word"
                        maxlength="200"
                        class="form-control input-sm"
                        :placeholder="$t('settings.search.currency')"
                        @input="onInput"
                      />
                    </div>
                  </div>
                  <div
                    class="col-sm-6 col-md-4 col-all-closer col-all-closer--leftend col-sm-closer--rightend col-xs-closer--rightend"
                  >
                    <div class="form-group">
                      <label>
                        <span class="label-text">{{ $t('settings.change_reservation') }}</span>
                      </label>
                      <div class="input-group">
                        <div class="input-with-icon">
                          <el-date-picker
                            class="date-input"
                            v-model="searchParams.start_date"
                            type="date"
                            format="YYYY/MM/DD"
                            value-format="YYYY/MM/DD"
                            :placeholder="$t('common.enter_or_select')"
                            :clearable="false"
                          />
                        </div>
                        <span class="input-group-addon prn">{{ $t('common.from') }}</span>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-sm-6 col-md-4 col-all-closer col-all-closer--rightend col-sm-closer--leftend col-xs-closer--leftend"
                  >
                    <div class="form-group">
                      <label class="hidden-xs"><span class="label-text">&nbsp;</span></label>
                      <div class="input-group">
                        <div class="input-with-icon">
                          <el-date-picker
                            class="date-input"
                            v-model="searchParams.end_date"
                            type="date"
                            format="YYYY/MM/DD"
                            value-format="YYYY/MM/DD"
                            :placeholder="$t('common.enter_or_select')"
                            :clearable="false"
                          />
                        </div>
                        <span class="input-group-addon prn">{{ $t('common.to') }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="section-submit js-section-submit" style="padding: 0">
              <span class="submit-arrow"></span>
              <div class="row">
                <div class="col-sm-4 col-md-5 hidden-xs">
                  <button class="btn btn-sm btn-flat-default btn-clear" @click="clearFilter()">
                    {{ $t('common.clear') }}
                  </button>
                </div>
                <div class="col-sm-4 col-md-2">
                  <button class="btn btn-sm btn-primary btn-block js-btn-filter--minimization" @click="handleSearch()">
                    {{ $t('common.search') }}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            class="btn-filter-again with-condition"
            v-show="!showBoxFilter"
            @click="showBoxFilter = true"
            style="display: block"
          >
            <div class="filter--condition">
              <span>{{ $t('common.all') }}</span>
              <span style="color: #3650a5">{{ $t('common.reset_conditions') }}</span>
            </div>
          </div>
          <div>
            <div>
              <table class="table table-hover table-exchange-rate">
                <colgroup span="1" style="width: 42px"></colgroup>
                <colgroup span="1"></colgroup>
                <colgroup span="1"></colgroup>
                <colgroup span="1"></colgroup>
                <colgroup span="1" style="width: 1em"></colgroup>
                <colgroup span="1"></colgroup>
                <colgroup span="1" style="width: 38px"></colgroup>
                <thead>
                  <tr>
                    <th></th>
                    <th>{{ $t('settings.currency') }}</th>
                    <th class="text-right">
                      <span class="prl">{{ $t('settings.applicable_rate') }}</span>
                    </th>
                    <th>{{ $t('settings.change_reservation') }}</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody v-if="!checkEmpty(this.curExchangeRateList)" class="sortable--LineByLine--tbody">
                  <tr
                    v-for="(item, index) in curExchangeRateList"
                    class="scroll-margin-top"
                    :key="item.id"
                    :style="styleFilter(item)"
                    :id="`setting_rate_${index}`"
                  >
                    <td class="cell--updown cell-control">
                      <button @click="upRow(index)" class="btn btn-xs btn-flat-default js-btn-rowup">
                        <span class="icon-abui-arrow-up"></span>
                      </button>
                      <button @click="downRow(index)" class="btn btn-xs btn-flat-default js-btn-rowdown">
                        <span class="icon-abui-arrow-down"></span>
                      </button>
                    </td>
                    <td class="cell-control currency-col">
                      <SingleOptionSelect
                        :list-data="currencyList"
                        :class="
                          (!checkEmpty(errors.exchange_rate[index]?.currency) ||
                            this.listDuplicateFields.includes(index)) &&
                          'has-validation-error-el'
                        "
                        :default-list="item.currency"
                        :option-index="{ haveIndex: true, index }"
                        :display-by-locale="true"
                        :isCurrency="true"
                        :placeholder="$t('common.please_select')"
                        @handle-selected-params="listenCurrencyParams"
                      />
                      <span role="alert" class="error-feedback" v-text="errors.exchange_rate[index]?.currency" />
                    </td>
                    <td class="text-right">
                      <span class="prl">
                        {{ item.applying_rate == 1 ? $t('settings.is_not_set') : item.applying_rate }}
                      </span>
                    </td>
                    <td class="cell-control">
                      <div class="form-group">
                        <div class="input-with-icon">
                          <el-date-picker
                            class="date-input"
                            :class="
                              (!checkEmpty(errors.exchange_rate[index]?.change_reservation) ||
                                this.listDuplicateFields.includes(index)) &&
                              'has-validation-error-el-datepicker'
                            "
                            v-model="curExchangeRateList[index].change_reservation"
                            type="date"
                            format="YYYY/MM/DD"
                            value-format="YYYY/MM/DD"
                            :placeholder="$t('common.enter_or_select')"
                            :clearable="false"
                          >
                          </el-date-picker>
                        </div>
                      </div>
                      <span
                        role="alert"
                        class="error-feedback"
                        v-text="errors.exchange_rate[index]?.change_reservation"
                      />
                    </td>
                    <td>
                      <span style="white-space: nowrap">{{ $t('settings.from') }}</span>
                    </td>
                    <td class="cell-control">
                      <input
                        type="text"
                        v-model="curExchangeRateList[index].applicable_rate"
                        class="form-control input-sm text-right"
                        :class="!checkEmpty(errors.exchange_rate[index]?.new_exchange_rate) && 'has-validation-error'"
                        onkeydown="javascript: return event.keyCode !== 69;"
                        @input="
                          curExchangeRateList[index].applicable_rate = convertNumberFullToHalf(
                            curExchangeRateList[index].applicable_rate,
                          )
                        "
                        placeholder="1.0000"
                        @change="convertData(curExchangeRateList[index].applicable_rate, index, 'applicable_rate')"
                      />
                      <span
                        role="alert"
                        class="error-feedback"
                        v-text="errors.exchange_rate[index]?.new_exchange_rate"
                      />
                    </td>
                    <td class="cell-control">
                      <a class="btn btn-flat-danger btn-sm" @click="removeExchangeRate(item)"
                        ><span class="icon-abui-cross"></span
                      ></a>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr class="addrow js-addcontrol-item" id="js-addcontrol-currency">
                    <td colspan="7">
                      <a class="btn btn-sm" @click="addExchangeRate()">
                        <span class="icon-abui-plus"></span>
                      </a>
                    </td>
                  </tr>
                </tfoot>
              </table>
              <div ref="rate_table_footer">
                <span role="alert" class="row error-feedback" v-text="errors.have_duplicated_fields"></span>
              </div>
            </div>
          </div>
        </div>
        <div class="section mb-70p">
          <h4 class="section--title">{{ $t('customer.sso_setting') }}</h4>
          <p>
            {{ $t('customer.sso_setting_note') }}
          </p>
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <div class="form-group  show--edit show--new hide--view">
                <label>
                  <span class="label-text">{{ $t('customer.sso_setting_idp_entity_id') }}</span>
                </label>
                <input
                    type="text"
                    class="form-control input-sm"
                    placeholder=""
                    maxlength="1000"
                    id="customer-name"
                    v-model="ssoSetting.idp_entity_id"
                />
              </div>
              <p role="alert" class="error-feedback" v-text="errors.idp_entity_id"></p>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <div class="form-group show--edit show--new hide--view">
                <label>
                  <span class="label-text">{{ $t('customer.sso_setting_idp_sso_url') }}</span>
                </label>
                <input
                    type="text"
                    class="form-control input-sm"
                    placeholder=""
                    maxlength="1000"
                    id="customer-name"
                    v-model="ssoSetting.idp_sso_url"
                />
              </div>
              <p role="alert" class="error-feedback" v-text="errors.idp_sso_url"></p>
            </div>
          </div>
<!--          <div class="row">-->
<!--            <div class="col-sm-12 col-md-6">-->
<!--              <div class="form-group show&#45;&#45;edit show&#45;&#45;new hide&#45;&#45;view">-->
<!--                <label>-->
<!--                  <span class="label-text">{{ $t('customer.sso_setting_idp_sp_url') }}</span>-->
<!--                </label>-->
<!--                <input-->
<!--                    type="text"-->
<!--                    class="form-control input-sm"-->
<!--                    placeholder=""-->
<!--                    maxlength="1000"-->
<!--                    id="customer-name"-->
<!--                    v-model="ssoSetting.idp_sp_url"-->
<!--                />-->
<!--              </div>-->
<!--              <p role="alert" class="error-feedback" v-text="errors.idp_sp_url"></p>-->
<!--            </div>-->
<!--          </div>-->
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <div class="form-group show--edit show--new hide--view">
                <label>
                  <span class="label-text">{{ $t('customer.sso_setting_idp_x509_certificate') }}</span>
                </label>
                <input
                    type="text"
                    class="form-control input-sm"
                    placeholder=""
                    maxlength="5000"
                    id="customer-name"
                    v-model="ssoSetting.idp_x509_certificate"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          class="navbar navbar-default navbar-fixed-bottom navbar-footblock with-sidebar submit-new--wrap show--estimate"
          role="navigation"
          style="z-index: 990; padding: 12px"
        >
          <div class="row">
            <div class="col-sm-4 col-md-3 col-sm-offset-8 col-md-offset-9">
              <a @click="saveSettings()" class="btn btn-sm btn-block btn-primary open-alert" >
                {{ $t('settings.keep') }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalUploadCSV
      :dialogVisible="showUploadFile"
      :is-single-upload="true"
      accepted-files=".csv"
      @cancel="showUploadFile = false"
      @confirm="confirmUploadFile"
      :errorValidate="errorValidate"
      :title="$t('common.title_modal_upload_file_csv')"
    />
  </div>
</template>
<script>
import { DEPARTURE_DATE_TYPE, RETURN_DATE_TYPE, PAYMENT_DATE_TYPE, DEFAULT_CHANGE_RESERVATION_DATE } from '@/constants';
import SingleOptionSelect from '@/components/common/SingleOptionSelect.vue';
import ModalUploadCSV from '@/components/common/ModalUploadCSV';
import moment from 'moment';
import mixins from '@/helpers/mixins';

export default {
  name: 'SettingsDetail',
  emits: ['onChangeData'],
  mixins: [mixins],
  components: { SingleOptionSelect, ModalUploadCSV },
  data() {
    return {
      errorValidate: [],
      listDuplicateFields: [],
      searchParams: {
        key_word: '',
        start_date: '',
        end_date: '',
      },
      searchParamsClone: {
        key_word: '',
        start_date: '',
        end_date: '',
      },
      ssoSetting: {
        idp_entity_id: null,
        idp_sso_url: null,
        idp_sp_url: null,
        idp_x509_certificate: null
      },
      showBoxFilter: false,
      DEPARTURE_DATE_TYPE,
      RETURN_DATE_TYPE,
      PAYMENT_DATE_TYPE,
      isShowUserId: false,
      isOverseaInsuranceRequired: false,
      applicableDate: PAYMENT_DATE_TYPE,
      isExchangeRateOverwrite: false,
      currencyList: [],
      allSettings: [],
      curExchangeRateList: [],
      curExchangeRateListOfficial: [],
      exchangeRateCsvFiles: [],
      showUploadFile: false,
      isTwoAuthenticated: false,
      isSendMultiEmail: false,
      flagHaveDuplicated: false,
      flagValidate: true,
      errors: {
        exchange_rate: [],
        have_duplicated_fields: '',
      },
    };
  },
  created() {
    this.startLoading();
    Promise.all([this.getCurrencyList(), this.getAllSettings()]).then(() => {
      this.endLoading();
    });
  },
  computed: {
    showExpenseSettlement() {
      return !['employee_approval_management'].includes(this.userRole);
    },
    defaultReservationDate() {
      return !this.checkEmpty(this.allSettings.exchange_rate?.change_reservation)
        ? this.showDateTime(this.allSettings.exchange_rate?.change_reservation, 'YYYY/MM/DD')
        : DEFAULT_CHANGE_RESERVATION_DATE;
    },
    isDataChanged() {
      const is_show_user_id = this.isShowUserId ? 1 : 0;
      const is_oversea_insurance_required = this.isOverseaInsuranceRequired ? 1 : 0;
      const is_two_authenticate = this.isTwoAuthenticated ? 1 : 0;
      const is_sent_multi_email = this.isSendMultiEmail ? 1 : 0;
      const is_exchange_rate_overwrite = this.isExchangeRateOverwrite ? 1 : 0;

      return (
        is_show_user_id != this.allSettings.is_show_user_id ||
        is_oversea_insurance_required != this.allSettings.is_oversea_insurance_required ||
        is_two_authenticate != this.allSettings.is_two_authenticate ||
        is_sent_multi_email != this.allSettings.is_send_multi_email ||
        this.applicableDate != this.allSettings.applicable_date ||
        is_exchange_rate_overwrite != this.allSettings.is_exchange_rate_overwrite ||
        !this.compareArray(this.curExchangeRateList, this.curExchangeRateListOfficial)
      );
    },
  },
  watch: {
    showUploadFile() {
      this.errorValidate = [];
    },
    isDataChanged(newVal) {
      this.$emit('onChangeData', newVal);
    },
    'allSettings'(newVal) {
      this.isShowUserId = newVal ? true : false;
      this.ssoSetting = {
        idp_entity_id: newVal.idp_entity_id,
        idp_sso_url: newVal.idp_sso_url,
        idp_sp_url: newVal.idp_sp_url,
        idp_x509_certificate: newVal.idp_x509_certificate
      };
    },
    'allSettings.is_show_user_id'(newVal) {
      this.isShowUserId = newVal ? true : false;
    },
    'allSettings.is_oversea_insurance_required'(newVal) {
      this.isOverseaInsuranceRequired = newVal ? true : false;
    },
    'allSettings.is_exchange_rate_overwrite'(newVal) {
      this.isExchangeRateOverwrite = newVal ? true : false;
    },
    'allSettings.exchange_rates'(newVal) {
      this.curExchangeRateList = [];
      this.curExchangeRateListOfficial = [];
      if (!this.checkEmpty(newVal)) {
        let curExchangeRateList = [];
        newVal.forEach(item => {
          //Ignore if currency is Yen
          if (item.currency != 1) {
            curExchangeRateList.push({
              id: item.id,
              currency: item.currency,
              currency_info: item.currency_info,
              change_reservation: this.showDateTime(item.change_reservation, 'YYYY/MM/DD'),
              applying_rate:
                parseFloat(item.applying_rate) >= 1000
                  ? this.splitThousandNumber(item.applying_rate)
                  : item.applying_rate,
              applicable_rate: String(
                parseFloat(item.change_rate) >= 1000 ? this.splitThousandNumber(item.change_rate) : item.change_rate,
              ),
            });
          }
        });
        this.curExchangeRateList = curExchangeRateList;
        if (!this.checkEmpty(curExchangeRateList)) {
          curExchangeRateList.forEach(item => this.curExchangeRateListOfficial.push(this.cloneObject(item)));
        }
      }
    },
    'allSettings.applicable_date'(newVal) {
      if (!this.checkEmpty(newVal)) this.applicableDate = newVal;
    },
    'allSettings.is_two_authenticate'(newVal) {
      this.isTwoAuthenticated = newVal ? true : false;
    },
    'allSettings.is_send_multi_email'(newVal) {
      this.isSendMultiEmail = newVal ? true : false;
    },
  },
  methods: {
    isValidHttpUrl(string) {
      let res = string.match(
          /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g,
      );
      if (res == null) {
        return false;
      } else {
        return true;
      }
    },
    //Convert Data to ',' split type
    convertData(data, index, changeName) {
      if (this.checkEmpty(data)) return;
      if (String(data).includes('.') || String(data).includes('．')) {
        if (String(data).includes('．')) {
          data = data.replaceAll('．', '.');
        }
        this.curExchangeRateList[index][`${changeName}`] = String(parseFloat(data).toFixed(2))
          .replace(/^(?!00[^0])0/, '')
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      } else {
        data = data.replaceAll(',', '');
        this.curExchangeRateList[index][`${changeName}`] = data
          .replace(/(\..*?)\..*/g, '$1')
          .replace(/^(?!00[^0])0/, '')
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    },
    handleSearch() {
      this.searchParamsClone = { ...this.searchParams };
    },
    clearFilter() {
      this.searchParams = {
        key_word: '',
        start_date: '',
        end_date: '',
      };
      this.searchParamsClone = { ...this.searchParams };
      this.showBoxFilter = false;
    },
    styleFilter(item) {
      if (this.searchParamsClone.start_date) {
        const startDate = moment(this.searchParamsClone.start_date).format('YYYY/MM/DD');
        if (item.change_reservation < startDate) {
          return 'display: none';
        }
      }
      if (this.searchParamsClone.end_date) {
        const endDate = moment(this.searchParamsClone.end_date).format('YYYY/MM/DD');
        if (item.change_reservation > endDate) {
          return 'display: none';
        }
      }
      if (this.searchParamsClone.key_word) {
        const keyWord = this.searchParamsClone.key_word.toLowerCase();
        if (
          !item?.currency_info?.code.toLowerCase().includes(keyWord) &&
          !item?.currency_info?.ja_name.toLowerCase().includes(keyWord) &&
          !item?.currency_info?.en_name.toLowerCase().includes(keyWord)
        ) {
          return 'display: none';
        }
      }
      return '';
    },
    convertNumberFullToHalf(number) {
      number = number.replace(/[^0-9０-９.．]/g, '');
      number = String(number).replace(/[\uFF10-\uFF19]/g, function (m) {
        return String.fromCharCode(m.charCodeAt(0) - 0xfee0);
      });

      return number.replaceAll('．', '.');
    },
    pickerFromOptions(time) {
      return moment(new Date(time)).format('YYYY-MM-DD') <= moment(new Date()).format('YYYY-MM-DD');
    },
    listenCurrencyParams(selectedCurrencyId, selectIndex) {
      this.curExchangeRateList[selectIndex].currency = selectedCurrencyId;
    },
    addExchangeRate() {
      this.curExchangeRateList.push({
        currency: '',
        change_reservation: DEFAULT_CHANGE_RESERVATION_DATE,
        applying_rate: 1,
        applicable_rate: '',
      });
      if (!this.compareArray(this.curExchangeRateList, this.curExchangeRateListOfficial)) {
        this.$emit('onChangeData', true);
      }
    },
    upRow(index) {
      const tmp = this.curExchangeRateList[index];
      this.curExchangeRateList[index] = this.curExchangeRateList[index - 1];
      this.curExchangeRateList[index - 1] = tmp;

      const error_tmp = this.errors.exchange_rate[index];
      this.errors.exchange_rate[index] = this.errors.exchange_rate[index - 1];
      this.errors.exchange_rate[index - 1] = error_tmp;
      if (!this.compareArray(this.curExchangeRateList, this.curExchangeRateListOfficial)) {
        this.$emit('onChangeData', true);
      }
    },
    downRow(index) {
      const tmp = this.curExchangeRateList[index];
      this.curExchangeRateList[index] = this.curExchangeRateList[index + 1];
      this.curExchangeRateList[index + 1] = tmp;

      const error_tmp = this.errors.exchange_rate[index];
      this.errors.exchange_rate[index] = this.errors.exchange_rate[index + 1];
      this.errors.exchange_rate[index + 1] = error_tmp;
      if (!this.compareArray(this.curExchangeRateList, this.curExchangeRateListOfficial)) {
        this.$emit('onChangeData', true);
      }
    },
    removeExchangeRate(item) {
      if (this.curExchangeRateList.includes(item))
        this.curExchangeRateList.splice(this.curExchangeRateList.indexOf(item), 1);
      if (!this.compareArray(this.curExchangeRateList, this.curExchangeRateListOfficial)) {
        this.$emit('onChangeData', true);
      }
    },
    confirmUploadFile(listFiles) {
      if (!this.checkEmpty(listFiles)) this.getSettingsFromCsv(listFiles[0]);
    },
    downloadCSV(res) {
      window.open(res.file_path, '_blank').focus();
    },
    async saveSettings() {
      if (this.ssoSetting.idp_entity_id) {
        await this.$services.common.validateCheckEntityId(
            {idp_entity_id: this.ssoSetting.idp_entity_id},
            res => {
              if (res.entity_id_company_exist) {
                this.errors.idp_entity_id = this.$t('ENTITY-ID-EXIST');
              } else {
                this.errors.idp_entity_id = '';
              }
            }
        );
      } else {
        this.errors.idp_entity_id = '';
      }
      if (!this.checkEmpty(this.errors.idp_entity_id))  {
        return;
      }
      this.listDuplicateFields = [];
      this.flagValidate = true;
      this.flagHaveDuplicated = false;
      let curErrorRateIndex = -1;
      this.curExchangeRateList.forEach((item, index) => {
        this.errors.exchange_rate[index] = {};
        //Validate currency
        if (this.checkEmpty(item.currency)) {
          this.flagValidate = false;
          this.errors.exchange_rate[index].currency = this.$t('E-CM-004');
          if (curErrorRateIndex == -1) curErrorRateIndex = index;
        } else {
          this.errors.exchange_rate[index].currency = '';
        }

        //Validate selected change_reservation organizations
        if (this.checkEmpty(item.change_reservation)) {
          this.flagValidate = false;
          this.errors.exchange_rate[index].change_reservation = this.$t('E-CM-004');
          if (curErrorRateIndex == -1) curErrorRateIndex = index;
        } else {
          if (
            !this.flagHaveDuplicated &&
            this.checkDuplicatedField(item.change_reservation, 'change_reservation', item.currency)
          ) {
            this.errors.have_duplicated_fields = this.$t('E-CU-068');
          }
          this.errors.exchange_rate[index].change_reservation = '';
        }
        if (this.ssoSetting.idp_entity_id) {
          if (!this.isValidHttpUrl(this.ssoSetting.idp_entity_id)) {
            this.flagValidate = false;
            this.errors.idp_entity_id = this.$t('common.valid_url');
          } else {
            delete this.errors.idp_entity_id;
          }
        }
        if (this.ssoSetting.idp_sso_url) {
          if (!this.isValidHttpUrl(this.ssoSetting.idp_sso_url)) {
            this.flagValidate = false;
            this.errors.idp_sso_url = this.$t('common.valid_url');
          } else {
            delete this.errors.idp_sso_url;
          }
        }
        if (this.ssoSetting.idp_sp_url) {
          if (!this.isValidHttpUrl(this.ssoSetting.idp_sp_url)) {
            this.flagValidate = false;
            this.errors.idp_sp_url = this.$t('common.valid_url');
          } else {
            delete this.errors.idp_sp_url;
          }
        }

        //Validate new exchange rate
        if (this.checkEmpty(item.applicable_rate)) {
          this.flagValidate = false;
          this.errors.exchange_rate[index].new_exchange_rate = this.$t('E-CM-004');
          if (curErrorRateIndex == -1) curErrorRateIndex = index;
        } else {
          const newExchangeFloat = parseFloat(item.applicable_rate);
          const newExchangeString = String(item.applicable_rate);
          if (!this.isNumeric(newExchangeFloat)) {
            this.flagValidate = false;
            this.errors.exchange_rate[index].new_exchange_rate = this.$t('E-CM-047', {
              item: this.$t('settings.exchange_rate'),
            });
            if (curErrorRateIndex == -1) curErrorRateIndex = index;
          }
          if (newExchangeString.length > 11) {
            this.flagValidate = false;
            this.errors.exchange_rate[index].new_exchange_rate = this.$t('E-CU-013');
            if (curErrorRateIndex == -1) curErrorRateIndex = index;
          }
        }
      });

      //Auto scroll when invalid fields existed
      if (!this.flagValidate) {
        this.scrollIntoDivCustom(`setting_rate_${curErrorRateIndex}`);
        return;
      }

      this.$emit('onChangeData', false);
      if (!this.checkEmpty(this.curExchangeRateList)) {
        this.curExchangeRateList.forEach(item => {
          item.applying_rate = parseFloat(String(item.applying_rate).replaceAll(',', ''));
          item.applicable_rate = parseFloat(String(item.applicable_rate).replaceAll(',', ''));
        });
      }
      const params = {
        is_show_user_id: this.isShowUserId,
        is_oversea_insurance_required: this.isOverseaInsuranceRequired,
        applicable_date: this.applicableDate,
        is_exchange_rate_overwrite: this.isExchangeRateOverwrite,
        is_two_step_verification: this.isTwoAuthenticated,
        is_send_multi_email: this.isSendMultiEmail,
        exchange_rates: this.curExchangeRateList,
        updated_at: this.allSettings.updated_at,
        idp_entity_id: this.ssoSetting.idp_entity_id,
        idp_sso_url: this.ssoSetting.idp_sso_url,
        idp_sp_url: this.ssoSetting.idp_sp_url,
        idp_x509_certificate: this.ssoSetting.idp_x509_certificate,
      };
      this.startLoading();
      //CU-193
      this.$services.settings.updateSetting(
        params,
        () => {
          this.endLoading();
          this.notifySuccess(this.$t('I-CM-103'));
          this.getAllSettings();
        },
        err => {
          this.endLoading();
          this.notifyError(this.$t(err.error), true);
        },
      );
    },

    checkDuplicatedField(value, name, currencyId) {
      let matchedArr = [];
      this.curExchangeRateList.forEach((item, index) => {
        if (item[`${name}`] === value && item.currency === currencyId) matchedArr.push(index);
      });
      if (matchedArr.length >= 2) {
        matchedArr.forEach(item => {
          this.listDuplicateFields.push(item);
        });
      }

      if (matchedArr.length >= 2) {
        this.flagHaveDuplicated = true;
        this.flagValidate = false;
        this.$refs.rate_table_footer.scrollIntoView({
          behavior: 'smooth',
        });
        return true;
      } else {
        this.flagHaveDuplicated = false;
        this.errors.have_duplicated_fields = '';
        return false;
      }
    },

    //CU-050
    async getCurrencyList() {
      await this.$services.common.getCurrencyList(
        {},
        res => {
          const data = res.list.filter(item => item.code != 'JPY');
          this.currencyList = data;
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },

    //CU-192
    async getAllSettings() {
      await this.$services.settings.getAllSettings(
        res => {
          this.allSettings = res;
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },

    //CU-218
    getExchangeRateCsvFile(hasLoading = true) {
      !hasLoading && this.startLoading(),
        this.$services.settings.getExchangeRateCsvFile(
          { is_sample: false },
          res => {
            this.endLoading();
            this.downloadCSV(res);
          },
          err => {
            this.endLoading();
            this.notifyError(this.$t(err.error));
          },
        );
    },

    //CU-218
    getExchangeRateCsvExampleFile(hasLoading = true) {
      !hasLoading && this.startLoading(),
        this.$services.settings.getExchangeRateCsvFile(
          { is_sample: true },
          res => {
            this.endLoading();
            this.downloadCSV(res);
          },
          err => {
            this.endLoading();
            this.notifyError(this.$t(err.error));
          },
        );
    },

    //CU-256
    getSettingsFromCsv(csvSetting) {
      this.startLoading();
      let formData = new FormData();
      formData.append('file', csvSetting);
      this.errorValidate = [];
      this.$services.settings.getSettingsFromCsv(
        formData,
        res => {
          this.endLoading();
          if (!this.checkEmpty(res.list)) {
            // res.list.forEach(item => {
            //   this.curExchangeRateList.push({
            //     currency: item.currency_id,
            //     change_reservation: this.showDateTime(item.change_reservation_date, 'YYYY/MM/DD'),
            //     applying_rate: item.applying_rate,
            //     applicable_rate: item.change_rate,
            //   });
            // });
            this.$emit('onChangeData', true);
            this.showUploadFile = false;
            this.getAllSettings();
          }
        },
        err => {
          this.endLoading();
          if (err.code == 422) {
            this.errorValidate = err.error;
          } else {
            this.notifyError(this.$t(err.error));
          }
        },
      );
    },
  },
};
</script>
<style scoped>
.text_type {
  padding-left: 3rem !important;
}
.text_type_radio {
  padding-left: 3.2rem !important;
}
.checkbox-radio-div {
  margin-left: 2rem !important;
}
.table-exchange-rate td {
  vertical-align: top;
  line-height: 2.2;
}
.error-feedback {
  padding: 5px 0;
}
.table-exchange {
  padding-bottom: 0px !important;
}
.mb-70p {
  margin-bottom: 70px;
}
.currency-col {
  width: 500px !important;
}
</style>
