import { createWebHistory, createRouter } from 'vue-router';

import Dashboard from './pages/Dashboard.vue';
import Login from './pages/Login.vue';
import Trip from './pages/Trip.vue';
import TripItem from './pages/TripItem.vue';
import Information from './pages/Information/index.vue';
import Expense from './pages/Expense/index.vue';
import ExpenseItem from './pages/Expense/Item/list.vue';
import ExpenseItemEdit from './pages/Expense/Item/edit.vue';
import ExpenseItemNew from './pages/Expense/Item/new.vue';
import Application from './pages/Application.vue';
import NewTrip from './pages/NewTrip.vue';
import UpdateTrip from './pages/UpdateTrip.vue';
import RegulationCheck from './pages/RegulationCheck.vue';
import RegulationCheckDetail from './pages/RegulationCheck/_id/Detail.vue';
import Security from './pages/Security/index.vue';
import NewSecurity from './pages/Security/New.vue';
import UpdatePassword from './pages/UpdatePassword.vue';
import Cookies from 'js-cookie';
import PAGES from './utils/pages.js';
import InformationDetail from './pages/Information/_id/Detail.vue';
import Zone from './pages/DailyAllowance/Zone/index.vue';
import ZoneDetail from './pages/DailyAllowance/Zone/_id/Detail.vue';
import SecurityDetail from './pages/Security/_id/Detail.vue';
import Organization from './pages/Organization/index.vue';
import OrganizationDetail from './pages/Organization/_id/Detail.vue';
import Employee from './pages/Employee/index.vue';
import Position from './pages/Position/index.vue';
import DailyAllowance from './pages/DailyAllowance/DailyAllowance/index.vue';
import Settings from './pages/Settings.vue';
import Regulation from './pages/Regulation/index.vue';
import NewEmployee from './pages/Employee/NewEmployee.vue';
import EmployeeDetail from './pages/Employee/_id/Detail.vue';
import Budget from './pages/Budget.vue';
import Input from './pages/Input/index.vue';
import Requirement from './pages/Requirement/index.vue';
import RequirementDetail from './pages/Requirement/_id/Detail.vue';
import Info from './pages/Info/index.vue';
import SettingCIQ from './pages/SettingCIQ/index.vue';
import SettingCIQDetail from './pages/SettingCIQ/_id/Detail.vue';
import ApprovalFlow from './pages/ApprovalFlow/index.vue';
import InputDetail from './pages/Input/Detail/index.vue';
import InputPreview from './pages/Input/Preview/index.vue';
import CustomerEnterprise from './pages/CustomerEnterprise/index.vue';
import CustomerEnterpriseDetail from './pages/CustomerEnterprise/_id/Detail.vue';
import CustomerEnterpriseCreate from './pages/CustomerEnterprise/new.vue';
import Notification from './pages/Notification/index.vue';
import NotificationDetail from './pages/Notification/_id/Detail.vue';
import NewApprovalFlow from './pages/ApprovalFlow/New.vue';
import ApprovalFlowDetail from './pages/ApprovalFlow/_id/Detail.vue';
import NotFound from './pages/NotFound.vue';
import Unauthorized from './pages/Unauthorized.vue';
import User from './pages/User/index.vue';
import UserNew from './pages/User/New.vue';
import UserDetail from './pages/User/_id/Detail.vue';
import RegulationArea from './pages/Regulation/Area/index.vue';
import RegulationAreaDetail from './pages/Regulation/Area/_id/Detail.vue';
import Profile from './pages/Profile.vue';
import SsoAuth from "./pages/SsoAuth.vue";

const routes = [
  {
    path: PAGES.SSO_AUTH,
    component: SsoAuth,
    meta: {
      middleware: [
        'employee_using_app',
        'employee_no_authority',
        'employee_approval',
        'employee_manager',
        'employee_approval_management',
        'admin',
        'operator',
        'employee_manager_reference',
      ],
    },
  },
  {
    path: PAGES.LOGIN,
    component: Login,
    meta: {
      middleware: [
        'employee_using_app',
        'employee_no_authority',
        'employee_approval',
        'employee_manager',
        'employee_approval_management',
        'admin',
        'operator',
        'employee_manager_reference',
      ],
    },
  },
  {
    name: PAGES.UPDATE_PASSWORD,
    path: PAGES.UPDATE_PASSWORD,
    component: UpdatePassword,
    meta: {
      middleware: [
        'employee_using_app',
        'employee_no_authority',
        'employee_approval',
        'employee_manager',
        'employee_approval_management',
        'admin',
        'operator',
        'employee_manager_reference',
      ],
    },
  },
  {
    path: PAGES.HOME,
    component: Dashboard,
    meta: {
      middleware: [
        'authentication',
        'employee_using_app',
        'employee_no_authority',
        'employee_approval',
        'employee_manager',
        'employee_approval_management',
        'admin',
        'operator',
        'employee_manager_reference',
      ],
    },
  },
  {
    path: PAGES.DASHBOARD,
    component: Dashboard,
    meta: {
      middleware: [
        'authentication',
        'employee_using_app',
        'employee_no_authority',
        'employee_approval',
        'employee_manager',
        'employee_approval_management',
        'admin',
        'operator',
        'employee_manager_reference',
      ],
    },
  },
  {
    path: PAGES.TRIP,
    component: Trip,
    meta: {
      middleware: [
        'authentication',
        'employee_no_authority',
        'employee_approval',
        'employee_manager',
        'employee_approval_management',
        'operator',
        'admin',
        'employee_manager_reference',
      ],
    },
  },
  {
    path: PAGES.TRIP_ITEM,
    name: PAGES.TRIP_ITEM,
    component: TripItem,
    meta: {
      middleware: [
        'authentication',
        'employee_no_authority',
        'employee_approval',
        'employee_manager',
        'employee_approval_management',
        'operator',
        'admin',
        'employee_manager_reference',
      ],
    },
  },
  {
    path: PAGES.TRIP_NEW,
    component: NewTrip,
    meta: {
      middleware: [
        'authentication',
        'employee_no_authority',
        'employee_approval',
        'employee_manager',
        'employee_approval_management',
        'employee_manager_reference',
      ],
    },
  },
  {
    path: PAGES.TRIP_UPDATE,
    name: PAGES.TRIP_UPDATE,
    component: UpdateTrip,
    meta: {
      middleware: [
        'authentication',
        'employee_no_authority',
        'employee_approval',
        'employee_manager',
        'employee_approval_management',
        'operator',
        'admin',
        'employee_manager_reference',
      ],
    },
  },
  {
    path: PAGES.APPLICATION,
    component: Application,
    meta: {
      middleware: [
        'authentication',
        'employee_no_authority',
        'employee_approval',
        'employee_manager',
        'employee_approval_management',
        'operator',
        'employee_manager_reference',
      ],
    },
  },
  {
    path: PAGES.EXPENSE,
    component: Expense,
    meta: {
      middleware: [
        'authentication',
        'employee_no_authority',
        'employee_approval',
        'employee_manager',
        'employee_approval_management',
        'operator',
        'admin',
        'employee_manager_reference',
      ],
    },
  },
  {
    path: PAGES.EXPENSE_ITEM,
    component: ExpenseItem,
    meta: {
      middleware: ['authentication', 'employee_manager', 'employee_approval_management'],
    },
  },
  {
    name: PAGES.EXPENSE_ITEM_EDIT,
    path: PAGES.EXPENSE_ITEM_EDIT,
    component: ExpenseItemEdit,
    meta: {
      middleware: ['authentication', 'employee_manager', 'employee_approval_management'],
    },
  },
  {
    path: PAGES.EXPENSE_ITEM_CREATE,
    component: ExpenseItemNew,
    meta: {
      middleware: ['authentication', 'employee_manager', 'employee_approval_management'],
    },
  },
  {
    path: PAGES.REGULATION_CHECK,
    component: RegulationCheck,
    meta: {
      middleware: ['authentication', 'admin', 'operator'],
    },
  },
  {
    path: PAGES.REGULATION_CHECK_DETAIL,
    name: PAGES.REGULATION_CHECK_DETAIL,
    component: RegulationCheckDetail,
    meta: {
      middleware: ['authentication', 'admin', 'operator'],
    },
  },
  {
    path: PAGES.INFORMATION,
    component: Information,
    meta: {
      middleware: [
        'authentication',
        'employee_no_authority',
        'employee_approval',
        'employee_manager',
        'employee_approval_management',
        'employee_manager_reference',
      ],
    },
  },
  {
    path: PAGES.INFORMATION_DETAIL,
    name: PAGES.INFORMATION_DETAIL,
    component: InformationDetail,
    meta: {
      middleware: [
        'authentication',
        'employee_no_authority',
        'employee_approval',
        'employee_manager',
        'employee_approval_management',
        'employee_manager_reference',
      ],
    },
  },
  {
    path: PAGES.CRISIS_MANAGEMENT,
    component: Security,
    meta: {
      middleware: [
        'authentication', 
        'employee_manager', 
        'admin', 
        'operator',
        'employee_manager_reference',
      ],
    },
  },
  {
    path: PAGES.CRISIS_MANAGEMENT_CREATE,
    component: NewSecurity,
    meta: {
      middleware: [
        'authentication', 
        'employee_manager', 
        'operator',
        'employee_manager_reference',
      ],
    },
  },
  {
    path: PAGES.CRISIS_MANAGEMENT_DETAIL,
    name: PAGES.CRISIS_MANAGEMENT_DETAIL,
    component: SecurityDetail,
    meta: {
      middleware: [
        'authentication', 
        'employee_manager', 
        'admin', 
        'operator',
        'employee_manager_reference',
      ],
    },
  },
  {
    path: PAGES.ZONE,
    component: Zone,
    meta: {
      middleware: ['authentication', 'employee_manager', 'employee_approval_management'],
    },
  },
  {
    path: PAGES.ZONE_DETAIL,
    name: PAGES.ZONE_DETAIL,
    component: ZoneDetail,
    meta: {
      middleware: ['authentication', 'employee_manager', 'employee_approval_management'],
    },
  },
  {
    path: PAGES.ZONE_NEW,
    name: PAGES.ZONE_NEW,
    component: ZoneDetail,
    meta: {
      middleware: ['authentication', 'employee_manager', 'employee_approval_management'],
    },
  },
  {
    path: PAGES.EMPLOYEE,
    name: PAGES.EMPLOYEE,
    component: Employee,
    meta: {
      middleware: ['authentication', 'employee_manager', 'operator'],
    },
  },
  {
    path: PAGES.EMPLOYEE_NEW,
    name: PAGES.EMPLOYEE_NEW,
    component: NewEmployee,
    meta: {
      middleware: ['authentication', 'employee_manager', 'operator'],
    },
  },
  {
    path: PAGES.EMPLOYEE_DETAIL,
    name: PAGES.EMPLOYEE_DETAIL,
    component: EmployeeDetail,
    meta: {
      middleware: ['authentication', 'employee_manager', 'operator'],
    },
  },
  {
    path: PAGES.PROFILE,
    name: PAGES.PROFILE,
    component: Profile,
    meta: {
      middleware: [
        'authentication',
        'employee_using_app',
        'employee_no_authority',
        'employee_approval',
        'employee_approval_management',
        'employee_manager_reference',
      ],
    },
  },
  {
    path: PAGES.ORGANIZATION,
    name: PAGES.ORGANIZATION,
    component: Organization,
    meta: {
      middleware: ['authentication', 'employee_manager', 'operator'],
    },
  },
  {
    path: PAGES.ORGANIZATION_CREATE,
    name: PAGES.ORGANIZATION_CREATE,
    component: OrganizationDetail,
    meta: {
      middleware: ['authentication', 'employee_manager', 'operator'],
    },
  },
  {
    path: PAGES.ORGANIZATION_DETAIL,
    name: PAGES.ORGANIZATION_DETAIL,
    component: OrganizationDetail,
    meta: {
      middleware: ['authentication', 'employee_manager', 'operator'],
    },
  },
  {
    path: PAGES.INPUT,
    name: PAGES.INPUT,
    component: Input,
    meta: {
      middleware: ['authentication', 'admin', 'operator'],
    },
  },
  {
    path: PAGES.INPUT_DETAIL,
    name: PAGES.INPUT_DETAIL,
    component: InputDetail,
    meta: {
      middleware: ['authentication', 'operator', 'admin'],
    },
  },
  {
    path: PAGES.INPUT_PREVIEW,
    name: PAGES.INPUT_PREVIEW,
    component: InputPreview,
    meta: {
      middleware: ['authentication', 'operator', 'admin'],
    },
  },
  {
    path: PAGES.REQUIREMENT,
    name: PAGES.REQUIREMENT,
    component: Requirement,
    meta: {
      middleware: ['authentication', 'admin', 'operator'],
    },
  },
  {
    path: PAGES.INFO,
    name: PAGES.INFO,
    component: Info,
    meta: {
      middleware: ['authentication', 'admin', 'operator'],
    },
  },
  {
    path: PAGES.POSITION,
    name: PAGES.POSITION,
    component: Position,
    meta: {
      middleware: ['authentication', 'employee_manager', 'operator'],
    },
  },
  {
    path: PAGES.DAILY_ALLOWANCE,
    name: PAGES.DAILY_ALLOWANCE,
    component: DailyAllowance,
    meta: {
      middleware: ['authentication', 'employee_manager', 'employee_approval_management', 'operator'],
    },
  },
  {
    path: PAGES.REQUIREMENT,
    name: PAGES.REQUIREMENT,
    component: Requirement,
    meta: {
      middleware: ['authentication', 'admin', 'operator'],
    },
  },
  {
    path: PAGES.REQUIREMENT_DETAIL,
    name: PAGES.REQUIREMENT_DETAIL,
    component: RequirementDetail,
    meta: {
      middleware: ['authentication', 'admin', 'operator'],
    },
  },
  {
    path: PAGES.APPROVAL_FLOW,
    name: PAGES.APPROVAL_FLOW,
    component: ApprovalFlow,
    meta: {
      middleware: ['authentication', 'employee_manager'],
    },
  },
  {
    path: PAGES.NOTIFICATION,
    name: PAGES.NOTIFICATION,
    component: Notification,
    meta: {
      middleware: ['authentication', 'admin', 'operator'],
    },
  },
  {
    path: PAGES.APPROVAL_NEW,
    name: PAGES.APPROVAL_NEW,
    component: NewApprovalFlow,
    meta: {
      middleware: ['authentication', 'employee_manager'],
    },
  },
  {
    path: PAGES.NOTIFICATION_DETAIL,
    name: PAGES.NOTIFICATION_DETAIL,
    component: NotificationDetail,
    meta: {
      middleware: ['authentication', 'admin', 'operator'],
    },
  },
  {
    path: PAGES.NOTIFICATION_CREATE,
    name: PAGES.NOTIFICATION_CREATE,
    component: NotificationDetail,
    meta: {
      middleware: ['authentication', 'admin', 'operator'],
    },
  },
  {
    path: PAGES.APPROVAL_DETAIL,
    name: PAGES.APPROVAL_DETAIL,
    component: ApprovalFlowDetail,
    meta: {
      middleware: ['authentication', 'employee_manager', 'operator'],
    },
  },
  {
    path: PAGES.SETTINGS,
    name: PAGES.SETTINGS,
    component: Settings,
    meta: {
      middleware: ['authentication', 'employee_manager', 'employee_approval_management'],
    },
  },
  {
    path: PAGES.SETTINGS_CIQ,
    name: PAGES.SETTINGS_CIQ,
    component: SettingCIQ,
    meta: {
      middleware: ['authentication', 'operator', 'admin'],
    },
  },
  {
    path: PAGES.SETTINGS_CIQ_DETAIL,
    name: PAGES.SETTINGS_CIQ_DETAIL,
    component: SettingCIQDetail,
    meta: {
      middleware: ['authentication', 'operator', 'admin'],
    },
  },
  {
    path: PAGES.BUDGET,
    name: PAGES.BUDGET,
    component: Budget,
    meta: {
      middleware: ['authentication', 'employee_manager', 'employee_approval_management', 'operator'],
    },
  },
  {
    path: PAGES.CUSTOMER_ENTERPRISE,
    name: PAGES.CUSTOMER_ENTERPRISE,
    component: CustomerEnterprise,
    meta: {
      middleware: ['authentication', 'operator', 'admin'],
    },
  },
  {
    path: PAGES.CUSTOMER_ENTERPRISE_DETAIL,
    name: PAGES.CUSTOMER_ENTERPRISE_DETAIL,
    component: CustomerEnterpriseDetail,
    meta: {
      middleware: ['authentication', 'operator', 'admin'],
    },
  },
  {
    path: PAGES.CUSTOMER_ENTERPRISE_NEW,
    name: PAGES.CUSTOMER_ENTERPRISE_NEW,
    component: CustomerEnterpriseCreate,
    meta: {
      middleware: ['authentication', 'operator', 'admin'],
    },
  },
  {
    path: PAGES.USER,
    name: PAGES.USER,
    component: User,
    meta: {
      middleware: ['authentication', 'admin'],
    },
  },
  {
    path: PAGES.USER_NEW,
    name: PAGES.USER_NEW,
    component: UserNew,
    meta: {
      middleware: ['authentication', 'admin'],
    },
  },
  {
    path: PAGES.USER_DETAIL,
    name: PAGES.USER_DETAIL,
    component: UserDetail,
    meta: {
      middleware: ['authentication', 'operator', 'admin'],
    },
  },
  {
    path: PAGES.REGULATION,
    name: PAGES.REGULATION,
    component: Regulation,
    meta: {
      middleware: ['authentication', 'employee_manager', 'operator'],
    },
  },
  {
    path: PAGES.REGULATION_AREA,
    component: RegulationArea,
    meta: {
      middleware: ['authentication', 'employee_manager', 'operator'],
    },
  },
  {
    path: PAGES.REGULATION_AREA_DETAIL,
    name: PAGES.REGULATION_AREA_DETAIL,
    component: RegulationAreaDetail,
    meta: {
      middleware: ['authentication', 'employee_manager', 'operator'],
    },
  },
  {
    path: PAGES.REGULATION_AREA_NEW,
    name: PAGES.REGULATION_AREA_NEW,
    component: RegulationAreaDetail,
    meta: {
      middleware: ['authentication', 'employee_manager', 'operator'],
    },
  },
  {
    path: PAGES.UNAUTHORIZED,
    component: Unauthorized,
    meta: {
      middleware: [
        'authentication',
        'employee_using_app',
        'employee_no_authority',
        'employee_approval',
        'employee_manager',
        'employee_approval_management',
        'operator',
        'admin',
        'employee_manager_reference',
      ],
      layout: 'warning',
    },
  },
  {
    path: '/:catchAll(.*)',
    component: NotFound,
    meta: {
      middleware: [
        'authentication',
        'employee_using_app',
        'employee_no_authority',
        'employee_approval',
        'employee_manager',
        'employee_approval_management',
        'operator',
        'admin',
        'employee_manager_reference',
      ],
      layout: 'warning',
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // auto scroll to top when route changed
  window.scrollTo(0, 0);

  const token = Cookies.get('access_token');
  const role = Cookies.get('role');

  const { middleware } = to.meta;
  Cookies.set('redirectPathClone', to.path, {
    expires: 90,
  });
  // not logged in so redirect to login page with the return url
  if (middleware.includes('authentication') && !token) {
    Cookies.set('redirectPath', to.path, {
      expires: 90,
    });
    return next(PAGES.LOGIN);
  }

  // check if route is restricted by role
  if (role && !middleware.includes(role)) {
    // role not authorized so redirect to home page
    return next(PAGES.UNAUTHORIZED);
  }

  next();
});

export default router;
