<template>
  <div>
    <div class="section noborder">
      <div class="row">
        <div class="col-sm-3 col-md-2">
          <div class="form-group viewonly hide--new show--edit show--view">
            <label>
              <span class="label-text">{{ $t('customer.code') }}</span>
            </label>
            <p class="viewonly-text input-sm" id="people--code__view">{{ user?.employee_number }}</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 col-md-4">
          <div class="form-group viewonly show--view hide--edit hide--new">
            <label>
              <span class="label-text">{{ $t('customer.full_name') }}</span>
            </label>
            <p class="viewonly-text input-sm" id="people--name__view">{{ user?.name }}</p>
          </div>
        </div>
        <div class="col-sm-6 col-md-4">
          <div class="form-group show--edit show--new hide--view hide--edit-personal">
            <label>
              <span class="label-text">{{ $t('employee.authority') }}</span>
            </label>
            <el-select
              v-model="roleId"
              name="input"
              popper-class="custom-dropdown-select"
              class="select-block select-sm"
            >
              <el-option v-for="item in listRole" :key="item.id" :label="this.labelLocale(item)" :value="item.id">
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 col-md-4">
          <div class="form-group viewonly show--view hide--edit hide--new">
            <label>
              <span class="label-text">{{ $t('customer.position') }}</span>
            </label>
            <p class="viewonly-text input-sm" id="people--jobtitle__view">{{ user.department?.management_title }}</p>
          </div>
        </div>
        <div class="col-sm-6 col-md-4">
          <div class="form-group viewonly show--view hide--edit hide--new">
            <label>
              <span class="label-text">{{ $t('customer.billing_organization') }}</span>
            </label>
            <p class="viewonly-text input-sm" id="people--dept__view">{{ user.organization?.name }}</p>
          </div>
        </div>
        <div class="col-sm-6 col-md-4">
          <div class="form-group viewonly show--view hide--edit hide--new">
            <label>
              <span class="label-text">{{ $t('customer.work_location') }}</span>
            </label>
            <p class="viewonly-text input-sm" id="people--workplace__view">
              {{ companyAddress() }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="section">
      <h4 class="section-title">{{ $t('customer.passport') }}</h4>
      <div v-for="(item, index) in user.passports" :key="index">
        <div class="row">
          <div class="col-sm-6 col-md-4">
            <div class="form-group viewonly hide--edit hide--new show--view">
              <label>
                <span class="label-text">{{ $t('customer.passport_number') }}</span>
              </label>
              <p class="viewonly-text input-sm" id="people--passport-num__view">
                {{ item?.passport_number }}
              </p>
            </div>
          </div>
          <div class="show--view hide--edit hide--new">
            <div class="col-sm-6 col-md-4">
              <div class="form-group viewonly">
                <label>
                  <span class="label-text">{{ $t('customer.date_of_issue') }}</span>
                </label>
                <p class="viewonly-text input-sm" id="people--passport-date-issue__view">
                  {{ showDateTime(item?.issue_date, 'YYYY/MM/DD') }}
                </p>
              </div>
            </div>
            <div class="col-sm-6 col-md-4">
              <div class="form-group viewonly">
                <label>
                  <span class="label-text">{{ $t('customer.date_of_expired') }}</span>
                </label>
                <p class="viewonly-text input-sm" id="people--passport-date-expiry__view">
                  {{ showDateTime(item?.expired_date, 'YYYY/MM/DD') }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="show--view hide--edit hide--new">
            <div class="col-sm-6 col-md-8">
              <div class="form-group viewonly">
                <label>
                  <span class="label-text">{{ $t('customer.roman_name') }}</span>
                </label>
                <p class="viewonly-text input-sm" id="people--passport-name__view">
                  {{ getFullName(item) }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="show--view hide--edit hide--new">
            <div class="col-sm-6 col-md-3">
              <div class="form-group viewonly">
                <label>
                  <span class="label-text">{{ $t('customer.sex') }}</span>
                </label>
                <p class="viewonly-text input-sm" id="people--sex__view">{{ getSexType(parseInt(item?.sex)) }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 col-md-4">
            <div class="form-group viewonly show--view hide--edit hide--new">
              <label>
                <span class="label-text">{{ $t('customer.country_of_city') }}</span>
              </label>
              <p class="viewonly-text input-sm" id="people--nationality__view">
                {{ getNameCountry(parseInt(item?.country_id)) }}
              </p>
            </div>
          </div>
          <div class="col-sm-6 col-md-4 show--view hide--edit hide--new">
            <div class="form-group viewonly">
              <label>
                <span class="label-text">{{ $t('customer.date_of_birth') }}</span>
              </label>
              <p class="viewonly-text input-sm">
                {{ showDateTime(item?.bird_date, 'YYYY/MM/DD') }} {{ getAgeNow(item) }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <p v-if="checkEmpty(user.passports)" class="no-data">{{ $t('common.no_data') }}</p>
    </div>

    <div class="section">
      <h4 class="section-title">{{ $t('customer.visa') }}</h4>
      <div v-for="(item, index) in user.visas" :key="index">
        <div class="row">
          <div class="col-sm-6 col-md-4">
            <div class="form-group viewonly hide--edit hide--new show--view">
              <label>
                <span class="label-text">{{ $t('customer.visa_type') }}</span>
              </label>
              <p class="viewonly-text input-sm" id="people--passport-num__view">
                {{ labelLocale(item?.type) }}
              </p>
            </div>
          </div>
          <div class="show--view hide--edit hide--new">
            <div class="col-sm-6 col-md-4">
              <div class="form-group viewonly">
                <label>
                  <span class="label-text">{{ $t('customer.place_of_use') }}</span>
                </label>
                <p class="viewonly-text input-sm" id="people--passport-date-issue__view">
                  {{ item?.place_of_use }}
                </p>
              </div>
            </div>
            <div class="col-sm-6 col-md-4">
              <div class="form-group viewonly">
                <label>
                  <span class="label-text">{{ $t('customer.visa_number') }}</span>
                </label>
                <p class="viewonly-text input-sm" id="people--passport-date-expiry__view">
                  {{ item?.visa_number }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 col-md-4">
            <div class="form-group viewonly hide--edit hide--new show--view">
              <label>
                <span class="label-text">{{ $t('customer.purpose') }}</span>
              </label>
              <p class="viewonly-text input-sm" id="people--passport-num__view">{{ item?.purpose }}</p>
            </div>
          </div>
          <div class="show--view hide--edit hide--new">
            <div class="col-sm-6 col-md-4">
              <div class="form-group viewonly">
                <label>
                  <span class="label-text">{{ $t('customer.issue_date') }}</span>
                </label>
                <p class="viewonly-text input-sm" id="people--passport-date-issue__view">
                  {{ showDateTime(item?.issue_date, 'YYYY/MM/DD') }}
                </p>
              </div>
            </div>
            <div class="col-sm-6 col-md-4">
              <div class="form-group viewonly">
                <label>
                  <span class="label-text">{{ $t('customer.expired_date') }}</span>
                </label>
                <p class="viewonly-text input-sm" id="people--passport-date-expiry__view">
                  {{ showDateTime(item?.expired_date, 'YYYY/MM/DD') }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <hr />
      </div>
      <p v-if="checkEmpty(user.visas)" class="no-data">{{ $t('common.no_data') }}</p>
    </div>

    <div class="section">
      <h4 class="section-title">{{ $t('customer.contact_address') }}</h4>
      <div class="row">
        <div class="hide--edit hide--new show--view">
          <div class="col-xs-12 col-sm-12 col-md-12">
            <div class="form-group viewonly">
              <label>
                <span class="label-text">{{ $t('customer.home_address') }}</span>
              </label>
              <p class="viewonly-text input-sm">
                {{ getHomeAddress() }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 col-md-4">
          <div class="form-group viewonly hide--edit hide--new show--view">
            <label>
              <span class="label-text">{{ $t('customer.emergency_contact') }}</span>
            </label>
            <p class="viewonly-text input-sm" id="people--personal-phone__view">
              {{ contact?.phone }}
            </p>
          </div>
        </div>
      </div>
    </div>


    <!-- SSO ID -->
    <div class="section mb-70p">
      <h4 class="section--title">{{ $t('customer.sso_id_setting') }}</h4>
      <div class="row">
        <div class="col-sm-6 col-md-4">
          <div class="form-group viewonly hide--new show--edit show--view">
            <label>
              <span class="label-text">{{ $t('customer.sso_id') }}</span>
            </label>
            <p class="viewonly-text input-sm" id="people--code__view">{{ user?.sso_id }}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Tabireji -->
    <div class="section">
      <h4 class="section-title">{{ $t('employee.tabi_reji') }}</h4>
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <div class="form-group">
            <label
                class="checkbox checkbox-inline checkbox-type-div checkbox-expense"
                :class="user?.use_tabireji && 'checked'"
            >
                  <span class="icons">
                    <span class="first-icon icon-abui-checkbox-unchecked"></span>
                    <span class="second-icon icon-abui-checkbox-checked"></span>
                  </span>
              <span class="text_type" style="padding-left: 3rem !important">
                    {{ $t('employee.use_tabireji') }}
                  </span>
            </label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <div class="form-group viewonly hide--new show--edit show--view">
            <label>
              <span class="label-text">{{ $t('employee.email_tabireji') }}</span>
            </label>
            <p class="viewonly-text input-sm" id="people--code__view">{{ user?.emails_tabireji }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="section">
      <h4 class="section-title">{{ $t('customer.user') }}</h4>
      <div class="row">
        <div class="col-sm-6 col-md-4 hide--edit hide--new show--view">
          <div class="form-group viewonly">
            <label>
              <span class="label-text">{{ $t('customer.user_id') }}</span>
            </label>
            <p class="viewonly-text input-sm" id="people--userid__view">{{ user.id }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="section">
      <div class="row">
        <div class="col-sm-3 col-md-2 hidden-xs">
          <a @click="$emit('back')" class="btn btn-sm btn-flat-default">
            <span class="icon-abui-arrow-left prx"></span>{{ $t('customer.back_to_list') }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { SEX_TYPE } from '@/constants/';
import moment from 'moment';
export default {
  name: 'PeopleDetail',
  emits: ['back'],
  components: {},
  props: {
    userId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      user: {},
      contact: {},
      listCountry: [],
      listRole: [],
      roleId: '',
    };
  },
  created() {
    this.startLoading();
    Promise.all([this.getUserInformation(), this.getListCountry(), this.getListRole()]).then(() => {
      this.endLoading();
    });
  },
  watch: {
    'user.authority': {
      handler(newVal) {
        if (!this.checkEmpty(newVal)) {
          this.roleId = newVal.id;
        }
      },
      deep: true,
    },
    roleId(newVal) {
      if (!this.checkEmpty(newVal) && !this.checkEmpty(this.user.authority) && this.roleId != this.user.authority.id)
        this.updateRoleOperator();
    },
  },
  methods: {
    async updateRoleOperator() {
      if (this.checkEmpty(this.user.id) || this.checkEmpty(this.roleId)) return;
      this.startLoading();
      let params = {
        user_id: this.user.id,
        role_id: this.roleId,
      };
      await this.$services.user.updateRoleOperator(
        params,
        () => {
          this.notifySuccess(this.$t('I-CM-103'));
          this.endLoading();
        },
        err => {
          this.endLoading();
          this.notifyError(this.$t(err.error));
        },
      );
    },
    async getListRole() {
      await this.$services.common.getListRole(
        res => {
          let listRoleRes = res.list.filter(item => item.id != 2 && item.id != 6 && item.id != 7); // Only company role and web role
          this.listRole = listRoleRes.sort(function(a, b) {
            return a.sort_order - b.sort_order;
          });
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    getFullName(item) {
      let first_name = !this.checkEmpty(item.first_name) ? item.first_name : '';
      let middle_name = !this.checkEmpty(item.middle_name) ? item.middle_name : '';
      let last_name = !this.checkEmpty(item.last_name) ? item.last_name : '';

      return first_name + ' ' + middle_name + ' ' + last_name;
    },
    companyAddress() {
      if (this.checkEmpty(this.user.company)) return '';

      let address = '';
      if (this.user?.company?.province) {
        address += this.labelLocale(this.user?.company?.province);
      }
      if (this.user?.company?.district) {
        address += this.user?.company?.district;
      }
      if (this.user?.company?.address) {
        address += this.user?.company?.address;
      }
      if (this.user?.company?.building) {
        address += this.user?.company?.building;
      }

      return address;
    },
    async getListCountry() {
      await this.$services.security.getListCountry(
        res => {
          this.listCountry = this.sortCountryByName(res.list);
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    getNameCountry(id) {
      let country = this.listCountry.find(ct => ct.id == id);
      return this.labelLocale(country);
    },
    async getUserInformation() {
      if (this.checkEmpty(this.userId)) return;

      await this.$services.customer_enterprise.getUserInformation(
        this.userId,
        res => {
          this.user = res;
          this.contact = res.contact_addresses.find(contact => contact.is_home == 1);
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    getSexType(type) {
      let sex = SEX_TYPE.find(item => item.type == type);
      return this.labelLocale(sex);
    },
    getAgeNow(item) {
      let currentDate = moment(new Date()).format('YYYY-MM-DD');
      return (
        '(' + this.diffDate(currentDate, item?.bird_date.substring(0, 10), 'years') + this.$t('customer.age') + ')'
      );
    },
    getHomeAddress() {
      let zipCode = !this.checkEmpty(this.contact?.zip_code)
        ? this.contact?.zip_code.toString() + this.$t('common.comma')
        : '';
      let province = !this.checkEmpty(this.contact?.province)
        ? this.contact?.province.toString() + this.$t('common.comma')
        : !this.checkEmpty(this.contact?.city)
        ? this.labelLocale(this.contact?.city).toString() + this.$t('common.comma')
        : '';
      let district = !this.checkEmpty(this.contact?.district) ? this.contact?.district.toString() : '';
      let street = !this.checkEmpty(this.contact?.street)
        ? this.contact?.street.toString() + this.$t('common.comma')
        : '';
      return zipCode + province + street + district;
    },
  },
};
</script>
