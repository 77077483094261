<template>
  <nav class="navbar navbar-default navbar-lg" role="navigation" id="header-content">
    <div class="navbar-header">
      <h2 class="navbar-title">{{ $t('sidebar.customer_enterprise') }}</h2>
    </div>
    <div class="collapse navbar-collapse">
      <ul class="nav navbar-nav navbar-right">
        <li>
          <a href="#" @click="goToCreate"> <span class="icon-abui-plus prx" />{{ $t('zone.add_new') }} </a>
        </li>
      </ul>
    </div>
  </nav>

  <div class="wrapper">
    <div class="container-fluid">
      <div class="filter-wrap">
        <div class="filter js-filter--minimization" v-show="showBoxFilter">
          <div class="section section-filter section-filter-special noborder js-section-filter">
            <div class="row">
              <div class="col-xs-12 col-sm-6 col-md-3">
                <div class="form-group">
                  <label>{{ $t('customer.company_id') }}</label>
                  <input
                    type="text"
                    class="form-control input-sm"
                    :placeholder="$t('customer.partial_match')"
                    v-model="searchParams.code"
                  />
                </div>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-6">
                <div class="form-group">
                  <label>{{ $t('customer.company_name') }}</label>
                  <input
                    type="text"
                    class="form-control input-sm"
                    :placeholder="$t('customer.partial_match')"
                    v-model="searchParams.name"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="section-submit js-section-submit" v-show="showBoxFilter">
          <span class="submit-arrow"></span>
          <div class="row">
            <div class="col-sm-4 col-md-5 hidden-xs">
              <button class="btn btn-sm btn-flat-default btn-clear" @click="clearFilter()">
                {{ $t('common.clear') }}
              </button>
            </div>
            <div class="col-sm-4 col-md-2">
              <button class="btn btn-sm btn-primary btn-block js-btn-filter--minimization" @click="handleSearch()">
                {{ $t('common.search') }}
              </button>
            </div>
            <div class="col-sm-4 col-md-5 hidden-xs">
              <button class="btn btn-sm btn-flat-default btn-close" @click="showBoxFilter = false">
                {{ $t('common.close') }}
              </button>
            </div>
          </div>
        </div>

        <div
          class="btn-filter-again with-condition"
          v-show="!showBoxFilter"
          @click="showBoxFilter = true"
          style="display: block"
        >
          <div class="filter--condition">
            <span>{{ $t('common.all') }}</span>
            <span style="color: #3650a5">{{ $t('common.reset_conditions') }}</span>
          </div>
        </div>

        <div class="filter-result js-filter-result--minimization displaynone" style="display: block">
          <div class="section noborder">
            <Pagination
              v-if="listData?.length > 0"
              :paginate="paginate"
              @change-page="changePageData"
              @change-number="changeNumber"
            />

            <div class="hidden-xs">
              <table class="table table-hover table--org-list table-hover--pointer" id="">
                <colgroup span="1" style="width: 12.5%" />
                <colgroup span="1" />
                <thead>
                  <tr>
                    <th class="">{{ $t('customer.company_id') }}</th>
                    <th class="">{{ $t('customer.company_name') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in listData" :key="index" @click="goToDetail(item)">
                    <td class="">{{ item.code }}</td>
                    <td class="">{{ item.name }}</td>
                  </tr>
                </tbody>
              </table>
              <p v-if="listData?.length == 0" class="no-data">{{ $t('common.no_data') }}</p>
            </div>

            <Pagination
              v-if="listData?.length > 0"
              @change-page="changePageData"
              :paginate="paginate"
              @change-number="changeNumber"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Pagination from '@/components/common/Pagination';
export default {
  name: 'CustomerEnterprise',
  components: { Pagination },
  data() {
    return {
      listData: [],
      paginate: {
        page: +this.$route.query.page || 1,
        per_page: +this.$route.query.per_page || 10,
      },
      searchParams: {
        name: this.$route.query.name || '',
        code: this.$route.query.code || '',
      },
      showBoxFilter: false,
    };
  },
  created() {
    this.getListCustomerEnterprise();
  },
  methods: {
    goToCreate() {
      this.$router.push(this.PAGES.CUSTOMER_ENTERPRISE_NEW);
    },
    changePageData(page) {
      this.paginate.page = +page;
      this.getListCustomerEnterprise();
    },
    handleSearch() {
      this.paginate.page = 1;
      this.getListCustomerEnterprise();
    },
    changeNumber(number) {
      this.paginate = {
        ...this.paginate,
        page: 1,
        per_page: number,
      };
      this.getListCustomerEnterprise();
    },
    clearFilter() {
      this.searchParams = {
        keyword: '',
        include_invalid: false,
      };
    },
    async getListCustomerEnterprise() {
      let dataSearch = {
        ...this.searchParams,
        page: this.paginate.page,
        per_page: this.paginate.per_page,
      };
      this.startLoading();
      await this.$services.customer_enterprise.getListCustomerEnterprise(
        dataSearch,
        res => {
          this.endLoading();
          this.listData = res.list;
          this.paginate = { ...this.paginate, ...res.paginate };
          this.showBoxFilter = false;

          this.$router.push({
            path: this.$route.path,
            query: {
              page: res.paginate.current_page,
              per_page: res.paginate.per_page,
              name: this.searchParams.name,
              code: this.searchParams.code,
            },
          });
        },
        err => {
          this.endLoading();
          this.notifyError(err.error);
        },
      );
    },
    goToDetail(item) {
      this.$router.push({
        name: this.PAGES.CUSTOMER_ENTERPRISE_DETAIL,
        params: {
          id: item.id,
        },
      });
    },
  },
};
</script>
