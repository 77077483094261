<template>
  <nav class="navbar navbar-default navbar-lg" role="navigation" id="header-content">
    <div class="navbar-header">
      <h2 class="navbar-title">{{ $t('sidebar.regulation_check') }}</h2>
    </div>
  </nav>

  <div class="wrapper with-navbar-fixed-top with-navbar-fixed-bottom">
    <div class="container-fluid">
      <div
        class="plan-status"
        :class="regulationDetail.is_confirmed && 'status-done-custom'"
        style="margin-top: -48px; margin-bottom: 40px"
      >
        <span class="plan--staff">{{ regulationDetail?.creator }}</span>
        <span style="margin-left: auto">
          <i :class="styleTripRegulationIcon(regulationDetail.status)" style="padding-right: 5px"></i>
          <span :class="styleTripRegulationText(regulationDetail.status)">
            {{ regulationTextStatus(regulationDetail.status) }}
          </span>
        </span>
        <span
          class="status-text"
          :class="regulationDetail.is_confirmed && 'color-status-done'"
          style="margin-left: 12px"
        >
          {{ regulationDetail.is_confirmed ? $t('regulation_check.confirmed') : $t('regulation_check.unconfirmed') }}
        </span>
      </div>

      <div class="section noborder ptn" id="section--main">
        <div class="">
          <div class="row">
            <div class="col-sm-12">
              <div class="row">
                <div class="col-sm-12">
                  <div class="passport-msg" v-if="!regulationDetail.is_passport_registered">
                    <span class="text-warning">
                      {{ $t('regulation_check.passport_is_not_registered') }}
                    </span>
                  </div>
                  <div class="totrip">
                    <router-link
                      v-if="!checkEmpty(regulationCheckId)"
                      :to="{
                        name: this.PAGES.TRIP_UPDATE,
                        params: {
                          id: regulationCheckId,
                        },
                      }"
                      target="_blank"
                      rel="noopener"
                    >
                      <a class="cursor-pointer">
                        <i class="icon-abui-globe prx"></i>
                        {{ regulationDetail?.trip_code }}：{{ regulationDetail?.trip_name }}
                      </a>
                    </router-link>
                  </div>
                  <p class="" id="">
                    {{ showDateTime(regulationDetail.trip_start_time, 'YYYY/MM/DD (ddd) ') }} -
                    {{ showDateTime(regulationDetail.trip_end_time, 'YYYY/MM/DD (ddd) ') }}
                  </p>
                  <div class="mbl">
                    <router-link
                      v-if="!checkEmpty(regulationDetail.company_id)"
                      :to="{
                        name: this.PAGES.CUSTOMER_ENTERPRISE_DETAIL,
                        params: {
                          id: regulationDetail.company_id || 0,
                        },
                      }"
                      target="_blank"
                      rel="noopener"
                    >
                      <a class="cursor-pointer">
                        <i class="icon-abui-company prx"></i>{{ regulationDetail.company_name }}
                      </a>
                    </router-link>
                  </div>
                </div>
              </div>
              <div class="mbm">
                <ul v-if="!checkEmpty(regulationDetail.files)" class="uploaded--file-list">
                  <li v-for="(file, index) in regulationDetail.files" :key="index">
                    <a :href="file.link" target="_blank" style="display: flex; align-items: center" rel="noopener">
                      <i class="icon-abui-file"></i>
                      <span class="file--name">{{ file.file_name }}</span>
                    </a>
                    <span class="file--date text-left">
                      {{ showDateTime(file.file_create_at, 'YYYY/MM/DD HH:mm') }}
                    </span>
                  </li>
                </ul>
              </div>

              <hr />

              <!-- business_trip_days -->
              <div class="regulation--item--label margin-top-7">
                <label>{{ $t('regulation_check.business_trip_all_day') }}</label>
              </div>
              <div v-if="!checkEmpty(regulationDetail.business_trip_days)">
                <div v-for="(item, index) in regulationDetail.business_trip_days" :key="index" class="margin-bottom-12">
                  <div class="regulation--item--inner">
                    <div class="regulation--item--applied" :class="backgroundColorRegulation(item.status)">
                      <div class="regulation--item--check-status">
                        <i :class="styleTripRegulationIcon(item.status)"></i>
                      </div>
                      <span v-html="item.data_registered"></span>
                    </div>
                    <div class="regulation--item--reffered" v-html="item.data_required"></div>
                  </div>
                  <!-- <div v-if="item.status !== TRIP_REGULATION_OK">
                    <div class="form-group">
                      <input
                        type="text"
                        v-model="regulationCheckComments.business_trip_days[index].comment"
                        class="form-control input-sm margin-comment-top-12"
                        :placeholder="$t('regulation_check.cost_comment_placeholder')"
                      />
                    </div>
                  </div> -->
                </div>
              </div>
              <div v-else>
                <div class="text-muted" style="font-size: 14px">
                  {{ $t('common.no_data') }}
                </div>
              </div>

              <!-- passport_number_of_days -->
              <div class="regulation--item--label margin-top-7">
                <label>{{ $t('regulation_check.passport_remaining_note') }}</label>
              </div>
              <div v-if="!checkEmpty(regulationDetail.passport_number_of_days)">
                <div
                  v-for="(item, index) in regulationDetail.passport_number_of_days"
                  :key="index"
                  class="margin-bottom-12"
                >
                  <div class="regulation--item--inner">
                    <div class="regulation--item--applied" :class="backgroundColorRegulation(item.status)">
                      <div class="regulation--item--check-status">
                        <i :class="styleTripRegulationIcon(item.status)"></i>
                      </div>
                      <span v-html="item.data_registered"></span>
                    </div>
                    <div class="regulation--item--reffered" v-html="item.data_required"></div>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="text-muted" style="font-size: 14px">
                  {{ $t('common.no_data') }}
                </div>
              </div>

              <hr />

              <h4>{{ $t('regulation_check.flight') }}</h4>

              <!-- transit_airport -->
              <div class="regulation--item--label margin-top-7">
                <label>{{ $t('regulation_check.transit_airport') }}</label>
              </div>
              <div v-if="!checkEmpty(regulationDetail.transit_airports)">
                <div v-for="(item, index) in regulationDetail.transit_airports" :key="index" class="margin-bottom-12">
                  <div class="regulation--item--inner">
                    <div class="regulation--item--applied" :class="backgroundColorRegulation(item.status)">
                      <div class="regulation--item--check-status">
                        <i :class="styleTripRegulationIcon(item.status)"></i>
                      </div>
                      <span v-html="item.data_registered"></span>
                    </div>
                    <div class="regulation--item--reffered" v-html="item.data_required"></div>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="text-muted" style="font-size: 14px">
                  {{ $t('common.no_data') }}
                </div>
              </div>

              <!-- Banned airlines -->
              <div class="regulation--item--label margin-top-7">
                <label>{{ $t('regulation_check.banned_airlines') }}</label>
              </div>
              <div v-if="!checkEmpty(regulationDetail.banned_airlines)">
                <div v-for="(item, index) in regulationDetail.banned_airlines" :key="index" class="margin-bottom-12">
                  <div class="regulation--item--inner">
                    <div class="regulation--item--applied" :class="backgroundColorRegulation(item.status)">
                      <div class="regulation--item--check-status">
                        <i :class="styleTripRegulationIcon(item.status)"></i>
                      </div>
                      <span v-html="item.data_registered"></span>
                    </div>
                    <div class="regulation--item--reffered" v-html="item.data_required"></div>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="text-muted" style="font-size: 14px">
                  {{ $t('common.no_data') }}
                </div>
              </div>

              <!-- Flight section -->
              <div class="regulation--item--label margin-top-7">
                <label>{{ $t('regulation_check.flight_section') }}</label>
              </div>
              <div v-if="!checkEmpty(regulationDetail.flight_sections)">
                <div v-for="(item, index) in regulationDetail.flight_sections" :key="index" class="margin-bottom-12">
                  <div class="regulation--item--inner">
                    <div class="regulation--item--applied" :class="backgroundColorRegulation(item.status)">
                      <div class="regulation--item--check-status">
                        <i :class="styleTripRegulationIcon(item.status)"></i>
                      </div>
                      <span v-html="item.data_registered"></span>
                    </div>
                    <div class="regulation--item--reffered" v-html="item.data_required"></div>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="text-muted" style="font-size: 14px">
                  {{ $t('common.no_data') }}
                </div>
              </div>

              <!-- Transit times -->
              <div class="regulation--item--label margin-top-7">
                <label>{{ $t('regulation_check.transit_time_each') }}</label>
              </div>
              <div v-if="!checkEmpty(regulationDetail.transit_times)">
                <div v-for="(item, index) in regulationDetail.transit_times" :key="index" class="margin-bottom-12">
                  <div class="regulation--item--inner">
                    <div class="regulation--item--applied" :class="backgroundColorRegulation(item.status)">
                      <div class="regulation--item--check-status">
                        <i :class="styleTripRegulationIcon(item.status)"></i>
                      </div>
                      <span v-html="item.data_registered"></span>
                    </div>
                    <div class="regulation--item--reffered" v-html="item.data_required"></div>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="text-muted" style="font-size: 14px">
                  {{ $t('common.no_data') }}
                </div>
              </div>

              <!-- number_of_connections -->
              <div class="regulation--item--label margin-top-7">
                <label>{{ $t('regulation_check.number_of_connections') }}</label>
              </div>
              <div v-if="!checkEmpty(regulationDetail.number_of_connections)">
                <div
                  v-for="(item, index) in regulationDetail.number_of_connections"
                  :key="index"
                  class="margin-bottom-12"
                >
                  <div class="regulation--item--inner">
                    <div class="regulation--item--applied" :class="backgroundColorRegulation(item.status)">
                      <div class="regulation--item--check-status">
                        <i :class="styleTripRegulationIcon(item.status)"></i>
                      </div>
                      <span v-html="item.data_registered"></span>
                    </div>
                    <div class="regulation--item--reffered" v-html="item.data_required"></div>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="text-muted" style="font-size: 14px">
                  {{ $t('common.no_data') }}
                </div>
              </div>

              <hr />

              <h4>{{ $t('regulation_check.seat_class') }}</h4>

              <!-- seat_classes -->
              <div class="regulation--item--label margin-top-7">
                <label>{{ $t('regulation_check.seat_class') }}</label>
              </div>
              <div v-if="!checkEmpty(regulationDetail.seat_classes)">
                <div v-for="(item, index) in regulationDetail.seat_classes" :key="index" class="margin-bottom-12">
                  <div class="regulation--item--inner">
                    <div class="regulation--item--applied" :class="backgroundColorRegulation(item.status)">
                      <div class="regulation--item--check-status">
                        <i :class="styleTripRegulationIcon(item.status)"></i>
                      </div>
                      <span v-html="item.data_registered"></span>
                    </div>
                    <div class="regulation--item--reffered" v-html="item.data_required"></div>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="text-muted" style="font-size: 14px">
                  {{ $t('common.no_data') }}
                </div>
              </div>

              <!-- air_ticket_costs -->
              <div class="regulation--item--label margin-top-7">
                <label>{{ $t('regulation_check.amount_of_money') }}</label>
              </div>
              <div v-if="!checkEmpty(regulationDetail.air_ticket_costs)">
                <div v-for="(item, index) in regulationDetail.air_ticket_costs" :key="index" class="margin-bottom-12">
                  <div class="regulation--item--inner">
                    <div class="regulation--item--applied" :class="backgroundColorRegulation(item.status)">
                      <div class="regulation--item--check-status">
                        <i :class="styleTripRegulationIcon(item.status)"></i>
                      </div>
                      <span v-html="item.data_registered"></span>
                    </div>
                    <div class="regulation--item--reffered" v-html="item.data_required"></div>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="text-muted" style="font-size: 14px">
                  {{ $t('common.no_data') }}
                </div>
              </div>

              <hr />

              <h4>{{ $t('regulation_check.hotel') }}</h4>

              <!-- hotel_costs -->
              <div class="regulation--item--label margin-top-7">
                <label>{{ $t('regulation_check.amount_of_money') }}</label>
              </div>
              <div v-if="!checkEmpty(regulationDetail.hotel_costs)">
                <div v-for="(item, index) in regulationDetail.hotel_costs" :key="index" class="margin-bottom-12">
                  <div class="regulation--item--inner">
                    <div class="regulation--item--applied" :class="backgroundColorRegulation(item.status)">
                      <div class="regulation--item--check-status">
                        <i :class="styleTripRegulationIcon(item.status)"></i>
                      </div>
                      <span v-html="item.data_registered"></span>
                    </div>
                    <div class="regulation--item--reffered" v-html="item.data_required"></div>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="text-muted" style="font-size: 14px">
                  {{ $t('common.no_data') }}
                </div>
              </div>

              <hr />

              <h4>{{ $t('regulation_check.total_travel_expenses') }}</h4>

              <!-- total_costs -->
              <div class="regulation--item--label margin-top-7">
                <label>{{ $t('regulation_check.amount_of_money') }}</label>
              </div>
              <div v-if="!checkEmpty(regulationDetail.total_costs)">
                <div v-for="(item, index) in regulationDetail.total_costs" :key="index" class="margin-bottom-12">
                  <div class="regulation--item--inner">
                    <div class="regulation--item--applied" :class="backgroundColorRegulation(item.status)">
                      <div class="regulation--item--check-status">
                        <i :class="styleTripRegulationIcon(item.status)"></i>
                      </div>
                      <span v-html="item.data_registered"></span>
                    </div>
                    <div class="regulation--item--reffered" v-html="item.data_required"></div>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="text-muted">
                  {{ $t('common.no_data') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="navbar navbar-default navbar-fixed-bottom navbar-footblock with-sidebar submit-new--wrap show--estimate"
      id=""
      role="navigation"
      style="z-index: 990; padding: 12px"
    >
      <div class="row">
        <div class="col-sm-4 col-md-3 col-sm-offset-8 col-md-offset-9">
          <button
            v-show="!regulationDetail.is_confirmed"
            @click="handleConfirm()"
            class="btn btn-sm btn-block btn-primary open-alert"
          >
            {{ $t('regulation_check.confirm') }}
          </button>
        </div>
      </div>
    </div>
    <ModalConfirm
      :dialogVisible="showBoxConfirm"
      @cancel="showBoxConfirm = false"
      @yes="confirmRegulationCheck()"
      :title="$t('regulation_check.passport_is_not_registered')"
    />
  </div>
</template>
<script>
import { TRIP_REGULATION_OK, TRIP_REGULATION_WARNING, TRIP_REGULATION_ERROR } from '@/constants';
import ModalConfirm from '@/components/common/ModalConfirm';

export default {
  name: 'RegulationCheckDetail',
  emits: [],
  components: { ModalConfirm },
  data() {
    return {
      regulationDetail: {},
      showBoxConfirm: false,
      TRIP_REGULATION_OK,
      TRIP_REGULATION_WARNING,
      TRIP_REGULATION_ERROR,
      regulationCheckComments: {
        business_trip_days: [],
        passport_number_of_days: [],
        transit_airports: [],
        flight_sections: [],
        transit_times: [],
        number_of_connections: [],
        seat_classes: [],
        air_ticket_costs: [],
        hotel_costs: [],
        total_costs: [],
        banned_airlines: [],
      },
      regulationCheckCommentsOfficial: {
        business_trip_days: [],
        passport_number_of_days: [],
        transit_airports: [],
        flight_sections: [],
        transit_times: [],
        number_of_connections: [],
        seat_classes: [],
        air_ticket_costs: [],
        hotel_costs: [],
        total_costs: [],
      },
    };
  },
  created() {
    this.startLoading();
    Promise.all([this.getRegulationCheckDetail()]).then(() => {
      this.endLoading();
    });
  },
  computed: {
    regulationCheckId() {
      return this.$route.params.id || '';
    },
  },
  watch: {
    regulationDetail: {
      handler(newVal) {
        if (!this.checkEmpty(newVal?.business_trip_days)) {
          newVal.business_trip_days.forEach((item, index) => {
            if (item.status !== TRIP_REGULATION_OK) {
              this.regulationCheckComments.business_trip_days[index] = {
                id: item?.id,
                comment: item?.comment || '',
              };
            }
          });
        }

        if (!this.checkEmpty(newVal?.passport_number_of_days)) {
          newVal.passport_number_of_days.forEach((item, index) => {
            if (item.status !== TRIP_REGULATION_OK) {
              this.regulationCheckComments.passport_number_of_days[index] = {
                id: item?.id,
                comment: item?.comment || '',
              };
            }
          });
        }

        if (!this.checkEmpty(newVal?.transit_airports)) {
          newVal.transit_airports.forEach((item, index) => {
            if (item.status !== TRIP_REGULATION_OK) {
              this.regulationCheckComments.transit_airports[index] = {
                id: item?.id,
                comment: item?.comment || '',
              };
            }
          });
        }

        if (!this.checkEmpty(newVal?.banned_airlines)) {
          newVal.banned_airlines.forEach((item, index) => {
            if (item.status !== TRIP_REGULATION_OK) {
              this.regulationCheckComments.banned_airlines[index] = {
                id: item?.id,
                comment: item?.comment || '',
              };
            }
          });
        }

        if (!this.checkEmpty(newVal?.flight_sections)) {
          newVal.flight_sections.forEach((item, index) => {
            if (item.status !== TRIP_REGULATION_OK) {
              this.regulationCheckComments.flight_sections[index] = {
                id: item?.id,
                comment: item?.comment || '',
              };
            }
          });
        }

        if (!this.checkEmpty(newVal?.transit_times)) {
          newVal.transit_times.forEach((item, index) => {
            if (item.status !== TRIP_REGULATION_OK) {
              this.regulationCheckComments.transit_times[index] = {
                id: item?.id,
                comment: item?.comment || '',
              };
            }
          });
        }

        if (!this.checkEmpty(newVal?.number_of_connections)) {
          newVal.number_of_connections.forEach((item, index) => {
            if (item.status !== TRIP_REGULATION_OK) {
              this.regulationCheckComments.number_of_connections[index] = {
                id: item?.id,
                comment: item?.comment || '',
              };
            }
          });
        }

        if (!this.checkEmpty(newVal?.seat_classes)) {
          newVal.seat_classes.forEach((item, index) => {
            if (item.status !== TRIP_REGULATION_OK) {
              this.regulationCheckComments.seat_classes[index] = {
                id: item?.id,
                comment: item?.comment || '',
              };
            }
          });
        }

        if (!this.checkEmpty(newVal?.air_ticket_costs)) {
          newVal.air_ticket_costs.forEach((item, index) => {
            if (item.status !== TRIP_REGULATION_OK) {
              this.regulationCheckComments.air_ticket_costs[index] = {
                id: item?.id,
                comment: item?.comment || '',
              };
            }
          });
        }

        if (!this.checkEmpty(newVal?.hotel_costs)) {
          newVal.hotel_costs.forEach((item, index) => {
            if (item.status !== TRIP_REGULATION_OK) {
              this.regulationCheckComments.hotel_costs[index] = {
                id: item?.id,
                comment: item?.comment || '',
              };
            }
          });
        }

        if (!this.checkEmpty(newVal?.total_costs)) {
          newVal.total_costs.forEach((item, index) => {
            if (item.status !== TRIP_REGULATION_OK) {
              this.regulationCheckComments.total_costs[index] = {
                id: item?.id,
                comment: item?.comment || '',
              };
            }
          });
        }

        const newData = this.cloneObject(newVal);
        if (!this.checkEmpty(newData?.business_trip_days)) {
          newData.business_trip_days.forEach((item, index) => {
            if (item.status !== TRIP_REGULATION_OK) {
              this.regulationCheckCommentsOfficial.business_trip_days[index] = {
                id: item?.id,
                comment: item?.comment || '',
              };
            }
          });
        }

        if (!this.checkEmpty(newData?.passport_number_of_days)) {
          newData.passport_number_of_days.forEach((item, index) => {
            if (item.status !== TRIP_REGULATION_OK) {
              this.regulationCheckCommentsOfficial.passport_number_of_days[index] = {
                id: item?.id,
                comment: item?.comment || '',
              };
            }
          });
        }

        if (!this.checkEmpty(newData?.transit_airports)) {
          newData.transit_airports.forEach((item, index) => {
            if (item.status !== TRIP_REGULATION_OK) {
              this.regulationCheckCommentsOfficial.transit_airports[index] = {
                id: item?.id,
                comment: item?.comment || '',
              };
            }
          });
        }

        if (!this.checkEmpty(newData?.banned_airlines)) {
          newData.banned_airlines.forEach((item, index) => {
            if (item.status !== TRIP_REGULATION_OK) {
              this.regulationCheckCommentsOfficial.banned_airlines[index] = {
                id: item?.id,
                comment: item?.comment || '',
              };
            }
          });
        }

        if (!this.checkEmpty(newData?.flight_sections)) {
          newData.flight_sections.forEach((item, index) => {
            if (item.status !== TRIP_REGULATION_OK) {
              this.regulationCheckCommentsOfficial.flight_sections[index] = {
                id: item?.id,
                comment: item?.comment || '',
              };
            }
          });
        }

        if (!this.checkEmpty(newData?.transit_times)) {
          newData.transit_times.forEach((item, index) => {
            if (item.status !== TRIP_REGULATION_OK) {
              this.regulationCheckCommentsOfficial.transit_times[index] = {
                id: item?.id,
                comment: item?.comment || '',
              };
            }
          });
        }

        if (!this.checkEmpty(newData?.number_of_connections)) {
          newData.number_of_connections.forEach((item, index) => {
            if (item.status !== TRIP_REGULATION_OK) {
              this.regulationCheckCommentsOfficial.number_of_connections[index] = {
                id: item?.id,
                comment: item?.comment || '',
              };
            }
          });
        }

        if (!this.checkEmpty(newData?.seat_classes)) {
          newData.seat_classes.forEach((item, index) => {
            if (item.status !== TRIP_REGULATION_OK) {
              this.regulationCheckCommentsOfficial.seat_classes[index] = {
                id: item?.id,
                comment: item?.comment || '',
              };
            }
          });
        }

        if (!this.checkEmpty(newData?.air_ticket_costs)) {
          newData.air_ticket_costs.forEach((item, index) => {
            if (item.status !== TRIP_REGULATION_OK) {
              this.regulationCheckCommentsOfficial.air_ticket_costs[index] = {
                id: item?.id,
                comment: item?.comment || '',
              };
            }
          });
        }

        if (!this.checkEmpty(newData?.hotel_costs)) {
          newData.hotel_costs.forEach((item, index) => {
            if (item.status !== TRIP_REGULATION_OK) {
              this.regulationCheckCommentsOfficial.hotel_costs[index] = {
                id: item?.id,
                comment: item?.comment || '',
              };
            }
          });
        }

        if (!this.checkEmpty(newData?.total_costs)) {
          newData.total_costs.forEach((item, index) => {
            if (item.status !== TRIP_REGULATION_OK) {
              this.regulationCheckCommentsOfficial.total_costs[index] = {
                id: item?.id,
                comment: item?.comment || '',
              };
            }
          });
        }
      },
      deep: true,
    },
  },
  beforeRouteLeave(to, from, next) {
    if (
      !this.compareArray(
        this.regulationCheckComments.business_trip_days,
        this.regulationCheckCommentsOfficial.business_trip_days,
      ) ||
      !this.compareArray(
        this.regulationCheckComments.passport_number_of_days,
        this.regulationCheckCommentsOfficial.passport_number_of_days,
      ) ||
      !this.compareArray(
        this.regulationCheckComments.transit_airports,
        this.regulationCheckCommentsOfficial.transit_airports,
      ) ||
      !this.compareArray(
        this.regulationCheckComments.flight_sections,
        this.regulationCheckCommentsOfficial.flight_sections,
      ) ||
      !this.compareArray(
        this.regulationCheckComments.transit_times,
        this.regulationCheckCommentsOfficial.transit_times,
      ) ||
      !this.compareArray(
        this.regulationCheckComments.number_of_connections,
        this.regulationCheckCommentsOfficial.number_of_connections,
      ) ||
      !this.compareArray(
        this.regulationCheckComments.seat_classes,
        this.regulationCheckCommentsOfficial.seat_classes,
      ) ||
      !this.compareArray(
        this.regulationCheckComments.air_ticket_costs,
        this.regulationCheckCommentsOfficial.air_ticket_costs,
      ) ||
      !this.compareArray(this.regulationCheckComments.hotel_costs, this.regulationCheckCommentsOfficial.hotel_costs) ||
      !this.compareArray(this.regulationCheckComments.total_costs, this.regulationCheckCommentsOfficial.total_costs)
    ) {
      const answer = window.confirm(this.$t('common.confirm_leave_page'));
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  methods: {
    handleConfirm() {
      // if (!this.regulationDetail.is_passport_registered) {
      //   // this.showBoxConfirm = true;
      //   return;
      // }
      this.confirmRegulationCheck();
    },
    regulationTextStatus(status) {
      switch (status) {
        case TRIP_REGULATION_OK:
          return this.$t('regulation_check.status_ok');
        case TRIP_REGULATION_WARNING:
          return this.$t('regulation_check.status_warning');
        case TRIP_REGULATION_ERROR:
          return this.$t('regulation_check.status_error');
        default:
          return '';
      }
    },
    backgroundColorRegulation(status) {
      if (status === TRIP_REGULATION_OK) return 'ok';
      if (status === TRIP_REGULATION_WARNING || status === TRIP_REGULATION_ERROR) return 'warning';
      return '';
    },
    styleTripRegulationIcon(status) {
      if (status === TRIP_REGULATION_OK) return 'icon-abui-check-inverted text-success';
      if (status === TRIP_REGULATION_WARNING) return 'icon-abui-alert text-warning';
      if (status === TRIP_REGULATION_ERROR) return 'icon-abui-triangle-alert text-danger';
      return '';
    },
    styleTripRegulationText(status) {
      if (status === TRIP_REGULATION_OK) return 'text-success';
      if (status === TRIP_REGULATION_WARNING) return 'text-warning';
      if (status === TRIP_REGULATION_ERROR) return 'text-danger';
      return '';
    },
    //Cu-163
    confirmRegulationCheck() {
      this.showBoxConfirm = false;
      let flagValidate = true;
      let submitParams = {
        trip_id: this.regulationDetail?.id,
      };
      for (let key in this.regulationCheckComments) {
        if (!this.checkEmpty(this.regulationCheckComments[key])) {
          submitParams[key] = this.regulationCheckComments[key];
        }
      }
      if (flagValidate) {
        this.startLoading();
        this.$services.regulation_check.confirmRegulationCheck(
          submitParams,
          () => {
            this.endLoading();
            this.notifySuccess(this.$t('I-CM-103'));
            this.getRegulationCheckDetail();
          },
          err => {
            this.endLoading();
            this.notifyError(this.$t(err.error));
          },
        );
      }
    },
    //OP-162
    async getRegulationCheckDetail() {
      if (!this.checkEmpty(this.regulationCheckId)) {
        await this.$services.regulation_check.getRegulationCheckDetail(
          { id: this.regulationCheckId },
          res => {
            this.regulationDetail = res;
          },
          err => {
            this.notifyError(this.$t(err.error));
            this.$router.push(this.PAGES.REGULATION_CHECK);
          },
        );
      }
    },
  },
};
</script>
<style scoped>
.margin-comment-top-12 {
  margin-top: 12px;
}
.margin-top-7 {
  margin-top: 7px;
}
.margin-bottom-12 {
  margin-bottom: 12px;
}
.status-done-custom {
  background-color: #e6f5e5 !important;
}
.color-status-done {
  color: #65c15e;
}
</style>
