<template>
  <nav class="navbar navbar-default navbar-lg" role="navigation" id="header-content">
    <div class="navbar-header">
      <h2 class="navbar-title">{{ $t('application.application') }}</h2>
    </div>
  </nav>
  <div class="filter-wrap">
    <SearchForm
      :is-show-search-box="showBoxFilter"
      :data-search="searchParams"
      :is-search-activated="1"
      @listenShowSearchBox="listenShowSearchBox"
      @listenSearchAction="listenSearchAction"
      @listenCloseSearchBox="listenCloseSearchBox"
    />
    <div class="filter-result js-filter-result--minimization">
      <div class="section noborder">
        <Pagination
          v-if="!checkEmpty(listApplications)"
          @change-page="changePageData"
          :paginate="paginate"
          @change-number="changeNumber"
        />
        <div>
          <DataTable
            :application-data="listApplications"
            :reset-check-all="resetCheckAllToggle"
            :list-application-selected="listApplicationSelected"
            @sort-data-table="sortDataTable"
            @listen-disable-approve="listenDisableApprove"
          />

          <Pagination
            v-if="!this.checkEmpty(listApplications)"
            @change-page="changePageData"
            :paginate="paginate"
            @change-number="changeNumber"
          />
        </div>
      </div>
    </div>
    <div class="row btn-row footer-row">
      <div
        class="col-sm-3 col-md-3"
        :class="showButtonApproved ? 'col-sm-offset-6 col-md-offset-6' : 'col-sm-offset-9 col-md-offset-9'"
      >
        <DownloadApplicationCSV :is-download-disabled="isDisabledDownload" @download="getListApplications(true)" />
      </div>
      <div v-if="showButtonApproved" class="col-sm-3 col-md-3">
        <ApproveApplicationButton
          :is-approve-disabled="isApproveDisabled"
          @listenApproveApplications="listenApproveApplications"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Pagination from '@/components/common/Pagination';
import SearchForm from '@/components/application/SearchForm';
import DataTable from '@/components/application/DataTable';
import DownloadApplicationCSV from '@/components/application/footer/DownloadApplicationCSV';
import ApproveApplicationButton from '@/components/application/footer/ApproveApplicationButton';
import mixins from '@/helpers/mixins';

import {
  DES_TYPE_DOMESTIC,
  DES_TYPE_ABROAD,
  IS_APPLICANT_ONLY,
  IS_APPROVER_ONLY,
  DEFAULT_APPROVAL,
  DEFAULT_REPORTING,
  DEFAULT_EXPENSE,
} from '@/constants/';

export default {
  name: 'Application',
  mixins: [mixins],
  components: {
    Pagination,
    SearchForm,
    DataTable,
    DownloadApplicationCSV,
    ApproveApplicationButton,
  },
  data() {
    return {
      showBoxFilter: false,
      resetCheckAllToggle: false,
      listApplications: [],
      paginate: {
        page: +this.$route.query.page || 1,
        per_page: +this.$route.query.per_page || 10,
        total: 0,
      },
      pageSize: this.$route.query.per_page || 10,
      listOrganizations: [],
      isSearchActivated: this.$route.query.is_search_activated ? this.$route.query.is_search_activated == 1 : false,
      searchParams: {
        isRequestInMyTurn: this.$route.query?.isRequestInMyTurn ? this.$route.query?.isRequestInMyTurn == 1 : true,
        isRequestNotInMyTurn: this.$route.query?.isRequestNotInMyTurn
          ? this.$route.query?.isRequestNotInMyTurn == 1
          : false,
        sort_column: this.$route.query.sort_column || '',
        sort_by: this.$route.query.sort_by || '',
        locale: this.$i18n.locale,
        approvalSearch:
          this.$route.query.is_search_activated == 1
            ? !this.checkEmpty(this.$route.query.approval_status)
              ? JSON.parse(this.$route.query.approval_status)
              : []
            : DEFAULT_APPROVAL,
        reportingSearch:
          this.$route.query.is_search_activated == 1
            ? !this.checkEmpty(this.$route.query.reporting_status)
              ? JSON.parse(this.$route.query.reporting_status)
              : []
            : DEFAULT_REPORTING,
        expenseSearch:
          this.$route.query.is_search_activated == 1
            ? !this.checkEmpty(this.$route.query.expense_status)
              ? JSON.parse(this.$route.query.expense_status)
              : []
            : DEFAULT_EXPENSE,
        des_abroad: this.$route.query?.des_abroad ? this.$route.query.des_abroad == 1 : true,
        des_domestic: this.$route.query?.des_domestic ? this.$route.query.des_domestic == 1 : true,
        start_date: this.$route.query?.start_date || '',
        approve_start_date: this.$route.query?.approve_start_date || '',
        start_from_date: this.$route.query?.start_from_date || '',
        start_until_date: this.$route.query?.start_until_date || '',
        end_date: this.$route.query?.end_date || '',
        approve_end_date: this.$route.query?.approve_end_date || '',
        end_from_date: this.$route.query?.end_from_date || '',
        end_until_date: this.$route.query?.end_until_date || '',
        is_applicant_or_approver: this.$route.query.is_applicant_or_approver
          ? parseInt(this.$route.query.is_applicant_or_approver)
          : IS_APPLICANT_ONLY,
        is_approval_or_report: this.$route.query?.is_approval_or_report
          ? this.$route.query?.is_approval_or_report == 1
          : true,
        is_expense_reimbursement: this.$route.query?.is_expense_reimbursement
          ? this.$route.query?.is_expense_reimbursement == 1
          : true,
        user_name: this.$route.query?.user_name || '',
        destination: this.$route.query?.destination || '',
        trip_name: this.$route.query?.trip_name || '',
        burden_organization_ids: this.$route.query.burden_organization_ids
          ? JSON.parse(this.$route.query?.burden_organization_ids)
          : [],
        organization_ids: this.$route.query.organization_ids ? JSON.parse(this.$route.query.organization_ids) : [],
        search_from_dashboard: this.$route.query?.search_from_dashboard || 0,
      },
      listApplicationSelected: [],
      isApproveDisabled: true,
    };
  },
  computed: {
    showApprovalExpense() {
      return this.$store.state.auth?.user?.setting?.is_feature_payment || false;
    },
    isShowSettingApproval() {
      return this.$store.state.auth?.user?.setting.is_show_approval || false;
    },
    isShowSettingReport() {
      return this.$store.state.auth?.user?.setting.is_show_report || false;
    },
    showButtonApproved() {
      return !['employee_approval_management', 'employee_no_authority', 'employee_manager_reference'].includes(this.userRole);
    },
    isDisabledDownload() {
      if (this.paginate.total > 0) return false;
      return true;
    },
  },
  async created() {
    await this.getCurrentUser()
    if (!this.isShowSettingApproval && !this.showApprovalExpense) {
      this.$router.push(this.PAGES.UNAUTHORIZED);
      return;
    }
    localStorage.setItem('hoild_ids', JSON.stringify([]));
    this.getListApplications();
  },
  methods: {
    async getCurrentUser() {
      await this.$services.account.current(
          res => {
            this.$store.dispatch('auth/initCurrentData', res);
          },
          () => {
            this.logout();
          },
      );
    },
    sortDataTable(sortParams) {
      this.searchParams.sort_column = sortParams.sort_column;
      this.searchParams.sort_by = sortParams.sort_by;
      this.getListApplications();
    },
    listenShowSearchBox() {
      this.showBoxFilter = true;
    },
    changePageData(page) {
      this.paginate.page = +page;
      if (!this.checkEmpty(this.listApplicationSelected)) {
        this.isDisabledDownload = false;
        this.isApproveDisabled = false;
      }
      this.getListApplications();
    },
    async listenSearchAction(params) {
      await this.getCurrentUser();
      this.searchParams = params;
      this.paginate.page = 1;
      this.isSearchActivated = true;
      if (!this.showApprovalExpense) {
        this.searchParams.is_expense_reimbursement = 0;
        delete this.searchParams['expenseSearch'];
      }
      if (!this.isShowSettingReport) {
        delete this.searchParams['reportingSearch'];
      }
      this.getListApplications();
    },
    listenCloseSearchBox() {
      this.showBoxFilter = false;
    },
    listenApproveApplications() {
      this.approveSelectedApplications();
    },
    listenDisableApprove(value, appliList) {
      this.isApproveDisabled = value;
      this.listApplicationSelected = appliList;
    },
    approveSelectedApplications() {
      this.startLoading();
      const paramsApproved = {
        request_ids: this.listApplicationSelected,
      };
      this.$services.application.approveApplications(
        paramsApproved,
        () => {
          // this.endLoading();
          this.getListApplications();
          this.listApplicationSelected = [];
          this.resetCheckAllToggle = true;
          this.notifySuccess(this.$t('application.approve_multiple_success'));
        },
        err => {
          this.endLoading();
          this.notifyError(this.$t(err.error), true);
        },
      );
    },
    downloadRequestsCSV(dataSearch, queryBuilder) {
      //CU-292
      this.$services.application.downloadRequestsCSV(
        dataSearch,
        queryBuilder,
        res => {
          this.endLoading();
          window.open(res.file_path, '_blank').focus();
          this.notifySuccess(this.$t('application.download_csv_successfully'));
        },
        err => {
          this.endLoading();
          this.notifyError(this.$t(err.error));
        },
      );
    },
    async getListApplications(isDownloadCsvAction = false) {
      this.startLoading();
      const listApprovalAndReporting = this.searchParams.approval_or_report_status || [];
      if (!this.checkEmpty(listApprovalAndReporting)) {
        listApprovalAndReporting.forEach(element => {
          const itemId = parseInt(element.substring(element.length - 1));
          if (
            element.substring(0, element.lastIndexOf('-')) === 'approval' &&
            !this.searchParams.approvalSearch.includes(itemId)
          )
            this.searchParams.approvalSearch.push(itemId);
          else if (
            element.substring(0, element.lastIndexOf('-')) === 'reporting' &&
            !this.searchParams.reportingSearch.includes(itemId)
          )
            this.searchParams.reportingSearch.push(itemId);
        });
      }

      let dataSearch = {
        ...this.searchParams,
        sort_by: this.searchParams.sort_by == 'dsc' || this.searchParams.sort_by == 'desc' ? 'desc' : 'asc',
        user_name: this.searchParams.user_name,
        expected_approval: [],
        page: this.paginate.page,
        per_page: +this.paginate.per_page,
      };
      delete dataSearch['approval_or_report_status'];
      delete dataSearch['burden_organization_ids'];
      delete dataSearch['organization_ids'];
      delete dataSearch['type'];
      delete dataSearch['approvalSearch'];
      delete dataSearch['reportingSearch'];
      delete dataSearch['expenseSearch'];
      delete dataSearch['des_domestic'];
      delete dataSearch['des_abroad'];
      delete dataSearch['isRequestInMyTurn'];
      delete dataSearch['isRequestNotInMyTurn'];

      // if (this.searchParams.search_from_dashboard == 1) {
      //   delete dataSearch['is_applicant_or_approver'];
      // }

      let queryBuilder = '';
      if (!this.checkEmpty(this.searchParams.burden_organization_ids)) {
        this.searchParams.burden_organization_ids.forEach(item => {
          queryBuilder += 'burden_organization_ids[]=' + item + '&';
        });
      }
      if (!this.checkEmpty(this.searchParams.organization_ids)) {
        this.searchParams.organization_ids.forEach(item => {
          queryBuilder += 'organization_ids[]=' + item + '&';
        });
      }
      if (!this.checkEmpty(this.searchParams.approvalSearch)) {
        this.searchParams.approvalSearch.forEach(item => {
          queryBuilder += 'approval_status[]=' + item + '&';
        });
      }
      if (!this.checkEmpty(this.searchParams.reportingSearch) && this.isShowSettingReport) {
        this.searchParams.reportingSearch.forEach(item => {
          queryBuilder += 'reporting_status[]=' + item + '&';
        });
      }
      if (!this.checkEmpty(this.searchParams.expenseSearch) && this.showApprovalExpense) {
        this.searchParams.expenseSearch.forEach(item => {
          queryBuilder += 'expense_status[]=' + item + '&';
        });
      }

      if (this.searchParams.isRequestInMyTurn && this.searchParams.is_applicant_or_approver == IS_APPROVER_ONLY) {
        queryBuilder += 'expected_approval[]=' + 1 + '&';
      }
      if (this.searchParams.isRequestNotInMyTurn && this.searchParams.is_applicant_or_approver == IS_APPROVER_ONLY) {
        queryBuilder += 'expected_approval[]=' + 2 + '&';
      }

      if (this.searchParams.des_domestic) {
        queryBuilder += 'destination_type[]=' + DES_TYPE_DOMESTIC + '&';
      }
      if (this.searchParams.des_abroad) {
        queryBuilder += 'destination_type[]=' + DES_TYPE_ABROAD + '&';
      }

      for (let key in dataSearch) {
        if (this.checkEmpty(dataSearch[key])) delete dataSearch[key];
      }
      //Ignore default search params when search is not activated
      // const searchDataWhenNotActivated = {
      //   page: +this.paginate.page,
      //   per_page: +this.paginate.per_page,
      //   sort_column: this.searchParams.sort_column,
      //   sort_by: this.searchParams.sort_by,
      // };

      if (isDownloadCsvAction) {
        this.downloadRequestsCSV(dataSearch, queryBuilder);
        return;
      }

      //CU-001
      await this.$services.application.getListApplications(
        dataSearch,
        queryBuilder,
        res => {
          this.endLoading();
          this.listApplications = res.list;
          this.paginate = {
            ...res.paginate,
            page: res.paginate.current_page,
            per_page: res.paginate.per_page,
            total: res.paginate.total,
          };
          this.showBoxFilter = false;
          //Keep data search
          this.updateRouter(res);
        },
        err => {
          this.endLoading();
          this.notifyError(this.$t(err.error));
        },
      );
    },
    updateRouter(res) {
      let query = {
        page: res.paginate.current_page,
        per_page: res.paginate.per_page,
        sort_column: this.searchParams.sort_column,
        sort_by: this.searchParams.sort_by == 'dsc' || this.searchParams.sort_by == 'desc' ? 'desc' : 'asc',
        approval_status: !this.checkEmpty(this.searchParams.approvalSearch)
          ? JSON.stringify(this.searchParams.approvalSearch)
          : '',
        reporting_status: !this.checkEmpty(this.searchParams.reportingSearch)
          ? JSON.stringify(this.searchParams.reportingSearch)
          : '',
        expense_status: !this.checkEmpty(this.searchParams.expenseSearch)
          ? JSON.stringify(this.searchParams.expenseSearch)
          : '',
        isRequestInMyTurn: this.searchParams.isRequestInMyTurn ? 1 : 0,
        isRequestNotInMyTurn: this.searchParams.isRequestNotInMyTurn ? 1 : 0,
        des_abroad: this.searchParams.des_abroad ? 1 : 0,
        des_domestic: this.searchParams.des_domestic ? 1 : 0,
        start_date: this.searchParams.start_date,
        end_date: this.searchParams.end_date,
        approve_start_date: this.searchParams.approve_start_date,
        approve_end_date: this.searchParams.approve_end_date,
        start_from_date: this.searchParams.start_from_date,
        end_from_date: this.searchParams.end_from_date,
        start_until_date: this.searchParams.start_until_date,
        end_until_date: this.searchParams.end_until_date,
        is_applicant_or_approver: this.searchParams.is_applicant_or_approver,
        is_approval_or_report: this.searchParams.is_approval_or_report ? 1 : 0,
        is_expense_reimbursement: this.searchParams.is_expense_reimbursement ? 1 : 0,
        user_name: this.searchParams.user_name,
        destination: this.searchParams.destination,
        trip_name: this.searchParams.trip_name,
        burden_organization_ids: !this.checkEmpty(this.searchParams.burden_organization_ids)
          ? JSON.stringify(this.searchParams.burden_organization_ids)
          : '',
        organization_ids: !this.checkEmpty(this.searchParams.organization_ids)
          ? JSON.stringify(this.searchParams.organization_ids)
          : '',
        is_search_activated: this.isSearchActivated ? 1 : 0,
      };
      if (!this.isShowSettingReport) {
        delete query.reporting_status;
      }
      this.$router.push({
        path: this.$route.path,
        query,
      });
    },
    changeNumber(number) {
      this.paginate = {
        ...this.paginate,
        page: 1,
        per_page: number,
      };
      this.getListApplications();
    },
  },
};
</script>
<style scoped>
.checkbox.checked .second-icon,
.radio.checked .second-icon {
  color: #3650a5;
  opacity: 1;
  transition: color 0.25s linear;
}
.footer-row {
  margin-bottom: 2rem;
  padding-right: 32px;
}
</style>
