<template>
  <el-select
    v-model="selectedList"
    :popper-append-to-body="false"
    popper-class="custom-dropdown-select"
    @visible-change="resetDataSelect"
    clearable
    multiple
    :title="displayByLocale ? $t('common.all') : $t('common.not_specified')"
    :placeholder="placeholder != '' ? placeholder : displayByLocale ? $t('common.all') : $t('common.not_specified')"
    :disabled="isDisabled"
  >
    <div class="bs-searchbox" onclick="event.stopPropagation()">
      <input
        v-if="isDisplaySearch"
        type="text"
        class="form-control"
        autocomplete="off"
        v-model="searchName"
        role="tetxbox"
        aria-label="Search"
      />
    </div>

    <div v-if="!checkEmpty(filteredSearchData)">
      <div v-if="showSelectAll" onclick="event.stopPropagation()" class="border">
        <li
          class="el-select-dropdown__item hover"
          :class="isSelectAll && 'selected'"
          @click="handleSelectAll(!isSelectAll)"
        >
          <div class="el-custom-select-dropdown">
            <label class="checkbox no-label no-hover" :class="isSelectAll && 'checked'">
              <div>
                <span class="icons dropdown-option-icon">
                  <span class="first-icon icon-abui-checkbox-unchecked"></span>
                  <span class="second-icon icon-abui-checkbox-checked"></span>
                </span>
              </div>
            </label>
            <span class="dropdown-option-name">{{ $t('common.all') }}</span>
          </div>
        </li>
        <li class="divider" />
      </div>
      <el-option
        v-for="(item, index) in filteredSearchData"
        :key="item.id"
        :label="displayByLocale ? this.labelLocale(item) : item[`${labelShow}`]"
        :value="item.id"
        :class="
          (index == 0 && isListCountry && !showSelectAll && 'border') ||
          (item.id == -1 && !showSelectAll && 'border-top')
        "
      >
        <div class="el-custom-select-dropdown">
          <label
            :class="
              selectedList.includes(item.id) ? 'checkbox no-label no-hover checked' : 'checkbox no-label no-hover'
            "
          >
            <div>
              <span class="icons dropdown-option-icon">
                <span class="first-icon icon-abui-checkbox-unchecked" />
                <span class="second-icon icon-abui-checkbox-checked" />
              </span>
            </div>
          </label>
          <span class="dropdown-option-name">
            {{ displayByLocale ? this.labelLocale(item) : item[`${labelShow}`] }}
          </span>
        </div>
        <li class="divider" />
        <hr />
      </el-option>
    </div>
    <div v-else-if="checkEmpty(filteredSearchData) && !checkEmpty(listData)">
      <el-option value="" disabled>{{ " '" + searchName + "'" + $t('common.no_results_matched') }}</el-option>
    </div>
    <div v-else>
      <p class="no-data">{{ $t('common.no_data') }}</p>
    </div>
  </el-select>
</template>
<script>
import mixins from '@/helpers/mixins';

export default {
  name: 'MultiOptionSelect',
  emits: ['handleSelectedParams'],
  mixins: [mixins],
  props: {
    listData: {
      type: Array,
      default: () => [],
    },
    displayByLocale: {
      type: Boolean,
      default: false,
    },
    isDisplaySearch: {
      type: Boolean,
      default: true,
    },
    defaultList: {
      type: [Array, Object],
      default: () => [] || {},
    },
    labelShow: {
      type: String,
      default: () => 'name',
    },
    isListCountry: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    isSort: {
      type: Boolean,
      default: true,
    },
    showSelectAll: {
      type: Boolean,
      default: false,
    },
    showSelectOther: {
      type: Boolean,
      default: false,
    },
    optionIndex: {
      type: Object,
      default: () => {
        return {
          haveIndex: false,
          index: 0,
        };
      },
    },
  },
  data() {
    return {
      searchName: '',
      selectedList: [],
      isSelectAll: false,
    };
  },
  created() {
    if (!this.checkEmpty(this.defaultList)) {
      this.selectedList = this.defaultList;
    }
  },
  computed: {
    filteredSearchData() {
      const lowerCaseSearch = this.searchName.toLowerCase();
      if (this.showSelectOther) {
        let itemOther = {
          id: -1,
          code: '',
          en_name: 'Other',
          ja_name: 'その他',
        };

        let listResult = [];

        if (this.displayByLocale) {
          if (this.isSort) {
            listResult = this.sortCountryByName(
              this.listData.filter(item => this.labelLocale(item).toLowerCase().includes(lowerCaseSearch)),
            );
            listResult.push(itemOther);

            return listResult;
          }

          listResult = this.listData.filter(item => this.labelLocale(item).toLowerCase().includes(lowerCaseSearch));
          listResult.push(itemOther);

          return listResult;
        }

        if (this.isSort) {
          listResult = this.sortCountryByName(
            this.listData.filter(item => item[`${this.labelShow}`].toLowerCase().includes(lowerCaseSearch)),
          );
          listResult.push(itemOther);

          return listResult;
        }

        listResult = this.listData.filter(item => item[`${this.labelShow}`].toLowerCase().includes(lowerCaseSearch));
        listResult.push(itemOther);

        return listResult;
      } else {
        if (this.displayByLocale) {
          if (this.isSort)
            return this.sortCountryByName(
              this.listData.filter(item => this.labelLocale(item).toLowerCase().includes(lowerCaseSearch)),
            );

          return this.listData.filter(item => this.labelLocale(item).toLowerCase().includes(lowerCaseSearch));
        }

        if (this.isSort)
          return this.sortCountryByName(
            this.listData.filter(item => item[`${this.labelShow}`].toLowerCase().includes(lowerCaseSearch)),
          );

        return this.listData.filter(item => item[`${this.labelShow}`].toLowerCase().includes(lowerCaseSearch));
      }
    },
  },
  watch: {
    filteredSearchData(newVal) {
      if (this.showSelectAll && this.selectedList.length != newVal.length) this.isSelectAll = false;
      if (this.showSelectAll && this.selectedList.length == newVal.length) this.isSelectAll = true;
    },
    defaultList(value) {
      this.selectedList = value;
    },
    selectedList(newVal) {
      if (this.showSelectAll && newVal.length != this.filteredSearchData.length) this.isSelectAll = false;
      if (this.showSelectAll && newVal.length == this.filteredSearchData.length) this.isSelectAll = true;

      if (this.optionIndex.haveIndex) {
        this.$emit('handleSelectedParams', this.selectedList, this.optionIndex.index);
      } else {
        this.$emit('handleSelectedParams', this.selectedList);
      }
    },
    listData() {
      if (this.isSort) return this.sortCountryByName(this.listData);

      return this.listData;
    },
  },
  methods: {
    resetDataSelect() {
      this.searchName = '';
    },
    handleSelectAll(newVal) {
      this.isSelectAll = newVal;
      this.selectedList = [];
      if (newVal) {
        this.filteredSearchData.forEach(element => {
          this.selectedList.push(element.id);
        });
      }
    },
    clearFilter() {
      if (!this.checkEmpty(this.defaultList)) {
        this.selectedList = this.defaultList;
        return;
      }
      this.selectedList = [];
    },
  },
};
</script>
<style scoped>
.dropdown-option-icon {
  margin-top: 50%;
}
.dropdown-option-icon span.first-icon.icon-abui-checkbox-unchecked {
  font-size: 15px;
  color: rgba(152, 169, 176, 0.5);
}
.dropdown-option-icon span.second-icon.icon-abui-checkbox-checked {
  font-size: 15px;
  color: rgba(255, 255, 255, 0.8) !important;
}
.dropdown-option-name {
  padding-left: 2.7rem;
}
.bs-searchbox {
  margin-bottom: 5px;
  padding-top: 0;
}
.no-hover {
  pointer-events: none !important;
}
.border {
  border-bottom: 1px solid #d0d6da;
}
.dropdown-option-name {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
</style>
