import BaseService from './BaseService';
import API_CODE from '../utils/api_code.js';

export default class ScheduleAPI extends BaseService {
  async createSchedule(params, success, error) {
    await this.post(API_CODE.CU_028, params, success, error);
  }
  async updateSchedule(id, params, success, error) {
    await this.post(API_CODE.CU_017 + id, params, success, error);
  }
  async deleteSchedule(id, success, error) {
    await this.delete(API_CODE.CU_018 + id, {}, success, error);
  }
  async getListScheduleType(success, error) {
    await this.get(API_CODE.CU_022, success, error);
  }
  async getListAirport(success, error) {
    await this.get(API_CODE.CU_047, success, error);
  }
  async getListStation(success, error) {
    await this.get(API_CODE.CU_248, success, error);
  }
}
