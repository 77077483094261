import { createStore } from 'vuex';
import { auth } from './auth.module';
import { notification } from './notification.module';
import { map } from './map.module';

const store = createStore({
  modules: {
    auth,
    notification,
    map,
  },
});

export default store;
