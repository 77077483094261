<template>
  <SettingsDetail @onChangeData="checkOnChangeData" />
</template>
<script>
import SettingsDetail from '@/components/settings/SettingsDetail.vue';
export default {
  name: 'Settings',
  components: { SettingsDetail },
  data() {
    return {
      isDataChanged: false,
    };
  },
  beforeRouteLeave(to, from, next) {
    if (this.isDataChanged) {
      const answer = window.confirm(this.$t('common.confirm_leave_page'));
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  methods: {
    checkOnChangeData(val) {
      this.isDataChanged = val;
    },
  },
};
</script>
<style scoped></style>
