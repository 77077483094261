<template>
  <div v-if="this.$route.meta.layout == 'warning'">
    <router-view />
  </div>

  <div v-else>
    <nav v-if="hasLogin" class="navbar-expand navbar-dark bg-dark" :class="isOperatorOrAdmin && 'operator'">
      <div class="wrapper-page" style="overflow: hidden">
        <nav class="sidebar sidebar-inverse sidebar-fixed-top-bs" id="sidebar-general">
          <Sidebar />
        </nav>
        <Navbar />

        <div class="wrapper with-navbar-fixed-top">
          <div class="container-fluid with-sidebar">
            <router-view />
          </div>
        </div>
      </div>
    </nav>

    <div v-else>
      <router-view />
    </div>
  </div>
</template>

<script>
import Sidebar from '@/components/common/Sidebar';
import Navbar from '@/components/common/Navbar';
export default {
  mixins: [],
  components: { Navbar, Sidebar },
  computed: {
    hasLogin() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/project.scss";
// Body reset
body {
  font-family: 'Lato', 'SpicaNeueP-Regular', YuGothic, 'Yu Gothic', sans-serif;
  font-feature-settings: 'palt' 1;
  font-size: $font-size-base;
  color: $text-color;
  line-height: $line-height-base;
  background-color: $body-bg;
}

a {
  color: $link-color;
  text-decoration: none;

  &:hover,
  &:focus {
    color: $link-hover-color;
    text-decoration: none;
    outline: none !important;
  }
  &:focus {
    outline: none !important;
    outline-offset: -2px;
  }
}
</style>
