<template>
  <div class="filter js-filter--minimization" v-show="isShowSearchBox">
    <div class="section section-filter noborder js-section-filter">
      <div class="row">
        <DatePickerWithRange
          :parent-start-date="searchFormParams.start_date"
          :parent-end-date="searchFormParams.end_date"
          :date-picker-title="$t('application.application_date')"
          @listenStartDateChange="listenStartDateChange"
          @listenEndDateChange="listenEndDateChange"
        />
        <DatePickerWithRange
          :parent-start-date="searchFormParams.approve_start_date"
          :parent-end-date="searchFormParams.approve_end_date"
          :date-picker-title="$t('application.approve_date')"
          @listenStartDateChange="listenApproveStartDateChange"
          @listenEndDateChange="listenApproveEndDateChange"
        />
      </div>

      <div class="row">
        <div class="col-sm-6 col-md-6 col-lg-6">
          <div class="form-group">
            <label>
              <span class="label-text">{{ $t('application.burden_organization') }}</span>
            </label>
            <MultiOptionSelectApplication
              ref="RequestOrganizationSelect"
              :list-data="listBurdenOrganizations"
              @handle-selected-params="listenBurdenOrganization"
            />
          </div>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-4">
          <div class="form-group">
            <label>
              <span class="label-text">
                {{ $t('application.application_type') }}
              </span>
            </label>
            <label
                v-if="isShowSettingApproval"
              class="checkbox checkbox-inline checkbox-type-div"
              :class="(searchFormParams.is_approval_or_report || !isShowSettingApproval) &&  ' checked'"
            >
              <span class="icons">
                <span class="first-icon icon-abui-checkbox-unchecked"></span>
                <span class="second-icon icon-abui-checkbox-checked"></span>
              </span>
              <input type="checkbox" v-model="searchFormParams.is_approval_or_report"  :disabled="!showApprovalExpense"/>
              <span class="text_type">
                {{ $t('application.type.approval') + (isShowSettingReport ? '/' + $t('application.type.report') : '') }}
              </span>
            </label>
            <label
                v-if="showApprovalExpense"
              class="checkbox checkbox-inline checkbox-type-div checkbox-expense"
              :class="(searchFormParams.is_expense_reimbursement || !isShowSettingApproval) && ' checked'"
            >
              <span class="icons">
                <span class="first-icon icon-abui-checkbox-unchecked"></span>
                <span class="second-icon icon-abui-checkbox-checked"></span>
              </span>
              <input type="checkbox" v-model="searchFormParams.is_expense_reimbursement" :disabled="!isShowSettingApproval"/>
              <span class="text_type">
                {{ $t('application.type.expense_reimbursement') }}
              </span>
            </label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6 form-group">
          <label
            class="checkbox checkbox-inline checkbox-radio-div"
            :class="searchFormParams.is_applicant_or_approver == isApplicantOnly && 'checked'"
          >
            <span class="icons">
              <span class="first-icon icon-abui-radio-unchecked"></span>
              <span class="second-icon icon-abui-radio-checked"></span>
            </span>
            <input type="radio" :value="isApplicantOnly" v-model="searchFormParams.is_applicant_or_approver" />
            <span class="text_type_radio">
              {{ $t('application.applicant_only') }}
            </span>
          </label>

          <label
            class="checkbox checkbox-inline checkbox-radio-div type-approver"
            :class="searchFormParams.is_applicant_or_approver == isApproverOnly && 'checked'"
          >
            <span class="icons">
              <span class="first-icon icon-abui-radio-unchecked"></span>
              <span class="second-icon icon-abui-radio-checked"></span>
            </span>
            <input type="radio" :value="isApproverOnly" v-model="searchFormParams.is_applicant_or_approver" />
            <span class="text_type_radio">
              {{ $t('application.approver_only') }}
            </span>
          </label>

          <label
            class="checkbox checkbox-inline checkbox-radio-div type-approver"
            v-if="showCheckBoxAll"
            :class="searchFormParams.is_applicant_or_approver == isApproverAndApplicant && 'checked'"
          >
            <span class="icons">
              <span class="first-icon icon-abui-radio-unchecked"></span>
              <span class="second-icon icon-abui-radio-checked"></span>
            </span>
            <input type="radio" :value="isApproverAndApplicant" v-model="searchFormParams.is_applicant_or_approver" />
            <span class="text_type_radio">
              {{ $t('common.all') }}
            </span>
          </label>
        </div>
      </div>
      <div v-show="searchFormParams.is_applicant_or_approver == isApproverOnly" class="row">
        <div class="col-sm-6 form-group">
          <label
            class="checkbox checkbox-inline checkbox-type-div"
            :class="searchFormParams.isRequestInMyTurn && ' checked'"
          >
            <span class="icons">
              <span class="first-icon icon-abui-checkbox-unchecked"></span>
              <span class="second-icon icon-abui-checkbox-checked"></span>
            </span>
            <input type="checkbox" v-model="searchFormParams.isRequestInMyTurn" />
            <span class="text_type">
              {{ $t('application.followed_turn.in_my_turn') }}
            </span>
          </label>
          <label
            class="checkbox checkbox-inline checkbox-type-div checkbox-expense"
            :class="searchFormParams.isRequestNotInMyTurn && ' checked'"
          >
            <span class="icons">
              <span class="first-icon icon-abui-checkbox-unchecked"></span>
              <span class="second-icon icon-abui-checkbox-checked"></span>
            </span>
            <input type="checkbox" v-model="searchFormParams.isRequestNotInMyTurn" />
            <span class="text_type">
              {{ $t('application.followed_turn.not_in_my_turn') }}
            </span>
          </label>
        </div>
      </div>

      <hr />

      <div class="row">
        <div class="col-sm-6 col-md-6 col-lg-6" v-if="isShowSettingApproval">
          <div class="form-group">
            <label>
              <span class="label-text">
                {{ $t('application.type.approval') + (isShowSettingReport ? '／' + $t('application.type.report') : '')  + $t('common.status') }}
              </span>
            </label>
            <MultiSelectExpanded
              ref="ApprovalAndReportSelect"
              :list-data= "isShowSettingReport ? approvalAndReportList : approvalOnlyList"
              :default-list= "isShowSettingReport ? defaultApprovalAndReport : defaultApprovalOnlyList"
              :parent-selected="approvalAndReportSearched"
              :displayByLocale="true"
              @handle-selected-params="listenApprovalAndReportSearch"
            />
          </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6" v-if="showApprovalExpense">
          <div class="form-group">
            <label>
              <span class="label-text">{{ $t('application.expense_settlement') }}</span>
            </label>

            <MultiOptionSelectApplication
              ref="ExpenseSelect"
              :list-data="expenseStatus"
              :default-list="defaultExpense"
              :parent-selected="expenseSearched"
              :showSelectAll="true"
              :textSelectAll="'application.type.all_expense'"
              :displayByLocale="true"
              :is-display-search="false"
              @handle-selected-params="listenExpenseSearch"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 col-sm-6 col-md-4">
          <div class="form-group">
            <label>{{ $t('application.trip') }}</label>
            <input
              type="text"
              v-model="searchFormParams.trip_name"
              maxlength="50"
              class="form-control input-sm"
              :placeholder="$t('application.trip_input_format')"
            />
          </div>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-4">
          <div class="form-group">
            <label>{{ $t('application.destination_label') }}</label>
            <input
              type="text"
              v-model="searchFormParams.destination"
              maxlength="100"
              class="form-control input-sm"
              :placeholder="$t('application.destination_input_format')"
            />
          </div>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-4">
          <div class="form-group">
            <label>
              <span class="label-text">{{ $t('application.destination_type') }}</span>
            </label>

            <label
              class="checkbox checkbox-inline checkbox-type-div"
              :class="searchFormParams.des_domestic && ' checked'"
            >
              <span class="icons">
                <span class="first-icon icon-abui-checkbox-unchecked"></span>
                <span class="second-icon icon-abui-checkbox-checked"></span>
              </span>
              <input type="checkbox" v-model="searchFormParams.des_domestic" />
              <span class="text_type">{{ $t('application.destination.domestic') }}</span>
            </label>
            <label
              class="checkbox checkbox-inline checkbox-type-div checkbox-abroad"
              :class="searchFormParams.des_abroad && ' checked'"
            >
              <span class="icons">
                <span class="first-icon icon-abui-checkbox-unchecked"></span>
                <span class="second-icon icon-abui-checkbox-checked"></span>
              </span>
              <input type="checkbox" v-model="searchFormParams.des_abroad" />
              <span class="text_type">
                {{ $t('application.destination.abroad') }}
              </span>
            </label>
          </div>
        </div>
      </div>
      <div class="row">
        <DatePickerWithRange
          :parent-start-date="searchFormParams.start_from_date"
          :parent-end-date="searchFormParams.start_until_date"
          :date-picker-title="$t('application.business_trip_start_date')"
          @listenStartDateChange="listenStartFromDateChange"
          @listenEndDateChange="listenStartToDateChange"
        />
        <DatePickerWithRange
          :parent-start-date="searchFormParams.end_from_date"
          :parent-end-date="searchFormParams.end_until_date"
          :date-picker-title="$t('application.business_trip_end_date')"
          @listenStartDateChange="listenEndFromDateChange"
          @listenEndDateChange="listenEndToDateChange"
        />
      </div>
      <div class="row">
        <div class="col-xs-12 col-sm-6 col-md-4">
          <div class="form-group">
            <label>{{ $t('application.employee') }}</label>
            <input
              type="text"
              :disabled="isDisableSearchByUser"
              v-model="searchFormParams.user_name"
              maxlength="50"
              class="form-control input-sm"
              :placeholder="$t('application.employee_name_format')"
            />
          </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6">
          <div class="form-group">
            <label>
              <span class="label-text">{{ $t('application.affiliation_organization') }}</span>
            </label>
            <MultiOptionSelectApplication
              ref="AffilationOrganizationSelect"
              :disabled="isDisableSearchByUser"
              :list-data="listOrganizations"
              :parentSelected="searchFormParams.organization_ids"
              :defaultList="searchFormParams.organization_ids"
              @handle-selected-params="listenTripOrganization"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /.filter -->

  <div class="section-submit js-section-submit" v-show="isShowSearchBox">
    <span class="submit-arrow"></span>
    <div class="row">
      <div class="col-sm-4 col-md-5 hidden-xs">
        <button class="btn btn-sm btn-flat-default btn-clear" @click="clearFilter()">
          {{ $t('common.clear') }}
        </button>
      </div>
      <div class="col-sm-4 col-md-2">
        <button class="btn btn-sm btn-primary btn-block js-btn-filter--minimization" @click="emitSearchAction()">
          {{ $t('common.search') }}
        </button>
      </div>
      <div class="col-sm-4 col-md-5 hidden-xs">
        <button class="btn btn-sm btn-flat-default btn-close" @click="emitCloseSearchBox()">
          {{ $t('common.close') }}
        </button>
      </div>
    </div>
  </div>
  <!-- /.section-submit -->

  <div
    class="btn-filter-again with-condition"
    @click="emitShowSearchBox"
    style="display: block"
    v-show="!isShowSearchBox"
  >
    <div class="filter--condition">
      <span>{{ $t('common.all') }}</span>
      <span style="color: #3650a5">{{ $t('common.reset_conditions') }}</span>
    </div>
  </div>
</template>

<script>
import {
  IS_APPLICANT_ONLY,
  IS_APPROVER_ONLY,
  DEFAULT_APPROVAL,
  IS_APPROVER_AND_APPLICANT,
  DEFAULT_REPORTING,
  DEFAULT_EXPENSE,
} from '@/constants/';

import DatePickerWithRange from '@/components/application/DatePickerWithRange';
import MultiOptionSelectApplication from '@/components/application/MultiOptionSelectApplication';
import MultiSelectExpanded from '@/components/common/MultiSelectExpanded';

export default {
  name: 'SearchForm',
  emits: ['listenSearchAction', 'listenShowSearchBox', 'listenCloseSearchBox'],
  components: {
    DatePickerWithRange,
    MultiOptionSelectApplication,
    MultiSelectExpanded,
  },
  props: {
    isShowSearchBox: {
      type: Boolean,
      default: false,
    },
    dataSearch: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isSearchActivated: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isApplicantOnly: IS_APPLICANT_ONLY,
      isApproverOnly: IS_APPROVER_ONLY,
      isApproverAndApplicant: IS_APPROVER_AND_APPLICANT,
      searchFormParams: {
        isRequestInMyTurn: this.$route.query?.isRequestInMyTurn ? this.$route.query?.isRequestInMyTurn == 1 : true,
        isRequestNotInMyTurn: this.$route.query?.isRequestNotInMyTurn
          ? this.$route.query?.isRequestNotInMyTurn == 1
          : false,
        trip_name: this.$route.query?.trip_name || '',
        user_name: this.$route.query?.user_name || '',
        is_approval_or_report: this.$route.query?.is_approval_or_report
          ? this.$route.query?.is_approval_or_report == 1
          : true,
        is_expense_reimbursement: this.$route.query?.is_expense_reimbursement
          ? this.$route.query?.is_expense_reimbursement == 1
          : true,
        des_domestic: this.$route.query?.des_domestic ? this.$route.query.des_domestic == 1 : true,
        des_abroad: this.$route.query?.des_abroad ? this.$route.query.des_abroad == 1 : true,
        start_date: this.$route.query?.start_date || '',
        end_date: this.$route.query?.end_date || '',
        approve_start_date: this.$route.query?.approve_start_date || '',
        approve_end_date: this.$route.query?.approve_end_date || '',
        start_from_date: this.$route.query?.start_from_date || '',
        start_until_date: this.$route.query?.start_until_date || '',
        end_from_date: this.$route.query?.end_from_date || '',
        end_until_date: this.$route.query?.end_until_date || '',
        burden_organization_ids: this.$route.query.burden_organization_ids
          ? JSON.parse(this.$route.query?.burden_organization_ids)
          : [],
        approvalSearch: this.$route.query.approval_status ? JSON.parse(this.$route.query.approval_status) : [],
        reportingSearch: this.$route.query.reporting_status ? JSON.parse(this.$route.query.reporting_status) : [],
        expenseSearch: this.$route.query.expense_status ? JSON.parse(this.$route.query.expense_status) : [],
        type: [],
        expense_status: [],
        organization_ids: this.$route.query.organization_ids ? JSON.parse(this.$route.query.organization_ids) : [],
        is_applicant_or_approver: this.$route.query?.is_applicant_or_approver
          ? this.$route.query.is_applicant_or_approver
          : IS_APPLICANT_ONLY,
      },
      approvalStatus: [],
      reportingStatus: [],
      expenseStatus: [],
      listOrganizations: [],
      listBurdenOrganizations: [],
      listApplications: [],
      listApplicationSelected: [],
      isDisabledDownload: true,
      resetOrigin: false,
    };
  },
  async created() {
    this.startLoading();
    await this.getCurrentUser();
    Promise.all([
      this.getListStatusApproval(),
      this.getListStatusReport(),
      this.getListStatusExpense(),
      this.getBurdenOrganizations(),
      this.getListOrganizations(),
    ]).then(() => {
      this.endLoading();
    });
    if (this.checkEmpty(this.searchFormParams.user_name)) this.searchFormParams.user_name = this.userName || '';
    if (!this.checkEmpty(this.userOrganizationId) && this.checkEmpty(this.searchFormParams.organization_ids)) {
      this.searchFormParams.organization_ids = [];
      this.searchFormParams.organization_ids.push(this.userOrganizationId);
    }
  },
  computed: {
    showApprovalExpense() {
      return this.$store.state.auth?.user?.setting?.is_feature_payment || false;
    },
    isShowSettingApproval() {
      return this.$store.state.auth?.user?.setting?.is_show_approval || false;
    },
    isShowSettingReport() {
      return this.$store.state.auth?.user?.setting.is_show_report || false;
    },
    showCheckBoxAll() {
      return this.$store.state.auth?.user?.role?.code === "employee_manager" || this.$store.state.auth?.user?.role?.code === "employee_manager_reference" ||
          this.$store.state.auth?.user?.role?.code === "employee_approval_management";
    },
    userId() {
      return this.$store.state.auth?.user?.id || '';
    },
    userName() {
      return this.$store.state.auth?.user?.name || '';
    },
    userRole() {
      return this.$store.state.auth?.user?.role?.id || '';
    },
    userOrganizationId() {
      return this.$store.state.auth?.user?.parent_organization_id || '';
    },
    isDisableSearchByUser() {
      if (!this.checkEmpty(this.userRole) && this.userRole != 1) return false;
      return true;
    },
    approvalAndReportSearched() {
      let list = [];
      if (this.$route.query.approval_status) {
        const approvalSearchList = JSON.parse(this.$route.query.approval_status);
        list[0] = { name: 'approval', data: [] };
        if (approvalSearchList.length > 0) approvalSearchList.forEach(item => list[0].data.push(item));
      }
      if (this.$route.query.reporting_status) {
        const reportSearchList = JSON.parse(this.$route.query.reporting_status);
        list[1] = { name: 'report', data: [] };
        if (reportSearchList.length > 0) reportSearchList.forEach(item => list[1].data.push(item));
      }
      return list;
    },
    expenseSearched() {
      let list = [];
      if (this.$route.query.expense_status) {
        const expenseSearchList = JSON.parse(this.$route.query.expense_status);
        if (expenseSearchList.length > 0) expenseSearchList.forEach(item => list.push(item));
      }
      return list;
    },
    approvalAndReportList() {
      return [
        {
          name: 'application.type.approval',
          select_all_text: 'application.type.all_approval',
          data: this.approvalStatus,
        },
        {
          name: 'application.type.report',
          select_all_text: 'application.type.all_report',
          data: this.reportingStatus,
        },
      ];
    },
    approvalOnlyList() {
      return [
        {
          name: 'application.type.approval',
          select_all_text: 'application.type.all_approval',
          data: this.approvalStatus,
        },
      ];
    },
    defaultExpense() {
      return DEFAULT_EXPENSE;
    },
    defaultApprovalAndReport() {
      return [
        {
          name: 'approval',
          data: DEFAULT_APPROVAL,
        },
        {
          name: 'report',
          data: DEFAULT_REPORTING,
        },
      ];
    },
    defaultApprovalOnlyList() {
      return [
        {
          name: 'approval',
          data: DEFAULT_APPROVAL,
        },
      ];
    },
    getCollapsedStatus() {
      if (!this.searchFormParams.approval_or_report_status) return false;
      return this.searchFormParams.approval_or_report_status.length > 4 ? true : false;
    },
    getCollapsedExpenseStatus() {
      if (!this.searchFormParams.expenseSearch) return false;
      return this.searchFormParams.expenseSearch.length > 4 ? true : false;
    },
  },
  watch: {
    userRole() {
      this.initData();
    },
    dataSearch: {
      handler() {
        if (this.isSearchActivated) this.searchFormParams = this.dataSearch;
      },
      deep: true,
    },
  },
  methods: {
    initData() {
      this.searchFormParams.user_name = this.userName;
      if (!this.checkEmpty(this.userOrganizationId) && this.checkEmpty(this.searchFormParams.organization_ids)) {
        this.searchFormParams.organization_ids = [];
        this.searchFormParams.organization_ids.push(this.userOrganizationId);
      }
    },
    async getCurrentUser() {
      await this.$services.account.current(
          res => {
            this.$store.dispatch('auth/initCurrentData', res);
          },
          () => {
            this.logout();
          },
      );
    },
    listenApprovalAndReportSearch(searchList) {
      this.searchFormParams.approvalSearch = [];
      this.searchFormParams.reportingSearch = [];
      searchList.forEach(item => {
        const listName = item.split('#')[0];
        const itemId = item.split('#').pop();
        switch (listName) {
          case 'approval':
            this.searchFormParams.approvalSearch.push(itemId);
            break;
          case 'report':
            this.searchFormParams.reportingSearch.push(itemId);
            break;
          default:
            break;
        }
      });
    },
    listenExpenseSearch(searchList) {
      this.searchFormParams.expenseSearch = searchList;
    },
    listenTripOrganization(selectedList) {
      this.searchFormParams.organization_ids = selectedList;
    },
    listenBurdenOrganization(selectedList) {
      this.searchFormParams.burden_organization_ids = selectedList;
    },
    listenRequestOrganization(selectedList) {
      this.searchFormParams.burden_organization_ids = selectedList;
    },
    listenStartDateChange(startDate) {
      this.searchFormParams.start_date = startDate;
    },
    listenEndDateChange(endDate) {
      this.searchFormParams.end_date = endDate;
    },
    listenApproveStartDateChange(startDate) {
      this.searchFormParams.approve_start_date = startDate;
    },
    listenApproveEndDateChange(endDate) {
      this.searchFormParams.approve_end_date = endDate;
    },
    listenStartFromDateChange(startDate) {
      this.searchFormParams.start_from_date = startDate;
    },
    listenStartToDateChange(endDate) {
      this.searchFormParams.start_until_date = endDate;
    },
    listenEndFromDateChange(startDate) {
      this.searchFormParams.end_from_date = startDate;
    },
    listenEndToDateChange(endDate) {
      this.searchFormParams.end_until_date = endDate;
    },
    emitShowSearchBox() {
      this.$emit('listenShowSearchBox');
    },
    emitSearchAction() {
      this.$emit('listenSearchAction', this.searchFormParams);
    },
    emitCloseSearchBox() {
      this.$emit('listenCloseSearchBox');
    },

    async getListStatusApproval() {
      await this.$services.common.getListStatusApproval(
        {'is_list_request': 1},
        res => {
          this.approvalStatus = res.list;
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },

    getListStatusReport() {
      this.$services.common.getListStatusReport(
        {'is_list_request': 1},
        res => {
          this.reportingStatus = res.list;
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },

    getListStatusExpense() {
      this.$services.common.getListStatusExpense(
        { with_other: 1, 'is_list_request': 1},
        res => {
          this.expenseStatus = res.list;
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },

    getBurdenOrganizations() {
      this.$services.application.getBurdenOrganizations(
        res => {
          this.listBurdenOrganizations = res.list;
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },

    getListOrganizations() {
      const params = {};
      this.$services.application.getListParentOrganizations(
        params,
        res => {
          this.listOrganizations = res.list;
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },

    clearFilter() {
      this.resetOrigin = true;
      this.searchFormParams = {
        user_name: '',
        trip_name: '',
        start_date: '',
        end_date: '',
        approve_start_date: '',
        approve_end_date: '',
        start_from_date: '',
        start_until_date: '',
        end_from_date: '',
        end_until_date: '',
        is_approval_or_report: true,
        is_expense_reimbursement: true,
        des_domestic: true,
        des_abroad: true,
        burden_organization_ids: [],
        approvalSearch: DEFAULT_APPROVAL,
        reportingSearch: DEFAULT_REPORTING,
        expenseSearch: DEFAULT_EXPENSE,
        destination_type: [],
        type: [],
        expense_status: [],
        organization_ids: [],
        is_applicant_or_approver: IS_APPLICANT_ONLY,
      };
      this.$refs.ApprovalAndReportSelect.clearFilter();
      this.$refs.ExpenseSelect.clearFilter();
      this.$refs.RequestOrganizationSelect.clearFilter();
      this.$refs.AffilationOrganizationSelect.clearFilter();
      this.initData();
    },
  },
};
</script>
<style scoped>
.checkbox-type-div {
  margin-top: 1rem !important;
}
#chkbox_type_2 {
  margin-left: 2.5rem !important;
}
.checkbox-radio-div {
  margin-top: 1rem !important;
}
.text_type {
  padding-left: 3rem !important;
}
.text_type_radio {
  padding-left: 3.2rem !important;
}
#checkbox_radio_type_2 {
  margin-left: 2.5rem !important;
}
.checkbox-expense {
  margin-left: 2.5rem !important;
}
.checkbox-abroad {
  margin-left: 2.5rem !important;
}
.type-approver {
  margin-left: 2.5rem !important;
}
</style>
