<template>
  <el-dialog
    v-model="dialogVisibleStatus"
    :show-close="false"
    @close="$emit('cancel')"
    width="742px"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-header--title">{{ $t('regulation.airport') }}</div>
        </div>
        <div class="modal-body">
          <div class="section noborder pbn">
            <div class="add-selected">
              <div class="row">
                <div class="col-sm-12 col-md-12">
                  <div class="add-selected__wrap">
                    <div class="add-selected__wrap__list">
                      <ul class="add-selected-list">
                        <li v-for="(item, index) in listAirportSelected" :key="index">
                          {{ labelLocale(item) }}
                          <span class="icon icon-abui-cross" @click="removeAirport(item, index)"></span>
                        </li>
                      </ul>
                    </div>
                    <div class="add-selected__wrap__btn" @click="deleteAllAirport" v-on:click.stop>
                      <button class="btn btn-sm js-btn--del-selected-list-item">
                        <span class="icon-abui-trash" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-12 col-md-12">
                  <div class="js-refine-search">
                    <table class="table table-striped table-word-seach">
                      <thead>
                        <tr>
                          <th>
                            <div class="input-group input-group-sm input-group-rounded" :class="{ focus: hasFocus }">
                              <span class="input-group-btn">
                                <button type="submit" class="btn btn-sm"><span class="icon-abui-search" /></button>
                              </span>
                              <input
                                type="text"
                                class="form-control js-refine-search__input input-sm"
                                :placeholder="$t('regulation.narrow_select')"
                                v-model="searchName"
                                @focus="onFocus"
                                @blur="onBlur"
                              />
                            </div>
                          </th>
                        </tr>
                      </thead>
                    </table>
                    <div class="innerScrollFade">
                      <div
                        v-show="!checkEmpty(filteredSearchData)"
                        id="innerScroll--add-selected-table2"
                        style="overflow: auto; height: 252px"
                      >
                        <table class="table table-striped table-hover table-word-seach__body">
                          <colgroup span="1"></colgroup>
                          <colgroup span="1"></colgroup>
                          <colgroup span="1"></colgroup>
                          <colgroup span="1" style="width: 44px"></colgroup>
                          <tbody id="refine-tgtlist--modal2">
                            <tr class="js-refine-search__item" v-for="(item, index) in filteredSearchData" :key="index">
                              <td class="js-refine-search__text">{{ labelLocale(item.country) }}</td>
                              <td class="js-refine-search__text">{{ labelLocale(item) }}</td>
                              <td>{{ item.code }}</td>
                              <td class="cell-control" @click="addAirport(item)">
                                <a class="btn btn-flat-default btn-sm js-btn--add-selected-list-item">
                                  <span class="icon-abui-plus" />
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div
                        v-show="checkEmpty(filteredSearchData)"
                        id="innerScroll--add-selected-table2"
                        style="
                          overflow: auto;
                          height: 252px;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        "
                      >
                        <p>{{ $t('regulation.please_input_data_search') }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="row">
            <div class="col-xs-6 col-sm-6 col-md-6">
              <button class="btn btn-sm btn-flat-default btn-block" @click="$emit('cancel')">
                {{ $t('regulation.cancel') }}
              </button>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6">
              <button class="btn btn-sm btn-flat-primary btn-block btn--feedback" @click="updateAirport">
                {{ $t('regulation.confirm') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import mixins from '@/helpers/mixins';

export default {
  components: {},
  name: 'ModalUpdateAirPort',
  emits: ['cancel', 'update'],
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    listAirport: {
      type: Array,
      default: () => [],
    },
    defaultList: {
      type: Array,
      default: () => [],
    },
  },
  mixins: [mixins],
  created() {
    if (!this.checkEmpty(this.defaultList)) {
      this.listAirportSelected = this.defaultList;
    }
  },
  computed: {
    filteredSearchData() {
      const lowerCaseSearch = this.searchName.toLowerCase();
      if (this.checkEmpty(lowerCaseSearch)) {
        return [];
      } else {
        let result = [];
        this.listAirport.forEach(airport => {
          if (
            this.labelLocale(airport).toLowerCase().includes(lowerCaseSearch) ||
            this.labelLocale(airport.country).toLowerCase().includes(lowerCaseSearch) ||
            airport.code.toLowerCase().includes(lowerCaseSearch)
          ) {
            result.push(airport);
          }
        });
        return result;
      }
    },
  },
  watch: {
    dialogVisible() {
      this.dialogVisibleStatus = this.dialogVisible;
    },
    defaultList(value) {
      this.listAirportSelected = value;
    },
  },
  data() {
    return {
      dialogVisibleStatus: false,
      listAirportSelected: [],
      searchName: '',
      hasFocus: false,
    };
  },
  methods: {
    onFocus() {
      this.hasFocus = true;
    },
    onBlur() {
      this.hasFocus = false;
    },
    updateAirport() {
      this.$emit('update', this.listAirportSelected);
    },
    addAirport(item) {
      let check = 0;
      this.listAirportSelected.forEach(airport => {
        if (this.labelLocale(airport) == this.labelLocale(item)) {
          check++;
        }
      });
      if (check > 0) {
        this.notifyError(this.$t('E-CU-058', { name: this.$t('regulation.flight') }));
      } else {
        this.listAirportSelected.push(item);
      }
    },
    removeAirport(item, index) {
      if (this.listAirportSelected[index] === item) {
        this.listAirportSelected.splice(index, 1);
      } else {
        let found = this.listAirportSelected.indexOf(item);
        this.listAirportSelected.splice(found, 1);
      }
    },
    deleteAllAirport() {
      this.listAirportSelected = [];
    },
  },
};
</script>
