import BaseService from './BaseService';
import API_CODE from '../utils/api_code.js';

export default class ApprovalFlowAPI extends BaseService {
  async getListApprovalFlow(params, query, success, error) {
    await this.get(API_CODE.CU_206 + this.urlParse(params, query), success, error);
  }
  async downloadFileCSV(params, query, success, error) {
    await this.get(API_CODE.CU_249 + this.urlParse(params, query), success, error);
  }
  async downloadExampleFileCSV(success, error) {
    await this.get(API_CODE.CU_250, success, error);
  }
  async uploadFileCSV(params, success, error) {
    await this.post(API_CODE.CU_211, params, success, error);
  }
  async createApprovalFlow(params, success, error) {
    await this.post(API_CODE.CU_209, params, success, error);
  }
  async approvalFlowDetail(id, success, error) {
    await this.get(API_CODE.CU_207 + id, success, error);
  }
  async updateApprovalFlow(id, params, success, error) {
    await this.post(API_CODE.CU_208 + id, params, success, error);
  }
  async deleteApprovalFlow(id, success, error) {
    await this.delete(API_CODE.CU_210 + id, {}, success, error);
  }
  async getApproval(params, success, error) {
    await this.get(API_CODE.CU_015 + this.urlParse(params), success, error);
  }
}
