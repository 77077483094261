<template>
  <div v-if="showListPeople" class="filter-wrap">
    <div
      v-show="!showBoxCreate"
      class="button-new"
      style="
        font-size: 14px;
        text-align: right;
        padding: 16px 32px;
        background-color: #e7ebec;
        border-bottom: 1px solid #cccccc80;
      "
    >
      <a href="#" class="cursor-pointer" @click="showBoxCreate = true">
        <span class="icon-abui-plus prx"></span>{{ $t('employee.add_new') }}
      </a>
    </div>

    <div v-if="showBoxCreate">
      <EmployeeForm
        :isOperator="true"
        @create-success="createSuccess"
        @cancel="showBoxCreate = false"
        @onChangeData="onChangeData"
      />
    </div>

    <div v-else>
      <div class="filter js-filter--minimization" v-show="showBoxFilter">
        <div class="section section-filter section-filter-special noborder js-section-filter">
          <div class="row">
            <div class="col-xs-12 col-sm-6 col-md-6">
              <div class="form-group">
                <label>{{ $t('customer.full_name') }}</label>
                <input
                  type="text"
                  v-model="searchParams.name"
                  class="form-control input-sm"
                  :placeholder="$t('customer.partial_match')"
                  maxlength="100"
                />
              </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-6">
              <div class="form-group">
                <label>&nbsp;</label>
                <label
                  class="checkbox checkbox-sm checkbox-inline"
                  :class="searchParams.passport_not_registered && 'checked'"
                  @click="searchParams.passport_not_registered = !searchParams.passport_not_registered"
                >
                  <span class="icons">
                    <span class="first-icon icon-abui-checkbox-unchecked"></span>
                    <span class="second-icon icon-abui-checkbox-checked"></span>
                  </span>
                  <span style="margin-left: 32px">{{ $t('customer.passport_information') }}</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section-submit js-section-submit" v-show="showBoxFilter">
        <span class="submit-arrow"></span>
        <div class="row">
          <div class="col-sm-4 col-md-5 hidden-xs">
            <button class="btn btn-sm btn-flat-default btn-clear" @click="clearFilter()">
              {{ $t('common.clear') }}
            </button>
          </div>
          <div class="col-sm-4 col-md-2">
            <button class="btn btn-sm btn-primary btn-block js-btn-filter--minimization" @click="handleSearch()">
              {{ $t('common.search') }}
            </button>
          </div>
          <div class="col-xs-12 visible-xs mts">
            <button class="btn btn-sm btn-flat-default btn-block btn-clear" @click="clearFilter()">
              {{ $t('common.clear') }}
            </button>
          </div>
        </div>
      </div>

      <div
        class="btn-filter-again with-condition"
        v-show="!showBoxFilter"
        @click="showBoxFilter = true"
        style="display: block"
      >
        <div class="filter--condition">
          <span>{{ $t('common.all') }}</span>
          <span style="color: #3650a5">{{ $t('common.reset_conditions') }}</span>
        </div>
      </div>

      <div class="filter-result js-filter-result--minimization displaynone" style="display: block">
        <div class="section noborder">
          <Pagination
            v-if="listData?.length > 0"
            :paginate="paginate"
            @change-page="changePageData"
            @change-number="changeNumber"
          />

          <div class="hidden-xs">
            <div class="table-responsive">
              <table class="table table-hover table-hover--pointer table--property-list" id="table--plan">
                <thead>
                  <tr>
                    <th
                      class="table-sort"
                      :class="sortActive.column == 'name' && 'table-sort-active'"
                      @click="sortAction('name')"
                    >
                      <span :class="sortActive.column == 'name' ? sortActive.by : 'asc'">
                        {{ $t('customer.full_name') }}
                      </span>
                    </th>
                    <th
                      class="table-sort table-sort-active"
                      :class="sortActive.column == 'employee_number' && 'table-sort-active'"
                      @click="sortAction('employee_number')"
                    >
                      <span :class="sortActive.column == 'employee_number' ? sortActive.by : 'asc'">
                        {{ $t('customer.code') }}
                      </span>
                    </th>
                    <th>{{ $t('customer.position') }}</th>
                    <th>{{ $t('customer.passport') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in listData" :key="index" @click="showPeopleDetail(item)">
                    <td>{{ item.name }}</td>
                    <td>{{ item.employee_number }}</td>
                    <td>{{ item.position?.title }}</td>
                    <td>
                      <span v-if="item.passport_check">
                        <i class="icon-abui-check-inverted prx"></i>{{ $t('customer.registered') }}
                      </span>
                      <span v-else class="text-warning">{{ $t('customer.unregistered') }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p v-if="listData?.length == 0" class="no-data">{{ $t('common.no_data') }}</p>
            </div>
          </div>

          <Pagination
            v-if="listData?.length > 0"
            @change-page="changePageData"
            :paginate="paginate"
            @change-number="changeNumber"
          />
        </div>
      </div>
    </div>
  </div>
  <PeopleDetail v-else @back="showListPeople = true" :user-id="peopleSelected.id" />
</template>
<script>
import EmployeeForm from '@/components/employee/EmployeeForm';
import Pagination from '@/components/common/Pagination';
import PeopleDetail from './PeopleDetail.vue';
export default {
  name: 'People',
  components: { Pagination, PeopleDetail, EmployeeForm },
  emits: ['onChangeData'],
  data() {
    return {
      listData: [],
      paginate: {
        page: +this.$route.query.page || 1,
        per_page: +this.$route.query.per_page || 10,
      },
      searchParams: {
        name: '',
        passport_not_registered: false,
      },
      showBoxFilter: false,
      showBoxCreate: false,
      showListPeople: true,
      peopleSelected: {},
      sortActive: {
        column: 'name',
        by: 'asc',
      },
    };
  },
  created() {
    this.getListUsers();
  },
  computed: {
    companyId() {
      return this.$route.params.id;
    },
  },
  methods: {
    onChangeData(val) {
      this.$emit('onChangeData', val);
    },
    sortAction(name) {
      this.sortActive.column = name;
      if (this.sortActive.by == 'dsc') this.sortActive.by = 'asc';
      else this.sortActive.by = 'dsc';

      this.getListUsers();
    },
    changePageData(page) {
      this.paginate.page = +page;
      this.getListUsers();
    },
    handleSearch() {
      this.paginate.page = 1;
      this.getListUsers();
    },
    changeNumber(number) {
      this.paginate = {
        ...this.paginate,
        page: 1,
        per_page: number,
      };
      this.getListUsers();
    },
    clearFilter() {
      this.searchParams = {
        name: '',
        passport_not_registered: false,
      };
    },
    async getListUsers() {
      let dataSearch = {
        ...this.searchParams,
        company_id: this.companyId,
        page: this.paginate.page,
        per_page: this.paginate.per_page,
        sort_column: this.sortActive.column,
        sort_by: this.sortActive.by == 'dsc' ? 'desc' : 'asc',
      };
      if (this.$route.query.user_id && this.$route.query.from == 'requirement') {
        dataSearch.is_all = 1;
      }
      this.startLoading();
      await this.$services.user.getListUsers(
        dataSearch,
        res => {
          this.endLoading();
          this.listData = res.list;
          if (this.$route.query.user_id && this.$route.query.from == 'requirement') {
            let dataUser = this.listData.find(user => user.id == this.$route.query.user_id);
            this.showPeopleDetail(dataUser);
          }
          this.paginate = { ...this.paginate, ...res.paginate };
          this.showBoxFilter = false;
        },
        err => {
          this.endLoading();
          this.notifyError(err.error);
        },
      );
    },
    showPeopleDetail(item) {
      this.peopleSelected = item;
      this.showListPeople = false;
    },
    createSuccess() {
      this.showBoxCreate = false;
      this.getListUsers();
    },
  },
};
</script>
<style scoped>
.button-new {
  font-size: 14px;
  text-align: right;
  padding: 16px 32px;
  background-color: #e7ebec;
  border-bottom: 1px solid #cccccc80;
}
.button-new a {
  color: #657a84;
}
.button-new a:hover {
  color: #3650a5;
}
.button-new:hover {
  color: #3650a5;
}
.table-hover td {
  padding: 8px !important;
}
</style>
