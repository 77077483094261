<template>
  <div class="close--report--wrap">
    <div v-if="!checkEmpty(reportList)">
      <div v-for="(report, i) in reportList" :key="i" class="close--report--item">
        <div class="close--report--item--date">
          {{ showDateTime(report.date, 'YYYY/MM/DD（ddd）') }}
        </div>
        <div class="close--report--item--title">{{ report.name }}</div>
        <pre
          v-if="report.description"
          class="close--report--item--body report-list-style"
          v-html="report.description"
        />
        <ul v-if="report.file_list && report.file_list.length > 0" class="close--report--item--attached--list">
          <li v-for="(file, inx) in report.file_list" :key="inx">
            <a :href="file.link" target="_blank" rel="noopener">
              <i class="icon-abui-file"></i><span class="file--name">{{ file.name }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div v-else class="close--report--item no-data report-tab">
      {{ $t('common.no_data') }}
    </div>
  </div>
</template>
<script>
import mixins from '@/helpers/mixins';

export default {
  mixins: [mixins],
  props: {
    tripId: {
      type: Number,
      default: null,
    },
    requestDetail: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      reportList: [],
    };
  },
  created() {
    if (this.tripId) this.getReportList();
  },
  watch: {
    tripId(val) {
      if (val) this.getReportList();
    },
  },
  mounted() {
    this.$emitter.on('reload_report_list', () => {
      this.getReportList();
    });
  },
  beforeUnmount() {
    this.$emitter.off('reload_report_list');
  },
  methods: {
    async getReportList() {
      // this.startLoading();
      if (this.checkEmpty(this.checkEmpty(this.tripId))) return;
      let params = {
        trip_id: this.tripId,
      };
      if (!this.checkEmpty(this.requestDetail?.id)) {
        params.request_id = this.requestDetail.id;
      }
      await this.$services.report.getReportFile(
        params,
        res => {
          // this.endLoading();
          this.reportList = res.list;
        },
        () => {
          // this.endLoading();
        },
      );
    },
  },
};
</script>
<style scoped>
.report-tab {
  align-items: center !important;
}
.report-list-style {
  word-break: break-all;
  padding-left: 0 !important;
  width: 100% !important;
  border: none !important;
}
</style>
