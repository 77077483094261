<template>
  <el-dialog
    v-model="isVisible"
    width="740px"
    @close="closeModal()"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    :show-close="false"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <button @click="closeModal()" type="button" class="close">
          <span class="icon-abui-cross" aria-hidden="true"></span>
        </button>
        <div class="modal-header" style="display: flex; justify-content: flex-start">
          <div class="modal-header--title" style="width: 100%">
            {{ $t('business_report.approved_expenses_title') }}
          </div>
        </div>
        <div class="modal-body">
          <div v-if="!checkEmpty(tripDetail.approval)" class="ringi-record">
            <div class="form-group mbn">
              <div class="btn-group bootstrap-select select-block select-sm">
                <button type="button" class="btn dropdown-toggle btn-input" role="button">
                  <span class="filter-option pull-left">
                    {{ showDateTime(tripDetail.approval.approve_date, 'YYYY/MM/DD') }}
                    <span v-if="!checkEmpty(tripDetail.approval.approve_date)"> -</span>
                    {{
                      tripDetail.approval.employee_number +
                      ' ' +
                      tripDetail.approval.name +
                      ' ' +
                      $t('business_report.final_approve')
                    }}
                  </span>
                  &nbsp;
                  <span class="bs-caret">
                    <span class="caret"></span>
                  </span>
                </button>
              </div>
            </div>
          </div>

          <div class="apply-expense--head">
            <div class="apply-expense--head--left" style="width: 80%">
              <div class="apply-expense--trip-title">{{ tripDetail?.name }}</div>
              <div class="apply-expense--trip-period">
                <i class="icon-abui-calendar prx"></i>
                {{ tripDetail.start_time && showDateTime(tripDetail.start_time, 'YYYY/MM/DD（ddd）') }} -
                {{ tripDetail.end_time && showDateTime(tripDetail.end_time, 'YYYY/MM/DD（ddd）') }}
              </div>
              <div class="apply-expense--trip-destination">
                <i class="icon-abui-location prx"></i>
                {{ labelLocale(tripDetail.schedule_location) }}
              </div>
            </div>
            <div class="apply-expense--head--right" style="width: 20%">
              <div class="apply-expense--total-sum">{{ splitThousandYen(totalCost) }}</div>
            </div>
          </div>

          <CostTab
            :trip-id-params="parseInt(tripDetail.tripId)"
            :request-id-params="requestId"
            :is-approved-expenses-modal="true"
            @emit-total-cost="listenTotalCost"
          />
        </div>

        <div class="modal-footer">
          <div class="row">
            <div class="col-sm-12" @click="closeModal()">
              <button class="btn btn-sm btn-flat-default btn-block js-btn--close-ringi-reffer">
                {{ $t('common.close') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import CostTab from '@/components/trip/trip-item/CostTab';
export default {
  name: 'ModalApprovedExpenses',
  emits: ['closeModal'],
  components: { CostTab },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    tripDetail: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isVisible: false,
      totalCost: 0,
    };
  },
  computed: {
    requestId() {
      if (this.checkEmpty(this.tripDetail.requests)) return 0;
      return this.tripDetail.requests[this.tripDetail.requests.length - 1].id;
    },
  },
  watch: {
    dialogVisible(newVal) {
      this.isVisible = newVal;
    },
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
    listenTotalCost(total) {
      this.totalCost = total;
    },
  },
};
</script>
<style scoped></style>
