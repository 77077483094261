<template>
  <el-dialog
    v-model="dialogVisibleStatus"
    :show-close="false"
    @close="closeModal()"
    width="736px"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <button type="button" class="close" @click="closeModal()">
          <span class="icon-abui-cross" aria-hidden="true"></span>
        </button>
        <div class="modal-header" style="display: flex; justify-content: flex-start">
          <div class="modal-header--title" style="width: 100%">{{ $t('expense_cost.settlement_of_expenses') }}</div>
        </div>
        <div class="modal-body">
          <p class="modal-dialog-text" style="margin-bottom: 24px">
            {{ $t('expense_cost.settlement_of_expenses_note') }}
          </p>

          <hr style="margin-bottom: 24px" />

          <div class="apply-approval-flow">
            <div>
              <label>
                <span class="label-text">{{ $t('business_report.approval_flow') }}</span>
              </label>
              <SingleOptionSelect
                ref="RequestOrganizationSelect"
                :default-list="default_approval_flow_id ? default_approval_flow_id : approvalFlowsByType[0]?.id"
                :showClearable="false"
                :list-data="approvalFlowsByType"
                @handle-selected-params="listenApprovalFlows"
              />
            </div>
            <div class="js-select_item-select-approval-flow__tgt select-approval-flow_1">
              <p class="note mbs" v-if="!checkEmpty(approvalFlowDetail)">
                {{ $t('business_report.approval_flow_note') }}
              </p>

              <div v-if="!checkEmpty(approvalFlowDetail)" class="step-list step-list--ringi-apply">
                <div
                  v-for="(detail, stepIndex) in approvalFlowDetail"
                  :key="stepIndex"
                  class="step-item"
                  :class="appendToStepClass(stepIndex, detail.user_select_option)"
                >
                  <i class="step-mark"></i>
                  <span>{{ displayStepTitle(stepIndex) }}</span>
                  <span class="ringi-step--type">{{ textToStepType(detail.step_type) }}</span>
                  <ul v-if="!checkEmpty(detail.step_detail)" class="list--approver">
                    <li v-for="(stepDetail, idx) in detail.step_detail" :key="idx">{{ stepDetail.user_name }}</li>
                  </ul>
                  <div v-if="detail.user_select_option == 1" class="custom-select-approver">
                    <div class="row">
                      <div class="col-sm-8">
                        <div class="form-group viewonly">
                          <p class="viewonly-text input-sm input-approver break-down-span">
                            {{ inputApprover[stepIndex]?.display_text }}
                          </p>
                        </div>
                        <p
                          class="error-feedback"
                          style="position: absolute; top: 22%"
                          v-text="inputApprover[stepIndex]?.error"
                        />
                      </div>
                      <div class="col-sm-4">
                        <a
                          @click="openAddApprover(stepIndex, detail.step_id)"
                          class="btn btn-sm btn-block btn-inverse-primary"
                        >
                          {{ $t('business_report.reference') }}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr style="margin-bottom: 24px" />

          <div class="close-expense--head">
            <div class="close-expense--head--left" style="width: 100%">
              {{ expenseDetail.title }}
            </div>
            <div class="close-expense--head--right">
              <div class="close-expense--total-sum">{{ splitThousandYen(expenseDetail.amount) }}</div>
            </div>
          </div>

          <div>
            <label>
              <span class="label-text">{{ $t('business_report.cost_bearing') }}</span>
            </label>
            <div v-for="(item, index) in listExpense" :key="index" class="close-expense--dept--item">
              <div style="width: 50%" class="prs">
                <div class="form-group">
                  <el-select
                    class="select-block select-sm"
                    popper-class="custom-dropdown-select"
                    :placeholder="$t('organization.choose_item')"
                    size="large"
                    v-model="listExpense[index].organization_id"
                  >
                    <el-option
                      v-for="item in listOrganizations"
                      :value="item.id"
                      :key="item.id"
                      :label="item.name"
                      :disabled="listOrganizationUsing.includes(item.id)"
                    />
                  </el-select>
                  <p class="error-feedback" v-if="listExpense[index].error_organization">
                    {{ listExpense[index].error_organization }}
                  </p>
                </div>
              </div>
              <div class="phs">
                <div class="form-group">
                  <div class="input-group" style="width: 25%">
                    <input
                      class="form-control input-sm"
                      type="text"
                      maxlength="3"
                      v-model="listExpense[index].price_percentage"
                      @input="
                        listExpense[index].price_percentage = convertNumberFullToHalf(
                          listExpense[index].price_percentage,
                        )
                      "
                    />
                    <span class="input-group-addon">%</span>
                  </div>
                  <p class="error-feedback" v-if="listExpense[index].error_price">
                    {{ listExpense[index].error_price }}
                  </p>
                </div>
              </div>
              <div class="phs" style="margin-left: auto">
                <div class="form-group">
                  <div class="close-expense--dept">
                    {{ splitThousandYen(listExpense[index].amount) }}
                  </div>
                </div>
              </div>
              <div style="width: 38px" class="mls">
                <div class="form-group">
                  <button v-if="index > 0" class="btn btn-sm btn-flat-danger" @click="removeItem(index)">
                    <i class="icon-abui-cross"></i>
                  </button>
                </div>
              </div>
            </div>
            <button
              class="btn btn-flat-primary btn-sm js-add-expense-dept"
              @click="addItem()"
              :class="!displayAddCostBearing && 'disabled'"
            >
              <i class="icon-abui-plus prx"></i>{{ $t('business_report.add_organization') }}
            </button>
          </div>
        </div>

        <div class="modal-footer">
          <div class="row">
            <div class="col-xs-6 col-sm-6 col-md-6">
              <button class="btn btn-sm btn-flat-default btn-block" @click="$emit('cancel')">
                {{ $t('common.cancel') }}
              </button>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6">
              <button class="btn btn-sm btn-primary btn-block" @click="submitData()">
                {{ $t('trip_item.right_menu.modal_settlement_expense.send_settlement_application') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>

  <AddApproverModal
    :visible="addApproverVisible"
    @close-modal="closeAddApprover()"
    :current-approver-list="currentApproverStepList"
    request-type="3"
    @emit-add-approver="handleAddApprover"
  />
</template>

<script>
import { ElDialog } from 'element-plus';
import SingleOptionSelect from '@/components/common/SingleOptionSelect';
import AddApproverModal from '@/components/trip/AddApproverModal';
import { APPROVAL, PASS_ROUND_PERUSAL, REMAND, HOLD, REQUEST_CALCULATE_TYPE } from '@/constants/';
import mixins from '@/helpers/mixins';

export default {
  components: {
    ElDialog,
    SingleOptionSelect,
    AddApproverModal,
  },
  name: 'ModalExpenseCost',
  emits: ['cancel', 'success'],
  mixins: [mixins],
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    expenseDetail: {
      type: Object,
      default: () => {},
    },
    lastExpenseFlowId: {
      typeof: Number,
      default: 0,
    },
  },
  watch: {
    dialogVisible() {
      this.dialogVisibleStatus = this.dialogVisible;
      if (this.dialogVisible) {
        this.getApprovalFlowsByNearest({request_type: 3});
      }
    },
    destinationType() {
      this.getApprovalFlowsByType();
    },
    userOrganizationId(newVal) {
      this.listExpense[0].price_percentage = newVal ? 100 : 0;
      this.listExpense[0].organization_id = newVal;
    },
    listExpense: {
      handler(newVal) {
        this.calCostBearing(newVal);
      },
      deep: true,
    },
    expenseDetail: {
      handler(newVal) {
        if (!this.checkEmpty(newVal)) this.calCostBearing(this.listExpense);
      },
      deep: true,
    },
  },
  data() {
    return {
      default_approval_flow_id: null,
      displayAddCostBearing: true,
      dialogVisibleStatus: false,
      addApproverVisible: false,
      totalPricePercent: 0,
      approvalFlowsByType: [],
      approvalFlowDetail: [],
      inputApprover: {},
      curApprovalFLowId: 0,
      curInputApprover: 0,
      listExpense: [
        {
          organization_id: this.userOrganizationId,
          price_percentage: this.userOrganizationId ? 100 : 0,
        },
      ],
      listOrganizations: [],
    };
  },
  created() {
    this.startLoading();
    Promise.all([this.getListOrganizations(), this.getApprovalFlowsByType()]).then(() => {
      this.endLoading();
    });
    this.listExpense[0].price_percentage = this.userOrganizationId ? 100 : 0;
    this.listExpense[0].organization_id = this.userOrganizationId;
  },
  computed: {
    //Total remainder of all cost bearing
    totalDecimalCostBearingRemainder() {
      let total = 0;
      if (this.listExpense.length < 2 || this.checkEmpty(this.expenseDetail.amount)) return 0;
      this.listExpense.forEach(item => {
        const price = item.price_percentage * this.expenseDetail.amount * 0.01;
        total += price - Math.floor(price);
      });
      return total;
    },
    userOrganizationId() {
      return this.$store.state.auth?.user?.organization_id || '';
    },
    listOrganizationUsing() {
      let listIdUsing = this.listExpense.map(item => item.organization_id);

      return listIdUsing;
    },
    destinationType() {
      return this.expenseDetail.destination_type || '';
    },
    currentApproverStepList() {
      return this.approvalFlowDetail[this.curInputApprover]?.step_detail || [];
    },
  },
  methods: {
    //CU-314
    async getApprovalFlowsByNearest(params) {
      await this.$services.trip.getApprovalFlowNearest(
          params,
          res => {
            this.default_approval_flow_id = res?.approval_flow_id || null;
          },
          err => {
            this.notifyError(this.$t(err.error));
          },
      );
    },
    calCostBearing(newVal) {
      let totalPercentage = 0;
      if (!this.checkEmpty(newVal)) {
        newVal.forEach((item, index) => {
          if (this.isNumeric(parseFloat(item.price_percentage))) totalPercentage += parseFloat(item.price_percentage);
          const costPercentage = this.isNumeric(parseFloat(item.price_percentage))
            ? parseFloat(item.price_percentage)
            : 0;
          const price = costPercentage * this.expenseDetail.amount * 0.01;
          const roundNum = Math.floor(price);
          this.listExpense[index].amount =
            index != 0 ? roundNum : +(Math.floor(price + this.totalDecimalCostBearingRemainder + 'e+0') + 'e-0');
        });
      }
      this.totalPricePercent = totalPercentage;
      if (totalPercentage >= 100) {
        this.displayAddCostBearing = false;
        return;
      }

      this.displayAddCostBearing = true;
    },
    convertNumberFullToHalf(number) {
      number = number.replace(/[^0-9０-９]/g, '');
      number = String(number).replace(/[\uFF10-\uFF19]/g, function (m) {
        return String.fromCharCode(m.charCodeAt(0) - 0xfee0);
      });

      return number;
    },
    price(index) {
      if (this.listExpense[index]?.price_percentage <= 100) {
        return this.splitThousandYen(
          ((this.listExpense[index].price_percentage * this.expenseDetail.amount) / 100).toFixed(0),
        );
      } else {
        return '-';
      }
    },
    handleAddApprover(params) {
      this.inputApprover[this.curInputApprover].display_text = params.employee_number + ' ' + params.name;
      this.inputApprover[this.curInputApprover].approve_id = params.id;
      this.addApproverVisible = false;
    },
    openAddApprover(inputApproverId, stepId) {
      this.inputApprover[inputApproverId] = {};
      this.curInputApprover = inputApproverId;
      this.inputApprover[inputApproverId].step_id = stepId;
      this.addApproverVisible = true;
    },
    closeAddApprover() {
      this.addApproverVisible = false;
    },
    listenApprovalFlows(approvalFlowId) {
      if (!this.checkEmpty(approvalFlowId)) {
        this.inputApprover = {};
        this.curApprovalFLowId = approvalFlowId;
        if (!this.checkEmpty(approvalFlowId)) this.getApprovalFlowStep(approvalFlowId);
      }
    },
    getApprovalFlowStep(approvalFlowId) {
      const params = {
        approval_flow_id: approvalFlowId,
      };
      this.$services.trip_business_report.getApprovalFlowStep(
        params,
        res => {
          this.approvalFlowDetail = res.list;
          res.list.forEach((item, index) => {
            if (item.user_select_option) {
              this.inputApprover[index] = {
                step_id: item.step_id,
                approve_id: null,
              };
            }
          });
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    async getApprovalFlowsByType() {
      let params = {
        request_type: REQUEST_CALCULATE_TYPE,
      };
      // Enable if necessary for getting business flow
      // if (!this.checkEmpty(this.destinationType)) params.trip_type = this.destinationType;
      await this.$services.trip_business_report.getApprovalFlowsByType(
        params,
        res => {
          this.approvalFlowsByType = res.list;
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    submitData() {
      let totalPercent = 0;
      let flgCheck = true;

      let data = {
        cost_id: this.expenseDetail.id,
        approval_flow_id: this.curApprovalFLowId,
        cost_bearing_organizations: [],
        approval_flow_steps: [],
      };

      this.listExpense.forEach(item => {
        totalPercent += +item.price_percentage;
        if (this.checkEmpty(item.organization_id)) {
          item.error_organization = this.$t('E-CM-004');
          flgCheck = false;
        } else {
          item.error_organization = '';
        }
        if (this.checkEmpty(item.price_percentage)) {
          item.error_price = this.$t('E-CM-004');
          flgCheck = false;
        } else if (item.price_percentage > 100) {
          flgCheck = false;
          item.error_price = this.$t('business_report.total_cost_bearing_err');
        } else {
          item.error_price = '';
        }

        data.cost_bearing_organizations.push({
          organization_id: item.organization_id,
          price_percentage: +item.price_percentage,
          amount: item.amount,
        });
      });

      for (let key in this.inputApprover) {
        // if (
        //   this.checkEmpty(this.approvalFlowDetail[key].step_detail) &&
        //   this.approvalFlowDetail[key].user_select_option === 1 &&
        //   this.checkEmpty(this.inputApprover[key]?.display_text)
        // ) {
        //   this.inputApprover[key].error = this.$t('E-CM-100', {
        //     item: this.$t('common.item'),
        //   });
        //   flgCheck = false;
        // } else {
        this.inputApprover[key].error = '';
        // }

        data.approval_flow_steps.push({
          approve_id: this.inputApprover[key].approve_id,
          step_id: this.inputApprover[key].step_id,
        });
      }

      if (!flgCheck) return;

      if (totalPercent !== 100) {
        this.notifyError(this.$t('business_report.total_cost_bearing_err'));
        return;
      }

      this.startLoading();
      this.$services.trip_item.sendSettlementApplication(
        data,
        () => {
          this.endLoading();
          this.$emit('success');
        },
        err => {
          this.endLoading();
          this.notifyError(this.$t(err.error));
        },
      );
    },
    addItem() {
      if (!this.displayAddCostBearing) return;
      this.listExpense.push({
        organization_id: '',
        price_percentage: 100 - this.totalPricePercent,
      });
    },
    removeItem(index) {
      this.listExpense.splice(index, 1);
    },
    handleBearingOrganization(organizationId, optionIndex) {
      this.listExpense[optionIndex].organization_id = organizationId;
    },
    async getListOrganizations() {
      const params = {
        all_data: true,
      };
      await this.$services.common.getListOrganizations(
        params,
        res => {
          this.listOrganizations = res.list;
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    closeModal() {
      for (let item in this.inputApprover) {
        this.inputApprover[item].error = '';
        this.inputApprover[item].approve_id = '';
        this.inputApprover[item].display_text = '';
      }
      // this.listExpense = [
      //   {
      //     organization_id: '',
      //     price_percentage: '',
      //   },
      // ];
      this.$emit('cancel');
    },
    displayStepTitle(stepIndex) {
      stepIndex++;
      const lastStep = this.approvalFlowDetail.length;
      if (stepIndex == lastStep) {
        return this.$t('trip_item.trip_process.finally');
      }
      return stepIndex;
    },
    textToStepType(stepType) {
      if (stepType === APPROVAL) return this.$t('trip_item.trip_process.approval');
      if (stepType === PASS_ROUND_PERUSAL) return this.$t('trip_item.trip_process.pass_perusal');
      if (stepType === REMAND) return this.$t('trip_item.trip_process.remand');
      if (stepType === HOLD) return this.$t('trip_item.trip_process.on_hold');
      return '-';
    },
    appendToStepClass(stepIndex, hasForm) {
      if (hasForm === 1) {
        if (stepIndex == 0) return 'first-step has-form';
        if (stepIndex != 0 && this.approvalFlowDetail[stepIndex - 1]?.user_select_option == 1)
          return 'has-previous-form';
        return 'has-form';
      }
      if (stepIndex == 0) return 'first-step';
      return '';
    },
  },
};
</script>
<style lang="scss" scoped>
.file-preview {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
  border: 1px solid #657a84;

  &:hover {
    color: white;
    background-color: #f77581;
    border: 1px solid #f77581;
  }
}
.btn-show-again-search {
  height: 80px;
  margin-bottom: 16px;
  background: #e7ebec;
  margin-right: -32px;
  margin-left: -32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    border-color: #dfe3f1;
    background-color: #dfe3f1;
  }

  span {
    color: #3650a5;
  }
}
.list-tab {
  cursor: pointer;
}
.select-approval-flow_1 {
  margin-top: 12px;
}
.step-list.step-list--ringi-apply {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
  margin-top: 12px;
  margin-bottom: 24px;
}
.step-list.step-list--ringi-apply .step-item {
  justify-content: flex-start;
  width: 100%;
  height: 24px;
  margin-bottom: 16px;
}
.step-list.step-list--ringi-apply .step-item.has-form {
  height: 36px;
}
.step-list.step-list--ringi-apply .step-item.has-form + .step-item::before {
  position: absolute;
  top: -26px;
  left: 7px;
  width: 2px;
  height: 30px;
}
.step-item.first-step::before {
  display: none;
}
.step-item.has-form::before {
  height: 30px !important;
}
.step-list.step-list--ringi-apply .step-mark + span {
  width: 40px;
  min-width: 40px;
  text-align: left;
}
.step-list.step-list--ringi-apply .list--approver li {
  display: inline-block;
}
.step-list.step-list--ringi-apply .list--approver {
  margin-bottom: 0;
  padding: 0;
}
.step-list.step-list--ringi-apply .list--approver li + li::before {
  display: inline-block;
  margin-right: 4px;
  content: '、';
}
.step-list.step-list--ringi-apply .step-item::before {
  position: absolute;
  top: -20px;
  left: 7px;
  width: 2px;
  height: 24px;
}
.step-list.step-list--ringi-apply .step-mark {
  min-width: 16px;
  margin-right: 12px;
}
.step-list.step-list--ringi-apply .step-item::after {
  display: none;
}
.step-list.step-list--ringi-apply .step-item span {
  display: inline-block;
  position: unset;
  top: unset;
  width: auto;
}
.step-list.step-list--ringi-apply .ringi-step--type {
  width: 48px;
  min-width: 48px;
  margin-right: 12px;
}
.step-item {
  display: flex;
  position: relative;
  align-items: center;
  color: #98a9b0;
  font-size: 12px;
  text-align: center;
}
.step-item::before {
  display: block;
  content: '';
  background-color: #d0d6da;
  content: '';
}
.ringi-step--type {
  top: unset !important;
  bottom: -24px;
  color: #45545b;
  font-size: 11px;
}
.js-btn--toggle-allowance {
  cursor: pointer;
}
.custom-select-approver {
  width: 55%;
  display: revert;
  justify-content: flex-end;
  margin-left: auto;
}
.padding-10 {
  padding-top: 10px;
}
.adjust-amount input {
  text-align: right;
}
.est-cost-check {
  padding-left: 28px;
  padding-top: 7px;
}
.custom-apply-expense {
  align-items: flex-start !important;
}
.apply-expense--dept {
  padding-top: 5px;
}
</style>
