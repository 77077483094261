import BaseService from './BaseService';
import API_CODE from '../utils/api_code.js';

export default class TripBusinessReportAPI extends BaseService {
  async getApprovalCosts(params, success, error) {
    await this.get(API_CODE.CU_033 + this.urlParse(params), success, error);
  }
  async getReportCosts(params, success, error) {
    await this.get(API_CODE.CU_027 + this.urlParse(params), success, error);
  }
  async sendBusinessApprovalApplication(params, success, error) {
    await this.post(API_CODE.CU_012, params, success, error);
  }
  async sendBusinessReportApplication(params, success, error) {
    await this.post(API_CODE.CU_032, params, success, error);
  }
  async getApprovalFlowsByType(params, success, error) {
    await this.get(API_CODE.CU_019 + this.urlParse(params), success, error);
  }
  async getApprovalFlowStep(params, success, error) {
    await this.get(API_CODE.CU_020 + '?approval_flow_id=' + params.approval_flow_id, success, error);
  }
  async uploadRequestFiles(params, success, error) {
    await this.post(API_CODE.CU_304, params, success, error);
  }
}
