<template>
  <nav class="navbar navbar-default navbar-lg">
    <div class="navbar-header">
      <h2 class="navbar-title">{{ $t('user.user') }}</h2>
    </div>
  </nav>
  <UserForm @onChangeData="onChangeData" />
</template>
<script>
import UserForm from '@/components/user/UserForm';

export default {
  name: 'UserNew',
  components: { UserForm },
  data() {
    return {
      isChangeData: false,
    };
  },
  beforeRouteLeave(to, from, next) {
    if (this.isChangeData) {
      const answer = window.confirm(this.$t('common.confirm_leave_page'));
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  methods: {
    onChangeData(val) {
      this.isChangeData = val;
    },
  },
};
</script>
<style lang="scss" scoped></style>
