<template>
  <nav class="navbar navbar-default navbar-lg" role="navigation" id="header-content">
    <div class="navbar-header">
      <h2 class="navbar-title">{{ $t('employee.employee') }}</h2>
    </div>
    <div class="collapse navbar-collapse">
      <ul class="nav navbar-nav navbar-right">
        <li>
          <a href="#" @click="downloadFileCSV">
            <span class="icon-abui-download prx"></span>{{ $t('employee.download_in_csv') }}
          </a>
        </li>
        <li class="hide--sbtm">
          <a class="cursor-pointer" @click="$router.push(PAGES.EMPLOYEE_NEW)">
            <span class="icon-abui-plus prx"></span>{{ $t('employee.add_new') }}
          </a>
        </li>
        <li class="dropdown addNew hidden-sm">
          <a
            href="#"
            class="dropdown-toggle pls"
            @click="isShowDropdown = !isShowDropdown"
            v-click-outside="
              () => {
                isShowDropdown = false;
              }
            "
          >
            <b class="caret"></b>
          </a>
          <span class="dropdown-arrow"></span>
          <ul class="dropdown-menu" :class="isShowDropdown && 'showDropdown'" style="left: -200px">
            <li>
              <a href="#" @click="showModalUploadCSV = true"><span class="icon-abui-upload prx" />{{ $t('employee.batch_registration_from_csv') }}</a>
            </li>
            <li class="divider"></li>
            <li>
              <a @click="downloadExampleFileCSV" href="#">
                <span class="icon-abui-download prx"></span>{{ $t('employee.download_csv_formate') }}
              </a>
            </li>
            <li>
              <a href="https://www.iace.co.jp/travelmgr/pdf/import_sample/shain" target="_blank" >
                <span class="icon-abui-book prx" />{{ $t('common.manual_registration') }}
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </nav>
  <div class="filter-wrap">
    <div class="filter" v-show="showBoxFilter">
      <div class="section section-filter noborder">
        <div class="row">
          <div class="col-xs-12 col-sm-6 col-md-6">
            <div class="form-group">
              <label>
                <font style="vertical-align: inherit">
                  <font style="vertical-align: inherit">{{ $t('employee.family_name') }}</font>
                </font>
              </label>
              <input
                v-model="searchParams.name"
                type="text"
                maxlength="100"
                class="form-control input-sm"
                :placeholder="$t('employee.match')"
              />
              <p class="error-feedback" v-text="errors.name"></p>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-6">
            <div class="form-group">
              <label class="checkbox checkbox-sm checkbox-inline">
                <el-checkbox style="margin-top: 17px" v-model="searchParams.passport_not_registered" size="large">
                  {{ $t('employee.passport_not_registered') }}
                </el-checkbox>
              </label>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-group">
              <label class="checkbox checkbox-sm checkbox-inline">
                <el-checkbox
                  v-model="searchParams.with_deleted"
                  class="pl-0"
                  :label="$t('employee.show_deleted')"
                  size="large"
                />
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /.filter -->

    <div class="section-submit" v-show="showBoxFilter">
      <span class="submit-arrow"></span>
      <div class="row">
        <div class="col-sm-4 col-md-5 hidden-xs">
          <button class="btn btn-sm btn-flat-default btn-clear" @click="clearFilter()">{{ $t('common.clear') }}</button>
        </div>
        <div class="col-sm-4 col-md-2">
          <button class="btn btn-sm btn-primary btn-block" @click="handleSearch()">
            <font style="vertical-align: inherit">
              <font style="vertical-align: inherit">{{ $t('common.search') }}</font>
            </font>
          </button>
        </div>
        <div class="col-sm-4 col-md-5 hidden-xs">
          <button class="btn btn-sm btn-flat-default btn-close" @click="showBoxFilter = false">
            {{ $t('common.close') }}
          </button>
        </div>
      </div>
    </div>
    <!-- /.section-submit -->

    <div
      class="btn-filter-again with-condition"
      v-show="!showBoxFilter"
      @click="showBoxFilter = true"
      style="display: block"
    >
      <!-- IE11, Edgeのバグ対策の為インラインにdisplayを指定 -->
      <div class="filter--condition">
        <span>{{ $t('common.all') }}</span>
        <span style="color: #3650a5">{{ $t('common.reset_conditions') }}</span>
      </div>
    </div>
    <!-- /.btn-filter-again -->

    <div class="filter-result">
      <div class="section noborder">
        <Pagination
          v-if="listEmployees.length > 0"
          :paginate="paginate"
          @change-page="changePageData"
          @change-number="changeNumber"
        />

        <div class="hidden-xs">
          <div class="table-responsive">
            <table class="table table-hover table-hover--pointer table--property-list" id="table--plan">
              <colgroup span="1" style="width: 30px"></colgroup>
              <thead>
                <tr>
                  <th></th>
                  <th
                    class="table-sort"
                    :class="sortActive.column == 'employee_number' && 'table-sort-active'"
                    @click="sortAction('employee_number')"
                  >
                    <span :class="sortActive.column == 'employee_number' ? sortActive.by : 'asc'">
                      {{ $t('employee.employee_number') }}
                    </span>
                  </th>
                  <th
                    class="table-sort"
                    :class="sortActive.column == 'name' && 'table-sort-active'"
                    @click="sortAction('name')"
                  >
                    <span :class="sortActive.column == 'name' ? sortActive.by : 'asc'">
                      {{ $t('employee.family_name') }}
                    </span>
                  </th>
                  <th>
                    <font style="vertical-align: inherit">
                      <font style="vertical-align: inherit">{{ $t('employee.position') }}</font>
                    </font>
                  </th>
                  <th>
                    <font style="vertical-align: inherit">
                      <font style="vertical-align: inherit">{{ $t('employee.passport') }}</font>
                    </font>
                  </th>
                  <th>
                    <font style="vertical-align: inherit">
                      <font style="vertical-align: inherit">{{ $t('employee.authority') }}</font>
                    </font>
                  </th>
                  <th>
                    <font style="vertical-align: inherit">
                      <font style="vertical-align: inherit">{{ $t('employee.is_deleted') }}</font>
                    </font>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  :class="item.deleted && 'text-muted'"
                  v-for="(item, index) in listEmployees"
                  :key="index"
                  @click="goToEmployeeDetail(item)"
                >
                  <td v-if="item.is_sb == 0"><i></i></td>
                  <td v-else><i class="info-item-linked"></i></td>
                  <td>
                    <font style="vertical-align: inherit">
                      <font style="vertical-align: inherit">{{ item.employee_number }}</font>
                    </font>
                  </td>
                  <td>
                    <font style="vertical-align: inherit">
                      <font style="vertical-align: inherit">{{ item.name }}</font>
                    </font>
                  </td>
                  <td>
                    <font style="vertical-align: inherit">
                      <font style="vertical-align: inherit">
                        {{ item.position ? item.position.management_title : '' }}
                      </font>
                    </font>
                  </td>
                  <td v-if="item.passport_check == 1">
                    <i class="icon-abui-check-inverted prx"></i>
                    {{ $t('employee.registered') }}
                  </td>
                  <td v-else>
                    <span class="text-warning">
                      {{ $t('employee.unregistered') }}
                    </span>
                  </td>
                  <td>
                    <font style="vertical-align: inherit">
                      <font style="vertical-align: inherit">{{ labelLocale(item.roles) }}</font>
                    </font>
                  </td>
                  <td>
                    <font style="vertical-align: inherit">
                      <font style="vertical-align: inherit">{{ item.deleted ? $t('employee.deleted') : '' }}</font>
                    </font>
                  </td>
                </tr>
              </tbody>
            </table>
            <p v-if="listEmployees.length == 0" class="no-data">{{ $t('common.no_data') }}</p>
          </div>
        </div>

        <Pagination
          v-if="listEmployees.length > 0"
          @change-page="changePageData"
          :paginate="paginate"
          @change-number="changeNumber"
        />
      </div>
    </div>
    <ModalUploadCSV
      :dialogVisible="showModalUploadCSV"
      @cancel="showModalUploadCSV = false"
      @confirm="confirmUploadFileCSV"
      :errorValidate="errorValidate"
      :title="$t('common.title_modal_upload_file_csv')"
    />
  </div>
</template>
<script>
import Pagination from '@/components/common/Pagination';
import ModalUploadCSV from '@/components/common/ModalUploadCSV';
import vClickOutside from 'click-outside-vue3';

export default {
  name: 'Employee',
  components: { Pagination, ModalUploadCSV },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      showModalUploadCSV: false,
      isShowDropdown: false,
      listEmployees: [],
      showBoxFilter: false,
      paginate: {
        page: +this.$route.query.page || 1,
        per_page: +this.$route.query.per_page || 10,
      },
      searchParams: {
        name: this.$route.query.name || '',
        passport_not_registered: this.$route.query.not_registered == 1,
        with_deleted: this.$route.query?.with_deleted ? this.$route.query.with_deleted == 1 : false,
      },
      errors: {},
      sortActive: {
        column: this.$route.query.sort_column || 'created_at',
        by: this.$route.query.sort_by || 'dsc',
      },
      errorValidate: [],
    };
  },
  watch: {
    searchParams: {
      handler: function (newValue) {
        if (this.strLen(newValue.name) > 100) {
          this.errors.name = this.$t('E-CM-111', { size: 100 });
        } else {
          delete this.errors.name;
        }
      },
      deep: true,
    },
    showModalUploadCSV() {
      this.errorValidate = [];
    },
  },
  created() {
    this.getListEmployee();
  },
  methods: {
    goToEmployeeDetail(item) {
      this.$router.push({
        name: this.PAGES.EMPLOYEE_DETAIL,
        params: {
          id: item.id,
        },
      });
    },
    changePageData(page) {
      this.paginate.page = +page;
      this.getListEmployee();
    },
    sortAction(name) {
      this.sortActive.column = name;
      if (this.sortActive.by == 'dsc') this.sortActive.by = 'asc';
      else this.sortActive.by = 'dsc';

      this.getListEmployee();
    },
    async getListEmployee() {
      this.startLoading();
      const dataFilter = {
        ...this.searchParams,
        page: this.paginate.page,
        per_page: this.paginate.per_page,
        sort_column: this.sortActive.column,
        sort_by: this.sortActive.by == 'dsc' ? 'desc' : 'asc',
      };

      if (this.$i18n.locale != 'ja') {
        dataFilter.locale = this.$i18n.locale;
      }

      await this.$services.employee.getListEmployees(
        dataFilter,
        res => {
          this.listEmployees = res.list;
          this.paginate = { ...this.paginate, ...res.paginate };
          this.showBoxFilter = false;
          this.endLoading();

          this.$router.push({
            path: this.$route.path,
            query: {
              page: res.paginate.current_page,
              per_page: res.paginate.per_page,
              name: this.searchParams.name,
              not_registered: this.searchParams.passport_not_registered ? 1 : 0,
              with_deleted: this.searchParams.with_deleted ? 1 : 0,
              sort_column: this.sortActive.column,
              sort_by: this.sortActive.by,
            },
          });
        },
        err => {
          this.endLoading();
          this.notifyError(this.$t(err.error));
        },
      );
    },
    changeNumber(number) {
      this.paginate = {
        ...this.paginate,
        page: 1,
        per_page: number,
      };
      this.getListEmployee();
    },
    clearFilter() {
      this.searchParams = {
        name: '',
        passport_not_registered: false,
        with_deleted: false,
      };
    },
    handleSearch() {
      if (!this.checkEmpty(this.errors)) return;

      this.paginate.page = 1;
      this.getListEmployee();
    },
    confirmUploadFileCSV(listFiles) {
      let formData = new FormData();
      if (listFiles.length > 0) {
        formData.append('file', listFiles[0]);
        this.errorValidate = [];
        this.startLoading();
        this.$services.employee.uploadFileCSV(
          formData,
          () => {
            this.endLoading();
            this.showModalUploadCSV = false;
            this.getListEmployee();
          },
          err => {
            this.endLoading();
            if (err.code == 422) {
              this.errorValidate = err.error;
            } else {
              this.notifyError(this.$t(err.error));
            }
          },
        );
      } else {
        this.notifyError(this.$t('E-CM-101', { name: this.$t('common.file') }));
      }
    },
    downloadFileCSV() {
      this.startLoading();
      this.$services.employee.downloadFileCSV(
        this.searchParams,
        res => {
          this.endLoading();
          if (res.file_path) this.handleDownloadCSV(res.file_path);
        },
        err => {
          this.endLoading();
          this.notifyError(this.$t(err.error));
        },
      );
    },
    downloadExampleFileCSV() {
      this.startLoading();
      this.$services.employee.downloadExampleFileCSV(
        res => {
          this.endLoading();
          if (res.file_path) this.handleDownloadCSV(res.file_path);
        },
        err => {
          this.endLoading();
          this.notifyError(this.$t(err.error));
        },
      );
    },
  },
};
</script>
