import BaseService from './BaseService';
import API_CODE from '../utils/api_code.js';

export default class CustomerEnterpriseAPI extends BaseService {
  async getListCustomerEnterprise(params, success, error) {
    await this.get(API_CODE.OP_135 + this.urlParse(params), success, error);
  }
  async getUserInformation(id, success, error) {
    await this.get(API_CODE.OP_106 + id, success, error);
  }
  async updateUserInformation(params, success, error) {
    await this.post(API_CODE.OP_107 + params.id, params, success, error);
  }
  async getListFile(params, success, error) {
    await this.get(API_CODE.OP_161 + this.urlParse(params), success, error);
  }
  async getCompanyInformation(id, success, error) {
    await this.get(API_CODE.OP_137 + id, success, error);
  }
  async updateCompanyInformation(params, success, error) {
    await this.post(API_CODE.OP_138 + params.id, params, success, error);
  }
  async createCompany(params, success, error) {
    await this.post(API_CODE.OP_139, params, success, error);
  }
}
