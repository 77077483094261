const API_CODE = {
  CU_015: 'customer/users/get/approval',
  // Auth
  CU_215: 'customer/users/current',
  CU_321: 'customer/users/saveColumnOrderList',
  CU_071: 'user/login',
  CU_072: 'user/verify/code',
  CU_005: 'user/forgot/password',
  CU_216: 'user/password/update',
  CU_177: 'user/logout',
  // Notification
  CU_060: 'customer/notifications/get/by-user',
  CU_319: 'customer/notifications/read/by-user',
  CU_061: 'customer/notifications/detail/',
  CU_065: 'customer/notifications/get',
  CU_090: 'customer/notifications/update/is-read/',
  CU_288: 'customer/notifications/update/is-unread/',
  CU_220: 'customer/notifications/count-unread',

  // Absent
  CU_067: 'customer/absents/get',
  CU_073: 'customer/absents/create',
  CU_074: 'customer/absents/update/',
  CU_075: 'customer/absents/delete/',
  CU_076: 'customer/absents/detail/',
  // Trips
  CU_006: 'customer/trips/list',
  CU_007: 'customer/trips/create',
  CU_008: 'customer/trips/detail/',
  CU_326: 'customer/trips/check-regulation/',
  CU_293: 'customer/trips/view/',
  CU_010: 'customer/trips/update/',
  CU_013: 'customer/insurances/upload',
  CU_042: 'customer/trips/need/approve/',
  CU_066: 'customer/requests/get/status',
  CU_068: 'customer/trips/get/condition',

  CU_114: 'customer/status/approval',
  CU_115: 'customer/status/report',
  CU_116: 'customer/status/expense',

  // Trip todos
  CU_035: 'customer/todos/get/by-trip',
  CU_036: 'customer/todos/detail/',
  CU_037: 'customer/todos/create',
  CU_038: 'customer/todos/delete/',
  CU_044: 'customer/todos/update/',

  //Applications
  CU_001: 'customer/requests/get',
  CU_002: 'customer/requests/approve',
  CU_170: 'customer/organizations/burden/list',
  CU_292: 'customer/requests/download/csv',

  // organizations
  CU_003: 'customer/organizations/list',

  // Get list country
  CU_069: 'customer/countries/list',

  // Get list safety info (list security)
  CU_004: 'customer/safety-info/list',

  // Get list airlines
  CU_070: 'customer/airlines/list',

  // Get list target person
  CU_064: 'customer/safety-info/get',
  CU_077: 'customer/safety-info/create',

  //daily allowance
  CU_185: 'customer/zones/get',
  CU_186: 'customer/zones/download/csv',
  CU_187: 'customer/zones/download/csv-sample',
  CU_188: 'customer/zones/upload',
  CU_201: 'customer/zones/detail/',
  CU_202: 'customer/zones/update/',
  CU_203: 'customer/zones/create',
  CU_204: 'customer/zones/delete/',
  CU_030: 'customer/daily-allowances/get',
  CU_034: 'customer/daily-allowances/detail',
  CU_031: 'customer/daily-allowances/update',
  CU_221: 'customer/daily-allowances/hotels/get',
  CU_140: 'customer/zones/cities',
  CU_141: 'customer/daily-allowances/get-by-company',
  CU_142: 'customer/daily-allowances/save',
  CU_281: 'customer/daily-allowances/upload/file',

  // security
  CU_078: 'customer/safety-info/detail/',
  CU_079: 'customer/safety-info/delete/',
  CU_080: 'customer/safe-info-user/send/all-user',
  CU_081: 'customer/safe-info-user/send/user/',
  CU_082: 'customer/safe-info-user/update/status/',
  CU_083: 'customer/safe-info-user/update/',
  CU_223: 'customer/safety-info/update-title/',
  CU_224: 'customer/safety-info/download/',

  // Time zone
  CU_023: 'customer/timezones/list',
  CU_247: 'customer/timezones/search',

  // Cost
  CU_024: 'customer/costs/get/by-trip',
  CU_043: 'customer/costs/create',
  CU_089: 'customer/costs/update/',
  CU_229: 'customer/costs/get/by-group-trip',
  CU_091: 'customer/costs/expenses/download',
  CU_092: 'customer/costs/get/expenses',
  CU_322: 'customer/costs/get/code-expenses',
  CU_102: 'customer/costs/create/transport/expense',
  CU_009: 'customer/requests/get/by-date',
  CU_046: 'customer/costs/delete/',
  CU_230: 'customer/costs/update/order',
  CU_040: 'customer/costs/upload',
  CU_283: 'customer/costs/duplicate',

  // Cost Item
  CU_120: 'customer/cost-item/list',
  CU_123: 'customer/cost-item/download',
  CU_128: 'customer/company-cost-items/expense',
  CU_143: 'customer/company-cost-items/parent-list',
  CU_144: 'customer/company-cost-items/create',
  CU_145: 'customer/company-cost-items/update/',
  CU_146: 'customer/company-cost-items/detail/',
  CU_147: 'customer/company-cost-items/delete/',
  CU_254: 'customer/company-cost-items/csv_sample',
  CU_255: 'customer/company-cost-items/upload',

  // Clip
  CU_025: 'customer/clips/get/by-trip',
  CU_045: 'customer/clips/delete/',
  CU_231: 'customer/clips/update/order',
  CU_054: 'customer/clips/create',
  CU_053: 'customer/clips/detail/',
  CU_055: 'customer/clips/update/',

  // Schedule
  CU_022: 'customer/schedule-type/list',
  CU_026: 'customer/schedules/detail',
  CU_028: 'customer/schedules/create',
  CU_017: 'customer/schedules/update/',
  CU_018: 'customer/schedules/delete/',
  CU_047: 'customer/locations/airports',
  CU_248: 'customer/locations/stations',
  CU_294: 'customer/timezones/search-by-airport',

  //Trip item api
  CU_094: 'customer/requests/detail/',
  CU_084: 'customer/requests/histories/',
  CU_098: 'customer/requests/execute',
  CU_103: 'customer/requests/get/settlement/',
  CU_104: 'customer/requests/settlement-application/send',
  CU_169: 'customer/organizations/parent/list',

  CU_021: 'customer/files/get/by-trip',
  CU_014: 'customer/trips/upload/file',

  CU_059: 'customer/trips/cancel/',
  CU_318: 'customer/trips/restore/',
  CU_062: 'customer/trips/delete/',

  CU_286: 'customer/trips/get/trip-sb',
  CU_287: 'customer/trips/clone/trip-sb',
  CU_290: 'customer/trips/override/trip-sb',

  CU_050: 'customer/currencies/list',
  CU_051: 'customer/cost-item/get',
  CU_052: 'customer/schedules/get/by-trip',
  CU_057: 'customer/payment-method/get',
  CU_058: 'customer/tax/classifications',
  CU_088: 'customer/costs/detail/',
  CU_048: 'customer/cost-item/get/by-trip',
  CU_049: 'customer/requests/settlement/send',
  CU_240: 'customer/requests/delete/',
  CU_056: 'customer/approval-flows/get/type',
  CU_093: 'customer/currencies/rate/',

  // Report
  CU_016: 'customer/reports/get/file-by-trip',
  CU_063: 'customer/reports/save',
  CU_323: 'customer/reports/delete/',

  //Trip business report
  CU_033: 'customer/costs/get/approval',
  CU_027: 'customer/costs/get/report',
  CU_012: 'customer/requests/approval/send',
  CU_019: 'customer/approval-flows/get-by-type',
  CU_314: 'customer/approval-flows/get-nearest',
  CU_029: 'customer/get/cost-bearing', //Removed - use 003 instead
  CU_020: 'customer/approval-flow-steps/list',
  CU_304: 'customer/requests/upload/file',
  // routes
  CU_096: 'customer/routes/get',

  CU_032: 'customer/requests/report/send',

  //Employee
  CU_246: 'customer/users/list',
  CU_232: 'customer/users/create',
  CU_297: 'customer/users/scan/passport',
  CU_298: 'customer/users/upload/file',
  CU_233: 'customer/users/detail/',
  CU_299: 'customer/users/profile',
  CU_234: 'customer/users/update',
  CU_108: 'customer/users/delete/',
  CU_317: 'customer/users/restore/',
  CU_259: 'customer/contacts/delete/',
  CU_258: 'customer/visa/delete/',
  CU_257: 'customer/season-ticket/delete/',
  CU_251: 'customer/users/download/csv',
  CU_252: 'customer/users/download/csv-sample',
  CU_253: 'customer/users/upload',

  //Position
  CU_127: 'customer/positions/list',
  CU_151: 'customer/positions/updates',
  CU_152: 'customer/positions/download/csv',
  CU_153: 'customer/positions/download/csv-sample',
  CU_154: 'customer/positions/upload',

  //Organization
  CU_157: 'customer/organizations/list',
  CU_158: 'customer/organizations/download/csv',
  CU_159: 'customer/organizations/download/csv-sample',
  CU_160: 'customer/organizations/upload',
  CU_171: 'customer/organizations/create',
  CU_172: 'customer/organizations/update/',
  CU_173: 'customer/organizations/detail/',
  CU_174: 'customer/organizations/delete/',

  //Settings
  CU_192: 'customer/setting/get',
  CU_193: 'customer/setting/update',
  CU_218: 'customer/setting/exchange-rate/get-file-csv',
  CU_256: 'customer/setting/exchange-rate/upload',
  CU_305: 'admin/setting/change-two-step-verification',
  CU_306: 'admin/setting/config',

  //Regulation
  CU_273: 'customer/areas/get',
  CU_274: 'customer/areas/upload',
  CU_275: 'customer/areas/download-csv',
  CU_276: 'customer/areas/download-csv-sample',
  CU_277: 'customer/areas/detail/',
  CU_278: 'customer/areas/update/',
  CU_279: 'customer/areas/create',
  CU_280: 'customer/areas/delete/',
  CU_134: 'customer/airports/list',
  CU_129: 'customer/regulations/save',
  CU_262: 'customer/regulations/get-by-company',
  CU_284: 'customer/regulations/upload',
  CU_324: 'customer/regulations/upload-csv-passport',
  CU_325: 'customer/regulations/download/csv-passport-sample',

  //Authority
  OP_105: 'customer/roles/list',

  //Visa
  CU_121: 'customer/visa/get',

  //Bank
  CU_124: 'customer/banks/list',
  CU_125: 'customer/bank-branch/list',

  //City
  CU_122: 'customer/cities/get',

  //Provinces
  CU_285: 'customer/provinces',

  //Zipcode
  OP_155: 'customer/contacts/detail/',

  //Relationships
  CU_222: 'customer/relationships/list',

  //season ticket
  CU_119: 'customer/season-ticket/get-company',

  //setting CIQ
  OP_166: 'operation/ciq/list',
  OP_178: 'operation/ciq/detail/',
  OP_180: 'operation/ciq/file/delete',
  OP_184: 'operation/ciq/upload/',
  OP_189: 'operation/ciq/update/',

  //Companies
  OP_135: 'operation/companies/get',

  //Approval Flow
  CU_206: 'customer/approval-flows/list',
  CU_249: 'customer/approval-flows/download/csv',
  CU_250: 'customer/approval-flows/download/csv-sample',
  CU_211: 'customer/approval-flows/upload',
  CU_209: 'customer/approval-flows/create',
  CU_207: 'customer/approval-flows/detail/',
  CU_208: 'customer/approval-flows/update/',
  CU_210: 'customer/approval-flows/delete/',

  // Customer Enterprise
  OP_137: 'operation/companies/detail/',
  OP_138: 'operation/companies/update/',
  OP_139: 'operation/companies/create',
  OP_161: 'operation/files/get/by-company',

  //NotificationOp
  OP_175: 'operation/notifications/list',
  OP_181: 'operation/users/get/by-company',
  CU_182: 'customer/notifications/get/type',
  OP_272: 'operation/notifications/update/',
  OP_190: 'operation/notifications/delete/',
  OP_271: 'operation/notifications/detail/',
  OP_268: 'operation/notifications/create',

  //User
  OP_100: 'operation/users/list',
  OP_106: 'operation/users/detail/',
  OP_264: 'operation/users/create',
  OP_107: 'operation/users/update/',
  OP_282: 'operation/users/delete/',
  OP_310: 'operation/users/update-role',
  // Trip OP
  OP_267: 'operation/trips/list',

  // Expense OP
  OP_265: 'operation/costs/get/expenses',

  // Get list safety info OP
  CU_266: 'operation/safety-info/list',

  // Requirement
  OP_165: 'operation/required-procedure/get',
  OP_164: 'operation/required-procedure/get-status',
  OP_195: 'operation/required-procedure/detail/',
  OP_196: 'operation/required-procedure/create',
  OP_197: 'operation/required-procedure/update/',
  OP_198: 'operation/required-procedure/delete/',
  OP_199: 'operation/required-procedure/send/notify',

  // Trip detail
  OP_269: 'operation/trips/detail/',

  // Input
  OP_101: 'operation/inputs/list',
  OP_111: 'operation/inputs/detail/',
  OP_112: 'operation/inputs/publish/',
  OP_109: 'operation/inputs/save/',
  CU_313: 'customer/inputs/update/',
  OP_309: 'operation/inputs/update-status/',
  OP_131: 'operation/inputs/editing/',
  OP_113: 'operation/inputs/next-data/',
  CU_099: 'customer/inputs/status',
  OP_200: 'operation/companies/get-setting-detail/',
  CU_320: 'customer/inputs/delete/',

  //Budget
  CU_213: 'customer/budget/get',
  CU_214: 'customer/budget/save',
  CU_226: 'customer/budget/download/csv',
  CU_227: 'customer/budget/download/csv-sample',
  CU_228: 'customer/budget/upload',
  //Regulation check
  OP_149: 'operation/regulations/list',
  OP_162: 'operation/regulations/check/',
  OP_163: 'operation/regulations/confirmed',
  // Ticket type
  CU_126: 'customer/tickets/list-type',

  // Get list station
  CU_291: 'customer/routes/station',

  //Areas country
  CU_303: 'customer/areas/countries',

  //Validate
  CU_315: 'validate/check-entity-id',
  CU_316: 'validate/check-sso-id',
};

export default API_CODE;
