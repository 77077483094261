<template>
  <el-select
    v-model="selectedList"
    :popper-append-to-body="false"
    popper-class="custom-dropdown-select"
    @visible-change="resetDataSelect"
    multiple
    clearable
    :title="displayByLocale ? $t('common.all') : $t('common.not_specified')"
    :placeholder="placeholder != '' ? placeholder : $t('common.not_specified')"
    :disabled="isDisabled"
  >
    <div class="bs-searchbox" onclick="event.stopPropagation()">
      <input
        v-if="isDisplaySearch"
        type="text"
        class="form-control"
        autocomplete="off"
        v-model="searchName"
        role="tetxbox"
        aria-label="Search"
      />
    </div>
    <div v-if="!checkEmpty(filteredSearchData)">
      <el-option
        v-for="(item, index) in filteredSearchData"
        :key="item.id"
        :label="displayByLocale ? this.labelLocale(item) : item[`${labelShow}`]"
        :value="item.id"
        :class="(index == 0 && isListCountry && 'border') || (item.id == -1 && 'border-top')"
      >
        <div class="el-custom-select-dropdown">
          <label
            :class="
              selectedList.includes(item.id) ? 'checkbox no-label no-hover checked' : 'checkbox no-label no-hover'
            "
          >
            <div>
              <span class="icons dropdown-option-icon">
                <span class="first-icon icon-abui-checkbox-unchecked" />
                <span class="second-icon icon-abui-checkbox-checked" />
              </span>
            </div>
          </label>
          <span class="dropdown-option-name">
            {{ displayByLocale ? this.labelLocale(item) : item[`${labelShow}`] }}
          </span>
        </div>
        <li class="divider" />
        <hr />
      </el-option>
    </div>
    <div v-else-if="checkEmpty(filteredSearchData) && !checkEmpty(listData)">
      <el-option value="" disabled>{{ " '" + searchName + "'" + $t('common.no_results_matched') }}</el-option>
    </div>
    <div v-else>
      <p class="no-data">{{ $t('common.no_data') }}</p>
    </div>
  </el-select>
</template>
<script>
import mixins from '@/helpers/mixins';

export default {
  name: 'MultiOptionSelectRegulation',
  emits: ['handleSelectedParams'],
  mixins: [mixins],
  props: {
    listData: {
      type: Array,
      default: () => [],
    },
    displayByLocale: {
      type: Boolean,
      default: false,
    },
    isDisplaySearch: {
      type: Boolean,
      default: true,
    },
    defaultList: {
      type: [Array, Object],
      default: () => [] || {},
    },
    labelShow: {
      type: String,
      default: () => 'name',
    },
    isListCountry: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    isSort: {
      type: Boolean,
      default: true,
    },
    optionIndex: {
      type: Object,
      default: () => {
        return {
          haveIndex: false,
          index: 0,
        };
      },
    },
  },
  data() {
    return {
      searchName: '',
      selectedList: [],
    };
  },
  created() {
    if (!this.checkEmpty(this.defaultList)) {
      if (typeof this.defaultList === 'object') {
        this.selectedList = Object.values(this.defaultList);
      } else {
        this.selectedList = this.defaultList;
      }
    }
  },
  computed: {
    filteredSearchData() {
      const lowerCaseSearch = this.searchName.toLowerCase();
      if (this.displayByLocale) {
        if (this.isSort)
          return this.sortCountryByName(
            this.listData.filter(item => this.labelLocale(item).toLowerCase().includes(lowerCaseSearch)),
          );

        return this.listData.filter(item => this.labelLocale(item).toLowerCase().includes(lowerCaseSearch));
      }

      if (this.isSort)
        return this.sortCountryByName(
          this.listData.filter(item => item[`${this.labelShow}`].toLowerCase().includes(lowerCaseSearch)),
        );

      return this.listData.filter(item => item[`${this.labelShow}`].toLowerCase().includes(lowerCaseSearch));
    },
  },
  watch: {
    defaultList(value) {
      if (typeof this.defaultList === 'object') {
        this.selectedList = Object.values(value);
      } else {
        this.selectedList = value;
      }
    },
    selectedList() {
      if (this.optionIndex.haveIndex) {
        this.$emit('handleSelectedParams', this.selectedList, this.optionIndex.index);
      } else {
        this.$emit('handleSelectedParams', this.selectedList);
      }
    },
    listData() {
      if (this.isSort) return this.sortCountryByName(this.listData);

      return this.listData;
    },
  },
  methods: {
    resetDataSelect() {
      this.searchName = '';
    },
    clearFilter() {
      if (!this.checkEmpty(this.defaultList)) {
        this.selectedList = this.defaultList;
        return;
      }
      this.selectedList = [];
    },
  },
};
</script>
<style scoped>
.dropdown-option-icon {
  margin-top: 50%;
}
.dropdown-option-icon span.first-icon.icon-abui-checkbox-unchecked {
  font-size: 15px;
  color: rgba(152, 169, 176, 0.5);
}
.dropdown-option-icon span.second-icon.icon-abui-checkbox-checked {
  font-size: 15px;
  color: rgba(255, 255, 255, 0.8) !important;
}
.dropdown-option-name {
  padding-left: 2.7rem;
}
.bs-searchbox {
  margin-bottom: 5px;
  padding-top: 0;
}
.no-hover {
  pointer-events: none !important;
}
.border {
  border-bottom: 1px solid #d0d6da;
}
</style>
