<template>
  <el-dialog
    v-model="isVisible"
    width="640px"
    @close="closeModal()"
    :show-close="true"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-header--title">{{ $t('trip_item.modal_approve.title') }}</div>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <p v-if="!checkEmpty(nextApproverInf)" class="modal--diaglog--text">
                {{ $t('trip_item.modal_approve.text') }}
              </p>
              <div v-if="!checkEmpty(nextApproverInf)" class="next-approver">
                <div class="next-approver-title">{{ $t('trip_item.modal_approve.next_approver_title') }}</div>
                <div v-for="(item, index) in nextApproverInf" :key="index" class="next-approver--item">
                  <div class="next-approver--item--code-name">
                    <span v-if="isShowUserId || isEmployeeManagerOperatorOrAdmin" class="next-approver--item--code">
                      {{ item.employee_number }}
                    </span>
                    <span class="next-approver--item--name">{{ item.name }}</span>
                  </div>
                  <span class="next-approver--item--org">{{ item.organization }}</span>
                </div>
              </div>
              <div class="form-group" v-if="!isStepView">
                <textarea
                  v-model="approvalMsg"
                  class="form-control fixed-width-textarea"
                  rows="6"
                  :placeholder="$t('trip_item.modal_approve.msg_placeholder')"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="row">
            <div class="col-xs-6 col-sm-6 col-md-6" @click="closeModal()">
              <button class="btn btn-sm btn-flat-default btn-block">{{ $t('common.cancel') }}</button>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6">
              <button
                class="btn btn-sm btn-primary btn-block open-alert"
                @click="emitApproveModal()"
                id="btn-do-approve"
              >
                {{ $t('trip_item.modal_approve.execute_approval') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: 'ModalApprove',
  emits: ['submitApproveModal', 'closeModal'],
  props: {
    isModalVisible: {
      type: Boolean,
      default: true,
    },
    nextApproverInf: {
      type: Array,
      default: () => [],
    },
    isStepView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isVisible: false,
      isNextApprover: true,
      approvalMsg: '',
    };
  },
  watch: {
    isModalVisible() {
      this.isVisible = this.isModalVisible;
    },
  },
  computed: {
    userId() {
      return this.$store.state.auth?.user?.id || '';
    },
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
    emitApproveModal() {
      let approver = this.nextApproverInf.find(arv => arv.approver_id == this.userId);
      if (this.checkEmpty(approver)) {
        this.isNextApprover = false
      }

      this.$emit('submitApproveModal', this.approvalMsg, this.isNextApprover);
      this.approvalMsg = '';
    },
  },
};
</script>
<style scoped></style>
