const initialState = { unread: 0 };

export const notification = {
  namespaced: true,
  state: initialState,
  actions: {
    totalNotificationUnread({ commit }, total) {
      commit('totalNotificationUnread', total);
    },
  },
  mutations: {
    totalNotificationUnread(state, total) {
      state.unread = total;
    },
  },
};
