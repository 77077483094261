<template>
  <nav class="navbar navbar-default navbar-lg" role="navigation" id="header-content">
    <div class="navbar-header">
      <h2 class="navbar-title">{{ $t('sidebar.regulation_area') }}</h2>
    </div>
  </nav>

  <body class="single">
    <el-dialog
      v-model="showModalListCountry"
      :show-close="false"
      title=""
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <div class="modal-header--title">{{ $t('zone.country') }}</div>
          </div>
          <div class="modal-body">
            <div class="section noborder">
              <div class="add-selected">
                <div class="row">
                  <div class="col-sm-12 col-md-12">
                    <div class="add-selected__wrap">
                      <div class="add-selected__wrap__list">
                        <ul class="add-selected-list">
                          <li
                            v-for="(item, index) in listName"
                            :key="index"
                            :class="index == listName.length - 1 && 'last-country-item'"
                          >
                            <span v-if="!checkEmpty(item.id) && !checkEmpty(item.code)">
                              {{ item.name }}
                              <span class="icon icon-abui-cross" @click="removeCountry(item, index)"></span>
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div class="add-selected__wrap__btn" @click="deleteAllCountry" v-on:click.stop>
                        <button class="btn btn-sm js-btn--del-selected-list-item">
                          <span class="icon-abui-trash"></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-12 col-md-12">
                    <div class="js-refine-search" data-container-id="refine-tgtlist--modal2">
                      <table class="table table-striped table-word-seach">
                        <thead>
                          <tr>
                            <th>
                              <div class="input-group input-group-sm input-group-rounded" :class="{ focus: hasFocus }">
                                <span class="input-group-btn">
                                  <button type="submit" class="btn"><span class="icon-abui-search"></span></button>
                                </span>
                                <input
                                  type="text"
                                  v-model="searchKeyword"
                                  class="form-control js-refine-search__input"
                                  :placeholder="$t('zone.placeholder_search_country')"
                                  @focus="onFocus"
                                  @blur="onBlur"
                                />
                              </div>
                            </th>
                          </tr>
                        </thead>
                      </table>
                      <div class="innerScrollFade">
                        <div id="innerScroll--add-selected-table2" style="overflow: auto; height: 233px">
                          <table class="table table-striped table-hover table-word-seach__body">
                            <colgroup span="1"></colgroup>
                            <colgroup span="1" style="width: 44px"></colgroup>
                            <tbody id="refine-tgtlist--modal2">
                              <tr v-if="listFilter.length == listCountryToEdit.length" class="js-refine-search__item">
                                <td class="js-refine-search__text">{{ labelLocale(itemAll) }}</td>
                                <td class="cell-control" @click="addAllCountry()">
                                  <a class="btn btn-flat-default btn-sm js-btn--add-selected-list-item">
                                    <span class="icon-abui-plus"></span>
                                    <input type="checkbox" hidden v-model="isCheckAll" />
                                  </a>
                                </td>
                              </tr>
                              <tr class="js-refine-search__item" v-for="(item, index) in listFilter" :key="index">
                                <td
                                  class="js-refine-search__text"
                                  :style="item.children && 'padding-left: 32px !important;'"
                                >
                                  {{ item.name }}
                                </td>
                                <td class="cell-control" @click="addCountry(item)">
                                  <a class="btn btn-flat-default btn-sm js-btn--add-selected-list-item">
                                    <span class="icon-abui-plus"></span>
                                  </a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="row">
              <div class="col-xs-6 col-sm-6 col-md-6">
                <button class="btn btn-flat-default btn-block" @click="cancelListZoneCountry">
                  {{ $t('common.cancel') }}
                </button>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-6">
                <button class="btn btn-flat-primary btn-block btn--feedback" @click="updateListZoneCountry">
                  {{ $t('common.confirm') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <div class="wrapper">
      <div class="container-fluid">
        <div class="section noborder" id="section--main">
          <div class="mbl">
            <p>{{ $t('regulation.define_the_area_reference') }}</p>
          </div>

          <div class="">
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label>
                    <span class="label-text">{{ $t('regulation.regulation_area_name') }}</span>
                  </label>
                  <input
                    name="area_name"
                    type="text"
                    class="form-control input-sm"
                    :class="!checkEmpty(errors.area_name) && 'has-validation-error'"
                    :placeholder="$t('common.required')"
                    id="area_name"
                    maxlength="150"
                    v-model.trim="areaName"
                  />
                  <p role="alert" class="error-feedback" v-text="errors.area_name"></p>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-10">
                <div class="form-group viewonly">
                  <label>
                    <span class="label-text">{{ $t('zone.country') }}</span>
                  </label>
                  <p class="viewonly-text input-sm input--fltr-country-multi">
                    <span v-for="(item, index) in area.countries" :key="index">
                      {{ item.name }}
                      <span v-if="index < area.countries.length - 1">{{ $t('common.comma') }}</span>
                    </span>
                  </p>
                  <p role="alert" class="error-feedback" v-text="errors.countries"></p>
                </div>
              </div>
              <div class="col-sm-2">
                <a class="btn btn-sm btn-inverse btn-block btn--ls" @click="showModalListCountry = true">
                  {{ $t('zone.reference') }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="navbar navbar-default navbar-fixed-bottom navbar-footblock with-sidebar submit-new--wrap show--estimate"
        role="navigation"
        style="z-index: 990; padding: 12px"
      >
        <div class="row">
          <div class="col-sm-3">
            <a class="btn btn-sm btn-flat-default" @click="showModalConfirm = true" v-if="areaId">
              <i class="icon-abui-trash prx"></i>{{ $t('regulation.delete_rule_area') }}
            </a>
          </div>
          <div class="col-sm-4 col-md-3 col-sm-offset-5 col-md-offset-6">
            <button type="submit" class="btn btn-sm btn-block btn-primary open-alert" @click="submitData">
              {{ $t('common.submit') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <ModalConfirm
      :dialogVisible="showModalConfirm"
      @cancel="showModalConfirm = false"
      @yes="deleteArea()"
      :title="$t('Q-CM-104')"
    />
  </body>
</template>
<script>
import ModalConfirm from '@/components/common/ModalConfirm';
export default {
  name: 'RegulationAreaDetail',
  components: { ModalConfirm },
  data() {
    return {
      area: {},
      areaOfficial: {},
      areaName: '',
      areaNameOfficial: '',
      showModalConfirm: false,
      showModalListCountry: false,
      listZoneCountryToEdit: [],
      listCountryToEdit: [],
      searchKeyword: '',
      errors: {
        countries: '',
        area_name: '',
      },
      hasFocus: false,
      isSubmit: false,
      itemAll: {
        id: 0,
        en_name: 'すべて',
        ja_name: 'すべて',
        code: 'すべて',
      },
      isCheckAll: false,
    };
  },
  beforeRouteLeave(to, from, next) {
    if (
      (!this.compareObject(this.area, this.areaOfficial) || this.areaName !== this.areaNameOfficial) &&
      !this.isSubmit
    ) {
      const answer = window.confirm(this.$t('common.confirm_leave_page'));
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  watch: {
    areaName: {
      handler: function (value) {
        if (this.strLen(value) > 150) {
          this.errors.area_name = this.$t('E-CM-111', { size: 150 });
        } else {
          this.errors.area_name = '';
        }
      },
      deep: true,
    },
  },
  computed: {
    areaId() {
      return this.$route.params.id;
    },
    listFilter() {
      if (this.searchKeyword == '') return this.sortCountryByName(this.cloneObject(this.listCountryToEdit));
      const originalCountriesList = this.cloneObject(this.listCountryToEdit);
      let arrCountries = [];
      this.listCountryToEdit.forEach(item => {
        //Check if search key map to parent name
        if (
          item.name.includes(this.searchKeyword.toLowerCase()) &&
          this.checkEmpty(item.parent) &&
          !arrCountries.includes(item)
        ) {
          const parentName = item.name;
          arrCountries.push(item);
          originalCountriesList.forEach(country => {
            if (!this.checkEmpty(country.parent) && country.parent == parentName && !arrCountries.includes(country))
              arrCountries.push(country);
          });
          //Check if search key map to child name
        } else if (
          item.name.includes(this.searchKeyword.toLowerCase()) &&
          !this.checkEmpty(item.parent) &&
          !arrCountries.includes(item)
        ) {
          const parentName = item.parent;
          const isChildNotExisted = this.checkEmpty(
            arrCountries.filter(country => country.id == item.id && !this.checkEmpty(country.parent)),
          );
          const isParentNotExisted = this.checkEmpty(
            arrCountries.filter(country => country.name == parentName && this.checkEmpty(country.parent)),
          );
          if (isParentNotExisted) {
            originalCountriesList.forEach(country => {
              if (country.name == parentName && this.checkEmpty(country.parent) && !arrCountries.includes(country)) {
                arrCountries.push(country);
                if (isChildNotExisted) arrCountries.push(item);
              }
            });
          }
          //Do nothing if search key doesn't map any child name or parent name
        }
      });
      return this.sortCountryByName(arrCountries);
    },
    listName() {
      return this.sortCountryByName(
        this.listZoneCountryToEdit.filter(item => {
          return !this.checkEmpty(item.id) && !this.checkEmpty(item.code);
        }),
      );
    },
  },
  created() {
    this.startLoading();
    Promise.all([this.getAreaDetail(), this.getListCountry()]).then(() => {
      this.endLoading();
    });
  },
  methods: {
    onFocus() {
      this.hasFocus = true;
    },
    onBlur() {
      this.hasFocus = false;
    },
    async getAreaDetail() {
      if (!this.areaId) return;
      await this.$services.regulation.getAreaDetail(
        this.areaId,
        res => {
          let response = this.cloneObject(res);

          this.area = {
            id: response.id,
            code: response.code,
            en_name: response.en_name,
            ja_name: response.ja_name,
            created_at: response.created_at,
            updated_at: response.updated_at,
            countries: [],
          };
          if (!this.checkEmpty(response.countries)) {
            response.countries.forEach(country => {
              let item = {
                id: country.id,
                code: country.code,
                name: this.labelLocale(country),
              };
              this.area.countries.push(item);
            });
          }
          this.areaName = this.labelLocale(this.area);
          this.areaOfficial = this.cloneObject(res);
          this.areaNameOfficial = this.labelLocale(this.areaOfficial);
          let listCountries = this.cloneObject(res.countries);
          listCountries.forEach(country => {
            let item = {
              id: country.id,
              code: country.code,
              name: this.labelLocale(country),
            };

            this.listZoneCountryToEdit.push(item);
          });
        },
        err => {
          this.notifyError(this.$t(err.error));
          this.$router.push(this.PAGES.REGULATION_AREA);
        },
      );
    },
    async getListCountry() {
      await this.$services.common.getListAreaCountry(
        res => {
          this.handleListAreaCountry(res.list);
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    handleListAreaCountry(listData) {
      listData.forEach(area => {
        let item = {
          id: '',
          code: '',
          name: area.area_name,
        };
        this.listFilter.push(item);
        this.listCountryToEdit.push(item);

        if (!this.checkEmpty(area.countries)) {
          area.countries.forEach(country => {
            let ctr = {
              id: country.id,
              code: country.code,
              name: country.name,
              children: true,
              parent: area.area_name,
            };
            this.listFilter.push(ctr);
            this.listCountryToEdit.push(ctr);
          });
        }
      });
    },
    deleteArea() {
      this.isSubmit = true;
      this.startLoading();
      this.$services.regulation.deleteArea(
        this.areaId,
        () => {
          this.showModalConfirm = false;
          this.$router.push(this.PAGES.REGULATION_AREA);
          this.notifySuccess(this.$t('I-CM-102'));
        },
        err => {
          this.endLoading();
          this.showModalConfirm = false;
          this.notifyError(this.$t(err.error));
        },
      );
    },
    submitData() {
      let flgValidate = true;
      if (this.area?.countries && this.area?.countries.length > 0) {
        this.errors.countries = '';
      } else {
        flgValidate = false;
        this.errors.countries = this.$t('E-CM-100', { item: this.$t('zone.country') });
      }

      if (!this.checkEmpty(this.areaName)) {
        if (this.strLen(this.areaName) > 150) {
          flgValidate = false;
          this.errors.area_name = this.$t('E-CM-111', { size: 150 });
        } else {
          this.errors.area_name = '';
        }
      } else {
        flgValidate = false;
        this.errors.area_name = this.$t('E-CM-100', { item: this.$t('regulation.regulation_area_name') });
      }

      if (!flgValidate) return;

      const locale = this.$i18n.locale;
      if (locale === 'ja') {
        this.area.ja_name = this.areaName;
      } else {
        this.area.en_name = this.areaName;
      }

      if (this.areaId) {
        this.updateArea();
      } else {
        this.createArea();
      }
    },
    updateArea() {
      this.isSubmit = true;
      const params = {
        id: this.areaId,
        area: {
          area_name: this.areaName,
          countries: [],
          updated_at: this.area.updated_at,
        },
      };
      this.area.countries.forEach(country => {
        params.area.countries.push(country.id);
      });
      this.startLoading();
      this.$services.regulation.updateArea(
        params,
        () => {
          this.getAreaDetail();
          this.getListCountry();
          this.showModalConfirm = false;
          this.showModalListCountry = false;
          this.notifySuccess(this.$t('I-CM-103'));
          this.$router.push(this.PAGES.REGULATION_AREA);
        },
        err => {
          this.endLoading();
          if (err.error == 'E-CM-400-1') {
            this.notifyError(this.$t(err.error), true);
            return;
          }
          this.notifyError(this.$t('E-CM-107', { item: this.$t('regulation.regulation_area_name') }));
        },
      );
    },
    createArea() {
      this.isSubmit = true;
      const params = {
        area_name: this.areaName,
        countries: [],
      };
      this.area.countries.forEach(country => {
        params.countries.push(country.id);
      });
      this.startLoading();
      this.$services.regulation.createArea(
        params,
        () => {
          this.showModalConfirm = false;
          this.showModalListCountry = false;
          this.$router.push(this.PAGES.REGULATION_AREA);
        },
        () => {
          this.endLoading();
          this.notifyError(this.$t('E-CM-107', { item: this.$t('regulation.regulation_area_name') }));
        },
      );
    },
    removeCountry(item, index) {
      if (this.listZoneCountryToEdit[index] === item) {
        this.listZoneCountryToEdit.splice(index, 1);
      } else {
        let found = this.listZoneCountryToEdit.indexOf(item);
        this.listZoneCountryToEdit.splice(found, 1);
      }
    },
    addCountry(item) {
      if (!this.checkEmpty(item.id) && !this.checkEmpty(item.code)) {
        let check = 0;
        this.listZoneCountryToEdit.forEach(country => {
          if (country.name == item.name) {
            check++;
          }
        });
        if (check > 0) {
          this.notifyError(this.$t('E-CM-107', { item: this.$t('zone.country') }));
        } else {
          this.listZoneCountryToEdit.push(item);
        }

        if (this.listZoneCountryToEdit?.length == this.listFilter?.length) {
          this.isCheckAll = true;
        }
      } else {
        let listCountry = this.cloneObject(this.listFilter);
        let listChildren = listCountry.filter(
          country => !this.checkEmpty(country.parent) && country.parent == item.name,
        );
        if (this.isSubsetCountryArray(this.listName, listChildren)) {
          this.notifyError(this.$t('E-CM-107', { item: this.$t('zone.country') }));
          return;
        }
        listChildren.forEach(children => {
          let checkItem = this.listZoneCountryToEdit.find(country => country.id == children.id);
          if (this.checkEmpty(checkItem)) {
            this.listZoneCountryToEdit.push(children);
          }
        });
      }
    },
    addAllCountry() {
      this.isCheckAll = !this.isCheckAll;
      if (this.isCheckAll) {
        this.listZoneCountryToEdit = this.cloneObject(this.listFilter);
      } else {
        this.listZoneCountryToEdit = [];
      }
    },
    deleteAllCountry() {
      this.listZoneCountryToEdit = [];
      this.isCheckAll = false;
    },
    updateListZoneCountry() {
      if (this.listZoneCountryToEdit.length > 0) {
        this.area.countries = [];
        let listZoneEdit = this.cloneObject(this.listZoneCountryToEdit);
        listZoneEdit.forEach(zone => {
          if (!this.checkEmpty(zone.id) && !this.checkEmpty(zone.code)) {
            let item = {
              id: zone.id,
              code: zone.code,
              name: zone.name,
            };
            this.area.countries.push(item);
          }
        });
        this.showModalListCountry = false;
      } else {
        this.notifyError(this.$t('E-CU-057'));
      }
    },
    cancelListZoneCountry() {
      this.showModalListCountry = false;
      this.isCheckAll = false;
      if (!this.checkEmpty(this.area.countries)) {
        this.listZoneCountryToEdit = [];
        let listCountries = this.cloneObject(this.area.countries);
        listCountries.forEach(country => {
          let item = {
            id: country.id,
            code: country.code,
            name: country.name,
          };

          this.listZoneCountryToEdit.push(item);
        });
      } else {
        this.listZoneCountryToEdit = [];
      }
    },
  },
};
</script>
