import BaseService from './BaseService';
import API_CODE from '../utils/api_code.js';

export default class TripAPI extends BaseService {
  async getListTrips(params, query, success, error) {
    await this.get(API_CODE.CU_006 + this.urlParse(params, query), success, error);
  }
  async getListTripsOP(params, query, success, error) {
    await this.get(API_CODE.OP_267 + this.urlParse(params, query), success, error);
  }
  async createTrip(params, success, error) {
    await this.post(API_CODE.CU_007, params, success, error);
  }
  async tripDetail(id, success, error) {
    await this.get(API_CODE.CU_008 + id, success, error);
  }
  async checkRegulationTrip(id, success, error) {
    await this.get(API_CODE.CU_326 + id, success, error);
  }
  async tripDetailRequest(id, success, error) {
    await this.get(API_CODE.CU_293 + id, success, error);
  }
  async tripDetailOP(id, success, error) {
    await this.get(API_CODE.OP_269 + id, success, error);
  }
  async updateInsurance(params, success, error) {
    await this.post(API_CODE.CU_013, params, success, error);
  }
  async updateTrip(trip_id, params, success, error) {
    await this.post(API_CODE.CU_010 + trip_id, params, success, error);
  }
  async needApproveTrip(trip_id, success, error) {
    await this.post(API_CODE.CU_042 + trip_id, {}, success, error);
  }
  async getListTodos(params, success, error) {
    await this.get(API_CODE.CU_035 + this.urlParse(params), success, error);
  }
  async todoDetail(todo_id, success, error) {
    await this.get(API_CODE.CU_036 + todo_id, success, error);
  }
  async createTodo(params, success, error) {
    await this.post(API_CODE.CU_037, params, success, error);
  }
  async deleteTodo(todo_id, success, error) {
    await this.delete(API_CODE.CU_038 + todo_id, {}, success, error);
  }
  async updateTodo(todo_id, params, success, error) {
    await this.post(API_CODE.CU_044 + todo_id, params, success, error);
  }
  async getFilesByTrip(params, success, error) {
    await this.get(API_CODE.CU_021 + this.urlParse(params), success, error);
  }
  async uploadFile(params, success, error) {
    await this.post(API_CODE.CU_014, params, success, error);
  }
  async getCostItemList(success, error) {
    await this.get(API_CODE.CU_051, success, error);
  }
  async getCostItemListInput(params, success, error) {
    await this.get(API_CODE.CU_051 + this.urlParse(params), success, error);
  }
  async getScheduleByTripList(params, success, error) {
    await this.get(API_CODE.CU_052 + this.urlParse(params), success, error);
  }
  async getCostDetail(id, success, error) {
    await this.get(API_CODE.CU_088 + id, success, error);
  }
  async getCostByTrip(params, success, error) {
    await this.get(API_CODE.CU_048 + this.urlParse(params), success, error);
  }
  async sendSettlement(params, success, error) {
    await this.post(API_CODE.CU_049, params, success, error);
  }
  async getApprovalFlow(params, success, error) {
    await this.get(API_CODE.CU_056 + this.urlParse(params), success, error);
  }
  async getApprovalFlowByType(params, success, error) {
    await this.get(API_CODE.CU_019 + this.urlParse(params), success, error);
  }
  async getApprovalFlowNearest(params, success, error) {
    await this.get(API_CODE.CU_314 + this.urlParse(params), success, error);
  }
  async getApprovalFlowStepList(params, success, error) {
    await this.get(API_CODE.CU_020 + this.urlParse(params), success, error);
  }
  async costUpload(params, success, error) {
    await this.post(API_CODE.CU_040, params, success, error);
  }
  async createCost(params, success, error) {
    await this.post(API_CODE.CU_043, params, success, error);
  }
  async updateCost(id, params, success, error) {
    await this.post(API_CODE.CU_089 + id, params, success, error);
  }
  async getCurrencyRate(id, success, error) {
    await this.get(API_CODE.CU_093 + id, success, error);
  }
  async cancelTrip(trip_id, success, error) {
    await this.post(API_CODE.CU_059 + trip_id, {}, success, error);
  }
  async restoreTrip(trip_id, success, error) {
    await this.post(API_CODE.CU_318 + trip_id, {}, success, error);
  }
  async deleteTrip(trip_id, success, error) {
    await this.delete(API_CODE.CU_062 + trip_id, {}, success, error);
  }
  async cancelRingi(request_id, success, error) {
    await this.delete(API_CODE.CU_240 + request_id, {}, success, error);
  }
  async duplicateCost(params, success, error) {
    await this.post(API_CODE.CU_283, params, success, error);
  }
  async getListTripSB(success, error) {
    await this.get(API_CODE.CU_286, success, error);
  }
  async syncTripFromSB(params, success, error) {
    await this.post(API_CODE.CU_287, params, success, error);
  }
  async overrideTripFromSB(params, success, error) {
    await this.post(API_CODE.CU_290, params, success, error);
  }
}
