<template>
  <el-dialog
    v-model="dialogVisibleStatus"
    :show-close="false"
    :modal="showMask"
    @close="closeModal()"
    width="736px"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <button type="button" class="close" @click="closeModal()">
          <span class="icon-abui-cross" aria-hidden="true"></span>
        </button>
        <div class="modal-header" style="display: flex; justify-content: space-between">
          <div class="modal-header--title col-sm-12" v-if="!isTransportType" style="width: 100%">{{ $t('expense_cost.modal_title') }}</div>
          <div class="modal-header--title col-sm-12" v-if="isTransportType" style="width: 100%; padding: 0px;">{{ $t('expense.dropdown.option1') }}
            <span v-if="expenseCostDetail.transportation_cost_type == 0">
                        （{{ $t('transportation.round_ticket') }}）
                      </span>
            <span v-else>（{{ $t('transportation.one_way_ticket') }}）</span></div>
          <button
            v-if="
              !flgCreate &&
              !isOperatorOrAdmin &&
              !expenseCostDetail.is_deleted &&
              !expenseCostDetail.disable_btn &&
              !viewOnly &&
              isLastExpenseRequestFinished
            "
            class="btn btn-sm btn-flat-default"
            @click="showBoxConfirm = true"
          >
            <i class="icon-abui-trash prx"></i>
            {{ $t('transportation.remove') }}
          </button>
        </div>
        <div class="modal-body">
          <Form @submit.prevent="submitForm" :validation-schema="validate" ref="form">
            <Field name="currency_id" type="hidden" v-model="dataRequest.currency_id" />
            <Field name="tax_type" type="hidden" v-model="dataRequest.tax_type" />
            <Field name="payment_method" type="hidden" v-model="dataRequest.payment_method" />
            <Field name="cost_item" type="hidden" v-model="dataRequest.cost_item" />
            <div>
              <div v-if="viewOnly || isOperatorOrAdmin || !isLastExpenseRequestFinished" class="row">
                <div class="col-sm-12" v-if="!isTransportType">
                  <div class="form-group">
                    <div v-if="!checkEmpty(listFile)" class="expense--detail--detected">
                      <a style="display: block" v-for="(file, index) in listFile" :key="index">
                        <span @click="openReceipt(file)" class="cursor-pointer">
                          <i class="icon-abui-scan-check prx" />
                          {{ file.name }}
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="row">
                <div class="col-sm-12" v-if="!isTransportType">
                  <div class="form-group">
                    <label>
                      <span class="label-text">{{ $t('expense_cost.files') }}</span>
                    </label>
                    <button
                      v-if="!isOperatorOrAdmin || !viewOnly"
                      :disabled="isTransportType || !isLastExpenseRequestFinished"
                      :class="(isTransportType || !isLastExpenseRequestFinished) && 'disabled'"
                      type="button"
                      class="btn btn-inverse btn-sm"
                      @click="$emit('showUploadFile')"
                    >
                      <i class="icon-abui-upload prx"></i>{{ $t('common.upload') }}
                    </button>
                    <p role="alert" class="error-feedback" v-text="errors.files" />
                    <div v-if="!checkEmpty(listFile)" style="margin-top: 10px">
                      <div
                        v-for="(file, i) in listFile"
                        :key="i"
                        class="btn-sm file-preview cursor-pointer"
                        @click="openInvoice(file)"
                      >
                        <span class="thumbnail--voucher--filename">{{ file.name }}</span>
                        <i class="icon-abui-cross pls cursor-pointer" @click.stop="removeFile(file, i)"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <label>
                      <span class="label-text">{{ $t('expense_cost.title') }}</span>
                    </label>
                    <Field
                      name="title"
                      type="text"
                      style="background: white"
                      class="form-control input-sm"
                      v-model="dataRequest.title"
                      maxlength="100"
                      :disabled="isOperatorOrAdmin || viewOnly || !isLastExpenseRequestFinished"
                      :placeholder="$t('common.required')"
                    />
                    <ErrorMessage name="title" as="p" class="error-feedback" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>
                      <span class="label-text">{{ $t('expense_cost.payment_date') }}</span>
                    </label>
                    <div class="input-with-icon">
                      <el-date-picker
                        class="date-input"
                        v-model="dataRequest.payment_date"
                        :clearable="false"
                        type="date"
                        format="YYYY/MM/DD"
                        value-format="YYYYMMDD"
                        :placeholder="$t('common.enter_or_select_required')"
                        :disabled="isOperatorOrAdmin || viewOnly || !isLastExpenseRequestFinished"
                      />
                    </div>
                    <Field name="payment_date" type="hidden" v-model="dataRequest.payment_date" />
                    <ErrorMessage name="payment_date" class="error-feedback" as="p" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <label>
                      <span class="label-text">{{ $t('expense_cost.payment_to') }}</span>
                    </label>
                    <Field
                      name="payment_to"
                      type="text"
                      style="background: white"
                      class="form-control input-sm"
                      v-model="dataRequest.payment_to"
                      maxlength="100"
                      :disabled="isOperatorOrAdmin || viewOnly || !isLastExpenseRequestFinished"
                    />
                    <ErrorMessage name="payment_to" class="error-feedback" as="p" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <label>
                      <span class="label-text">{{ $t('expense_cost.cost_item') }}</span>
                    </label>
                    <SingleGroupOptionSelectExpenseCostItem
                      ref="CompanyCostItemSelect"
                      :list-data="listCostItem"
                      :disabled="isOperatorOrAdmin || viewOnly || !isLastExpenseRequestFinished"
                      :initItem="dataRequest.cost_item"
                      @handle-selected-params="onChangeCostType"
                      :placeholder="$t('trip_item.right_menu.manual_input_model.unspecified_required')"
                    />
                    <span
                      v-if="dataRequest.company_cost_item?.is_valid === 0"
                      v-text="$t('trip.cost_is_deactivated')"
                      class="error-feedback"
                    />
                    <ErrorMessage name="cost_item" class="error-feedback" as="p" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <label>
                      <span class="label-text">{{ $t('trip_item.right_menu.manual_input_model.expense_code') }}</span>
                    </label>
                    <input
                      type="text"
                      class="form-control input-sm max-textarea"
                      :value="dataRequest.expense_code"
                      @input="dataRequest.expense_code = $event.target.value.toUpperCase()"
                      maxlength="15"
                      :disabled="isOperatorOrAdmin || viewOnly || !isLastExpenseRequestFinished"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>
                      <span class="label-text">{{ $t('expense_cost.currency') }}</span>
                    </label>
                    <el-select
                      popper-class="custom-dropdown-select"
                      v-model="dataRequest.currency_id"
                      :disabled="isOperatorOrAdmin || viewOnly || !isLastExpenseRequestFinished"
                    >
                      <el-option
                        v-for="item in currencyList"
                        @click="dataRequest.tax_type = 3"
                        :key="item.id"
                        :label="labelLocale(item) + '(' + item.code + ')'"
                        :value="item.id"
                      />
                    </el-select>
                  </div>
                </div>
                <div v-if="dataRequest.currency_id == 1" class="col-sm-6 currency__info-col_1">
                  <div class="form-group">
                    <label>
                      <span class="label-text">{{ $t('expense_cost.amount') }}</span>
                      <span class="change-eki-cost" v-if="isChangeEki">{{ $t('expense.with_modification') }}</span>
                    </label>
                    <div class="input-group">
                      <span class="input-group-addon">
                        <span class="">¥</span>
                      </span>
                      <input
                        name="amount"
                        type="text"
                        style="background: white; text-align: right"
                        class="form-control input-sm"
                        v-model="dataRequest.amount"
                        maxlength="15"
                        :disabled="isOperatorOrAdmin || viewOnly || !isLastExpenseRequestFinished"
                        onkeydown="javascript: return event.keyCode !== 69;"
                        @input="dataRequest.amount = convertNumberFullToHalf(dataRequest.amount)"
                        @change="convertDataRequestAmount(dataRequest.amount)"
                        :placeholder="$t('common.required')"
                      />
                    </div>
                    <p name="amount" class="error-feedback" as="p" v-text="errors.amount" />
                  </div>
                </div>
                <div v-if="dataRequest.currency_id != 1" class="col-sm-6">
                  <div class="form-group">
                    <label>
                      <span class="label-text">{{ $t('expense_cost.rate') }}</span>
                    </label>
                    <Field
                      name="currency_rate"
                      type="text"
                      style="text-align: right"
                      class="form-control input-sm"
                      v-model="dataRequest.currency_rate"
                      :readonly="!hasOverRightRate"
                      maxlength="11"
                      :disabled="isOperatorOrAdmin || viewOnly || !isLastExpenseRequestFinished"
                      :class="
                        !checkEmpty(costDetailRate) &&
                        dataRequest.currency_rate != costDetailRate &&
                        'feedback-overwrite'
                      "
                      @input="dataRequest.currency_rate = convertNumberFullToHalfHasDot(dataRequest.currency_rate)"
                      @change="convertData(dataRequest.currency_rate)"
                    />
                    <p
                      v-if="!checkEmpty(costDetailRate) && dataRequest.currency_rate != costDetailRate"
                      class="help-block text-right text-info"
                    >
                      {{ $t('common.changed') }}
                    </p>
                    <ErrorMessage name="currency_rate" class="error-feedback" as="p" />
                  </div>
                </div>
              </div>
              <div v-if="dataRequest.currency_id != 1" class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>
                      <span class="label-text">{{ $t('expense_cost.currency_amount') }}</span>
                    </label>
                    <div class="input-group">
                      <span class="input-group-addon">
                        <span class="currency__info-col_1">
                          {{ signalCurrency }}
                        </span>
                      </span>
                      <input
                        name="currency_amount"
                        type="text"
                        style="background: white"
                        class="form-control input-sm text-right"
                        onkeydown="javascript: return event.keyCode !== 69;"
                        v-model="dataRequest.currency_amount"
                        @input="
                          dataRequest.currency_amount = convertNumberFullToHalfHasDot(dataRequest.currency_amount)
                        "
                        @change="convertCurrencyAmount(dataRequest.currency_amount)"
                        maxlength="15"
                        :disabled="isOperatorOrAdmin || viewOnly || !isLastExpenseRequestFinished"
                        :placeholder="$t('common.required')"
                      />
                    </div>
                    <p name="amount" class="error-feedback" as="p" v-text="errors.currency_amount" />
                    <!-- <ErrorMessage name="currency_amount" class="error-feedback" as="p" /> -->
                  </div>
                </div>
                <div v-if="dataRequest.currency_id != 1" class="col-sm-6">
                  <div class="form-group viewonly">
                    <label>
                      <span class="label-text">{{ $t('expense_cost.amount') }}</span>
                    </label>
                    <div class="input-group">
                      <span class="input-group-addon">
                        <span class="">{{ $t('trip.yen') }}</span>
                      </span>
                      <p class="viewonly-text input-sm text-right">
                        {{ splitThousandNumber(this.dataRequest.amount || 0) }}
                      </p>
                    </div>
                    <p name="amount" class="error-feedback" as="p" v-text="errors.amount" />
                  </div>
                </div>
              </div>

              <div class="row js-select_item-currency__modal__tgt currency__modal_1">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>
                      <span class="label-text">{{ $t('expense_cost.tax_distinction') }}</span>
                    </label>
                    <div class="btn-group bootstrap-select select-block select-sm">
                      <el-select
                        popper-class="custom-dropdown-select"
                        v-model="dataRequest.tax_type"
                        :disabled="isOperatorOrAdmin || viewOnly || !isLastExpenseRequestFinished"
                      >
                        <el-option
                          v-for="item in listTaxClassification"
                          :key="item.id"
                          :label="labelLocale(item)"
                          :value="item.id"
                        />
                      </el-select>
                    </div>
                    <ErrorMessage name="tax_type" class="error-feedback" as="p" />
                  </div>
                </div>
                <div class="col-sm-6">
                  <!-- <div class="form-group viewonly">
                    <label>
                      <span class="label-text">{{ $t('expense_cost.tax_amount') }}</span>
                    </label>
                    <div class="input-group">
                      <span class="input-group-addon">{{ $t('trip.yen') }}</span>
                      <p class="viewonly-text input-sm text-right">
                        {{ splitThousandNumber(this.dataRequest.tax_amount || 0) }}
                      </p>
                    </div>
                  </div> -->
                  <div class="form-group">
                    <label>
                      <span class="label-text">{{ $t('expense_cost.tax_amount') }}</span>
                    </label>
                    <div class="input-group">
                      <span class="input-group-addon">{{ $t('trip.yen') }}</span>
                      <input
                        name="tax_amount"
                        type="text"
                        style="background: white"
                        class="form-control input-sm text-right"
                        v-model="dataRequest.tax_amount"
                        @input="dataRequest.tax_amount = convertNumberFullToHalf(dataRequest.tax_amount)"
                        @change="convertDataTaxAmount(dataRequest.tax_amount)"
                        maxlength="15"
                        :disabled="isOperatorOrAdmin || viewOnly || !isLastExpenseRequestFinished"
                      />
                    </div>
                    <p name="tax_amount" class="error-feedback" as="p" v-text="errors.tax_amount" />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>
                      <span class="label-text">{{ $t('expense_cost.payment_method') }}</span>
                    </label>
                    <div class="btn-group bootstrap-select select-block select-sm">
                      <el-select
                        popper-class="custom-dropdown-select"
                        v-model="dataRequest.payment_method"
                        :disabled="isOperatorOrAdmin || viewOnly || !isLastExpenseRequestFinished"
                        :placeholder="$t('common.please_select_required')"
                      >
                        <el-option
                          v-for="item in listPaymentMethod"
                          :key="item.id"
                          :label="labelLocale(item)"
                          :value="item.id"
                        />
                      </el-select>
                    </div>
                    <ErrorMessage name="payment_method" class="error-feedback" as="p" />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <!-- <label>
                      <span class="label-text">{{ $t('expense_cost.attendees') }}</span>
                    </label>
                    <Field
                      name="note"
                      type="text"
                      style="background: white"
                      class="form-control input-sm"
                      v-model="dataRequest.note"
                      :disabled="isOperatorOrAdmin"
                    /> -->
                    <label>
                      <span class="label-text">{{ $t('trip_item.right_menu.manual_input_model.remarks') }}</span>
                    </label>
                    <textarea
                      type="text"
                      style="max-width: 100%; min-width: 100%"
                      class="form-control input-sm max-textarea note-area"
                      v-model="dataRequest.note"
                      maxlength="200"
                      :disabled="isOperatorOrAdmin || viewOnly || !isLastExpenseRequestFinished"
                    />
                  </div>
                </div>
              </div>
              <div v-if="viewOnly" class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <label>
                      <span class="label-text">{{
                        $t('trip_item.right_menu.manual_input_model.linking_appointments')
                      }}</span>
                    </label>
                    <MultiGroupOptionSelectSchedule
                      :list-data="listSchedule"
                      :disable-select="true"
                      @handle-selected-params="listenUrlSelected"
                      :default-list="expenseCostDetail.schedule_ids"
                    />
                    <p role="alert" class="error-feedback" v-text="errors.url" />
                  </div>
                </div>
              </div>
              <div style="padding: 16px 0 32px">
                <div
                    class="row"
                    v-if="
                    !isOperatorOrAdmin && !expenseCostDetail.disable_btn && !viewOnly && !isLastExpenseRequestFinished
                  "
                >
                  <div class="col-xs-6 col-sm-6 col-md-6 col-xs-offset-6 col-sm-offset-6 col-md-offset-6">
                    <a
                        v-if="!expenseCostDetail.is_deleted
                        && expenseCostDetail?.request?.expense_status_id
                        && expenseCostDetail?.request?.expense_status_id !== 4
                        && expenseCostDetail?.request?.expense_status_id !== 5"
                        href="#"
                        class="btn btn-sm btn-inverse btn-block js-btn--close-modal-schedule-item"
                        @click="showBoxConfirmDialog()"
                    >
                      {{ $t('trip_update.btn_cancel_ringi_expense') }}
                    </a>
                  </div>
                </div>
              </div>
              <div style="padding: 16px 0 32px">
                <div
                  class="row"
                  v-if="
                    !isOperatorOrAdmin && !expenseCostDetail.disable_btn && !viewOnly && isLastExpenseRequestFinished
                  "
                >
                  <div class="col-xs-6 col-sm-6 col-md-6" v-if="!expenseCostDetail.is_deleted
                        && expenseCostDetail?.request?.expense_status_id === 4">
                    <a
                        href="#"
                        class="btn btn-sm btn-inverse-primary btn-block js-btn--close-modal-schedule-item"
                        @click="redirectToPageRequest()"
                    >
                      {{ $t('application.show_request') }}
                    </a>
                  </div>
                  <div class="col-xs-6 col-sm-6 col-md-6" :class="expenseCostDetail?.request?.expense_status_id !== 4 && 'col-xs-offset-6 col-sm-offset-6 col-md-offset-6'">
                    <a
                      v-if="!expenseCostDetail.is_deleted"
                      href="#"
                      class="btn btn-sm btn-inverse-primary btn-block js-btn--close-modal-schedule-item"
                      @click="submitForm()"
                    >
                      {{ $t('expense_cost.save') }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </el-dialog>

  <ModalConfirm
      :dialogVisible="showBoxNeedApprove"
      @cancel="showBoxNeedApprove = false"
      @yes="submitCancelRequest"
      :classCustom="actionBoxConfirm == 'need-approve' ? 'btn-flat-default' : 'btn-flat-danger'"
      :title="titleBoxConfirm"
  />

  <ModalCancel
      :is-modal-visible="showBoxCancel"
      :name-btn="$i18n.locale == 'en' ? 'Withdraw Execution' : '取下実行'"
      @submit-cancel-modal="submitCancelRequest"
      @close-modal="showBoxCancel = false"
  />

  <ModalConfirm
    :dialogVisible="showBoxConfirm"
    @cancel="showBoxConfirm = false"
    @yes="deleteExpenseCost()"
    :title="$t('Q-CM-104')"
  />
</template>
<script>
import { ElDialog } from 'element-plus';
import { PAYMENT_DATE_TYPE } from '@/constants/';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import ModalConfirm from '@/components/common/ModalConfirm';
import SingleGroupOptionSelectExpenseCostItem from '@/components/common/SingleGroupOptionSelectExpenseCostItem.vue';
import MultiGroupOptionSelectSchedule from '@/components/common/MultiGroupOptionSelectSchedule.vue';
import moment from 'moment';
import ModalCancel from '@/components/trip/trip-item/modal/ModalCancel';


export default {
  components: {
    ElDialog,
    Form,
    ModalCancel,
    Field,
    ErrorMessage,
    ModalConfirm,
    SingleGroupOptionSelectExpenseCostItem,
    MultiGroupOptionSelectSchedule,
  },
  name: 'ModalExpenseCost',
  emits: ['cancel', 'success', 'removeFile', 'showUploadFile'],
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    showMask: {
      type: Boolean,
      default: true,
    },
    expenseCostDetail: {
      type: Object,
      default: () => {},
    },
    listFile: {
      type: Array,
      default: () => [],
    },
    listSchedule: {
      type: Array,
      default: () => [],
    },
    viewOnly: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    currentRate(newVal) {
      if (!this.checkEmpty(newVal) && !this.isFirstLoadingCostDetail) this.dataRequest.currency_rate = newVal;
    },
    listFile(newVal) {
      if (this.isUploadInvoice && this.checkEmpty(newVal) && !this.isTransportType) {
        this.errors.files = this.$t('E-CM-100', {
          item: this.$t('expense_cost.files'),
        });
      } else {
        this.errors.files = '';
      }
    },
    'dataRequest.amount'() {
      this.validateRequestAmount();
    },
    'dataRequest.tax_amount'() {
      this.validateRequestTaxAmount();
    },
    dialogVisible(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.CompanyCostItemSelect.resetDataSelect();
        });
      }
      this.dialogVisibleStatus = val;
    },
    dialogVisibleStatus(val) {
      if (val) {
        this.getCostItemList();
        setTimeout(() => {
          if (this.isUploadInvoice && this.checkEmpty(this.listFile) && !this.isTransportType) {
            this.errors.files = this.$t('E-CM-100', {
              item: this.$t('expense_cost.files'),
            });
          } else {
            this.errors.files = '';
          }
          this.$refs.form.validate();
        }, 120);

        setTimeout(() => {
          this.isFirstLoadingCostDetail = false;
        }, 120);
        return;
      }
      this.currentRate = this.costDetailRate;
      this.isFirstLoadingCostDetail = true;
    },
    expenseCostDetail: {
      handler: function (val) {
        if (!this.checkEmpty(val)) {
          delete val.files;
          this.firstLoading = val.firstLoading;
          this.dataRequest = val;
          if (this.isFirstLoadingCostDetail) this.costDetailRate = val.currency_rate;
          let crs = this.currencyList.find(cr => cr.id == val.currency_id);
          if (this.checkEmpty(crs) && !this.checkEmpty(this.allCurrencyList)) {
            let currencyData = this.allCurrencyList.find(cr => cr.id == val.currency_id);

            currencyData.exchange_rates = [
              {
                applying_rate: val.currency_rate,
                change_rate: val.currency_rate,
              },
            ];
            this.currencyList.push(currencyData);
          }
          crs = this.currencyList.find(cr => cr.id == val.currency_id);

          if (val.company_cost_item?.is_valid === 0) this.dataRequest.cost_item = '';
          this.dataRequest.tax_type = !this.checkEmpty(val.tax_type) ? this.cloneObject(val.tax_type) : '';
          this.dataRequest.tax_amount = !this.checkEmpty(val.tax_amount) ? val.tax_amount : 0;
          return;
        }
        this.dataRequest = {
          currency_id: 1, // JPY
          tax_type: 2, // Default 10%
          payment_method: '',
          cost_item: '',
          trip_id: '',
        };
      },
      deep: true,
    },
    'dataRequest.currency_id'(val) {
      if (this.isFirstLoadingCostDetail || this.checkEmpty(val)) return;
      this.checkCurrencyRate();
    },
    // 'dataRequest.currency_amount'(val) {
    //   if (val)
    //     String((this.dataRequest.currency_amount = this.convertNumberFullToHalfHasDot(val)))
    //       .replace(/(\..*?)\..*/g, '$1')
    //       .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    //   this.updateAmount();
    // },
    'dataRequest.payment_date'() {
      if (this.isFirstLoadingCostDetail) return;
      this.checkCurrencyRate();
    },
    'dataRequest.currency_rate'() {
      this.updateAmount();
    },
    'dataRequest.tax_type'() {
      this.updateTaxAmount();
    },
  },
  data() {
    return {
      costDetailRate: '',
      actionBoxConfirm: '',
      titleBoxConfirm: '',
      isFirstLoadingCostDetail: true,
      showBoxConfirm: false,
      showBoxNeedApprove: false,
      dialogVisibleStatus: false,
      showBoxCancel: false,
      firstLoading: 1,
      hasOverRightRate: 0,
      currentRate: 0,
      currencyList: [],
      allCurrencyList: this.$store.state.auth?.user?.list_currency || [],
      listTaxClassification: this.$store.state.auth?.user?.classifications || [],
      listPaymentMethod: this.$store.state.auth?.user?.payment_method || [],
      listCostItem: this.$store.state.auth?.user?.cost_item || [],
      listFileRemove: [],
      dataRequest: {
        currency_id: 1, // JPY
        tax_type: 2, // Default 10%
        payment_method: '',
        cost_item: '',
        trip_id: '',
      },
      errors: {
        files: '',
        amount: '',
        tax_amount: '',
      },
    };
  },
  // beforeUnmount() {
  //   console.log();
  //   this.isFirstLoadingCostDetail = false;
  // },
  created() {
    this.startLoading();

    if (this.currentRouteName.split('/')[1] != 'request') {
      Promise.all([
        this.getCurrencyList(),
        this.getTaxClassificationList(),
        this.getPaymentMethodList(),
        this.getCostItemList(),
        this.getAllCurrencyList(),
      ]).then(() => {
        if (this.currentRouteName.split('/')[1] != 'request') this.endLoading();
        this.validateRequestAmount();
        this.validateRequestTaxAmount();
      });
    } else {
      Promise.all([
        this.getCurrencyList()
      ]).then(() => {
        if (this.currentRouteName.split('/')[1] != 'request') this.endLoading();
        this.validateRequestAmount();
        this.validateRequestTaxAmount();
      });
    }
  },
  computed: {
    applicableDate() {
      return this.$store.state.auth?.user?.setting.applicable_date;
    },
    isChangeEki() {
      return this.dataRequest.amount != this.splitThousandNumber(this.expenseCostDetail.eki_amount) &&
          !this.checkEmpty(this.expenseCostDetail.eki_amount);
    },
    isLastExpenseRequestFinished() {
      if (this.checkEmpty(this.expenseCostDetail.is_last_request_expense_finished)) return true;
      return this.expenseCostDetail.is_last_request_expense_finished;
    },
    isTransportType() {
      return this.dataRequest.type_cost == 3;
    },
    isUploadInvoice() {
      return this.$store.state.auth?.user?.setting.is_upload_invoice == 1 || false;
    },
    flgCreate() {
      return this.checkEmpty(this.dataRequest?.id);
    },
    signalCurrency() {
      const currency = this.currencyList.find(item => item.id == this.dataRequest.currency_id);
      return currency?.signal || '¥';
    },
    validate() {
      return yup.object().shape({
        title: yup
          .string()
          .trim()
          .required(this.$t('E-CM-100', { item: this.$t('expense_cost.title') }))
          .max(100, this.$t('E-CM-111', { size: 100 }))
          .nullable(),
        payment_to: yup
          .string()
          .trim()
          .required(this.$t('E-CM-100', { item: this.$t('expense_cost.payment_to') }))
          .max(100, this.$t('E-CM-111', { size: 100 }))
          .nullable(),
        currency_id: yup.number(),
        // amount: yup
        //   .string()
        //   .trim()
        //   .when('currency_id', {
        //     is: currency_id => currency_id == 1,
        //     then: yup
        //       .string()
        //       .required(this.$t('E-CM-100', { item: this.$t('expense_cost.amount') }))
        //       .nullable(),
        //   })
        //   .max(15, this.$t('E-CM-111', { size: 15 })),
        // tax_amount: yup
        //   .string()
        //   .trim()
        //   .when('currency_id', {
        //     is: currency_id => currency_id == 1 && this.dataRequest.tax_type != 3,
        //     then: yup
        //       .string()
        //       .required(this.$t('E-CM-100', { item: this.$t('expense_cost.tax_amount') }))
        //       .nullable(),
        //   })
        //   .max(15, this.$t('E-CM-111', { size: 15 })),
        currency_rate: yup
          .string()
          .trim()
          .when('currency_id', {
            is: currency_id => currency_id != 1,
            then: yup
              .string()
              .required(this.$t('E-CM-100', { item: this.$t('expense_cost.rate') }))
              .nullable(),
          }),
        // currency_amount: yup
        //   .string()
        //   .trim()
        //   .when('currency_id', {
        //     is: currency_id => currency_id != 1,
        //     then: yup
        //       .string()
        //       .required(this.$t('E-CM-100', { item: this.$t('expense_cost.currency_amount') }))
        //       .nullable(),
        //   })
        //   .max(15, this.$t('E-CM-111', { size: 15 })),
        payment_date: yup
          .string()
          .trim()
          .required(this.$t('E-CM-100', { item: this.$t('expense_cost.payment_date') }))
          .nullable(),
        payment_method: yup
          .string()
          .trim()
          .required(this.$t('E-CM-100', { item: this.$t('expense_cost.payment_method') }))
          .nullable(),
        cost_item: yup
          .string()
          .trim()
          .required(this.$t('E-CM-100', { item: this.$t('expense_cost.cost_item') }))
          .nullable(),
      });
    },
  },
  methods: {
    async showBoxConfirmDialog() {
      // this.titleBoxConfirm = this.$t('Q-CU-004');
      // this.actionBoxConfirm = 'cancel-ringi';
      this.showBoxCancel = true;
    },
    async redirectToPageRequest() {
      this.$router.push({
        name: this.PAGES.TRIP_ITEM,
        params: {
          id: this.dataRequest.request.id,
        },
        query: {
          type: 3,
        },
      });
    },
    //CU-050
    async getAllCurrencyList() {
      await this.$services.common.getCurrencyList(
          {},
          res => {
            this.allCurrencyList = res.list;
          },
          err => {
            this.notifyError(this.$t(err.error));
          },
      );
    },
    sortExchangeRate(listExchangeRate) {
      listExchangeRate.sort((a, b) => {
        if (
          moment(new Date(a.change_reservation_date)).format('YYYY-MM-DD') >
          moment(new Date(b.change_reservation_date)).format('YYYY-MM-DD')
        )
          return -1;
        if (
          moment(new Date(a.change_reservation_date)).format('YYYY-MM-DD') <
          moment(new Date(b.change_reservation_date)).format('YYYY-MM-DD')
        )
          return 1;
        return 0;
      });

      return listExchangeRate;
    },
    checkCurrencyRate() {
      if (this.checkEmpty(this.dataRequest.currency_id) || this.isFirstLoadingCostDetail) return;
      let crs = this.currencyList.find(currency => currency.id == this.dataRequest.currency_id);
      // this.errors = {
      //   files: '',
      //   amount: '',
      //   tax_amount: '',
      // };
      let date = '';

      if (!this.checkEmpty(this.dataRequest.payment_date) && String(this.dataRequest.payment_date).includes('/')) {
        date = this.dataRequest.payment_date;
      } else if (
        !this.checkEmpty(this.dataRequest.payment_date) &&
        !String(this.dataRequest.payment_date).includes('/')
      ) {
        date = `${this.dataRequest.payment_date.substr(0, 4)}-${this.dataRequest.payment_date.substring(
          4,
          6,
        )}-${this.dataRequest.payment_date.substring(6, 8)}`;
      } else {
        date = new Date();
      }

      let listExchangeRate = this.sortExchangeRate(this.cloneObject(crs.exchange_rates));
      let latestDate = listExchangeRate.find(
        item =>
          moment(new Date(item.change_reservation_date)).format('YYYY-MM-DD') <=
          moment(new Date(date)).format('YYYY-MM-DD'),
      );
      if (this.applicableDate == PAYMENT_DATE_TYPE) {
        switch (listExchangeRate.length) {
          case 0:
            this.currentRate = crs.rate;
            this.onChangeRate = crs.rate;
            break;
          case 1:
            if (
              moment(new Date(listExchangeRate[0].change_reservation_date)).format('YYYY-MM-DD') <=
              moment(new Date(date)).format('YYYY-MM-DD')
            ) {
              this.currentRate = listExchangeRate[0].change_rate;
              this.onChangeRate = listExchangeRate[0].change_rate;
            } else {
              this.currentRate = listExchangeRate[0].applying_rate;
              this.onChangeRate = listExchangeRate[0].applying_rate;
            }
            break;
          default:
            if (!this.checkEmpty(latestDate)) {
              this.currentRate = latestDate.change_rate;
              this.onChangeRate = latestDate.change_rate;
            } else {
              this.currentRate = listExchangeRate[listExchangeRate.length - 1].applying_rate;
              this.onChangeRate = listExchangeRate[listExchangeRate.length - 1].applying_rate;
            }
            break;
        }
      } else if (!this.checkEmpty(crs.exchange_rates)) {
        this.currentRate = crs.exchange_rates[0].applying_rate;
        this.onChangeRate = crs.exchange_rates[0].applying_rate;
      } else {
        this.currentRate = 1;
        this.onChangeRate = 1;
      }
    },
    openInvoice(file) {
      if (!this.checkEmpty(file.path)) window.open(file.path, '_blank');
    },
    validateRequestAmount() {
      if (
        (this.checkEmpty(this.dataRequest.amount) || this.dataRequest.amount == 0) &&
        this.dataRequest.currency_id == 1 &&
        !this.isTransportType
      ) {
        this.errors.amount = this.$t('E-CM-100', { item: this.$t('expense_cost.amount_of_money') });
        return;
      }
      if (
        (this.checkEmpty(this.dataRequest.amount) || this.dataRequest.amount == 0) &&
        this.dataRequest.currency_id != 1 &&
        !this.isTransportType
      ) {
        this.errors.amount = this.$t('E-CM-100', { item: this.$t('expense_cost.currency_amount') });
        return;
      }
      this.updateTaxAmount();
      this.errors.amount = '';
    },
    validateRequestTaxAmount() {
      if (
        this.checkEmpty(this.dataRequest.tax_amount) &&
        this.dataRequest.currency_id == 1 &&
        ![3, 4, 5].includes(this.dataRequest.tax_type) &&
        !this.isTransportType
      ) {
        this.errors.tax_amount = this.$t('E-CM-100', { item: this.$t('expense_cost.tax_amount') });
        return;
      }
      this.errors.tax_amount = '';
    },
    convertDataRequestAmount(data) {
      if (this.checkEmpty(data)) data = 0;
      if (String(data).includes('.') || String(data).includes('．')) {
        if (String(data).includes('．')) {
          data = String(data).replaceAll('．', '.');
        }
        this.dataRequest.amount = String(parseFloat(data).toFixed(2))
          .replace(/^(?!00[^0])0/, '')
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        this.updateTaxAmount();
      } else {
        data = String(data).replaceAll(',', '');
        this.dataRequest.amount = data
          .replace(/(\..*?)\..*/g, '$1')
          .replace(/^(?!00[^0])0/, '')
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        this.updateTaxAmount();
      }
    },
    convertDataTaxAmount(data) {
      if (this.checkEmpty(data)) data = 0;
      if (String(data).includes('.') || String(data).includes('．')) {
        if (String(data).includes('．')) {
          data = String(data).replaceAll('．', '.');
        }
        this.dataRequest.tax_amount = String(parseFloat(data).toFixed(2))
          .replace(/^(?!00[^0])0/, '')
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        // this.updateTaxAmount();
      } else {
        data = String(data).replaceAll(',', '');
        this.dataRequest.tax_amount = data
          .replace(/(\..*?)\..*/g, '$1')
          .replace(/^(?!00[^0])0/, '')
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        // this.updateTaxAmount();
      }
    },
    convertCurrencyAmount(data) {
      if (this.checkEmpty(data)) return;
      if (String(data).includes('.') || String(data).includes('．')) {
        if (String(data).includes('．')) {
          data = data.replaceAll('．', '.');
        }
        this.dataRequest.currency_amount = String(parseFloat(data).toFixed(2))
          .replace(/^(?!00[^0])0/, '')
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      } else {
        data = data.replaceAll(',', '');
        this.dataRequest.currency_amount = data
          .replace(/(\..*?)\..*/g, '$1')
          .replace(/^(?!00[^0])0/, '')
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
      if (!this.checkEmpty(this.dataRequest.currency_amount)) this.errors.currency_amount = '';
      this.updateTaxAmount();
      this.updateAmount();
    },
    convertData(data) {
      if (this.checkEmpty(data)) return;
      if (String(data).includes('.') || String(data).includes('．')) {
        if (String(data).includes('．')) {
          data = data.replaceAll('．', '.');
        }
        this.dataRequest.currency_rate = String(parseFloat(data).toFixed(2))
          .replace(/^(?!00[^0])0/, '')
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      } else {
        data = data.replaceAll(',', '');
        this.dataRequest.currency_rate = data
          .replace(/(\..*?)\..*/g, '$1')
          .replace(/^(?!00[^0])0/, '')
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    },
    openReceipt(file) {
      window.open(
        file.path,
        'Preview',
        'height=640,width=960,toolbar=no,menubar=no,scrollbars=no,location=no,status=no',
      );
    },
    convertNumberFullToHalf(number) {
      if (!number) return;
      number = String(number).replace(/[^0-9０-９]/g, '');
      number = String(number).replace(/[\uFF10-\uFF19]/g, function (m) {
        return String.fromCharCode(m.charCodeAt(0) - 0xfee0);
      });

      return number;
    },
    convertNumberFullToHalfHasDot(number) {
      if (!number) return;

      number = number.replace(/[^0-9０-９.．]/g, '');
      number = String(number).replace(/[\uFF10-\uFF19]/g, function (m) {
        return String.fromCharCode(m.charCodeAt(0) - 0xfee0);
      });

      return number;
    },
    removeFile(file, i) {
      if (this.isOperatorOrAdmin || this.viewOnly || !this.isLastExpenseRequestFinished) return;
      if (file.id) {
        this.listFileRemove.push(file.id);
      }
      this.$emit('removeFile', i);
    },
    onChangeCostType(id, isActionSelectCostItem) {
      if (this.checkEmpty(this.listCostItem) || this.checkEmpty(id) || !isActionSelectCostItem) return;
      this.listCostItem.forEach(cost => {
        cost.data.forEach(item => {
          if (item.id == id) {
            let taxClassification = this.listTaxClassification.find(tax => item.tax_type == tax.id);
            if (taxClassification) {
              this.dataRequest.tax_type = taxClassification.id;
            }
          }
        });
      });
      this.dataRequest.cost_item = id;
    },
    async getCostItemList() {
      await this.$services.trip.getCostItemList(
          res => {
            this.listCostItem = res.list;
          },
          err => {
            this.notifyError(this.$t(err.error));
          },
      );
    },
    updateAmount() {
      if (this.dataRequest.currency_id != 1 && this.dataRequest.currency_amount) {
        let rate =
          !this.checkEmpty(this.dataRequest.currency_rate) &&
          this.dataRequest.currency_rate != 'e' &&
          this.dataRequest.currency_rate != 'ê'
            ? this.dataRequest.currency_rate.length > 3
              ? this.dataRequest.currency_rate.replaceAll(',', '')
              : this.dataRequest.currency_rate
            : 0;

        let currencyAmount =
          !this.checkEmpty(this.dataRequest.currency_amount) &&
          this.dataRequest.currency_amount != 'e' &&
          this.dataRequest.currency_amount != 'ê'
            ? this.dataRequest.currency_amount.length > 3
              ? this.dataRequest.currency_amount.replaceAll(',', '')
              : this.dataRequest.currency_amount
            : 0;

        this.dataRequest.amount = Math.ceil(currencyAmount * rate) == 0 ? '' : Math.ceil(currencyAmount * rate);
      }
    },
    updateTaxAmount() {
      if (this.isFirstLoadingCostDetail) {
        this.dataRequest.tax_amount = this.splitThousandNumber(this.dataRequest.tax_amount);
        return;
      }
      const taxValue = this.listTaxClassification.find(item => item.id == this.dataRequest.tax_type);
      if (taxValue) {
        let amount = this.convertNumberFullToHalf(this.dataRequest?.amount) || 0;
        if (this.dataRequest.currency_id == 1) {
          amount = String(amount).replace(/[^\d]/g, '');
        }
        let result = Number(amount - amount / (1 + taxValue.percent));
        this.dataRequest.tax_amount =
          this.isNumeric(result) && !this.checkEmpty(result)
            ? String(Math.round(amount - amount / (1 + taxValue.percent)))
                .replace(/(\..*?)\..*/g, '$1')
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
            : 0;
      }
    },
    closeModal() {
      this.dialogVisibleStatus = false;
      this.errors.files = '';
      this.$emit('cancel');
    },
    clearData() {
      this.dialogVisibleStatus = false;
      this.listFileRemove = [];
      this.dataRequest = {
        currency_id: 1, // JPY
        tax_type: 2, // Default 10%
        payment_method: '',
        payment_to: '',
        cost_item: '',
        trip_id: '',
      };

      setTimeout(() => {
        this.$refs.form.setErrors({
          title: '',
          payment_to: '',
          amount: '',
          tax_amount: '',
          current_amount: '',
          payment_date: '',
        });
      }, 100);
    },
    async getCurrencyList() {
      if (this.currentRouteName.split('/')[1] != 'request') {
        await this.$services.common.getCurrencyList(
            {is_cost: 1},
            res => {
              this.currencyList = res.list;
              this.hasOverRightRate = res.is_exchange_rate_overwrite;
            },
            err => {
              this.notifyError(this.$t(err.error));
            },
        );
      } else {
        this.currencyList = this.$store.state.auth?.user?.list_currency_by_cost || [];
        this.hasOverRightRate = this.$store.state.auth?.user?.is_exchange_rate_overwrite || [];
      }
    },
    async getTaxClassificationList() {
      await this.$services.common.getTaxClassificationList(
          res => {
            this.listTaxClassification = res.list;
          },
          err => {
            this.notifyError(this.$t(err.error));
          },
      );
    },
    async getPaymentMethodList() {
      await this.$services.common.getPaymentMethodList(
          res => {
            this.listPaymentMethod = res.list;
          },
          err => {
            this.notifyError(this.$t(err.error));
          },
      );
    },
    deleteExpenseCost() {
      this.startLoading();
      this.$services.trip_item.deleteCostItem(
        this.expenseCostDetail.id,
        () => {
          this.endLoading();
          this.showBoxConfirm = false;
          this.notifySuccess(this.$t('I-OP-053'));
          this.closeModal();
        },
        err => {
          this.endLoading();
          this.notifyError(this.$t(err.error));
        },
      );
    },
    submitForm() {
      let flgValidate = true;
      if (this.isUploadInvoice && this.checkEmpty(this.listFile) && !this.isTransportType) {
        flgValidate = false;
        this.errors.files = this.$t('E-CM-100', {
          item: this.$t('expense_cost.files'),
        });
      } else {
        this.errors.files = '';
      }
      delete this.expenseCostDetail.schedule_ids;
      if (
        (this.checkEmpty(this.dataRequest.amount) || this.dataRequest.amount == 0) &&
        this.dataRequest.currency_id == 1 &&
        !this.isTransportType
      ) {
        this.errors.amount = this.$t('E-CM-100', { item: this.$t('expense_cost.amount_of_money') });
        flgValidate = false;
      } else if (
        (this.checkEmpty(this.dataRequest.amount) || this.dataRequest.amount == 0) &&
        this.dataRequest.currency_id != 1 &&
        !this.isTransportType
      ) {
        this.errors.amount = this.$t('E-CM-100', { item: this.$t('expense_cost.currency_amount') });
        flgValidate = false;
      } else if (!this.checkEmpty(this.dataRequest.amount) && String(this.dataRequest.amount).length > 15) {
        this.errors.amount = this.$t('E-CM-111', { size: 15 });
        flgValidate = false;
      } else {
        this.errors.amount = '';
      }

      if (this.dataRequest.currency_id != 1 && this.checkEmpty(this.dataRequest.currency_amount)) {
        flgValidate = false;
        this.errors.currency_amount = this.$t('E-CM-100', { item: this.$t('expense_cost.currency_amount') });
      } else if (String(this.dataRequest.currency_amount).replaceAll(',', '').length > 15) {
        flgValidate = false;
        this.errors.currency_amount = this.$t('E-CM-111', { size: 15 });
      } else {
        this.errors.currency_amount = '';
      }
      if (
        this.checkEmpty(this.dataRequest.tax_amount) &&
        this.dataRequest.currency_id == 1 &&
        ![3, 4, 5].includes(this.dataRequest.tax_type) &&
        !this.isTransportType
      ) {
        this.errors.tax_amount = this.$t('E-CM-100', { item: this.$t('expense_cost.tax_amount') });
        flgValidate = false;
      } else if (
        !this.checkEmpty(this.dataRequest.tax_amount) &&
        String(this.dataRequest.tax_amount).replaceAll(',', '').length > 15
      ) {
        this.errors.tax_amount = this.$t('E-CM-111', { size: 15 });
        flgValidate = false;
      } else {
        this.errors.tax_amount = '';
      }

      this.$refs.form.validate().then(res => {
        if (res.valid && flgValidate) {
          let rate =
            !this.checkEmpty(this.dataRequest.currency_rate) &&
            this.dataRequest.currency_rate != 'e' &&
            this.dataRequest.currency_rate != 'ê'
              ? this.dataRequest.currency_rate.length > 3
                ? this.dataRequest.currency_rate.replaceAll(',', '')
                : this.dataRequest.currency_rate
              : 0;
          const data = { ...this.dataRequest };
          data.amount = String(data.amount).replace(/[^\d]/g, '');
          data.tax_amount = String(data.tax_amount).replace(/[^\d]/g, '');
          data.currency_rate = rate;

          if (data.currency_id == 1) {
            delete data.currency_amount;
            delete data.currency_rate;
          } else {
            data.currency_amount = data.currency_amount.replaceAll(',', '');
          }

          delete data.firstLoading;

          let formData = this.convertParams(data);
          if (!this.checkEmpty(this.listFile)) {
            for (let index = 0; index < this.listFile.length; index++) {
              if (this.listFile[index].type) {
                formData.append('files[]', this.listFile[index]);
              }
            }
          }

          delete formData.schedule_ids;

          this.startLoading();
          if (this.flgCreate) {
            if (data?.currency_rate && this.currentRate == data.currency_rate) {
              delete data.currency_rate;
            }

            this.createFunc(formData);
          } else {
            this.updateFunc(this.expenseCostDetail.id, formData);
          }
        }
      });
    },
    createFunc(formData) {
      this.$services.cost.createExpenseCostItem(
        formData,
        () => {
          this.endLoading();
          this.clearData();
          this.$emit('success');
        },
        err => {
          this.endLoading();
          this.notifyError(this.$t(err.error));
        },
      );
    },
    updateFunc(id, formData) {
      if (this.listFileRemove.length > 0) {
        for (let index = 0; index < this.listFileRemove.length; index++) {
          formData.append('file_delete[]', this.listFileRemove[index]);
        }
      }
      this.$services.cost.updateExpenseCostItem(
        id,
        formData,
        () => {
          this.endLoading();
          this.closeModal();
        },
        err => {
          this.endLoading();
          this.notifyError(this.$t(err.error));
        },
      );
    },
    submitCancelRequest(comment) {
      let request_id = this.expenseCostDetail?.request_id || null;
      if (request_id) {
        this.startLoading();
        this.$services.trip.cancelRingi(
            request_id + '?comment=' + comment,
            () => {
              this.endLoading();
              this.showBoxCancel = false;
              this.closeModal();
            },
            err => {
              this.endLoading();
              this.notifyError(this.$t(err.error));
            },
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.file-preview {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
  border: 1px solid #657a84;

  &:hover {
    color: white;
    background-color: #f77581;
    border: 1px solid #f77581;
  }
}
.btn-show-again-search {
  height: 80px;
  margin-bottom: 16px;
  background: #e7ebec;
  margin-right: -32px;
  margin-left: -32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    border-color: #dfe3f1;
    background-color: #dfe3f1;
  }

  span {
    color: #3650a5;
  }
}
.btn-inverse.disabled {
  background-color: #f0f2f5 !important;
  border-color: #f0f2f5 !important;
  color: #657a84 !important;
}
.note-area {
  min-height: 200px;
}
.change-eki-cost {
  background-color: #8B0000;
  font-size: 10px;
  color: #fff;
  border-radius: 12px;
  padding: 2px 5px;
  margin-left: 5px;
}
</style>
