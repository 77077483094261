<template>
  <div class="wrapper-page">
    <nav class="navbar navbar-default navbar-lg" role="navigation" id="header-content">
      <div class="navbar-header">
        <h2 class="navbar-title">{{ $t('approval_flow.approval_flow') }}</h2>
      </div>
    </nav>
    <ApprovalFlowForm @onChangData="onChangData" />
  </div>
</template>
<script>
import ApprovalFlowForm from '@/components/approval-flow/ApprovalFlowForm';
export default {
  name: 'NewApprovalFlow',
  components: { ApprovalFlowForm },
  data() {
    return {
      isChangeData: false,
    };
  },
  beforeRouteLeave(to, from, next) {
    if (this.isChangeData) {
      const answer = window.confirm(this.$t('common.confirm_leave_page'));
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  methods: {
    onChangData(val) {
      this.isChangeData = val;
    },
  },
};
</script>
<style></style>
