<template>
  <el-dialog
      v-model="isVisible"
      width="736px"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      @close="closeModal()"
      :show-close="false"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <button type="button" class="close" @click="closeModal()">
          <span class="icon-abui-cross" aria-hidden="true"></span>
        </button>
        <div class="modal-header" style="display: flex; justify-content: flex-start">
          <div class="modal-header--title" style="width: 100%">
            <span v-if="businessReportType === tripApproval">
              {{ $t('business_report.title_approval') }}
            </span>
            <span v-else>
              {{ $t('business_report.title_report') }}
            </span>
          </div>
        </div>
        <div class="modal-body">
          <a
              v-if="this.tripDetail?.regulation_files?.length > 0"
              href="#"
              rel="noopener"
              @click="openFileKite"
              style="display: block; text-align-last: end; margin-top: -15px"
          >
            {{ $t('trip.regulations') }}
            <i class="icon-abui-outside plx" />
          </a>
          <p v-if="businessReportType === tripApproval" class="modal-dialog-text" style="margin-bottom: 24px">
            {{ $t('business_report.note_approval') }}
          </p>
          <p v-else class="modal-dialog-text" style="margin-bottom: 24px">
            {{ $t('business_report.note1') }}
            <br />
            {{ $t('business_report.note2') }}
            <br />
            {{ $t('business_report.note3') }}
          </p>

          <hr style="margin-bottom: 24px" />
          <div
              v-if="regularCheck.length > 0 && businessReportType === tripApproval && $i18n.locale != 'en'"
              class="panel--wrap show--status20"
              style="margin: 0px 0px 20px 0px !important;"
              id="msg--regulation-check"
          >
            <div class="panel panel-warning mbn">
              <div class="panel-heading" style="border: none">
                <p class="panel-title"><i class="icon-abui-alert prx"></i>{{ $t('trip_item.main_content.rule_text') }}</p>
              </div>
              <div class="panel-body break-word-custom">
                <ul class="regulation-check--feedback">
                  <li
                      v-for="(item, index) in regularCheck"
                      :key="index"
                      class="regulation-check--feedback-item"
                  >
                    <b>{{ titleType(item.title_type) }}</b> : {{ item.comment }}
                    <br />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="apply-expense--head">
            <div class="apply-expense--head--left" style="width: 100%">
              <div class="apply-expense--trip-title">{{ dataToBusinessReport.name }}</div>
              <div class="apply-expense--trip-period">
                <i class="icon-abui-calendar prx"></i>
                {{
                  dataToBusinessReport.start_time && showDateTime(dataToBusinessReport.start_time, 'YYYY/MM/DD（ddd）')
                }}
                -
                {{ dataToBusinessReport.end_time && showDateTime(dataToBusinessReport.end_time, 'YYYY/MM/DD（ddd）') }}
              </div>
              <div class="apply-expense--trip-destination">
                <i class="icon-abui-location prx"></i>
                {{ labelLocale(dataToBusinessReport.schedule_location) }}
              </div>
            </div>
          </div>

          <div>
            <div class="form-group">
              <label>
                <span class="label-text">
                  {{ $t('business_report.purpose') }}
                </span>
              </label>
              <textarea
                  ref="trip_purpose"
                  v-model="tripPurpose"
                  maxlength="200"
                  class="form-control fixed-width-textarea"
                  rows="4"
                  placeholder=""
              ></textarea>
            </div>
            <p role="alert" class="error-feedback" v-text="errors.purpose" />
          </div>

          <div class="apply-approval-flow" ref="approval_flow">
            <div>
              <label>
                <span class="label-text">{{ $t('business_report.approval_flow') }}</span>
              </label>
              <SingleOptionSelect
                  ref="RequestOrganizationSelect"
                  :showClearable="false"
                  :default-list="approvalFlowIdList.includes(defaultApprovalFlows) ? defaultApprovalFlows : ''"
                  :list-data="approvalFlowsByType"
                  @handle-selected-params="listenApprovalFlows"
              />
            </div>
            <div class="js-select_item-select-approval-flow__tgt select-approval-flow_1">
              <p role="alert" class="error-feedback" v-text="errors.approver_flow_id" />
              <p
                  v-show="
                  currentApprovalFlowHaveSelectOption &&
                  !checkEmpty(approvalFlowDetail) &&
                  approvalFlowIdList.includes(curApprovalFLowId)
                "
                  class="note mbs"
              >
                {{ $t('business_report.approval_flow_note') }}
              </p>
              <div
                  v-if="!checkEmpty(approvalFlowDetail) && approvalFlowIdList.includes(curApprovalFLowId)"
                  class="step-list step-list--ringi-apply"
              >
                <div
                    v-for="(detail, stepIndex) in approvalFlowDetail"
                    :key="stepIndex"
                    class="step-item"
                    :class="appendToStepClass(stepIndex, detail.user_select_option)"
                >
                  <i class="step-mark"></i>
                  <span>{{ displayStepTitle(stepIndex) }}</span>
                  <span class="ringi-step--type">{{ textToStepType(detail.step_type) }}</span>
                  <ul v-if="!checkEmpty(detail.step_detail)" class="list--approver">
                    <li v-for="(stepDetail, id) in detail.step_detail" :key="id">{{ stepDetail.user_name }}</li>
                  </ul>
                  <div v-if="detail.user_select_option == 1" class="custom-select-approver">
                    <div class="row" style="display: flex; position: relative">
                      <div class="col-sm-8">
                        <div class="form-group viewonly">
                          <p class="viewonly-text input-sm input-approver">
                            <span class="break-down-span">{{ inputApprover[stepIndex]?.display_text }}</span>
                          </p>
                          <span role="alert" class="error-feedback float-left-err">
                            {{ errors?.approval_flows[stepIndex] }}
                          </span>
                        </div>
                      </div>
                      <div class="col-sm-4" style="margin-top: 7px">
                        <a
                            @click="openAddApprover(stepIndex, detail.step_id)"
                            class="btn btn-sm btn-block btn-inverse-primary custom-add-approver"
                        >
                          {{ $t('business_report.reference') }}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ul
            v-if="flg_old_val"
            id="apply-expense--close--tab"
            class="nav nav-tabs"
            role="tablist"
            style="margin-top: 24px"
          >
            <li class="list-tab" :class="costTab && 'active'" @click="costTab = true">
              <a role="tab">{{ $t('business_report.cost') }}</a>
            </li>
            <li class="list-tab" :class="!costTab && 'active'" @click="showReportTab()">
              <a role="tab">{{ $t('business_report.report') }}</a>
            </li>
          </ul>

          <div id="apply-expense--close--tabContent" class="tab-content">
            <div v-show="costTab">
              <div class="tab-pane fade active in" id="apply-expense--close--tab__1">
                <div class="apply-expense--total--wrap">
                  <div class="apply-expense--total-sum">
                    <span class="apply-expense--total-sum--appendix">{{ $t('business_report.amount') }}</span>
                    {{ splitThousandYen(totalCostBearing) }}
                  </div>
                  <a
                    v-if="flg_old_val"
                    class="js-btn--reffer-ringi has-pointer"
                    style="display: block"
                    @click="openApprovedExpenses()"
                  >
                    <div class="apply-expense--total-applied">
                      <span>{{ $t('business_report.approval') }}</span>
                      <span class="value">{{ splitThousandYen(totalApprovedExpenses) }} </span>
                    </div>
                    <div class="apply-expense--total-dif">
                      <span></span>
                      <span class="value text-warning"> （ {{ approvalAndReportExpenseDiff }} ）</span>
                    </div>
                  </a>
                </div>

                <div ref="bearing_organizations">
                  <label>
                    <span class="label-text">{{ $t('business_report.cost_bearing') }}</span>
                  </label>
                  <div
                      v-for="(bearingDetail, index) in approvedBearing"
                      :key="index"
                      class="apply-expense--dept--item custom-apply-expense"
                  >
                    <div style="width: 50%" class="prs organizarion_select">
                      <div class="form-group">
                        <SingleOptionSelect
                            :list-data="listOrganizations"
                            :default-list="bearingDetail.organization_id"
                            :option-index="{ haveIndex: true, index }"
                            :is-display-search="false"
                            :using-list-items="listOrganizationUsing"
                            @handle-selected-params="handleBearingOrganization"
                        />
                      </div>
                      <div>
                        <p role="alert" class="error-feedback" v-text="errors.selected_bearing.organization[index]" />
                      </div>
                    </div>
                    <div style="width: 25%" class="phs">
                      <div class="form-group">
                        <div class="input-group percentage">
                          <input
                              v-model="approvedBearing[index].price_percentage"
                              @input="
                              approvedBearing[index].price_percentage = convertNumberFullToHalf(
                                approvedBearing[index].price_percentage,
                              )
                            "
                              type="text"
                              maxlength="5"
                              @keypress="isNumber($event)"
                              class="form-control input-sm"
                              :ref="'price_percentage'"
                              @blur="validateCostBearing"
                          />
                          <span class="input-group-addon">%</span>
                        </div>
                      </div>
                      <div>
                        <p
                            role="alert"
                            class="error-feedback"
                            v-text="errors.selected_bearing.price_percentage[index]"
                        />
                      </div>
                    </div>
                    <div class="phs" style="margin-left: auto">
                      <div class="form-group">
                        <div class="apply-expense--dept">
                          {{ splitThousandYen(approvedBearing[index].amount) }}
                        </div>
                      </div>
                    </div>
                    <div style="width: 38px" class="mls">
                      <div class="form-group">
                        <button @click="removeCostBearing(bearingDetail)" class="btn btn-sm btn-flat-danger">
                          <i class="icon-abui-cross"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div>
                    <span role="alert" class="error-feedback" v-text="errors.total_price_percentage" />
                  </div>
                  <div>
                    <span role="alert" class="error-feedback" v-text="errors.have_duplicated_fields" />
                  </div>
                  <button
                      @click="addCostBearing()"
                      class="btn btn-flat-primary btn-sm js-add-expense-dept"
                      :class="!displayAddCostBearing && 'disabled'"
                  >
                    <i class="icon-abui-plus prx"></i>
                    {{ $t('business_report.add_organization') }}
                  </button>
                </div>

                <hr class="mbl" />

                <div class="mbl">
                  <div class="apply-expense--section-head">
                    <h6>{{ $t('business_report.daily_allowance') }}</h6>
                    <div class="reference plm">
                      <a class="has-pointer" href="#" @click="openFileOnNewTab">
                        {{ $t('business_report.daily_allowance_rule') }}
                        <i class="icon-abui-outside plx"></i>
                      </a>
                    </div>
                    <span
                      v-if="flg_old_val"
                      class="apply-expense--price__before hide--status10 hide--status20"
                    >
                      {{ $t('business_report.last_time') }}
                      {{ splitThousandYen(totalPreviousDailyAllowances) }}
                    </span>
                    <div class="apply-expense--section-sum" :class="flg_old_val && splitThousandYen(totalPreviousDailyAllowances) && splitThousandYen(totalPreviousDailyAllowances) !== splitThousandYen(totalDailyAllowance) && 'text-warning'">
                      {{ splitThousandYen(totalDailyAllowance) }}
                      <a @click="isDisplayAllowance = !isDisplayAllowance" class="js-btn--toggle-allowance">
                        <span
                            class="icon plx"
                            :class="isDisplayAllowance ? 'icon-abui-arrow-down' : 'icon-abui-arrow-right'"
                        />
                      </a>
                    </div>
                  </div>
                  <Transition name="slidedown">
                    <div v-if="isDisplayAllowance" class="js-btn--toggle-allowance__tgt">
                      <div v-if="!checkEmpty(approvalCosts.daily_allowances)" class="js-toggle-content">
                        <div
                            v-for="(item, index) in approvalCosts.daily_allowances"
                            :key="index"
                            class="apply-expense--item fixed"
                        >
                          <span class="apply-expense--icon"><i class="icon-abui-check"></i></span>
                          <span class="apply-expense--title">{{ item.daily_allowance_type }}</span>
                          <span class="apply-expense--date">
                            {{ showDateTime(item.start_date, 'YYYY/MM/DD') }}
                          </span>
                          <span class="apply-expense--price">{{ splitThousandYen(item.amount) }}</span>
                        </div>
                      </div>
                    </div>
                  </Transition>
                  <div class="apply-expense--section-head">
                    <h6>{{ $t('business_report.insurance_fees') }}</h6>
                    <span
                      v-if="flg_old_val"
                      class="apply-expense--price__before"
                      :class="customCostPadding(totalPreviousInsurances)"
                    >
                      {{ $t('business_report.last_time') }}
                      {{ splitThousandYen(totalPreviousInsurances) }}
                    </span>
                    <div
                      class="apply-expense--section-sum"
                      :class="flg_old_val && splitThousandYen(totalPreviousInsurances) !== splitThousandYen(totalInsuranceFees) && 'text-warning'"
                    >
                      {{ splitThousandYen(totalInsuranceFees) }}
                    </div>
                  </div>
                  <div class="apply-expense--wrap">
                    <div v-if="!checkEmpty(approvalCosts.insurance)">
                      <div
                        v-for="(item, index) in approvalCosts.insurance"
                        :key="index"
                        @click="selectInsuranceFees(item, index)"
                        class="apply-expense--item"
                        :class="!isInSelectedInsurance(item) && 'exclude'"
                      >
                        <span class="apply-expense--icon"><i class="icon-abui-check"></i></span>
                        <span class="apply-expense--title">{{ item.title }}</span>
                        <span class="apply-expense--date">
                          {{ showDateTime(item.payment_date, 'YYYY/MM/DD') }}
                        </span>
                        <span class="apply-expense--payto">{{ item.payable_to }}</span>
                        <span class="apply-expense--paymenttype">
                          {{
                            isNumeric(item.payment_method)
                                ? textToPaymentMethod(item.payment_method)
                                : item.payment_method
                          }}
                        </span>
                        <span v-if="flg_old_val" class="apply-expense--price__before">
                          {{ splitThousandYen(item.request_amount) }}
                        </span>
                        <span class="apply-expense--price">{{ splitThousandYen(item.amount) }}</span>
                      </div>
                    </div>
                  </div>

                  <!-- Company Cost items -->
                  <div v-if="!checkEmpty(approvalCosts?.company_cost_items)">
                    <div v-for="(companyCost, index) in approvalCosts.company_cost_items" :key="companyCost.id">
                      <div class="apply-expense--section-head">
                        <h6>{{ companyCost?.title }}</h6>
                        <span
                          v-if="flg_old_val"
                          class="apply-expense--price__before hide--status10 hide--status20"
                          :style="'margin-right:' + customCostPadding(showTotalPreviousCompanyCost(companyCost))"
                        >
                          {{ $t('business_report.last_time') }}
                          {{ splitThousandYen(showTotalPreviousCompanyCost(companyCost)) }}
                        </span>
                        <div
                          class="apply-expense--section-sum"
                          :class="flg_old_val && splitThousandYen(showTotalPreviousCompanyCost(companyCost)) !== splitThousandYen(totalCompanyCosts[index]) && 'text-warning'"
                        >
                          {{ splitThousandYen(totalCompanyCosts[index]) }}
                        </div>
                      </div>

                      <div class="apply-expense--wrap">
                        <div v-if="!checkEmpty(companyCost.cost_items)">
                          <div
                              v-for="(item, itemIndex) in companyCost.cost_items"
                              :key="itemIndex"
                              @click="selectCompanyFees(index, item)"
                              class="apply-expense--item"
                              :class="!isInSelectedCompanyCost(index, item) && 'exclude'"
                          >
                            <span class="apply-expense--icon"><i class="icon-abui-check"></i></span>
                            <span class="apply-expense--title">{{ item?.title }}</span>
                            <span class="apply-expense--date">
                              {{ showDateTime(item.payment_date, 'YYYY/MM/DD') }}
                            </span>
                            <span class="apply-expense--payto">{{ item?.payable_to }}</span>
                            <span class="apply-expense--paymenttype">
                              {{
                                isNumeric(item.payment_method)
                                    ? textToPaymentMethod(item.payment_method)
                                    : item.payment_method
                              }}
                            </span>
                            <span v-show="flg_old_val" class="apply-expense--price__before">
                              {{ showPreviousCostItemAmount(companyCost, item, 1) }}
                            </span>
                            <span class="apply-expense--price">{{ splitThousandYen(item.amount) }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Estimate Cost items -->
                  <div v-if="!checkEmpty(approvalCosts.cost_estimate)">
                    <div v-for="(estimateCost, index) in approvalCosts.cost_estimate" :key="estimateCost.id">
                      <div class="apply-expense--section-head">
                        <h6>{{ groupScheduleTypeLabel(estimateCost.schedule_group_type) }}</h6>
                        <span
                          v-if="flg_old_val"
                          class="apply-expense--price__before hide--status10 hide--status20"
                          :style="'margin-right:' + customCostPadding(showPreviousEstimateCost(estimateCost))"
                        >
                          {{ $t('business_report.last_time') }}
                          {{ splitThousandYen(showPreviousEstimateCost(estimateCost)) }}
                        </span>
                        <div
                          class="apply-expense--section-sum"
                          :class="flg_old_val && splitThousandYen(showPreviousEstimateCost(estimateCost)) !== splitThousandYen(totalEstimateCosts[index]) && 'text-warning'"
                        >
                          {{ splitThousandYen(totalEstimateCosts[index]) }}
                        </div>
                      </div>

                      <div class="apply-expense--wrap">
                        <div v-if="!checkEmpty(estimateCost.cost_items)">
                          <div
                              v-for="(item, itemIndex) in estimateCost.cost_items"
                              :key="itemIndex"
                              @click="selectEstimateFees(index, item)"
                              class="apply-expense--item"
                              :class="!isInSelectedEstimateCost(index, item) && 'exclude'"
                          >
                            <span class="apply-expense--icon"><i class="icon-abui-check"></i></span>
                            <span class="apply-expense--title">
                              {{ item.title }}
                            </span>
                            <span class="apply-expense--date">
                              {{ showDateTime(item.payment_date, 'YYYY/MM/DD') }}
                            </span>
                            <span class="apply-expense--payto">{{ item?.payable_to }}</span>
                            <span class="apply-expense--paymenttype">
                              {{
                                isNumeric(item.payment_method)
                                    ? textToPaymentMethod(item.payment_method)
                                    : item.payment_method
                              }}
                            </span>
                            <span v-show="flg_old_val" class="apply-expense--price__before">
                              {{ showPreviousCostItemAmount(estimateCost, item, 2) }}
                            </span>
                            <span class="apply-expense--price">{{ splitThousandYen(item.amount) }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="apply-expense--section-head">
                    <h6>{{ $t('settings.temporary_expenses') }}</h6>
                    <span
                        v-if="flg_old_val"
                        class="apply-expense--price__before"
                        :class="customCostPadding(totalPreviousWifiRouterRental)"
                    >
                      {{ $t('business_report.last_time') }}
                      {{ splitThousandYen(totalPreviousWifiRouterRental) }}
                    </span>
                    <div
                        class="apply-expense--section-sum"
                        :class="flg_old_val && splitThousandYen(totalPreviousWifiRouterRental) !== splitThousandYen(totalWifiRouterRental) && 'text-warning'"
                    >
                      {{ splitThousandYen(totalWifiRouterRental) }}
                    </div>
                  </div>
                  <div class="apply-expense--wrap">
                    <div v-if="!checkEmpty(approvalCosts.wifi_router_rental)">
                      <div
                          v-for="(item, index) in approvalCosts.wifi_router_rental"
                          :key="index"
                          @click="selectWifiRouterRental(item)"
                          class="apply-expense--item"
                          :class="!isInSelectedWifiRouterRental(item) && 'exclude'"
                      >
                        <span class="apply-expense--icon"><i class="icon-abui-check"></i></span>
                        <span class="btn btn-used-in-request" style="background-color: #dcb823; color: #fff">
                           {{ $t('settings.temporary_expenses_label')}}
                        </span>
                        <span class="apply-expense--title">{{ item.title }}</span>
                        <span class="apply-expense--date">
                          {{ showDateTime(item.payment_date, 'YYYY/MM/DD') }}
                        </span>
                        <span class="apply-expense--payto">{{ item.payable_to }}</span>
                        <span class="apply-expense--paymenttype">
                          {{
                            isNumeric(item.payment_method)
                                ? textToPaymentMethod(item.payment_method)
                                : item.payment_method
                          }}
                        </span>
                        <span v-if="businessReportType === tripReport" class="apply-expense--price__before">
                          {{ splitThousandYen(item.request_amount) }}
                        </span>
                        <span class="apply-expense--price">{{ splitThousandYen(item.amount) }}</span>
                      </div>
                    </div>
                  </div>

                  <!-- Upload attachment file-->
                  <div>
                    <div class="apply-expense--section-head">
                      <h6>{{ $t('business_report.upload_attachment') }}</h6>
                    </div>
                    <div class="apply-expense--wrap">
                      <button
                          style="margin-bottom: 4px"
                          type="button"
                          class="btn btn-inverse btn-sm"
                          @click="showModalUploadFile = true"
                          :disabled="isOperatorOrAdmin || isTransportType"
                          :class="isTransportType && 'disabled'"
                      >
                        <i class="icon-abui-upload prx" />{{ $t('trip_item.right_menu.manual_input_model.upload') }}
                      </button>
                      <button
                          style="width: 100%; display: flex; justify-content: space-between; margin-bottom: 4px"
                          v-for="(item, index) in listFileSelected"
                          :key="index"
                          type="button"
                          class="btn btn-inverse-hover-danger btn-sm js-btn--delete-voucher-general"
                          :disabled="isOperatorOrAdmin"
                      >
                        <span class="thumbnail--voucher--filename">{{ item.name }}</span>
                        <i class="icon-abui-cross pls" @click="deleteFile(item)" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- PDF -->
          <div v-show="!costTab">
            <div class="tab-pane fade active in" id="apply-expense--close--tab__2">
              <div v-for="(report, reportIndex) in reportList" :key="reportIndex" class="close--report--wrap">
                <div class="close--report--item">
                  <div class="close--report--item--date">
                    <div style="vertical-align: inherit">
                      <div style="vertical-align: inherit">
                        {{ showDateTime(report.created_at, 'YYYY/MM/DD（ddd）HH:mm') }}
                      </div>
                    </div>
                  </div>
                  <div class="close--report--item--title">
                    <div style="vertical-align: inherit">
                      <div style="vertical-align: inherit">
                        {{ report.name }}
                      </div>
                    </div>
                  </div>
                  <div class="close--report--item--body">
                    <div style="vertical-align: inherit">
                      <div style="vertical-align: inherit; word-break: break-word;">
                        {{ report.description }}
                      </div>
                    </div>
                  </div>
                  <ul class="close--report--item--attached--list">
                    <li v-for="(item, index) in report?.file_list" :key="index">
                      <a :href="item.link" target="_blank" rel="noopener">
                        <i class="icon-abui-file"></i>
                        <span class="file--name">{{ item.name }}</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <hr />
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label>
                  <span class="label-text">{{ $t('business_report.comment') }}</span>
                </label>
                <textarea
                    v-model="businessReportComment"
                    maxlength="300"
                    class="form-control fixed-width-textarea"
                    rows="4"
                    placeholder=""
                    ref="trip_comment"
                ></textarea>
              </div>
              <p role="alert" class="error-feedback" v-text="errors.comment" />
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="col-xs-6 col-sm-6 col-md-6">
            <button class="btn btn-sm btn-flat-default btn-block" @click="closeModal()">
              {{ $t('common.cancel') }}
            </button>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-6">
            <button
                class="btn btn-sm btn-primary btn-block open-alert alertAfterAnother"
                id="js-btn--do-submit-close-trip"
                @click="sendBusinessApproval"
            >
              <span v-if="businessReportType === tripApproval">
                {{ $t('business_report.submit_trip_approval_application') }}
              </span>
              <span v-else>
                {{ $t('business_report.submit_trip_report_application') }}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
  <AddApproverModal
      :visible="addApproverVisible"
      :current-approver-list="currentApproverStepList"
      @close-modal="closeAddApprover()"
      @emit-add-approver="handleAddApprover"
  />
  <ModalUploadFile
      :dialogVisible="showModalUploadFile"
      @cancel="showModalUploadFile = false"
      @confirm="confirmUploadFile"
      :title="$t('trip_item.right_menu.upload_receipt_or_card_or_invoice')"
  />
</template>
<script>
import SingleOptionSelect from '@/components/common/SingleOptionSelect';
import AddApproverModal from '@/components/trip/AddApproverModal';
import vClickOutside from 'click-outside-vue3';
import ModalUploadFile from '@/components/common/ModalUploadFile';

import {
  APPROVAL_OR_REPORT_REQUEST_TYPE,
  APPROVAL,
  PASS_ROUND_PERUSAL,
  COST_ORGANIZATION_PERCENTAGE,
  APPROVAL_STATUS,
  REPORT_STATUS,
  payment_reimbursement,
  PAYMENT_CORPORATE_CARD,
  PAYMENT_INVOICE,
} from '@/constants/';

export default {
  name: 'BusinessReportModal',
  emits: ['closeModal', 'openApprovedExpenses', 'reloadTripDetail'],
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    SingleOptionSelect,
    AddApproverModal,
    ModalUploadFile,
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    dataToBusinessReport: {
      type: Object,
      default: () => {},
    },
    businessReportType: {
      type: Number,
      default: 0,
    },
    tripDetail: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      default_approval_flow_id: null,
      flg_old_val: false,
      isVisible: false,
      allReportDataAfterReject: {},
      dailyAllowanceFiles: [],
      tripApproval: APPROVAL_STATUS,
      tripReport: REPORT_STATUS,
      curApprovalFLowId: 0,
      approvalFlowsByType: [],
      approvalFlowDetail: [],
      selectedInsuranceFees: [],
      selectedWifiRouterRental: [],
      selectedCompanyCostItems: [],
      selectedEstimateCostItems: [],
      displayAddCostBearing: true,
      previousApprovalCompanyCosts: [],
      previousApprovalEstimateCosts: [],
      previousDailyAllowances: [],
      previousInsurances: 0,
      previousWifiRouterRental: 0,

      showModalUploadFile: false,
      listFileSelected: [],
      regularCheck: [],

      costTab: true,
      isDisplayAllowance: false,
      addApproverVisible: false,
      curInputApprover: 0,
      inputApprover: [],
      reportList: [],
      approvalCosts: {
        request: {
          approval_flow_id: 0,
        },
        company_cost_items: [],
      },
      listOrganizations: [],
      flagHaveDuplicated: false,

      //Submit Value
      tripPurpose: '',
      businessReportComment: '',

      //Fees
      dailyAllowances: [],
      insuranceFees: [],

      //Other estimate fees
      otherInsuranceFeeEst: '',
      otherCompanyExpenseEst: [],
      otherEstimateExpenseEst: [],
      approvedBearing: [
        {
          organization_id: this.userOrganizationId,
          price_percentage: this.userOrganizationId ? 100 : COST_ORGANIZATION_PERCENTAGE,
        },
      ],
      errors: {
        files: '',
        approver_flow_id: '',
        other_fee_est: '',
        other_entertainment_est: '',
        other_meeting_est: '',
        other_accommodation_est: '',
        other_transportation_est: '',
        other_insurance_est: '',
        total_price_percentage: '',
        have_duplicated_fields: '',
        approval_flows: [],
        selected_bearing: {
          organization: [],
          price_percentage: [],
        },
        other_company_expense_est: [],
        other_estimate_expense_est: [],
        purpose: '',
        comment: '',
      },
    };
  },
  created() {
    this.initData();
  },
  computed: {
    currentApprovalFlowHaveSelectOption() {
      if (this.checkEmpty(this.approvalFlowDetail)) return false;
      let flag = false;
      for (let i = 0; i < this.approvalFlowDetail.length; i++) {
        if (this.approvalFlowDetail[i].user_select_option == 1) flag = true;
      }
      return flag;
    },
    approvalFlowIdList() {
      if (this.checkEmpty(this.approvalFlowsByType)) return [];
      let approvalFlowList = [];

      this.approvalFlowsByType.forEach(item => approvalFlowList.push(item.id));
      return approvalFlowList;
    },
    listOrganizationIds() {
      if (this.checkEmpty(this.listOrganizations)) return [];
      let organizationIds = [];

      this.listOrganizations.forEach(item => organizationIds.push(item.id));
      return organizationIds;
    },
    tripId() {
      return this.$route.params.id || '';
    },
    currentApproverStepList() {
      return this.approvalFlowDetail[this.curInputApprover]?.step_detail || [];
    },
    defaultApprovalFlows() {
      if (this.default_approval_flow_id) {
        return this.default_approval_flow_id;
      }
      // if (
      //   this.dataToBusinessReport.trip_code == 'approval_rejected' &&
      //   this.allReportDataAfterReject.approval_flow?.id
      // ) {
      //   return this.allReportDataAfterReject.approval_flow?.id;
      // }
      // if (this.businessReportType == this.tripReport && this.approvalCosts.request?.approval_flow_id)
      //   return this.approvalCosts.request.approval_flow_id;
      // if (!this.checkEmpty(this.approvalFlowsByType)) return this.approvalFlowsByType[0]?.id;
      return 0;
    },
    totalApprovedExpenses() {
      let approvedExpenses = 0;
      if (!this.checkEmpty(this.previousApprovalCompanyCosts)) {
        this.previousApprovalCompanyCosts.forEach(item => {
          if (!this.checkEmpty(item)) approvedExpenses += item.amount;
        });
      }

      if (!this.checkEmpty(this.previousApprovalEstimateCosts)) {
        this.previousApprovalEstimateCosts.forEach(item => {
          if (!this.checkEmpty(item)) approvedExpenses += item.amount;
        });
      }

      return approvedExpenses + this.totalPreviousInsurances + this.totalPreviousDailyAllowances + this.totalPreviousWifiRouterRental;
    },
    totalPreviousDailyAllowances() {
      let total = 0;
      if (!this.checkEmpty(this.previousDailyAllowances)) {
        this.previousDailyAllowances.forEach(item => (total += item.amount));
      }
      return total;
    },
    totalPreviousInsurances() {
      let total = 0;
      if (!this.checkEmpty(this.previousInsurances)) {
        this.previousInsurances.forEach(item => (total += item.amount));
      }
      return total;
    },
    totalPreviousWifiRouterRental() {
      let total = 0;
      if (!this.checkEmpty(this.previousWifiRouterRental)) {
        this.previousWifiRouterRental.forEach(item => (total += item.amount));
      }
      return total;
    },
    totalApprovedEstimateExpenses() {
      let approvedExpenses = 0;
      if (!this.checkEmpty(this.previousApprovalEstimateCosts)) {
        this.previousApprovalEstimateCosts.forEach(item => {
          if (!this.checkEmpty(item)) approvedExpenses += item.amount;
        });
      }

      return approvedExpenses + this.totalPreviousInsurances + this.previousWifiRouterRental;
    },
    approvalAndReportExpenseDiff() {
      const costDiff = this.totalCostBearing - this.totalApprovedExpenses;
      if (costDiff >= 0) return this.splitThousandYen(costDiff);
      return '-' + String(this.splitThousandYen(Math.abs(costDiff)));
    },
    totalInsuranceFees() {
      let totalCost = 0;
      this.selectedInsuranceFees?.forEach(element => {
        totalCost += element.amount;
      });
      let otherInsuranceEstNum = 0;
      if (!this.checkEmpty(this.otherInsuranceFeeEst)) {
        otherInsuranceEstNum = parseInt(this.otherInsuranceFeeEst?.replaceAll(',', '')) || 0;
      }
      return totalCost + otherInsuranceEstNum;
    },
    totalWifiRouterRental() {
      let totalCost = 0;
      this.selectedWifiRouterRental?.forEach(element => {
        totalCost += element.amount;
      });
      let otherInsuranceEstNum = 0;
      if (!this.checkEmpty(this.otherInsuranceFeeEst)) {
        otherInsuranceEstNum = parseInt(this.otherInsuranceFeeEst?.replaceAll(',', '')) || 0;
      }
      return totalCost + otherInsuranceEstNum;
    },
    totalDailyAllowance() {
      let totalCost = 0;
      if (!this.checkEmpty(this.approvalCosts.daily_allowances)) {
        this.approvalCosts.daily_allowances?.forEach(element => {
          if (!this.checkEmpty(element.amount)) {
            totalCost += element.amount;
          }
        });
      }
      return totalCost;
    },
    totalCostBearing() {
      let totalCost = 0;
      if (!this.checkEmpty(this.totalCompanyCosts)) {
        this.totalCompanyCosts.forEach(item => {
          totalCost += item;
        });
      }
      if (!this.checkEmpty(this.totalEstimateCosts)) {
        this.totalEstimateCosts.forEach(item => {
          totalCost += item;
        });
      }
      return this.totalInsuranceFees + totalCost + this.totalDailyAllowance + this.totalWifiRouterRental;
    },
    totalCompanyCosts() {
      let totalCost = [];
      if (!this.checkEmpty(this.selectedCompanyCostItems)) {
        this.selectedCompanyCostItems.forEach((item, index) => {
          totalCost[index] = item.amount;
          let estimatedCosts = [];
          if (this.checkEmpty(this.otherCompanyExpenseEst[index])) {
            estimatedCosts[index] = 0;
          } else {
            estimatedCosts[index] = parseFloat(this.otherCompanyExpenseEst[index].replaceAll(',', ''));
          }
          totalCost[index] += estimatedCosts[index];
        });
      }

      return totalCost;
    },
    totalEstimateCosts() {
      let totalCost = [];
      if (!this.checkEmpty(this.selectedEstimateCostItems)) {
        this.selectedEstimateCostItems.forEach((item, index) => {
          totalCost[index] = 0;
          if (!this.checkEmpty(item.cost_items)) {
            item.cost_items.forEach(item => (totalCost[index] += item.amount));
          }
          let estimatedCosts = [];
          if (this.checkEmpty(this.otherEstimateExpenseEst[index])) {
            estimatedCosts[index] = 0;
          } else {
            estimatedCosts[index] = parseFloat(this.otherEstimateExpenseEst[index].replaceAll(',', ''));
          }
          totalCost[index] += estimatedCosts[index];
        });
      }

      return totalCost;
    },
    destinationType() {
      return this.dataToBusinessReport.destination_type || '';
    },
    //Total remainder of all cost bearing
    totalCostBearingDecimal() {
      let total = 0;
      if (this.approvedBearing.length < 2) return 0;
      this.approvedBearing.forEach(item => {
        const price = item.price_percentage * this.totalCostBearing * 0.01;
        total += price - Math.floor(price);
      });
      return total;
    },
    userOrganizationId() {
      return this.$store.state.auth?.user?.organization_id || '';
    },
    lastRequestId() {
      if (this.checkEmpty(this.dataToBusinessReport.requests)) return '';
      return this.dataToBusinessReport.requests[this.dataToBusinessReport.requests.length - 1].id;
    },
    listOrganizationUsing() {
      let listIdUsing = this.approvedBearing.map(item => item.organization_id) || [];

      return listIdUsing;
    },
  },
  watch: {
    lastRequestId(newVal) {
      if (!this.checkEmpty(newVal)) this.getListCostsByDate();
    },
    defaultApprovalFlows(newVal) {
      if (
          !this.checkEmpty(newVal) &&
          !this.checkEmpty(this.approvalFlowIdList) &&
          !this.approvalFlowIdList.includes(this.newVal)
      ) {
        this.errors.approver_flow_id = this.$t('business_report.approval_flow_changed_or_deleted');
        this.approvalFlowDetail = [];
      }
    },
    async dialogVisible(val) {
      if (val) {
        this.startLoading();
        if (this.businessReportType === this.tripApproval) {
          await this.$services.trip.tripDetail(
              this.$route.params.id,
              res => {
                this.regularCheck = res?.regular_check || [];
              },
              err => {
                this.notifyError(this.$t(err.error));
              },
          );
        }
        await this.$services.report.getReportFile(
            { trip_id: this.$route.params.id },
            res => {
              this.endLoading();
              this.reportList = res.list;
            },
            err => {
              this.endLoading();
              this.notifyError(this.$t(err.error));
            },
        );
      }
      if (val && this.businessReportType === this.tripApproval) await this.getApprovalCosts();
      if (val && this.businessReportType === this.tripReport) await this.getReportCosts();
      if (val && !this.checkEmpty(this.curApprovalFLowId)) {
        await this.getApprovalFlowStep(this.curApprovalFLowId, false);
        this.isVisible = val;
        return;
      }
      this.isVisible = val;
    },
    userOrganizationId(newVal) {
      this.approvedBearing[0].price_percentage = newVal ? 100 : 0;
      this.approvedBearing[0].organization_id = newVal;
    },
    allReportDataAfterReject: {
      handler(newVal) {
        if (this.checkEmpty(newVal) || this.dataToBusinessReport.trip_code != 'approval_rejected') return;
        this.businessReportComment = newVal.request?.comment || '';
        this.tripPurpose = newVal.request?.purpose || '';
        this.approvalCosts.request.approval_flow_id = newVal.approval_flow?.id;
        this.approvalCosts.daily_allowances = newVal.daily_allowances;
        // this.approvalCosts.company_cost_items = newVal.company_cost_items;
        // this.approvalCosts.cost_estimate = newVal.cost_estimate;
        // this.inputApprover = [];
        if (!this.checkEmpty(newVal.cost_bearing_organizations)) {
          this.approvedBearing = [];
          newVal.cost_bearing_organizations.forEach(item => {
            this.approvedBearing.push({
              price_percentage: item?.price_percentage,
              amount: item?.amount,
              organization_id: item?.id,
            });
          });
        }
        this.insuranceFees = newVal.insurance;
        this.asyncApproverListInput();
      },
      deep: true,
    },
    destinationType() {
      this.getApprovalFlowsByType();
      this.getApprovalFlowsByNearest({request_type: this.destinationType});
    },
    businessReportType(newVal) {
      this.getApprovalFlowsByNearest({request_type: this.destinationType});
      if (newVal === this.tripApproval && this.isVisible) this.getApprovalCosts();
      if (newVal === this.tripReport && this.isVisible) this.getReportCosts();
    },
    approvalFlowDetail() {
      this.asyncApproverListInput();
    },
    'approvalCosts.insurance'(newVal) {
      if (!this.checkEmpty(newVal)) {
        this.selectedInsuranceFees = this.cloneObject(newVal);
        // this.previousInsurances = newVal[0]?.amount;
      }
    },
    'approvalCosts.wifi_router_rental'(newVal) {
      if (!this.checkEmpty(newVal)) {
        this.selectedWifiRouterRental = this.cloneObject(newVal);
        // this.previousWifiRouterRental = newVal[0]?.amount;
      }
    },
    'approvalCosts.company_cost_items'(newVal) {
      const cloneCompanyCosts = this.cloneObject(newVal);
      this.selectedCompanyCostItems = cloneCompanyCosts;
      // if (
      //   !this.checkEmpty(cloneCompanyCosts) &&
      //   (this.dataToBusinessReport.trip_code == 'approval_rejected' ||
      //     this.dataToBusinessReport.trip_code == 'report_rejected' ||
      //     this.dataToBusinessReport.trip_code == 'report_no_report' ||
      //     this.dataToBusinessReport.trip_code == 'report_no_report_change')
      // ) {
      //   cloneCompanyCosts.forEach((item, index) => {
      //     const indexInList = this.previousApprovalCompanyCosts.findIndex(detail => detail.id == item.id);
      //     if (!this.checkEmpty(item.cost_items) && indexInList != -1 && indexInList != undefined) {
      //       item.cost_items.forEach((costItem, costId) => {
      //         const existedInPrevious = this.previousApprovalCompanyCosts[indexInList]?.cost_items.findIndex(
      //           cost => costItem.id == cost.cost_id,
      //         );
      //         if (existedInPrevious == -1 || existedInPrevious == undefined) {
      //           this.selectedCompanyCostItems[index].cost_items.splice(costId, 1);
      //           this.selectedCompanyCostItems[index].amount -= costItem.amount;
      //         }
      //       });
      //     } else {
      //       this.selectedCompanyCostItems[index].cost_items = [];
      //       this.selectedCompanyCostItems[index].amount = 0;
      //     }
      //   });
      // }
    },
    'approvalCosts.cost_estimate'(newVal) {
      const cloneCostEstimate = this.cloneObject(newVal);
      this.selectedEstimateCostItems = cloneCostEstimate;
      // if (
      //   !this.checkEmpty(cloneCostEstimate) &&
      //   (this.dataToBusinessReport.trip_code == 'approval_rejected' ||
      //     this.dataToBusinessReport.trip_code == 'report_rejected' ||
      //     this.dataToBusinessReport.trip_code == 'report_no_report' ||
      //     this.dataToBusinessReport.trip_code == 'report_no_report_change')
      // ) {
      //   cloneCostEstimate.forEach((item, index) => {
      //     const indexInList = this.previousApprovalEstimateCosts.findIndex(
      //       detail => detail.schedule_group_type == item.schedule_group_type,
      //     );
      //     if (!this.checkEmpty(item.cost_items) && indexInList != -1 && indexInList != undefined) {
      //       item.cost_items.forEach((costItem, costId) => {
      //         const existedInPrevious = this.previousApprovalEstimateCosts[indexInList]?.cost_items.findIndex(
      //           cost => costItem.id == cost.cost_id,
      //         );
      //         if (existedInPrevious == -1 || existedInPrevious == undefined) {
      //           this.selectedEstimateCostItems[index].cost_items.splice(costId, 1);
      //           // this.selectedEstimateCostItems[index].amount -= costItem.amount;
      //         }
      //       });
      //     } else {
      //       this.selectedEstimateCostItems[index].cost_items = [];
      //       this.selectedEstimateCostItems[index].amount = 0;
      //     }
      //   });
      // }
    },
    'approvalCosts.request'(newVal) {
      if (this.checkEmpty(newVal)) return;
      if (!this.checkEmpty(newVal.purpose)) this.tripPurpose = newVal?.purpose;
      if (!this.checkEmpty(newVal.comment)) this.businessReportComment = newVal?.comment;
    },
    'approvalCosts.request_cost_bearing_organizations'(newVal) {
      if (this.businessReportType === this.tripReport) {
        this.approvedBearing = [];
        if (!this.checkEmpty(newVal)) {
          newVal.forEach(item => {
            this.approvedBearing.push({
              amount: 0,
              price_percentage: item.price_percentage,
              organization_id: item.burden_organization_id,
            });
          });
        }
        this.calCostBearing(this.approvedBearing);
      }
    },
    totalCostBearing() {
      this.calCostBearing(this.approvedBearing);
    },
    approvedBearing: {
      handler(newVal) {
        this.calCostBearing(newVal);
      },

      deep: true,
    },

    inputApprover: {
      handler(newVal) {
        if (!this.checkEmpty(newVal)) {
          newVal.forEach((item, index) => {
            if (!this.checkEmpty(item.display_text)) this.errors.approval_flows[index] = '';
          });
        }
      },

      deep: true,
    },
  },
  methods: {
    titleType(type) {
      switch (type) {
        case 1:
          return '出張日数';
        case 2:
          return 'パスポート残存有効期限必要日数';
        case 3:
          return '別途海外旅行傷害保険の手続き必須';
        case 4:
          return '乗り継ぎ禁止地（空港）';
        case 5:
          return '利用禁止航空会社';
        case 6:
          return '禁止フライト区間';
        case 7:
          return '注意フライト区間';
        case 8:
          return '空港ごとの乗り継ぎ時間(下限より短い)';
        case 9:
          return '空港ごとの乗り継ぎ時間(上限より長い)';
        case 10:
          return '空港ごとの乗り継ぎ注意航空会社';
        case 11:
          return '乗り継ぎ回数';
        case 12:
          return '座席クラス(国内線)';
        case 13:
          return '座席クラス(国際線)';
        case 14:
          return '金額(航空券、下限より安い)';
        case 15:
          return '金額(航空券、上限より高い)';
        case 16:
          return '金額(ホテル、下限より安い)';
        case 17:
          return '金額(ホテル、上限より高い)';
        case 18:
          return '出張費用総額(下限より安い)';
        case 19:
          return '出張費用総額(上限より高い)';
        default:
          return '-';
      }
    },
    initData() {
      this.startLoading();
      Promise.all([this.getApprovalFlowsByType(), this.getListOrganizations()]).then(() => {
        this.endLoading();
      });
      this.getDailyAllowance();
      this.approvedBearing[0].price_percentage = this.userOrganizationId ? 100 : 0;
      this.approvedBearing[0].organization_id = this.userOrganizationId;
    },
    confirmUploadFile(files) {
      files.forEach(file => {
        let fileExits = this.listFileSelected.find(item => item.name == file.name);
        if (this.checkEmpty(fileExits)) {
          this.listFileSelected.push(file);
          this.errors.files = '';
        } else {
          this.notifyError(this.$t('common.invalid_file'));
        }
      });
      this.showModalUploadFile = false;
    },
    deleteFile(item) {
      this.listFileSelected = this.listFileSelected.filter(file => file.name != item.name);
    },
    showPreviousCostItemAmount(parentCost, curCostDetail, type = 2) {
      if (type == 1) {
        const indexInList = this.previousApprovalCompanyCosts.findIndex(
            previousCost => previousCost.id == parentCost.id,
        );
        if (indexInList != -1 || indexInList !== undefined) {
          const costDetailInList = this.previousApprovalCompanyCosts[indexInList]?.cost_items.filter(cost => {
            if (cost.cost_id == curCostDetail.id) return cost;
          });

          if (!this.checkEmpty(costDetailInList)) return this.splitThousandYen(curCostDetail.amount);
        }
        return '-';
      } else {
        const indexInList = this.previousApprovalEstimateCosts.findIndex(
            previousCost => previousCost.schedule_group_type == parentCost.schedule_group_type,
        );
        if (indexInList != -1 || indexInList !== undefined) {
          const costDetailInList = this.previousApprovalEstimateCosts[indexInList]?.cost_items.filter(
              cost => cost.cost_id == curCostDetail.id,
          );
          if (!this.checkEmpty(costDetailInList)) return this.splitThousandYen(curCostDetail.amount);
        }
        return '-';
      }
    },
    showTotalPreviousCompanyCost(cost) {
      const indexInList = this.previousApprovalCompanyCosts.findIndex(item => item.id == cost.id);
      if (indexInList != -1 && indexInList != undefined) return this.previousApprovalCompanyCosts[indexInList].amount;
      return 0;
    },
    showPreviousEstimateCost(cost) {
      const indexInList = this.previousApprovalEstimateCosts.findIndex(
          item => item.schedule_group_type == cost.schedule_group_type,
      );
      if (indexInList != -1 && indexInList != undefined) return this.previousApprovalEstimateCosts[indexInList].amount;
      return 0;
    },
    async getDailyAllowance() {
      await this.$services.zone.getDailyAllowance(
          res => {
            this.dailyAllowanceFiles = res.files;
          },
          err => {
            console.log(err);
          },
      );
    },
    openFileOnNewTab() {
      if (this.dailyAllowanceFiles.length > 0 && this.dailyAllowanceFiles[this.dailyAllowanceFiles.length - 1]?.link)
        window.open(this.dailyAllowanceFiles[this.dailyAllowanceFiles.length - 1].link, '_blank');
    },
    openFileKite() {
      if (this.tripDetail.regulation_files.length > 0) {
        const url = this.tripDetail?.regulation_files[this.tripDetail.regulation_files.length - 1]?.file_url;
        if (url) window.open(url, '_blank');
      }
    },
    isNumber: function (event = window.event) {
      let charCode = event.which ? event.which : event.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        event.preventDefault();
      } else {
        return true;
      }
    },
    textToPaymentMethod(status) {
      if (status === payment_reimbursement) return this.$t('trip_item.main_content.payment_reimbursement');
      if (status === PAYMENT_CORPORATE_CARD) return this.$t('trip_item.main_content.payment_corporate_card');
      if (status === PAYMENT_INVOICE) return this.$t('trip_item.main_content.payment_invoice');
      return '';
    },
    groupScheduleTypeLabel(type) {
      switch (type) {
        case 0:
          return '移動';
        case 1:
          return '宿泊';
        case 2:
          return '仕事';
        case 3:
          return 'その他';
        default:
          return '-';
      }
    },
    convertNumberFullToHalf(number) {
      number = number.replace(/[^0-9０-９.．]/g, '');
      number = String(number).replace(/[\uFF10-\uFF19]/g, function (m) {
        return String.fromCharCode(m.charCodeAt(0) - 0xfee0);
      });

      return number.replaceAll('．', '.');
    },
    customCostPadding(cost) {
      const costNum = String(cost).length - 3;
      if (costNum > 0) return String(`${costNum * 7}px`);
      return '0px';
    },
    openApprovedExpenses() {
      this.$emit('openApprovedExpenses');
    },
    showReportTab() {
      (this.costTab = false), this.getReportList();
    },
    openAddApprover(inputApproverId, stepId) {
      this.inputApprover[inputApproverId] = {};
      this.curInputApprover = inputApproverId;
      this.inputApprover[inputApproverId].step_id = stepId;
      this.addApproverVisible = true;
    },
    closeAddApprover() {
      this.addApproverVisible = false;
    },
    handleAddApprover(params) {
      if (this.isEmployeeManagerOperatorOrAdmin || this.isShowUserId) {
        this.inputApprover[this.curInputApprover].display_text = params.employee_number + ' ' + params.name;
      } else {
        this.inputApprover[this.curInputApprover].display_text = params.name;
      }
      this.inputApprover[this.curInputApprover].approve_id = params.id;
      this.addApproverVisible = false;
      this.isVisible = true;
    },
    closeModal() {
      this.$emit('closeModal');
    },
    listenApprovalFlows(approvalFlowId) {
      if (!this.checkEmpty(approvalFlowId)) {
        this.curApprovalFLowId = approvalFlowId;
        if (approvalFlowId != 0 && !this.checkEmpty(approvalFlowId)) {
          this.errors.approver_flow_id = '';
        }
        if (!this.checkEmpty(approvalFlowId)) this.getApprovalFlowStep(approvalFlowId);
      }
    },
    initApprovalFees(costItem, selectedList, originalList) {
      if (!this[`${selectedList}`].includes(costItem)) {
        this[`${selectedList}`].push(costItem);
      }
      if (!this[`${originalList}`].includes(costItem)) {
        this[`${originalList}`].push(costItem);
      }
    },
    displayStepTitle(stepIndex) {
      stepIndex++;
      const lastStep = this.approvalFlowDetail.length;
      if (stepIndex == lastStep) {
        return this.$t('trip_item.trip_process.finally');
      }
      return stepIndex;
    },
    textToStepType(stepType) {
      if (stepType === APPROVAL) return this.$t('trip_item.trip_process.approval');
      if (stepType === PASS_ROUND_PERUSAL) return this.$t('trip_item.trip_process.pass_perusal');
      return '-';
    },

    //validate cost bearing organization
    validateCostBearing() {
      let totalPricePercentage = 0;
      this.approvedBearing.forEach((item, index) => {
        totalPricePercentage += parseFloat(item.price_percentage);
        if (this.checkEmpty(item.organization_id)) {
          this.errors.selected_bearing.organization[index] = this.$t('E-CM-004');
        }
        if (!this.checkEmpty(item.organization_id)) {
          if (!this.flagHaveDuplicated && this.checkDuplicatedField(item.organization_id, 'organization_id')) {
            this.errors.have_duplicated_fields = this.$t('E-CU-068');
          }
          this.errors.selected_bearing.organization[index] = '';
        }
        if (this.checkEmpty(item.price_percentage) && item.price_percentage !== 0) {
          this.errors.selected_bearing.price_percentage[index] = this.$t('E-CM-004');
        } else if (!this.isNumeric(item.price_percentage)) {
          this.errors.selected_bearing.price_percentage[index] = this.$t(
              'E-CM-047',
              this.$t('business_report.cost_percentage'),
          );
        } else {
          this.errors.selected_bearing.price_percentage[index] = '';
        }
      });

      if (totalPricePercentage != 100) {
        this.errors.total_price_percentage = this.$t('business_report.total_cost_bearing_err');
      } else {
        this.errors.total_price_percentage = '';
      }
    },
    appendToStepClass(stepIndex, hasForm) {
      if (hasForm === 1) {
        if (stepIndex == 0) return 'first-step has-form';
        if (stepIndex != 0 && this.approvalFlowDetail[stepIndex - 1]?.user_select_option == 1)
          return 'has-previous-form';
        return 'has-form';
      }
      if (stepIndex == 0) return 'first-step';
      return '';
    },
    calCostBearing(newVal) {
      let totalPercentage = 0;
      if (!this.checkEmpty(newVal)) {
        newVal.forEach((item, index) => {
          if (this.isNumeric(parseFloat(item.price_percentage))) totalPercentage += parseFloat(item.price_percentage);
          const costPercentage = this.isNumeric(parseFloat(item.price_percentage))
              ? parseFloat(item.price_percentage)
              : 0;
          const price = costPercentage * this.totalCostBearing * 0.01;
          const roundNum = Math.floor(price);
          this.approvedBearing[index].amount =
              index != 0 ? roundNum : +(Math.floor(price + this.totalCostBearingDecimal + 'e+0') + 'e-0');
        });
      }
      if (totalPercentage >= 100) {
        this.displayAddCostBearing = false;
        return;
      }
      this.displayAddCostBearing = true;
    },
    removeCostBearing(bearingDetail) {
      if (this.approvedBearing.includes(bearingDetail)) {
        this.approvedBearing.splice(this.approvedBearing.indexOf(bearingDetail), 1);
      }
    },
    addCostBearing() {
      let currentPercentage = 0;
      this.approvedBearing.forEach(item => {
        if (!this.checkEmpty(item.price_percentage)) {
          currentPercentage += parseFloat(item.price_percentage);
        } else {
          currentPercentage += 0;
        }
      });
      if (currentPercentage != 100) {
        this.approvedBearing.push({ price_percentage: +(Math.round(100 - currentPercentage + 'e+3') + 'e-3') });
      }
      this.validateCostBearing();
    },
    handleBearingOrganization(organizationId, optionIndex) {
      this.approvedBearing[optionIndex].organization_id = organizationId;
      this.validateCostBearing();
    },
    selectInsuranceFees(costItem, index) {
      if (this.isInSelectedInsurance(costItem)) {
        this.selectedInsuranceFees.splice(index, 1);
        return;
      }
      this.selectedInsuranceFees.push(costItem);
    },
    selectWifiRouterRental(costItem) {
      if (this.isInSelectedWifiRouterRental(costItem)) {
        this.selectedWifiRouterRental = this.selectedWifiRouterRental.filter(item => item.id != costItem.id);
        return;
      }
      this.selectedWifiRouterRental.push(costItem);
    },
    selectCompanyFees(index, costItem) {
      const costIndex = this.selectedCompanyCostItems[index].cost_items?.findIndex(ele => ele.id === costItem.id);
      if (costIndex !== -1) {
        this.selectedCompanyCostItems[index].cost_items.splice(costIndex, 1);
        this.selectedCompanyCostItems[index].amount -= costItem.amount;
      } else {
        this.selectedCompanyCostItems[index].cost_items.push(costItem);
        costItem.checked = true;
        this.selectedCompanyCostItems[index].amount += costItem.amount;
      }
    },
    selectEstimateFees(index, costItem) {
      const costIndex = this.selectedEstimateCostItems[index].cost_items?.findIndex(ele => ele.id === costItem.id);
      if (costIndex !== -1) {
        this.selectedEstimateCostItems[index].cost_items.splice(costIndex, 1);
        costItem.checked = false;
        this.selectedEstimateCostItems[index].amount -= costItem.amount;
      } else {
        this.selectedEstimateCostItems[index].cost_items.push(costItem);
        costItem.checked = true;
        this.selectedEstimateCostItems[index].amount += costItem.amount;
      }
    },
    checkDuplicatedField(value, name) {
      let countItem = 0;
      this.approvedBearing.forEach(item => {
        if (item[`${name}`] === value) {
          countItem++;
        }
      });
      if (countItem > 1) {
        this.flagHaveDuplicated = true;
        return true;
      } else {
        this.flagHaveDuplicated = false;
        this.errors.have_duplicated_fields = '';
        return false;
      }
    },

    //Convert to JP string
    convertData(data, type, index = '') {
      switch (type) {
        case 1:
          this.otherInsuranceFeeEst = data
              .replace(/(\..*?)\..*/g, '$1')
              .replace(/^(?!00[^0])0/, '')
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          break;
        case 2:
          this.otherEstimateExpenseEst[index] = data
              .replace(/(\..*?)\..*/g, '$1')
              .replace(/^(?!00[^0])0/, '')
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          break;
        default:
          this.otherCompanyExpenseEst[index] = data
              .replace(/(\..*?)\..*/g, '$1')
              .replace(/^(?!00[^0])0/, '')
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          break;
      }
    },
    //CU-019
    async getApprovalFlowsByType() {
      let params = {
        request_type: APPROVAL_OR_REPORT_REQUEST_TYPE,
      };
      if (this.checkEmpty(this.destinationType)) return;
      params.trip_type = this.destinationType;
      await this.$services.trip_business_report.getApprovalFlowsByType(
          params,
          res => {
            this.approvalFlowsByType = res.list;
          },
          err => {
            this.notifyError(this.$t(err.error));
          },
      );
    },
    //CU-314
    async getApprovalFlowsByNearest(params) {
      await this.$services.trip.getApprovalFlowNearest(
          params,
          res => {
            this.default_approval_flow_id = res?.approval_flow_id || null;
          },
          err => {
            this.notifyError(this.$t(err.error));
          },
      );
    },
    //CU-020
    async getApprovalFlowStep(approvalFlowId, showLoading = true) {
      showLoading && this.startLoading();
      const params = {
        approval_flow_id: approvalFlowId,
      };
      await this.$services.trip_business_report.getApprovalFlowStep(
          params,
          res => {
            showLoading && this.endLoading();
            this.approvalFlowDetail = res.list;
            this.inputApprover = [];
            this.errors.approval_flows = [];
          },
          err => {
            showLoading && this.endLoading();
            this.notifyError(this.$t(err.error));
          },
      );
    },
    //CU-016
    async getReportList() {
      this.startLoading();
      await this.$services.report.getReportFile(
          { trip_id: this.$route.params.id },
          res => {
            this.endLoading();
            this.reportList = res.list;
          },
          err => {
            this.endLoading();
            this.notifyError(this.$t(err.error));
          },
      );
    },
    isInSelectedInsurance(item) {
      const costIndex = this.selectedInsuranceFees.findIndex(ele => ele.id === item.id);

      return costIndex !== -1;
    },
    isInSelectedWifiRouterRental(item) {
      const costIndex = this.selectedWifiRouterRental.findIndex(ele => ele.id === item.id);

      return costIndex !== -1;
    },

    isInSelectedCompanyCost(index, item) {
      const costIndex = this.selectedCompanyCostItems[index].cost_items?.findIndex(ele => ele.id === item.id);

      return costIndex !== -1;
    },
    isInSelectedEstimateCost(index, item) {
      const costIndex = this.selectedEstimateCostItems[index].cost_items?.findIndex(ele => ele.id === item.id);

      return costIndex !== -1;
    },

    //CU-033 - Get approval costs
    async getApprovalCosts(hasLoading = true) {
      !hasLoading && this.startLoading();
      if (
          this.dataToBusinessReport.trip_code == 'approval_rejected' &&
          this.$route.params.id &&
          !this.checkEmpty(this.lastRequestId)
      ) {
        let params = {
          trip_id: this.$route.params.id,
          request_id: this.lastRequestId,
        };
        params.flg_send_request = 1;
        params.flg_modal = 1;
        await this.$services.trip_item.getListCostsByDate(
            params,
            res => {
              this.endLoading();
              this.allReportDataAfterReject = res.list;
            },
            err => {
              this.endLoading();
              this.notifyError(this.$t(err.error));
            },
        );
      }
      await this.$services.trip_business_report.getApprovalCosts(
          { trip_id: this.$route.params.id },
          res => {
            this.endLoading();
            this.approvalCosts = res.list;
            this.approvalCosts.request = {
              approval_flow_id: this.allReportDataAfterReject.approval_flow.id || 0,
            };
            if (!this.checkEmpty(res.list?.insurance)) this.insuranceFees = res.list.insurance;
          },
          err => {
            this.endLoading();
            this.notifyError(this.$t(err.error));
          },
      );
    },
    asyncApproverListInput(newVal = this.allReportDataAfterReject) {
      let approverList = [];
      if (this.checkEmpty(newVal.requestSteps)) {
        this.inputApprover = [];
        return;
      }
      if (this.approvalCosts.request.approval_flow_id != this.curApprovalFLowId) return;
      if (!this.checkEmpty(newVal.requestSteps.request_steps)) {
        newVal.requestSteps.request_steps.forEach((item, index) => {
          const stepListDetail = this.approvalFlowDetail[this.approvalFlowDetail.length - 1 - index]?.step_detail || [];
          if (!this.checkEmpty(item.request_child_steps)) {
            item.request_child_steps.forEach(child => {
              if (stepListDetail.findIndex(detail => detail.user_id == child.approve_id) == -1) {
                approverList.push({
                  step_id: item.order,
                  display_text:
                      this.isEmployeeManagerOperatorOrAdmin || this.isShowUserId
                          ? child.approver?.employee_number + ' ' + child.approver?.name
                          : child.approver?.name,
                  approve_id: child.approve_id,
                });
              }
            });
          }
        });
      }
      approverList.sort((a, b) => a.step_id - b.step_id);
      this.inputApprover = approverList;
    },
    //CU-027 - Get report cost
    getReportCosts(hasLoading = true) {
      !hasLoading && this.startLoading();
      this.$services.trip_business_report.getReportCosts(
          { trip_id: this.$route.params.id },
          res => {
            this.endLoading();
            this.approvalCosts = res.list;
            if (!this.checkEmpty(res.list?.insurance)) this.insuranceFees = res.list.insurance;
          },
          err => {
            this.endLoading();
            this.notifyError(this.$t(err.error));
          },
      );
    },
    //CU-003
    async getListOrganizations() {
      const params = {
        all_data: true,
      };
      await this.$services.common.getListOrganizations(
          params,
          res => {
            this.listOrganizations = res.list;
          },
          err => {
            this.notifyError(this.$t(err.error));
          },
      );
    },

    //CU-009
    getListCostsByDate() {
      let params = {
        trip_id: this.tripId,
        request_id: this.lastRequestId,
      };
      params.flg_send_request = 1;
      params.flg_modal = 1;
      this.$services.trip_item.getListCostsByDate(
        params,
        res => {
          if (this.checkEmpty(res.list)) {
            this.flg_old_val = false;
          } else {
            this.flg_old_val = true;
          }
          this.previousApprovalCompanyCosts = [];
          if (!this.checkEmpty(res.list.company_cost_items)) {
            res.list.company_cost_items.forEach((item, index) => {
              this.previousApprovalCompanyCosts[index] = {};
              this.previousApprovalCompanyCosts[index].id = item.id;
              this.previousApprovalCompanyCosts[index].title = item.title;
              this.previousApprovalCompanyCosts[index].amount = 0;
              this.previousApprovalCompanyCosts[index].cost_items = item.cost_items;
              if (!this.checkEmpty(item.cost_items)) {
                item.cost_items.forEach(cost => {
                  this.previousApprovalCompanyCosts[index].amount += cost.amount;
                });
              }
            });
          }

          this.previousApprovalEstimateCosts = [];
          if (!this.checkEmpty(res.list.cost_estimate)) {
            res.list.cost_estimate.forEach((item, index) => {
              this.previousApprovalEstimateCosts[index] = {};
              this.previousApprovalEstimateCosts[index].schedule_group_type = item.schedule_group_type;
              this.previousApprovalEstimateCosts[index].amount = 0;
              this.previousApprovalEstimateCosts[index].cost_items = item.cost_items;
              if (!this.checkEmpty(item.cost_items)) {
                item.cost_items.forEach(cost => {
                  this.previousApprovalEstimateCosts[index].amount += cost.amount;
                });
              }
            });
          }
          this.previousDailyAllowances = res.list?.daily_allowances;
          this.previousInsurances = res.list?.insurance_histories;
          this.previousWifiRouterRental = res.list?.wifi_router_rental_histories;
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },

    //CU-304
    async uploadRequestAttachments(requestId) {
      if (this.checkEmpty(this.listFileSelected)) {
        this.$emitter.emit('reloadInsuranceList');
        this.$emit('reloadTripDetail');
        this.$emitter.emit('reloadTripItemExpenses');
        this.notifySuccess(this.$t('trip_item.right_menu.modal_settlement_expense.have_sent_application'));
        this.endLoading();
        return;
      }
      let requestFormData = new FormData();
      this.listFileSelected.forEach(item => {
        requestFormData.append('files[]', item);
      });
      requestFormData.append('request_id', requestId);
      await this.$services.trip_business_report.uploadRequestFiles(
          requestFormData,
          () => {
            this.listFileSelected = [];
            this.$emitter.emit('reloadInsuranceList');
            this.$emit('reloadTripDetail');
            this.$emitter.emit('reloadTripItemExpenses');
            this.notifySuccess(this.$t('trip_item.right_menu.modal_settlement_expense.have_sent_application'));
            this.endLoading();
          },
          err => {
            this.endLoading();
            this.notifyError(this.$t(err.error), true);
          },
      );
    },

    //CU-012
    sendBusinessApproval() {
      let flgValidate = true;
      this.flagHaveDuplicated = false;

      //Validate other estimated fees
      if (!this.checkEmpty(this.otherEstimateExpenseEst)) {
        this.otherEstimateExpenseEst.forEach((item, index) => {
          if (!this.checkEmpty(item)) {
            const otherEstimated = parseFloat(item.replaceAll(',', ''));
            this.errors.other_estimate_expense_est[index] = '';
            if (!this.isNumeric(otherEstimated)) {
              flgValidate = false;
              this.errors.other_estimate_expense_est[index] = this.$t('E-CM-047', {
                item: 'その他' + this.approvalCosts.company_cost_items[index].title + '概算',
              });
            } else if (String(otherEstimated).length > 11) {
              flgValidate = false;
              this.errors.other_estimate_expense_est[index] = this.$t('E-CM-111', { size: 11 });
            } else {
              this.errors.other_estimate_expense_est[index] = '';
            }
          }
        });
      }

      //Validate approver flow id
      if (this.curApprovalFLowId == 0) {
        flgValidate = false;
        this.errors.approver_flow_id = this.$t('E-CM-100', {
          item: this.$t('business_report.approval_flow'),
        });
        this.$refs.trip_purpose.scrollIntoView({ behavior: 'smooth' });
      } else if (!this.approvalFlowIdList.includes(this.curApprovalFLowId)) {
        flgValidate = false;
        this.errors.approver_flow_id = this.$t('business_report.approval_flow_changed_or_deleted');
      } else {
        this.errors.approver_flow_id = '';
      }

      //Validate other insurance fees estimation
      if (!this.checkEmpty(this.otherInsuranceFeeEst)) {
        const otherInsurNum = parseFloat(this.otherInsuranceFeeEst?.replaceAll(',', ''));
        if (!this.isNumeric(otherInsurNum)) {
          flgValidate = false;
          this.errors.other_insurance_est = this.$t('E-CM-047', {
            item: this.$t('business_report.insurance_premium_est'),
          });
        } else if (String(otherInsurNum).length > 11) {
          flgValidate = false;
          this.errors.other_insurance_est = this.$t('E-CM-111', { size: 11 });
        } else {
          this.errors.other_insurance_est = '';
        }
      }

      //validate cost bearing organization
      let totalPricePercentage = 0;
      this.approvedBearing.forEach((item, index) => {
        totalPricePercentage += parseFloat(item.price_percentage);
        if (this.checkEmpty(item.organization_id)) {
          flgValidate = false;
          this.errors.selected_bearing.organization[index] = this.$t('E-CM-004');
          this.$refs.bearing_organizations.scrollIntoView({ behavior: 'smooth' });
        }
        if (!this.checkEmpty(item.organization_id)) {
          if (!this.flagHaveDuplicated && this.checkDuplicatedField(item.organization_id, 'organization_id')) {
            this.errors.have_duplicated_fields = this.$t('E-CU-068');
            flgValidate = false;
            this.$refs.bearing_organizations.scrollIntoView({ behavior: 'smooth' });
          }
          if (!this.listOrganizationIds.includes(item.organization_id)) {
            flgValidate = false;
            this.errors.selected_bearing.organization[index] = this.$t(
                'business_report.cost_bearing_flow_changed_or_deleted',
            );
            this.$refs.bearing_organizations.scrollIntoView({ behavior: 'smooth' });
          } else {
            this.errors.selected_bearing.organization[index] = '';
          }
        }
        if (this.checkEmpty(item.price_percentage) && item.price_percentage !== 0) {
          flgValidate = false;
          this.errors.selected_bearing.price_percentage[index] = this.$t('E-CM-004');
          this.$refs.price_percentage[index].focus();
        } else if (!this.isNumeric(item.price_percentage)) {
          flgValidate = false;
          this.errors.selected_bearing.price_percentage[index] = this.$t(
              'E-CM-047',
              this.$t('business_report.cost_percentage'),
          );
        } else {
          this.errors.selected_bearing.price_percentage[index] = '';
        }
      });

      if (totalPricePercentage != 100) {
        flgValidate = false;
        this.errors.total_price_percentage = this.$t('business_report.total_cost_bearing_err');
      } else {
        this.errors.total_price_percentage = '';
      }

      //Validate trip purpose
      if (this.checkEmpty(this.tripPurpose)) {
        flgValidate = false;
        this.errors.purpose = this.$t('E-CM-100', {
          item: this.$t('business_report.purpose'),
        });
        this.$refs.trip_purpose.focus();
      } else if (this.tripPurpose.length > 200) {
        flgValidate = false;
        this.errors.purpose = this.$t('E-CM-111', { size: 200 });
        this.$refs.trip_purpose.focus();
      } else {
        this.errors.purpose = '';
      }

      //Validate business report comment
      if (this.businessReportComment.length > 300) {
        flgValidate = false;
        this.errors.comment = this.$t('E-CM-111', { size: 300 });
        this.$refs.trip_comment.focus();
      } else {
        this.errors.comment = '';
      }

      //Validate input approver required
      if (!this.checkEmpty(this.approvalFlowDetail)) {
        this.approvalFlowDetail.forEach((flow, index) => {
          // if (
          //   this.checkEmpty(flow.step_detail) &&
          //   flow.user_select_option === 1 &&
          //   this.checkEmpty(this.inputApprover[index]?.display_text)
          // ) {
          //   flgValidate = false;
          //   this.errors.approval_flows[index] = this.$t('common.please_select');
          //   this.$refs.approval_flow.scrollIntoView({ behavior: 'smooth' });
          // } else {
          this.errors.approval_flows[index] = '';
          // }
        });
      }

      if (flgValidate) {
        let inputApproverList = [];
        if (!this.checkEmpty(this.inputApprover)) {
          this.inputApprover.forEach(item => {
            if (!this.checkEmpty(item) && !this.checkEmpty(item.approve_id)) inputApproverList.push(item);
          });
        }

        this.approvedBearing.forEach((item, index) => {
          this.approvedBearing[index].price_percentage = parseFloat(item.price_percentage);
        });

        this.otherCompanyExpenseEst.forEach((item, index) => {
          this.selectedCompanyCostItems[index].other_amount = parseFloat(item.replaceAll(',', ''));
        });

        this.otherEstimateExpenseEst.forEach((item, index) => {
          this.selectedEstimateCostItems[index].cost_items.push({
            id: 0,
            title:
                'その他' +
                this.groupScheduleTypeLabel(this.selectedEstimateCostItems[index].schedule_group_type) +
                '概算',
            amount: parseFloat(item.replaceAll(',', '')),
            is_other_amount: 1,
            payment_date: null,
            has_files: null,
            image_url: null,
            note: null,
            payable_to: null,
            payment_destination: null,
          });
        });

        let validEstimateCosts = [];
        if (!this.checkEmpty(this.selectedEstimateCostItems)) {
          this.selectedEstimateCostItems.forEach(item => {
            if (!this.checkEmpty(item.cost_items)) {
              item.cost_items.forEach(cost => {
                cost.checked = true;
                cost.is_other_amount = 1;
              });
              validEstimateCosts.push(item);
            }
          });
        }

        let validCompanyCosts = [];
        if (!this.checkEmpty(this.selectedCompanyCostItems)) {
          this.selectedCompanyCostItems.forEach(item => {
            if (!this.checkEmpty(item.cost_items)) {
              item.cost_items.forEach(cost => {
                cost.is_other_amount = 0;
                cost.checked = true;
              });
              validCompanyCosts.push(item);
            }
          });
        }

        const paramsBusinessForm = {
          trip_id: this.$route.params.id,
          trip_type: this.destinationType,
          purpose: this.tripPurpose,
          comment: this.businessReportComment,
          insurance_cost: this.totalInsuranceFees,
          insurances: this.selectedInsuranceFees,
          wifi_router_rental: this.selectedWifiRouterRental,
          company_cost_items: validCompanyCosts,
          cost_estimate: validEstimateCosts,
          cost_bearing_organizations: this.approvedBearing,
          approval_flow_id: this.curApprovalFLowId,
          approval_flow_steps: inputApproverList,
        };

        if (this.businessReportType === this.tripApproval) {
          //CU-012
          this.startLoading();
          this.$services.trip_business_report.sendBusinessApprovalApplication(
              paramsBusinessForm,
              res => {
                this.closeModal();
                this.uploadRequestAttachments(res.id);
              },
              () => {
                this.endLoading();
                this.closeModal();
                this.notifyError(this.$t('E-CM-005'));
              },
          );
        }
        if (this.businessReportType === this.tripReport) {
          //CU-032
          this.startLoading();
          this.$services.trip_business_report.sendBusinessReportApplication(
            paramsBusinessForm,
            res => {
              this.closeModal();
              this.uploadRequestAttachments(res.id);
              window.location.reload();
            },
            () => {
              this.endLoading();
              this.closeModal();
              this.notifyError(this.$t('E-CM-005'));
            },
          );
        }
      }
    },
  },
};
</script>
<style scoped>
.list-tab {
  cursor: pointer;
}
.select-approval-flow_1 {
  margin-top: 12px;
}
.step-list.step-list--ringi-apply {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
  margin-top: 12px;
  margin-bottom: 24px;
}
.step-list.step-list--ringi-apply .step-item {
  justify-content: flex-start;
  width: 100%;
  height: 24px;
  margin-bottom: 16px;
}
.step-list.step-list--ringi-apply .step-item.has-form {
  height: 36px;
}
.step-list.step-list--ringi-apply .step-item.has-form + .step-item::before {
  position: absolute;
  top: -26px;
  left: 7px;
  width: 2px;
  height: 30px;
}
.step-item.first-step::before {
  display: none;
}
.step-item.has-form::before {
  height: 30px !important;
}
.step-list.step-list--ringi-apply .step-mark + span {
  width: 40px;
  min-width: 40px;
  text-align: left;
}
.step-list.step-list--ringi-apply .list--approver li {
  display: initial;
}
.step-list.step-list--ringi-apply .list--approver {
  margin-bottom: 0;
  padding: 0;
}
.step-list.step-list--ringi-apply .list--approver li + li::before {
  display: inline-block;
  margin-right: 4px;
  content: '、';
}
.step-list.step-list--ringi-apply .step-item::before {
  position: absolute;
  top: -20px;
  left: 7px;
  width: 2px;
  height: 24px;
}
.step-list.step-list--ringi-apply .step-mark {
  min-width: 16px;
  margin-right: 12px;
}
.step-list.step-list--ringi-apply .step-item::after {
  display: none;
}
.step-list.step-list--ringi-apply .step-item span {
  display: inline-block;
  position: unset;
  top: unset;
  width: auto;
}
.step-list.step-list--ringi-apply .ringi-step--type {
  width: 48px;
  min-width: 48px;
  margin-right: 12px;
}
.step-item {
  display: flex;
  position: relative;
  align-items: center;
  color: #98a9b0;
  font-size: 12px;
  text-align: left !important;
}
.step-item::before {
  display: block;
  content: '';
  background-color: #d0d6da;
  content: '';
}
.ringi-step--type {
  top: unset !important;
  bottom: -24px;
  color: #45545b;
  font-size: 11px;
}
.js-btn--toggle-allowance {
  cursor: pointer;
}
.custom-select-approver {
  width: 55%;
  display: revert;
  justify-content: flex-end;
  margin-left: auto;
}
.padding-10 {
  padding-top: 10px;
}
.adjust-amount input {
  text-align: right;
}
.est-cost-check {
  padding-left: 28px;
  padding-top: 7px;
}
.custom-apply-expense {
  align-items: flex-start !important;
}
.apply-expense--dept {
  padding-top: 5px;
}
.float-left-err {
  float: left;
}
.custom-add-approver {
  position: absolute;
  left: 0;
  bottom: 32%;
  width: 87%;
}
.padding-right-115 {
  padding-right: 115px;
}

.btn-used-in-request {
  padding: 3px 7px;
  font-size: 11px;
  border-radius: 10px;
  margin-right: 5px;
}
</style>
