<template>
  <div class="wrapper-page">
    <nav class="navbar navbar-default navbar-lg" role="navigation" id="header-content">
      <div class="navbar-header">
        <h2 class="navbar-title">
          {{ $t('employee.employee') }}<span class="navbar-title-separator">｜</span>
          <span>{{ $t('employee.sign_up') }}</span>
        </h2>
      </div>
    </nav>
    <EmployeeForm @onChangeData="onChangeData" />
  </div>
</template>
<script>
import EmployeeForm from '@/components/employee/EmployeeForm';

export default {
  name: 'Employee-new',
  components: { EmployeeForm },
  data() {
    return {
      isChangeData: false,
    };
  },
  watch: {},
  computed: {},
  created() {},
  methods: {
    onChangeData(val) {
      this.isChangeData = val;
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.isChangeData) {
      const answer = window.confirm(this.$t('common.confirm_leave_page'));
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
};
</script>
<style lang="scss" scoped></style>
