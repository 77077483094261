<template>
  <div class="table-responsive">
    <table class="table table-hover table-hover--pointer table--property-list" id="table--plan">
      <thead>
        <tr>
          <th class="cell-control" style="width: 33px">
            <label :class="allSelected === true ? 'checkbox no-label checked' : 'checkbox no-label'">
              <span class="icons">
                <span class="first-icon icon-abui-checkbox-unchecked" />
                <span class="second-icon icon-abui-checkbox-checked" />
              </span>
              <input type="checkbox" @click="checkSelectAll" v-model="allSelected" />
            </label>
          </th>
          <th>{{ $t('security.family_name') }}</th>
          <th>
            <span class="inline-guide" id="inline-guide--2">
              <span class="label-text">{{ $t('security.place') }}</span>
              <el-tooltip
                class="label-icon-guide"
                :content="$t('security.tooltip_content')"
                raw-content
                popper-class="tool-tip-guide"
                placement="top"
              >
                <span class="label-icon-guide" />
              </el-tooltip>
            </span>
          </th>
          <th>{{ $t('security.plan_stay_period') }}</th>
          <th>{{ $t('security.journey') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in listData"
          :key="item.trip_id"
          @click="selectMember(item)"
          :class="
            selectedMember.trip_id == item.trip_id
              ? 'active'
              : !listTargetPersonSelected.includes(item.trip_id) && 'muted'
          "
        >
          <td class="cell-control" style="width: 33px" @click.stop>
            <label
              :class="
                listTargetPersonSelected.includes(item.trip_id) ? 'checkbox no-label checked' : 'checkbox no-label'
              "
            >
              <div>
                <span class="icons">
                  <span class="first-icon icon-abui-checkbox-unchecked" />
                  <span class="second-icon icon-abui-checkbox-checked" />
                </span>
                <input type="checkbox" @click="onChangeSelectMember(item)" v-on:click.stop :value="item.trip_id" />
              </div>
            </label>
          </td>
          <td>{{ item.member_name ? item.member_name : item.full_name ? item.full_name : '' }}</td>
          <td>
            <span v-html="getListPlaceInformation(item)" />
          </td>
          <td>
            <span v-html="getLengthOfStayInformation(item)" />
          </td>
          <td class="cell-control">
            <a class="btn btn-sm btn-flat-default" target="_blank" @click="navigateToTripDetail(item)">
              <i class="icon-abui-globe" />
            </a>
          </td>
        </tr>
      </tbody>
    </table>
    <p v-if="listData.length == 0" class="no-data">{{ $t('common.no_data') }}</p>
  </div>
</template>

<script>
import mixins from '@/helpers/mixins';
import moment from 'moment';

export default {
  name: 'DataTableTargetPerson',
  emits: ['onChangeMemberSelected', 'onChangeListTargetPersonSelected'],
  mixins: [mixins],

  data() {
    return {
      allSelected: false,
      listTargetPersonSelected: [],
      selectedMember: {},
      selectedEmergencyContacts: [],
    };
  },
  props: {
    listTargetPerson: {
      type: Array,
      default: () => [],
    },
    listCountryTarget: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    listData() {
      return [...this.listTargetPerson];
    },
  },
  methods: {
    checkSelectAll() {
      this.allSelected = !this.allSelected;
      this.listTargetPersonSelected = [];
      if (this.allSelected) {
        this.listTargetPerson.forEach(ps => {
          this.listTargetPersonSelected.push(ps.trip_id);
        });
      }
      this.$emit('onChangeListTargetPersonSelected', this.listTargetPersonSelected);
    },
    selectMember(item) {
      this.selectedMember = item;
      this.selectedEmergencyContacts = item.emergency_contacts ? item.emergency_contacts : [];
      this.$emit('onChangeMemberSelected', item);
    },
    onChangeSelectMember(item) {
      this.selectedMember = item;
      this.selectedEmergencyContacts = item.emergency_contacts ? item.emergency_contacts : [];
      this.$emit('onChangeMemberSelected', item);
      let isExit = this.listTargetPersonSelected.find(ps => ps == item.trip_id);
      if (!this.checkEmpty(isExit)) {
        this.listTargetPersonSelected = this.listTargetPersonSelected.filter(ps => ps != item.trip_id);
      } else {
        this.listTargetPersonSelected.push(item.trip_id);
      }
      this.allSelected = this.listTargetPerson.length == this.listTargetPersonSelected.length;
      this.$emit('onChangeListTargetPersonSelected', this.listTargetPersonSelected);
    },
    navigateToTripDetail(item) {
      let routeData = this.$router.resolve({
        name: this.PAGES.TRIP_UPDATE,
        params: {
          id: item.trip_id,
        },
      });
      window.open(routeData.href, '_blank');
    },
    getListPlaceInformation(item) {
      let location = '';
      if (!this.checkEmpty(item.gps_show_data.country) && !this.checkEmpty(item.matchedUserLocation)) {
        location += item.gps_show_data.country;
        if (!this.checkEmpty(item.gps_show_data.city)) {
          location +=
            '<i class="icon-abui-arrow-right phx" />' + item.gps_show_data.city + '<i class="icon-abui-gps plx" />';
        }
        location +=
          '&nbsp;&nbsp;&nbsp;&nbsp;取得時間：' +
          moment(item.matchedUserLocation.created_at).format('YYYY/MM/DD HH:mm') +
          '<br/>';
      }

      if (!this.checkEmpty(item.schedule_data)) {
        item.schedule_data.forEach(schedule => {
          if (!this.checkEmpty(schedule.departure_country)) {
            location += schedule.departure_country;
            if (!this.checkEmpty(schedule.departure_city)) {
              location += '<i class="icon-abui-arrow-right phx" />' + schedule.departure_city;
            }
          }
          if (!this.checkEmpty(schedule.arrival_country)) {
            if (!this.checkEmpty(schedule.departure_country) && !this.checkEmpty(schedule.departure_city)) {
              location += ' → ';
            }
            location += schedule.arrival_country;
            if (!this.checkEmpty(schedule.arrival_city)) {
              location += '<i class="icon-abui-arrow-right phx" />' + schedule.arrival_city;
            }
            location += '<br/>';
          } else {
            location += '<br/>';
          }
        });
      }

      return location;
    },
    getLengthOfStayInformation(item) {
      let lengthOfStay = '';
      if (!this.checkEmpty(item.gps_show_data) && !this.checkEmpty(item.matchedUserLocation)) {
        lengthOfStay +=
          moment(item.gps_show_data.start_date).format('YYYY/MM/DD') +
          ' - ' +
          moment(item.gps_show_data.end_date).format('YYYY/MM/DD') +
          '<br/>';
      }

      if (!this.checkEmpty(item.schedule_data)) {
        item.schedule_data.forEach(schedule => {
          lengthOfStay += schedule.start_date + ' - ' + schedule.end_date + '<br/>';
        });
      }

      return lengthOfStay;
    },
  },
};
</script>
