import BaseService from './BaseService';
import API_CODE from '../utils/api_code.js';

export default class CommonAPI extends BaseService {
  async getListStatusApproval(params, success, error) {
    await this.get(API_CODE.CU_114 + this.urlParse(params), success, error);
  }
  async getListStatusReport(params, success, error) {
    await this.get(API_CODE.CU_115 + this.urlParse(params), success, error);
  }
  async getListStatusExpense(params, success, error) {
    await this.get(API_CODE.CU_116 + this.urlParse(params), success, error);
  }
  async getPaymentMethodList(success, error) {
    await this.get(API_CODE.CU_057, success, error);
  }
  async getListOrganizations(params, success, error) {
    await this.get(API_CODE.CU_003 + this.urlParse(params), success, error);
  }
  async getListTimeZones(success, error) {
    await this.get(API_CODE.CU_023, success, error);
  }
  async searchTimeZones(params, success, error) {
    await this.get(API_CODE.CU_247 + this.urlParse(params), success, error);
  }
  async searchByAirport(params, success, error) {
    await this.get(API_CODE.CU_294 + this.urlParse(params), success, error);
  }
  async getListClipsByTrip(id, success, error) {
    await this.get(API_CODE.CU_025 + '?trip_id=' + id, success, error);
  }
  async getDetailClip(id, success, error) {
    await this.get(API_CODE.CU_053 + id, success, error);
  }
  async createClip(params, success, error) {
    await this.post(API_CODE.CU_054, params, success, error);
  }
  async updateClip(clip_id, params, success, error) {
    await this.post(API_CODE.CU_055 + clip_id, params, success, error);
  }
  async deleteClip(id, success, error) {
    await this.delete(API_CODE.CU_045 + id, {}, success, error);
  }
  async updateClipOrder(params, success, error) {
    await this.post(API_CODE.CU_231, params, success, error);
  }
  async getListCostsByTrip(id, success, error) {
    await this.get(API_CODE.CU_024 + '?trip_id=' + id, success, error);
  }
  async getApiEki(params, success, error) {
    await this.get(API_CODE.CU_096 + this.urlParse(params), success, error);
  }
  async getCurrencyList(params, success, error) {
    await this.get(API_CODE.CU_050 + this.urlParse(params), success, error);
  }
  async getTaxClassificationList(success, error) {
    await this.get(API_CODE.CU_058, success, error);
  }
  async getListPosition(params, success, error) {
    await this.get(API_CODE.CU_127 + this.urlParse(params), success, error);
  }
  async getListRole(success, error) {
    await this.get(API_CODE.OP_105, success, error);
  }
  async getListBank(success, error) {
    await this.get(API_CODE.CU_124, success, error);
  }
  async getListBranch(params, success, error) {
    await this.get(API_CODE.CU_125 + this.urlParse(params), success, error);
  }
  async getListCity(success, error) {
    await this.get(API_CODE.CU_122, success, error);
  }
  async getListProvinces(success, error) {
    await this.get(API_CODE.CU_285, success, error);
  }
  async getListVisa(success, error) {
    await this.get(API_CODE.CU_121, success, error);
  }
  async getListRelationship(success, error) {
    await this.get(API_CODE.CU_222, success, error);
  }
  async getListRailroadCompany(success, error) {
    await this.get(API_CODE.CU_119, success, error);
  }
  async getAddressByZipCode(zip_code, success, error) {
    await this.get(API_CODE.OP_155 + zip_code, success, error);
  }
  async getListCompanies(params, success, error) {
    await this.get(API_CODE.OP_135 + this.urlParse(params), success, error);
  }
  async getListTicketType(success, error) {
    await this.get(API_CODE.CU_126, success, error);
  }
  async getListTransportation(params, success, error) {
    await this.get(API_CODE.CU_291 + this.urlParse(params), success, error);
  }
  async getListAreaCountry(success, error) {
    await this.get(API_CODE.CU_303, success, error);
  }
  async validateCheckSsoId(params, success, error) {
    await this.get(API_CODE.CU_316 + this.urlParse(params), success, error);
  }
  async validateCheckEntityId(params, success, error) {
    await this.get(API_CODE.CU_315 + this.urlParse(params), success, error);
  }
}
