<template>
  <nav class="navbar navbar-default navbar-lg" role="navigation" id="header-content">
    <div class="navbar-header">
      <h2 class="navbar-title">{{ $t('security.security') }}</h2>
    </div>
    <div class="collapse navbar-collapse">
      <ul class="nav navbar-nav navbar-right">
        <li v-if="!isOperatorOrAdmin" class="hide--sbtm">
          <a href="#" @click.prevent="onHandleCreateSecurity()">
            {{ $t('security.business_trip_status_confirm') }}
          </a>
        </li>
      </ul>
    </div>
  </nav>
  <div class="filter-wrap">
    <Filter @submit="onHandleSearch" />
    <DataTableResult :paginate="paginate" :listSafetyInfo="listSafetyInfo" @changePageData="changePageData" />
  </div>
</template>

<script>
import Filter from '@/components/security/Filter';
import DataTableResult from '@/components/security/DataTableResult';
import PAGES from '@/utils/pages.js';

export default {
  name: 'Security',
  components: {
    DataTableResult,
    Filter,
  },
  data() {
    return {
      isShowFilter: false,
      isShowResult: true,
      listSafetyInfo: [],
      searchParams: {
        country_ids: this.$route.query.country_ids ? JSON.parse(this.$route.query.country_ids) : [],
        company: this.$route.query.company ? JSON.parse(this.$route.query.company) : [],
        start_from_date: this.$route.query.start_from_date || '',
        end_from_date: this.$route.query.end_from_date || '',
        start_until_date: this.$route.query.start_until_date || '',
        end_until_date: this.$route.query.end_until_date || '',
        is_including_confirmation_completion:
          this.$route.query.is_including_confirmation_completion == 'true' ? '1' : '0',
      },
      paginate: {
        page: +this.$route.query.page || 1,
        per_page: +this.$route.query.per_page || 10,
      },
    };
  },
  async created() {
    await this.getCurrentUser()
    if (!this.isShowSettingKikikanri) {
      this.$router.push(this.PAGES.UNAUTHORIZED);
      return;
    }
    this.getListSafetyInfo();
  },
  computed: {
    isShowSettingKikikanri() {
      return this.$store.state.auth?.user?.setting.is_show_kikikanri || false;
    }
  },
  methods: {
    async getCurrentUser() {
      await this.$services.account.current(
          res => {
            this.$store.dispatch('auth/initCurrentData', res);
          },
          () => {
            this.logout();
          },
      );
    },
    async getListSafetyInfo() {
      let query = '';
      if (this.searchParams.country_ids && this.searchParams.country_ids.length) {
        this.searchParams.country_ids.map(country_id => {
          query += '&country_ids[]=' + country_id;
        });
        query = query.substr(1);
      }

      let dataSearch = {
        ...this.searchParams,
        page: this.paginate.page,
        per_page: this.paginate.per_page,
      };

      delete dataSearch.country_ids;

      this.startLoading();
      if (this.isOperatorOrAdmin) {
        if (this.searchParams?.company?.length) {
          this.searchParams.company.map(country_id => {
            query += '&company[]=' + country_id;
          });

          query = query.substr(1);
        }
        delete dataSearch.company;
        await this.$services.security.getListSafetyInfoOP(
          dataSearch,
          query,
          response => {
            this.endLoading();
            this.listSafetyInfo = response.list;
            this.paginate = response.paginate;
            this.$router.push({
              path: this.$route.path,
              query: {
                page: response.paginate.current_page,
                per_page: response.paginate.per_page,
                end_from_date: this.searchParams.end_from_date,
                end_until_date: this.searchParams.end_until_date,
                is_including_confirmation_completion: this.searchParams.is_including_confirmation_completion,
                start_from_date: this.searchParams.start_from_date,
                start_until_date: this.searchParams.start_until_date,
                country_ids: JSON.stringify(this.searchParams.country_ids),
                company: JSON.stringify(this.searchParams.company),
              },
            });
          },
          err => {
            this.endLoading();
            this.notifyError(this.$t(err.error));
          },
        );
      } else {
        await this.$services.security.getListSafetyInfo(
          dataSearch,
          query,
          response => {
            this.endLoading();
            this.listSafetyInfo = response.list;
            this.paginate = response.paginate;
            this.$router.push({
              path: this.$route.path,
              query: {
                page: response.paginate.current_page,
                per_page: response.paginate.per_page,
                end_from_date: this.searchParams.end_from_date,
                end_until_date: this.searchParams.end_until_date,
                is_including_confirmation_completion: this.searchParams.is_including_confirmation_completion,
                start_from_date: this.searchParams.start_from_date,
                start_until_date: this.searchParams.start_until_date,
                country_ids: JSON.stringify(this.searchParams.country_ids),
              },
            });
          },
          err => {
            this.endLoading();
            this.notifyError(this.$t(err.error));
          },
        );
      }
    },
    changePageData(page) {
      this.startLoading();
      this.paginate.page = page;
      this.getListSafetyInfo();
    },
    onHandleCreateSecurity() {
      this.$router.replace(PAGES.CRISIS_MANAGEMENT_CREATE);
    },
    onHandleSearch(searchParams) {
      this.startLoading();
      let query = '';
      this.searchParams = {
        end_from_date: searchParams.end_from_date,
        end_until_date: searchParams.end_until_date,
        is_including_confirmation_completion: searchParams.is_including_confirmation_completion,
        start_from_date: searchParams.start_from_date,
        start_until_date: searchParams.start_until_date,
        country_ids: !this.checkEmpty(searchParams.country_ids) ? searchParams.country_ids : [],
        company: !this.checkEmpty(searchParams.company) ? searchParams.company : [],
      };

      if (searchParams.country_ids && searchParams.country_ids.length) {
        searchParams.country_ids.map(country_id => {
          query += '&country_ids[]=' + country_id;
        });
        query = query.substr(1);
      }

      let dataSearch = {
        ...this.searchParams,
        page: this.paginate.page,
        per_page: this.paginate.per_page,
      };

      delete dataSearch.country_ids;

      if (this.isOperatorOrAdmin) {
        if (searchParams?.company?.length) {
          searchParams.company.map(country_id => {
            query += '&company[]=' + country_id;
          });

          query = query.substr(1);
        }

        delete dataSearch.company;

        this.$services.security.getListSafetyInfoOP(
          dataSearch,
          query,
          response => {
            this.endLoading();
            this.listSafetyInfo = response.list;
            this.paginate = response.paginate;
            this.$router.push({
              path: this.$route.path,
              query: {
                page: response.paginate.current_page,
                per_page: response.paginate.per_page,
                end_from_date: this.searchParams.end_from_date,
                end_until_date: this.searchParams.end_until_date,
                is_including_confirmation_completion: this.searchParams.is_including_confirmation_completion,
                start_from_date: this.searchParams.start_from_date,
                start_until_date: this.searchParams.start_until_date,
                country_ids: JSON.stringify(this.searchParams.country_ids),
                company: JSON.stringify(this.searchParams.company),
              },
            });
          },
          err => {
            this.endLoading();
            this.notifyError(this.$t(err.error));
          },
        );
      } else {
        this.$services.security.getListSafetyInfo(
          dataSearch,
          query,
          response => {
            this.endLoading();
            this.listSafetyInfo = response.list;
            this.paginate = response.paginate;
            this.$router.push({
              path: this.$route.path,
              query: {
                page: response.paginate.current_page,
                per_page: response.paginate.per_page,
                end_from_date: this.searchParams.end_from_date,
                end_until_date: this.searchParams.end_until_date,
                is_including_confirmation_completion: this.searchParams.is_including_confirmation_completion,
                start_from_date: this.searchParams.start_from_date,
                start_until_date: this.searchParams.start_until_date,
                country_ids: JSON.stringify(this.searchParams.country_ids),
              },
            });
          },
          err => {
            this.endLoading();
            this.notifyError(this.$t(err.error));
          },
        );
      }
    },
  },
};
</script>
