<template>
  <div v-show="isShow" @click="onHandleClickFilter" class="btn-filter-again with-condition" style="display: block">
    <div class="filter--condition">
      <span>{{ $t('common.all') }}</span>
      <span style="color: #3650a5">{{ $t('common.reset_conditions') }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ButtonFilter',
  props: {
    isShow: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    onHandleClickFilter() {
      this.$emit('onHandleClickFilter');
    },
  },
};
</script>
