import BaseService from './BaseService';
import API_CODE from '../utils/api_code.js';

export default class CostAPI extends BaseService {
  async getListExpense(params, query, success, error) {
    await this.get(API_CODE.CU_092 + this.urlParse(params, query), success, error);
  }
  async getListCodeExpense(params, query, success, error) {
    await this.get(API_CODE.CU_322 + this.urlParse(params, query), success, error);
  }
  async getListExpenseOP(params, query, success, error) {
    await this.get(API_CODE.OP_265 + this.urlParse(params, query), success, error);
  }
  async getListExpenseItem(params, success, error) {
    await this.get(API_CODE.CU_120 + this.urlParse(params), success, error);
  }
  async downloadCSVExpenseItem(params, success, error) {
    await this.post(API_CODE.CU_123, params, success, error);
  }
  async getExpense(success, error) {
    await this.get(API_CODE.CU_128, success, error);
  }
  async getCostParentList(success, error) {
    await this.get(API_CODE.CU_143, success, error);
  }
  async expensesDownloadCSV(params, query, success, error) {
    await this.get(API_CODE.CU_091 + this.urlParse(params, query), success, error);
  }
  async createCostItem(params, success, error) {
    await this.post(API_CODE.CU_144, params, success, error);
  }
  async createExpenseCostItem(params, success, error) {
    await this.post(API_CODE.CU_043, params, success, error);
  }
  async updateExpenseCostItem(id, params, success, error) {
    await this.post(API_CODE.CU_089 + id, params, success, error);
  }
  async createTransportExpense(params, success, error) {
    await this.post(API_CODE.CU_102, params, success, error);
  }
  async updateCostItem(id, params, success, error) {
    await this.post(API_CODE.CU_145 + id, params, success, error);
  }
  async getCostItemDetail(id, success, error) {
    await this.get(API_CODE.CU_146 + id, success, error);
  }
  async deleteCostItemDetail(id, success, error) {
    await this.delete(API_CODE.CU_147 + id, {}, success, error);
  }
  async downloadCsvExample(success, error) {
    await this.get(API_CODE.CU_254, success, error);
  }
  async uploadCsv(params, success, error) {
    await this.post(API_CODE.CU_255, params, success, error);
  }
}
