import BaseService from './BaseService';
import API_CODE from '../utils/api_code.js';

export default class UserAPI extends BaseService {
  async getListUsers(params, success, error) {
    await this.get(API_CODE.OP_100 + this.urlParse(params), success, error);
  }
  async getUserDetail(id, success, error) {
    await this.get(API_CODE.OP_106 + id, success, error);
  }
  async deleteUser(id, success, error) {
    await this.delete(API_CODE.OP_282 + id, {}, success, error);
  }
  async createUser(params, success, error) {
    await this.post(API_CODE.OP_264, params, success, error);
  }
  async updateUser(id, params, success, error) {
    await this.post(API_CODE.OP_107 + id, params, success, error);
  }
  async updateRoleOperator(params, success, error) {
    await this.post(API_CODE.OP_310, params, success, error);
  }
}
