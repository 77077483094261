<template>
  <div class="login">
    <el-dialog
        v-model="showPasscode"
        :show-close="false"
        title=""
        width="680px"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
    >
      <div class="modal--2fa" id="modal--2fa">
        <div class="modal-dialog">
          <div class="modal-content">
            <button type="button" class="close" @click="showPasscode = false">
              <span class="icon-abui-cross" aria-hidden="true"></span>
            </button>
            <div class="modal-header">
              <div class="modal-header--title text-center">
                <i class="icon-abui-key"></i>
                {{ $t('verify.two_step_verification') }}
              </div>
            </div>
            <div class="modal-body">
              <p class="modal--diaglog--text text-center mbl">{{ $t('verify.6_digit_pass_code') }}</p>
              <div class="row">
                <div class="col-xs-12 col-sm-6 col-md-6 col-sm-offset-3 col-md-offset-3">
                  <div class="login-form-2fa">
                    <div class="form-group textfield-digit">
                      <input
                          type="text"
                          class="input-hg form-control digit-underline"
                          style="padding-left: 20px; width: 164.1px"
                          placeholder=""
                          value="––––––"
                          disabled=""
                      />
                      <input
                          v-model="verifyCode"
                          type="text"
                          class="form-control input-hg"
                          maxlength="6"
                          id="pass-code"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <div class="row">
                <div class="col-xs-12 col-sm-6 col-md-6 col-sm-offset-3 col-md-offset-3">
                  <p class="error-feedback" v-text="errors_message.verifyCode && $t(errors_message.verifyCode)"></p>

                  <button
                      class="btn btn-primary btn-block js--btn-login2"
                      :disabled="!verifyCode || verifyCode.length !== 6"
                      @click="submitVerifyCode"
                  >
                    {{ $t('verify.certification') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>

    <el-dialog
        v-model="forgotPassword"
        :show-close="false"
        title=""
        width="680px"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
    >
      <ForgotPassword @close-popup="forgotPassword = false"/>
    </el-dialog>

    <el-dialog
        v-model="showModalSsoCompany"
        :closable="false"
        :show-close="false"
        title=""
        width="640px"
        :close-on-press-escape="false"
        :close-on-click-modal="true"
    >
      <SsoCompany @close-popup="showModalSsoCompany = false" :messageValidate="errors_message.sso_login"/>
    </el-dialog>

    <div class="login-screen container">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-8 col-lg-6 col-md-offset-2 col-lg-offset-3">
          <div class="login-icon">
            <h4><span style="text-transform: uppercase">Travel Manager</span></h4>
          </div>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-8 col-lg-6 col-md-offset-2 col-lg-offset-3">
          <div class="btn-group btn-group-sm btn-switcher js-switcher mbl">
            <button
                type="button"
                class="btn btn-flat-default"
                :class="$i18n.locale == 'ja' && 'active'"
                id="switcher-a"
                @click="changeLocale('ja')"
            >
              日本語
            </button>
            <button
                type="button"
                class="btn btn-flat-default"
                :class="$i18n.locale == 'en' && 'active'"
                id="switcher-b"
                @click="changeLocale('en')"
            >
              ENGLISH
            </button>
          </div>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-8 col-lg-6 col-md-offset-2 col-lg-offset-3">
          <Form class="login-form" @submit="handleLogin" :validation-schema="validate">
            <div class="form-group">
              <Field
                  name="company_code"
                  type="text"
                  class="form-control login-field js--login-field--company-account"
                  :placeholder="$t('login.company_code')"
                  id="login-company"
                  maxlength="50"
                  v-model="dataRequest.company_code"
              />
              <ErrorMessage name="company_code" class="error-feedback" as="p"/>
            </div>
            <div class="form-group">
              <Field
                  name="employee_number"
                  type="text"
                  class="form-control login-field js--login-field--user-account"
                  :placeholder="$t('login.user_id')"
                  v-model="dataRequest.employee_number"
                  maxlength="50"
                  id="login-name"
              />
              <ErrorMessage name="employee_number" class="error-feedback" as="p"/>
            </div>
            <div class="form-group">
              <div class="input-with-icon">
                <Field
                    name="password"
                    type="password"
                    class="form-control login-field js--login-field--password"
                    :placeholder="$t('login.password')"
                    v-model="dataRequest.password"
                    maxlength="50"
                    id="login-pass"
                />
                <span class="input-icon icon-abui-lock"></span>

                <ErrorMessage name="password" class="error-feedback" as="p"/>
              </div>
            </div>

            <p class="error-feedback" v-text="errors_message.login && $t(errors_message.login)"></p>

            <button class="btn btn-primary btn-block js--btn-login" :disabled="disabledSubmit" style="margin-top: 10px">
              {{ $t('login.btn_login') }}
            </button>
            <div>
              <a class="login-link" @click="forgotPassword = true" href="#">
                {{ $t('login.forgot_password') }}
              </a>
            </div>
            <div class="box-or">
              <hr class="w45per">
              <p class="pts txt-dividing-line">{{ $t('common.or') }}</p>
              <hr class="w45per">
            </div>
            <div class="sso-login ptm pbm" @click="showModalSsoCompany = true">
              <a class="btn btn-inverse btn-block" data-toggle="modal" data-target=".modal--sso-cyberark"
                 href="#">{{ $t('customer.login_with_sso') }}</a>
            </div>
            <hr>
            <a class="login-link link-terms mtn" href="https://www.iace.co.jp/all/yakkan/smartbtm.html" target="_blank">
              {{ $t('login.term_conditions') }}
            </a>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ElDialog} from 'element-plus';
import {Form, Field, ErrorMessage} from 'vee-validate';
import * as yup from 'yup';
import Cookies from 'js-cookie';
import ForgotPassword from '@/components/ForgotPassword';
import SsoCompany from '@/components/SsoCompany';
import moment from 'moment';
import ElementPlus from 'element-plus';

export default {
  name: 'Login',
  components: {Form, Field, ErrorMessage, ForgotPassword, SsoCompany, ElDialog},
  mounted() {
    console.log('Element Plus version:', ElementPlus.version);
  },
  data() {
    return {
      showPasscode: false,
      forgotPassword: false,
      showModalSsoCompany: false,
      verifyCode: '',
      errors_message: {
        login: '',
        sso_login: '',
        forgotPassword: '',
        verifyCode: '',
      },
      message: '',
      dataRequest: {
        company_code: '',
        employee_number: '',
        password: '',
      },
      passwordForget: {
        company_code: '',
        employee_number: '',
      },
      dataResponse: {},
    };
  },
  computed: {
    disabledSubmit() {
      return (
          !this.dataRequest.employee_number.trim() ||
          !this.dataRequest.company_code.trim() ||
          !this.dataRequest.password.trim()
      );
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    validate() {
      return yup.object().shape({
        company_code: yup
            .string()
            .trim()
            .required(this.$t('E-CM-100', {item: this.$t('login.company_code')})),
        employee_number: yup
            .string()
            .trim()
            .required(this.$t('E-CM-100', {item: this.$t('login.user_id')})),
        password: yup
            .string()
            .trim()
            .required(this.$t('E-CM-100', {item: this.$t('login.password')})),
      });
    },
    validateUserId() {
      return yup
          .string()
          .trim()
          .required(this.$t('E-CM-100', {item: this.$t('login.user_id')}));
    },
    validateCompanyCode() {
      return yup
          .string()
          .trim()
          .required(this.$t('E-CM-100', {item: this.$t('login.company_code')}));
    },
  },
  watch: {
    loggedIn(val) {
      if (val) this.$router.push(this.PAGES.HOME);
    },
    verifyCode(val) {
      this.verifyCode = val.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
    },
    showModalSsoCompany(val) {
      if (!val) {
        this.errors_message = {
          ...this.errors_message,
          sso_login: ''
        };
      }
    },
  },
  created() {
    if (Cookies.get('sso_auth_failed') !== undefined && Cookies.get('sso_auth_failed') === 'true') {
      Cookies.set('sso_auth_failed', false, {
        expires: 365,
      });
      if (Cookies.get('error_message')) {
        this.errors_message = {
          ...this.errors_message,
          sso_login: this.$t('SSO-' + Cookies.get('error_message')),
        };
      }
      this.showModalSsoCompany = true;
    } else {
      this.errors_message = {
        ...this.errors_message,
        sso_login: ''
      };
    }
    if (this.loggedIn) this.$router.push(this.PAGES.HOME);
  },
  methods: {
    changeLocale(val) {
      this.$i18n.locale = val;
      Cookies.set('CurrentLanguage', val, {
        expires: 365,
      });
    },
    handleLogin(user) {
      this.startLoading();

      this.verifyCode = '';
      this.errors_message = {
        ...this.errors_message,
        login: '',
      };

      this.$services.account.login(
          user,
          res => {
            this.errors_message = '';
            this.dataResponse = res;
            this.endLoading();

            if (res.is_two_authenticate) {
              this.showPasscode = true;
            } else {
              if (res.is_first_login) {
                this.$router.push({
                  path: this.PAGES.UPDATE_PASSWORD,
                  query: {
                    is_first_login: 1,
                    user_id: user.employee_number,
                    token: res.token,
                    user_identifier: res.user_id
                  },
                });
              } else {
                this.$store.dispatch('auth/loginSuccess', user).then(() => {
                  this.saveAccessToken(res.token);
                  if (!this.checkEmpty(res.expires_at)) {
                    const expiredDateTime = moment().add(res.expires_at, 'seconds').format('YYYY/MM/DD-HH:mm:ss');
                    this.saveExpiredDateTime(expiredDateTime);
                  }
                  this.saveRole(res.role_code);
                  this.saveUserId(res.user_id);
                  if (!this.checkEmpty(Cookies.get('redirectPath')) && Cookies.get('redirectPath') !== '/login') {
                    window.location = Cookies.get('redirectPath');
                  } else {
                    window.location = '/';
                  }
                });
              }
            }
          },
          err => {
            this.errors_message = {
              ...this.errors_message,
              login: err.error,
            };
            this.endLoading();
          },
      );
    },
    submitVerifyCode() {
      this.startLoading();

      this.errors_message = {
        ...this.errors_message,
        verifyCode: '',
      };

      const data = {
        company_code: this.dataRequest.company_code,
        employee_number: this.dataRequest.employee_number,
        token: this.dataResponse.token_code,
        code: this.verifyCode,
      };

      this.$services.account.verify(
          data,
          res => {
            this.endLoading();

            if (this.dataResponse.is_first_login) {
              this.$router.push({
                path: this.PAGES.UPDATE_PASSWORD,
                query: {
                  is_first_login: 1,
                  user_id: data.employee_number,
                  token: res.token,
                },
              });
            } else {
              this.$store.dispatch('auth/loginSuccess', data).then(() => {
                this.saveAccessToken(res.token);
                if (!this.checkEmpty(res.expires_at)) {
                  const expiredDateTime = moment().add(res.expires_at, 'seconds').format('YYYY/MM/DD-HH:mm:ss');
                  this.saveExpiredDateTime(expiredDateTime);
                }
                this.saveRole(this.dataResponse.role_code);
                if (!this.checkEmpty(res.user_id) || !this.checkEmpty(this.dataResponse.user_id)) {
                  const userId = !this.checkEmpty(res.user_id) ? res.user_id : this.dataResponse.user_id;
                  this.saveUserId(userId);
                }

                if (['admin', 'operator'].includes(this.dataResponse.role_code)) {
                  window.location = this.PAGES.TRIP;
                } else {
                  window.location = '/';
                }
              });
            }
          },
          err => {
            this.errors_message = {
              ...this.errors_message,
              verifyCode: this.$t(err.error, {name: this.$t('verify.code')}),
            };
            this.endLoading();
          },
      );
    },
  },
};
</script>
<style scoped>
.w45per {
  width: 45%;
}

.box-or {
  display: flex;
  align-items: center;
}

.txt-dividing-line{
  color: #657a84;
  font-size: 13px;
  width: 10%;
}

.sso-cyberark, .btn-inverse.sso-cyberark:active {
  background: transparent url('~@/assets/images/sso-CyberArk.png') no-repeat 16px center / 20px auto;
}
</style>
