<template>
  <div class="absent--box">
    <el-dialog
      v-if="(userRole == 'employee_no_authority')"
      v-model="modalEdit"
      :show-close="false"
      width="640px"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <div class="modal--2fa">
        <div class="modal-dialog">
          <div v-loading="loadingData" class="modal-content">
            <div class="modal-header">
              <div class="modal-header--title">
                {{ mode == 'edit' ? $t('dashboard.absent.title_edit') : $t('dashboard.absent.title_create') }}
              </div>
            </div>
            <div class="modal-body">
              <div class="row">
                <div
                  class="col-md-6 col-all-closer col-all-closer--leftend col-sm-closer--rightend col-xs-closer--rightend"
                >
                  <div class="form-group">
                    <div class="input-group">
                      <div class="input-with-icon" style="line-height: 36px">
                        <el-date-picker
                          class="date-input"
                          v-model="dataRequest.from_date"
                          :clearable="false"
                          type="date"
                          format="YYYY/MM/DD"
                          value-format="YYYY/MM/DD"
                          :placeholder="$t('common.enter_or_select_required')"
                          :disabled-date="pickerFromOptions"
                        />
                      </div>
                      <p role="alert" class="error-feedback" v-text="errors.from_date"></p>
                      <p role="alert" class="error-feedback" v-text="errors.api_res && $t(errors.api_res[0])"></p>
                      <span class="input-group-addon prn" style="vertical-align: baseline">
                        {{ $t('common.from') }}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  class="col-md-6 col-all-closer col-all-closer--rightend col-sm-closer--leftend col-xs-closer--leftend"
                >
                  <div class="form-group">
                    <div class="input-group">
                      <div class="input-with-icon" style="line-height: 36px">
                        <el-date-picker
                          class="date-input"
                          v-model="dataRequest.to_date"
                          :clearable="false"
                          type="date"
                          format="YYYY/MM/DD"
                          value-format="YYYY/MM/DD"
                          :placeholder="$t('common.enter_or_select_required')"
                          :disabled-date="pickerToOptions"
                        />
                        <p role="alert" class="error-feedback" v-text="errors.to_date"></p>
                      </div>
                      <span class="input-group-addon prn" style="vertical-align: baseline">{{ $t('common.to') }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="!isAuthorOrAbsent && mode == 'edit'" class="row ptm pbl">
                <div class="col-all-closer col-all-closer--leftend" :class="hasCreateOrEdit ? 'col-xs-9' : 'col-xs-12'">
                  <div class="form-group viewonly input-sm">
                    <label>
                      <span class="label-text">{{ $t('dashboard.absent.text_note') }}</span>
                    </label>
                    <p class="viewonly-text text-ellipsis input-sm" id="input--approver">
                      <span v-if="isShowUserId">{{ dataRequest.authorizer_employee_number }}</span>
                      {{ dataRequest.name }}
                    </p>
                    <p role="alert" class="error-feedback" v-text="errors.authorizer_id"></p>
                  </div>
                </div>
                <div class="col-xs-3 col-all-closer col-all-closer--rightend" v-if="hasCreateOrEdit">
                  <button class="btn btn-inverse btn-sm btn-block btn--ls" @click="showBoxAuthorizedUser = true">
                    {{ $t('common.reference') }}..
                  </button>
                </div>
              </div>
              <button
                v-if="isAuthorOrAbsent && dataRequest.id"
                class="btn btn-sm btn-flat-default"
                @click="confirmDelete(dataRequest.id)"
              >
                <i class="icon-abui-trash prx"></i>
                {{ $t('common.delete') }}
              </button>
            </div>
            <div class="modal-footer">
              <div class="row">
                <div
                  class="col-xs-6 col-sm-6 col-md-6"
                  :class="!isAuthorOrAbsent && mode != 'create' && 'col-md-offset-6'"
                >
                  <button class="btn btn-sm btn-flat-default btn-block" @click="modalEdit = false">
                    {{ $t('common.cancel') }}
                  </button>
                </div>
                <div class="col-xs-6 col-sm-6 col-md-6" v-if="isAuthorOrAbsent || mode == 'create'">
                  <button class="btn btn-sm btn-primary btn-block open-alert" @click="submitData()">
                    {{ $t('common.submit') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>

    <el-dialog
      v-else
      v-model="modalEdit"
      :show-close="false"
      width="640px"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <div class="modal--2fa">
        <div class="modal-dialog">
          <div v-loading="loadingData" class="modal-content">
            <div class="modal-header">
              <div class="modal-header--title">
                {{ mode == 'edit' ? $t('dashboard.absent.title_edit') : $t('dashboard.absent.title_create') }}
              </div>
            </div>
            <div class="modal-body">
              <div class="row">
                <div
                  class="col-md-6 col-all-closer col-all-closer--leftend col-sm-closer--rightend col-xs-closer--rightend"
                >
                  <div class="form-group">
                    <div class="input-group">
                      <div class="input-with-icon" style="line-height: 36px">
                        <el-date-picker
                          class="date-input"
                          v-model="dataRequest.from_date"
                          :clearable="false"
                          type="date"
                          format="YYYY/MM/DD"
                          value-format="YYYY/MM/DD"
                          :placeholder="$t('common.enter_or_select_required')"
                          :disabled-date="pickerFromOptions"
                        />
                      </div>
                      <p role="alert" class="error-feedback" v-text="errors.from_date"></p>
                      <p role="alert" class="error-feedback" v-text="errors.api_res && $t(errors.api_res[0])"></p>
                      <span class="input-group-addon prn" style="vertical-align: baseline">
                        {{ $t('common.from') }}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  class="col-md-6 col-all-closer col-all-closer--rightend col-sm-closer--leftend col-xs-closer--leftend"
                >
                  <div class="form-group">
                    <div class="input-group">
                      <div class="input-with-icon" style="line-height: 36px">
                        <el-date-picker
                          class="date-input"
                          v-model="dataRequest.to_date"
                          :clearable="false"
                          type="date"
                          format="YYYY/MM/DD"
                          value-format="YYYY/MM/DD"
                          :placeholder="$t('common.enter_or_select_required')"
                          :disabled-date="pickerToOptions"
                        />
                        <p role="alert" class="error-feedback" v-text="errors.to_date"></p>
                      </div>
                      <span class="input-group-addon prn" style="vertical-align: baseline">{{ $t('common.to') }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="(userRole == 'employee_manager') || (userRole == 'employee_manager_reference')" class="row ptm pbl">
                <div
                  class="col-all-closer col-all-closer--leftend"
                  :class="isAuthorOrAbsent || mode == 'create' ? 'col-xs-9' : 'col-xs-12'"
                >
                  <div class="form-group viewonly input-sm">
                    <label>
                      <span class="label-text">{{ $t('dashboard.absent.text_note_absent') }}</span>
                    </label>
                    <p class="viewonly-text text-ellipsis input-sm" id="input--approver">
                      <span v-if="isShowUserId && !checkEmpty(dataRequest.absent_user_id)">
                        {{ dataRequest.absent_employee_number }}
                      </span>
                      {{ dataRequest?.absent_users_name }}
                    </p>
                    <p role="alert" class="error-feedback" v-text="errors.absent_user_id"></p>
                  </div>
                </div>
                <div
                  v-if="isAuthorOrAbsent || mode == 'create'"
                  class="col-xs-3 col-all-closer col-all-closer--rightend"
                >
                  <button
                    :disabled="!canSelectApprover"
                    class="btn btn-inverse btn-sm btn-block btn--ls btn-disable-absent"
                    @click="handleChooseAbsent()"
                  >
                    {{ $t('common.reference') }}..
                  </button>
                </div>
              </div>
              <div class="row ptm pbl">
                <div
                  class="col-all-closer col-all-closer--leftend"
                  :class="isAuthorOrAbsent || mode == 'create' ? 'col-xs-9' : 'col-xs-12'"
                >
                  <div class="form-group viewonly input-sm">
                    <label>
                      <span class="label-text">{{ $t('dashboard.absent.text_note') }}</span>
                    </label>
                    <p class="viewonly-text text-ellipsis input-sm" id="input--approver">
                      <span v-if="isShowUserId && dataRequest?.authorizer_id">
                        {{ dataRequest?.authorizer_employee_number }}
                      </span>
                      {{ dataRequest?.name }}
                    </p>
                    <p role="alert" class="error-feedback" v-text="errors.authorizer_id"></p>
                  </div>
                </div>
                <div
                  v-if="isAuthorOrAbsent || mode == 'create'"
                  class="col-xs-3 col-all-closer col-all-closer--rightend"
                >
                  <button
                    :disabled="!canSelectApprover"
                    class="btn btn-inverse btn-sm btn-block btn--ls btn-disable-absent"
                    @click="handleChooseAuthorizer()"
                  >
                    {{ $t('common.reference') }}..
                  </button>
                </div>
              </div>
              <button
                v-if="dataRequest.id && isAuthorOrAbsent"
                class="btn btn-sm btn-flat-default"
                @click="confirmDelete(dataRequest.id)"
              >
                <i class="icon-abui-trash prx"></i>
                {{ $t('common.delete') }}
              </button>
            </div>
            <div class="modal-footer">
              <div class="row">
                <div
                  class="col-xs-6 col-sm-6 col-md-6"
                  :class="!isAuthorOrAbsent && mode == 'edit' && 'col-md-offset-6'"
                >
                  <button class="btn btn-sm btn-flat-default btn-block" @click="modalEdit = false">
                    {{ $t('common.cancel') }}
                  </button>
                </div>
                <div v-if="isAuthorOrAbsent || mode == 'create'" class="col-xs-6 col-sm-6 col-md-6">
                  <button class="btn btn-sm btn-primary btn-block open-alert" @click="submitData()">
                    {{ $t('common.submit') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>

    <div style="display: flex; align-items: center; justify-content: space-between">
      <h6 class="absent--box--title">{{ $t('dashboard.absent.title') }}</h6>
      <button class="btn btn-sm btn-flat-default mrm" @click="showBoxCreateOrEdit(false)">
        <i class="icon-abui-plus"></i>
      </button>
    </div>

    <ul class="absent--list">
      <li v-for="(item, index) in listData" :key="index" @click="showBoxCreateOrEdit(item)">
        <div class="absent--list-info">
          <span class="duration">
            {{ showDateTime(item.from_date, 'YYYY/MM/DD（ddd）') }} -
            {{ showDateTime(item.to_date, 'YYYY/MM/DD（ddd）') }}
          </span>
          <span class="substitute">
            <i class="icon-abui-user prx"></i>
            {{ item.absent_users_name }}&nbsp;
            <small v-if="item.authorizers_name" class="icon-abui-arrow-alt-right prx"></small>
            <i v-if="item.authorizers_name" class="icon-abui-user prx"></i>
            {{ item.authorizers_name }}
          </span>
        </div>
      </li>
    </ul>

    <ModalConfirm
      :dialogVisible="showBoxConfirm"
      @cancel="showBoxConfirm = false"
      @yes="deleteAbsent"
      :title="$t('Q-CM-104')"
    />

    <el-dialog
      v-model="showBoxAuthorizedUser"
      title=""
      width="680px"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <AuthorizedUser
        :absentUserList="absentListByDate"
        @chooseUser="chooseAuthorizedUser"
        @cancelChoose="showBoxAuthorizedUser = false"
      />
    </el-dialog>
  </div>
</template>

<script>
import { ElDialog, ElDatePicker } from 'element-plus';
import ModalConfirm from '@/components/common/ModalConfirm';
import AuthorizedUser from '@/components/absent/AuthorizedUser';
import moment from 'moment';

export default {
  name: 'AbsentList',
  components: {
    ModalConfirm,
    AuthorizedUser,
    ElDialog,
    ElDatePicker,
  },
  data() {
    return {
      mode: 'create',
      listData: [],
      loadingData: false,
      modalEdit: false,
      showBoxConfirm: false,
      showBoxAuthorizedUser: false,
      idDelete: false,
      chooseAbsent: false,
      dataRequest: {
        from_date: '',
        to_date: '',
        authorizer_id: '',
        authorizer_employee_number: '',
        absent_user_id: '',
        absent_employee_number: '',
      },
      errors: {
        from_date: '',
        to_date: '',
        authorizer_id: '',
        absent_user_id: '',
        authorizer_employee_number: '',
        absent_employee_number: '',
        api_res: '',
      },
    };
  },
  watch: {
    modalEdit(val) {
      if (!val) {
        this.dataRequest = {
          from_date: '',
          to_date: '',
          authorizer_id: '',
          absent_user_id: '',
          authorizer_employee_number: '',
          absent_employee_number: '',
        };
        this.errors = {
          from_date: '',
          to_date: '',
          authorizer_id: '',
          absent_user_id: '',
          authorizer_employee_number: '',
          absent_employee_number: '',
          api_res: '',
        };
      }
    },
    'dataRequest.from_date': function () {
      this.validateAbsentDate();
    },
    'dataRequest.to_date': function () {
      this.validateAbsentDate();
    },
    'dataRequest.authorizer_id': function () {
      this.errors.authorizer_id = '';
    },
    'dataRequest.absent_user_id': function () {
      this.errors.absent_user_id = '';
    },
  },
  created() {
    this.startLoading();
    Promise.all([this.getListAbsent()]).then(() => {
      this.endLoading();
    });
  },
  computed: {
    isAuthorOrAbsent() {
      if (this.checkEmpty(this.listData) || this.checkEmpty(this.dataRequest)) return false;
      let absentDetail = this.listData.find(item => item.id == this.dataRequest.id);
      if (this.checkEmpty(absentDetail)) return false;
      return this.dataRequest.created_by === this.userId || absentDetail.absent_user_id === this.userId;
    },
    userNumber() {
      return this.$store.state.auth?.user?.employee_number || '';
    },
    username() {
      return this.$store.state.auth?.user?.name || '';
    },
    hasCreateOrEdit() {
      return !['employee_no_authority', 'employee_using_app'].includes(this.userRole);
    },
    canSelectApprover() {
      return !this.checkEmpty(this.dataRequest.from_date) && !this.checkEmpty(this.dataRequest.to_date);
    },
    absentListByDate() {
      if (
        this.checkEmpty(this.dataRequest.from_date) ||
        this.checkEmpty(this.dataRequest.to_date) ||
        this.checkEmpty(this.listData)
      )
        return [];
      let absentArr = [];

      this.listData.forEach(item => {
        if (
          item.from_date <= this.dataRequest.to_date &&
          item.to_date >= this.dataRequest.from_date &&
          item.absent_user_id != this.dataRequest.absent_user_id
          // !this.isAuthorOrAbsent
        )
          absentArr.push(item.absent_user_id);
      });
      return absentArr;
    },
  },
  methods: {
    handleChooseAbsent() {
      this.chooseAbsent = true;
      this.showBoxAuthorizedUser = true;
    },
    handleChooseAuthorizer() {
      this.chooseAbsent = false;
      this.showBoxAuthorizedUser = true;
    },
    pickerFromOptions(time) {
      return (
        moment(new Date(time)).format('YYYY-MM-DD') > moment(this.dataRequest?.to_date).format('YYYY-MM-DD') ||
        (this.mode == 'edit' &&
          moment(new Date(time)).format('YYYY-MM-DD') < moment(this.dataRequest?.created_at).format('YYYY-MM-DD')) ||
        (this.mode == 'create' && moment(new Date(time)).format('YYYY-MM-DD') < moment(new Date()).format('YYYY-MM-DD'))
      );
    },
    pickerToOptions(time) {
      if (!this.dataRequest.from_date)
        return moment(new Date(time)).format('YYYY-MM-DD') < moment(new Date()).format('YYYY-MM-DD');

      return (
        moment(new Date(time)).format('YYYY-MM-DD') < moment(this.dataRequest?.from_date).format('YYYY-MM-DD') ||
        (this.mode == 'edit' &&
          moment(new Date(time)).format('YYYY-MM-DD') < moment(this.dataRequest?.created_at).format('YYYY-MM-DD'))
      );
    },
    async getListAbsent() {
      await this.$services.dashboard.getListAbsent(
        res => {
          this.listData = res.list;
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    confirmDelete(id) {
      this.idDelete = id;
      this.showBoxConfirm = true;
    },
    deleteAbsent() {
      if (!this.idDelete) return;

      this.startLoading();
      this.$services.dashboard.deleteAbsent(
        this.idDelete,
        () => {
          this.endLoading();
          this.getListAbsent();
          this.modalEdit = false;
          this.showBoxConfirm = false;
          this.idDelete = false;
        },
        err => {
          this.endLoading();
          this.notifyError(this.$t(err.error));
        },
      );
    },
    async showBoxCreateOrEdit(data = false) {
      if (data) {
        this.mode = 'edit';
        await this.detailAbsent(data);
      } else {
        this.mode = 'create';
        this.dataRequest = {
          from_date: '',
          to_date: '',
          absent_employee_number: this.absentListByDate.includes(this.userId) ? '' : this.userNumber,
          absent_user_id: this.absentListByDate.includes(this.userId) ? '' : this.userId,
          absent_users_name: this.absentListByDate.includes(this.userId) ? '' : this.username,
        };
        this.validateAbsentDate();
      }
      this.modalEdit = true;
    },
    chooseAuthorizedUser(data) {
      if (!data) return;

      if (this.chooseAbsent) {
        this.dataRequest = {
          ...this.dataRequest,
          absent_employee_number: data.employee_number,
          absent_user_id: data.id,
          absent_users_name: data.name,
        };
        if (data.id == this.dataRequest.authorizer_id) {
          this.dataRequest.authorizer_id = '';
          this.dataRequest.name = '';
          this.dataRequest.authorizer_employee_number = '';
        }
        this.showBoxAuthorizedUser = false;
        return;
      }

      this.dataRequest = {
        ...this.dataRequest,
        authorizer_employee_number: data.employee_number,
        authorizer_id: data.id,
        name: data.name,
      };
      if (data.id == this.dataRequest.absent_user_id) {
        this.dataRequest.absent_employee_number = '';
        this.dataRequest.absent_user_id = '';
        this.dataRequest.absent_users_name = '';
      }
      this.showBoxAuthorizedUser = false;
    },
    validateAbsentDate() {
      if (!this.dataRequest.from_date) {
        this.errors.from_date = this.$t('E-CM-100', {
          item: this.$t('dashboard.absent.start_date'),
        });
      } else {
        this.errors.from_date = '';
      }
      if (!this.dataRequest.to_date) {
        this.errors.to_date = this.$t('E-CM-100', {
          item: this.$t('dashboard.absent.end_date'),
        });
      } else {
        this.errors.to_date = '';
      }
      // if (!this.checkEmpty(this.dataRequest.from_date) && !this.checkEmpty(this.dataRequest.to_date)) {
      //   if (this.absentListByDate.includes(this.dataRequest.authorizer_id)) {
      //     // this.dataRequest.authorizer_id = '';
      //     // this.dataRequest.name = '';
      //   }
      // }
    },
    submitData() {
      if (!this.dataRequest) return;

      let flgValidate = true;
      if (!this.dataRequest.from_date) {
        flgValidate = false;
        this.errors.from_date = this.$t('E-CM-100', {
          item: this.$t('dashboard.absent.start_date'),
        });
      } else {
        this.errors.from_date = '';
      }
      if (!this.dataRequest.to_date) {
        flgValidate = false;
        this.errors.to_date = this.$t('E-CM-100', {
          item: this.$t('dashboard.absent.end_date'),
        });
      } else {
        this.errors.to_date = '';
      }
      if (
        !this.dataRequest.authorizer_id && 
        !(this.userRole == 'employee_no_authority')
      ) {
        flgValidate = false;
        this.errors.authorizer_id = this.$t('E-CM-100', {
          item: this.$t('dashboard.absent.authorizer'),
        });
      } else {
        this.errors.authorizer_id = '';
      }
      if (
        !this.checkEmpty(this.dataRequest.authorizer_id) &&
        this.absentListByDate.includes(this.dataRequest.authorizer_id) &&
        !(this.userRole == 'employee_no_authority')
      ) {
        flgValidate = false;
        this.errors.authorizer_id = this.$t('E-CM-100', {
          item: this.$t('dashboard.absent.authorizer'),
        });
      } else {
        this.errors.authorizer_id = '';
      }

      if (
        !this.dataRequest.absent_user_id && 
        (this.userRole !== 'employee_no_authority')
      ) {
        flgValidate = false;
        this.errors.absent_user_id = this.$t('E-CM-100', {
          item: this.$t('dashboard.absent.authorizer'),
        });
      } else {
        this.errors.absent_user_id = '';
      }

      if (
        !this.checkEmpty(this.dataRequest.absent_user_id) &&
        this.absentListByDate.includes(this.dataRequest.absent_user_id)
      ) {
        flgValidate = false;
        this.errors.absent_user_id = this.$t('E-CM-100', {
          item: this.$t('dashboard.absent.authorizer'),
        });
      } else {
        this.errors.absent_user_id = '';
      }

      if (this.userRole === 'employee_no_authority') {
        delete this.dataRequest.authorizer_id;
      }
      if (this.userRole === 'employee_no_authority') {
        delete this.dataRequest.absent_user_id;
      }

      if (!flgValidate) return;

      this.startLoading();
      if (this.dataRequest.id) {
        this.updateData();
      } else {
        this.createData();
      }
    },
    detailAbsent(data) {
      this.loadingData = true;
      this.$services.dashboard.detailAbsent(
        data.id,
        res => {
          this.loadingData = false;
          this.dataRequest = {
            ...res,
            id: data.id,
            name: data.authorizers_name,
            absent_users_name: data.absent_users_name,
          };
        },
        err => {
          this.loadingData = false;
          this.notifyError(this.$t(err.error));
        },
      );
    },
    createData() {
      this.$services.dashboard.createAbsent(
        this.dataRequest,
        () => {
          this.endLoading();
          this.getListAbsent();
          this.modalEdit = false;
          this.showBoxConfirm = false;
          this.idDelete = false;
        },
        err => {
          this.endLoading();
          this.errors.api_res = err.error.to_date;
        },
      );
    },
    updateData() {
      this.$services.dashboard.updateAbsent(
        this.dataRequest,
        () => {
          this.endLoading();
          this.getListAbsent();
          this.modalEdit = false;
          this.showBoxConfirm = false;
          this.idDelete = false;
        },
        err => {
          this.endLoading();
          this.notifyError(this.$t(err.error), true);
          this.errors.api_res = err.error.to_date;
        },
      );
    },
  },
};
</script>
<style scoped>
.btn-disable-absent[disabled] {
  border: 1px solid #657a84 !important;
}
</style>
