<template>
  <nav class="navbar navbar-default navbar-lg" role="navigation" id="header-content" style="margin-bottom: 0px">
    <div class="navbar-header">
      <h2 class="navbar-title">{{ $t('notification.notification') }}</h2>
    </div>
  </nav>
  <div class="wrapper with-navbar-fixed-top with-navbar-fixed-bottom">
    <div class="container-fluid">
      <div
        v-if="notification.status == 2"
        class="plan-status status-done"
        style="margin-top: -48px; margin-bottom: 40px; background-color: #e6f5e5"
      >
        <span class="status-text">{{ $t('notification.delivered') }}<i class="icon-abui-check-inverted plx"></i></span>
      </div>
      <div class="section noborder ptn" id="section--main">
        <div class="">
          <div class="row">
            <div class="col-sm-4">
              <div class="form-group show--edit show--new hide--view">
                <label>
                  <span class="label-text">{{ $t('notification.delivery_date') }}</span>
                </label>
                <div class="input-with-icon">
                  <el-date-picker
                    :disabled="disable"
                    type="date"
                    class="date-input"
                    :class="!checkEmpty(errors.delivery_date) && 'has-validation-error-el-datepicker'"
                    format="YYYY/MM/DD"
                    value-format="YYYY-MM-DD"
                    v-model="notification.delivery_date"
                    :clearable="false"
                    :placeholder="$t('common.enter_or_select_required')"
                    :disabled-date="pickerEndOptions"
                  />
                </div>
                <p role="alert" class="error-feedback" v-text="errors.delivery_date" />
              </div>
            </div>
            <div class="col-sm-2">
              <div class="form-group show--edit show--new hide--view">
                <label>
                  <span class="label-text">{{ $t('notification.delivery_time') }}</span>
                </label>
                <div class="input-with-icon" :class="!checkEmpty(errors.delivery_time) && 'has-validation-error-el'">
                  <el-input
                    :disabled="disable"
                    v-model="notification.delivery_time"
                    placeholder="00:00"
                    @blur="checkStartTime('delivery_time')"
                  >
                    <template #suffix>
                      <el-icon class="el-input__icon"><clock /></el-icon>
                    </template>
                  </el-input>
                </div>
                <p role="alert" class="error-feedback" v-text="errors.delivery_time"></p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <div class="form-group">
                <label>
                  <span class="label-text">{{ $t('notification.delivery_destination') }}</span>
                </label>
                <SingleOptionSelect
                  :disabled="disable"
                  ref="TripOrganizationSelect"
                  :showClearable="false"
                  :class="!checkEmpty(errors.company_id) && 'has-validation-error-el'"
                  :list-data="companies"
                  :have-select-all-option="true"
                  @handle-selected-params="listenSelectCompany"
                  :defaultList="notification.company_id"
                />
                <p role="alert" class="error-feedback" v-text="errors.company_id" />
              </div>
            </div>
            <div v-if="!checkEmpty(employees)" class="col-sm-12 col-md-6 js-select_item-company__tgt company_1">
              <div class="form-group">
                <label>
                  <span class="label-text">{{ $t('notification.employee') }}</span>
                </label>
                <MultiOptionSelect
                  :disabled="disable"
                  ref="TripOrganizationSelect"
                  :list-data="employees"
                  :placeholder="$t('common.all')"
                  @handle-selected-params="listenSelectEmployees"
                  :defaultList="notification.employees"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6 col-sm-4">
              <div class="form-group">
                <label>
                  <span class="label-text">{{ $t('notification.type') }}</span>
                </label>
                <SingleOptionSelect
                  :disabled="disable"
                  ref="TripOrganizationSelect"
                  :list-data="notificationType"
                  :showClearable="false"
                  @handle-selected-params="listenSelectNotificationType"
                  :defaultList="notification.notification_type"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group show--edit show--new hide--view hide--edit-personal">
                <label>
                  <span class="label-text">{{ $t('notification.d-title') }}</span>
                </label>
                <input
                  :disabled="disable"
                  maxlength="100"
                  v-model.trim="notification.title"
                  :class="!checkEmpty(errors.title) && 'has-validation-error'"
                  type="text"
                  class="form-control input-sm"
                  :placeholder="$t('common.required')"
                />
                <p role="alert" class="error-feedback" v-text="errors.title" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group show--edit show--new hide--view hide--edit-personal">
                <label>
                  <span class="label-text">{{ $t('notification.contents') }}</span>
                </label>
                <textarea
                  :disabled="disable"
                  maxlength="200"
                  v-model.trim="notification.text_detail"
                  :class="!checkEmpty(errors.text_detail) && 'has-validation-error'"
                  class="form-control"
                  rows="6"
                  :placeholder="$t('common.required')"
                >
                </textarea>
                <p role="alert" class="error-feedback" v-text="errors.text_detail" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="notification.status == 1 || !notificationId"
      class="navbar navbar-default navbar-fixed-bottom navbar-footblock with-sidebar submit-new--wrap show--estimate"
      id=""
      role="navigation"
      style="z-index: 990; padding: 12px; transition: none 0s ease 0s; margin-bottom: 0px"
    >
      <div class="row">
        <div class="col-sm-3">
          <button
            v-if="notificationId && notification.status == 1"
            @click="showModalConfirm = true"
            class="btn btn-sm btn-flat-default"
          >
            <i class="icon-abui-trash prx"></i>{{ $t('notification.delete_notification') }}
          </button>
        </div>
        <div
          v-if="notification.status == 1 || !notificationId"
          class="col-sm-4 col-md-3 col-sm-offset-5 col-md-offset-6"
        >
          <a href="#" @click="submitData()" class="btn btn-sm btn-block btn-primary open-alert" id="">
            {{ $t('notification.keep') }}
          </a>
        </div>
      </div>
    </div>
  </div>
  <ModalConfirm
    :dialogVisible="showModalConfirm"
    @cancel="showModalConfirm = false"
    @yes="deleteNotification"
    :title="$t('Q-CM-104')"
  />
</template>
<script>
import SingleOptionSelect from '@/components/common/SingleOptionSelect.vue';
import MultiOptionSelect from '@/components/common/MultiOptionSelect.vue';
import ModalConfirm from '@/components/common/ModalConfirm';
import moment from 'moment';

export default {
  name: 'detail',
  components: { SingleOptionSelect, MultiOptionSelect, ModalConfirm },
  props: {},
  data() {
    return {
      showModalConfirm: false,
      companies: [],
      employees: [],
      notificationType: [],
      notification: {
        notification_type: 1,
        send_time: '',
        company_id: '',
        employees: [],
        delivery_date: '',
        delivery_time: moment().format('HH:mm'),
        title: '',
        text_detail: '',
      },
      notificationOfficial: {
        notification_type: 1,
        send_time: '',
        company_id: '',
        employees: [],
        delivery_date: '',
        delivery_time: moment().format('HH:mm'),
        title: '',
        text_detail: '',
      },
      errors: {
        delivery_date: '',
        title: '',
        text_detail: '',
        company_id: '',
        delivery_time: '',
      },
      initCompanyId: null,
      initEmployees: [],
      isSubmit: false,
    };
  },
  beforeRouteLeave(to, from, next) {
    if (!this.compareObject(this.notification, this.notificationOfficial) && !this.isSubmit) {
      const answer = window.confirm(this.$t('common.confirm_leave_page'));
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  watch: {
    'notification.company_id': function (newVal) {
      if (newVal) {
        this.getByCompanies(newVal);
      }
    },
  },
  computed: {
    notificationId() {
      return this.$route.params.id;
    },
    companyIdList() {
      let list = [];
      this.companies.forEach(item => list.push(item.company_id));
      return list;
    },
    disable() {
      return this.notification.status == 2 ? true : false;
    },
  },
  created() {
    this.startLoading();
    Promise.all([
      this.getListCompany(),
      this.getByCompanies(),
      this.getNotificationType(),
      this.getDetailNotification(),
    ]).then(() => {
      this.endLoading();
    });
  },
  methods: {
    checkStartTime(key) {
      let val = this.notification[key];
      if (val) {
        val = this.autoFormatTime(val);
        if (this.isValidTime(val)) {
          this.errors[key] = '';
        } else {
          this.errors[key] = this.$t('input.time_error');
        }

        this.notification[key] = val;
      }
    },
    async submitData() {
      let flgValidate = true;
      if (!this.notification.delivery_date) {
        flgValidate = false;
        this.errors.delivery_date = this.$t('E-CM-100', {
          item: this.$t('notification.delivery_date'),
        });
      } else {
        this.errors.delivery_date = '';
      }

      if (this.notification.title == '') {
        flgValidate = false;
        this.errors.title = this.$t('E-CM-100', {
          item: this.$t('notification.d-title'),
        });
      } else {
        this.errors.title = '';
      }

      if (this.isValidTime(this.notification.delivery_time)) {
        this.errors.delivery_time = '';
      } else {
        this.errors.delivery_time = this.$t('input.time_error');
        flgValidate = false;
      }

      if (this.notification.text_detail == '') {
        flgValidate = false;
        this.errors.text_detail = this.$t('E-CM-100', {
          item: this.$t('notification.contents'),
        });
      } else {
        this.errors.text_detail = '';
      }

      if (!flgValidate) return;

      if (this.checkEmpty(this.notification.employees)) {
        this.notification.employees = this.employees.map(item => {
          return item.id;
        });
      }
      let date = this.notification.delivery_date;
      let time = this.notification.delivery_time;
      this.notification.send_time = date + ' ' + time;
      if (this.notificationId) {
        await this.updateNotification();
      } else {
        await this.createNotification();
      }
    },
    async deleteNotification() {
      this.isSubmit = true;
      this.startLoading();
      await this.$services.notification.deleteNotification(
        this.notificationId,
        () => {
          // this.endLoading();
          this.showModalConfirm = false;
          this.notifySuccess(this.$t('I-CM-102'));
          this.$router.push(this.PAGES.NOTIFICATION);
        },
        err => {
          this.endLoading();
          this.showModalConfirm = false;
          this.notifyError(this.$t(err.error));
        },
      );
    },
    async createNotification() {
      this.isSubmit = true;
      this.startLoading();
      await this.$services.notification.createNotification(
        this.notification,
        () => {
          // this.endLoading();
          this.notifySuccess(this.$t('I-CM-103'));
          this.$router.push(this.PAGES.NOTIFICATION);
        },
        err => {
          this.endLoading();
          this.notifyError(this.$t(err.error));
        },
      );
    },
    async updateNotification() {
      this.isSubmit = true;
      this.startLoading();
      await this.$services.notification.updateNotification(
        this.notification,
        () => {
          // this.endLoading();
          this.$router.push(this.PAGES.NOTIFICATION);
        },
        err => {
          this.endLoading();
          this.notifyError(this.$t(err.error), true);
        },
      );
    },
    async getListCompany() {
      await this.$services.notification.getCompanies(
        { all_data: 1 },
        res => {
          this.companies = res.list;
          this.companies = this.companies.map(item => {
            item.name = item.code + ':' + item.name;
            return item;
          });
          if (!this.notificationId) {
            this.notification.company_id = this.companies[0].id;
            this.notificationOfficial.company_id = this.companies[0].id;
          }
        },
        err => {
          this.notifyError(this.$t(err.err));
        },
      );
    },
    async getByCompanies(id) {
      await this.$services.notification.getByCompanies(
        { company_id: id },
        res => {
          this.employees = res.list;
          this.employees = this.employees.map(item => {
            item.name = item.employee_number + ':' + item.name;
            return item;
          });
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    async getNotificationType() {
      await this.$services.notification.getNotificationType(
        {},
        res => {
          this.notificationType = res.list;
          this.notificationType.forEach(item => {
            if (item.notification_type == 1) {
              item.name = this.$t('notification.from_the_operator');
            } else {
              item.name = this.$t('notification.safety_information');
            }
          });
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    async getDetailNotification() {
      if (!this.notificationId) return;
      await this.$services.notification.getDetailNotification(
        this.notificationId,
        res => {
          this.notification = res;
          this.initCompanyId = res.company_id;
          this.initEmployees = res.employees;
          if (this.notification.send_time) {
            let send_time = this.notification.send_time.split(' ');
            this.notification.delivery_date = send_time[0];
            this.notification.delivery_time = send_time[1];
          }
          if (this.notification.employees) {
            this.notification.employees = this.notification.employees.map(item => {
              return item.id;
            });
          }
          this.notificationOfficial = this.cloneObject(res);
          if (this.notificationOfficial.send_time) {
            let send_time = this.notificationOfficial.send_time.split(' ');
            this.notificationOfficial.delivery_date = send_time[0];
            this.notificationOfficial.delivery_time = send_time[1];
          }
        },
        err => {
          this.notifyError(this.$t(err.error));
          this.$router.push(this.PAGES.NOTIFICATION);
        },
      );
    },
    listenSelectCompany(select) {
      this.notification.company_id = select;
      if (select == this.initCompanyId) {
        this.notification.employees = this.initEmployees.map(item => {
          return item.id;
        });
      } else {
        this.notification.employees = [];
      }
    },
    listenSelectEmployees(select) {
      this.notification.employees = select;
    },
    listenSelectNotificationType(select) {
      this.notification.notification_type = select;
    },
    pickerEndOptions(time) {
      return moment(new Date()).format('YYYY-MM-DD') > moment(time).format('YYYY-MM-DD');
    },
    disabledHours(time) {
      if (moment(new Date()).format('YYYY-MM-DD') >= moment(this.notification.delivery_date).format('YYYY-MM-DD')) {
        return this.makeRange(0, moment(time).format('HH') - 1);
      }
    },
    disabledMinutes() {
      let deliveryHour = !this.checkEmpty(this.notification.delivery_time)
        ? this.notification.delivery_time.split(':')[0]
        : moment(new Date()).format('HH');
      if (
        moment(new Date()).format('YYYY-MM-DD') >= moment(this.notification.delivery_date).format('YYYY-MM-DD') &&
        moment(new Date()).format('HH') >= deliveryHour
      ) {
        let date = new Date();
        let minutes = date.getMinutes();
        return this.makeRange(0, minutes);
      }
    },
    makeRange(start, end) {
      const result = [];
      for (let i = start; i <= end; i++) {
        result.push(i);
      }
      return result;
    },
  },
};
</script>
<style lang="scss" scoped>
.status-text {
  color: #65c15e;
}
</style>
