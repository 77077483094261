<template>
  <div class="filter-result js-filter-result--minimization">
    <div class="section noborder">
      <Pagination
        v-if="listNotifications.length"
        :paginate="paginate"
        :current-number="+paginate.currentPage"
        @change-page="changePageData"
        :hasOptionPaginate="false"
      />

      <div class="hidden-xs">
        <div class="table-responsive">
          <table
            class="table table-hover table-hover--pointer"
            id=""
            style="table-layout: fixed; word-break: break-all"
          >
            <colgroup span="1" style="width: 12.5%"></colgroup>
            <colgroup span="1" style="width: 12.5%"></colgroup>
            <colgroup span="1" style="width: 8.33%"></colgroup>
            <colgroup span="1"></colgroup>
            <colgroup span="1" style="width: 8.33%"></colgroup>
            <colgroup span="1" style="width: 12.5%"></colgroup>
            <thead>
              <tr>
                <th class="">{{ $t('notification.delivery_date_and_time') }}</th>
                <th class="">{{ $t('notification.delivery_destination') }}</th>
                <th>{{ $t('notification.kinds') }}</th>
                <th></th>
                <th>{{ $t('notification.status') }}</th>
                <th>{{ $t('notification.registration_date') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                :class="item.status == 1 ? '' : 'muted done'"
                v-for="(item, index) in listNotifications"
                :key="index"
                @click="onRedirectNotificationItemPage(item)"
              >
                <td class="">{{ item.send_time }}</td>
                <td class="ellipsis">{{ item.company?.name }}</td>
                <td>
                  {{
                    item.notification_type == 1
                      ? $t('notification.from_the_operator')
                      : $t('notification.safety_information')
                  }}
                </td>
                <td class="ellipsis">
                  {{ item.title }}
                  <span v-if="item.text_detail">/</span>
                  {{ item.text_detail }}
                </td>
                <td>{{ item.status == 1 ? $t('notification.waiting_for_delivery') : $t('notification.delivered') }}</td>
                <td>{{ item.created_at }}</td>
              </tr>
            </tbody>
          </table>
          <p v-if="!listNotifications.length" class="no-data">{{ $t('security.no_data') }}</p>
        </div>
      </div>

      <Pagination
        v-if="listNotifications.length"
        :paginate="paginate"
        :current-number="+paginate.currentPage"
        @change-page="changePageData"
        :hasOptionPaginate="false"
      />
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/common/Pagination';

export default {
  name: 'DataTableResult',
  components: {
    Pagination,
  },
  props: {
    listNotifications: {
      type: Array,
      default: () => [],
    },
    paginate: {
      type: Object,
      default: () => {
        return {
          page: 1,
          perPage: 1,
          from: 1,
          to: 1,
          lastPage: 1,
          total: 1,
        };
      },
    },
  },
  methods: {
    changePageData(page) {
      this.$emit('changePageData', page);
    },

    onRedirectNotificationItemPage(item) {
      this.$router.push({
        name: this.PAGES.NOTIFICATION_DETAIL,
        params: {
          id: item.id,
        },
      });
    },
  },
};
</script>
