<template>
  <el-dialog
    v-model="dialogVisibleStatus"
    :show-close="false"
    @close="closeModal()"
    width="736px"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <div v-loading="loading" class="modal-dialog">
      <div class="modal-content">
        <button type="button" class="close" @click="closeModal()">
          <span class="icon-abui-cross" aria-hidden="true"></span>
        </button>
        <div class="modal-header" style="display: flex; justify-content: space-between">
          <div class="modal-header--title" style="width: 100%">{{ $t('transportation.title') }}</div>
        </div>
        <div class="modal-body">
          <div style="padding-bottom: 32px">
            <Form
              v-show="showFormFilter"
              @submit.prevent="submitForm"
              :validation-schema="validate"
              ref="form"
              class="filter-wrap"
            >
              <div class="filter js-filter" style="height: auto">
                <div class="section section-filter noborder js-section-filter" style="visibility: visible">
                  <div class="row">
                    <div class="col-sm-4 input-dep col-all-closer col-all-closer--leftend">
                      <div class="form-group required form-group-typeahead">
                        <label>
                          <span class="label-text">{{ $t('transportation.departure_station') }}</span>
                        </label>
                        <div class="input-with-icon input-sm">
                          <Field name="from" type="hidden" v-model="dataRequest.from" />
                          <el-autocomplete
                            name="from"
                            class="input-sm input-typeahead typeahead--jr-stn"
                            v-model="dataRequest.from"
                            :fetch-suggestions="querySearch"
                            :placeholder="$t('transportation.plc_input')"
                            @select="handleSelectFrom"
                            :trigger-on-focus="false"
                            maxlength="100"
                          />
                          <span class="input-icon-typeahead icon-abui-pencil" />
                        </div>
                        <ErrorMessage name="from" class="error-feedback" as="p" />
                      </div>
                    </div>
                    <div class="col-sm-4 col-all-closer">
                      <div class="form-group required input-mid form-group-typeahead">
                        <label>
                          <span class="label-text">{{ $t('transportation.arrival_station') }}</span>
                        </label>
                        <div class="input-with-icon input-sm">
                          <Field name="to" type="hidden" v-model="dataRequest.to" />
                          <el-autocomplete
                            name="from"
                            class="input-sm input-typeahead typeahead--jr-stn"
                            v-model="dataRequest.to"
                            :fetch-suggestions="querySearch"
                            :trigger-on-focus="false"
                            :placeholder="$t('transportation.plc_input')"
                            @select="handleSelectTo"
                            maxlength="100"
                          />
                          <span class="input-icon-typeahead icon-abui-pencil" />
                        </div>
                        <ErrorMessage name="to" class="error-feedback" as="p" />
                      </div>
                    </div>
                    <div class="col-sm-4 col-all-closer col-all-closer--rightend">
                      <div class="form-group form-group-typeahead">
                        <label>
                          <span class="label-text">{{ $t('transportation.stop_station') }}</span>
                        </label>
                        <div class="input-with-icon input-sm">
                          <Field name="via" type="hidden" v-model="dataRequest.via" />
                          <el-autocomplete
                            name="from"
                            class="input-sm input-typeahead typeahead--jr-stn"
                            v-model="dataRequest.via"
                            :fetch-suggestions="querySearch"
                            :trigger-on-focus="false"
                            :placeholder="$t('transportation.plc_input')"
                            @select="handleSelectVia"
                            maxlength="100"
                          />
                          <span class="input-icon-typeahead icon-abui-pencil" />
                        </div>
                        <ErrorMessage name="via" class="error-feedback" as="p" />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="form-group">
                        <label
                          class="radio radio-inline radio-sm radio-m-d"
                          :class="dataRequest.transportation_cost_type == 1 && 'checked'"
                          @click="dataRequest.transportation_cost_type = 1"
                        >
                          <span class="icons">
                            <span class="first-icon icon-abui-radio-unchecked" />
                            <span class="second-icon icon-abui-radio-checked" />
                          </span>
                          <input type="radio" name="03-02" value="1" checked="" />
                          <span class="text_type-special">{{ $t('transportation.one_way_ticket') }}</span>
                        </label>
                        <label
                          class="radio radio-inline radio-sm radio-m-d"
                          :class="dataRequest.transportation_cost_type == 0 && 'checked'"
                          @click="dataRequest.transportation_cost_type = 0"
                        >
                          <span class="icons">
                            <span class="first-icon icon-abui-radio-unchecked" />
                            <span class="second-icon icon-abui-radio-checked" />
                          </span>
                          <input type="radio" name="03-02" value="0" />
                          <span class="text_type-special">{{ $t('transportation.round_ticket') }}</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group required">
                        <label>
                          <span class="label-text">{{ $t('transportation.date') }}</span>
                        </label>
                        <div class="input-with-icon">
                          <el-date-picker
                            class="date-input"
                            v-model="dataRequest.date"
                            :clearable="false"
                            type="date"
                            format="YYYY/MM/DD"
                            value-format="YYYYMMDD"
                            :placeholder="$t('common.enter_or_select')"
                          />
                        </div>
                        <p role="alert" class="error-feedback" v-text="errors.date"></p>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label><span class="label-text" />&nbsp;</label>
                        <label
                          class="radio radio-inline radio-sm radio-m-d"
                          :class="dataRequest.ic_card == 1 && 'checked'"
                          @click="dataRequest.ic_card = 1"
                        >
                          <span class="icons">
                            <span class="first-icon icon-abui-radio-unchecked" />
                            <span class="second-icon icon-abui-radio-checked" />
                          </span>
                          <input type="radio" name="03-02" value="1" checked="" />
                          <span class="text_type-special">{{ $t('transportation.ic_card.IC') }}</span>
                        </label>
                        <label
                          class="radio radio-inline radio-sm radio-m-d"
                          :class="dataRequest.ic_card == 0 && 'checked'"
                          @click="dataRequest.ic_card = 0"
                        >
                          <span class="icons">
                            <span class="first-icon icon-abui-radio-unchecked" />
                            <span class="second-icon icon-abui-radio-checked" />
                          </span>
                          <input type="radio" name="03-02" value="0" />
                          <span class="text_type-special">{{ $t('transportation.ic_card.unreserved_seat') }}</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="form-group">
                        <label>
                          <span class="label-text">{{ $t('transportation.surchargeKind') }}</span>
                        </label>
                        <label
                          class="radio radio-inline radio-sm radio-m-d"
                          :class="dataRequest.surchargeKind == 'free' && 'checked'"
                          @click="dataRequest.surchargeKind = 'free'"
                        >
                          <span class="icons">
                            <span class="first-icon icon-abui-radio-unchecked" />
                            <span class="second-icon icon-abui-radio-checked" />
                          </span>
                          <input type="radio" name="03-03" value="free" checked="" />
                          <span class="text_type-special">{{ $t('transportation.free') }}</span>
                        </label>
                        <label
                          class="radio radio-inline radio-sm radio-m-d"
                          :class="dataRequest.surchargeKind == 'reserved' && 'checked'"
                          @click="dataRequest.surchargeKind = 'reserved'"
                        >
                          <span class="icons"
                            ><span class="first-icon icon-abui-radio-unchecked" />
                            <span class="second-icon icon-abui-radio-checked" />
                          </span>
                          <input type="radio" name="03-03" value="reserved" />
                          <span class="text_type-special">{{ $t('transportation.reserved') }}</span>
                        </label>
                        <label
                          class="radio radio-inline radio-sm radio-m-d"
                          :class="dataRequest.surchargeKind == 'green' && 'checked'"
                          @click="dataRequest.surchargeKind = 'green'"
                        >
                          <span class="icons">
                            <span class="first-icon icon-abui-radio-unchecked" />
                            <span class="second-icon icon-abui-radio-checked" />
                          </span>
                          <input type="radio" name="03-03" value="green" />
                          <span class="text_type-special">{{ $t('transportation.green') }}</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>

            <div v-show="showFormFilter" class="section-submit js-section-submit">
              <span class="submit-arrow" />
              <div class="row">
                <div class="col-sm-4 col-md-4 hidden-xs">
                  <button class="btn btn-flat-default btn-clear" @click="resetData()">{{ $t('common.clear') }}</button>
                </div>
                <div class="col-sm-4 col-md-4">
                  <button class="btn btn-primary btn-block btn-filter js-btn-filter" @click="submitForm()">
                    {{ $t('common.search') }}
                  </button>
                </div>
                <div class="col-xs-12 visible-xs mts">
                  <button class="btn btn-flat-default btn-block btn-clear">{{ $t('common.clear') }}</button>
                </div>
              </div>
            </div>

            <div v-show="!showFormFilter" @click="showFormFilter = true" class="btn-show-again-search">
              <span>{{ $t('common.reset_conditions') }}</span>
            </div>

            <div v-show="hasAnswer && !createTransportExpense" class="filter-result js-filter-result">
              <div class="section noborder">
                <Pagination
                  v-if="listData.length > 0"
                  :paginate="paginate"
                  :hasOptionPaginate="false"
                  @change-page="changePageData"
                />

                <table
                  v-if="listData.length > 0"
                  class="table table-hover table-hover--pointer table-compound-condition--demo list--expense-route"
                >
                  <colgroup span="1" style=""></colgroup>
                  <colgroup span="1" style=""></colgroup>
                  <colgroup span="1" style="width: 44px"></colgroup>
                  <tbody>
                    <tr
                      v-for="(item, index) in listDataWithPaginate[paginate.page - 1]"
                      :key="index"
                      @click="showBoxCreateTransportExpense(item)"
                    >
                      <td class="price" style="width: 200px">{{ splitThousandYen(item.total_amount) }}</td>
                      <td>
                        <div class="expense--route">
                          <template v-for="(route, i) in item.route" :key="i">
                            <span class="expense--route--station">
                              {{ route.point_name }}
                            </span>
                            <span v-if="i < item?.route?.length - 1" class="expense--route--train">
                              <i class="icon-abui-arrow-alt-down prs"></i>
                              <i v-if="searchOneWay == false" class="icon-abui-arrow-alt-up prs"></i>
                              <template v-if="route.line_name">
                                {{ route.line_name }}
                              </template>
                            </span>
                          </template>
                        </div>
                      </td>
                      <td class="cell-control">
                        <a class="btn btn-flat-default btn-sm btn--feedback" href="#">
                          <span class="icon-abui-check" />
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p v-if="!loading && listData.length == 0" class="no-data">{{ $t('common.no_data') }}</p>
              </div>
            </div>

            <div v-if="createTransportExpense" class="expense--route--set-value" style="margin-top: 32px">
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group viewonly">
                    <label>
                      <span class="label-text">{{ $t('information.date') }}</span>
                    </label>
                    <p class="viewonly-text">{{ showDateTime(transportExpense.date, 'YYYY/MM/DD') }}</p>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group viewonly">
                    <label>
                      <span class="label-text">{{ $t('expense.amount') }}</span>
                    </label>
                    <p class="viewonly-text text-right">{{ splitThousandYen(transportExpense.amount) }}</p>
                  </div>
                </div>
              </div>

              <div style="padding-top: 16px">
                <div class="row">
                  <div class="col-xs-6 col-sm-6 col-md-6 col-xs-offset-6 col-sm-offset-6 col-md-offset-6">
                    <button
                      class="btn btn-sm btn-inverse-primary btn-block js-btn--close-modal-schedule-item"
                      @click="submitCreateTransportExpense()"
                    >
                      {{ $t('expense_cost.save') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<style></style>
<script>
import Pagination from '@/components/common/Pagination';
import { ElDialog } from 'element-plus';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';

const PER_PAGE = 5;

export default {
  components: {
    ElDialog,
    Form,
    Field,
    ErrorMessage,
    Pagination,
  },
  name: 'ModalTransportation',
  emits: ['success', 'cancel'],
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    flgCreate: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    dialogVisible() {
      this.dialogVisibleStatus = this.dialogVisible;
    },
    'dataRequest.date': function () {
      this.errors.date = '';
    },
  },
  data() {
    return {
      loading: false,
      hasAnswer: false,
      showFormFilter: true,
      dialogVisibleStatus: false,
      createTransportExpense: false,
      searchOneWay: true,
      transportExpense: {},
      listData: [],
      errors: {},
      dataRequest: {
        from: '',
        via: '',
        to: '',
        date: '',
        ic_card: 1,
        //1: One way ticket, 2: Round ticket
        transportation_cost_type: 1,
        surchargeKind: 'free',
      },
      paginate: {
        page: 1,
        per_page: PER_PAGE,
      },
    };
  },
  computed: {
    userMonthlyTickets() {
      return this.$store.state.auth?.user?.monthly_tickets || [];
    },
    listDataWithPaginate() {
      if (this.listData.length <= PER_PAGE) return [this.listData];
      return this.arrayChunk(this.listData, PER_PAGE);
    },
    paginates() {
      let to = 0;
      if (this.paginate.page == this.listDataWithPaginate.length) {
        to = this.listData.length;
      } else {
        to = this.paginate.page * PER_PAGE;
      }

      return {
        current_page: this.paginate.page,
        from: (this.paginate.page - 1) * PER_PAGE + 1,
        to,
        last_page: this.listDataWithPaginate.length,
        per_page: PER_PAGE,
        total: this.listData.length,
      };
    },
    validate() {
      return yup.object().shape({
        from: yup
          .string()
          .trim()
          .required(this.$t('E-CM-100', { item: this.$t('transportation.departure_station') }))
          .max(100, this.$t('E-CM-111', { size: 100 })),
        to: yup
          .string()
          .trim()
          .required(this.$t('E-CM-100', { item: this.$t('transportation.arrival_station') }))
          .max(100, this.$t('E-CM-111', { size: 100 })),
        via: yup
          .string()
          .trim()
          .max(100, this.$t('E-CM-111', { size: 100 })),
      });
    },
  },
  methods: {
    closeModal() {
      this.clearData();
      this.$emit('cancel');
    },
    showBoxCreateTransportExpense(item) {
      this.transportExpense = {
        amount: item.total_amount,
        date: this.dataRequest.date,
        route: item?.route,
        payable_to: item?.corporation,
      };
      this.createTransportExpense = true;
    },
    handleSelectFrom(val) {
      this.dataRequest.from = val.value;
    },
    handleSelectTo(val) {
      this.dataRequest.to = val.value;
    },
    handleSelectVia(val) {
      this.dataRequest.via = val.value;
    },
    async querySearch(queryString, cb) {
      if (!queryString) cb([]);
      this.$services.common.getListTransportation(
        { name: queryString },
        res => {
          const data = res.list.map(item => {
            return { value: item[0] };
          });

          const results = queryString ? data.filter(this.createFilter(queryString)) : data;

          cb(results);
        },
        () => {
          cb([]);
        },
      );
    },
    createFilter(queryString) {
      return item => {
        return item.value.toLowerCase().includes(queryString.toLowerCase());
      };
    },
    submitCreateTransportExpense() {
      this.loading = true;
      const params = {
        ...this.transportExpense,
        ...this.dataRequest,
        title: this.dataRequest.from + ' ' + this.dataRequest.to,
      };
      if (this.$route?.params?.id) params.trip_id = this.$route.params.id;
      this.$services.cost.createTransportExpense(
        params,
        () => {
          this.loading = false;
          this.$emit('success');
          this.clearData();
        },
        err => {
          this.loading = false;
          this.notifyError(this.$t(err.error), true);
        },
      );
    },
    resetData() {
      this.dataRequest = {
        from: '',
        via: '',
        to: '',
        date: '',
        ic_card: 1,
        transportation_cost_type: 1,
        surchargeKind: 'free',
      };

      this.errors.date = '';

      setTimeout(() => {
        this.$refs.form.setErrors({
          from: '',
          via: '',
          to: '',
        });
      }, 100);
    },
    clearData() {
      this.loading = false;
      this.hasAnswer = false;
      this.showFormFilter = true;
      this.dialogVisibleStatus = false;
      this.createTransportExpense = false;
      this.dataRequest = {
        from: '',
        via: '',
        to: '',
        date: '',
        ic_card: 1,
        transportation_cost_type: 1,
        surchargeKind: 'free',
      };

      setTimeout(() => {
        this.$refs.form.setErrors({
          from: '',
          via: '',
          to: '',
        });
      }, 100);
    },
    changePageData(page) {
      this.paginate.page = page;
      this.paginate = { ...this.paginate, ...this.paginates };
    },
    submitForm() {
      this.$refs.form.validate().then(res => {
        if (this.checkEmpty(this.dataRequest.date)) {
          this.errors.date = this.$t('E-CM-100', { item: this.$t('transportation.date') });
          return;
        }

        if (res.valid) {
          this.listData = [];
          this.hasAnswer = true;
          this.showFormFilter = false;
          this.createTransportExpense = false;
          this.loading = true;

          const KEY = process.env.VUE_APP_KEY_API_EKISAPERT || '';
          if (KEY) this.dataRequest.key = KEY;
          // if (!this.checkEmpty(this.userMonthlyTickets))
          //   this.dataRequest.monthly_ticket_code = this.userMonthlyTickets[0].monthly_ticket_code;
          this.$services.common.getApiEki(
            this.dataRequest,
            res => {
              this.loading = false;
              this.paginate.page = 1;
              this.listData = res.list;

              let paginate = {
                current_page: 1,
                from: 1,
                total: res.list.length,
              };
              if (res.list.length <= PER_PAGE) {
                paginate = {
                  ...paginate,
                  to: res.list.length,
                  last_page: 1,
                };
              } else {
                paginate = {
                  ...paginate,
                  to: this.paginate.page * PER_PAGE,
                  last_page: this.listDataWithPaginate.length,
                };
              }

              this.paginate = { ...this.paginate, ...paginate };
              this.hasAnswer = true;
              this.searchOneWay = this.dataRequest.transportation_cost_type == 1 ? true : false;
              this.showFormFilter = false;
            },
            err => {
              this.loading = false;
              this.notifyError(this.$t(err.error));
            },
          );
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-show-again-search {
  height: 80px;
  margin-bottom: 16px;
  background: #e7ebec;
  margin-right: -32px;
  margin-left: -32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    border-color: #dfe3f1;
    background-color: #dfe3f1;
  }

  span {
    color: #3650a5;
  }
}
</style>
