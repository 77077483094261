import BaseService from './BaseService';
import API_CODE from '../utils/api_code.js';

export default class RegulationCheckAPI extends BaseService {
  async getListRegulations(params, query, success, error) {
    await this.get(API_CODE.OP_149 + this.urlParse(params, query), success, error);
  }
  async getRegulationCheckDetail(params, success, error) {
    await this.get(API_CODE.OP_162 + params.id, success, error);
  }
  async confirmRegulationCheck(params, success, error) {
    await this.post(API_CODE.OP_163, params, success, error);
  }
}
