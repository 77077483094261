<template>
  <div class="plan-head hide--estimate">
    <div class="show--new show--newfirstonly">
      <h4 class="text-center">{{ $t('new_trip.title') }}</h4>

      <p class="text-center" style="margin-bottom: 24px" v-if="isShowUploadTrip">
        {{ $t('new_trip.sub_title') }}
      </p>
    </div>

    <div class="plan-head-block" v-if="isShowUploadTrip">
      <div class="plan-head--left" style="display: none"></div>

      <div class="plan-head--right" style="width: auto; min-width: unset; margin: 0px auto; text-align: center">
        <button
          type="button"
          class="btn btn-lg btn-primary show--new hide--status20 hide--status30 hide--status31 hide--status40 hide--status41 hide--status90"
          @click="showUpload = true"
        >
          <i class="icon-abui-upload prx"></i>
          {{ $t('new_trip.reservation_slip_upload') }}
        </button>
        <p class="note mtm show--new show--newfirstonly" style="margin-bottom: 24px">
          {{ $t('new_trip.note_reservation_slip_upload_1') }}<br />{{ $t('new_trip.note_reservation_slip_upload_2') }}
        </p>

        <p class="show--new show--newfirstonly">{{ $t('common.or') }}</p>
      </div>
    </div>
  </div>

  <div class="section noborder ptn" id="section--main">
    <div class="schedule--start pts show--new">
      <div class="row">
        <div class="col-sm-3 col-sm-offset-2">
          <div class="form-group">
            <label>
              <span class="label-text">{{ $t('common.departure_date') }}</span>
            </label>
            <div class="input-with-icon">
              <el-date-picker
                class="date-input"
                v-model="dataRequest.start_date"
                :clearable="false"
                type="date"
                format="YYYY/MM/DD"
                value-format="YYYY/MM/DD"
                :placeholder="$t('common.enter_or_select_required')"
                :disabled-date="pickerFromOptions"
                :disabled="showUpload"
                @change="onChangeStartDate"
              />
              <p role="alert" class="error-feedback" v-text="errors.start_date"></p>
            </div>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-group">
            <label>
              <span class="label-text">{{ $t('common.return_date') }}</span>
            </label>
            <div class="input-with-icon">
              <el-date-picker
                class="date-input"
                v-model="dataRequest.end_date"
                :clearable="false"
                type="date"
                format="YYYY/MM/DD"
                value-format="YYYY/MM/DD"
                :placeholder="$t('common.enter_or_select_required')"
                :disabled-date="pickerToOptions"
                :disabled="showUpload"
              />
              <p role="alert" class="error-feedback" v-text="errors.end_date"></p>
            </div>
          </div>
        </div>
        <div class="col-sm-3 col-md-2">
          <div class="form-group">
            <button class="btn btn-sm btn-block btn--ls btn-inverse-primary" @click="createTrip()">
              {{ $t('new_trip.create') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ReservationUpload
    :title="$t('new_trip.reservation_slip')"
    :subTitle="$t('new_trip.upload_note')"
    accepted-files="image/jpg, image/jpeg, image/png, .heif, application/pdf"
    :acceptImageAndPDFOnly="true"
    :dialogVisible="showUpload"
    @cancel="showUpload = false"
    @yes="handleFile"
  />
</template>
<script>
import moment from 'moment';
import ReservationUpload from '@/components/trip/trip-item/ReservationUpload';
import mixins from '@/helpers/mixins';

export default {
  name: 'Trip',
  mixins: [mixins],
  components: { ReservationUpload },
  data() {
    return {
      showUpload: false,
      dataRequest: {
        start_date: '',
        end_date: '',
        files: [],
        note: '',
        content_reservation: '',
      },
      errors: {},
    };
  },
  computed: {
    isShowUploadTrip() {
      return this.$store.state.auth?.user?.setting.is_upload_itinerary == 1 || false;
    }
  },
  watch: {
    'dataRequest.start_date': function (val) {
      if (!val) this.errors.start_date = this.$t('E-CM-100', { item: this.$t('common.departure_date') });
      else this.errors.start_date = '';
    },
    'dataRequest.end_date': function (val) {
      if (!val) this.errors.end_date = this.$t('E-CM-100', { item: this.$t('common.return_date') });
      else this.errors.end_date = '';
    },
  },
  methods: {
    onChangeStartDate() {
      if (!this.checkEmpty(this.dataRequest.start_date) && this.checkEmpty(this.dataRequest.end_date)) {
        this.dataRequest.end_date = this.dataRequest.start_date;
      }
    },
    async getCurrentUserCheck() {
      await this.$services.account.current(
          res => {
            this.$store.dispatch('auth/initCurrentData', res);
          },
          () => {
            this.logout();
          },
      );
    },
    async handleFile(files, note, content_reservation) {
      await this.getCurrentUserCheck()
      if (!this.isShowUploadTrip) {
        this.$router.push(this.PAGES.UNAUTHORIZED);
        return;
      }
      this.dataRequest = {
        ...this.dataRequest,
        files: files,
        note,
        content_reservation,
      };
      this.showUpload = false;
      this.createTrip(false);
    },
    createTrip(hasDate = true) {
      if (hasDate) {
        if (this.checkEmpty(this.dataRequest.start_date)) {
          this.errors.start_date = this.$t('E-CM-100', { item: this.$t('common.departure_date') });
        } else this.errors.start_date = '';
        if (this.checkEmpty(this.dataRequest.end_date)) {
          this.errors.end_date = this.$t('E-CM-100', { item: this.$t('common.return_date') });
        } else this.errors.end_date = '';

        if (Object.values(this.errors).filter(item => item).length) return;
      }

      let formData = new FormData();
      if (hasDate) {
        formData.append('start_date', this.dataRequest.start_date);
        formData.append('end_date', this.dataRequest.end_date);
      } else {
        formData.append('note', this.dataRequest.note);
        formData.append('content_reservation', this.dataRequest.content_reservation);
        if (this.dataRequest.files.length > 0) {
          for (let index = 0; index < this.dataRequest.files.length; index++) {
            delete this.dataRequest.files[index].preview;
            formData.append('files[]', this.dataRequest.files[index]);
          }
        }
      }

      this.startLoading();
      this.$services.trip.createTrip(
        formData,
        res => {
          // this.endLoading();
          if (this.dataRequest.files.length > 0) {
            this.notifySuccess(this.$t('trip_item.main_content.message_upload_file_success'));
          }
          this.$router.push({
            name: this.PAGES.TRIP_UPDATE,
            params: {
              id: res.id,
            },
          });
        },
        err => {
          this.endLoading();
          this.notifyError(this.$t(err.error));
        },
      );
    },
    pickerFromOptions(time) {
      return (
        moment(new Date(time)).format('YYYY-MM-DD') > moment(this.dataRequest?.end_date).format('YYYY-MM-DD') ||
        moment(new Date(time)).format('YYYY-MM-DD') < moment(new Date()).format('YYYY-MM-DD')
      );
    },
    pickerToOptions(time) {
      if (!this.dataRequest.start_date)
        return moment(new Date(time)).format('YYYY-MM-DD') < moment(new Date()).format('YYYY-MM-DD');

      return moment(new Date(time)).format('YYYY-MM-DD') < moment(this.dataRequest?.start_date).format('YYYY-MM-DD');
    },
  },
};
</script>
