<template>
  <RightInfo
    ref="rightInfo"
    :trip-detail="tripFromDetail"
    :tripTime="tripTime"
    @onChangeData="onChangeData"
    @reloadTripDetailInfo="reloadTripDetail"
  />
  <Detail
    ref="TripDetail"
    :isButtonCreateRequestVisible="isButtonCreateRequestVisible"
    @showBusinessApproval="showBusinessApproval"
    @showBusinessReport="showBusinessReport"
    @syncDataToBusinessReport="syncDataToBusinessReport"
    @onShowModalExpense="onShowModalExpense"
    @syncTripDetail="syncTripDetail"
    @onChangeTimeTrip="onChangeTimeTrip"
    @onChangeData="onChangeData"
    @reloadManualInput="reloadManualInput"
    @reloadTripDetail="reloadTripDetail"
  />
  <BusinessReportModal
    :data-to-business-report="dataToReport"
    :business-report-type="businessReportType"
    :dialog-visible="isBusinessReportVisible"
    :trip-detail="tripFromDetail"
    @reloadTripDetail="reloadTripDetail"
    @openApprovedExpenses="openApprovedExpenses"
    @closeModal="closeBusinessReport"
  />
  <ModalApprovedExpenses
    :dialog-visible="approvedExpensesVisible"
    :trip-detail="dataToReport"
    @closeModal="closeApprovedExpenses"
  />
</template>
<script>
import Detail from '@/components/trip/trip-item/Detail';
import RightInfo from '@/components/trip/trip-item/RightInfo';
import BusinessReportModal from '@/components/trip/BusinessReportModal';
import ModalApprovedExpenses from '@/components/trip/ModalApprovedExpenses';
import { APPROVAL_STATUS, REPORT_STATUS } from '@/constants';
export default {
  name: 'Trip',
  components: { Detail, RightInfo, BusinessReportModal, ModalApprovedExpenses },
  created() {
    if (this.$store.state.auth.status.loggedIn) {
      this.getCurrentUser();
    }
  },
  mounted() {
    this.$emitter.on('syncBusinessDialogVisible', status => {
      if (status == 1) {
        this.isBusinessReportVisible = true;
        return;
      }
      // this.notifyError(this.$t('trip.the_application_not_be_sent'));
      this.isBusinessReportVisible = false;
    });
    this.$emitter.on('syncButtonCreateRequestVisible', status => {
      if (status == 1) {
        this.isButtonCreateRequestVisible = true;
        return;
      }
      // this.notifyError(this.$t('trip.the_application_not_be_sent'));
      this.isButtonCreateRequestVisible = false;
    });
  },

  beforeUnmount() {
    this.$emitter.off('syncBusinessDialogVisible');
    this.$emitter.off('syncButtonCreateRequestVisible');
  },
  data() {
    return {
      isBusinessReportVisible: false,
      isButtonCreateRequestVisible: true,
      approvedExpensesVisible: false,
      dataToReport: {},
      businessReportType: 0,
      tripTime: {},
      tripFromDetail: {},
      isChangeData: false,
    };
  },
  beforeRouteLeave(to, from, next) {
    if (this.isChangeData) {
      const answer = window.confirm(this.$t('common.confirm_leave_page'));
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  methods: {
    reloadManualInput() {
      this.$refs.rightInfo.reloadCostDetail();
    },
    reloadTripDetail() {
      this.$refs.TripDetail.getTripDetail();
      this.$refs.rightInfo.getFilesByTrip();
    },
    syncTripDetail(data) {
      this.tripFromDetail = data;
    },
    onChangeData(val) {
      this.isChangeData = val;
    },
    showBusinessApproval() {
      this.businessReportType = APPROVAL_STATUS;
      this.$refs.rightInfo.validateExpenses();
      // this.isBusinessReportVisible = true;
    },
    showBusinessReport() {
      this.businessReportType = REPORT_STATUS;
      this.$refs.rightInfo.validateExpenses();
      // this.isBusinessReportVisible = true;
    },
    closeBusinessReport() {
      this.$refs.TripDetail.getTripDetail();
      this.isBusinessReportVisible = false;
    },
    syncDataToBusinessReport(data) {
      this.dataToReport = data;
    },
    onShowModalExpense() {
      this.$refs.rightInfo.loadShowBtnSendRequestExpense();
    },
    openApprovedExpenses() {
      this.approvedExpensesVisible = true;
      this.isBusinessReportVisible = false;
    },
    closeApprovedExpenses() {
      this.approvedExpensesVisible = false;
      this.isBusinessReportVisible = true;
    },
    onChangeTimeTrip(time) {
      this.tripTime = time;
    },
    async getCurrentUser() {
      await this.$services.account.current(
        res => {
          this.$store.dispatch('auth/initCurrentData', res);
        },
        () => {
          this.logout();
        },
      );
    },
  },
};
</script>
