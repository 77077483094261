<template>
  <nav
    class="navbar navbar-default navbar-lg"
    role="navigation"
    id="header-content"
  >
    <div class="navbar-header">
      <h2 class="navbar-title">{{ $t('information.notice') }}</h2>
    </div>
  </nav>
  <!-- /.navbar -->
  <div class="wrapper">
    <div class="container-fluid">
      <div>
        <div class="section noborder">
          <div class="pagination">
            <Pagination
              v-if="listNotifications.length > 0"
              :hasOptionPaginate="false"
              :paginate="paginate"
              @change-page="changePageData"
              @change-number="changeNumber"
            />
          </div>
          <div class="hidden-xs">
            <div class="table-responsive">
              <table
                class="table table-hover table-hover--pointer table--information-list"
                id="table--plan"
              >
                <colgroup span="1" style="width: 8.33%;"></colgroup>
                <colgroup span="1" style="width: 12.5%;"></colgroup>
                <colgroup span="1" style=""></colgroup>
                <thead>
                  <tr>
                    <th>{{ $t('information.date') }}</th>
                    <th class="cell-control">
                      <el-select
                        @change="changeType()"
                        v-model="listNotificationType"
                        popper-class="custom-dropdown-select"
                        class="select-block select-sm display-select"
                        :clearable="false"
                      >
                        <el-option
                          v-for="item in listNotice"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        />
                      </el-select>
                    </th>
                    <th></th>
                    <th class="text-end">
                      <button
                          @click="readListNotification()"
                          class="btn-read-all"
                      >
                        {{ $t('common.read_all') }}
                      </button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in listNotifications"
                    :key="index"
                    @click="goToNotificationDetail(item.id)"
                  >
                    <td>{{ showDateTime(item.created_at, 'YYYY/MM/DD') }}</td>
                    <td>
                      {{ getNotificationTypeLabel(item.notification_type) }}
                    </td>
                    <td>
                      <span v-if="!checkEmpty(item.trip_id)">
                        {{ item.title }}
                      </span>
                      <span
                        v-else-if="
                          checkEmpty(item?.trip_id) &&
                          checkEmpty(item?.is_view_passport) &&
                          (item?.notification_type == 4 ||
                            item?.notification_type == 6
                          )
                        "
                      >
                        {{ $t('application.cost_no_trip') + item.title }}
                      </span>
                      <span v-else>
                        {{ item.title }}
                      </span>
                    </td>
                    <td class="text-end" @click.stop>
                      <button
                        v-if="item.is_read == 1"
                        @click="updateIsRead(item)"
                        class="btn btn-xs btn-flat-default btn-unread"
                      >
                        {{ $t('information.is_read') }}
                      </button>
                      <button v-else class="btn-read"></button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p v-if="listNotifications.length == 0" class="no-data">
                {{ $t('common.no_data') }}
              </p>
            </div>
          </div>

          <div class="pagination">
            <Pagination
              v-if="listNotifications.length > 0"
              :hasOptionPaginate="false"
              :paginate="paginate"
              @change-page="changePageData"
              @change-number="changeNumber"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Pagination from '@/components/common/Pagination'
export default {
  name: 'Information',
  components: {
    Pagination,
  },
  data() {
    return {
      listNotifications: [],
      listNotificationType: this.$route.query.notification_type
        ? '' + this.$route.query.notification_type
        : '',
      listNotice: [
        {
          value: '',
          label: this.$t('common.all'),
        },
        {
          value: '1',
          label: this.$t('information.type.1'),
        },
        {
          value: '2',
          label: this.$t('information.type.2'),
        },
        {
          value: '4',
          label: this.$t('information.type.4'),
        },
        {
          value: '5',
          label: this.$t('information.type.5'),
        },
        {
          value: '6',
          label: this.$t('information.type.6'),
        },
      ],
      paginate: {
        page: +this.$route.query.page || 1,
        per_page: +this.$route.query.per_page || 10,
      },
    }
  },
  computed: {
    userId() {
      return this.$store.state.auth?.user?.id || ''
    },
  },
  created() {
    this.getListNotification(+this.listNotificationType)
  },
  methods: {
    handleTitleInformation(item) {
      if (this.checkEmpty(item.trip_code)) return item.title
      let newTitle = item.title.split(item.trip_code)

      return newTitle
    },
    changeType() {
      this.paginate.page = 1
      this.getListNotification(+this.listNotificationType)
    },
    updateIsRead(item) {
      this.$services.notification.updateIsUnReadNotification(
        item.id,
        () => {
          item.is_read = 0
          this.checkNotificationUnread()
        },
        (err) => {
          this.notifyError(this.$t(err.error))
        },
      )
    },
    checkNotificationUnread() {
      this.$services.notification.countNotificationUnread(
        (res) => {
          this.$store.dispatch(
            'notification/totalNotificationUnread',
            res.count,
          )
        },
        (err) => {
          this.notifyError(this.$t(err.error))
        },
      )
    },
    changePageData(page) {
      this.paginate.page = +page
      this.getListNotification(+this.listNotificationType)
    },
    async getListNotification(type) {
      const dataFilter = {
        notification_type: type,
        page: this.paginate.page,
        per_page: this.paginate.per_page,
      }
      if (!type) {
        delete dataFilter.notification_type
      }
      this.startLoading()
      await this.$services.notification.getListNotifications(
        dataFilter,
        (res) => {
          this.listNotifications = res.list
          this.paginate = { ...this.paginate, ...res.paginate }
          this.endLoading()

          this.$router.push({
            path: this.$route.path,
            query: {
              notification_type: this.listNotificationType,
              page: res.paginate.current_page,
              per_page: res.paginate.per_page,
            },
          })
        },
        (err) => {
          this.endLoading()
          this.notifyError(this.$t(err.error))
        },
      )
    },
    async readListNotification() {
      const dataFilter = {
        notification_type: this.listNotificationType
      }
      if (!this.listNotificationType) {
        delete dataFilter.notification_type
      }
      this.startLoading()
      await this.$services.notification.readListNotifications(
        dataFilter,
        () => {
          this.endLoading()
          this.getListNotification(+this.listNotificationType)
        },
        (err) => {
          this.endLoading()
          this.notifyError(this.$t(err.error))
        },
      )
    },
    checkCondition(item) {
      if (
        !this.checkEmpty(item?.trip_id) ||
        !this.checkEmpty(item?.request_id) ||
        !this.checkEmpty(item?.is_view_passport)
      ) {
        return true
      }

      return false
    },
    redirectNotification(item) {
      let routeData
      if (!this.checkEmpty(item.trip_id)) {
        routeData = this.$router.resolve({
          name: this.PAGES.TRIP_UPDATE,
          params: {
            id: item.trip_id,
          },
        })
      } else if (!this.checkEmpty(item.request_id)) {
        routeData = this.$router.resolve({
          name: this.PAGES.TRIP_ITEM,
          params: {
            id: item.request_id,
          },
        })
      } else {
        routeData = this.$router.resolve({
          name: this.PAGES.EMPLOYEE_DETAIL,
          params: {
            id: this.userId,
          },
        })
      }
      window.open(routeData.href, '_blank')
    },
    changeNumber(number) {
      this.paginate = {
        ...this.paginate,
        page: 1,
        per_page: number,
      }
      this.getListNotification(+this.listNotificationType)
    },
    goToNotificationDetail(id) {
      this.$router.push({
        name: this.PAGES.INFORMATION_DETAIL,
        params: {
          id: id,
        },
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.text-end {
  text-align: end;
}
.display-select {
  margin-bottom: 5px;
}
.btn-read {
  background-color: #3650a5;
  border: none;
  color: white;
  padding: 3px;
  font-size: 10px;
  margin: 4px 40px;
  border-radius: 50%;
  margin-right: 0px;
}
.btn-read-all {
  color: #3650a5 !important;
  width: auto !important;
  font-size: 11px !important;
  background-color: #fff !important;
  font-weight: normal;
  -webkit-font-smoothing: subpixel-antialiased;
  line-height: 1.429;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  border: none;
  border-radius: 2px;
  background-image: none;
  padding: 12px 13px;
  transition: 0.25s;
  display: inline-block;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.btn-read-all:hover {
  background-color: #eff1f8 !important;
  color: #3650a5 !important;
}
</style>
