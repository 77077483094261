<template>
  <el-dialog
    v-model="isVisible"
    width="640px"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    :show-close="true"
    @close="$emit('closeModal')"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-header--title">{{ $t('trip_item.modal_suspend_ringi.title') }}</div>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <p class="modal--diaglog--text">{{ $t('trip_item.modal_suspend_ringi.text') }}</p>
              <div class="form-group">
                <textarea
                  v-model="suspendRingiMsg"
                  class="form-control fixed-width-textarea"
                  rows="6"
                  :placeholder="$t('trip_item.modal_suspend_ringi.msg_placeholder')"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="row">
            <div class="col-xs-6 col-sm-6 col-md-6">
              <button @click="closeModal()" class="btn btn-sm btn-flat-default btn-block">
                {{ $t('common.cancel') }}
              </button>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6">
              <button
                @click="submitSuspendRingiModal()"
                class="btn btn-sm btn-default btn-block open-alert js-btn--do-suspend"
                id=""
              >
                {{ $t('trip_item.modal_suspend_ringi.execute_suspend') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: 'ModalSuspendRingi',
  emits: ['submitSuspendRingiModal', 'closeModal'],
  props: {
    isModalVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isVisible: false,
      suspendRingiMsg: '',
    };
  },
  watch: {
    isModalVisible() {
      this.isVisible = this.isModalVisible;
    },
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
    submitSuspendRingiModal() {
      this.$emit('submitSuspendRingiModal', this.suspendRingiMsg);
      this.suspendRingiMsg = '';
    },
  },
};
</script>
<style scoped></style>
