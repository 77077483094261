<template>
  <div class="navbar navbar-fixed-top with-sidebar with-sidebar-below" role="navigation" id="header-site">
    <div class="navbar-header">
      <h1 class="navbar-title">
        <a href="#" @click="$router.push(PAGES.HOME)">
          <span class="site-title" style="text-transform: uppercase">
            <img src="@/assets/images/logo.png" alt="logo" class="w-100" /><small v-if="isOperatorOrAdmin">
              for Operator
            </small>
          </span>
        </a>
      </h1>
    </div>
    <div class="navbar-collapse collapse" id="navbar-collapse-01">
      <div class="navbar-right">
        <p class="navbar-text" v-if="!isOperatorOrAdmin">
          <span class="companyname" v-text="companyName"></span>
        </p>

        <p class="navbar-icon mrl" v-if="!isOperatorOrAdmin">
          <a class="cursor-pointer" @click="this.$router.push(this.PAGES.INFORMATION)">
            <span class="icon-abui-bell"></span>
            <span v-if="newNotification" class="navbar-unread">{{ newNotification }}</span>
          </a>
        </p>

        <ul class="nav navbar-nav" style="margin-top: 1px">
          <li class="dropdown">
            <a
              href="#"
              class="dropdown-toggle navbar-user"
              @click="isShowDropdown = !isShowDropdown"
              v-click-outside="
                () => {
                  isShowDropdown = false;
                }
              "
            >
              <span class="user--wrap prx">
                <span class="icon-abui-user"></span>
              </span>
              {{ username }}
            </a>
            <ul
              class="dropdown-menu"
              :class="isShowDropdown && 'showDropdown'"
              :style="isOperatorOrAdmin && 'right: 4px;left:auto'"
            >
              <li>
                <a href="#" @click="logout">{{ $t('common.logout') }}</a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'click-outside-vue3';

export default {
  name: 'Dashboard',
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      isShowDropdown: false,
    };
  },
  created() {
    if (this.$store.state.auth.status.loggedIn) {
      // this.startLoading();
      Promise.all([this.getCurrentUser(), this.countNotificationUnread()]).then(() => {
        // this.endLoading();
      });
    }
  },
  computed: {
    newNotification() {
      return this.$store.state.notification.unread;
    },
    username() {
      return this.$store.state.auth?.user?.name || '';
    },
    employee_number() {
      return this.$store.state.auth?.user?.employee_number || '';
    },
    companyName() {
      return this.$store.state.auth?.user?.company?.name || '';
    },
  },
  methods: {
    async getCurrentUser() {
      await this.$services.account.current(
        res => {
          this.$store.dispatch('auth/initCurrentData', res);
        },
        () => {
          this.logout();
        },
      );
    },
    async countNotificationUnread() {
      await this.$services.notification.countNotificationUnread(
        res => {
          this.$store.dispatch('notification/totalNotificationUnread', res.count);
        },
        () => {},
      );
    },
    logout() {
      // this.startLoading();
      this.$services.account.logout(
        { user_id: this.employee_number },
        () => {
          this.$store.dispatch('auth/logout').then(() => {
            this.removeAccessToken();
            this.$router.push(this.PAGES.LOGIN);
          });
          // this.endLoading();
        },
        () => {
          // this.endLoading();
        },
      );
    },
  },
};
</script>
