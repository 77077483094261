<template>
  <div class="col-sm-6 col-md-3 col-all-closer col-all-closer--leftend col-sm-closer--rightend col-xs-closer--rightend">
    <div class="form-group">
      <label>
        <span class="label-text">
          {{ datePickerTitle }}
        </span>
      </label>
      <div class="input-group">
        <div class="input-with-icon">
          <el-date-picker
            class="date-input"
            v-model="startDate"
            type="date"
            format="YYYY/MM/DD"
            value-format="YYYY/MM/DD"
            :placeholder="$t('common.enter_or_select')"
            :clearable="false"
            :disabled-date="pickerStartOptions"
          >
          </el-date-picker>
        </div>
        <span class="input-group-addon prn">{{ $t('application.from') }}</span>
      </div>
    </div>
  </div>
  <div class="col-sm-6 col-md-3 col-all-closer col-all-closer--rightend col-sm-closer--leftend col-xs-closer--leftend">
    <div class="form-group">
      <label class="hidden-xs"><span class="label-text">&nbsp;</span></label>
      <div class="input-group">
        <div class="input-with-icon">
          <el-date-picker
            class="date-input"
            v-model="endDate"
            type="date"
            format="YYYY/MM/DD"
            value-format="YYYY/MM/DD"
            :placeholder="$t('common.enter_or_select')"
            :clearable="false"
            :disabled-date="pickerEndOptions"
          >
          </el-date-picker>
        </div>
        <span class="input-group-addon prn">{{ $t('application.to') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import mixins from '@/helpers/mixins';

export default {
  name: 'DatePickerWithRange',
  emits: ['listenStartDateChange', 'listenEndDateChange'],
  mixins: [mixins],
  props: {
    parentStartDate: {
      type: String,
      default: '',
    },
    parentEndDate: {
      type: String,
      default: '',
    },
    datePickerTitle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      startDate: this.parentStartDate,
      endDate: this.parentEndDate,
    };
  },
  watch: {
    startDate(newVal) {
      if (this.checkEmpty(this.endDate)) this.endDate = newVal;
      this.$emit('listenStartDateChange', newVal);
    },
    endDate() {
      this.$emit('listenEndDateChange', this.endDate);
    },
    parentStartDate() {
      this.startDate = this.parentStartDate;
    },
    parentEndDate() {
      this.endDate = this.parentEndDate;
    },
    datePickerTitle() {
      this.title = this.datePickerTitle;
    },
  },
  methods: {
    pickerStartOptions(time) {
      if (this.checkEmpty(this.endDate)) return false;

      return moment(new Date(time)).format('YYYY-MM-DD') > moment(this.endDate).format('YYYY-MM-DD');
    },
    pickerEndOptions(time) {
      if (this.checkEmpty(this.startDate)) return false;

      return moment(new Date(time)).format('YYYY-MM-DD') < moment(this.startDate).format('YYYY-MM-DD');
    },
  },
};
</script>
<style scoped></style>
