import BaseService from './BaseService';
import API_CODE from '../utils/api_code.js';

export default class NotificationAPI extends BaseService {
  async getListNotifications(params, success, error) {
    await this.get(API_CODE.CU_060 + this.urlParse(params), success, error);
  }
  async readListNotifications(params, success, error) {
    await this.get(API_CODE.CU_319 + this.urlParse(params), success, error);
  }
  async getInformationDetail(id, success, error) {
    await this.get(API_CODE.CU_061 + id, success, error);
  }
  async countNotificationUnread(success, error) {
    await this.get(API_CODE.CU_220, success, error);
  }
  async updateIsReadNotification(id, success, error) {
    await this.post(API_CODE.CU_090 + id, {}, success, error);
  }
  async updateIsUnReadNotification(id, success, error) {
    await this.post(API_CODE.CU_288 + id, {}, success, error);
  }
  async getListNotificationsOp(params, query, success, error) {
    await this.get(API_CODE.OP_175 + this.urlParse(params, query), success, error);
  }
  async getCompanies(params, success, error) {
    await this.get(API_CODE.OP_135 + this.urlParse(params), success, error);
  }
  async getByCompanies(params, success, error) {
    await this.get(API_CODE.OP_181 + this.urlParse(params), success, error);
  }
  async getNotificationType(params, success, error) {
    await this.get(API_CODE.CU_182 + this.urlParse(params), success, error);
  }
  async updateNotification(params, success, error) {
    await this.post(API_CODE.OP_272 + params.id, params, success, error);
  }
  async deleteNotification(id, success, error) {
    await this.delete(API_CODE.OP_190 + id, {}, success, error);
  }
  async getDetailNotification(id, success, error) {
    await this.get(API_CODE.OP_271 + id, success, error);
  }
  async createNotification(params, success, error) {
    await this.post(API_CODE.OP_268, params, success, error);
  }
}
