<template>
  <el-dialog
    v-model="isVisible"
    width="736px"
    @close="closeModal()"
    :show-close="false"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <button @click="closeModal()" type="button" class="close">
          <span class="icon-abui-cross" aria-hidden="true"></span>
        </button>
        <div class="modal-header">
          <div class="modal-header--title">{{ $t('business_report.authorized_users') }}</div>
        </div>
        <div class="modal-body" style="padding-bottom: 24px">
          <div class="section">
            <div class="add-selected">
              <div class="row">
                <div class="col-sm-12 col-md-12">
                  <div class="js-refine-search">
                    <table class="table table-striped table-word-seach">
                      <thead>
                        <tr>
                          <th>
                            <div
                              class="input-group input-group-sm input-group-rounded"
                              :class="isFocusSearch && 'focus'"
                            >
                              <span class="input-group-btn">
                                <button
                                  type="submit"
                                  class="btn btn-sm btn-search-left"
                                  :class="isFocusSearch && 'custom-icon-search'"
                                >
                                  <span class="icon-abui-search"></span>
                                </button>
                              </span>
                              <input
                                type="text"
                                v-model="searchName"
                                class="form-control js-refine-search__input input-sm"
                                @focus="isFocusSearch = true"
                                @blur="isFocusSearch = false"
                                :placeholder="$t('business_report.search_approver_placeholder')"
                              />
                            </div>
                          </th>
                        </tr>
                      </thead>
                    </table>
                    <div id="table--user--wrap" style="overflow: auto; height: 798px">
                      <table class="table table-striped table-hover table-word-seach__body table-hover--pointer">
                        <colgroup v-if="isEmployeeManagerOperatorOrAdmin || isShowUserId" span="1"></colgroup>
                        <colgroup span="1" style="width: 120px"></colgroup>
                        <colgroup span="1"></colgroup>
                        <colgroup span="1" style="width: 88px"></colgroup>
                        <tbody v-if="!checkEmpty(filteredData)" id="refine-tgtlist--modal">
                          <tr
                            v-for="(approver, index) in filteredData"
                            :key="index"
                            class="js-refine-search__item"
                            :style="approverExisted(approver.id) && 'display: none;'"
                            @click="addApprover(approver)"
                          >
                            <td v-if="isEmployeeManagerOperatorOrAdmin || isShowUserId" class="js-refine-search__text">
                              {{ approver.employee_number }}
                            </td>
                            <td class="js-refine-search__text">{{ approver.name }}</td>
                            <td class="js-refine-search__text">{{ approver.organization }}</td>
                            <td class="js-refine-search__text">{{ approver.position }}</td>
                          </tr>
                        </tbody>
                        <tbody v-else id="refine-tgtlist--modal">
                          <tr class="js-refine-search__item">
                            <td colspan="4" class="js-refine-search__text">
                              <p class="no-data">{{ $t('common.no_data') }}</p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import mixins from '@/helpers/mixins';
import { REQUEST_BUSINESS_TRIP_TYPE, REQUEST_CALCULATE_TYPE } from '@/constants/';

export default {
  name: 'AddApproverModal',
  emits: ['closeModal', 'emitAddApprover'],
  mixins: [mixins],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    requestType: {
      type: Number,
      default: 0,
    },
    currentApproverList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      isVisible: false,
      listApproval: [],
      searchName: '',
      isFocusSearch: false,
      REQUEST_BUSINESS_TRIP_TYPE,
      REQUEST_CALCULATE_TYPE,
    };
  },
  created() {
    this.startLoading();
    Promise.all([this.getApproval()]).then(() => {
      this.endLoading();
    });
  },
  computed: {
    userId() {
      return this.$store.state.auth?.user?.id || '';
    },
    filteredData() {
      const lowerCaseSearch = this.searchName.toLowerCase() || '';
      return this.listApproval.filter(item => {
        if (lowerCaseSearch == '') return item;

        return (
          (!this.checkEmpty(item?.name) && item.name.toLowerCase().indexOf(lowerCaseSearch) > -1) ||
          (!this.checkEmpty(item?.organization) && item.organization.toLowerCase().indexOf(lowerCaseSearch) > -1) ||
          (!this.checkEmpty(item?.position) && item.position.toLowerCase().indexOf(lowerCaseSearch) > -1)
        );
      });
    },
  },
  watch: {
    visible(value) {
      this.isVisible = value;
    },
  },
  methods: {
    approverExisted(approverId) {
      if (this.checkEmpty(this.currentApproverList)) return false;
      for (let i = 0; i < this.currentApproverList.length; i++) {
        if (approverId == this.currentApproverList[i].user_id) return true;
      }
      return false;
    },
    closeModal() {
      this.$emit('closeModal');
    },
    addApprover(approverDetail) {
      this.$emit('emitAddApprover', approverDetail);
    },
    async getApproval() {
      await this.$services.dashboard.getApproval(
        { request_type: this.requestType == 3 ? REQUEST_CALCULATE_TYPE : REQUEST_BUSINESS_TRIP_TYPE },
        res => {
          this.listApproval = res.list;
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
  },
};
</script>
<style scoped>
.custom-icon-search {
  border-right: none !important;
  border-color: #3650a5 !important;
  outline: 0;
}
</style>
