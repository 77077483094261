<template>
  <div @click="toggleShowDetail">
    <div
        class="ringi--step show--ringi trip-process"
        :style="isShowDetail ? { height: processHeight } : { height: '92px' }"
    >
      <div v-if="hasHoldOn &&
(requestDetail?.approval_status_id === 4 ||
          requestDetail?.report_status_id === 4 ||
          requestDetail?.expense_status_id === 3)" class="msg-suspend-ringi">
        <span>{{ $t('trip_item.trip_process.hold_msg') }}</span>
      </div>
      <button class="btn btn-sm btn-flat-default btn--ringi-history" @click.stop="showHistory">
        <i class="icon-abui-time"></i><br>
        {{ $t('common.show_histories') }}
      </button>
      <div ref="myDiv" class="step-list" :style="isShowDetail ? { height: processHeight } : { height: '92px' }"
           :class="requestDetail?.approval_flow_detail?.length < 9 && 'justify-content-center'"
      >
        <div ref="boxDiv" class="box-box">
        <div
            class="step-item"
            v-for="(item, stepIndex) in requestDetail?.approval_flow_detail"
            :key="stepIndex"
            :class="checkCurrentStep(stepIndex)"
        >
          <i class="step-mark"></i>
          <span>{{ checkStepTitle(stepIndex) }}</span>
          <span class="ringi-step--type">{{ textToStepType(item.approval_flow_type) }}</span>
          <ul class="list--approver" v-if="isShowDetail">
            <div v-for="(approver, id) in item.step_approver" :key="id">
              <li v-if="!approver?.is_authorizer" :class="calApproverStyle(approver, stepIndex)">
                {{ approver?.old_approver_name }}
              </li>
            </div>
          </ul>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { APPROVAL, PASS_ROUND_PERUSAL, REMAND, HOLD } from '@/constants/';
import mixins from '@/helpers/mixins';

export default {
  name: 'TripProcess',
  emits: ['canApproverSubmit', 'showHistory', 'handleShowDetail'],
  mixins: [mixins],
  props: {
    showDetail: {
      type: Boolean,
      default: false,
    },
    requestDetail: {
      type: Object,
      default: () => {
        return {
          daily_allowance: {
            amount: '',
            data: [],
            note: '',
          },
          fees: {
            title: '',
            amount: '',
            data: [],
          },
        };
      },
    },
  },
  data() {
    return {
      isShowDetail: false,
      curStep: 0,
      processHeight: '160px',
    };
  },
  computed: {
    userId() {
      return this.$store.state.auth.user?.id || '';
    },
    hasHoldOn() {
      if (this.requestDetail?.flag_withdrawal === 1) {
        return false;
      }
      if (this.checkEmpty(this.requestDetail)) return false;
      const stepApprover = this.requestDetail.approval_flow_detail[this.curStep - 1]?.step_approver;
      if (this.checkEmpty(stepApprover)) return false;
      for (let i = 0; i < stepApprover.length; i++) {
        if (stepApprover[i].request_status == HOLD) return true;
      }
      return false;
    },
    isRequestRejected() {
      return (
          this.requestDetail.approval_status_id == 5 ||
          this.requestDetail.report_status_id == 5 ||
          this.requestDetail.expense_status_id == 4
      );
    },
    isRequestFinished() {
      let hasApprovedAction = false;
      if (!this.curStep) return false;
      const requestStepApprover = this.requestDetail.approval_flow_detail[this.curStep - 1]?.step_approver;
      if (!this.checkEmpty(requestStepApprover)) {
        requestStepApprover.every(item => {
          if (item.request_status == APPROVAL || item.request_status == PASS_ROUND_PERUSAL) {
            hasApprovedAction = true;
            return false;
          } else {
            return true;
          }
        });
      }
      if (this.requestDetail.approval_flow_detail?.length == this.requestDetail.current_step && hasApprovedAction)
        return true;
      return false;
    },
  },
  watch: {
    isRequestFinished(newVal) {
      if (newVal) this.$emit('canApproverSubmit', false);
    },
    requestDetail(newVal) {
      if (this.checkEmpty(newVal)) return;
      this.curStep = newVal.current_step || '';
      const requestStepApprover = newVal.approval_flow_detail[this.curStep - 1]?.step_approver;
      this.calHeightApprover();
      for (let i = 0; i < requestStepApprover.length; i++) {
        if (
            requestStepApprover[i].approver_id === this.userId &&
            requestStepApprover[i].request_status === HOLD &&
            !this.isRequestFinished &&
            !this.isRequestRejected
        ) {
          this.$emit('canApproverSubmit', true);
          return;
        }
        if (
            requestStepApprover[i].approver_id === this.userId &&
            requestStepApprover[i].request_status === null &&
            !this.hasHoldOn &&
            !this.isRequestFinished &&
            !this.isRequestRejected
        ) {
          this.$emit('canApproverSubmit', true);
          return;
        }
      }
      this.$emit('canApproverSubmit', false);
    },
    showDetail() {
      this.isShowDetail = this.showDetail;
    },
  },
  methods: {
    checkStepTitle(stepIndex) {
      stepIndex++;
      const lastStep = this.requestDetail.approval_flow_detail.length;
      if (stepIndex == lastStep) {
        return this.$t('trip_item.trip_process.finally');
      }
      return stepIndex;
    },
    toggleShowDetail() {
      this.isShowDetail = !this.isShowDetail;
      this.$emit('handleShowDetail', this.isShowDetail);
    },
    showHistory() {
      this.$emit('showHistory');
    },
    checkCurrentStep(stepIndex) {
      const checkStep = stepIndex + 1;
      if (checkStep == this.curStep) {
        return this.isRequestRejected ? 'done' : 'active';
      }
      if (checkStep < this.curStep) return 'done';
      return 'undone';
    },
    textToStepType(stepType) {
      if (stepType === APPROVAL) return this.$t('trip_item.trip_process.approval');
      if (stepType === PASS_ROUND_PERUSAL) return this.$t('trip_item.trip_process.pass_perusal');
      if (stepType === REMAND) return this.$t('trip_item.trip_process.remand');
      if (stepType === HOLD) return this.$t('trip_item.trip_process.on_hold');
      return '-';
    },
    calApproverStyle(approver, stepIndex) {
      // if (this.isRequestRejected) return '';
      stepIndex++;
      if (approver.approver_id == this.userId) {
        if (
            (approver.request_status === APPROVAL || approver.request_status === PASS_ROUND_PERUSAL) &&
            this.curStep > stepIndex
        )
          return 'self current-did';
        if (approver.request_status === HOLD && this.curStep === stepIndex) {
          return 'self current-held-on';
        }
        return 'self';
      }
      if (approver.approver_id !== this.userId) {
        if (
            this.curStep > stepIndex &&
            (approver.request_status === APPROVAL || approver.request_status === PASS_ROUND_PERUSAL)
        )
          return 'did-others';
        if (this.curStep == stepIndex && (approver.request_status === REMAND || approver.request_status === HOLD))
          return 'did rejected-or-hold';
      }
      return '';
    },
    calHeightApprover() {
      let maxLengthApprover = 0;
      this.requestDetail?.approval_flow_detail.forEach(item => {
        if (item.step_approver?.length > maxLengthApprover) maxLengthApprover = item.step_approver?.length;
      });
      if (maxLengthApprover > 3) {
        this.processHeight = String(160 + (maxLengthApprover - 3) * 18) + 'px';
        return;
      }
      this.processHeight = '182px';
    },
  },
};
</script>
<style scoped>
.justify-content-center {
  justify-content: center !important;
}
.step-list {
  overflow-x: scroll !important;
  padding-right: 300px !important;
  width: 100% !important;
  justify-content: left;
}
.trip-process {
  /*overflow-x: scroll;*/
  padding-left: 0px !important;
  /*width: calc(100% - 145px) !important;*/
}
.btn--ringi-history {
  right: 55px;
  background-color: #f2f3f5;
  transition: unset;
  width: 165px;
  height: 182px;
  z-index: 2;
}
.ringi--step .step-item.done .list--approver li {
  opacity: 0.5;
}
.ringi--step .step-item.undone .list--approver li.self {
  opacity: 0.5;
}
li.self.current-did {
  opacity: 1 !important;
}
li.did-others {
  opacity: 1 !important;
  font-weight: bold;
}
li.self.current-rejected-or-undo {
  opacity: 0.5 !important;
}
li.self.current-held-on {
  opacity: 1 !important;
}
li.did.rejected-or-hold {
  opacity: 0.5 !important;
}
.box-box {
  display: flex;
}
</style>
