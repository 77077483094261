<template>
  <div></div>
</template>
<script>
import Cookies from "js-cookie";

export default {
  name: 'SsoAuth',
  created() {
    if (this.$route?.query?.token) {
      Cookies.set('access_token', this.$route.query.token, {
        expires: 90,
      });
      if (!this.checkEmpty(Cookies.get('redirectPath')) && Cookies.get('redirectPath') !== '/login') {
        window.location = Cookies.get('redirectPath');
      } else {
        window.location = '/';
      }
    } else {
      Cookies.set('sso_auth_failed', true, {
        expires: 365,
      });
      Cookies.set('error_message', this.$route.query.msg, {
        expires: 90,
      });
      this.$router.push(this.PAGES.LOGIN);
    }
    this.endLoading();
  },
};
</script>