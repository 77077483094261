<template>
  <el-dialog
    v-model="dialogVisibleStatus"
    @close="closeModal()"
    width="736px"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <button type="button" class="close" @click="closeModal()">
          <span class="icon-abui-cross" aria-hidden="true"></span>
        </button>
        <div class="modal-header">
          <div class="modal-header--title">{{ $t('expense.file_info') }}</div>
        </div>
        <div class="modal-body">
          <div class="section">
            <div class="add-selected">
              <div class="row">
                <div class="col-sm-12 col-md-12">
                  <div class="js-refine-search">
                    <div style="overflow: auto">
                      <table class="table table-hover table-hover--pointer table--org-list" id="">
                        <colgroup span="1" style="width: 20%"></colgroup>
                        <colgroup span="1" style="width: 12.5%"></colgroup>
                        <colgroup span="1" style="width: 12.5%"></colgroup>
                        <colgroup span="1" style="width: 12.5%"></colgroup>
                        <thead>
                          <tr>
                            <th>{{ $t('common.file_name') }}</th>
                            <th>{{ $t('common.page_number') }}</th>
                            <th>{{ $t('common.resolution') }}</th>
                            <th>{{ $t('common.color_information') }}</th>
                          </tr>
                        </thead>
                        <tbody v-if="!checkEmpty(listFile)">
                          <tr v-for="(file, idx) in listFile" :key="idx" @click="openFile(file)">
                            <td>{{ file?.name || '' }}</td>
                            <td v-if="file.type == 'pdf'">{{ idx + 1 }}ページ目</td>
                            <td v-else>-</td>
                            <td v-if="file?.attributes?.width">
                              {{ file?.attributes?.width }} x {{ file?.attributes?.height }} px
                            </td>
                            <td v-else>-</td>
                            <td v-if="file?.attributes?.bits">CMYK {{ file?.attributes?.bits }}bit</td>
                            <td v-else>-</td>
                          </tr>
                        </tbody>
                        <tbody v-else>
                          <tr class="no-data">
                            <td colspan="4">{{ $t('common.cant_get_data_info') }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<style></style>
<script>
import { ElDialog } from 'element-plus';

export default {
  components: {
    ElDialog,
  },
  name: 'ModalListFileInfo',
  emits: ['close'],
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    listFile: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    dialogVisible() {
      this.dialogVisibleStatus = this.dialogVisible;
    },
  },
  data() {
    return {
      dialogVisibleStatus: false,
    };
  },
  methods: {
    openFile(file) {
      if (file?.file_path) window.open(file?.file_path, '_blank');
    },
    closeModal() {
      this.$emit('close');
    },
  },
};
</script>
