<template>
  <nav class="navbar navbar-default navbar-lg" role="navigation" id="header-content">
    <div class="navbar-header">
      <h2 class="navbar-title">{{ $t('sidebar.expense_item') }}</h2>
    </div>
  </nav>
  <div class="wrapper with-navbar-fixed-top with-navbar-fixed-bottom">
    <div class="container-fluid">
      <div class="section noborder ptn" id="section--main">
        <div class="mbl">
          <p>{{ $t('expense.item.text_note') }}</p>
        </div>

        <ExpenseForm
          :dataForm="dataRequest"
          :actionSubmitForm="actionSubmitForm"
          :errors="errors"
          @submit="submitForm"
          @onChangeData="checkOnChangeData"
        />
      </div>
    </div>
    <div
      class="navbar navbar-default navbar-fixed-bottom navbar-footblock with-sidebar submit-new--wrap show--estimate"
      style="z-index: 990; padding: 12px"
    >
      <div class="row">
        <div class="col-sm-3">
          <button v-if="!dataRequest.has_child" class="btn btn-sm btn-flat-default" @click="showBoxConfirm = true">
            <i class="icon-abui-trash prx"></i>{{ $t('expense.btn_delete') }}
          </button>
        </div>
        <div class="col-sm-4 col-md-3 col-sm-offset-5 col-md-offset-6">
          <a
            href="#"
            class="btn btn-sm btn-block btn-primary open-alert"
            @click="this.actionSubmitForm = !this.actionSubmitForm"
          >
            {{ $t('common.submit') }}
          </a>
        </div>
      </div>
    </div>
  </div>

  <ModalConfirm
    :dialogVisible="showBoxConfirm"
    @cancel="showBoxConfirm = false"
    @yes="handleDelete()"
    :title="$t('Q-CM-104')"
  />
</template>
<script>
import ModalConfirm from '@/components/common/ModalConfirm';
import ExpenseForm from '@/components/expense/ExpenseForm';
export default {
  components: {
    ExpenseForm,
    ModalConfirm,
  },
  data() {
    return {
      flgCreate: false,
      actionSubmitForm: false,
      showBoxConfirm: false,
      errors: {},
      dataRequest: {
        type: 2,
        cost_code: '',
        has_child: false,
        name: '',
        display_order: '',
        note: '',
        parent_cost_items: '',
        items_code: '',
        sub_subject_code: '',
        tax_type: 3,
        is_valid: '',
        import_code: ''
      },
      isChangeData: false,
    };
  },
  created() {
    this.getCostItemDetail();
  },
  computed: {
    costItemId() {
      return this.$route.params.id;
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.isChangeData && !this.actionSubmitForm) {
      const answer = window.confirm(this.$t('common.confirm_leave_page'));
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  methods: {
    checkOnChangeData(val) {
      this.isChangeData = val;
    },
    async getCostItemDetail() {
      this.startLoading();
      await this.$services.cost.getCostItemDetail(
        this.costItemId,
        res => {
          this.endLoading();
          this.dataRequest = {
            ...res,
            code: res.cost_code,
            tax_type: res?.tax_type || 3,
            is_valid: res.is_valid || false,
            type: res.parent_id ? 2 : 1,
          };
        },
        err => {
          // this.endLoading();
          this.notifyError(this.$t(err.error));
          this.$router.push(this.PAGES.EXPENSE_ITEM);
        },
      );
    },
    handleDelete() {
      this.isChangeData = false;
      this.startLoading();
      this.$services.cost.deleteCostItemDetail(
        this.costItemId,
        () => {
          // this.endLoading();
          this.notifySuccess(this.$t('I-CM-102'));
          this.$router.push(this.PAGES.EXPENSE_ITEM);
        },
        err => {
          this.endLoading();
          this.showBoxConfirm = false;
          this.notifyError(this.$t(err.error));
        },
      );
    },
    submitForm(val) {
      this.isChangeData = false;
      const data = { ...val, cost_items_type: val.type };

      this.startLoading();
      this.$services.cost.updateCostItem(
        this.costItemId,
        data,
        () => {
          // this.endLoading();
          this.notifySuccess(this.$t('I-CM-103'));
          this.$router.push(this.PAGES.EXPENSE_ITEM);
        },
        err => {
          this.endLoading();
          if (err?.error) {
            this.errors = {
              code: err.error?.code ? err.error?.code[0] : '',
              name: err.error?.name ? err.error?.name[0] : '',
              subject_code: err.error?.subject_code ? err.error?.subject_code[0] : '',
              import_code: err.error?.import_code ? err.error?.import_code[0] : '',
              time: new Date().getTime(),
            };
            this.notifyError(this.$t(err.error), true);
          } else {
            this.errors = {};
            this.notifyError(this.$t(err.error), true);
          }
        },
      );
    },
  },
};
</script>
