import BaseService from './BaseService';
import API_CODE from '../utils/api_code.js';

export default class EmployeeAPI extends BaseService {
  async getListEmployees(params, success, error) {
    await this.get(API_CODE.CU_246 + this.urlParse(params), success, error);
  }
  async createEmployee(params, success, error) {
    await this.post(API_CODE.CU_232, params, success, error);
  }
  async scanPassport(params, success, error) {
    await this.post(API_CODE.CU_297, params, success, error);
  }
  async uploadPassport(params, success, error) {
    await this.post(API_CODE.CU_298, params, success, error);
  }
  async getEmployeeDetail(id, success, error) {
    await this.get(API_CODE.CU_233 + id, success, error);
  }
  async getMyProfile(success, error) {
    await this.get(API_CODE.CU_299, success, error);
  }
  async deleteEmployee(id, success, error) {
    await this.delete(API_CODE.CU_108 + id, {}, success, error);
  }
  async restoreEmployee(id, success, error) {
    await this.post(API_CODE.CU_317 + id, {}, success, error);
  }
  async deleteContact(id, success, error) {
    await this.delete(API_CODE.CU_259 + id, {}, success, error);
  }
  async deleteVisa(id, success, error) {
    await this.delete(API_CODE.CU_258 + id, {}, success, error);
  }
  async deleteSeasonTicket(id, success, error) {
    await this.delete(API_CODE.CU_257 + id, {}, success, error);
  }
  async updateEmployee(params, success, error) {
    await this.post(API_CODE.CU_234, params, success, error);
  }
  async downloadFileCSV(params, success, error) {
    await this.get(API_CODE.CU_251 + this.urlParse(params), success, error);
  }
  async downloadExampleFileCSV(success, error) {
    await this.get(API_CODE.CU_252, success, error);
  }
  async uploadFileCSV(params, success, error) {
    await this.post(API_CODE.CU_253, params, success, error);
  }
}
