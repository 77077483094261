<template>
  <div class="detail--wrap expense--detail">
    <button class="btn btn-sm btn-flat-default btn-detail-close js-btn--btn--close-detail" @click="$emit('close')">
      <i class="icon-abui-cross" style="font-size: 14px" />
    </button>
    <div v-if="!checkEmpty(costDetail.files) && showUploadedCostInvoice" class="expense--detail--detected">
      <a
          style="display: block"
          v-for="(file, index) in costDetail.files"
          :key="index"
          :href="file.path"
          target="_blank"
      >
        <i class="icon-abui-scan-check prx" />
        {{ file.name }}
      </a>
    </div>
    <div v-if="!checkEmpty(costDetail.input_files)" class="expense--detail--detected">
      <a style="costdetail.input_files: block" :href="costDetail.input_files.path" target="_blank">
        <i class="icon-abui-scan-check prx" />
        {{ costDetail.input_files.name }}
      </a>
    </div>
    <div class="row" id="form-upload-receipt" style="margin-right: 22px" v-if="!isTransportType">
      <div class="col-sm-12">
        <div class="form-group">
          <label>
            <span class="label-text">
              {{ $t('trip_item.right_menu.manual_input_model.receipt_card_statement_invoice') }}
            </span>
          </label>
          <button
              style="margin-bottom: 4px"
              type="button"
              class="btn btn-inverse btn-sm"
              @click="showModalUploadFile = true"
              :disabled="((isOperatorOrAdmin || disableEdit || isTransportType) &&
              (!checkEmpty(costDetail.files) || (tripStatus !== 3 && reportStatusId !== 6)) && !costDetail.flag_temporary_cost) ||
                ((tripStatus === 3 || reportStatusId === 6) && costDetail.is_sb)"
              :class="isTransportType && 'disabled'"
          >
            <i class="icon-abui-upload prx" />{{ $t('trip_item.right_menu.manual_input_model.upload') }}
          </button>
          <p role="alert" class="error-feedback" v-text="errors.files" />
          <button
              style="width: calc(100% + 34px); display: flex; justify-content: space-between; margin-bottom: 4px"
              v-for="(item, index) in listFileSelected"
              :key="index"
              type="button"
              class="btn btn-inverse-hover-danger btn-sm js-btn--delete-voucher-general"
              :disabled="((isOperatorOrAdmin || disableEdit) &&
              (!checkEmpty(costDetail.files) || (tripStatus !== 3 && reportStatusId !== 6)) && !costDetail.flag_temporary_cost) ||
                ((tripStatus === 3 || reportStatusId === 6) && costDetail.is_sb)"
          >
            <span class="thumbnail--voucher--filename" style="overflow: hidden; text-overflow: ellipsis">
              {{ item.name }}
            </span>
            <i class="icon-abui-cross pls" @click="deleteFile(item)" />
          </button>
        </div>
      </div>
    </div>
    <span v-if="costDetail.flag_temporary_cost === 1" class="btn btn-used-in-request">
         {{ $t('settings.temporary_expenses_label')}}
      </span>
    <div class="detail--title" :class="costDetail.flag_temporary_cost !== 1 && 'display-block'" style="padding-right: 0px">
      <input
          type="text"
          class="form-control input-sm"
          :placeholder="$t('trip_item.right_menu.manual_input_model.placeholder_cost_title')"
          v-model.trim="costDetail.title"
          maxlength="100"
          :disabled="isOperatorOrAdmin || disableEdit"
      />
      <p v-if="isTransportType" role="alert" class="transaction-cost">
        {{ $t('expense.dropdown.option1') }}
        <span v-if="costDetail.transportation_cost_type == 0"> （{{ $t('transportation.round_ticket') }}） </span>
        <span v-else>（{{ $t('transportation.one_way_ticket') }}）</span>
      </p>
      <p role="alert" class="error-feedback" v-text="errors.title" />
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div>
          <el-checkbox
              class="checkbox-display"
              :disabled="isOperatorOrAdmin || disableEdit"
              v-model="costDetail.is_insurance_cost"
              size="large"
          >
            {{ $t('expense.registration_insurance_costs') }}
          </el-checkbox>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <label>
            <span class="label-text">{{ $t('trip_item.right_menu.manual_input_model.payment_date') }}</span>
          </label>
          <div class="input-with-icon">
            <el-date-picker
                type="date"
                class="date-input"
                format="YYYY/MM/DD"
                value-format="YYYY/MM/DD"
                :placeholder="$t('common.enter_or_select')"
                :clearable="false"
                v-model="costDetail.payment_date"
                :disabled="isOperatorOrAdmin ||
                (disableEdit && costDetail.payment_date !== '' && !(tripStatus === 3 && costDetail.flag_temporary_cost)) ||
                ((tripStatus === 3 || reportStatusId === 6) && costDetail.is_sb)
                "
                @change="checkCurrencyRate"
            />
            <p role="alert" class="error-feedback" v-text="errors.payment_date" />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label>
            <span class="label-text">{{ $t('trip_item.right_menu.manual_input_model.payable_to') }}</span>
          </label>
          <input
              type="text"
              class="form-control input-sm"
              v-model.trim="costDetail.payment_to"
              id="input--expense--detail--payto"
              maxlength="255"
              :disabled="isOperatorOrAdmin || disableEdit"
          />
          <p role="alert" class="error-feedback" v-text="errors.payment_to" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label>
            <span class="label-text">{{ $t('trip_item.right_menu.manual_input_model.cost_item') }}</span>
          </label>
          <SingleGroupOptionSelect
              :list-data="listCostItem"
              @handle-selected-params="onChangeCostType"
              :initItem="defaultCompanyCostItem"
              :disabled="isOperatorOrAdmin || disableEdit"
          />
          <p role="alert" class="error-feedback" v-text="errors.cost_type" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <label>
            <span class="label-text">{{ $t('trip_item.right_menu.manual_input_model.currency') }}</span>
          </label>
          <el-select
              class="select-block select-sm js-select_item"
              popper-class="custom-dropdown-select"
              placeholder=""
              size="large"
              @change="checkCurrencyRate"
              v-model="currencySelected"
              :disabled="isOperatorOrAdmin || disableEdit"
          >
            <el-option v-for="item in currencyList" :value="labelLocale(item)" :key="item.id">
              {{ labelLocale(item) }}
            </el-option>
          </el-select>
          <p role="alert" class="error-feedback" v-text="errors.currency" />
        </div>
      </div>
      <div v-show="isCurrencyJapan" class="col-sm-6 js-select_item-currency__info-col__tgt currency__info-col_1">
        <div class="form-group">
          <label>
            <span class="label-text">{{ $t('trip_item.right_menu.manual_input_model.amount_of_money') }}</span>
            <span class="change-eki-cost" v-if="isChangeEki">{{ $t('expense.with_modification') }}</span>
          </label>
          <div class="input-group adjust-amount">
            <span class="input-group-addon">
              <span class="">{{ $t('trip.yen') }}</span>
            </span>
            <input
                v-model="currentAmount"
                type="text"
                autocomplete="off"
                class="form-control input-sm"
                onkeydown="javascript: return event.keyCode !== 69;"
                @input="currentAmount = convertNumberFullToHalf(currentAmount)"
                maxlength="14"
                :disabled="isOperatorOrAdmin || disableEdit"
                @change="convertData(currentAmount, 1)"
            />
          </div>
          <p role="alert" class="error-feedback" style="margin-left: 32px" v-text="errors.amount" />
        </div>
      </div>
      <div
          class="col-sm-6 js-select_item-currency__info-col__tgt currency__info-col_2 currency__info-col_3 currency__info-col_4 currency__info-col_5 currency__info-col_6"
          v-show="!isCurrencyJapan"
      >
        <div class="form-group">
          <label>
            <span class="label-text">{{ $t('trip_item.right_menu.manual_input_model.rate') }}</span>
          </label>
          <input
              class="form-control input-sm text-right"
              :class="onChangeRate != currentRate && 'feedback-overwrite'"
              :disabled="isDisableRate"
              v-model="onChangeRate"
              id="exchange-rate"
              maxlength="11"
              onkeydown="javascript: return event.keyCode !== 69;"
              @input="onChangeRate = convertNumberFullToHalf(onChangeRate)"
              @change="convertData(onChangeRate, 2)"
          />
          <p v-show="onChangeRate != currentRate" class="help-block text-right text-info">変更済</p>
          <p role="alert" class="error-feedback" v-text="errors.change_rate" />
        </div>
      </div>
    </div>
    <div v-if="isCurrencyJapan" class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <label>
            <span class="label-text">{{ $t('trip_item.right_menu.manual_input_model.tax_classification') }}</span>
          </label>
          <el-select
              class="select-block select-sm js-select_item"
              popper-class="custom-dropdown-select"
              :disabled="isOperatorOrAdmin || disableEdit"
              size="large"
              v-model="taxClassificationSelected"
              @change="onChangeTaxClassification"
              placeholder=" "
          >
            <el-option v-for="item in listTaxClassification" :key="item.id" :value="labelLocale(item)">
              {{ this.labelLocale(item) }}
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="col-sm-6 js-select_item-currency__info-col__tgt currency__info-col_1">
        <div class="form-group">
          <label>
            <span class="label-text">{{ $t('trip_item.right_menu.manual_input_model.tax_amount') }}</span>
          </label>
          <div class="input-group">
            <span class="input-group-addon">
              <span class="">{{ $t('trip.yen') }}</span>
            </span>
            <!-- <el-input
              :value="currentTaxAmount"
              autocomplete="off"
              class="adjust-amount"
              maxlength="14"
              @input="currentAmount = convertNumberFullToHalf(currentAmount)"
            /> -->
            <input
                v-model="currentTaxAmount"
                type="text"
                autocomplete="off"
                class="form-control input-sm text-right"
                onkeydown="javascript: return event.keyCode !== 69;"
                @input="currentTaxAmount = convertNumberFullToHalf(currentTaxAmount)"
                maxlength="14"
                :disabled="isOperatorOrAdmin || disableEdit"
                @change="convertData(currentTaxAmount, 4)"
            />
          </div>
          <p role="alert" class="error-feedback" style="margin-left: 32px" v-text="errors.tax_amount" />
        </div>
      </div>
    </div>
    <div
        v-else
        class="row js-select_item-currency__info-col__tgt currency__info-col_2 currency__info-col_3 currency__info-col_4 currency__info-col_5 currency__info-col_6"
    >
      <div class="col-sm-6">
        <div class="form-group">
          <label>
            <span class="label-text">
              {{ $t('trip_item.right_menu.manual_input_model.foreign_currency_denominated_amount') }}
            </span>
          </label>
          <div class="input-group">
            <span class="input-group-addon">
              <span class="js-select_item-currency__info-col__tgt currency__info-col_1">{{ currencyCode }}</span>
            </span>
            <el-input
                v-model="foreignAmount"
                autocomplete="off"
                class="adjust-amount"
                :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                onkeydown="javascript: return event.keyCode !== 69;"
                @input="foreignAmount = convertNumberFullToHalf(foreignAmount)"
                maxlength="14"
                :disabled="isOperatorOrAdmin || disableEdit"
                @change="convertData(foreignAmount, 3)"
            />
          </div>
          <p role="alert" class="error-feedback" v-text="errors.foreign_amount" />
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group viewonly">
          <label>
            <span class="label-text">{{ $t('trip_item.right_menu.manual_input_model.amount_of_money') }}</span>
          </label>
          <div class="input-group">
            <span class="input-group-addon">
              <span class="">{{ $t('trip.yen') }}</span>
            </span>
            <input :value="foreignAmountTotal" disabled class="viewonly-text input-sm text-right" />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <label>
            <span class="label-text">{{ $t('trip_item.right_menu.manual_input_model.method_of_payment') }}</span>
          </label>
          <el-select
              class="select-block select-sm js-select_item"
              popper-class="custom-dropdown-select"
              v-model="paymentMethodSelected"
              @change="onChangePaymentMethod"
              size="large"
              :disabled="isOperatorOrAdmin || disableEdit"
          >
            <el-option v-for="item in listPaymentMethod" :key="item.id" :value="labelLocale(item)">
              {{ this.labelLocale(item) }}
            </el-option>
          </el-select>
          <p role="alert" class="error-feedback" v-text="errors.payment_method" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label>
            <span class="label-text">{{ $t('trip_item.right_menu.manual_input_model.expense_code') }}</span>
          </label>
          <input
              type="text"
              class="form-control input-sm max-textarea"
              :value="costDetail.expense_code"
              @input="costDetail.expense_code = $event.target.value.toUpperCase()"
              maxlength="15"
              :disabled="isOperatorOrAdmin || disableEdit"
          />
          <p role="alert" class="error-feedback" v-text="errors.expense_code" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label>
            <span class="label-text">{{ $t('trip_item.right_menu.manual_input_model.remarks') }}</span>
          </label>
          <textarea
              type="text"
              class="form-control input-sm max-textarea"
              style="height: auto !important"
              :placeholder="$t('trip_item.right_menu.manual_input_model.cost_item_placeholder')"
              rows="3"
              v-model="costDetail.note"
              maxlength="1000"
              :disabled="isOperatorOrAdmin || disableEdit"
          />
          <p role="alert" class="error-feedback" v-text="errors.note" />
        </div>
      </div>
    </div>
    <div class="row" v-show="!costDetail.is_insurance_cost">
      <div class="col-sm-12">
        <div class="form-group">
          <label>
            <span class="label-text">{{ $t('trip_item.right_menu.manual_input_model.linking_appointments') }}</span>
          </label>
          <MultiGroupOptionSelectSchedule
              :list-data="listSchedule"
              @handle-selected-params="listenUrlSelected"
              :default-list="costDetail.schedule_ids"
              :disabled="isOperatorOrAdmin || disableEdit"
          />
          <p role="alert" class="error-feedback" v-text="errors.url" />
        </div>
      </div>
    </div>
    <div v-if="!disableEdit || (reportStatusId === 6 && !costDetail.is_sb && costDetail.flag_temporary_cost)" class="row btn-row" style="display: flex; justify-content: center">
      <div class="col-sm-6" v-if="tripStatus !== 3 && reportStatusId !== 6">
        <button :disabled="isDisable" class="btn btn-sm btn-block btn-inverse-primary js-btn--btn--close-detail" @click="checkValidate(false, true)">
          {{ $t('settings.temporary_expense') }}
        </button>
      </div>
      <div class="col-sm-6" v-if="tripStatus != 3 || !costDetail.flag_temporary_cost">
        <button :disabled="isDisable && tripStatus !== 3" class="btn btn-sm btn-block btn-primary js-btn--btn--close-detail" @click="checkValidate(false)">
          {{ $t('settings.keep') }}
        </button>
      </div>
      <div class="col-sm-6" v-else>
        <button :disabled="isDisable && tripStatus !== 3" class="btn btn-sm btn-block btn-primary js-btn--btn--close-detail" @click="checkValidate(false, false, true)">
          {{ $t('settings.keep') }}
        </button>
      </div>
    </div>
    <div v-if="!isCreateMode && !disableEdit && !isHiddenBtn" class="btn-row" style="display: flex; justify-content: space-between">
      <button class="btn btn-sm btn-flat-default" @click="showBoxDelete = true">
        <i class="icon-abui-trash prx" />{{ $t('trip_item.right_menu.manual_input_model.remove_costs') }}
      </button>
      <button :disabled="isDisable" class="btn btn-sm btn-flat-default" @click="checkValidate(true)">
        <i class="icon-abui-copy prx" />{{ $t('trip_item.right_menu.manual_input_model.duplicate_costs') }}
      </button>
    </div>
    <ModalConfirm
        :dialogVisible="showBoxDelete"
        @cancel="showBoxDelete = false"
        @yes="deleteCost"
        :title="$t('Q-CM-104')"
    />
    <ModalConfirm
        :dialogVisible="showBoxConfimSb"
        @cancel="showBoxConfimSb = false"
        @yes="checkValidate(false)"
        :title="$t('Q-CM-106')"
    />
    <ModalUploadFile
        :dialogVisible="showModalUploadFile"
        :isSingleUpload="true"
        :acceptImageAndPDFOnly="true"
        accepted-files="image/jpg, image/jpeg, image/png, .heif, application/pdf"
        @cancel="showModalUploadFile = false"
        @confirm="confirmUploadFile"
        :title="$t('trip_item.right_menu.upload_receipt_or_card_or_invoice')"
    />
  </div>
</template>
<script>
import MultiGroupOptionSelectSchedule from '@/components/common/MultiGroupOptionSelectSchedule.vue';
import SingleGroupOptionSelect from '@/components/common/SingleGroupOptionSelect.vue';
import ModalConfirm from '@/components/common/ModalConfirm';
import { JPY } from '@/constants/';
import ModalUploadFile from '@/components/common/ModalUploadFile';
import moment from 'moment';

export default {
  name: 'ManualInput',
  emits: ['close', 'openModalUploadFile', 'onChangeData'],
  components: { MultiGroupOptionSelectSchedule, ModalConfirm, ModalUploadFile, SingleGroupOptionSelect },
  props: {
    tripId: {
      type: [Number, String],
      default: null,
    },
    isCreateMode: {
      type: Boolean,
      default: true,
    },
    costId: {
      type: Number,
      default: null,
    },
    order: {
      type: [Number, String],
      default: null,
    },
    checkDisableEdit: {
      type: Boolean,
      default: false,
    },
    tripTime: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    'costDetail.flag_temporary_cost'(val) {
      console.log(val)
      if ((this.tripStatus === 3 || this.reportStatusId === 6) && !this.costDetail.is_sb) {
        if (this.checkEmpty(this.costDetail.payment_date)) {
          this.errors.payment_date = this.$t('E-CM-100', {
            item: this.$t('trip_item.right_menu.manual_input_model.payment_date'),
          });
        } else {
          this.errors.payment_date = '';
        }

        if (
            this.isUploadInvoice &&
            this.checkEmpty(this.costDetail.files) &&
            this.checkEmpty(this.costDetail.input_files) &&
            this.checkEmpty(this.listFileSelected)
        ) {
          this.errors.files = this.$t('E-CM-100', {
            item: this.$t('trip_item.right_menu.manual_input_model.receipt_card_statement_invoice'),
          });
        } else {
          this.errors.files = '';
        }
      }
    },
    checkDisableEdit(val) {
      this.disableEdit = val;
    },
    isCreateMode() {
      this.disableEdit = this.checkDisableEdit;
    },
    costDuplicateId(val) {
      if (!this.checkEmpty(val)) {
        this.getCostDetail(val);
      }
    },
    tripTime(val) {
      this.tripStartTime = val.startTime;
      this.tripEndTime = val.endTime;
      this.checkCurrencyRate();
    },
    tripStartTime() {
      this.checkCurrencyRate();
    },
    tripEndTime() {
      this.checkCurrencyRate();
    },
    isDataChanged(val) {
      this.$emit('onChangeData', val);
    },
  },
  computed: {
    isChangeEki() {
      return this.currentAmount != this.splitThousandNumber(this.costDetail.eki_amount) &&
          !this.checkEmpty(this.costDetail.eki_amount);
    },
    isDataChanged() {
      let flag = false;

      if (this.isCreateMode) {
        let cr = this.currencyList.find(cr => cr.code == JPY);
        //Find default task 10%
        let taxSelectedName = this.labelLocale(this.listTaxClassification.find(item => item.id == 2));
        if (
            this.currencySelected !== this.labelLocale(cr) ||
            this.taxClassificationSelected !== taxSelectedName ||
            !this.checkEmpty(this.foreignAmount) ||
            this.paymentMethodSelected !== this.labelLocale(this.listPaymentMethod[0]) ||
            !this.checkEmpty(this.listFileSelected)
        ) {
          flag = true;
        }
      } else {
        let crs = this.currencyList.find(currency => this.labelLocale(currency) === this.currencySelected);
        let taxClassification = this.listTaxClassification.find(
            tax => this.labelLocale(tax) === this.taxClassificationSelected,
        );
        if (
            (!this.checkEmpty(crs) && crs.id != this.costDetailOfficial.currency_id) ||
            this.foreignAmount !== String(this.splitThousandNumber(this.costDetailOfficial.amount)) ||
            this.currentPaymentMethod.id !== this.costDetailOfficial.payment_method ||
            (!this.checkEmpty(this.listFileSelected) &&
                !this.compareArray(this.listFileSelected, this.costDetailOfficial.files)) ||
            (!this.checkEmpty(taxClassification) && taxClassification.id != this.costDetailOfficial.tax_type) ||
            this.costDetail.currency_rate != this.costDetailOfficial.currency_rate
        ) {
          flag = true;
        }
      }
      return flag;
    },
    defaultCompanyCostItem() {
      if (this.checkEmpty(this.costDetail.company_cost_item)) return '';
      if (this.costDetail.company_cost_item?.is_valid === 1) return this.costDetail.cost_item;
      return '';
    },
    showUploadedCostInvoice() {
      return this.checkEmpty(this.listFileSelected);
    },
    currencyCode() {
      let crs = this.currencyList.find(currency => this.labelLocale(currency) == this.currencySelected);
      return !this.checkEmpty(crs) ? crs.signal : JPY;
    },
    foreignAmountTotal() {
      let fa = !this.checkEmpty(this.foreignAmount)
          ? this.foreignAmount.length > 3
              ? this.foreignAmount.replaceAll(',', '')
              : !this.checkEmpty(this.foreignAmount) && this.foreignAmount != 'e' && this.foreignAmount != 'ê'
                  ? this.foreignAmount
                  : 0
          : 0;
      let rate =
          !this.checkEmpty(this.onChangeRate) && this.onChangeRate != 'e' && this.onChangeRate != 'ê'
              ? this.onChangeRate.length > 3
                  ? this.onChangeRate.replaceAll(',', '')
                  : this.onChangeRate
              : 0;

      const total = (fa * rate).toFixed(0);

      return String(total).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    },
    isUploadInvoice() {
      return this.$store.state.auth?.user?.setting.is_upload_invoice == 1 || false;
    },
    applicableDate() {
      return this.$store.state.auth?.user?.setting.applicable_date;
    },
    isTransportType() {
      return this.costDetail.type_cost == 3;
    },
    isHiddenBtn() {
      return this.costDetail.type_cost == 3 && (this.checkEmpty(this.paymentMethodSelectedInit) || this.checkEmpty(this.currentCostTypeInit));
    },
  },
  mounted() {
    this.$emitter.on('reloadTripTime', time => {
      this.tripStartTime = time.startTime;
      this.tripEndTime = time.endTime;
    });
  },
  beforeUnmount() {
    this.$emitter.off('reloadTripTime');
  },
  created() {
    this.disableEdit = this.checkDisableEdit;
    this.isFirstLoad = true;
    this.startLoading();
    Promise.all([
      this.getCurrencyList(),
      this.getCostItemList(),
      this.getScheduleByTripList(),
      this.getPaymentMethodList(),
      this.getTaxClassificationList(),
      this.getTripDetail(),
      this.getAllCurrencyList(),
    ]).then(() => {
      this.endLoading();
      this.isFirstLoad = false;
    });
  },
  data() {
    return {
      tripStatus: null,
      reportStatusId: null,
      disableEdit: false,
      showBoxConfimSb: false,
      currencyList: [],
      listSchedule: [],
      listCostItem: [],
      listPaymentMethod: [],
      listTaxClassification: [],
      costDetail: {},
      costDetailOfficial: {},
      errors: {
        payable: '',
        title: '',
        payment_to: '',
        files: '',
        currency: '',
        amount: '',
        tax_type: '',
        tax_amount: '',
        payment_method: '',
        cost_type: '',
        note: '',
        url: '',
        payment_date: '',
        is_insurance_cost: false,
        foreign_amount: '',
        change_rate: '',
        expense_code: '',
      },
      currencySelected: '',
      paymentMethodSelected: '',
      paymentMethodSelectedInit: '',
      taxClassificationSelected: '',
      costTypeSelected: '',
      showBoxDelete: false,
      isCurrencyJapan: true,
      urlSelected: [],
      currentAmount: '',
      currentTaxAmount: '',
      currentRate: '',
      onChangeRate: '',
      currentPaymentMethod: {},
      currentTaxClassification: {},
      currentCostType: {},
      currentCostTypeInit: {},
      foreignAmount: '',
      showModalUploadFile: false,
      listFileSelected: [],
      isDisableRate: true,
      isDuplicate: false,
      isDisable: false,
      costDuplicateId: null,
      tripStartTime: '',
      tripEndTime: '',
      isFirstLoad: true,
      countChangeCostItem: 0,
      allCurrencyList: [],
    };
  },
  methods: {
    //CU-050
    async getAllCurrencyList() {
      await this.$services.common.getCurrencyList(
          {},
          res => {
            this.allCurrencyList = res.list;
          },
          () => {
            this.notifyError(this.$t('E-CM-500'));
          },
      );
    },
    reloadData() {
      this.getCostDetail();
      this.getScheduleByTripList();
    },
    convertNumberFullToHalf(number) {
      number = number.replace(/[^0-9０-９.．]/g, '');
      number = String(number).replace(/[\uFF10-\uFF19]/g, function (m) {
        return String.fromCharCode(m.charCodeAt(0) - 0xfee0);
      });

      return number;
    },
    checkOnChangeData(flag = true) {
      this.$emit('onChangeData', flag);
    },
    convertData(data, type) {
      switch (type) {
        case 1:
          if (String(data).includes('.') || String(data).includes('．')) {
            if (String(data).includes('．')) {
              data = data.replaceAll('．', '.');
            }
            this.currentAmount = String(parseFloat(data).toFixed(2))
                .replace(/^(?!00[^0])0/, '')
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

            let amount = this.currentAmount.length > 3 ? this.currentAmount.replaceAll(',', '') : this.currentAmount;
            let result = !this.checkEmpty(this.currentTaxClassification)
                ? Number(amount - amount / (1 + this.currentTaxClassification.percent))
                : '';
            this.currentTaxAmount =
                this.isNumeric(result) && !this.checkEmpty(result)
                    ? String(result.toFixed(0)).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                    : '';
          } else {
            data = data.replaceAll(',', '');
            this.currentAmount = data
                .replace(/(\..*?)\..*/g, '$1')
                .replace(/^(?!00[^0])0/, '')
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

            let amount = this.currentAmount.length > 3 ? this.currentAmount.replaceAll(',', '') : this.currentAmount;
            let result = !this.checkEmpty(this.currentTaxClassification)
                ? Number(amount - amount / (1 + this.currentTaxClassification.percent))
                : '';
            this.currentTaxAmount =
                this.isNumeric(result) && !this.checkEmpty(result)
                    ? String(result.toFixed(0)).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                    : '';
          }

          break;
        case 2:
          if (String(data).includes('.') || String(data).includes('．')) {
            if (String(data).includes('．')) {
              data = data.replaceAll('．', '.');
            }
            this.onChangeRate = String(parseFloat(data).toFixed(2))
                .replace(/^(?!00[^0])0/, '')
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          } else {
            data = data.replaceAll(',', '');
            this.onChangeRate = data
                .replace(/(\..*?)\..*/g, '$1')
                .replace(/^(?!00[^0])0/, '')
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          }
          break;
        case 3:
          if (String(data).includes('.') || String(data).includes('．')) {
            if (String(data).includes('．')) {
              data = data.replaceAll('．', '.');
            }
            this.foreignAmount = String(parseFloat(data).toFixed(2))
                .replace(/^(?!00[^0])0/, '')
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          } else {
            data = data.replaceAll(',', '');
            this.foreignAmount = data
                .replace(/(\..*?)\..*/g, '$1')
                .replace(/^(?!00[^0])0/, '')
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          }
          break;
        default:
          if (String(data).includes('.') || String(data).includes('．')) {
            if (String(data).includes('．')) {
              data = data.replaceAll('．', '.');
            }
            this.currentTaxAmount = String(parseFloat(data).toFixed(0))
                .replace(/^(?!00[^0])0/, '')
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          } else {
            data = data.replaceAll(',', '');
            this.currentTaxAmount = data
                .replace(/(\..*?)\..*/g, '$1')
                .replace(/^(?!00[^0])0/, '')
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          }
          break;
      }
    },
    async getTripDetail() {
      if (this.isOperatorOrAdmin) {
        await this.$services.trip.tripDetailOP(
            this.tripId,
            res => {
              this.tripStartTime = res.start_time;
              this.tripEndTime = res.end_time;
              this.tripStatus = res.trip_status;
              this.reportStatusId = res.report_status_id;
            },
            () => {
              this.notifyError(this.$t('E-CM-500'));
            },
        );
      } else {
        await this.$services.trip.tripDetail(
            this.tripId,
            res => {
              this.tripStartTime = res.start_time;
              this.tripEndTime = res.end_time;
              this.tripStatus = res.trip_status;
              this.reportStatusId = res.report_status_id;
            },
            () => {
              this.notifyError(this.$t('E-CM-500'));
            },
        );
      }
    },
    async getCurrencyList() {
      await this.$services.common.getCurrencyList(
          { is_cost: true },
          res => {
            this.currencyList = res.list;
            this.isDisableRate = !res.is_exchange_rate_overwrite;
            if (this.isCreateMode) {
              let cr = this.currencyList.find(cr => cr.code == JPY);
              this.currencySelected = this.labelLocale(cr);
            } else {
              this.getCostDetail(this.costId);
            }
          },
          () => {
            this.notifyError(this.$t('E-CM-500'));
          },
      );
    },
    async getCostItemList() {
      await this.$services.trip.getCostItemList(
          res => {
            this.listCostItem = res.list;
          },
          () => {
            this.notifyError(this.$t('E-CM-500'));
          },
      );
    },
    async getScheduleByTripList() {
      if (this.checkEmpty(this.tripId)) return;
      await this.$services.trip.getScheduleByTripList(
          { trip_id: this.tripId },
          res => {
            this.listSchedule = res.list;
          },
          () => {
            this.notifyError(this.$t('E-CM-500'));
          },
      );
    },
    async getPaymentMethodList() {
      await this.$services.common.getPaymentMethodList(
          res => {
            this.listPaymentMethod = res.list;
            if (this.isCreateMode) {
              this.paymentMethodSelected = this.labelLocale(this.listPaymentMethod[0]);
              let paymentMethod = this.listPaymentMethod.find(
                  method => this.labelLocale(method) == this.paymentMethodSelected,
              );
              this.currentPaymentMethod = paymentMethod;
            }
          },
          () => {
            this.notifyError(this.$t('E-CM-500'));
          },
      );
    },
    async getTaxClassificationList() {
      await this.$services.common.getTaxClassificationList(
          res => {
            this.listTaxClassification = res.list;
            if (!this.checkEmpty(res.list) && res.list.length > 1 && this.isCreateMode) {
              this.currentTaxClassification = res.list[1];
              this.taxClassificationSelected = this.labelLocale(res.list[1]);
            }
          },
          () => {
            this.notifyError(this.$t('E-CM-500'));
          },
      );
    },
    async getCostDetail(costId = this.costId) {
      if (this.checkEmpty(costId)) return;

      await this.$services.trip.getCostDetail(
          costId,
          res => {
            this.costDetail = res;
            if (res.company_cost_item?.is_valid === 0) this.notifyError(this.$t('trip.cost_is_deactivated'));
            this.costDetailOfficial = this.cloneObject(res);
            let crs = this.currencyList.find(cr => cr.id == res.currency_id);
            if (this.checkEmpty(crs)) {
              let currencyData = this.allCurrencyList.find(cr => cr.id == res.currency_id);

              currencyData.exchange_rates = [
                {
                  applying_rate: res.currency_rate,
                  change_rate: res.currency_rate,
                },
              ];
              this.currencyList.push(currencyData);
            }
            crs = this.currencyList.find(cr => cr.id == res.currency_id);
            if (!this.checkEmpty(crs)) {
              this.currencySelected = this.labelLocale(crs);
              this.isCurrencyJapan = crs.code == JPY;
            }
            let paymentMethod = this.listPaymentMethod.find(method => method.id == res.payment_method);
            if (!this.checkEmpty(paymentMethod)) {
              this.currentPaymentMethod = paymentMethod;
              this.paymentMethodSelected = this.labelLocale(paymentMethod);
              this.paymentMethodSelectedInit = this.labelLocale(paymentMethod);
            }
            let taxClassification = this.listTaxClassification.find(tax => tax.id == res.tax_type);
            if (!this.checkEmpty(taxClassification)) {
              this.currentTaxClassification = taxClassification;
              this.taxClassificationSelected = this.labelLocale(taxClassification);
            }
            this.currentAmount = !this.checkEmpty(res.amount)
                ? String(res.amount).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                : '';
            this.currentTaxAmount = !this.checkEmpty(res.tax_amount)
                ? String(res.tax_amount).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                : '';
            this.listCostItem.forEach(cost => {
              cost.data.forEach(item => {
                if (item.id == res.cost_item && res.company_cost_item?.is_valid === 1) {
                  this.currentCostType = item;
                  this.currentCostTypeInit = item;
                  this.costTypeSelected = item;
                }
              });
            });
            this.currentRate = res.currency_rate;
            this.onChangeRate = res.currency_rate;
            this.foreignAmount = this.splitThousandNumber(res.currency_amount);
          },
          () => {
            this.notifyError(this.$t('E-CM-500'));
          },
      );
    },
    checkCurrencyRate() {
      if (this.checkEmpty(this.currencySelected) || this.isFirstLoad) return;
      let crs = this.currencyList.find(currency => this.labelLocale(currency) == this.currencySelected);
      this.isCurrencyJapan = crs.code == 'JPY';
      this.errors.amount = '';
      this.errors.tax_amount = '';
      this.errors.foreign_amount = '';
      this.errors.currency = '';
      let date = '';
      switch (this.applicableDate) {
        case 1:
          date = this.tripStartTime;
          break;
        case 2:
          date = this.tripEndTime;
          break;
        default:
          date = !this.checkEmpty(this.costDetail.payment_date) ? this.costDetail.payment_date : new Date();
          break;
      }
      let listExchangeRate = this.sortExchangeRate(this.cloneObject(crs.exchange_rates));
      let latestDate = listExchangeRate.find(
          item =>
              moment(new Date(item.change_reservation_date)).format('YYYY-MM-DD') <=
              moment(new Date(date)).format('YYYY-MM-DD'),
      );
      switch (listExchangeRate.length) {
        case 0:
          this.currentRate = crs.rate;
          this.onChangeRate = crs.rate;
          break;
        case 1:
          if (
              moment(new Date(listExchangeRate[0].change_reservation_date)).format('YYYY-MM-DD') <=
              moment(new Date(date)).format('YYYY-MM-DD')
          ) {
            this.currentRate = listExchangeRate[0].change_rate;
            this.onChangeRate = listExchangeRate[0].change_rate;
          } else {
            this.currentRate = listExchangeRate[0].applying_rate;
            this.onChangeRate = listExchangeRate[0].applying_rate;
          }
          break;
        default:
          if (!this.checkEmpty(latestDate)) {
            this.currentRate = latestDate.change_rate;
            this.onChangeRate = latestDate.change_rate;
          } else {
            this.currentRate = listExchangeRate[listExchangeRate.length - 1].applying_rate;
            this.onChangeRate = listExchangeRate[listExchangeRate.length - 1].applying_rate;
          }
          break;
      }
    },
    sortExchangeRate(listExchangeRate) {
      listExchangeRate.sort((a, b) => {
        if (
            moment(new Date(a.change_reservation_date)).format('YYYY-MM-DD') >
            moment(new Date(b.change_reservation_date)).format('YYYY-MM-DD')
        )
          return -1;
        if (
            moment(new Date(a.change_reservation_date)).format('YYYY-MM-DD') <
            moment(new Date(b.change_reservation_date)).format('YYYY-MM-DD')
        )
          return 1;
        return 0;
      });

      return listExchangeRate;
    },
    onChangePaymentMethod() {
      this.errors.payment_method = '';
      let paymentMethod = this.listPaymentMethod.find(method => this.labelLocale(method) == this.paymentMethodSelected);
      this.currentPaymentMethod = paymentMethod;
    },
    onChangeTaxClassification() {
      let taxClassification = this.listTaxClassification.find(
          tax => this.labelLocale(tax) == this.taxClassificationSelected,
      );
      this.currentTaxClassification = taxClassification;
      let amount = this.currentAmount.length > 3 ? this.currentAmount.replaceAll(',', '') : this.currentAmount;
      let result = !this.checkEmpty(this.currentTaxClassification)
          ? Number(amount - amount / (1 + this.currentTaxClassification.percent))
          : '';
      this.currentTaxAmount =
          this.isNumeric(result) && !this.checkEmpty(result)
              ? String(result.toFixed(0)).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
              : '';
    },
    onChangeCostType(id) {
      if (id != this.currentCostType.id) {
        this.countChangeCostItem += 1;
      }
      if (this.countChangeCostItem > 0 || this.isCreateMode || this.checkEmpty(this.currentCostType)) {
        this.errors.cost_type = '';
        this.listCostItem.forEach(cost => {
          cost.data.forEach(item => {
            if (item.id == id) {
              let taxClassification = this.listTaxClassification.find(tax => item.tax_type == tax.id);
              if (taxClassification) {
                this.taxClassificationSelected = this.labelLocale(taxClassification);
                this.currentTaxClassification = taxClassification;
              }
              this.currentCostType = item;
              this.costTypeSelected = item;
            }
          });
        });

        let amount = this.currentAmount.length > 3 ? this.currentAmount.replaceAll(',', '') : this.currentAmount;
        let result = !this.checkEmpty(this.currentTaxClassification)
            ? Number(amount - amount / (1 + this.currentTaxClassification.percent))
            : '';
        this.currentTaxAmount =
            this.isNumeric(result) && !this.checkEmpty(result)
                ? String(result.toFixed(0)).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                : '';
      }
    },
    listenUrlSelected(listUrl) {
      this.errors.url = '';
      this.costDetail.schedule_ids = listUrl;
    },
    checkValidate(isDuplicate, isTemporaryCost = false, showModal = false) {
      let flgValidate = true;
        if (this.checkEmpty(this.costDetail.title)) {
          flgValidate = false;
          this.errors.title = this.$t('E-CM-006');
        } else {
          if (this.strLen(this.costDetail.title) > 255) {
            flgValidate = false;
            this.errors.title = this.$t('E-CM-111', {size: 255});
          } else {
            this.errors.title = '';
          }
        }

        if (this.checkEmpty(this.costDetail.payment_date) && (!this.costDetail.is_sb || this.tripStatus == 3) && !this.costDetail.is_sb) {
          flgValidate = false;
          this.errors.payment_date = this.$t('E-CM-100', {
            item: this.$t('trip_item.right_menu.manual_input_model.payment_date'),
          });
        } else {
          this.errors.payment_date = '';
        }

        if (!this.checkEmpty(this.costDetail.payment_to)) {
          if (this.strLen(this.costDetail.payment_to) > 255) {
            flgValidate = false;
            this.errors.payment_to = this.$t('E-CM-111', {size: 255});
          } else {
            this.errors.payment_to = '';
          }
        } else {
          flgValidate = false;
          this.errors.payment_to = this.$t('E-CM-100', {
            item: this.$t('trip_item.right_menu.manual_input_model.payable_to'),
          });
        }

        if (this.isCurrencyJapan) {
          if (!this.checkEmpty(this.currentAmount)) {
            // Number.parseFloat(result).toFixed(2);
            const amount = this.currentAmount.length > 3 ? this.currentAmount.replaceAll(',', '') : this.currentAmount;
            if (!this.isNumeric(amount)) {
              flgValidate = false;
              this.errors.amount = this.$t('E-CM-108', {
                item: this.$t('trip_item.right_menu.manual_input_model.amount_of_money'),
              });
            } else {
              if (this.strLen(amount) > 11) {
                flgValidate = false;
                this.errors.amount = this.$t('E-CM-111', {size: 11});
              } else {
                this.errors.amount = '';
              }
            }
          } else {
            flgValidate = false;
            this.errors.amount = this.$t('E-CM-100', {
              item: this.$t('trip_item.right_menu.manual_input_model.amount_of_money'),
            });
          }
          if (!this.checkEmpty(this.currentTaxClassification.percent)) {
            if (!this.checkEmpty(this.currentTaxAmount)) {
              // Number.parseFloat(result).toFixed(2);
              const taxAmount =
                  this.currentTaxAmount.length > 3 ? this.currentTaxAmount.replaceAll(',', '') : this.currentTaxAmount;
              if (!this.isNumeric(taxAmount)) {
                flgValidate = false;
                this.errors.tax_amount = this.$t('E-CM-108', {
                  item: this.$t('trip_item.right_menu.manual_input_model.tax_amount'),
                });
              } else {
                if (this.strLen(taxAmount) > 11) {
                  flgValidate = false;
                  this.errors.tax_amount = this.$t('E-CM-111', {size: 11});
                } else {
                  this.errors.tax_amount = '';
                }
              }
            } else {
              flgValidate = false;
              this.errors.tax_amount = this.$t('E-CM-100', {
                item: this.$t('trip_item.right_menu.manual_input_model.tax_amount'),
              });
            }
          }
        } else {
          if (!this.checkEmpty(this.foreignAmount)) {
            const amount = this.foreignAmount.length > 3 ? this.foreignAmount.replaceAll(',', '') : this.foreignAmount;
            if (!this.isNumeric(amount)) {
              flgValidate = false;
              this.errors.foreign_amount = this.$t('E-CM-108', {
                item: this.$t('trip_item.right_menu.manual_input_model.foreign_currency_denominated_amount'),
              });
            } else {
              if (this.strLen(amount) > 11) {
                flgValidate = false;
                this.errors.foreign_amount = this.$t('E-CM-111', {size: 11});
              } else {
                this.errors.foreign_amount = '';
              }
            }
          } else {
            flgValidate = false;
            this.errors.foreign_amount = this.$t('E-CM-100', {
              item: this.$t('trip_item.right_menu.manual_input_model.foreign_currency_denominated_amount'),
            });
          }

          if (!this.isDisableRate) {
            if (this.checkEmpty(this.onChangeRate)) {
              flgValidate = false;
              this.errors.change_rate = this.$t('E-CM-100', {
                item: this.$t('trip_item.right_menu.manual_input_model.rate'),
              });
            } else {
              if (!this.isNumeric(this.onChangeRate)) {
                flgValidate = false;
                this.errors.change_rate = this.$t('E-CM-108', {
                  item: this.$t('trip_item.right_menu.manual_input_model.rate'),
                });
              } else {
                if (this.strLen(this.onChangeRate) > 11) {
                  flgValidate = false;
                  this.errors.change_rate = this.$t('E-CM-111', {size: 11});
                } else {
                  this.errors.change_rate = '';
                }
              }
            }
          }
        }

        if (!this.checkEmpty(this.costDetail.note)) {
          if (this.strLen(this.costDetail.note) > 1000) {
            flgValidate = false;
            this.errors.note = this.$t('E-CM-111', {size: 1000});
          } else {
            this.errors.note = '';
          }
        }

        if (this.checkEmpty(this.currencySelected)) {
          flgValidate = false;
          this.errors.currency = this.$t('E-CU-017');
        } else {
          this.errors.currency = '';
        }

        if (this.checkEmpty(this.paymentMethodSelected)) {
          flgValidate = false;
          this.errors.payment_method = this.$t('E-CU-017');
        } else {
          this.errors.payment_method = '';
        }

        if (this.checkEmpty(this.currentCostType)) {
          flgValidate = false;
          this.errors.cost_type = this.$t('E-CU-017');
        } else {
          this.errors.cost_type = '';
        }

        if (this.isCreateMode) {
          if (this.isUploadInvoice && this.checkEmpty(this.listFileSelected) && (!this.costDetail.is_sb || this.tripStatus == 3)) {
            flgValidate = false;
            this.errors.files = this.$t('E-CM-100', {
              item: this.$t('trip_item.right_menu.manual_input_model.receipt_card_statement_invoice'),
            });
          } else {
            this.errors.files = '';
          }
        } else {
          if (
              this.isUploadInvoice &&
              this.checkEmpty(this.costDetail.files) &&
              this.checkEmpty(this.costDetail.input_files) &&
              this.checkEmpty(this.listFileSelected) &&
              !this.isTransportType && (!this.costDetail.is_sb || this.tripStatus == 3)
          ) {
            flgValidate = false;
            this.errors.files = this.$t('E-CM-100', {
              item: this.$t('trip_item.right_menu.manual_input_model.receipt_card_statement_invoice'),
            });
          } else {
            this.errors.files = '';
          }
        }
      if (!flgValidate) return;
      if (showModal) {
        this.showBoxConfimSb = true;
        return;
      }
      this.isDisable = true;
      this.setupData(isDuplicate, isTemporaryCost);
    },
    setupData(isDuplicate, isTemporaryCost = false) {
      let currency = this.currencyList.find(cr => this.labelLocale(cr) == this.currencySelected);
      let costItem = {};
      this.listCostItem.forEach(cost => {
        cost.data.forEach(item => {
          if (item.id == this.currentCostType.id) {
            costItem = item;
          }
        });
      });
      let formData = new FormData();
      if (isTemporaryCost) {
        formData.append('flag_temporary_cost', 1);
      }
      if (!this.checkEmpty(this.listFileSelected)) {
        for (let index = 0; index < this.listFileSelected.length; index++) {
          formData.append('files[]', this.listFileSelected[index]);
        }
      }
      if (this.costDetail.is_insurance_cost) {
        this.costDetail.schedule_ids = [];
      } else if (!this.checkEmpty(this.costDetail.schedule_ids)) {
        for (let index = 0; index < this.costDetail.schedule_ids.length; index++) {
          formData.append('schedule_ids[]', this.costDetail.schedule_ids[index]);
        }
      }
      let amount = 0;
      let fgAmount = 0;
      if (this.isCurrencyJapan) {
        amount = this.currentAmount.length > 3 ? this.currentAmount.replaceAll(',', '') : this.currentAmount;
        let currentTA =
            this.currentTaxAmount.length > 3 ? this.currentTaxAmount.replaceAll(',', '') : this.currentTaxAmount;
        formData.append('tax_amount', currentTA);
        formData.append('tax_type', this.currentTaxClassification.id);
      } else {
        amount =
            this.foreignAmountTotal.length > 3 ? this.foreignAmountTotal.replaceAll(',', '') : this.foreignAmountTotal;
        fgAmount = this.foreignAmount.length > 3 ? this.foreignAmount.replaceAll(',', '') : this.foreignAmount;
        formData.append('currency_amount', fgAmount);
        formData.append('currency_rate', this.onChangeRate);
      }
      formData.append('title', this.costDetail.title);
      formData.append('payment_to', !this.checkEmpty(this.costDetail.payment_to) ? this.costDetail.payment_to : '');
      formData.append('currency_id', currency.id);
      formData.append('amount', amount);
      formData.append(
          'payment_method',
          !this.checkEmpty(this.currentPaymentMethod.id) ? this.currentPaymentMethod.id : '',
      );
      formData.append('cost_item', costItem.id);
      formData.append('note', !this.checkEmpty(this.costDetail.note) ? this.costDetail.note : '');
      formData.append(
          'payment_date',
          !this.checkEmpty(this.costDetail.payment_date) ? this.costDetail.payment_date : '',
      );
      formData.append('trip_id', this.tripId);
      if (!this.checkEmpty(this.costDetail.is_insurance_cost)) {
        formData.append('is_insurance_cost', Number(this.costDetail.is_insurance_cost));
      }
      if (!this.checkEmpty(this.order)) {
        formData.append('order', this.order);
      }
      if (!this.checkEmpty(this.costDetail.expense_code)) {
        formData.append('expense_code', this.costDetail.expense_code);
      }

      if (this.isCreateMode) {
        this.createCost(formData);
      } else if (isDuplicate) {
        this.duplicateCost();
      } else {
        formData.append('updated_at', this.costDetail.updated_at);
        this.updateCost(formData);
      }
    },
    createCost(formData) {
      this.$emit('onChangeData', false);
      this.startLoading();
      this.$services.trip.createCost(
          formData,
          () => {
            this.isDisable = false;
            this.$emit('close');
            this.endLoading();
            this.$emitter.emit('activeTodo', 0);
            this.$emit('onRelaodBtnShow');
          },
          () => {
            this.isDisable = false;
            this.endLoading();
            this.notifyError(this.$t('E-CM-500'));
          },
      );
    },
    updateCost(formData) {
      this.$emit('onChangeData', false);
      let id = !this.checkEmpty(this.costDuplicateId) ? this.costDuplicateId : this.costId;
      this.startLoading();
      this.$services.trip.updateCost(
          id,
          formData,
          () => {
            this.isDisable = false;
            this.endLoading();
            this.$emitter.emit('syncButtonCreateRequestVisible', 1);
            this.$emit('close');
            this.$emitter.emit('activeTodo', 0);
          },
          err => {
            this.isDisable = false;
            this.endLoading();
            this.notifyError(this.$t(err.error), true);
          },
      );
    },
    duplicateCost() {
      let formData = new FormData();
      formData.append('cost_id', this.costId);
      this.startLoading();
      this.$services.trip.duplicateCost(
          formData,
          res => {
            this.isDisable = false;
            this.costDuplicateId = res.id;
            this.notifySuccess(this.$t('trip_item.right_menu.manual_input_model.duplicate_cost_success'));
            this.$emit('close');
            this.endLoading();
            this.$emitter.emit('activeTodo', 0);
          },
          () => {
            this.isDisable = false;
            this.endLoading();
            this.notifyError(this.$t('E-CM-500'));
          },
      );
    },
    resetData() {
      return {
        currencyList: [],
        listSchedule: [],
        listCostItem: [],
        listPaymentMethod: [],
        listTaxClassification: [],
        costDetail: {},
        errors: {
          payable: '',
          title: '',
          payment_to: '',
          files: '',
          currency: '',
          amount: '',
          tax_type: '',
          tax_amount: '',
          payment_method: '',
          cost_type: '',
          note: '',
          url: '',
          payment_date: '',
          foreign_amount: '',
        },
        currencySelected: '',
        paymentMethodSelected: '',
        taxClassificationSelected: '',
        costTypeSelected: '',
        showBoxDelete: false,
        isCurrencyJapan: true,
        urlSelected: [],
        currentAmount: '',
        currentRate: '',
        onChangeRate: '',
        currentPaymentMethod: {},
        currentTaxClassification: {},
        currentCostType: {},
        foreignAmount: '',
        showModalUploadFile: false,
        listFileSelected: [],
      };
    },
    confirmUploadFile(files) {
      files.forEach(file => {
        let fileExits = this.listFileSelected.find(item => item.name == file.name);
        if (this.checkEmpty(fileExits)) {
          this.checkOnChangeData();
          this.listFileSelected = [];
          this.listFileSelected.push(file);
          this.errors.files = '';
        } else {
          this.notifyError(this.$t('common.invalid_file'));
        }
      });
      this.showModalUploadFile = false;
    },
    deleteFile(item) {
      this.checkOnChangeData();
      this.listFileSelected = this.listFileSelected.filter(file => file.name != item.name);
    },
    deleteCost() {
      this.$emit('onChangeData', false);
      this.startLoading();
      this.$services.trip_item.deleteCostItem(
          this.costId,
          () => {
            this.endLoading();
            this.notifySuccess(this.$t('I-OP-053'));
            this.showBoxDelete = false;
            this.$emit('close');
            this.$emitter.emit('activeTodo', 0);
          },
          () => {
            this.endLoading();
            this.showBoxDelete = false;
            this.notifyError(this.$t('E-CM-500'));
          },
      );
    },
  },
};
</script>
<style scoped>
.btn-file-upload {
  width: 100%;
  text-align: left;
  border: unset;
}
.btn-file-upload:hover {
  background-color: unset;
  color: white;
}
.btn-file-upload:focus {
  background-color: unset;
  color: white;
}
.btn-file-upload:active {
  background-color: unset;
  color: white;
}
.group-info-file-upload {
  display: flex;
  justify-content: space-between;
  border: 1px solid #657a84;
  margin: 4px 0;
  align-items: center;
}
.group-info-file-upload:hover {
  background-color: #f77581;
  color: white;
}
.icon-delete-file {
  padding-right: 10px;
  cursor: pointer;
}
.btn-save {
  color: white;
  background-color: #3650a5;
  width: 50%;
}
.btn-save:hover {
  color: white;
  background-color: #5e73b7;
  border-color: #5e73b7;
}
.feedback-overwrite {
  border-color: #65c15e;
  background-color: rgba(101, 193, 94, 0.1);
}
.feedback-overwrite:focus {
  border-color: #65c15e;
  background-color: rgba(101, 193, 94, 0.1);
}
.btn-inverse.disabled {
  background-color: #f0f2f5 !important;
  border-color: #f0f2f5 !important;
  color: #657a84 !important;
}
.transaction-cost {
  font-weight: bold;
  font-size: 12px;
}

.max-textarea {
  max-width: 100%;
  min-width: 100%;
}
.expense--detail--detected {
  margin-top: 0px !important;
}
.change-eki-cost {
  background-color: #8B0000;
  font-size: 10px;
  color: #fff;
  border-radius: 12px;
  padding: 2px 5px;
  margin-left: 5px;
}
.btn-used-in-request {
  padding: 5px 10px;
  border-radius: 23px;
  margin-right: 5px;
  background-color: #dcb823;
  color: #fff;
  display: block;
  float: left;
  font-size: 14px;
}

.display-block {
  display: block;
}
</style>
