import BaseService from './BaseService';
import API_CODE from '../utils/api_code.js';

export default class TripItemAPI extends BaseService {
  async getRequestDetail(params, success, error) {
    await this.get(API_CODE.CU_094 + params.id + '?request_type=' + params.type, success, error);
  }
  async getRequestHistories(params, success, error) {
    await this.get(API_CODE.CU_084 + params, success, error);
  }
  async getListCostsByDate(params, success, error) {
    await this.get(API_CODE.CU_009 + this.urlParse(params), success, error);
  }
  async getCostByGroup(id, success, error) {
    await this.get(API_CODE.CU_229 + '?trip_id=' + id, success, error);
  }
  async executeRequest(params, success, error) {
    await this.post(API_CODE.CU_098, params, success, error);
  }
  async getSettlementWithId(id, success, error) {
    await this.get(API_CODE.CU_103 + id, success, error);
  }
  async sendSettlementApplication(params, success, error) {
    await this.post(API_CODE.CU_104, params, success, error);
  }
  async deleteCostItem(id, success, error) {
    await this.delete(API_CODE.CU_046 + id, {}, success, error);
  }
  async updateCostOrder(params, success, error) {
    await this.post(API_CODE.CU_230, params, success, error);
  }
  async uploadCost(params, success, error) {
    await this.post(API_CODE.CU_040, params, success, error);
  }
}
