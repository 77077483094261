<template>
  <el-dialog
    v-model="dialogVisibleStatus"
    :show-close="false"
    @close="cancelUpload()"
    width="655px"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <div class="modal--upload-voucher">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <div class="modal-header--title">
              <span> {{ title }}</span>
            </div>
          </div>
          <div class="modal-body">
            <p class="modal--diaglog--text">
              {{ subTitle }}
            </p>
            <div class="uploaded" style="display: block">
              <ul class="trip--info--file-list" v-if="!checkEmpty(listInsurances)">
                <li class="uploaded-item" v-for="(item, index) in listInsurances" :key="index">
                  <a :href="item.file_path" target="_blank" rel="noopener">
                    <i class="icon-abui-file"></i>
                    <span class="file--name">{{ item.file_name }}</span>
                  </a>
                  <i
                    style="padding-right: 14px; color: brown; font-weight: bold"
                    class="icon-abui-cross pls cursor-pointer"
                    @click="handleRemoveInsurance(item.file_id)"
                  ></i>
                </li>
              </ul>
              <button v-if="!checkEmpty(this.insurance)" class="btn btn-sm btn-flat-default" @click="chooseFile()">
                <i class="icon-abui-plus prx"></i>{{ $t('trip.addition') }}
              </button>
            </div>
            <div v-show="showPreview" class="row">
              <div class="col-md-12">
                <input
                  type="file"
                  :multiple="!isSingleUpload"
                  name="file"
                  style="display: none"
                  @change="onFileChange"
                  ref="inputFile"
                  :accept="acceptedList"
                />
                <div
                  class="dropzone cursor-pointer"
                  @click="chooseFile()"
                  :data-active="false"
                  @dragenter.prevent="true"
                  @dragover.prevent="true"
                  @dragleave.prevent="false"
                  @drop.prevent="onDrop"
                >
                  <div v-if="listFile.length == 0" class="dz-default dz-message">
                    <span>
                      <span class="icon icon-abui-file"></span>
                      {{ $t('common.drop_file_here') }}
                      <small>{{ $t('common.click_to_specify_the_location') }}</small>
                    </span>
                  </div>
                  <div v-else>
                    <div v-for="(item, index) in listFile" :key="index" class="dz-preview dz-complete dz-image-preview">
                     <div class="dz-image" @click.stop>
                        <el-image v-if="item.preview" :src="item.preview" fit="fill" />
                        <span v-else class="icon icon-abui-file"></span>
                      </div>
                      <div class="dz-details" @click.stop style="opacity: 1">
                        <div class="dz-size">
                          <span data-dz-size="">
                            <strong>{{ getSize(item.size) }}</strong> MB
                          </span>
                        </div>
                        <div class="dz-filename">
                          <span data-dz-name="">{{ item.name }}</span>
                        </div>
                      </div>
                      <div class="dz-success-mark">
                        <img src="@/assets/images/dz-success-mark.svg" alt="" />
                      </div>
                      <a class="dz-remove" href="javascript:undefined;" @click.stop="removeFile(index)"></a>
                    </div>
                  </div>
                </div>
                <p role="alert" class="error-feedback" v-text="errors.file"></p>
              </div>
              <div class="col-md-12" v-if="!isModalInsurance">
                <label><span class="label-text">{{$t('trip.enter_content_with_text')}}</span></label>
                <textarea
                    class="form-control"
                    rows="10"
                    :placeholder="$t('trip.content_reservation')"
                    maxlength="10000"
                    style="resize: none;"
                    v-model="content_reservation"
                />
                <p role="alert" class="error-feedback" v-text="errors.content_reservation"></p>
              </div>
            </div>
            <!-- <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label>
                    <span class="label-text">{{ $t('new_trip.note') }}</span>
                  </label>
                  <el-input
                    v-if="!sbUpload"
                    v-model="note"
                    :rows="3"
                    type="textarea"
                    maxlength="50"
                    :placeholder="$t('new_trip.note_placeholder')"
                  />
                  <el-input v-else v-model="note" :rows="3" type="textarea" />
                </div>
              </div>
            </div> -->
          </div>
          <div class="modal-footer">
            <div class="row">
              <div class="col-xs-6 col-sm-6 col-md-6">
                <button class="btn btn-sm btn-flat-default btn-block" @click="cancelUpload(true)">
                  {{ $t('common.cancel') }}
                </button>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-6">
                <button
                  class="btn btn-sm btn-primary btn-block open-alert alertAfterAnother"
                  @click="uploadExecution()"
                >
                  {{ isModalInsurance ? $t('settings.keep') : $t('common.upload_execution') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalConfirm
      :dialogVisible="showBoxConfirm"
      @cancel="(showBoxConfirm = false), (dialogVisibleStatus = true)"
      @yes="deleteInsurance"
      :classCustom="'btn-flat-danger'"
      :title="$t('common.delete_insurance_msg')"
    />
  </el-dialog>
</template>

<script>
import { MAX_FILE_SIZE } from '@/constants/';
import mixins from '@/helpers/mixins';
import ModalConfirm from '@/components/common/ModalConfirm';

export default {
  name: 'ReservationUpload',
  emits: ['yes', 'cancel'],
  mixins: [mixins],
  components: { ModalConfirm },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
    insurance: {
      type: Array,
      default: () => [],
    },
    sbUpload: {
      type: Boolean,
      default: false,
    },
    acceptedFiles: {
      type: String,
      default: '*',
    },
    acceptImageAndPDFOnly: {
      type: Boolean,
      default: false,
    },
    isSingleUpload: {
      type: Boolean,
      default: false,
    },
    isModalInsurance: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogVisibleStatus: false,
      listFile: [],
      note: '',
      content_reservation: '',
      errors: {},
      insuranceFileId: 0,
      listInsurances: [],
      showBoxConfirm: false,
    };
  },
  computed: {
    showPreview() {
      return this.listFile.length > 0 || !this.sbUpload || (this.sbUpload && this.checkEmpty(this.insurance));
    },
    acceptedList() {
      if (!this.checkEmpty(this.acceptedFiles)) {
        return this.acceptedFiles;
      }

      return 'image/jpg, image/jpeg, image/png, .pdf, .csv, .xlsx, .docx, .heif';
    },
  },
  watch: {
    insurance(val) {
      this.listInsurances = this.cloneObject(val);
      if (val && val.note) this.note = val.note;
    },
    dialogVisible(newVal) {
      this.dialogVisibleStatus = newVal;
      if (newVal) this.listInsurances = this.cloneObject(this.insurance);
    },
    listFile(val) {
      if (val) this.errors.file = '';
    },
  },
  methods: {
    handleRemoveInsurance(fileId) {
      this.insuranceFileId = fileId;
      // this.dialogVisibleStatus = false;
      // this.showBoxConfirm = true;
      this.deleteInsurance();
    },
    //Call to delete Insurance
    deleteInsurance() {
      if (this.insuranceFileId == 0 || this.checkEmpty(this.insuranceFileId)) return;
      this.listInsurances.splice(
        this.listInsurances.findIndex(ins => ins.file_id == this.insuranceFileId),
        1,
      );
      // (this.showBoxConfirm = false), (this.dialogVisibleStatus = true);
    },
    cancelUpload(clear = false) {
      this.$emit('cancel');
      if (clear) {
        this.listFile = [];
        this.note = this.insurance?.note || '';
        this.content_reservation = '';
      }
    },
    chooseFile() {
      this.$refs.inputFile.click();
    },
    uploadExecution() {
      let flgCheck = true;
      if (this.content_reservation.length > 10000) {
        this.errors.content_reservation = this.$t('E-CM-111', { size: 10000 });
        flgCheck = false;
      }
      if (!this.isModalInsurance) {
        if (this.listFile.length == 0 && this.checkEmpty(this.content_reservation)) {
          this.errors.file = this.$t('trip_update.validate_upload_trip');

          flgCheck = false;
          //Process this only when accept image only.
        } else if (this.acceptImageAndPDFOnly) {
          let flagValidateFormat = true;
          const acceptedFormatArr = this.acceptedList.split(', ');
          this.listFile.forEach(item => {
            if (!acceptedFormatArr.includes(item.type)) flagValidateFormat = false;
          });
          if (!flagValidateFormat) {
            this.errors.file = this.$t('common.invalid_image_or_pdf_format');
            return;
          }
        } else {
          this.errors.file = '';
        }
      }

      if (!flgCheck) return;
      const listFile = [...this.listFile];
      if (this.isModalInsurance) {
        this.$emit('yes', listFile, this.listInsurances, this.note);
        this.listFile = [];
        this.content_reservation = '';
        this.dialogVisibleStatus = false;
        return;
      }
      this.$emit('yes', listFile, this.note, this.content_reservation);
      this.listFile = [];
      this.content_reservation = '';
    },
    removeFile(index) {
      this.listFile.splice(index, 1);
    },
    getSize(size) {
      return (size / (1024 * 1024)).toFixed(2);
    },
    onDrop(e) {
      this.handleUploadFile(e.dataTransfer);
    },
    async onFileChange(e) {
      this.handleUploadFile(e.target);
    },
    async handleUploadFile(data) {
      const files = data.files;
      let messageErrors = [];

      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        let flg = true;

       if (file?.size && file.size > MAX_FILE_SIZE) {
          messageErrors.push(`${file.name} ${this.$t('E-CU-223')}`);
          flg = false;
        }

        if (flg) this.listFile = [...this.listFile, file];
      }

      messageErrors.length > 0 && this.notifyError(messageErrors, true);

      this.$refs.inputFile.value = '';
    },
  },
};
</script>

<style scoped>
.dz-image .icon {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
