<template>
  <div>
    <div v-show="isShowSearch" class="filter js-filter--minimization">
      <div class="section section-filter noborder js-section-filter">
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <div class="form-group">
              <label>
                <span class="label-text">{{ $t('common.country') }}</span>
              </label>
              <MultiOptionSelect
                ref="ListCountrySelect"
                :list-data="listCountry"
                :display-by-locale="true"
                :isListCountry="true"
                :showSelectAll="true"
                :default-list="searchParams.country_ids"
                @handle-selected-params="listenSelectedCountry"
              />
            </div>
          </div>
          <div v-if="isOperatorOrAdmin" class="col-sm-12 col-md-6">
            <div class="form-group">
              <label>
                <span class="label-text">{{ $t('common.company') }}</span>
              </label>
              <MultiOptionSelect
                ref="CompanySelect"
                :list-data="listCompanies"
                :default-list="searchParams.company"
                @handle-selected-params="listenSelectedCompany"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div
            class="col-sm-6 col-md-3 col-all-closer col-all-closer--leftend col-sm-closer--rightend col-xs-closer--rightend"
          >
            <div class="form-group">
              <label>
                <span class="label-text">{{ $t('common.target_period_start_date') }}</span>
              </label>
              <div class="input-group">
                <div class="input-with-icon">
                  <el-date-picker
                    type="date"
                    class="date-input"
                    format="YYYY/MM/DD"
                    value-format="YYYY-MM-DD"
                    v-model="searchParams.start_from_date"
                    :clearable="false"
                    :placeholder="$t('common.enter_or_select')"
                    :disabled-date="isDisableStartDate"
                  />
                </div>
                <span class="input-group-addon prn">{{ $t('common.from') }}</span>
              </div>
            </div>
          </div>
          <div
            class="col-sm-6 col-md-3 col-all-closer col-all-closer--rightend col-sm-closer--leftend col-xs-closer--leftend"
          >
            <div class="form-group">
              <label class="hidden-xs"><span class="label-text">&nbsp;</span></label>
              <div class="input-group">
                <div class="input-with-icon">
                  <el-date-picker
                    type="date"
                    class="date-input"
                    format="YYYY/MM/DD"
                    value-format="YYYY-MM-DD"
                    v-model="searchParams.start_until_date"
                    :disabled-date="isDisableStartUtilDate"
                    :clearable="false"
                    :placeholder="$t('common.enter_or_select')"
                  />
                </div>
                <span class="input-group-addon prn">{{ $t('common.to') }}</span>
              </div>
            </div>
          </div>
          <div
            class="col-sm-6 col-md-3 col-all-closer col-all-closer--leftend col-sm-closer--rightend col-xs-closer--rightend"
          >
            <div class="form-group">
              <label>
                <span class="label-text">{{ $t('common.end_date_of_the_target_period') }}</span>
              </label>
              <div class="input-group">
                <div class="input-with-icon">
                  <el-date-picker
                    type="date"
                    class="date-input"
                    format="YYYY/MM/DD"
                    value-format="YYYY-MM-DD"
                    v-model="searchParams.end_from_date"
                    :disabled-date="isDisableEndDate"
                    :clearable="false"
                    :placeholder="$t('common.enter_or_select')"
                  />
                </div>
                <span class="input-group-addon prn">{{ $t('common.from') }}</span>
              </div>
            </div>
          </div>
          <div
            class="col-sm-6 col-md-3 col-all-closer col-all-closer--rightend col-sm-closer--leftend col-xs-closer--leftend"
          >
            <div class="form-group">
              <label class="hidden-xs"><span class="label-text">&nbsp;</span></label>
              <div class="input-group">
                <div class="input-with-icon">
                  <el-date-picker
                    type="date"
                    class="date-input"
                    format="YYYY/MM/DD"
                    value-format="YYYY-MM-DD"
                    v-model="searchParams.end_until_date"
                    :disabled-date="isDisableEndUtilDate"
                    :clearable="false"
                    :placeholder="$t('common.enter_or_select')"
                  />
                </div>
                <span class="input-group-addon prn">{{ $t('common.to') }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-sm-6 col-md-6">
            <div class="form-group">
              <label class="checkbox checkbox-sm checkbox-inline">
                <el-checkbox
                  :label="$t('security.including_confirmation_completion')"
                  v-model="searchParams.is_including_confirmation_completion"
                  class="pl-0"
                  size="large"
                />
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="isShowSearch" class="section-submit js-section-submit">
      <span class="submit-arrow"></span>
      <div class="row">
        <div class="col-sm-4 col-md-5 hidden-xs">
          <button class="btn btn-sm btn-flat-default btn-clear" @click.prevent="onHandleClearCondition">
            {{ $t('common.clear') }}
          </button>
        </div>
        <div class="col-sm-4 col-md-2">
          <button
            class="btn btn-sm btn-primary btn-block js-btn-filter--minimization"
            @click.prevent="onHandleSubmitFilter"
          >
            {{ $t('common.search') }}
          </button>
        </div>
        <div class="col-sm-4 col-md-5 hidden-xs">
          <button class="btn btn-sm btn-flat-default btn-close" @click="isShowSearch = false">
            {{ $t('common.close') }}
          </button>
        </div>
      </div>
    </div>
    <div
      v-show="!isShowSearch"
      @click="isShowSearch = true"
      class="btn-filter-again with-condition"
      style="display: block"
    >
      <div class="filter--condition">
        <span>{{ $t('common.all') }}</span>
        <span style="color: #3650a5">{{ $t('common.reset_conditions') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import MultiOptionSelect from '@/components/common/MultiOptionSelect.vue';

export default {
  name: 'Filter',
  components: { MultiOptionSelect },
  emits: ['submit'],
  props: {},
  data() {
    return {
      listCountry: [],
      listCompanies: [],
      searchParams: {
        country_ids: this.$route.query.country_ids ? JSON.parse(this.$route.query.country_ids) : [],
        company: this.$route.query.company ? JSON.parse(this.$route.query.company) : [],
        start_from_date: this.$route.query.start_from_date || '',
        end_from_date: this.$route.query.end_from_date || '',
        start_until_date: this.$route.query.start_until_date || '',
        end_until_date: this.$route.query.end_until_date || '',
        is_including_confirmation_completion:
          this.$route.query.is_including_confirmation_completion == 'true' ? '1' : '0',
      },
      isShowSearch: false,
    };
  },
  created() {
    // this.getListCountry();
    const promiseListCom = this.isOperatorOrAdmin ? this.getListCompanies() : Promise.resolve();
    this.startLoading();
    Promise.all([promiseListCom, this.getListCountry()]).then(() => {
      this.endLoading();
    });
  },
  watch: {
    'searchParams.start_from_date'(newVal) {
      if (this.checkEmpty(this.searchParams.start_until_date)) this.searchParams.start_until_date = newVal;
    },
    'searchParams.end_from_date'(newVal) {
      if (this.checkEmpty(this.searchParams.end_until_date)) this.searchParams.end_until_date = newVal;
    },
  },
  methods: {
    onHandleClearCondition() {
      this.searchParams = {
        country_ids: [],
        company: [],
        start_from_date: '',
        end_from_date: '',
        start_until_date: '',
        end_until_date: '',
        is_including_confirmation_completion: false,
      };
      this.$refs.ListCountrySelect.clearFilter();
      if (this.isOperatorOrAdmin) {
        this.$refs.CompanySelect.clearFilter();
      }
    },
    onHandleSubmitFilter() {
      this.$emit('submit', this.searchParams);
    },
    async getListCompanies() {
      const params = {
        all_data: true,
      };
      await this.$services.common.getListCompanies(
        params,
        res => {
          this.listCompanies = res.list;
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    listenSelectedCountry(listData) {
      this.searchParams.country_ids = listData;
    },
    listenSelectedCompany(listData) {
      this.searchParams.company = listData;
    },
    async getListCountry() {
      await this.$services.security.getListCountry(
        response => {
          const { list } = response;
          this.listCountry = list;
        },
        error => {
          this.notifyError(this.$t(error.error));
        },
      );
    },
    isDisableStartDate(time) {
      if (this.checkEmpty(this.searchParams.start_until_date)) return false;
      return (
        moment(new Date(time)).format('YYYY-MM-DD') > moment(this.searchParams.start_until_date).format('YYYY-MM-DD')
      );
    },
    isDisableEndDate(time) {
      if (this.checkEmpty(this.searchParams.end_until_date)) return false;
      return (
        moment(new Date(time)).format('YYYY-MM-DD') > moment(this.searchParams.end_until_date).format('YYYY-MM-DD')
      );
    },
    isDisableStartUtilDate(time) {
      if (this.checkEmpty(this.searchParams.start_from_date)) return false;
      return (
        moment(new Date(time)).format('YYYY-MM-DD') < moment(this.searchParams.start_from_date).format('YYYY-MM-DD')
      );
    },
    isDisableEndUtilDate(time) {
      if (this.checkEmpty(this.searchParams.end_from_date)) return false;
      return moment(new Date(time)).format('YYYY-MM-DD') < moment(this.searchParams.end_from_date).format('YYYY-MM-DD');
    },
  },
};
</script>
