import BaseService from './BaseService';
import API_CODE from '../utils/api_code.js';

export default class RegulationAPI extends BaseService {
  async getListArea(success, error) {
    await this.get(API_CODE.CU_273, success, error);
  }
  async getListAirport(success, error) {
    await this.get(API_CODE.CU_134, success, error);
  }
  async getRegulation(success, error) {
    await this.get(API_CODE.CU_262, success, error);
  }
  async createRegulation(params, success, error) {
    await this.post(API_CODE.CU_129, params, success, error);
  }
  async saveRegulationFiles(params, success, error) {
    await this.post(API_CODE.CU_284, params, success, error);
  }
  async uploadFileCSV(params, success, error) {
    await this.post(API_CODE.CU_274, params, success, error);
  }
  async downloadFileCSV(params, success, error) {
    await this.get(API_CODE.CU_275, params, success, error);
  }
  async downloadExampleFileCSV(params, success, error) {
    await this.get(API_CODE.CU_276, params, success, error);
  }
  async getAreaDetail(id, success, error) {
    await this.get(API_CODE.CU_277 + id, success, error);
  }
  async updateArea(params, success, error) {
    await this.post(API_CODE.CU_278 + params.id, params.area, success, error);
  }
  async createArea(params, success, error) {
    await this.post(API_CODE.CU_279, params, success, error);
  }
  async deleteArea(id, success, error) {
    await this.delete(API_CODE.CU_280 + id, {}, success, error);
  }
  async downloadExampleFileCSVPassport(success, error) {
    await this.get(API_CODE.CU_325, success, error);
  }
  async uploadFileCSVPassport(params, success, error) {
    await this.post(API_CODE.CU_324, params, success, error);
  }
}
