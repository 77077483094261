<template>
  <div class="wrapper">
    <nav class="navbar navbar-default navbar-lg" role="navigation" id="header-content">
      <div class="navbar-header">
        <h2 class="navbar-title">{{ $t('sidebar.regulation') }}</h2>
      </div>
    </nav>
    <div class="filter-wrap">
      <div class="filter js-filter--minimization" style="height: auto; overflow: visible" v-show="showBoxFilter">
        <div class="section section-filter noborder js-section-filter" style="visibility: visible">
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <div class="form-group">
                <label>
                  <span class="label-text">{{ $t('regulation_check.trip') }}</span>
                </label>
                <input
                  type="text"
                  v-model="searchParams.trip_name"
                  :placeholder="$t('regulation_check.input_format_trip')"
                  class="form-control input-sm"
                />
                <p role="alert" class="error-feedback est-cost-check" v-text="errors.trip_name" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <div class="form-group">
                <label>
                  <span class="label-text">{{ $t('regulation_check.customer_enterprise') }}</span>
                </label>
                <input
                  type="text"
                  v-model="searchParams.company_name"
                  :placeholder="$t('regulation_check.input_format_customer_enterprise')"
                  class="form-control input-sm"
                />
                <p role="alert" class="error-feedback est-cost-check" v-text="errors.company_name" />
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
              <div class="form-group">
                <label>
                  <span class="label-text">
                    {{ $t('regulation_check.customer_employee') }}
                  </span>
                </label>
                <input
                  type="text"
                  v-model="searchParams.employee_number"
                  :placeholder="$t('regulation_check.partial_match')"
                  class="form-control input-sm"
                />
                <p role="alert" class="error-feedback est-cost-check" v-text="errors.employee_number" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <div class="form-group">
                <label>
                  <span class="label-text">
                    {{ $t('regulation_check.point_of_departure') }}
                  </span>
                </label>
                <input
                  type="text"
                  v-model="searchParams.departure"
                  :placeholder="$t('regulation_check.partial_match')"
                  class="form-control input-sm"
                />
                <p role="alert" class="error-feedback est-cost-check" v-text="errors.departure" />
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
              <div class="form-group">
                <label>
                  <span class="label-text">
                    {{ $t('regulation_check.destination') }}
                  </span>
                </label>
                <input
                  type="text"
                  v-model="searchParams.destination"
                  :placeholder="$t('regulation_check.partial_match')"
                  class="form-control input-sm"
                />
                <p role="alert" class="error-feedback est-cost-check" v-text="errors.destination" />
              </div>
            </div>
          </div>
          <div class="row">
            <DatePickerWithRange
              :parent-start-date="searchParams.departure_date_from"
              :parent-end-date="searchParams.departure_date_to"
              :date-picker-title="$t('regulation_check.departure_date')"
              @listenStartDateChange="listenDepartureDateFrom"
              @listenEndDateChange="listenDepartureDateTo"
            />
            <DatePickerWithRange
              :parent-start-date="searchParams.arrival_date_from"
              :parent-end-date="searchParams.arrival_date_to"
              :date-picker-title="$t('regulation_check.return_date')"
              @listenStartDateChange="listenReturnDateFrom"
              @listenEndDateChange="listenReturnDateTo"
            />
          </div>

          <div class="row">
            <DatePickerWithRange
              :parent-start-date="searchParams.approval_date_from"
              :parent-end-date="searchParams.approval_date_to"
              :date-picker-title="$t('regulation_check.approval_date')"
              @listenStartDateChange="listenApprovalDateFrom"
              @listenEndDateChange="listenApprovalDateTo"
            />
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <div class="form-group">
                <label>
                  <span class="label-text">{{ $t('regulation_check.country') }}</span>
                </label>
                <MultiOptionSelect
                  ref="ListCountrySelect"
                  :list-data="listCountries"
                  :display-by-locale="true"
                  :isListCountry="true"
                  :default-list="searchParams.country_ids"
                  @handle-selected-params="listenSelectedCountry"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <!-- <div class="row"> -->
            <div class="col-xs-12 col-sm-6 col-md-6">
              <div class="form-group">
                <label>{{ $t('regulation_check.suitability') }}</label>
                <label class="checkbox checkbox-sm checkbox-inline" :class="searchParams.no_problem_check && 'checked'">
                  <span class="icons">
                    <span class="first-icon icon-abui-checkbox-unchecked"></span>
                    <span class="second-icon icon-abui-checkbox-checked"></span>
                  </span>
                  <input type="checkbox" v-model="searchParams.no_problem_check" />
                  <span class="suitability_custom">
                    {{ $t('regulation_check.no_problem') }}
                  </span>
                </label>
                <label
                  class="checkbox checkbox-sm checkbox-inline suitability_chk_custom"
                  :class="searchParams.be_careful_check && 'checked'"
                >
                  <span class="icons">
                    <span class="first-icon icon-abui-checkbox-unchecked"></span>
                    <span class="second-icon icon-abui-checkbox-checked"></span>
                  </span>
                  <input type="checkbox" value="" v-model="searchParams.be_careful_check" />
                  <span class="suitability_custom">
                    {{ $t('regulation_check.be_careful') }}
                  </span>
                </label>
                <label
                  class="checkbox checkbox-sm checkbox-inline suitability_chk_custom"
                  :class="searchParams.problem_check && 'checked'"
                >
                  <span class="icons">
                    <span class="first-icon icon-abui-checkbox-unchecked"></span>
                    <span class="second-icon icon-abui-checkbox-checked"></span>
                  </span>
                  <input type="checkbox" v-model="searchParams.problem_check" />
                  <span class="suitability_custom">
                    {{ $t('regulation_check.have_problem') }}
                  </span>
                </label>
              </div>
              <!-- </div> -->
            </div>
          </div>
        </div>
      </div>
      <!-- /.filter -->

      <div class="section-submit js-section-submit" v-show="showBoxFilter">
        <span class="submit-arrow"></span>
        <div class="row">
          <div class="col-sm-4 col-md-5">
            <button @click="clearSearchData()" class="btn btn-sm btn-flat-default btn-clear">
              {{ $t('common.clear') }}
            </button>
          </div>
          <div class="col-sm-4 col-md-2">
            <button
              @click="searchRegulationCheck()"
              class="btn btn-sm btn-primary btn-block js-btn-filter--minimization"
            >
              {{ $t('common.search') }}
            </button>
          </div>
          <div class="col-sm-4 col-md-5 hidden-xs">
            <button class="btn btn-sm btn-flat-default btn-close" @click="showBoxFilter = false">
              {{ $t('common.close') }}
            </button>
          </div>
        </div>
      </div>
      <!-- /.section-submit -->

      <div
        v-show="!showBoxFilter"
        @click="showBoxFilter = true"
        class="btn-filter-again with-condition"
        style="display: block"
      >
        <div class="filter--condition">
          <span>{{ $t('common.all') }}</span>
        </div>
      </div>
      <!-- /.btn-filter-again -->

      <div class="filter-result" style="">
        <div class="section noborder">
          <Pagination
            v-if="listRegulations.length > 0"
            @change-page="changePageData"
            :paginate="paginate"
            @change-number="changeNumber"
          />

          <div>
            <div class="table-responsive">
              <table class="table table-hover table-hover--pointer table--property-list" id="table--plan">
                <thead>
                  <tr>
                    <th>{{ $t('regulation_check.approval_date_time') }}</th>
                    <th style="width: 12.5%">{{ $t('regulation_check.destination_country') }}</th>
                    <th
                      class="table-sort"
                      :class="sortActive.column == 'period' && 'table-sort-active'"
                      @click="sortAction('period')"
                    >
                      <span :class="sortActive.column == 'period' ? sortActive.sort_by : 'asc'">
                        {{ $t('regulation_check.period') }}
                      </span>
                    </th>
                    <th>{{ $t('regulation_check.trip') }}</th>
                    <th>{{ $t('regulation_check.suitability') }}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody v-if="!checkEmpty(listRegulations)">
                  <tr
                    v-for="(regulation, index) in listRegulations"
                    :key="index"
                    :class="regulation.is_confirmed && 'done muted'"
                    @click="pushToRegulationCheckDetail(regulation)"
                  >
                    <td>{{ showDateTime(regulation?.date_time, 'YYYY/MM/DD HH:mm') }}</td>
                    <td>{{ regulation?.country_name }}</td>
                    <td>
                      {{ showDateTime(regulation.start_time, 'YYYY/MM/DD（ddd）') }}
                      -
                      {{ showDateTime(regulation.end_time, 'YYYY/MM/DD（ddd）') }}
                    </td>
                    <td class="totrip">{{ regulation?.trip_code }}：{{ regulation?.trip_name }}</td>
                    <td>
                      <i v-if="regulation.status === TRIP_REGULATION_OK" class="icon-abui-check-inverted text-success">
                      </i>
                      <i v-if="regulation.status === TRIP_REGULATION_WARNING" class="icon-abui-alert text-warning"></i>
                      <i
                        v-if="regulation.status === TRIP_REGULATION_ERROR"
                        class="icon-abui-triangle-alert text-danger"
                      >
                      </i>
                    </td>
                    <td class="totrip" @click.stop>
                      <router-link
                        v-if="!checkEmpty(regulation.id)"
                        :to="{
                          name: this.PAGES.TRIP_UPDATE,
                          params: {
                            id: regulation.id,
                          },
                        }"
                        target="_blank"
                      >
                        <!-- <a>{{ regulation?.trip_code }}：{{ regulation?.trip_name }}</a> -->
                        <a class="btn btn-sm btn-flat-default">
                          <i class="icon-abui-globe" />
                        </a>
                      </router-link>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr class="no-data">
                    <td colspan="5">{{ $t('common.no_data') }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <Pagination
            v-if="listRegulations.length > 0"
            @change-page="changePageData"
            :paginate="paginate"
            @change-number="changeNumber"
          />
        </div>
      </div>
      <!-- /#filter-result -->
    </div>
    <!-- /#filter-wrap -->
  </div>
</template>
<script>
import { TRIP_REGULATION_OK, TRIP_REGULATION_WARNING, TRIP_REGULATION_ERROR } from '@/constants';
import DatePickerWithRange from '@/components/application/DatePickerWithRange';
import Pagination from '@/components/common/Pagination';
import MultiOptionSelect from '@/components/common/MultiOptionSelect.vue';

export default {
  name: 'RegulationCheck',
  emits: [],
  components: { DatePickerWithRange, Pagination, MultiOptionSelect },
  created() {
    this.startLoading();
    Promise.all([this.getListCountry(), this.getListRegulations()]).then(() => {
      this.endLoading();
    });
  },
  data() {
    return {
      isSearchActivated: this.$route.query.is_search_activated
        ? this.$route.query.is_search_activated == '1' && true
        : false,
      listCountries: [],
      listRegulations: [],
      selectedCountries: [],
      showBoxFilter: false,
      paginate: {
        page: +this.$route.query.page || 1,
        per_page: +this.$route.query.per_page || 10,
      },
      sortActive: {
        column: this.$route.query.sort_column || 'created_at',
        sort_by: this.$route.query.sort_by || 'dsc',
        locale: this.$i18n.locale,
      },
      searchParams: {
        country_ids: this.$route.query.country_ids ? JSON.parse(this.$route.query.country_ids) : [],
        trip_name: this.$route.query.trip_name || '',
        company_name: this.$route.query.company_name || '',
        employee_number: this.$route.query.employee_number || '',
        departure: this.$route.query.departure || '',
        destination: this.$route.query.destination || '',
        departure_date_from: this.$route.query.departure_date_from || '',
        departure_date_to: this.$route.query.departure_date_to || '',
        arrival_date_from: this.$route.query.arrival_date_from || '',
        arrival_date_to: this.$route.query.arrival_date_to || '',
        approval_date_from: this.$route.query.approval_date_from || '',
        approval_date_to: this.$route.query.approval_date_to || '',
        no_problem_check: this.$route.query.no_problem_check
          ? this.$route.query.no_problem_check == '1' && true
          : false,
        be_careful_check: this.$route.query.be_careful_check ? this.$route.query.be_careful_check == '1' && true : true,
        problem_check: this.$route.query.problem_check ? this.$route.query.problem_check == '1' && true : true,
      },
      TRIP_REGULATION_OK,
      TRIP_REGULATION_WARNING,
      TRIP_REGULATION_ERROR,
      errors: {
        trip_name: '',
        company_name: '',
        employee_number: '',
        departure: '',
        destination: '',
        departure_date_from: '',
        departure_date_to: '',
        arrival_date_from: '',
        arrival_date_to: '',
        approval_date_from: '',
        approval_date_to: '',
      },
    };
  },
  watch: {},
  methods: {
    sortAction(name) {
      this.sortActive.column = name;
      if (this.sortActive.sort_by == 'dsc') this.sortActive.sort_by = 'asc';
      else this.sortActive.sort_by = 'dsc';
      this.getListRegulations();
    },
    changePageData(page) {
      this.paginate.page = +page;
      this.getListRegulations();
    },
    changeNumber(number) {
      this.paginate = {
        ...this.paginate,
        page: 1,
        per_page: number,
      };
      this.getListRegulations();
    },
    listenSelectedCountry(list) {
      this.selectedCountries = list;
      this.searchParams.country_ids = list;
    },
    listenDepartureDateFrom(date) {
      this.searchParams.departure_date_from = date;
    },
    listenDepartureDateTo(date) {
      this.searchParams.departure_date_to = date;
    },
    listenReturnDateFrom(date) {
      this.searchParams.arrival_date_from = date;
    },
    listenReturnDateTo(date) {
      this.searchParams.arrival_date_to = date;
    },
    listenApprovalDateFrom(date) {
      this.searchParams.approval_date_from = date;
    },
    listenApprovalDateTo(date) {
      this.searchParams.approval_date_to = date;
    },
    searchRegulationCheck() {
      this.isSearchActivated = true;
      this.paginate.page = 1;
      let flagValidate = true;
      //Validate input trip_name
      if (!this.checkEmpty(this.searchParams.trip_name) && this.searchParams.trip_name.length > 50) {
        flagValidate = false;
        this.errors.trip_name = this.$t('E-CM-111', { size: 50 });
      } else {
        this.errors.trip_name = '';
      }
      //Validate input company_name
      if (!this.checkEmpty(this.searchParams.company_name) && this.searchParams.company_name.length > 50) {
        flagValidate = false;
        this.errors.company_name = this.$t('E-CM-111', { size: 50 });
      } else {
        this.errors.company_name = '';
      }
      //Validate input employee_number
      if (!this.checkEmpty(this.searchParams.employee_number) && this.searchParams.employee_number.length > 50) {
        flagValidate = false;
        this.errors.employee_number = this.$t('E-CM-111', { size: 50 });
      } else {
        this.errors.employee_number = '';
      }
      //Validate input departure
      if (!this.checkEmpty(this.searchParams.departure) && this.searchParams.departure.length > 50) {
        flagValidate = false;
        this.errors.departure = this.$t('E-CM-111', { size: 50 });
      } else {
        this.errors.departure = '';
      }
      //Validate input destination
      if (!this.checkEmpty(this.searchParams.destination) && this.searchParams.destination.length > 50) {
        flagValidate = false;
        this.errors.destination = this.$t('E-CM-111', { size: 50 });
      } else {
        this.errors.destination = '';
      }
      if (flagValidate) this.getListRegulations();
    },
    clearSearchData() {
      this.searchParams = {
        country_ids: [],
        page: this.paginate.page,
        per_page: +this.paginate.per_page,
        trip_name: '',
        company_name: '',
        employee_number: '',
        departure: '',
        destination: '',
        departure_date_from: '',
        departure_date_to: '',
        arrival_date_from: '',
        arrival_date_to: '',
        approval_date_from: '',
        approval_date_to: '',
        no_problem_check: false,
        be_careful_check: true,
        problem_check: true,
      };
      this.$refs.ListCountrySelect.clearFilter();
    },

    pushToRegulationCheckDetail(regulation) {
      this.$router.push({
        name: this.PAGES.REGULATION_CHECK_DETAIL,
        params: {
          id: regulation.id,
        },
      });
    },
    //CU-069
    async getListCountry() {
      await this.$services.security.getListCountry(
        res => {
          this.listCountries = this.sortCountryByName(res.list);
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },

    updateRouter(res) {
      let queryParams = {
        page: res.paginate.current_page,
        per_page: res.paginate.per_page,
        sort_column: this.sortActive.column,
        sort_by: this.sortActive.sort_by,
        trip_name: this.searchParams.trip_name,
        company_name: this.searchParams.company_name,
        employee_number: this.searchParams.employee_number,
        departure: this.searchParams.departure,
        destination: this.searchParams.destination,
        departure_date_from: this.searchParams.departure_date_from,
        departure_date_to: this.searchParams.departure_date_to,
        arrival_date_from: this.searchParams.arrival_date_from,
        arrival_date_to: this.searchParams.arrival_date_to,
        approval_date_from: this.searchParams.approval_date_from,
        approval_date_to: this.searchParams.approval_date_to,
        no_problem_check: this.searchParams.no_problem_check ? '1' : '0',
        be_careful_check: this.searchParams.be_careful_check ? '1' : '0',
        problem_check: this.searchParams.problem_check ? '1' : '0',
        is_search_activated: this.isSearchActivated ? '1' : '0',
        country_ids: JSON.stringify(this.searchParams.country_ids),
      };

      queryParams = this.removeEmptyObject(queryParams);

      this.$router.push({
        path: this.$route.path,
        query: this.isSearchActivated
          ? queryParams
          : {
              page: res.paginate.current_page,
              per_page: res.paginate.per_page,
              sort_column: this.sortActive.column,
              sort_by: this.sortActive.sort_by,
            },
      });
    },

    //CU-149
    async getListRegulations() {
      let queryBuilder = '';
      let dataSearch = {
        ...this.searchParams,
        page: this.paginate.page,
        per_page: +this.paginate.per_page,
        sort_column: this.sortActive.column,
        sort_by: this.sortActive.sort_by == 'dsc' ? 'desc' : 'asc',
      };
      //Push country ids to queryBuilder
      if (!this.checkEmpty(this.selectedCountries)) {
        this.selectedCountries.map(item => {
          queryBuilder += '&country_ids[]=' + item;
        });
        queryBuilder = queryBuilder.substr(1);
      }
      delete dataSearch.country_ids;

      if (!this.isSearchActivated) {
        dataSearch = {
          page: this.paginate.page,
          per_page: +this.paginate.per_page,
          sort_column: this.sortActive.column,
          sort_by: this.sortActive.sort_by == 'dsc' ? 'desc' : 'asc',
        };
      }

      await this.$services.regulation_check.getListRegulations(
        dataSearch,
        queryBuilder,
        res => {
          this.listRegulations = res.list;
          this.paginate = { ...this.paginate, ...res.paginate };
          this.showBoxFilter = false;
          this.updateRouter(res);
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
  },
};
</script>
<style scoped>
.suitability_chk_custom {
  margin-left: 17px !important;
}
.suitability_custom {
  padding-left: 30px;
}
</style>
