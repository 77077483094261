<template>
  <nav class="navbar navbar-default navbar-lg" role="navigation" id="header-content">
    <div class="navbar-header">
      <h2 class="navbar-title">{{ $t('organization.organization') }}</h2>
    </div>
    <div class="collapse navbar-collapse">
      <ul class="nav navbar-nav navbar-right">
        <li>
          <a style="cursor: pointer" @click="downloadOrganizationCSV">
            <span class="icon-abui-download prx" />{{ $t('organization.download_CSV') }}
          </a>
        </li>
        <li>
          <a style="cursor: pointer" @click="this.$router.push(this.PAGES.ORGANIZATION_CREATE)">
            <span class="icon-abui-plus prx" />{{ $t('organization.add_new') }}
          </a>
        </li>
        <li class="dropdown addNew hidden-sm">
          <a
            style="cursor: pointer"
            class="dropdown-toggle pls"
            @click="isShowDropdown = !isShowDropdown"
            v-click-outside="
              () => {
                isShowDropdown = false;
              }
            "
          >
            <b class="caret" />
          </a>
          <span class="dropdown-arrow" />
          <ul class="dropdown-menu" :class="isShowDropdown && 'showDropdown'" style="left: -200px">
            <li>
              <a style="cursor: pointer" @click="showModalUploadCSV = true">
                <span class="icon-abui-upload prx" />{{ $t('organization.upload_CSV') }}
              </a>
            </li>
            <li>
              <a style="cursor: pointer" @click="downloadExampleFileCSV">
                <span class="icon-abui-download prx" />{{ $t('organization.download_CSV_format') }}
              </a>
            </li>
            <li>
              <a href="https://www.iace.co.jp/travelmgr/pdf/import_sample/soshiki" target="_blank" >
                <span class="icon-abui-book prx" />{{ $t('common.manual_registration') }}
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </nav>

  <div class="filter-wrap">
    <div v-show="isShowFilter" class="filter js-filter--minimization">
      <div class="section section-filter noborder js-section-filter">
        <div class="row">
          <div class="col-sm-12 col-md-12">
            <div class="form-group">
              <label>
                <span class="label-text">{{ $t('organization.keyword') }}</span>
              </label>
              <el-input
                maxlength="50"
                :placeholder="$t('organization.placeholder_keyword')"
                v-model="searchParams.keyword"
              />
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-group">
              <label class="checkbox checkbox-sm checkbox-inline">
                <el-checkbox
                  v-model="searchParams.with_deleted"
                  class="pl-0"
                  :label="$t('employee.show_deleted')"
                  size="large"
                />
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ButtonSubmit
      :is-show="isShowFilter"
      @onHandleClearCondition="onHandleClearCondition"
      @onHandleSubmitFilter="onHandleSubmitFilter"
      @listenCloseSearchBox="listenCloseSearchBox"
    />
    <ButtonFilter :is-show="!isShowFilter" @onHandleClickFilter="onHandleClickFilter" />
    <DataTableResult :paginate="paginate" :listOrganizations="listOrganizations" @changePageData="changePageData" />
    <ModalUploadCSV
      :dialogVisible="showModalUploadCSV"
      @cancel="showModalUploadCSV = false"
      @confirm="confirmUploadFileCSV"
      :errorValidate="errorValidate"
      :title="$t('common.title_modal_upload_file_csv')"
    />
  </div>
</template>

<script>
import ButtonSubmit from '@/components/security/ButtonSubmit';
import DataTableResult from '@/components/organization/DataTableResult';
import ButtonFilter from '@/components/security/ButtonFilter';
import ModalUploadCSV from '@/components/common/ModalUploadCSV';
import PAGES from '@/utils/pages.js';
import vClickOutside from 'click-outside-vue3';

export default {
  name: 'Organization',
  components: {
    ButtonFilter,
    DataTableResult,
    ButtonSubmit,
    ModalUploadCSV,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      showModalUploadCSV: false,
      isShowDropdown: false,
      isShowFilter: false,
      isShowResult: true,
      listOrganizations: [],
      clearFilter: false,
      isFilter: !this.checkEmpty(this.$route.query.keyword) ? true : false,
      searchParams: {
        keyword: this.$route.query.keyword || '',
        with_deleted: this.$route.query?.with_deleted ? this.$route.query.with_deleted == 1 : false,
      },
      paginate: {
        page: +this.$route.query.page || 1,
        perPage: 10,
        from: 1,
        to: 1,
        lastPage: 1,
        total: 1,
      },
      errorValidate: [],
    };
  },
  created() {
    this.startLoading();
    this.getListOrganizations();
  },
  watch: {
    showModalUploadCSV() {
      this.errorValidate = [];
    },
  },
  methods: {
    downloadOrganizationCSV() {
      this.startLoading();
      this.$services.organization.downloadOrganizationCSV(
        this.searchParams,
        res => {
          this.endLoading();
          this.handleDownloadCSV(res.file_path);
        },
        err => {
          this.endLoading();
          this.notifyError(this.$t(err.error));
        },
      );
    },
    downloadExampleFileCSV() {
      this.startLoading();
      this.$services.organization.downloadOrganizationExampleCSV(
        res => {
          this.endLoading();
          this.handleDownloadCSV(res.file_path);
        },
        err => {
          this.endLoading();
          this.notifyError(this.$t(err.error));
        },
      );
    },
    confirmUploadFileCSV(listFiles) {
      let formData = new FormData();
      if (!this.checkEmpty(listFiles.length)) {
        formData.append('file', listFiles[0]);
        this.errorValidate = [];
        this.startLoading();
        this.$services.organization.uploadFileCSV(
          formData,
          () => {
            this.showModalUploadCSV = false;
            this.getListOrganizations();
          },
          err => {
            this.endLoading();
            if (err.code == 422) {
              this.errorValidate = err.error;
            } else {
              this.notifyError(this.$t(err.error));
            }
          },
        );
      } else {
        this.notifyError(this.$t('E-CM-101', { name: this.$t('common.file') }));
      }
    },

    onHandleClickFilter() {
      this.isShowFilter = true;
    },

    async getListOrganizations() {
      const dataFilter = {
        ...this.searchParams,
        page: this.paginate.page,
        per_page: this.paginate.perPage,
      };

      await this.$services.organization.getListOrganization(
        dataFilter,
        response => {
          this.endLoading();
          const { list, paginate } = response;
          this.listOrganizations = list;
          this.paginate = paginate;

          this.$router.push({
            path: this.$route.path,
            query: {
              page: response.paginate.current_page,
              per_page: response.paginate.per_page,
              keyword: this.searchParams.keyword,
              with_deleted: this.searchParams.with_deleted ? 1 : 0,
            },
          });
        },
        err => {
          this.endLoading();
          this.notifyError(this.$t(err.error));
        },
      );
    },

    changePageData(page) {
      this.startLoading();
      this.paginate.page = page;
      this.getListOrganizations();
    },

    onHandleClearCondition() {
      this.searchParams = {
        keyword: '',
        with_deleted: false,
      };
      this.clearFilter = true;
    },

    onHandleSubmitFilter() {
      this.isFilter = true;
      this.startLoading();
      this.getListOrganizations();
    },

    setClearFilter(value) {
      this.clearFilter = value;
      this.isFilter = false;
    },
    onHandleCreateSecurity() {
      this.$router.replace(PAGES.CRISIS_MANAGEMENT_CREATE);
    },
    listenCloseSearchBox() {
      this.isShowFilter = false;
    },
  },
};
</script>
