<template>
  <div>
    <div id="trip--info--expense-list--wrap" class="trip--info--expense-list--wrap">
      <draggable
          v-if="!checkEmpty(costsInput)"
          tag="ul"
          class="trip--info--clip-list ui-sortable"
          :list="costsInput"
          handle=".ui-sortable-handle"
      >
        <li
            style="display: flex"
            v-for="(item, index) in costsInput"
            :key="index"
            class="trip--info--expense-list--group list-input-cost-group"
            :id="`cost_field_${item.id}`"
        >
          <div class="trip--info--expense-list--group--inner">
            <ul class="trip--info--expense-list--group--list">
              <li style="display: block" class="ui-state-disabled">
                <div style="display: flex">
                  <a href="#">
                    <i class="info-item-linked" :style="'opacity: 0'" />
                    <div class="expense-item-body">
                      <span class="expense--name">
                        {{ $t('common.cost_typing') }}
                      </span>
                      <div class="expense-item-subinfo">
                        <span class="expense--account-title">...</span>
                      </div>
                    </div>
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </li>
      </draggable>
      <draggable
          v-if="!checkEmpty(costs)"
          tag="ul"
          class="trip--info--clip-list ui-sortable"
          :list="costs"
          @change="updateOrder"
          handle=".ui-sortable-handle"
          :disabled="disableEdit"
      >
        <li
            style="display: flex"
            v-for="(item, index) in costs"
            :key="index"
            class="trip--info--expense-list--group"
            :id="`cost_field_${item.detail[0].id}`"
        >
          <div class="trip--info--expense-list--group--inner">
            <div class="trip--info--expense-list--group--head ui-state-disabled">
              <span class="expense--amount">
                <span>{{ splitThousandYen(totalAmount(item)) }}</span>
                <span class="currency" />
              </span>
              <button
                  v-if="!disableEdit"
                  @click="deleteAll(item.detail)"
                  class="btn btn-sm btn-flat-danger del-item"
                  :disabled="isOperatorOrAdmin || isHasCostTyping(item)"
              >
                <i class="icon-abui-cross"></i>
              </button>
            </div>
            <ul class="trip--info--expense-list--group--list">
              <li
                  style="display: block"
                  v-for="(val, i) in item.detail"
                  :key="i"
                  class="ui-state-disabled"
                  :style="val.is_used_in_trip_request == 0 && 'background-color: #e6e9ec'"
                  @click="updateCost(val)"
              >
                <div style="display: flex">
                  <a href="#">
                    <i class="info-item-linked" :style="val?.schedules?.length > 0 ? 'opacity: 1' : 'opacity: 0'" />
                    <div class="expense-item-body">
                      <span class="expense--name">
                        <span v-if="val.flag_temporary_cost == 1" class="btn btn-used-in-request" style="background-color: #dcb823; color: #fff">
                           {{ $t('settings.temporary_expenses_label')}}
                        </span>
                        <button v-if="val.is_used_in_trip_request == 0" class="btn btn-used-in-request btn-default">
                          {{ $t('common.used_in_request') }}
                        </button>
                        {{ val.input_status == 2 ? $t('common.cost_typing') : val.title }}
                      </span>
                      <div class="expense-item-subinfo">
                        <span class="expense--account-title">
                          {{ val.company_cost_item_name }}
                        </span>
                        <span class="expense--payto" v-if="val.destination">{{ val.destination }}</span>
                      </div>
                    </div>
                    <div
                        v-if="!checkEmpty(val.file) || !checkEmpty(val.input_status)"
                        class="expense--receipt-attached"
                    >
                      <i class="icon-abui-clip" />
                    </div>
                    <span v-show="val.input_status != 2" class="expense--amount">
                      <span style="width: 100px; text-align: right">{{ splitThousandYen(val.amount) }}</span>
                      <span class="currency" />
                    </span>
                  </a>
                  <template v-if="!disableEdit">
                    <button
                        v-if="!disableEdit && val.input_status != 2"
                        class="btn btn-sm btn-flat-danger del-item"
                        @click.stop="showModalDelete(val.id)"
                        :disabled="isOperatorOrAdmin || isHasCostTyping(item)"
                    >
                      <i class="icon-abui-cross"></i>
                    </button>
                    <span v-else style="width: 38px"></span>
                  </template>
                </div>
                <p
                    v-if="listCostItemError.includes(val.id)"
                    style="margin-left: 34px"
                    role="alert"
                    class="error-feedback"
                >
                  {{ ((tripData.trip_status !== 3 && tripData.report_status_id !== 6) || val.flag_temporary_cost !== 1) ?
                    $t('E-CM-004') : $t('E-CU-129') }}
                </p>
              </li>
            </ul>
            <div
                v-if="!disableEdit && btnShowAddCost"
                class="trip--info--expense-list--group--bottom ui-state-disabled"
            >
              <button
                  class="btn btn-xs btn-flat-default js-btn-addnew-info-item"
                  @click.stop="$emit('createCost', item.order)"
                  :disabled="isOperatorOrAdmin || isHasCostTyping(item)"
              >
                <i class="icon-abui-plus" />
              </button>
            </div>
          </div>
          <span style="margin-right: -24px" class="sortable-handle-transparent ui-sortable-handle"> </span>
        </li>
      </draggable>
      <p v-if="checkEmpty(costs) && checkEmpty(costsInput) && loaded" class="no-data">{{ $t('common.no_data_cost') }}</p>
    </div>
    <ModalConfirm
        :dialogVisible="showBoxConfirm"
        @cancel="showBoxConfirm = false"
        @yes="deleteCostItem"
        :title="$t('Q-CM-104')"
    />
  </div>
</template>
<script>
import ModalConfirm from '@/components/common/ModalConfirm';
import { VueDraggableNext } from 'vue-draggable-next';
export default {
  name: 'Cost',
  emits: ['createCost', 'updateCost', 'reloadTripDetail'],
  components: { ModalConfirm, draggable: VueDraggableNext },
  props: {
    resetCost: {
      type: [Number, String],
      default: 0,
    },
    showAddCost: {
      type: Boolean,
      default: true,
    },
    checkDisableEdit: {
      type: Boolean,
      default: false,
    },
    isReloadCost: {
      type: Boolean,
      default: false,
    },
    listCostItemError: {
      type: [Array, Object],
      default: () => [],
    },
    tripData: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      disableEdit: false,
      btnShowAddCost: true,
      showBoxConfirm: false,
      listCosts: [],
      dragging: false,
      costs: [],
      costsInput: [],
      id: '',
      listError: [],
      loaded: false,
    };
  },
  mounted() {
    this.$emitter.on('checkShowButtonSettlementOfExpenses', () => {
      this.getCostByGroup();
    });
  },
  beforeUnmount() {
    this.$emitter.off('checkShowButtonSettlementOfExpenses');
  },
  watch: {
    checkDisableEdit(val) {
      this.disableEdit = val;
    },
    resetClip() {
      this.$emitter.emit('activeScheduleOff', true);
    },
    showAddCost(val) {
      this.btnShowAddCost = val;
    },
    listCostItemError: {
      handler(val) {
        this.listError = val;
        if (!this.checkEmpty(val)) this.scrollIntoCustom(`cost_field_${val[0]}`);
      },
      deep: true,
    },
    isReloadCost(val) {
      if (val) {
        this.getCostByGroup();
      }
    },
  },
  created() {
    this.disableEdit = this.checkDisableEdit;
    this.getCostByGroup();
  },
  computed: {
    tripId() {
      return this.$route.params.id;
    },
  },
  methods: {
    scrollIntoCustom(elementId = '', additionalOffset = 0) {
      if (elementId == '') return;
      let ele = document.getElementById(`${elementId}`);
      if (!this.checkEmpty(ele)) {
        ele.scrollIntoView({
          top: ele.offsetTop + additionalOffset,
          behavior: 'smooth',
        });
      }
    },
    isHasCostTyping(item) {
      return !this.checkEmpty(item.detail.find(cost => cost.input_status == 2));
    },
    updateCost(val) {
      if (val.input_status == 2) return;
      this.$emit('updateCost', val);
    },
    checkHasDeleteAll(data) {
      let flg = true;
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        if (!this.checkEmpty(element?.schedules) && !this.disableEdit) flg = false;
      }

      return flg;
    },
    totalAmount(data) {
      if (!data?.detail) return 0;

      let amount = 0;
      for (let index = 0; index < data.detail.length; index++) {
        const element = data.detail[index];

        amount += +element.amount;
      }

      return amount;
    },
    getCostByGroup() {
      this.$services.trip_item.getCostByGroup(
          this.tripId,
          res => {
            this.costs = res.costs;
            this.costsInput = res.input_cost;
            this.loaded = true;
          },
          () => {
            this.loaded = true;
            // this.notifyError(this.$t(err.error));
          },
      );
    },
    showModalDelete(id) {
      this.id = id;
      this.showBoxConfirm = true;
    },
    deleteAll(list) {
      this.id = list.map(item => item.id);
      this.showBoxConfirm = true;
    },
    async deleteCostItem() {
      this.startLoading();
      if (Array.isArray(this.id)) {
        for (let index = 0; index < this.id.length; index++) {
          const id = this.id[index];

          await this.$services.trip_item.deleteCostItem(
              id,
              () => {
                this.showBoxConfirm = false;
                this.$emit('reloadTripDetail');
              },
              err => {
                this.notifyError(this.$t(err.error));
              },
          );
        }

        this.endLoading();
        this.notifySuccess(this.$t('I-CM-102'));
        this.showBoxConfirm = false;
        this.getCostByGroup();
      } else {
        this.$services.trip_item.deleteCostItem(
            this.id,
            () => {
              this.endLoading();
              this.notifySuccess(this.$t('I-CM-102'));
              this.showBoxConfirm = false;
              this.getCostByGroup();
              this.$emit('reloadTripDetail');
            },
            err => {
              this.endLoading();
              this.showBoxConfirm = false;
              this.notifyError(this.$t(err.error));
            },
        );
      }
    },
    updateOrder() {
      if (this.disableEdit) return;

      let data = [];
      this.costs.forEach((element, index) => {
        data.push({ order: element.order, new_order: index });
      });

      this.$services.trip_item.updateCostOrder(
          { data: data, trip_id: this.tripId },
          () => {
            this.getCostByGroup();
          },
          err => {
            this.notifyError(this.$t(err.error));
          },
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.display-button {
  float: right;
}
.detail--wrap.clip--detail {
  overflow: scroll;
  height: calc(100vh - 145px);
}
.info-item-no-linked {
  margin-right: 25px;
}
.trip--info--expense-list--wrap {
  height: calc(100vh - 94px);
}
.list-input-cost-group::after {
  content: '' !important;
}
.btn-used-in-request {
  padding: 3px 7px;
  font-size: 11px;
  border-radius: 10px;
  margin-right: 5px;
}
</style>
