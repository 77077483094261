<template>
  <el-dialog
    v-model="isVisible"
    width="704px"
    :show-close="true"
    @close="$emit('handleCloseModal')"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header" style="display: flex; justify-content: flex-start">
          <div class="modal-header--title" style="width: 100%">{{ $t('trip_item.modal_ringi_history.title') }}</div>
        </div>
        <div class="modal-body">
          <div>
            <table class="table">
              <colgroup span="1" style="width: 8em"></colgroup>
              <thead>
                <tr>
                  <th style="width: 150px">{{ $t('trip_item.modal_ringi_history.th1_title') }}</th>
                  <th style="width: 70px">{{ $t('trip_item.modal_ringi_history.th2_title') }}</th>
                  <th style="width: 130px">{{ $t('trip_item.modal_ringi_history.th3_title') }}</th>
                  <th>{{ $t('trip_item.modal_ringi_history.th4_title') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="checkEmpty(requestHistories)">
                  <td class="no-data" colspan="4">{{ $t('common.no_data') }}</td>
                </tr>
                <tr v-else v-for="item in requestHistories" :key="item.id">
                  <td>
                    {{ showDateTime(item.date_time, 'YYYY/MM/DD') }}
                    <br />
                    {{ showDateTime(item.date_time, 'HH:mm') }}
                  </td>
                  <td v-html="labelLocale(item.approval_status)"></td>
                  <td
                    style="min-width: 150px"
                    v-html="
                      !checkEmpty(item.authorizer)
                        ? `${item.approve_name + ' ' + $t('trip_item.trip_process.is_authorizer')}`
                        : item.approve_name
                    "
                  ></td>
                  <td v-html="item.comment" style="max-width: 250px"></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="modal-footer">
          <div class="row">
            <div class="col-sm-12">
              <button
                @click="handleCloseModal()"
                class="btn btn-sm btn-flat-default btn-block js-btn--close-ringi-reffer"
              >
                {{ $t('trip_item.modal_ringi_history.close') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import mixins from '@/helpers/mixins';

export default {
  name: 'ModalRingiHistory',
  emits: ['handleCloseModal'],
  mixins: [mixins],
  props: {
    isModalVisible: {
      type: Boolean,
      default: false,
    },
    requestHistories: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isVisible: false,
    };
  },
  watch: {
    isModalVisible() {
      this.isVisible = this.isModalVisible;
    },
  },
  methods: {
    handleCloseModal() {
      this.$emit('handleCloseModal');
    },
  },
};
</script>
<style scoped>
.modal-header {
  padding-left: 15px;
  padding-right: 10px;
}
.modal-body {
  padding-left: 15px;
  padding-right: 15px;
}
.modal-footer {
  padding-left: 15px;
  padding-right: 15px;
}
</style>
